import React, { useMemo } from 'react';
import { string } from 'prop-types';
import { Tooltip } from 'antd';
import Avatar from 'components/Avatar';
import styled from 'styled-components';
import { colors } from '../../../assets/colors';
import { useTranslation } from 'react-i18next';
// @ts-ignore
const TooltipTitle = (props = { userName: '', email: '' }) => {
  const { t } = useTranslation(['insurance']);
  const { userName, email } = props;
  return (
    <div>
      <div>
        {t('name')}: {userName}
      </div>
      <div>
        {t('Email')}: {email}
      </div>
    </div>
  );
};

TooltipTitle.propTypes = {
  userName: string.isRequired,
  email: string.isRequired,
};

// @ts-ignore
const InsuranceViewColumn = (props = { avatar: '', userName: '', email: '' }) => {
  const { avatar, userName, email } = props;
  const TooltipTitleMemo = useMemo(
    () => <TooltipTitle userName={userName} email={email} />,
    [userName, email],
  );

  return (
    <Container>
      <Avatar name={avatar} />
      <Tooltip title={TooltipTitleMemo}>
        <div style={{ marginLeft: 10 }}>
          <InfoName>{userName?.substring(0, 62)}</InfoName>
          <InfoEmail>{email?.substring(0, 35)}</InfoEmail>
        </div>
      </Tooltip>
    </Container>
  );
};

InsuranceViewColumn.propTypes = {
  userName: string.isRequired,
  email: string.isRequired,
  avatar: string.isRequired,
};

export default React.memo(InsuranceViewColumn);
export const Container = styled.div`
  display: flex;
`;
export const InfoEmail = styled.div`
  color: #9ca3af;
  font-family: Roboto;
  font-size: 12px;
  height: 16px;
`;
export const InfoName = styled.div`
  color: ${colors.gray900};
  font-family: 'Roboto';
  font-size: 14px;
`;

import styled from 'styled-components';

export const Container = styled.div`
  background-color: $grey;
  overflow-y: hidden;
`;

export const ContainerLeft = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: column;
`;

export const HeaderTitle = styled.div`
  font-size: var(--txt_font_size_h1);
  font-weight: var(--txt_font_weight_medium);
  color: black;
`;

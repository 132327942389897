export enum statusWatchingList {
  idle = 'idle',
  pending = 'pending',
  success = 'success',
  error = 'error',
}

export enum typeFilterWatchingList {
  watchingListData = 'watchingListData',
}

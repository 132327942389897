import { GroupTemplateEmail } from 'hrm-common/extensions/interfaces/personel';
import queryString from 'query-string';
import axiosClient from './axiosClient';

const onboardApi = {
  // email template
  getGroupTemplateEmail: async () => {
    const response = await axiosClient.get('/group-email/template-group');
    return response;
  },
  createGroupTemplateEmail: async (group: {
    name: string;
    module: string;
    descriptions: string;
  }) => {
    const response = await axiosClient.post('/group-email/template-group', group);
    return response;
  },
  updateGroupTemplateEmail: async (group: GroupTemplateEmail) => {
    const data = {
      name: group.name,
      module: group.module,
      descriptions: group.descriptions,
    };
    const response = await axiosClient.put(`/group-email/template-group/${group.id}`, data);
    return response;
  },
  deleteGroupTemplateEmail: async (id: string) => {
    const response = await axiosClient.delete(`/group-email/template-group/${id}`);
    return response;
  },
  getVariablesEmail: async (module: string) => {
    const response = await axiosClient.get(`/email/get-email-variable?module=${module}`);
    return response;
  },
  createTemplate: async (params: any) => {
    const data: any = {
      name: params.name,
      subject: params.subject,
      body: params.body,
      descriptions: params.descriptions,
      to: params.to,
      toGroup: params.toRole,
      toDepartment: params.toDepartment,
      toInvolverGroup: params.toGroup,
      cc: params.cc,
      ccGroup: params.ccRole,
      ccDepartment: params.ccDepartment,
      ccInvolverGroup: params.ccGroup,
      bcc: params.bcc,
      bccGroup: params.bccRole,
      bccDepartment: params.bccDepartment,
      bccInvolverGroup: params.bccGroup,
      personalType: params.personalType,
      linkModule: params.linkModule,
      attachment: [],
      groupId: params.groupId,
    };
    const response = await axiosClient.post(`/email/template`, data);
    return response;
  },
  updateTemplate: async (params: any) => {
    const data: any = {
      name: params.name,
      subject: params.subject,
      body: params.body,
      descriptions: params.descriptions,
      to: params.to,
      toGroup: params.toRole,
      toDepartment: params.toDepartment,
      toInvolverGroup: params.toGroup,
      cc: params.cc,
      ccGroup: params.ccRole,
      ccDepartment: params.ccDepartment,
      ccInvolverGroup: params.ccGroup,
      bcc: params.bcc,
      bccGroup: params.bccRole,
      bccDepartment: params.bccDepartment,
      bccInvolverGroup: params.bccGroup,
      personalType: params.personalType,
      linkModule: params.linkModule,
      attachment: params.attachment,
      groupId: params.groupId,
    };

    const response = await axiosClient.put(`/email/template/${params.id}`, data);
    return response;
  },
  getTemplates: (params?: any) => {
    const response = axiosClient.get(`/email/template`);
    return response;
  },
  deleteTemplateById: (params: any) => {
    const response = axiosClient.delete(`/email/template/${params.id}`);
    return response;
  },
  sendMailManual: (params: any) => {
    const response = axiosClient.post(`/email/template/${params.idMailTemplate}/send`, {
      configId: params.from,
      emailType: params.type,
      ids: params.ids,
      toGroup: params.toRole,
      toDepartment: params.toDepartment,
      toInvolverGroup: params.toGroup,
      cc: params.idcc,
      ccGroup: params.ccRole,
      ccDepartment: params.ccDepartment,
      ccInvolverGroup: params.ccGroup,
      bcc: params.idbcc,
      bccGroup: params.bccRole,
      bccDepartment: params.bccDepartment,
      bccInvolverGroup: params.bccGroup,
    });
    return response;
  },
  getAutoSendMailConfig: () => {
    const response = axiosClient.get(`/configurations/config-email`);
    return response;
  },
  createAutoSendMailConfig: (params: any) => {
    const response = axiosClient.post(`/configurations/config-email/create`, params);
    return response;
  },
  updateAutoSendMailConfig: (params: any) => {
    const response = axiosClient.put(`/configurations/config-email/update`, params);
    return response;
  },
  getAutoConfigSendMailByModule: (module: any) => {
    const response = axiosClient.get(`/configurations/module-action?module=${module}`);
    return response;
  },
  getAutoConfigMailIdListByModule: (module: any) => {
    const response = axiosClient.get(`/email/get-all-template-main-info?module=${module}`);
    return response;
  },
  // process
  confirmStep: (params: any) => {
    const response = axiosClient.put(`/onboard/${params.id}/finished`, {
      index: params.index,
      finished: params.finished,
    });
    return response;
  },
  createProcess: (params: any) => {
    const response = axiosClient.post(`/process/template`, params);
    return response;
  },
  getProcess: () => {
    const response = axiosClient.get(`/process/template`);
    return response;
  },
  getProcessById: (params: any) => {
    const response = axiosClient.get(`/process/template/${params.id}`);
    return response;
  },
  getOnboardByEmployee: (params: any) => {
    const response = axiosClient.get(`/onboard/employee/${params.id}`);
    return response;
  },
  getOnboard: (params: any) => {
    const response = axiosClient.get(`/onboard?${queryString.stringify(params)}`);
    return response;
  },
  updateOnboardStatus: (params: any) => {
    const response = axiosClient.put(`/onboard/${params.id}/status`, {
      status: params.status,
    });
    return response;
  },
  deleteProcessById: (params: any) => {
    const response = axiosClient.delete(`/process/template/${params.id}`);
    return response;
  },
  updateProcessStatus: (params: any) => {
    const response = axiosClient.put(`/process/template/${params.id}/status`, {
      active: params.active,
    });
    return response;
  },
  updateTaskTemplate: ({ id, ...params }: any) => {
    const response = axiosClient.put(`/onboard-task/${id}`, params);
    return response;
  },
  updateSelfEvaluate: ({ id, ...params }: any) => {
    const response = axiosClient.put(`/onboard-evaluate/${id}`, params);
    return response;
  },
  updateManagerEvaluate: ({ id, ...params }: any) => {
    const response = axiosClient.put(`/onboard-evaluate/${id}/evaluate`, params);
    return response;
  },
  createEvaluate: ({ id, ...params }: any) => {
    const response = axiosClient.post(`/onboard-evaluate`, params);
    return response;
  },
  createTaskTemplate: ({ id, ...params }: any) => {
    const response = axiosClient.post(`/onboard-task`, params);
    return response;
  },
  sendProcess: (params: any) => {
    const response = axiosClient.put(`/onboard/send-process`, {
      ids: params.ids,
      processId: params.processId,
    });
    return response;
  },
};

export default onboardApi;

import { Button, Checkbox, Col, Divider, Form, Input, Modal, Popconfirm, Radio, Row } from 'antd';
import { notificationToast } from 'components/notificationToast';
import { Notification } from 'constants/notification';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import { useAppSelector } from 'app/hooks';
import { colors } from 'assets/colors';
import { SCHEDULE_INCREASE_PARTICIPANT_LIST } from 'constants/permissions';
import CustomButton from 'styles/buttonStyled';
import { getUser } from 'utils/auth';
import {
  formatCurrencyUnit,
  formatDateInsurances,
  labelInsurancesMenuActions,
  labelKeyNameView,
  labelKeyStatus,
  labelUnit,
} from '../constants/common';
import { createScheduleInsurances, labelInsurancesComplusory } from '../constants/label';
import { postApproved } from '../insuranceAction';
import { selectInsurance } from '../insuranceSlice';
import { InfoInput, InfoRadio, InfoRow } from './ModalCreate';
import { InfoButton } from './ModalEdit';
import ModalHistory from './ModalHistory';
import ModalReject from './ModalReject';
import { useMaskValue } from 'hooks';

const { TextArea } = Input;

interface ViewModalProps {
  isVisible: boolean;
  isShowFullMenu: boolean;
  itemId: any;
  checkview: any;
  onClose: () => void;
  reSetData: () => void;
}
const ModalView = (props: ViewModalProps) => {
  const { isVisible, onClose, itemId } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation(['insurance']);
  let permissions = getUser()?.permissions;
  const insurance = useAppSelector(selectInsurance);
  const dataType = insurance?.dataTypeInsurance?.data?.result;
  const [data, setData] = useState(dataType);
  const [iswidth] = useState(561);
  const [radiovalue, setRadioValue] = useState<any>(1);
  const [isVisibleInsuranceReject, setIsVisibleInsuranceReject] = useState(false);
  const [checkedList, setCheckedList] = useState([]);
  const [checkHistory, setCheckHistory] = useState(true);

  useEffect(() => {
    if (itemId?.adjustFor === 'increase') {
      setRadioValue(createScheduleInsurances.INCREASE_PREMIUM);
    } else {
      setRadioValue(createScheduleInsurances.DECREASE_PREMIUM);
    }
    setCheckHistory(true);
  }, [itemId]);

  useEffect(() => {
    if (props.checkview !== labelKeyNameView.DROPDOWN_PARTICIPANT_LIST) {
      if (dataType) {
        let typeInsurance = itemId?.typeInsurance;
        let obj: any = [];
        let m_obj: any = [];
        for (let i = 0; i < dataType.length; i++) {
          let is_obj: any = [];
          // eslint-disable-next-line array-callback-return
          typeInsurance.map((item: any) => {
            if (String(dataType[i].insuranceCode) === item) {
              obj.push({
                id: dataType[i].insuranceCode,
                insuranceName: dataType[i].insuranceName,
                check: true,
              });
              is_obj.push({ check: true });
              m_obj.push(Number(dataType[i].insuranceCode));
            }
          });
          if (is_obj.length < 1) {
            obj.push({
              id: dataType[i].insuranceCode,
              insuranceName: dataType[i].insuranceName,
              check: false,
            });
          }
        }
        setData(obj);
        setCheckedList(m_obj);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataType]);

  const handleResetAll = () => {};

  const handleClose = () => {
    setCheckHistory(true);
    handleResetAll();
    onClose();
  };
  const handleOpenInsuranceClose = () => {
    setIsVisibleInsuranceReject(true);
  };
  const handleRejectInsurance = () => {
    setIsVisibleInsuranceReject(false);
  };
  const handleRejected = () => {
    handleOpenInsuranceClose();
  };
  const handleApprove = () => {
    dispatch(
      postApproved({
        status: 'approved',
        approvedBy: getUser()?.fullName,
        reason: '',
        select: {
          key: 'id',
          value: [props?.itemId?.id],
        },
        callBack: (response: any) => {
          if (!response.error) {
            props.reSetData && props.reSetData();
            // notification.success({
            //   closeIcon: <></>,
            //   message: t('approved_ansurance_success'),
            //   duration: 4,
            // });
            notificationToast(
              Notification.Type.Success,
              t('approved_ansurance_success'),
              Notification.Duration._4s,
            );
            handleClose();
          }
        },
      }),
    );
  };

  const showButtonReject = () => {
    if (
      permissions?.indexOf(SCHEDULE_INCREASE_PARTICIPANT_LIST.PARTICIPANT_LIST_EXPORT) !== -1 &&
      props.checkview === labelKeyNameView.DROPDOWN_APPROVAL &&
      itemId?.status !== labelInsurancesMenuActions.REJECTED &&
      itemId?.status !== labelInsurancesMenuActions.APPROVED
    ) {
      return <InfoButtonReject onClick={handleRejected}>{t('reject')}</InfoButtonReject>;
    } else {
      return null;
    }
  };
  const showButtonApprove = () => {
    if (
      permissions?.indexOf(SCHEDULE_INCREASE_PARTICIPANT_LIST.PARTICIPANT_LIST_EXPORT) !== -1 &&
      props.checkview === labelKeyNameView.DROPDOWN_APPROVAL &&
      itemId?.status !== labelInsurancesMenuActions.REJECTED &&
      itemId?.status !== labelInsurancesMenuActions.APPROVED
    ) {
      return (
        <Popconfirm
          title={t('Are_you_approve_request')}
          onConfirm={() => {
            handleApprove();
          }}
          // onCancel={cancel}
          okText={t('yes')}
          cancelText={t('no')}
        >
          <InfoButton>{t('approve')}</InfoButton>
        </Popconfirm>
      );
    } else {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            marginRight: 15,
          }}
        >
          <InfoButton onClick={handleClose}>{t('close')}</InfoButton>
        </div>
      );
    }
  };
  const getClickCheckHistory = () => {
    console.log(props.itemId);
    setCheckHistory(!checkHistory);
  };
  return (
    <>
      <InsuranceModalView
        title={labelInsurancesComplusory.SCHEDULE + itemId?.user?.fullName}
        visible={isVisible}
        maskClosable={false}
        onCancel={handleClose}
        width={iswidth}
        style={{ top: 40 }}
        footer={null}
      >
        <Container>
          <ContainerTop type={itemId?.status}>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <div style={{ display: 'flex' }}>
                  <InfoTitle>{t('status')} :</InfoTitle>
                  <InfoText type={itemId?.status}>{itemId?.status}</InfoText>
                </div>
              </Col>
            </Row>
            <InfoRow gutter={[16, 16]} style={{ marginTop: -10 }}>
              <Col span={12}>
                <InfoTitle>{t('name')}</InfoTitle>
                <InfoInput
                  value={itemId?.user?.fullName}
                  disabled
                  style={{ background: colors.gray100 }}
                  type="text"
                />
              </Col>
              <Col span={12}>
                <InfoTitle>{createScheduleInsurances.ID}</InfoTitle>
                <InfoInput
                  value={itemId?.user?.employeeId}
                  disabled
                  style={{ background: colors.gray100 }}
                  type="text"
                />
              </Col>
            </InfoRow>
            <InfoRow gutter={[16, 16]} style={{ marginTop: -15 }}>
              <Col span={12}>
                <InfoTitle>{t('position')}</InfoTitle>
                <InfoInput
                  value={itemId?.user?.positionName}
                  disabled
                  style={{ background: colors.gray100 }}
                  type="text"
                />
              </Col>
              <Col span={12}>
                <InfoTitle>{t('social_insurance_number')}</InfoTitle>
                <InfoInput
                  value={itemId?.user?.socialInsuranceNumber}
                  disabled
                  style={{ background: colors.gray100 }}
                  type="text"
                />
              </Col>
            </InfoRow>
            <InfoRow gutter={[16, 16]} style={{ marginTop: -15 }}>
              <Col span={12}>
                <InfoTitle>{t('current_salary_premium')}</InfoTitle>
                <InfoInput
                  value={useMaskValue(formatCurrencyUnit(itemId?.currentSalaryPremium, labelUnit))}
                  disabled
                  style={{ background: colors.gray100 }}
                  type="text"
                />
              </Col>
              <Col span={12}>
                <InfoTitle>{t('last_join_social_insurance_date')}</InfoTitle>
                <InfoInput
                  value={
                    itemId?.currentValidDate ? formatDateInsurances(itemId?.currentValidDate) : ''
                  }
                  disabled
                  style={{ background: colors.gray100 }}
                  type="text"
                />
              </Col>
            </InfoRow>

            <InfoRow gutter={[16, 16]} style={{ marginTop: -15 }}>
              <Col span={12}>
                <InfoTitle>{t('adjust_for')}</InfoTitle>
                <InfoRadio.Group value={radiovalue}>
                  <InfoRadioN disabled value={createScheduleInsurances.INCREASE_PREMIUM}>
                    {t('increase_premium')}
                  </InfoRadioN>
                  <InfoRadioN
                    disabled
                    value={createScheduleInsurances.DECREASE_PREMIUM}
                    style={{ marginTop: 6 }}
                  >
                    {t('decrease_premium')}
                  </InfoRadioN>
                </InfoRadio.Group>
              </Col>
              <Col span={12}>
                <InfoTitle>{t('insurance_policy')}</InfoTitle>
                <div style={{ height: 80, width: '100%', overflowY: 'scroll' }}>
                  <div style={{ padding: 10 }}>
                    <Checkbox.Group value={checkedList}>
                      {data !== undefined
                        ? data.map((item: any) => (
                            <InfoCheckbox
                              disabled
                              key={item?.id}
                              style={{ margin: -0.5 }}
                              value={item?.id}
                            >
                              {item?.insuranceName}
                            </InfoCheckbox>
                          ))
                        : null}
                    </Checkbox.Group>
                  </div>
                </div>
              </Col>
            </InfoRow>
            <InfoRow gutter={[16, 16]} style={{ marginTop: -15 }}>
              <Col span={12}>
                <InfoTitle>{t('new_salary_premium')}</InfoTitle>
                <InfoInput
                  value={useMaskValue(formatCurrencyUnit(itemId?.newSalaryPremium, labelUnit))}
                  disabled
                  style={{ background: colors.gray100 }}
                  type="text"
                />
              </Col>
              <Col span={12}>
                <InfoTitle>{t('new_valid_date')}</InfoTitle>
                <InfoInput
                  value={formatDateInsurances(itemId?.newValidDate)}
                  disabled
                  style={{ background: colors.gray100 }}
                  type="text"
                />
              </Col>
            </InfoRow>
            <InfoRow gutter={[16, 16]} style={{ marginTop: -15 }}>
              <Col span={24}>
                <InfoTitle>{t('note')}</InfoTitle>
                <TextArea
                  rows={2}
                  disabled
                  value={itemId?.note}
                  style={{
                    padding: 6,
                    width: '100%',
                    borderRadius: 6,
                    color: colors.gray900,
                    background: colors.gray100,
                  }}
                />
              </Col>
            </InfoRow>
            {itemId?.status === labelKeyStatus.KEY_REJECTED ? (
              <InfoRow gutter={[16, 16]} style={{ marginTop: -15 }}>
                <Col span={24}>
                  <InfoTitle>{createScheduleInsurances.REASON}</InfoTitle>
                  <TextArea
                    rows={2}
                    disabled
                    value={itemId?.reason}
                    // placeholder={createScheduleInsurances.REASON}
                    style={{
                      padding: 6,
                      width: '100%',
                      borderRadius: 6,
                      color: colors.gray900,
                      background: colors.gray100,
                    }}
                  />
                </Col>
              </InfoRow>
            ) : null}

            {props.itemId && (
              <div style={{ marginTop: -6 }}>
                <CustomButton
                  aType="text"
                  style={{
                    backgroundColor: '#fff',
                    boxShadow: 'none',
                    marginTop: 10,
                    color: '#000',
                  }}
                  onClick={() => getClickCheckHistory()}
                >
                  {checkHistory === false ? (
                    <div style={{ display: 'flex', marginLeft: -12 }}>
                      <InfoTitle style={{ color: '#F1592A', margin: 0, marginTop: 3 }}>
                        {t('view_history')}
                      </InfoTitle>
                    </div>
                  ) : (
                    <div style={{ display: 'flex', marginLeft: -12 }}>
                      <InfoTitle style={{ color: '#F1592A', margin: 0, marginTop: 3 }}>
                        {t('hide_history')}
                      </InfoTitle>
                    </div>
                  )}
                </CustomButton>
              </div>
            )}
            {checkHistory === false ? null : itemId?.status === labelKeyStatus.KEY_REJECTED ? (
              <div style={{ height: 400, width: '100%', marginTop: 20 }}>
                <ModalHistory itemId={props.itemId} />
              </div>
            ) : null}
          </ContainerTop>
          <ContainerButton>
            {showButtonReject()}
            {showButtonApprove()}
          </ContainerButton>
        </Container>
      </InsuranceModalView>
      <ModalReject
        isVisible={isVisibleInsuranceReject}
        isShowFullMenu={false}
        itemId={props?.itemId}
        reSetData={props?.reSetData}
        onClose={handleRejectInsurance}
      />
    </>
  );
};

export default React.memo(ModalView);
export const EmployeeAddModalContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  height: 100%;
  margin-right: -15px;
`;
export const Container = styled.div`
  height: 620px;
  width: 100%;
`;
export const ContainerTop = styled.div`
  height: 92%;
  ${(props: InfoTextProps) => {
    // @ts-ignore
    switch (props?.type) {
      case labelKeyStatus.KEY_REJECTED:
        return css`
          overflow-y: scroll;
          overflow-x: hidden;
          padding-right: 4.5%;
        `;
      default:
        return css`
          padding-right: 4.5%;
        `;
    }
  }};
`;
export const ContainerButton = styled.div`
  margin-top: 10px;
  width: 98%;
  display: flex;
  justify-content: flex-end;
`;
export const InfoButtonReject = styled(Button)`
  height: 32px;
  background: #ffffff;
  color: #6b7280;
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto;
  border-radius: 6px;
  margin-right: 10px;
`;

interface InfoTextProps {
  type: string;
  al?: boolean;
}

export const InfoTitle = styled.div`
  //height: 20px;
  margin-top: 15px;
  color: #000000;
  font-size: 14px;
  font-family: Roboto;
`;

export const InsuranceModalView = styled(Modal)`
  .ant-modal-header {
    background: linear-gradient(263.81deg, #f1592a -3.68%, #f57644 45.38%, #fa9b66 108.73%);
  }

  .ant-modal-title {
    color: #ffffff;
    //color: ${colors.gray900};
    font-size: 20px;
  }

  .ant-modal-close-x {
    color: #ffffff;
    //color: ${colors.gray900};
    font-size: 20px;
  }
  .ant-modal-body {
    margin-right: -4.29%;
  }

  .ant-modal-content {
    width: $iswidth;

    .ant-modal-close-x {
      margin-right: -10px;
    }

    .ant-modal-body {
      margin-top: -25px;
    }
  }
`;

export const InfoText = styled.div`
  margin-top: 15px;
  margin-left: 10px;
  padding: 0px 15px;
  color: #000000;
  font-size: 14px;
  border-radius: 4px;
  ${(props: InfoTextProps) => {
    // @ts-ignore
    switch (props?.children) {
      case labelKeyStatus.KEY_INACTIVE:
        return css`
          background: #fee2e2;
          color: #dc2626;
        `;
      case labelKeyStatus.KEY_PENDING:
        return css`
          background: #fef3c7;
          color: #d97706;
        `;
      case labelKeyStatus.KEY_ACTIVE:
        return css`
          background: #d1fae5;
          color: #059669;
        `;
      case labelKeyStatus.KEY_REJECTED:
        return css`
          background: #fee2e2;
          color: #dc2626;
        `;
      case labelKeyStatus.KEY_WAITING_FOR_APPROVAL:
        return css`
          background: #fef3c7;
          color: #d97706;
        `;
      case labelKeyStatus.KEY_APPROVED:
        return css`
          background: #d1fae5;
          color: #059669;
        `;
      case labelKeyStatus.KEY_DRAFT:
        return css`
          background: #d1d5db;
          color: #374151;
        `;
      default:
        break;
    }
  }};
`;

export const EmployeeAddModalDivider = styled(Divider)`
  height: 752px;
  border-left-width: 5px;
  border-radius: 2px;
`;

export const EmployeeModalForm = styled(Form)`
  padding-top: 15px;
  padding-right: 15px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 752px;

  &::-webkit-scrollbar-track {
    margin-top: 20px;
  }

  &::-webkit-scrollbar {
    background-color: var(--cl_white);
  }
`;
export const InfoCheckbox = styled(Checkbox)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background: #f1c5b8;
    border-color: #f1c5b8;
  }
`;
export const InfoRadioN = styled(Radio)`
  .ant-radio-disabled .ant-radio-inner {
    border-color: #f1c5b8;
  }
  .ant-radio-inner::after {
    background: #f1c5b8;
    border-color: #f1c5b8;
  }
`;
export const InfoDiv = styled.div`
  -ms-transform: rotate(450deg);
  transform: rotate(450deg);
  font-size: 20px;
  color: #f1592a;
  margin-left: 4px;
  font-family: Roboto;
`;

export const ContainerLoading = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

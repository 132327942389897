import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import AdvancesTable from './components/AdvancesTable';

const AdvancesInformantionFields = (props: any) => {
  const { isShowComponents, employeeData, callBack, monthYear } = props;

  const { t } = useTranslation(['modal', 'employee', 'timesheet']);

  const handleData = (data: any) => {
    props.onReceiveData(data);
  };

  return (
    <div style={isShowComponents ? {} : { display: 'none' }}>
      <SkillInfoTitle>
        {' '}
        {t('employee:advance_information_fields.advance_information')}
      </SkillInfoTitle>
      <div style={{ backgroundColor: '#fff' }}>
        <AdvancesTable
          employeeData={employeeData}
          callBack={callBack}
          onReceiveData={handleData}
          monthYear={monthYear}
        />
      </div>
    </div>
  );
};

export default AdvancesInformantionFields;
export const SkillInfoTitle = styled.div`
  height: 38px;
  font-size: 20px;
  font-weight: 550;
  background-color: var(--cl_gray200);
  border-radius: 5px;
  line-height: 38px;
  padding: 25px 10px 25px 10px;
  bottom: 1000px;
  margin-top: 25px;
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

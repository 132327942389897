import authApi from 'api/authApi';
import firebase from 'utils/firebase';
import { setUser } from 'utils/auth';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  PERMISSION_PROFILE_ACCESS_PERSONAL,
  PERMISSION_TIMESHEET_ACCESS_PERSONAL,
  PERMISSION_PAYSLIP_ACCESS_PERSONAL,
  PERMISSION_TIMEKEEPING_ACCESS_PERSONAL,
  PERMISSION_ONBOARD_ACCESS_PERSONNEL,
  PERMISSION_OFFBOARD_ACCESS_PERSONNEL,
  PERMISSION_DEVICE_ACCESS_PERSONNEL,
  PERMISSION_TIMESHEET_ACCESS_OBSERVATION_LIST,
  PERMISSION_LEAVE_OFF_ACCESS_PERSONAL,
  PERMISSION_TIMESHEET_REQUEST_APPROVAL,
} from 'constants/permissions';
import axiosClient from 'api/axiosClient';
import { setGlobalLoading } from 'app/commonRedux/appSlice';
import { ROOT_API, TIMEZONE_DEFAULT } from 'constants/commons';

const removeDuplicates = (arr: Array<string>) => {
  let s = new Set(arr);
  let it = s.values();
  return Array.from(it);
};

export const loginWithPassword = createAsyncThunk(
  'auth/loginWithPassword',
  async (
    params: { username?: string; phone?: string; password: string; callBack?: any },
    thunkApi,
  ) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      axiosClient.defaults.headers.common['authorizationMs'] = '';
      let user: any = null;
      if (params?.phone) {
        user = await axiosClient.post(`${ROOT_API}/auth/signin`, {
          phone: params?.phone,
          password: params?.password,
          callBack: params?.callBack,
        });
      } else {
        user = await axiosClient.post(`${ROOT_API}/auth/signin`, {
          username: params?.username,
          password: params?.password,
          callBack: params?.callBack,
        });
      }

      const newUser = {
        ...user,
        type_login: 'local',
        timeZone: user?.timeZone ? user?.timeZone : TIMEZONE_DEFAULT,
        permissions: [
          ...user.permissions,
          PERMISSION_PROFILE_ACCESS_PERSONAL.PROFILE_ACCESS_PERSONAL,
          PERMISSION_TIMESHEET_ACCESS_PERSONAL.TIMESHEET_ACCESS_PERSONAL,
          PERMISSION_PAYSLIP_ACCESS_PERSONAL,
          PERMISSION_TIMEKEEPING_ACCESS_PERSONAL,
          PERMISSION_ONBOARD_ACCESS_PERSONNEL.ONBOARD_ACCESS_PERSONNEL,
          PERMISSION_OFFBOARD_ACCESS_PERSONNEL.OFFBOARD_ACCESS_PERSONNEL,
          PERMISSION_DEVICE_ACCESS_PERSONNEL.DEVICE_ACCESS_PERSONNEL,
          PERMISSION_TIMESHEET_ACCESS_OBSERVATION_LIST.TIMESHEET_ACCESS_OBSERVATION_LIST,
          PERMISSION_LEAVE_OFF_ACCESS_PERSONAL,
        ],
        extraPermissions: removeDuplicates([
          ...user.extraPermissions,
          user?.permissions?.includes(
            PERMISSION_TIMESHEET_REQUEST_APPROVAL.TIMESHEET_REQUEST_APPROVAL,
          )
            ? 'PROJECT_LEADER'
            : '',
        ]),
      };
      setUser(newUser);
      params.callBack && params.callBack(user);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return newUser;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      params.callBack && params.callBack({ error });
      thunkApi.rejectWithValue(error);
    }
  },
);

export const loginWithMicrosoft = createAsyncThunk(
  'auth/loginWithMicrosoft',
  async (params: { email: string; accessToken: string }) => {
    axiosClient.defaults.headers.common['authorizationMs'] = params.accessToken;
    const user: any = await axiosClient.post(`auth/signin`, {
      email: params.email,
    });
    const newUser = {
      ...user,
      type_login: 'ms',
      timeZone: user?.timeZone ? user?.timeZone : TIMEZONE_DEFAULT,
      permissions: [
        ...user.permissions,
        PERMISSION_PROFILE_ACCESS_PERSONAL.PROFILE_ACCESS_PERSONAL,
        PERMISSION_TIMESHEET_ACCESS_PERSONAL.TIMESHEET_ACCESS_PERSONAL,
        PERMISSION_PAYSLIP_ACCESS_PERSONAL,
        PERMISSION_TIMEKEEPING_ACCESS_PERSONAL,
        PERMISSION_ONBOARD_ACCESS_PERSONNEL.ONBOARD_ACCESS_PERSONNEL,
        PERMISSION_OFFBOARD_ACCESS_PERSONNEL.OFFBOARD_ACCESS_PERSONNEL,
        PERMISSION_DEVICE_ACCESS_PERSONNEL.DEVICE_ACCESS_PERSONNEL,
        PERMISSION_TIMESHEET_ACCESS_OBSERVATION_LIST.TIMESHEET_ACCESS_OBSERVATION_LIST,
        PERMISSION_LEAVE_OFF_ACCESS_PERSONAL,
      ],
      extraPermissions: removeDuplicates([
        ...user.extraPermissions,
        user?.permissions?.includes(
          PERMISSION_TIMESHEET_REQUEST_APPROVAL.TIMESHEET_REQUEST_APPROVAL,
        )
          ? 'PROJECT_LEADER'
          : '',
      ]),
    };
    setUser(newUser);
    return newUser;
  },
);

export const loginWithFirebase = createAsyncThunk(
  'auth/loginWithFirebase',
  async (params: any, { rejectWithValue }: any) => {
    const provider = new firebase.auth.OAuthProvider('microsoft.com');
    const result = await firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(() => {
        return firebase.auth().signInWithPopup(provider);
      });
    if (!result) {
      // error
      return rejectWithValue('Error login firebase');
    }

    const currentUser = await firebase.auth().currentUser;
    const idToken = currentUser ? await currentUser.getIdToken() : '';
    if (!currentUser || !idToken) {
      // error
      return rejectWithValue('Error login firebase');
    }

    const data = {
      username: currentUser.email,
      password: '',
    };
    const user: any = await authApi.loginWithMicrosoft(data, idToken);
    const newUser = {
      ...user,
      type_login: 'ms',
      timeZone: user?.timeZone ? user?.timeZone : TIMEZONE_DEFAULT,
      permissions: [
        ...user.permissions,
        PERMISSION_PROFILE_ACCESS_PERSONAL.PROFILE_ACCESS_PERSONAL,
        PERMISSION_TIMESHEET_ACCESS_PERSONAL.TIMESHEET_ACCESS_PERSONAL,
        PERMISSION_PAYSLIP_ACCESS_PERSONAL,
        PERMISSION_TIMEKEEPING_ACCESS_PERSONAL,
        PERMISSION_ONBOARD_ACCESS_PERSONNEL.ONBOARD_ACCESS_PERSONNEL,
        PERMISSION_OFFBOARD_ACCESS_PERSONNEL.OFFBOARD_ACCESS_PERSONNEL,
        PERMISSION_DEVICE_ACCESS_PERSONNEL.DEVICE_ACCESS_PERSONNEL,
        PERMISSION_TIMESHEET_ACCESS_OBSERVATION_LIST.TIMESHEET_ACCESS_OBSERVATION_LIST,
        PERMISSION_LEAVE_OFF_ACCESS_PERSONAL,
      ],
      extraPermissions: removeDuplicates([
        ...user.extraPermissions,
        user?.permissions?.includes(
          PERMISSION_TIMESHEET_REQUEST_APPROVAL.TIMESHEET_REQUEST_APPROVAL,
        )
          ? 'PROJECT_LEADER'
          : '',
      ]),
    };
    setUser(newUser);
    return newUser;
  },
);

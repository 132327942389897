import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import dashboardEmployeeApi from 'api/dashboardEmployeeApi';
import { exportToCSV } from 'utils/common';
import HeadcountPositionTableFilter from './HeadcountPositionTableFilter';
import ITVTable, { COLUMNS } from 'components/ITVTable';
import moment from 'moment';
import HeadcountLineChart from '../HeadcountLine/HeadcountLineChart';

const StyledContainer = styled.div`
  padding: 16px 24px;
  border: 0.5px solid var(--cl_gray200);
  border-radius: 12px;
  background-color: var(--cl_white);
  height: auto;
`;

const StyledHeaderTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto;
  line-height: 20px;
  color: #121926;
`;

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  flex-wrap: wrap;
  gap: 10px;
`;

export function HeadcountPositionTable() {
  const { t } = useTranslation(['dashboard']);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const [data, setData] = useState();

  const [sizePage, setSizePage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [dataChart, setDataChart] = useState({
    labels: [],
    datasets: [],
  });

  const [filters, setFilters] = useState<{ period: string }>({
    period: moment(new Date()).format('yyyy-MM'),
  });

  useEffect(() => {
    async function fetchHeadcountByPosition() {
      try {
        setIsLoading(true);
        const response = await dashboardEmployeeApi.getDataHeadcountByPositionTable(filters);
        setData(response?.data?.result);
        const datasets = [
          {
            label: '',
            data: response?.data?.result?.map((item: any) => item?.count),
            fill: true,
            backgroundColor: '#F37725',
            borderColor: 'white',
            barThickness: 20,
            borderRadius: 4,
          },
        ];
        setDataChart({
          labels: response?.data?.result?.map((item: any) => item?.position),
          datasets: datasets,
        });
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }

    if (filters?.period) {
      fetchHeadcountByPosition();
    }
  }, [filters, filters?.period]);

  const handleSubmit = ({ ...payload }: any) => {
    setFilters({ period: moment(payload?.monthYear).format('yyyy-MM') });
  };

  const handleExport = async () => {
    try {
      setIsLoadingExport(true);
      const response = await dashboardEmployeeApi.exportHeadcountByPositionTable(filters);
      setIsLoadingExport(false);
      const exportFileName = `Headcount_By_Position_${filters.period}.xlsx`;
      exportToCSV(response, exportFileName);
    } catch (error) {
      setIsLoadingExport(false);
    }
  };

  const columns: COLUMNS[] = [
    {
      title: t('dashboard_employee.postion'),
      width: 200,
      minWidth: 200,
      dataIndex: 'position',
      key: 'position',
      align: 'center',
      render: function (text: any, record: any, index: any) {
        return text;
      },
    },
    {
      title: t('dashboard_employee.total_headcount'),
      width: 200,
      minWidth: 200,
      dataIndex: 'count',
      key: 'count',
      align: 'center',

      render: function (text: any, record: any, index: any) {
        return text;
      },
    },
  ];

  return (
    <StyledContainer>
      <StyledWrapper>
        <StyledHeaderTitle>{t('dashboard_employee.headcount_by_position')}</StyledHeaderTitle>

        <HeadcountPositionTableFilter
          onSubmit={handleSubmit}
          loadingExport={isLoadingExport}
          handleExport={handleExport}
          initialValues={{ monthYear: moment(new Date()) }}
        />
      </StyledWrapper>
      {/* <ITVTable
        //   rowKey={['key', 'groupCode']}
        columns={columns}
        data={data}
        isRowLight={true}
        totalResult={[]?.length || 0} //Check number of object with same name and take that length of return object
        isRowSelect={false}
        isScroll={true}
        setOffset={setCurrentPage}
        offset={currentPage}
        setLimit={setSizePage}
        limit={sizePage}
      /> */}
      <HeadcountLineChart loading={isLoading} dataChart={dataChart} />
    </StyledContainer>
  );
}

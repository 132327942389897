import styled from 'styled-components';
import { Input, Checkbox } from 'antd';

export const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 20rem;
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: white;
  padding: 1rem;
  box-shadow: 0px 10px 18px rgba(9, 30, 66, 0.15), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 8px;
`;

export const FooterStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const TitleStyled = styled.div`
  font-size: var(--txt_font_size_h3);
  font-weight: var(--txt_font_weight_medium);
  padding-bottom: 10px;
`;

export const SearchStyled = styled(Input.Search)`
  .ant-input-group-addon {
    display: none;
  }
  .ant-input-affix-wrapper {
    height: 32px;
    input {
      font-size: 14px;
    }
  }
`;

export const IconImgStyled = styled.img`
  height: 15px;
  width: 15px;
  margin-right: 11px;
`;

export const CheckoxGroupStyled = styled(Checkbox.Group)`
  display: flex;
  flex-direction: column;
  height: 15rem;
  overflow-y: auto;
  margin-bottom: 0.5rem;

  &::-webkit-scrollbar-track {
    margin-top: -20px;
  }
`;

export const CheckoxStyled = styled(Checkbox)`
  font-size: var(--txt_font_size_body);
  color: var(--cl_gray900);
  margin-top: 10px;
`;

export const DoneButton = styled.div`
  text-align: center;
  padding: 4px 10px;
  border-radius: 3px;
  background-color: var(--cl_primary);
  color: white;
  font-weight: 700;
  cursor: context-menu;
  &:hover {
    background-color: var(--cl_primary_dark);
  }
`;

export const CancelButton = styled.div`
  text-align: center;
  padding: 4px 10px;
  border-radius: 3px;
  background-color: white;
  border: 1px var(--cl_gray300) solid;
  font-weight: 700;
  color: var(--cl_gray400);
  cursor: context-menu;
  &:hover {
    color: var(--cl_primary);
    border: 1px var(--cl_primary) solid;
  }
`;

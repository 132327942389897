import { IconProps } from 'types';

export const TotalEmployeeIcon = ({ width = '24px', height = '24px', className }: IconProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.9866 10.6715C11.9134 10.6642 11.8256 10.6642 11.7451 10.6715C10.0027 10.6129 8.61914 9.18542 8.61914 7.42847C8.61914 5.63491 10.0686 4.1781 11.8695 4.1781C13.6631 4.1781 15.1199 5.63491 15.1199 7.42847C15.1125 9.18542 13.7289 10.6129 11.9866 10.6715Z"
      stroke="#121926"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.04755 14.0965C6.13649 15.3758 6.13649 17.4606 8.04755 18.732C10.2192 20.1851 13.7807 20.1851 15.9524 18.732C17.8634 17.4527 17.8634 15.368 15.9524 14.0965C13.7886 12.6514 10.2271 12.6514 8.04755 14.0965Z"
      stroke="#121926"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.1831 6.7406C19.4287 6.7406 20.4302 7.74862 20.4302 8.98777C20.4302 10.2012 19.4672 11.19 18.2665 11.2349C18.2152 11.2285 18.1574 11.2285 18.0996 11.2349"
      stroke="#121926"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.8872 18.8438C20.4959 18.717 21.0708 18.4719 21.5443 18.1083C22.8631 17.1192 22.8631 15.4875 21.5443 14.4983C21.0793 14.1432 20.5128 13.9065 19.9126 13.7712"
      stroke="#121926"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.82627 6.7406C4.5807 6.7406 3.5791 7.74862 3.5791 8.98777C3.5791 10.2012 4.54217 11.19 5.74281 11.2349C5.79417 11.2285 5.85196 11.2285 5.90974 11.2349"
      stroke="#121926"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.11935 18.8438C3.51064 18.717 2.93575 18.4719 2.4623 18.1083C1.14343 17.1192 1.14343 15.4875 2.4623 14.4983C2.92729 14.1432 3.49373 13.9065 4.09399 13.7712"
      stroke="#121926"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

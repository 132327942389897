import { DatePicker, Select } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import IconExport from 'assets/icons/ic_export_gray.svg';
import { CustomIcon } from 'components/ITVSearch';
import ITVTable from 'components/ITVTable';
import PageHeader from 'components/PageHeader';
import { YYYY_MM_DD } from 'constants/commons';
import { ContainerMain, HeaderRemove } from 'features/masterData/Department/styled';
import { TitleColumnCenter } from 'styles/tableStyled';
import timesheetApi from 'api/timesheetApi';
import { getErrorMessage } from 'utils/getErrorMessage';
import { notificationToast } from 'components/notificationToast';
import { Notification } from 'constants/notification';
import { exportToCSV } from 'utils/common';

const { RangePicker } = DatePicker;

export function TimesheetReportPage() {
  const { t } = useTranslation(['action', 'report', 'timesheet']);

  const [isLoading, setIsLoading] = useState(false);

  const [filters, setFilters] = useState({
    typeExport: 'excel',
    dateRange: {
      start: moment().startOf('month').format(YYYY_MM_DD),
      end: moment().endOf('month').format(YYYY_MM_DD),
    },
  });

  const IconExportComponents = (
    <CustomIcon type={'export'} component={() => <img src={IconExport} alt="" />} />
  );

  const listReports = [
    {
      id: 1,
      label: 'Timesheet Report',
    },
  ];

  const exportTypes = [
    {
      value: 'excel',
      label: 'Excel',
    },
    {
      value: 'pdf',
      label: 'PDF',
    },
  ];

  let COLUMNS = [
    {
      title: () => {
        return <TitleColumnCenter>{t('report:report_name')}</TitleColumnCenter>;
      },
      dataIndex: 'label',
      key: 'label',
      ellipsis: true,
      width: 100,
      minWidth: 100,
    },
    {
      title: () => {
        return <TitleColumnCenter>{t('report:fillter_period')}</TitleColumnCenter>;
      },
      dataIndex: 'filterPeriod',
      key: 'filterPeriod',
      ellipsis: true,
      align: 'center',
      width: 100,
      minWidth: 100,
      render: function (text: any, record: any, index: any) {
        return (
          <RangePicker
            format={YYYY_MM_DD}
            style={{ marginLeft: '1%' }}
            onChange={onChangeDateTime}
            value={[
              filters.dateRange.start ? moment(filters.dateRange.start) : null,
              filters.dateRange.end ? moment(filters.dateRange.end) : null,
            ]}
          />
        );
      },
    },
    {
      title: () => {
        return <TitleColumnCenter>{t('report:export_format')}</TitleColumnCenter>;
      },
      dataIndex: 'exportFormat',
      key: 'exportFormat',
      ellipsis: true,
      align: 'center',
      width: 60,
      minWidth: 60,
      render: function (text: any, record: any, index: any) {
        return (
          <Select
            defaultValue={exportTypes[0].value}
            style={{ width: 130 }}
            onSelect={(value: string) => {
              onSelectTypes(value);
            }}
            options={exportTypes}
          />
        );
      },
    },
    {
      title: t('action:action'),
      dataIndex: 'action',
      align: 'center',
      key: 'action',
      width: 50,
      minWidth: 50,
      render: function (text: any, record: any, index: any) {
        return (
          <HeaderRemove
            onClick={() => handleExport()}
            style={{ marginLeft: 5 }}
            icon={IconExportComponents}
            loading={isLoading}
          >
            {t('action:export')}
          </HeaderRemove>
        );
      },
    },
  ];

  const onChangeDateTime = (_: any, dateStrings: any) => {
    setFilters({
      ...filters,
      dateRange: {
        start: dateStrings[0],
        end: dateStrings[1],
      },
    });
  };

  const onSelectTypes = (value: any) => {
    setFilters({
      ...filters,
      typeExport: value,
    });
  };

  const handleExport = async () => {
    try {
      setIsLoading(true);
      const response = await timesheetApi.exportTimesheetSummaryKeeping(filters);

      const exportFileName = `Timesheet_Report.xlsx`;
      exportToCSV(response, exportFileName);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      const message = getErrorMessage(error);
      notificationToast(Notification.Type.Error, message, Notification.Duration._3s);
    }
  };

  return (
    <StyledContainer>
      <PageHeader title={t('timesheet:timesheet_report')} />

      <ContainerMain>
        <ITVTable columns={COLUMNS} data={listReports} isRowSelect={false} />
      </ContainerMain>
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

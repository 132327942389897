import React from 'react';
import { Card, Col, Radio, Row } from 'antd';
import { useController } from 'react-hook-form';
import styled from 'styled-components';
import { InputField } from 'components/FormFields';
import { useTranslation } from 'react-i18next';

const StyledContainer = styled.div`
  .row {
    margin-bottom: 16px;
  }

  .label {
    text-align: left;
    padding-right: 8px;
    flex: 1 0 30px;
  }

  .input,
  .select,
  .textarea,
  .datepicker {
    flex: 2;
  }

  .select .ant-select {
    width: 100%;
  }

  .textarea .ant-input {
    width: 100%;
    min-height: 120px; /* Đặt chiều cao tối thiểu cho TextArea */
  }

  .datepicker .ant-picker {
    width: 100%;
  }

  .status .ant-select {
    width: 100%;
    min-height: 100px; /* Đặt chiều cao tối thiểu cho Select */
    display: flex;
    align-items: center; /* Căn giữa nội dung */
  }
`;

const CourseEval = ({ control, getValues }) => {
  const { field } = useController({
    name: 'users[0].userFeedback',
    control,
    defaultValue: '', // giá trị mặc định
  });
  const { t } = useTranslation(['trainningManagement']);

  return (
    <StyledContainer>
      <Card
        title={t('trainningManagement:course_management.course_eval_feedback')}
        bordered={false}
        headStyle={{
          fontSize: 24,
          fontWeight: 500,
        }}
      >
        <Row gutter={[100, 16]}>
          <Col span={18}>
            <Row className="row" align="middle">
              <Col className="label">
                {t('trainningManagement:course_management.how_do_you_feel')}
              </Col>
            </Row>
            <Row style={{ marginBottom: 16 }}>
              <Radio.Group {...field}>
                <Radio value="very good">
                  {t('trainningManagement:course_management.very_good')}
                </Radio>
                <Radio value="good">{t('trainningManagement:course_management.good')}</Radio>
                <Radio value="fair">{t('trainningManagement:course_management.fair')}</Radio>
                <Radio value="poor">{t('trainningManagement:course_management.poor')}</Radio>
                <Radio value="very poor">
                  {t('trainningManagement:course_management.very_poor')}
                </Radio>
              </Radio.Group>
            </Row>
            <Row className="row" align="middle">
              <Col className="label">
                {t('trainningManagement:course_management.what_did_you_like')}
              </Col>
              <Col className="input">
                <InputField control={control} name="users[0].userFeedbackLike" />
              </Col>
            </Row>
            <Row className="row" align="middle">
              <Col className="label">
                {t('trainningManagement:course_management.what_should_improve')}
              </Col>
              <Col className="input">
                <InputField control={control} name="users[0].userFeedbackImproved" />
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </StyledContainer>
  );
};

export default CourseEval;

export const customCommand = (editor, options) => {
  const { showModal } = options;
  const commands = editor?.Commands;
  commands?.add('canvas-clear', {
    run() {
      showModal();
    },
  });
};
export const addDevices = (editor) => {
  const commands = editor?.Commands;
  commands?.add('set-device-desktop', {
    run: (editor) => editor?.setDevice('Desktop'),
  });
  commands?.add('set-device-tablet', {
    run: (editor) => editor?.setDevice('Tablet'),
  });
  commands?.add('set-device-mobile', {
    run: (editor) => editor?.setDevice('Mobile'),
  });
};

import React, { useState, useRef } from 'react';
import { Modal } from 'antd';
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PersonnelInfomationItems from './components/PersonnelInfomationItems';
import EducationInformationItems from './components/EducationInformationItems';
import BankInformationItems from './components/BankInformationItems';
import RelativePeopleInformationItems from './components/RelativePeopleInformationItems';
import RelativeForm from './components/RelativeFormModal';
import { PersonnelInfomationHeader, PersonnelInfomationHeaderButton } from './styled';

const PersonalInformationFields = (props: any) => {
  const {
    isShowComponents,
    isMyProfile,
    isRequriedCheckSave,
    isRequriedCheckSendRequest,
    isRequriedCheckApprove,
    employeeData,
    setEmployeeData,
    status,
    formData,
  } = props;
  const { t } = useTranslation(['modal', 'employee']);
  const childRefRelativeForm = useRef<any>();
  const [relativeIndex, setRelativeIndex] = useState(null);

  const [isVisibleRelativeForm, setIsVisibleRelativeForm] = useState(false);

  const handleOpenRelativeForm = () => {
    setIsVisibleRelativeForm(true);
  };

  const handleCloseRelativeForm = () => {
    if (childRefRelativeForm.current) {
      childRefRelativeForm.current.resetStyleSelect();
      childRefRelativeForm.current.resetFieldsForm();
    }
    setIsVisibleRelativeForm(false);
    setRelativeIndex(null);
  };

  const handleRemoveRelative = (itemIndex: any) => {
    Modal.confirm({
      title: t('modal:confirm_delete', { field: 'relative' }),
      icon: <ExclamationCircleOutlined />,
      okText: t('modal:btn_ok'),
      okType: 'danger',
      cancelText: t('modal:btn_cancel'),
      onOk: () => {
        setEmployeeData({
          ...employeeData,
          relative: employeeData.relative.filter((_: any, index: any) => index !== itemIndex),
        });
      },
    });
  };

  const handleEditRelativeInfo = (index: any) => {
    setRelativeIndex(index);
    handleOpenRelativeForm();
  };

  const handleSaveRelative = (data: any, index = null) => {
    if (index !== null) {
      const newRelative = [...employeeData.relative];
      newRelative[index] = data;
      setEmployeeData({ ...employeeData, relative: newRelative });
    } else {
      const idxDuplicate = employeeData.relative.findIndex(
        (item: any) =>
          item.name === data.name &&
          item.yearOfBirth === data.yearOfBirth &&
          item.relationship.id.toString() === data.relationship.id.toString() &&
          item.email === data.email &&
          item.occupation === data.occupation &&
          item.phoneNumber === data.phoneNumber,
      );

      if (idxDuplicate >= 0) {
        Modal.error({
          title: t('modal:relative_duplicate'),
        });
        return;
      }
      if (employeeData.relative) {
        setEmployeeData({ ...employeeData, relative: [...employeeData.relative, data] });
      } else {
        setEmployeeData({ ...employeeData, relative: [data] });
      }
    }
  };

  return (
    <>
      <div style={isShowComponents ? {} : { display: 'none' }}>
        <PersonnelInfomationHeader>
          {t('employee:personnel_information_fields.personal_information')}
        </PersonnelInfomationHeader>
        <PersonnelInfomationItems
          isMyProfile={isMyProfile}
          isRequriedCheckSave={isRequriedCheckSave}
          isRequriedCheckSendRequest={isRequriedCheckSendRequest}
          isRequriedCheckApprove={isRequriedCheckApprove}
          formData={formData}
          status={status}
        />
        <PersonnelInfomationHeader>
          {t('employee:personnel_information_fields.education_information')}
        </PersonnelInfomationHeader>
        <EducationInformationItems status={status} />
        <PersonnelInfomationHeader>
          {t('employee:personnel_information_fields.bank_information')}
        </PersonnelInfomationHeader>
        <BankInformationItems status={status} />
        <PersonnelInfomationHeader>
          {t('employee:personnel_information_fields.relative_people_information')}
          <PersonnelInfomationHeaderButton
            disabled={status === 'deleted' ? true : false}
            icon={<PlusOutlined />}
            onClick={handleOpenRelativeForm}
          >
            {t('employee:personnel_information_fields.add_relative_people')}
          </PersonnelInfomationHeaderButton>
        </PersonnelInfomationHeader>
        {Array.isArray(employeeData.relative) && !!employeeData.relative.length && (
          <RelativePeopleInformationItems
            employeeData={employeeData}
            handleEditRelativeInfo={handleEditRelativeInfo}
            handleRemoveRelative={handleRemoveRelative}
          />
        )}
      </div>
      <div>
        <RelativeForm
          isVisible={isVisibleRelativeForm}
          dataRelative={relativeIndex !== null ? employeeData.relative[relativeIndex] : null}
          itemIndex={relativeIndex}
          handleSaveRelative={handleSaveRelative}
          handleCloseForm={handleCloseRelativeForm}
          ref={childRefRelativeForm}
        />
      </div>
    </>
  );
};

export default PersonalInformationFields;

/* eslint-disable array-callback-return */
import moment from 'moment-timezone';

import {
  LOCALE_FORMAT_DATE,
  LOCALE_FORMAT_DATETIME,
  LOCALE_FORMAT_MONTH,
  LOCALE_FORMAT_DATEMONTH,
} from 'constants/localeFormatDate';
import { store } from 'app/store';
import {
  DATE_FORMAT,
  DATETIME_FORMAT,
  DATEMONTH_FORMAT,
  DIGITS_NUMBER_FORMAT,
  MONTH_FORMAT,
} from 'constants/commons';
import { notificationToast } from 'components/notificationToast';
import { Notification } from 'constants/notification';

export const b64toBlob = (b64Data: string) => {
  const splitDataURI = b64Data.split(',');

  const byteString =
    splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);

  const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

  const ia = new Uint8Array(byteString.length);

  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

  return new Blob([ia], { type: mimeString });
};

export const filterImagesInStringElement = (str: string) => {
  const imgRegExp = /<img.*?src="(.*?)"[^>]+>/g;
  const images = [];
  let img;
  while ((img = imgRegExp.exec(str))) {
    images.push(img[1]);
  }
  return images;
};

export const getArrayByValue = (arr: any, valueArr: number[]) => {
  let result: any[] = [];

  arr.map((item: any) => {
    valueArr.map((ele: any) => {
      if (item.id === ele) {
        result.push(item);
      }
    });
  });
  return result;
};

export const checkPermission = (
  userPermissions: any,
  requiredPermission: any,
  isSystemAdmin: boolean = false,
): any => {
  try {
    if (isSystemAdmin) {
      return true;
    }
    if (!requiredPermission || requiredPermission?.length < 1) {
      return true;
    }
    return !!userPermissions.find((p: any) => requiredPermission.indexOf(p) >= 0);
  } catch (error) {
    return false;
  }
};

export const checkExtraPermission = (
  userPermissions: any,
  requiredPermission: any,
  isSystemAdmin: boolean = false,
): any => {
  try {
    if (isSystemAdmin) {
      return true;
    }
    return !!userPermissions.find((p: any) => requiredPermission.indexOf(p) >= 0);
  } catch (error) {
    return false;
  }
};

export const generateColorTag = (indexItem: number) => {
  let lastDigit = indexItem % 10;
  let color = 'purple';
  switch (lastDigit) {
    case 0:
      color = 'magenta';
      break;
    case 1:
      color = 'red';
      break;
    case 2:
      color = 'volcano';
      break;
    case 3:
      color = 'orange';
      break;
    case 4:
      color = 'gold';
      break;
    case 5:
      color = 'lime';
      break;
    case 6:
      color = 'green';
      break;
    case 7:
      color = 'cyan';
      break;
    case 8:
      color = 'blue';
      break;
    case 9:
      color = 'geekblue';
      break;
    default:
      color = 'purple';
      break;
  }
  return color;
};

export const getLocaleDateString = () => {
  const locale = navigator.language?.toString().toLowerCase();
  // @ts-ignore
  return LOCALE_FORMAT_DATE[locale] || DATE_FORMAT;
};
export const getDotColor = (colorName: string) => {
  const color: any = store.getState().configuration.dataColor || [];
  return color.filter((item: any) => item.name === colorName)[0]?.code || colorName;
};

export const getLocaleDateTimeString = () => {
  const locale = navigator.language?.toString().toLowerCase();
  // @ts-ignore
  return LOCALE_FORMAT_DATETIME[locale] || DATETIME_FORMAT;
};

export const getLocaleMonthString = () => {
  const locale = navigator.language?.toString().toLowerCase();
  // @ts-ignore
  return LOCALE_FORMAT_MONTH[locale] || MONTH_FORMAT;
};

export const getLocaleDateMonthString = () => {
  const locale = navigator.language?.toString().toLowerCase();
  // @ts-ignore
  return LOCALE_FORMAT_DATEMONTH[locale] || DATEMONTH_FORMAT;
};

export const showError = (message: string) => {
  // notification.error({
  //   placement: 'topRight',
  //   duration: 5,
  //   message: message,
  // });
  notificationToast(Notification.Type.Error, message, Notification.Duration._5s);
};

export const showDay = (number: any) => {
  if (typeof number === 'string') {
    number = parseInt(number);
  }
  if (typeof number === 'number') {
    return number > 0 ? number + ' days' : number + ' day';
  }
  return '';
};

export const formatNumber = (data: any, digits: number | undefined = undefined) => {
  if (!data) {
    return 0;
  }
  if (data % 1 === 0) {
    return data;
  }
  if (digits) {
    return parseFloat(data).toFixed(digits);
  } else {
    return parseFloat(data).toFixed(DIGITS_NUMBER_FORMAT);
  }
};

export const getTimeZone = () => {
  const zone: any[] = [];
  moment.tz.names().map((timezone: any) => {
    const utc = moment['tz'](timezone).utcOffset() / 60;
    const sign = utc < 0 ? '' : '+';
    const utcString = `(UTC${sign}${utc})`;
    const item = {
      id: timezone,
      name: `${utcString} ${timezone}`,
      utc: utc,
    };
    zone.push(item);
  });
  const zoneSort = zone.sort((a: any, b) => a.utc - b.utc);
  return zoneSort;
};

export const checkIsPreviousMonth = (
  monthNow: number,
  yearNow: number,
  monthCalendar: number,
  yearCalendar: number,
): boolean => {
  if (yearCalendar < yearNow) {
    return true;
  } else if (yearCalendar === yearNow) {
    return monthCalendar <= monthNow ? true : false;
  } else {
    return false;
  }
};

export function checkEveryChildPropertiesHasKeys(
  node: any,
  keys: string[],
  property: string,
): boolean {
  if (node.children && Number(node.id) > 0) {
    let result = true;

    for (let i = 0; i < node.children.length; i++) {
      result = checkEveryChildPropertiesHasKeys(node.children[i], keys, property);
    }

    if (keys.includes(node[property]) && result) return true;

    return false;
  }

  if (Number(node.id) > 0 && !keys.includes(node[property])) return false;

  return true;
}
export const formatZeroNumber = (myNumber: any, digits: number | undefined = undefined) => {
  const formattedNumber = myNumber.toLocaleString('en-US', {
    minimumIntegerDigits: digits || 0,
    useGrouping: false,
  });
  return formattedNumber;
};
export const checkLengthUserName = (userName: any) => {
  if (userName?.length > 17) {
    return userName.slice(0, 15) + '...';
  } else {
    return userName;
  }
};
export const checkLengthEmail = (email: any) => {
  if (email?.length > 18) {
    return email.slice(0, 18) + '...';
  } else {
    return email;
  }
};

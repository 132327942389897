import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Button, Checkbox, Select, AutoComplete } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';

export const Container = styled.div`
  padding: 1rem;
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--cl_primary);
    border-color: var(--cl_primary);
  }
  .ant-checkbox-inner {
    border-color: var(--cl_primary);
  }
  width: 250px;
  text-transform: capitalize !important;
`;

export const CheckboxGroup = styled.div`
  height: 220px;
  overflow-y: auto;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const { Option } = Select;

const SearchPopup = React.forwardRef((props: any, ref: any) => {
  const {
    selectedKeys,
    options,
    onClickFilter,
    setSelectedKeys,
    dataIndex,
    confirm,
    filteredInfo,
  } = props;

  const oldest = useRef(selectedKeys);
  const [checkAll, setCheckAll] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [textSearch, setTextSearch] = useState('');

  useImperativeHandle(ref, () => ({
    getSearchFilterOption: () => searchFilterOption,
    effectWhenOpenOrHideFilterDropdown: (e: any) => {
      if (!e) {
        setSelectedKeys(oldest.current);
      }

      const isCheckAllInFilter = oldest.current?.length === options?.length;
      const isCheckIndeterminate = oldest.current?.length > 0 && !isCheckAllInFilter;
      setCheckAll(isCheckAllInFilter);
      setIndeterminate(isCheckIndeterminate);
    },
  }));

  let newOptions: { label: string; value: string }[] = options?.reduce(
    (previousValue: any, currentValue: any) => [
      ...previousValue,
      { label: currentValue || 'Blank', value: currentValue },
    ],
    [],
  );
  const [searchFilterOption, setSearchFilterOption]: any = useState(newOptions);

  useEffect(() => {
    let newOptions: { label: string; value: string }[] = options?.reduce(
      (previousValue: any, currentValue: any) => [
        ...previousValue,
        { label: currentValue || 'Blank', value: currentValue },
      ],
      [],
    );
    setSearchFilterOption(newOptions);
  }, [options]);

  useEffect(() => {
    setIndeterminate(!!selectedKeys?.length && selectedKeys?.length < options?.length);
    const newSearchArr = newOptions.filter((item: any) => !selectedKeys?.includes(item));
    if (textSearch) {
      const searchArr = newOptions.filter(
        (item: any) => item.label.toLowerCase().indexOf(textSearch.toLowerCase()) === 0,
      );
      setSearchFilterOption(searchArr);
    } else setSearchFilterOption(newSearchArr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredInfo, selectedKeys, textSearch]);

  useEffect(() => {
    if (isEmpty(filteredInfo)) {
      onClear();
    }
  }, [filteredInfo]);

  const onCheckboxChange = (checkedValues: any) => {
    if (searchFilterOption.length !== options.length) {
      const onSearchOptions = searchFilterOption.map((item: any) => item.value);
      const values = [...selectedKeys, ...checkedValues];
      const valuesArr = removeDuplicates(values);
      if (checkedValues.length === 0) {
        //handle deselect when search filter
        const indexToRemove = valuesArr.findIndex((item: any) => onSearchOptions.includes(item));
        valuesArr.splice(indexToRemove, 1);
        setSelectedKeys(valuesArr);
      }
      if (checkedValues.length > 0) {
        // eslint-disable-next-line array-callback-return
        const unselectOptArr = onSearchOptions.map((item: any) => {
          if (!checkedValues.find((value: any) => value === item)) {
            return item;
          }
        });
        for (let i = 0; i < unselectOptArr.length; i++) {
          if (valuesArr.some((value: any) => value === unselectOptArr[i])) {
            let indexRemove = valuesArr.findIndex((x: any) => x === unselectOptArr[i]);
            valuesArr.splice(indexRemove, 1);
          }
        }
        setSelectedKeys(valuesArr);
      }
    } else {
      setSelectedKeys(checkedValues);
    }
  };

  const removeDuplicates = (arr: Array<string>) => {
    let s = new Set(arr);
    let it = s.values();
    return Array.from(it);
  };

  const children = [];
  for (let i = 0; i < options?.length; i++) {
    children.push(<Option key={options[i]}>{options[i]}</Option>);
  }

  const selectedOption = selectedKeys ? selectedKeys : [];

  const handleSearch = (searchKey: any) => {
    if (searchKey) {
      const searchArr = newOptions.filter(
        (item: any) => item.label.toLowerCase().indexOf(searchKey.toLowerCase()) === 0,
      );
      setSearchFilterOption(searchArr);
      setTextSearch(searchKey);
    } else {
      setSearchFilterOption(newOptions);
      setTextSearch('');
    }
  };
  const { t } = useTranslation(['insurance', 'action']);

  const onCheckAllChange = (e: any) => {
    setSelectedKeys(e.target.checked ? newOptions.map((x: any) => x.value) : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const clickFilterExternal = (e: any) => {
    oldest.current = selectedKeys;
    onClickFilter && onClickFilter(selectedKeys, dataIndex, e);
    confirm(); //hide filter dropdown
  };

  const onClear = () => {
    setCheckAll(false);
    setIndeterminate(false);
  };

  return (
    <Container id={dataIndex}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <AutoComplete
          style={{ width: 'inherit', marginBottom: 8 }}
          dropdownRender={undefined}
          placeholder={t('Search')}
          onSearch={handleSearch}
          value={textSearch}
        />
        <Checkbox onChange={onCheckAllChange} indeterminate={indeterminate} checked={checkAll}>
          {t('action:select_all')}
        </Checkbox>
      </div>
      <CheckboxGroup>
        <Checkbox.Group
          style={{ display: 'flex', flexDirection: 'column' }}
          options={searchFilterOption}
          value={selectedOption}
          onChange={onCheckboxChange}
        />
      </CheckboxGroup>
      <ButtonContainer>
        <Button style={{ marginTop: '10px' }} onClick={clickFilterExternal}>
          {t('action:filter')}
        </Button>
      </ButtonContainer>
    </Container>
  );
});

export default SearchPopup;

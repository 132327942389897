import moment from 'moment';

export const ROOT_API = process.env.REACT_APP_BASE_URL;
export const DEFAULT_PAGE = 1;
export const DEFAULT_LIMIT = 20;
export const KEY_RECAPTCHA = '6LdkKw8dAAAAABF8L3gGW4KhdZvz85CKHw97_9Sh';
export const DOMAIN_MAIN = '.kingwork.vn';
export const TIMEZONE_DEFAULT = 'Asia/Ho_Chi_Minh';

export const ON_BOARD_STATUS_YES = 200;
export const ON_BOARD_STATUS_NO = 201;

export const APP_TYPE = 'web_admin';
export const LOCAL_STORAGE_PREFIX = 'hrm';
export const DEVICE_TOKEN_KEY = 'device_token';

export const DIGITS_NUMBER_FORMAT = 2;
export const MINUTE_FORMAT = 'mm:ss';
export const HOUR_FORMAT = 'HH:mm';
export const MONTH_FORMAT = 'MM/YYYY';
export const MONTH_YEAR_FORMAT = 'YYYY-MM';
export const YEAR_MONTH = 'YYYY/MM';
export const DATE_FORMAT = 'MM/DD/YYYY';
export const YYYY_MM_DD = 'YYYY-MM-DD';
export const DATETIME_FORMAT = 'MM/DD/YYYY - HH:mm';
export const DATEMONTH_FORMAT = 'MM/DD';
export const DATE_EXPORT_FORMAT = 'YYYY-MM-DD_HH-mm';
export const DATE_PAYLOAD_FORMAT = 'YYYY-MM-DD';
export const DATE_REPORT_FORMAT = 'YYYY-MM-DD_HHmmSS';

export const DATE_DEFAULT = '01/01/1900';
export const DATETIME_DEFAULT = '01/01/1900 - 00: 00';

export const COLOR_AVATAR_USER = ['#e8430f', '#374151', '#1d4ed8', '#047857', '#b45309'];

export const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const MONTH_NAMES_VN = [
  'Tháng 01',
  'Tháng 02',
  'Tháng 03',
  'Tháng 04',
  'Tháng 05',
  'Tháng 06',
  'Tháng 07',
  'Tháng 08',
  'Tháng 09',
  'Tháng 10',
  'Tháng 11',
  'Tháng 12',
];

export const MONTH_NUMBERS = [
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
];

export const dayInWeek = (lan: string, daysSelected?: Array<number>) => {
  if (lan === 'en') {
    const arrDayInWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    return daysSelected
      ? arrDayInWeek.filter((_, index) => daysSelected.includes(index))
      : arrDayInWeek;
  } else if (lan === 'vi') {
    //return ['Chủ nhật', 'Thứ hai', 'Thứ ba', 'Thứ tư', 'Thứ năm', 'Thứ sáu', 'Thứ bảy'];
    const arrDayInWeek = ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7'];
    return daysSelected
      ? arrDayInWeek.filter((_, index) => daysSelected.includes(index))
      : arrDayInWeek;
  } else {
    const arrDayInWeek =
      lan === 'ko'
        ? ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일']
        : ['日曜日', '月曜日', ' 火曜日', '水曜日', '木曜日', '金曜日', '土曜日'];
    return daysSelected
      ? arrDayInWeek.filter((_, index) => daysSelected.includes(index))
      : arrDayInWeek;
  }
};

export const dayInWeekSmall = (lan: string) => {
  if (lan === 'en') {
    return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  } else if (lan === 'vi') {
    return ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7'];
  } else {
    return lan === 'ko'
      ? ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일']
      : ['日曜日', '月曜日', ' 火曜日', '水曜日', '木曜日', '金曜日', '土曜日'];
  }
};

export const listMonth = (month: number, type: string) => {
  const MONTH_NAMES_ENG = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const MONTH_NAMES_KOREA = [
    '일월',
    '이월',
    '삼월',
    '사월',
    '오월',
    '유월',
    '칠월',
    '팔월',
    '구월',
    '시월',
    '십일월',
    '십이월',
  ];

  const MONTH_NAMES_JAPAN = [
    '一月',
    '二月',
    '三月',
    '四月',
    '五月',
    '六月',
    '七月',
    '八月',
    '九月',
    '十月',
    '	十一月',
    '十二月',
  ];

  const MONTH_NAMES_VN = [
    'Tháng 01',
    'Tháng 02',
    'Tháng 03',
    'Tháng 04',
    'Tháng 05',
    'Tháng 06',
    'Tháng 07',
    'Tháng 08',
    'Tháng 09',
    'Tháng 10',
    'Tháng 11',
    'Tháng 12',
  ];
  if (type === 'en') {
    return MONTH_NAMES_ENG[month];
  } else if (type === 'vi') {
    return MONTH_NAMES_VN[month];
  } else if (type === 'ko') {
    return MONTH_NAMES_KOREA[month];
  } else if (type === 'ja') {
    return MONTH_NAMES_JAPAN[month];
  }
};

export const DAY_NUMBERS = new Array(31).fill(0).map((_, i) => ({
  id: i + 1,
  value: (i + 1).toString(),
}));

export const getListYear = (length: number = 10) => {
  const currentYear = +moment().year();
  let arrYear: any = [];
  Array.from({ length }, (_, i) => (arrYear = [...arrYear, currentYear - i]));
  return arrYear;
};

export const CURRENCY_FORMAT = '0,0';
export const CURRENCY_FORMAT_DECIMAL = '0,0.00';

export const EMPTY_TIME_RANGE = '00:00 - 00:00';

export const HOUR_MINUTE_SECOND_FORMAT = 'HH:mm:ss';

export const getTimeNotGMT = (currentDay: any, time: any) => {
  return (
    moment(currentDay).format(DATE_PAYLOAD_FORMAT) +
    'T' +
    moment(time).format(HOUR_MINUTE_SECOND_FORMAT)
  );
};

export const LIST_DEPENDENTS = [
  {
    label: 'Cha/mẹ',
    value: 'D_PA',
  },
  {
    label: 'Con',
    value: 'D_CH',
  },
  {
    label: 'Vợ/Chồng',
    value: 'D_SP',
  },
  {
    label: 'Người thân khác',
    value: 'D_O',
  },
];

export const STATUS_PERFORMANCE = {
  WAITTNG_TO_ACCEPT: 'Waiting to Accept',
  ACCEPT: 'Accept',
  NEED_DISCUSSION: 'Need disscussion',
};

export function generateRandomColor() {
  return '#' + (0x1000000 + Math.random() * 0xffffff).toString(16).substr(1, 6);
}

import { DeleteTwoTone, EditTwoTone, EyeTwoTone, SyncOutlined } from '@ant-design/icons';
import { Menu, Popconfirm } from 'antd';
import { notificationToast } from 'components/notificationToast';
import { Notification } from 'constants/notification';
import { PermissionType } from 'hrm-common/extensions/enums/personel';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { getUser } from 'utils/auth';
import { labelInsurancesMenuActions, labelKeyNameView, labelKeyStatus } from '../constants/common';
import { buttonInsurances } from '../constants/label';
import { deleteSchedulePremium, postApproved } from '../insuranceAction';
import ModalEdit from './ModalEdit';
import ModalReject from './ModalReject';
import ModalView from './ModalView';

const MenuActionsInsurance = (props: any) => {
  const { isShowFullMenu, item, permissions, handleHideMenuActions, checkview } = props;
  const dispatch = useDispatch();
  const { t }: any = useTranslation(['insurance', 'timesheet']);
  const history = useHistory();
  const [isVisibleInsuranceView, setIsVisibleInsuranceView] = useState(false);
  const [isVisibleInsuranceEdit, setIsVisibleInsuranceEdit] = useState(false);
  const [isVisibleInsuranceReject, setIsVisibleInsuranceReject] = useState(false);
  const [mode, setMode] = useState('reject');

  const handleOpenInsuranceView = () => {
    setIsVisibleInsuranceView(true);
  };
  const handleOpenInsuranceEdit = () => {
    setIsVisibleInsuranceEdit(true);
  };

  const handleCloseInsuranceView = () => {
    setIsVisibleInsuranceView(false);
  };
  const handleCloseInsuranceEdit = () => {
    setIsVisibleInsuranceEdit(false);
  };

  const handleViewProfile = () => {
    handleHideMenuActions();
    handleOpenInsuranceView();
  };
  const handleEditProfile = () => {
    handleHideMenuActions();
    handleOpenInsuranceEdit();
  };
  const setClickDelete = () => {
    dispatch(
      deleteSchedulePremium({
        listId: [item?.id],
        callBack: (response: any) => {
          if (!response.error) {
            props.handleHideMenuActions && props.handleHideMenuActions();
            props.reSetData && props.reSetData();
          }
        },
      }),
    );
  };

  const setClickApprove = () => {
    dispatch(
      postApproved({
        status: 'approved',
        approvedBy: getUser()?.fullName,
        reason: '',
        select: {
          key: 'id',
          value: [item?.id],
        },
        callBack: (response: any) => {
          if (!response.error) {
            notificationToast(
              Notification.Type.Success,
              t('approved_ansurance_success'),
              Notification.Duration._4s,
            );
            props.reSetData && props.reSetData();
            props.handleHideMenuActions && props.handleHideMenuActions();
            handleCloseInsuranceView();
          }
        },
      }),
    );
  };
  const handleOpenInsuranceClose = (mode: string) => {
    setMode(mode);
    handleHideMenuActions();
    setIsVisibleInsuranceReject(true);
  };
  const handleRejectInsurance = () => {
    setIsVisibleInsuranceReject(false);
  };

  const onClickHistoryParticipantList = () => {
    history.push('/insurance/complusory-insurance/participant-detail', {
      params: item,
      checkview: labelKeyNameView.HISTORY_PAYMENT,
    });
  };

  const showModelEdit = () => {
    if (permissions?.includes(PermissionType.SCHEDULE_INCREASE_DECREASE_EDIT)) {
      return (
        <Menu.Item
          key={buttonInsurances.EDIT}
          onClick={handleEditProfile}
          icon={<EditTwoTone twoToneColor="#F1592A" />}
        >
          {t('edit')}
        </Menu.Item>
      );
    } else {
      return null;
    }
  };

  const showModelApprove = () => {
    if (permissions?.includes(PermissionType.INSURANCE_APPROVAL_APPROVE_REJECT_REQUEST)) {
      return (
        <Popconfirm
          title={t('Are_you_approve_request')}
          onConfirm={() => {
            setClickApprove();
          }}
          // onCancel={cancel}
          okText={t('yes')}
          cancelText={t('no')}
        >
          <Menu.Item key={buttonInsurances.APPROVE} icon={<EyeTwoTone />}>
            {t('approve')}
          </Menu.Item>
        </Popconfirm>
      );
    } else {
      return null;
    }
  };

  const showModelDelete = () => {
    if (permissions?.includes(PermissionType.SCHEDULE_INCREASE_DECREASE_DELETE)) {
      return (
        <Popconfirm
          title={t('Are_you_delete_request')}
          onConfirm={() => {
            setClickDelete();
          }}
          okText={t('yes')}
          cancelText={t('no')}
        >
          <Menu.Item key={buttonInsurances.DELETE} icon={<DeleteTwoTone twoToneColor="#B7B7B7" />}>
            {t('delete')}
          </Menu.Item>
        </Popconfirm>
      );
    } else {
      return null;
    }
  };

  const showModalReset = () => {
    if (permissions?.includes(PermissionType.INSURANCE_APPROVAL_APPROVE_REJECT_REQUEST)) {
      return (
        <Menu.Item
          key={buttonInsurances.RESET}
          icon={<SyncOutlined twoToneColor="#B7B7B7" />}
          onClick={() => handleOpenInsuranceClose('reset')}
        >
          {t('timesheet:Reset')}
        </Menu.Item>
      );
    } else {
      return null;
    }
  };

  const showModelReject = () => {
    if (permissions?.includes(PermissionType.INSURANCE_APPROVAL_APPROVE_REJECT_REQUEST)) {
      return (
        <Menu.Item
          key={buttonInsurances.REJECT}
          onClick={() => handleOpenInsuranceClose('reject')}
          icon={<EyeTwoTone />}
        >
          {t('reject')}
        </Menu.Item>
      );
    } else {
      return null;
    }
  };

  const showModelViewParticipantList = () => {
    return (
      <Menu.Item
        key={buttonInsurances.VIEW_INFORMATION}
        onClick={onClickHistoryParticipantList}
        icon={<EyeTwoTone />}
      >
        {t('View_information')}
      </Menu.Item>
    );
  };

  const showModelViewInformation = () => {
    return (
      <Menu.Item
        key={buttonInsurances.VIEW_INFORMATION}
        onClick={handleViewProfile}
        icon={<EyeTwoTone />}
      >
        {t('View_information')}
      </Menu.Item>
    );
  };

  const checkPermissionsView = () => {
    if (
      checkview === labelKeyNameView.DROPDOWN_DECREASE &&
      item?.status === labelInsurancesMenuActions.DRAFT
    ) {
      return (
        <>
          {showModelViewInformation()}
          {showModelDelete()}
          {showModelEdit()}
        </>
      );
    }
    if (
      checkview === labelKeyNameView.DROPDOWN_DECREASE &&
      item?.status === labelKeyStatus.KEY_WAITING_FOR_APPROVAL
    ) {
      return <>{showModelViewInformation()}</>;
    }
    if (
      checkview === labelKeyNameView.DROPDOWN_DECREASE &&
      item?.status === labelKeyStatus.KEY_REJECTED
    ) {
      return (
        <>
          {showModelViewInformation()}
          {showModelDelete()}
          {showModelEdit()}
        </>
      );
    }
    if (
      checkview === labelKeyNameView.DROPDOWN_APPROVAL &&
      item?.status === labelKeyStatus.KEY_WAITING_FOR_APPROVAL
    ) {
      return (
        <>
          {showModelViewInformation()}
          {showModelApprove()}
          {showModelReject()}
        </>
      );
    }
    if (
      checkview === labelKeyNameView.DROPDOWN_DECREASE &&
      item?.status === labelKeyStatus.KEY_APPROVED
    ) {
      return (
        <>
          {showModelViewInformation()}
          {showModalReset()}
        </>
      );
    }
    if (
      checkview === labelKeyNameView.DROPDOWN_APPROVAL &&
      item?.status === labelInsurancesMenuActions.APPROVED
    ) {
      return <>{showModelViewInformation()}</>;
    }
    if (
      checkview === labelKeyNameView.DROPDOWN_APPROVAL &&
      item?.status === labelInsurancesMenuActions.REJECTED
    ) {
      return <>{showModelViewInformation()}</>;
    }
    if (checkview === labelKeyNameView.DROPDOWN_PARTICIPANT_LIST) {
      return <>{showModelViewParticipantList()}</>;
    }
  };

  const ViewMenu = () => {
    return <Menu>{checkPermissionsView()}</Menu>;
  };
  return (
    <>
      {ViewMenu()}
      <ModalView
        isVisible={isVisibleInsuranceView}
        isShowFullMenu={isShowFullMenu}
        itemId={item}
        // @ts-ignore
        checkview={props.checkview}
        reSetData={props.reSetData}
        onClose={handleCloseInsuranceView}
      />
      <ModalEdit
        isVisible={isVisibleInsuranceEdit}
        isShowFullMenu={isShowFullMenu}
        itemId={item}
        checkview={props.checkview}
        reSetData={props.reSetData}
        onClose={handleCloseInsuranceEdit}
      />
      <ModalReject
        isVisible={isVisibleInsuranceReject}
        itemId={item}
        reSetData={props.reSetData}
        isShowFullMenu={isShowFullMenu}
        onClose={handleRejectInsurance}
        mode={mode}
      />
    </>
  );
};

export default MenuActionsInsurance;

export const IconImage = styled.img`
  padding-bottom: 6px;
  width: 16px;
  height: 16px;
`;

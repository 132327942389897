import { Tabs } from 'antd';
import ITVTable from 'components/ITVTable';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Container } from './styled';
import PageHeader from 'components/PageHeader';
import { useAppSelector } from 'app/hooks';
import { selectConfiguration } from 'features/configuration/configurationSlice';
import MenuAction from 'components/MenuAction';
import CustomButton from 'styles/buttonStyled';
import CourseInformation from './components/CourseInformation';
import { useForm } from 'react-hook-form';
import CourseContent from './components/CourseContent';
import CourseEval from './components/CourseEval';
import { getTrainingDetail, postFeedBack } from 'features/training/trainingAction';
import { selectTrainingData } from 'features/training/trainingSlice';
import ButtonBack from 'components/ButtonBack';
import { useTranslation } from 'react-i18next';

const { TabPane } = Tabs;

const CourseDetail = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { id }: any = useParams();
  const trainData = useAppSelector(selectTrainingData);
  const trainingDetail = trainData?.trainingDetail;
  const { t } = useTranslation(['action']);

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    resolver: undefined,
    defaultValues: useMemo(() => {
      return trainingDetail;
    }, [trainingDetail]),
  });

  useEffect(() => {
    if (trainingDetail) reset(trainingDetail);
  }, [trainingDetail]);

  useEffect(() => {
    if (id) {
      dispatch(getTrainingDetail({ id, query: { isEmployee: true } }));
    }
  }, [id]);

  const onSubmit = (data: any) => {
    const body = {
      courseId: data?.users?.[0]?.courseId,
      userFeedback: data?.users?.[0]?.userFeedback,
      userFeedbackLike: data?.users?.[0]?.userFeedbackImproved,
      userFeedbackImproved: data?.users?.[0].userFeedbackLike,
    };
    dispatch(postFeedBack({ body }));
  };

  return (
    <Container>
      <ButtonBack handleBack={() => history.replace('/my-page/my-training')} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <CourseInformation getValues={getValues} />
        <CourseContent getValues={getValues} control={control} callBack={() => {}} />
        <CourseEval getValues={getValues} control={control} />
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', gap: 8 }}>
            <CustomButton aType="outline" onClick={() => history.replace('/my-page/my-training')}>
              {t('action:cancel')}
            </CustomButton>
            <CustomButton htmlType="submit">{t('action:save')}</CustomButton>
          </div>
        </div>
      </form>
    </Container>
  );
};

export default CourseDetail;

import styled from 'styled-components';
import { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';

import PayrollSummaryLocationDepartmentFilters from './PayrollSummaryLocationDepartmentFilters';
import PayrollSummaryLocationDepartmentChart from './PayrollSummaryLocationDepartmentChart';
import { DiligencePayload, DiligenceReport, IDiligenceFilters } from 'types';
import { DATE_PAYLOAD_FORMAT } from 'constants/commons';
import dashboardApi from 'api/dashboardApi';
import { path } from 'constants/path';
import { SelectType } from 'constants/timesheet';
import payrollDashboardApi from 'api/payrollDashboardApi';

const StyledContainer = styled.div`
  padding: 16px 24px;
  border: 0.5px solid var(--cl_gray200);
  border-radius: 12px;
  background-color: var(--cl_white);
  height: 100%;
`;

const StyledHeaderTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto;
  line-height: 20px;
  color: #121926;
`;

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const chartData = {
  labels: [],
  datasets: [
    {
      label: 'Overtime',
      data: [],
      backgroundColor: '#9e480e',
    },
    {
      label: 'Insurance',
      data: [],
      backgroundColor: '#70ad47',
    },
    {
      label: 'PIT',
      data: [],
      backgroundColor: '#ffc000',
    },
    {
      label: 'Gross Salary',
      data: [],
      backgroundColor: '#ed7d31',
    },
  ],
};

export function PayrollSummaryLocationDepartment({ location, department }) {
  const { t } = useTranslation(['dashboard']);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [dataChart, setDataChart] = useState<DiligenceReport>(chartData);

  const [filterMonthYear, setFilterMonthYear] = useState({
    monthYear: moment(new Date()).clone().subtract(1, 'months').format('YYYY-MM'),
  });

  const [filterLocation, setFilterLocation] = useState(
    location && location[location?.length - 1]?.id,
  );
  const [filterDepartment, setFilterDepartment] = useState(
    department && department[department?.length - 1]?.projectCode,
  );

  useEffect(() => {
    const fetchDataPayrollDepartment = async () => {
      setIsLoading(true);
      const params = {
        period: filterMonthYear.monthYear,
        locationId: filterLocation,
        projectPrimary: filterDepartment,
      };
      const response = await payrollDashboardApi.getPayrollLine(params);

      const labelDataCharts: any = [];

      const dataOvertime: any = [];
      const dataInsurance: any = [];
      const dataTax: any = [];
      const dataTotalGrossSalary: any = [];

      response?.data?.result?.forEach((item) => {
        labelDataCharts.push(item?.projectPrimaryName);
        dataOvertime.push(item?.overTime);
        dataInsurance.push(item?.insurance);
        dataTax.push(item?.tax);
        dataTotalGrossSalary.push(item?.totalGrossSalary);
      });

      setDataChart({
        labels: labelDataCharts,
        datasets: [
          {
            ...chartData.datasets[0],
            data: dataOvertime,
          },
          {
            ...chartData.datasets[1],
            data: dataInsurance,
          },
          {
            ...chartData.datasets[2],
            data: dataTax,
          },
          {
            ...chartData.datasets[3],
            data: dataTotalGrossSalary,
          },
        ],
      });
      setIsLoading(false);
    };

    fetchDataPayrollDepartment().catch((err) => {
      setIsLoading(false);
    });
  }, [filterMonthYear, filterLocation, filterDepartment]);

  const handleSubmit = (values: any) => {
    if (values?.from === 'monthYear') {
      const formattedMonthYear = moment(values.monthYear).format('YYYY-MM');
      setFilterMonthYear({
        monthYear: formattedMonthYear,
      });
    }
    if (values.viewByLocation !== filterLocation) {
      setFilterLocation(values?.viewByLocation);
    }
    if (values.viewByDepartment !== filterDepartment) {
      setFilterDepartment(values?.viewByDepartment);
    }
  };

  return (
    <StyledContainer>
      <StyledWrapper>
        <StyledHeaderTitle>Payroll Summary by Location & Line</StyledHeaderTitle>

        <PayrollSummaryLocationDepartmentFilters
          initialValues={{
            monthYear: moment(new Date(filterMonthYear.monthYear)),
            viewByLocation: filterLocation,
            viewByDepartment: filterDepartment,
          }}
          loading={isLoading}
          onSubmit={handleSubmit}
          location={location}
          department={department}
        />
      </StyledWrapper>

      <PayrollSummaryLocationDepartmentChart loading={isLoading} dataChart={dataChart} />
    </StyledContainer>
  );
}

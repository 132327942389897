import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import dashboardEmployeeApi from 'api/dashboardEmployeeApi';
import { MONTH_FORMAT, MONTH_YEAR_FORMAT } from 'constants/commons';
import { TimesheetAdjustmentReport } from 'types';
import { exportToCSV } from 'utils/common';
import HeadcountContractTypeChart from './HeadcountContractTypeChart';
import HeadcountContractTypeFilters, {
  HeadcountContractTypePayload,
} from './HeadcountContractTypeFilters';

const StyledContainer = styled.div`
  padding: 16px 24px;
  border: 0.5px solid var(--cl_gray200);
  border-radius: 12px;
  background-color: var(--cl_white);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledHeaderTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto;
  line-height: 20px;
  color: #121926;
`;

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  flex-wrap: wrap;
  gap: 10px;
`;

export function HeadcountByContractType() {
  const { t } = useTranslation(['dashboard']);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const [dataChart, setDataChart] = useState<TimesheetAdjustmentReport>({
    labels: [],
    datasets: [],
  });

  const [filters, setFilters] = useState<{ monthYear: string; locationId: string }>({
    monthYear: moment(new Date()).format(MONTH_YEAR_FORMAT),
    locationId: '0',
  });

  useEffect(() => {
    async function fetchHeadcountByContractType() {
      try {
        setIsLoading(true);
        const response = await dashboardEmployeeApi.getHeadcountContractType(filters);

        const labels = response.data.labels;
        const datasets = [
          {
            label: '',
            data: response.data.datasets.map((x: any) => x),
            backgroundColor: [
              '#4472C4',
              '#F37725',
              '#A5A5A5',
              '#FFC000',
              '#5B9BD5',
              '#70AD47',
              '#9E480E',
            ],
            borderColor: 'white',
          },
        ];

        setIsLoading(false);
        setDataChart((prevState) => ({
          ...prevState,
          labels,
          datasets,
        }));
      } catch (error) {
        setIsLoading(false);
      }
    }

    if (filters?.locationId) {
      fetchHeadcountByContractType();
    }
  }, [filters, filters?.locationId]);

  const handleSubmit = ({ monthYear, ...payload }: Partial<HeadcountContractTypePayload>) => {
    const formattedYear = moment(monthYear).format(MONTH_YEAR_FORMAT);

    setFilters((prevFilters) => ({
      ...prevFilters,
      ...payload,
      monthYear: formattedYear,
    }));
  };

  const handleExport = async (location: { value: string; label: string }) => {
    try {
      setIsLoadingExport(true);
      const response = await dashboardEmployeeApi.exportHeadcountContractType(filters);
      setIsLoadingExport(false);

      const exportFileName = `Headcount_By_Contract_Type_${moment(filters.monthYear).format(
        MONTH_FORMAT,
      )}_${location.label}.xlsx`;
      exportToCSV(response, exportFileName);
    } catch (error) {
      setIsLoadingExport(false);
    }
  };

  return (
    <StyledContainer>
      <StyledWrapper>
        <StyledHeaderTitle>{t('dashboard_employee.headcount_contract')}</StyledHeaderTitle>

        <HeadcountContractTypeFilters
          onSubmit={handleSubmit}
          loadingExport={isLoadingExport}
          handleExport={handleExport}
          initialValues={{
            monthYear: moment(new Date(filters.monthYear)),
          }}
        />
      </StyledWrapper>

      <HeadcountContractTypeChart loading={isLoading} dataChart={dataChart} />
    </StyledContainer>
  );
}

import { IEmployeeData } from './interfaces/watchingListInterfaces';
import { findParentNode } from './utils/helperWatchingList';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { IWatchingList, IWatchingListData, IEmployeeDataSlice } from './interfaces';
import {
  getEmployeeInlineManager,
  getEmployeeWatchingList,
  searchListRequest,
  cancelRequestManager,
  rejectRequestManager,
  approveRequestManager,
} from './watchingListAction';
import { statusWatchingList, typeFilterWatchingList } from './interfaces/watchingListEnums';
import { TTypeSelect } from 'components/interfaces/ITVTableInterfaces';

const initialState: IWatchingList = {
  watchingListData: [],
  filterWatchingList: {
    watchingListData: {
      dataFiltered: [],
      totalPage: 0,
      totalResult: 0,
      status: 'idle',
    },
  },
  isGettingWatchingList: false,
  total: 0,
  totalResult: 0,
  listRequestEmployee: [],
  isRequestLoading: false,
  error: null,
  selectedWatchingListData: { selectedDataList: [], isSelectAll: false },
};

const watchingListSlice = createSlice({
  name: 'watchingList',
  initialState,
  reducers: {
    filterWatchingList: {
      reducer(
        state,
        action: PayloadAction<{
          value: string | IEmployeeData[];
          typeFilter: typeFilterWatchingList;
          totalResult: number;
          totalPage: number;
        }>,
      ) {
        if (
          action.payload.typeFilter === typeFilterWatchingList.watchingListData &&
          typeof action.payload.value === 'object'
        ) {
          const newWatchingListData = action.payload.value.map((child) => {
            const { departments, positions, employeeId, ...rest } = child;
            const newChild = {
              ...rest,
              key: employeeId,
              employeeId,
              departmentName: departments?.[0]?.name || '',
              positionName: positions[0] || '',
            };

            return newChild;
          });

          state.filterWatchingList[action.payload.typeFilter].dataFiltered = newWatchingListData;
          state.filterWatchingList[action.payload.typeFilter].status = statusWatchingList.idle;
          state.filterWatchingList[action.payload.typeFilter].totalResult =
            action.payload.totalResult;
          state.filterWatchingList[action.payload.typeFilter].totalPage = action.payload.totalPage;
        }
      },
      prepare(
        value: string | IEmployeeData[],
        typeFilter: typeFilterWatchingList,
        totalResult: number,
        totalPage: number,
      ) {
        return { payload: { value, typeFilter, totalResult, totalPage } };
      },
    },
    addSelectedWatchingListData(
      state,
      action: PayloadAction<{
        typeSelect: TTypeSelect;
        data: Omit<IEmployeeDataSlice, 'departments' | 'positions' | 'childrenTotal'>[] | undefined;
      }>,
    ) {
      const { typeSelect, data } = action.payload;
      switch (typeSelect) {
        case 'selectOne':
          data && state.selectedWatchingListData.selectedDataList.push(data[0]);

          state.selectedWatchingListData.isSelectAll = false;

          return;
        case 'selectPart':
          data &&
            data.forEach((item) => {
              if (
                !state.selectedWatchingListData.selectedDataList.some(
                  (selected) => selected.employeeId === item.employeeId,
                )
              )
                state.selectedWatchingListData.selectedDataList.push(item);
            });

          return;
        case 'selectAll':
          state.selectedWatchingListData.isSelectAll = true;

          if (data) state.selectedWatchingListData.selectedDataList = data;

          return;
        case 'clearOne':
          data &&
            state.selectedWatchingListData.selectedDataList.splice(
              state.selectedWatchingListData.selectedDataList.findIndex(
                (item) => item.employeeId === data[0].employeeId,
              ),
              1,
            );

          state.selectedWatchingListData.isSelectAll = false;
          return;
        case 'clearAll':
          state.selectedWatchingListData.selectedDataList = [];

          state.selectedWatchingListData.isSelectAll = false;

          return;
        case 'clearPart':
          if (data)
            state.selectedWatchingListData.selectedDataList =
              state.selectedWatchingListData.selectedDataList.filter(
                (item) => !data.some((dt) => dt?.employeeId === item?.employeeId),
              );

          return;
        default:
          return;
      }
    },
    clearListRequestEmployee: (state) => {
      state.listRequestEmployee = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEmployeeInlineManager.pending, (state) => {
        state.isGettingWatchingList = true;
        state.error = null;
      })
      .addCase(
        getEmployeeInlineManager.fulfilled,
        (state, action: PayloadAction<IWatchingListData>) => {
          const parent = state.watchingListData
            .map((child) => findParentNode(child, action.payload.managerId))
            .filter((child) => child !== null)
            .pop();
          const newWatchingListData = action.payload.result.map((child) => {
            const { departments, positions, childrenTotal, employeeId, ...rest } = child;
            const newChild = {
              ...rest,
              key: employeeId,
              employeeId,
              departmentName: departments[0]?.name || '',
              positionName: positions[0] || '',
            };

            return childrenTotal > 0
              ? {
                  ...newChild,
                  children: Array.from({ length: childrenTotal }).map((_, index) => ({
                    id: `${-index}`,
                    disabled: true,
                  })),
                }
              : newChild;
          });

          state.isGettingWatchingList = false;
          if (action.payload.callChild && parent) {
            parent.children = newWatchingListData.map((data) => ({ ...data, disabled: true }));
          } else {
            state.watchingListData = newWatchingListData;
            state.total = action.payload.total;
            state.totalResult = action.payload.totalResult;
          }
        },
      )
      .addCase(getEmployeeInlineManager.rejected, (state, action: any) => {
        state.isGettingWatchingList = false;
        state.error = action.payload;
        state.watchingListData = [];
        state.total = 0;
      })
      .addCase(getEmployeeWatchingList.pending, (state) => {
        state.isGettingWatchingList = true;
        state.error = null;
      })
      .addCase(
        getEmployeeWatchingList.fulfilled,
        (state, action: PayloadAction<IWatchingListData>) => {
          const parent = state.watchingListData
            .map((child) => findParentNode(child, action.payload.managerId))
            .filter((child) => child !== null)
            .pop();
          const newWatchingListData = action.payload.result.map((child) => {
            const { departments, positions, childrenTotal, employeeId, ...rest } = child;
            const newChild = {
              ...rest,
              key: employeeId,
              employeeId,
              departmentName: departments[0]?.name || '',
              positionName: positions[0] || '',
            };

            return childrenTotal > 0
              ? {
                  ...newChild,
                  children: Array.from({ length: childrenTotal }).map((_, index) => ({
                    id: `${-index}`,
                    disabled: true,
                  })),
                }
              : newChild;
          });
          state.isGettingWatchingList = false;
          if (action.payload.callChild && parent) {
            parent.children = newWatchingListData.map((data) => ({ ...data, disabled: true }));
          } else {
            state.watchingListData = newWatchingListData;
            state.total = action.payload.total;
            state.totalResult = action.payload.totalResult;
          }
        },
      )
      .addCase(getEmployeeWatchingList.rejected, (state, action: any) => {
        state.isGettingWatchingList = false;
        state.error = action.payload;
        state.watchingListData = [];
        state.total = 0;
      })
      .addCase(searchListRequest.pending, (state, action: any) => {
        state.isGettingWatchingList = true;
        state.error = null;
      })
      .addCase(searchListRequest.fulfilled, (state, action) => {
        state.isGettingWatchingList = false;
        state.listRequestEmployee = action.payload.data;
      })
      .addCase(searchListRequest.rejected, (state, action: any) => {
        state.isGettingWatchingList = false;
        state.error = action.payload;
        state.listRequestEmployee = [];
      })
      .addCase(cancelRequestManager.pending, (state, action: any) => {
        state.isRequestLoading = true;
      })
      .addCase(cancelRequestManager.fulfilled, (state: any, action: any) => {
        state.isRequestLoading = false;
        state.listRequestEmployee = action.payload.newAllListRequest;
      })
      .addCase(cancelRequestManager.rejected, (state, action: any) => {
        state.isRequestLoading = false;
        state.listRequestEmployee = action.payload.newAllListRequest;
      })
      .addCase(approveRequestManager.pending, (state, action: any) => {
        state.isRequestLoading = true;
        state.isGettingWatchingList = true;
      })
      .addCase(approveRequestManager.fulfilled, (state: any, action: any) => {
        state.isRequestLoading = false;
        state.listRequestEmployee = action.payload.newAllListRequest;
      })
      .addCase(approveRequestManager.rejected, (state, action: any) => {
        state.isRequestLoading = false;
        state.listRequestEmployee = action.payload.newAllListRequest;
      })
      .addCase(rejectRequestManager.pending, (state, action: any) => {
        state.isRequestLoading = true;
      })
      .addCase(rejectRequestManager.fulfilled, (state: any, action: any) => {
        state.isRequestLoading = false;
        state.listRequestEmployee = action.payload.newAllListRequest;
      })
      .addCase(rejectRequestManager.rejected, (state, action: any) => {
        state.isRequestLoading = false;
        state.listRequestEmployee = action.payload.newAllListRequest;
      });
  },
});

export const selectWatchingList = (state: RootState) => state.watchingList;

export const selectWatchingListData = (state: RootState): IWatchingList['watchingListData'] =>
  state.watchingList.watchingListData;

export const selectWatchingListFiltered = (state: RootState, typeFilter: typeFilterWatchingList) =>
  state.watchingList.filterWatchingList[typeFilter].dataFiltered;

export const selectTotalResultWatchingListFiltered = (
  state: RootState,
  typeFilter: typeFilterWatchingList,
) => state.watchingList.filterWatchingList[typeFilter].totalResult;

export const selectTotalPageWatchingListFiltered = (
  state: RootState,
  typeFilter: typeFilterWatchingList,
) => state.watchingList.filterWatchingList[typeFilter].totalPage;

export const selectStatusWatchingListFiltered = (
  state: RootState,
  typeFilter: typeFilterWatchingList,
) => state.watchingList.filterWatchingList[typeFilter].status;

export const selectIsGettingEmployee = (state: RootState) =>
  state.watchingList.isGettingWatchingList;

export const selectTotalWatchingListData = (state: RootState) => state.watchingList.total;

export const selectTotalResultWatchingListData = (state: RootState) =>
  state.watchingList.totalResult;

export const selectWatchingListDataMarked = (state: RootState) =>
  state.watchingList.selectedWatchingListData;

export const selectWatchingListIdsMarked = createSelector(
  (state: RootState) => state.watchingList?.selectedWatchingListData,
  (selectedWatchingListData) =>
    selectedWatchingListData?.selectedDataList?.map((req: any) => req?.id),
);

export const selectIsRequestLoading = (state: RootState) => state.watchingList.isRequestLoading;

export const { filterWatchingList, addSelectedWatchingListData, clearListRequestEmployee } =
  watchingListSlice.actions;

export default watchingListSlice.reducer;

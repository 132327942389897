import { InputNumber } from 'antd';
import styled from 'styled-components';

export const SkillInfoTitle = styled.div`
  height: 38px;
  font-size: 20px;
  font-weight: 550;
  background-color: var(--cl_gray200);
  border-radius: 5px;
  line-height: 38px;
  padding: 25px 10px 25px 10px;
  bottom: 1000px;
  margin-top: 25px;
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const RightAlignInputNumber = styled(InputNumber)`
  .ant-input-number-input {
    text-align: right;
  }
`;
import React, { useState } from 'react';
import XLSX from 'xlsx';
import { Upload, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { FILE_TYPE_ACCEPT_IMPORT } from '../../constants/common';
import { getToken } from 'utils/auth';
import CustomButton from 'styles/buttonStyled';
import { EmployeeModal } from './styled';
import { getEmployeeList } from 'features/employee/employeeAction';
import { useDispatch } from 'react-redux';
import { ROOT_API } from 'constants/commons';
import { useTranslation } from 'react-i18next';

const get_header_row = (sheet: any) => {
  var headers = [],
    range = XLSX.utils.decode_range(sheet['!ref']);
  var C,
    R = range.s.r; /* start in the first row */
  for (C = range.s.c; C <= range.e.c; ++C) {
    /* walk every column in the range */
    var cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })]; /* find the cell in the first row */
    var hdr = 'UNKNOWN ' + C; // <-- replace with your desired default
    if (cell && cell.t) hdr = XLSX.utils.format_cell(cell);
    headers.push(hdr);
  }
  return headers;
};

const ImportEmployees = (props: any) => {
  const { isVisibleEmployeeImport, onClose } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation(['onboard', 'action']);
  const [fileList, setFileList]: Array<any> = useState([]);
  const [isDisableButton, setIsDisableButton] = useState(true);
  const [resUpload, setResUpload] = useState({
    error: [],
    success: [],
  });

  const urlImport = `${ROOT_API}/employee/import-employee`;
  const token = getToken();
  const propsUpload = {
    name: 'file',
    accept: FILE_TYPE_ACCEPT_IMPORT.join(','),
    multiple: false,
    showUploadList: { showRemoveIcon: false },
    action: urlImport,
    headers: {
      Authorization: `Bearer ${token}`,
      'x-tenant-id': localStorage.getItem('hrm.tenant')
        ? localStorage.getItem('hrm.tenant')
        : window.location.hostname === 'localhost'
        ? ROOT_API === 'https://dev.kingwork.vn/api'
          ? 'dev'
          : ROOT_API === 'https://qa.kingwork.vn/api'
          ? 'qa'
          : ''
        : window.location.hostname.replace('.kingwork.vn', ''),
    },
    fileList,
    beforeUpload(file: any) {
      setFileList([]);
      if (!FILE_TYPE_ACCEPT_IMPORT.includes(file.type)) {
        message.error(`${file.name} is not a excel file`);
      }
      return FILE_TYPE_ACCEPT_IMPORT.includes(file.type) ? true : Upload.LIST_IGNORE;
    },
    onChange(info: any) {
      const { status } = info.file;
      setFileList(info.fileList);
      if (status === 'done') {
        readFileExcel(info.file.originFileObj);
        message.success(`Upload ${info.file.name} file selected successfully.`);
        setResUpload(info.file?.response?.data);
        setIsDisableButton(false);
      } else if (status === 'error') {
        message.error(info.file?.response?.message);
        setIsDisableButton(true);
      }
    },
    onDrop(e: any) {
      if (e.dataTransfer.files.length > 1) {
        message.error(`Please select only one file!`);
      }
      const fileUpload = e.dataTransfer.files[0];
      if (fileUpload) {
        if (!FILE_TYPE_ACCEPT_IMPORT.includes(fileUpload.type)) {
          message.info(`${fileUpload.name} is not a excel file`);
        }
      }
    },
  };

  const readFileExcel = (file: any) => {
    const reader = new FileReader();
    reader.onload = function (e: any) {
      let data = e.target.result;
      let workbook = XLSX.read(data, {
        type: 'binary',
        cellText: true,
        cellDates: true,
        dateNF: 'yyyy/MM/dd',
      });

      let firstSheetName = workbook.SheetNames[0];
      var firstWorkSheet = workbook.Sheets[firstSheetName];

      // Update numbers in scientific (exponential) notation
      Object.keys(firstWorkSheet).forEach(function (s) {
        if (firstWorkSheet[s].t === 'n') {
          firstWorkSheet[s].v = firstWorkSheet[s].v.toLocaleString('fullwide', {
            useGrouping: false,
          });
        }
      });

      // Convert sheet to json and object
      let rowHeader = get_header_row(firstWorkSheet);
      let jsonContent = XLSX.utils.sheet_to_json(firstWorkSheet, {
        raw: false,
        dateNF: 'yyyy/MM/dd',
      });

      console.log('rowHeader', rowHeader);
      console.log('jsonContent', jsonContent);
    };
    reader.readAsBinaryString(file);
  };

  const handleImport = () => {
    if (resUpload) {
      if (resUpload?.success?.length > 0) {
        message.success(`Import sucess ${resUpload?.success?.length} records`);
      }
      if (resUpload?.error?.length > 0) {
        message.error(`Import failed ${resUpload?.error?.length} records`);
      }
      handleClose();
    } else {
      message.error(`Import failed all records`);
    }
  };

  const handleClose = () => {
    setFileList([]);
    setIsDisableButton(true);
    setResUpload({
      error: [],
      success: [],
    });
    onClose();
  };

  return (
    <EmployeeModal
      visible={isVisibleEmployeeImport}
      maskClosable={false}
      onCancel={handleClose}
      width={600}
      style={{ top: 20 }}
      footer={
        <CustomButton
          type="primary"
          key="reset-button"
          onClick={handleImport}
          disabled={isDisableButton}
        >
          {t('action:import')}
        </CustomButton>
      }
    >
      <Upload.Dragger {...propsUpload}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">{t('click_or_drag_file_to_this_area_to_upload')}</p>
        <p className="ant-upload-hint">{t('please_select_file_excel_and_click_import')}</p>
      </Upload.Dragger>
    </EmployeeModal>
  );
};

export default ImportEmployees;

import axiosClient from './axiosClient';
import queryString from 'query-string';

const departmentApi = {
  getList: (params: any) => {
    const response = axiosClient.get(`/departments?${queryString.stringify(params)}`);
    return response;
  },
};

export default departmentApi;

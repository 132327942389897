import { ExclamationCircleOutlined } from '@ant-design/icons';
import ModalConfirm from 'components/ModalConfirm';
import { notificationToast } from 'components/notificationToast';
import { Notification } from 'constants/notification';
import grapesjs from 'grapesjs';
import 'grapesjs-preset-newsletter';
import 'grapesjs/dist/css/grapes.min.css';
import PropTypes from 'prop-types';
import { memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  addDevices,
  customCommand,
  customListBlock,
  customPanelManager,
  customRichTextEditor,
  customStyleManager,
  customTypeComponent,
  removeBlocks,
} from '../config-email-template';

const devices = [
  {
    name: 'Desktop',
    width: '',
    widthMedia: '',
  },
  {
    name: 'Tablet',
    width: '600px', // this value will be used on canvas width
    widthMedia: '600px', // this value will be used in CSS @media
  },
  {
    name: 'Mobile',
    width: '425px',
    widthMedia: '480px',
  },
];

EditorEmail.propTypes = {
  listVariableEmail: PropTypes.array,
  setHtml: PropTypes.func,
  html: PropTypes.string,
  isDefaultHtml: PropTypes.bool,
  isSelectedModule: PropTypes.bool,
  onlyView: PropTypes.bool,
};

function EditorEmail(props) {
  const { listVariableEmail, html, setHtml, isDefaultHtml, onlyView, isSelectedModule } = props;
  const { t } = useTranslation(['onboard', 'leaveOff']);
  const [editor, setEditor] = useState();
  const [urlsB64, setUrlsB64] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const defaultValueCellspacing = useRef(0);
  const componentTypeTable = useRef();
  const isTypeTable = useRef(false);
  const commands = editor?.Commands;

  const onLoadEditor = () => {
    const editor = grapesjs.init({
      container: '#gjs',
      height: '500px',
      plugins: ['gjs-preset-newsletter'],
      pluginsOpts: {
        'gjs-preset-newsletter': {
          tableStyle: {
            height: '150px',
            width: '100%',
          },
        },
      },
      deviceManager: {
        devices: devices,
      },
      // storageManager: false,
      storageManager: {
        id: 'gjs-',
        type: 'local',
        autosave: true,
        autoload: true,
        stepsBeforeSave: 1,
        storeComponents: true,
        storeStyles: true,
        storeHtml: false,
        storeCss: false,
      },
      assetManager: {
        uploadFile: (e) => {
          const files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
          uploadFileToAsset(files);
        },
      },
    });
    return setEditor(editor);
  };

  const getEditorHtmlCss = () => {
    editor?.on('update', () => {
      let getHtmlCss = commands?.run('gjs-get-inlined-html');
      setHtml(getHtmlCss);
    });
    editor?.on('load', () => {
      let getHtmlCss = commands?.run('gjs-get-inlined-html');
      setHtml(getHtmlCss);
    });
  };
  const uploadFileToAsset = (files) => {
    let allowedExtensionImage = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/bmp'];
    let fileArray = [];
    for (let i = 0; i < files.length; i++) {
      if (files[i] && allowedExtensionImage.includes(files[i].type)) {
        if (files[i].size < 2000000) {
          // const urlBase64 = resizeDataURL({
          //   imgB64: imgB64,
          // });
          // urlBase64.then((imgB64Resize) => {
          //   fileArray.push({ src: imgB64Resize });
          //   setUrlsB64(fileArray);
          // })
          getBase64(files[i]).then((data) => {
            fileArray.push(data);
            setUrlsB64(fileArray);
          });
        } else {
          // notificationWithIcon({ type: 'error', description: t('check_size_image_upload') });
          notificationToast(
            Notification.Type.Error,
            t('check_size_image_upload'),
            Notification.Duration._3s,
          );
        }

        // fileArray.push({ src: URL.createObjectURL(files[i]) });
      } else {
        // notificationWithIcon({ type: 'error', description: t('check_type_upload_file') });
        notificationToast(
          Notification.Type.Error,
          t('check_type_upload_file'),
          Notification.Duration._3s,
        );
      }
    }
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const onloadDefaultEditor = () => {
    if (onlyView) {
      commands?.run('preview');
      editor?.on('run:preview', () => {
        commands?.stop('component-outline');
      });
      editor?.on('run', () => {
        const elPanel = document.querySelector('.gjs-pn-panels');
        const elIconEye = document.querySelector('.fa-eye-slash');
        const elGjsTool = document.getElementById('gjs-cv-tools');
        if (elPanel && elIconEye && elGjsTool) {
          elPanel.style.display = 'none';
          elIconEye.style.display = 'none';
          elGjsTool.style.display = 'none';
        }
      });
    }
    if (isDefaultHtml) {
      const parseHtml = editor?.Parser.parseHtml(html);
      editor?.setComponents(parseHtml.html);
    }
  };

  useEffect(() => {
    onLoadEditor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listVariableEmail]);

  useEffect(() => {
    editor?.on('load', () => {
      if (editor && isSelectedModule) {
        const wrapper = editor?.getWrapper();
        const componentVariable = wrapper.find('variable');
        componentVariable.forEach((item) => item.remove());
      }
    });
  }, [isSelectedModule, editor]);

  useEffect(() => {
    customRichTextEditor(editor, { listVariableEmail });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor]);

  useEffect(() => {
    customTypeComponent(editor, { onlyView });
    customStyleManager(editor, {
      isTypeTable,
      componentTypeTable,
      defaultValueCellspacing,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor]);

  useEffect(() => {
    customCommand(editor, { showModal });
    customPanelManager(editor);
    addDevices(editor);
    removeBlocks(editor);
    customListBlock(editor);
  }, [editor]);

  useEffect(() => {
    if (!onlyView && editor) {
      getEditorHtmlCss();
      editor?.on('run', () => {
        const elPaletteColor = document.querySelectorAll('.sp-container');
        elPaletteColor.forEach((el) => {
          el.style.left = '526px';
          el.style.top = '41px';
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor, onlyView, html, isDefaultHtml]);

  useEffect(() => {
    // Add image in asset manager
    urlsB64 && editor?.AssetManager.add(urlsB64);
  }, [urlsB64, editor]);

  useEffect(() => {
    // ui editor when edit and view
    onloadDefaultEditor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commands, html, isDefaultHtml]);

  useEffect(() => {
    editor?.on('run', () => {
      if (isDefaultHtml) editor?.Storage.setAutosave(false);
    });
  }, [isDefaultHtml, editor]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    editor?.DomComponents.clear();
    editor?.CssComposer.clear();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <ContainerEditor>
      <div id="gjs">
        <ModalConfirm
          onCancel={handleCancel}
          onOk={handleOk}
          isModalVisible={isModalVisible}
          getContainer={document.getElementById('gjs')}
          heading={t('leaveOff:modal_item.reconfirm')}
          content={t('clear_canvas')}
          icon={
            <ExclamationCircleOutlined
              style={{ fontSize: '22px', color: 'var(--cl_primary400)' }}
            />
          }
        />
      </div>
    </ContainerEditor>
  );
}

export default memo(EditorEmail);
const ContainerEditor = styled.div`
  #gjs {
    border: 1px solid #828282;
    .ant-modal {
      &-footer {
        .ant-btn {
          width: 100px;
        }
      }
    }
  }
  .gjs-pn-views {
    width: 25%;
  }
  .gjs-pn-views-container {
    width: 25%;
  }
  .gjs-pn-options {
    right: 25%;
  }
  .gjs-cv-canvas {
    width: 75%;
  }
  .gjs-four-color,
  .gjs-four-color-h:hover {
    color: var(--cl_primary);
  }
  .tree-select__type {
    cursor: pointer;
    background: transparent;
    border: unset;
    width: 98px;
  }
  .tree-select__type:focus-visible {
    outline: unset;
  }
`;

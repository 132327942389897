import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosClient from 'api/axiosClient';
import { getUser } from 'utils/auth';
import { setGlobalLoading } from './appSlice';

export const getNotifications = createAsyncThunk(
  'notification/getNotifications',
  async (params: { userId: string; limit: number; offset: number; titles: any }) => {
    const res = await axiosClient.get(
      `/notification/list/${params.userId}?limit=${params.limit}&offset=${params.offset}&titles=${params.titles}`,
    );
    return res;
  },
);
export const getNotificationsLineManager = createAsyncThunk(
  'notification/getNotificationsLineManager',
  async (params: { userId: string; limit: number; offset: number; titles: any }) => {
    let temp = params.titles.reduce((a, b) => {
      return a + `&titles[]=${b}`;
    }, '');

    const res = await axiosClient.get(
      `/notification/list/${params.userId}?limit=${params.limit}&offset=${params.offset}${temp}`,
    );
    return res;
  },
);

export const readNotifications = createAsyncThunk(
  'notification/readNotifications',
  async (params: { userId: string; notiId: number | String }) => {
    const res = await axiosClient.put(`/notification/${params.userId}/${params.notiId}`);
    return res;
  },
);
export const allNotifications = createAsyncThunk(
  'notification/AllNotifications',
  async ({ callBack, ...params }: any, thunkApi) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.post(
        `/notification/set-multi-read/${getUser().id}`,
        params,
      );
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack && callBack(response.data);
      return response;
    } catch (error) {
      callBack && callBack({ error });
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return thunkApi.rejectWithValue(error);
    }
  },
);

const notificationSlice = createSlice({
  name: 'notification',
  initialState: {
    notifications: [],
    notificationsLineManagers: [],
    loading: false,
    error: null,
    badgeNumber: 0,
    totalNoti: 0,
    totalNotiLineManager: 0,
    unreadNotiLineManager: 0,
  },
  reducers: {
    setBadgeNumber: (state, action) => {
      state.badgeNumber = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotifications.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getNotifications.fulfilled, (state, action) => {
        // console.log('file: notificationSlice.ts ~ line 47 ~ action', action);
        state.loading = false;
        state.notifications = action.payload.data.result;
        state.badgeNumber = action.payload.data.unreadNumber;
        state.totalNoti = action.payload.data.total;
      })
      .addCase(getNotifications.rejected, (state, action: any) => {
        state.loading = false;
        // state.error = action.payload;
      })
      .addCase(getNotificationsLineManager.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getNotificationsLineManager.fulfilled, (state, action) => {
        state.loading = false;
        state.notificationsLineManagers = action.payload.data.result;
        state.totalNotiLineManager = action.payload.data.total;
        state.unreadNotiLineManager = action.payload.data.unreadNumber;
      })
      .addCase(getNotificationsLineManager.rejected, (state, action: any) => {
        state.loading = false;
      })
      .addCase(readNotifications.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(readNotifications.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(readNotifications.rejected, (state, action: any) => {
        state.loading = false;
      });
  },
});

export const { setBadgeNumber } = notificationSlice.actions;
export default notificationSlice.reducer;

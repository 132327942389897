import { combineReducers } from '@reduxjs/toolkit';
import appSlice from './appSlice';
import themeSlice from './themeSlice';
import departmentSlice from 'features/department/departmentSlice';
import roleSlice from 'features/role/roleSlice';
import configurationSlice from 'features/configuration/configurationSlice';
import contractSlice from 'features/contract/contractSlice';
import timesheetSlice from 'features/timesheet/pages/timesheetSlice';
import authSlice from 'features/auth/authSlice';
import watchingListSlice from 'features/watchingList/watchingListSlice';
import masterDataSlice from 'features/masterData/masterDataSlice';
import notificationSlice from './notificationSlice';
import onboardSlice from 'features/onboard/onboardSlice';
import equipmentSlice from 'features/equipment/equipmentSlice';
import dashboardSlice from 'features/dashboard/dashboardSlice';
import leaveOffSlice from 'features/leaveOff/leaveOffSlice';
import offboardSlice from 'features/offboard/offboardSlice';
import insuranceSlice from 'features/insurances/insuranceSlice';
import employeeSlice from 'features/employee/employeeSlice';
import workShiftSlice from 'features/workShifts/workShiftSlice';
import overtimeDataSlice from 'features/overtime/overtimeSlice';
import payrollSlice from 'features/payroll/payrollSlice';
import performanceSlice from 'features/performance/performanceSlice';
import trainingSlice from 'features/training/trainingSlice';
import recruitmentSlice from 'features/recruitment/recruitmentSlice';

const rootReducer = combineReducers({
  app: appSlice,
  theme: themeSlice,
  auth: authSlice,
  employee: employeeSlice,
  configuration: configurationSlice,
  contract: contractSlice,
  department: departmentSlice,
  role: roleSlice,
  watchingList: watchingListSlice,
  timesheet: timesheetSlice,
  notification: notificationSlice,
  onboard: onboardSlice,
  offboard: offboardSlice,
  equipment: equipmentSlice,
  dashboard: dashboardSlice,
  masterData: masterDataSlice,
  leaveOff: leaveOffSlice,
  insurance: insuranceSlice,
  workshift: workShiftSlice,
  overtime: overtimeDataSlice,
  payroll: payrollSlice,
  performance: performanceSlice,
  training: trainingSlice,
  recruitment: recruitmentSlice,
});

export default rootReducer;

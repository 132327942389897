import { Switch } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import dashboardEmployeeApi from 'api/dashboardEmployeeApi';
import IconExport from 'assets/icons/ic_export_gray.svg';
import { CustomIcon } from 'components/ITVSearch';
import { MONTH_NAMES } from 'constants/commons';
import { HeaderRemove } from 'features/masterData/Department/styled';
import { EmployeeGrowthReport, EmployeeSummaryPayload } from 'types';
import { exportToCSV } from 'utils/common';
import EmployeeSummaryChart from './EmployeeSummaryChart';
import EmployeeSummaryFilters from './EmployeeSummaryFilters';

const StyledContainer = styled.div`
  padding: 16px 24px;
  background-color: var(--cl_white);
  border-radius: 12px;
  border: 0.5px solid var(--cl_gray200);
  height: 100%;
`;
const StyledWrapperSwitch = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const StyledHeaderTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  font-family: Roboto;
  color: #121926;
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: end;
  margin-bottom: 20px;
  width: 100%;
`;
const StyledWrapperButtonRight = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
`;

export function EmployeeSummary() {
  const { t } = useTranslation(['dashboard', 'action']);
  const labels = MONTH_NAMES.map((month) => month.slice(0, 3));

  const [isSwitch, setIsSwitch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const [dataChart, setDataChart] = useState<EmployeeGrowthReport>({
    labels: [],
    datasets: [],
  });
  const [filters, setFilters] = useState<EmployeeSummaryPayload>({
    year: moment(new Date()),
    yearRange: {
      fromYear: moment(new Date()).subtract(4, 'years'),
      endYear: moment(new Date()),
    },
    type: 'single',
  });

  const IconExportComponents = (
    <CustomIcon type={'export'} component={() => <img src={IconExport} alt="" />} />
  );

  useEffect(() => {
    async function fetchReportEmployeeGrowth() {
      try {
        if (!isSwitch) {
          setIsLoading(true);
          const response = await dashboardEmployeeApi.getEmployeeSummaryChart({
            year: filters.year?.format('YYYY')
              ? filters.year?.format('YYYY')
              : moment(new Date()).format('YYYY'),
            type: filters?.type ? filters.type : 'single',
          });
          const datasets = [
            {
              label: '',
              data: response.data?.map((x: any) => x.month_count),
              fill: true,
              backgroundColor: '#D1DBFF',
              borderColor: '#D1DBFF',
            },
          ];
          setIsLoading(false);
          setDataChart((prevState) => ({
            ...prevState,
            labels,
            datasets,
          }));
        } else {
          setIsLoading(true);
          const response = await dashboardEmployeeApi.getEmployeeSummaryChart({
            fromDate: filters?.yearRange.fromYear.format('YYYY'),
            toDate: filters?.yearRange.endYear.format('YYYY'),
            type: filters?.type ? filters.type : 'muti',
          });

          const datasets = [
            {
              label: '',
              data: response.data?.map((x: any) => x.month_count),
              fill: true,
              backgroundColor: '#D1DBFF',
              borderColor: '#D1DBFF',
            },
          ];
          const labels = response.data?.map((x: any) => x.data_time);
          setIsLoading(false);

          setDataChart((prevState) => ({
            ...prevState,
            labels,
            datasets,
          }));
        }
      } catch (error) {
        setIsLoading(false);
      }
    }

    fetchReportEmployeeGrowth();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const handleSubmit = ({ year, yearRange }: EmployeeSummaryPayload) => {
    const formattedYear = moment(year);

    if (!isSwitch) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        year: formattedYear,
      }));
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        yearRange: {
          fromYear: yearRange[0],
          endYear: yearRange[1],
        },
      }));
    }
  };

  const onChangeSwitch = (checked: boolean) => {
    setIsSwitch(checked);
    setFilters({
      ...filters,
      type: checked === true ? 'muti' : 'single',
    });
  };

  const handleExport = async () => {
    try {
      if (!isSwitch) {
        setIsLoadingExport(true);
        const response = await dashboardEmployeeApi.exportEmployeeSummaryChart({
          year: filters.year?.format('YYYY')
            ? filters.year?.format('YYYY')
            : moment(new Date()).format('YYYY'),
          type: filters?.type ? filters.type : 'single',
        });
        setIsLoadingExport(false);

        const exportFileName = `Employee_Symmary_${filters.year?.format('YYYY')}.xlsx`;
        exportToCSV(response, exportFileName);
      } else {
        setIsLoadingExport(true);
        const response = await dashboardEmployeeApi.exportEmployeeSummaryChart({
          fromDate: filters?.yearRange.fromYear.format('YYYY'),
          toDate: filters?.yearRange.endYear.format('YYYY'),
          type: filters?.type ? filters.type : 'muti',
        });
        setIsLoadingExport(false);

        const exportFileName = `Employee_Summary_${filters?.yearRange.fromYear.format(
          'YYYY',
        )}-${filters?.yearRange.endYear.format('YYYY')}.xlsx`;
        exportToCSV(response, exportFileName);
      }
    } catch (error) {
      setIsLoadingExport(false);
    }
  };

  return (
    <StyledContainer>
      <StyledWrapperSwitch>
        <StyledHeaderTitle>{t('dashboard:dashboard_employee.employee_summary')}</StyledHeaderTitle>

        <Switch
          checkedChildren={t('dashboard:dashboard_employee.muti_year')}
          unCheckedChildren={t('dashboard:dashboard_employee.single_year')}
          onChange={onChangeSwitch}
        />
      </StyledWrapperSwitch>

      <StyledWrapper>
        <StyledWrapperButtonRight>
          <EmployeeSummaryFilters
            initialValues={filters}
            onSubmit={handleSubmit}
            isSwitch={isSwitch}
          />

          <HeaderRemove
            onClick={handleExport}
            icon={IconExportComponents}
            loading={isLoadingExport}
            style={{
              backgroundColor: '#f6f6f6',
              fontSize: '12px',
              color: '#1a2b88',
              fontWeight: 'bold',
            }}
          >
            {t('action:export')}
          </HeaderRemove>
        </StyledWrapperButtonRight>
      </StyledWrapper>

      <EmployeeSummaryChart loading={isLoading} dataChart={dataChart} />
    </StyledContainer>
  );
}

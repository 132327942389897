export const LOCALE_FORMAT_DATE = {
  'af-za': 'YYYY/MM/DD',
  'am-et': 'D/M/YYYY',
  'ar-ae': 'DD/MM/YYYY',
  'ar-bh': 'DD/MM/YYYY',
  'ar-dz': 'DD-MM-YYYY',
  'ar-eg': 'DD/MM/YYYY',
  'ar-iq': 'DD/MM/YYYY',
  'ar-jo': 'DD/MM/YYYY',
  'ar-kw': 'DD/MM/YYYY',
  'ar-lb': 'DD/MM/YYYY',
  'ar-ly': 'DD/MM/YYYY',
  'ar-ma': 'DD-MM-YYYY',
  'ar-om': 'DD/MM/YYYY',
  'ar-qa': 'DD/MM/YYYY',
  'ar-sa': 'DD/MM/YY',
  'ar-sy': 'DD/MM/YYYY',
  'ar-tn': 'DD-MM-YYYY',
  'ar-ye': 'DD/MM/YYYY',
  'arn-cl': 'DD-MM-YYYY',
  'as-in': 'DD-MM-YYYY',
  'az-cyrl-az': 'DD.MM.YYYY',
  'az-latn-az': 'DD.MM.YYYY',
  'ba-ru': 'DD.MM.YY',
  'be-by': 'DD.MM.YYYY',
  'bg-bg': 'DD.M.YYYY',
  'bn-bd': 'DD-MM-YY',
  'bn-in': 'DD-MM-YY',
  'bo-cn': 'YYYY/M/D',
  'br-fr': 'DD/MM/YYYY',
  'bs-cyrl-ba': 'D.M.YYYY',
  'bs-latn-ba': 'D.M.YYYY',
  'ca-es': 'DD/MM/YYYY',
  'co-fr': 'DD/MM/YYYY',
  'cs-cz': 'D.M.YYYY',
  'cy-gb': 'DD/MM/YYYY',
  'da-dk': 'DD-MM-YYYY',
  'de-at': 'DD.MM.YYYY',
  'de-ch': 'DD.MM.YYYY',
  'de-de': 'DD.MM.YYYY',
  'de-li': 'DD.MM.YYYY',
  'de-lu': 'DD.MM.YYYY',
  'dsb-de': 'D. M. yyyy',
  'dv-mv': 'DD/MM/YY',
  'el-gr': 'D/M/YYYY',
  'en-029': 'MM/DD/YYYY',
  'en-au': 'D/MM/YYYY',
  'en-bz': 'DD/MM/YYYY',
  'en-ca': 'DD/MM/YYYY',
  'en-gb': 'DD/MM/YYYY',
  'en-ie': 'DD/MM/YYYY',
  'en-in': 'DD-MM-YYYY',
  'en-jm': 'DD/MM/YYYY',
  'en-my': 'D/M/YYYY',
  'en-nz': 'D/MM/YYYY',
  'en-ph': 'M/D/YYYY',
  'en-sg': 'D/M/YYYY',
  'en-tt': 'DD/MM/YYYY',
  'en-us': 'MM/DD/YYYY',
  'en-za': 'YYYY/MM/DD',
  'en-zw': 'M/D/YYYY',
  'es-ar': 'DD/MM/YYYY',
  'es-bo': 'DD/MM/YYYY',
  'es-cl': 'DD-MM-YYYY',
  'es-co': 'DD/MM/YYYY',
  'es-cr': 'DD/MM/YYYY',
  'es-do': 'DD/MM/YYYY',
  'es-ec': 'DD/MM/YYYY',
  'es-es': 'DD/MM/YYYY',
  'es-gt': 'DD/MM/YYYY',
  'es-hn': 'DD/MM/YYYY',
  'es-mx': 'DD/MM/YYYY',
  'es-ni': 'DD/MM/YYYY',
  'es-pa': 'MM/DD/YYYY',
  'es-pe': 'DD/MM/YYYY',
  'es-pr': 'DD/MM/YYYY',
  'es-py': 'DD/MM/YYYY',
  'es-sv': 'DD/MM/YYYY',
  'es-us': 'MM/DD/YYYY',
  'es-uy': 'DD/MM/YYYY',
  'es-ve': 'DD/MM/YYYY',
  'et-ee': 'D.MM.YYYY',
  'eu-es': 'YYYY/MM/DD',
  'fa-ir': 'MM/DD/YYYY',
  'fi-fi': 'D.M.YYYY',
  'fil-ph': 'M/D/YYYY',
  'fo-fo': 'DD-MM-YYYY',
  'fr-be': 'D/MM/YYYY',
  'fr-ca': 'YYYY-MM-DD',
  'fr-ch': 'DD.MM.YYYY',
  'fr-fr': 'DD/MM/YYYY',
  'fr-lu': 'DD/MM/YYYY',
  'fr-mc': 'DD/MM/YYYY',
  'fy-nl': 'D-M-YYYY',
  'ga-ie': 'DD/MM/YYYY',
  'gd-gb': 'DD/MM/YYYY',
  'gl-es': 'DD/MM/YY',
  'gsw-fr': 'DD/MM/YYYY',
  'gu-in': 'DD-MM-YY',
  'ha-latn-ng': 'D/M/YYYY',
  'he-il': 'DD/MM/YYYY',
  'hi-in': 'DD-MM-YYYY',
  'hr-ba': 'D.M.YYYY.',
  'hr-hr': 'D.M.YYYY',
  'hsb-de': 'D. M. YYYY',
  'hu-hu': 'YYYY. MM. dd.',
  'hy-am': 'DD.MM.YYYY',
  'id-id': 'DD/MM/YYYY',
  'ig-ng': 'D/M/YYYY',
  'ii-cn': 'YYYY/M/D',
  'is-is': 'D.M.YYYY',
  'it-ch': 'DD.MM.YYYY',
  'it-it': 'DD/MM/YYYY',
  'iu-cans-ca': 'D/M/YYYY',
  'iu-latn-ca': 'D/MM/YYYY',
  'ja-jp': 'YYYY/MM/DD',
  'ka-ge': 'DD.MM.YYYY',
  'kk-kz': 'DD.MM.YYYY',
  'kl-gl': 'DD-MM-YYYY',
  'km-kh': 'YYYY-MM-DD',
  'kn-in': 'DD-MM-YY',
  'ko-kr': 'MM/DD/YYYY',
  'kok-in': 'DD-MM-YYYY',
  'ky-kg': 'DD.MM.YY',
  'lb-lu': 'DD/MM/YYYY',
  'lo-la': 'DD/MM/YYYY',
  'lt-lt': 'YYYY.MM.DD',
  'lv-lv': 'YYYY.MM.DD.',
  'mi-nz': 'DD/MM/YYYY',
  'mk-mk': 'DD.MM.YYYY',
  'ml-in': 'DD-MM-YY',
  'mn-mn': 'YY.MM.DD',
  'mn-mong-cn': 'YYYY/M/D',
  'moh-ca': 'M/D/YYYY',
  'mr-in': 'DD-MM-YYYY',
  'ms-bn': 'DD/MM/YYYY',
  'ms-my': 'DD/MM/YYYY',
  'mt-mt': 'DD/MM/YYYY',
  'nb-no': 'DD.MM.YYYY',
  'ne-np': 'M/D/YYYY',
  'nl-be': 'D/MM/YYYY',
  'nl-nl': 'D-M-YYYY',
  'nn-no': 'DD.MM.YYYY',
  'nso-za': 'YYYY/MM/DD',
  'oc-fr': 'DD/MM/YYYY',
  'or-in': 'DD-MM-YY',
  'pa-in': 'DD-MM-YY',
  'pl-pl': 'DD.MM.YYYY',
  'prs-af': 'DD/MM/YY',
  'ps-af': 'DD/MM/YY',
  'pt-br': 'D/M/YYYY',
  'pt-pt': 'DD-MM-YYYY',
  'qut-gt': 'DD/MM/YYYY',
  'quz-bo': 'DD/MM/YYYY',
  'quz-ec': 'DD/MM/YYYY',
  'quz-pe': 'DD/MM/YYYY',
  'rm-ch': 'DD/MM/YYYY',
  'ro-ro': 'DD.MM.YYYY',
  'ru-ru': 'DD.MM.YYYY',
  'rw-rw': 'M/D/YYYY',
  'sa-in': 'DD-MM-YYYY',
  'sah-ru': 'MM.DD.YYYY',
  'se-fi': 'D.M.YYYY',
  'se-no': 'DD.MM.YYYY',
  'se-se': 'YYYY-MM-DD',
  'si-lk': 'YYYY-MM-DD',
  'sk-sk': 'D. M. yyyy',
  'sl-si': 'D.M.YYYY',
  'sma-no': 'DD.MM.YYYY',
  'sma-se': 'YYYY-MM-DD',
  'smj-no': 'DD.MM.YYYY',
  'smj-se': 'YYYY-MM-DD',
  'smn-fi': 'D.M.YYYY',
  'sms-fi': 'D.M.YYYY',
  'sq-al': 'YYYY-MM-DD',
  'sr-cyrl-ba': 'D.M.YYYY',
  'sr-cyrl-cs': 'D.M.YYYY',
  'sr-cyrl-me': 'D.M.YYYY',
  'sr-cyrl-rs': 'D.M.YYYY',
  'sr-latn-ba': 'D.M.YYYY',
  'sr-latn-cs': 'D.M.YYYY',
  'sr-latn-me': 'D.M.YYYY',
  'sr-latn-rs': 'D.M.YYYY',
  'sv-fi': 'D.M.YYYY',
  'sv-se': 'YYYY-MM-DD',
  'sw-ke': 'M/D/YYYY',
  'syr-sy': 'DD/MM/YYYY',
  'ta-in': 'DD-MM-YYYY',
  'te-in': 'DD-MM-YY',
  'tg-cyrl-tj': 'DD.MM.YY',
  'th-th': 'D/M/YYYY',
  'tk-tm': 'DD.MM.YY',
  'tn-za': 'YYYY/MM/DD',
  'tr-tr': 'DD.MM.YYYY',
  'tt-ru': 'DD.MM.YYYY',
  'tzm-latn-DZ': 'DD-MM-YYYY',
  'ug-cn': 'YYYY-M-D',
  'uk-ua': 'MM/DD/YYYY',
  'ur-pk': 'DD/MM/YYYY',
  'uz-cyrl-uz': 'DD.MM.YYYY',
  'uz-latn-uz': 'DD/MM YYYY',
  'vi-vn': 'DD/MM/YYYY',
  'wo-sn': 'DD/MM/YYYY',
  'xh-za': 'YYYY/MM/DD',
  'yo-ng': 'D/M/YYYY',
  'zh-cn': 'YYYY/M/D',
  'zh-hk': 'D/M/YYYY',
  'zh-mo': 'D/M/YYYY',
  'zh-sg': 'D/M/YYYY',
  'zh-tw': 'YYYY/M/D',
  'zu-za': 'YYYY/MM/DD',

  af: 'YYYY/MM/DD',
  am: 'D/M/YYYY',
  ar: 'DD/MM/YYYY',
  arn: 'DD-MM-YYYY',
  as: 'DD-MM-YYYY',
  az: 'DD.MM.YYYY',
  ba: 'DD.MM.YY',
  be: 'DD.MM.YYYY',
  bg: 'DD.M.YYYY',
  bn: 'DD-MM-YY',
  bo: 'YYYY/M/D',
  br: 'DD/MM/YYYY',
  bs: 'D.M.YYYY',
  ca: 'DD/MM/YYYY',
  co: 'DD/MM/YYYY',
  cs: 'D.M.YYYY',
  cy: 'DD/MM/YYYY',
  da: 'DD-MM-YYYY',
  de: 'DD.MM.YYYY',
  dsb: 'D. M. YYYY',
  dv: 'DD/MM/YY',
  el: 'D/M/YYYY',
  en: 'MM/DD/YYYY',
  es: 'DD/MM/YYYY',
  et: 'D.MM.YYYY',
  eu: 'YYYY/MM/DD',
  fa: 'MM/DD/YYYY',
  fi: 'D.M.YYYY',
  fil: 'M/D/YYYY',
  fo: 'DD-MM-YYYY',
  fr: 'D/MM/YYYY',
  fy: 'D-M-YYYY',
  ga: 'DD/MM/YYYY',
  gd: 'DD/MM/YYYY',
  gl: 'DD/MM/YY',
  gsw: 'DD/MM/YYYY',
  gu: 'DD-MM-YY',
  ha: 'D/M/YYYY',
  he: 'DD/MM/YYYY',
  hi: 'DD-MM-YYYY',
  hr: 'D.M.YYYY.',
  hsb: 'D. M. YYYY',
  hu: 'YYYY. MM. dd.',
  hy: 'DD.MM.YYYY',
  id: 'DD/MM/YYYY',
  ig: 'D/M/YYYY',
  ii: 'YYYY/M/D',
  is: 'D.M.YYYY',
  it: 'DD.MM.YYYY',
  iu: 'D/M/YYYY',
  ja: 'YYYY/MM/DD',
  ka: 'DD.MM.YYYY',
  kk: 'DD.MM.YYYY',
  kl: 'DD-MM-YYYY',
  km: 'YYYY-MM-DD',
  kn: 'DD-MM-YY',
  ko: 'MM/DD/YYYY',
  kok: 'DD-MM-YYYY',
  ky: 'DD.MM.YY',
  lb: 'DD/MM/YYYY',
  lo: 'DD/MM/YYYY',
  lt: 'YYYY.MM.DD',
  lv: 'YYYY.MM.DD.',
  mi: 'DD/MM/YYYY',
  mk: 'DD.MM.YYYY',
  ml: 'DD-MM-YY',
  mn: 'YY.MM.DD',
  moh: 'M/D/YYYY',
  mr: 'DD-MM-YYYY',
  ms: 'DD/MM/YYYY',
  mt: 'DD/MM/YYYY',
  nb: 'DD.MM.YYYY',
  ne: 'M/D/YYYY',
  nl: 'D/MM/YYYY',
  nn: 'DD.MM.YYYY',
  nso: 'YYYY/MM/DD',
  oc: 'DD/MM/YYYY',
  or: 'DD-MM-YY',
  pa: 'DD-MM-YY',
  pl: 'DD.MM.YYYY',
  prs: 'DD/MM/YY',
  ps: 'DD/MM/YY',
  pt: 'D/M/YYYY',
  qut: 'DD/MM/YYYY',
  quz: 'DD/MM/YYYY',
  rm: 'DD/MM/YYYY',
  ro: 'DD.MM.YYYY',
  ru: 'DD.MM.YYYY',
  rw: 'M/D/YYYY',
  sa: 'DD-MM-YYYY',
  sah: 'MM.DD.YYYY',
  se: 'D.M.YYYY',
  si: 'YYYY-MM-DD',
  sk: 'D. M. YYYY',
  sl: 'D.M.YYYY',
  sma: 'DD.MM.YYYY',
  smj: 'DD.MM.YYYY',
  smn: 'D.M.YYYY',
  sms: 'D.M.YYYY',
  sq: 'YYYY-MM-DD',
  sr: 'D.M.YYYY',
  sv: 'D.M.YYYY',
  sw: 'M/D/YYYY',
  syr: 'DD/MM/YYYY',
  ta: 'DD-MM-YYYY',
  te: 'DD-MM-YY',
  tg: 'DD.MM.YY',
  th: 'D/M/YYYY',
  tk: 'DD.MM.YY',
  tn: 'YYYY/MM/DD',
  tr: 'DD.MM.YYYY',
  tt: 'DD.MM.YYYY',
  tzm: 'DD-MM-YYYY',
  ug: 'YYYY-M-D',
  uk: 'MM/DD/YYYY',
  ur: 'DD/MM/YYYY',
  uz: 'DD.MM.YYYY',
  vi: 'DD/MM/YYYY',
  wo: 'DD/MM/YYYY',
  xh: 'YYYY/MM/DD',
  yo: 'D/M/YYYY',
  zh: 'YYYY/M/D',
  zu: 'YYYY/MM/DD',
};

export const LOCALE_FORMAT_DATETIME = {
  'af-za': 'YYYY/MM/DD - HH:mm',
  'am-et': 'D/M/YYYY - HH:mm',
  'ar-ae': 'DD/MM/YYYY - HH:mm',
  'ar-bh': 'DD/MM/YYYY - HH:mm',
  'ar-dz': 'DD-MM-YYYY - HH:mm',
  'ar-eg': 'DD/MM/YYYY - HH:mm',
  'ar-iq': 'DD/MM/YYYY - HH:mm',
  'ar-jo': 'DD/MM/YYYY - HH:mm',
  'ar-kw': 'DD/MM/YYYY - HH:mm',
  'ar-lb': 'DD/MM/YYYY - HH:mm',
  'ar-ly': 'DD/MM/YYYY - HH:mm',
  'ar-ma': 'DD-MM-YYYY - HH:mm',
  'ar-om': 'DD/MM/YYYY - HH:mm',
  'ar-qa': 'DD/MM/YYYY - HH:mm',
  'ar-sa': 'DD/MM/YY - HH:mm',
  'ar-sy': 'DD/MM/YYYY - HH:mm',
  'ar-tn': 'DD-MM-YYYY - HH:mm',
  'ar-ye': 'DD/MM/YYYY - HH:mm',
  'arn-cl': 'DD-MM-YYYY - HH:mm',
  'as-in': 'DD-MM-YYYY - HH:mm',
  'az-cyrl-az': 'DD.MM.YYYY - HH:mm',
  'az-latn-az': 'DD.MM.YYYY - HH:mm',
  'ba-ru': 'DD.MM.YY - HH:mm',
  'be-by': 'DD.MM.YYYY - HH:mm',
  'bg-bg': 'DD.M.YYYY - HH:mm',
  'bn-bd': 'DD-MM-YY - HH:mm',
  'bn-in': 'DD-MM-YY - HH:mm',
  'bo-cn': 'YYYY/M/D - HH:mm',
  'br-fr': 'DD/MM/YYYY - HH:mm',
  'bs-cyrl-ba': 'D.M.YYYY - HH:mm',
  'bs-latn-ba': 'D.M.YYYY - HH:mm',
  'ca-es': 'DD/MM/YYYY - HH:mm',
  'co-fr': 'DD/MM/YYYY - HH:mm',
  'cs-cz': 'D.M.YYYY - HH:mm',
  'cy-gb': 'DD/MM/YYYY - HH:mm',
  'da-dk': 'DD-MM-YYYY - HH:mm',
  'de-at': 'DD.MM.YYYY - HH:mm',
  'de-ch': 'DD.MM.YYYY - HH:mm',
  'de-de': 'DD.MM.YYYY - HH:mm',
  'de-li': 'DD.MM.YYYY - HH:mm',
  'de-lu': 'DD.MM.YYYY - HH:mm',
  'dsb-de': 'D. M . yyyy - HH:mm',
  'dv-mv': 'DD/MM/YY - HH:mm',
  'el-gr': 'D/M/YYYY - HH:mm',
  'en-029': 'MM/DD/YYYY - HH:mm',
  'en-au': 'D/MM/YYYY - HH:mm',
  'en-bz': 'DD/MM/YYYY - HH:mm',
  'en-ca': 'DD/MM/YYYY - HH:mm',
  'en-gb': 'DD/MM/YYYY - HH:mm',
  'en-ie': 'DD/MM/YYYY - HH:mm',
  'en-in': 'DD-MM-YYYY - HH:mm',
  'en-jm': 'DD/MM/YYYY - HH:mm',
  'en-my': 'D/M/YYYY - HH:mm',
  'en-nz': 'D/MM/YYYY - HH:mm',
  'en-ph': 'M/D/YYYY - HH:mm',
  'en-sg': 'D/M/YYYY - HH:mm',
  'en-tt': 'DD/MM/YYYY - HH:mm',
  'en-us': 'MM/DD/YYYY - HH:mm',
  'en-za': 'YYYY/MM/DD - HH:mm',
  'en-zw': 'M/D/YYYY - HH:mm',
  'es-ar': 'DD/MM/YYYY - HH:mm',
  'es-bo': 'DD/MM/YYYY - HH:mm',
  'es-cl': 'DD-MM-YYYY - HH:mm',
  'es-co': 'DD/MM/YYYY - HH:mm',
  'es-cr': 'DD/MM/YYYY - HH:mm',
  'es-do': 'DD/MM/YYYY - HH:mm',
  'es-ec': 'DD/MM/YYYY - HH:mm',
  'es-es': 'DD/MM/YYYY - HH:mm',
  'es-gt': 'DD/MM/YYYY - HH:mm',
  'es-hn': 'DD/MM/YYYY - HH:mm',
  'es-mx': 'DD/MM/YYYY - HH:mm',
  'es-ni': 'DD/MM/YYYY - HH:mm',
  'es-pa': 'MM/DD/YYYY - HH:mm',
  'es-pe': 'DD/MM/YYYY - HH:mm',
  'es-pr': 'DD/MM/YYYY - HH:mm',
  'es-py': 'DD/MM/YYYY - HH:mm',
  'es-sv': 'DD/MM/YYYY - HH:mm',
  'es-us': 'MM/DD/YYYY - HH:mm',
  'es-uy': 'DD/MM/YYYY - HH:mm',
  'es-ve': 'DD/MM/YYYY - HH:mm',
  'et-ee': 'D.MM.YYYY - HH:mm',
  'eu-es': 'YYYY/MM/DD - HH:mm',
  'fa-ir': 'MM/DD/YYYY - HH:mm',
  'fi-fi': 'D.M.YYYY - HH:mm',
  'fil-ph': 'M/D/YYYY - HH:mm',
  'fo-fo': 'DD-MM-YYYY - HH:mm',
  'fr-be': 'D/MM/YYYY - HH:mm',
  'fr-ca': 'YYYY-MM-DD - HH:mm',
  'fr-ch': 'DD.MM.YYYY - HH:mm',
  'fr-fr': 'DD/MM/YYYY - HH:mm',
  'fr-lu': 'DD/MM/YYYY - HH:mm',
  'fr-mc': 'DD/MM/YYYY - HH:mm',
  'fy-nl': 'D-M-YYYY - HH:mm',
  'ga-ie': 'DD/MM/YYYY - HH:mm',
  'gd-gb': 'DD/MM/YYYY - HH:mm',
  'gl-es': 'DD/MM/YY - HH:mm',
  'gsw-fr': 'DD/MM/YYYY - HH:mm',
  'gu-in': 'DD-MM-YY - HH:mm',
  'ha-latn-ng': 'D/M/YYYY - HH:mm',
  'he-il': 'DD/MM/YYYY - HH:mm',
  'hi-in': 'DD-MM-YYYY - HH:mm',
  'hr-ba': 'D.M.YYYY - HH:mm.',
  'hr-hr': 'D.M.YYYY - HH:mm',
  'hsb-de': 'D. M . YYYY - HH:mm',
  'hu-hu': 'YYYY. MM . dd. - HH:mm',
  'hy-am': 'DD.MM.YYYY - HH:mm',
  'id-id': 'DD/MM/YYYY - HH:mm',
  'ig-ng': 'D/M/YYYY - HH:mm',
  'ii-cn': 'YYYY/M/D - HH:mm',
  'is-is': 'D.M.YYYY - HH:mm',
  'it-ch': 'DD.MM.YYYY - HH:mm',
  'it-it': 'DD/MM/YYYY - HH:mm',
  'iu-cans-ca': 'D/M/YYYY - HH:mm',
  'iu-latn-ca': 'D/MM/YYYY - HH:mm',
  'ja-jp': 'YYYY/MM/DD - HH:mm',
  'ka-ge': 'DD.MM.YYYY - HH:mm',
  'kk-kz': 'DD.MM.YYYY - HH:mm',
  'kl-gl': 'DD-MM-YYYY - HH:mm',
  'km-kh': 'YYYY-MM-DD - HH:mm',
  'kn-in': 'DD-MM-YY - HH:mm',
  'ko-kr': 'MM/DD/YYYY - HH:mm',
  'kok-in': 'DD-MM-YYYY - HH:mm',
  'ky-kg': 'DD.MM.YY - HH:mm',
  'lb-lu': 'DD/MM/YYYY - HH:mm',
  'lo-la': 'DD/MM/YYYY - HH:mm',
  'lt-lt': 'YYYY.MM.DD - HH:mm',
  'lv-lv': 'YYYY.MM.DD - HH:mm.',
  'mi-nz': 'DD/MM/YYYY - HH:mm',
  'mk-mk': 'DD.MM.YYYY - HH:mm',
  'ml-in': 'DD-MM-YY - HH:mm',
  'mn-mn': 'YY.MM.DD - HH:mm',
  'mn-mong-cn': 'YYYY/M/D - HH:mm',
  'moh-ca': 'M/D/YYYY - HH:mm',
  'mr-in': 'DD-MM-YYYY - HH:mm',
  'ms-bn': 'DD/MM/YYYY - HH:mm',
  'ms-my': 'DD/MM/YYYY - HH:mm',
  'mt-mt': 'DD/MM/YYYY - HH:mm',
  'nb-no': 'DD.MM.YYYY - HH:mm',
  'ne-np': 'M/D/YYYY - HH:mm',
  'nl-be': 'D/MM/YYYY - HH:mm',
  'nl-nl': 'D-M-YYYY - HH:mm',
  'nn-no': 'DD.MM.YYYY - HH:mm',
  'nso-za': 'YYYY/MM/DD - HH:mm',
  'oc-fr': 'DD/MM/YYYY - HH:mm',
  'or-in': 'DD-MM-YY - HH:mm',
  'pa-in': 'DD-MM-YY - HH:mm',
  'pl-pl': 'DD.MM.YYYY - HH:mm',
  'prs-af': 'DD/MM/YY - HH:mm',
  'ps-af': 'DD/MM/YY - HH:mm',
  'pt-br': 'D/M/YYYY - HH:mm',
  'pt-pt': 'DD-MM-YYYY - HH:mm',
  'qut-gt': 'DD/MM/YYYY - HH:mm',
  'quz-bo': 'DD/MM/YYYY - HH:mm',
  'quz-ec': 'DD/MM/YYYY - HH:mm',
  'quz-pe': 'DD/MM/YYYY - HH:mm',
  'rm-ch': 'DD/MM/YYYY - HH:mm',
  'ro-ro': 'DD.MM.YYYY - HH:mm',
  'ru-ru': 'DD.MM.YYYY - HH:mm',
  'rw-rw': 'M/D/YYYY - HH:mm',
  'sa-in': 'DD-MM-YYYY - HH:mm',
  'sah-ru': 'MM.DD.YYYY - HH:mm',
  'se-fi': 'D.M.YYYY - HH:mm',
  'se-no': 'DD.MM.YYYY - HH:mm',
  'se-se': 'YYYY-MM-DD - HH:mm',
  'si-lk': 'YYYY-MM-DD - HH:mm',
  'sk-sk': 'D. M . yyyy - HH:mm',
  'sl-si': 'D.M.YYYY - HH:mm',
  'sma-no': 'DD.MM.YYYY - HH:mm',
  'sma-se': 'YYYY-MM-DD - HH:mm',
  'smj-no': 'DD.MM.YYYY - HH:mm',
  'smj-se': 'YYYY-MM-DD - HH:mm',
  'smn-fi': 'D.M.YYYY - HH:mm',
  'sms-fi': 'D.M.YYYY - HH:mm',
  'sq-al': 'YYYY-MM-DD - HH:mm',
  'sr-cyrl-ba': 'D.M.YYYY - HH:mm',
  'sr-cyrl-cs': 'D.M.YYYY - HH:mm',
  'sr-cyrl-me': 'D.M.YYYY - HH:mm',
  'sr-cyrl-rs': 'D.M.YYYY - HH:mm',
  'sr-latn-ba': 'D.M.YYYY - HH:mm',
  'sr-latn-cs': 'D.M.YYYY - HH:mm',
  'sr-latn-me': 'D.M.YYYY - HH:mm',
  'sr-latn-rs': 'D.M.YYYY - HH:mm',
  'sv-fi': 'D.M.YYYY - HH:mm',
  'sv-se': 'YYYY-MM-DD - HH:mm',
  'sw-ke': 'M/D/YYYY - HH:mm',
  'syr-sy': 'DD/MM/YYYY - HH:mm',
  'ta-in': 'DD-MM-YYYY - HH:mm',
  'te-in': 'DD-MM-YY - HH:mm',
  'tg-cyrl-tj': 'DD.MM.YY - HH:mm',
  'th-th': 'D/M/YYYY - HH:mm',
  'tk-tm': 'DD.MM.YY - HH:mm',
  'tn-za': 'YYYY/MM/DD - HH:mm',
  'tr-tr': 'DD.MM.YYYY - HH:mm',
  'tt-ru': 'DD.MM.YYYY - HH:mm',
  'tzm-latn-DZ': 'DD-MM-YYYY - HH:mm',
  'ug-cn': 'YYYY-M-D - HH:mm',
  'uk-ua': 'MM/DD/YYYY - HH:mm',
  'ur-pk': 'DD/MM/YYYY - HH:mm',
  'uz-cyrl-uz': 'DD.MM.YYYY - HH:mm',
  'uz-latn-uz': 'DD/MM YYYY - HH:mm',
  'vi-vn': 'HH:mm - DD/MM/YYYY',
  'wo-sn': 'DD/MM/YYYY - HH:mm',
  'xh-za': 'YYYY/MM/DD - HH:mm',
  'yo-ng': 'D/M/YYYY - HH:mm',
  'zh-cn': 'YYYY/M/D - HH:mm',
  'zh-hk': 'D/M/YYYY - HH:mm',
  'zh-mo': 'D/M/YYYY - HH:mm',
  'zh-sg': 'D/M/YYYY - HH:mm',
  'zh-tw': 'YYYY/M/D - HH:mm',
  'zu-za': 'YYYY/MM/DD - HH:mm',

  af: 'YYYY/MM/DD - HH:mm',
  am: 'D/M/YYYY - HH:mm',
  ar: 'DD/MM/YYYY - HH:mm',
  arn: 'DD-MM-YYYY - HH:mm',
  as: 'DD-MM-YYYY - HH:mm',
  az: 'DD.MM.YYYY - HH:mm',
  ba: 'DD.MM.YY - HH:mm',
  be: 'DD.MM.YYYY - HH:mm',
  bg: 'DD.M.YYYY - HH:mm',
  bn: 'DD-MM-YY - HH:mm',
  bo: 'YYYY/M/D - HH:mm',
  br: 'DD/MM/YYYY - HH:mm',
  bs: 'D.M.YYYY - HH:mm',
  ca: 'DD/MM/YYYY - HH:mm',
  co: 'DD/MM/YYYY - HH:mm',
  cs: 'D.M.YYYY - HH:mm',
  cy: 'DD/MM/YYYY - HH:mm',
  da: 'DD-MM-YYYY - HH:mm',
  de: 'DD.MM.YYYY - HH:mm',
  dsb: 'D. M . YYYY - HH:mm',
  dv: 'DD/MM/YY - HH:mm',
  el: 'D/M/YYYY - HH:mm',
  en: 'MM/DD/YYYY - HH:mm',
  es: 'DD/MM/YYYY - HH:mm',
  et: 'D.MM.YYYY - HH:mm',
  eu: 'YYYY/MM/DD - HH:mm',
  fa: 'MM/DD/YYYY - HH:mm',
  fi: 'D.M.YYYY - HH:mm',
  fil: 'M/D/YYYY - HH:mm',
  fo: 'DD-MM-YYYY - HH:mm',
  fr: 'D/MM/YYYY - HH:mm',
  fy: 'D-M-YYYY - HH:mm',
  ga: 'DD/MM/YYYY - HH:mm',
  gd: 'DD/MM/YYYY - HH:mm',
  gl: 'DD/MM/YY - HH:mm',
  gsw: 'DD/MM/YYYY - HH:mm',
  gu: 'DD-MM-YY - HH:mm',
  ha: 'D/M/YYYY - HH:mm',
  he: 'DD/MM/YYYY - HH:mm',
  hi: 'DD-MM-YYYY - HH:mm',
  hr: 'D.M.YYYY - HH:mm.',
  hsb: 'D. M . YYYY - HH:mm',
  hu: 'YYYY. MM . dd. - HH:mm',
  hy: 'DD.MM.YYYY - HH:mm',
  id: 'DD/MM/YYYY - HH:mm',
  ig: 'D/M/YYYY - HH:mm',
  ii: 'YYYY/M/D - HH:mm',
  is: 'D.M.YYYY - HH:mm',
  it: 'DD.MM.YYYY - HH:mm',
  iu: 'D/M/YYYY - HH:mm',
  ja: 'YYYY/MM/DD - HH:mm',
  ka: 'DD.MM.YYYY - HH:mm',
  kk: 'DD.MM.YYYY - HH:mm',
  kl: 'DD-MM-YYYY - HH:mm',
  km: 'YYYY-MM-DD - HH:mm',
  kn: 'DD-MM-YY - HH:mm',
  ko: 'MM/DD/YYYY - HH:mm',
  kok: 'DD-MM-YYYY - HH:mm',
  ky: 'DD.MM.YY - HH:mm',
  lb: 'DD/MM/YYYY - HH:mm',
  lo: 'DD/MM/YYYY - HH:mm',
  lt: 'YYYY.MM.DD - HH:mm',
  lv: 'YYYY.MM.DD - HH:mm.',
  mi: 'DD/MM/YYYY - HH:mm',
  mk: 'DD.MM.YYYY - HH:mm',
  ml: 'DD-MM-YY - HH:mm',
  mn: 'YY.MM.DD - HH:mm',
  moh: 'M/D/YYYY - HH:mm',
  mr: 'DD-MM-YYYY - HH:mm',
  ms: 'DD/MM/YYYY - HH:mm',
  mt: 'DD/MM/YYYY - HH:mm',
  nb: 'DD.MM.YYYY - HH:mm',
  ne: 'M/D/YYYY - HH:mm',
  nl: 'D/MM/YYYY - HH:mm',
  nn: 'DD.MM.YYYY - HH:mm',
  nso: 'YYYY/MM/DD - HH:mm',
  oc: 'DD/MM/YYYY - HH:mm',
  or: 'DD-MM-YY - HH:mm',
  pa: 'DD-MM-YY - HH:mm',
  pl: 'DD.MM.YYYY - HH:mm',
  prs: 'DD/MM/YY - HH:mm',
  ps: 'DD/MM/YY - HH:mm',
  pt: 'D/M/YYYY - HH:mm',
  qut: 'DD/MM/YYYY - HH:mm',
  quz: 'DD/MM/YYYY - HH:mm',
  rm: 'DD/MM/YYYY - HH:mm',
  ro: 'DD.MM.YYYY - HH:mm',
  ru: 'DD.MM.YYYY - HH:mm',
  rw: 'M/D/YYYY - HH:mm',
  sa: 'DD-MM-YYYY - HH:mm',
  sah: 'MM.DD.YYYY - HH:mm',
  se: 'D.M.YYYY - HH:mm',
  si: 'YYYY-MM-DD - HH:mm',
  sk: 'D. M . YYYY - HH:mm',
  sl: 'D.M.YYYY - HH:mm',
  sma: 'DD.MM.YYYY - HH:mm',
  smj: 'DD.MM.YYYY - HH:mm',
  smn: 'D.M.YYYY - HH:mm',
  sms: 'D.M.YYYY - HH:mm',
  sq: 'YYYY-MM-DD - HH:mm',
  sr: 'D.M.YYYY - HH:mm',
  sv: 'D.M.YYYY - HH:mm',
  sw: 'M/D/YYYY - HH:mm',
  syr: 'DD/MM/YYYY - HH:mm',
  ta: 'DD-MM-YYYY - HH:mm',
  te: 'DD-MM-YY - HH:mm',
  tg: 'DD.MM.YY - HH:mm',
  th: 'D/M/YYYY - HH:mm',
  tk: 'DD.MM.YY - HH:mm',
  tn: 'YYYY/MM/DD - HH:mm',
  tr: 'DD.MM.YYYY - HH:mm',
  tt: 'DD.MM.YYYY - HH:mm',
  tzm: 'DD-MM-YYYY - HH:mm',
  ug: 'YYYY-M-D - HH:mm',
  uk: 'MM/DD/YYYY - HH:mm',
  ur: 'DD/MM/YYYY - HH:mm',
  uz: 'DD.MM.YYYY - HH:mm',
  vi: 'HH:mm - DD/MM/YYYY',
  wo: 'DD/MM/YYYY - HH:mm',
  xh: 'YYYY/MM/DD - HH:mm',
  yo: 'D/M/YYYY - HH:mm',
  zh: 'YYYY/M/D - HH:mm',
  zu: 'YYYY/MM/DD - HH:mm',
};

export const LOCALE_FORMAT_MONTH = {
  'af-za': 'YYYY/MM',
  'am-et': 'M/YYYY',
  'ar-ae': 'MM/YYYY',
  'ar-bh': 'MM/YYYY',
  'ar-dz': 'MM-YYYY',
  'ar-eg': 'MM/YYYY',
  'ar-iq': 'MM/YYYY',
  'ar-jo': 'MM/YYYY',
  'ar-kw': 'MM/YYYY',
  'ar-lb': 'MM/YYYY',
  'ar-ly': 'MM/YYYY',
  'ar-ma': 'MM-YYYY',
  'ar-om': 'MM/YYYY',
  'ar-qa': 'MM/YYYY',
  'ar-sa': 'MM/YY',
  'ar-sy': 'MM/YYYY',
  'ar-tn': 'MM-YYYY',
  'ar-ye': 'MM/YYYY',
  'arn-cl': 'MM-YYYY',
  'as-in': 'MM-YYYY',
  'az-cyrl-az': 'MM.YYYY',
  'az-latn-az': 'MM.YYYY',
  'ba-ru': 'MM.YY',
  'be-by': 'MM.YYYY',
  'bg-bg': 'M.YYYY',
  'bn-bd': 'MM-YY',
  'bn-in': 'MM-YY',
  'bo-cn': 'YYYY/M',
  'br-fr': 'MM/YYYY',
  'bs-cyrl-ba': 'M.YYYY',
  'bs-latn-ba': 'M.YYYY',
  'ca-es': 'MM/YYYY',
  'co-fr': 'MM/YYYY',
  'cs-cz': 'M.YYYY',
  'cy-gb': 'MM/YYYY',
  'da-dk': 'MM-YYYY',
  'de-at': 'MM.YYYY',
  'de-ch': 'MM.YYYY',
  'de-de': 'MM.YYYY',
  'de-li': 'MM.YYYY',
  'de-lu': 'MM.YYYY',
  'dsb-de': 'M . yyyy',
  'dv-mv': 'MM/YY',
  'el-gr': 'M/YYYY',
  'en-029': 'MM/YYYY',
  'en-au': 'MM/YYYY',
  'en-bz': 'MM/YYYY',
  'en-ca': 'MM/YYYY',
  'en-gb': 'MM/YYYY',
  'en-ie': 'MM/YYYY',
  'en-in': 'MM-YYYY',
  'en-jm': 'MM/YYYY',
  'en-my': 'M/YYYY',
  'en-nz': 'MM/YYYY',
  'en-ph': 'M/YYYY',
  'en-sg': 'M/YYYY',
  'en-tt': 'MM/YYYY',
  'en-us': 'MM/YYYY',
  'en-za': 'YYYY/MM',
  'en-zw': 'M/YYYY',
  'es-ar': 'MM/YYYY',
  'es-bo': 'MM/YYYY',
  'es-cl': 'MM-YYYY',
  'es-co': 'MM/YYYY',
  'es-cr': 'MM/YYYY',
  'es-do': 'MM/YYYY',
  'es-ec': 'MM/YYYY',
  'es-es': 'MM/YYYY',
  'es-gt': 'MM/YYYY',
  'es-hn': 'MM/YYYY',
  'es-mx': 'MM/YYYY',
  'es-ni': 'MM/YYYY',
  'es-pa': 'MM/YYYY',
  'es-pe': 'MM/YYYY',
  'es-pr': 'MM/YYYY',
  'es-py': 'MM/YYYY',
  'es-sv': 'MM/YYYY',
  'es-us': 'MM/YYYY',
  'es-uy': 'MM/YYYY',
  'es-ve': 'MM/YYYY',
  'et-ee': 'MM.YYYY',
  'eu-es': 'YYYY/MM',
  'fa-ir': 'MM/YYYY',
  'fi-fi': 'M.YYYY',
  'fil-ph': 'M/YYYY',
  'fo-fo': 'MM-YYYY',
  'fr-be': 'MM/YYYY',
  'fr-ca': 'YYYY-MM',
  'fr-ch': 'MM.YYYY',
  'fr-fr': 'MM/YYYY',
  'fr-lu': 'MM/YYYY',
  'fr-mc': 'MM/YYYY',
  'fy-nl': 'M-YYYY',
  'ga-ie': 'MM/YYYY',
  'gd-gb': 'MM/YYYY',
  'gl-es': 'MM/YY',
  'gsw-fr': 'MM/YYYY',
  'gu-in': 'MM-YY',
  'ha-latn-ng': 'M/YYYY',
  'he-il': 'MM/YYYY',
  'hi-in': 'MM-YYYY',
  'hr-ba': 'M.YYYY.',
  'hr-hr': 'M.YYYY',
  'hsb-de': ' M . YYYY',
  'hu-hu': 'YYYY. MM',
  'hy-am': 'MM.YYYY',
  'id-id': 'MM/YYYY',
  'ig-ng': 'M/YYYY',
  'ii-cn': 'YYYY/M',
  'is-is': 'M.YYYY',
  'it-ch': 'MM.YYYY',
  'it-it': 'MM/YYYY',
  'iu-cans-ca': 'M/YYYY',
  'iu-latn-ca': 'MM/YYYY',
  'ja-jp': 'YYYY/MM',
  'ka-ge': 'MM.YYYY',
  'kk-kz': 'MM.YYYY',
  'kl-gl': 'MM-YYYY',
  'km-kh': 'YYYY-MM',
  'kn-in': 'MM-YY',
  'ko-kr': 'MM/YYYY',
  'kok-in': 'MM-YYYY',
  'ky-kg': 'MM.YY',
  'lb-lu': 'MM/YYYY',
  'lo-la': 'MM/YYYY',
  'lt-lt': 'YYYY.MM',
  'lv-lv': 'YYYY.MM.',
  'mi-nz': 'MM/YYYY',
  'mk-mk': 'MM.YYYY',
  'ml-in': 'MM-YY',
  'mn-mn': 'YY.MM',
  'mn-mong-cn': 'YYYY/M',
  'moh-ca': 'M/YYYY',
  'mr-in': 'MM-YYYY',
  'ms-bn': 'MM/YYYY',
  'ms-my': 'MM/YYYY',
  'mt-mt': 'MM/YYYY',
  'nb-no': 'MM.YYYY',
  'ne-np': 'M/YYYY',
  'nl-be': 'MM/YYYY',
  'nl-nl': 'M-YYYY',
  'nn-no': 'MM.YYYY',
  'nso-za': 'YYYY/MM',
  'oc-fr': 'MM/YYYY',
  'or-in': 'MM-YY',
  'pa-in': 'MM-YY',
  'pl-pl': 'MM.YYYY',
  'prs-af': 'MM/YY',
  'ps-af': 'MM/YY',
  'pt-br': 'M/YYYY',
  'pt-pt': 'MM-YYYY',
  'qut-gt': 'MM/YYYY',
  'quz-bo': 'MM/YYYY',
  'quz-ec': 'MM/YYYY',
  'quz-pe': 'MM/YYYY',
  'rm-ch': 'MM/YYYY',
  'ro-ro': 'MM.YYYY',
  'ru-ru': 'MM.YYYY',
  'rw-rw': 'M/YYYY',
  'sa-in': 'MM-YYYY',
  'sah-ru': 'MM.YYYY',
  'se-fi': 'M.YYYY',
  'se-no': 'MM.YYYY',
  'se-se': 'YYYY-MM',
  'si-lk': 'YYYY-MM',
  'sk-sk': ' M . yyyy',
  'sl-si': 'M.YYYY',
  'sma-no': 'MM.YYYY',
  'sma-se': 'YYYY-MM',
  'smj-no': 'MM.YYYY',
  'smj-se': 'YYYY-MM',
  'smn-fi': 'M.YYYY',
  'sms-fi': 'M.YYYY',
  'sq-al': 'YYYY-MM',
  'sr-cyrl-ba': 'M.YYYY',
  'sr-cyrl-cs': 'M.YYYY',
  'sr-cyrl-me': 'M.YYYY',
  'sr-cyrl-rs': 'M.YYYY',
  'sr-latn-ba': 'M.YYYY',
  'sr-latn-cs': 'M.YYYY',
  'sr-latn-me': 'M.YYYY',
  'sr-latn-rs': 'M.YYYY',
  'sv-fi': 'M.YYYY',
  'sv-se': 'YYYY-MM',
  'sw-ke': 'M/YYYY',
  'syr-sy': 'MM/YYYY',
  'ta-in': 'MM-YYYY',
  'te-in': 'MM-YY',
  'tg-cyrl-tj': 'MM.YY',
  'th-th': 'M/YYYY',
  'tk-tm': 'MM.YY',
  'tn-za': 'YYYY/MM',
  'tr-tr': 'MM.YYYY',
  'tt-ru': 'MM.YYYY',
  'tzm-latn-DZ': 'MM-YYYY',
  'ug-cn': 'YYYY-M',
  'uk-ua': 'MM/YYYY',
  'ur-pk': 'MM/YYYY',
  'uz-cyrl-uz': 'MM.YYYY',
  'uz-latn-uz': 'MM YYYY',
  'vi-vn': 'HH:mm - MM/YYYY',
  'wo-sn': 'MM/YYYY',
  'xh-za': 'YYYY/MM',
  'yo-ng': 'M/YYYY',
  'zh-cn': 'YYYY/M',
  'zh-hk': 'M/YYYY',
  'zh-mo': 'M/YYYY',
  'zh-sg': 'M/YYYY',
  'zh-tw': 'YYYY/M',
  'zu-za': 'YYYY/MM',

  af: 'YYYY/MM',
  am: 'M/YYYY',
  ar: 'MM/YYYY',
  arn: 'MM-YYYY',
  as: 'MM-YYYY',
  az: 'MM.YYYY',
  ba: 'MM.YY',
  be: 'MM.YYYY',
  bg: 'M.YYYY',
  bn: 'MM-YY',
  bo: 'YYYY/M',
  br: 'MM/YYYY',
  bs: 'M.YYYY',
  ca: 'MM/YYYY',
  co: 'MM/YYYY',
  cs: 'M.YYYY',
  cy: 'MM/YYYY',
  da: 'MM-YYYY',
  de: 'MM.YYYY',
  dsb: ' M . YYYY',
  dv: 'MM/YY',
  el: 'M/YYYY',
  en: 'MM/YYYY',
  es: 'MM/YYYY',
  et: 'MM.YYYY',
  eu: 'YYYY/MM',
  fa: 'MM/YYYY',
  fi: 'M.YYYY',
  fil: 'M/YYYY',
  fo: 'MM-YYYY',
  fr: 'MM/YYYY',
  fy: 'M-YYYY',
  ga: 'MM/YYYY',
  gd: 'MM/YYYY',
  gl: 'MM/YY',
  gsw: 'MM/YYYY',
  gu: 'MM-YY',
  ha: 'M/YYYY',
  he: 'MM/YYYY',
  hi: 'MM-YYYY',
  hr: 'M.YYYY.',
  hsb: ' M . YYYY',
  hu: 'YYYY. MM.',
  hy: 'MM.YYYY',
  id: 'MM/YYYY',
  ig: 'M/YYYY',
  ii: 'YYYY/M',
  is: 'M.YYYY',
  it: 'MM.YYYY',
  iu: 'M/YYYY',
  ja: 'YYYY/MM',
  ka: 'MM.YYYY',
  kk: 'MM.YYYY',
  kl: 'MM-YYYY',
  km: 'YYYY-MM',
  kn: 'MM-YY',
  ko: 'MM/YYYY',
  kok: 'MM-YYYY',
  ky: 'MM.YY',
  lb: 'MM/YYYY',
  lo: 'MM/YYYY',
  lt: 'YYYY.MM',
  lv: 'YYYY.MM.',
  mi: 'MM/YYYY',
  mk: 'MM.YYYY',
  ml: 'MM-YY',
  mn: 'YY.MM',
  moh: 'M/YYYY',
  mr: 'MM-YYYY',
  ms: 'MM/YYYY',
  mt: 'MM/YYYY',
  nb: 'MM.YYYY',
  ne: 'M/YYYY',
  nl: 'MM/YYYY',
  nn: 'MM.YYYY',
  nso: 'YYYY/MM',
  oc: 'MM/YYYY',
  or: 'MM-YY',
  pa: 'MM-YY',
  pl: 'MM.YYYY',
  prs: 'MM/YY',
  ps: 'MM/YY',
  pt: 'M/YYYY',
  qut: 'MM/YYYY',
  quz: 'MM/YYYY',
  rm: 'MM/YYYY',
  ro: 'MM.YYYY',
  ru: 'MM.YYYY',
  rw: 'M/YYYY',
  sa: 'MM-YYYY',
  sah: 'MM.YYYY',
  se: 'M.YYYY',
  si: 'YYYY-MM',
  sk: ' M . YYYY',
  sl: 'M.YYYY',
  sma: 'MM.YYYY',
  smj: 'MM.YYYY',
  smn: 'M.YYYY',
  sms: 'M.YYYY',
  sq: 'YYYY-MM',
  sr: 'M.YYYY',
  sv: 'M.YYYY',
  sw: 'M/YYYY',
  syr: 'MM/YYYY',
  ta: 'MM-YYYY',
  te: 'MM-YY',
  tg: 'MM.YY',
  th: 'M/YYYY',
  tk: 'MM.YY',
  tn: 'YYYY/MM',
  tr: 'MM.YYYY',
  tt: 'MM.YYYY',
  tzm: 'MM-YYYY',
  ug: 'YYYY-M',
  uk: 'MM/YYYY',
  ur: 'MM/YYYY',
  uz: 'MM.YYYY',
  vi: 'HH:mm - MM/YYYY',
  wo: 'MM/YYYY',
  xh: 'YYYY/MM',
  yo: 'M/YYYY',
  zh: 'YYYY/M',
  zu: 'YYYY/MM',
};

export const LOCALE_FORMAT_DATEMONTH = {
  'af-za': 'MM/DD',
  'am-et': 'D/M',
  'ar-ae': 'DD/MM',
  'ar-bh': 'DD/MM',
  'ar-dz': 'DD-MM',
  'ar-eg': 'DD/MM',
  'ar-iq': 'DD/MM',
  'ar-jo': 'DD/MM',
  'ar-kw': 'DD/MM',
  'ar-lb': 'DD/MM',
  'ar-ly': 'DD/MM',
  'ar-ma': 'DD-MM',
  'ar-om': 'DD/MM',
  'ar-qa': 'DD/MM',
  'ar-sa': 'DD/MM',
  'ar-sy': 'DD/MM',
  'ar-tn': 'DD-MM',
  'ar-ye': 'DD/MM',
  'arn-cl': 'DD-MM',
  'as-in': 'DD-MM',
  'az-cyrl-az': 'DD.MM',
  'az-latn-az': 'DD.MM',
  'ba-ru': 'DD.MM',
  'be-by': 'DD.MM',
  'bg-bg': 'DD.M',
  'bn-bd': 'DD-MM',
  'bn-in': 'DD-MM',
  'bo-cn': 'M/D',
  'br-fr': 'DD/MM',
  'bs-cyrl-ba': 'D.M',
  'bs-latn-ba': 'D.M',
  'ca-es': 'DD/MM',
  'co-fr': 'DD/MM',
  'cs-cz': 'D.M',
  'cy-gb': 'DD/MM',
  'da-dk': 'DD-MM',
  'de-at': 'DD.MM',
  'de-ch': 'DD.MM',
  'de-de': 'DD.MM',
  'de-li': 'DD.MM',
  'de-lu': 'DD.MM',
  'dsb-de': 'D. M',
  'dv-mv': 'DD/MM',
  'el-gr': 'D/M',
  'en-029': 'MM/DD',
  'en-au': 'D/MM',
  'en-bz': 'DD/MM',
  'en-ca': 'DD/MM',
  'en-gb': 'DD/MM',
  'en-ie': 'DD/MM',
  'en-in': 'DD-MM',
  'en-jm': 'DD/MM',
  'en-my': 'D/M',
  'en-nz': 'D/MM',
  'en-ph': 'M/D',
  'en-sg': 'D/M',
  'en-tt': 'DD/MM',
  'en-us': 'MM/DD',
  'en-za': 'MM/DD',
  'en-zw': 'M/D',
  'es-ar': 'DD/MM',
  'es-bo': 'DD/MM',
  'es-cl': 'DD-MM',
  'es-co': 'DD/MM',
  'es-cr': 'DD/MM',
  'es-do': 'DD/MM',
  'es-ec': 'DD/MM',
  'es-es': 'DD/MM',
  'es-gt': 'DD/MM',
  'es-hn': 'DD/MM',
  'es-mx': 'DD/MM',
  'es-ni': 'DD/MM',
  'es-pa': 'MM/DD',
  'es-pe': 'DD/MM',
  'es-pr': 'DD/MM',
  'es-py': 'DD/MM',
  'es-sv': 'DD/MM',
  'es-us': 'MM/DD',
  'es-uy': 'DD/MM',
  'es-ve': 'DD/MM',
  'et-ee': 'D.MM',
  'eu-es': 'MM/DD',
  'fa-ir': 'MM/DD',
  'fi-fi': 'D.M',
  'fil-ph': 'M/D',
  'fo-fo': 'DD-MM',
  'fr-be': 'D/MM',
  'fr-ca': 'MM-DD',
  'fr-ch': 'DD.MM',
  'fr-fr': 'DD/MM',
  'fr-lu': 'DD/MM',
  'fr-mc': 'DD/MM',
  'fy-nl': 'D-M',
  'ga-ie': 'DD/MM',
  'gd-gb': 'DD/MM',
  'gl-es': 'DD/MM',
  'gsw-fr': 'DD/MM',
  'gu-in': 'DD-MM',
  'ha-latn-ng': 'D/M',
  'he-il': 'DD/MM',
  'hi-in': 'DD-MM',
  'hr-ba': 'D.M.',
  'hr-hr': 'D.M',
  'hsb-de': 'D. M',
  'hu-hu': ' MM. dd.',
  'hy-am': 'DD.MM',
  'id-id': 'DD/MM',
  'ig-ng': 'D/M',
  'ii-cn': 'M/D',
  'is-is': 'D.M',
  'it-ch': 'DD.MM',
  'it-it': 'DD/MM',
  'iu-cans-ca': 'D/M',
  'iu-latn-ca': 'D/MM',
  'ja-jp': 'MM/DD',
  'ka-ge': 'DD.MM',
  'kk-kz': 'DD.MM',
  'kl-gl': 'DD-MM',
  'km-kh': 'MM-DD',
  'kn-in': 'DD-MM',
  'ko-kr': 'MM/DD',
  'kok-in': 'DD-MM',
  'ky-kg': 'DD.MM',
  'lb-lu': 'DD/MM',
  'lo-la': 'DD/MM',
  'lt-lt': 'MM.DD',
  'lv-lv': 'MM.DD.',
  'mi-nz': 'DD/MM',
  'mk-mk': 'DD.MM',
  'ml-in': 'DD-MM',
  'mn-mn': 'MM.DD',
  'mn-mong-cn': 'M/D',
  'moh-ca': 'M/D',
  'mr-in': 'DD-MM',
  'ms-bn': 'DD/MM',
  'ms-my': 'DD/MM',
  'mt-mt': 'DD/MM',
  'nb-no': 'DD.MM',
  'ne-np': 'M/D',
  'nl-be': 'D/MM',
  'nl-nl': 'D-M',
  'nn-no': 'DD.MM',
  'nso-za': 'MM/DD',
  'oc-fr': 'DD/MM',
  'or-in': 'DD-MM',
  'pa-in': 'DD-MM',
  'pl-pl': 'DD.MM',
  'prs-af': 'DD/MM',
  'ps-af': 'DD/MM',
  'pt-br': 'D/M',
  'pt-pt': 'DD-MM',
  'qut-gt': 'DD/MM',
  'quz-bo': 'DD/MM',
  'quz-ec': 'DD/MM',
  'quz-pe': 'DD/MM',
  'rm-ch': 'DD/MM',
  'ro-ro': 'DD.MM',
  'ru-ru': 'DD.MM',
  'rw-rw': 'M/D',
  'sa-in': 'DD-MM',
  'sah-ru': 'MM.DD',
  'se-fi': 'D.M',
  'se-no': 'DD.MM',
  'se-se': 'MM-DD',
  'si-lk': 'MM-DD',
  'sk-sk': 'D. M',
  'sl-si': 'D.M',
  'sma-no': 'DD.MM',
  'sma-se': 'MM-DD',
  'smj-no': 'DD.MM',
  'smj-se': 'MM-DD',
  'smn-fi': 'D.M',
  'sms-fi': 'D.M',
  'sq-al': 'MM-DD',
  'sr-cyrl-ba': 'D.M',
  'sr-cyrl-cs': 'D.M',
  'sr-cyrl-me': 'D.M',
  'sr-cyrl-rs': 'D.M',
  'sr-latn-ba': 'D.M',
  'sr-latn-cs': 'D.M',
  'sr-latn-me': 'D.M',
  'sr-latn-rs': 'D.M',
  'sv-fi': 'D.M',
  'sv-se': 'MM-DD',
  'sw-ke': 'M/D',
  'syr-sy': 'DD/MM',
  'ta-in': 'DD-MM',
  'te-in': 'DD-MM',
  'tg-cyrl-tj': 'DD.MM',
  'th-th': 'D/M',
  'tk-tm': 'DD.MM',
  'tn-za': 'MM/DD',
  'tr-tr': 'DD.MM',
  'tt-ru': 'DD.MM',
  'tzm-latn-DZ': 'DD-MM',
  'ug-cn': 'M-D',
  'uk-ua': 'MM/DD',
  'ur-pk': 'DD/MM',
  'uz-cyrl-uz': 'DD.MM',
  'uz-latn-uz': 'DD/MM',
  'vi-vn': 'DD/MM',
  'wo-sn': 'DD/MM',
  'xh-za': 'MM/DD',
  'yo-ng': 'D/M',
  'zh-cn': 'M/D',
  'zh-hk': 'D/M',
  'zh-mo': 'D/M',
  'zh-sg': 'D/M',
  'zh-tw': 'M/D',
  'zu-za': 'MM/DD',

  af: 'MM/DD',
  am: 'D/M',
  ar: 'DD/MM',
  arn: 'DD-MM',
  as: 'DD-MM',
  az: 'DD.MM',
  ba: 'DD.MM',
  be: 'DD.MM',
  bg: 'DD.M',
  bn: 'DD-MM',
  bo: 'M/D',
  br: 'DD/MM',
  bs: 'D.M',
  ca: 'DD/MM',
  co: 'DD/MM',
  cs: 'D.M',
  cy: 'DD/MM',
  da: 'DD-MM',
  de: 'DD.MM',
  dsb: 'D. M',
  dv: 'DD/MM',
  el: 'D/M',
  en: 'MM/DD',
  es: 'DD/MM',
  et: 'D.MM',
  eu: 'MM/DD',
  fa: 'MM/DD',
  fi: 'D.M',
  fil: 'M/D',
  fo: 'DD-MM',
  fr: 'D/MM',
  fy: 'D-M',
  ga: 'DD/MM',
  gd: 'DD/MM',
  gl: 'DD/MM',
  gsw: 'DD/MM',
  gu: 'DD-MM',
  ha: 'D/M',
  he: 'DD/MM',
  hi: 'DD-MM',
  hr: 'D.M.',
  hsb: 'D. M',
  hu: 'MM. dd.',
  hy: 'DD.MM',
  id: 'DD/MM',
  ig: 'D/M',
  ii: 'M/D',
  is: 'D.M',
  it: 'DD.MM',
  iu: 'D/M',
  ja: 'MM/DD',
  ka: 'DD.MM',
  kk: 'DD.MM',
  kl: 'DD-MM',
  km: 'MM-DD',
  kn: 'DD-MM',
  ko: 'MM/DD',
  kok: 'DD-MM',
  ky: 'DD.MM',
  lb: 'DD/MM',
  lo: 'DD/MM',
  lt: 'MM.DD',
  lv: 'MM.DD.',
  mi: 'DD/MM',
  mk: 'DD.MM',
  ml: 'DD-MM',
  mn: 'MM.DD',
  moh: 'M/D',
  mr: 'DD-MM',
  ms: 'DD/MM',
  mt: 'DD/MM',
  nb: 'DD.MM',
  ne: 'M/D',
  nl: 'D/MM',
  nn: 'DD.MM',
  nso: 'MM/DD',
  oc: 'DD/MM',
  or: 'DD-MM',
  pa: 'DD-MM',
  pl: 'DD.MM',
  prs: 'DD/MM',
  ps: 'DD/MM',
  pt: 'D/M',
  qut: 'DD/MM',
  quz: 'DD/MM',
  rm: 'DD/MM',
  ro: 'DD.MM',
  ru: 'DD.MM',
  rw: 'M/D',
  sa: 'DD-MM',
  sah: 'MM.DD',
  se: 'D.M',
  si: 'MM-DD',
  sk: 'D. M',
  sl: 'D.M',
  sma: 'DD.MM',
  smj: 'DD.MM',
  smn: 'D.M',
  sms: 'D.M',
  sq: 'MM-DD',
  sr: 'D.M',
  sv: 'D.M',
  sw: 'M/D',
  syr: 'DD/MM',
  ta: 'DD-MM',
  te: 'DD-MM',
  tg: 'DD.MM',
  th: 'D/M',
  tk: 'DD.MM',
  tn: 'MM/DD',
  tr: 'DD.MM',
  tt: 'DD.MM',
  tzm: 'DD-MM',
  ug: 'M-D',
  uk: 'MM/DD',
  ur: 'DD/MM',
  uz: 'DD.MM',
  vi: 'DD/MM',
  wo: 'DD/MM',
  xh: 'MM/DD',
  yo: 'D/M',
  zh: 'M/D',
  zu: 'MM/DD',
};

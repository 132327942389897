import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import roleApi from 'api/roleApi';
import { RootState } from 'app/store';

export const getRoleList = createAsyncThunk(
  'role/getList',
  async ({ page, limit, filters }: any) => {
    const roleList: any = await roleApi.getList({
      page,
      limit,
      ...filters,
    });
    return roleList;
  },
);

const role = createSlice({
  name: 'role',
  initialState: {
    isRequesting: false,
    error: null,
    data: [],
    total: 0,
    searchCriteria: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRoleList.pending, (state, action: any) => {
        state.isRequesting = true;
        state.error = null;
        state.searchCriteria = { ...action.payload };
      })
      .addCase(getRoleList.fulfilled, (state, action) => {
        state.isRequesting = false;
        state.error = null;
        state.data = action.payload.data.result;
        state.total = action.payload.data.total;
      })
      .addCase(getRoleList.rejected, (state, action: any) => {
        state.isRequesting = false;
        state.error = action.payload;
        state.data = [];
      });
  },
});

export const selectRole = (state: RootState) => state.role;

export const { reducer, actions } = role;

export default reducer;

import {
  InputField,
  RangePickerField,
  RichTextField,
  SelectField,
  TextAreaField,
} from 'components/FormFields';
import { useForm } from 'react-hook-form';
import CustomButton from 'styles/buttonStyled';
import { VIEW_SCREEN } from './CreateJob';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useEffect, useState } from 'react';
import { Radio, Row, Col, Select, InputNumber, Form } from 'antd';
import { JobHirringProcess } from './types';
import { selectRecruitmentData } from 'features/recruitment/recruitmentSlice';
import { listJobTypes } from 'features/recruitment/recruitmentAction';
import moment from 'moment';
import payrollDashboardApi from 'api/payrollDashboardApi';
import { useTranslation } from 'react-i18next';

enum VIEW_SCREEN_TEMPLATE {
  CREATE_JOB = 'CREATE_JOB',
  HIRING_PROCESS = 'HIRING_PROCESS',
}

interface IItemInput {
  label: string;
  name: string;
  options?: Array<any>;
  fieldType: 'select' | 'input' | 'rangePicker' | 'richText' | 'salary';
}

const CompleteForm = ({
  setVisible,
  setViewScreen,
  callBack,
}: {
  setVisible: any;
  setViewScreen: any;
  callBack: any;
}) => {
  const dispatch = useAppDispatch();
  const jobHirringProcess: { result: JobHirringProcess[] } =
    useAppSelector(selectRecruitmentData)?.jobHirringProcess;
  const listJobTypesData = useAppSelector(selectRecruitmentData)?.listJobTypes?.result;

  const [viewScreenTempl, setViewScreenTempl] = useState<VIEW_SCREEN_TEMPLATE>(
    VIEW_SCREEN_TEMPLATE.CREATE_JOB,
  );
  const [selectedHirringProcess, setSelectedHirringProcess] = useState<string>('');
  const [location, setLocation] = useState([]);
  const [department, setDepartment] = useState([]);
  const [salaryCurrency, setSalaryCurrency] = useState('VND');

  const { t } = useTranslation([
    'validation',
    'masterConfig',
    'setup_menu_screen',
    'insurance',
    'common',
    'overtime',
    'employee',
    'menu',
    'recruitment',
    'action',
  ]);

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    resolver: undefined,
  });

  const [form] = Form.useForm();

  useEffect(() => {
    const fetchData = async () => {
      const responseDataLocation = await payrollDashboardApi.getLocation();
      setLocation(responseDataLocation?.data?.result || []);
      const responseDataProject = await payrollDashboardApi.getProject();
      setDepartment(responseDataProject?.data?.result || []);
    };
    fetchData();
    dispatch(listJobTypes({}));
  }, [dispatch]);

  const onClickCreate = () => {
    form.validateFields().then((values) => {
      setViewScreenTempl(VIEW_SCREEN_TEMPLATE.HIRING_PROCESS);
    });
  };

  const onSubmit = () => {
    const formData = getValues();
    const jobType = listJobTypesData?.find((i: any) => i?.id === formData?.jobType);
    const formatData: any = { ...formData, jobType };
    const { date, ...rest } = formatData;
    const data = {
      selectedHirringProcess,
      jobHirringProcess: jobHirringProcess.result.find((i) => i.id === selectedHirringProcess),
      jobTemplates: {
        ...rest,
        minimumSalary: rest?.minimumSalary,
        maximumSalary: rest?.maximumSalary,
        currencySymbol: rest?.currencySymbol || 'VND',
      },
      openDate: formData?.date?.[0] ? moment(formData?.date?.[0]).format() : undefined,
      closeDate: formData?.date?.[1] ? moment(formData?.date?.[1]).format() : undefined,
    };
    callBack && callBack(data);
  };

  const itemsInputLeft: Array<IItemInput> = [
    {
      label: t('recruitment:position_name'),
      name: 'positionName',
      fieldType: 'input',
    },
    {
      label: t('recruitment:location'),
      name: 'locationId',
      fieldType: 'select',
      options: location?.map((i: any) => ({ label: i?.name, value: i?.id })),
    },
    {
      label: t('recruitment:department'),
      name: 'departmentId',
      fieldType: 'select',
      options: department?.map((i: any) => ({ label: i?.name, value: i?.projectCode })),
    },
    {
      label: t('recruitment:headcount'),
      name: 'headcount',
      fieldType: 'input',
    },
  ];

  const itemsInputRight: Array<IItemInput> = [
    {
      label: t('recruitment:date_range'),
      name: 'date',
      fieldType: 'rangePicker',
    },
    {
      label: t('recruitment:job_type'),
      name: 'jobType',
      fieldType: 'select',
      options: listJobTypesData?.map((i: any) => ({ label: i?.name, value: i?.id })),
    },
    {
      label: t('recruitment:minimum_salary'),
      name: 'minimumSalary',
      fieldType: 'salary',
    },
    {
      label: t('recruitment:maximum_salary'),
      name: 'maximumSalary',
      fieldType: 'salary',
    },
  ];

  const renderItemEdit = (
    fieldType: 'select' | 'input' | 'rangePicker' | 'richText' | 'salary',
    item: IItemInput,
  ) => {
    switch (fieldType) {
      case 'select':
        return (
          <SelectField
            control={control}
            options={item?.options || []}
            name={item.name}
            style={{ width: '100%' }}
          />
        );
      case 'input':
        return <InputField control={control} name={item.name} style={{ width: '100%' }} />;
      case 'rangePicker':
        return <RangePickerField style={{ width: '100%' }} control={control} name={item.name} />;
      case 'richText':
        return <RichTextField control={control} name={item.name} label={item.label} />;
      case 'salary':
        return (
          <div style={{ display: 'flex', width: '100%' }}>
            <InputNumber
              style={{ width: '70%' }}
              min={0}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
              onChange={(value) => setValue(item.name, value)}
            />
            <Select
              style={{ width: '30%' }}
              value={salaryCurrency}
              onChange={(value) => {
                setSalaryCurrency(value);
                setValue('currencySymbol', value);
              }}
            >
              <Select.Option value="VND">VND</Select.Option>
              <Select.Option value="USD">USD</Select.Option>
            </Select>
          </div>
        );
    }
  };

  const renderScreen = (screen: VIEW_SCREEN_TEMPLATE) => {
    const hirringProcess = jobHirringProcess?.result || [];
    switch (screen) {
      case VIEW_SCREEN_TEMPLATE.HIRING_PROCESS:
        return (
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                fontWeight: 'bold',
                fontSize: 18,
              }}
            >
              {t('recruitment:please_select_hiring_process')}
            </div>
            <Radio.Group
              style={{ display: 'flex', flexDirection: 'column', gap: 8, marginTop: 16 }}
              onChange={(e) => {
                setSelectedHirringProcess(e.target.value);
              }}
            >
              {hirringProcess?.map((item) => (
                <Radio key={item.id} value={item.id}>
                  {item.name}
                </Radio>
              ))}
            </Radio.Group>
            <div style={{ display: 'flex', justifyContent: 'center', gap: 8, marginTop: '1rem' }}>
              <CustomButton
                aType="text"
                onClick={() => {
                  setViewScreenTempl(VIEW_SCREEN_TEMPLATE.CREATE_JOB);
                }}
              >
                {t('action:cancel')}
              </CustomButton>
              <CustomButton
                disabled={!selectedHirringProcess}
                onClick={() => {
                  onSubmit();
                }}
              >
                {t('recruitment:select')}
              </CustomButton>
            </div>
          </div>
        );

      default:
        return (
          <Form form={form}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                fontWeight: 'bold',
                fontSize: 18,
                marginBottom: 16,
              }}
            >
              {t('recruitment:create_job')}
            </div>
            <Row gutter={64}>
              <Col span={12}>
                {itemsInputLeft.map((item) => (
                  <Row key={item.name} style={{ marginBottom: 16 }}>
                    <Col span={8}>
                      <span style={{ color: 'red' }}>* </span>
                      <label>{item.label + ':'}</label>
                    </Col>
                    <Col span={16}>
                      <Form.Item
                        name={item?.name}
                        rules={[
                          {
                            required: true,
                            message: t('HRM_VAD_REQUIRED', {
                              field: item?.label,
                            }),
                          },
                        ]}
                      >
                        {renderItemEdit(item.fieldType, item)}
                      </Form.Item>
                    </Col>
                  </Row>
                ))}
              </Col>
              <Col span={12}>
                {itemsInputRight.map((item) => (
                  <Row key={item.name} style={{ marginBottom: 16 }}>
                    <Col span={8}>
                      <span style={{ color: 'red' }}>* </span>
                      <label>{item.label + ':'}</label>
                    </Col>

                    <Col span={16}>
                      <Form.Item
                        name={item?.name}
                        rules={[
                          {
                            required: true,
                            message: t('HRM_VAD_REQUIRED', {
                              field: item?.label,
                            }),
                          },
                        ]}
                      >
                        {renderItemEdit(item.fieldType, item)}
                      </Form.Item>
                    </Col>
                  </Row>
                ))}
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <RichTextField
                  control={control}
                  name="requirements"
                  label={t('recruitment:job_requirement')}
                />
              </Col>
            </Row>
            <div style={{ display: 'flex', justifyContent: 'center', gap: 8, marginTop: '1rem' }}>
              <CustomButton
                aType="text"
                onClick={() => {
                  setViewScreen && setViewScreen(VIEW_SCREEN.CREATE_JOB);
                }}
              >
                {t('action:cancel')}
              </CustomButton>
              <CustomButton
                onClick={() => {
                  onClickCreate();
                }}
              >
                {t('insurance:create')}
              </CustomButton>
            </div>
          </Form>
        );
    }
  };

  return renderScreen(viewScreenTempl);
};

export default CompleteForm;

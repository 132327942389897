import { Badge } from 'antd';
import styled from 'styled-components';

interface BadgeStatusApprovedProps {
  children: JSX.Element;
  count: number;
}

export default function BadgeStatusApproved({ children, count }: BadgeStatusApprovedProps) {
  return (
    <CustomBadge size="small" count={count} offset={[10, 0]}>
      {children}
    </CustomBadge>
  );
}
const CustomBadge = styled(Badge)`
  .ant-badge-count {
    background-color: var(--cl_error100);
    color: var(--cl_error600);
    outline: 1px solid var(--cl_error100);
  }
`;

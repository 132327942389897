import React, { useState } from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';
import { updateEmployeeId } from 'features/employee/employeeAction';

import validateUpdateEmployeeId from 'features/employee/utils/validateUpdateEmployeeId';

import CustomFormItem from 'styles/formStyled';
import CustomInput from 'styles/inputStyled';
import CustomButton from 'styles/buttonStyled';
import { EmployeeUpdateIdStyled } from './styled';

const EmployeeUpdateId = (props: any) => {
  const { recordId, employeeIdOld, isVisible, onClose } = props;
  const { t } = useTranslation(['validation', 'employee']);
  const dispatch = useDispatch();

  const [employeeIdNew, setEmployeeIdNew] = useState('');

  const handleSave = () => {
    const lstMessageValidate = validateUpdateEmployeeId({
      employeeIdOld: employeeIdOld?.toString(),
      employeeIdNew: employeeIdNew?.toString(),
    });
    if (lstMessageValidate.length > 0) {
      Modal.error({
        title: t('validation:title_error'),
        content: (
          <div dangerouslySetInnerHTML={{ __html: `${lstMessageValidate.join('<br/>')}` }} />
        ),
      });
    } else {
      dispatch(
        updateEmployeeId({
          idItem: recordId,
          dataRequest: {
            employeeIdOld: employeeIdOld?.toString(),
            employeeIdNew: employeeIdNew?.toString(),
          },
        }),
      );
      handleClose();
    }
  };

  const handleClose = () => {
    setEmployeeIdNew('');
    onClose();
  };

  return (
    <Modal
      visible={isVisible}
      maskClosable={false}
      onCancel={handleClose}
      style={{ top: 20 }}
      title={t('employee:employee_update_id.title')}
      footer={[
        <CustomButton aType="primary" key="employee-update-id-form-save" onClick={handleSave}>
          {t('employee:employee_update_id.btn_save')}
        </CustomButton>,
        <CustomButton aType="primary" key="employee-update-id-form-cancel" onClick={handleClose}>
          {t('employee:employee_update_id.btn_cancel')}
        </CustomButton>,
      ]}
    >
      <EmployeeUpdateIdStyled layout="vertical">
        <CustomFormItem label={t('employee:employee_update_id.employee_id_old')}>
          <CustomInput disabled value={employeeIdOld} />
        </CustomFormItem>
        <CustomFormItem required label={t('employee:employee_update_id.employee_id_new')}>
          <CustomInput
            maxLength={5}
            placeholder={t('employee:employee_update_id.employee_id_new_placeholder')}
            value={employeeIdNew}
            onChange={(e) => setEmployeeIdNew(e.target.value)}
          />
        </CustomFormItem>
      </EmployeeUpdateIdStyled>
    </Modal>
  );
};

export default EmployeeUpdateId;

import omit from 'lodash/omit';
import { persistReducer, createMigrate, createTransform } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import rootReducer from 'app/commonRedux/rootReducer';
import { initialRootState } from 'app/store';
import { removeStorage } from 'utils/auth';
import migrations from './migrations';

export const configReducer = (state: any, action: any) => {
  if (action.type === 'auth/logout') {
    removeStorage();
    return rootReducer(initialRootState, action);
  }
  return rootReducer(state, action);
};

const blacklistPaths = [
  'app.globalLoading',
  'employee.employeeList',
  'employee.employeeFilterList',
  'employee.employeeListApproved',
];

const persistConfig = {
  key: 'root',
  storage: storage,
  version: 3,
  whitelist: [
    'app',
    'theme',
    'auth',
    'department',
    'watchingList',
    'employee',
    'timesheet',
    'onboard',
    'equipment',
    'configuration',
    'dashboard',
  ],
  blacklist: blacklistPaths.filter((a) => !a.includes('.')),
  transforms: [
    createTransform((inboundState: any, key: any) => {
      const blacklistPaths_forKey = blacklistPaths
        .filter((path) => path.startsWith(`${key}.`))
        .map((path) => path.substring(key.length + 1));
      return omit(inboundState, ...blacklistPaths_forKey);
    }, null),
  ],
  migrate: createMigrate(migrations as any, { debug: true }),
};

const persistedReducer = persistReducer(persistConfig, configReducer) as typeof configReducer;

export default persistedReducer;

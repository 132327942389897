import { CloudUploadOutlined } from '@ant-design/icons';
import { Button, Image, Spin, Upload } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { notificationToast } from 'components/notificationToast';
import { Notification } from 'constants/notification';
import {
  getPersonCameraAI,
  postImgCheckInPersonRegister,
} from 'features/masterData/masterDataAction';
import { selectMasterData } from 'features/masterData/masterDataSlice';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import CustomButton from 'styles/buttonStyled';

const ModelImportImage = (props: any) => {
  const { setShowModal, prev, selectedEmployee, placeId } = props;
  const { t } = useTranslation(['masterConfig', 'employee', 'leaveOff']);
  const [previewImg, setPreviewImg] = useState<any>(null);
  const dispatch = useAppDispatch();
  const masterData = useAppSelector(selectMasterData);
  const loading: boolean = masterData.loading;
  const onChangeImg = async (info: any) => {
    let src;
    if (info.file.status === 'done') {
      src = info.file;
      setPreviewImg(src);
    } else if (info.file.status === 'error') {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(info.file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
      setPreviewImg(src);
    }
  };

  const onSuccess = (response: any) => {
    if (response) {
      setShowModal(false);
      dispatch(getPersonCameraAI(placeId));
      notificationToast(
        Notification.Type.Success,
        t('masterConfig:manage_place.Update_image_success'),
        Notification.Duration._3s,
      );
    }
  };

  function DataURIToBlob(dataURI: string) {
    const splitDataURI = dataURI.split(',');
    const byteString =
      splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
  }
  const handlePostImg = () => {
    const formData = new FormData();
    const file = DataURIToBlob(previewImg);
    formData.append('employeeId', selectedEmployee?.employeeId);
    formData.append('placeId', placeId);
    formData.append('file', file);
    dispatch(
      postImgCheckInPersonRegister({
        formData,
        callBack: onSuccess,
      }),
    );
  };
  return (
    <>
      {previewImg && <Image src={previewImg} />}
      <Upload.Dragger
        accept=".jpg,.png,.jpeg"
        style={{
          marginTop: '50px',
        }}
        onChange={onChangeImg}
        maxCount={1}
        showUploadList={false}
      >
        {!previewImg && (
          <>
            <CloudUploadOutlined style={{ fontSize: '50px' }} />
            <br />
            {t('masterConfig:manage_place.Drag_files_here')}
            <br />
            {t('masterConfig:manage_place.or')}
            <br />
          </>
        )}
        <CustomButton>{t('masterConfig:manage_place.select_image')}</CustomButton>
      </Upload.Dragger>
      <ButtonContainer>
        {/* <ButtonCancel onClick={() => setShowModal(false)}>Cancel</ButtonCancel> */}
        {prev && <CustomButton onClick={prev}>{t('leaveOff:preview.previous')}</CustomButton>}
        {!prev && <div></div>}
        <CustomButton
          onClick={handlePostImg}
          disabled={previewImg && selectedEmployee ? false : true}
          //loading={loading}
        >
          {loading ? <CustomSpin tip="Uploading image..." /> : t('leaveOff:item.btn_save')}
        </CustomButton>
      </ButtonContainer>
    </>
  );
};

export default React.memo(ModelImportImage);

export const ButtonCancel = styled(Button)`
  border-radius: 4px;
  padding-left: 12px;
  padding-right: 12px;
  height: 32px;
  margin-right: 10px;
`;

export const ButtonContainer = styled.div`
  margin-top: 45px;
  display: flex;
  justify-content: space-between;
`;

export const CustomSpin = styled(Spin)`
  .ant-spin-dot-item {
    background-color: white;
  }
`;

import React, { ChangeEvent, InputHTMLAttributes, ReactNode } from 'react';
import { ContainerStyled, LabelStyled, InputStyled, InputContainerStyled } from './styled';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  value?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  children?: ReactNode;
  isTopUp?: boolean;
}

function AuthInput(props: Props) {
  const { label, value, onChange, children, isTopUp = true, autoFocus = false, ...rest } = props;

  return (
    <ContainerStyled istopup={isTopUp}>
      <LabelStyled>{label}</LabelStyled>
      <InputContainerStyled>
        <InputStyled {...rest} value={value} onChange={onChange} autoFocus={autoFocus} />
        {children}
      </InputContainerStyled>
    </ContainerStyled>
  );
}

export default AuthInput;

import { Col, Row } from 'antd';
import { useAppSelector } from 'app/hooks';
import EmployeeColumn from 'components/EmployeeColumn';
import { getAllDataInterviewTemplate } from 'features/configuration/configurationAction';
import { selectConfiguration } from 'features/configuration/configurationSlice';
import { HiringProcessEntity } from 'features/recruitment/jobs/components/types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

const uniqueArrayObject = (arr: Array<any>, key: string) => {
  return Array.from(new Map(arr?.map((item) => [item?.[key], item])).values());
};

const InterviewCol = (currentItem: any) => {
  const { data } = currentItem;

  const dispatch = useDispatch();
  const dataStore = useAppSelector(selectConfiguration);
  const dataInterviewTemplate = dataStore?.dataInterviewTemplate?.result;

  const { t } = useTranslation([
    'validation',
    'masterConfig',
    'setup_menu_screen',
    'insurance',
    'common',
    'overtime',
    'employee',
    'menu',
    'recruitment',
  ]);

  const params = {
    limit: 1000,
    offset: 1,
    searchName: '',
  };

  const getDataInterviewTemplateSelected = (idInterviewTemplate: any) => {
    const result = dataInterviewTemplate?.find((item) => item?.id === idInterviewTemplate);
    return result?.name;
  };

  useEffect(() => {
    dispatch(getAllDataInterviewTemplate(params));
  }, [dispatch]);

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <div style={{ marginTop: 35 }}>
          <p style={{ fontSize: 14, fontWeight: '700', margin: 0, padding: 0 }}>
            {t('recruitment:interviewers')}
          </p>
          <div
            style={{
              minHeight: 100,
              border: '1px solid #e1e1e1',
              padding: 10,
              maxHeight: 200,
              overflowY: 'auto',
              overflowX: 'hidden',
              display: 'flex',
              flexDirection: 'column',
              gap: 15,
              marginTop: 10,
              borderRadius: 4,
            }}
          >
            {data?.interviewers?.map((item) => {
              return (
                <EmployeeColumn
                  avatar={item?.fullName}
                  fullName={item?.fullName}
                  email={item?.email}
                />
              );
            })}
          </div>
          <div style={{ marginTop: 10 }}>
            <p style={{ fontSize: 14, fontWeight: '700', margin: 0, padding: 0 }}>
              {t('menu:interview-template')}
            </p>
            <div>
              <p style={{ fontSize: 14, fontWeight: '400', margin: 0, padding: 0 }}>
                {data?.interviewTemplate?.name ||
                  getDataInterviewTemplateSelected(data?.interviewTemplateId)}
              </p>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default InterviewCol;

export const ColHeader = styled(Col)`
  font-weight: bold;
`;

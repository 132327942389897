import { memo } from 'react';
import { Spin } from 'antd';
import styled from 'styled-components';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import { DiligenceReport } from 'types';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const options = {
  indexAxis: 'y' as const,
  plugins: {
    legend: {
      display: true,
      position: 'bottom' as const,
    },
  },
  maintainAspectRatio: false,
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      grid: {
        borderDash: [8, 6],
        color: '#CDD5DF',
      },
    },
  },
};

interface DiligenceChartProps {
  loading: boolean;
  dataChart: DiligenceReport;
  onColumnClick?: (label: string, type: string) => void;
}

const StyledWrapperChart = styled.div`
  width: 100%;
  height: 350px;
`;

function PayrollSummaryLocationDepartmentChart({
  loading,
  dataChart,
  onColumnClick,
}: DiligenceChartProps) {
  const data: DiligenceReport = {
    ...dataChart,
    datasets:
      dataChart &&
      dataChart.datasets &&
      dataChart.datasets.map((x) => ({
        ...x,
        barThickness: 7,
        borderRadius: 4,
      })),
  };

  return (
    <Spin spinning={loading}>
      <StyledWrapperChart>
        <Bar
          options={options}
          data={data}
          getElementAtEvent={(elements, event) => {
            if (event.type === 'click' && elements.length) {
              const label = dataChart.labels[elements[0].index];
              const type = dataChart.datasets[elements[0].datasetIndex].label;

              onColumnClick?.(label, type);
            }
          }}
        />
      </StyledWrapperChart>
    </Spin>
  );
}

export default memo(PayrollSummaryLocationDepartmentChart);

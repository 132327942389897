import { DeleteOutlined, EditOutlined, EyeTwoTone, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Select } from 'antd';
import { useAppSelector } from 'app/hooks';
import ITVSearch from 'components/ITVSearch';
import ITVTable from 'components/ITVTable';
import MenuAction from 'components/MenuAction';
import { notificationToast } from 'components/notificationToast';
import { Notification } from 'constants/notification';
import { AppCameraAI, Place } from 'features/masterData/General/Interfaces/CameraAI';
import {
  createPlace,
  deletePlace,
  getAppCameraAI,
  getDevices,
  getPlace,
  updatePlace,
} from 'features/masterData/masterDataAction';
import { actions, selectMasterData } from 'features/masterData/masterDataSlice';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import CustomButton from 'styles/buttonStyled';
import { TitleColumnCenter } from 'styles/tableStyled';
import { removeAccents } from 'utils/text';
import { FormHeader } from '../AccessCheckInOutData/AccessCheckInOutData';
import ListOfDevices from './components/ListOfDevices';
import ListOfUsers from './components/ListOfUsers';

const { Option } = Select;

const ManagePlace = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation(['masterConfig', 'employee', 'action']);
  const masterData = useAppSelector(selectMasterData);
  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDevice, setShowDevice] = useState<any>();
  const [showListUsers, setShowListUsers] = useState<any>();
  const [, setSearching] = useState(false);
  const [textSearch, setTextSearch] = useState('');
  const dataPlace: Place[] = masterData.dataPlace;
  const listAppCameraAI: AppCameraAI[] = masterData?.listAppCameraAI?.result;
  const placeId = masterData.currentPlace;
  const [dataPlaceState, setDataPlaceState] = useState<Place[]>([]);
  const [stateListApp, setStateListApp] = useState(listAppCameraAI);
  const [appId, setAppId] = useState<any>();
  const loading: boolean = masterData.loadingParent;
  const { checkCurrentPlace } = actions;
  const [sizePage, setSizePage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();

  const menu = [
    {
      name: t('masterConfig:manage_place.view_devices'),
      width: 100,
      minWidth: 100,
      icon: <EyeTwoTone />,
      handleClick: (data: Place) => {
        setShowDevice(data);
      },
    },
    {
      name: t('masterConfig:manage_place.view_users'),
      width: 100,
      minWidth: 100,
      icon: <EyeTwoTone />,
      handleClick: (data: any) => {
        // setCurrentPlace(data.)
        setShowListUsers(data);
      },
    },
    {
      name: t('masterConfig:manage_place.edit_place'),
      width: 100,
      minWidth: 100,
      icon: <EditOutlined style={{ color: 'red' }} />,
      handleClick: (data: Place) => {
        setShowEdit(true);
        form.setFieldsValue({
          placeId: data.id,
          address: data.address,
          place: data.place,
        });
      },
    },
    {
      name: t('action:delete'),
      type: 'delete',
      icon: <DeleteOutlined />,
      handleClick: (data: any) => {
        dispatch(
          deletePlace({
            id: data?.id,
            callBack: () => {
              dispatch(getPlace({ id: appId }));
            },
          }),
        );
      },
    },
  ];

  let COLUMNS = [
    {
      title: () => {
        return <TitleColumnCenter>{t('masterConfig:manage_place.place')}</TitleColumnCenter>;
      },
      dataIndex: 'place',
      key: 'place',
      width: 100,
      minWidth: 100,
      ellipsis: true,
    },
    {
      title: () => {
        return <TitleColumnCenter>{t('masterConfig:manage_place.address')}</TitleColumnCenter>;
      },
      dataIndex: 'address',
      key: 'address',
      width: 100,
      minWidth: 100,
      ellipsis: true,
    },
    {
      title: () => {
        return (
          <TitleColumnCenter>{t('masterConfig:manage_place.total_cameras')}</TitleColumnCenter>
        );
      },
      dataIndex: 'totalDevices',
      key: 'totalDevices',
      width: 60,
      minWidth: 60,
      ellipsis: true,
      align: 'right',
    },
    {
      title: () => {
        return <TitleColumnCenter>{t('masterConfig:manage_place.total_users')}</TitleColumnCenter>;
      },
      dataIndex: 'totalUsers',
      key: 'totalUsers',
      width: 60,
      minWidth: 60,
      ellipsis: true,
      align: 'right',
    },
    {
      title: () => {
        return (
          <TitleColumnCenter>
            {t('masterConfig:manage_place.View_device_information')}
          </TitleColumnCenter>
        );
      },
      dataIndex: 'action',
      key: 'action',
      width: 100,
      minWidth: 100,
      align: 'center',
      ellipsis: true,
      render: (text: string, row: Place) => {
        return <MenuAction menu={menu} data={row} />;
      },
    },
  ];

  useEffect(() => {
    dispatch(getAppCameraAI({}));
  }, [dispatch]);

  useEffect(() => {
    if (appId && appId >= 0) dispatch(getPlace({ id: appId }));
  }, [appId, dispatch]);

  useEffect(() => {
    if (dataPlace) {
      setDataPlaceState(dataPlace);
    }
    if (textSearch) {
      handleSearchIdName(textSearch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPlace]);

  useEffect(() => {
    if (listAppCameraAI?.length) {
      setStateListApp(listAppCameraAI);
      setAppId(listAppCameraAI?.[0]?.id);
    }
  }, [listAppCameraAI]);

  const handleSearchIdName = (keySearch: string) => {
    const temp = dataPlace.filter((item: any) => {
      return (
        item?.place?.toString().toLowerCase().includes(keySearch) ||
        removeAccents((item?.place).replace(/ /g, ''))
          .toLowerCase()
          .includes(removeAccents(keySearch).replace(/ /g, '').toLowerCase())
      );
    });
    // setResultTotal(temp.length);
    setDataPlaceState(temp);
  };

  const onSearchList = (key: string) => {
    setSearching(true);
    const text = key.toLowerCase().trim();
    if (text) {
      setTextSearch(text);
      handleSearchIdName(text);
    } else {
      setTextSearch(text);
      setDataPlaceState(dataPlace);
      // setResultTotal(dataPlaceState?.length);
    }
    setSearching(false);
  };
  const onFinish = (values: any) => {
    if (values.place || values.address) {
      const body: any = {
        name: values.place.trim() || '',
        address: values.address.trim() || '',
        idApp: appId || '',
      };
      if (showEdit && values?.placeId) {
        // body.placeId = values.placeId;
        delete body.idApp;
        // return dispatch(updatePlace({ body,callBack:()=>{dispatch(getPlace({id:appId}));} }));
      }
      showEdit
        ? dispatch(
            updatePlace({
              placeId: values?.placeId,
              body,
              callBack: () => {
                setShow(false);
                setShowEdit(false);
                form.resetFields();
                dispatch(getPlace({ id: appId }));
              },
            }),
          )
        : dispatch(
            createPlace({
              body,
              callBack: () => {
                setShow(false);
                setShowEdit(false);
                form.resetFields();
                dispatch(getPlace({ id: appId }));
              },
            }),
          );
    } else
      return notificationToast(
        Notification.Type.Error,
        t('masterConfig:manage_place.Must_fill_place_or_address_input'),
        Notification.Duration._3s,
      );
  };
  return (
    <>
      {/*Modal List of users*/}
      <Modal
        visible={showListUsers ? true : false}
        centered
        title={t('masterConfig:manage_place.list_users')}
        width={'100%'}
        onCancel={() => {
          setShowListUsers(null);
        }}
        okButtonProps={{ style: { display: 'none' } }}
      >
        <ListOfUsers placeId={showListUsers?.id} />
      </Modal>

      {/*Modal List of devices*/}
      <Modal
        visible={showDevice ? true : false}
        centered
        width={'100%'}
        title={t('masterConfig:manage_place.List_of_devices')}
        onCancel={() => {
          setShowDevice(null);
        }}
        footer={[
          <InfoButtonCancel
            onClick={() => {
              setShowDevice(null);
            }}
          >
            {t('employee:employee_manage.cancel')}
          </InfoButtonCancel>,
          <CustomButton
            onClick={() => {
              dispatch(getDevices(showDevice?.id));
            }}
          >
            {t('masterConfig:manage_place.refresh')}
          </CustomButton>,
        ]}
      >
        <ListOfDevices placeId={showDevice?.id} />
      </Modal>
      {/*Modal edit place*/}
      <SmallCustomModal
        visible={showEdit}
        centered
        title={t('masterConfig:manage_place.edit_place')}
        onCancel={() => {
          setShowEdit(false);
          form.resetFields();
        }}
        footer={[
          <InfoButtonCancel
            onClick={() => {
              form.resetFields();
              setShowEdit(false);
            }}
          >
            {t('employee:employee_manage.cancel')}
          </InfoButtonCancel>,
          <CustomButton form="addNew" key="submit" htmlType="submit">
            {t('employee:employee_relative.btn_save')}
          </CustomButton>,
        ]}
      >
        <Form id="addNew" form={form} name="control-hooks" onFinish={onFinish}>
          <ItemBody>
            {
              <>
                <Titles>
                  {t('masterConfig:manage_place.id')}
                  <span style={{ color: 'red', marginLeft: '5px' }}></span>
                </Titles>
                <Form.Item name="placeId">
                  <Input disabled value={placeId} />
                </Form.Item>
              </>
            }
            <Titles>{t('masterConfig:manage_place.place')}</Titles>
            <Form.Item name="place">
              <Input placeholder={t('manage_place.please_input_place')} />
            </Form.Item>
            <Titles>{t('masterConfig:manage_place.address')}</Titles>
            <Form.Item name="address">
              <Input placeholder={t('manage_place.please_input_address')} />
            </Form.Item>
          </ItemBody>
        </Form>
      </SmallCustomModal>
      {/*Modal create place*/}
      <SmallCustomModal
        visible={show}
        centered
        title={t('masterConfig:manage_place.create_place')}
        onCancel={() => {
          setShow(false);
          form.resetFields();
        }}
        footer={[
          <InfoButtonCancel
            onClick={() => {
              form.resetFields();
              setShow(false);
            }}
          >
            {t('employee:employee_manage.cancel')}
          </InfoButtonCancel>,
          <CustomButton form="addNew" key="submit" htmlType="submit">
            {t('employee:employee_relative.btn_save')}
          </CustomButton>,
        ]}
      >
        <Form id="addNew" form={form} name="control-hooks" onFinish={onFinish}>
          <ItemBody>
            <Titles>{t('masterConfig:manage_place.place')}</Titles>
            <Form.Item name="place">
              <Input placeholder={t('manage_place.please_input_place')} />
            </Form.Item>
            <Titles>{t('masterConfig:manage_place.address')}</Titles>
            <Form.Item name="address">
              <Input placeholder={t('manage_place.please_input_address')} />
            </Form.Item>
          </ItemBody>
        </Form>
      </SmallCustomModal>
      <FormHeader>
        <h2 style={{ color: 'white', margin: '0px', marginLeft: '25px' }}>
          {t('masterConfig:manage_place.List_of_places')}
        </h2>
      </FormHeader>
      <FormContent>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ width: '80%' }}>
            <SearchBar handleSearch={(value: any) => onSearchList(value)} />
            <Select
              style={{ width: '19%', marginLeft: '1%' }}
              value={appId || ''}
              onChange={(value: any) => {
                setAppId(value);
              }}
            >
              {stateListApp?.map((app: any) => (
                <Option value={app?.id}>{app?.name}</Option>
              ))}
            </Select>
          </div>
          <CustomButton
            icon={<PlusOutlined />}
            onClick={() => {
              dispatch(checkCurrentPlace(''));
              setShow(true);
            }}
          >
            {t('masterConfig:manage_place.create_place')}
          </CustomButton>
        </div>
        <ITVTable
          columns={COLUMNS}
          data={dataPlaceState}
          isScroll={true}
          loading={loading}
          isRowSelect={false}
          setOffset={setCurrentPage}
          offset={currentPage}
          setLimit={setSizePage}
          limit={sizePage}
        />
      </FormContent>
    </>
  );
};

export default ManagePlace;

export const FormContent = styled.div`
  display: flex;
  align-content: space-between;
  flex-direction: column;
  padding: 20px 30px 10px 30px;
  background-color: white;
`;

export const SearchBar = styled(ITVSearch)`
  width: 40%;
  margin-bottom: 10px;
`;

export const Titles = styled.div`
  display: flex;
  height: 20px;
  font-size: 14px;
  font-family: Roboto;
`;

export const ItemBody = styled.div`
  margin-bottom: 6px;
`;

export const InfoButtonCancel = styled(Button)`
  border-radius: 4px;
  padding-left: 12px;
  padding-right: 12px;
  height: 30px;
`;

export const SmallCustomModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 6px 6px 6px 6px;
  }
  .ant-modal-header {
    background: #f1592a;
    border-radius: 6px 6px 0 0;
  }
  .ant-modal-title {
    color: white;
    font-size: 20px;
  }
  .ant-modal-close-x {
    color: white;
  }
`;

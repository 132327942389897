import { EyeTwoTone } from '@ant-design/icons';
import {
  DatePicker as AntdDatePicker,
  Button,
  Checkbox,
  Popconfirm,
  Radio,
  RadioChangeEvent,
} from 'antd';
import checkinApi from 'api/checkinApi';
import { selectFormatDate, selectFormatDatePayload } from 'app/commonRedux/appSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import EmployeeColumn from 'components/EmployeeColumn';
import ITVSearch from 'components/ITVSearch';
import ITVTable from 'components/ITVTable';
// import MenuAction from 'components/MenuAction';
import { notificationToast } from 'components/notificationToast';
import { TIMEZONE_DEFAULT } from 'constants/commons';
import { Notification } from 'constants/notification';
import {
  getAccessCheckInOut,
  updateAccessCheckInOut,
  updateMultipleDateCheckinOnline,
} from 'features/masterData/masterDataAction';
import { selectMasterData } from 'features/masterData/masterDataSlice';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-multi-date-picker';
import styled from 'styled-components';
import { TitleColumnCenter } from 'styles/tableStyled';
import { getUser } from 'utils/auth';
import { removeAccents } from 'utils/text';
import HistoryAccess from './../../common/HistoryAccess';

const { RangePicker } = AntdDatePicker;

const getLastDayOfMonth = (month: number) => {
  const year = new Date().getFullYear(); // Lấy năm hiện tại
  const endOfMonth = new Date(year, month, 0); // month + 1 rồi lấy ngày 0 sẽ cho ngày cuối tháng
  return endOfMonth;
};

const SynchronizeData = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['masterConfig', 'employee']);
  const [, setResultTotal] = useState(0);
  const [data, setData] = useState<any>([]);
  const [textSearch, setTextSearch] = useState('');
  const masterData = useAppSelector(selectMasterData);
  const dataCheckIn = masterData.dataCheckIn;
  const [dataOri, setDataOri] = useState<any>([]);
  const loading = masterData.loading;
  const [currentSelectedUser, setCurrentSelectedUser] = useState();
  const timeZone = getUser().timeZone ? getUser().timeZone : TIMEZONE_DEFAULT;
  const [isCheckApplyMultiOnl, setIsCheckApplyMultiOnl] = useState(false);
  const [durationOnlMulti, setDurationOnlMulti] = useState();
  const [listSelectedUsers, setListSelectedUsers]: any = useState([]);
  const [getDateString, setGetDateString] = useState();
  const [visibleHistory, setVisibleHistory] = useState(false);
  const [typeCheckIn, setTypeCheckIn] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [sizePage, setSizePage] = useState(10);

  const dateFormat = useAppSelector(selectFormatDate);
  const dateFormatPayload = useAppSelector(selectFormatDatePayload);

  //Set default timezone in server
  moment.tz.setDefault(timeZone);

  useEffect(() => {
    dispatch(getAccessCheckInOut());
  }, [dispatch]);

  useEffect(() => {
    if (isCheckApplyMultiOnl === false && durationOnlMulti) {
      //clear duration on apply multi picker when uncheck apply multi
      setDurationOnlMulti(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCheckApplyMultiOnl]);

  useEffect(() => {
    if (masterData) {
      setDataOri(
        dataCheckIn?.map((item: any) => {
          return {
            ...item,
            listDateCustom: item?.onlineAccess[0]?.listDateCustom,
          };
        }),
      );
      setData(
        dataCheckIn?.map((item: any) => {
          return {
            ...item,
            listDateCustom: item?.onlineAccess[0]?.listDateCustom,
          };
        }),
      );
    }
    if (textSearch) {
      handleSearchIdName(textSearch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCheckIn, textSearch]);

  /// handle in access check in/out online

  const handleClickOk = () => {
    if (getDateString) {
      if (listSelectedUsers.length > 0) {
        let _tmp = dataOri.map((item: any) => {
          let check = listSelectedUsers.indexOf(item.id) !== -1;
          if (check) {
            return {
              ...item,
              online: true,
              onlineStartDate: moment(getDateString[0], dateFormat),
              onlineEndDate: moment(getDateString[1], dateFormat),
            };
          }
          return item;
        });

        setDataOri(_tmp);
        setData(_tmp);
        setListSelectedUsers([]);
        setIsCheckApplyMultiOnl(false);
      }
    }
  };

  const handleOnClickUpdate = (e: any, record: any) => {
    setCurrentSelectedUser(record);
    if (e.target.checked === false) {
      setDataOri(
        dataOri.map((item: any) => ({
          ...item,
          online: record.employeeId !== item.employeeId ? item.online : e.target.checked,
          onlineStartDate: record.employeeId !== item.employeeId ? item.onlineStartDate : null,
          onlineEndDate: record.employeeId !== item.employeeId ? item.onlineEndDate : null,
        })),
      );
      setData(
        data.map((item: any) => ({
          ...item,
          online: record.employeeId !== item.employeeId ? item.online : e.target.checked,
          onlineStartDate: record.employeeId !== item.employeeId ? item.onlineStartDate : null,
          onlineEndDate: record.employeeId !== item.employeeId ? item.onlineEndDate : null,
        })),
      );
    } else {
      //this to get unsaved duration record back
      const unsavedRecord = dataCheckIn.filter((item: any) => record.id === item.id);
      setDataOri(
        dataOri.map((item: any) => ({
          ...item,
          online: record.employeeId !== item.employeeId ? item.online : e.target.checked,
          onlineStartDate:
            record.employeeId !== item.employeeId
              ? item.onlineStartDate
              : unsavedRecord[0].onlineStartDate,
          onlineEndDate:
            record.employeeId !== item.employeeId
              ? item.onlineEndDate
              : unsavedRecord[0].onlineEndDate,
        })),
      );
      setData(
        data.map((item: any) => ({
          ...item,
          online: record.employeeId !== item.employeeId ? item.online : e.target.checked,
          onlineStartDate:
            record.employeeId !== item.employeeId
              ? item.onlineStartDate
              : unsavedRecord[0].onlineStartDate,
          onlineEndDate:
            record.employeeId !== item.employeeId
              ? item.onlineEndDate
              : unsavedRecord[0].onlineEndDate,
        })),
      );
    }
  };

  const handleCheckAllOnline = (e: any) => {
    setDataOri(
      dataOri.map((item: { employeeId: any; online: any }) => ({
        ...item,
        online: e.target.checked,
      })),
    );
    setData(
      data.map((item: { employeeId: any; online: any }) => ({
        ...item,
        online: e.target.checked,
      })),
    );
  };

  function findDifferences(array1, array2) {
    function deepEqual(obj1, obj2) {
      if (obj1 === obj2) return true;

      if (obj1 == null || obj2 == null || typeof obj1 !== 'object' || typeof obj2 !== 'object') {
        return false;
      }

      let keys1 = Object.keys(obj1);
      let keys2 = Object.keys(obj2);

      if (keys1.length !== keys2.length) return false;

      for (let key of keys1) {
        // If the key is not in both objects, or the values are not deeply equal, return false
        if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
          return false;
        }
      }

      return true;
    }

    let differences = [];

    array1.forEach((obj1) => {
      let correspondingObj = array2.find((obj2) => obj2.userId === obj1.userId);
      // If there is no corresponding object or they are not deeply equal, add to differences
      if (!correspondingObj || !deepEqual(obj1, correspondingObj)) {
        differences.push(obj1);
      }
    });

    return differences;
  }

  function filterOnlineAccess(array) {
    return array.map((item) => ({
      ...item,
      onlineAccess: item.onlineAccess.filter((access) => access.listDate.length > 0),
    }));
  }

  function formatNumber(num) {
    if (num >= 1 && num <= 9) {
      return '0' + num.toString();
    } else {
      return num.toString();
    }
  }

  const handleUpdateCheckInOut = () => {
    if (dataOri?.length > 0) {
      const userDataChange = dataOri.filter(
        (item: any) => !dataCheckIn.some((record: any) => isEqual(record, item)),
      );

      let result = findDifferences(dataOri, dataCheckIn);

      var finalResult = result.filter((item: any) => item.onlineType === 'multiple');

      var filteredOnlineAccess = filterOnlineAccess(finalResult);

      var missMutipleDate = filteredOnlineAccess.find((element: any) => {
        return !element?.onlineAccess || element?.onlineAccess?.length <= 0;
      });

      if (missMutipleDate) {
        notificationToast(
          Notification.Type.Warning,
          t('masterConfig:common.please_select_mutipledate', {
            userName: missMutipleDate.fullName,
          }),
          Notification.Duration._3s,
        );
      } else {
        const param = userDataChange.map((item: any) => {
          return {
            hkvision: item.hkvision,
            camera: item.camera,
            machine: item.machine,
            online: item.online,
            userId: item.userId,
            id: item.id,
            onlineStartDate: item.onlineStartDate
              ? moment(item.onlineStartDate).format(dateFormatPayload)
              : null,
            onlineEndDate: item.onlineEndDate
              ? moment(item.onlineEndDate).format(dateFormatPayload)
              : null,
            typeDateOnline: item?.onlineType ?? 'range',
            isCheckIP: item?.isCheckIP ?? false,
          };
        });

        const newArray = filteredOnlineAccess.flatMap((user) =>
          user.onlineAccess.map((item) => ({
            userId: user.userId,
            id: item.id,
            month: item.monthYear,
            accessDays: item.listDate,
            isGenerate: user.isAutoGenerate ?? false,
          })),
        );

        if (newArray) {
          dispatch(updateMultipleDateCheckinOnline(newArray));
        }
        dispatch(updateAccessCheckInOut(param));
        setCurrentSelectedUser(undefined);
        setIsCheckApplyMultiOnl(false);
        setDurationOnlMulti(undefined);
      }
    }
  };

  const handleChangeApplyMulti = (e: any) => {
    setIsCheckApplyMultiOnl(e.target.checked);
  };

  const disabledDate = (current: any) => {
    // Can not select days before today
    return current < moment().startOf('day');
  };

  const handleOnApplyDateMulti = (dates: any, dateString: any) => {
    setDurationOnlMulti(dates);
    setGetDateString(dateString);
  };

  /////////////////

  //handle check in out camera
  const handleCameraClickUpdate = (e: any, record: any) => {
    setDataOri(
      dataOri.map((item: any) => ({
        ...item,
        camera: record.employeeId !== item.employeeId ? item.camera : e.target.checked,
      })),
    );
    setData(
      data.map((item: any) => ({
        ...item,
        camera: record.employeeId !== item.employeeId ? item.camera : e.target.checked,
      })),
    );
  };

  const handleCheckAllCamera = (e: any) => {
    setDataOri(
      dataOri.map((item: { employeeId: any; camera: any }) => ({
        ...item,
        camera: e.target.checked,
      })),
    );
    setData(
      data.map((item: { employeeId: any; camera: any }) => ({
        ...item,
        camera: e.target.checked,
      })),
    );
  };
  //handle check in out face
  const handleFaceClickUpdate = (e: any, record: any) => {
    setDataOri(
      dataOri.map((item: any) => ({
        ...item,
        hkvision: record.employeeId !== item.employeeId ? item.hkvision : e.target.checked,
      })),
    );
    setData(
      data.map((item: any) => ({
        ...item,
        hkvision: record.employeeId !== item.employeeId ? item.hkvision : e.target.checked,
      })),
    );
  };
  const handleCheckAllFace = (e: any) => {
    setDataOri(
      dataOri.map((item: { employeeId: any; hkvision: any }) => ({
        ...item,
        hkvision: e.target.checked,
      })),
    );
    setData(
      data.map((item: { employeeId: any; hkvision: any }) => ({
        ...item,
        hkvision: e.target.checked,
      })),
    );
  };
  ///////////

  const handleSearchIdName = (keySearch: string) => {
    const temp = dataCheckIn.filter((item: any) => {
      return (
        item?.employeeId?.toString().toLowerCase().includes(keySearch) ||
        removeAccents((item?.fullName).replace(/ /g, ''))
          .toLowerCase()
          .includes(removeAccents(keySearch).replace(/ /g, '').toLowerCase())
      );
    });

    var finalList = temp?.map((item: any) => {
      return {
        ...item,
        listDateCustom: item?.onlineAccess[0]?.listDateCustom,
      };
    });

    setResultTotal(finalList.length);
    setDataOri(finalList);
  };

  const onChangeOnlineDuration = (dates: any, dateString: any) => {
    setDataOri(
      dataOri.map((item: any) => ({
        ...item,
        onlineStartDate: isEqual(item, currentSelectedUser)
          ? dateString[0]
            ? moment(dateString[0], dateFormat)
            : null
          : item.onlineStartDate, //compare two object using lodash
        onlineEndDate: isEqual(item, currentSelectedUser)
          ? dateString[1]
            ? moment(dateString[1], dateFormat)
            : null
          : item.onlineEndDate,
      })),
    );
    setData(
      data.map((item: any) => ({
        ...item,
        onlineStartDate: isEqual(item, currentSelectedUser)
          ? dateString[0]
            ? moment(dateString[0], dateFormat)
            : null
          : item.onlineStartDate, //compare two object using lodash
        onlineEndDate: isEqual(item, currentSelectedUser)
          ? dateString[1]
            ? moment(dateString[1], dateFormat)
            : null
          : item.onlineEndDate,
      })),
    );
  };

  const removeDuplicates = (arr: Array<string>) => {
    let s = new Set(arr);
    let it = s.values();
    return Array.from(it);
  };

  const onClickCheckOnline = (e: any, record: any, typeCheckIn: string) => {
    //get current selected user to bind with duration
    setCurrentSelectedUser(record);
    setTypeCheckIn(typeCheckIn); //get type checkin
  };

  const onClickEditDuration = (e: any, record: any) => {
    if (e.target.checked) {
      setListSelectedUsers(removeDuplicates([...listSelectedUsers, record.id]));
    } else {
      if (listSelectedUsers.length > 0) {
        const isEmployeeSelected = listSelectedUsers.includes(record.id);

        const indexEmployeeIdToRemove = listSelectedUsers.findIndex(
          (item: any) => item.id === record.id,
        );

        if (isEmployeeSelected && listSelectedUsers.length > 0) {
          const newArr = [...listSelectedUsers];
          newArr.splice(indexEmployeeIdToRemove, 1);
          setListSelectedUsers(newArr as any);
        }
      }
    }
  };

  const disableCheckOnline = (record: any) => {
    // if (
    //   record?.onlineStartDate &&
    //   record?.onlineEndDate &&
    //   moment().isBetween(
    //     moment(record?.onlineStartDate),
    //     moment(record?.onlineEndDate),
    //     'day',
    //     '[]', //this to get range from start date to end date
    //   )
    // ) {
    //   return false;
    // }
    // if (moment(record?.onlineStartDate).isAfter(moment().startOf('day'))) {
    //   return false;
    // }
    // if (!record?.onlineStartDate && !record?.onlineEndDate) {
    //   return false;
    // } else {
    //   return true;
    // }
    return false;
  };

  const menuOnline = [
    {
      name: t('masterConfig:general.view_history'),
      icon: <EyeTwoTone />,
      handleClick: (data: any) => {
        setVisibleHistory(true);
      },
    },
  ];

  const onChangeRadioTypeDateOnline = (e: RadioChangeEvent, record: any) => {
    setCurrentSelectedUser(record);
    setDataOri(
      dataOri.map((item: any) => ({
        ...item,
        onlineType: record.employeeId !== item.employeeId ? item.onlineType : e.target.value,
      })),
    );
    setData(
      data.map((item: any) => ({
        ...item,
        onlineType: record.employeeId !== item.employeeId ? item.onlineType : e.target.value,
      })),
    );
  };
  const onChangeAutoGenerateMonths = (e: any, record: any) => {
    const result = record.onlineAccess.filter(
      (item) => item.monthYear === moment(new Date()).format('MM/YYYY'),
    );

    setCurrentSelectedUser(record);
    if (e.target.checked === true) {
      setDataOri(
        dataOri.map((item: any) => ({
          ...item,
          isAutoGenerate:
            record.employeeId !== item.employeeId ? item.isAutoGenerate : e.target.checked,
          onlineAccess: record.employeeId !== item.employeeId ? item.onlineAccess : result,
          listDateCustom:
            record.employeeId !== item.employeeId
              ? item.listDateCustom
              : record.onlineAccess[0].listDateCustom,
        })),
      );
      setData(
        data.map((item: any) => ({
          ...item,
          isAutoGenerate:
            record.employeeId !== item.employeeId ? item.isAutoGenerate : e.target.checked,
          onlineAccess: record.employeeId !== item.employeeId ? item.onlineAccess : result,
          listDateCustom:
            record.employeeId !== item.employeeId
              ? item.listDateCustom
              : record.onlineAccess[0].listDateCustom,
        })),
      );
    } else {
      setDataOri(
        dataOri.map((item: any) => ({
          ...item,
          isAutoGenerate:
            record.employeeId !== item.employeeId ? item.isAutoGenerate : e.target.checked,
        })),
      );
      setData(
        data.map((item: any) => ({
          ...item,
          isAutoGenerate:
            record.employeeId !== item.employeeId ? item.isAutoGenerate : e.target.checked,
        })),
      );
    }
  };

  const onChangeCheckIP = (e: any, record: any) => {
    setDataOri(
      dataOri.map((item: any) => ({
        ...item,
        isCheckIP: record.employeeId !== item.employeeId ? item?.isCheckIP : e.target.checked,
      })),
    );
    setData(
      data.map((item: any) => ({
        ...item,
        isCheckIP: record.employeeId !== item.employeeId ? item?.isCheckIP : e.target.checked,
      })),
    );
  };

  const onChangeMutipleDate = (dates: any, record: any) => {
    setCurrentSelectedUser(record);

    let initalValue = record.onlineAccess;

    dates?.forEach((ele: any) => {
      const monthYear = `${formatNumber(ele.month.number)}/${ele.year}`;

      // Tìm index của object có monthYear tương ứng trong array
      let existingObjectIndex = initalValue.findIndex((obj) => obj.monthYear === monthYear);

      if (existingObjectIndex !== -1) {
        // Nếu tồn tại, tạo một object mới với listDate được cập nhật
        const updatedObject = {
          ...initalValue[existingObjectIndex],
          listDate: dates?.map((ele: any) => formatNumber(ele.day)),
          listDateCustom: dates?.map(
            (ele: any) => `${formatNumber(ele.day)}/${formatNumber(ele.month.number)}/${ele.year}`,
          ),
        };

        // Tạo một mảng mới với object được cập nhật
        initalValue = [
          ...initalValue.slice(0, existingObjectIndex),
          updatedObject,
          ...initalValue.slice(existingObjectIndex + 1),
        ];
      } else {
        const newListDate = dates?.map((ele: any) => formatNumber(ele.day));
        const newListDateCustom = dates?.map(
          (ele: any) => `${formatNumber(ele.day)}/${formatNumber(ele.month.number)}/${ele.year}`,
        );
        initalValue = [
          ...initalValue,
          {
            id: null,
            monthYear: monthYear,
            listDate: newListDate,
            listDateCustom: newListDateCustom,
          },
        ];
      }
    });

    setDataOri(
      dataOri.map((item: any) => ({
        ...item,
        customMultipleDate:
          record.employeeId !== item.employeeId ? item?.customMultipleDate : dates,
        onlineAccess: record.employeeId !== item.employeeId ? item?.onlineAccess : initalValue,
      })),
    );
    setData(
      data.map((item: any) => ({
        ...item,
        customMultipleDate:
          record.employeeId !== item.employeeId ? item?.customMultipleDate : dates,
        onlineAccess: record.employeeId !== item.employeeId ? item?.onlineAccess : initalValue,
      })),
    );
  };

  const handleMonthChange = async (value: any, record: any) => {
    try {
      const response = await checkinApi.getOnlineAccessByMonth({
        userId: record.userId,
        month: `${formatNumber(value.month.number)}/${value.year}`,
      });

      const updatedDataOri = dataOri.map((item) => {
        if (item.userId === record.userId) {
          // Tìm trong onlineAccess phần tử có cùng monthYear với response.data.monthYear
          const targetOnlineAccess = item.onlineAccess.find(
            (oa) => oa.monthYear === response.data.monthYear,
          );

          if (targetOnlineAccess) {
            return {
              ...item,
              listDateCustom:
                targetOnlineAccess.listDateCustom ?? targetOnlineAccess.listDateMutipleCustom, // Cập nhật với các phần tử giao nhau
            };
          } else {
            // Nếu chưa có monthYear này trong onlineAccess, thêm mới
            return {
              ...item,
              listDateCustom: response.data.listDateMutipleCustom,
              onlineAccess: [...item.onlineAccess, response.data],
            };
          }
        }
        return item;
      });
      const updatedData = data.map((item) => {
        if (item.userId === record.userId) {
          // Tìm trong onlineAccess phần tử có cùng monthYear với response.data.monthYear
          const targetOnlineAccess = item.onlineAccess.find(
            (oa) => oa.monthYear === response.data.monthYear,
          );

          if (targetOnlineAccess) {
            return {
              ...item,
              listDateCustom: targetOnlineAccess.listDateCustom, // Cập nhật với các phần tử giao nhau
            };
          } else {
            // Nếu chưa có monthYear này trong onlineAccess, thêm mới
            return {
              ...item,
              listDateCustom: response.data.listDateMutipleCustom,
              onlineAccess: [...item.onlineAccess, response.data],
            };
          }
        }
        return item;
      });

      setDataOri(updatedDataOri);
      setData(updatedData);
    } catch (error) {
      console.error(error);
    }
  };

  let COLUMNSCHECKIN = [
    {
      title: () => {
        return (
          <TitleColumnCenter>{t('employee:employee_information_fields.id')}</TitleColumnCenter>
        );
      },
      dataIndex: 'employeeId',
      key: 'employeeId',
      ellipsis: true,
      width: 40,
      minWidth: 40,
      align: 'center',
      fixed: 'left',
      render: function (text: any, record: any, index: any) {
        return <div>{text}</div>;
      },
    },
    {
      title: () => {
        return (
          <TitleColumnCenter>
            {t('employee:employee_information_fields.employee')}
          </TitleColumnCenter>
        );
      },
      dataIndex: 'fullName',
      key: 'fullName',
      width: 120,
      minWidth: 120,
      ellipsis: true,
      fixed: 'left',
      render: function (text: any, record: any, index: any) {
        return (
          <EmployeeColumn
            avatar={record?.fullName}
            fullName={record?.fullName}
            email={record?.email}
          />
        );
      },
    },
    {
      title: () => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <p>{t('masterConfig:general.machine')}</p>
            <AccessControlCheckBox disabled={true} />
          </div>
        );
      },
      key: 'machine',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (record: any) => {
        return <AccessControlCheckBox checked={record?.machine} disabled={true} />;
      },
    },
    {
      title: (record: any) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <p>{t('masterConfig:general.online')}</p>
            <AccessControlCheckBox onClick={(e) => handleCheckAllOnline(e)} />
          </div>
        );
      },
      key: 'online',
      width: 200,
      minWidth: 200,
      align: 'center',
      render: (record: any) => {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '10px',
            }}
            onClick={(e) => onClickCheckOnline(e, record, 'online')}
          >
            <div style={{ display: 'flex', alignSelf: 'flex-start', position: 'absolute' }}>
              {isCheckApplyMultiOnl && (
                <EditDurationCheckBox onClick={(e) => onClickEditDuration(e, record)} />
              )}
            </div>

            <AccessControlCheckBox
              checked={record?.online}
              onClick={(e) => handleOnClickUpdate(e, record)}
              disabled={disableCheckOnline(record)}
            />
            {/* <div style={{ display: 'flex', alignSelf: 'flex-end', position: 'absolute' }}>
              <MenuAction menu={menuOnline} data={record} />
            </div> */}

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              {record.online && (
                <div
                  style={{
                    marginTop: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                    width: '100%',
                  }}
                >
                  <Checkbox
                    checked={record?.isCheckIP ?? false}
                    onClick={(e) => onChangeCheckIP(e, record)}
                    style={{ textAlign: 'left' }}
                  >
                    {t('masterConfig:common.company_ip')}
                  </Checkbox>
                  <Radio.Group
                    onChange={(e) => onChangeRadioTypeDateOnline(e, record)}
                    value={record?.onlineType ? record?.onlineType : 'range'}
                    style={{ display: 'flex', flexWrap: 'wrap' }}
                  >
                    <Radio value={'range'}>{t('masterConfig:common.option_range_date')}</Radio>
                    <Radio value={'multiple'}>{t('masterConfig:common.option_mutiple_date')}</Radio>
                  </Radio.Group>
                  {record.onlineType === 'multiple' ? (
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                      <Checkbox
                        checked={record?.isAutoGenerate ?? false}
                        onClick={(e) => onChangeAutoGenerateMonths(e, record)}
                        style={{ textAlign: 'left' }}
                      >
                        {t('masterConfig:common.automatically_generated')}
                      </Checkbox>
                      <DatePicker
                        value={record?.listDateCustom}
                        onChange={(e) => onChangeMutipleDate(e, record)}
                        multiple
                        sort
                        format={'DD/MM/YYYY'}
                        calendarPosition="bottom-center"
                        placeholder={t('masterConfig:common.please_select_date')}
                        style={{
                          width: '100%',
                          height: '30px',
                          borderRadius: 'unset',
                          padding: '4px 10px',
                        }}
                        maxDate={
                          record?.isAutoGenerate
                            ? getLastDayOfMonth(Number(moment(new Date()).format('M')))
                            : undefined
                        }
                        onMonthChange={(e) => handleMonthChange(e, record)}
                      />
                    </div>
                  ) : (
                    <StyleOnlineRangePicker
                      value={
                        record.online === false
                          ? null
                          : record?.onlineStartDate && record?.onlineEndDate
                          ? [moment(record?.onlineStartDate), moment(record?.onlineEndDate)]
                          : null
                      }
                      disabled={record?.online === true ? false : true}
                      disabledDate={disabledDate}
                      onChange={onChangeOnlineDuration}
                      format={dateFormat}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        );
      },
    },
    {
      title: () => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <p>{t('masterConfig:general.camera')}</p>
            <AccessControlCheckBox onClick={(e) => handleCheckAllCamera(e)} />
          </div>
        );
      },
      key: 'camera',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (record: any) => {
        return (
          <AccessControlCheckBox
            checked={record?.camera}
            onClick={(e) => handleCameraClickUpdate(e, record)}
          />
        );
      },
    },
    {
      title: () => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <p>{t('masterConfig:general.face')}</p>
            <AccessControlCheckBox onClick={(e) => handleCheckAllFace(e)} />
          </div>
        );
      },
      key: 'hkvision',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (record: any) => {
        return (
          <AccessControlCheckBox
            checked={record?.hkvision}
            onClick={(e) => handleFaceClickUpdate(e, record)}
          />
        );
      },
    },
  ];

  const onSearchList = (key: string) => {
    const text = removeAccents(key.toLowerCase().trim());

    if (text) {
      setTextSearch(text);
    } else {
      setTextSearch('');
    }
  };

  return (
    <>
      <FormHeader>
        <h2 style={{ color: 'white', margin: '0px', marginLeft: '25px' }}>{`${t(
          'masterConfig:general.access_checkin_out_method',
        )}`}</h2>
      </FormHeader>
      <FormContent>
        <div>
          <div
            style={{ display: 'flex', justifyContent: 'space-between', padding: '10px 0', flex: 5 }}
          >
            <SearchBar handleSearch={(value: any) => onSearchList(value)} />
            {/* <div style={{ display: 'flex', flex: 1 }}></div> */}
            <div
              style={{
                display: 'flex',
                flexDirection: 'column-reverse',
                alignItems: 'center',
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <StyleOnlineRangePicker
                  disabled={isCheckApplyMultiOnl === true ? false : true}
                  disabledDate={disabledDate}
                  onChange={handleOnApplyDateMulti}
                  value={durationOnlMulti}
                  format={dateFormat}
                />
                <Popconfirm
                  title={t('masterConfig:general.are_you_sure')}
                  okText={t('masterConfig:action.yes')}
                  cancelText={t('masterConfig:action.no')}
                  onConfirm={handleClickOk}
                  disabled={
                    isCheckApplyMultiOnl && listSelectedUsers.length > 0
                      ? durationOnlMulti
                        ? false
                        : true
                      : true
                  }
                >
                  <CustomApprove
                    disabled={
                      isCheckApplyMultiOnl && listSelectedUsers.length > 0
                        ? durationOnlMulti
                          ? false
                          : true
                        : true
                    }
                  >
                    {t('masterConfig:action.ok')}
                  </CustomApprove>
                </Popconfirm>
              </div>
              <div style={{ marginBottom: '10px', alignSelf: 'flex-start' }}>
                <Checkbox
                  checked={isCheckApplyMultiOnl}
                  onChange={handleChangeApplyMulti}
                  style={{ marginRight: '10px' }}
                />
                {t('masterConfig:general.check_to_apply_multi_user')}
              </div>
            </div>
            {/* <div style={{ display: 'flex', flex: 1 }}></div> */}
          </div>
        </div>
        <ITVTable
          columns={COLUMNSCHECKIN}
          data={dataOri}
          isScroll={true}
          loading={loading}
          isRowSelect={false}
          height={600}
          limit={sizePage}
          setLimit={setSizePage}
          offset={currentPage}
          setOffset={setCurrentPage}
        />
        <div style={{ display: 'flex', alignSelf: 'flex-end' }}>
          <Popconfirm
            title={t('masterConfig:general.are_you_sure')}
            onConfirm={handleUpdateCheckInOut}
            okText="Yes"
            cancelText="No"
          >
            <CustomApprove>{t('masterConfig:action.save')}</CustomApprove>
          </Popconfirm>
        </div>
        <HistoryAccess
          typeHistory={typeCheckIn}
          visible={visibleHistory}
          setVisibleHistory={setVisibleHistory}
          record={currentSelectedUser}
        />
      </FormContent>
    </>
  );
};

export default SynchronizeData;

export const DatePickerCustom = styled(AntdDatePicker)`
  width: 100%;
`;

export const DatePickerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const FormContainer = styled.div`
  width: 80%;
  margin: 20px;
`;
export const FormHeader = styled.div`
  background: linear-gradient(263.81deg, #f1592a -3.68%, #fa9b66 108.73%);
  height: 50px;
  display: flex;
  align-items: center;
`;

export const FormContent = styled.div`
  display: flex;
  align-content: space-between;
  flex-direction: column;
  padding: 20px 30px 10px 30px;
  background-color: white;
`;

export const CustomApprove = styled(Button)`
  background: #f1592a;
  border-radius: 4px;
  padding: 4px 12px;
  margin-left: 10px;
  height: 100%;
  color: #ffffff;
`;

export const SearchBar = styled(ITVSearch)`
  width: 30%;
`;

export const EditDurationCheckBox = styled(Checkbox)`
  .ant-checkbox-inner {
    width: 18px;
    height: 18px;
    border-color: #1890ff;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #1890ff;
    width: 18px;
    height: 18px;
    border-color: #1890ff;
  }
  // .ant-checkbox-inner::after {
  //   width: 13.714286px;
  //   height: 23.142857px;
  // }
`;
export const StyleOnlineRangePicker = styled(RangePicker)`
  height: auto;
  width: 100%;
  font-size: 17px;
  .ant-picker-input > input {
    text-align: center;
  }
`;
export const MutipleDatePickerCustom = styled(DatePicker)`
  height: auto;
  width: max-content;
  font-size: 17px;
`;

export const AccessControlCheckBox = styled(Checkbox)`
  .ant-checkbox-inner {
    width: 20px;
    height: 20px;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-top-color: rgb(217, 217, 217);
    border-top-style: solid;
    border-top-width: 1px;
    border-right-color: rgb(217, 217, 217);
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-color: rgb(217, 217, 217);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: rgb(217, 217, 217);
    border-left-style: solid;
    border-left-width: 1px;
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
    border-radius: 2px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
    border-collapse: separate;
    transition: all 0.3s;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    width: 20px;
    height: 20px;
  }
  .ant-checkbox-wrapper:hover {
    border-color: #fe753e;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #fe753e;
  }
  .ant-checkbox-disabled > .ant-checkbox-inner {
    background-color: #fbfbfb;
  }
`;

import { toInteger } from './utils';
import { gridToGraphPoint } from './pointConversion';
import { svgDrawSmoothLinePath } from './svgDrawSmoothLinePath';
import { pathfindingJumpPointNoDiagonal } from './generatePath';
import getBoundingBoxes from './getBoundingBoxes';
import createGrid from './createGrid';

export function getSmartEdge({
  options = {},
  nodes = [],
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  edgesPath = [],
}) {
  try {
    const { drawEdge = svgDrawSmoothLinePath, generatePath = pathfindingJumpPointNoDiagonal } =
      options;

    let { gridRatio = 10, nodePadding = 10, edgePadding = 5 } = options;
    gridRatio = toInteger(gridRatio);
    nodePadding = toInteger(nodePadding);

    // We use the node's information to generate bounding boxes for them
    // and the graph
    const { graphBox, nodeBoxes } = getBoundingBoxes(nodes, nodePadding, gridRatio);

    const source = {
      x: sourceX,
      y: sourceY,
      position: sourcePosition,
    };

    const target = {
      x: targetX,
      y: targetY,
      position: targetPosition,
    };

    // With this information, we can create a 2D grid representation of
    // our graph, that tells us where in the graph there is a "free" space or not
    const { grid, start, end } = createGrid(
      graphBox,
      nodeBoxes,
      source,
      target,
      gridRatio,
      edgesPath,
      edgePadding,
    );

    // We then can use the grid representation to do pathfinding
    const generatePathResult = generatePath(grid, start, end);

    if (generatePathResult === null) {
      return null;
    }

    const { fullPath, smoothedPath } = generatePathResult;

    // Here we convert the grid path to a sequence of graph coordinates.
    const graphPath = smoothedPath.map((gridPoint) => {
      const [x, y] = gridPoint;
      const graphPoint = gridToGraphPoint({ x, y }, graphBox.xMin, graphBox.yMin, gridRatio);
      return [graphPoint.x, graphPoint.y];
    });

    // Finally, we can use the graph path to draw the edge
    const svgPathString = drawEdge(source, target, graphPath);

    // Compute the edge's middle point using the full path, so users can use
    // it to position their custom labels
    const index = Math.floor(fullPath.length / 2);
    const middlePoint = fullPath[index];
    const [middleX, middleY] = middlePoint;
    const { x: edgeCenterX, y: edgeCenterY } = gridToGraphPoint(
      { x: middleX, y: middleY },
      graphBox.xMin,
      graphBox.yMin,
      gridRatio,
    );

    return { svgPathString, edgeCenterX, edgeCenterY, smoothedPath, graphPath };
  } catch {
    return null;
  }
}

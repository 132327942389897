import { useAppSelector } from 'app/hooks';
import { SelectField } from 'components/FormFields';
import { selectRecruitmentData } from 'features/recruitment/recruitmentSlice';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CustomButton from 'styles/buttonStyled';

function AddCandidate({ setVisible, callBack, filteredListJobAppliedEval, dataParent }) {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: undefined,
  });
  const { t } = useTranslation(['recruitment', 'action']);

  const onSubmit = (data) => {
    const newData = filteredListJobAppliedEval?.find(
      (job) => job?.candidateRequisitionId === data?.appliedJob,
    );
    callBack && callBack(newData);
  };

  const filteredOptions = filteredListJobAppliedEval?.filter(
    (job) =>
      !dataParent?.candidateEvaluation?.some(
        (evaluation) => evaluation.candidateRequisitionId === job.candidateRequisitionId,
      ),
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div style={{ display: 'flex', justifyContent: 'center', fontWeight: 'bold', fontSize: 16 }}>
        {t('recruitment:add_candidate_evaluation')}
      </div>
      <SelectField
        label={t('recruitment:select_applied_job')}
        name="appliedJob"
        control={control}
        options={filteredOptions?.map((item) => ({
          label: item?.positionName,
          value: item?.candidateRequisitionId,
        }))}
        showSearch
        allowClear={false}
      />
      <div style={{ display: 'flex', justifyContent: 'center', gap: 8, marginTop: 8 }}>
        <CustomButton aType="text" onClick={() => setVisible(false)}>
          {t('action:cancel')}
        </CustomButton>
        <CustomButton htmlType="submit">{t('action:save')}</CustomButton>
      </div>
    </form>
  );
}

export default AddCandidate;

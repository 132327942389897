import styled from 'styled-components';
import HeadcountSummaryEmployeeTypeChart from './HeadcountSummaryEmployeeTypeChart';
import HeadcountSummaryFilter from '../HeadcountSummary/HeadcountSummaryFilter';
import { useState } from 'react';
import moment from 'moment';

const StyledContainer = styled.div`
  padding: 16px 24px;
  background-color: var(--cl_white);
  border-radius: 12px;
  border: 0.5px solid var(--cl_gray200);
  height: 100%;
`;

const StyledHeaderTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto;
  line-height: 20px;
  color: #121926;
`;

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export function HeadcountSummaryEmployeeType({ location }) {
  const [filters, setFilters] = useState({
    monthYear: moment(new Date()).clone().subtract(1, 'months').format('YYYY-MM'),
    locationId: 0,
  });
  return (
    <StyledContainer>
      <StyledWrapper>
        <StyledHeaderTitle>Headcount Summary by Employee Type</StyledHeaderTitle>
        <HeadcountSummaryFilter
          initialValues={{
            locationId: filters.locationId,
            monthYear: moment(new Date(filters.monthYear)),
          }}
          location={location}
          // department={department}
          onSubmit={(value) => {
            setFilters({ ...filters, ...value });
          }}
        />
      </StyledWrapper>
      <HeadcountSummaryEmployeeTypeChart filters={filters} />
    </StyledContainer>
  );
}

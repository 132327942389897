import { Tabs } from 'antd';
import { useAppSelector } from 'app/hooks';
import PageHeader from 'components/PageHeader';
import { selectConfiguration } from 'features/configuration/configurationSlice';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import ViewProject from './ViewProject';
import ViewUser from './ViewUser';

const { TabPane } = Tabs;

const HistoryProjectManage = () => {
  const location: any = useLocation();
  const { t } = useTranslation(['menu', 'action', 'onboard', 'timesheet']);
  const menuStore = useAppSelector(selectConfiguration);
  const dataScreenManage = menuStore?.dataScreenManage?.result;

  const [tab, setTab] = useState('1');
  const [titleHeader, setTitleHeader] = useState('');

  useEffect(() => {
    const temp = dataScreenManage
      ?.filter((item: any) => location.pathname === item.path)
      ?.map((item: any) => item.nameScreen);

    setTitleHeader(temp ? temp[0] : '');
  }, [dataScreenManage, location.pathname]);

  const onChange = (key: string) => {
    setTab(key);
  };

  return (
    <ContainerMain>
      <Container>
        <PageHeader title={titleHeader} />
      </Container>
      <Tabs onChange={onChange} activeKey={tab}>
        <TabPane tab={t('overtime:project')} key="1">
          <ViewProject />
        </TabPane>
        <TabPane tab={t('overtime:user')} key="2">
          <ViewUser />
        </TabPane>
      </Tabs>
    </ContainerMain>
  );
};

export default HistoryProjectManage;

export const ContainerMain = styled.div`
  background-color: $grey;
  overflow-y: hidden;
`;

export const SearchContainer = styled.div`
  width: 200px;
  margin-bottom: 20px;
`;

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

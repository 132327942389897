import React, { useEffect, useMemo, useState } from 'react';
import { Form, Input, Select, DatePicker, Radio, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { CancelButton, DoneButton } from './AddColumn/styled';
import styled from 'styled-components';
import { DatePickerIcon } from 'assets/icons';
import CustomSelect from 'styles/inputSelectStyled';
import { useEmployeeListForContractV2 } from 'hooks/fetchers/useEmployeeListForContractV2';
import employeeContractApi from 'api/employeeContractApi';
import { useContractTypeList } from 'hooks';
import { useAppSelector } from 'app/hooks';
import { selectOvertimeData } from 'features/overtime/overtimeSlice';
import { selectConfiguration } from 'features/configuration/configurationSlice';
import CustomButton from 'styles/buttonStyled';
import { selectEmployee } from '../employeeSlice';
import moment from 'moment';
import { YYYY_MM_DD } from 'constants/commons';

const { Option } = Select;

interface EmployeeSimpleFiltersProps {
  onFilterChange: (values: any) => void;
}

const EmployeeSimpleFilters: React.FC<EmployeeSimpleFiltersProps> = ({ onFilterChange }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation(['employeeContract', 'employee']);
  const filters = useMemo(() => ({ offset: 1, limit: 1000 }), []);
  const { data, isLoading } = useEmployeeListForContractV2(filters);
  const { data: dataContractType } = useContractTypeList();
  const listProject = useAppSelector(selectOvertimeData).listProject;
  const dataConfiguration = useAppSelector(selectConfiguration);
  const dataPosition = dataConfiguration?.dataPosition;
  const dataLocation = dataConfiguration?.dataLocation;
  const dataSex = dataConfiguration?.dataSex;
  const listManager = useAppSelector(selectEmployee)?.listManager?.result;

  const onApply = () => {
    form.validateFields().then((values) => {
      const filter = {
        ...values,
        projectPrimaryName: [] as string[],
        positionName: [] as string[],
        status: ['approved'] as string[],
        sex: (values.sex || '') + '',
        location: (values.location || []).map(String) as string[],
        positions: (values.positions || []).map(String) as string[],
        contractType: (values.contractType || []).map(String) as string[],
        startDate: {
          fromDate: values.startDate?.from
            ? moment(values?.startDate?.from).format(YYYY_MM_DD)
            : '',
          toDate: values.startDate?.to ? moment(values?.startDate?.to).format(YYYY_MM_DD) : '',
        },
        endDate: {
          fromDate: values.endDate?.from ? moment(values?.endDate?.from).format(YYYY_MM_DD) : '',
          toDate: values.endDate?.to ? moment(values?.endDate?.to).format(YYYY_MM_DD) : '',
        },
        contractStartDate: {
          fromDate: values.contractStartDate?.from
            ? moment(values?.contractStartDate?.from).format(YYYY_MM_DD)
            : '',
          toDate: values.contractStartDate?.to
            ? moment(values?.contractStartDate?.to).format(YYYY_MM_DD)
            : '',
        },
        contractExpirationDate: {
          fromDate: values.contractExpirationDate?.from
            ? moment(values?.contractExpirationDate?.from).format(YYYY_MM_DD)
            : '',
          toDate: values.contractExpirationDate?.to
            ? moment(values?.contractExpirationDate?.to).format(YYYY_MM_DD)
            : '',
        },
      };
      onFilterChange && onFilterChange(filter);
    });
  };

  const onClear = () => {
    form.resetFields();
  };

  return (
    <Form layout="vertical" form={form}>
      <ContainerStyled>
        <WrapperStyled>
          <TitleStyled>{t('employee:filter.title')}</TitleStyled>
          <Form.Item
            name="employeeId"
            label={t('employee:filter.employee_id')}
            style={{ fontWeight: '600' }}
          >
            <Input />
            {/* <CustomSelect
            allowClear
            showSearch
            placeholder={t('employee:filter.employee_id')}
            filterOption={(input, option) =>
              option?.props?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0 ||
              option?.props?.value?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
            }
          >
            {data?.map((data: any, key: any) => (
              <Option key={key} value={data?.employeeId}>
                {data?.employeeId}
              </Option>
            ))}
          </CustomSelect> */}
          </Form.Item>
          <Form.Item
            name={'name'}
            label={t('employee:filter.by_employee_name')}
            style={{ fontWeight: '600' }}
          >
            <Input />
            {/* <CustomSelect
            allowClear
            showSearch
            placeholder={t('employee:filter.select_employee_name')}
            filterOption={(input, option) =>
              option?.props?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0 ||
              option?.props?.value?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
            }
          >
            {data?.map((data: any, key: any) => (
              <Option key={key} value={data?.fullName}>
                {data?.fullName}
              </Option>
            ))}
          </CustomSelect> */}
          </Form.Item>
          <Form.Item
            name="projectPrimary"
            label={t('employee:filter.line')}
            style={{ fontWeight: '600' }}
          >
            <CustomSelect
              mode="multiple"
              maxTagCount={'responsive'}
              allowClear
              showSearch
              placeholder={t('employee:filter.line')}
              filterOption={
                (input, option) =>
                  option?.props?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                // ||
                // option?.props?.value?.toString()?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
              }
            >
              {listProject?.map((data: any, key: any) => (
                <Option key={key} value={data.projectCode}>
                  {data?.name}
                </Option>
              ))}
            </CustomSelect>
          </Form.Item>
          <Form.Item
            name="positions"
            label={t('employee:filter.position')}
            style={{ fontWeight: '600' }}
          >
            <CustomSelect
              mode="multiple"
              maxTagCount={'responsive'}
              allowClear
              showSearch
              placeholder={t('employee:filter.position')}
              filterOption={(input, option) =>
                option?.props?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
              }
            >
              {dataPosition?.map((data: any, key: any) => (
                <Option key={key} value={data.id}>
                  {data?.label}
                </Option>
              ))}
            </CustomSelect>
          </Form.Item>
          <Form.Item
            name="manager"
            label={t('employee:filter.manager')}
            style={{ fontWeight: '600' }}
          >
            <CustomSelect
              allowClear
              showSearch
              placeholder={t('employee:filter.manager')}
              filterOption={(input, option) =>
                option?.props?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
              }
            >
              {listManager?.map((data: any, key: any) => (
                <Option key={key} value={data?.employeeId}>
                  {data?.fullName}
                </Option>
              ))}
            </CustomSelect>
          </Form.Item>
          <Form.Item
            name="location"
            label={t('employee:filter.location')}
            style={{ fontWeight: '600' }}
          >
            <CustomSelect
              mode="multiple"
              maxTagCount={'responsive'}
              allowClear
              showSearch
              placeholder={t('employee:filter.location')}
              filterOption={(input, option) =>
                option?.props?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
              }
            >
              {dataLocation?.map((data: any, key: any) => (
                <Option key={key} value={data.id}>
                  {data?.label}
                </Option>
              ))}
            </CustomSelect>
          </Form.Item>
          <Form.Item
            name={'contractType'}
            label={t('employee:filter.by_contract_type')}
            style={{ fontWeight: '600' }}
          >
            <CustomSelect
              mode="multiple"
              maxTagCount={'responsive'}
              allowClear
              showSearch
              placeholder={t('employee:filter.select_contract_type')}
              filterOption={(input, option) =>
                option?.props?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
              }
            >
              {dataContractType?.map((data: any, key: any) => (
                <Option key={key} value={data.id}>
                  {data?.name}
                </Option>
              ))}
            </CustomSelect>
          </Form.Item>
          <Form.Item name="sex" label={t('employee:filter.gender')} style={{ fontWeight: '600' }}>
            <Radio.Group>
              {dataSex?.map((data: any, key: any) => (
                <Radio.Button key={key} value={data.id}>
                  {data?.label}
                </Radio.Button>
              ))}
            </Radio.Group>
          </Form.Item>
          <Form.Item
            style={{ marginBottom: 6, fontWeight: '600' }}
            name="startDate"
            label={t('employee:filter.employee_start_date')}
          >
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Form.Item name={['startDate', 'from']}>
                <DatePicker
                  style={{ width: 150 }}
                  picker="date"
                  allowClear={false}
                  suffixIcon={<DatePickerIcon />}
                />
              </Form.Item>
              <p style={{ marginBottom: 22 }}>to</p>
              <Form.Item name={['startDate', 'to']}>
                <DatePicker
                  style={{ width: 150 }}
                  picker="date"
                  allowClear={false}
                  suffixIcon={<DatePickerIcon />}
                />
              </Form.Item>
            </div>
          </Form.Item>
          <Form.Item
            style={{ marginBottom: 6, fontWeight: '600' }}
            name="endDate"
            label={t('employee:filter.employee_end_date')}
          >
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Form.Item name={['endDate', 'from']}>
                <DatePicker
                  style={{ width: 150 }}
                  picker="date"
                  allowClear={false}
                  suffixIcon={<DatePickerIcon />}
                />
              </Form.Item>
              <p style={{ marginBottom: 22 }}>to</p>
              <Form.Item name={['endDate', 'to']}>
                <DatePicker
                  style={{ width: 150 }}
                  picker="date"
                  allowClear={false}
                  suffixIcon={<DatePickerIcon />}
                />
              </Form.Item>
            </div>
          </Form.Item>
          <Form.Item
            style={{ marginBottom: 6, fontWeight: '600' }}
            name="contractStartDate"
            label={t('employee:filter.contract_start_date')}
          >
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Form.Item name={['contractStartDate', 'from']}>
                <DatePicker
                  style={{ width: 150 }}
                  picker="date"
                  allowClear={false}
                  suffixIcon={<DatePickerIcon />}
                />
              </Form.Item>
              <p style={{ marginBottom: 22 }}>to</p>
              <Form.Item name={['contractStartDate', 'to']}>
                <DatePicker
                  style={{ width: 150 }}
                  picker="date"
                  allowClear={false}
                  suffixIcon={<DatePickerIcon />}
                />
              </Form.Item>
            </div>
          </Form.Item>
          <Form.Item
            style={{ marginBottom: 6, fontWeight: '600' }}
            name="contractExpirationDate"
            label={t('employee:filter.contract_expiration_date')}
          >
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Form.Item name={['contractExpirationDate', 'from']}>
                <DatePicker
                  style={{ width: 150 }}
                  picker="date"
                  allowClear={false}
                  suffixIcon={<DatePickerIcon />}
                />
              </Form.Item>
              <p style={{ marginBottom: 22 }}>to</p>
              <Form.Item name={['contractExpirationDate', 'to']}>
                <DatePicker
                  style={{ width: 150 }}
                  picker="date"
                  allowClear={false}
                  suffixIcon={<DatePickerIcon />}
                />
              </Form.Item>
            </div>
          </Form.Item>
          <FooterStyled>
            <CustomButton aType="outline" onClick={onClear}>
              {t('employee:filter.clear')}
            </CustomButton>
            <CustomButton onClick={onApply}>{t('employee:filter.apply')}</CustomButton>
          </FooterStyled>
        </WrapperStyled>
      </ContainerStyled>
    </Form>
  );
};

export default EmployeeSimpleFilters;

export const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 23rem;
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: white;
  padding: 1rem 10px 0 1rem;
  box-shadow: 0px 10px 18px rgba(9, 30, 66, 0.15), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 8px;
  max-height: 500px;
  overflow-y: auto;
  &::-webkit-scrollbar-track {
    background: white;
  }
`;
export const WrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const FooterStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  position: sticky;
  bottom: 0;
  left: 0;
  background-color: white;
  z-index: 10;
  padding: 10px 0;
`;

export const TitleStyled = styled.div`
  font-size: var(--txt_font_size_h3);
  font-weight: var(--txt_font_weight_medium);
  padding-bottom: 10px;
  text-transform: capitalize;
`;

export const msalConfig = {
  auth: {
    clientId: 'f18b045f-c33a-409b-8855-c76a2e7e097b',
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: 'https://itv.kingwork.vn/',
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ['User.Read'],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: process.env.REACT_APP_MS_GRAPHME_URL,
};

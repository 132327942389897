import { Switch, SwitchProps } from 'antd';
import { Control, FieldValues, Path, useController } from 'react-hook-form';
import styled, { CSSProperties, css } from 'styled-components';

export type SwitchFieldProps<T extends FieldValues> = SwitchProps & {
  requiredIcon?: boolean;
  label?: string;
  name: Path<T>;
  control: Control<T>;
  directionLabel?: 'horizontal' | 'vertical';
  labelStyle?: CSSProperties;
};

interface StyledWrapperProps {
  directionLabel: 'horizontal' | 'vertical';
}

const StyledContainer = styled.div``;

const StyledWrapper = styled.div`
  ${(props: StyledWrapperProps) =>
    props?.directionLabel === 'horizontal' &&
    css`
      display: flex;
      align-items: center;
      gap: 8px;
    `}
`;

const StyledLabel = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: var(--cl_gray900);
  ${(props: StyledWrapperProps) =>
    props?.directionLabel === 'vertical' &&
    css`
      margin-bottom: 6px;
    `}
`;

const StyledErrorMessage = styled.div`
  color: var(--cl_error500);
  margin-top: 6px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
`;

export function SwitchField<T extends FieldValues>({
  label,
  name,
  control,
  requiredIcon = false,
  directionLabel = 'vertical',
  labelStyle,
  onChange: externalOnChange,
  checked: externalValue,
  ...rest
}: SwitchFieldProps<T>) {
  const {
    field: { onChange, value, ref },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  return (
    <StyledContainer>
      <StyledWrapper directionLabel={directionLabel}>
        {label && (
          <StyledLabel style={labelStyle} directionLabel={directionLabel}>
            {label}
          </StyledLabel>
        )}

        <Switch
          checked={value}
          onChange={(checked, event) => {
            onChange(checked);
            externalOnChange?.(checked, event);
          }}
          ref={ref}
          {...rest}
        />
      </StyledWrapper>

      {error?.message && <StyledErrorMessage>{error?.message}</StyledErrorMessage>}
    </StyledContainer>
  );
}

import styled from 'styled-components';

export const ContainerInfoStyled = styled.div`
  max-height: 100vh;
  box-sizing: border-box;
  align-items: center;
  @media only screen and (max-width: 600px) {
    height: 100%;
    flex-direction: column;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    justify-content: center;
  }
`;

export const ContainerTopStyled = styled.div`
  display: flex;
  height: 8vh;
  margin-bottom: 15px;
  justify-items: center;
  @media only screen and (max-width: 600px) {
    height: 100%;
    flex-direction: column;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    justify-content: center;
  }
`;

export const ContainerBodyStyled = styled.div`
  display: flex;
  box-sizing: border-box;
  padding-left: 10%;
  padding-right: 10%;
  margin-bottom: 20px;
  align-items: center;
  @media only screen and (max-width: 600px) {
    height: 100%;
    flex-direction: column;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    justify-content: center;
  }
`;

export const ContainerVideoStyled = styled.div`
  display: flex;
  justify-items: center;
  flex-direction: column;
`;

export const ContainerButtomStyled = styled.div`
  height: 34vh;
  background: #0e86f4;
  @media only screen and (max-width: 600px) {
    height: 100%;
    flex-direction: column;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    justify-content: center;
  }
`;

export const MobileContainerStyled = styled.div`
  height: 100%;
  flex-direction: column;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  justify-content: center;
`;

export const WebBoxStyled = styled.div`
  width: 37.2%;
  max-height: 550px;
  background-color: white;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding: 2rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 5.5%;
  @media only screen and (max-width: 600px) {
    width: 100%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 0.8rem;
    margin-right: 0px;
    margin-bottom: 1.2rem;
  }
`;

export const MobileBoxStyled = styled.div`
  width: 100%;
  max-height: 450px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 0.8rem;
  margin-right: 0px;
  margin-bottom: 1.2rem;
`;

export const ImageStyled = styled.img`
  width: 1.5rem;
  height: 1.5rem;
`;

import { SearchIcon } from 'assets/icons';
import { InputField, SelectField } from 'components/FormFields';
import { useLineCategoryList } from 'hooks';
import { debounce } from 'lodash';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { DependentPayload } from 'types';

interface DependentFiltersProps {
  initialValues?: Partial<DependentPayload>;
  onSubmit?: (payload: DependentPayload) => void;
}

export function DependentFilters({ initialValues, onSubmit }: DependentFiltersProps) {
  const { t } = useTranslation(['action', 'payroll']);

  const { control, handleSubmit } = useForm<DependentPayload>({
    defaultValues: {
      search: '',
      ...initialValues,
    },
  });

  const { data: lineCategoryList, isLoading } = useLineCategoryList();
  const lineCategoryOptions = lineCategoryList.map((x) => ({
    label: x.name,
    value: x.projectCode,
  }));

  const handleFiltersSubmit = (formValues: DependentPayload) => {
    onSubmit?.(formValues);
  };

  const debounceSearchChange = debounce(handleSubmit(handleFiltersSubmit), 350);

  return (
    <form onSubmit={handleSubmit(handleFiltersSubmit)}>
      <StyledContainer>
        <InputField
          name="search"
          control={control}
          placeholder={t('action:search')}
          prefix={<SearchIcon />}
          onChange={() => debounceSearchChange()}
        />

        <StyledSearchField>
          <SelectField
            name="line"
            placeholder={t('payroll:contract_salary.line_category_placeholder')}
            control={control}
            options={lineCategoryOptions}
            showSearch
            allowClear
            loading={isLoading}
            onChange={handleSubmit(handleFiltersSubmit)}
          />
        </StyledSearchField>
      </StyledContainer>
    </form>
  );
}

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
const StyledSearchField = styled.div`
  width: 200px;
`;

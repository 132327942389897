import styled from 'styled-components';
import { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import PayrollSummaryEmployeeTypeFilters from './PayrollSummaryEmployeeTypeFilters';
import PayrollSummaryEmployeeTypeChart from './PayrollSummaryEmployeeTypeChart';
import { DiligencePayload, DiligenceReport, IDiligenceFilters } from 'types';
import payrollDashboardApi from 'api/payrollDashboardApi';

const StyledContainer = styled.div`
  padding: 16px 24px;
  border: 0.5px solid var(--cl_gray200);
  border-radius: 12px;
  background-color: var(--cl_white);
  height: 100%;
`;

const StyledHeaderTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto;
  line-height: 20px;
  color: #121926;
`;

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export interface IPayrollSummaryByEmployeeType {
  overTime: number;
  totalPayroll: number;
  grossSalary: number;
  totalTax: number;
  totalInsurance: number;
  color: string;
  name: string;
}

export function PayrollSummaryEmployeeType() {
  const { t } = useTranslation(['dashboard']);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [dataChart, setDataChart] = useState<DiligenceReport>({
    labels: ['Total Payroll', 'Gross Salary', 'PIT', 'Insurance', 'Overtime'],
    datasets: [
      {
        label: 'Full time',
        data: [],
        backgroundColor: ['rgb(68 114 196)'],
        borderWidth: 2,
      },
      {
        label: 'Part time',
        data: [],
        backgroundColor: ['#f58340'],
        borderWidth: 2,
      },
    ],
  });
  const [filters, setFilters] = useState<any>({
    monthYear: moment(new Date()).clone().subtract(1, 'months').format('YYYY-MM'),
  });

  useEffect(() => {
    async function fetchDataPayrollSummaryByEmployeeType() {
      try {
        setIsLoading(true);
        const response: { data: IPayrollSummaryByEmployeeType[] } =
          await payrollDashboardApi.getPayrollSummaryByEmployeeType(filters);
        // const fulltimeData = response?.data?.fulltime;
        // const parttimeData = response?.data?.parttime;

        // const fulltimeDatasetsArr = [
        //   fulltimeData?.totalPayroll,
        //   fulltimeData?.grossSalary,
        //   fulltimeData?.totalTax,
        //   fulltimeData?.totalInsurance,
        //   fulltimeData?.overTime,
        // ];
        // const parttimeDatasetsArr = [
        //   parttimeData?.totalPayroll,
        //   parttimeData?.grossSalary,
        //   parttimeData?.totalTax,
        //   parttimeData?.totalInsurance,
        //   parttimeData?.overTime,
        // ];

        const data = response?.data?.map((item) => ({
          label: item.name,
          data: [
            item.totalPayroll,
            item.grossSalary,
            item.totalTax,
            item.totalInsurance,
            item.overTime,
          ],
          backgroundColor: item.color,
          borderWidth: 2,
        }));

        setDataChart({
          ...dataChart,
          datasets: data,
        });
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }

    fetchDataPayrollSummaryByEmployeeType();
  }, [filters]);

  const handleSubmit = (value: any) => {
    const formattedMonthYear = moment(value).format('YYYY-MM');
    setFilters({ monthYear: formattedMonthYear });
  };

  return (
    <StyledContainer>
      <StyledWrapper>
        <StyledHeaderTitle>Payroll Summary by Employee Type</StyledHeaderTitle>
        <PayrollSummaryEmployeeTypeFilters
          initialValues={{ monthYear: moment(new Date(filters.monthYear)) }}
          loading={isLoading}
          onSubmit={handleSubmit}
        />
      </StyledWrapper>

      <PayrollSummaryEmployeeTypeChart
        loading={isLoading}
        dataChart={dataChart}
        // onColumnClick={handleColumnChartClick}
      />
    </StyledContainer>
  );
}

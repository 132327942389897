import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

interface TimesheetFooterProps {
  approved: number;
  pending: number;
  rejected: number;
  clickable?: boolean;
}

const TimesheetFooter = ({
  approved = 0,
  pending = 0,
  rejected = 0,
  clickable = false,
}: TimesheetFooterProps) => {
  const { t } = useTranslation(['timesheet']);
  return (
    <>
      <StatusFooter style={clickable ? { marginTop: '8px' } : {}}>
        <StatusItem>
          <StatusIcon style={{ backgroundColor: '#D1FAE5' }} />
          <span
            style={{
              marginLeft: 5,
            }}
          >{`${t('approved')} (${approved})`}</span>
        </StatusItem>
        <StatusItem>
          <StatusIcon style={{ backgroundColor: '#FEF3C7' }} />
          <span
            style={{
              marginLeft: 5,
            }}
          >{`${t('Pending')} (${pending})`}</span>
        </StatusItem>
        <StatusItem>
          <StatusIcon style={{ backgroundColor: '#FDE2E2' }} />
          <span
            style={{
              marginLeft: 5,
            }}
          >{`${t('Rejected')} (${rejected})`}</span>
        </StatusItem>
      </StatusFooter>
    </>
  );
};

export default TimesheetFooter;

export const StatusFooter = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

export const StatusItem = styled.div`
  margin-right: 40px;
  display: flex;
  align-items: center;
`;

export const StatusIcon = styled.div`
  width: 17px;
  height: 17px;
  border-radius: 4px;
  box-shadow: 0 0 4px rgb(0 0 0 / 20%);
`;

import { IconProps } from 'types';

export const OnboardIcon = ({ width = '24px', height = '24px', className }: IconProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.8701 17.9673H14.8701"
      stroke="#121926"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.8701 20.9674V14.9674"
      stroke="#121926"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.3172 9.37933C11.234 9.37101 11.1342 9.37101 11.0427 9.37933C9.06264 9.31278 7.49027 7.69049 7.49027 5.69383C7.48195 3.65557 9.13751 2 11.1758 2C13.214 2 14.8696 3.65557 14.8696 5.69383C14.8696 7.69049 13.2889 9.31278 11.3172 9.37933Z"
      stroke="#121926"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.3713 13.0043C12.0931 12.021 8.99716 12.1947 7.00802 13.5256C4.99472 14.8651 4.99472 17.0614 7.00802 18.4091C8.02597 19.0878 9.32514 19.4656 10.662 19.5425"
      stroke="#121926"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

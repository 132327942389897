import queryString from 'query-string';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosClient from 'api/axiosClient';
import { setGlobalLoading } from 'app/commonRedux/appSlice';
import { notificationToast } from 'components/notificationToast';
import { actions } from './trainingSlice';
import { Notification } from 'constants/notification';

export const getTrainingCategory = createAsyncThunk(
  '/training/category',
  async (params: any, thunkApi: any) => {
    try {
      const response = await axiosClient.get(`/training/category`);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getTrainingStatus = createAsyncThunk(
  '/training/status',
  async (params: any, thunkApi: any) => {
    try {
      const response = await axiosClient.get(`/training/status`);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getTrainingList = createAsyncThunk('/training', async (params: any, thunkApi: any) => {
  try {
    const response = await axiosClient.get(`/training`);
    return response;
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const getTrainingDetail = createAsyncThunk(
  '/training/detail/:id',
  async (params: any, thunkApi: any) => {
    try {
      const { id, query } = params;
      const response = await axiosClient.get(
        `/training/detail/${id}?${queryString.stringify(query)}`,
      );
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const createTraining = createAsyncThunk(
  'create/training',
  async (payload: any, thunkApi: any) => {
    try {
      const { body, callBack } = payload;
      const response = await axiosClient.post(`/training`, body, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      response && callBack && callBack();
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getMyTrainingList = createAsyncThunk(
  '/training/my-training',
  async (params: any, thunkApi: any) => {
    try {
      const response = await axiosClient.get(
        `/training/my-training?${queryString.stringify(params)}`,
      );
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const postFeedBack = createAsyncThunk(
  '/training/feedback',
  async (params: any, thunkApi: any) => {
    try {
      const { body } = params;
      const response = await axiosClient.post(`/training/feedback`, body);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getTraineeList = createAsyncThunk(
  '/training/trainee-management',
  async (params: any, thunkApi: any) => {
    try {
      const response = await axiosClient.get(
        `/training/trainee-management?${queryString.stringify(params)}`,
      );
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getTraineeDetail = createAsyncThunk(
  '/training/trainee-management/:id',
  async (params: any, thunkApi: any) => {
    try {
      const { userId, ...rest } = params;
      const response = await axiosClient.get(
        `/training/trainee-management/${userId}?${queryString.stringify(rest)}`,
      );
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const patchTraining = createAsyncThunk(
  '/training/:id',
  async (payload: any, thunkApi: any) => {
    try {
      const { id, formData, callBack } = payload;
      const response = await axiosClient.patch(`/training/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      response && callBack && callBack();
      notificationToast(Notification.Type.Success, response?.message, Notification.Duration._3s);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const exportTraining = createAsyncThunk(
  '/training/export',
  async (payload: any, thunkApi: any) => {
    try {
      let str: any = `Training.xlsx`;
      const response: any = await axiosClient.get(`/training/export`, { responseType: 'blob' });
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', str);
      document.body.appendChild(link);
      link.click();
      return response;
    } catch (error) {
      return error;
    }
  },
);

export const exportTrainingDetail = createAsyncThunk(
  '/training/detail/export/:id',
  async (payload: any, thunkApi: any) => {
    try {
      let str: any = `Training_detail.xlsx`;
      const { id } = payload;
      const response: any = await axiosClient.get(`/training/detail/export/${id}`, {
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', str);
      document.body.appendChild(link);
      link.click();
      return response;
    } catch (error) {
      return error;
    }
  },
);

export const notifyTraining = createAsyncThunk(
  '/training/notify',
  async (payload: any, thunkApi: any) => {
    try {
      const response = await axiosClient.post(`/training/notify`, payload);
      notificationToast(
        Notification.Type.Success,
        'Notify to assigned trainees successful',
        Notification.Duration._3s,
      );
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const confirmTraining = createAsyncThunk(
  '/training/confirm',
  async (payload: any, thunkApi: any) => {
    try {
      const response = await axiosClient.post(`/training/confirm`, payload);
      notificationToast(Notification.Type.Success, response?.message, Notification.Duration._3s);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const updateResult = createAsyncThunk(
  '/training/update-result',
  async (payload: any, thunkApi: any) => {
    try {
      const response = await axiosClient.post(`/training/update-result`, payload);
      // notificationToast(Notification.Type.Success, response?.message, Notification.Duration._3s);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const addAssignee = createAsyncThunk(
  '/training/assignee/add',
  async (payload: any, thunkApi: any) => {
    try {
      const { callBack, filteredData } = payload;
      const response = await axiosClient.post(`/training/assignee/add`, filteredData);
      // notificationToast(Notification.Type.Success, response?.message, Notification.Duration._3s);
      response && callBack && callBack();
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const searchAssignee = createAsyncThunk(
  '/training/assignee/search',
  async (params: any, thunkApi: any) => {
    try {
      const { search, ...rest } = params;
      const response = await axiosClient.post(
        `/training/assignee/search?${queryString.stringify(rest)}`,
        { keyword: search },
      );
      // notificationToast(Notification.Type.Success, response?.message, Notification.Duration._3s);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

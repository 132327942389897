import { selectCurrencyFormat } from 'app/commonRedux/appSlice';
import { useAppSelector } from 'app/hooks';
import { selectShowMoney } from 'features/auth/authSlice';
import { formatCurrencyUnitVND } from 'features/insurances/constants/common';
import { useMaskValue } from 'hooks';
import numeral from 'numeral';

const MaskedValueRenderer: React.FC<{ value: number | string }> = ({ value }) => {
  const isShowMoney = useAppSelector(selectShowMoney);
  const currencyFormat = useAppSelector(selectCurrencyFormat);
  const maskedValue = useMaskValue(isNaN(Number(value)) || !value ? 0 : value, isShowMoney);
  return maskedValue !== '*****' ? formatCurrencyUnitVND(maskedValue, 'VND') : maskedValue; //numeral(maskedValue).format(currencyFormat)
};

export default MaskedValueRenderer;

import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import AuthHeader from 'features/auth/components/AuthHeader';
import AuthFooter from 'features/auth/components/AuthFooter';
import AuthVideo from 'features/auth/components/AuthVideo';
import AuthVideoMobile from 'features/auth/components/AuthVideoMobile';
import AuthSignIn from 'features/auth/components/AuthSignIn';

import {
  ContainerInfoStyled,
  ContainerTopStyled,
  ContainerBodyStyled,
  ContainerVideoStyled,
  ContainerButtomStyled,
  MobileContainerStyled,
  WebBoxStyled,
  MobileBoxStyled,
} from './styled';

interface Props {}
const Auth = (props: Props) => {
  localStorage.setItem('hrm.register', 'no');
  if (!isMobileOnly) {
    return (
      <ContainerInfoStyled>
        <ContainerTopStyled>
          <AuthHeader />
        </ContainerTopStyled>
        <ContainerBodyStyled>
          <WebBoxStyled>
            <AuthSignIn />
          </WebBoxStyled>
          <ContainerVideoStyled>
            <AuthVideo />
          </ContainerVideoStyled>
        </ContainerBodyStyled>
        <ContainerButtomStyled>
          <AuthFooter />
        </ContainerButtomStyled>
      </ContainerInfoStyled>
    );
  } else {
    return (
      <MobileContainerStyled>
        <MobileBoxStyled>
          <AuthSignIn />
        </MobileBoxStyled>
        <AuthVideoMobile />
      </MobileContainerStyled>
    );
  }
};

export default Auth;

import debounce from 'lodash/debounce';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { InputField } from 'components/FormFields';
import { SearchIcon } from 'assets/icons';
import { PayrollByYearPayload } from 'types';

const StyledContainer = styled.form`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

interface PayrollByYearFiltersProps {
  initialValues?: PayrollByYearPayload;
  onSubmit?: (payload: PayrollByYearPayload) => void;
}

export function PayrollByYearFilters({ initialValues, onSubmit }: PayrollByYearFiltersProps) {
  const { t } = useTranslation(['timesheet']);
  const { control, handleSubmit } = useForm<PayrollByYearPayload>({
    defaultValues: {
      search: '',
      ...initialValues,
    },
  });

  const handleFiltersSubmit = (payload: PayrollByYearPayload) => {
    onSubmit?.(payload);
  };

  const debounceSearchChange = debounce(handleSubmit(handleFiltersSubmit), 350);

  return (
    <form onSubmit={handleSubmit(handleFiltersSubmit)}>
      <StyledContainer>
        <InputField
          name="search"
          control={control}
          placeholder={t('search_placeholder')}
          prefix={<SearchIcon />}
          onChange={debounceSearchChange}
        />
      </StyledContainer>
    </form>
  );
}

import React, { useCallback, useEffect, useState } from 'react';
import { Table, Progress, Modal, InputNumber, Input } from 'antd';
import CustomButton from 'styles/buttonStyled';
import AddCandidate from './AddCandidate';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { getListJobAppliedEval } from 'features/recruitment/recruitmentAction';
import { useParams } from 'react-router-dom';
import { isInterviewer } from 'features/recruitment/jobs/components/types';
import styled from 'styled-components';
import { selectRecruitmentData } from 'features/recruitment/recruitmentSlice';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

const NotedInput = ({ initialValue, disabled, recordKey, onSave }) => {
  const [value, setValue] = useState(initialValue);

  // Keep local state in sync with the initial value if it changes
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleBlur = () => {
    onSave(recordKey, value);
  };

  return (
    <Input
      value={value}
      disabled={disabled}
      onChange={(e) => setValue(e.target.value)}
      onBlur={handleBlur}
    />
  );
};

const CandidateEval = ({ data: dataParent, handleChange }) => {
  const { t } = useTranslation(['recruitment']);
  const { id } = useParams();
  const [data, setData] = React.useState([]);
  const dispatch = useAppDispatch();
  const [visible, setVisible] = React.useState(false);
  const listJobAppliedEval = useAppSelector(selectRecruitmentData)?.listJobAppliedEval;

  useEffect(() => {
    dispatch(getListJobAppliedEval({ candidateId: id }));
  }, [dispatch, id]);

  useEffect(() => {
    if (dataParent && dataParent.candidateEvaluation) {
      const transformedData = dataParent.candidateEvaluation?.flatMap((evaluation) =>
        evaluation.candidateEvaluation?.flatMap((candidateEval) =>
          candidateEval.evaluation?.flatMap((evaluator) =>
            evaluator.data?.map((item, itemIndex) => ({
              key: `${_.uniqueId()}-${evaluation?.candidateRequisitionId}-${
                candidateEval?.candidateEvaluationId
              }-${item?.name}-${evaluator?.evaluatedById}`,
              candidateProcessId: candidateEval?.candidateProcessId,
              candidateEvaluationId: candidateEval?.candidateEvaluationId,
              overallScore: evaluator?.overallScore,
              positionName: evaluation?.positionName,
              competencyName: item?.name,
              score: item?.score,
              noted: item?.comment,
              jobStage: candidateEval?.stageName || '',
              evaluatedBy: evaluator?.evaluatedByName || '',
              evaluatedById: evaluator?.evaluatedById || '',
              minimumScore: item?.minimumScore || 0,
              maximumScore: item?.maximumScore || 10,
              evaluationCount: candidateEval?.evaluation?.length,
              isApplied: evaluation?.isApplied,
              candidateRequisitionId: evaluation?.candidateRequisitionId,
              candidateId: evaluation?.candidateId,
              isEdit: evaluator?.isEdit,
            })),
          ),
        ),
      );

      setData(transformedData);
    }
  }, [dataParent]);

  const debouncedHandleChange = useCallback(
    (updatedEvaluation) => {
      handleChange && handleChange(updatedEvaluation);
    },
    [handleChange],
  );

  const handleScoreChange = (key, newScore) => {
    setData((prevData) => {
      const updatedData = prevData.map((item) =>
        item.key === key && item.isEdit
          ? { ...item, score: Math.max(item.minimumScore, Math.min(newScore, item.maximumScore)) }
          : item,
      );

      const updatedEvaluation = transformDataToEvaluation(updatedData);
      debouncedHandleChange(updatedEvaluation);

      return updatedData;
    });
  };

  const handleNotedChange = (key, newNoted) => {
    setData((prevData) => {
      const updatedData = prevData.map((item) =>
        item.key === key && item.isEdit ? { ...item, noted: newNoted } : item,
      );
      const updatedEvaluation = transformDataToEvaluation(updatedData);
      handleChange && handleChange(updatedEvaluation);
      return updatedData;
    });
  };

  const transformDataToEvaluation = (data) => {
    const groupedData = data.reduce((acc, item) => {
      const [, candidateRequisitionId, candidateEvaluationId, , evaluatedById] =
        item.key.split('-');
      if (!acc[candidateEvaluationId]) {
        acc[candidateEvaluationId] = {
          candidateProcessId: item.candidateProcessId,
          candidateEvaluationId: item.candidateEvaluationId,
          stageName: item.jobStage,
          positionName: item.positionName,
          candidateRequisitionId: item?.candidateRequisitionId,
          evaluation: [],
        };
      }

      let evaluatorIndex = acc[candidateEvaluationId].evaluation.findIndex(
        (e) => e.evaluatedById === evaluatedById,
      );
      if (evaluatorIndex === -1) {
        acc[candidateEvaluationId].evaluation.push({
          data: [],
          overallScore: item.overallScore,
          evaluatedById: item.evaluatedById,
          evaluatedByName: item.evaluatedBy,
          isEdit: item.isEdit,
        });
        evaluatorIndex = acc[candidateEvaluationId].evaluation.length - 1;
      }

      acc[candidateEvaluationId].evaluation[evaluatorIndex].data.push({
        name: item.competencyName,
        score: item.score,
        comment: item.noted,
        minimumScore: item.minimumScore,
        maximumScore: item.maximumScore,
      });

      return acc;
    }, {});

    return {
      candidateEvaluation: Object.values(groupedData),
    };
  };

  const columns = [
    {
      title: t('recruitment:position_name'),
      dataIndex: 'overallScore',
      key: 'overallScore',
      align: 'center',
      render: (score, row, index) => {
        const obj = {
          children: (
            <div style={{ fontWeight: 'bold', display: 'flex', flexDirection: 'column', gap: 5 }}>
              <div style={{ fontSize: 16 }}>{row?.positionName}</div>
              <div style={{ fontSize: 14 }}>{t('recruitment:overall_score')}</div>
              <Progress
                type="circle"
                percent={(+(row?.overallScore || 0) / (row?.maximumScore || 10)) * 100}
                width={100}
                format={() => `${row?.overallScore || 0}/${row?.maximumScore || 10}`}
              />
            </div>
          ),
          props: {},
        };
        if (
          index === 0 ||
          data[index - 1]?.candidateRequisitionId !== row?.candidateRequisitionId ||
          data[index - 1]?.evaluatedById !== row?.evaluatedById
        ) {
          obj.props.rowSpan = data.filter(
            (item) =>
              item?.candidateRequisitionId === row?.candidateRequisitionId &&
              item?.evaluatedById === row?.evaluatedById,
          ).length;
        } else {
          obj.props.rowSpan = 0;
        }
        return obj;
      },
    },
    {
      title: t('recruitment:competency_name'),
      dataIndex: 'competencyName',
      key: 'competencyName',
    },
    {
      title: t('recruitment:score'),
      dataIndex: 'score',
      key: 'score',
      width: 155,
      render: (text, record) => (
        <CustomInputNumber
          value={text}
          disabled={!record?.isEdit}
          onChange={(value) => handleScoreChange(record.key, value)}
          min={record?.minimumScore}
          max={record?.maximumScore}
          addonAfter={`/${record?.maximumScore || ''}`}
        />
      ),
    },
    {
      title: t('recruitment:noted'),
      dataIndex: 'noted',
      key: 'noted',
      render: (text, record) => (
        <NotedInput
          initialValue={text}
          disabled={!record.isEdit}
          recordKey={record.key}
          onSave={handleNotedChange}
        />
      ),
    },
    {
      title: t('recruitment:job_stage'),
      dataIndex: 'jobStage',
      key: 'jobStage',
    },
    {
      title: t('recruitment:evaluated_by'),
      dataIndex: 'evaluatedBy',
      key: 'evaluatedBy',
      //merge if have same evaluatedById
      render: (value, row, index) => {
        const obj = {
          children: value,
          props: {},
        };
        if (
          index === 0 ||
          data[index - 1]?.candidateRequisitionId !== row?.candidateRequisitionId ||
          data[index - 1]?.evaluatedById !== row?.evaluatedById
        ) {
          obj.props.rowSpan = data.filter(
            (item) =>
              item?.candidateRequisitionId === row?.candidateRequisitionId &&
              item?.evaluatedById === row?.evaluatedById,
          ).length;
        } else {
          obj.props.rowSpan = 0;
        }
        return obj;
      },
    },
  ];

  const handleChangeAddCandidate = (newData) => {
    const transformedData =
      newData?.candidateEvaluation?.flatMap((evaluation) =>
        evaluation?.evaluation?.flatMap((evaluator) =>
          evaluator?.data?.map((item, itemIndex) => ({
            key: `${itemIndex}-${newData?.candidateRequisitionId}-${evaluation?.candidateEvaluationId}-${item?.name}-${evaluator?.evaluatedById}`,
            candidateProcessId: evaluation?.candidateProcessId,
            candidateEvaluationId: evaluation?.candidateEvaluationId,
            overallScore: evaluator?.overallScore,
            positionName: newData?.positionName,
            competencyName: item?.name,
            score: item?.score,
            noted: item?.comment,
            jobStage: evaluation?.stageName || '',
            evaluatedBy: evaluator?.evaluatedByName || '',
            evaluatedById: evaluator?.evaluatedById || '',
            minimumScore: item?.minimumScore || 0,
            maximumScore: item?.maximumScore || 10,
            evaluationCount: evaluation?.evaluation?.length,
            isApplied: newData?.isApplied,
            candidateRequisitionId: newData?.candidateRequisitionId,
            candidateId: newData?.candidateId,
            isEdit: evaluator?.isEdit,
          })),
        ),
      ) || [];

    setData((prevData) => {
      const updatedData = [...prevData, ...transformedData];
      const updatedEvaluation = transformDataToEvaluation(updatedData);
      handleChange && handleChange(updatedEvaluation);
      return updatedData;
    });

    setVisible(false);
  };

  const filteredListJobAppliedEval = listJobAppliedEval?.filter(
    (job) => !data.some((item) => item?.candidateRequisitionId === job?.candidateRequisitionId),
  );

  return (
    <>
      {
        <CustomButton onClick={() => setVisible(true)}>
          + {t('recruitment:add_candidate_evaluation')}
        </CustomButton>
      }
      <Table columns={columns} dataSource={data} bordered pagination={false} />
      <Modal open={visible} onCancel={() => setVisible(false)} footer={null} destroyOnClose>
        <AddCandidate
          setVisible={setVisible}
          callBack={handleChangeAddCandidate}
          filteredListJobAppliedEval={filteredListJobAppliedEval || []}
          dataParent={dataParent}
        />
      </Modal>
    </>
  );
};

export default CandidateEval;

const CustomInputNumber = styled(InputNumber)`
  .ant-input-number-input {
    text-align: right;
  }
  .ant-input-number-handler-wrap {
    display: none;
  }
`;

import styled from 'styled-components';
import { WarningOutlined } from '@ant-design/icons';
export const ChooseLeaveDayListWrapper = styled.div`
  display: inline-block;
  height: 400px;
  overflow: auto;
  padding: 10px;
`;
export const ChooseLeaveDayListStyled = styled.div<{ sizeList: 'large' | 'small' }>`
  display: grid;
  grid-template-columns: repeat(${({ sizeList }) => (sizeList === 'large' ? 2 : 1)}, 1fr);
  gap: 1.5rem;
  grid-auto-rows: minmax(2rem, auto);
`;

export const ItemGrid = styled.div`
  position: relative;
  box-shadow: 0px 1px 5px -2px gray;
  border-radius: 10px;
`;
export const Abbreviations = styled.div<{ sizeList: 'large' | 'small' }>`
  display: inline-block;
  width: ${({ sizeList }) => (sizeList === 'large' ? '6rem' : '4rem')};
  height: ${({ sizeList }) => (sizeList === 'large' ? '6rem' : '4rem')};
  background-color: #ffc55a;
  border-radius: 10px 0 0 10px;
`;

export const ContentAbbreviations = styled.span<{ sizeList: 'large' | 'small' }>`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: ${({ sizeList }) => (sizeList === 'large' ? '1.8em' : '1.4em')};
  letter-spacing: 1px;
  font-weight: 300;
`;

export const ItemContent = styled.div<{ sizeList: 'large' | 'small' }>`
  display: inline-block;
  height: 100%;
  vertical-align: top;
  font-size: ${({ sizeList }) => (sizeList === 'large' ? '1.3em' : '1.1em')};
  font-weight: 600;
  padding: ${({ sizeList }) => (sizeList === 'large' ? '10px 20px' : '10px 10px')};
`;

export const ItemFullName = styled.div`
  margin-bottom: 10px;
`;

export const SelectAllDays = styled.div`
  font-size: 1.2em;
  text-align: right;
  grid-column: 2;
  padding: 10px 0;
`;
export const WarningOutlinedStyled = styled(WarningOutlined)`
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  font-size: 0.6em;
  color: orange;
`;

export const ButtonBack = styled.button`
  background-color: white;
  border: none;
  margin-right: 1rem;
  cursor: pointer;
`;

export const SearchWrapper = styled.div`
  width: 200px;
  float: left;
`;

import { ArrowRightOutlined } from '@ant-design/icons';
import { DatePicker, Divider, Form, Tag } from 'antd';
import { selectFormatDate } from 'app/commonRedux/appSlice';
import { useAppSelector } from 'app/hooks';
import countBy from 'lodash/countBy';
import filter from 'lodash/filter';
import moment from 'moment';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import CustomInput from 'styles/inputStyled';
import Workflow2ViewHistory from './Workflow2ViewHistory';

const { RangePicker } = DatePicker;

function pair(arr: any, number = 2) {
  return arr.reduce(
    (acc: any, cur: any, i: any) =>
      i % number
        ? Object.assign([...acc], {
            [acc.length - 1]: [...acc[acc.length - 1], cur],
          })
        : [...acc, [cur]],
    [],
  );
}
function compareUpdatedLabelNode(currentItem: any) {
  const isSameLabelNode = (a: any, b: any) => a?.data.label === b?.data.label;
  const onlyInLeft = (left: any, right: any, compareFunction: any) =>
    left?.filter(
      (leftValue: any) => !right?.some((rightValue: any) => compareFunction(leftValue, rightValue)),
    );
  const onlyInA = onlyInLeft(currentItem?.oldNodes, currentItem?.newNodes, isSameLabelNode);

  const onlyInB = onlyInLeft(currentItem?.newNodes, currentItem?.oldNodes, isSameLabelNode);

  const resultsUpdateLabelNode = [...(onlyInA || []), ...(onlyInB || [])].sort(
    (a, b) => parseFloat(b.id) - parseFloat(a.id),
  );

  const counts = countBy(resultsUpdateLabelNode, 'id');

  const resultsUpdateLabelNodeDuplicate = filter(resultsUpdateLabelNode, (x) => counts[x.id] > 1);

  return resultsUpdateLabelNodeDuplicate;
}

function compareUpdatedStatusNode(currentItem: any) {
  const isSameLabelNode = (a: any, b: any) => {
    return a?.id === b?.id && a?.data?.status?.status === b?.data?.status?.status;
  };
  const onlyInLeft = (left: any, right: any, compareFunction: any) =>
    left?.filter(
      (leftValue: any) =>
        !right?.some((rightValue: any) => {
          return compareFunction(leftValue, rightValue);
        }),
    );
  const onlyInA = onlyInLeft(currentItem?.oldNodes, currentItem?.newNodes, isSameLabelNode);

  const onlyInB = onlyInLeft(currentItem?.newNodes, currentItem?.oldNodes, isSameLabelNode);

  const resultsUpdateStatusNode = [...(onlyInA || []), ...(onlyInB || [])].sort(
    (a, b) => parseFloat(b.id) - parseFloat(a.id),
  );

  const counts = countBy(resultsUpdateStatusNode, 'id');

  const resultsUpdateStatusNodeDuplicate = filter(resultsUpdateStatusNode, (x) => counts[x.id] > 1);

  return resultsUpdateStatusNodeDuplicate;
}
function compareUpdatedPermissionEditEmployeeNode(updated: any, original: any) {
  return (
    updated
      ?.map((item: any) => {
        return {
          employees: item?.data?.employees?.filter(
            ({ permissionEdit, id }: any) =>
              original
                ?.find((platform: any) => platform.id === item?.id)
                ?.data.employees?.find((itemE: any) => itemE.id === id)?.permissionEdit !==
              permissionEdit,
          ),
          idNode: item?.id,
          labelNode: item?.data?.label,
        };
      })
      // eslint-disable-next-line array-callback-return
      ?.flatMap((item) => {
        if (original?.[item?.idNode] !== undefined) {
          return item?.employees?.map(({ permissionEdit, id, fullName }) => ({
            permissionEdit,
            permissionEditOld: original[item?.idNode]?.data?.employees?.find(
              (item) => item?.id === id,
            )?.permissionEdit,
            id,
            idNode: item?.idNode,
            labelNode: item?.labelNode,
            fullName,
          }));
        }
      })
      ?.filter(function (element) {
        return element !== undefined;
      })
  );
}
function compareUpdatedPermissionDeleteEmployeeNode(updated, original) {
  return (
    updated
      ?.map((item) => {
        return {
          employees: item?.data?.employees?.filter(
            ({ permissionDelete, id }) =>
              original
                ?.find((platform) => platform.id === item?.id)
                ?.data.employees?.find((itemE) => itemE.id === id)?.permissionDelete !==
              permissionDelete,
          ),
          idNode: item?.id,
          labelNode: item?.data?.label,
        };
      })
      // eslint-disable-next-line array-callback-return
      ?.flatMap((item) => {
        if (original?.[item?.idNode] !== undefined) {
          return item?.employees?.map(({ permissionDelete, id, fullName }) => ({
            permissionDelete,
            permissionDeleteOld: original[item?.idNode]?.data?.employees?.find(
              (item) => item?.id === id,
            )?.permissionDelete,
            id,
            idNode: item?.idNode,
            labelNode: item?.labelNode,
            fullName,
          }));
        }
      })
      ?.filter(function (element) {
        return element !== undefined;
      })
  );
}
function compareAddMultiEmployeeNode(updated, original) {
  let arr = updated
    ?.map((item) => {
      return {
        employees: item?.data?.employees?.filter(
          ({ id }) =>
            original
              ?.find((platform) => platform.id === item?.id)
              ?.data.employees?.find((itemE) => itemE.id === id)?.id !== id,
        ),
        idNode: item?.id,
        labelNode: item?.data?.label,
      };
    })
    // eslint-disable-next-line array-callback-return
    ?.flatMap((item) => {
      if (original?.[item?.idNode] !== undefined) {
        return item?.employees?.map(({ id, fullName }) => ({
          id,
          idNode: item?.idNode,
          labelNode: item?.labelNode,
          fullName,
        }));
      }
    })
    .filter(function (element) {
      return element !== undefined;
    });

  const uniqObjs = [];
  const dupeObjs = [];

  arr?.forEach((obj) =>
    [uniqObjs, dupeObjs][
      +(arr.map((obj) => obj.idNode).filter((idNode) => idNode === obj.idNode).length > 1)
    ].push(obj),
  );

  const grouped = Object.values(
    dupeObjs.reduce((a, curr) => {
      const { labelNode } = curr;
      const key = `${labelNode}`;
      (a[key] = a[key] || []).push(curr);
      return a;
    }, {}),
  );

  return grouped;
}
function compareAddOneEmployeeNode(updated, original) {
  let arr = updated
    ?.map((item) => {
      return {
        employees: item?.data?.employees?.filter(
          ({ id }) =>
            original
              ?.find((platform) => platform.id === item?.id)
              ?.data.employees?.find((itemE) => itemE.id === id)?.id !== id,
        ),
        idNode: item?.id,
        labelNode: item?.data?.label,
      };
    })
    // eslint-disable-next-line array-callback-return
    ?.flatMap((item) => {
      if (original?.[item?.idNode] !== undefined) {
        return item?.employees?.map(({ id, fullName }) => ({
          id,
          idNode: item?.idNode,
          labelNode: item?.labelNode,
          fullName,
        }));
      }
    })
    .filter(function (element) {
      return element !== undefined;
    });

  const uniqObjs = [];
  const dupeObjs = [];

  arr?.forEach((obj) =>
    [uniqObjs, dupeObjs][
      +(arr.map((obj) => obj.idNode).filter((idNode) => idNode === obj.idNode).length > 1)
    ].push(obj),
  );
  return uniqObjs;
}
function compareDeleteMultiEmployeeNode(updated, original) {
  let arr = updated
    ?.map((item) => {
      return {
        employees: item?.data?.employees?.filter(
          ({ id }) =>
            original
              ?.find((platform) => platform.id === item?.id)
              ?.data.employees?.find((itemE) => itemE.id === id)?.id !== id,
        ),
        idNode: item?.id,
        labelNode: item?.data?.label,
      };
    })
    // eslint-disable-next-line array-callback-return
    ?.flatMap((item) => {
      if (original[item?.idNode] !== undefined) {
        return item?.employees?.map(({ id, fullName }) => ({
          id,
          idNode: item?.idNode,
          labelNode: item?.labelNode,
          fullName,
        }));
      }
    })
    .filter(function (element) {
      return element !== undefined;
    });

  const uniqObjs = [];
  const dupeObjs = [];

  arr?.forEach((obj) =>
    [uniqObjs, dupeObjs][
      +(arr.map((obj) => obj.idNode).filter((idNode) => idNode === obj.idNode).length > 1)
    ].push(obj),
  );
  const grouped = Object.values(
    dupeObjs.reduce((a, curr) => {
      const { labelNode } = curr;
      const key = `${labelNode}`;
      (a[key] = a[key] || []).push(curr);
      return a;
    }, {}),
  );
  return grouped;
}
function compareDeleteOneEmployeeNode(updated, original) {
  let arr = updated
    ?.map((item) => {
      return {
        employees: item?.data?.employees?.filter(
          ({ id }) =>
            original
              ?.find((platform) => platform.id === item?.id)
              ?.data.employees?.find((itemE) => itemE.id === id)?.id !== id,
        ),
        idNode: item?.id,
        labelNode: item?.data?.label,
      };
    })
    // eslint-disable-next-line array-callback-return
    ?.flatMap((item) => {
      if (original[item?.idNode] !== undefined) {
        return item?.employees?.map(({ id, fullName }) => ({
          id,
          idNode: item?.idNode,
          labelNode: item?.labelNode,
          fullName,
        }));
      }
    })
    .filter(function (element) {
      return element !== undefined;
    });

  const uniqObjs = [];
  const dupeObjs = [];

  arr?.forEach((obj) =>
    [uniqObjs, dupeObjs][
      +(arr.map((obj) => obj.idNode).filter((idNode) => idNode === obj.idNode).length > 1)
    ].push(obj),
  );
  return uniqObjs;
}
function compareAddDeleteLabelNode(currentItem) {
  const isSameLabelNode = (a, b) => a?.data.label === b?.data.label;
  const onlyInLeft = (left, right, compareFunction) =>
    left?.filter(
      (leftValue) => !right?.some((rightValue) => compareFunction(leftValue, rightValue)),
    );
  const onlyInA = onlyInLeft(currentItem?.oldNodes, currentItem?.newNodes, isSameLabelNode);
  const onlyInB = onlyInLeft(currentItem?.newNodes, currentItem?.oldNodes, isSameLabelNode);

  const resultsUpdateLabelNode = [...(onlyInA || []), ...(onlyInB || [])].sort(
    (a, b) => parseFloat(b.id) - parseFloat(a.id),
  );
  const counts = countBy(resultsUpdateLabelNode, 'id');
  const resultsUpdateLabelNodeNotDuplicate = filter(
    resultsUpdateLabelNode,
    (x) => counts[x.id] <= 1,
  );
  return resultsUpdateLabelNodeNotDuplicate;
}
//Compare edge
function compareUpdatedLabelEdge(currentItem) {
  let oldEdges = currentItem?.oldEdges?.map((item, idx) => {
    return { ...item, id: `${idx + 1}` };
  });

  let newEdges = currentItem?.newEdges?.map((item, idx) => {
    return { ...item, id: `${idx + 1}` };
  });

  const isSameLabelNode = (a: any, b: any) => a?.id === b?.id && a?.label === b?.label;
  const onlyInLeft = (left: any, right: any, compareFunction: any) =>
    left?.filter(
      (leftValue: any) => !right?.some((rightValue: any) => compareFunction(leftValue, rightValue)),
    );
  const onlyInA = onlyInLeft(oldEdges, newEdges, isSameLabelNode);
  const onlyInB = onlyInLeft(newEdges, oldEdges, isSameLabelNode);

  const resultsUpdateLabeledge = [...(onlyInA || []), ...(onlyInB || [])].sort(
    (a, b) => parseFloat(b.id) - parseFloat(a.id),
  );

  const counts = countBy(resultsUpdateLabeledge, 'id');
  const resultsUpdateLabelNodeDuplicate = filter(resultsUpdateLabeledge, (x) => counts[x.id] > 1);

  return resultsUpdateLabelNodeDuplicate;
}
function compareAddDeleteLabelNodeEdge(currentItem) {
  let oldEdges = currentItem?.oldEdges
    ?.filter((item) => item.id !== '0')
    ?.map((item, idx) => {
      return { ...item, id: `${idx + 1}` };
    });

  let newEdges = currentItem?.newEdges
    ?.filter((item) => item.id !== '0')
    ?.map((item, idx) => {
      return { ...item, id: `${idx + 1}` };
    });

  // b diff a
  let resultA = oldEdges?.filter(
    (elm) => !newEdges?.map((elm) => JSON.stringify(elm)).includes(JSON.stringify(elm)),
  );

  // newEdges diff oldEdges
  let resultB = newEdges?.filter(
    (elm) => !oldEdges?.map((elm) => JSON.stringify(elm)).includes(JSON.stringify(elm)),
  );
  const resultsUpdateLabeledge = [...(resultA || []), ...(resultB || [])].sort(
    (a, b) => parseFloat(b.id) - parseFloat(a.id),
  );
  const counts = countBy(resultsUpdateLabeledge, 'id');

  const resultsUpdateLabelEdgeNotDuplicate = filter(
    resultsUpdateLabeledge,
    (x) => counts[x.id] <= 1,
  );
  return resultsUpdateLabelEdgeNotDuplicate;
}
const ModalViewWorkflowTypeHistory = (props: any) => {
  const { form, modalType, currentItem } = props;
  const [edges, setEdges] = useState(currentItem?.newEdges);
  const [nodes, setNodes] = useState(currentItem?.newNodes);
  const [selectAction, setSelectAction] = useState();
  const [selectStatus, setSelectStatus] = useState();
  const format = useAppSelector(selectFormatDate);

  useEffect(() => {
    setSelectAction(currentItem?.actionGroupId); //Khi edit thi dropdown dc
    // setIdRow(dataFindOneWorkflowType?.workflowTypeDetail?.[0]?.id);
    setSelectStatus(currentItem?.statusGroupId);
    form.setFieldsValue({
      businessGroup: currentItem?.workflowTypeDetail?.workflowType?.businessModule?.businessName,
      workflowId: currentItem?.workflowTypeCode,
      workflowName: currentItem?.workflowTypeName,
      status: currentItem?.workflowTypeDetail?.statusGroup?.groupName,
      action: currentItem?.workflowTypeDetail?.actionGroup?.groupName,
      description: currentItem?.description,
      date: [moment(currentItem?.startDate), moment(new Date(2999, 11, 31))],
      active: currentItem?.status,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentItem, modalType]);

  return (
    <>
      <Form form={form} layout="vertical">
        <EmployeeAddModalContent>
          <EmployeeAddModalContentLeft>
            <CustomFormItem name={'businessGroup'} label={<div>Business</div>}>
              {/* <CustomSelect disabled allowClear value={selectValue}></CustomSelect> */}
              <CustomInput disabled type="text" />
            </CustomFormItem>
            <CustomFormItem name={'workflowId'} label={`Workflow Id`}>
              <CustomInput disabled type="text" />
            </CustomFormItem>
            <CustomFormItem name={'workflowName'} label={`Workflow Name`}>
              <CustomInput disabled type="text" />
            </CustomFormItem>
            <CustomFormItem name={'status'} label={<div>Status Group</div>}>
              {/* <CustomSelect disabled allowClear value={selectStatus}></CustomSelect> */}
              <CustomInput disabled type="text" />
            </CustomFormItem>
            <CustomFormItem name={'action'} label={<div>Action Group</div>}>
              {/* <CustomSelect disabled allowClear value={selectAction}></CustomSelect> */}
              <CustomInput disabled type="text" />
            </CustomFormItem>
            <CustomFormItem name={'date'} label={`Date`}>
              <RangePicker disabled format={format} style={{ width: '100%' }} />
            </CustomFormItem>
            <CustomFormItem name={'active'} label={<div>Status</div>}>
              <CustomInput disabled type="text" />
            </CustomFormItem>
            <CustomFormItem name={'description'} label={`Description`}>
              <CustomInput disabled type="text" />
            </CustomFormItem>
          </EmployeeAddModalContentLeft>
          <EmployeeAddModalDivider type="vertical" />
          <EmployeeAddModalContentRight>
            <Workflow2ViewHistory
              setNodes={setNodes}
              setEdges={setEdges}
              edges={edges}
              nodes={nodes}
              currentItem={currentItem}
              modalType={modalType}
              selectAction={selectAction}
              selectStatus={selectStatus}
            />
            <div style={{ display: 'flex' }}>
              <div
                style={{
                  width: '80%',
                  // color: 'red',
                  height: 80,
                  overflowY: 'scroll',
                  marginTop: '1%',
                }}
              >
                {/* Khi them moi hoac xoa 1 node */}
                {compareAddDeleteLabelNode(currentItem).map((x) => (
                  <div>
                    <div>
                      {currentItem?.newNodes.findIndex(
                        (x) => x.id === compareAddDeleteLabelNode(currentItem)[0]?.id,
                      ) === -1
                        ? 'Deleted '
                        : 'Created '}
                      a node <b>{x.data.label}</b>
                    </div>
                  </div>
                ))}
                {/* Khi sua description cua 1 node */}
                {pair(compareUpdatedLabelNode(currentItem)).map((x) => (
                  <div key={x?.id}>
                    <div>
                      Updated <b>description</b> in node <b>{x[1]?.data.label}</b>
                    </div>
                    <div
                      style={{
                        marginLeft: '20px',
                      }}
                    >
                      <Tag>{x[0]?.data.label}</Tag> <ArrowRightOutlined />{' '}
                      <Tag>{x[1]?.data.label}</Tag>
                    </div>
                  </div>
                ))}
                {pair(compareUpdatedStatusNode(currentItem)).map((x) => (
                  <div key={x[0]?.id}>
                    <div>
                      Updated <b>Status</b> in node <b>{x[1]?.data.label}</b>
                    </div>
                    <div
                      style={{
                        marginLeft: '20px',
                      }}
                    >
                      <Tag>{x[0]?.data.status.status}</Tag> <ArrowRightOutlined />{' '}
                      <Tag>{x[1]?.data.status.status}</Tag>
                    </div>
                  </div>
                ))}
                {/* Tao 1 nhan vien trong 1 node */}
                {compareAddOneEmployeeNode(currentItem?.newNodes, currentItem?.oldNodes)?.map(
                  (item) => {
                    return (
                      <div key={item?.id}>
                        <div>
                          Created <b>a employee</b> in node <b>{item?.labelNode}</b>
                        </div>
                        <div
                          style={{
                            marginLeft: '20px',
                          }}
                        >
                          <Tag>{item?.fullName}</Tag>
                        </div>
                      </div>
                    );
                  },
                )}
                {/* Tao nhieu nhan vien trong 1 node */}

                {compareAddMultiEmployeeNode(currentItem?.newNodes, currentItem?.oldNodes)?.map(
                  (item, index) => {
                    return (
                      <div key={item?.id}>
                        <div>
                          Created <b>employee</b> in node <b>{item?.[index]?.labelNode}</b>
                        </div>
                        {item?.map((itemChild) => {
                          return (
                            <div
                              key={itemChild?.id}
                              style={{
                                marginLeft: '20px',
                              }}
                            >
                              <Tag>{itemChild?.fullName}</Tag>
                            </div>
                          );
                        })}
                      </div>
                    );
                  },
                )}
                {/* Xoa nhieu nhan vien trong 1 node */}
                {compareDeleteMultiEmployeeNode(currentItem?.oldNodes, currentItem?.newNodes)?.map(
                  (item, index) => {
                    return (
                      <div key={item?.id}>
                        <div>
                          Deleted <b>employee</b> in node <b>{item?.[index]?.labelNode}</b>
                        </div>
                        {item?.map((itemChild) => {
                          return (
                            <div
                              key={itemChild?.id}
                              style={{
                                marginLeft: '20px',
                              }}
                            >
                              <Tag>{itemChild?.fullName}</Tag>
                            </div>
                          );
                        })}
                      </div>
                    );
                  },
                )}

                {/* Xoa 1 nhan vien trong node */}
                {compareDeleteOneEmployeeNode(currentItem?.oldNodes, currentItem?.newNodes)?.map(
                  (item, index) => {
                    return (
                      <div key={item?.id}>
                        <div>
                          Deleted <b>employee</b> in node <b>{item?.labelNode}</b>
                        </div>
                        <div
                          key={item?.id}
                          style={{
                            marginLeft: '20px',
                          }}
                        >
                          <Tag>{item?.fullName}</Tag>
                        </div>
                      </div>
                    );
                  },
                )}

                {compareUpdatedPermissionEditEmployeeNode(
                  currentItem?.newNodes,
                  currentItem?.oldNodes,
                )?.map((item) => {
                  return item?.permissionEditOld !== undefined ? (
                    <div key={item.id}>
                      <div>
                        Updated <b>permission edit of {item?.fullName} employee </b> in node{' '}
                        <b>{item?.labelNode}</b>
                      </div>
                      <div
                        style={{
                          marginLeft: '20px',
                        }}
                      >
                        <Tag>{item?.permissionEditOld ? 'Yes' : 'No'}</Tag> <ArrowRightOutlined />{' '}
                        <Tag>{item?.permissionEdit ? 'Yes' : 'No'}</Tag>
                      </div>
                    </div>
                  ) : (
                    <></>
                  );
                })}
                {compareUpdatedPermissionDeleteEmployeeNode(
                  currentItem?.newNodes,
                  currentItem?.oldNodes,
                )?.map((item) => {
                  return item?.permissionDeleteOld !== undefined ? (
                    <div key={item.id}>
                      <div>
                        Updated <b>permission delete of {item?.fullName} employee </b> in node{' '}
                        <b>{item?.labelNode}</b>
                      </div>
                      <div
                        style={{
                          marginLeft: '20px',
                        }}
                      >
                        <Tag>{item?.permissionDeleteOld ? 'Yes' : 'No'}</Tag> <ArrowRightOutlined />{' '}
                        <Tag>{item?.permissionDelete ? 'Yes' : 'No'}</Tag>
                      </div>
                    </div>
                  ) : (
                    <></>
                  );
                })}

                {/* Khi sua name action cua 1 edge */}
                {pair(compareUpdatedLabelEdge(currentItem)).map((x: any) => (
                  <div key={x.id}>
                    <div>
                      Updated <b>name action</b> in line <b>{x[1].label}</b>
                    </div>
                    <div
                      style={{
                        marginLeft: '20px',
                      }}
                    >
                      <Tag>{x[0].label}</Tag> <ArrowRightOutlined /> <Tag>{x[1].label}</Tag>
                    </div>
                  </div>
                ))}
                {/* Khi them moi hoac xoa 1 edge */}
                {compareAddDeleteLabelNodeEdge(currentItem).map((x) => (
                  <div>
                    {currentItem?.newEdges
                      ?.map((item: any, idx: any) => {
                        return { ...item, id: `${idx + 1}` };
                      })
                      .findIndex(
                        (x) => x.id === compareAddDeleteLabelNodeEdge(currentItem)[0]?.id,
                      ) === -1
                      ? 'Deleted '
                      : 'Created '}
                    a edge <b key={x.id}>{x.label}</b>
                  </div>
                ))}
              </div>
            </div>
          </EmployeeAddModalContentRight>
        </EmployeeAddModalContent>
      </Form>
    </>
  );
};

export default ModalViewWorkflowTypeHistory;
export const CustomFormItem = styled(Form.Item)`
  margin-bottom: 5px;
  .ant-form-item-label {
    padding: 0px;
  }
`;
export const EmployeeAddModalContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  height: 100%;
  width: 100%;
  margin-right: -15px;
`;

export const EmployeeAddModalContentLeft = styled.div`
  padding-right: 10px;
  height: 531px;
  min-width: 200px;
  width: 32%%;
`;

export const EmployeeAddModalContentRight = styled.div`
  width: 100%;
  height: 446px;
  width: 82%;
`;

export const EmployeeAddModalDivider = styled(Divider)`
  height: 504px;
  border-left-width: 5px;
  border-radius: 2px;
`;

import { ITimesheetAdjustmentFilters, TimesheetAdjustmentReport, SuccessResponse } from 'types';
import queryString from 'query-string';

import {
  ComparisonFilters,
  DiligenceReport,
  IDiligenceSummaryFilters,
  IDiligenceFilters,
  DiligenceSummaryReport,
  Statistic,
  BirthdayParams,
  Birthday,
  HolidayParams,
  Holiday,
  ExpiredContractParams,
  ExpiredContract,
  NewLeaveOffParams,
  EmployeeGrowthParams,
  EmployeeGrowthResponse,
  LeaveOffStatisticReport,
  ILeaveOffSummaryFilters,
  LeaveOffSummaryReport,
  NewLeaveOffResponse,
  IEmploymentRateFilters,
} from 'types/dashboard';
import axiosClient from './axiosClient';

const dashboardApi = {
  getDashboard: (params: any) => {
    const response = axiosClient.get(`/dashboard?${queryString.stringify(params)}`);
    return response;
  },
  getStatistic(): Promise<SuccessResponse<Statistic>> {
    return axiosClient.get('/dashboard');
  },
  getHoliday(params: HolidayParams): Promise<SuccessResponse<Holiday[]>> {
    return axiosClient.get('dashboard/holidays', {
      params: params,
    });
  },
  getBirthday(params: BirthdayParams): Promise<SuccessResponse<Birthday[]>> {
    return axiosClient.get('/dashboard/birthdays', {
      params: params,
    });
  },
  getEmployeeChart: (params: any) => {
    const response = axiosClient.get(
      `/dashboard/get-employee-chart?${queryString.stringify(params)}`,
    );
    return response;
  },
  getLeaveOffChart: (params: any) => {
    const response = axiosClient.get(
      `/dashboard/request-leave-off-chart?${queryString.stringify(params)}`,
    );
    return response;
  },
  getExpiredContract: (
    params: ExpiredContractParams,
  ): Promise<SuccessResponse<ExpiredContract[]>> => {
    return axiosClient.get(`/dashboard/get-contract-expire`, {
      params,
    });
  },
  getRequestLeaveOff(params: NewLeaveOffParams): Promise<SuccessResponse<NewLeaveOffResponse>> {
    return axiosClient.get(`/dashboard/request-leave-off`, {
      params,
    });
  },
  getGrowthChart(params: EmployeeGrowthParams): Promise<SuccessResponse<EmployeeGrowthResponse>> {
    return axiosClient.get(`/dashboard/growth-chart`, {
      params,
    });
  },
  getGrowthPercent: (params: any) => {
    const response = axiosClient.get(`/dashboard/growth-percent?${queryString.stringify(params)}`);
    return response;
  },
  getAllAgeEmployee: (params: any) => {
    const { data } = params;
    const response = axiosClient.get(`/dashboard/all-age-employee?${queryString.stringify(data)}`);
    return response;
  },
  getAllEmployeeContracts: (params: any) => {
    const response = axiosClient.get(`/dashboard/contract`);
    return response;
  },
  getNewEmployee: (params: any) => {
    const response = axiosClient.get(`/dashboard/new-employee?${queryString.stringify(params)}`);
    return response;
  },
  getLeaveOffStatistic(
    params: ComparisonFilters,
  ): Promise<SuccessResponse<LeaveOffStatisticReport>> {
    return axiosClient.get('/dashboard/compare-leave-off-chart', {
      params: params,
    });
  },
  getReportDiligence(params: IDiligenceFilters): Promise<SuccessResponse<DiligenceReport>> {
    return axiosClient.get('/dashboard/report-diligence', {
      params: params,
    });
  },
  getLeaveOffSummary(
    params: ILeaveOffSummaryFilters,
  ): Promise<SuccessResponse<LeaveOffSummaryReport>> {
    return axiosClient.get('/dashboard/leave-off-chart-by-year', {
      params: params,
    });
  },
  getSubViewBy(
    viewBy: string,
  ): Promise<SuccessResponse<Array<{ id: string | number; name: string }>>> {
    return axiosClient.get('/dashboard/dashboard-group-report', {
      params: {
        groupBy: viewBy,
      },
    });
  },
  getReportDiligenceSummary(
    params: IDiligenceSummaryFilters,
  ): Promise<SuccessResponse<DiligenceSummaryReport>> {
    return axiosClient.get('/dashboard/diligence-chart-by-year', {
      params: params,
    });
  },
  getReportTimesheetAdjustment(
    params: ITimesheetAdjustmentFilters,
  ): Promise<SuccessResponse<TimesheetAdjustmentReport>> {
    return axiosClient.get('/dashboard/report-adjust', {
      params: params,
    });
  },
  getReportEmploymentRate(params: {
    monthYear: string;
    viewBy: string;
    objectId?: string;
  }): Promise<SuccessResponse<any>> {
    return axiosClient.get(`timesheet/attendance-rate-report?${queryString.stringify(params)}`);
  },
  exportEmploymentRate(params: {
    monthYear: string;
    viewBy: string;
    objectId?: string;
  }): Promise<SuccessResponse<any>> {
    return axiosClient.post(`/timesheet/attendance-rate-report/export`, params, {
      responseType: 'blob',
    });
  },
};

export default dashboardApi;

import { Tabs } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Container } from './styled';
import PageHeader from 'components/PageHeader';
import { useAppSelector } from 'app/hooks';
import { selectConfiguration } from 'features/configuration/configurationSlice';
import MenuAction from 'components/MenuAction';
import CustomButton from 'styles/buttonStyled';
import CourseDetail from './CourseDetail';
import { confirmTraining, getMyTrainingList } from 'features/training/trainingAction';
import { selectTrainingData } from 'features/training/trainingSlice';
import moment from 'moment';
import { EditOutlined } from '@ant-design/icons';
import { YYYY_MM_DD } from 'constants/commons';
// import ITVTable from './components/ITVTableSingle';
import ITVSearch from 'components/ITVSearch';
import ITVTable from 'components/ITVTable';
import { useTranslation } from 'react-i18next';
import { TitleColumnCenter } from 'styles/tableStyled';

const { TabPane } = Tabs;

const MyTraining = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { id }: any = useParams();

  const { t } = useTranslation(['employee', 'action']);
  const menuStore = useAppSelector(selectConfiguration);
  const dataScreenManage = menuStore?.dataScreenManage?.result;
  const trainData = useAppSelector(selectTrainingData);
  const myTrainingList = trainData?.myTrainingList;
  const [titleHeader, setTitleHeader] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [sizePage, setSizePage] = useState(10);
  const [item, setItem] = useState([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    const temp = dataScreenManage
      ?.filter((item) => location.pathname === item.path)
      ?.map((item) => item.nameScreen);

    setTitleHeader(temp ? temp[0] : '');
  }, [dataScreenManage, location.pathname]);

  useEffect(() => {
    dispatch(
      getMyTrainingList({
        limit: sizePage,
        offset: currentPage,
        searchName: search,
      }),
    );
  }, [dispatch, sizePage, currentPage, search]);

  const menu = [
    {
      name: t('action:edit'),
      icon: <EditOutlined style={{ color: 'red' }} />,
      handleClick: (data: any) => {
        history.push(`/my-page/my-training/${data?.id}`);
      },
    },
  ];

  const columns = [
    {
      title: () => {
        return <TitleColumnCenter>{t('employee:my_training.course_name')}</TitleColumnCenter>;
      },
      key: 'courseName',
      dataIndex: 'courseName',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (text) => {
        return text;
      },
    },
    {
      title: () => {
        return <TitleColumnCenter>{t('employee:my_training.training_date')}</TitleColumnCenter>;
      },
      key: 'description',
      dataIndex: 'description',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (_, record: any) => {
        const dateFrom = record?.dateFrom ? moment(record?.dateFrom).format(YYYY_MM_DD) : '';
        const dateTo = record?.dateTo ? moment(record?.dateTo).format(YYYY_MM_DD) : '';
        return `${dateFrom} - ${dateTo}`;
      },
    },
    {
      title: () => {
        return <TitleColumnCenter>{t('employee:my_training.description')}</TitleColumnCenter>;
      },
      key: 'description',
      dataIndex: 'description',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (text) => {
        return text;
      },
    },
    {
      title: () => {
        return <TitleColumnCenter>{t('employee:my_training.result')}</TitleColumnCenter>;
      },
      key: 'result',
      dataIndex: 'result',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (_: any, record: any) => {
        return record?.userDetail?.result || '';
      },
    },
    {
      title: () => {
        return <TitleColumnCenter>{t('employee:my_training.status')}</TitleColumnCenter>;
      },
      key: 'status',
      dataIndex: 'status',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (_: any, record: any) => {
        return record?.userDetail?.status || '';
      },
    },
    {
      title: () => {
        return <TitleColumnCenter>{t('employee:my_training.action')}</TitleColumnCenter>;
      },
      key: 'action',
      width: 80,
      minWidth: 80,
      align: 'center',
      fixed: 'right',
      render: (text, record) => {
        return <MenuAction menu={menu} data={record} />;
      },
    },
  ];

  // const rowSelection = {
  //   type: 'radio',
  //   onChange: (selectedRowKeys, selectedRows) => {
  //     setItem(selectedRows[0]?.id);
  //   },
  // };

  const confirm = () => {
    dispatch(confirmTraining({ courseIds: item }));
  };

  return (
    <Container>
      <PageHeader title={titleHeader} />
      <div style={{ marginBottom: 16 }}>
        <CustomButton onClick={confirm}>
          {t('employee:my_training.confirm_participation')}
        </CustomButton>
      </div>
      <ITVSearch style={{ marginBottom: '1rem' }} handleSearch={setSearch} />
      <ITVTable
        data={myTrainingList?.result || []}
        columns={columns}
        setSelectedUser={setItem}
        isRowSelect={true}
        limit={sizePage}
        setLimit={setSizePage}
        offset={currentPage}
        setOffset={setCurrentPage}
        totalResult={myTrainingList?.totalResult}
      />
    </Container>
  );
};

export default MyTraining;

import styled from 'styled-components';
import { Drawer, Card, Tag, Form, Menu, Modal, Radio } from 'antd';
import ReactFlow from 'react-flow-renderer';
export const CustomDrawer = styled(Drawer)`
  .ant-drawer-mask {
    background: transparent !important;
  }
  .ant-drawer-content-wrapper {
    height: 550px;
  }
`;
export const CustomCard = styled(Card)`
  margin-bottom: 5px;
`;
export const CustomTag = styled(Tag)`
  font-size: 16px;
`;
export const CustomFormItem = styled(Form.Item)`
  margin-top: 10px;
  margin-bottom: 0px !important;
`;
export const CustomMenu = styled(Menu)`
  .ant-menu .ant-menu-item {
    padding: 0px 0px !important;
    height: 100% !important;
    white-space: normal !important;
  }
  &.ant-menu .ant-menu-submenu .ant-menu-submenu-title {
    border: 1px solid #dedede;
    padding-left: 9px;
    margin-bottom: 22px;
  }
  .ant-menu-inline .ant-menu-item::after {
    border-right: none;
  }
  .ant-menu-item:active {
    background: none;
  }
  .ant-menu .ant-menu-item-selected {
    background: none !important;
  }
`;
export const InfoRadio = styled(Radio)`
  .ant-radio-disabled .ant-radio-inner {
    border-color: #f1592a;
  }
  .ant-radio-inner::after {
    background: #f1592a;
    border-color: #f1592a;
  }
`;
export const CustomReactFlow = styled(ReactFlow)`
  .react-flow__attribution {
    display: none;
  }
  path.react-flow__edge-path {
    stroke-width: 2;
    cursor: pointer;
    stroke: black;
  }
  path.react-flow__edge-path:hover {
    stroke-width: 4;
    cursor: pointer;
    stroke: black;
  }
  .react-flow__edge-textbg {
    cursor: pointer;
  }
  .react-flow__edge-text {
    font-weight: 700 !important;
    font-size: 12px !important;
  }
  .react-flow__node {
    background: #fff;
    border: 1px solid #1a192b;
    border-radius: 3px;
    color: #222;
    font-size: 12px;
    padding: 10px;
    text-align: center;
    width: 150px;
  }
`;
export const CustomFormItem1 = styled(Form.Item)`
  margin-bottom: 0;
`;
export const CustomModal = styled(Modal)`
  .ant-modal-title {
    font-weight: 700;
    font-size: 18px;
  }
`;

import { ChangeEvent } from 'react';
import { Input, InputProps } from 'antd';
import { Control, useController, FieldValues, Path } from 'react-hook-form';
import styled, { CSSProperties, css } from 'styled-components';

export type InputFieldProps<T extends FieldValues> = InputProps & {
  requiredIcon?: boolean;
  label?: string;
  name: Path<T>;
  control: Control<T>;
  directionLabel?: 'horizontal' | 'vertical';
  labelStyle?: CSSProperties;
  inputStyle?: CSSProperties;
};

interface StyledContainerProps {
  hasPrefix: boolean;
}

interface StyledWrapperProps {
  directionLabel: 'horizontal' | 'vertical';
}

const StyledContainer = styled.div`
  .ant-input,
  .ant-input-affix-wrapper {
    border-radius: 6px;
    border: 1px solid var(--cl_neutral300);
  }
  ${(props: StyledContainerProps) =>
    props?.hasPrefix &&
    css`
      .ant-input {
        border: none;
      }
    `}
`;

const StyledWrapper = styled.div`
  ${(props: StyledWrapperProps) =>
    props?.directionLabel === 'horizontal' &&
    css`
      display: flex;
      align-items: center;
      gap: 8px;
    `}
`;

const StyledLabel = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: var(--cl_gray900);
  ${(props: StyledWrapperProps) =>
    props?.directionLabel === 'vertical' &&
    css`
      margin-bottom: 6px;
    `}
`;

const StyledErrorMessage = styled.div`
  color: var(--cl_error500);
  margin-top: 6px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
`;

export function InputField<T extends FieldValues>({
  label,
  name,
  control,
  prefix,
  requiredIcon = false,
  directionLabel = 'vertical',
  labelStyle,
  inputStyle,
  onChange: externalOnChange,
  onBlur: externalOnBlur,
  value: externalValue,
  ...rest
}: InputFieldProps<T>) {
  const {
    field: { onBlur, onChange, value, ref },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  return (
    <StyledContainer hasPrefix={Boolean(prefix)}>
      <StyledWrapper directionLabel={directionLabel}>
        {label && (
          <StyledLabel style={labelStyle} directionLabel={directionLabel}>
            {label}
          </StyledLabel>
        )}

        <Input
          value={value}
          ref={ref}
          prefix={prefix}
          status={error?.message ? 'error' : undefined}
          style={inputStyle}
          onBlur={onBlur}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            onChange(event);
            externalOnChange?.(event);
          }}
          {...rest}
        />
      </StyledWrapper>

      {error?.message && <StyledErrorMessage>{error?.message}</StyledErrorMessage>}
    </StyledContainer>
  );
}

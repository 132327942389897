import { ArrowDownOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import { selectFormatDate, selectFormatDatetime } from 'app/commonRedux/appSlice';
import { useAppSelector } from 'app/hooks';
import ITVTable from 'components/ITVTable';
import EmployeeColumn from 'features/employee/components/EmployeeColumn';
import { getHistoryPlanProjectDetail, getProjectList } from 'features/overtime/overtimeAction';
import { selectOvertimeData } from 'features/overtime/overtimeSlice';
import { TimeSheetStatus } from 'hrm-common/extensions/enums/personel';
import cond from 'lodash/cond';
import stubTrue from 'lodash/stubTrue';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { TitleColumnCenter } from 'styles/tableStyled';
import { removeAccents } from 'utils/text';

const { Option } = Select;

const ViewProject = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState<any>([]);
  const { t } = useTranslation(['validation', 'overtime', 'timesheet']);
  const [sizePage, setSizePage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const dateFormat = useAppSelector(selectFormatDate);
  const formatDatetime = useAppSelector(selectFormatDatetime);
  const overtimeData = useAppSelector(selectOvertimeData);
  const listProject = overtimeData?.listProject;
  const dataHistoryPlanProjectDetail = overtimeData?.dataHistoryPlanProjectDetail;
  const loadingDataHistoryPlanProjectDetail = overtimeData?.loadingDataHistoryPlanProjectDetail;
  const [project, setProject] = useState('');
  const [projectStatusState] = useState('');
  const [text] = useState('');

  const searchFilterSortParam = {
    projectCode: project,
    status: projectStatusState,
    limit: sizePage,
    offset: currentPage,
  };
  const [paramSortFilter, setParamSortFilter] = useState(searchFilterSortParam);

  useEffect(() => {
    dispatch(getProjectList());
  }, [dispatch]);

  useEffect(() => {
    setParamSortFilter({
      ...paramSortFilter,
      projectCode: project,
      status: projectStatusState,
      limit: sizePage,
      offset: currentPage,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectStatusState, project, sizePage, currentPage]);

  useEffect(() => {
    dispatch(getHistoryPlanProjectDetail(paramSortFilter));
  }, [dispatch, paramSortFilter]);

  useEffect(() => {
    if (dataHistoryPlanProjectDetail?.result) {
      //fix here for api get
      if (text) {
        const temp = dataHistoryPlanProjectDetail.result.filter((item: any) => {
          return (
            removeAccents(item?.projectCode?.toLowerCase()).includes(text) ||
            removeAccents(item?.overtimeRequestApprove?.project?.name?.toLowerCase()).includes(text)
          );
        });
        setData(temp);
      } else setData(dataHistoryPlanProjectDetail.result);
    }
  }, [dataHistoryPlanProjectDetail, text]);

  const getColor = cond([
    [(x: string) => x === TimeSheetStatus.APPROVED, () => '#10B981'],
    [(x: string) => x === TimeSheetStatus.PENDING, () => '#fcc059'],
    [(x: string) => x === TimeSheetStatus.REJECTED, () => '#EF4444'],
    [stubTrue, () => 'black'],
  ]);

  const COLUMNS: any = [
    {
      title: <TitleColumnCenter>{t('overtime:project')}</TitleColumnCenter>,
      dataIndex: 'project',
      key: 'project',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: function (text: any, record: any, index: any) {
        const obj: any = {
          children: record?.projectCode + '-' + record?.overtimeRequestApprove?.project?.name || '',
          props: {
            style: {
              verticalAlign: 'top',
            },
          },
        };
        if (index >= 1 && data[index - 1]?.projectCode === record?.projectCode) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            index + i !== data.length && data[index + i]?.projectCode === record?.projectCode;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }
        return obj;
      },
    },
    {
      title: <TitleColumnCenter>{t('overtime:duration')}</TitleColumnCenter>,
      dataIndex: 'duration',
      key: 'duration',
      width: 150,
      minWidth: 150,
      align: 'center',
      render: function (text: any, record: any, index: any) {
        const trueIndex = index + sizePage * (currentPage - 1);
        const obj: any = {
          children:
            data[trueIndex + 1]?.overtimeRequestApprove?.id ===
              record?.overtimeRequestApprove?.id &&
            (data[trueIndex + 1]?.startDate !== record?.startDate ||
              data[trueIndex + 1]?.endDate !== record?.endDate) ? (
              <div>
                {`${
                  data[trueIndex + 1]?.startDate
                    ? moment(data[trueIndex + 1]?.startDate).format(dateFormat)
                    : ''
                } `}
                {`${
                  data[trueIndex + 1]?.endDate
                    ? '~ ' + moment(data[trueIndex + 1]?.endDate).format(dateFormat)
                    : ''
                }`}
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <ArrowDownOutlined />
                </div>
                {`${record?.startDate ? moment(record?.startDate).format(dateFormat) : ''} `}
                {`${record?.endDate ? '~ ' + moment(record?.endDate).format(dateFormat) : ''}`}
              </div>
            ) : (
              <div>
                {`${record?.startDate ? moment(record?.startDate).format(dateFormat) : ''} `}
                {`${record?.endDate ? '~ ' + moment(record?.endDate).format(dateFormat) : ''}`}
              </div>
            ),
          props: {
            style: {
              verticalAlign: 'top',
            },
          },
        };
        if (
          index >= 1 &&
          data[trueIndex - 1]?.projectCode === record?.projectCode &&
          data[trueIndex - 1]?.startDate === record?.startDate &&
          data[trueIndex - 1]?.endDate === record?.endDate
        ) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== data.length &&
            data[trueIndex + i]?.projectCode === record?.projectCode &&
            data[trueIndex + i]?.startDate === record?.startDate &&
            data[trueIndex + i]?.endDate === record?.endDate;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }
        return obj;
      },
    },
    {
      title: t('overtime:information'),
      children: [
        {
          title: t('overtime:updated_at'),
          dataIndex: 'updatedAt',
          align: 'center',
          key: 'updatedAt',
          width: 100,
          minWidth: 100,
          render: function (text: any, record: any, index: any) {
            const obj: any = {
              children: text ? moment(text).format(formatDatetime) : null,
              props: {
                style: {
                  verticalAlign: 'top',
                },
              },
            };
            return obj;
          },
        },
        {
          title: t('overtime:overtime_date'),
          dataIndex: 'overtimeDate',
          key: 'overtimeDate',
          align: 'center',
          width: 100,
          minWidth: 100,
          render: function (text: any, record: any, index: any) {
            const obj: any = {
              children: text ? moment(text).format(dateFormat) : null,
              props: {
                style: {
                  verticalAlign: 'top',
                },
              },
            };
            return obj;
          },
        },
        {
          title: t('overtime:overtime_hour'),
          dataIndex: 'actualHour',
          key: 'actualHour',
          width: 150,
          minWidth: 150,
          align: 'center',
          render: function (text: any, record: any, index: any) {
            const obj: any = {
              children: (
                <div
                  style={{
                    color: record?.actualHour > record?.totalHour ? 'red' : getColor(record.status),
                    fontWeight: record?.actualHour > record?.totalHour ? 'bold' : 'normal',
                  }}
                >
                  {record?.actualHour?.toFixed(2) || '0.00'}
                </div>
              ),
              props: {
                style: {
                  verticalAlign: 'top',
                },
              },
            };
            return obj;
          },
        },
      ],
    },
    {
      title: t('overtime:updated_by'),
      dataIndex: 'updatedBy',
      key: 'updatedBy',
      width: 100,
      minWidth: 100,
      render: function (text: any, record: any, index: any) {
        const obj: any = {
          children: (
            <EmployeeColumn avatar={text?.fullName} fullName={text?.fullName} email={text?.email} />
          ),
          props: {
            style: {
              verticalAlign: 'top',
            },
          },
        };
        return obj;
      },
    },
  ];

  return (
    <Container>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ width: '100%', display: 'flex', paddingBottom: '10px' }}>
          <div>
            <Select
              showSearch
              style={{ width: 200 }}
              defaultValue={project}
              onChange={(value: any) => setProject(value)}
              filterOption={(input, option: any) =>
                (option!.value as unknown as string).toLowerCase().includes(input.toLowerCase())
              }
            >
              <Option value="">{t('overtime:all_projects')}</Option>
              {listProject?.map((item: any) => {
                return (
                  <Option value={item?.projectCode} title={item?.name}>
                    {item?.projectCode} - {item?.name}
                  </Option>
                );
              })}
            </Select>
          </div>
        </div>
      </div>
      <ITVTable
        isRowSelect={false}
        loading={loadingDataHistoryPlanProjectDetail}
        columns={COLUMNS}
        isScroll={true}
        width={'max-content'}
        data={data}
        setOffset={setCurrentPage}
        offset={currentPage}
        setLimit={setSizePage}
        limit={sizePage}
        totalResult={dataHistoryPlanProjectDetail?.total}
        totalIni={dataHistoryPlanProjectDetail?.total}
      />
    </Container>
  );
};

export default ViewProject;

export const SearchContainer = styled.div`
  width: 200px;
  margin-bottom: 20px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  background: white;
  width: 100%;
  height: max-content;
`;

import { EyeOutlined } from '@ant-design/icons';
import { useAppSelector } from 'app/hooks';
import ButtonBack from 'components/ButtonBack';
import EmployeeColumn from 'components/EmployeeColumn';
import ITVSearch from 'components/ITVSearch';
import ITVTable from 'components/ITVTable';
import MenuAction from 'components/MenuAction';
import { YYYY_MM_DD } from 'constants/commons';
import { selectConfiguration } from 'features/configuration/configurationSlice';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { getHRPerformanceView } from '../performanceAction';
import { selectPerformanceData } from '../performanceSlice';
import { useTranslation } from 'react-i18next';

export default function PerformanceReviewHR() {
  const { t } = useTranslation(['recruitment', 'performance']);
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const location: any = useLocation();
  const stateURL = location.state;
  const nameTitle = stateURL?.name ? stateURL.name : '';
  const menuStore = useAppSelector(selectConfiguration);
  const dataScreenManage = menuStore?.dataScreenManage?.result;
  const performanceStore = useAppSelector(selectPerformanceData);
  const performanceDataHR = performanceStore?.performanceDataHR;
  const [titleHeader, setTitleHeader] = useState('');
  const [sizePage, setSizePage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchName, setSearchName] = useState('');

  const callApi = () => {
    dispatch(
      getHRPerformanceView({ page: currentPage, limit: sizePage, searchName, reviewCycleId: id }),
    );
  };

  useEffect(() => {
    callApi();
  }, [dispatch, currentPage, sizePage, id, searchName]);

  useEffect(() => {
    const temp = dataScreenManage
      ?.filter((item: any) => location.pathname === item.path)
      ?.map((item: any) => item.nameScreen);

    setTitleHeader(temp ? temp[0] : '');
  }, [dataScreenManage, location.pathname]);

  const menu = [
    {
      name: t('performance:performance_review.view_detail'),
      icon: <EyeOutlined style={{ color: '#1890FF' }} />,
      handleClick: (value: any) => {
        // dispatch(getDetailCycle({ id: value?.id }));
        // setCreateMode(true);
        // setViewMode(true);
        history.push({
          pathname: `/performance/performance-review/${value?.userId}/${value?.reviewCycleId}`,
        });
        // Handle the detail view action here
      },
    },
  ];

  const columns = [
    {
      title: '#',
      key: 'employeeCode',
      dataIndex: 'employeeCode',
      width: 80,
      minWidth: 80,
      align: 'center',
      render: (text: any, record: any, index: any) => {
        const trueIndex = index + sizePage * (currentPage - 1);
        return <div>{trueIndex + 1}</div>;
      },
    },
    {
      title: t('performance:pending_review.employee_name'),
      key: 'fullName',
      dataIndex: 'fullName',
      width: 100,
      minWidth: 100,
      render: (text: any, record: any) => {
        return (
          <EmployeeColumn
            avatar={
              record?.fullName ? `${record?.fullName}` : `${record?.lastName} ${record?.firstName}`
            }
            fullName={
              record?.fullName ? `${record?.fullName}` : `${record?.lastName} ${record?.firstName}`
            }
            email={record?.email}
          />
        );
      },
    },
    {
      title: t('recruitment:overall_score'),
      key: 'overallScore',
      dataIndex: 'overallScore',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (text: any) => {
        return text;
      },
    },
    {
      title: t('performance:performance_review.status'),
      key: 'status',
      dataIndex: 'status',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (text: any) => {
        return text;
      },
    },
    {
      title: t('performance:performance_review.created_on'),
      key: 'createdAt',
      dataIndex: 'createdAt',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (_: any, record: any, index: any) => {
        return record?.createdAt ? moment(record?.createdAt).format(YYYY_MM_DD) : '';
      },
    },
    {
      title: t('performance:performance_review.start_date'),
      key: 'startDate',
      dataIndex: 'startDate',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (text: any) => {
        return text ? moment(text).format(YYYY_MM_DD) : '';
      },
    },
    {
      title: t('performance:performance_review.end_date'),
      key: 'endDate',
      dataIndex: 'endDate',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (text: any) => {
        return text ? moment(text).format(YYYY_MM_DD) : '';
      },
    },
    {
      title: t('performance:pending_review.action'),
      key: 'action',
      dataIndex: 'action',
      width: 80,
      minWidth: 80,
      align: 'center',
      render: (_: any, record: any, index: any) => {
        return <MenuAction menu={menu} data={record} />;
      },
    },
  ];

  const handleSearch = (text: string) => {
    setSearchName(text);
  };

  return (
    <>
      <ButtonBack
        title={nameTitle}
        handleBack={() => history.push('/performance/performance-review/')}
      />
      <ITVSearch style={{ marginBottom: '1rem' }} handleSearch={handleSearch} />
      <ITVTable
        data={performanceDataHR?.result || []}
        columns={columns}
        isRowSelect={false}
        setOffset={setCurrentPage}
        offset={currentPage}
        setLimit={setSizePage}
        limit={sizePage}
        totalResult={performanceDataHR?.totalResult || undefined}
      />
    </>
  );
}

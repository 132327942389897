/* eslint-disable @typescript-eslint/no-unused-vars */
import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb } from 'antd';
import { setFormatDatetime } from 'app/commonRedux/appSlice';
import { readNotifications } from 'app/commonRedux/notificationSlice';
import queryString from 'query-string';
import { lazy, Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import {
  getLocaleDateMonthString,
  getLocaleDateString,
  getLocaleDateTimeString,
} from 'utils/function';

import { PrivateRoute } from 'routes/PrivateRoute';
import { ContentStyled, LayoutContentStyled, LayoutPageStyled } from './layoutStyled';

import Backdrop from 'components/Backdrop';
import breadcrumbNameMap from 'constants/breadcrumb';
import { LayoutDesktopConsumer, LayoutDesktopProvider } from 'contexts/LayoutDesktopContext';
import MoveDaysLeaveOff from 'features/configuration/components/MoveDayLeaveOff';
import { useTranslation } from 'react-i18next';
import {
  PERMISSION_CATEGORY_MANAGE,
  PERMISSION_DASHBOARD_ACCESS,
  PERMISSION_DEPARTMENT_MANAGE,
  PERMISSION_DEVICE_INVENTORY,
  PERMISSION_DEVICE_MANAGE,
  PERMISSION_EMAIL_TEMPLATE_MANAGE,
  PERMISSION_GENERAL_MANAGE,
  PERMISSION_LEAVE_OFF_ACCESS_PERSONAL,
  PERMISSION_LEAVE_OFF_MANAGE,
  PERMISSION_OFFBOARD_ACCESS_PERSONNEL,
  PERMISSION_OFFBOARD_MANAGE_EMPLOYEE,
  PERMISSION_OFFBOARD_MANAGE_PROCESS,
  PERMISSION_ONBOARD_ACCESS_PERSONNEL,
  PERMISSION_ONBOARD_MANAGE_EMPLOYEE,
  PERMISSION_ONBOARD_MANAGE_PROCESS,
  PERMISSION_PERMISSION_MANAGE,
  PERMISSION_PERSONNEL_MANAGE,
  PERMISSION_POSITION_MANAGE,
  PERMISSION_TIMEKEEPING_ACCESS_PERSONAL,
  PERMISSION_TIMESHEET_ACCESS_OBSERVATION_LIST,
  PERMISSION_TIMESHEET_ACCESS_PERSONAL,
  PERMISSION_TIMESHEET_MANAGE_EMPLOYEE,
  PERMISSION_TIMESHEET_MONTH_APPROVAL,
  PERMISSION_TIMESHEET_REQUEST_APPROVAL,
  PERMISSION_WATCHINGLIST_MANAGE,
  PERMISSION_WORKSHIFT_MANAGE,
} from '../../constants/permissions';

import SetupWorkflowType from 'features/configuration/pages/SetupWorkflowType';
import DefineNoti from 'features/onboard/pages/DefineNoti/DefineNoti';
import HistoryOTApproval from 'features/overtime/pages/historyOTApproval';
import HistoryProjectManage from 'features/overtime/pages/historyProjectManagement';
import HistoryRequestOT from 'features/overtime/pages/historyRequestOT';
import ManageRequest from 'features/payroll/pages/ManageRequest';
import AllPayRuns from 'features/payroll/pages/PayRun';
import ViewEmployee from 'features/payroll/pages/ViewEmployee';
import ViewOverallEmployee from 'features/payroll/pages/ViewEmployee/ViewOverallEmployee';
import { Page } from 'features/timesheet/pages/timesheetInterfaces';

import { useAppSelector } from 'app/hooks';
import { path } from 'constants/path';
import BusinessRegistrationForm from 'features/companyInformation';
import PageITPolicy from 'features/companyPolicy/itPolicy';
import { selectConfiguration } from 'features/configuration/configurationSlice';
import TaxManagement from 'features/configuration/pages/ManagePayroll/TaxManagement';
import TestPayroll from 'features/configuration/pages/SetupWorkflowType/TestPayroll';
import MyTraining from 'features/employee/pages/MyTraining';
import CourseDetail from 'features/employee/pages/MyTraining/CourseDetail';
import PerformanceReviewSelf from 'features/employee/pages/PerformanceReview';
import PerformanceDetail from 'features/employee/pages/PerformanceReview/components/PerformanceDetail';
import DashboardEmployee from 'features/employeeDashboard';
import ManageAutoSendMail from 'features/onboard/pages/ConfigAutoSendMail';
import GroupEmail from 'features/onboard/pages/GroupEmail/GroupEmail';
import DashboardPayroll from 'features/payroll/pages/Dashboard';
import ViewPayslipEmployee from 'features/payslip/pages';
import PerformanceReviewPage from 'features/performance/PerformanceReviewPage';
import PerformanceReviewHR from 'features/performance/hr/PerformanceReviewHR';
import ManagerViewPage from 'features/performance/manager';
import PerformancePendingReviewPage from 'features/performance/manager/PerformancePendingReviewPage';
import PTIParticipantList from 'features/pti/ParticipantList';
import Recruitment from 'features/recruitment';
import CandidatesManagement from 'features/recruitment/candidates';
import CandidateDetail from 'features/recruitment/candidates/detail';
import JobsRequisitionManagement from 'features/recruitment/jobs/JobsRequisitionManagement';
import JobDetail from 'features/recruitment/jobs/detail';
import TaxReport from 'features/report/pages/TaxReport';
import FormCourse from 'features/training/components/FormCourse';
import CourseManagement from 'features/training/pages/CourseManagement';
import TraineeManagement from 'features/training/pages/TraineeManagement';
import TraineeManagementDetail from 'features/training/pages/TraineeManagementDetail';
import PageXclaim from 'features/xclaim';
import { PermissionType } from 'hrm-common/extensions/enums/personel';
import TimesheetReport from 'features/timesheetReport';
import PageXclaimInsurance from 'features/xclaimInsurance';
import TimeKeepingConfig from 'features/configuration/pages/TimeKeeping';

const ContractManagementFeature = lazy(() => import('features/contractManagement'));
const RequestContractFeature = lazy(() => import('features/requestContract'));
const EmployeeContractFeature = lazy(() => import('features/employeeContract'));
const ManageOvertimeType = lazy(() => import('features/overtime/pages/manageOvertimeType'));
const OvertimeApproval = lazy(() => import('features/overtime/pages/overtimeApproval'));
const RequestOvertime = lazy(() => import('features/overtime/pages/requestOvertime'));
const ProjectManagement = lazy(() => import('features/overtime/pages/projectManagement'));
const ManageAssignWorkShift = lazy(
  () => import('features/workShifts/components/ManageAssignWorkshift'),
);
const SetupWorkShifts = lazy(() => import('features/workShifts/SetupWorkShifts'));

const Footers = lazy(() => import('../LayoutPage/Footers'));
const Headers = lazy(() => import('../LayoutPage/Headers'));
const SlideBar = lazy(() => import('../LayoutPage/SlideBar'));

const CompensatoryConfiguration = lazy(
  () => import('features/overtime/pages/compensatoryConfiguration'),
);
const WatchingList = lazy(() => import('features/watchingList/pages/WatchingList'));
const ManagerRequestList = lazy(() => import('features/watchingList/pages/ManagerRequestList'));
const HRLeadRequestList = lazy(() => import('features/watchingList/pages/HRLeadRequestList'));
const DashboardFeature = lazy(() => import('features/dashboard'));
const ReportFeature = lazy(() => import('features/report'));
const ManageEmailTemplate = lazy(() => import('features/onboard/pages/ManageEmailTemplate'));
const EmployeeOnboard = lazy(() => import('features/onboard/pages/EmployeeOnboard'));
const ManageProcess = lazy(() => import('features/onboard/pages/ManageProcess'));
const Handover = lazy(() => import('features/equipment/pages/Handover'));
const EquipmentHandover = lazy(() => import('features/equipment/pages/EquipmentHandover'));
const EmployeeOffboard = lazy(() => import('features/offboard/pages/EmployeeOffboard'));
const OffboardDetail = lazy(() => import('features/offboard/pages/OffboardDetail'));
const MyOffboard = lazy(() => import('features/offboard/pages/MyOffboard'));
const CreateProcess = lazy(() => import('features/onboard/pages/CreateProcess'));
const SeeOnboard = lazy(() => import('features/onboard/pages/SeeOnboard'));

const SeeOffboard = lazy(() => import('features/offboard/pages/SeeOffboard'));

const ContractTemplateList = lazy(() => import('features/contract/pages/ContractTemplateList'));
const ContractTemplateAdd = lazy(() => import('features/contract/pages/ContractTemplateAdd'));
const ContractList = lazy(() => import('features/contract/pages/ContractList'));
const ContractListEmployee = lazy(() => import('features/contract/pages/ContractListEmployee'));
const ContractView = lazy(() => import('features/contract/pages/ContractView'));

const EmployeeLists = lazy(() => import('../../features/employee/pages/EmployeeLists'));
const PersonnelView = lazy(() => import('../../features/timesheet/pages/personnel/PersonnelView'));
const ManageContainer = lazy(() => import('../../features/timesheet/pages/manage/ManageContainer'));
const ItemOverView = lazy(
  () => import('../../features/timesheet/pages/manage/ItemTimeSheetDetails/ItemOverView'),
);
const HRView = lazy(() => import('../../features/timesheet/pages/resources/HRView'));
const ItemHrExportDetails = lazy(
  () => import('../../features/timesheet/pages/resources/ItemHrExportDetails'),
);
const TimesheetAdjustment = lazy(
  () => import('../../features/timesheet/pages/personnel/TimesheetAdjustment'),
);
const TimeKeeping = lazy(() => import('../../features/timesheet/pages/personnel/TimeKeeping'));
const ItemOverDetails = lazy(
  () => import('../../features/timesheet/pages/manage/ItemTimeSheetDetails/ItemOverDetails'),
);
const RequestApprovalDetail = lazy(
  () => import('../../features/timesheet/pages/manage/RequestApprovalDetail'),
);
const TimesheetApproval = lazy(
  () => import('../../features/timesheet/pages/manage/ItemTimesheetApproval/TimesheetApproval'),
);
const ObservationList = lazy(
  () => import('../../features/timesheet/pages/manage/ItemTimesheetApproval/ObservationList'),
);
const ConfigurationCategories = lazy(() => import('../../features/masterData/Category'));
const ConfigurationDepartment = lazy(() => import('../../features/masterData/Department'));
const ConfigurationPosition = lazy(() => import('../../features/masterData/Position'));
const ConfigurationPermission = lazy(() => import('../../features/masterData/Role'));
const ConfigurationSetting = lazy(() => import('../../features/masterData/Setting'));
const ItemObservationView = lazy(
  () => import('../../features/timesheet/pages/manage/ItemTimesheetApproval/ItemObservationView'),
);
const ConfigurationWorkshift = lazy(
  () => import('../../features/configuration/pages/ManageWorkshift'),
);
const ConfigurationPayroll = lazy(() => import('../../features/configuration/pages/ManagePayroll'));
const ConfigurationStatus = lazy(() => import('../../features/configuration/pages/ManageStatus'));
const ConfigurationAction = lazy(() => import('../../features/configuration/pages/ManageAction'));
const SetupMenuScreen = lazy(() => import('../../features/configuration/pages/SetupMenuScreen'));
const PerformanceReview = lazy(
  () => import('../../features/configuration/pages/PerformanceReview'),
);
const ConfigurationRecruitment = lazy(
  () => import('../../features/configuration/pages/Recruitment'),
);
const Goals = lazy(() => import('../../features/configuration/pages/Goals'));

const LeaveOff = lazy(() => import('../../features/leaveOff/pages/index'));
const LeaveOffManage = lazy(() => import('../../features/leaveOff/pages/ManageLeaveOff'));
const ComplusoryContainer = lazy(
  () => import('../../features/insurances/pages/complusory_insurance/ComplusoryContainer'),
);
const HistoryParticipantList = lazy(
  () =>
    import(
      '../../features/insurances/pages/complusory_insurance/insurance_role/HistoryParticipantList'
    ),
);
const SettingInsuranceContainer = lazy(
  () => import('../../features/insurances/pages/setting_insurance/SettingInsuranceContainer'),
);

const GeneralConfigSetting = lazy(() => import('../../features/masterData/General'));
const RequestPending = lazy(() => import('../../features/timesheet/pages/manage/RequestPending'));

const NotFoundRedirect = () => <Redirect to="/not-found" />;

const LayoutDesktop = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation(['breadcrumb', 'holiday']);

  const userId = useSelector((state: { auth: { user: { id: string } | null } }) =>
    state.auth.user ? state.auth.user.id : '',
  );

  // Set status change x-tenant-id
  localStorage.setItem('hrm.register', 'no');

  const menuStore = useAppSelector(selectConfiguration);
  const dataScreenManage = (menuStore?.dataScreenManage as any)?.result;
  const dataMenuScreenManage = (menuStore?.dataMenuScreenManage as any)?.result;
  const [extraBread, setExtraBread] = useState('');
  const [breadItem, setBreadItem] = useState('');

  useEffect(() => {
    const temp = dataScreenManage
      ?.filter((item: any) => location.pathname === item.path)
      ?.map((item: any) => item.nameScreen);
    const anotherTemp = dataScreenManage
      ?.filter((item: any) => location.pathname.includes(item.path))
      ?.map((item: any) => item.nameScreen);

    setExtraBread(
      temp?.length > 0
        ? temp[0]
        : anotherTemp?.length > 0
        ? anotherTemp[anotherTemp?.length - 1]
        : '',
    );

    const breadCum = dataMenuScreenManage?.filter((item: any) => {
      const temp = item.dtmenuScreen?.filter(
        (obj: any) => location.pathname === obj.manageScreen.path,
      );
      const anotherTemp = item.dtmenuScreen?.filter((obj: any) =>
        location.pathname.includes(obj.manageScreen.path),
      );

      return temp?.length > 0 ? item : anotherTemp?.length > 0 ? item : null;
    });

    setBreadItem(breadCum?.length > 0 ? breadCum[breadCum.length - 1]?.menuParent?.menuName : '');
  }, [dataMenuScreenManage, dataScreenManage, location.pathname]);

  // Handle Breadcrumb
  const pathSnippets = location.pathname
    .split('/')
    .filter((i) => i)
    .slice(0, 2);
  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
    return (
      <Breadcrumb.Item key={url}>
        {breadcrumbNameMap[url]?.isLink ? extraBread : breadItem}
      </Breadcrumb.Item>
    );
  });

  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/">
        <HomeOutlined /> {t('/')}
      </Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);

  // Handle notification
  const handleReadNotification = async (path: string) => {
    const params: queryString.ParsedQuery<string> = queryString.parse(path);

    if (params.notiCode && !Array.isArray(params.notiCode)) {
      await dispatch(readNotifications({ userId, notiId: params.notiCode }));
      const newUrl = window.location.href.replace(
        `notiCode=${params.notiCode}${Object.keys(params).length > 1 ? '&' : ''}`,
        '',
      );
      const newPathname = newUrl.replace(window.location.origin, '');
      history.replace(newPathname);
      // dispatch(getNotifications({ userId }));
    }
  };

  useEffect(() => {
    if (history.location.search.search('notiCode=') !== 0 && userId !== '') {
      handleReadNotification(history.location.search);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.search]);

  // Handle load format date time
  useEffect(() => {
    const formatLocaleDate = getLocaleDateString();
    const formatLocaleDateTime = getLocaleDateTimeString();
    const formatLocaleDateMonth = getLocaleDateMonthString();
    dispatch(
      setFormatDatetime({
        formatDate: formatLocaleDate,
        formatDatetime: formatLocaleDateTime,
        formatDateMonth: formatLocaleDateMonth,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LayoutDesktopProvider>
      <LayoutPageStyled hasSider>
        <SlideBar />
        <LayoutDesktopConsumer>
          {({ collapsedslidebar }) => (
            <LayoutContentStyled collapsedslidebar={collapsedslidebar}>
              <Headers />
              <ContentStyled>
                <Breadcrumb style={{ padding: '10px 0 0', display: 'inline-block' }} separator=">">
                  {breadcrumbItems}
                </Breadcrumb>
                <Suspense fallback={<Backdrop />}>
                  <Switch>
                    {/* Dashboard */}

                    <PrivateRoute
                      path="/dashboard"
                      permissions={[PERMISSION_DASHBOARD_ACCESS.DASHBOARD_ACCESS_HR]}
                    >
                      <DashboardFeature />
                    </PrivateRoute>
                    {/* Report */}
                    <PrivateRoute
                      path="/report"
                      permissions={[PERMISSION_DASHBOARD_ACCESS.DASHBOARD_ACCESS_HR]}
                    >
                      <ReportFeature />
                    </PrivateRoute>
                    <PrivateRoute
                      path="/configuration/tax-report"
                      permissions={[PERMISSION_DASHBOARD_ACCESS.DASHBOARD_ACCESS_HR]}
                    >
                      <TaxReport />
                    </PrivateRoute>
                    {/* Mypage */}
                    <PrivateRoute
                      path="/my-page/timesheet/details"
                      permissions={[PERMISSION_TIMESHEET_ACCESS_PERSONAL.TIMESHEET_ACCESS_PERSONAL]}
                    >
                      <RequestApprovalDetail />
                    </PrivateRoute>
                    <PrivateRoute
                      path="/my-page/timesheet"
                      permissions={[PERMISSION_TIMESHEET_ACCESS_PERSONAL.TIMESHEET_ACCESS_PERSONAL]}
                    >
                      <PersonnelView />
                    </PrivateRoute>
                    <PrivateRoute
                      path="/my-page/payslip"
                      permissions={[PERMISSION_TIMESHEET_ACCESS_PERSONAL.TIMESHEET_ACCESS_PERSONAL]}
                    >
                      <ViewPayslipEmployee />
                    </PrivateRoute>
                    <PrivateRoute
                      path="/my-page/timekeeping"
                      permissions={[PERMISSION_TIMEKEEPING_ACCESS_PERSONAL]}
                    >
                      <TimeKeeping />
                    </PrivateRoute>
                    <PrivateRoute
                      path="/my-page/performance-review/detail/:id"
                      permissions={['PERFORMANCE_ACCESS_PERSONAL']}
                    >
                      <PerformanceDetail />
                    </PrivateRoute>

                    <PrivateRoute
                      path="/my-page/performance-review"
                      permissions={['PERFORMANCE_ACCESS_PERSONAL']}
                    >
                      <PerformanceReviewSelf isStaff={true} />
                    </PrivateRoute>

                    <PrivateRoute
                      path="/my-page/on-off/onboard"
                      permissions={[PERMISSION_ONBOARD_ACCESS_PERSONNEL.ONBOARD_ACCESS_PERSONNEL]}
                    >
                      <SeeOnboard isStaff />
                    </PrivateRoute>
                    <PrivateRoute
                      path="/my-page/on-off/offboard"
                      permissions={[PERMISSION_OFFBOARD_ACCESS_PERSONNEL.OFFBOARD_ACCESS_PERSONNEL]}
                    >
                      <SeeOffboard isStaff />
                    </PrivateRoute>
                    <PrivateRoute
                      path="/my-page/on-off/offboard-detail/:id"
                      permissions={[PERMISSION_OFFBOARD_ACCESS_PERSONNEL.OFFBOARD_ACCESS_PERSONNEL]}
                    >
                      <OffboardDetail isStaff={true} />
                    </PrivateRoute>
                    <PrivateRoute path="/my-page/device">
                      <div>{t('holiday:please_come_back_later')}</div>
                    </PrivateRoute>
                    <PrivateRoute
                      path="/my-page/leave-off"
                      permissions={[PERMISSION_LEAVE_OFF_ACCESS_PERSONAL]}
                    >
                      <LeaveOff />
                    </PrivateRoute>
                    <PrivateRoute path="/my-page/contract">
                      <ContractListEmployee />
                    </PrivateRoute>
                    <PrivateRoute
                      path="/my-page/observation_list"
                      permissions={[
                        PERMISSION_TIMESHEET_ACCESS_OBSERVATION_LIST.TIMESHEET_ACCESS_OBSERVATION_LIST,
                      ]}
                    >
                      <ObservationList />
                    </PrivateRoute>
                    <PrivateRoute
                      path="/my-page/observation_list/details"
                      permissions={[
                        PERMISSION_TIMESHEET_ACCESS_OBSERVATION_LIST.TIMESHEET_ACCESS_OBSERVATION_LIST,
                      ]}
                    >
                      <ItemObservationView />
                    </PrivateRoute>
                    {/* WatchingList */}
                    <PrivateRoute
                      path="/manage-watching-list/watching-list"
                      permissions={[PERMISSION_WATCHINGLIST_MANAGE.WATCHINGLIST_VIEW]}
                    >
                      <WatchingList />
                    </PrivateRoute>
                    <PrivateRoute
                      path="/manage-watching-list/manager-request-list"
                      permissions={[
                        PERMISSION_WATCHINGLIST_MANAGE.WATCHINGLIST_ADD_EMPLOYEE,
                        PERMISSION_WATCHINGLIST_MANAGE.WATCHINGLIST_REMOVE_EMPLOYEE,
                        PERMISSION_WATCHINGLIST_MANAGE.WATCHINGLIST_MANAGER_REQUEST_LIST_VIEW,
                      ]}
                    >
                      <ManagerRequestList />
                    </PrivateRoute>
                    <PrivateRoute
                      path="/manage-watching-list/hr-request-list"
                      permissions={[
                        PERMISSION_WATCHINGLIST_MANAGE.WATCHINGLIST_ADD_EMPLOYEE,
                        PERMISSION_WATCHINGLIST_MANAGE.WATCHINGLIST_REMOVE_EMPLOYEE,
                        PERMISSION_WATCHINGLIST_MANAGE.WATCHINGLIST_HR_REQUEST_LIST_VIEW,
                      ]}
                    >
                      <HRLeadRequestList />
                    </PrivateRoute>
                    {/* Timesheet */}
                    {/* <PrivateRoute path="/manage/request_approval/details">
                <ItemOverDetails />
              </PrivateRoute> */}
                    <PrivateRoute
                      path="/manage/request_approval/detail/:id"
                      permissions={[
                        PERMISSION_TIMESHEET_REQUEST_APPROVAL.TIMESHEET_ADJUST_TIMECLOCK_APPROVAL,
                        PERMISSION_TIMESHEET_REQUEST_APPROVAL.TIMESHEET_OVERTIME_APPROVAL,
                        PERMISSION_TIMESHEET_REQUEST_APPROVAL.TIMESHEET_TIMEOFF_APPROVAL,
                      ]}
                      extraPermissions={['PROJECT_LEADER']}
                    >
                      <RequestApprovalDetail />
                    </PrivateRoute>
                    <PrivateRoute
                      path="/manage/request_approval/itemOverView"
                      permissions={[
                        PERMISSION_TIMESHEET_REQUEST_APPROVAL.TIMESHEET_OVERVIEW_LIST_REQUEST,
                      ]}
                    >
                      <ItemOverView />
                    </PrivateRoute>
                    <PrivateRoute
                      path="/manage/request_approval"
                      permissions={[
                        PERMISSION_TIMESHEET_REQUEST_APPROVAL.TIMESHEET_REQUEST_APPROVAL,
                      ]}
                      extraPermissions={['PROJECT_LEADER']}
                    >
                      <ManageContainer />
                    </PrivateRoute>
                    <PrivateRoute
                      path="/manage/request_pending"
                      permissions={[
                        PERMISSION_TIMESHEET_REQUEST_APPROVAL.TIMESHEET_REQUEST_APPROVAL,
                      ]}
                      extraPermissions={['PROJECT_LEADER']}
                    >
                      <RequestPending />
                    </PrivateRoute>
                    <PrivateRoute
                      path="/manage/timesheet_report"
                      permissions={[PermissionType.TIMESHEET_REPORT]}
                    >
                      <TimesheetReport />
                    </PrivateRoute>
                    <PrivateRoute
                      path="/manage/timesheet_approval/exportDetails"
                      permissions={[PERMISSION_TIMESHEET_MONTH_APPROVAL]}
                    >
                      <ItemHrExportDetails />
                    </PrivateRoute>
                    <PrivateRoute
                      exact
                      path="/manage/timesheet_approval"
                      permissions={[PERMISSION_TIMESHEET_MONTH_APPROVAL]}
                    >
                      <TimesheetApproval />
                    </PrivateRoute>
                    <PrivateRoute
                      path="/manage/employee/exportDetails/details"
                      permissions={[PERMISSION_TIMESHEET_MANAGE_EMPLOYEE.TIMESHEET_EXPORT]}
                    >
                      <ItemOverDetails />
                    </PrivateRoute>
                    <PrivateRoute
                      path="/manage/timesheet_approval/:id"
                      permissions={[PERMISSION_TIMESHEET_MONTH_APPROVAL]}
                    >
                      <PersonnelView page={Page.TIMESHEET_APPROVAL} />
                    </PrivateRoute>
                    <PrivateRoute
                      path="/manage/employee_timesheet"
                      permissions={[PERMISSION_TIMESHEET_MANAGE_EMPLOYEE.TIMESHEET_MANAGE_EMPLOYEE]}
                    >
                      <HRView />
                    </PrivateRoute>

                    {/* Employee */}
                    <PrivateRoute
                      path="/employee/list-employee/"
                      permissions={[PermissionType.PERSONNEL_MANAGE]}
                    >
                      <EmployeeLists />
                    </PrivateRoute>
                    <PrivateRoute
                      path="/employee/dashboard-employee/"
                      permissions={[PermissionType.PERSONNEL_MANAGE]}
                    >
                      <DashboardEmployee />
                    </PrivateRoute>

                    <PrivateRoute
                      path={path.employeeContract}
                      permissions={[
                        PermissionType.VIEW_ALL_CONTRACT,
                        PermissionType.EDIT_ALL_CONTRACT,
                      ]}
                    >
                      <EmployeeContractFeature />
                    </PrivateRoute>

                    <PrivateRoute
                      path={path.requestContract}
                      permissions={[
                        PermissionType.APPROVE_CONTRACT,
                        PermissionType.EDIT_ALL_CONTRACT,
                      ]}
                    >
                      <RequestContractFeature />
                    </PrivateRoute>

                    <PrivateRoute
                      path={path.contractManagement}
                      permissions={[
                        PermissionType.VIEW_ALL_CONTRACT,
                        PermissionType.EDIT_ALL_CONTRACT,
                      ]}
                    >
                      <ContractManagementFeature />
                    </PrivateRoute>

                    {/* Onboard Offboard*/}
                    <PrivateRoute
                      exact
                      path="/onboard-offboard/employee-onboard"
                      permissions={[PERMISSION_ONBOARD_MANAGE_EMPLOYEE.ONBOARD_MANAGE_EMPLOYEE]}
                    >
                      <EmployeeOnboard />
                    </PrivateRoute>
                    <PrivateRoute
                      path="/onboard-offboard/employee-onboard/view/:id"
                      permissions={[PERMISSION_ONBOARD_MANAGE_EMPLOYEE.ONBOARD_MANAGE_EMPLOYEE]}
                    >
                      <SeeOnboard />
                    </PrivateRoute>
                    <PrivateRoute
                      exact
                      path="/onboard-offboard/employee-offboard"
                      permissions={[PERMISSION_OFFBOARD_MANAGE_EMPLOYEE.OFFBOARD_MANAGE_EMPLOYEE]}
                    >
                      <EmployeeOffboard />
                    </PrivateRoute>
                    <PrivateRoute
                      path="/onboard-offboard/employee-offboard/view/:id"
                      permissions={[
                        PERMISSION_OFFBOARD_MANAGE_PROCESS.OFFBOARD_MANAGE_PROCESS,
                        PERMISSION_OFFBOARD_MANAGE_PROCESS.OFFBOARD_REQUEST_APPROVAL,
                      ]}
                    >
                      <OffboardDetail />
                    </PrivateRoute>
                    {/* Contract */}
                    <PrivateRoute path="/contract/contract-template">
                      <ContractTemplateList />
                    </PrivateRoute>
                    <PrivateRoute path="/contract/contract-template-create">
                      <ContractTemplateAdd />
                    </PrivateRoute>
                    <PrivateRoute path="/contract/contract-list">
                      <ContractList />
                    </PrivateRoute>
                    <PrivateRoute path="/contract/contract-view">
                      <ContractView />
                    </PrivateRoute>
                    {/* Equipment */}
                    <PrivateRoute
                      path="/equipment/inventory-control"
                      permissions={[PERMISSION_DEVICE_INVENTORY.DEVICE_INVENTORY]}
                    >
                      <div>{t('holiday:please_come_back_later')}</div>
                    </PrivateRoute>
                    <PrivateRoute
                      exact
                      path="/equipment/equipment-handover"
                      permissions={[PERMISSION_DEVICE_MANAGE.DEVICE_MANAGE]}
                    >
                      <Handover />
                    </PrivateRoute>
                    <PrivateRoute
                      path="/equipment/equipment-handover/handover/:id"
                      permissions={[PERMISSION_DEVICE_MANAGE.DEVICE_MANAGE]}
                    >
                      <EquipmentHandover />
                    </PrivateRoute>
                    {/* Configuration */}
                    <PrivateRoute
                      path="/configuration/category"
                      permissions={[PERMISSION_CATEGORY_MANAGE.CATEGORY_MANAGE]}
                    >
                      <ConfigurationCategories />
                    </PrivateRoute>
                    <PrivateRoute
                      path="/configuration/department"
                      permissions={[PERMISSION_DEPARTMENT_MANAGE.DEPARTMENT_MANAGE]}
                    >
                      <ConfigurationDepartment />
                    </PrivateRoute>
                    <PrivateRoute
                      path="/configuration/position"
                      permissions={[PERMISSION_POSITION_MANAGE.POSITION_MANAGE]}
                    >
                      <ConfigurationPosition />
                    </PrivateRoute>
                    <PrivateRoute
                      path="/configuration/role"
                      permissions={[PERMISSION_PERMISSION_MANAGE.PERMISSION_MANAGE]}
                    >
                      <ConfigurationPermission />
                    </PrivateRoute>
                    <PrivateRoute
                      path="/configuration/setup-policy"
                      permissions={[PERMISSION_PERMISSION_MANAGE.PERMISSION_MANAGE]}
                    >
                      <ConfigurationSetting />
                    </PrivateRoute>
                    <PrivateRoute
                      exact
                      path="/configuration/timekeeping"
                      permissions={['TIMESHEET_CONFIG_TIMEKEEPING']}
                    >
                      <TimeKeepingConfig />
                    </PrivateRoute>
                    <PrivateRoute
                      exact
                      path="/configuration/workshift"
                      permissions={[PERMISSION_WORKSHIFT_MANAGE.WORKSHIFT_MANAGE]}
                    >
                      <ConfigurationWorkshift />
                    </PrivateRoute>
                    <PrivateRoute
                      exact
                      path="/configuration/workshift/move-days"
                      permissions={[PERMISSION_WORKSHIFT_MANAGE.WORKSHIFT_MANAGE]}
                    >
                      <MoveDaysLeaveOff />
                    </PrivateRoute>
                    <PrivateRoute
                      exact
                      path="/configuration/setup-workflow"
                      permissions={[
                        PERMISSION_ONBOARD_MANAGE_PROCESS.ONBOARD_MANAGE_PROCESS,
                        PERMISSION_ONBOARD_MANAGE_PROCESS.ONBOARD_MANAGE_TASK,
                        PERMISSION_ONBOARD_MANAGE_PROCESS.ONBOARD_EVALUATE,
                      ]}
                    >
                      <ManageProcess />
                    </PrivateRoute>
                    <PrivateRoute
                      path="/configuration/setup-workflow/create"
                      permissions={[
                        PERMISSION_ONBOARD_MANAGE_PROCESS.ONBOARD_MANAGE_PROCESS,
                        PERMISSION_ONBOARD_MANAGE_PROCESS.ONBOARD_MANAGE_TASK,
                        PERMISSION_ONBOARD_MANAGE_PROCESS.ONBOARD_EVALUATE,
                      ]}
                    >
                      <CreateProcess />
                    </PrivateRoute>
                    {/* Payroll config */}
                    <PrivateRoute
                      exact
                      path="/configuration/payroll"
                      permissions={[PermissionType.SETUP_PAYROLL]}
                    >
                      <ConfigurationPayroll />
                    </PrivateRoute>
                    {/* Payroll config */}
                    <PrivateRoute
                      exact
                      path="/configuration/status"
                      permissions={[PermissionType.SETUP_WORKFLOW]}
                    >
                      <ConfigurationStatus />
                    </PrivateRoute>
                    <PrivateRoute
                      exact
                      path="/configuration/workflow-type"
                      permissions={[PermissionType.SETUP_WORKFLOW]}
                    >
                      <SetupWorkflowType />
                    </PrivateRoute>

                    <PrivateRoute
                      exact
                      path="/configuration/menu-screen"
                      permissions={[
                        PERMISSION_ONBOARD_MANAGE_PROCESS.ONBOARD_MANAGE_PROCESS,
                        PERMISSION_ONBOARD_MANAGE_PROCESS.ONBOARD_MANAGE_TASK,
                        PERMISSION_ONBOARD_MANAGE_PROCESS.ONBOARD_EVALUATE,
                      ]}
                    >
                      <SetupMenuScreen />
                    </PrivateRoute>
                    <PrivateRoute
                      exact
                      path="/configuration/performance-review"
                      permissions={[
                        PERMISSION_ONBOARD_MANAGE_PROCESS.ONBOARD_MANAGE_PROCESS,
                        PERMISSION_ONBOARD_MANAGE_PROCESS.ONBOARD_MANAGE_TASK,
                        PERMISSION_ONBOARD_MANAGE_PROCESS.ONBOARD_EVALUATE,
                        PERMISSION_ONBOARD_MANAGE_PROCESS.PERFORMANCE_MANAGE,
                      ]}
                    >
                      <PerformanceReview />
                    </PrivateRoute>
                    <PrivateRoute
                      exact
                      path="/configuration/goals"
                      permissions={[
                        PERMISSION_ONBOARD_MANAGE_PROCESS.ONBOARD_MANAGE_PROCESS,
                        PERMISSION_ONBOARD_MANAGE_PROCESS.ONBOARD_MANAGE_TASK,
                        PERMISSION_ONBOARD_MANAGE_PROCESS.ONBOARD_EVALUATE,
                        PERMISSION_ONBOARD_MANAGE_PROCESS.SETUP_GOAL,
                      ]}
                    >
                      <Goals />
                    </PrivateRoute>
                    <PrivateRoute
                      exact
                      path="/recruitment/configuration"
                      permissions={['RECRUIT_CREATE_TEMPLATE']}
                    >
                      <ConfigurationRecruitment />
                    </PrivateRoute>
                    <PrivateRoute
                      exact
                      path="/testpayroll"
                      permissions={[PermissionType.SETUP_WORKFLOW]}
                    >
                      <TestPayroll />
                    </PrivateRoute>
                    <PrivateRoute
                      exact
                      path="/configuration/action"
                      permissions={[PermissionType.SETUP_WORKFLOW]}
                    >
                      <ConfigurationAction />
                    </PrivateRoute>
                    {/* <PrivateRoute
                      exact
                      path="/configuration/work-shift/setup-work-shifts/:tabsWorkShifts?"
                      permissions={[PERMISSION_WORKSHIFT_MANAGE.WORKSHIFT_MANAGE]}
                    >
                      <SetupWorkShifts />
                    </PrivateRoute>
                    <PrivateRoute
                      exact
                      path="/configuration/work-shift/manage-assign-work-shifts"
                      permissions={[PERMISSION_WORKSHIFT_MANAGE.WORKSHIFT_MANAGE]}
                    >
                      <ManageAssignWorkShift />
                    </PrivateRoute> */}
                    <PrivateRoute
                      path="/configuration/manage-mail-&-notification/manage-mail-templates"
                      permissions={[PERMISSION_EMAIL_TEMPLATE_MANAGE.EMAIL_TEMPLATE_MANAGE]}
                    >
                      <ManageEmailTemplate />
                    </PrivateRoute>
                    <PrivateRoute
                      path="/configuration/manage-mail-&-notification/auto-send-mail"
                      permissions={[PERMISSION_EMAIL_TEMPLATE_MANAGE.EMAIL_TEMPLATE_MANAGE]}
                    >
                      <ManageAutoSendMail />
                    </PrivateRoute>
                    <PrivateRoute
                      path="/configuration/manage-mail-&-notification/config-notification"
                      permissions={[PERMISSION_EMAIL_TEMPLATE_MANAGE.EMAIL_TEMPLATE_MANAGE]}
                    >
                      <DefineNoti />
                    </PrivateRoute>
                    <PrivateRoute
                      path="/configuration/manage-mail-&-notification/group-email"
                      permissions={[PERMISSION_EMAIL_TEMPLATE_MANAGE.EMAIL_TEMPLATE_MANAGE]}
                    >
                      <GroupEmail />
                    </PrivateRoute>
                    <PrivateRoute
                      path="/configuration/general-setting"
                      permissions={[PERMISSION_GENERAL_MANAGE.GENERAL_MANAGE]}
                    >
                      <GeneralConfigSetting />
                    </PrivateRoute>
                    {/* Leave Off */}
                    <PrivateRoute
                      path="/manage-leave-off"
                      permissions={[PERMISSION_LEAVE_OFF_MANAGE.LEAVE_OFF_MANAGE_ACCESS]}
                    >
                      <LeaveOffManage />
                    </PrivateRoute>
                    {/* Project manage */}
                    <PrivateRoute
                      path="/manage-project"
                      permissions={[
                        //PERMISSION_OVERTIME_MANAGE.OVERTIME_PLAN_VIEW,
                        PERMISSION_PERSONNEL_MANAGE.PERSONNEL_MANAGE,
                      ]}
                    >
                      <ProjectManagement />
                    </PrivateRoute>
                    <PrivateRoute
                      path="/history-manage-project"
                      permissions={[
                        //PERMISSION_PROJECT_MANAGE.PROJECT_VIEW,
                        PERMISSION_PERSONNEL_MANAGE.PERSONNEL_MANAGE,
                      ]}
                    >
                      <HistoryProjectManage />
                    </PrivateRoute>
                    {/* Manage overtime */}
                    <PrivateRoute
                      exact
                      path="/manage-overtime/history-overtime-plan-request"
                      // permissions={[
                      //   PERMISSION_PROJECT_MANAGE.PROJECT_VIEW,
                      //   PERMISSION_PERSONNEL_MANAGE.PERSONNEL_MANAGE,
                      // ]}
                      extraPermissions={['PROJECT_LEADER']}
                    >
                      <HistoryRequestOT />
                    </PrivateRoute>
                    <PrivateRoute
                      exact
                      path="/manage-overtime/overtime-plan-request"
                      // permissions={[
                      //   PERMISSION_OVERTIME_MANAGE.OVERTIME_PLAN_VIEW,
                      //   PERMISSION_PERSONNEL_MANAGE.PERSONNEL_MANAGE,
                      // ]}
                      extraPermissions={['PROJECT_LEADER']}
                    >
                      <RequestOvertime />
                    </PrivateRoute>
                    <PrivateRoute
                      exact
                      path="/manage-overtime/history-overtime-approval"
                      permissions={[PERMISSION_PERSONNEL_MANAGE.PERSONNEL_MANAGE]}
                    >
                      <HistoryOTApproval />
                    </PrivateRoute>
                    <PrivateRoute
                      exact
                      path="/manage-overtime/overtime-approval"
                      permissions={[PERMISSION_PERSONNEL_MANAGE.PERSONNEL_MANAGE]}
                    >
                      <OvertimeApproval />
                    </PrivateRoute>
                    <PrivateRoute
                      path="/manage-overtime/manage-overtime-type"
                      permissions={[PERMISSION_PERSONNEL_MANAGE.PERSONNEL_MANAGE]}
                    >
                      <ManageOvertimeType />
                    </PrivateRoute>
                    <PrivateRoute
                      path="/manage-overtime/compensatory-configuration"
                      permissions={[PERMISSION_PERSONNEL_MANAGE.PERSONNEL_MANAGE]}
                    >
                      <CompensatoryConfiguration />
                    </PrivateRoute>

                    {/*Payroll */}
                    <PrivateRoute
                      exact
                      path="/payroll/pay-runs"
                      permissions={[PermissionType.VIEW_PAYRUNS_INFO]}
                    >
                      <AllPayRuns />
                    </PrivateRoute>
                    <PrivateRoute
                      exact
                      path="/payroll/pay-runs/detail/:id"
                      permissions={[PermissionType.VIEW_PAYRUNS_INFO]}
                    >
                      <ManageRequest />
                    </PrivateRoute>
                    <PrivateRoute
                      path="/payroll/overall"
                      permissions={[PermissionType.VIEW_EMPLOYEE_PAYROLL_INFO]}
                    >
                      <ViewOverallEmployee />
                    </PrivateRoute>
                    <PrivateRoute
                      path="/payroll/view-employee"
                      permissions={[PermissionType.VIEW_EMPLOYEE_PAYROLL_INFO]}
                    >
                      <ViewEmployee />
                    </PrivateRoute>
                    <PrivateRoute
                      path="/payroll/manage-request"
                      permissions={[PermissionType.VIEW_PAYRUNS_INFO]}
                    >
                      <ManageRequest />
                    </PrivateRoute>
                    <PrivateRoute
                      path="/payroll/dashboard"
                      permissions={[PermissionType.VIEW_EMPLOYEE_PAYROLL_INFO]}
                    >
                      <DashboardPayroll />
                    </PrivateRoute>
                    {/* Complusory Insurance */}
                    <PrivateRoute
                      path="/insurance/complusory-insurance/participant-detail"
                      permissions={[PermissionType.INSURANCE_PARTICIPANT_DETAIL_VIEW]}
                    >
                      <HistoryParticipantList />
                    </PrivateRoute>
                    <PrivateRoute
                      path="/insurance/complusory-insurance"
                      permissions={[
                        PermissionType.SCHEDULE_INCREASE_DECREASE_LIST,
                        PermissionType.INSURANCE_APPROVAL_LIST_VIEW,
                        PermissionType.INSURANCE_PARTICIPANTS_LIST_VIEW,
                        PermissionType.RECORD_INSURANCE_PAYMENT_VIEW,
                      ]}
                    >
                      <ComplusoryContainer />
                    </PrivateRoute>
                    <PrivateRoute
                      path="/insurance/setting-insurance"
                      permissions={[PermissionType.COMPULSORY_INSURANCE_SETTING_VIEW]}
                    >
                      <SettingInsuranceContainer />
                    </PrivateRoute>
                    <PrivateRoute path="/xclaim" permissions={['PROFILE_ACCESS_PERSONAL']}>
                      <PageXclaim />
                    </PrivateRoute>
                    <PrivateRoute
                      path="/xclaim-insurance"
                      permissions={['PROFILE_ACCESS_PERSONAL']}
                    >
                      <PageXclaimInsurance />
                    </PrivateRoute>
                    <PrivateRoute path="/it-policy" permissions={['PROFILE_ACCESS_PERSONAL']}>
                      <PageITPolicy />
                    </PrivateRoute>
                    <PrivateRoute
                      path="/company-information"
                      permissions={['PROFILE_ACCESS_PERSONAL']}
                    >
                      <BusinessRegistrationForm />
                    </PrivateRoute>
                    <PrivateRoute
                      path="/performance/performance-review/:id/:detailId"
                      permissions={['VIEW_PERFORMANCE_REVIEW']}
                    >
                      <PerformanceDetail />
                    </PrivateRoute>
                    <PrivateRoute
                      path="/performance/performance-review/:id"
                      permissions={['VIEW_PERFORMANCE_REVIEW']}
                    >
                      <PerformanceReviewHR />
                    </PrivateRoute>
                    <PrivateRoute
                      path="/performance/performance-review"
                      permissions={['VIEW_PERFORMANCE_REVIEW']}
                    >
                      <PerformanceReviewPage />
                    </PrivateRoute>
                    <PrivateRoute
                      exact
                      path="/configuration/tax-management"
                      permissions={[PermissionType.SETUP_PAYROLL]}
                    >
                      <TaxManagement />
                    </PrivateRoute>
                    <PrivateRoute
                      path="/recruitment/jobs/detail/:id"
                      permissions={[
                        'RECRUIT_JOB_CREATED',
                        'RECRUIT_JOB_OFFER',
                        'RECRUIT_CANDIDATE_INTERVIEW',
                        'RECRUIT_JOB_APPROVE',
                        'RECRUIT_APPROVE_OFFER',
                      ]}
                    >
                      <JobDetail />
                    </PrivateRoute>
                    <PrivateRoute
                      path="/recruitment/jobs"
                      permissions={[
                        'RECRUIT_JOB_CREATED',
                        'RECRUIT_JOB_OFFER',
                        'RECRUIT_CANDIDATE_INTERVIEW',
                        'RECRUIT_JOB_APPROVE',
                        'RECRUIT_APPROVE_OFFER',
                      ]}
                    >
                      <JobsRequisitionManagement />
                    </PrivateRoute>
                    <PrivateRoute
                      path="/recruitment/candidates/detail/:id/:jobId"
                      permissions={[
                        'RECRUIT_CREATE_TEMPLATE',
                        'RECRUIT_JOB_OFFER',
                        'RECRUIT_JOB_APPROVE',
                        'RECRUIT_CANDIDATE_INTERVIEW',
                        'RECRUIT_APPROVE_OFFER',
                        'RECRUIT_JOB_CREATED',
                      ]}
                    >
                      <CandidateDetail />
                    </PrivateRoute>
                    <PrivateRoute
                      path="/recruitment/candidates"
                      permissions={[
                        'RECRUIT_JOB_CREATED',
                        'RECRUIT_CREATE_TEMPLATE',
                        'RECRUIT_JOB_OFFER',
                        'RECRUIT_JOB_APPROVE',
                        'RECRUIT_CANDIDATE_INTERVIEW',
                        'RECRUIT_APPROVE_OFFER',
                      ]}
                    >
                      <CandidatesManagement />
                    </PrivateRoute>
                    {/* <PrivateRoute
                      path="/recruitment"
                      permissions={[PermissionType.TIMESHEET_ACCESS_PERSONAL]}
                    >
                      <Recruitment />
                    </PrivateRoute> */}
                    <PrivateRoute path="/pti/participant-list" permissions={['PTI_MANAGE']}>
                      <PTIParticipantList />
                    </PrivateRoute>
                    <PrivateRoute
                      path="/performance/pending-review/:id/:detailId"
                      permissions={['VIEW_PENDING_REVIEW']}
                    >
                      <PerformanceDetail />
                    </PrivateRoute>
                    <PrivateRoute
                      path="/performance/pending-review"
                      permissions={['VIEW_PENDING_REVIEW']}
                    >
                      <PerformancePendingReviewPage />
                    </PrivateRoute>
                    <PrivateRoute
                      exact
                      path="/performance/employee-pr-management/:id/:detailId"
                      permissions={['VIEW_EMPLOYEE_PR_MANAGEMENT']}
                    >
                      <PerformanceDetail />
                    </PrivateRoute>
                    <PrivateRoute
                      exact
                      path="/performance/employee-pr-management/:id"
                      permissions={['VIEW_EMPLOYEE_PR_MANAGEMENT']}
                    >
                      <PerformanceReviewSelf isStaff={false} />
                    </PrivateRoute>
                    <PrivateRoute
                      path="/performance/employee-pr-management"
                      permissions={['VIEW_EMPLOYEE_PR_MANAGEMENT']}
                    >
                      <ManagerViewPage />
                    </PrivateRoute>

                    <PrivateRoute
                      path="/training/course-management/:id"
                      permissions={['TRAINING_MANAGE']}
                    >
                      <FormCourse />
                    </PrivateRoute>
                    <PrivateRoute
                      path="/training/course-management"
                      permissions={['TRAINING_MANAGE']}
                    >
                      <CourseManagement />
                    </PrivateRoute>
                    <PrivateRoute
                      path="/training/trainee-management/:id"
                      permissions={['TRAINING_MANAGE']}
                    >
                      <TraineeManagementDetail />
                    </PrivateRoute>
                    <PrivateRoute
                      path="/training/trainee-management"
                      permissions={['TRAINING_MANAGE']}
                    >
                      <TraineeManagement />
                    </PrivateRoute>
                    <PrivateRoute
                      path="/my-page/my-training/:id"
                      permissions={['TRAINING_ACCESS_PERSONAL']}
                    >
                      <CourseDetail />
                    </PrivateRoute>
                    <PrivateRoute
                      path="/my-page/my-training"
                      permissions={['TRAINING_ACCESS_PERSONAL']}
                    >
                      <MyTraining />
                    </PrivateRoute>

                    <Route component={NotFoundRedirect} />
                  </Switch>
                </Suspense>
              </ContentStyled>
              <Footers />
            </LayoutContentStyled>
          )}
        </LayoutDesktopConsumer>
      </LayoutPageStyled>
    </LayoutDesktopProvider>
  );
};

export default LayoutDesktop;

import { Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'app/hooks';
import { formatDateInsurances, labelKeyTable } from '../constants/common';
import { getRejectedHistory } from '../insuranceAction';
import { selectInsurance } from '../insuranceSlice';
import { InfoTextCenter } from '../pages/complusory_insurance/insurance_role/HrStaffComplusory';
import InsuranceTable from './InsuranceTable';

const Columns_History = [
  {
    title: function () {
      return <InfoTextCenter style={{ fontSize: 12 }}>Requested by</InfoTextCenter>;
    },
    dataIndex: 'createdBy',
    key: 'createdBy',
    width: 100,
    render: function (text: any, record: any, index: any) {
      return <InfoTextCenter>{record?.schedulePremium?.createdBy}</InfoTextCenter>;
    },
  },
  {
    title: function () {
      return <InfoTextCenter style={{ fontSize: 12 }}>Rejected by</InfoTextCenter>;
    },
    dataIndex: 'rejectedBy',
    key: 'rejectedBy',
    width: 80,
    render: function (text: any, record: any, index: any) {
      return <InfoTextCenter>{record?.rejectedBy}</InfoTextCenter>;
    },
  },
  {
    title: function () {
      return <InfoTextCenter style={{ fontSize: 12 }}>Rejected date</InfoTextCenter>;
    },
    dataIndex: 'rejectedDate',
    key: 'rejectedDate',
    width: 100,
    render: function (text: any, record: any, index: any) {
      return <InfoTextCenter>{formatDateInsurances(record?.rejectedDat)}</InfoTextCenter>;
    },
  },
  {
    title: function () {
      return <InfoTextCenter style={{ fontSize: 12 }}>Adjust for</InfoTextCenter>;
    },
    dataIndex: 'user',
    key: 'user',
    width: 100,
    render: function (text: any, record: any, index: any) {
      return <InfoTextCenter>{record?.schedulePremium?.adjustFor}</InfoTextCenter>;
    },
  },
  {
    title: function () {
      return <InfoTextCenter style={{ fontSize: 12 }}>Type</InfoTextCenter>;
    },
    dataIndex: 'typeOfPremium',
    key: 'typeOfPremium',
    width: 100,
    render: function (text: any, record: any, index: any) {
      return <InfoTextCenter>{record?.schedulePremium?.typeOfPremium}</InfoTextCenter>;
    },
  },
  {
    title: function () {
      return (
        <div>
          <InfoTextCenter style={{ fontSize: 12 }}>New salary </InfoTextCenter>
          <InfoTextCenter style={{ fontSize: 12 }}>premium</InfoTextCenter>
        </div>
      );
    },
    dataIndex: 'user',
    key: 'user',
    width: 100,
    render: function (text: any, record: any, index: any) {
      return <InfoTextCenter>{record?.schedulePremium?.adjustFor}</InfoTextCenter>;
    },
  },
  {
    title: function () {
      return <InfoTextCenter style={{ fontSize: 12 }}>New valid date</InfoTextCenter>;
    },
    dataIndex: 'newValidDate',
    key: 'newValidDate',
    width: 100,
    render: function (text: any, record: any, index: any) {
      return <InfoTextCenter>{formatDateInsurances(record?.newValidDate)}</InfoTextCenter>;
    },
  },
  {
    title: function () {
      return <InfoTextCenter style={{ fontSize: 12 }}>Reason</InfoTextCenter>;
    },
    dataIndex: 'reasonReject',
    key: 'reasonReject',
    width: 100,
    render: function (text: any, record: any, index: any) {
      return (
        <InfoTextCenter>
          <Tooltip placement="topLeft" title={<div>{record?.reasonReject}</div>}>
            {record?.reasonReject}
          </Tooltip>
        </InfoTextCenter>
      );
    },
  },
];
const ModalHistory = (props: any) => {
  const dispatch = useDispatch();
  const insurance = useAppSelector(selectInsurance);
  const datarejectHistory = insurance?.datarejectHistory;
  const [columnshistory] = useState(Columns_History);
  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(
      getRejectedHistory({
        search: props.itemId?.user?.id,
        offset: 1,
        limit: 50,
      }),
    );
  }, [props.itemId, dispatch]);

  useEffect(() => {
    if (Array.isArray(datarejectHistory?.data?.result)) {
      setData(datarejectHistory?.data?.result);
    }
  }, [datarejectHistory]);

  return (
    <div>
      <div style={{ height: 400, width: '100%', marginTop: 20 }}>
        <InsuranceTable
          width={900}
          columns={columnshistory}
          data={data}
          isRowSelect={false}
          showPagination={false}
          setrowSelection={labelKeyTable.KEY_TABLE_NOT_CHECKBOX}
        />
      </div>
    </div>
  );
};

export default ModalHistory;

import styled from 'styled-components';
import { DatePicker } from 'antd';
import { useState } from 'react';
import { Moment } from 'moment';

import { EmployeeGrowthPayload } from 'types';
import { DatePickerIcon } from 'assets/icons';

const StyledWrapperYear = styled.div`
  width: 80px;
  .ant-picker {
    border-radius: 6px;
    border: 1px solid var(--cl_neutral300);
  }
`;

interface EmployeeGrowthFiltersProps {
  loading?: boolean;
  initialValues?: EmployeeGrowthPayload;
  onSubmit?: (payload: EmployeeGrowthPayload) => void;
}

export default function PayrollSummaryFilters({
  initialValues,
  onSubmit,
}: EmployeeGrowthFiltersProps) {
  const [year, setYear] = useState<Moment | null>(initialValues?.year || null);

  return (
    <StyledWrapperYear>
      <DatePicker
        picker="year"
        value={year}
        allowClear={false}
        suffixIcon={<DatePickerIcon />}
        onChange={(value) => {
          setYear(value);
          onSubmit?.({ year: value });
        }}
      />
    </StyledWrapperYear>
  );
}

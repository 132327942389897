import { FC } from 'react';
import styled from 'styled-components';

type TPageHeaderTitle = { title: string; subTitle?: string };

const PageHeaderTitle: FC<TPageHeaderTitle> = ({ title, subTitle }) => (
  <PageHeaderTitleStyled>
    {title}
    <br />
    <SubTitle>{subTitle}</SubTitle>
  </PageHeaderTitleStyled>
);

const PageHeaderTitleStyled = styled.h1`
  margin: 0;
  font-size: 24px;
  font-weight: 500;
  position: relative;
  margin-bottom: 0.2rem;
  line-height: 1;
`;

const SubTitle = styled.small`
  font-size: 0.6em;
  color: var(--cl_gray500);
`;

export default PageHeaderTitle;

import { DEFAULT_CURRENT_PAGE, DEFAULT_SIZE_PAGE } from 'hrm-common/extensions/constant/personel';
import moment from 'moment';
import { RootState } from '../store';
import colors from 'styles/variables/colors';

const migrations = {
  0: (state: RootState) => {
    return {
      ...state,
      dashboard: {
        ...state.dashboard,
        holidaysInMonth: [],
      },
      employee: {
        ...state.employee,
        employeeFilterList: [],
        sizePage: DEFAULT_SIZE_PAGE,
        currentPage: DEFAULT_CURRENT_PAGE,
      },
      timesheet: {
        ...state.timesheet,
        currentMonth: moment().format(),
        currentEmployee: {},
      },
    };
  },
  1: (state: RootState) => {
    return {
      ...state,
      watchingList: {
        ...state.watchingList,
        filterWatchingList: {
          watchingListData: {
            dataFiltered: [],
            totalPage: 0,
            totalResult: 0,
            status: 'idle',
          },
          employeeAddData: {
            dataFiltered: [],
            status: 'idle',
          },
        },
        totalResult: 0,
        selectedWatchingListData: { selectedDataList: [], isSelectAll: false },
      },
    };
  },
  2: (state: RootState) => {
    return {
      ...state,
      theme: {
        ...state.theme,
        colors: {
          ...state.theme.colors,
          cl_success_notify100: colors.cl_success_notify100,
          cl_success_notify200: colors.cl_success_notify200,
          cl_gray250: '#d6d9e0',
        },
      },
    };
  },
  3: (state: RootState) => {
    return {
      ...state,
      watchingList: {
        ...state.watchingList,
        watchingListData: [],
      },
    };
  },
};

export default migrations;

import { Form } from 'antd';
import ITVTable from 'components/ITVTable';
import EmployeeColumn from 'features/employee/components/EmployeeColumn';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { selectFormatDatetime } from 'app/commonRedux/appSlice';
import { useAppSelector } from 'app/hooks';
import moment from 'moment';
import { EyeOutlined } from '@ant-design/icons';
import MenuAction from 'components/MenuAction';
import { MODE } from 'constants/types';
import { getAllWorkflowTypeHistory } from 'features/configuration/configurationAction';
import { selectConfiguration } from 'features/configuration/configurationSlice';
import { TitleColumnCenter } from 'styles/tableStyled';
import { CustomModalWL } from './ManageWorkflowType';
import ModalViewWorkflowTypeHistory from './ModalViewWorkflowTypeHistory';

const HistoryWorkflowType = (props: any) => {
  const { t } = useTranslation(['validation', 'overtime', 'timesheet']);
  const [sizePage, setSizePage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const dataStore = useAppSelector(selectConfiguration);
  const formatDatetime = useAppSelector(selectFormatDatetime);
  const dataListWorkflowTypeHistory = dataStore?.dataListWorkflowTypeHistory;
  const [currentItem, setCurrentItem] = useState<any>(null);
  const [modalType, setModalType] = useState<MODE>();
  const [formEdit] = Form.useForm();

  useEffect(() => {
    dispatch(getAllWorkflowTypeHistory({}));
  }, [dispatch]);

  const MENU = [
    {
      name: t('timesheet:View_Detail'),
      icon: <EyeOutlined style={{ color: '#1890FF' }} />,
      handleClick: (value: any) => {
        setModalType(MODE.VIEW);
        setCurrentItem(value);
      },
    },
  ];
  const COLUMNS: any = [
    {
      title: <TitleColumnCenter>Workflow Id</TitleColumnCenter>,
      dataIndex: 'workflowId',
      key: 'workflowId',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: function (text: any, record: any, index: any) {
        const trueIndex = index + sizePage * (currentPage - 1);
        const obj: any = {
          children: record?.workflowTypeCode,
          props: { style: { verticalAlign: 'top' } },
        };
        if (
          index >= 1 &&
          record?.workflowTypeCode === dataListWorkflowTypeHistory[trueIndex - 1]?.workflowTypeCode
        ) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataListWorkflowTypeHistory.length &&
            record?.workflowTypeCode ===
              dataListWorkflowTypeHistory[trueIndex + i]?.workflowTypeCode;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }
        return obj;
      },
    },
    {
      title: <TitleColumnCenter>Workflow Name</TitleColumnCenter>,
      dataIndex: 'workflowName',
      key: 'workflowName',
      width: 150,
      minWidth: 150,
      align: 'center',
      render: function (text: any, record: any, index: any) {
        const trueIndex = index + sizePage * (currentPage - 1);
        const obj: any = {
          children: record?.workflowTypeName,
          props: { style: { verticalAlign: 'top' } },
        };
        if (
          index >= 1 &&
          record?.workflowTypeName === dataListWorkflowTypeHistory[trueIndex - 1]?.workflowTypeName
        ) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== dataListWorkflowTypeHistory.length &&
            record?.workflowTypeName ===
              dataListWorkflowTypeHistory[trueIndex + i]?.workflowTypeName;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }
        return obj;
      },
    },
    {
      title: t('overtime:updated_at'),
      dataIndex: 'updatedAt',
      align: 'center',
      key: 'updatedAt',
      width: 100,
      minWidth: 100,
      render: function (text: any, record: any, index: any) {
        const obj: any = {
          children: text ? moment(text).format(formatDatetime) : null,
          props: {
            style: {
              verticalAlign: 'top',
            },
          },
        };
        return obj;
      },
    },
    {
      title: t('overtime:updated_by'),
      dataIndex: 'updatedBy',
      key: 'updatedBy',
      width: 100,
      minWidth: 100,
      render: function (text: any, record: any, index: any) {
        const obj: any = {
          children: (
            <EmployeeColumn avatar={text?.fullName} fullName={text?.fullName} email={text?.email} />
          ),
          props: {
            style: {
              verticalAlign: 'top',
            },
          },
        };
        return obj;
      },
    },
    {
      title: t('timesheet:action'),
      width: 100,
      minWidth: 100,
      key: 'action',
      dataIndex: 'action',
      fixed: 'right',
      align: 'center',
      render: function (text: any, record: any, index: any) {
        return <MenuAction menu={MENU} data={record} />;
      },
    },
  ];

  return (
    <Container>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ width: '100%', display: 'flex' }}>
          <SearchContainer></SearchContainer>
        </div>
      </div>
      <ITVTable
        isRowSelect={false}
        columns={COLUMNS}
        isScroll={true}
        width={'max-content'}
        data={dataListWorkflowTypeHistory}
        setOffset={setCurrentPage}
        offset={currentPage}
        setLimit={setSizePage}
        limit={sizePage}
      />
      {/* View Workflow Type */}
      {modalType === MODE.VIEW && (
        <CustomModalWL
          style={{ top: 0 }}
          title={'View workflow type'}
          cancelText={t('timesheet:Cancel')}
          okText={t('timesheet:Save')}
          visible={true}
          onCancel={() => {
            setModalType('');
            setCurrentItem(null);
          }}
          width={1950}
          onOk={() => {}}
          footer={null}
          destroyOnClose
        >
          <Container>
            <ModalViewWorkflowTypeHistory
              currentItem={currentItem}
              form={formEdit}
              setModalType={setModalType}
              modalType={modalType}
              setCurrentItem={setCurrentItem}
            />
          </Container>
        </CustomModalWL>
      )}
    </Container>
  );
};

export default HistoryWorkflowType;

export const SearchContainer = styled.div`
  width: 200px;
  margin-bottom: 20px;
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  background: white;
  width: 100%;
  height: max-content;
`;

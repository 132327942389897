import { Control, FieldValues, Path, useController } from 'react-hook-form';
import { DatePicker } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import styled, { css } from 'styled-components';
import { DatePickerIcon } from 'assets/icons';

const { RangePicker } = DatePicker;

interface StyledWrapperProps {
  directionLabel: 'horizontal' | 'vertical';
}

export type RangePickerFieldProps<T extends FieldValues> = RangePickerProps & {
  name: Path<T>;
  control: Control<T>;
  directionLabel?: 'horizontal' | 'vertical';
  label?: string;
};

const StyledContainer = styled.div`
  .ant-picker {
    border-radius: 6px;
    border: 1px solid var(--cl_neutral300);
  }
`;

const StyledWrapper = styled.div`
  ${(props: StyledWrapperProps) =>
    props?.directionLabel === 'horizontal' &&
    css`
      display: flex;
      align-items: center;
    `}
`;

const StyledLabel = styled.p`
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: var(--cl_gray900);
  ${(props: StyledWrapperProps) =>
    props?.directionLabel === 'vertical' &&
    css`
      margin-bottom: 6px;
    `}
`;

const StyledErrorMessage = styled.p`
  color: var(--cl_error500);
  margin-top: 6px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
`;

export function RangePickerField<T extends FieldValues>({
  name,
  control,
  directionLabel = 'vertical',
  label,
  onChange: externalOnChange,
  ...rest
}: RangePickerFieldProps<T>) {
  const {
    field: { onChange, value, ref },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  return (
    <StyledContainer>
      <StyledWrapper directionLabel={directionLabel}>
        {label && <StyledLabel directionLabel={directionLabel}>{label}</StyledLabel>}

        <RangePicker
          status={error?.message ? 'error' : undefined}
          ref={ref}
          value={value}
          suffixIcon={<DatePickerIcon />}
          onChange={(dates, dateStrings) => {
            onChange(dates);
            externalOnChange?.(dates, dateStrings);
          }}
          {...rest}
        />
      </StyledWrapper>

      {error?.message && <StyledErrorMessage>{error?.message}</StyledErrorMessage>}
    </StyledContainer>
  );
}

// export interface hrmUser {
//   accessToken: string;
//   email: string;
//   fullName: string;
//   id: string;
//   permissions: Array<string>;
//   tenant: string;
//   type_login: string;
// }
export interface hrmUser {
  accessToken: string;
  email: string;
  employeeId: string;
  fullName: string | null;
  id: string;
  isSystemAdmin: boolean;
  managers: Array<object>;
  paths: Array<string>;
  permissions: Array<string>;
  tenant: string;
  timeZone: string;
  type_login: string;
}

export enum MODE {
  ADD = 'add',
  EDIT = 'edit',
  VIEW = 'view',
  COPY = 'copy',
}

import { svgHeader, svgText } from '../icons/icon_svg';
import { blockBanner } from './DefineBlock/block-banner';
import { blockFooter } from './DefineBlock/block-footer';
import { blockFooter2 } from './DefineBlock/block-footer-2';
import { blockHeader2 } from './DefineBlock/block-header-2';
import { blockHeader } from './DefineBlock/block-header.js';

export const customListBlock = (editor) => {
  editor?.Blocks.add('text-inline', {
    label: 'Text Inline',
    select: true,
    content: {
      type: 'text',
      tagName: 'span',
      components: 'Insert your text here',
    },
    media: svgText,
  });
  editor?.Blocks.add('text', {
    label: 'Text',
    select: true,
    content: {
      type: 'text',
      components: 'Insert your text here',
    },
    media: svgText,
  });
  editor?.Blocks.add('divider', {
    label: 'Divider',
    content: `<table style="width: 100%; margin-top: 5px;" cellspacing="0">
        <tr>
          <td style="background-color: black; height: 1px"></td>
        </tr>
      </table>
      <style>
      </style>`,
    attributes: { class: 'gjs-fonts gjs-f-divider' },
  });
  editor?.Blocks.add('footer', {
    label: 'Footer',
    content: blockFooter,
  });
  editor?.Blocks.add('footer2', {
    label: 'Footer 2',
    content: blockFooter2,
  });
  editor?.Blocks.add('header', {
    label: 'Header',
    content: blockHeader,
    media: svgHeader,
  });
  editor?.Blocks.add('header2', {
    label: 'Header 2',
    content: blockHeader2,
    media: svgHeader,
  });
  editor?.Blocks.add('banner', {
    label: 'Banner',
    content: blockBanner,
    // media: svgBanner,
  });
  // editor?.Blocks.add('button', {
  //   label: 'Button',
  //   // content: '<table data-gjs-type="link" style="background: red;><tbody><tr><td><a style="margin: 16px">Button</a></td></tr></tbody></table>'
  //   content: '<table cellspacing="0" style="background: red; padding: 16px 10px;"><tbody><tr><td><a style="color: white;">Button</a></td></tr></tbody></table>',
  // })
};

export const removeBlocks = (editor) => {
  editor?.BlockManager.remove('text');
  editor?.BlockManager.remove('button');
  editor?.BlockManager.remove('divider');
};

import {
  DeleteFilled,
  DownOutlined,
  EditOutlined,
  EyeOutlined,
  InboxOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { useAppSelector } from 'app/hooks';
import EmployeeColumn from 'components/EmployeeColumn';
import ITVTable from 'components/ITVTable';
import MenuAction from 'components/MenuAction';
import { selectConfiguration } from 'features/configuration/configurationSlice';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getUser } from 'utils/auth';
// import Header from 'features/employee/components/HeaderEmployee';
import { Dropdown, Menu, Modal, Upload } from 'antd';
import { Registration } from './component/RegistrationForm';
import {
  createPtiParticipant,
  exportPti,
  getPtiParticipant,
  updatePtiParticipant,
} from 'features/insurances/insuranceAction';
import { selectInsurance } from 'features/insurances/insuranceSlice';
import moment from 'moment';
import { YYYY_MM_DD } from 'constants/commons';
import CustomButton from 'styles/buttonStyled';
import styled from 'styled-components';
import PageHeader from 'components/PageHeader';
import { SendPTIForm } from './component/SendPtiForm';
import { setCheckRow } from 'features/employee/employeeSlice';

export default function PTIParticipantList() {
  const dispatch = useDispatch();
  const location: any = useLocation();
  const menuStore = useAppSelector(selectConfiguration);
  const dataScreenManage = menuStore?.dataScreenManage?.result;
  const insurance = useAppSelector(selectInsurance);
  const dataPti = insurance?.dataPti;

  const [titleHeader, setTitleHeader] = useState('');
  const [sizePage, setSizePage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState<any>([]);
  const [currentItem, setCurrentItem] = useState(null);
  const [isSendPti, setIsSendPti] = useState(false);
  const [selectedUser, setSelectedUser] = useState([]);

  useEffect(() => {
    const temp = dataScreenManage
      ?.filter((item: any) => location.pathname === item.path)
      ?.map((item: any) => item.nameScreen);

    setTitleHeader(temp ? temp[0] : '');
  }, [dataScreenManage, location.pathname]);

  useEffect(() => {
    if (dataPti) {
      const newData = dataPti?.map((item: any) => ({ ...item, disabled: item?.is_send }));
      setData(newData);
    }
  }, [dataPti]);

  const callApi = () => {
    dispatch(
      getPtiParticipant({
        page: currentPage,
        limit: sizePage,
      }),
    );
  };

  useEffect(() => {
    callApi();
  }, [dispatch, currentPage, sizePage]);

  const menu = [
    {
      name: 'Edit',
      icon: <EditOutlined style={{ color: 'red' }} />,
      handleClick: (data: any) => {
        setCurrentItem(data);
        setVisible(true);
      },
    },
  ];

  const columns = [
    {
      title: 'Employee ID',
      key: 'employee_id',
      dataIndex: 'employee_id',
      width: 80,
      minWidth: 80,
      align: 'center',
      render: (text: any, record: any) => text,
    },
    {
      title: 'Employee Name',
      key: 'fullName',
      dataIndex: 'fullName',
      width: 200,
      minWidth: 200,
      render: (_: any, record: any) => {
        return (
          <EmployeeColumn
            avatar={
              record?.full_name
                ? `${record?.full_name}`
                : `${record?.lastName} ${record?.firstName}`
            }
            fullName={
              record?.full_name
                ? `${record?.full_name}`
                : `${record?.lastName} ${record?.firstName}`
            }
            email={record?.email}
          />
        );
      },
    },
    {
      title: 'Group',
      key: 'employee_group',
      dataIndex: 'employee_group',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (text: any) => text,
    },
    {
      title: 'Premium amount',
      key: 'insurance_premium',
      dataIndex: 'insurance_premium',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (text: any) => text,
    },
    {
      title: 'PTI',
      key: 'pti',
      dataIndex: 'pti',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (text: any) => text,
    },
    {
      title: 'Effective Start Date',
      key: 'valid_start_date',
      dataIndex: 'valid_start_date',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (text: any) => (text ? moment(text).format(YYYY_MM_DD) : ''),
    },
    {
      title: 'Effective End Date',
      key: 'valid_end_date',
      dataIndex: 'valid_end_date',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (text: any) => (text ? moment(text).format(YYYY_MM_DD) : ''),
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'action',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (text: any, record: any) => <MenuAction menu={menu} data={record} />,
    },
  ];

  const buttonMenu = (
    <Menu>
      <Menu.Item
        disabled={!selectedUser || selectedUser?.length == 0}
        onClick={() => {
          sendAction(true);
        }}
      >
        Auto send
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setIsSendPti(true);
          setVisible(true);
        }}
      >
        Attach file
      </Menu.Item>
    </Menu>
  );

  const sendAction = (isAutoSend: boolean) => {
    const body = {
      userIds: selectedUser,
      isAutoSend,
    };
    dispatch(
      exportPti({
        body,
        callBack: () => {
          dispatch(setCheckRow([]));
          setVisible(false);
          setCurrentItem(null);
          setIsSendPti(false);
          callApi();
        },
      }),
    );
  };

  const sendFile = (file: any) => {
    const data = new FormData();
    data.append('file', file);
    dispatch(
      exportPti({
        body: data,
        callBack: () => {
          setVisible(false);
          setCurrentItem(null);
          setIsSendPti(false);
          callApi();
        },
      }),
    );
  };

  return (
    <>
      <PageHeader title={titleHeader} />
      <WrapperDiv>
        <CustomButton
          aType="outline"
          type="dashed"
          disabled={!selectedUser || selectedUser?.length == 0}
          onClick={() => sendAction(false)}
        >
          Generate
        </CustomButton>
        <Dropdown overlay={buttonMenu}>
          <CustomButton style={{ marginLeft: 5 }} aType="outline">
            Action <DownOutlined />
          </CustomButton>
        </Dropdown>
        <CustomButton
          icon={<PlusOutlined />}
          type="primary"
          onClick={() => {
            setVisible(true);
          }}
        >
          Add new registration
        </CustomButton>
      </WrapperDiv>
      <Modal
        open={visible}
        onCancel={() => {
          setVisible(false);
          setCurrentItem(null);
          setIsSendPti(false);
        }}
        title={
          isSendPti ? 'Send PTI' : currentItem ? currentItem?.full_name : 'Add new registration'
        }
        footer={null}
        destroyOnClose
        maskClosable={false}
      >
        {!isSendPti ? (
          <Registration
            item={currentItem}
            onSubmit={(values: any) => {
              if (currentItem) {
                const { userId, startDate, endDate, ...rest } = values;
                dispatch(
                  updatePtiParticipant({
                    body: {
                      ...rest,
                      validStartDate: startDate,
                      validEndDate: endDate,
                      userId: currentItem?.user_id,
                    },
                    id: currentItem?.id,
                    callBack: () => {
                      setVisible(false);
                      setCurrentItem(null);
                      callApi();
                    },
                  }),
                );
              } else
                dispatch(
                  createPtiParticipant({
                    body: {
                      ...values,
                      validStartDate: values?.startDate,
                      validEndDate: values?.endDate,
                    },
                    callBack: () => {
                      setVisible(false);
                      setCurrentItem(null);
                      callApi();
                    },
                  }),
                );
            }}
          />
        ) : (
          <SendPTIForm callBack={sendFile} />
        )}
      </Modal>
      <ITVTable
        data={data || []}
        columns={columns}
        isRowSelect={true}
        setOffset={setCurrentPage}
        setSelectedUser={setSelectedUser}
        offset={currentPage}
        setLimit={setSizePage}
        limit={sizePage}
        rowKey={['user_id']}
      />
    </>
  );
}

const WrapperDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  margin-bottom: 16px;
`;

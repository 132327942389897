export const SORT_TYPES = {
  ascend: 'asc',
  descend: 'desc',
};

export const CONTRACT_SALARY_SORT_FIELDS = {
  employeeId: 'employeeId',
  fullName: 'fullName',
  resideStatus: 'resideStatus',
  taxProfile: 'taxProfile',
  salary: 'salary',
  ratio: 'ratio',
};

export const ALLOWANCE_SORT_FIELDS = {
  employeeId: 'employeeId',
  fullName: 'fullName',
};

export const MONTH_YEAR_FORMAT = 'YYYY-MM';

export const YEAR_FORMAT = 'YYYY';

export const ContractSalaryConfirmStatus = {
  APPROVED: 'approved',
};

export const KEY_EMPLOYEE_TAB = {
  INSURANCE: 'insurance',
  TIMESHEET: 'timesheet',
  ALLOWANCE: 'allowance',
  BONUS: 'bonus',
  DEPENDENTS: 'dependents',
  ADVANCES: 'advances',
  CONTRACT_SALARY: 'contract_salary',
  ADJUSTMENT_SALARY: 'adjustment_salary',
};

export const PAYROLL_YEAR_SORT_FIELDS = {
  employee_id: 'employee_id',
  full_name: 'full_name',
};

export const ADJUSTMENT_SALARY_SORT_FIELDS = {
  employeeId: 'employeeId',
  fullName: 'fullName',
};

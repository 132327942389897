import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import colors from 'styles/variables/colors';
const themeSlice = createSlice({
  name: 'theme',
  initialState: { colors },
  reducers: {
    updateTheme: (state, action: PayloadAction<typeof colors>) => {
      const keys: string[] = Object.keys(action.payload);
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        state.colors[key] = action.payload[key];
      }
    },
  },
});

export const { updateTheme } = themeSlice.actions;
export default themeSlice.reducer;

import { useAppDispatch, useAppSelector } from 'app/hooks';
import PageHeader from 'components/PageHeader';
import { selectConfiguration } from 'features/configuration/configurationSlice';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import SideBarMenu from './components/SideBarMenu';
import CustomButton from 'styles/buttonStyled';
import CandidatesList from './components/CandidatesList';
import {
  getCandidateManagement,
  getCandidateManagementInterviewer,
  sendMailOffer,
} from '../recruitmentAction';
import { useHistory } from 'react-router-dom';
import ITVSearch from 'components/ITVSearch';
import { Dropdown, Menu, Popover } from 'antd';
import { DownOutlined, FilterOutlined } from '@ant-design/icons';
import { getUser } from 'utils/auth';
import { isApproveOffer, isInterviewer } from '../jobs/components/types';
import { selectEmployee, setCheckRow } from 'features/employee/employeeSlice';
import { useTranslation } from 'react-i18next';
import { CustomButtonFilter } from 'features/employee/components/Functions';
import CandidateSimpleFilter from './components/CandidateSimpleFilter';

enum HiringProcessStatusCandidate {
  APPLICATION_RECEIVED = 'Application Received',
  SHORTLISTED = 'Shortlisted',
  IN_PROGRESS = 'In Progress',
  JOB_OFFER = 'Job Offer',
  OFFER_ACCEPTED = 'Offer Accepted',
  OFFER_REJECTED = 'Offer Rejected',
  CLOSED = 'Closed',
}

const CandidatesManagement = () => {
  const { t } = useTranslation(['recruitment', 'employee']);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const menuStore = useAppSelector(selectConfiguration);
  const dataScreenManage = menuStore?.dataScreenManage?.result;
  const [titleHeader, setTitleHeader] = useState('');
  const [sizePage, setSizePage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchName, setSearchName] = useState('');
  const [status, setStatus] = useState<HiringProcessStatusCandidate | undefined>();
  const [data, setData] = useState<any>([]);
  const isCheckAll = useAppSelector(selectEmployee).isCheckAll;
  const [isOpenSimpleFilter, setIsOpenSimpleFilter] = useState(false);
  const [filter, setFilter] = useState<any>({});

  useEffect(() => {
    const temp = dataScreenManage
      ?.filter((item) => location.pathname === item.path)
      ?.map((item) => item.nameScreen);

    setTitleHeader(temp ? temp[0] : '');
  }, [dataScreenManage, location.pathname]);

  const callApi = () => {
    const query = {
      limit: sizePage,
      offset: currentPage,
      searchName,
      status,
      filter,
      //
    };
    if (isInterviewer()) {
      dispatch(getCandidateManagementInterviewer(query));
    } else {
      dispatch(getCandidateManagement(query));
    }
  };

  useEffect(() => {
    if (!isCheckAll) callApi();
  }, [dispatch, sizePage, currentPage, searchName, status, filter]);

  useEffect(() => {
    if (isCheckAll) {
      const query = { status };
      if (isInterviewer()) {
        dispatch(getCandidateManagementInterviewer(query));
      } else {
        dispatch(getCandidateManagement(query));
      }
    }
  }, [isCheckAll, status]);

  useEffect(() => {
    dispatch(setCheckRow([]));
  }, [status]);

  const menuItems = [
    {
      key: '1',
      label: t('recruitment:interview_invitation'),
      onClick: () => {
        dispatch(
          sendMailOffer({
            body: {
              jobCandidateIds: data,
              status: 'approved',
              type: 'Interview',
            },
          }),
        );
      },
    },
    {
      key: '2',
      label: t('recruitment:interview_rejection'),
      onClick: () => {
        dispatch(
          sendMailOffer({
            body: {
              jobCandidateIds: data,
              status: 'rejected',
              type: 'Interview',
            },
          }),
        );
      },
    },
  ];

  const buttonMenu = [
    {
      key: '1',
      label: t('recruitment:offer_email'),
      onClick: () => {
        dispatch(
          sendMailOffer({
            body: {
              jobCandidateIds: data,
              status: 'approved',
              type: 'Offer',
            },
          }),
        );
      },
    },
    {
      key: '2',
      label: t('recruitment:rejection_email'),
      onClick: () => {
        dispatch(
          sendMailOffer({
            body: {
              jobCandidateIds: data,
              status: 'rejected',
              type: 'Offer',
            },
          }),
        );
      },
    },
  ];

  const handleOpenSimpleFilter = (visible: any) => {
    setIsOpenSimpleFilter(visible);
  };

  return (
    <>
      <PageHeader title={titleHeader} />

      <div>
        {(!isInterviewer() || isApproveOffer()) && (
          <SideBarMenu status={status} setStatus={setStatus} onChange={() => setFilter({})} />
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
            width: '100%',
            overflow: 'auto',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 5 }}>
            {(isApproveOffer() || getUser()?.permissions?.includes('RECRUIT_JOB_CREATED')) && (
              <>
                {!isInterviewer() && (
                  <CustomButton
                    style={{ marginBottom: '1rem' }}
                    onClick={() => history.push(`/recruitment/candidates/detail/draft/create`)}
                  >
                    {t('recruitment:create_candidate')}
                  </CustomButton>
                )}
                {status === HiringProcessStatusCandidate.IN_PROGRESS ? (
                  <Dropdown disabled={!data?.length} menu={{ items: menuItems }}>
                    <CustomButton aType="primary">
                      {t('recruitment:send_interview_email')} <DownOutlined />
                    </CustomButton>
                  </Dropdown>
                ) : (
                  status === HiringProcessStatusCandidate.JOB_OFFER && (
                    <Dropdown disabled={!data?.length} menu={{ items: buttonMenu }}>
                      <CustomButton style={{ marginLeft: 5 }}>
                        {t('recruitment:send_offer_email')} <DownOutlined />
                      </CustomButton>
                    </Dropdown>
                  )
                )}
              </>
            )}
            <Popover
              placement="bottom"
              content={
                <CandidateSimpleFilter
                  filter={filter}
                  status={status}
                  onFilterChange={(value) => {
                    setFilter(value);
                    setCurrentPage(1);
                  }}
                />
              }
              trigger="click"
              open={isOpenSimpleFilter}
              onOpenChange={handleOpenSimpleFilter}
            >
              <CustomButtonFilter style={{ marginLeft: 8 }}>
                <FilterOutlined />
                {t('employee:filter.title')}
                <DownOutlined />
              </CustomButtonFilter>
            </Popover>
            <ITVSearch handleSearch={(value: string) => setSearchName(value)} />
          </div>
          <div style={{ overflowX: 'auto', width: '100%' }}>
            <CandidatesList
              status={status}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              setSizePage={setSizePage}
              sizePage={sizePage}
              callBack={callApi}
              setData={setData}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CandidatesManagement;

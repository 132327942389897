import { Checkbox, Col, Form, Modal, Popconfirm, Row, Select } from 'antd';
import { selectFormatDate } from 'app/commonRedux/appSlice';
import { useAppSelector } from 'app/hooks';
import ITVTable from 'components/ITVTable';
import { actions, selectConfiguration } from 'features/configuration/configurationSlice';
import { selectEmployee } from 'features/employee/employeeSlice';
import chain from 'lodash/chain';
import reduce from 'lodash/reduce';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import CustomButton from 'styles/buttonStyled';
import { CustomInputNumber } from './AdvancesTable';
import { CustomDatePicker } from './ViewPlanEdit';

enum MODE {
  VIEW = 'VIEW',
  ADD = 'ADD',
  UPDATE = 'UPDATE',
}

const ViewPlan = ({
  mode,
  recordItem,
  onSave,
  dataTablePlan,
  setDataTablePlan,
  dataTablePlanOri,
  setDataTablePlanOri,
  data,
  setData,
  setMode,
  currency,
}: any) => {
  const [form] = Form.useForm();
  const { t } = useTranslation(['validation', 'masterConfig', 'insurance', 'common', 'overtime']);
  const dataStore = useAppSelector(selectConfiguration);
  const dispatch = useDispatch();
  const { setErrorMessage } = actions;
  const error: any = dataStore.error;
  const formatDate = useAppSelector(selectFormatDate);
  const { Option } = Select;
  const [sizePage, setSizePage] = useState(12);
  const [currentPage, setCurrentPage] = useState(1);

  const [errMsg, setErrMsg] = useState<any>('');
  const [valueRemain, setValueRemain] = useState();
  const [nameType, setNameType] = useState('');
  const employee = useAppSelector<any>(selectEmployee);
  const dataAdvances = employee?.dataListAdvances?.result;
  const MINIMUM_STEP = 100000;

  console.log('view plan');

  useEffect(() => {
    if (error?.fieldErrors) {
      form.setFields([
        {
          name: 'name',
          errors: error?.fieldErrors?.name,
        },
      ]);
    }
    return () => {
      dispatch(setErrorMessage(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const handleFormChange = () => {};

  useEffect(() => {
    if (mode === MODE.UPDATE && recordItem) {
      form.setFieldsValue({
        name: recordItem?.name,
        description: recordItem?.description,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordItem]);

  const onFinish = () => {
    if (mode === MODE.ADD) {
      const moneyDataPlan = dataTablePlan?.reduce((acc: any, curr: any) => {
        return acc + curr.amount;
      }, 0);

      if (moneyDataPlan === recordItem?.amount) {
        onSave(dataTablePlan, recordItem?.key);
      } else {
        setErrMsg([{ message: 'Your payment plan does not match with your Advance Amount' }]);
      }
    }
  };

  function foo(l: any, target: any) {
    const off =
      target -
      reduce(
        l,
        function (acc, x) {
          return acc + Math.round(x);
        },
        0,
      );
    return chain(l)
      .sortBy(function (x) {
        return Math.round(x) - x;
      })
      .map(function (x, i) {
        return Math.round(x) + (off > i) - (i >= l.length + off);
      })
      .value();
  }
  function getApportionStep(amount: any) {
    let step = MINIMUM_STEP;
    if (amount < step) {
      return amount;
    }
    if (amount >= 100000 && amount <= 999999) {
      step = 1000;
    } else if (amount >= 1000000 && amount <= 9999999) {
      step = 10000;
    } else if (amount >= 10000000 && amount <= 36000000) {
      step = 100000;
    } else if (amount >= 36000001 && amount <= 99999999) {
      step = 1000000;
    } else if (amount >= 100000000 && amount <= 999999999) {
      step = 1000000;
    } else if (amount >= 1000000000 && amount <= 9999999999) {
      step = 10000000;
    }
    return step;
  }
  function recursiveApportion(remainingAmount: any, amountPerMonths: any): any {
    if (!remainingAmount) return amountPerMonths;
    let step = getApportionStep(remainingAmount);
    const updated = amountPerMonths?.map((item: any) => {
      if (remainingAmount > 0 && remainingAmount > step) {
        item.amount += step;
        remainingAmount -= step;
      } else {
        item.amount += remainingAmount;
        remainingAmount -= remainingAmount;
      }
      return item;
    });
    const rs = recursiveApportion(remainingAmount >= 0 ? remainingAmount : 0, updated);
    return rs;
  }
  const handleOnChangePaidStatus = (e: any, record: any) => {
    if (e.target.checked) {
      let temp1: any = dataTablePlan?.map((obj: any, objIndex: any) => {
        if (obj?.id === record?.id) {
          return { ...obj, paidStatus: e.target.checked, indexDisable: record?.id + 1 };
        }
        return { ...obj, indexDisable: record?.id + 1 };
      });
      let temp2: any = dataTablePlanOri?.map((obj: any, objIndex: any) =>
        obj?.id === record?.id
          ? { ...obj, paidStatus: e.target.checked }
          : { ...obj, paidStatus: false },
      );
      setDataTablePlan(temp1);
      setDataTablePlanOri(temp2);
    } else {
      let temp1: any = dataTablePlan?.map((obj: any, objIndex: any) => {
        if (obj?.id >= record?.id) {
          return { ...obj, paidStatus: false, indexDisable: record?.id };
        }
        return { ...obj, indexDisable: record?.id };
      });
      let temp2: any = dataTablePlanOri?.map((obj: any, objIndex: any) =>
        obj?.id === record?.id
          ? { ...obj, paidStatus: e.target.checked }
          : { ...obj, paidStatus: false },
      );
      setDataTablePlan(temp1);
      setDataTablePlanOri(temp2);
    }
  };
  const handleOnChangeAmount = (value: any, trueIndex: any) => {
    let temp1: any = dataTablePlan?.map((obj: any, objIndex: any) =>
      objIndex === trueIndex ? { ...obj, amount: value ?? 0 } : obj,
    );

    let arrUpdateMoneyAfter = [];
    let arrUpdateMoneyBefore = [];
    let arrUpdateMoney = [];
    for (let index = 0; index < temp1.length; index++) {
      if (index > trueIndex) {
        arrUpdateMoneyAfter.push(temp1[index]);
      } else {
        arrUpdateMoneyBefore.push(temp1[index]);
      }
    }
    const moneyBefore =
      recordItem?.amount -
      arrUpdateMoneyBefore?.reduce((acc: any, curr: any) => {
        return acc + curr.amount;
      }, 0);

    let arrUpdateMoneyAfter1 = arrUpdateMoneyAfter?.map((item, idx) => {
      return {
        ...item,
        amount:
          Math.floor(moneyBefore / arrUpdateMoneyAfter.length) > 0
            ? Math.floor(moneyBefore / arrUpdateMoneyAfter.length)
            : 0,
      };
    });
    let arrayAmount = arrUpdateMoneyAfter1?.map((item, idx) => {
      return item.amount;
    });
    let arrUpdateMoneyAfter2 = foo(arrayAmount, moneyBefore)?.map((item, idx) => {
      return {
        id: arrUpdateMoneyAfter1[idx]?.id,
        month: arrUpdateMoneyAfter1[idx]?.month,
        amount: Math.floor(item) >= 0 ? Math.floor(item) : 0,
        paidStatus: arrUpdateMoneyAfter1[idx]?.paidStatus,
      };
    });

    arrUpdateMoney = [...arrUpdateMoneyBefore, ...arrUpdateMoneyAfter2];

    setDataTablePlan(arrUpdateMoney);
  };
  const COLUMNS: any = [
    {
      title: t('employee:advance_information_fields.month'),
      dataIndex: 'month',
      key: 'month',
      width: 150,
      minWidth: 150,
      render: function (text: any, record: any, index: any) {
        return record?.month;
      },
    },

    {
      title: t('employee:advance_information_fields.amount'),
      dataIndex: 'amount',
      key: 'amount',
      // width: 200,
      render: function (text: any, record: any, index: any) {
        const trueIndex = index + sizePage * (currentPage - 1);
        return (
          <CustomInputNumber
            disabled={record?.paidStatus}
            max={10000000000}
            min={0}
            style={{ width: '100%' }}
            onChange={(value: any) => {
              handleOnChangeAmount(value, trueIndex);
            }}
            value={record?.amount}
            formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value: any) => (value ? value.replace(/\$\s?|(,*)/g, '') : 100000)}
          />
        );
      },
    },
    {
      title: t('employee:advance_information_fields.paid'),
      dataIndex: 'paid',
      key: 'paid',
      width: 150,
      minWidth: 150,
      align: 'center',
      render: function (text: any, record: any, index: any) {
        let TodayDate = new Date();
        let monthCurrent = TodayDate.getMonth() + 1;
        let monthRecord = Number(record?.month?.substring(0, 2));
        let yearCurrent = TodayDate.getFullYear();
        let yearRecord = Number(record?.month?.substring(3));
        let dateCurrent = new Date(yearCurrent, monthCurrent - 1, 2);
        let dateRecord = new Date(yearRecord, monthRecord - 1, 2);
        return (
          <Checkbox
            disabled={record?.id > record?.indexDisable || dateCurrent < dateRecord}
            checked={record?.paidStatus}
            onChange={(e: any) => {
              handleOnChangePaidStatus(e, record);
            }}
          ></Checkbox>
        );
      },
    },
  ];

  useEffect(() => {
    for (let index = 0; index < data.length; index++) {
      if (data[index]?.key === recordItem?.key && data[index]?.paymentPlan?.length > 0) {
        return setDataTablePlan(data[index]?.paymentPlan);
      } else {
        var arr = Array.from(new Array(recordItem?.term), (x, i) => i + 1);

        let monthUpdate = arr?.map((item, idx) => {
          return {
            id: idx,
            month: moment(recordItem?.effectiveDate).add(idx, 'months').format('MM/YYYY'),
            amount: 0,
            paidStatus: false,
            indexDisable: 0,
          };
        });
        let monthPlan = recursiveApportion(recordItem?.amount, monthUpdate);
        setDataTablePlan(monthPlan);
      }
    }

    setNameType(
      dataAdvances?.find((item: any) => item?.id === recordItem?.advanceConfigurationHistoryId)
        ?.name,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordItem]);

  useEffect(() => {
    let filterIsPaid = dataTablePlan?.filter((item: any) => {
      return !item.paidStatus;
    });
    const moneyIsPaid = filterIsPaid?.reduce((acc: any, curr: any) => {
      return acc + curr.amount;
    }, 0);
    setValueRemain(moneyIsPaid);
  }, [dataTablePlan]);

  const handleStartMonth = (value: any) => {
    let month = dataTablePlan?.map((item: any, idx: any) => {
      return {
        ...item,
        month: moment(value).add(idx, 'months').format('MM/YYYY'),
      };
    });
    setDataTablePlan(month);
  };

  const handleCancelPlan = () => {
    setMode('');
  };
  const [startMonth, setStartMonth] = useState();
  const monthFormat = 'MM/YYYY';

  useEffect(() => {
    let sortDataPlan = recordItem?.paymentPlan
      ? [...recordItem?.paymentPlan]
      : []?.sort((a: any, b: any) => {
          return a?.id - b?.id;
        });
    let effectiveDate = new Date(recordItem?.effectiveDate);
    setStartMonth(
      sortDataPlan.length > 0
        ? sortDataPlan?.[0]?.month
        : `${effectiveDate.getMonth() + 1 + '/' + effectiveDate.getFullYear()}`,
    );
  }, [recordItem]);

  return (
    <Modal
      visible={mode === MODE.ADD}
      title={`Payment Plan`}
      okText={t('timesheet:Save')}
      cancelText={t('timesheet:Cancel')}
      width={550}
      maskClosable={false}
      onCancel={handleCancelPlan}
      onOk={() => {}}
      centered
      footer={null}
    >
      <Form form={form} onFieldsChange={handleFormChange} layout="vertical">
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Row gutter={[16, 16]}>
              <ColLeft span={24}>{t('employee:advance_information_fields.effective_date')}</ColLeft>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={24}>{moment(recordItem?.effectiveDate).format(formatDate)}</Col>
            </Row>
          </Col>
          <Col span={8}>
            <Row gutter={[16, 16]}>
              <ColLeft span={24}>{t('employee:advance_information_fields.type_advance')}</ColLeft>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={24}>{nameType}</Col>
            </Row>
          </Col>
          <Col span={8}>
            <Row gutter={[16, 16]}>
              <ColLeft span={24}>{t('employee:advance_information_fields.term')}</ColLeft>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={24}>{recordItem?.term} months</Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={16}>
            <Row gutter={[16, 16]}>
              <ColLeft span={24}>{t('employee:advance_information_fields.advance_amount')}</ColLeft>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                {currency === 'VND' &&
                  `${recordItem?.amount}`?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                {currency !== 'VND' &&
                  Intl.NumberFormat('en-US', {
                    currency: 'USD',
                    minimumFractionDigits: 2,
                  }).format(recordItem?.amount)}
              </Col>
            </Row>
          </Col>
          <Col span={8}>
            <Row gutter={[16, 16]}>
              <ColLeft span={24}>{t('employee:advance_information_fields.remaining')}</ColLeft>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                {currency === 'VND' && `${valueRemain}`?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                {currency !== 'VND' &&
                  Intl.NumberFormat('en-US', {
                    currency: 'USD',
                    minimumFractionDigits: 2,
                  }).format(valueRemain)}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={16}>
            <Form.Item
              name={'start_month'}
              label={
                <span style={{ fontWeight: 700 }}>
                  {t('employee:advance_information_fields.startMonth')}
                </span>
              }
              rules={[
                {
                  required: true,
                  message: t('HRM_VAD_REQUIRED', { field: `Start Month` }),
                },
              ]}
            >
              {startMonth && (
                <CustomDatePicker
                  format={monthFormat}
                  style={{ width: '32%' }}
                  picker="month"
                  onChange={handleStartMonth}
                  disabledDate={(current) => {
                    return current && current < moment(recordItem?.effectiveDate).add(0, 'month');
                  }}
                  // defaultValue={
                  //   recordItem?.effectiveDate
                  //     ? moment(recordItem?.effectiveDate).add(1, 'months')
                  //     : undefined
                  // }
                  defaultValue={recordItem && moment(startMonth, monthFormat)}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <div>
              <span>Currency Units: </span>
              <Select defaultValue={currency} disabled style={{ width: 80 }}>
                <Option value={currency}>{currency}</Option>
              </Select>
            </div>
          </Col>
        </Row>
        <div>
          <ITVTable
            columns={COLUMNS}
            data={dataTablePlan}
            isRowSelect={false}
            setOffset={setCurrentPage}
            offset={currentPage}
            setLimit={setSizePage}
            limit={sizePage}
            width={450}
            height={350}
          />
        </div>
        {errMsg && (
          <div style={{ color: 'red', height: 24, overflowY: 'scroll' }}>
            {errMsg?.flat(1)?.map((item: any, i: any, arr: any) => {
              let divider = i < arr.length - 1 && <div></div>;
              return (
                <span key={i}>
                  <div>{item?.message}</div>
                  {/* <ul style={{ listStylePosition: 'inside' }}>
                  {item?.message?.map((childItem: any) => (
                    <li style={{ listStyleType: 'disc' }}>{childItem}</li>
                  ))}
                </ul> */}
                  {divider}
                </span>
              );
            })}
          </div>
        )}
        <div
          style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: '2%' }}
        >
          <Popconfirm
            title={`Are you sure`}
            onConfirm={() => {
              onFinish();
            }}
            okText={`${t('insurance:Yes')}`}
            cancelText={`${t('insurance:no')}`}
          >
            <CustomButton>Confirm</CustomButton>
          </Popconfirm>
        </div>
      </Form>
    </Modal>
  );
};

export default ViewPlan;
export const ColCenter = styled(Col)`
  text-align: center;
  background: #fea481;
  color: white;
  font-weight: 700;
`;
export const ColLeft = styled(Col)`
  text-align: left;
  font-weight: 700;
  margin-top: 5px;
`;

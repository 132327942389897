import { PlusOutlined } from '@ant-design/icons';
import { Col, Form, Input, InputNumber, Popconfirm, Row, Select, Spin, notification } from 'antd';
import { useAppSelector } from 'app/hooks';
import { MODE } from 'constants/types';
import { actions, selectConfiguration } from 'features/configuration/configurationSlice';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import CustomButton from 'styles/buttonStyled';
import CustomSelect from 'styles/inputSelectStyled';
import { useEmployeeListForContractV2 } from 'hooks/fetchers/useEmployeeListForContractV2';
import { getAllDataInterviewTemplate } from 'features/configuration/configurationAction';

const { Option } = Select;

const CANDIDATE_STATUS = [
  { name: 'Application Received' },
  { name: 'Shortlisted' },
  { name: 'In Progress' },
  { name: 'Job Offer' },
  { name: 'Offer Accepted' },
  { name: 'Offer Rejected' },
  { name: 'Closed' },
];

const PROCESS_TYPE_EVENT = [{ name: 'Interview' }, { name: 'Test' }, { name: 'Other' }];

const TYPE_OF_TEST = [
  { name: 'Attitude Test' },
  { name: 'Job Knowledge Test' },
  { name: 'Personality Test' },
];

const AddStage = ({ mode, record, onSaveNewStage, stageOrder }: any) => {
  const [form] = Form.useForm();
  const { t } = useTranslation([
    'validation',
    'masterConfig',
    'setup_menu_screen',
    'insurance',
    'common',
    'overtime',
    'employee',
    'menu',
    'recruitment',
  ]);

  const [isLoadingPage, setIsLoading] = useState(false);

  const dataStore = useAppSelector(selectConfiguration);
  const dispatch = useDispatch();

  const filters = useMemo(() => ({ offset: 1, limit: 1000 }), []);
  const { data, isLoading } = useEmployeeListForContractV2(filters);

  const error = dataStore.error as any;
  const { setErrorMessage } = actions;

  const [processTypeEventSelected, setProcessTypeEventSelected] = useState();

  const dataInterviewTemplate = dataStore?.dataInterviewTemplate?.result;

  const params = {
    limit: 1000,
    offset: 1,
    searchName: '',
  };

  useEffect(() => {
    if (error?.fieldErrors) {
      form.setFields([
        {
          name: 'name',
          errors: error?.fieldErrors?.menuCode,
        },
        {
          name: 'eventType',
          errors: error?.fieldErrors?.menuName,
        },
      ]);
    }
    notification.destroy();

    return () => {
      dispatch(setErrorMessage(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    dispatch(getAllDataInterviewTemplate(params));
  }, [dispatch]);

  const handleFormChange = () => {};

  const getDataInterviewerSelected = (idInterviewerSelected: any) => {
    const result = idInterviewerSelected?.map((interviewerId: any) => {
      const findItem = data?.find((item) => item?.id === interviewerId);
      return {
        userId: findItem?.id,
        fullName: findItem?.fullName,
        email: findItem?.email,
      };
    });
    return result;
  };

  const onFinish = () => {
    form.validateFields().then((values) => {
      const newValues = {
        stageOrder: stageOrder,
        name: values?.stageName,
        eventType: values?.processTypeEvent,
        testType: processTypeEventSelected === 'Test' ? values?.typeOfTest : '',
        interviewers:
          processTypeEventSelected === 'Interview'
            ? getDataInterviewerSelected(values?.interviewers)
            : [],
        interviewTemplateId:
          processTypeEventSelected === 'Interview' ? values?.interviewTemplateId : '',
        statusCandidate: values?.candidateStatus,
        assigners: getDataInterviewerSelected(values?.assigners),
        externalInterviewers: values?.externalInterviewers,
      };
      onSaveNewStage(newValues, mode);
    });
  };

  const handleSelectProcessTypeEvent = (values: any) => {
    setProcessTypeEventSelected(values);
  };
  useEffect(() => {
    if (mode === MODE.ADD) {
      form.setFieldsValue({
        stageName: '',
      });
    } else {
      form.setFieldsValue({
        stageName: record?.name,
        processTypeEvent: record?.eventType,
        typeOfTest: record?.testType,
        interviewTemplateId: record?.interviewTemplateId,
        interviewers: record?.interviewers?.map((item) => item?.userId),
        candidateStatus: record?.statusCandidate,
        assigners: record?.assigners?.map((item) => item?.userId),
        externalInterviewers: record?.externalInterviewers,
      });
      setProcessTypeEventSelected(record?.eventType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isLoading || isLoadingPage ? (
    <div style={{ textAlign: 'center' }}>
      <Spin />
    </div>
  ) : (
    <Form form={form} onFieldsChange={handleFormChange} layout="vertical">
      <Row gutter={[16, 16]}>
        <Col span={5}>
          <span style={{ color: 'red' }}>* </span>
          {t('recruitment:stage_name')}:
        </Col>
        <Col span={6}>
          <Form.Item
            name={'stageName'}
            rules={[
              {
                whitespace: true,
                required: true,
                message: t('HRM_VAD_REQUIRED', {
                  field: t('recruitment:stage_name'),
                }),
              },
            ]}
          >
            <Input placeholder={t('recruitment:stage_name_placeHolderr')} />
          </Form.Item>
        </Col>
        <Col span={1} />
        <Col span={5}>
          <span style={{ color: 'red' }}>* </span>
          {t('recruitment:candidate_status')}:
        </Col>
        <Col span={6}>
          <Form.Item
            name={'candidateStatus'}
            rules={[
              {
                required: true,
                message: t('HRM_VAD_REQUIRED', {
                  field: t('recruitment:candidate_status'),
                }),
              },
            ]}
          >
            <CustomSelect
              allowClear
              showSearch
              placeholder={t('recruitment:candidate_status_placeHolder')}
              filterOption={(input, option) =>
                option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0 ||
                option?.value?.toString()?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
              }
            >
              {CANDIDATE_STATUS?.map((data: any, key: any) => (
                <Option key={key} value={data?.name}>
                  {data?.name}
                </Option>
              ))}
            </CustomSelect>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={5}>
          <span style={{ color: 'red' }}>* </span>
          {t('recruitment:process_type_event')}:
        </Col>
        <Col span={6}>
          <Form.Item
            name={'processTypeEvent'}
            rules={[
              {
                required: true,
                message: t('HRM_VAD_REQUIRED', {
                  field: t('recruitment:process_type_event'),
                }),
              },
            ]}
          >
            <CustomSelect
              allowClear
              showSearch
              placeholder={t('recruitment:process_type_event_placeHolder')}
              filterOption={(input, option) =>
                option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0 ||
                option?.value?.toString()?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
              }
              onChange={(value) => {
                handleSelectProcessTypeEvent(value);
              }}
            >
              {PROCESS_TYPE_EVENT?.map((data: any, key: any) => (
                <Option key={key} value={data?.name}>
                  {data?.name}
                </Option>
              ))}
            </CustomSelect>
          </Form.Item>
        </Col>
        <Col span={1} />
        {processTypeEventSelected == 'Interview' && (
          <>
            <Col span={5}>
              <span style={{ color: 'red' }}>* </span>
              {t('menu:interview-template')}:
            </Col>
            <Col span={6}>
              <Form.Item
                name={'interviewTemplateId'}
                rules={[
                  {
                    required: true,
                    message: t('HRM_VAD_REQUIRED', {
                      field: t('menu:interview-template'),
                    }),
                  },
                ]}
              >
                <CustomSelect
                  allowClear
                  showSearch
                  placeholder={t('recruitment:interview_template_placeHolder')}
                  filterOption={(input, option) =>
                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                    option?.value.toString()?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {dataInterviewTemplate?.map((data: any, key: any) => (
                    <Option key={key} value={data?.id}>
                      {data?.name}
                    </Option>
                  ))}
                </CustomSelect>
              </Form.Item>
            </Col>
          </>
        )}
        {processTypeEventSelected == 'Test' && (
          <>
            <Col span={5}>
              <span style={{ color: 'red' }}>* </span>
              {t('recruitment:type_of_test')}:
            </Col>
            <Col span={6}>
              <Form.Item
                name={'typeOfTest'}
                rules={[
                  {
                    required: true,
                    message: t('HRM_VAD_REQUIRED', {
                      field: t('recruitment:type_of_test'),
                    }),
                  },
                ]}
              >
                <CustomSelect
                  allowClear
                  showSearch
                  placeholder={t('recruitment:type_of_test_placeHolder')}
                  filterOption={(input, option) =>
                    option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0 ||
                    option?.value?.toString()?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                  }
                >
                  {TYPE_OF_TEST?.map((data: any, key: any) => (
                    <Option key={key} value={data?.name}>
                      {data?.name}
                    </Option>
                  ))}
                </CustomSelect>
              </Form.Item>
            </Col>
          </>
        )}
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={5}>
          <span style={{ color: 'red' }}>* </span>
          {t('recruitment:assigners')}:
        </Col>
        <Col span={6}>
          <Form.Item
            name={'assigners'}
            rules={[
              {
                required: true,
                message: t('HRM_VAD_REQUIRED', {
                  field: t('recruitment:assigners'),
                }),
              },
            ]}
          >
            <CustomSelect
              mode="multiple"
              allowClear
              showSearch
              placeholder={t('recruitment:assigners_placeHolder')}
              filterOption={(input, option) =>
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                option?.value.toString()?.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              maxTagCount={'responsive'}
            >
              {data?.map((data: any, key: any) => (
                <Option key={key} value={data?.id}>
                  {data?.fullName}
                </Option>
              ))}
            </CustomSelect>
          </Form.Item>
        </Col>
        <Col span={1} />
        <Col span={5}>
          <span style={{ color: 'red', visibility: 'hidden' }}>* </span>
          {t('recruitment:interviewer_external')}:
        </Col>
        <Col span={6}>
          <Form.Item
            name={'externalInterviewers'}
            rules={[
              {
                whitespace: true,
                required: false,
                message: t('HRM_VAD_REQUIRED', {
                  field: t('recruitment:interviewer_external'),
                }),
              },
            ]}
          >
            <Input placeholder={t('recruitment:interviewer_external_placeHolder')} />
          </Form.Item>
        </Col>
      </Row>

      {processTypeEventSelected == 'Interview' && (
        <>
          <Row gutter={[16, 16]}>
            <Col span={5}>
              <span style={{ color: 'red' }}>* </span>
              {t('recruitment:interviewer_internal')}:
            </Col>
            <Col span={6}>
              <Form.Item
                name={'interviewers'}
                rules={[
                  {
                    required: true,
                    message: t('HRM_VAD_REQUIRED', {
                      field: t('recruitment:interviewer_internal'),
                    }),
                  },
                ]}
              >
                <CustomSelect
                  mode="multiple"
                  allowClear
                  showSearch
                  placeholder={t('recruitment:interviewer_internal_placeHolder')}
                  filterOption={(input, option) =>
                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                    option?.value.toString()?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  maxTagCount={'responsive'}
                >
                  {data?.map((data: any, key: any) => (
                    <Option key={key} value={data?.id}>
                      {data?.fullName}
                    </Option>
                  ))}
                </CustomSelect>
              </Form.Item>
            </Col>
          </Row>
        </>
      )}

      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: 60 }}>
        <Popconfirm
          title={t('overtime:are_you_sure')}
          onConfirm={() => {
            onFinish();
          }}
          okText={`${t('insurance:Yes')}`}
          cancelText={`${t('insurance:no')}`}
        >
          <CustomButton>{t('masterConfig:action.save')}</CustomButton>
        </Popconfirm>
      </div>
    </Form>
  );
};

export default AddStage;

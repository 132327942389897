import { useEffect, useMemo, useState } from 'react';
import AssignTrainee from './components/AssignTrainee';
import CourseContent from './components/CourseContent';
import GeneralInformation from './components/GeneralInformation';
import CustomButton from 'styles/buttonStyled';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  createTraining,
  getTrainingCategory,
  getTrainingDetail,
  getTrainingStatus,
  patchTraining,
  updateResult,
} from '../trainingAction';
import { useHistory, useParams } from 'react-router-dom';
import { selectTrainingData } from '../trainingSlice';
import ButtonBack from 'components/ButtonBack';
import moment from 'moment';
import { actions } from '../trainingSlice';
import { useTranslation } from 'react-i18next';

interface Props {
  setCreated?: any;
}
const FormCourse = ({ setCreated }: Props) => {
  const { id }: any = useParams();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [selectedUser, setSelectedUser] = useState([]);
  const [file, setFile] = useState();
  const trainData = useAppSelector(selectTrainingData);
  const trainingDetail = trainData?.trainingDetail;
  const { resettrainingDetail, resetfilterTrainee } = actions;
  const [dataResult, setDataResult] = useState<any>([]);
  const { t } = useTranslation(['action']);

  const defaultValue = {
    courseName: '',
    trainerName: '',
    categoryId: 1,
    statusId: 1,
    description: '',
    dateFrom: '',
    dateTo: '',
    trainingDate: {
      from: '',
      to: '',
    },
    budget: 0,
    contentLessonName: '',
    contentDescription: '',
    contentLearningUrl: '',
    actualAmount: null,
  };
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    resolver: undefined,
    defaultValues: useMemo(() => {
      return !trainingDetail
        ? defaultValue
        : {
            ...trainingDetail,
            trainingDate: {
              from: trainingDetail?.dateFrom ? new Date(trainingDetail?.dateFrom) : '',
              to: trainingDetail?.dateTo ? new Date(trainingDetail?.dateTo) : '',
            },
          };
    }, [trainingDetail]),
  });

  useEffect(() => {
    if (trainingDetail)
      reset({
        ...trainingDetail,
        trainingDate: {
          from: trainingDetail?.dateFrom ? new Date(trainingDetail?.dateFrom) : '',
          to: trainingDetail?.dateTo ? new Date(trainingDetail?.dateTo) : '',
        },
      });
  }, [trainingDetail]);

  useEffect(() => {
    if (id) {
      dispatch(getTrainingDetail({ id }));
    }
  }, [id]);

  useEffect(() => {
    dispatch(getTrainingStatus({}));
    dispatch(getTrainingCategory({}));
    return () => {
      dispatch(resettrainingDetail({}));
      dispatch(resetfilterTrainee({}));
    };
  }, [dispatch]);

  const onSubmit = (data: any) => {
    const { trainingDate, ...rest } = data;
    const postData = {
      ...rest,
      dateFrom: data?.trainingDate?.from ? +moment(data?.trainingDate?.from) : null,
      dateTo: data?.trainingDate?.to ? +moment(data?.trainingDate?.to) : null,
      userIds: selectedUser ? selectedUser?.map((i) => i?.id) : [],
    };
    const formData = new FormData();
    if (id) {
      formData.append('updateData', JSON.stringify(postData));
      dispatch(
        patchTraining({
          id,
          formData,
          callBack: () => {
            history.replace('/training/course-management');
          },
        }),
      );
      //update result employee
      if (dataResult?.length) {
        dispatch(
          updateResult({
            courseId: Number(id),
            results: dataResult,
          }),
        );
      }
    } else {
      formData.append('createData', JSON.stringify(postData));
      file?.forEach((f: any) => {
        formData.append('files', f.originFileObj);
      });
      dispatch(
        createTraining({
          body: formData,
          callBack: () => {
            setCreated && setCreated(false);
          },
        }),
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ButtonBack
        handleBack={() => {
          id ? history.replace(`/training/course-management`) : setCreated && setCreated(false);
          dispatch(resettrainingDetail({}));
          dispatch(resetfilterTrainee({}));
        }}
      />
      <GeneralInformation control={control} getValues={getValues} />
      <CourseContent control={control} callBack={setFile} getValues={getValues} />
      <AssignTrainee
        control={control}
        getValues={getValues}
        setValue={setValue}
        reset={reset}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        inputData={trainingDetail?.users || null}
        viewMode={Boolean(id)}
        setDataResult={setDataResult}
      />
      <div style={{ display: 'flex', justifyContent: 'end', margin: '16px 0', gap: 16 }}>
        <CustomButton
          aType="outline"
          onClick={() => {
            id ? history.replace('/training/course-management') : setCreated && setCreated(false);
          }}
        >
          {t('cancel')}
        </CustomButton>
        <CustomButton htmlType="submit">{t('save')}</CustomButton>
      </div>
    </form>
  );
};

export default FormCourse;

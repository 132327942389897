import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import dashboardEmployeeApi from 'api/dashboardEmployeeApi';
import { MONTH_FORMAT, MONTH_YEAR_FORMAT } from 'constants/commons';
import { TimesheetAdjustmentReport } from 'types';
import { exportToCSV } from 'utils/common';
import HeadcountGenderChart from './HeadcountGenderChart';
import HeadcountGenderFilters, { HeadcountGenderPayload } from './HeadcountGenderFilters';

const StyledContainer = styled.div`
  padding: 16px 24px;
  border: 0.5px solid var(--cl_gray200);
  border-radius: 12px;
  background-color: var(--cl_white);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledHeaderTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto;
  line-height: 20px;
  color: #121926;
`;

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  flex-wrap: wrap;
  gap: 10px;
`;

export function HeadcountByGender() {
  const { t } = useTranslation(['dashboard']);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const [dataChart, setDataChart] = useState<TimesheetAdjustmentReport>({
    labels: [t('dashboard_employee.male'), t('dashboard_employee.female')],
    datasets: [],
  });

  const [filters, setFilters] = useState<{ monthYear: string; locationId: string }>({
    monthYear: moment(new Date()).format(MONTH_YEAR_FORMAT),
    locationId: '0',
  });

  useEffect(() => {
    async function fetchHeadcountByLine() {
      try {
        setIsLoading(true);
        const response = await dashboardEmployeeApi.getHeadcountGender(filters);

        const datasets = [
          {
            label: '',
            data: [response.data?.maleCount, response.data?.femaleCount],
            fill: true,
            backgroundColor: ['#4472C4', '#F37725'],
            borderColor: 'white',
            barThickness: 20,
            borderRadius: 4,
          },
        ];

        setIsLoading(false);
        setDataChart((prevState) => ({
          ...prevState,
          datasets,
        }));
      } catch (error) {
        setIsLoading(false);
      }
    }

    if (filters?.locationId) {
      fetchHeadcountByLine();
    }
  }, [filters, filters?.locationId]);

  const handleSubmit = ({ monthYear, ...payload }: Partial<HeadcountGenderPayload>) => {
    const formattedYear = moment(monthYear).format(MONTH_YEAR_FORMAT);

    setFilters((prevFilters) => ({
      ...prevFilters,
      ...payload,
      monthYear: formattedYear,
    }));
  };

  const handleExport = async (location: { value: string; label: string }) => {
    try {
      setIsLoadingExport(true);
      const response = await dashboardEmployeeApi.exportHeadcountGender(filters);
      setIsLoadingExport(false);

      const exportFileName = `Headcount_By_Gender_${moment(filters.monthYear).format(
        MONTH_FORMAT,
      )}_${location.label}.xlsx`;
      exportToCSV(response, exportFileName);
    } catch (error) {
      setIsLoadingExport(false);
    }
  };

  return (
    <StyledContainer>
      <StyledWrapper>
        <StyledHeaderTitle>{t('dashboard_employee.headcount_gender')}</StyledHeaderTitle>

        <HeadcountGenderFilters
          onSubmit={handleSubmit}
          loadingExport={isLoadingExport}
          handleExport={handleExport}
          initialValues={{
            monthYear: moment(new Date(filters.monthYear)),
          }}
        />
      </StyledWrapper>

      <HeadcountGenderChart loading={isLoading} dataChart={dataChart} />
    </StyledContainer>
  );
}

import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import {
  getProjectList,
  getProjectDetail,
  getProjectListCurrentUser,
  getOtRequestList,
  getOtRequestForEdit,
  getApproveOt,
  getOvertimeType,
  getAllHistoryOtType,
  getOvertimeAllType,
  getProjectByDate,
  getUserApproveOt,
  getOtRequestHistoryProject,
  getOtRequestHistoryUser,
  getHistoryProjectManageProjectDetail,
  getHistoryProjectManageUserDetail,
  getHistoryPlanProjectDetail,
  getHistoryPlanUserDetail,
} from './overtimeAction';

const initialState: any = {
  loading: false,
  loadingDetail: false,
  error: null,
  listProject: [],
  projectDetail: {},
  projectDetailUser: [],
  loadingProjectDetailUser: false,
  overtimeRequestList: [],
  loadingOvertimeRequestList: false,
  overtimeRequestForEdit: {},
  approveOtList: [],
  loadingApproveOtList: false,
  approveUserOtList: [],
  loadingApproveUserOtList: false,
  dataOvertimeType: [],
  dataOtAllType: [],
  dataHistoryOtType: [],
  dataHistoryRequestProject: [],
  loadingDataHistoryRequestProject: false,
  dataHistoryRequestUser: [],
  loadingDataHistoryRequestUser: false,
  dataHistoryProjectDetail: [],
  loadingDataHistoryProjectDetail: false,
  dataHistoryUserDetail: [],
  loadingDataHistoryUserDetail: false,
  dataHistoryPlanProjectDetail: [],
  loadingDataHistoryPlanProjectDetail: false,
  dataHistoryPlanUserDetail: [],
  loadingDataHistoryPlanUserDetail: false,
  nodes: [
    {
      id: 'edges-1',
      type: 'input',
      data: { label: 'Node 1' },
      position: { x: 150, y: 100 },
    },
    {
      id: 'edges-2',
      type: 'default',
      data: { label: 'Node 2' },
      position: { x: 0, y: 180 },
    },
    {
      id: 'edges-3',
      type: 'output',
      data: { label: 'Node 3' },
      position: { x: 0, y: 260 },
    },
    {
      id: 'edges-4',
      type: 'output',
      data: { label: 'Node 4' },
      position: { x: 100, y: 260 },
    },
  ],
  edges: [
    {
      id: 'edges-e1-2',
      source: 'edges-1',
      target: 'edges-2',
      label: '',
      type: 'buttonedge',
    },
    {
      id: 'edges-e2-3',
      source: 'edges-2',
      target: 'edges-3',
      label: 'transition 2',
      className: 'normal-edge',
      type: 'buttonedge',
    },
    {
      id: 'edges-e2-4',
      source: 'edges-2',
      target: 'edges-4',
      label: 'transition 3',
      className: 'normal-edge',
    },
  ],
};

const overtimeDataSlice = createSlice({
  name: 'overtimeData',
  initialState: initialState,
  reducers: {
    resetStateModal(state, action) {
      state.projectDetailUser = [];
      state.projectDetail = {};
      state.overtimeRequestForEdit = {};
    },
    setErrorMessage(state, action) {
      state.error = action.payload;
    },
    setNodesRedux(state, action) {
      state.nodes = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProjectList.pending, (state, action: any) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(getProjectList.fulfilled, (state, action: any) => {
        state.listProject = action?.payload?.data?.result;
        state.loading = false;
      })
      .addCase(getProjectList.rejected, (state, action: any) => {
        state.error = action.error;
        state.listProject = [];
        state.loading = false;
      })
      .addCase(getProjectDetail.pending, (state, action: any) => {
        state.error = null;
        state.loadingDetail = true;
      })
      .addCase(getProjectDetail.fulfilled, (state, action: any) => {
        state.projectDetail = action?.payload?.data;
        state.loadingDetail = false;
      })
      .addCase(getProjectDetail.rejected, (state, action: any) => {
        state.error = action.error;
        state.projectDetail = {};
        state.loadingDetail = false;
      })
      .addCase(getProjectListCurrentUser.pending, (state, action: any) => {
        state.error = null;
        state.loadingProjectDetailUser = true;
      })
      .addCase(getProjectListCurrentUser.fulfilled, (state, action) => {
        state.projectDetailUser = action.payload?.data?.result;
        state.loadingProjectDetailUser = false;
      })
      .addCase(getProjectListCurrentUser.rejected, (state, action: any) => {
        state.error = action.error;
        state.projectDetailUser = [];
        state.loadingProjectDetailUser = false;
      })
      .addCase(getOtRequestList.pending, (state, action: any) => {
        state.error = null;
        state.loadingOvertimeRequestList = true;
      })
      .addCase(getOtRequestList.fulfilled, (state, action) => {
        state.overtimeRequestList = action.payload?.data;
        state.loadingOvertimeRequestList = false;
      })
      .addCase(getOtRequestList.rejected, (state, action: any) => {
        state.error = action.error;
        state.overtimeRequestList = [];
        state.loadingOvertimeRequestList = false;
      })
      .addCase(getOtRequestForEdit.pending, (state, action: any) => {
        state.error = null;
      })
      .addCase(getOtRequestForEdit.fulfilled, (state, action) => {
        state.overtimeRequestForEdit = action.payload?.data;
      })
      .addCase(getOtRequestForEdit.rejected, (state, action: any) => {
        state.error = action.error;
        state.overtimeRequestForEdit = {};
      })
      .addCase(getApproveOt.pending, (state, action: any) => {
        state.error = null;
        state.loadingApproveOtList = true;
      })
      .addCase(getApproveOt.fulfilled, (state, action) => {
        state.approveOtList = action.payload?.data;
        state.loadingApproveOtList = false;
      })
      .addCase(getApproveOt.rejected, (state, action: any) => {
        state.error = action.error;
        state.approveOtList = [];
        state.loadingApproveOtList = false;
      })
      .addCase(getUserApproveOt.pending, (state, action: any) => {
        state.error = null;
        state.loadingApproveUserOtList = true;
      })
      .addCase(getUserApproveOt.fulfilled, (state, action) => {
        state.approveUserOtList = action.payload?.data;
        state.loadingApproveUserOtList = false;
      })
      .addCase(getUserApproveOt.rejected, (state, action: any) => {
        state.error = action.error;
        state.approveUserOtList = [];
        state.loadingApproveUserOtList = false;
      })
      .addCase(getOvertimeType.pending, (state, action: any) => {
        state.error = null;
        state.loading = true;
        state.dataOvertimeType = [];
      })
      .addCase(getOvertimeType.fulfilled, (state, action) => {
        state.dataOvertimeType = action.payload?.result.filter(
          (item: any) => item.deleteFlag === false,
        );
        state.loading = false;
      })
      .addCase(getOvertimeType.rejected, (state, action: any) => {
        state.error = action.error;
        state.dataOvertimeType = [];
        state.loading = false;
      })
      .addCase(getOvertimeAllType.pending, (state, action: any) => {
        state.error = null;
        state.dataOtAllType = [];
      })
      .addCase(getOvertimeAllType.fulfilled, (state, action) => {
        state.dataOtAllType = action.payload?.result.filter(
          (item: any) => item.deleteFlag === false,
        );
      })
      .addCase(getOvertimeAllType.rejected, (state, action: any) => {
        state.error = action.error;
        state.dataOtAllType = [];
      })
      .addCase(getAllHistoryOtType.pending, (state, action: any) => {
        state.error = null;
        state.dataHistoryOtType = [];
        state.loading = true;
      })
      .addCase(getAllHistoryOtType.fulfilled, (state, action) => {
        state.dataHistoryOtType = action.payload?.result;
        state.loading = false;
      })
      .addCase(getAllHistoryOtType.rejected, (state, action: any) => {
        state.error = action.error;
        state.dataHistoryOtType = [];
        state.loading = false;
      })
      .addCase(getProjectByDate.pending, (state, action: any) => {
        state.error = null;
      })
      .addCase(getProjectByDate.fulfilled, (state, action) => {
        state.projectDetailUser = action.payload?.data?.result;
      })
      .addCase(getProjectByDate.rejected, (state, action: any) => {
        state.error = action.error;
        state.projectDetailUser = [];
      })
      .addCase(getOtRequestHistoryProject.pending, (state, action: any) => {
        state.error = null;
        state.loadingDataHistoryRequestProject = true;
      })
      .addCase(getOtRequestHistoryProject.fulfilled, (state, action) => {
        state.dataHistoryRequestProject = action.payload?.data?.result;
        state.loadingDataHistoryRequestProject = false;
      })
      .addCase(getOtRequestHistoryProject.rejected, (state, action: any) => {
        state.error = action.error;
        state.dataHistoryRequestProject = [];
        state.loadingDataHistoryRequestProject = false;
      })
      .addCase(getOtRequestHistoryUser.pending, (state, action: any) => {
        state.error = null;
        state.loadingDataHistoryRequestUser = true;
      })
      .addCase(getOtRequestHistoryUser.fulfilled, (state, action) => {
        state.dataHistoryRequestUser = action.payload?.data?.result;
        state.loadingDataHistoryRequestUser = false;
      })
      .addCase(getOtRequestHistoryUser.rejected, (state, action: any) => {
        state.error = action.error;
        state.dataHistoryRequestUser = [];
        state.loadingDataHistoryRequestUser = false;
      })
      .addCase(getHistoryProjectManageProjectDetail.pending, (state, action: any) => {
        state.dataHistoryProjectDetail = [];
        state.error = null;
        state.loadingDataHistoryProjectDetail = true;
      })
      .addCase(getHistoryProjectManageProjectDetail.fulfilled, (state, action) => {
        state.dataHistoryProjectDetail = action.payload?.data?.result;
        state.loadingDataHistoryProjectDetail = false;
      })
      .addCase(getHistoryProjectManageProjectDetail.rejected, (state, action: any) => {
        state.error = action.error;
        state.dataHistoryProjectDetail = [];
        state.loadingDataHistoryProjectDetail = false;
      })
      .addCase(getHistoryProjectManageUserDetail.pending, (state, action: any) => {
        state.error = null;
        state.dataHistoryUserDetail = [];
        state.loadingDataHistoryUserDetail = true;
      })
      .addCase(getHistoryProjectManageUserDetail.fulfilled, (state, action) => {
        state.dataHistoryUserDetail = action.payload?.data?.result;
        state.loadingDataHistoryUserDetail = false;
      })
      .addCase(getHistoryProjectManageUserDetail.rejected, (state, action: any) => {
        state.error = action.error;
        state.dataHistoryUserDetail = [];
        state.loadingDataHistoryUserDetail = false;
      })
      .addCase(getHistoryPlanProjectDetail.pending, (state, action: any) => {
        state.error = null;
        state.loadingDataHistoryPlanProjectDetail = true;
      })
      .addCase(getHistoryPlanProjectDetail.fulfilled, (state, action) => {
        state.dataHistoryPlanProjectDetail = action.payload?.data;
        state.loadingDataHistoryPlanProjectDetail = false;
      })
      .addCase(getHistoryPlanProjectDetail.rejected, (state, action: any) => {
        state.error = action.error;
        state.dataHistoryPlanProjectDetail = [];
        state.loadingDataHistoryPlanProjectDetail = false;
      })
      .addCase(getHistoryPlanUserDetail.pending, (state, action: any) => {
        state.error = null;
        state.loadingDataHistoryPlanUserDetail = true;
      })
      .addCase(getHistoryPlanUserDetail.fulfilled, (state, action) => {
        state.dataHistoryPlanUserDetail = action.payload?.data;
        state.loadingDataHistoryPlanUserDetail = false;
      })
      .addCase(getHistoryPlanUserDetail.rejected, (state, action: any) => {
        state.error = action.error;
        state.dataHistoryPlanUserDetail = [];
        state.loadingDataHistoryPlanUserDetail = false;
      });
  },
});

export const selectOvertimeData = (state: RootState) => state.overtime;
export const { reducer, actions } = overtimeDataSlice;

export default reducer;

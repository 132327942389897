import Icon, { CloseCircleTwoTone, EditTwoTone, SaveTwoTone } from '@ant-design/icons';
import { Button, Form, InputNumber, Popconfirm } from 'antd';
import { useAppSelector } from 'app/hooks';
import ITVSearch from 'components/ITVSearch';
import ITVTable from 'components/ITVTable';
import TableResizableTitle from 'components/TableResizableTitle';
import { getAllDeduction, updateDeduction } from 'features/configuration/configurationAction';
import { selectConfiguration } from 'features/configuration/configurationSlice';
import { formatCurrencyUnit } from 'features/insurances/constants/common';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ContainerBgWhite } from 'styles/containerBgWhite';
import { removeAccents } from 'utils/text';
interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: 'number' | 'text';
  record: any;
  index: number;
  children: React.ReactNode;
}

const SetupDeduction = () => {
  const [form] = Form.useForm();
  const [sizePage, setSizePage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const { t } = useTranslation([
    'menu',
    'action',
    'overtime',
    'timesheet',
    'masterConfig',
    'validation',
    'payroll',
    'employee',
  ]);
  const dataStore = useAppSelector(selectConfiguration);
  const dataDeduct = dataStore?.dataDeduct?.result;
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState('');

  useEffect(() => {
    dispatch(getAllDeduction({}));
  }, [dispatch]);

  useEffect(() => {
    if (dataDeduct?.length >= 0) setData(dataDeduct);
  }, [dataDeduct]);

  const COLUMNS: any = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      width: 60,
      minWidth: 60,
      align: 'center',
      render: function (text: any, record: any, index: any) {
        const trueIndex = index + sizePage * (currentPage - 1);
        return <div>{trueIndex + 1}</div>;
      },
    },
    {
      title: t('payroll:name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('employee:allowance_information_fields.money'),
      dataIndex: 'money',
      key: 'money',
      align: 'right',
      width: 200,
      minWidth: 200,
      editable: true,
      onCell: (record: any) => {
        return {
          record,
          inputType: 'number',
          dataIndex: 'money',
          title: 'Money',
          editing: isEditing(record),
        };
      },
      render: function (text: any, record: any, index: any) {
        return formatCurrencyUnit(text, 'VND');
      },
    },
    {
      title: t('action:action'),
      dataIndex: 'action',
      align: 'center',
      key: 'action',
      width: 100,
      minWidth: 100,
      render: function (text: any, record: any, index: any) {
        const editable = isEditing(record);
        return editable ? (
          <div>
            <Popconfirm
              title={t('masterConfig:general.are_you_sure')}
              onConfirm={() => saveEditDeduction(record)}
            >
              <SaveTwoTone style={{ fontSize: 20 }} twoToneColor="#F1592A" />
            </Popconfirm>
            <Icon
              component={() => (
                <CloseCircleTwoTone
                  twoToneColor="#B7B7B7"
                  style={{ marginLeft: 20, fontSize: 20 }}
                />
              )}
              onClick={cancelEdit}
            />
          </div>
        ) : (
          <Button type="link">
            <EditTwoTone
              twoToneColor="#F1592A"
              style={{ fontSize: '20px' }}
              onClick={() => editRow(record)}
            />
          </Button>
        );
      },
    },
  ];

  const isEditing = (record: any) => {
    return record.id === editingKey;
  };

  const editRow = (record: any) => {
    form.setFieldsValue({
      money: record.money,
    });
    setEditingKey(record.id);
  };

  const saveEditDeduction = async (record: any) => {
    try {
      const row = await form.validateFields();
      const data = {
        id: record?.id,
        money: row.money.value,
        name: record?.name,
      };
      dispatch(
        updateDeduction({
          data,
          callBack: () => {
            setEditingKey('');
            dispatch(getAllDeduction({}));
          },
        }),
      );
    } catch (error) {
      setEditingKey('');
    }
  };

  const cancelEdit = () => {
    setEditingKey('');
  };
  const handleSearch = (value: string) => {
    const text = value && removeAccents(value.toLowerCase());
    if (text) {
      const temp = dataDeduct?.filter((item: any) => {
        return item?.name && removeAccents(item?.name?.toLowerCase()).includes(text);
      });
      setData(temp);
    } else {
      setData(dataDeduct);
    }
  };

  const EditableCell: React.FC<EditableCellProps> = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={[dataIndex, 'value']}
            noStyle
            rules={[
              {
                required: true,
                message: t('HRM_VAD_REQUIRED', {
                  field: title,
                }),
              },
            ]}
          >
            <CustomInputNumber
              defaultValue={record?.[dataIndex]}
              style={{ width: '100%' }}
              min={100000}
              max={2000000000}
              formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
              addonAfter="VND"
            />
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  return (
    <Container>
      <ContainerBgWhite style={{ marginTop: '20px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <ITVSearch style={{ marginBottom: 10 }} handleSearch={handleSearch} />
          <div></div>
        </div>
        <Form form={form} component={false}>
          <ITVTable
            components={{
              header: { cell: TableResizableTitle },
              body: { cell: EditableCell },
            }}
            isRowSelect={false}
            columns={COLUMNS}
            data={data}
            width={1080}
            height={550}
            setOffset={setCurrentPage}
            offset={currentPage}
            setLimit={setSizePage}
            limit={sizePage}
          />
        </Form>
      </ContainerBgWhite>
    </Container>
  );
};

export default SetupDeduction;
export const SearchContainer = styled.div`
  width: 200px;
  margin-bottom: 20px;
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: max-content;
`;
export const ContainerLeft = styled.div`
  display: flex;
`;

export const ContainerRight = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;
export const HeaderRemove = styled(Button)`
  font-size: var(--txt_font_size_body);
  border-radius: var(--bd_radius_default);
  img {
    padding-bottom: 6px;
  }
`;
export const CustomInputNumber = styled(InputNumber)`
  .ant-input-number-input {
    text-align: right;
  }
  .ant-input-number-handler-wrap {
    padding-right: 5px;
  }
  .ant-input-number-handler-wrap {
    display: none;
  }
`;

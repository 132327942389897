import React from 'react';
import VectorIcon from 'assets/icons/ic_check_blue.svg';
import { ContainerStyled, LabelStyled, IconStyled } from './styled';

interface Props {
  label: string;
  icon?: string;
}

function AuthLabel(props: Props) {
  const { label, icon = VectorIcon } = props;
  return (
    <ContainerStyled>
      <IconStyled src={icon} alt="vector icon" />
      <LabelStyled>{label}</LabelStyled>
    </ContainerStyled>
  );
}

export default AuthLabel;

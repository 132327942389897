import { Form, Input, Popconfirm, Radio, Row, Select } from 'antd';
import Avatar from 'components/Avatar';
import { getEmployeeListApproved } from 'features/employee/employeeAction';
import { selectEmployee } from 'features/employee/employeeSlice';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import CustomButton from 'styles/buttonStyled';
import { removeAccents } from 'utils/text';
import TypeUtils from 'utils/typeUtils';

enum MODE {
  VIEW = 'VIEW',
  ADD = 'ADD',
  UPDATE = 'UPDATE',
}

const ModalAddGroupEmail = ({ record, onSave, mode }: any) => {
  const employee = useSelector(selectEmployee);
  const employeeListApproved: any = employee?.employeeListApproved;

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { t } = useTranslation(['validation', 'masterConfig', 'insurance', 'onboard', 'auth']);

  const [listEmployee, setListEmployee]: any = useState([]);
  const [listErr, setListErr]: any = useState([]);

  useEffect(() => {
    dispatch(getEmployeeListApproved());
  }, [dispatch]);

  useEffect(() => {
    setListEmployee(employeeListApproved);
  }, [employeeListApproved]);

  useEffect(() => {
    if (record) {
      form.setFieldsValue({
        groupNameEmail: record.groupName,
        emailValue: record.listEmail,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record]);

  const removeDuplicates = (arr: Array<string>) => {
    let s = new Set(arr);
    let it = s.values();
    return Array.from(it);
  };

  const onFinish = () => {
    setListErr([]);
    // eslint-disable-next-line no-useless-escape
    let pattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    form.validateFields().then((values) => {
      if (mode === MODE.ADD) {
        if (values.emailValue.every((value: any) => pattern.test(value))) {
          setListErr([]);
          let temp = values.emailValue.map((item: string, index: number) => {
            let itemExist = listEmployee.filter((e: any) => e.email === item);
            return itemExist.length ? itemExist[0].id : item;
          });
          onSave({
            groupName: values.groupNameEmail,
            listEmail: temp.toString(),
          });
        } else {
          values.emailValue.forEach((value: any) => {
            if (!pattern.test(value)) {
              setListErr((current: any) => [...current, `${value} ${t('HRM_VAD_VALID')}`]);
            }
          });
        }
      }
      if (mode === MODE.UPDATE) {
        if (TypeUtils.isObject(values.emailValue[0])) {
          if (values.emailValue.every((value: any) => pattern.test(value.value))) {
            let list = values.emailValue.map((item: any, index: number) => {
              let itemExist = listEmployee.filter((e: any) => e.email === item.value);
              return itemExist.length ? itemExist[0].id : item.value;
            });
            onSave({
              groupName: values.groupNameEmail,
              listEmail: list.toString(),
            });
          } else {
            values.emailValue.forEach((value: any) => {
              if (!pattern.test(value.value)) {
                setListErr((current: any) => [...current, `${value.value} ${t('HRM_VAD_VALID')}`]);
              }
            });
          }
        } else {
          if (values.emailValue.every((value: any) => pattern.test(value))) {
            let list = values.emailValue.map((item: string, index: number) => {
              let itemExist = listEmployee.filter((e: any) => e.email === item);
              return itemExist.length ? itemExist[0].id : item;
            });
            onSave({
              groupName: values.groupNameEmail,
              listEmail: list.toString(),
            });
          } else {
            values.emailValue.forEach((value: any) => {
              if (!pattern.test(value)) {
                setListErr((current: any) => [...current, `${value} ${t('HRM_VAD_VALID')}`]);
              }
            });
          }
        }
      }
    });
  };

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name={'groupNameEmail'}
        label={<div>{t('onboard:email_name_group')}</div>}
        rules={[
          {
            required: true,
            message: t('HRM_VAD_REQUIRED', { field: t('onboard:email_name_group') }),
          },
          {
            whitespace: true,
            message: t('HRM_VAD_BLANK_CHARACTERS', { field: t('onboard:email_name_group') }),
          },
        ]}
      >
        <Input placeholder={t('onboard:input_email_name_group')} maxLength={255} />
      </Form.Item>

      <Form.Item
        label={<div>Email</div>}
        name={'emailValue'}
        rules={[
          {
            required: true,
            message: t('HRM_VAD_REQUIRED', { field: 'Email' }),
          },
        ]}
      >
        <Select
          style={{ width: '100%' }}
          mode="tags"
          allowClear
          placeholder={t('onboard:please_select')}
          showSearch
          filterOption={(input: any, option: any) => {
            return (
              removeAccents(option.name?.toLowerCase())?.indexOf(
                removeAccents(input.toLowerCase()),
              ) >= 0 ||
              removeAccents(option.key?.toLowerCase())?.indexOf(
                removeAccents(input.toLowerCase()),
              ) >= 0
            );
          }}
          optionLabelProp="name"
        >
          {listEmployee.map((item: any) => {
            return (
              <Select.Option key={item.email} value={item.email} name={item.fullName}>
                <Row>
                  <div style={{ marginTop: '5px', marginRight: '5px' }}>
                    <Avatar name={item.fullName} />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    <div
                      data-id="title"
                      style={{
                        fontWeight: 'bold',
                        fontSize: 14,
                      }}
                    >
                      {item.fullName}
                    </div>
                    <div
                      data-id="sub-title"
                      style={{
                        color: '#9CA3AF',
                        fontSize: 12,
                      }}
                    >
                      {item.email}
                    </div>
                  </div>
                </Row>
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>

      {removeDuplicates(listErr).map((item: string) => {
        return <div style={{ color: 'red', fontStyle: 'italic' }}>{item}</div>;
      })}

      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: '2%' }}>
        <Popconfirm
          title={`${t('insurance:Are_you_sure_confirm_this_request')}`}
          onConfirm={() => {
            onFinish();
          }}
          okText={`${t('insurance:Yes')}`}
          cancelText={`${t('insurance:no')}`}
        >
          <CustomButton>{t('masterConfig:action.save')}</CustomButton>
        </Popconfirm>
      </div>
    </Form>
  );
};

export default ModalAddGroupEmail;

export const InfoRadio = styled(Radio)`
  .ant-radio-disabled .ant-radio-inner {
    border-color: #f1592a;
  }
  .ant-radio-inner::after {
    background: #f1592a;
    border-color: #f1592a;
  }
`;

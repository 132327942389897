import { Collapse } from 'antd';
import ITV_Policy_Device_Management from 'assets/images/ITV_Policy_Device_Management.png';
import ITV_Policy_Network_Usage from 'assets/images/ITV_Policy_Network_Usage.png';
import ITV_Policy_virus_prevention from 'assets/images/ITV_Policy_virus_prevention.png';
import Application from 'assets/images/application.png';
import FileData from 'assets/images/fileData.png';
import logoInnotech from 'assets/images/logo_innotech.png';
import UsernamePassword from 'assets/images/username_password.png';
import WebBrowsing from 'assets/images/web_browsing.png';
import { CollapseTitle, CustomPanel, StyledButton } from 'features/xclaim';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

const PageITPolicy = () => {
  const [showsScrolBtn, setShowScrolBtn] = useState(false);

  useEffect(() => {
    const handleButtonVisibility = () => {
      window.pageYOffset > 300 ? setShowScrolBtn(true) : setShowScrolBtn(false);
    };

    window.addEventListener('scroll', handleButtonVisibility);
    return () => {
      window.addEventListener('scroll', handleButtonVisibility);
    };
  }, []);

  // IT POLICY
  const listWebBrowsing = [
    {
      textEng:
        'Internet services are authorized to designated employees by their manager to enhance their job responsibility. The Internet is an excellent tool but also creates security implications that the company must guard against. For that reason, employees are granted access only as a means of providing support in fulfilling their job responsibility',
      text: ' / Nhân viên chỉ được cấp quyền truy cập như một phương tiện hỗ trợ trong việc hoàn thành trách nhiệm công việc. Quyền truy cập được chỉ định từ Cấp quản lý để nâng cao trách nhiệm trong công việc được giao.​​',
    },
    {
      textEng:
        'It is strictly forbidden to access websites including pornographic websites, betting websites, unknown information web site  in the company',
      text: ' / Nghiêm cấm truy cập các trang web bao gồm: cá độ, khiêu dâm, trang web không rõ thông tin.',
    },
    {
      textEng: 'Large file sizes need to be reported to the IT(~1GB) to support',
      text: ' / Những tệp tin dung lượng lớn cần thông báo IT để hỗ trợ tải về trong trường hợp cần thiết.',
    },
    {
      textEng:
        'Take must care when web browsing on the types of websites and download that you make',
      text: ' / Cẩn thận khi tải về những file không rõ nguồn gốc.​​',
    },
    {
      textEng: 'You should not use tools to hide your webs browsing',
      text: ' / Không sử dụng công cụ ẩn truy cập.​​',
    },
  ];
  const listUsernamePassword = [
    {
      textEng:
        'It is the responsibility of each individual to protect and to keep private any and all passwords issued to him/her by the company',
      text: ' / Nhân viên có trách nhiệm bảo mật thông tin về toàn bộ mật khẩu của các tài khoản của công ty khi được cung cấp. ​',
    },
    {
      textEng:
        'You should ask for permission before creating any accounts for developing/testing purpose.',
      text: ' / Xin phê duyệt của cấp quản lý khi cần tạo bất kỳ tài khoản làm việc (develop/testing).',
    },
    {
      textEng: 'You must not share VPN account to other people.',
      text: ' / Không chia sẻ tài khoản VPN cho bất kì ai.',
    },
    {
      textEng:
        'The IT Department will establish guidelines for issuing new passwords, deleting passwords as required, and allowing employees to change their passwords',
      text: ' / Mọi thay đổi về mật khẩu sẽ được IT thực hiện khi có yêu cầu và được phê duyệt.',
    },
    {
      textEng:
        'The IT Department will delete all passwords of exiting employees upon notification from Human Resources.',
      text: ' / Khi nhận được thông tin xác nhận từ dự án về tài khoản làm việc của nhân viên nghỉ việc, IT sẽ xóa toàn bộ tài khoản mật khẩu liên quan.​​',
    },
    {
      textEng: 'User is responsible for all actions and functions performed by his/her account.',
      text: ' / Nhân viên sẽ chịu trách nhiệm cho mọi hoạt động và chức năng đã thực hiện bằng tài khoản của chính mình.​',
    },
  ];
  const listApplication = [
    {
      textEng:
        'Software purchased by the company or residing on company owned computers is to be used only within the terms of the license agreement for that software title.',
      text: ' / Phần mềm do công ty mua hoặc được cài đặt trên máy tính thuộc quyền sở hữu của công ty và chỉ được sử dụng trong các điều kiện được cấp phép.​​',
    },
    {
      textEng:
        'CEO and authorized members will be the sole governing body for defining appropriate software titles acceptable for use in the company.',
      text: ' / Giám đốc hoặc người được ủy quyền sẽ chỉ định các phần mểm phù hợp được sử dụng trong công ty.​​',
    },
    {
      textEng:
        'Under no circumstances will third party software applications be loaded onto company owned computer systems without the knowledge of and approval of the IT',
      text: ' / Trong mọi trường hợp, nhân viên không được phép cài đặt các ứng dụng/phần mềm bên thứ 3 trên các thiết bị công ty cung cấp khi chưa có sự phê duyệt từ cấp thẩm quyền.​​',
    },
    {
      textEng: 'Games should not be installed at all.',
      text: ' / Nhân viên không được cài đặt game trên tất cả các thiết bị của công ty.​​',
    },
  ];
  const listFileData = [
    {
      textEng:
        'You must not upload, or build, or run any project package to free server for testing purpose',
      text: ' / Nhân viên không được đăng tải, phát triển hoặc chạy bất kỳ một dự án nào của công ty trên máy chủ  miễn phí với bất kì mục đích kiểm thử.​​',
    },
    {
      textEng: 'You must not upload any package, or files to public cloud storage.',
      text: ' / Nhân viên không được đăng tải bất kỳ tài liệu, tệp lưu trữ lên bộ nhớ đám mây nào ngoại trừ Onedrive công ty.​​',
    },
    {
      textEng:
        'You must not store any package, or files in the personal machine, or personal storage, or personal account.',
      text: ' / Nhân viên không được lưu trữ tài liệu dự án, tệp thông tin trong máy cá nhân hay dữ liệu đám mây cá nhân.​​',
    },
    {
      textEng: 'You must not share or upload any customer document to public cloud storage.',
      text: ' / Nhân viên không được chia sẽ bất kỳ tài liệu, dữ liệu khách hàng trên bộ nhớ đám mây công cộng.​​',
    },
    {
      textEng: 'You may not use a personal laptop to store company files.',
      text: ' / Nhân viên Không sử dụng máy tính cá nhân để lưu giữ dữ liệu của công ty.',
    },
    {
      textEng: 'You may not use unencrypted USB to copy/ Store company data.',
      text: ' / Nhân viên không sử dụng usb để sao chép lưu trữ dữ liệu của công ty.',
    },
  ];
  const listDeviceManagement = [
    {
      textEng:
        'Commitment to preserving and using equipment issued by the company. Responsible for compensation calculated according to the cost of repair or purchase of a new one if loss or damage occurs due to the employee.',
      text: ' / Cam kết bảo quản và sử dụng các thiết bị do công ty cấp. Chịu trách nhiệm bồi thường tính theo chi phí sửa chữa hoặc mua mới nếu xảy ra mất mát, hư hỏng do người lao động.',
    },
    {
      textEng:
        'In case of device loss, you must immediately notify IT to promptly handle login blocking. If you do not notify, you will be fully responsible for all related losses.',
      text: ' / Trường hợp mất máy phải thông báo ngay cho IT để xử lý kịp thời việc chặn đăng nhập. Nếu không thông báo, bạn sẽ hoàn toàn chịu trách nhiệm về mọi tổn thất liên quan.',
    },
  ];
  const listVirusPrevention = [
    {
      textEng:
        'It is strictly forbidden to use viruses or other disruptive and destructive programs in the company’s environment(possibly prosecuted).',
      text: ' / Nghiêm cấm sử dụng virus hoặc các chương trình gây rối, phá hoại khác trong môi trường công ty (có thể bị khởi tố).',
    },
    {
      textEng: 'Avoid accessing or downloading from unofficial websites.',
      text: ' / Nhân viên không được truy cập tải xuống những trang web không rõ nguồn gốc có mã độc.',
    },
  ];
  const listNetWorkUsage = [
    {
      textEng:
        'Define proper use of the company’s network resources. Use of the company network for non-work purposes is not allowed.',
      text: ' / Xác định việc sử dụng hợp lý tài nguyên mạng của công ty. Không được phép sử dụng mạng công ty cho mục đích ngoài công việc.',
    },
    {
      textEng: 'Specify guidelines for remote access and use of virtual private networks (VPNs).',
      text: ' / Chỉ định các nguyên tắc truy cập từ xa và sử dụng mạng riêng ảo (VPNs)​.',
    },
  ];

  return (
    <section>
      {showsScrolBtn && (
        <div
          id="scrolToTop"
          onClick={() => {
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth',
            });
          }}
          style={{
            position: 'fixed',
            bottom: '60px',
            right: '60px',
            color: 'gray',
            textAlign: 'center',
            lineHeight: 0,
            textDecoration: 'none',
            borderRadius: '50%',
            backgroundColor: 'white',
            zIndex: '100',
          }}
        >
          <StyledButton />
        </div>
      )}

      {/* POLICY IT */}
      <Container>
        <ContainerImgITPolicy>
          <img src={logoInnotech} alt="background logoInnotech" />
        </ContainerImgITPolicy>
        <ContainerTitleInnotech>
          <span>INNO</span>TECH VIETNAM CORPORATION​
        </ContainerTitleInnotech>
        <CrossbarITPolicy>
          <span />
        </CrossbarITPolicy>
        <ContainerTitleITPolicy>
          IT <span>POLICY SECURITY</span>
        </ContainerTitleITPolicy>
      </Container>
      <Collapse
        accordion
        collapsible="header"
        style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}
      >
        <CustomPanel
          header={
            <div>
              <CollapseTitle>Web browsing</CollapseTitle>
            </div>
          }
          key="1"
        >
          <SectionITPolicy>
            <BoxLeftITPolicy>
              <img src={WebBrowsing} alt="background container" />
            </BoxLeftITPolicy>
            <BoxRightITPolicy>
              <ul className="ul_list">
                {listWebBrowsing.map((item) => {
                  return (
                    <li className="li_list">
                      <strong>{item?.textEng}</strong>
                      {item.text}
                    </li>
                  );
                })}
              </ul>
            </BoxRightITPolicy>
          </SectionITPolicy>
        </CustomPanel>
        <CustomPanel
          header={
            <div>
              <CollapseTitle>Username password</CollapseTitle>
            </div>
          }
          key="2"
        >
          <SectionITPolicy>
            <BoxLeftITPolicy>
              <img src={UsernamePassword} alt="background container" />
            </BoxLeftITPolicy>
            <BoxRightITPolicy>
              <ul className="ul_list">
                {listUsernamePassword.map((item) => {
                  return (
                    <li className="li_list">
                      <strong>{item?.textEng}</strong>
                      {item.text}
                    </li>
                  );
                })}
              </ul>
            </BoxRightITPolicy>
          </SectionITPolicy>
        </CustomPanel>
        <CustomPanel
          header={
            <div>
              <CollapseTitle>Application</CollapseTitle>
            </div>
          }
          key="3"
        >
          <SectionITPolicy>
            <BoxLeftITPolicy>
              <img src={Application} alt="background container" />
            </BoxLeftITPolicy>
            <BoxRightITPolicy>
              <ul className="ul_list">
                {listApplication.map((item) => {
                  return (
                    <li className="li_list">
                      <strong>{item?.textEng}</strong>
                      {item.text}
                    </li>
                  );
                })}
              </ul>
            </BoxRightITPolicy>
          </SectionITPolicy>
        </CustomPanel>
        <CustomPanel
          header={
            <div>
              <CollapseTitle>File & data​</CollapseTitle>
            </div>
          }
          key="4"
        >
          <SectionITPolicy>
            <BoxLeftITPolicy>
              <img src={FileData} alt="background container" />
            </BoxLeftITPolicy>
            <BoxRightITPolicy>
              <ul className="ul_list">
                {listFileData.map((item) => {
                  return (
                    <li className="li_list">
                      <strong>{item?.textEng}</strong>
                      {item.text}
                    </li>
                  );
                })}
              </ul>
            </BoxRightITPolicy>
          </SectionITPolicy>
        </CustomPanel>
        <CustomPanel
          header={
            <div>
              <CollapseTitle>Virus Prevention</CollapseTitle>
            </div>
          }
          key="5"
        >
          <SectionITPolicy>
            <BoxLeftITPolicy>
              <img src={ITV_Policy_virus_prevention} alt="background container" />
            </BoxLeftITPolicy>
            <BoxRightITPolicy>
              <ul className="ul_list">
                {listVirusPrevention.map((item) => {
                  return (
                    <li className="li_list">
                      <strong>{item?.textEng}</strong>
                      {item.text}
                    </li>
                  );
                })}
              </ul>
            </BoxRightITPolicy>
          </SectionITPolicy>
        </CustomPanel>
        <CustomPanel
          header={
            <div>
              <CollapseTitle>Network Usage</CollapseTitle>
            </div>
          }
          key="6"
        >
          <SectionITPolicy>
            <BoxLeftITPolicy>
              <img src={ITV_Policy_Network_Usage} alt="background container" />
            </BoxLeftITPolicy>
            <BoxRightITPolicy>
              <ul className="ul_list">
                {listNetWorkUsage.map((item) => {
                  return (
                    <li className="li_list">
                      <strong>{item?.textEng}</strong>
                      {item.text}
                    </li>
                  );
                })}
              </ul>
            </BoxRightITPolicy>
          </SectionITPolicy>
        </CustomPanel>
        <CustomPanel
          header={
            <div>
              <CollapseTitle>Device Management​</CollapseTitle>
            </div>
          }
          key="7"
        >
          <SectionITPolicy>
            <BoxLeftITPolicy>
              <img src={ITV_Policy_Device_Management} alt="background container" />
            </BoxLeftITPolicy>
            <BoxRightITPolicy>
              <ul className="ul_list">
                {listDeviceManagement.map((item) => {
                  return (
                    <li className="li_list">
                      <strong>{item?.textEng}</strong>
                      {item.text}
                    </li>
                  );
                })}
                <li className="li_list" style={{ color: 'red' }}>
                  <i>
                    <strong>Contact points:</strong>
                    <ul style={{ paddingLeft: '30px' }}>
                      <li style={{ listStyleType: 'disc' }}>Phúc Vinh – IT: 0899497760​</li>
                      <li style={{ listStyleType: 'disc' }}>Hồng Yến - Admin: 0948920137​​</li>
                    </ul>
                  </i>
                </li>
              </ul>
            </BoxRightITPolicy>
          </SectionITPolicy>
        </CustomPanel>
      </Collapse>
    </section>
  );
};

export default PageITPolicy;
export const Container = styled.div`
  margin: 15px 0;
  width: 100%;
  height: 130px;
  position: relative;
  p {
    margin: 0;
  }
`;
export const ContainerImgITPolicy = styled.div`
  display: flex;
  justify-content: center;
  img {
    width: 150px;
    height: 100%;
  }
`;
export const ContainerTitleInnotech = styled.div`
  font-size: 17px;
  font-weight: bold;
  text-align: center;
  margin-top: 2px;
  span {
    color: #fe753e;
  }
`;
export const CrossbarITPolicy = styled.div`
  display: flex;
  justify-content: center;
  span {
    display: inline-block;
    width: 10%;
    height: 4px;
    background: #fe753e;
    margin: 5px 0px;
  }
`;
export const ContainerTitleITPolicy = styled.div`
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  margin-top: 5px;
  span {
    color: #fe753e;
  }
`;
export const SectionITPolicy = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
export const BoxLeftITPolicy = styled.div`
  width: 40%;
  height: 40%;
  margin-right: 30px;
  img {
    width: 100%;
    height: 400px;
  }
`;
export const BoxRightITPolicy = styled.div`
  width: 60%;
  .ul_list {
    margin: 0;
    .li_list {
      list-style-type: disclosure-closed;
      color: black;
      font-size: 16px;
      margin-top: 8px;
    }
  }
`;
export const ContainerFooterITPolicy = styled.div`
  width: 100%;
  background: #fe753e;
  height: 50px;
  text-align: center;
  span {
    color: #fff;
  }
`;

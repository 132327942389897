import React, { useEffect, useState } from 'react';
import { Card, Col, DatePicker, Input, Row, Select } from 'antd';
import { useController } from 'react-hook-form';
import styled from 'styled-components';
import TextArea from 'antd/es/input/TextArea';
import {
  DateRangePickerField,
  InputField,
  SelectField,
  TextAreaField,
} from 'components/FormFields';
import { useAppSelector } from 'app/hooks';
import { selectTrainingData } from 'features/training/trainingSlice';
import { useParams } from 'react-router-dom';
import { YYYY_MM_DD } from 'constants/commons';
import { useTranslation } from 'react-i18next';

const StyledContainer = styled.div`
  .row {
    margin-bottom: 16px;
  }

  .label {
    text-align: left;
    padding-right: 8px;
    flex: 1 0 30px;
  }

  .input,
  .select,
  .textarea,
  .datepicker {
    flex: 2;
  }

  .select .ant-select {
    width: 100%;
  }

  .textarea .ant-input {
    width: 100%;
    min-height: 120px; /* Đặt chiều cao tối thiểu cho TextArea */
  }

  .datepicker .ant-picker {
    width: 100%;
  }

  .status .ant-select {
    width: 100%;
    min-height: 100px; /* Đặt chiều cao tối thiểu cho Select */
    display: flex;
    align-items: center; /* Căn giữa nội dung */
  }
`;

const GeneralInformation = ({ control, getValues }) => {
  const { id } = useParams();
  const trainingCategory = useAppSelector(selectTrainingData)?.trainingCategory;
  const trainingStatus = useAppSelector(selectTrainingData)?.trainingStatus;
  const [category, setCategory] = useState([]);
  const [status, setStatus] = useState([]);
  const { t } = useTranslation(['trainningManagement']);

  useEffect(() => {
    if (trainingCategory) {
      const arr = trainingCategory?.map((item: any) => ({
        value: item?.id,
        label: item?.categoryName,
      }));
      setCategory(arr);
    }
  }, [trainingCategory]);

  useEffect(() => {
    if (trainingStatus) {
      const arr = trainingStatus?.map((item: any) => ({
        value: item?.statusId,
        label: item?.statusText,
      }));
      setStatus(arr);
    }
  }, [trainingStatus]);

  return (
    <StyledContainer>
      <Card
        title={id ? getValues('courseName') : t('course_management.create_a_new_course')}
        bordered={false}
        headStyle={{
          fontSize: 24,
          fontWeight: 500,
        }}
      >
        <Row gutter={[100, 16]}>
          <Col span={12}>
            <Row className="row" align="middle">
              <Col className="label">{t('course_management.course_name')}</Col>
              <Col className="input">
                <InputField control={control} name="courseName" />
              </Col>
            </Row>
            <Row className="row" align="middle">
              <Col className="label">{t('course_management.course_category')}</Col>
              <Col className="select">
                <SelectField control={control} name="categoryId" options={category} />
              </Col>
            </Row>
            <Row className="row" align="middle">
              <Col className="label">{t('course_management.description')}</Col>
              <Col className="textarea">
                <TextAreaField
                  control={control}
                  name="description"
                  autoSize={{ minRows: 4, maxRows: 6 }}
                />
              </Col>
            </Row>
            <Row className="row" align="middle">
              <Col className="label">{t('course_management.budget_amount')}</Col>
              <Col className="input">
                <InputField type="number" addonAfter="VND" control={control} name="budget" />
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row className="row" align="middle">
              <Col className="label">{t('course_management.trainer')}</Col>
              <Col className="input">
                <InputField control={control} name="trainerName" />
              </Col>
            </Row>
            <Row className="row" align="middle">
              <Col className="label">{t('course_management.training_date')}</Col>
              <Col className="datepicker" style={{ width: '100%' }}>
                <DateRangePickerField
                  control={control}
                  name="trainingDate"
                  format={YYYY_MM_DD}
                  allowClear
                />
              </Col>
            </Row>
            <Row className="row" align="middle">
              <Col className="label">{t('course_management.status')}</Col>
              <Col className="status" style={{ flex: 2 }}>
                <SelectField control={control} name="statusId" options={status} />
              </Col>
            </Row>
            <Row className="row" align="middle">
              <Col className="label">{t('course_management.actual_amount')}</Col>
              <Col className="input">
                <InputField type="number" addonAfter="VND" control={control} name="actualAmount" />
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </StyledContainer>
  );
};

export default GeneralInformation;

import { Modal } from 'antd';
import React from 'react';
import styled from 'styled-components';

interface ModalConfirmProps {
  isModalVisible: boolean;
  content?: React.ReactNode;
  heading?: string;
  icon?: React.ReactNode;
  onCancel?: () => void;
  onOk?: (...args: any) => void;
  styleContent?: React.CSSProperties;
  [prop: string]: any;
}
function ModalConfirm(props: ModalConfirmProps) {
  const { isModalVisible, heading, content, icon, onCancel, onOk, styleContent, ...prop } = props;

  return (
    <>
      <CustomModal visible={isModalVisible} onCancel={onCancel} onOk={onOk} {...prop}>
        <Heading>
          {icon}
          <Title>{heading}</Title>
        </Heading>
        <Content style={styleContent}>{content}</Content>
      </CustomModal>
    </>
  );
}

export default ModalConfirm;

const CustomModal = styled(Modal)`
  .ant-modal-body {
    padding: 32px 32px 0px;
  }
  .ant-modal-footer {
    border-top: unset;
    padding: 24px 32px;
  }
`;

const Heading = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  margin-bottom: 8px;
`;

const Title = styled.div`
  font-size: 16px;
  overflow: hidden;
  font-weight: 500;
`;
const Content = styled.div`
  width: 80%;
  margin-left: 30px;
`;

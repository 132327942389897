import styled from 'styled-components';
import { Button } from 'antd';

export const EmployeeHeaderContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-left: 10px;
`;

export const EmployeeHeaderContainerLeft = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: column;
`;

export const EmployeeHeaderTitle = styled.div`
  font-size: var(--txt_font_size_h1);
  font-weight: var(--txt_font_weight_medium);
  color: black;
`;

export const EmployeeHeaderDetail = styled.div`
  font-size: var(--txt_font_size_caption);
  font-weight: var(--txt_font_weight_regular);
`;

export const EmployeeHeaderContainerRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const EmployeeHeaderButton = styled(Button)`
  font-size: var(--txt_font_size_body);
  background-color: var(--cl_primary500);
  color: var(--cl_white);
  border-radius: var(--bd_radius_default);
`;

import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import { DatePickerField, DateRangePickerField, SelectField } from 'components/FormFields';
import { DiligencePayload } from 'types';
import { DATE_FORMAT } from 'constants/commons';
import moment from 'moment';

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const StyledWrapperDateRange = styled.div`
  width: 226px;
`;

interface DiligenceFiltersProps {
  loading?: boolean;
  initialValues?: any;
  onSubmit?: (payload: any) => void;
  location?: any;
  department?: any;
}

export default function HeadcountSummaryFilter({
  initialValues,
  onSubmit,
  location,
  department,
}: DiligenceFiltersProps) {
  const { t } = useTranslation(['dashboard']);

  const { control, handleSubmit } = useForm<any>({
    defaultValues: initialValues,
  });

  const viewByLocation =
    location &&
    location?.map((item) => ({
      label: item?.name,
      value: item?.id,
    }));

  const viewByDepartment =
    department &&
    department?.map((item) => ({
      label: item?.name,
      value: item?.id,
    }));

  const handleFiltersSubmit = (formValues: any) => {
    if (formValues.monthYear) {
      formValues.monthYear = moment(formValues.monthYear).format('YYYY-MM'); // Format monthYear
    }
    onSubmit?.(formValues);
  };

  const handleDateRangeChange = () => {
    handleSubmit(handleFiltersSubmit)();
  };

  return (
    <StyledForm onSubmit={handleSubmit(handleFiltersSubmit)}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <p style={{ padding: 0, margin: 0, marginRight: 10, fontWeight: 600 }}>Location</p>
        <SelectField
          style={{
            width: 130,
            marginLeft: 22,
          }}
          name="locationId"
          control={control}
          options={viewByLocation || []}
          showSearch
          allowClear={false}
          onChange={handleSubmit(handleFiltersSubmit)}
        />
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <p style={{ padding: 0, margin: 0, marginRight: 10, fontWeight: 600 }}>Month year</p>
        <DatePickerField
          control={control}
          style={{
            width: 130,
          }}
          name="monthYear"
          picker="month"
          allowClear={false}
          onChange={handleSubmit(handleFiltersSubmit)}
        />
        {/* <SelectField
          style={{
            width: 130,
          }}
          name="viewByDepartment"
          control={control}
          options={viewByDepartment || []}
          showSearch
          allowClear={false}
          onChange={handleSubmit(handleFiltersSubmit)}
        /> */}
      </div>
    </StyledForm>
  );
}

import { ArrowRightIcon } from 'assets/icons';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { getErrorMessage } from 'utils/getErrorMessage';

import dashboardEmployeeApi from 'api/dashboardEmployeeApi';
import IconExport from 'assets/icons/ic_export_gray.svg';
import { CustomIcon } from 'components/ITVSearch';
import { notificationToast } from 'components/notificationToast';
import { Notification } from 'constants/notification';
import { ListingItem } from 'features/dashboard/components/Listing/ListingItem';
import { ListingModal } from 'features/dashboard/components/Listing/ListingModal';
import { HeaderRemove } from 'features/masterData/Department/styled';
import { exportToCSV } from 'utils/common';

const StyledContainer = styled.div`
  padding: 16px 24px;
  background-color: var(--cl_white);
  border-radius: 12px;
  border: 0.5px solid var(--cl_gray200);
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow-y: auto;
  min-height: 250px;
`;

const StyledHeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 10px;
`;

const StyledHeadingLeft = styled.div`
  display: flex;
  gap: 8px;
`;

const StyledTitle = styled.div`
  color: var(--cl_neutral900);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
`;

const StyledHeadingRight = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

const StyledSubTitle = styled.div`
  color: var(--cl_neutral600);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
`;

const StyledBadge = styled.div`
  padding: 1px 6px;
  border-radius: 999px;
  background-color: var(--cl_secondary500);
  color: var(--cl_white);
  font-family: Roboto;
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow-y: auto;
`;

export interface DataList {
  imageUrl?: string;
  id: string;
  title: string;
  tag: string;
}

export function UpComingOnboardEmployees() {
  const { t } = useTranslation(['dashboard', 'action']);

  const IconExportComponents = (
    <CustomIcon type={'export'} component={() => <img src={IconExport} alt="" />} />
  );

  const [listUpComingOnboard, setListUpComingOnboard] = useState<DataList[]>([]);
  const [isOpenModalViewAll, setIsOpenModalViewAll] = useState(false);
  const [isLoadingExport, setIsLoadingExport] = useState(false);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const response = dashboardEmployeeApi.getUpComingOnboardEmployees();
        const results = await Promise.allSettled([response]);
        const list = results[0].status === 'fulfilled' ? results[0].value?.data : [];
        const customList = list.map(
          (x: { date_time: string; employee_id: string; full_name: string }) => ({
            id: x.employee_id,
            imageUrl: x.full_name,
            title: x.full_name,
            tag: moment(x.date_time).format('DD MMM'),
          }),
        );

        setListUpComingOnboard(customList);
      } catch (error) {
        const message = getErrorMessage(error);
        notificationToast(Notification.Type.Error, message, Notification.Duration._3s);
      }
    };

    fetchDashboardData();
  }, []);

  const onViewAllClick = () => {
    setIsOpenModalViewAll(true);
  };
  const onCancelModalViewAll = () => {
    setIsOpenModalViewAll(false);
  };

  const handleExport = async () => {
    try {
      setIsLoadingExport(true);
      const response = await dashboardEmployeeApi.exportUpComingOnboardEmployees();
      setIsLoadingExport(false);

      const exportFileName = `UpComming_Onboard_Employees.xlsx`;
      exportToCSV(response, exportFileName);
    } catch (error) {
      setIsLoadingExport(false);
    }
  };

  return (
    <StyledContainer>
      <StyledHeading>
        <StyledHeadingLeft>
          <StyledTitle> {t('dashboard_employee.upcoming_onboard_epls')}</StyledTitle>
        </StyledHeadingLeft>

        {listUpComingOnboard.length > 0 && (
          <HeaderRemove
            onClick={handleExport}
            icon={IconExportComponents}
            loading={isLoadingExport}
            style={{
              backgroundColor: '#f6f6f6',
              fontSize: '12px',
              color: '#1a2b88',
              fontWeight: 'bold',
            }}
          >
            {t('action:export')}
          </HeaderRemove>
        )}
      </StyledHeading>

      {listUpComingOnboard.length > 0 && (
        <StyledHeadingRight onClick={onViewAllClick}>
          <StyledSubTitle>{t('view_all')}</StyledSubTitle>
          <StyledBadge>{listUpComingOnboard.length}</StyledBadge>
          <ArrowRightIcon />
        </StyledHeadingRight>
      )}
      <StyledContent>
        {listUpComingOnboard.map((item) => {
          return <ListingItem key={item.id} {...item} />;
        })}
      </StyledContent>

      <ListingModal
        title={
          <StyledHeadingLeft>
            <StyledTitle>Upcoming Onboard Employees</StyledTitle>
            <StyledBadge>{listUpComingOnboard.length}</StyledBadge>
          </StyledHeadingLeft>
        }
        isOpen={isOpenModalViewAll}
        onCancel={onCancelModalViewAll}
      >
        {listUpComingOnboard.map((item) => {
          return <ListingItem key={item.id} {...item} />;
        })}
      </ListingModal>
    </StyledContainer>
  );
}

import { useEffect, useState } from 'react';
import CreateReviewCycle from './CreateReviewcycle';
import PerformanceReview from './PerformanceReview';
import ManagerViewPage from './manager';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { actions } from './performanceSlice';

export default function PerformanceReviewPage() {
  const dispatch = useAppDispatch();
  const [createMode, setCreateMode] = useState(false);
  const user = useAppSelector((state) => state.auth?.user);
  const [viewMode, setViewMode] = useState('');
  const { resetcycleDetail, resetperformanceTemplateDetail } = actions;

  useEffect(() => {
    dispatch(resetperformanceTemplateDetail({}));
    if (createMode && !viewMode) dispatch(resetcycleDetail({}));
  }, [dispatch, createMode, viewMode]);

  return (
    <>
      {createMode ? (
        <CreateReviewCycle
          setCreateMode={setCreateMode}
          setViewMode={setViewMode}
          viewMode={viewMode}
        />
      ) : (
        <PerformanceReview setCreateMode={setCreateMode} setViewMode={setViewMode} />
      )}
    </>
  );
}
function resetcycleDetail(): any {
  throw new Error('Function not implemented.');
}

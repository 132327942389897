import { TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { DownTrendIcon, UpTrendIcon } from 'assets/icons';
import moment from 'moment';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { selectFormatDate, selectFormatMonth } from 'app/commonRedux/appSlice';
import { useAppSelector } from 'app/hooks';
import ITVTable from 'components/ITVTable';
import { LIST_DEPENDENTS } from 'constants/commons';
import { selectPermissions } from 'features/auth/authSlice';
import EmployeeColumn from 'features/employee/components/EmployeeColumn';
import StatusColumnCell from 'features/payroll/pages/ViewEmployee/components/StatusColumnCell';
import { TitleColumnCenter } from 'styles/tableStyled';
import { RealDependentList } from 'types';

interface DependentListProps {
  loading: boolean;
  data: RealDependentList[];
  totalResult: number;
  offset: number;
  limit: number;
  setOffset: Dispatch<SetStateAction<number>>;
  setLimit: Dispatch<SetStateAction<number>>;
  onTableChange?: (
    pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue | null>,
    sorter: SorterResult<any>,
  ) => void;
  onRefresh?: () => void;
  monthYear: string;
}

export function DependentList({
  loading,
  data,
  totalResult,
  offset,
  limit,
  setOffset,
  setLimit,
  onTableChange,
  onRefresh,
  monthYear,
}: DependentListProps) {
  const { t } = useTranslation(['modal', 'employee', 'onboard', 'timesheet']);

  const dataFormatDate = useAppSelector(selectFormatDate);
  const dataFormatMonth = useAppSelector(selectFormatMonth);
  const permissions = useAppSelector(selectPermissions);

  const COLUMNS: any = [
    {
      title: () => {
        return (
          <TitleColumnCenter>{t('employee:employee_information_fields.id')}</TitleColumnCenter>
        );
      },
      dataIndex: 'employeeIdParent',
      key: 'employeeIdParent',
      ellipsis: true,
      width: 60,
      minWidth: 60,
      align: 'center',
      fixed: 'left',
      sorter: true,
      render: function (text: any, record: any, index: any) {
        const trueIndex =
          offset === 1 ? index + limit * (offset - 1) : index + limit * (offset - 1) - limit;

        const obj: any = {
          children: record?.employeeIdParent,
          props: {}, //{ style: { verticalAlign: 'top' } },
        };
        if (index >= 1 && record?.userId === data[trueIndex - 1]?.userId) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== data?.length && record?.userId === data[trueIndex + i]?.userId;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }
        return obj;
      },
    },
    {
      title: () => {
        return (
          <TitleColumnCenter>
            {t('employee:employee_information_fields.employee')}
          </TitleColumnCenter>
        );
      },
      dataIndex: 'fullNameParent',
      key: 'fullNameParent',
      width: 220,
      minWidth: 220,
      ellipsis: true,
      fixed: 'left',
      sorter: true,
      render: function (text: any, record: any, index: any) {
        const trueIndex =
          offset === 1 ? index + limit * (offset - 1) : index + limit * (offset - 1) - limit;
        const obj: any = {
          children: (
            <EmployeeColumn
              avatar={record?.fullNameParent}
              fullName={record?.fullNameParent}
              email={record?.emailParent}
            />
          ),
          props: {}, //{ style: { verticalAlign: 'top' } },
        };
        if (index >= 1 && record?.userId === data?.[trueIndex - 1]?.userId) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== data?.length && record?.userId === data?.[trueIndex + i]?.userId;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }
        return obj;
      },
    },
    {
      title: t('employee:dependant_information_fields.col_name'),
      dataIndex: 'dependentName',
      key: 'dependentName',
      width: 100,
      render: function (text: any, record: any, index: any) {
        return record?.fullName;
      },
    },
    {
      title: t('employee:dependant_information_fields.col_dob'),
      dataIndex: 'dob',
      key: 'dob',
      width: 100,
      render: function (text: any, record: any, index: any) {
        return record?.dateOfBirth ? moment.utc(record?.dateOfBirth).format(dataFormatDate) : '';
      },
    },
    {
      title: t('employee:dependant_information_fields.col_tax_number'),
      dataIndex: 'taxNumber',
      key: 'taxNumber',
      width: 100,
      render: function (text: any, record: any, index: any) {
        return record?.PITCode;
      },
    },
    {
      title: t('employee:dependant_information_fields.col_nationality'),
      dataIndex: 'nationality',
      key: 'nationality',
      width: 100,
      render: function (text: any, record: any, index: any) {
        return record?.nationality;
      },
    },
    {
      title: t('employee:dependant_information_fields.col_identity_card'),
      dataIndex: 'identity',
      key: 'identity',
      width: 100,
      render: function (text: any, record: any, index: any) {
        return record?.identity;
      },
    },
    {
      title: t('employee:dependant_information_fields.col_relationship'),
      dataIndex: 'relationship',
      key: 'relationship',
      width: 100,
      minWidth: 100,
      ellipsis: true,
      render: function (text: any, record: any, index: any) {
        const depen = LIST_DEPENDENTS.filter((item) => item.value === record?.relationship);
        return depen.length > 0 ? depen[0].label : '';
      },
    },
    {
      title: t('employee:dependant_information_fields.col_start_month'),
      dataIndex: 'startMonth',
      key: 'startMonth',
      width: 120,
      minWidth: 120,
      render: function (text: any, record: any, index: any) {
        return record?.startMonth ? moment(record?.startMonth).format(dataFormatMonth) : '';
      },
    },
    {
      title: t('employee:dependant_information_fields.col_end_month'),
      dataIndex: 'endMonth',
      key: 'endMonth',
      width: 120,
      minWidth: 120,
      render: function (text: any, record: any, index: any) {
        return record?.endMonth ? moment(record?.endMonth).format(dataFormatMonth) : '';
      },
    },
    {
      title: t('employee:dependant_information_fields.sum_dependents'),
      dataIndex: 'sumDependents',
      key: 'sumDependents',
      width: 120,
      minWidth: 120,
      align: 'center',
      render: function (text: any, record: any, index: any) {
        const trueIndex =
          offset === 1 ? index + limit * (offset - 1) : index + limit * (offset - 1) - limit;
        const status = record.isChange;

        const obj: any = {
          children: (
            <div
              style={{
                display: 'flex',
                gap: '4px',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {status === 'increase' ? (
                <>
                  <UpTrendIcon />
                  <StyledPercentage showUpTrendIcon={true} showDownTrendIcon={false}>
                    {record?.sumDependents}
                  </StyledPercentage>
                </>
              ) : status === 'decrease' ? (
                <>
                  <DownTrendIcon />
                  <StyledPercentage showUpTrendIcon={false} showDownTrendIcon={true}>
                    {record?.sumDependents}
                  </StyledPercentage>
                </>
              ) : (
                record?.sumDependents
              )}
            </div>
          ),
          props: {},
        };
        if (index >= 1 && record?.userId === data?.[trueIndex - 1]?.userId) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== data?.length && record?.userId === data?.[trueIndex + i]?.userId;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }
        return obj;
      },
    },
    {
      title: () => {
        return (
          <TitleColumnCenter>{t('employee:employee_information_fields.action')}</TitleColumnCenter>
        );
      },
      dataIndex: 'action',
      key: 'action',
      width: 60,
      minWidth: 60,
      align: 'center',
      fixed: 'right',
      render: (status: any, record: any, index: any) => {
        const trueIndex =
          offset === 1 ? index + limit * (offset - 1) : index + limit * (offset - 1) - limit;
        const obj: any = {
          children: (
            <StatusColumnCell
              showMenu={'dependent'}
              t={t}
              isShowFullMenu={true}
              item={{
                id: record.userId,
                fullName: record.fullNameParent,
                employeeId: record.employeeIdParent,
                email: record.emailParent,
              }}
              permissions={permissions}
              monthYear={monthYear}
              callBack={() => onRefresh?.()}
            />
          ),
          props: {}, //{ style: { verticalAlign: 'top' } },
        };
        if (index >= 1 && record?.userId === data?.[trueIndex - 1]?.userId) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== data?.length && record?.userId === data?.[trueIndex + i]?.userId;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }
        return obj;
      },
    },
  ];

  return (
    <ITVTable
      columns={COLUMNS}
      data={data}
      rowKeyMerge={'employeeId'}
      setSelectedUser={() => {}}
      loading={loading}
      totalResult={totalResult}
      setOffset={setOffset}
      offset={offset}
      setLimit={setLimit}
      limit={limit}
      onChange={onTableChange}
      rowKey={['employeeId']}
    />
  );
}

interface StyledPercentageProps {
  showUpTrendIcon?: boolean;
  showDownTrendIcon?: boolean;
}

const StyledPercentage = styled.div`
  ${(props: StyledPercentageProps) => {
    return css`
      color: ${props.showUpTrendIcon && 'var(--cl_success500)'};
      color: ${props.showDownTrendIcon && 'var(--cl_error500)'};
    `;
  }}
`;

import { ArrowRightOutlined } from '@ant-design/icons';
import { Card, Form, Popconfirm, Radio, Select, Tag } from 'antd';
import { SmartBezierEdge } from '@tisoap/react-flow-smart-edge';
import type { RadioChangeEvent } from 'antd';
import { Button, Drawer, Space } from 'antd';
import { useCallback, useEffect, useRef, useState } from 'react';
import ReactFlow, {
  Background,
  Controls,
  MarkerType,
  ReactFlowProvider,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
} from 'react-flow-renderer';
import styled from 'styled-components';
import ModalNode from '../../SetupWorkflowType/components/ModalNode';
import './index.css';

const { Option } = Select;

const initialNodes = [
  {
    id: '0',
    type: 'input',
    data: {
      label: 'CREATE PAYROLL',
      employee: [
        { fullName: 'HR Lead', email: 'hrstaff@gmail.com' },
        { fullName: 'HR Staff', email: 'hrstaff@gmail.com' },
      ],
    },
    position: { x: 150, y: 100 },
    style: {
      background: '#D6D5E6',
      color: '#333',
      border: '1px solid #222138',
      // width: 180,
    },
  },
  {
    id: '1',
    type: 'default',
    data: {
      label: 'CHECK PAYROLL',
      employee: [
        { fullName: 'HR Staff', email: 'hrstaff@gmail.com' },
        { fullName: 'CEO', email: 'ceo@gmail.com' },
      ],
    },
    position: { x: 0, y: 250 },
  },
  {
    id: '2',
    type: 'default',
    data: {
      label: 'CONFIRM PAYROLL',
      employee: [
        { fullName: 'HR Staff', email: 'hrstaff@gmail.com' },
        { fullName: 'CEO', email: 'ceo@gmail.com' },
      ],
    },
    position: { x: 200, y: 360 },
  },
  // {
  //   id: 'edges-4', //1
  //   type: 'output',
  //   data: {
  //     label: 'Node 4',
  //     description: 'aa',
  //     employee: [
  //       { fullName: 'HR Staff', email: 'hrstaff@gmail.com' },
  //       { fullName: 'CEO', email: 'ceo@gmail.com' },
  //     ],
  //     status: [],
  //   },
  //   position: { x: 0, y: 360 },
  // },
];
const initialEdges = [
  {
    id: '0',
    source: '0', //1
    target: '1',
    label: 'Send payroll',
    type: 'smart',
    markerEnd: { type: MarkerType.Arrow },
  },
  {
    id: '1',
    source: '1',
    target: '2',
    label: 'Approve',
    className: 'normal-edge',
    type: 'smart',
    markerEnd: { type: MarkerType.Arrow },
  },
  {
    id: '2',
    source: '2',
    target: '1',
    label: 'Approve eeeeeeeeee',
    className: 'normal-edge',
    type: 'smart',
    markerEnd: { type: MarkerType.Arrow },
  },
  // {
  //   id: 'edges-e2-4',
  //   source: 'edges-2',
  //   target: 'edges-4',
  //   label: 'transition 3',
  //   className: 'normal-edge',
  // },
];

const labelName = [
  { id: 1, name: 'PRL_WF_CREATE_1' },
  { id: 2, name: 'PRL_WF_CREATE_2' },
  { id: 3, name: 'PRL_WF_CREATE_3' },
  { id: 4, name: 'PRL_WF_CREATE_4' },
  { id: 5, name: 'PRL_WF_REQUEST1' },
  { id: 6, name: 'PRL_WF_CREATE_2' },
  { id: 7, name: 'PRL_WF_CREATE_3' },
  { id: 8, name: 'PRL_WF_CREATE_4' },
  { id: 9, name: 'PRL_WF_CONFIRM_1' },
  { id: 10, name: 'PRL_WF_CONFIRM_2' },
  { id: 11, name: 'PRL_WF_CONFIRM_3' },
  { id: 12, name: 'PRL_WF_CONFIRM_4' },
];
const edgeTypes = {
  smart: SmartBezierEdge,
};
const Workflow2 = (props: any) => {
  const { isShowComponents } = props;
  const reactFlowWrapper = useRef(null);
  const [nodes, setNodes] = useState(initialNodes);

  let arrayIdNode: any = nodes?.map((item, idx) => {
    return item?.id;
  });

  let idNodeMax = Math.max(...arrayIdNode);
  const getId = () => `${++idNodeMax}`;

  const [edges, setEdges] = useState(initialEdges);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [visible, setVisible] = useState(false);
  const [visibleAction, setVisibleAction] = useState(false);
  const [form] = Form.useForm();
  const [captureElementClick] = useState(true);
  const [captureEdgeClick] = useState(true);

  const [valueNode, setValueNode] = useState('');
  const [valueEdge, setValueEdge] = useState('');

  const [edgenextValueNode, setEdgeNextValueNode] = useState([]);
  const [edgePrevValueNode, setEdgePrevValueNode] = useState([]);
  const [valueAction, setValueAction] = useState('');
  const [fromNode, setFromNode] = useState<any[]>([]);
  const [toNode, setToNode] = useState({});
  const [valueRadio, setValueRadio] = useState(true);

  const handleNodesChange = useCallback((changes: any) => {
    setNodes((ns) => {
      return applyNodeChanges(changes, ns);
    });
  }, []);
  const handleEdgesChange = useCallback((changes: any) => {
    setEdges((es) => applyEdgeChanges(changes, es));
  }, []);
  const handleConnect = useCallback((params) => {
    setEdges((eds) => {
      return addEdge({ ...params, type: 'smart', markerEnd: { type: MarkerType.Arrow } }, eds);
    });
  }, []);

  const handleDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const handleDrop = useCallback(
    (event) => {
      event.preventDefault();

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const type = event.dataTransfer.getData('application/reactflow');

      // check if the dropped element is valid
      if (typeof type === 'undefined' || !type) {
        return;
      }

      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });
      const newNode = {
        id: getId(),
        type,
        position,
        data: { label: `${type} node` },
      };

      setNodes((nds) => nds.concat(newNode));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reactFlowInstance],
  );

  const onClose = () => {
    setVisible(false);
    setEdgePrevValueNode([]);
    setEdgeNextValueNode([]);
  };
  const onCloseAction = () => {
    setVisibleAction(false);
    setEdgePrevValueNode([]);
    setEdgeNextValueNode([]);
  };
  const handleFormChange = () => {};
  const onNodeClick = (event: any, node: any) => {
    const edgeNext = edges?.filter((item) => {
      return item.source === node.id;
    });
    const edgePrev = edges?.filter((item) => {
      return item.target === node.id;
    });

    setEdgeNextValueNode(edgeNext);
    setEdgePrevValueNode(edgePrev);

    setVisible(true);
    setValueNode(node);

    form.setFieldsValue({
      nameValue: node?.data.label,
      desValue: node?.data.label,
    });
  };
  const onEdgeClick = (event: any, edge: any) => {
    const from = nodes?.find((item) => {
      return edge.source === item.id;
    });
    setFromNode(from);
    const to = nodes?.find((item) => {
      return edge.target === item.id;
    });
    form.setFieldsValue({
      selectAction: edge.label,
    });
    setToNode(to);
    setValueEdge(edge);
    setValueAction(edge.label);

    setVisibleAction(true);
  };

  const onSave = () => {
    form.validateFields().then((values: any) => {
      setNodes((nds: any) =>
        nds.map((node: any) => {
          if (valueNode.id === node.id) {
            return {
              ...node,
              data: { ...node.data, label: values.desValue },
              style: {
                background: values.colorNode,
                color: '#333',
                border: '1px solid #222138',
                // width: 180,
              },
            };
          } else {
            return node;
          }
        }),
      );
    });
    setEdges((edgs) =>
      edgs.map((edge) => {
        if (valueEdge.id === edge.id) {
          return { ...edge, label: valueAction };
        } else {
          return edge;
        }
      }),
    );
    setVisible(false);
    setVisibleAction(false);
  };
  const [loading, setLoading] = useState(false);

  const loadMoreData = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    setLoading(false);
  };

  useEffect(() => {
    loadMoreData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectAction = (value: string) => {
    setValueAction(value);
  };

  const onDelete = () => {
    setVisible(false);
    setEdgePrevValueNode([]);
    setEdgeNextValueNode([]);
    setNodes((nds: any) => nds.filter((node: any) => node.id !== valueNode?.id));
    setEdges((edges: any) => edges.filter((edge: any) => edge.source !== valueNode?.id)); // delete edge prev
    setEdges((edges: any) => edges.filter((edge: any) => edge.target !== valueNode?.id)); // delete edge next
  };
  const onDeleteEdge = () => {
    setVisibleAction(false);
    setEdgePrevValueNode([]);
    setEdgeNextValueNode([]);
    setEdges((edges: any) => edges.filter((edge: any) => edge.id !== valueEdge?.id));
  };
  const onSaveAll = () => {
    console.log({ nodes: nodes, edges: edges });
  };

  const onChangeRadio = (e: RadioChangeEvent) => {
    setValueRadio(e.target.value);
  };

  return (
    // <div style={isShowComponents ? {} : { display: 'none' }}>
    isShowComponents && (
      <>
        <div
          className="site-drawer-render-in-current-wrapper"
          style={{ display: 'flex', height: '100%', width: '100%', position: 'relative' }}
        >
          <div className="dndflow">
            <ReactFlowProvider>
              <aside>
                <div
                  className="dndnode input"
                  onDragStart={(event) => onDragStart(event, 'input')}
                  draggable
                >
                  Input Node
                </div>
                <div
                  className="dndnode"
                  onDragStart={(event) => onDragStart(event, 'default')}
                  draggable
                >
                  Default Node
                </div>
                <div
                  className="dndnode output"
                  onDragStart={(event) => onDragStart(event, 'output')}
                  draggable
                >
                  Output Node
                </div>
              </aside>
              <div className="reactflow-wrapper" ref={reactFlowWrapper}>
                <CustomReactFlow
                  nodes={nodes}
                  edges={edges}
                  onNodesChange={handleNodesChange}
                  onEdgesChange={handleEdgesChange}
                  onConnect={handleConnect}
                  onInit={setReactFlowInstance}
                  onNodeClick={captureElementClick ? onNodeClick : undefined}
                  onEdgeClick={captureEdgeClick && onEdgeClick}
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                  fitView
                  // connectionLineComponent={ConnectionLine}
                  edgeTypes={edgeTypes}
                  deleteKeyCode={null}
                >
                  <Background />
                  <Controls />
                </CustomReactFlow>
              </div>
            </ReactFlowProvider>
          </div>

          {/* Modal node */}
          <CustomDrawer
            title={valueNode?.data?.label}
            placement="right"
            closable={false}
            onClose={onClose}
            visible={visible}
            getContainer={false}
            extra={
              <>
                <Space style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                  <InfoRadio.Group
                    style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}
                    onChange={onChangeRadio}
                    value={valueRadio}
                  >
                    <InfoRadio value={true}>Create</InfoRadio>
                    <InfoRadio value={false}>Update</InfoRadio>
                  </InfoRadio.Group>
                </Space>
                <Space>
                  {/* <Button onClick={onClose}>Cancel</Button> */}

                  <Button type="primary" onClick={onSave}>
                    Save
                  </Button>
                  <Button type="primary" onClick={onDelete}>
                    Delete
                  </Button>
                </Space>
              </>
            }
            style={{ position: 'absolute' }}
          >
            <ModalNode
              labelName={labelName}
              form={form}
              edgenextValueNode={edgenextValueNode}
              edgePrevValueNode={edgePrevValueNode}
              valueNode={valueNode}
              initialEdges={initialEdges}
              nodes={nodes}
              loadMoreData={loadMoreData}
            />
          </CustomDrawer>

          {/* Modal action */}
          <CustomDrawer
            title="Transition"
            placement="right"
            closable={false}
            onClose={onCloseAction}
            visible={visibleAction}
            getContainer={false}
            extra={
              <Space>
                <Button onClick={onCloseAction}>Cancel</Button>
                <Button type="primary" onClick={onSave}>
                  Save
                </Button>
                <Button type="primary" onClick={onDeleteEdge}>
                  Delete
                </Button>
              </Space>
            }
            style={{ position: 'absolute' }}
          >
            <Form form={form} onFieldsChange={handleFormChange} layout="vertical">
              {/* List action */}
              <div>
                <CustomCard>
                  <CustomTag color="green">{fromNode?.data?.label}</CustomTag>
                  <ArrowRightOutlined />
                  <CustomTag color="purple">{toNode?.data?.label}</CustomTag>
                  <CustomFormItem name="selectAction">
                    <Select onChange={handleSelectAction}>
                      {initialEdges?.map((item: any) => {
                        return <Option value={item?.label}>{item?.label}</Option>;
                      })}
                    </Select>
                  </CustomFormItem>
                </CustomCard>
              </div>
            </Form>
          </CustomDrawer>
          <div style={{ position: 'absolute', top: '10px', right: 0 }}>
            <Popconfirm
              title="Are you sure?"
              onConfirm={() => {
                onSaveAll();
              }}
              okText="Yes"
              cancelText="No"
              placement="topRight"
            >
              <Button type="primary">Save</Button>
            </Popconfirm>
          </div>
        </div>

        {/* </div> */}
      </>
    )
    // </div>
  );
};

export default Workflow2;

export const CustomDrawer = styled(Drawer)`
  .ant-drawer-mask {
    background: transparent !important;
  }
  .ant-drawer-content-wrapper {
    height: 500px;
  }
`;
export const CustomCard = styled(Card)`
  margin-bottom: 5px;
`;
export const CustomTag = styled(Tag)`
  font-size: 16px;
`;
export const CustomFormItem = styled(Form.Item)`
  margin-top: 10px;
  margin-bottom: 0px !important;
`;
export const InfoRadio = styled(Radio)`
  .ant-radio-disabled .ant-radio-inner {
    border-color: #f1592a;
  }
  .ant-radio-inner::after {
    background: #f1592a;
    border-color: #f1592a;
  }
`;
export const CustomReactFlow = styled(ReactFlow)`
  .react-flow__attribution {
    display: none;
  }
  path.react-flow__edge-path:hover {
    stroke-width: 4;
    cursor: pointer;
  }
  .react-flow__edge-textbg {
    cursor: pointer;
  }
`;

import styled from 'styled-components';
import { Form, Button } from 'antd';

export const PersonnelInfomationHeader = styled.div`
  height: 38px;
  font-size: 20px;
  font-weight: 550;
  background-color: var(--cl_gray200);
  border-radius: 2px;
  line-height: 38px;
  padding-left: 17px;
  bottom: 1000px;
  margin-top: 24px;
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PersonnelInfomationHeaderButton = styled(Button)`
  margin-right: 6px;
  border-radius: var(--bd_radius_small);
  border-color: var(--cl_primary500);
  color: var(--cl_primary500);
`;

export const RelativeModalForm = styled(Form)`
  overflow-y: auto;
  overflow-x: hidden;

  .ant-form-item-label > label.ant-form-item-required::before {
    content: none;
  }

  .ant-form-item-label > label.ant-form-item-required::after {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }

  &::-webkit-scrollbar-track {
    margin-top: 20px;
  }
  &::-webkit-scrollbar {
    background-color: var(--cl_white);
  }
`;

import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosClient from 'api/axiosClient';
import { notificationToast } from 'components/notificationToast';
import { Notification } from 'constants/notification';
import moment from 'moment';
import insurancesApi from 'api/insurancesApi';
import { setGlobalLoading } from '../../app/commonRedux/appSlice';

export const getGeneralSetting = createAsyncThunk(
  'insurance/general-setting',
  async (_, thunkApi) => {
    try {
      const res = await axiosClient.get('insurance/compulsory-insurance');
      return res;
    } catch (error) {
      thunkApi.rejectWithValue(error);
    }
  },
);

export const getHistorySetting = createAsyncThunk(
  'insurance/general-setting-history',
  async (_, thunkApi) => {
    try {
      const res = await axiosClient.get('insurance/general-setting-history');
      return res;
    } catch (error) {
      thunkApi.rejectWithValue(error);
    }
  },
);

export const getLeaveTypeActive = createAsyncThunk(
  'timesheet/list-leave-type-config-active',
  async (_, thunkApi) => {
    try {
      const res = axiosClient.get('timesheet/list-leave-type-config-active');
      return res;
    } catch (error) {
      thunkApi.rejectWithValue(error);
    }
  },
);

export const getTypeInsurance = createAsyncThunk(
  'insurance/getTypeInsurance',
  async (_, thunkApi) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await insurancesApi.getTypeInsurance();
      thunkApi.dispatch(setGlobalLoading({ loading: false }));

      return response;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return error;
    }
  },
);

export const pushInsuranceSetting = createAsyncThunk(
  'insurance/compulsory-insurance',
  async (params: any, thunkApi) => {
    try {
      const res = await axiosClient.post('insurance/compulsory-insurance', params);
      await thunkApi.dispatch(getGeneralSetting());
      notificationToast(
        Notification.Type.Success,
        'Update Insurance setting success',
        Notification.Duration._4s,
      );
      return res;
    } catch (error) {
      thunkApi.rejectWithValue(error);
    }
  },
);

export const getCheckIDUser = createAsyncThunk(
  'insurance/getCheckIDUser',
  async (params: any, thunkApi) => {
    try {
      const response: any = await insurancesApi.getCheckIDUser(params);
      return response;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return error;
    }
  },
);
export const getEmployeeSearch = createAsyncThunk(
  'insurance/getEmployeeSearch',
  async (params: any, thunkApi) => {
    try {
      const response: any = await insurancesApi.getEmployeeSearch(params);
      return response;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return error;
    }
  },
);
export const getTypeIncrease = createAsyncThunk(
  'insurance/getTypeIncrease',
  async (_, thunkApi) => {
    try {
      const response: any = await insurancesApi.getTypeIncrease();
      return response;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return error;
    }
  },
);
export const createIncreaseDecrease = createAsyncThunk(
  'insurance/createIncreaseDecrease',
  async ({ callBack, ...params }: any, thunkApi) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await insurancesApi.createIncreaseDecrease(params);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      notificationToast(
        Notification.Type.Success,
        'Create Insurance Schedule success',
        Notification.Duration._4s,
      );
      callBack && callBack(response.data);
      return response;
    } catch (error) {
      callBack && callBack({ error });
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return thunkApi.rejectWithValue(error);
    }
  },
);
export const getSchedulePremium = createAsyncThunk(
  'insurance/getSchedulePremium',
  async (params: any, thunkApi) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await insurancesApi.getSchedulePremium(params);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return response;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return error;
    }
  },
);
export const getSchedulePremiumApproval = createAsyncThunk(
  'insurance/getSchedulePremiumApproval',
  async (params: any, thunkApi) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await insurancesApi.getSchedulePremium(params);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return response;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return error;
    }
  },
);
export const getScheduleDecrease = createAsyncThunk(
  'insurance/getScheduleDecrease',
  async (params: any, thunkApi) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await insurancesApi.getSchedulePremium(params);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return response;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return error;
    }
  },
);
export const deleteSchedulePremium = createAsyncThunk(
  'insurance/deleteSchedulePremium',
  async ({ callBack, ...params }: any, thunkApi) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await insurancesApi.deleteSchedulePremium(params);
      callBack && callBack(response.statusCode);
      notificationToast(
        Notification.Type.Success,
        'Delete Insurance success',
        Notification.Duration._4s,
      );
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return response;
    } catch (error) {
      callBack && callBack({ error });
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const resetSchedulePremium = createAsyncThunk(
  'insurance/resetSchedulePremium',
  async ({ callBack, ...params }: any, thunkApi) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await insurancesApi.resetSchedulePremium(params);
      callBack && callBack(response);
      notificationToast(Notification.Type.Success, response.message, Notification.Duration._4s);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return response;
    } catch (error) {
      callBack && callBack({ error });
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const postApproved = createAsyncThunk(
  'insurance/postApproved',
  async ({ callBack, ...params }: any, thunkApi) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await insurancesApi.postApproved(params);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack && callBack(response.statusCode);
      return response;
    } catch (error) {
      callBack && callBack({ error });
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return thunkApi.rejectWithValue(error);
    }
  },
);
export const getRecordPayment = createAsyncThunk(
  'insurance/getRecordPayment',
  async (params: any, thunkApi) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await insurancesApi.getRecordPayment(params);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return response;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return error;
    }
  },
);

export const getRecordPaymentYear = createAsyncThunk(
  'insurance/getRecordPaymentYear',
  async (params: any, thunkApi) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await insurancesApi.getRecordPaymentYear(params);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return response;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return error;
    }
  },
);
export const getRecordPay = createAsyncThunk(
  'insurance/getRecordPay',
  async (params: any, thunkApi) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await insurancesApi.getRecordPay(params);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return response;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return error;
    }
  },
);

export const getPaymentHistory = createAsyncThunk(
  'insurance/getPaymentHistory',
  async (params: any, thunkApi) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await insurancesApi.getPaymentHistory(params);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return response;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return error;
    }
  },
);
export const getExportParticipant = createAsyncThunk(
  'insurance/getExportParticipant',
  async ({ callBack, ...params }: any, thunkApi) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await insurancesApi.getExportParticipant(params);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack && callBack(response.statusCode);
      return response;
    } catch (error) {
      callBack && callBack({ error });
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return thunkApi.rejectWithValue(error);
    }
  },
);
export const getExportRecordPayment = createAsyncThunk(
  'insurance/getExportRecordPayment',
  async ({ callBack, ...params }: any, thunkApi) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await insurancesApi.getExportRecordPayment(params);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack && callBack(response.statusCode);
      return response;
    } catch (error) {
      callBack && callBack({ error });
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return thunkApi.rejectWithValue(error);
    }
  },
);
export const getExportPaymentByYear = createAsyncThunk(
  'insurance/getExportPaymentByYear',
  async ({ callBack, ...params }: any, thunkApi) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await insurancesApi.getExportPaymentByYear(params);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack && callBack(response.statusCode);
      return response;
    } catch (error) {
      callBack && callBack({ error });
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return thunkApi.rejectWithValue(error);
    }
  },
);
export const getPaymentHistoryRecord = createAsyncThunk(
  'insurance/getPaymentHistoryRecord',
  async (params: any, thunkApi) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await insurancesApi.getPaymentHistoryRecord(params);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return response;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return error;
    }
  },
);
export const postExportPdf = createAsyncThunk(
  'insurance/postExportPdf',
  async ({ callBack, ...params }: any, thunkApi) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await insurancesApi.postExportPdf(params);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack && callBack(response.statusCode);
      return response;
    } catch (error) {
      callBack && callBack({ error });
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const postEditSchedule = createAsyncThunk(
  'insurance/postEditSchedule',
  async ({ callBack, ...params }: any, thunkApi) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await insurancesApi.postEditSchedule(params);
      callBack && callBack(response.data);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      notificationToast(
        Notification.Type.Success,
        'Update Insurance Schedule success',
        Notification.Duration._4s,
      );
      return response;
    } catch (error) {
      callBack && callBack({ error });
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return thunkApi.rejectWithValue(error);
    }
  },
);
export const getTotalSchedule = createAsyncThunk(
  'insurance/getTotalSchedule',
  async (_, thunkApi) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await insurancesApi.getTotalSchedule();
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return response;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return error;
    }
  },
);

export const closeRecordPayment = createAsyncThunk(
  'insurance/update-close-setting',
  async (params: { idConfig: number | string; monthClose: any }, thunkApi) => {
    try {
      const { idConfig, monthClose } = params;
      const res = await axiosClient.put(`insurance/update-close-setting/${idConfig}`, {
        monthClose: monthClose,
      });
      await notificationToast(
        Notification.Type.Success,
        'Close successfully in this month',
        Notification.Duration._3s,
      );
      return res;
    } catch (error) {
      thunkApi.rejectWithValue(error);
    }
  },
);

export const getDataInsuranceMaster = createAsyncThunk(
  'configurations/config-type/insurance_master',
  async (_, thunkApi) => {
    try {
      const res = axiosClient.get('configurations/config-type/insurance_master');
      return res;
    } catch (error) {
      thunkApi.rejectWithValue(error);
    }
  },
);

export const getRejectedHistory = createAsyncThunk(
  'insurance/getRejectedHistory',
  async (params: any, thunkApi) => {
    try {
      const response: any = await insurancesApi.getRejectedHistory(params);
      return response;
    } catch (error) {
      thunkApi.rejectWithValue(error);
      return error;
    }
  },
);
export const getAutoIncreaseDecrease = createAsyncThunk(
  'insurance/getAutoIncreaseDecrease',
  async (params: any, thunkApi) => {
    try {
      const response: any = await insurancesApi.getAutoIncreaseDecrease(params);
      return response;
    } catch (error) {
      thunkApi.rejectWithValue(error);
      return error;
    }
  },
);
export const getCreateAuto = createAsyncThunk(
  'insurance/getCreateAuto',
  async ({ callBack, ...params }: any, thunkApi) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await insurancesApi.getCreateAuto(params);
      callBack && callBack(response.data);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      notificationToast(
        Notification.Type.Success,
        'Auto create success',
        Notification.Duration._4s,
      );
      return response;
    } catch (error) {
      callBack && callBack({ error });
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return thunkApi.rejectWithValue(error);
    }
  },
);

//===============EXPORT MANAGER===================

export const scheduleAdjust = createAsyncThunk(
  'insurance/export-insurance',
  async (params: { type: string; data: any }, thunkApi) => {
    try {
      const { type, data } = params;
      const { from, end, selected } = data;

      const select = { key: 'id', value: selected };
      let str: any = `Insurance_schedule_${
        type === 'incre' ? 'increase' : 'decrease'
      }_${moment().format('YYYY-MM-DD HH:mm')}.xlsx`;
      const response: any = await axiosClient.get(
        `insurance/export-insurance?type=${
          type === 'incre' ? 'increase' : 'decrease'
        }&fromDate=${from}&toDate=${end}&select=${JSON.stringify(select)}`,
        { responseType: 'blob' },
      );
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', str);
      document.body.appendChild(link);
      link.click();
      return response;
    } catch (error) {
      thunkApi.rejectWithValue(error);
    }
  },
);

export const listApprovedInsurance = createAsyncThunk(
  'insurance/export-approval',
  async (params: any, thunkApi) => {
    try {
      const { from, end, selected } = params;
      const select = { key: 'id', value: selected };
      let str: any = `Insurance_approval_${moment().format('YYYY-MM-DD HH:mm')}.xlsx`;
      const response: any = await axiosClient.get(
        `insurance/export-approval?fromDate=${from}&toDate=${end}&select=${JSON.stringify(select)}`,
        { responseType: 'blob' },
      );
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', str);
      document.body.appendChild(link);
      link.click();
      return response;
    } catch (error) {
      thunkApi.rejectWithValue(error);
    }
  },
);

export const getPtiParticipant = createAsyncThunk(
  '/pti-insurance',
  async (params: any, thunkApi) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));

      const response = await insurancesApi.getPtiParticipant(params);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));

      return response;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      thunkApi.rejectWithValue(error);
    }
  },
);

export const createPtiParticipant = createAsyncThunk(
  'create/pti-insurance',
  async (params: any, thunkApi) => {
    try {
      const { body, callBack } = params;
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await insurancesApi.createPTIPaticipant(body);
      callBack && callBack();
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return response;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      thunkApi.rejectWithValue(error);
    }
  },
);

export const updatePtiParticipant = createAsyncThunk(
  'update/pti-insurance',
  async (params: any, thunkApi) => {
    try {
      const { id, body, callBack } = params;
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await insurancesApi.updatePTIPaticipant({ id, body });
      response && callBack && callBack();
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      notificationToast(Notification.Type.Success, response?.message, Notification.Duration._3s);
      return response;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      thunkApi.rejectWithValue(error);
    }
  },
);

export const exportPti = createAsyncThunk('/report/export-pti', async (params: any, thunkApi) => {
  try {
    const { body, callBack } = params;
    const { isAutoSend } = body;
    if (isAutoSend === false) {
      let str: any = `Generate.xlsx`;
      const response: any = await axiosClient.post(`pti-insurance/export-pti`, body, {
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', str);
      document.body.appendChild(link);
      link.click();
      return response;
    } else {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));

      const response = await insurancesApi.exportPti({ body });
      callBack && callBack();
      thunkApi.dispatch(setGlobalLoading({ loading: false }));

      return response;
    }
  } catch (error) {
    thunkApi.dispatch(setGlobalLoading({ loading: false }));
    thunkApi.rejectWithValue(error);
  }
});

export const sendMailPti = createAsyncThunk(
  '/pti-insurance/send-mail',
  async (params: any, thunkApi) => {
    try {
      const { body, callBack } = params;
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await insurancesApi.sendMailFile({ body });
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack && callBack();
      return response;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      thunkApi.rejectWithValue(error);
    }
  },
);

/* eslint-disable jsx-a11y/anchor-is-valid */
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Select, Table } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { ExpandableConfig } from 'antd/lib/table/interface';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  selectEmployee,
  selectSizePageAndCurrentPage,
  setCheckAllStatus,
  setCheckRow,
  setSizePageOrCurrentPage,
} from 'features/employee/employeeSlice';
import { DEFAULT_SIZE_PAGE } from 'hrm-common/extensions/constant/personel';
import { TFunctionResult } from 'i18next';
import React, { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ResizeCallbackData } from 'react-resizable';
import styled, { css } from 'styled-components';
import { checkEveryChildPropertiesHasKeys } from 'utils/function';
import TableResizableTitle from './TableResizableTitle';
import { IConfigSelectData } from './interfaces/ITVTableInterfaces';

const { Option } = Select;

export interface COLUMNS {
  title: ((arg0: any, ...props: any) => JSX.Element) | string | number | TFunctionResult;
  dataIndex?: string;
  key?: string;
  width: number;
  minWidth: number;
  children?: COLUMNS[];
  render?: (text: any, record: any, index: number) => React.ReactNode;
  [propName: string]: any;
}
interface ITVTableProps {
  components?: any;
  columns: Array<COLUMNS>;
  data: any;
  width?: number | string;
  height?: number;
  setSelectedUser?: any;
  totalResult?: number;
  totalIni?: number;
  isRowSelect?: boolean;
  selectionType?: string;
  rowKey?: Array<string>;
  isScroll?: boolean;
  paginationPosition?: Array<string>;
  isMobile?: boolean;
  loading?: boolean;
  bordered?: boolean;
  size?: SizeType;
  isRowLight?: boolean;
  expandable?: ExpandableConfig<object>;
  pagination?: boolean;
  selectData?: IConfigSelectData;
  onRow?: any;
  onChange?: any;
  setLimit?: React.Dispatch<React.SetStateAction<number>>;
  limit?: number;
  setOffset?: React.Dispatch<React.SetStateAction<number>>;
  offset?: number;
  scroll?: any;
  [propName: string]: any;
  errorList?: any;
  summary?: any;
  rowKeyMerge?: any;
  columnSelectTitle?: any;
  filteredData?: Array<any>;
  isRecordYear?: boolean;
  isFixedLast?: boolean;
  isShowTotalFooter?: boolean;
  isCheckedAll?: boolean;
  setIsCheckedAll?: any;
  showFooter?: boolean;
}

const ITVTable = (props: ITVTableProps) => {
  let {
    components = { header: { cell: TableResizableTitle } },
    columns,
    data,
    columnSelectTitle = '',
    height = '1000px',
    width = '100%',
    setSelectedUser,
    totalResult,
    totalIni,
    isRowSelect = true,
    selectionType = 'checkbox',
    rowKey = ['id'],
    isScroll = true,
    paginationPosition = ['bottomRight'],
    isMobile = false,
    loading = false,
    bordered = true,
    size = 'small',
    isRowLight = false,
    expandable,
    selectData = {
      onSelectedDataList: () => {},
      selectedDataList: [],
      isResetDataSelected: false,
    },
    onRow,
    onChange,
    setLimit,
    limit,
    setOffset,
    offset,
    scroll,
    errorList,
    summary,
    rowKeyMerge,
    filteredData,
    isRecordYear = false,
    isFixedLast = false,
    isShowTotalFooter = false,
    isCheckedAll,
    setIsCheckedAll,
    showFooter = true,
  } = props;
  const dispatch = useAppDispatch();
  const stateSelectEmployee: any = useAppSelector(selectEmployee);
  const { t } = useTranslation(['common', 'insurance']);
  const [sizePage, setSizePage] = useState(isMobile ? 5 : DEFAULT_SIZE_PAGE);
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[] | number[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const page = useAppSelector(selectSizePageAndCurrentPage);
  const isCheckAll: boolean = useAppSelector(selectEmployee).isCheckAll;
  const [columnsNew, setColumnsNew] = useState<COLUMNS[]>(columns);
  const [isAll, setIsAll] = useState(false);

  useEffect(() => {
    if (Number(limit) === 10) {
      setIsAll(false);
    } else if (Number(limit) === 20) {
      setIsAll(false);
    } else if (Number(limit) === 50) {
      setIsAll(false);
    } else if (Number(limit) === 100) {
      setIsAll(false);
    } else {
      setIsAll(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (selectData.isResetDataSelected) {
      dispatch(setCheckRow([]));
      setSelectedRowKeys([]);
    }
  }, [selectData.isResetDataSelected, dispatch]);

  useEffect(() => {
    if (isCheckAll) {
      dispatch(setCheckAllStatus(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowKey[0]]);

  useEffect(() => {
    if (currentPage > 1 && !setLimit && !setOffset) {
      dispatch(setSizePageOrCurrentPage({ sizePage, currentPage: 1 }));
    } else if (setLimit && setOffset && limit) {
      setOffset(1);
      setLimit(limit);
    } else {
      dispatch(setSizePageOrCurrentPage({ sizePage }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sizePage, limit]);

  useEffect(() => {
    setColumnsNew(columns);
  }, [columns]);

  const handleResize =
    (indexColumn: number) =>
    (e: SyntheticEvent, { size }: ResizeCallbackData) => {
      const nextColumns = [...columnsResize];
      nextColumns[indexColumn] = {
        ...nextColumns[indexColumn],
        width: size.width,
      };
      setColumnsNew(nextColumns);
    };

  const columnsResize = columnsNew.map((col, index: number) => {
    return {
      ...col,
      onHeaderCell: (column: COLUMNS) => ({
        minWidth: column.minWidth || 100,
        width: column.width || 100,
        onResize: handleResize(index),
      }),
    };
  });

  useEffect(() => {
    const disabledArr = data?.map((item: { disabled: any }) => item?.disabled);
    const checkArr = disabledArr?.filter((i: any) => i !== undefined); //check if there is a item that have disabled key exist

    if (isCheckAll === true && checkArr?.length === 0) {
      let selectAllData = [];
      switch (rowKey[0]) {
        case 'employeeId':
          selectAllData = data?.map((item: { employeeId: any }) => item.employeeId);
          dispatch(setCheckRow(selectAllData));
          setSelectedRowKeys(selectAllData);
          dispatch(setCheckAllStatus(false));
          setIsCheckedAll && setIsCheckedAll(false);
          break;
        case 'userId':
          selectAllData = data?.map((item: { userId: any }) => item.userId);
          dispatch(setCheckRow(selectAllData));
          setSelectedRowKeys(selectAllData);
          dispatch(setCheckAllStatus(false));
          setIsCheckedAll && setIsCheckedAll(false);
          break;
        default:
          selectAllData = data?.map((item: { id: any }) => item.id);
          dispatch(setCheckRow(selectAllData));
          setSelectedRowKeys(selectAllData);
          dispatch(setCheckAllStatus(false));
          setIsCheckedAll && setIsCheckedAll(false);
          break;
      }
    }
    if (isCheckAll === true && checkArr?.length > 0) {
      let selectAllData = [];
      switch (rowKey[0]) {
        case 'employeeId':
          selectAllData = data?.map((item: { employeeId: any }) => item.employeeId);
          dispatch(setCheckRow(selectAllData));
          setSelectedRowKeys(selectAllData);
          dispatch(setCheckAllStatus(false));
          setIsCheckedAll && setIsCheckedAll(false);
          break;
        case 'userId':
          selectAllData = data?.map((item: { userId: any }) => item.userId);
          // eslint-disable-next-line array-callback-return
          const disableData = data?.map((item: { disabled: boolean; userId: any }) => {
            if (item.disabled === true) return item.userId;
          });
          if (disableData?.length > 0) {
            for (let index = 0; index < disableData.length; index++) {
              let userIdDeselect = disableData?.[index];
              let indexUserIdToRemove = selectAllData.findIndex(
                (item: any) => item === userIdDeselect,
              );
              if (selectAllData.length >= 1 && indexUserIdToRemove !== -1) {
                selectAllData.splice(indexUserIdToRemove, 1);
              }
            }
          }
          dispatch(setCheckRow([...stateSelectEmployee.selectRowKey, ...selectAllData]));
          setSelectedRowKeys(selectAllData);
          dispatch(setCheckAllStatus(false));
          setIsCheckedAll && setIsCheckedAll(false);
          break;
        default:
          selectAllData = data?.map((item: { id: any }) => item.id);
          dispatch(setCheckRow(selectAllData));
          setSelectedRowKeys(selectAllData);
          dispatch(setCheckAllStatus(false));
          setIsCheckedAll && setIsCheckedAll(false);
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onSelectChange = (selectedRowKeys: any) => {
    //this handle invert for table
    setSelectedRowKeys(selectedRowKeys);
    setSelectedUser && setSelectedUser(selectedRowKeys);
    // if (selectedRowKeys.length <= sizePage && stateSelectEmployee.selectRowKey.length < sizePage) {
    //   if (selectedRowKeys.length === 0) {
    //     //handle when uncheck default
    //     dispatch(setCheckRow([]));
    //   } else {
    //     dispatch(setCheckRow(selectedRowKeys));
    //   }
    // }
    if (
      selectedRowKeys.length === sizePage ||
      selectedRowKeys.length === data.filter((i: any) => !i.disabled).length
      //selectedRowKeys.length === sizePage
      //&&
      //stateSelectEmployee.selectRowKey.length === sizePage
    ) {
      dispatch(
        setCheckRow(removeDuplicates([...stateSelectEmployee.selectRowKey, ...selectedRowKeys])),
      );
    }

    selectData.onSelectedDataList(
      'clearPart',
      data.filter((item: any) => !selectedRowKeys.includes(item.employeeId)),
    );
    selectData.onSelectedDataList(
      'selectPart',
      data.filter((item: any) => selectedRowKeys.includes(item.employeeId)),
    );
    if (selectedRowKeys.length === 0 && rowKey[0] === 'key') {
      selectData.onSelectedDataList('clearPart', data);
    }
    if (!selectedRowKeys?.length) {
      dispatch(
        setCheckRow(
          stateSelectEmployee.selectRowKey.filter((id: any) => {
            return !data?.some((t: any) => t[rowKey[0]] === id);
          }),
        ),
      );
    }
  };

  const onSelect = (record: any, selected: any, selectedRows: any, nativeEvent: any) => {
    // handle check/uncheck event
    if (selected === false) {
      switch (rowKey[0]) {
        case 'key':
          if (data.find((item: any) => item.key === record.key)) {
            setSelectedRowKeys(
              (selectedRowKeys as string[]).filter((key: string) => key !== record.key),
            );

            selectData.onSelectedDataList('clearOne', [
              data.find((item: any) => item.key === record.key),
            ]);
          }
          break;
        case 'employeeId':
          const employeeIdDeselect = record.employeeId;
          const isEmployeeSelected = stateSelectEmployee.selectRowKey.includes(employeeIdDeselect);
          const indexEmployeeIdToRemove = stateSelectEmployee.selectRowKey.findIndex(
            (item: any) => item === employeeIdDeselect,
          );

          if (isEmployeeSelected && stateSelectEmployee.selectRowKey.length >= 1) {
            const employeeIdInStore = [...stateSelectEmployee.selectRowKey];
            employeeIdInStore.splice(indexEmployeeIdToRemove, 1);
            setSelectedRowKeys(employeeIdInStore as any);
            dispatch(setCheckRow(employeeIdInStore));
          }
          break;
        case 'userId':
          const userIdDeselect = record.userId;
          const isUserIdSelected = stateSelectEmployee.selectRowKey.includes(userIdDeselect);
          const indexUserIdToRemove = stateSelectEmployee.selectRowKey.findIndex(
            (item: any) => item === userIdDeselect,
          );
          if (isUserIdSelected && stateSelectEmployee.selectRowKey.length >= 1) {
            const userIdInStore = [...stateSelectEmployee.selectRowKey];
            userIdInStore.splice(indexUserIdToRemove, 1);
            setSelectedRowKeys(userIdInStore as any);
            dispatch(setCheckRow(userIdInStore));
          }
          break;
        default:
          const idDeselect = record.id;
          const isIdSelected = stateSelectEmployee.selectRowKey.includes(idDeselect);
          const indexIdToRemove = stateSelectEmployee.selectRowKey.findIndex(
            (item: any) => item === idDeselect,
          );
          if (isIdSelected && stateSelectEmployee.selectRowKey.length >= 1) {
            const idInStore = [...stateSelectEmployee.selectRowKey];
            idInStore.splice(indexIdToRemove, 1);
            setSelectedRowKeys(idInStore as any);
            dispatch(setCheckRow(idInStore));
          }
      }
    } else {
      switch (rowKey[0]) {
        case 'key':
          if (data.find((item: any) => item.key === record.key)) {
            setSelectedRowKeys((selectedRowKeys as string[]).concat(record.key));

            if (
              !data.every((item: any) =>
                checkEveryChildPropertiesHasKeys(
                  item,
                  (selectedRowKeys as string[]).concat(record.key),
                  'employeeId',
                ),
              )
            )
              selectData.onSelectedDataList('selectOne', [
                data.find((item: any) => item.key === record.key),
              ]);
          }
          break;
        case 'employeeId':
          const employeeIdSelect: any = [];
          employeeIdSelect.push(record.employeeId);
          setSelectedRowKeys(employeeIdSelect);
          setSelectedUser && setSelectedUser(employeeIdSelect);
          dispatch(
            setCheckRow(
              removeDuplicates([...stateSelectEmployee.selectRowKey, ...employeeIdSelect]),
            ),
          );
          break;
        case 'userId':
          const userIdSelect: any = [];
          userIdSelect.push(record.userId);
          setSelectedRowKeys(userIdSelect);
          setSelectedUser(userIdSelect);
          dispatch(
            setCheckRow(removeDuplicates([...stateSelectEmployee.selectRowKey, ...userIdSelect])),
          );
          break;
        default:
          const idSelect: any = [];
          idSelect.push(record.id);
          setSelectedRowKeys(idSelect);
          setSelectedUser && setSelectedUser(idSelect);
          dispatch(
            setCheckRow(removeDuplicates([...stateSelectEmployee.selectRowKey, ...idSelect])),
          );
      }
    }
  };

  const removeDuplicates = (arr: Array<string>) => {
    let s = new Set(arr);
    let it = s.values();
    return Array.from(it);
  };

  useEffect(() => {
    if (setLimit && setOffset && limit && offset) {
      setLimit(limit);
      setOffset(offset);
    } else {
      setCurrentPage(page.currentPage);
      setSizePage(page.sizePage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page.currentPage, page.sizePage, limit, offset]);

  useEffect(() => {
    dispatch(setSizePageOrCurrentPage({ sizePage, currentPage }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    selectData.selectedDataList.length > 0
      ? setSelectedRowKeys(
          selectData.selectedDataList.map((data: any) => data?.employeeId as string),
        )
      : setSelectedRowKeys(stateSelectEmployee.selectRowKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    selectData.selectedDataList.length === 0 && setSelectedRowKeys([]);
  }, [selectData.selectedDataList.length]);

  const itemRender = (current: any, type: any, originalElement: any) => {
    if (type === 'prev') {
      return (
        <a
          style={{
            display: 'flex',
            alignItems: 'center',
            height: 24,
            minWidth: 24,
            padding: '0 6px',
            marginRight: 10,
            fontSize: '1em',
            letterSpacing: '1px',
          }}
        >
          <LeftOutlined style={{ fontSize: '0.8em', padding: '0 5px' }} />
          <span style={{ marginTop: '2px' }}>{` ${t('common:prev')}`}</span>
        </a>
      );
    }
    if (type === 'next') {
      return (
        <a
          style={{
            display: 'flex',
            alignItems: 'center',
            height: 24,
            minWidth: 24,
            padding: '0 6px',
            marginLeft: 10,
            fontSize: '1em',
            letterSpacing: '0.5px',
          }}
        >
          <span style={{ marginTop: '2px' }}>{`${t('common:next')} `}</span>
          <RightOutlined style={{ fontSize: '0.8em', padding: '0 5px' }} />
        </a>
      );
    }
    return originalElement;
  };

  const handleChange = useCallback((value: any) => {
    if (setOffset && setLimit && offset) {
      setLimit(Number(value));
      setOffset(offset);
    } else {
      setSizePage(value);
      setCurrentPage(currentPage);
      dispatch(setSizePageOrCurrentPage({ sizePage: value, currentPage }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showTotal = useMemo(() => {
    const getTotalResult = () => {
      switch (totalResult) {
        case 10:
          return totalResult + 1;
        case 20:
          return totalResult + 1;
        case 50:
          return totalResult + 1;
        case 100:
          return totalResult + 1;
        default:
          return totalResult;
      }
    };
    return (
      <ShowTotal>
        <CustomSelect id="dropdownSize" value={limit ? limit : sizePage} onChange={handleChange}>
          <Option value="10">10</Option>
          <Option value="20">20</Option>
          <Option value="50">50</Option>
          <Option value="100">100</Option>
          {totalResult && <Option value={getTotalResult()}>{t('common:all')}</Option>}
        </CustomSelect>
        {!isShowTotalFooter ? (
          <span>
            <span style={{ letterSpacing: '1px' }}>{t('common:total')}: </span>
            <span>{isAll ? data?.length : totalIni || totalResult || data?.length}</span>
          </span>
        ) : isAll ? (
          <span>
            <span style={{ letterSpacing: '1px' }}>{t('common:total')}: </span>
            <span>{isAll ? data?.length : totalIni || totalResult || data?.length}</span>
          </span>
        ) : null}
      </ShowTotal>
    );
  }, [
    handleChange,
    sizePage,
    limit,
    totalResult,
    data?.length,
    totalIni,
    t,
    isAll,
    isShowTotalFooter,
  ]);
  let newSelectedRowKeys: any = [];

  const propsTable: any = isRowSelect
    ? {
        rowSelection: {
          columnTitle: columnSelectTitle,
          type: selectionType,
          selectedRowKeys,
          onSelect: onSelect,
          onChange: onSelectChange,
          getCheckboxProps: (record: any) => ({
            disabled: record.disabled || record?.isChildren,
            name: record.disabled || record?.isChildren,
          }),
          selections: [
            Table.SELECTION_INVERT,
            {
              key: 'all',
              text: t('common:select_all'),
              onSelect: () => {
                let newSelectedRowKeys;
                switch (rowKey[0]) {
                  case 'key':
                    newSelectedRowKeys = data.map((record: any) => record.key);
                    setSelectedRowKeys(newSelectedRowKeys);
                    selectData.onSelectedDataList('selectAll');
                    break;
                  case 'employeeId':
                    dispatch(setCheckAllStatus(true));
                    setIsCheckedAll && setIsCheckedAll(true);
                    newSelectedRowKeys = data?.map((record: any) => record.employeeId);
                    setSelectedRowKeys(newSelectedRowKeys);
                    dispatch(setCheckRow(newSelectedRowKeys));
                    break;
                  case 'userId':
                    dispatch(setCheckAllStatus(true));
                    setIsCheckedAll && setIsCheckedAll(true);
                    newSelectedRowKeys = data?.map((record: any) => record.userId);
                    // eslint-disable-next-line array-callback-return
                    const disableData = data?.map((item: { disabled: boolean; userId: any }) => {
                      if (item.disabled === true) return item.userId;
                    });
                    if (disableData?.length > 0) {
                      for (let index = 0; index <= disableData.length; index++) {
                        let userIdDeselect = disableData?.[index];
                        let indexUserIdToRemove = newSelectedRowKeys.findIndex(
                          (item: any) => item === userIdDeselect,
                        );
                        if (newSelectedRowKeys.length >= 1 && indexUserIdToRemove !== -1) {
                          newSelectedRowKeys.splice(indexUserIdToRemove, 1);
                        }
                      }
                    }
                    setSelectedRowKeys(newSelectedRowKeys);
                    dispatch(setCheckRow(newSelectedRowKeys));
                    break;
                  default:
                    dispatch(setCheckAllStatus(true));
                    setIsCheckedAll && setIsCheckedAll(true);

                    // select all based on filtered data source
                    newSelectedRowKeys = filteredData
                      ? filteredData.map((record: any) => record.id)
                      : data.map((record: any) => record.id);
                    const disableDataId = filteredData
                      ? // eslint-disable-next-line array-callback-return
                        filteredData?.map((item: { disabled: boolean; id: any }) => {
                          if (item.disabled === true) return item.id;
                        })
                      : // eslint-disable-next-line array-callback-return
                        data?.map((item: { disabled: boolean; id: any }) => {
                          if (item.disabled === true) return item.id;
                        });

                    if (disableDataId && disableDataId.length > 0) {
                      for (let index = 0; index <= disableDataId.length; index++) {
                        let idDeselect = disableDataId[index];
                        let indexIdToRemove = newSelectedRowKeys.findIndex(
                          (item: any) => item === idDeselect,
                        );
                        if (newSelectedRowKeys.length >= 1 && indexIdToRemove !== -1) {
                          newSelectedRowKeys.splice(indexIdToRemove, 1);
                        }
                      }
                    }
                    setSelectedRowKeys(newSelectedRowKeys || []);
                    dispatch(setCheckRow(newSelectedRowKeys));
                    break;
                }
                setSelectedRowKeys(newSelectedRowKeys);
              },
            },
            {
              key: 'none',
              text: t('common:clear_all'),
              onSelect: () => {
                setSelectedRowKeys(newSelectedRowKeys);
                dispatch(setCheckRow(newSelectedRowKeys));
                dispatch(setCheckAllStatus(false));
                setIsCheckedAll && setIsCheckedAll(false);
                selectData.onSelectedDataList('clearAll');
              },
            },
          ],
          renderCell: function (
            checked: boolean,
            record: any,
            index: number,
            originNode: React.ReactNode,
          ) {
            const rowMerge = rowKeyMerge ?? rowKey?.[0];
            const obj: any = {
              children: originNode,
              props: {}, //{ style: { verticalAlign: 'top' } },
            };

            if (rowKeyMerge) {
              if (index >= 1 && record[rowMerge] === data[index - 1][rowMerge]) {
                obj.props.rowSpan = 0;
              } else {
                for (
                  let i = 0;
                  index + i !== data.length && record[rowMerge] === data[index + i][rowMerge];
                  i += 1
                ) {
                  obj.props.rowSpan = i + 1;
                }
              }
            }
            return obj;
          },
        },
      }
    : {};

  const handleSetRowKey = (record: any) => {
    return rowKey?.reduce((result: any, key: string) => result?.[key], record);
  };

  return (
    <CustomTable
      {...propsTable}
      loading={loading}
      tableLayout={'fixed'}
      size={size}
      components={components}
      columns={columnsResize}
      // rowSelection={rowSelection}
      // locale={{ emptyText: t('insurance:no_data') }}
      dataSource={data}
      rowKey={(record: any) => handleSetRowKey(record)}
      pagination={{
        position: paginationPosition,
        total: totalResult,
        showTotal: () => showTotal,
        showSizeChanger: false,
        pageSize: limit ? limit : sizePage,
        itemRender: itemRender,
        simple: isMobile ? true : false,
        current: offset ? offset : currentPage,
        onChange: (page: any) => {
          if (!setLimit && !setOffset) {
            setCurrentPage(page);
            dispatch(setSizePageOrCurrentPage({ currentPage: page }));
          } else {
            setOffset && setOffset(page);
          }
        },
      }}
      scroll={
        isScroll
          ? { y: height ? height : window.innerHeight - 300, x: width }
          : scroll
          ? scroll
          : {}
      }
      isScroll={isScroll}
      isMobile={isMobile}
      showFooter={showFooter}
      isRecordYear={isRecordYear}
      isFixedLast={isFixedLast}
      bordered={bordered}
      rowClassName={(record: any, index) => {
        return errorList?.length &&
          (typeof (record?.key === 'number')
            ? errorList?.includes(record?.key)
            : record?.id
            ? errorList?.includes(record?.id)
            : record?.userId
            ? errorList?.includes(record?.userId)
            : errorList?.includes(record?.employeeId))
          ? 'table-row-error'
          : index % 2 === 0 || isRowLight
          ? 'table-row-light'
          : 'table-row-dark';
      }}
      expandable={expandable}
      onRow={onRow}
      onChange={onChange}
      summary={summary}
    />
  );
};

export default ITVTable;

export const ShowTotal = styled.div`
  display: flex;
  justify-content: space-between;
  color: #111827;
  width: 155px;
  right: 100%;
  flex-wrap: wrap;
  align-items: center;
  font-weight: bold;
`;

export const SelectTotal = styled.select`
  /* width: 60px; */
  padding: 4px 7px;
  border-radius: 4px;
  background-color: white;
  transition: 0.5 ease-out;
  outline: none;
  font-weight: bold;
`;

interface CustomTableProps {
  isScroll?: boolean;
  isMobile?: boolean;
  isRecordYear?: boolean;
  isFixedLast?: boolean;
  showFooter?: boolean;
}

export const CustomTable = styled(Table)`
  // Custom scroll
  ${(props: CustomTableProps) =>
    props.isScroll &&
    css`
      .ant-table-body {
        overflow-y: auto scroll !important;
        border-radius: 0 0 5px 5px;
      }
    `}
  ${(props: CustomTableProps) =>
    !props.isScroll &&
    css`
      .ant-table-body {
        overflow-y: auto !important;
        border-radius: 0 0 5px 5px;
      }
    `}
  .ant-table {
    border: 1px solid var(--cl_gray100);
  }
  // Custom resize column
  .react-resizable {
    position: relative;
    background-clip: padding-box;
  }
  .react-resizable-handle {
    position: absolute;
    right: -5px;
    bottom: 0;
    z-index: 1;
    width: 10px;
    height: 100%;
    cursor: col-resize;
  }

  .ant-table-pagination.ant-pagination {
    ${(props: CustomTableProps) =>
      props.showFooter
        ? css``
        : css`
            display: none !important;
          `}
  }

  // Custom paging
  .ant-pagination {
    ${(props: CustomTableProps) =>
      props.isMobile
        ? css``
        : css`
            display: flex;
            padding-right: 12px;
            font-size: 16px;
            &-total-text {
              flex-grow: 7;
            }
          `}
  }
  .ant-pagination-item-active {
    background-color: var(--cl_primary);
    border-color: var(--cl_primary);
    a {
      color: white;
    }
  }

  // Custom color row
  .table-row-light {
    background-color: var(--cl_white);
    border: 1px solid var(--cl_gray300) !important;
    :hover {
      background-color: var(--cl_gray200);
      border: 1px solid var(--cl_gray300) !important;
    }
  }
  .table-row-dark {
    background-color: var(--cl_gray100);
    border: 1px solid var(--cl_gray300) !important;
    :hover {
      background-color: var(--cl_gray200);
      border: 1px solid var(--cl_gray300) !important;
    }
  }
  .table-row-error > td {
    border-top: 1px solid red !important;
    border-bottom: 1px solid red !important;
  }
  td.ant-table-cell-fix-left {
    background-color: inherit !important;
  }
  td.ant-table-cell-fix-right {
    background-color: inherit !important;
  }
  td.ant-table-cell {
    background-color: inherit !important;
  }
  th.ant-table-cell-fix-left {
    background-color: var(--cl_gray200) !important;
  }
  th.ant-table-cell-fix-right {
    background-color: var(--cl_gray200) !important;
  }
  th.ant-table-cell {
    background-color: var(--cl_gray250) !important;
  }
  .ant-table-small .ant-table-thead > tr > th {
    background-color: var(--cl_gray250) !important;
    text-align: center !important;
    font-weight: 500 !important;
    font-family: 'Roboto';
  }
  .ant-table-row-level-1 {
    background-color: hsl(17deg 91% 93%);
  }
  .ant-table-row-level-2 {
    background-color: hsl(17deg 91% 96%);
  }
  .ant-table-row-level-3 {
    background-color: hsl(17deg 91% 99%);
  }

  // Custom checkbox
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--cl_primary);
    border-color: var(--cl_primary);
  }
  .ant-checkbox-disabled > .ant-checkbox-inner {
    background-color: #fbfbfb;
  }
  .ant-checkbox-inner {
    border-color: var(--cl_primary);
  }
  .ant-checkbox-indeterminate {
    background-color: var(--cl_primary);
  }

  //icon
  .anticon-down {
    color: #fe753e;
  }
  .ant-table-row-expand-icon {
    color: black;
  }

  ${(props: CustomTableProps) =>
    props.isRecordYear &&
    css`
      .ant-table-thead .ant-table-cell {
        padding: 0 !important;
        overflow: hidden !important;
      }
      .ant-table-summary {
        background: #d8fff3 !important;
      }
      .ant-table-summary tr td:first-child {
        background: #d8fff3 !important;
      }
    `}
  ${(props: CustomTableProps) =>
    props.isFixedLast &&
    css`
      .ant-table-summary tr td:last-child {
        background: #d8fff3 !important;
      }
    `}
`;

const CustomSelect = styled(Select)`
  .anticon-down {
    color: unset;
  }
`;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { notification } from 'antd';
import axiosClient from 'api/axiosClient';
import { setGlobalLoading } from 'app/commonRedux/appSlice';
import { notificationToast } from 'components/notificationToast';
import { Notification } from 'constants/notification';
import {
  ReasonLeaveOff,
  ResponseReasonLeaveOff,
} from 'hrm-common/extensions/interfaces/masterData';

// ================================================== ROLE
export const getPermissionList = createAsyncThunk(
  'masterData/getPermissionList',
  async (_, thunkApi: any) => {
    try {
      const response = await axiosClient.get(`/permissions`);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const updateTypeofPermission = createAsyncThunk(
  'masterData/updateTypeofConfigType',
  async (params: { configId: string; body: any; configType: string }, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.put(`/roles/${params.configId}`, params.body);
      thunkApi.dispatch(getRoleList());
      thunkApi.dispatch(getPermissionList());
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const deleteRole = createAsyncThunk(
  'masterData/deleteRole',
  async (params: { id: string | number }, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.delete(`/roles/${params.id}`);
      thunkApi.dispatch(getRoleList());
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const updateRole = createAsyncThunk(
  'masterData/updateRole',
  async (params: { id: string | number; body: any }, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.put(`/roles/${params.id}`, params.body);
      thunkApi.dispatch(getRoleList());
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const addRole = createAsyncThunk(
  'masterData/addRole',
  async (params: { body: any }, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.post(`/roles`, params.body);
      thunkApi.dispatch(getRoleList());
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const getRoleList = createAsyncThunk('masterData/getRoleList', async (_, thunkApi: any) => {
  try {
    // thunkApi.dispatch(setGlobalLoading({ loading: true }));
    const response = await axiosClient.get(`/roles`);
    return response;
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  } finally {
    // thunkApi.dispatch(setGlobalLoading({ loading: false }));
  }
});

// ================================================== DEPARTMENT

export const deleteDepartment = createAsyncThunk(
  'masterData/deleteDepartment',
  async (params: { id: string | number }, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.delete(`/departments/${params.id}`);
      thunkApi.dispatch(getDepartmentList());
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const updateDepartment = createAsyncThunk(
  'masterData/updateDepartment',
  async (params: { id: string | number; body: any }, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.put(`/departments/${params.id}`, params.body);
      thunkApi.dispatch(getDepartmentList());
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const addDepartment = createAsyncThunk(
  'masterData/addDepartment',
  async (params: { body: any }, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.post(`/departments`, params.body);
      thunkApi.dispatch(getDepartmentList());
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const getDepartmentList = createAsyncThunk(
  'masterData/getDepartmentList',
  async (_, thunkApi: any) => {
    try {
      // thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.get(`/departments/get-all-with-group?offset=1&limit=1000`);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      // thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

// ================================================== CATEGORY
export const addCategory = createAsyncThunk(
  'masterData/addCategory',
  async (params: { body: any }, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.post(`/configurations`, params.body);
      thunkApi.dispatch(getCategoryList());
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const updateTypeofCategory = createAsyncThunk(
  'masterData/updateTypeofConfigType',
  async (params: { configId: string; body: any; configType: string }, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.put(`/configurations/${params.configId}`, params.body);
      thunkApi.dispatch(getCategoryList());
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const deleteConfigTypeCategory = createAsyncThunk(
  'masterData/deleteConfigType',
  async (params: { configId: string; configType: string }, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.delete(`/configurations/${params.configId}`);
      thunkApi.dispatch(getCategoryList());
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const getCategoryList = createAsyncThunk(
  'masterData/getCategoryList',
  async (_, thunkApi: any) => {
    try {
      // thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.get(`/configurations/get-all-category`);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      // thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

// ================================================== POSITION
export const getConfigTypeList = createAsyncThunk(
  'masterData/getConfigTypeList',
  async (params: { configType: string }, thunkApi: any) => {
    try {
      // thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.get(`/configurations/config-type/${params.configType}`);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      // thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const deleteConfigType = createAsyncThunk(
  'masterData/deleteConfigType',
  async (params: { configId: string; typeId: string; configType: string }, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.delete(
        `/configurations/config-type/${params.configId}/${params.typeId}`,
      );
      thunkApi.dispatch(getConfigTypeList({ configType: params.configType }));
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const getListOptionPosition = createAsyncThunk(
  'masterData/getListOption',
  async (_, thunkApi: any) => {
    try {
      // thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.get(`/departments`);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      // thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const updateTypeofConfigType = createAsyncThunk(
  'masterData/updateTypeofConfigType',
  async (
    params: { configId: string; typeId: string; body: any; configType: string },
    thunkApi: any,
  ) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.put(
        `/configurations/config-type/${params.configId}/${params.typeId}`,
        params.body,
      );
      thunkApi.dispatch(getConfigTypeList({ configType: params.configType }));
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const addConfigType = createAsyncThunk(
  'masterData/addConfigType',
  async (params: { configId: string; body: any; configType: string }, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.post(
        `/configurations/config-type/${params.configId}`,
        params.body,
      );
      thunkApi.dispatch(getConfigTypeList({ configType: params.configType }));
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

//============================ SETTING ==============================

export const getDataConfigSetting = createAsyncThunk(
  'configurations/detail/28',
  async (_, thunkApi) => {
    try {
      const response = await axiosClient.get('configurations/detail/28');
      return response;
    } catch (error) {
      thunkApi.rejectWithValue(error);
    }
  },
);

export const updateConfigSetting = createAsyncThunk(
  'api/configurations/update-activation/28',
  async (params: { id: string | number; data: any; callBack?: any }, thunkApi) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const { id, data, callBack } = params;
      const response = await axiosClient.put(`configurations/update-activation/28/${id}`, data);
      response &&
        notificationToast(
          Notification.Type.Success,
          'Update successfully',
          Notification.Duration._3s,
        );
      response && thunkApi.dispatch(getDataConfigSetting());
      response && callBack(response);
      return response;
    } catch (error) {
      thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

// overtime
export const getAllEmployeeAssignOt = createAsyncThunk(
  'api/overtime-total-hour/get-all-employee-assign-ot',
  async (params: { callBack?: any }, thunkApi) => {
    try {
      const { callBack } = params;
      const response = await axiosClient.get('/overtime/assign');
      response && callBack && callBack(response.data);
      return response.data;
    } catch (error) {
      thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);
export const getAllEmployeeNotAssignOt = createAsyncThunk(
  'api/overtime-total-hour/get-all-employee-not-assign-ot',
  async (params: { callBack?: any }, thunkApi) => {
    try {
      const { callBack } = params;
      const response: any = await axiosClient.get('/overtime/get-user-not-assign');
      response && callBack && callBack(response.data);
      // response.message && notificationToast('success', response?.message, 3);
      return response.data;
    } catch (error) {
      //  response.message && notificationToast('error', response?.message, 3);
      thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);
export const updateEmployeeAssignOt = createAsyncThunk(
  'api/overtime-total-hour/update-employee-assign-ot',
  async (params: { data: any; callBack?: any }, thunkApi) => {
    try {
      const { data, callBack } = params;
      const response: any = await axiosClient.put(`/overtime/assign`, data);
      response && callBack && callBack(response.data);
      response.message &&
        notificationToast(Notification.Type.Success, response?.message, Notification.Duration._3s);
      thunkApi.dispatch(getAllEmployeeAssignOt({}));
      return response.data;
    } catch (error) {
      thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);
export const assignEmployeeOt = createAsyncThunk(
  'api/overtime-total-hour/employee-assign-ot',
  async (params: { data: any; callBack?: any }, thunkApi) => {
    try {
      const { data, callBack } = params;
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.post('/overtime/assign', data);
      response && callBack && callBack(response.data);
      response.message &&
        notificationToast(Notification.Type.Success, response?.message, Notification.Duration._3s);
      thunkApi.dispatch(getAllEmployeeAssignOt({}));
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return response.data;
    } catch (error) {
      thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);
export const getDataOvertimeSetting = createAsyncThunk(
  '/configurations/config-type/overtime',
  async (params: { callBack?: any }, thunkApi) => {
    const { callBack } = params;
    try {
      const response = await axiosClient.get('/configurations/config-type/overtime_rule');
      response && callBack && callBack(response.data);
      return response.data;
    } catch (error) {
      thunkApi.rejectWithValue(error);
    }
  },
);

export const updateConfigOtSetting = createAsyncThunk(
  'api/overtime-total-hour/update-overtime-total-hour',
  async (params: { data: any; callBack?: any }, thunkApi) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const { data, callBack } = params;
      const response: any = await axiosClient.post(`/configurations/overtime-rule`, data);
      response && callBack && callBack(response.data);
      response.message &&
        notificationToast(Notification.Type.Success, response?.message, Notification.Duration._3s);
      thunkApi.dispatch(getDataOvertimeSetting({}));
      return response;
    } catch (error) {
      thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);
//============================ Reason LeaveOff ==============================

export const postReasonLeaveOff = createAsyncThunk(
  'api/configurations/postReasonLeaveOff',
  async (params: { data: ReasonLeaveOff[]; callBack?: any }, thunkApi) => {
    try {
      const response: any = await axiosClient.post(`configurations/reason-leaveoff`, {
        value: params.data,
      });
      const data: ResponseReasonLeaveOff = response.data;
      params.callBack && params.callBack(data);
      thunkApi.dispatch(getDataReasonLeaveOff());
      return data;
    } catch (error) {
      thunkApi.rejectWithValue(error);
    }
  },
);
export const getDataReasonLeaveOff = createAsyncThunk(
  `configurations/detail/reasonLeaveOff`,
  async (_, thunkApi) => {
    try {
      const { data }: { data: ResponseReasonLeaveOff } = await axiosClient.get(
        `configurations/reason-leaveoff`,
      );
      return data;
    } catch (error) {
      thunkApi.rejectWithValue(error);
    }
  },
);

export const getAccessCheckInOut = createAsyncThunk(
  'api/access-check-in-out',
  async (_, thunkApi) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.get(`/access-check-in-out`);
      return response;
    } catch (error) {
      thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const updateMultipleDateCheckinOnline = createAsyncThunk(
  'api/online-access',
  async (data: any, thunkApi: any) => {
    try {
      const response = await axiosClient.put('/online-access', data);
      response &&
        notificationToast(
          Notification.Type.Success,
          (response as any).message,
          Notification.Duration._3s,
        );
      return response;
    } catch (error) {
      return error;
    }
  },
);
export const updateAccessCheckInOut = createAsyncThunk(
  'api/access-check-in-out/update-access-check-in-out',
  async (data: any, thunkApi: any) => {
    try {
      const response = await axiosClient.put(
        '/access-check-in-out/update-access-check-in-out',
        data,
      );
      response &&
        notificationToast(
          Notification.Type.Success,
          (response as any).message,
          Notification.Duration._3s,
        );
      thunkApi.dispatch(getAccessCheckInOut());
      return response;
    } catch (error) {
      return error;
    }
  },
);

//============================ General camera AI ==============================

export const getPersonCameraAI = createAsyncThunk(
  'api/check-in-out/person',
  async (id: any, thunkApi: any) => {
    try {
      const response = await axiosClient.get(`/check-in-out/person?placeId=${id}`);
      return response;
    } catch (error) {
      return error;
    }
  },
);

export const getPlace = createAsyncThunk(
  'api/check-in-out/place',
  async (params: any, thunkApi: any) => {
    try {
      const response = await axiosClient.get(`/check-in-out/place?id=${params?.id}`);
      return response;
    } catch (error) {
      return error;
    }
  },
);

export const createPlace = createAsyncThunk(
  'api/check-in-out/place',
  async (params: any, thunkApi: any) => {
    try {
      const { body, callBack } = params;
      const response = await axiosClient.post(`/check-in-out/place`, body);
      response && callBack();
      return (
        response &&
        notificationToast(
          Notification.Type.Success,
          (response as any).message,
          Notification.Duration._3s,
        )
      );
    } catch (error) {
      return error;
    }
  },
);

export const updatePlace = createAsyncThunk(
  'api/check-in-out/place',
  async (params: any, thunkApi: any) => {
    try {
      const { placeId, callBack, ...body } = params;
      const response = await axiosClient.put(`/check-in-out/place/${placeId}`, body.body);
      response && callBack();
      return (
        response &&
        notificationToast(
          Notification.Type.Success,
          (response as any).message,
          Notification.Duration._3s,
        )
      );
    } catch (error) {
      return error;
    }
  },
);

export const deletePlace = createAsyncThunk(
  'api/check-in-out/place',
  async (params: any, thunkApi: any) => {
    try {
      const { id, callBack } = params;
      const response = await axiosClient.delete(`/check-in-out/place/${id}`);
      response && callBack();
      return (
        response &&
        notificationToast(
          Notification.Type.Success,
          (response as any).message,
          Notification.Duration._3s,
        )
      );
    } catch (error) {
      return error;
    }
  },
);

export const getDevices = createAsyncThunk(
  'api/check-in-out/device',
  async (id: number, thunkApi: any) => {
    try {
      const response = await axiosClient.get(`/check-in-out/device?placeId=${id}`);
      return response;
    } catch (error) {
      return error;
    }
  },
);

export const editDevice = createAsyncThunk(
  'api/check-in-out/device',
  async (params: any, thunkApi: any) => {
    try {
      const { callBack, ...body } = params;
      const response = await axiosClient.post(`/check-in-out/device`, body);
      response && callBack();
      return (
        response &&
        notificationToast(
          Notification.Type.Success,
          (response as any).message,
          Notification.Duration._3s,
        )
      );
    } catch (error) {
      return error;
    }
  },
);

export const getAppCameraAI = createAsyncThunk(
  'api/check-in-out/get-app-camera-ai',
  async (params: any, thunkApi: any) => {
    try {
      const response = await axiosClient.get(`/check-in-out/app-camera-ai`);
      return response;
    } catch (error) {
      return error;
    }
  },
);

export const getListCheckInUser = createAsyncThunk(
  'api/check-in-out/person/check-in-day',
  async (params: any, thunkApi: any) => {
    try {
      const { date, dateFrom, dateTo, placeId, employeeId, deviceIds, type } = params;
      const response = await axiosClient.post(`/check-in-out/person/check-in-day`, {
        placeId,
        date,
        employeeId,
        deviceIds,
        type,
        dateFrom,
        dateTo,
      });
      notification.destroy();
      return response;
    } catch (error) {
      return error;
    }
  },
);

export const getCheckInPersonRegister = createAsyncThunk(
  'api/check-in-out/person-register',
  async (params: { placeId: number }, thunkApi: any) => {
    try {
      const { placeId } = params;
      const response = await axiosClient.post(`/check-in-out/person-register`, { placeId });
      return response;
    } catch (error) {
      return error;
    }
  },
);

export const postAppCameraAI = createAsyncThunk(
  'api/check-in-out/post-app-camera-ai',
  async (params: any, thunkApi: any) => {
    try {
      const { callBack, ...rest } = params;
      const response = await axiosClient.post(`/check-in-out/app-camera-ai`, rest);
      response && callBack();
      return (
        response &&
        notificationToast(
          Notification.Type.Success,
          (response as any).message,
          Notification.Duration._3s,
        )
      );
    } catch (error) {
      return error;
    }
  },
);

export const updateAppCameraAI = createAsyncThunk(
  'api/check-in-out/update-app-camera-ai',
  async (params: any, thunkApi: any) => {
    try {
      const { callBack, id, ...rest } = params;
      const response = await axiosClient.put(`/check-in-out/app-camera-ai/${id}`, rest);
      response && callBack();
      return (
        response &&
        notificationToast(
          Notification.Type.Success,
          (response as any).message,
          Notification.Duration._3s,
        )
      );
    } catch (error) {
      return error;
    }
  },
);

export const deleteAppCameraAI = createAsyncThunk(
  'api/check-in-out/delete-app-camera-ai',
  async (params: any, thunkApi: any) => {
    try {
      const { callBack, id } = params;
      const response = await axiosClient.delete(`/check-in-out/app-camera-ai/${id}`);
      response && callBack();
      return (
        response &&
        notificationToast(
          Notification.Type.Success,
          (response as any).message,
          Notification.Duration._3s,
        )
      );
    } catch (error) {
      return error;
    }
  },
);

export const postImgCheckInPersonRegister = createAsyncThunk(
  'api/check-in-out/person/register',
  async (params: any, thunkApi: any) => {
    try {
      const { formData, callBack } = params;
      const response = await axiosClient.post(`/check-in-out/person/register`, formData, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      });
      response && callBack(response);
      return response;
    } catch (error) {
      return error;
    }
  },
);

export const getPersonalAccessCheckInHistory = createAsyncThunk(
  'api/history-access-check-in-out/history-of-access-type',
  async (param: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.post(
        `/history-access-check-in-out/history-of-access-type/?offset=${param.offset}&limit=${param.limit}`,
        param.body,
      );
      return response;
    } catch (error) {
      thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const removeCheckInPerson = createAsyncThunk(
  'api/check-in-out/person/remove',
  async (params: any, thunkApi: any) => {
    try {
      const { placeId, employeeIds, callBack } = params;
      const response = await axiosClient.post(`/check-in-out/person/remove`, {
        placeId,
        employeeIds,
      });
      response && callBack(response);
      return response;
    } catch (error) {
      return error;
    }
  },
);
export const getHistoryTimesheetConfig = createAsyncThunk(
  'api/configurations/history-configuration-standard-hour',
  async (param: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.get(
        `/configurations/history-configuration-standard-hour?offset=${param.offset}&limit=${param.limit}`,
      );
      return response;
    } catch (error) {
      thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

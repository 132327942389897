import Icon, { DeleteTwoTone, EditTwoTone, ExclamationCircleOutlined } from '@ant-design/icons';
import { Form, Modal, Tag } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import IconAdd from 'assets/icons/ic_add_white.svg';
import ITVSearch from 'components/ITVSearch';
import ITVTable from 'components/ITVTable';
import PageHeader from 'components/PageHeader';
import { selectConfiguration } from 'features/configuration/configurationSlice';
import {
  createGroupEmail,
  deleteGroupEmail,
  getListGroupEmail,
  updateGroupEmail,
} from 'features/onboard/onboardAction';
import { selectOnboard } from 'features/onboard/onboardSlice';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { ContainerBgWhite } from 'styles/containerBgWhite';
import ModalAddGroupEmail from './ModalAddGroupEmail';
import { Container, ContainerRight, HeaderAdd, SearchContainer } from './styled';

enum MODE {
  VIEW = 'VIEW',
  ADD = 'ADD',
  UPDATE = 'UPDATE',
}
const { confirm } = Modal;

const GroupEmail = () => {
  const { t } = useTranslation(['onboard', 'validation', 'timesheet', 'menu', 'userguide']);
  const dispatch = useAppDispatch();
  const location: any = useLocation();
  const [form] = Form.useForm();

  const onboard = useAppSelector(selectOnboard);
  const listGroupEmail = onboard?.listGroupEmail?.result;

  const [data, setData] = useState([]);
  const [currentItem, setCurrentItem] = useState<any>(null);
  const [mode, setMode] = useState<string>('');
  const [currentPage, setCurrentPage] = useState(1);
  const [sizePage, setSizePage] = useState(10);
  const [search, setSearch] = useState('');
  const menuStore = useAppSelector(selectConfiguration);
  const dataScreenManage = menuStore?.dataScreenManage?.result;
  const [titleHeader, setTitleHeader] = useState('');

  useEffect(() => {
    const temp = dataScreenManage
      ?.filter((item: any) => location.pathname === item.path)
      ?.map((item: any) => item.nameScreen);

    setTitleHeader(temp ? temp[0] : '');
  }, [dataScreenManage, location.pathname]);

  useEffect(() => {
    dispatch(getListGroupEmail({}));
  }, [dispatch]);

  useEffect(() => {
    setCurrentPage(1);
    if (listGroupEmail) {
      if (search) {
        const temp = listGroupEmail?.filter((item: any) => {
          return item.groupName?.toLowerCase().includes(search);
        });
        setData(temp);
      } else {
        setData(listGroupEmail);
      }
    }
  }, [listGroupEmail, search]);

  const handleSearch = (value: any) => {
    const text = value.toLowerCase();
    if (text) {
      const temp = listGroupEmail?.filter((item: any) => {
        return item.groupName?.toLowerCase().includes(text);
      });
      setSearch(text);
      setData(temp);
    } else {
      setSearch('');
      setData(listGroupEmail);
    }
  };

  const onAdd = () => {
    setMode(MODE.ADD);
    setCurrentItem(null);
  };

  const onUpdate = (record: any) => {
    setMode(MODE.UPDATE);
    setCurrentItem(record);
  };

  const showDeleteConfirm = (record: any) => {
    confirm({
      title: t('onboard:are_you_sure_to_delete_this_record'),
      icon: <ExclamationCircleOutlined />,
      okText: t('timesheet:Yes'),
      okType: 'danger',
      cancelText: t('timesheet:No'),
      onOk() {
        dispatch(
          deleteGroupEmail({
            id: record.id,
            callBack: (response: any) => {
              if (response.statusCode === 202) {
                dispatch(getListGroupEmail({}));
                setData(listGroupEmail);
              }
            },
          }),
        );
      },
      onCancel() {},
    });
  };

  const COLUMNS = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      width: 20,
      minWidth: 20,
      align: 'center',
      render: function (text: any, record: any, index: any) {
        return data.map((obj: any) => obj.id).indexOf(record.id) + 1;
      },
    },
    {
      title: t('onboard:email_name_group'),
      dataIndex: 'groupName',
      key: 'groupName',
      width: 100,
      minWidth: 100,
      render: function (text: any, record: any, index: any) {
        return text;
      },
    },
    {
      title: t('onboard:email_name'),
      dataIndex: 'groupName',
      key: 'groupName',
      width: 100,
      minWidth: 100,
      render: function (text: any, record: any, index: any) {
        return record?.listEmail?.map((item: { id: number; value: string; name: string }) => {
          return <Tag color="default">{item.name ?? item.value}</Tag>;
        });
      },
    },
    {
      title: t('timesheet:action'),
      dataIndex: 'action',
      align: 'center',
      key: 'action',
      width: 30,
      minWidth: 30,
      render: function (text: any, record: any, index: any) {
        return (
          <div>
            <Icon
              component={() => <EditTwoTone twoToneColor="#F1592A" style={{ fontSize: 20 }} />}
              onClick={() => onUpdate(record)}
            />
            {!record.emailTemplate.length ? (
              <Icon
                component={() => (
                  <DeleteTwoTone twoToneColor="#B7B7B7" style={{ marginLeft: 20, fontSize: 20 }} />
                )}
                onClick={() => showDeleteConfirm(record)}
              />
            ) : null}
          </div>
        );
      },
    },
  ];

  const onSaveAdd = (values: any) => {
    if (mode === MODE.ADD) {
      dispatch(
        createGroupEmail({
          values,
          callBack: () => {
            setMode('');
            setCurrentItem(null);
          },
        }),
      );
    }
    if (mode === MODE.UPDATE) {
      dispatch(
        updateGroupEmail({
          ...values,
          id: currentItem?.id,
          callBack: () => {
            setMode('');
            setCurrentItem(null);
          },
        }),
      );
    }
  };

  return (
    <>
      <ContainerWrapper>
        <PageHeader title={titleHeader} />
      </ContainerWrapper>
      <ContainerBgWhite>
        <Container>
          <SearchContainer>
            <ITVSearch handleSearch={(value: any) => handleSearch(value)} />
          </SearchContainer>
          <ContainerRight>
            <HeaderAdd
              icon={<Icon component={() => <img src={IconAdd} alt="" />} />}
              onClick={onAdd}
            >
              {t('action:add_new')}
            </HeaderAdd>
          </ContainerRight>
        </Container>

        <ITVTable
          columns={COLUMNS}
          data={data}
          width={978}
          height={550}
          isRowSelect={false}
          setSelectedUser={null}
          setOffset={setCurrentPage}
          offset={currentPage}
          setLimit={setSizePage}
          limit={sizePage}
        />
      </ContainerBgWhite>

      {mode !== '' && (
        <Modal
          visible={mode !== ''}
          title={
            mode === MODE.ADD ? t('onboard:add_new_email_group') : t('onboard:update_email_group')
          }
          okText={t('timesheet:Save')}
          cancelText={t('timesheet:Cancel')}
          width={550}
          maskClosable={false}
          onCancel={() => {
            setMode('');
            setCurrentItem(null);
            form.resetFields();
          }}
          onOk={() => {}}
          centered
          footer={null}
        >
          <ModalAddGroupEmail record={currentItem} onSave={onSaveAdd} mode={mode} />
        </Modal>
      )}
    </>
  );
};

export default GroupEmail;

const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

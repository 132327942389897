import { EyeOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import employeeContractApi from 'api/employeeContractApi';
import ITVTable from 'components/ITVTable';
import { VerifyOTPContractModal } from 'components/Modals';
import StatusView from 'components/StatusView';
import { notificationToast } from 'components/notificationToast';
import { Notification } from 'constants/notification';
import { ContractDetail } from 'types';
import { getErrorMessage } from 'utils/getErrorMessage';

const StyledContainer = styled.div`
  max-width: 730px;
`;

const StyledWrapperAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const StyledEyeOutlined = styled(EyeOutlined)`
  font-size: 18px;
  color: var(--cl_info500);
  cursor: pointer;
`;

const StyledWrapperVerifyOTPTitle = styled.div`
  text-align: center;
`;

const StyledVerifyOTPTitle = styled.div`
  font-weight: bold;
  font-size: 1.5rem;
  text-align: center;
  letter-spacing: 0.5px;
  color: var(--cl_text_primary);
`;

interface NewContractInformationFieldProps {
  id: string;
}

export default function NewContractInformationField({ id }: NewContractInformationFieldProps) {
  const { t } = useTranslation(['employeeContract']);
  const [isLoading, setIsLoading] = useState(false);
  const [contactDetailList, setContactDetailList] = useState<Array<ContractDetail>>([]);
  const [isVerifyOTPModalOpen, setIsVerifyOTPModalOpen] = useState(false);
  const [recordSelected, setRecordSelected] = useState<ContractDetail>();

  const fetchEmployeeContractDetailsAPI = async () => {
    try {
      setIsLoading(true);
      const response = await employeeContractApi.get(id);

      setContactDetailList(response?.data?.data || []);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchEmployeeContractDetailsAPI();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: 50,
      minWidth: 50,
      ellipsis: true,
      render: (text: any) => <div>{text}</div>,
    },
    {
      title: t('employee_contract_details.contract_type_column_title'),
      dataIndex: 'contract_name',
      key: 'contract_name',
      ellipsis: true,
      width: 180,
      minWidth: 180,
      render: (text: any) => <div>{text || ''}</div>,
    },
    {
      title: t('employee_contract_details.duration_column_title'),
      align: 'center',
      dataIndex: 'duration',
      key: 'duration',
      ellipsis: true,
      width: 100,
      minWidth: 100,
      render: (text: any) => <div>{text || ''}</div>,
    },
    {
      title: t('employee_contract_details.from_to_column_title'),
      align: 'center',
      dataIndex: 'start_end_date',
      key: 'start_end_date',
      ellipsis: true,
      width: 200,
      minWidth: 200,
      render: (text: any, record: any) => (
        <div>{`${record.start_date || ''} - ${record.end_date || ''}`}</div>
      ),
    },
    {
      title: t('employee_contract_details.status_column_title'),
      dataIndex: 'status_contract',
      key: 'status_contract',
      ellipsis: true,
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (text: any) => <StatusView text={text} />,
    },
    {
      title: t('employee_contract_details.action_column_title'),
      dataIndex: 'action',
      key: 'action',
      ellipsis: true,
      width: 80,
      minWidth: 80,
      render: (text: any, record: any) => (
        <StyledWrapperAction>
          {Boolean(!record?.deactive) && record?.key_upload !== null && (
            <Tooltip placement="top" title={t('action.view_contract_file')}>
              <StyledEyeOutlined onClick={() => handleViewContractDocument(record)} />
            </Tooltip>
          )}
        </StyledWrapperAction>
      ),
    },
  ];

  const handleViewContractDocument = async (record: any) => {
    try {
      setIsLoading(true);
      const response = await employeeContractApi.checkViewAdmin(record.id);
      setIsLoading(false);

      // if (response?.data) {
      //   window.open(response?.data, '_blank', 'noopener noreferrer');
      // } else {
      setRecordSelected(record);
      setIsVerifyOTPModalOpen(true);
      // }
    } catch (error) {
      setIsLoading(false);
      const message = getErrorMessage(error);
      notificationToast(Notification.Type.Error, message, Notification.Duration._3s);
    }
  };

  return (
    <StyledContainer>
      <ITVTable
        columns={columns}
        data={contactDetailList}
        isRowSelect={false}
        loading={isLoading}
      />

      {isVerifyOTPModalOpen && recordSelected && (
        <VerifyOTPContractModal
          isOpen={isVerifyOTPModalOpen}
          title={
            <StyledWrapperVerifyOTPTitle>
              <StyledVerifyOTPTitle>{t('verify_otp_modal.title')}</StyledVerifyOTPTitle>
            </StyledWrapperVerifyOTPTitle>
          }
          onCancel={() => setIsVerifyOTPModalOpen(false)}
          item={recordSelected}
          fromScreen="employee"
        />
      )}
    </StyledContainer>
  );
}

import moment from 'moment';
import { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import ITVTable from 'components/ITVTable';
import PayrollCostByDepartmentFilters from './PayrollCostByDepartmentFilters';
import payrollDashboardApi from 'api/payrollDashboardApi';
import { formatCurrency } from 'features/insurances/constants/common';

const StyledContainer = styled.div`
  padding: 16px 24px;
  background-color: var(--cl_white);
  border-radius: 12px;
  border: 0.5px solid var(--cl_gray200);
  height: 100%;
`;

const StyledHeaderTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto;
  line-height: 20px;
  color: #121926;
`;

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export function PayrollCostByDepartment({ location, department }: any) {
  const { t } = useTranslation(['dashboard']);

  const [isLoading, setIsLoading] = useState(false);
  const [filterMonthYear, setFilterMonthYear] = useState({
    monthYear: moment(new Date()).clone().subtract(1, 'months').format('YYYY-MM'),
  });
  const [filterLocation, setFilterLocation] = useState(
    location && location[location?.length - 1]?.id,
  );
  const [filterDepartment, setFilterDepartment] = useState(
    department && department[department?.length - 1]?.projectCode,
  );

  const [sizePage, setSizePage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPayrollCostByDepartment, setDataPayrollCostByDepartment] = useState<any>([]);

  const handleSubmit = (values: any) => {
    if (values?.from === 'monthYear') {
      const formattedMonthYear = moment(values.monthYear).format('YYYY-MM');
      setFilterMonthYear({
        monthYear: formattedMonthYear,
      });
    }
    if (values.viewByLocation !== filterLocation) {
      setFilterLocation(values?.viewByLocation);
    }
    if (values.viewByDepartment !== filterDepartment) {
      setFilterDepartment(values?.viewByDepartment);
    }
  };

  let COLUMNS = [
    {
      title: 'Line',
      width: 150,
      minWidth: 150,
      dataIndex: 'projectPrimaryName',
      key: 'projectPrimaryName',
      align: 'left',
      render: (text: any, record: any, index: any) => {
        return text;
      },
    },
    {
      title: 'Number of Employees',
      width: 150,
      minWidth: 150,
      dataIndex: 'totalEmpolyee',
      key: 'totalEmpolyee',
      align: 'center',
      render: (text: any, record: any, index: any) => {
        return text;
      },
    },
    {
      title: 'Gross Salary',
      width: 150,
      minWidth: 150,
      dataIndex: 'totalGrossSalary',
      key: 'totalGrossSalary',
      align: 'right',
      render: (text: any, record: any, index: any) => {
        return text && formatCurrency(parseFloat(text).toFixed(2));
      },
    },
    {
      title: 'Actual take home',
      width: 150,
      minWidth: 150,
      dataIndex: 'actualReceiveSalary',
      key: 'actualReceiveSalary',
      align: 'right',
      render: (text: any, record: any, index: any) => {
        return text && formatCurrency(parseFloat(text).toFixed(2));
      },
    },
    {
      title: 'Total Cost',
      width: 150,
      minWidth: 150,
      dataIndex: 'totalSalaryCost',
      key: 'totalSalaryCost',
      align: 'right',
      render: (text: any, record: any, index: any) => {
        return text && formatCurrency(parseFloat(text).toFixed(2));
      },
    },
  ];

  useEffect(() => {
    const fetchDataPayrollDepartment = async () => {
      setIsLoading(true);
      const params = {
        period: filterMonthYear.monthYear,
        locationId: filterLocation,
        projectPrimary: filterDepartment,
      };
      const response = await payrollDashboardApi.getPayrollLine(params);
      setDataPayrollCostByDepartment(response?.data?.result);
      setIsLoading(false);
    };
    fetchDataPayrollDepartment().catch((err) => {
      setIsLoading(false);
    });
  }, [filterMonthYear, filterLocation, filterDepartment]);

  return (
    <StyledContainer>
      <StyledWrapper>
        <StyledHeaderTitle>Payroll Cost by Line</StyledHeaderTitle>
        <PayrollCostByDepartmentFilters
          initialValues={{
            monthYear: moment(new Date(filterMonthYear.monthYear)),
            viewByLocation: filterLocation,
            viewByDepartment: filterDepartment,
          }}
          loading={isLoading}
          onSubmit={handleSubmit}
          location={location}
          department={department}
        />
      </StyledWrapper>

      <ITVTable
        // loading={loading}
        columns={COLUMNS}
        data={dataPayrollCostByDepartment}
        isRowSelect={false}
        setOffset={setCurrentPage}
        offset={currentPage}
        setLimit={setSizePage}
        limit={sizePage}
        height={400}
        scroll={{ x: 100, y: 100 }}
        loading={isLoading}
      />
    </StyledContainer>
  );
}

import Icon, { FileSearchOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { PROFILE_STATUS_COLORS } from 'constants/profiles';

import ITVTable from 'components/ITVTable';

import { ContainerBgWhite } from 'styles/containerBgWhite';
import { TitleColumnCenter } from 'styles/tableStyled';
import moment from 'moment';
import { getPayslipByYear } from 'features/payroll/payrollAction';
import { selectPayroll } from 'features/payroll/payrollSlice';
import { MONTH_YEAR_FORMAT } from 'constants/commons';
import PayslipDetailModal from './PayslipDetailModal';
import {
  EmployeeHeaderContainerLeft,
  EmployeeHeaderTitle,
  EmployeeHeaderDetail,
} from 'features/employee/components/Header/styled';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

interface User {
  userId: string;
  fullName: string;
  email: string;
  employeeId: string;
  data: Payslip[];
}

interface Payslip {
  payslipId: number;
  monthYear: string;
  name: string;
}

export interface PayslipDetail {
  statusCode: number;
  message: string;
  data?: string;
}

const ViewPayslipEmployee = () => {
  const { t } = useTranslation(['employee']);
  const dispatch = useAppDispatch();
  const payslipData = useAppSelector(selectPayroll)?.payslipData;
  const loading = useAppSelector(selectPayroll)?.loadingPayslipData;
  const [year, setYear] = useState(moment());
  const [data, setData] = useState<User[]>();
  const [payslipName, setPayslipName] = useState('');
  const [payslipId, setPayslipId] = useState(undefined);
  const [isVisiblePayslipDetailModal, setIsVisiblePayslipDetailModal] = useState(false);

  useEffect(() => {
    dispatch(getPayslipByYear({ year: year.year() }));
  }, [dispatch, year]);

  useEffect(() => {
    setData(payslipData || []);
  }, [payslipData]);

  const COLUMNS: any = [
    {
      title: () => {
        return <TitleColumnCenter>{t('employee:my_payslip.col_id')}</TitleColumnCenter>;
      },
      dataIndex: 'id',
      key: 'id',
      ellipsis: true,
      width: 60,
      minWidth: 60,
      align: 'center',
      fixed: 'left',
      render: function (text: any, record: any, index: any) {
        const trueIndex = index;
        return <>{trueIndex + 1}</>;
      },
    },
    {
      title: () => {
        return <TitleColumnCenter>{t('employee:my_payslip.period')}</TitleColumnCenter>;
      },
      dataIndex: 'payPeriod',
      key: 'payPeriod',
      width: 200,
      minWidth: 200,
      ellipsis: true,
      align: 'center',
      render: function (text: any, record: any, index: any) {
        const monthYearFormatted = record?.monthYear
          ? moment(record?.monthYear, MONTH_YEAR_FORMAT).format('MMMM YYYY')
          : '';
        return <>{monthYearFormatted}</>;
      },
    },
    {
      title: () => {
        return <TitleColumnCenter>{t('employee:my_payslip.payslip')}</TitleColumnCenter>;
      },
      dataIndex: 'payslip',
      key: 'payslip',
      width: 200,
      minWidth: 200,
      ellipsis: true,
      align: 'center',
      render: function (text: any, record: any, index: any) {
        const monthYearFormatted = moment()
          .subtract(6 - index, 'months')
          .format('MMMM YYYY');
        return <>{record?.name}</>;
      },
    },
    {
      title: () => {
        return <TitleColumnCenter>{t('employee:my_payslip.action')}</TitleColumnCenter>;
      },
      key: 'action',
      ellipsis: true,
      width: 120,
      minWidth: 120,
      align: 'center',
      render: function (value: any, record: any, index: any) {
        const trueIndex = index;
        return record ? (
          <Icon
            component={() => <FileSearchOutlined style={{ fontSize: 20 }} />}
            onClick={async () => {
              setIsVisiblePayslipDetailModal(true);
              setPayslipId(record?.payslipId);
              setPayslipName(record?.name);
              // const res: PayslipDetail = await payrollApi.getDetailPayslipMonth(monthYear);
              // // setIsVisiblePayslipDetailModal(true);
              // window.open(res.data, '_blank', 'noreferrer');
            }}
          />
        ) : null;
      },
    },
  ];

  const onCloseHandler = () => {
    setIsVisiblePayslipDetailModal(false);
  };
  return (
    <>
      <Container>
        <EmployeeHeaderContainerLeft>
          <EmployeeHeaderTitle>{t('employee:my_payslip.title')}</EmployeeHeaderTitle>
          <EmployeeHeaderDetail>{t('employee:my_payslip.sub_title')}</EmployeeHeaderDetail>
        </EmployeeHeaderContainerLeft>
        <ContainerBgWhite
          style={{ marginTop: '10px', display: 'flex', width: '75%', alignSelf: 'center' }}
        >
          <ITVTable
            loading={loading}
            columns={COLUMNS}
            data={data}
            isRowSelect={false}
            height={220}
            limit={0}
          />
        </ContainerBgWhite>
      </Container>
      <PayslipDetailModal
        isVisible={isVisiblePayslipDetailModal}
        item={{ payslipId, payslipName }}
        onClose={onCloseHandler}
      />
    </>
  );
};

export default ViewPayslipEmployee;

interface StatusColumnProps {
  status: string;
}
export const StatusColumn = styled.div`
  color: ${(props: StatusColumnProps) => PROFILE_STATUS_COLORS[props.status]};
`;

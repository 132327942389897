import { store } from 'app/store';
import moment, { Moment } from 'moment';
import { ApproveRequestContractPayload, FilterRequestLineManager, SuccessResponse } from 'types';
import axiosClient from './axiosClient';

interface RequestAddParams {
  employeeIds: string[];
  convertDate: Moment;
  newProjectCode: string;
  projectDetailId: string;
}

const watchingListApi = {
  requestAdd: ({ employeeIds, convertDate, newProjectCode, projectDetailId }: RequestAddParams) => {
    const requestManagerId = store.getState().auth.user.employeeId;
    const payload = {
      dateChange: moment(convertDate).format('YYYY-MM-DD'),
      requestManagerId,
      employeeIds,
      newProjectCode,
      projectDetailId,
    };
    return axiosClient.post(`/employee/employee-add-request`, payload);
  },
  requestRemove: (employeeIds: string[], convertDate: Moment, projectDetailId: string) => {
    const requestManagerId = store.getState().auth.user.employeeId;
    const payload = {
      dateChange: moment(convertDate).format('YYYY-MM-DD'),
      requestManagerId,
      employeeIds,
      projectDetailId,
    };

    return axiosClient.post(`/employee/employee-remove-request`, payload);
  },
  getEmployeeAdd: () => {
    let userId = store.getState().auth.user.employeeId;
    const response = axiosClient.get(`/employee/employee-add-request/${userId}`);
    return response;
  },
  getEmployeeLineManager: (params: Record<string, string | number>) => {
    const response = axiosClient.get(`/employee/employee-under-line-manager`, { params });
    return response;
  },
  getEmployeeWatchingList: (params: Record<string, string | number>) => {
    const response = axiosClient.get(`/employee/employee-watching-list`, { params });
    return response;
  },
  searchWatchingListData: (params: Record<string, string | number>) => {
    const response = axiosClient.post('/employee/search-employee-watching-list', {
      search: { name: params.keyword, limit: params.limit || 10, offset: params.offset || 1 },
      managerId: params.managerId,
    });
    return response;
  },
  searchListRequest: (params: any) => {
    const response = axiosClient.post(
      `/request-change-manager${params.employeeId && '/' + params.employeeId}/search`,
      {},
    );
    return response;
  },
  cancelRequestManager: (ids: Array<number>, currentProjectId: string) => {
    const response = axiosClient.put('/request-change-manager/cancel/', { ids, currentProjectId });
    return response;
  },
  approveRequestManager: (
    ids: Array<number>,
    dayToChangeLine: Moment | null,
    currentProjectId: string,
  ) => {
    return axiosClient.put('/request-change-manager/approve/', {
      ids,
      date: dayToChangeLine?.format('YYYY-MM-DD'),
      currentProjectId,
    });
  },
  rejectRequestManager: (ids: Array<number>, reasonReject: string, currentProjectId: string) => {
    return axiosClient.put('/request-change-manager/reject/', {
      ids,
      reasonReject,
      currentProjectId,
    });
  },
  adjustLineManager: (body: any) => {
    const response = axiosClient.post('/request-change-manager/adjust-line-manager/add', {
      ...body,
    });
    return response;
  },
  removeLineManager: (body: any) => {
    const response = axiosClient.post('/request-change-manager/adjust-line-manager/remove', {
      ...body,
    });
    return response;
  },
  approveRequestChangeLineByEmail(
    payload: ApproveRequestContractPayload,
  ): Promise<SuccessResponse<{ message: string; statusCode: number }>> {
    return axiosClient.post('/request-change-manager/solve-request-by-token', payload);
  },
  getListRequestHistoryLineManagement(
    payload: FilterRequestLineManager,
  ): Promise<SuccessResponse<{ message: string; statusCode: number }>> {
    return axiosClient.post('/request-change-manager/history/search-v2', payload);
  },
  getListRequestHistoryLineManagementByManager(payload: any) {
    return axiosClient.post(
      `/request-change-manager/history/${payload.employeeId || ''}/search`,
      payload.filtersHistory,
    );
  },
};

export default watchingListApi;

import debounce from 'lodash/debounce';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';

import { InputField, PasswordField, TextAreaField } from 'components/FormFields';
import { useEffect } from 'react';

export interface Password {
  password: string;
}
const StyledForm = styled.form`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 8px;
`;

const StyledWrapperReason = styled.div`
  width: 100%;
`;

interface PasswordFormProps {
  onSubmit?: (payload: Password) => void;
  error?: string;
}

export function PasswordForm({ onSubmit, error }: PasswordFormProps) {
  const { t } = useTranslation(['common', 'auth']);

  const schema = yup.object().shape({
    password: yup.string().required(t('auth:get_started.confirm_password_placeholder')),
  });

  const { control, handleSubmit, setError, clearErrors } = useForm<Password>({
    defaultValues: {
      password: '',
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    clearErrors();
    if (error) {
      setError('password', {
        type: 'manual',
        message: error,
      });
    }
  }, [error]);

  const handleFormSubmit = (formValues: Password) => {
    onSubmit?.(formValues);
  };

  return (
    <StyledForm onSubmit={handleSubmit(handleFormSubmit)}>
      <StyledWrapperReason>
        <PasswordField
          style={{ width: 300 }}
          name="password"
          label={t('auth:get_started.password_placeholder')}
          control={control}
          placeholder={t('auth:get_started.password_placeholder')}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSubmit(handleFormSubmit);
            }
          }}
        />
      </StyledWrapperReason>
    </StyledForm>
  );
}

import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  DeleteTwoTone,
  EditOutlined,
  EyeTwoTone,
  FileTextTwoTone,
  MinusCircleTwoTone,
  SafetyCertificateTwoTone,
} from '@ant-design/icons';
import { Menu, Popconfirm, notification } from 'antd';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';

import {
  PERMISSION_PERSONNEL_RECORD_ACTIVE_DEACTIVE,
  PERMISSION_PERSONNEL_RECORD_APPROVAL,
  PERMISSION_PERSONNEL_RECORD_REQUEST_APPROVAL,
} from 'constants/permissions';
import {
  PROFILE_APPROVED,
  PROFILE_DEACTIVE,
  PROFILE_DENIED,
  PROFILE_DRAFT,
  PROFILE_PENDING,
} from 'constants/profiles';

import { useAppSelector } from 'app/hooks';
import { logout, selectUser } from 'features/auth/authSlice';
import { actions, selectEmployee } from 'features/employee/employeeSlice';

import authApi from 'api/authApi';
import { notificationToast } from 'components/notificationToast';
import { TIMEZONE_DEFAULT } from 'constants/commons';
import { Notification } from 'constants/notification';
import {
  activeDeactiveEmployee,
  approveEmployee,
  deleteEmployee,
  getEmployeeDetail,
  requestApproveEmployee,
} from 'features/employee/employeeAction';
import EmployeeUpdateModal from 'features/employee/pages/EmployeeUpdateModal';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { getUser } from 'utils/auth';
import { transMomentTimezone } from 'utils/date';
import EmployeeDetailModal from '../pages/EmployeeDetailModal';
import RejectFormModal from './RejectFormModal';
import employeeApi from 'api/employeeApi';

const MenuActions = (props: any) => {
  const timeZone = getUser()?.timeZone ? getUser()?.timeZone : TIMEZONE_DEFAULT;

  //Set default timezone in server
  moment.tz.setDefault(timeZone);
  const { isShowFullMenu, item, permissions, handleHideMenuActions, t, paramSortFilter } = props;
  const { setErrorMessage } = actions;
  const dispatch = useDispatch();
  const userLogin = useAppSelector(selectUser);
  const stateEmployee = useAppSelector(selectEmployee);
  const error = stateEmployee?.error;
  const [isVisibleRejectForm, setIsVisibleRejectForm] = useState(false);
  const [isVisibleEmployeeDetailModal, setIsVisibleEmployeeDetailModal] = useState(false);

  useEffect(() => {
    const deactiveNotSuccess = error?.fieldErrors?.deactiveEmployee;
    if (deactiveNotSuccess) {
      notification.destroy();
      const errorList = JSON.parse(deactiveNotSuccess);
      notificationToast(
        Notification.Type.Warning,
        <>
          {errorList?.flat(1)?.map((item: any, i: any, arr: any) => {
            let divider = i < arr.length - 1 && <div></div>;
            return (
              <span key={i}>
                <div>{item?.payrollModule}</div>
                <ul style={{ listStylePosition: 'inside' }}>
                  {item?.message?.map((childItem: any) => (
                    <li style={{ listStyleType: 'disc' }}>{childItem}</li>
                  ))}
                </ul>
                {divider}
              </span>
            );
          })}
        </>,
        Notification.Duration._10s,
      );
    }
    return () => {
      dispatch(setErrorMessage(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const handleOpenRejectForm = () => {
    setIsVisibleRejectForm(true);
  };

  const handleCloseRejectForm = () => {
    setIsVisibleRejectForm(false);
  };

  const [isVisibleEmployeeUpdate, setIsVisibleEmployeeUpdate] = useState(false);

  const handleOpenEmployeeUpdate = () => {
    setIsVisibleEmployeeUpdate(true);
  };

  const handleCloseEmployeeUpdate = () => {
    setIsVisibleEmployeeUpdate(false);
  };

  useEffect(() => {
    // Logout if deactive myself
    if (stateEmployee?.isDeactiveMySelf) {
      authApi.logout(getUser()?.id);
      dispatch(logout());
      localStorage.removeItem('current_date_choose');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateEmployee?.isDeactiveMySelf]);

  const handleViewProfile = () => {
    handleHideMenuActions();
    dispatch(getEmployeeDetail({ idItem: item.id }));
    handleOpenEmployeeUpdate();
  };

  const handleViewDetailEmployee = () => {
    handleHideMenuActions();
    setIsVisibleEmployeeDetailModal(true);
  };

  const handleCloseDetailEmployee = () => {
    setIsVisibleEmployeeDetailModal(false);
  };

  const handleSendRequestApproval = () => {
    handleHideMenuActions();
    dispatch(
      requestApproveEmployee({
        dataRequest: {
          idProfile: item.id,
          idUserCreate: userLogin?.id,
          messageRequest: '',
        },
        paramSortFilter,
      }),
    );
  };

  const handleApprove = () => {
    handleHideMenuActions();
    dispatch(
      approveEmployee({
        dataRequest: {
          idProfile: item.id,
          idUserCreate: userLogin?.id,
          messageRequest: '',
          isApproved: true,
        },
        paramSortFilter,
      }),
    );
  };

  const handleShowRejectForm = () => {
    handleHideMenuActions();
    handleOpenRejectForm();
  };

  const handleReject = () => {
    handleHideMenuActions();
    handleShowRejectForm();
  };

  const handleActiveUser = async (_IsActive: boolean) => {
    handleHideMenuActions();
    if (_IsActive) {
      dispatch(
        activeDeactiveEmployee({
          dataRequest: {
            idProfile: item.id,
            idUserCreate: userLogin?.id,
            messageRequest: '',
            endWorkDate: transMomentTimezone().utc().toISOString(),
            isActive: _IsActive,
          },
          paramSortFilter,
        }),
      );
    } else {
      try {
        const response = await employeeApi.alertDeactiveEmployee({ userId: item.id });
        if (!response.data.canDeactivate) {
          notificationToast(
            Notification.Type.Warning,
            response.data.message,
            Notification.Duration._3s,
          );
          return;
        }

        dispatch(
          activeDeactiveEmployee({
            dataRequest: {
              idProfile: item.id,
              idUserCreate: userLogin?.id,
              messageRequest: '',
              endWorkDate: transMomentTimezone().utc().toISOString(),
              isActive: _IsActive,
            },
            paramSortFilter,
          }),
        );
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleDelete = () => {
    handleHideMenuActions();
    dispatch(
      deleteEmployee({
        idItem: item.id,
        employeeList: stateEmployee.employeeList,
        paramSortFilter,
      }),
    );
  };

  return (
    <>
      <Menu>
        <Menu.Item icon={<EyeTwoTone />} key="7" onClick={handleViewDetailEmployee}>
          {t('employee:employee_manage.view_profile')}
        </Menu.Item>
        <Menu.Item onClick={handleViewProfile} icon={<StyledEditOutlined />} key="0">
          Edit
        </Menu.Item>

        {(item.status === PROFILE_DRAFT || item.status === PROFILE_DENIED) &&
          permissions?.indexOf(PERMISSION_PERSONNEL_RECORD_REQUEST_APPROVAL) >= 0 && (
            <Menu.Item
              onClick={() => handleSendRequestApproval()}
              icon={<FileTextTwoTone twoToneColor="#FFF300" />}
              key="1"
            >
              {t('employee:employee_manage.send_request')}
            </Menu.Item>
          )}
        {(item?.status === PROFILE_DRAFT ||
          item?.status === PROFILE_DENIED ||
          item?.status === PROFILE_PENDING) &&
          permissions?.indexOf(PERMISSION_PERSONNEL_RECORD_APPROVAL) >= 0 && (
            <Menu.Item
              onClick={() => handleApprove()}
              icon={<CheckCircleTwoTone twoToneColor="#52c41a" />}
              key="2"
            >
              {t('employee:employee_manage.approve')}
            </Menu.Item>
          )}
        {(item.status === PROFILE_DRAFT || item.status === PROFILE_PENDING) &&
          permissions?.indexOf(PERMISSION_PERSONNEL_RECORD_APPROVAL) >= 0 && (
            <Menu.Item
              onClick={() => handleReject()}
              icon={<MinusCircleTwoTone twoToneColor="#FF0C00" />}
              key="3"
            >
              {t('employee:employee_manage.reject')}
            </Menu.Item>
          )}
        {item.status === PROFILE_DRAFT &&
          (permissions?.indexOf(PERMISSION_PERSONNEL_RECORD_APPROVAL) >= 0 ||
            permissions?.indexOf(PERMISSION_PERSONNEL_RECORD_REQUEST_APPROVAL)) && (
            <Menu.Item icon={<DeleteTwoTone twoToneColor="#B7B7B7" />} key="4">
              <Popconfirm
                key="4"
                title={t('onboard:are_you_sure_to_delete_this')}
                onConfirm={handleDelete}
                okText={t('timesheet:Yes')}
                cancelText={t('timesheet:No')}
                placement="topRight"
              >
                <a href=" ">{t('employee:employee_manage.delete')}</a>
              </Popconfirm>
            </Menu.Item>
          )}
        {item.status === PROFILE_APPROVED &&
          permissions?.indexOf(PERMISSION_PERSONNEL_RECORD_ACTIVE_DEACTIVE) >= 0 && (
            <Menu.Item
              onClick={() => handleActiveUser(false)}
              icon={<CloseCircleTwoTone twoToneColor="#C100FF " />}
              key="5"
            >
              {t('employee:employee_manage.deactive')}
            </Menu.Item>
          )}
        {item.status === PROFILE_DEACTIVE &&
          permissions?.indexOf(PERMISSION_PERSONNEL_RECORD_ACTIVE_DEACTIVE) >= 0 && (
            <Menu.Item
              onClick={() => handleActiveUser(true)}
              icon={<SafetyCertificateTwoTone />}
              key="6"
            >
              {t('employee:employee_manage.active')}
            </Menu.Item>
          )}
      </Menu>
      <EmployeeUpdateModal
        isVisible={isVisibleEmployeeUpdate}
        isShowFullMenu={isShowFullMenu}
        itemId={item.id}
        status={item.status}
        onClose={handleCloseEmployeeUpdate}
        paramSortFilter={paramSortFilter}
      />
      <RejectFormModal
        isVisible={isVisibleRejectForm}
        itemId={item.id}
        onClose={handleCloseRejectForm}
        paramSortFilter={paramSortFilter}
      />
      <EmployeeDetailModal
        isVisible={isVisibleEmployeeDetailModal}
        item={item}
        onClose={handleCloseDetailEmployee}
      />
    </>
  );
};

export default MenuActions;

export const IconImage = styled.img`
  padding-bottom: 6px;
  width: 20px;
  height: 20px;
`;
const StyledEditOutlined = styled(EditOutlined)`
  color: var(--cl_primary500);
  cursor: pointer;
`;

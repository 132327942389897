import styled from 'styled-components';

export const RowStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  &.bettween {
    justify-content: space-between;
  }
`;

export const LagerLofoStyled = styled.img`
  height: 2rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-bottom: 1rem;
  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

export const VideoStyled = styled.iframe`
  width: 520px;
  height: 297px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  @media only screen and (max-width: 600px) {
    width: 100%;
    height: 219px;
  }
`;

import { Image } from 'antd';
import { useAppDispatch } from 'app/hooks';
import completeForm from 'assets/images/completeForm.png';
import useTemplate from 'assets/images/useTemplate.png';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import CompleteForm from './CompleteForm';
import UseTemplate from './UseTemplate';
import { getJobHirringProcess } from 'features/recruitment/recruitmentAction';
import { useTranslation } from 'react-i18next';

export enum VIEW_SCREEN {
  CREATE_JOB = 'CREATE_JOB',
  COMPLETE_A_FORM = 'COMPLETE_A_FORM',
  USE_A_TEMPLATE = 'USE_A_TEMPLATE',
}
const CreateJob = ({ setCurrentScreen, setVisible }) => {
  const { t } = useTranslation(['recruitment']);
  const dispatch = useAppDispatch();
  const [viewScreen, setViewScreen] = useState<VIEW_SCREEN>(VIEW_SCREEN.CREATE_JOB);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    dispatch(getJobHirringProcess({ type: 'Hiring process' }));
  }, [dispatch]);

  const createJob = (form: any) => {
    history.push({ pathname: `/recruitment/jobs/detail/draft`, state: form });
    setVisible(false);
    // switch (viewScreen) {
    //   case VIEW_SCREEN.COMPLETE_A_FORM:
    //     const { from, to } = form?.date;
    //     body = {
    //       ...form,
    //       openDate: from ? +moment(form) : null,
    //       closeDate: from ? +moment(to) : null,
    //     };
    //     return;
    //   case VIEW_SCREEN.USE_A_TEMPLATE:
    //     body = {
    //       ...form,
    //     };
    //     history.push({ pathname: `/recruitment/jobs/detail/draft`, state: form });
    //     setVisible(false);
    //     // history.push({ pathname: `/recruitment/jobs/detail/draft`, state: form });
    //     // dispatch(createJobTemplate({body}))
    //     return;
    // }
  };

  const renderScreen = (screen: VIEW_SCREEN) => {
    setCurrentScreen && setCurrentScreen(screen);
    switch (screen) {
      case VIEW_SCREEN.COMPLETE_A_FORM:
        return (
          <CompleteForm
            setViewScreen={setViewScreen}
            setVisible={setVisible}
            callBack={createJob}
          />
        );
      case VIEW_SCREEN.USE_A_TEMPLATE:
        return (
          <UseTemplate setViewScreen={setViewScreen} setVisible={setVisible} callBack={createJob} />
        );

      default:
        return (
          <div>
            <div style={{ fontWeight: 'bold', fontSize: 20, marginBottom: 20 }}>
              {t('recruitment:create_job')}
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                textAlign: 'center',
                fontWeight: 'bold',
                gap: 8,
              }}
            >
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => setViewScreen(VIEW_SCREEN.COMPLETE_A_FORM)}
              >
                <Image preview={false} src={completeForm} />
                {t('recruitment:complete_a_form')}
              </div>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => setViewScreen(VIEW_SCREEN.USE_A_TEMPLATE)}
              >
                <Image preview={false} src={useTemplate} />
                {t('recruitment:use_a_template')}
              </div>
            </div>
          </div>
        );
    }
  };

  return <>{renderScreen(viewScreen)}</>;
};

export default CreateJob;

import { useAppDispatch, useAppSelector } from 'app/hooks';
import MenuAction from 'components/MenuAction';
import PageHeader from 'components/PageHeader';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { selectConfiguration } from 'features/configuration/configurationSlice';
import ITVTable from 'components/ITVTable';
import CustomButton from 'styles/buttonStyled';
import { CaretRightOutlined, EditOutlined, ExportOutlined, PlusOutlined } from '@ant-design/icons';
import { selectTrainingData } from '../trainingSlice';
import { YYYY_MM_DD } from 'constants/commons';
import moment from 'moment';
import { exportTraining, getTrainingList, notifyTraining } from '../trainingAction';
import ITVSearch from 'components/ITVSearch';
import { useTranslation } from 'react-i18next';
import { TitleColumnCenter } from 'styles/tableStyled';

const CourseScreen = ({ setCreated }) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const history = useHistory();

  const { t } = useTranslation(['trainningManagement', 'action']);
  const menuStore = useAppSelector(selectConfiguration);
  const trainData = useAppSelector(selectTrainingData);
  const listTraining = trainData?.trainingList?.result;
  const dataScreenManage = menuStore?.dataScreenManage?.result;
  const [titleHeader, setTitleHeader] = useState('');
  const [data, setData] = useState([]);
  const [items, setItems] = useState([]);

  useEffect(() => {
    const temp = dataScreenManage
      ?.filter((item) => location.pathname === item.path)
      ?.map((item) => item.nameScreen);

    setTitleHeader(temp ? temp[0] : '');
  }, [dataScreenManage, location.pathname]);

  useEffect(() => {
    dispatch(getTrainingList({}));
  }, [dispatch]);

  useEffect(() => {
    setData(listTraining || []);
  }, [listTraining]);

  const menu = [
    {
      name: t('action:edit'),
      icon: <EditOutlined style={{ color: 'red' }} />,
      handleClick: (data: any) => {
        history.push(`/training/course-management/${data?.id}`);
      },
    },
  ];
  const columns = [
    {
      title: () => {
        return (
          <TitleColumnCenter>
            {t('trainningManagement:course_management.course_id')}
          </TitleColumnCenter>
        );
      },
      key: 'courseTextId',
      dataIndex: 'courseTextId',
      width: 80,
      minWidth: 80,
      align: 'center',
      render: (text, record, index) => {
        return text;
      },
    },
    {
      title: () => {
        return (
          <TitleColumnCenter>
            {t('trainningManagement:course_management.course_name')}
          </TitleColumnCenter>
        );
      },
      key: 'courseName',
      dataIndex: 'courseName',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (text) => text,
    },
    {
      title: () => {
        return (
          <TitleColumnCenter>
            {t('trainningManagement:course_management.course_category')}
          </TitleColumnCenter>
        );
      },
      key: 'categoryName',
      dataIndex: 'categoryName',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (text) => {
        return text;
      },
    },
    {
      title: () => {
        return (
          <TitleColumnCenter>
            {t('trainningManagement:course_management.description')}
          </TitleColumnCenter>
        );
      },
      key: 'description',
      dataIndex: 'description',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (text) => {
        return text;
      },
    },
    {
      title: () => {
        return (
          <TitleColumnCenter>
            {t('trainningManagement:course_management.budget_amount')}
          </TitleColumnCenter>
        );
      },
      key: 'budget',
      dataIndex: 'budget',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (text) => {
        return text;
      },
    },
    {
      title: () => {
        return (
          <TitleColumnCenter>
            {t('trainningManagement:course_management.actual_amount')}
          </TitleColumnCenter>
        );
      },
      key: 'actualAmount',
      dataIndex: 'actualAmount',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (text) => {
        return text;
      },
    },
    {
      title: () => {
        return (
          <TitleColumnCenter>
            {t('trainningManagement:course_management.training_date')}
          </TitleColumnCenter>
        );
      },
      key: 'trainDate',
      dataIndex: 'trainDate',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (_: any, record: any) => {
        const dateFrom = record?.dateFrom ? moment(record?.dateFrom).format(YYYY_MM_DD) : '';
        const dateTo = record?.dateTo ? moment(record?.dateTo).format(YYYY_MM_DD) : '';
        return `${dateFrom} - ${dateTo}`;
      },
    },
    {
      title: () => {
        return (
          <TitleColumnCenter>
            {t('trainningManagement:course_management.trainer')}
          </TitleColumnCenter>
        );
      },
      key: 'trainerName',
      dataIndex: 'trainerName',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (text) => {
        return text;
      },
    },
    {
      title: () => {
        return (
          <TitleColumnCenter>{t('trainningManagement:course_management.status')}</TitleColumnCenter>
        );
      },
      key: 'statusText',
      dataIndex: 'statusText',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (text) => {
        return text;
      },
    },
    {
      title: () => {
        return (
          <TitleColumnCenter>{t('trainningManagement:course_management.action')}</TitleColumnCenter>
        );
      },
      key: 'action',
      width: 80,
      minWidth: 80,
      align: 'center',
      fixed: 'right',
      render: (text, record) => {
        return <MenuAction menu={menu} data={record} />;
      },
    },
  ];

  const search = (key: string) => {
    const text = key.toLowerCase();
    if (text) {
      const temp = listTraining.filter((item: any) => {
        return item?.courseName?.toString().toLowerCase().includes(text);
      });
      setData(temp);
    } else {
      setData(listTraining);
    }
  };

  const handleExport = () => {
    dispatch(exportTraining({}));
  };

  const handleNotify = () => {
    dispatch(notifyTraining({ courseIds: items }));
  };

  return (
    <div>
      <PageHeader title={titleHeader} />
      <div>
        <CustomButton
          style={{ marginBottom: 16 }}
          icon={<PlusOutlined />}
          onClick={() => {
            setCreated && setCreated(true);
          }}
        >
          {t('trainningManagement:course_management.create_a_new_course')}
        </CustomButton>
      </div>
      <div style={{ display: 'flex', gap: 8 }}>
        <ITVSearch style={{ marginBottom: 16 }} handleSearch={search} />
        <CustomButton icon={<ExportOutlined />} loading={false} onClick={handleExport}>
          {t('trainningManagement:course_management.export')}
        </CustomButton>
        <CustomButton icon={<CaretRightOutlined />} loading={false} onClick={handleNotify}>
          {t('trainningManagement:course_management.notify_to_assigned_trainees')}
        </CustomButton>
      </div>
      <ITVTable setSelectedUser={setItems} data={data} columns={columns} isRowSelect={true} />
    </div>
  );
};

export default CourseScreen;

import { createAsyncThunk } from '@reduxjs/toolkit';
import watchingListApi from 'api/watchingListApi';
import { setGlobalLoading } from 'app/commonRedux/appSlice';
import { notificationToast } from 'components/notificationToast';
import { Notification } from 'constants/notification';
import { Moment } from 'moment';

export const watchingListGetEmployeeAdd = createAsyncThunk(
  'watchingList/getListEmployeeAdd',
  async ({ callback }: { callback: (watchingListData: any) => void }, thunkApi: any) => {
    try {
      const watchingList: any = await watchingListApi.getEmployeeAdd();
      callback && callback(watchingList?.data?.result || []);
      return watchingList.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const watchingListAdd = createAsyncThunk(
  'watchingList/addEmployee',
  async (
    params: {
      employeeIds: string[];
      convertDate: Moment;
      employeeIdOfUser: string;
      newProjectCode: string;
      projectDetailId: string;
      callback: () => void;
    },
    thunkApi: any,
  ) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await watchingListApi.requestAdd({
        employeeIds: params.employeeIds,
        convertDate: params.convertDate,
        newProjectCode: params.newProjectCode,
        projectDetailId: params.projectDetailId,
      });

      thunkApi.dispatch(searchListRequest({ employeeId: params.employeeIdOfUser }));
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      if (response?.statusCode === 400) {
        notificationToast(Notification.Type.Error, response?.message, Notification.Duration._3s);
        throw Error(response?.message);
      } else {
        notificationToast(Notification.Type.Success, response?.message, Notification.Duration._3s);
      }
      params.callback && params.callback();
      return { data: response.data };
    } catch (error) {
      thunkApi.dispatch(searchListRequest({ employeeId: params.employeeIdOfUser }));
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const searchListRequest = createAsyncThunk(
  'watchingList/searchListRequest',
  async (params: any, thunkApi: any) => {
    try {
      const response: any = await watchingListApi.searchListRequest(params);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const cancelRequestManager = createAsyncThunk(
  'watchingList/cancelRequestManager',
  async (
    params: { ids: Array<number>; employeeIdOfUser: string; currentProjectId: string },
    thunkApi: any,
  ) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await watchingListApi.cancelRequestManager(
        params.ids,
        params.currentProjectId,
      );
      const newAllListRequest: any = await watchingListApi.searchListRequest({
        employeeId: params.employeeIdOfUser,
      });
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      notificationToast(Notification.Type.Success, response?.message, Notification.Duration._3s);
      return { response, newAllListRequest: newAllListRequest?.data };
    } catch (error) {
      const newAllListRequest: any = await watchingListApi.searchListRequest({
        employeeId: params.employeeIdOfUser,
      });
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return {
        response: thunkApi.rejectWithValue(error),
        newAllListRequest: newAllListRequest?.data,
      };
    }
  },
);
export const approveRequestManager = createAsyncThunk(
  'watchingList/approveRequestManager',
  async (
    params: { ids: Array<number>; dayToChangeLine: Moment | null; currentProjectId: string },
    thunkApi: any,
  ) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await watchingListApi.approveRequestManager(
        params.ids,
        params.dayToChangeLine,
        params.currentProjectId,
      );
      const newAllListRequest: any = await watchingListApi.searchListRequest({ employeeId: '' });
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      notificationToast(Notification.Type.Success, response?.message, Notification.Duration._3s);
      return { response, newAllListRequest: newAllListRequest?.data };
    } catch (error) {
      const newAllListRequest: any = await watchingListApi.searchListRequest({ employeeId: '' });
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return {
        response: thunkApi.rejectWithValue(error),
        newAllListRequest: newAllListRequest?.data,
      };
    }
  },
);
export const rejectRequestManager = createAsyncThunk(
  'watchingList/rejectRequestManager',
  async (
    params: { ids: Array<number>; reasonReject: string; currentProjectId: string },
    thunkApi: any,
  ) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await watchingListApi.rejectRequestManager(
        params.ids,
        params.reasonReject,
        params.currentProjectId,
      );
      const newAllListRequest: any = await watchingListApi.searchListRequest({
        employeeId: '',
      });
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      notificationToast(Notification.Type.Success, response?.message, Notification.Duration._3s);
      return { response, newAllListRequest: newAllListRequest?.data };
    } catch (error) {
      const newAllListRequest: any = await watchingListApi.searchListRequest({ employeeId: '' });
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return {
        response: thunkApi.rejectWithValue(error),
        newAllListRequest: newAllListRequest?.data,
      };
    }
  },
);

export const watchingListRemove = createAsyncThunk(
  'watchingList/removeEmployee',
  async (
    params: {
      employeeIds: string[];
      convertDate: Moment;
      employeeIdOfUser: string;
      projectDetailId: string;
      callback: () => void;
    },
    thunkApi: any,
  ) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await watchingListApi.requestRemove(
        params.employeeIds,
        params.convertDate,
        params.projectDetailId,
      );
      thunkApi.dispatch(searchListRequest({ employeeId: params.employeeIdOfUser }));
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      if (response?.statusCode === 400) {
        notificationToast(Notification.Type.Error, response?.message, Notification.Duration._3s);
        throw Error(response?.message);
      } else {
        notificationToast(Notification.Type.Success, response?.message, Notification.Duration._3s);
      }
      params.callback && params.callback();
      return { data: response.data };
    } catch (error) {
      thunkApi.dispatch(searchListRequest({ employeeId: params.employeeIdOfUser }));
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getEmployeeInlineManager = createAsyncThunk(
  'watchingList/getEmployeeLineManager',
  async (
    {
      paramsQuery,
      callChild = false,
    }: {
      paramsQuery: Record<string, string | number>;
      callChild?: boolean;
    },
    thunkApi: any,
  ) => {
    try {
      const watchingList: any = await watchingListApi.getEmployeeLineManager(paramsQuery);
      return { ...watchingList.data, callChild, managerId: paramsQuery.employeeId };
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getEmployeeWatchingList = createAsyncThunk(
  'watchingList/getEmployeeWatchingList',
  async (
    {
      paramsQuery,
      callChild = false,
      callback,
    }: { paramsQuery: Record<string, string | number>; callChild?: boolean; callback?: () => void },
    thunkApi: any,
  ) => {
    try {
      const watchingList: any = await watchingListApi.getEmployeeWatchingList(paramsQuery);

      callback && callback();

      return { ...watchingList.data, callChild, managerId: paramsQuery.employeeId };
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

import { IconProps } from 'types';

export const OffboardIcon = ({ width = '24px', height = '24px', className }: IconProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.2041 17.184H15.2041"
      stroke="#121926"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.6512 9.37933C11.568 9.37101 11.4682 9.37101 11.3766 9.37933C9.39662 9.31278 7.82425 7.69049 7.82425 5.69383C7.81593 3.65557 9.4715 2 11.5098 2C13.548 2 15.2036 3.65557 15.2036 5.69383C15.2036 7.69049 13.6229 9.31278 11.6512 9.37933Z"
      stroke="#121926"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.7053 13.0043C12.4271 12.021 9.33114 12.1947 7.34201 13.5256C5.32871 14.8651 5.32871 17.0614 7.34201 18.4091C8.35995 19.0878 9.65913 19.4656 10.996 19.5425"
      stroke="#121926"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import { selectFormatDatetime } from 'app/commonRedux/appSlice';
import { useAppSelector } from 'app/hooks';
import EmployeeColumn from 'components/EmployeeColumn';
import { StatusColumn } from 'components/EmployeeList';
import ITVSearch from 'components/ITVSearch';
import ITVTable from 'components/ITVTable';
import { PROFILE_STATUSES } from 'constants/profiles';
import { getHistoryPayRuns } from 'features/payroll/payrollAction';
import { selectPayroll } from 'features/payroll/payrollSlice';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ContainerBgWhite } from 'styles/containerBgWhite';
import { removeAccents } from 'utils/text';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: max-content;
`;

export const History = (props: any) => {
  const { year } = props;
  const dispatch = useDispatch();
  const [sizePage, setSizePage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const { t } = useTranslation(['menu', 'action', 'overtime', 'timesheet', 'payroll']);
  const payRunHistoryData = useAppSelector(selectPayroll)?.payRunHistoryData?.result;
  const loadingPayRunHistoryData = useAppSelector(selectPayroll)?.loadingPayRunHistoryData;
  const [data, setData] = useState([]); //dataMockPayRun
  const formatDatetime = useAppSelector(selectFormatDatetime);

  useEffect(() => {
    const params = {
      year: year ? moment(year).format('YYYY') : '',
    };
    dispatch(getHistoryPayRuns(params));
  }, [year, dispatch]);

  useEffect(() => {
    if (payRunHistoryData) setData(payRunHistoryData);
  }, [payRunHistoryData]);

  const COLUMNS: any = [
    {
      title: t('payroll:addNewPayRun'),
      dataIndex: 'name',
      key: 'name',
      width: 100,
      minWidth: 100,
      ellipsis: true,
      align: 'left',
    },
    {
      title: t('payroll:payPeriod'),
      dataIndex: 'period',
      key: 'period',
      align: 'left',
      width: 60,
      minWidth: 60,
    },
    {
      title: t('payroll:description'),
      dataIndex: 'description',
      key: 'description',
      width: 100,
      minWidth: 100,
      ellipsis: true,
      align: 'left',
    },
    {
      title: t('payroll:status'),
      dataIndex: 'status',
      key: 'status',
      align: 'left',
      width: 60,
      minWidth: 60,
      render: function (text: any, record: any, index: any) {
        return (
          <StatusColumn status={record?.deleteFlag ? 'deleted' : 'active'}>
            • {PROFILE_STATUSES[record?.deleteFlag ? 'deleted' : 'active']}
          </StatusColumn>
        );
      },
    },
    {
      title: t('payroll:modified_time'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      align: 'left',
      width: 100,
      minWidth: 100,
      render: function (text: any, record: any, index: any) {
        const obj: any = {
          children: text ? moment(text).format(formatDatetime) : null,
          props: {
            style: {},
          },
        };
        return obj;
      },
    },
    {
      title: t('payroll:modified_by'),
      dataIndex: 'updatedBy',
      key: 'updatedBy',
      align: 'left',
      width: 100,
      minWidth: 100,
      render: function (text: any, record: any, index: any) {
        return (
          <EmployeeColumn
            avatar={
              record?.updatedBy?.fullName
                ? `${record?.updatedBy?.fullName}`
                : `${record?.updatedBy?.lastName} ${record?.updatedBy?.firstName}`
            }
            fullName={
              record?.updatedBy?.fullName
                ? `${record?.updatedBy?.fullName}`
                : `${record?.updatedBy?.lastName} ${record?.updatedBy?.firstName}`
            }
            email={record?.updatedBy?.email}
          />
        );
      },
    },
  ];

  const handleSearch = (value: string) => {
    const text = value && removeAccents(value.toLowerCase());
    if (text) {
      const temp = payRunHistoryData?.filter((item: any) => {
        return item?.name && removeAccents(item?.name?.toLowerCase()).includes(text);
      });
      setData(temp);
    } else {
      setData(payRunHistoryData);
    }
  };

  return (
    <Container>
      <ContainerBgWhite>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
          <ITVSearch handleSearch={handleSearch} />
        </div>
        <ITVTable
          loading={loadingPayRunHistoryData}
          isRowSelect={false}
          columns={COLUMNS}
          data={data}
          width={1080}
          height={550}
          setOffset={setCurrentPage}
          offset={currentPage}
          setLimit={setSizePage}
          limit={sizePage}
        />
      </ContainerBgWhite>
    </Container>
  );
};

import { notification } from 'antd';
import styled from 'styled-components';
import { ReactComponent as ErrorIcon } from 'assets/icons/error.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';
import { ReactComponent as SuccessIcon } from 'assets/icons/success.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import 'styles/message.scss';
import { ReactNode } from 'react';
import i18n from 'i18next';
import { capitalizeFirstLetter } from 'utils/text';

type TMessage = 'success' | 'error' | 'warning' | 'info';

const commonStyledIcon = <T,>(Component: React.ComponentType<T>) => {
  return styled(Component)`
    fill: white;
    transform: scale(1.1);
  `;
};

const ErrorIconStyled = commonStyledIcon(ErrorIcon);

const SuccessIconStyled = commonStyledIcon(SuccessIcon);

const WarningIconStyled = commonStyledIcon(WarningIcon);

const InfoIconStyled = commonStyledIcon(InfoIcon);

const componentType: Record<TMessage | any, ReactNode> = {
  error: <ErrorIconStyled />,
  success: <SuccessIconStyled />,
  warning: <WarningIconStyled />,
  info: <InfoIconStyled />,
};

const colorType: Record<TMessage | any, string> = {
  success: 'var(--cl_success_notify100)',
  error: 'var(--cl_error500)',
  warning: 'var(--cl_warning400)',
  info: 'var(--cl_info500)',
};

const BackgroundIconStyled = styled.span<{ type: TMessage | any }>`
  background-color: ${(props) => colorType[props.type]};
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const notificationToast = (type: TMessage | any, message: any, duration: number) =>
  notification.open({
    message: capitalizeFirstLetter(i18n.t(`common:${type}`)),
    description: message,
    duration,
    icon: <BackgroundIconStyled type={type}>{componentType[type]}</BackgroundIconStyled>,
    className: 'disable-color-message-text',
    style: {
      borderRadius: '5px',
      borderLeft: `5px solid ${colorType[type]}`,
      padding: '6px 10px 11px',
      color: colorType[type].replace(
        /[0-9]+/g,
        Number(
          colorType[type].slice(
            colorType[type].search(/[0-9]/g),
            colorType[type].search(/[0-9]$/g),
          ),
        ) +
          100 +
          '',
      ),
    },
  });

import axiosClient from './axiosClient';
import queryString from 'query-string';

const roleApi = {
  getList: (params: any) => {
    const response = axiosClient.get(`/roles?${queryString.stringify(params)}`);
    return response;
  },
};

export default roleApi;

import React, { useState, useEffect } from 'react';
import { Col, Form, Modal, Row, Tooltip, Upload } from 'antd';
import { useTranslation } from 'react-i18next';
import Icon, {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  InboxOutlined,
  PlusOutlined,
  VerticalAlignBottomOutlined,
} from '@ant-design/icons';
import ITVTable from 'components/ITVTable';
import styled from 'styled-components';
import CustomButton from 'styles/buttonStyled';
import {
  SkillDescButton,
  SkillDescButtonContainer,
  SkillNameInput,
} from '../SkillInformationFields/styled';
import IconSuccess from 'assets/icons/ic_success_orange.svg';
import IconCancel from 'assets/icons/ic_cancel_orange.svg';
import { INIT_CERT_ITEM } from 'features/employee/constants/common';

const CertificatesFields = (props: any) => {
  const { isShowComponents, isCanUpdate, status, employeeData, setEmployeeData } = props;
  const [form] = Form.useForm();
  const { t } = useTranslation(['employee', 'modal', 'validation', 'recruitment']);

  const [isLoading, setIsLoading] = useState(false);
  const [certificate, setCertificate] = useState<any>([]);
  const [showUpload, setShowUpload] = useState(false);
  const [itemChoose, setItemChoose] = useState(null);
  const [uploadedFilename, setUploadedFilename] = useState<string>('');

  function base64MimeType(encoded: string) {
    var result = null;

    if (typeof encoded !== 'string') {
      return result;
    }

    var mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);

    if (mime && mime.length) {
      result = mime[1];
    }

    return result;
  }

  const openFileInNewTab = (base64String: string) => {
    const mimeType = base64MimeType(base64String);
    if (!mimeType) return;
    // Chuyển đổi base64 thành một đối tượng blob
    const b64 = base64String.split(',')[1];
    const byteCharacters = atob(b64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: mimeType });

    // Tạo URL từ đối tượng blob
    const url = URL.createObjectURL(blob);

    // Mở tệp PDF trong một tab mới
    window.open(url, '_blank');
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: 50,
      minWidth: 50,
      ellipsis: true,
      render: (text: any, record: any, index: any) => <div>{index + 1}</div>,
    },
    {
      title: 'Certificate name',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      width: 180,
      minWidth: 180,
      render: (text: any) => <div>{text || ''}</div>,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      ellipsis: true,
      width: 80,
      minWidth: 80,
      render: (text: any, record: any, index: any) =>
        isCanUpdate && (
          <StyledWrapperAction>
            {
              <Tooltip placement="top" title="View">
                <StyledEyeOutlined
                  onClick={() => {
                    openFileInNewTab('data:application/pdf;base64,' + record?.file);
                  }}
                />
              </Tooltip>
            }
            {/* {(
            <Tooltip placement="top" title='Edit'>
              <StyledEditOutlined onClick={() => {
                form.setFieldValue('name',record?.name)
                setShowUpload(true)
                setItemChoose(record)
              }} />
            </Tooltip>
          )} */}
            {/* {(
            <Tooltip placement="top" title='Download'>
              <StyledDownloadOutlined onClick={() => { }} />
            </Tooltip>
          )} */}
            {
              <Tooltip placement="top" title="Delete">
                <StyledDeleteOutlined
                  onClick={() => {
                    const newArray = certificate?.filter(
                      (cert: any) => cert?.name !== record?.name,
                    );
                    setCertificate(newArray);
                    onEmployeeUpdateCertificate(newArray);
                  }}
                />
              </Tooltip>
            }
          </StyledWrapperAction>
        ),
    },
  ];

  useEffect(() => {
    if (employeeData?.skill?.certificates) {
      setCertificate(employeeData?.skill?.certificates);
    } else {
      setCertificate([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeData?.skill]);

  const onEmployeeUpdateCertificate = (_Certificate: any) => {
    setEmployeeData({
      ...employeeData,
      skill: { ...employeeData?.skill, certificates: _Certificate },
    });
  };

  const onDeleteCertificate = (index: any) => () => {
    Modal.confirm({
      title: t('modal:confirm_delete', { field: 'certificate' }),
      icon: <ExclamationCircleOutlined />,
      okText: t('modal:btn_ok'),
      okType: 'danger',
      cancelText: t('modal:btn_cancel'),
      onOk: () => {
        const new_cert = certificate.filter((v, i) => i !== index);
        setCertificate(new_cert);
        setEmployeeData({
          ...employeeData,
          skill: { ...employeeData?.skill, certificates: new_cert },
        });
      },
    });
  };
  const fileToBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result); // Lấy phần base64 sau dấu phẩy
      reader.onerror = (error) => reject(error);
    });
  };

  const validateFile = async (rule: any, value: any) => {
    if (!value || value.length === 0) {
      throw new Error('Please select a file.');
    }

    const file = value?.file;
    // const isPDF = file.type === 'application/pdf';

    // if (!isPDF) {
    //   throw new Error('Only PDF files are allowed.');
    // }

    return true;
  };
  const onSaveCertificate = () => {
    form.validateFields().then((value) => {
      setCertificate([...certificate, { name: value?.name, link: '', file: uploadedFilename }]);
      onEmployeeUpdateCertificate([
        ...certificate,
        { name: value?.name, link: '', file: uploadedFilename },
      ]);
      form.resetFields();
      setShowUpload(false);
      setItemChoose(null);
      setUploadedFilename('');
    });
  };
  const onCancelAddCertificate = () => {
    form.resetFields();
    setShowUpload(false);
    setItemChoose(null);
    setUploadedFilename('');
  };

  return (
    <div style={isShowComponents ? {} : { display: 'none' }}>
      <ITVTable columns={columns} data={certificate} isRowSelect={false} loading={isLoading} />
      <CustomButton
        icon={<PlusOutlined />}
        onClick={() => {
          setShowUpload(true);
        }}
      >
        {t('recruitment:add_certification')}
      </CustomButton>
      <Form form={form} id="CertificateForm">
        <Row gutter={[16, 16]}>
          <Col md={16} xs={24}>
            {showUpload && (
              <Row align="middle" gutter={8}>
                <Col md={24} xs={24}>
                  <Form.Item
                    style={{ marginTop: 14 }}
                    required={true}
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: `${t('HRM_VAD_REQUIRED', { field: 'Certificate name' })}`,
                      },
                      {
                        whitespace: true,
                        message: `${t('HRM_VAD_BLANK_CHARACTERS', { field: 'Certificate name' })}`,
                      },
                    ]}
                  >
                    <SkillNameInput placeholder={'Enter certificate name'} />
                  </Form.Item>
                </Col>
              </Row>
            )}
            <Form.Item
              name="file"
              rules={[
                {
                  validator: validateFile,
                },
              ]}
            >
              {showUpload && (
                <Upload.Dragger
                  accept=".pdf"
                  beforeUpload={async (file) => {
                    const base64String = await fileToBase64(file);
                    setUploadedFilename(base64String?.split(',')[1]);
                    return false;
                  }}
                  maxCount={1}
                >
                  {
                    <div>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    </div>
                  }
                </Upload.Dragger>
              )}
            </Form.Item>

            {showUpload && (
              <SkillDescButtonContainer>
                <SkillDescButton
                  onClick={onSaveCertificate}
                  icon={<Icon component={() => <img src={IconSuccess} alt="" />} />}
                ></SkillDescButton>
                <SkillDescButton
                  onClick={onCancelAddCertificate}
                  icon={<Icon component={() => <img src={IconCancel} alt="" />} />}
                ></SkillDescButton>
              </SkillDescButtonContainer>
            )}
          </Col>
          <Col md={8} xs={24}></Col>
        </Row>
      </Form>
    </div>
  );
};

export default CertificatesFields;
const StyledWrapperAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const StyledEyeOutlined = styled(EyeOutlined)`
  font-size: 18px;
  color: var(--cl_info500);
  cursor: pointer;
`;
const StyledDeleteOutlined = styled(DeleteOutlined)`
  font-size: 18px;
  color: var(--cl_error500);
  cursor: pointer;
`;
const StyledEditOutlined = styled(EditOutlined)`
  font-size: 18px;
  color: var(--cl_primary500);
  cursor: pointer;
`;
const StyledDownloadOutlined = styled(VerticalAlignBottomOutlined)`
  font-size: 18px;
  color: var(--cl_info500);
  cursor: pointer;
`;

export const dataMock = {
  payroll: [
    {
      user: {
        id: '4adb62b2-6a8b-4fda-b6cb-18c879c6e757',
        username: 'hrmember',
        email: 'c6fe9a189@innotech-vn.com',
        status: 'approved',
        employeeId: '00269',
        prefixId: null,
        personalEmail: '5fa9b5302@gmail.com',
        onBoardStatus: {
          id: 1,
          name: 'Onboard',
          label: 'Onboard',
          value: 1,
          description: '',
        },
        startDate: null,
        endDate: null,
        dob: '1995-10-02T10:00:00.000Z',
        firstName: 'hr',
        lastName: 'member',
        fullName: 'member hr',
        fullNameNoAccent: 'member hr',
        fullNameWestern: 'hr member',
        fullNameNoAccentWestern: 'hr member',
        timezone: 'Asia/Ho_Chi_Minh',
        sex: {
          id: 2,
          name: 'Female',
          label: 'Female',
          value: 2,
        },
        nationality: 'Việt Nam',
        originalPlace: '',
        permanentAddress: '',
        citizenId: '',
        cidIssuedDate: '2022-04-21T07:32:09.434Z',
        cidPlaceOfIssue: '',
        placeOfBirth: '',
        temporaryAddress: '',
        religion: null,
        maritalStatus: null,
        level: {
          id: 1,
          name: 'Level 1',
          label: 'Level 1',
          value: 1,
          description: '',
        },
        school: '',
        schoolMajor: '',
        degree: null,
        trainingPeriodFrom: null,
        trainingPeriodTo: null,
        contractType: null,
        contractStartDate: null,
        contractExpirationDate: null,
        ethnicity: null,
        bankName: '',
        bankBranch: '',
        bankAccount: '',
        phoneNumber: '',
        taxCode: '',
        socialInsuranceNumber: '',
        positions: [
          {
            id: 50,
            name: 'HR staff',
            label: 'HR staff',
            value: 50,
            created: '07:53 26/11/2021',
            departments: [
              {
                id: 12,
                name: 'Human Resource',
              },
            ],
          },
        ],
        managerId: '00000',
        createdAt: '2021-10-11T23:34:07.693Z',
        updatedAt: '2022-10-11T21:03:28.683Z',
        relative: null,
        skill: null,
        permissions: [],
        onboard: null,
        roles: [
          {
            id: 3,
            name: 'HR Staff',
          },
        ],
        departments: [
          {
            id: 12,
            name: 'Human Resource',
          },
        ],
        degreeValue: null,
        ethnicityValue: null,
        religionValue: null,
        maritalStatusValue: null,
        sexValue: 'Female',
        contractTypeValue: null,
        levelValue: 'Level 1',
        onBoardStatusValue: 'Onboard',
        departmentName: 'Human Resource',
        managerName: '',
        roleName: 'HR Staff',
        managers: [
          {
            id: '201b6737-b68a-4be2-abf4-1753679099c9',
            email: '26fc4a0d4@innotech-vn.com',
            firstName: 'admin',
            lastName: 'system',
            fullName: 'system admin',
            fullNameWestern: 'admin system',
            employeeId: '00000',
            createdAt: '2021-09-30T19:30:30.000Z',
          },
        ],
        positionName: 'HR staff',
        relativeValue: '',
      },
      dataPayroll: {
        dataPayroll: {
          timeSheet: {
            actualHours: 133.7,
            leaveOffHours: 0,
            standardHours: 104,
          },
        },
      },
    },
    {
      user: {
        id: 'a7948b5e-0f89-44a4-9079-23eef7034539',
        username: 'staff',
        email: 'staff@innotech-vn.com',
        status: 'approved',
        employeeId: '00316',
        prefixId: null,
        personalEmail: '291229760@gmail.com',
        onBoardStatus: {
          id: 1,
          name: 'Onboard',
          label: 'Onboard',
          value: 1,
          description: '',
        },
        startDate: '2021-01-31T19:41:14.911Z',
        endDate: null,
        dob: '1979-12-31T10:00:00.000Z',
        firstName: 'Tô Y',
        lastName: 'Trần',
        fullName: 'Trần Tô Y',
        fullNameNoAccent: 'tran to y',
        fullNameWestern: 'Tô Y Trần',
        fullNameNoAccentWestern: 'to y tran',
        timezone: 'Asia/Ho_Chi_Minh',
        sex: {
          id: 2,
          name: 'Female',
          label: 'Female',
          value: 2,
        },
        nationality: null,
        originalPlace: '',
        permanentAddress: '',
        citizenId: '',
        cidIssuedDate: '2022-04-21T07:32:09.434Z',
        cidPlaceOfIssue: '',
        placeOfBirth: '',
        temporaryAddress: '',
        religion: null,
        maritalStatus: null,
        level: {
          id: 3,
          name: 'Level 3',
          label: 'Level 3',
          value: 3,
          description: '',
        },
        school: '',
        schoolMajor: '',
        degree: null,
        trainingPeriodFrom: null,
        trainingPeriodTo: null,
        contractType: {
          id: 1635487637979,
          name: 'Indefinite term contract',
        },
        contractStartDate: null,
        contractExpirationDate: null,
        ethnicity: null,
        bankName: '',
        bankBranch: '',
        bankAccount: '',
        phoneNumber: '',
        taxCode: '',
        socialInsuranceNumber: '',
        positions: [
          {
            id: 36,
            name: 'Software Engineer',
            label: 'Software Engineer',
            value: 36,
            created: '01:47 12/10/2021',
            departments: [
              {
                id: 21,
                name: 'Front Office',
              },
              {
                id: 16,
                name: 'Outsource',
              },
              {
                id: 17,
                name: 'Fintech',
              },
              {
                id: 4,
                name: 'Hà Nội Branch',
              },
            ],
          },
        ],
        managerId: '00334',
        createdAt: '2022-02-07T03:41:41.657Z',
        updatedAt: '2022-10-24T01:38:22.580Z',
        relative: [],
        skill: {
          mainSkill: [],
          softSkill: [],
        },
        permissions: [],
        onboard: null,
        roles: [
          {
            id: 3,
            name: 'HR Staff',
          },
        ],
        departments: [
          {
            id: 3,
            name: 'HRM',
          },
        ],
        degreeValue: null,
        ethnicityValue: null,
        religionValue: null,
        maritalStatusValue: null,
        sexValue: 'Female',
        contractTypeValue: 'Indefinite term contract',
        levelValue: 'Level 3',
        onBoardStatusValue: 'Onboard',
        departmentName: 'HRM',
        managerName: '',
        roleName: 'HR Staff',
        managers: [
          {
            id: 'b9769a7b-7592-4176-9c72-be0ee299e031',
            email: '486abd0cf@innotech-vn.com',
            firstName: 'Tô Q',
            lastName: 'Đào',
            fullName: 'Đào Tô Q',
            fullNameWestern: 'Tô Q Đào',
            employeeId: '00334',
            createdAt: '2022-03-21T00:37:13.164Z',
          },
        ],
        positionName: 'Software Engineer',
        relativeValue: '',
      },
      dataPayroll: {
        actualReceiveSalary: 14256420.363636363,
        nonTaxableOvertimePayment: 1278579.5454545454,
        salaryPeriodForActualWork: 12733352.272727272,
        taxPersonIncome: 3173068.090909091,
        taxableIncome: 0,
        taxableOvertimePayment: 1278579.5454545454,
        totalAdvance: 1000000,
        totalAllowanceNotGross: 0,
        totalAmountDeduction: 24200000,
        totalGrossSalary: 13000000,
        totalIncomeAdjustment: 90909,
        totalIncomeBeforeTax: 15781420.363636363,
        totalIncomeBonus: 400000,
        totalIncomeOvertime: 2557159.090909091,
        totalSalaryCost: 16781420.363636363,
        totalSalaryPerHour: 73863.63636363637,
        totalTaxPersonIncome: 0,
        dataPayroll: {
          timeSheet: {
            actualHours: 172.39,
            leaveOffHours: 0,
            standardHours: 176,
          },
          salary: {
            mainSalary: 5000000,
            grossSalary: 11000000,
            allowances: [
              {
                name: 'meal',
                isGross: true,
                isTaxed: false,
                money: 730000,
              },
              {
                name: 'cell phone',
                isGross: true,
                isTaxed: false,
                money: 1500000,
              },
              {
                name: 'business fee',
                isGross: true,
                isTaxed: false,
                money: 2500000,
              },
              {
                name: 'marketing',
                isGross: true,
                isTaxed: false,
                money: 1270000,
              },
              {
                name: 'other fee',
                isGross: true,
                isTaxed: false,
                money: 2000000,
              },
            ],
          },
          overtime: [
            {
              rate: 2,
              overtimeHours: 17.31,
            },
            {
              rate: 3,
              overtimeHours: 0,
            },
            {
              rate: 1.5,
              overtimeHours: 0,
            },
            {
              rate: 4,
              overtimeHours: 0,
            },
          ],
          adjustment: [
            {
              name: 'test adjust',
              amount: 90909,
              increaseFlag: true,
              isTax: false,
            },
          ],
          advance: [
            {
              amount: 1000000,
            },
          ],
          bonus: [
            {
              name: 'bonus',
              money: 400000,
              isTaxed: true,
            },
          ],
          deduction: {
            dependant: 4400000,
            dependantForPerson: 11000000,
            totalPersonDependant: 3,
          },
          duration: {
            startDate: '2022-09-01',
            endDate: '2022-09-30',
          },
          insuranceIncomeTax: {
            contribuEmployee: 525000,
            contribuEmployer: 1000000,
          },
          configurationPIT: [
            {
              maxValue: 10000000,
              minValue: 5000000,
              rate: 10,
              isProbationary: false,
            },
            {
              maxValue: 18000000,
              minValue: 10000000,
              rate: 15,
              isProbationary: false,
            },
            {
              maxValue: 32000000,
              minValue: 18000000,
              rate: 20,
              isProbationary: false,
            },
            {
              maxValue: 52000000,
              minValue: 32000000,
              rate: 25,
              isProbationary: false,
            },
            {
              maxValue: 80000000,
              minValue: 52000000,
              rate: 30,
              isProbationary: false,
            },
            {
              minValue: 80000000,
              rate: 35,
              isProbationary: false,
            },
          ],
          ratio: 100,
        },
      },
    },
  ],
  payrollDetails: {
    name: 'a',
    status: 'draft',
  },
};

export const dataHistoryPayroll = [
  {
    id: 1,
    employeeId: '0011',
    fullName: 'Nguyễn Văn A',
    email: 'nva@gmail.com',
    department: 'HRM',
    change: 'Salary: 400000-500000',
    reasons: '',
    updatedAt: '08/15/2022 - 11:01',
    updatedBy: 'Accountant',
    status: 'Waiting for HR manager Approve',
  },
  {
    id: 1,
    employeeId: '0011',
    fullName: 'Nguyễn Văn A',
    email: 'nva@gmail.com',
    department: 'HRM',
    change: 'Salary: 400000-500000',
    reasons: '',
    updatedAt: '08/15/2022 - 11:01',
    updatedBy: 'Accountant',
    status: 'Waiting for HR manager Approve',
  },
];

export const dataDeduct = [
  {
    id: 1,
    name: 'Person',
    money: 11000000,
  },
  {
    id: 2,
    name: 'Dependents',
    money: 4000000,
  },
];

export const dataBonus = [
  {
    id: 1,
    name: '13th salary',
    tax: true,
  },
  {
    id: 2,
    name: 'Best performance',
    tax: false,
  },
];

export const dataPIT = [
  {
    id: 1,
    taxFrom: 0,
    taxTo: 5,
    taxRate: 5,
  },
  {
    id: 2,
    taxFrom: 5,
    taxTo: 10,
    taxRate: 10,
  },
  {
    id: 3,
    taxFrom: 10,
    taxTo: 18,
    taxRate: 15,
  },
  {
    id: 4,
    taxFrom: 18,
    taxTo: 32,
    taxRate: 20,
  },
  {
    id: 5,
    taxFrom: 32,
    taxTo: 52,
    taxRate: 25,
  },
  {
    id: 6,
    taxFrom: 52,
    taxTo: 80,
    taxRate: 30,
  },
  {
    id: 7,
    taxFrom: 80,
    taxRate: 35,
  },
];

export const dataHistoryOverall = [
  {
    id: 1,
    createdBy: {
      employeeId: '0011',
      fullName: 'Nguyễn Văn A',
      email: 'nva@gmail.com',
    },
    createdAt: '08/15/2022 - 11:01',
    reasons: 'a',
    updatedAt: '08/15/2022 - 11:01',
    updatedBy: {
      employeeId: '0011',
      fullName: 'Nguyễn Văn A',
      email: 'nva@gmail.com',
    },
    status: 'Waiting for HR manager Approve',
  },
];

export const dataMockDetail = {
  monthYear: '2022-09',
  user: {
    id: 'a7948b5e-0f89-44a4-9079-23eef7034539',
    username: 'staff',
    email: 'staff@innotech-vn.com',
    status: 'approved',
    employeeId: '00316',
    prefixId: null,
    personalEmail: '291229760@gmail.com',
    onBoardStatus: {
      id: 1,
      name: 'Onboard',
      label: 'Onboard',
      value: 1,
      description: '',
    },
    startDate: '2021-01-31T19:41:14.911Z',
    endDate: null,
    dob: '1979-12-31T10:00:00.000Z',
    firstName: 'Tô Y',
    lastName: 'Trần',
    fullName: 'Trần Tô Y',
    fullNameNoAccent: 'tran to y',
    fullNameWestern: 'Tô Y Trần',
    fullNameNoAccentWestern: 'to y tran',
    timezone: 'Asia/Ho_Chi_Minh',
    sex: {
      id: 2,
      name: 'Female',
      label: 'Female',
      value: 2,
    },
    nationality: null,
    originalPlace: '',
    permanentAddress: '',
    citizenId: '',
    cidIssuedDate: '2022-04-21T07:32:09.434Z',
    cidPlaceOfIssue: '',
    placeOfBirth: '',
    temporaryAddress: '',
    religion: null,
    maritalStatus: null,
    level: {
      id: 3,
      name: 'Level 3',
      label: 'Level 3',
      value: 3,
      description: '',
    },
    school: '',
    schoolMajor: '',
    degree: null,
    trainingPeriodFrom: null,
    trainingPeriodTo: null,
    contractType: {
      id: 1635487637979,
      name: 'Indefinite term contract',
    },
    contractStartDate: null,
    contractExpirationDate: null,
    ethnicity: null,
    bankName: '',
    bankBranch: '',
    bankAccount: '',
    phoneNumber: '',
    taxCode: '',
    socialInsuranceNumber: '',
    positions: [
      {
        id: 36,
        name: 'Software Engineer',
        label: 'Software Engineer',
        value: 36,
        created: '01:47 12/10/2021',
        departments: [
          {
            id: 21,
            name: 'Front Office',
          },
          {
            id: 16,
            name: 'Outsource',
          },
          {
            id: 17,
            name: 'Fintech',
          },
          {
            id: 4,
            name: 'Hà Nội Branch',
          },
        ],
      },
    ],
    managerId: '00334',
    createdAt: '2022-02-07T03:41:41.657Z',
    updatedAt: '2022-10-24T01:38:22.580Z',
    relative: [],
    skill: {
      mainSkill: [],
      softSkill: [],
    },
    permissions: [],
    onboard: null,
    roles: [
      {
        id: 3,
        name: 'HR Staff',
      },
    ],
    departments: [
      {
        id: 3,
        name: 'HRM',
      },
    ],
    degreeValue: null,
    ethnicityValue: null,
    religionValue: null,
    maritalStatusValue: null,
    sexValue: 'Female',
    contractTypeValue: 'Indefinite term contract',
    levelValue: 'Level 3',
    onBoardStatusValue: 'Onboard',
    departmentName: 'HRM',
    managerName: '',
    roleName: 'HR Staff',
    managers: [
      {
        id: 'b9769a7b-7592-4176-9c72-be0ee299e031',
        email: '486abd0cf@innotech-vn.com',
        firstName: 'Tô Q',
        lastName: 'Đào',
        fullName: 'Đào Tô Q',
        fullNameWestern: 'Tô Q Đào',
        employeeId: '00334',
        createdAt: '2022-03-21T00:37:13.164Z',
      },
    ],
    positionName: 'Software Engineer',
    relativeValue: '',
  },
  dataPayroll: {
    checkGrossSalary: 2000000,
    actualReceiveSalary: 14256420.363636363,
    nonTaxableOvertimePayment: 1278579.5454545454,
    salaryPeriodForActualWork: 12733352.272727272,
    taxPersonIncome: 3173068.090909091,
    taxableIncome: 0,
    taxableOvertimePayment: 1278579.5454545454,
    totalAdvance: 1000000,
    totalAllowanceNotGross: 0,
    totalAmountDeduction: 24200000,
    totalGrossSalary: 13000000,
    totalIncomeAdjustment: 90909,
    totalIncomeBeforeTax: 15781420.363636363,
    totalIncomeBonus: 400000,
    totalIncomeOvertime: 2557159.090909091,
    totalSalaryCost: 16781420.363636363,
    totalSalaryPerHour: 73863.63636363637,
    totalTaxPersonIncome: 0,
    totalNonTaxPersonIncome: 9803579.545454545,
    dataPayroll: {
      timeSheet: {
        actualHours: 172.39,
        leaveOffHours: 0,
        standardHours: 176,
      },
      salary: {
        mainSalary: 5000000,
        grossSalary: 11000000,
        name: 'more-than-three-months',
        allowances: [
          {
            name: 'meal',
            isGross: true,
            isTaxed: false,
            money: 730000,
          },
          {
            name: 'cell phone',
            isGross: true,
            isTaxed: false,
            money: 1500000,
          },
          {
            name: 'business fee',
            isGross: true,
            isTaxed: false,
            money: 2500000,
          },
          {
            name: 'marketing',
            isGross: true,
            isTaxed: false,
            money: 1270000,
          },
          {
            name: 'other fee',
            isGross: true,
            isTaxed: false,
            money: 2000000,
          },
        ],
      },
      overtime: [
        {
          rate: 2,
          overtimeHours: 17.31,
        },
        {
          rate: 3,
          overtimeHours: 0,
        },
        {
          rate: 1.5,
          overtimeHours: 0,
        },
        {
          rate: 4,
          overtimeHours: 0,
        },
      ],
      adjustment: [
        {
          name: 'test adjust',
          amount: 90909,
          increaseFlag: true,
          isTax: false,
        },
      ],
      advance: [
        {
          name: 'advance',
          amount: 1000000,
        },
      ],
      bonus: [
        {
          name: 'bonus',
          money: 400000,
          isTaxed: true,
        },
      ],
      deduction: {
        dependant: 4400000,
        dependantForPerson: 11000000,
        totalPersonDependant: 3,
      },
      duration: {
        startDate: '2022-09-01',
        endDate: '2022-09-30',
      },
      insuranceIncomeTax: {
        contribuEmployee: 525000,
        contribuEmployer: 1000000,
      },
      configurationPIT: [
        {
          maxValue: 10000000,
          minValue: 5000000,
          rate: 10,
          isProbationary: false,
        },
        {
          maxValue: 18000000,
          minValue: 10000000,
          rate: 15,
          isProbationary: false,
        },
        {
          maxValue: 32000000,
          minValue: 18000000,
          rate: 20,
          isProbationary: false,
        },
        {
          maxValue: 52000000,
          minValue: 32000000,
          rate: 25,
          isProbationary: false,
        },
        {
          maxValue: 80000000,
          minValue: 52000000,
          rate: 30,
          isProbationary: false,
        },
        {
          minValue: 80000000,
          rate: 35,
          isProbationary: false,
        },
      ],
      ratio: 100,
    },
  },
};

import React from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';

import { REGEX_NUMBER } from 'constants/regex';

import CustomInput from 'styles/inputStyled';
import CustomFormItem from 'styles/formStyled';

const BankInformationItems = (props: any) => {
  const { status } = props;
  const { t } = useTranslation(['validation', 'employee']);

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col md={12} xs={24}>
          <CustomFormItem
            label={t('employee:personnel_information_fields.bank_account_number')}
            required={false}
            name="bankAccount"
            rules={[
              {
                pattern: new RegExp(REGEX_NUMBER),
                message: t('HRM_VAD_FORMAT', {
                  field: t('employee:personnel_information_fields.bank_account_number'),
                }),
              },
              {
                max: 30,
                message: t('HRM_VAD_MAXIMUM_CHARACTERS', {
                  field: t('employee:personnel_information_fields.bank_account_number'),
                  numberLeast: 30,
                }),
              },
              {
                whitespace: true,
                message: t('HRM_VAD_BLANK_CHARACTERS', {
                  field: t('employee:personnel_information_fields.bank_account_number'),
                }),
              },
            ]}
          >
            <CustomInput
              disabled={status === 'deleted' ? true : false}
              placeholder={t(
                'employee:personnel_information_fields.bank_account_number_placeholder',
              )}
            />
          </CustomFormItem>
        </Col>
        <Col md={12} xs={24}>
          <CustomFormItem
            label={t('employee:personnel_information_fields.bank_name')}
            required={false}
            name="bankName"
            rules={[
              {
                whitespace: true,
                message: t('HRM_VAD_BLANK_CHARACTERS', {
                  field: t('employee:personnel_information_fields.bank_name'),
                }),
              },
            ]}
          >
            <CustomInput
              disabled={status === 'deleted' ? true : false}
              placeholder={t('employee:personnel_information_fields.bank_name_placeholder')}
            />
          </CustomFormItem>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col md={12} xs={24}>
          <CustomFormItem
            label={t('employee:personnel_information_fields.branch_bank')}
            required={false}
            name="bankBranch"
            rules={[
              {
                whitespace: true,
                message: t('HRM_VAD_BLANK_CHARACTERS', {
                  field: t('employee:personnel_information_fields.branch_bank'),
                }),
              },
            ]}
          >
            <CustomInput
              disabled={status === 'deleted' ? true : false}
              placeholder={t('employee:personnel_information_fields.branch_bank_placeholder')}
            />
          </CustomFormItem>
        </Col>
      </Row>
    </>
  );
};

export default BankInformationItems;

import styled from 'styled-components';
import CustomButton from 'styles/buttonStyled';

export const Title = styled.div`
  margin-top: 10px;
  font-size: 18px;
  margin-bottom: 15px;
`;

export const Content = styled.div`
  margin-bottom: 30px;
  font-size: 16px;
  color: red;
`;

export default function ErrorFallback({ error, resetErrorBoundary }: any) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 20,
      }}
    >
      <img
        alt=""
        width={150}
        src={'https://s19499.pcdn.co/wp-content/uploads/2017/05/oops-clipart.jpg'}
      />
      <Title>Sorry, something went wrong:</Title>
      <Content>{error?.message}</Content>
      <CustomButton onClick={resetErrorBoundary}>Try again</CustomButton>
    </div>
  );
}

import styled from 'styled-components';

export const ContainerStyled = styled.div`
  background: #f2f2f6;
  border: 1px solid #0e86f4;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  margin: 0.5rem;
  padding: 0.735rem 0rem;
  &:hover {
    cursor: pointer;
    border: 1px solid var(--cl_info800);
    background: var(--cl_gray300);
  }
`;

export const LabelStyled = styled.div`
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 0.6rem;
  text-align: center;
  letter-spacing: 0.5px;
  color: var(--cl_text_primary);
`;

import { ArrowRightOutlined } from '@ant-design/icons';
import { Form, Select } from 'antd';

import { useAppSelector } from 'app/hooks';
import { getFindOneAction, getFindOneStatus } from 'features/configuration/configurationAction';
import { selectConfiguration } from 'features/configuration/configurationSlice';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  Background,
  ConnectionLineType,
  Controls,
  MarkerType,
  ReactFlowProvider,
  applyEdgeChanges,
  applyNodeChanges,
} from 'react-flow-renderer';
import { useDispatch } from 'react-redux';
import { CustomCard, CustomDrawer, CustomFormItem, CustomReactFlow, CustomTag } from '../styled';
import ButtonEdge from './ButtonEdge';
import ModalNodeView from './ModalNodeView';
import SmartEdge from './SmartEdge';
import StateNode from './StateNode';
import './indexViewWl.css';

const { Option } = Select;

const edgeTypes = { buttonedge: ButtonEdge, smart: SmartEdge };

const nodeTypes = { state: StateNode };

const initialEdges = [
  {
    id: '0',
    source: '0', //1
    target: '1',
    label: 'Send payroll',
    type: 'smart',
    markerEnd: { type: MarkerType.Arrow },
  },
  {
    id: '1',
    source: '1',
    target: '2',
    label: 'Approve',
    className: 'normal-edge',
    type: 'smart',
    markerEnd: { type: MarkerType.Arrow },
  },
  {
    id: '2',
    source: '2',
    target: '1',
    label: 'Approve eeeeeeeeee',
    className: 'normal-edge',
    type: 'smart',
    markerEnd: { type: MarkerType.Arrow },
  },
];

const labelName = [
  { id: 1, name: 'PRL_WF_CREATE_1' },
  { id: 2, name: 'PRL_WF_CREATE_2' },
  { id: 3, name: 'PRL_WF_CREATE_3' },
  { id: 4, name: 'PRL_WF_CREATE_4' },
  { id: 5, name: 'PRL_WF_REQUEST1' },
  { id: 6, name: 'PRL_WF_CREATE_2' },
  { id: 7, name: 'PRL_WF_CREATE_3' },
  { id: 8, name: 'PRL_WF_CREATE_4' },
  { id: 9, name: 'PRL_WF_CONFIRM_1' },
  { id: 10, name: 'PRL_WF_CONFIRM_2' },
  { id: 11, name: 'PRL_WF_CONFIRM_3' },
  { id: 12, name: 'PRL_WF_CONFIRM_4' },
];

const Workflow2ViewHistory = (props: any) => {
  const { setNodes, setEdges, edges, nodes, selectStatus, selectAction, currentItem, modalType } =
    props;
  const reactFlowWrapper = useRef(null);

  useEffect(() => {
    setEdges(currentItem?.newEdges);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentItem]);

  useEffect(() => {
    setNodes(currentItem?.newNodes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentItem]);

  const [, setReactFlowInstance] = useState(null);
  const [visible, setVisible] = useState(false);
  const [visibleAction, setVisibleAction] = useState(false);
  const [form] = Form.useForm();
  const [captureElementClick] = useState(true);
  const [captureEdgeClick] = useState(true);

  const [valueNode, setValueNode] = useState('');
  const [, setValueEdge] = useState('');

  const [edgenextValueNode, setEdgeNextValueNode] = useState([]);
  const [edgePrevValueNode, setEdgePrevValueNode] = useState([]);
  const [, setValueAction] = useState('');
  const [fromNode, setFromNode] = useState<any[]>([]);
  const [toNode, setToNode] = useState({});
  const dataStore = useAppSelector(selectConfiguration);
  const dataFindOneAction = selectAction !== undefined ? dataStore?.dataGroupAction : [];

  const dispatch = useDispatch();

  const handleNodesChange = useCallback((changes: any) => {
    nodes &&
      setNodes((ns) => {
        return ns && applyNodeChanges(changes, ns);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEdgesChange = useCallback((changes: any) => {
    setEdges((es) => applyEdgeChanges(changes, es));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDragOver = useCallback((event: any) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onClose = () => {
    setVisible(false);
    setEdgePrevValueNode([]);
    setEdgeNextValueNode([]);
  };
  const onCloseAction = () => {
    setVisibleAction(false);
    setEdgePrevValueNode([]);
    setEdgeNextValueNode([]);
  };
  const handleFormChange = () => {};
  const onNodeClick = (event: any, node: any) => {
    const edgeNext = edges?.filter((item) => {
      return item.source === node.id;
    });
    const edgePrev = edges?.filter((item) => {
      return item.target === node.id;
    });

    setEdgeNextValueNode(edgeNext);
    setEdgePrevValueNode(edgePrev);

    if (node?.data?.label !== 'Start' && node?.data?.label !== 'End') {
      setVisible(true);
    }
    setValueNode(node);

    form.setFieldsValue({
      nameValue: node?.data.label,
      desValue: node?.data.label,
    });
  };
  const onEdgeClick = (event: any, edge: any) => {
    const from = nodes?.find((item) => {
      return edge.source === item.id;
    });
    setFromNode(from);
    const to = nodes?.find((item) => {
      return edge.target === item.id;
    });
    form.setFieldsValue({
      selectAction: edge.label,
    });
    setToNode(to);
    setValueEdge(edge);
    setValueAction(edge.label);

    setVisibleAction(true);
  };

  const [loading, setLoading] = useState(false);

  const loadMoreData = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    setLoading(false);
  };

  useEffect(() => {
    loadMoreData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectAction = (value: string) => {
    setValueAction(value);
  };

  useEffect(() => {
    if (selectStatus !== undefined) {
      dispatch(getFindOneStatus({ id: selectStatus }));
    }
  }, [selectStatus, dispatch]);

  useEffect(() => {
    if (selectAction !== undefined) {
      dispatch(getFindOneAction({ id: selectAction }));
    }
  }, [selectAction, dispatch]);

  return (
    <>
      <div
        className="site-drawer-render-in-current-wrapper"
        style={{ display: 'flex', height: '100%', width: '100%', position: 'relative' }}
      >
        <div className="dndflow">
          <ReactFlowProvider>
            <div className="reactflow-wrapper" ref={reactFlowWrapper}>
              <CustomReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={handleNodesChange}
                onEdgesChange={handleEdgesChange}
                // // onConnect={handleConnect}
                onInit={setReactFlowInstance}
                onNodeClick={captureElementClick ? onNodeClick : undefined}
                onEdgeClick={captureEdgeClick && onEdgeClick}
                // onDrop={handleDrop}
                onDragOver={handleDragOver}
                fitView
                edgeTypes={edgeTypes}
                deleteKeyCode={null}
                nodeTypes={nodeTypes}
                connectionLineType={ConnectionLineType.SmoothStep}
                connectionMode="loose"
              >
                <Background />
                <Controls />
              </CustomReactFlow>
            </div>
          </ReactFlowProvider>
        </div>

        {/* Modal node */}

        <CustomDrawer
          title={valueNode?.data?.label}
          placement="right"
          closable={false}
          onClose={onClose}
          visible={visible}
          getContainer={false}
          style={{ position: 'absolute' }}
        >
          <ModalNodeView
            labelName={labelName}
            form={form}
            edgenextValueNode={edgenextValueNode}
            edgePrevValueNode={edgePrevValueNode}
            valueNode={valueNode}
            initialEdges={initialEdges}
            nodes={nodes}
            loadMoreData={loadMoreData}
            currentItem={currentItem}
            modalType={modalType}
            selectStatus={selectStatus}
            visibleAction={visibleAction}
          />
        </CustomDrawer>

        {/* Modal action */}
        <CustomDrawer
          title="Transition"
          placement="right"
          closable={false}
          onClose={onCloseAction}
          visible={visibleAction}
          getContainer={false}
          style={{ position: 'absolute' }}
        >
          <Form form={form} onFieldsChange={handleFormChange} layout="vertical">
            {/* List action */}
            <div>
              <CustomCard>
                <CustomTag color="green">{fromNode?.data?.label}</CustomTag>
                <ArrowRightOutlined />
                <CustomTag color="purple">{toNode?.data?.label}</CustomTag>
                <CustomFormItem name="selectAction">
                  <Select disabled onChange={handleSelectAction}>
                    {dataFindOneAction?.actionDetails?.map((item: any) => {
                      return <Option value={item?.id}>{item?.actionName}</Option>;
                    })}
                  </Select>
                </CustomFormItem>
              </CustomCard>
            </div>
          </Form>
        </CustomDrawer>
      </div>
    </>
  );
};

export default Workflow2ViewHistory;

import AssignedSalaryItems from 'features/payroll/pages/ManageRequest/components/AssignedSalaryItems';
import { useTranslation } from 'react-i18next';
import { SkillInfoTitle } from './styled';

const SalaryInformationFields = (props: any) => {
  const { isShowComponents, employeeData } = props;
  const { t } = useTranslation(['employee', 'modal']);

  return (
    <div style={isShowComponents ? {} : { display: 'none' }}>
      <SkillInfoTitle>{t('employee:salary_information_fields.salary')}</SkillInfoTitle>
      <AssignedSalaryItems employeeData={employeeData} />
    </div>
  );
};

export default SalaryInformationFields;

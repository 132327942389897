import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import { DateRangePickerField, SelectField } from 'components/FormFields';
import { DiligencePayload } from 'types';
import { DATE_FORMAT } from 'constants/commons';
import { DatePicker } from 'antd';
import { DatePickerIcon } from 'assets/icons';
import { useState } from 'react';
import { Moment } from 'moment';

const StyledForm = styled.form`
  display: flex;
  gap: 12px;
`;

const StyledWrapperDateRange = styled.div`
  width: 226px;
`;

interface DiligenceFiltersProps {
  loading?: boolean;
  initialValues?: any;
  onSubmit?: (payload: any) => void;
}

export default function DiligenceFilters({ initialValues, onSubmit }: DiligenceFiltersProps) {
  const { control, handleSubmit } = useForm<any>({
    defaultValues: initialValues,
  });

  const [monthYear, setMonthYear] = useState<Moment | null>(initialValues?.monthYear || null);

  const handleFiltersSubmit = (formValues: DiligencePayload) => {
    onSubmit?.(formValues);
  };

  return (
    <StyledForm onSubmit={handleSubmit(handleFiltersSubmit)}>
      <StyledWrapperDateRange>
        <div style={{ display: 'flex', alignItems: 'center', marginRight: 20 }}>
          <p style={{ margin: 0, padding: 0, marginRight: 10, fontWeight: 600 }}>Pay Period</p>
          <DatePicker
            style={{ width: 110 }}
            picker="month"
            value={monthYear}
            allowClear={false}
            suffixIcon={<DatePickerIcon />}
            onChange={(value) => {
              setMonthYear(value);
              onSubmit?.(value);
            }}
          />
        </div>
      </StyledWrapperDateRange>
    </StyledForm>
  );
}

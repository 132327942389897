import { Layout, Tabs } from 'antd';
import { useAppSelector } from 'app/hooks';
import PageHeader from 'components/PageHeader';
import { selectConfiguration } from 'features/configuration/configurationSlice';
import { getGroupTemplateEmail, getTemplates } from 'features/onboard/onboardAction';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import ConfigAutoSendMail from './ConfigAutoSendMail';
import ManageAction from './ManageAction';
const { Content } = Layout;
const { TabPane } = Tabs;

export default function ManageAutoSendMail() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['onboard']);
  const MANAGER_EMAIL_TEMPLATE = [t('onboard:manage_action'), t('onboard:config_auto_send_mail')];
  const [tab, setTab] = useState('0');
  const location: any = useLocation();
  const menuStore = useAppSelector(selectConfiguration);
  const dataScreenManage = menuStore?.dataScreenManage?.result;
  const [titleHeader, setTitleHeader] = useState('');

  useEffect(() => {
    const temp = dataScreenManage
      ?.filter((item: any) => location.pathname === item.path)
      ?.map((item: any) => item.nameScreen);

    setTitleHeader(temp ? temp[0] : '');
  }, [dataScreenManage, location.pathname]);

  const onChangeTab = (key: string) => {
    setTab(key);
    key === '0' && dispatch(getTemplates({}));
    key === '1' && dispatch(getGroupTemplateEmail());
  };

  useEffect(() => {
    dispatch(getGroupTemplateEmail());
    dispatch(getTemplates({}));
  }, [dispatch]);

  return (
    <>
      <Container>
        <PageHeader title={titleHeader} />
      </Container>
      <Content>
        <Tabs size="large" onChange={onChangeTab} defaultActiveKey="0">
          <TabPane tab={MANAGER_EMAIL_TEMPLATE[1]} key="0">
            {tab === '0' && <ConfigAutoSendMail />}
          </TabPane>
          <TabPane tab={MANAGER_EMAIL_TEMPLATE[0]} key="1">
            {tab === '1' && <ManageAction />}
          </TabPane>
        </Tabs>
      </Content>
    </>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

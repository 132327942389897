import { useState } from 'react';
import { Button, Dropdown } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { TFunction } from 'react-i18next';
import MenuActions from './MenuActions';

interface StatusColumnCellProps {
  isShowFullMenu: boolean;
  item: any;
  permissions: Array<any>;
  t?: TFunction<('modal' | 'employee' | 'onboard' | 'timesheet')[], undefined>;
  paramSortFilter?: any;
  showMenu?: any;
  callBack?: any;
  monthYear?: any;
}

function StatusColumnCell({
  isShowFullMenu,
  item,
  permissions,
  t,
  paramSortFilter,
  showMenu,
  callBack,
  monthYear,
}: StatusColumnCellProps) {
  const [isVisibleAddColumn, setIsVisibleAddColumn] = useState(false);

  return (
    <Dropdown
      overlay={
        <MenuActions
          isShowFullMenu={isShowFullMenu}
          item={item}
          permissions={permissions}
          handleHideMenuActions={() => setIsVisibleAddColumn(false)}
          t={t}
          showMenu={showMenu}
          paramSortFilter={paramSortFilter}
          callBack={callBack}
          monthYear={monthYear}
        />
      }
      overlayStyle={{ zIndex: 1020 }}
      placement="bottomRight"
      trigger={['click']}
      open={isVisibleAddColumn}
      onOpenChange={(open) => setIsVisibleAddColumn(open)}
    >
      <Button
        type="text"
        icon={<EllipsisOutlined style={{ fontSize: '150%', color: '#111827' }} />}
      />
    </Dropdown>
  );
}

export default StatusColumnCell;

import { setItem, getJson, removeItem } from './storage';

export const setUser = (user: any) => {
  setItem('user', JSON.stringify(user));
};

export const getUser = () => {
  return getJson('user');
};

export const logout = () => {
  removeItem('user');
};

export const getToken = () => {
  const localUser = getUser();
  if (!localUser) {
    return null;
  }
  return localUser.accessToken;
};

export const removeStorage = () => {
  localStorage.removeItem('hrm.user');
  localStorage.removeItem('hrm.tenant');
  localStorage.removeItem('hrm.register');
  localStorage.removeItem('os_pageViews');
  localStorage.removeItem('isOptedOut');
  localStorage.removeItem('isPushNotificationsEnabled');
  localStorage.removeItem('onesignal-notification-prompt');
  localStorage.removeItem('persist:root');
  localStorage.removeItem('current_date_choose');
  localStorage.removeItem('isVerifyOTP');
};

export const getLanguage = () => {
  return getJson('language');
};

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Form, Modal, Spin } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { useAppSelector } from 'app/hooks';
import { selectConfiguration } from 'features/configuration/configurationSlice';
import { selectEmployee } from 'features/employee/employeeSlice';

import AdjustmentInformationFields from 'features/employee/components/AdjustmentInformationFields';
import AdvancesInformantionFields from 'features/employee/components/AdvancesInformantionFields';
import AllowanceInformantionFields from 'features/employee/components/AllowanceInformantionFields';
import BonusInformationFields from 'features/employee/components/BonusInformationFields';
import ContractInformantionFields from 'features/employee/components/ContractInformationFields';
import DependantsInformantionFields from 'features/employee/components/DependantsInformantionFields';
import SalaryInformationFields from 'features/employee/components/SalaryInformationFields';
import { EMPLOYEE_KEY_MENU, INITIAL_EMPLOYEE } from 'features/employee/constants/common';
import {
  ContainerLoading,
  EmployeeAddModalContent,
  EmployeeAddModalContentLeft,
  EmployeeAddModalContentRight,
  EmployeeAddModalDivider,
  EmployeeModal,
  EmployeeModalForm,
  LoaderItem,
} from 'features/employee/pages/EmployeeUpdateModal/styled';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import xorWith from 'lodash/xorWith';
import SideBarMenu from './SideBarMenu';

const {
  employeeMenuKey,
  personalMenuKey,
  skillMenuKey,
  historyMenuKey,
  allowanceMenuKey,
  bonusMenuKey,
  salaryMenuKey,
  dependantsMenuKey,
  advancesMenuKey,
  contractMenuKey,
  adjustmentMenuKey,
  certificatesMenuKey,
} = EMPLOYEE_KEY_MENU;

interface EmployeeUpdateModalProps {
  isVisible: boolean;
  isShowFullMenu: boolean;
  itemId: any;
  status?: any;
  onClose: () => void;
  paramSortFilter?: any;
  showMenu?: any;
  callBack?: any;
  monthYear?: any;
  payrollUpdate?: any;
}

const EmployeeUpdateModal = (props: EmployeeUpdateModalProps) => {
  const {
    isVisible,
    isShowFullMenu,
    status,
    onClose,
    showMenu,
    callBack,
    monthYear,
    payrollUpdate,
  } = props;
  const [form] = Form.useForm();
  const dataConfiguration = useAppSelector(selectConfiguration);
  const employee = useAppSelector(selectEmployee);
  const isLoadingData = employee.isGettingDetail;
  const [openKeys, setOpenKeys] = useState(allowanceMenuKey);
  const [employeeInit, setEmployeeInit] = useState(INITIAL_EMPLOYEE);
  const [reloadContract, setReloadContract] = useState(false);
  const dataAssignAdvance = employee?.dataAssignAdvance?.result;
  const [newDataAdvances, setNewDataAdvances] = useState([]);

  const loadData = () => {
    const data = { ...employee?.employeeDetail };
    const onBoardStatus =
      dataConfiguration.dataOnBoardStatus && data.onBoardStatus
        ? dataConfiguration.dataOnBoardStatus?.find(
            (item: any) => item.value === data.onBoardStatus?.id,
          )
        : null;
    const level =
      dataConfiguration.dataLevel && data.level
        ? dataConfiguration.dataLevel?.find((item: any) => item.value === data?.level?.id)
        : null;
    const contractType =
      dataConfiguration.dataContractType && data.contractType
        ? dataConfiguration.dataContractType?.find(
            (item: any) => item.value === data.contractType?.id,
          )
        : null;
    const sex =
      dataConfiguration.dataSex && data.sex
        ? dataConfiguration.dataSex?.find((item: any) => item.value === data.sex?.id)
        : null;
    const maritalStatus =
      dataConfiguration.dataMaritalStatus && data.maritalStatus
        ? dataConfiguration.dataMaritalStatus?.find(
            (item: any) => item.value === data.maritalStatus?.id,
          )
        : null;
    const religion =
      dataConfiguration.dataReligion && data.religion
        ? dataConfiguration.dataReligion?.find((item: any) => item.value === data.religion?.id)
        : null;
    const ethnicity =
      dataConfiguration.dataEthnicity && data.ethnicity
        ? dataConfiguration.dataEthnicity?.find((item: any) => item.value === data.ethnicity?.id)
        : null;
    const degree =
      dataConfiguration.dataDegree && data.degree
        ? dataConfiguration.dataDegree?.find((item: any) => item.value === data.degree?.id)
        : null;
    const positions =
      data.positions && data.positions?.length > 0
        ? data.positions?.map((item: any) => ({ value: item.id, label: item.name }))
        : [];
    const departments =
      data.departments && data.departments?.length > 0
        ? data.departments?.map((item: any) => ({ value: item.id, label: item.name }))
        : [];
    const managers =
      data.managers && data.managers?.length > 0
        ? data.managers?.map((item: any) => ({
            value: item.employeeId,
            label: `${item.lastName} ${item.firstName}`,
          }))
        : [];
    const roles =
      data.roles && data.roles?.length > 0
        ? data.roles?.map((item: any) => ({ value: item.id, label: item.name }))
        : [];

    const initValues = data
      ? {
          ...data,
          startDate: data.startDate ? moment(data.startDate) : null,
          endDate: data.endDate ? moment(data.endDate) : null,
          dob: data.dob ? moment(data.dob) : null,
          cidIssuedDate: data.cidIssuedDate ? moment(data.cidIssuedDate) : null,
          contractStartDate: data.contractStartDate ? moment(data.contractStartDate) : null,
          contractExpirationDate: data.contractExpirationDate
            ? moment(data.contractExpirationDate)
            : null,
          trainingPeriodFrom: data.trainingPeriodFrom ? moment(data.trainingPeriodFrom) : null,
          trainingPeriodTo: data.trainingPeriodTo ? moment(data.trainingPeriodTo) : null,

          onBoardStatus: onBoardStatus,
          level: level,
          contractType: contractType,
          sex: sex,
          maritalStatus: maritalStatus,
          religion: religion,
          ethnicity: ethnicity,
          degree: degree,

          positions: positions,
          departments: departments,
          managers: managers,
          roles: roles,
        }
      : INITIAL_EMPLOYEE;
    setEmployeeInit(payrollUpdate?.user ? payrollUpdate?.user : initValues);
    form.setFieldsValue(initValues);
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employee, dataConfiguration, form]);

  const handleClickMenu = (keys: any) => {
    setOpenKeys(keys.key);
    if (keys.key === EMPLOYEE_KEY_MENU.contractMenuKey) {
      setReloadContract(true);
    } else {
      setReloadContract(false);
    }
  };

  useEffect(() => {
    if (!showMenu) {
      setOpenKeys(allowanceMenuKey);
    } else {
      switch (showMenu) {
        case 'contract':
          setOpenKeys(contractMenuKey);
          break;
        case 'allowance':
          setOpenKeys(allowanceMenuKey);
          break;
        case 'bonus':
          setOpenKeys(bonusMenuKey);
          break;
        case 'salary':
          setOpenKeys(salaryMenuKey);
          break;
        case 'dependent':
          setOpenKeys(dependantsMenuKey);
          break;
        case 'advances':
          setOpenKeys(advancesMenuKey);
          break;
        case 'adjustment':
          setOpenKeys(adjustmentMenuKey);
          break;
        default:
          break;
      }
    }
  }, [showMenu]);
  const handleResetAll = () => {
    !showMenu && setOpenKeys(allowanceMenuKey);
  };

  const handleData = (data: any) => {
    setNewDataAdvances(data);
  };

  const isArrayEqual = (x: any, y: any) => {
    return isEmpty(xorWith(x, y, isEqual));
  };
  const handleClose = () => {
    let newDataAdvancesUpdate = newDataAdvances.map(
      ({
        advanceConfigurationHistory,
        amount,
        createdAt,
        createdBy,
        deleteFlag,
        effectiveDate,
        id,
        paidAmount,
        paymentPlan,
        remain,
        status,
        term,
        updatedAt,
        updatedBy,
        user,
        version,
      }) => ({
        advanceConfigurationHistory,
        amount,
        createdAt,
        createdBy,
        deleteFlag,
        effectiveDate,
        id,
        paidAmount,
        paymentPlan,
        remain,
        status,
        term,
        updatedAt,
        updatedBy,
        user,
        version,
      }),
    );

    if (openKeys === advancesMenuKey && !isArrayEqual(dataAssignAdvance, newDataAdvancesUpdate)) {
      Modal.confirm({
        title: 'Are you sure current change will lost',
        icon: <ExclamationCircleOutlined />,
        content: '',
        okText: 'OK',
        okType: 'danger',
        cancelText: 'Cancel',
        onOk: async () => {
          handleResetAll();
          onClose();
        },
      });
    } else {
      handleResetAll();
      onClose();
    }
  };

  return (
    <>
      <EmployeeModal
        visible={isVisible}
        maskClosable={false}
        onCancel={handleClose}
        width={1200}
        style={{ top: 20 }}
        footer={null}
        // footer={
        //   !(status === 'deleted')
        //     ? !isLoadingData &&
        //       isShowFullMenu && (
        //         <FooterButtons
        //           profileStatus={employee?.employeeDetail?.status}
        //           permissions={dataPermissions}
        //           handleSave={handleSave}
        //           handleResetAll={handleResetAll}
        //           handleSaveAndSendRequestApprove={() => null}
        //           handleSaveAndApproveProfile={() => null}
        //           handleSendRequestApprove={handleSendRequestApprove}
        //           handleApproveProfile={handleApprove}
        //           handleRejectProfile={handleRejectProfile}
        //           handleDeleteProfile={handleDeleteProfile}
        //         />
        //       )
        //     : null
        // }
      >
        <EmployeeAddModalContent>
          <EmployeeAddModalContentLeft>
            <SideBarMenu
              showMenu={showMenu}
              status={status}
              isShowFullMenu={isShowFullMenu}
              employeeData={employeeInit}
              openKeys={openKeys}
              employeeMenuKey={employeeMenuKey}
              personalMenuKey={personalMenuKey}
              skillMenuKey={skillMenuKey}
              historyMenuKey={historyMenuKey}
              allowanceMenuKey={allowanceMenuKey}
              bonusMenuKey={bonusMenuKey}
              salaryMenuKey={salaryMenuKey}
              dependantsMenuKey={dependantsMenuKey}
              advancesMenuKey={advancesMenuKey}
              contractMenuKey={contractMenuKey}
              adjustmentMenuKey={adjustmentMenuKey}
              handleClickMenu={handleClickMenu}
              certificatesMenuKey={certificatesMenuKey}
            />
          </EmployeeAddModalContentLeft>
          <EmployeeAddModalDivider type="vertical" />
          <EmployeeAddModalContentRight>
            {isLoadingData ? (
              <ContainerLoading>
                <LoaderItem>
                  <Spin size="large" />
                </LoaderItem>
              </ContainerLoading>
            ) : (
              <EmployeeModalForm
                form={form}
                id="EmployeeForm"
                layout="vertical"
                // initialValues={employeeInit}
              >
                {
                  <>
                    {!showMenu || showMenu === 'allowance' ? (
                      <SalaryInformationFields
                        isShowComponents={openKeys === salaryMenuKey}
                        isCanUpdate={isShowFullMenu}
                        employeeData={employeeInit}
                        setEmployeeData={setEmployeeInit}
                        status={status}
                        callBack={callBack}
                      />
                    ) : null}
                    {!showMenu || showMenu === 'allowance' ? (
                      <AllowanceInformantionFields
                        isShowComponents={openKeys === allowanceMenuKey}
                        employeeData={employeeInit}
                        callBack={callBack}
                        monthYear={monthYear}
                      />
                    ) : null}
                    {!showMenu || showMenu === 'bonus' ? (
                      <BonusInformationFields
                        isShowComponents={openKeys === bonusMenuKey}
                        employeeData={employeeInit}
                        callBack={callBack}
                        monthYear={monthYear}
                      />
                    ) : null}

                    {!showMenu || showMenu === 'dependent' ? (
                      <DependantsInformantionFields
                        isShowComponents={openKeys === dependantsMenuKey}
                        employeeData={employeeInit}
                        callBack={callBack}
                        monthYear={monthYear}
                      />
                    ) : null}
                    {!showMenu || showMenu === 'advances' ? (
                      <AdvancesInformantionFields
                        isShowComponents={openKeys === advancesMenuKey}
                        employeeData={employeeInit}
                        callBack={callBack}
                        onReceiveData={handleData}
                        monthYear={monthYear}
                      />
                    ) : null}
                    {!showMenu || showMenu === 'contract' ? (
                      <ContractInformantionFields
                        isShowComponents={openKeys === contractMenuKey}
                        isCanUpdate={isShowFullMenu}
                        employeeData={employeeInit}
                        setEmployeeData={setEmployeeInit}
                        status={status}
                        callBack={callBack}
                        monthYear={monthYear}
                        payrollUpdate={payrollUpdate}
                        reloadContract={reloadContract}
                      />
                    ) : null}
                    {!showMenu || showMenu === 'adjustment' ? (
                      <AdjustmentInformationFields
                        isShowComponents={openKeys === adjustmentMenuKey}
                        isCanUpdate={isShowFullMenu}
                        employeeData={employeeInit}
                        setEmployeeData={setEmployeeInit}
                        status={status}
                        callBack={callBack}
                        monthYear={monthYear}
                        payrollUpdate={payrollUpdate}
                      />
                    ) : null}
                  </>
                }
              </EmployeeModalForm>
            )}
          </EmployeeAddModalContentRight>
        </EmployeeAddModalContent>
      </EmployeeModal>
      {/* <RejectFormModal
        isVisible={isVisibleRejectForm}
        itemId={itemId}
        onClose={handleCloseRejectForm}
        paramSortFilter={paramSortFilter}
      /> */}
    </>
  );
};

export default React.memo(EmployeeUpdateModal);

import i18next from 'i18next';

const validateReject = (_RejectData: any) => {
  let messageValidate: Array<string> = [];
  // Check required
  if (!_RejectData?.messageReject) {
    messageValidate.push(`${i18next.t('validation:required', { field: 'Reason' })}`);
  }
  return messageValidate;
};

export default validateReject;

import { EditTwoTone, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import ITVSearch from 'components/ITVSearch';
import ITVTable, { COLUMNS } from 'components/ITVTable';
import MenuAction from 'components/MenuAction';

import TableResizableTitle from 'components/TableResizableTitle';
import SearchPopup from 'features/employee/components/SearchPopup';
import { HeaderFilter } from 'features/masterData/General/components/pages/ManageCheckin/ListUser';
import { getAutoSendMailConfig } from 'features/onboard/onboardAction';
import { selectOnboard } from 'features/onboard/onboardSlice';
import { HRMModules } from 'hrm-common/extensions/enums/personel';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContainerBgWhite } from 'styles/containerBgWhite';
import { Header } from '../ManageEmailTemplate/ManageTemplate';
import ModalAddRowAutoSendMail from './component/ModalAddRowAutoSendMail';

const ConfigAutoSendMail = () => {
  const { t } = useTranslation(['action', 'onboard']);
  const dispatch = useAppDispatch();
  const modules: any = HRMModules;
  const listModule = Object.keys(modules).map((key: any) => {
    return modules[key];
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [sizePage, setSizePage] = useState(10);
  const [fData, setFData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [, setIsEdit] = useState(true);
  const [editRecord, setEditRecord]: any = useState();
  const onboard = useAppSelector(selectOnboard);
  const configAutoSendMailData = onboard?.configAutoSendMailData?.result;
  const [search, setSearch] = useState('');
  const [, setDataOri] = useState([]);

  useEffect(() => {
    dispatch(getAutoSendMailConfig({}));
  }, [dispatch]);

  useEffect(() => {
    setCurrentPage(1);
    if (configAutoSendMailData?.length) {
      const data = configAutoSendMailData
        ?.slice()
        ?.map((item: any, key: any) => ({ ...item, key }))
        ?.sort((a: any, b: any) => {
          if (a?.module > b?.module) return 1;
          if (a?.module < b?.module) return -1;
          return 0;
        });
      if (search) {
        const temp = data?.filter((item: any) => {
          return (
            item.templateName?.toLowerCase().includes(search) ||
            item.actionName?.toLowerCase().includes(search)
          );
        });
        setFData(temp);
        setDataOri(temp);
      } else {
        setFData(data);
        setDataOri(data);
      }
    }
  }, [configAutoSendMailData, search]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    setIsEdit(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsEdit(true);
    if (editRecord) {
      setEditRecord(undefined);
    }
  };

  const handleStatusValue = (value: any) => {
    if (value === true) {
      return <div style={{ color: '#dc2626' }}>{t('onboard:deactive_status')}</div>;
    } else {
      return <div style={{ color: '#059669' }}>{t('onboard:active_status')}</div>;
    }
  };

  const menuOnline = [
    {
      name: t('action:edit'),
      icon: <EditTwoTone />,
      // disabled: !isEdit,
      handleClick: (record: any) => {
        setEditRecord(record);
        setIsModalVisible(true);
        setIsEdit(false);
      },
    },
  ];

  const columns: COLUMNS[] = [
    {
      title: t('onboard:LabelFormEmail.module'),
      dataIndex: 'module',
      key: 'module',
      width: 120,
      minWidth: 120,
      ellipsis: true,
      ...getColumnSearchProps('module', listModule),
      render: (value: string, record: any, index: any) => {
        const trueIndex = index + sizePage * (currentPage - 1);
        const obj: any = {
          children: value,
          props: {},
        };
        if (index >= 1 && record?.module === fData[trueIndex - 1]?.module) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== fData.length && record?.module === fData[trueIndex + i]?.module;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }
        return obj;
      },
    },
    {
      title: t('onboard:LabelFormEmail.template_name'),
      dataIndex: 'templateName',
      key: 'templateName',
      width: 150,
      minWidth: 150,
    },
    {
      title: t('onboard:name'),
      dataIndex: 'actionName',
      key: 'actionName',
      width: 150,
      minWidth: 150,
    },
    {
      title: t('onboard:action_description'),
      dataIndex: 'description',
      key: 'description',
      width: 300,
      minWidth: 300,
    },
    {
      title: t('onboard:email_name'),
      key: 'mailNameFrom',
      dataIndex: 'mailNameFrom', //status in back
      width: 150,
      minWidth: 150,
    },
    {
      title: t('onboard:status_col'),
      key: 'status',
      dataIndex: 'deleteFlag', //status in back
      width: 100,
      minWidth: 100,
      render: (record: any) => {
        return <div>{handleStatusValue(record)}</div>;
      },
    },
    {
      title: t('action:action'),
      key: 'action',
      width: 100,
      minWidth: 100,
      render: (record: any) => {
        return <MenuAction menu={menuOnline} data={record} />;
      },
    },
  ];

  const onFilterPopupConfirm = (selectedKeys: any, confirm: any) => {
    // confirm && confirm();
  };

  function getColumnSearchProps(dataIndex: any, options: any) {
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
        <SearchPopup
          searchText={selectedKeys[0]}
          onPressEnter={() => confirm()}
          options={options}
          confirm={confirm}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          onSearchTextChange={(e: any) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onClickFilter={(checkedValues: any) => onFilterPopupConfirm(selectedKeys, confirm)}
        />
      ),
      filterIcon: (filtered: any) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      onFilter: (value: any, record: any) => {
        return record?.[dataIndex] === value;
      },
    };
  }

  const handleSearch = (key: string) => {
    const text = key.toLowerCase();
    if (text) {
      const temp = configAutoSendMailData?.filter((item: any) => {
        return (
          item.templateName?.toLowerCase().includes(search) ||
          item.actionName?.toLowerCase().includes(search)
        );
      });
      setSearch(text);
      setFData(temp);
    } else {
      setSearch('');
      setFData(configAutoSendMailData);
    }
  };

  const components = {
    header: {
      cell: TableResizableTitle,
    },
  };

  return (
    <>
      <ModalAddRowAutoSendMail
        listModule={listModule}
        isModalVisible={isModalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        editRecord={editRecord}
        setEditRecord={setEditRecord}
        setIsModalVisible={setIsModalVisible}
      />
      <ContainerBgWhite>
        <Header>
          <HeaderFilter>
            <ITVSearch handleSearch={handleSearch} style={{ width: 300 }} search="mail" />
          </HeaderFilter>
          <Button
            onClick={showModal}
            type="primary"
            icon={<PlusOutlined />}
            style={{ borderRadius: 4 }}
          >
            {t('onboard:add_new')}
          </Button>
        </Header>
        <ITVTable
          components={components}
          columns={columns}
          data={fData}
          width={1300}
          isRowSelect={false}
          isScroll={true}
          rowKey={['userId']}
          limit={sizePage}
          setLimit={setSizePage}
          offset={currentPage}
          setOffset={setCurrentPage}
        />
      </ContainerBgWhite>
    </>
  );
};

export default ConfigAutoSendMail;

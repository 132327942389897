import { EditTwoTone, EyeTwoTone, FileTextTwoTone, PlusOutlined } from '@ant-design/icons';
import { Modal, message } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import ITVSearch from 'components/ITVSearch';
import ITVTable, { COLUMNS } from 'components/ITVTable';
import MenuAction from 'components/MenuAction';
import { MODE } from 'constants/types';
import { setCheckRow } from 'features/employee/employeeSlice';
import { HeaderAdd } from 'features/masterData/Department/styled';
import { GroupTemplateEmail } from 'hrm-common/extensions/interfaces/personel';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ContainerBgWhite } from 'styles/containerBgWhite';
import TypeUtils from 'utils/typeUtils';
import SendTemplate from '../../components/SendTemplate';
import TemplateEmailModal from '../../components/TemplateEmailModal';
import { EmployeeOnboardSendMail } from '../../interfaces/templateEmail';
import { getListEmailServer, sendMail } from '../../onboardAction';
import { selectOnboard } from '../../onboardSlice';

message.config({
  maxCount: 50,
});

const ManageTemplate = () => {
  const dispatch = useAppDispatch();
  const [sizePage, setSizePage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const onboard: any = useAppSelector(selectOnboard);
  const listGroupEmailTemplate: GroupTemplateEmail[] = onboard?.groupTemplateEmail?.result;
  const templateList: any[] = onboard.templates;
  const isLoadingTemplate: boolean = useAppSelector(
    (state: any) => state.onboard.isLoadingTemplate,
  );

  const [sendTemplateVisible, setSendTemplateVisible] = useState(false);
  const [newTemplateVisible, setNewTemplateVisible] = useState(false);
  const [modalType, setModalType] = useState<MODE>(MODE.ADD);
  const [currentViewRow, setCurrentViewRow] = useState<any>();
  const [, setText] = useState('');
  const { t } = useTranslation(['onboard', 'insurance', 'action']);
  const [templates, setTemplates] = useState(templateList);

  useEffect(() => {
    dispatch(getListEmailServer({}));
  }, [dispatch]);

  const menu: any = [
    {
      name: t('btn_view'),
      icon: <EyeTwoTone />,
      handleClick: (data: any) => {
        setCurrentViewRow(data);
        setModalType(MODE.VIEW);
        setNewTemplateVisible(true);
      },
    },
    {
      name: t('insurance:edit'),
      icon: <EditTwoTone twoToneColor="#F1592A" />,
      handleClick: (data: any) => {
        setCurrentViewRow(data);
        setModalType(MODE.EDIT);
        setNewTemplateVisible(true);
      },
    },
    {
      name: t('send_mail'),
      icon: <FileTextTwoTone twoToneColor="#FFF300" />,
      handleClick: (data: any) => {
        setCurrentViewRow(data);
        setSendTemplateVisible(true);
      },
    },
  ];

  const menuMailUnactive = [
    {
      name: t('btn_view'),
      icon: <EyeTwoTone />,
      handleClick: (data: any) => {
        setCurrentViewRow(data);
        setModalType(MODE.VIEW);
        setNewTemplateVisible(true);
      },
    },
    {
      name: t('insurance:edit'),
      icon: <EditTwoTone twoToneColor="#F1592A" />,
      handleClick: (data: any) => {
        setCurrentViewRow(data);
        setModalType(MODE.EDIT);
        setNewTemplateVisible(true);
      },
    },
  ];

  useEffect(() => {
    setCurrentPage(1);
    if (templateList?.length) {
      const data = templateList
        ?.slice()
        ?.map((item: any, key: any) => ({ ...item, key }))
        ?.sort((a: any, b: any) => {
          if (a?.group.module > b?.group.module) return 1;
          if (a?.group.module < b?.group.module) return -1;
          return 0;
        });
      setTemplates(data);
    }
  }, [templateList]);

  const columns: COLUMNS[] = [
    {
      title: t('LabelFormEmail.module'),
      dataIndex: 'group',
      key: 'group',
      width: 80,
      minWidth: 80,
      render: (value: any, record: any, index: any) => {
        const trueIndex = index + sizePage * (currentPage - 1);
        const obj: any = {
          children: record?.group.module,
          props: {},
        };
        if (index >= 1 && record?.group.module === templates[trueIndex - 1]?.group.module) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== templates.length &&
            record?.group.module === templates[trueIndex + i]?.group.module;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }
        return obj;
      },
    },
    {
      title: t('form_group.group_name'),
      dataIndex: 'group',
      key: 'group',
      width: 150,
      minWidth: 150,
      render: (value: GroupTemplateEmail, record: any, index: any) => {
        const trueIndex = index + sizePage * (currentPage - 1);
        const obj: any = {
          children: record?.group.name,
          props: {},
        };
        if (index >= 1 && record?.group.name === templates[trueIndex - 1]?.group.name) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== templates.length &&
            record?.group.name === templates[trueIndex + i]?.group.name;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }
        return obj;
      },
    },
    {
      title: 'Mail Id',
      dataIndex: 'id',
      key: 'id',
      width: 100,
      minWidth: 100,
      align: 'left',
    },
    {
      title: t('onboard:LabelFormEmail.template_name'),
      dataIndex: 'name',
      key: 'name',
      width: 200,
      minWidth: 200,
      render: (text: string) => text,
    },
    {
      title: t('insurance:action'),
      dataIndex: 'action',
      key: 'action',
      width: 50,
      align: 'center',
      minWidth: 50,
      render: (text: string, record: any) => {
        return (
          <MenuAction menu={record.status === false ? menuMailUnactive : menu} data={record} />
        );
      },
    },
  ];

  const handleSearch = (key: string) => {
    const text = key.toLowerCase();
    if (text) {
      const temp = templateList?.filter((item: any) => {
        return item.name?.toLowerCase().includes(text);
      });
      setText(text);
      setTemplates(temp);
    } else {
      setText('');
      setTemplates(templateList);
    }
  };

  const handleListId = (value: any, type: string) => {
    let temp: any = [];
    let idDepartment: any = [];
    temp = value.filter((item: any) => {
      return TypeUtils.isString(item) ? item.includes(type) : null;
    });
    temp.map((item: any) => {
      const temp = item.split('_');
      return idDepartment.push(parseInt(temp[1]));
    });
    return idDepartment;
  };

  const handleSendMail = (
    from: number,
    type: string,
    to: string[],
    cc: string[],
    bcc: string[],
    tableData: EmployeeOnboardSendMail[],
  ) => {
    let ids: any = [];
    ids = to
      .filter((item) => (TypeUtils.isString(item) ? !item.includes('department_') : null))
      .filter((item) => (TypeUtils.isString(item) ? !item.includes('group_') : null));
    let toRole: any = [];
    toRole = to.filter((item) => TypeUtils.isNumber(item));
    let toDepartment: any = [];
    toDepartment = handleListId(to, 'department_');
    let toGroup: any = [];
    toGroup = handleListId(to, 'group_');

    let idcc: any = [];
    idcc = cc
      .filter((item) => (TypeUtils.isString(item) ? !item.includes('department_') : null))
      .filter((item) => (TypeUtils.isString(item) ? !item.includes('group_') : null));
    let ccRole: any = [];
    ccRole = cc.filter((item) => TypeUtils.isNumber(item));
    let ccDepartment: any = [];
    ccDepartment = handleListId(cc, 'department_');
    let ccGroup: any = [];
    ccGroup = handleListId(cc, 'group_');

    let idbcc: any = [];
    idbcc = bcc
      .filter((item) => (TypeUtils.isString(item) ? !item.includes('department_') : null))
      .filter((item) => (TypeUtils.isString(item) ? !item.includes('group_') : null));
    let bccRole: any = [];
    bccRole = bcc.filter((item) => TypeUtils.isNumber(item));
    let bccDepartment: any = [];
    bccDepartment = handleListId(bcc, 'department_');
    let bccGroup: any = [];
    bccGroup = handleListId(bcc, 'group_');

    dispatch(
      sendMail({
        from,
        type,
        ids,
        toRole,
        toDepartment,
        toGroup,
        idcc,
        ccRole,
        ccDepartment,
        ccGroup,
        idbcc,
        bccRole,
        bccDepartment,
        bccGroup,
        idMailTemplate: currentViewRow?.id,
        callBack: (response: any) => {
          if (!response.error) {
            let errorList = response.filter((item: any) => item.status === 'error');
            let successList = response.filter((item: any) => item.status === 'success');

            errorList?.forEach((item: any) => {
              const user = tableData?.find((i) => i.user?.id === item.userId)?.user;
              const mail = user ? (type === 'personal' ? user.personalEmail : user.email) : null;
              message.error(t('send_mail_failed', { mail: mail }));
            });

            successList?.forEach((item: any) => {
              const user = tableData?.find((i) => i.user?.id === item.userId)?.user;
              const mail = user ? (type === 'personal' ? user.personalEmail : user.email) : null;
              message.success(t('send_mail_success', { mail: mail }));
            });
            setSendTemplateVisible(false);
          }
        },
      }),
    );
    dispatch(setCheckRow([]));
  };

  return (
    <ContainerBgWhite>
      <Header>
        <HeaderFilter>
          <ITVSearch handleSearch={handleSearch} style={{ width: 300 }} />
        </HeaderFilter>
        <HeaderAdd
          style={{ margin: 0 }}
          icon={<PlusOutlined />}
          onClick={() => {
            setModalType(MODE.ADD);
            setNewTemplateVisible(true);
          }}
        >
          {t('add_new')}
        </HeaderAdd>
      </Header>

      <ITVTable
        columns={columns}
        data={templates}
        isRowSelect={false}
        totalIni={templates?.length}
        totalResult={templates?.length}
        setSelectedUser={null}
        loading={isLoadingTemplate}
        setOffset={setCurrentPage}
        offset={currentPage}
        setLimit={setSizePage}
        limit={sizePage}
      />

      <Modal
        visible={sendTemplateVisible}
        footer={null}
        onCancel={() => setSendTemplateVisible(false)}
        title={t('send_mail')}
        maskClosable={false}
        width={1200}
        centered={true}
        destroyOnClose={true}
      >
        <SendTemplate
          handleSubmit={handleSendMail}
          submitText={t('send')}
          selectionType="checkbox"
          actionVisible={false}
          rowKey={['user', 'id']}
          data={currentViewRow}
        />
      </Modal>
      {newTemplateVisible && (
        <TemplateEmailModal
          listGroupEmailTemplate={listGroupEmailTemplate}
          newTemplateVisible={newTemplateVisible}
          setNewTemplateVisible={setNewTemplateVisible}
          type={modalType}
          onlyView={[MODE.ADD, MODE.EDIT].includes(modalType) ? false : true}
          dataTemplateEmail={currentViewRow}
        />
      )}
    </ContainerBgWhite>
  );
};

export default ManageTemplate;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const ContainerSelectApply = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;
const HeaderFilter = styled.div`
  display: flex;
  gap: 0.8rem;
`;

import { FilterOutlined } from '@ant-design/icons';
import { Avatar, Divider, List, Select, Skeleton, Tooltip, Typography } from 'antd';
import { useAppSelector } from 'app/hooks';
import ITVSearch from 'components/ITVSearch';
import ListComponent from 'components/ListComponent';
import {
  AppCameraAI,
  ListUserCamera,
  Place,
  TypePerson,
} from 'features/masterData/General/Interfaces/CameraAI';
import { getAppCameraAI, getPersonCameraAI, getPlace } from 'features/masterData/masterDataAction';
import { actions, selectMasterData } from 'features/masterData/masterDataSlice';
import { selectTimesheet, setCurrentEmployee } from 'features/timesheet/pages/timesheetSlice';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import CustomButton from 'styles/buttonStyled';
import { removeAccents } from 'utils/text';

const { Option } = Select;
const { Text } = Typography;

export default function ListUser() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['masterConfig, employee', 'timesheet']);
  const {
    checkCurrentUser,
    checkCurrentPlace,
    checkCurrentApp,
    resetEmployee,
    setTypePerson,
    resetDataCheckInOutCamera,
    resetDataDevice,
  } = actions;
  const masterData = useAppSelector(selectMasterData);
  const timesheet = useAppSelector(selectTimesheet);

  const currentEmployee: ListUserCamera = timesheet?.currentEmployee;
  const dataEmployee: ListUserCamera[] = masterData?.dataEmployee;
  const listAppCameraAI: AppCameraAI[] = masterData?.listAppCameraAI?.result;
  const typePerson: TypePerson = masterData?.typePerson;
  const [dataEmployeeState, setDataEmployeeState] = useState<ListUserCamera[]>([]);
  const [defaultEmployee, setDefaultEmployee] = useState<ListUserCamera>({
    avatar: '',
    email: '',
    employeeId: '',
    name: '',
  });

  const dataPlace: Place[] = masterData?.dataPlace;
  const loadingParent: boolean = masterData?.loadingParent;
  const [currentApp, setCurrentApp] = useState<number>();
  const [currentPlace, setCurrentPlace] = useState<number>();
  const [statePlaces, setStatePlaces] = useState<Place[]>([]);

  useEffect(() => {
    dispatch(getAppCameraAI({}));
  }, [dispatch]);

  useEffect(() => {
    if (dataPlace) {
      setStatePlaces(dataPlace);
      if (dataPlace?.[0]?.id) {
        dispatch(getPersonCameraAI(dataPlace?.[0]?.id)); //get user after change app
        dispatch(checkCurrentPlace(dataPlace?.[0]?.id));
        setCurrentPlace(dataPlace?.[0]?.id);
      }
      if (!dataPlace?.[0]?.id) {
        dispatch(resetEmployee([]));
        dispatch(checkCurrentPlace(''));
        dispatch(resetDataDevice([]));
        setCurrentPlace(undefined);
        dispatch(
          resetDataCheckInOutCamera({
            cameraType: '',
            checkin: '',
            checkout: '',
            date: '',
            name: '',
            place: '',
            result: [],
          }),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPlace]);

  useEffect(() => {
    if (currentApp && currentApp >= 0) {
      dispatch(getPlace({ id: currentApp }));
    }
  }, [currentApp, dispatch]);

  useEffect(() => {
    if (listAppCameraAI?.length) {
      setCurrentApp(listAppCameraAI?.[0]?.id);
    }
  }, [listAppCameraAI]);

  useEffect(() => {
    dispatch(checkCurrentUser(true));
    return () => {
      dispatch(checkCurrentUser(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    dispatch(setCurrentEmployee(defaultEmployee));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultEmployee]);

  useEffect(() => {
    const userHaveName = dataEmployee
      .filter((i) => i.name)
      .sort((a, b) => {
        var nameA = a.name.toUpperCase(); // ignore upper and lowercase
        var nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1; //nameA comes first
        }
        if (nameA > nameB) {
          return 1; // nameB comes first
        }
        return 0; // names must be equal
      });
    const userNoName = dataEmployee.filter((i) => !i.name);
    const sortData = [...userHaveName, ...userNoName];
    dataEmployee && setDefaultEmployee(sortData[0]);
    dataEmployee && setDataEmployeeState(sortData);
  }, [dataEmployee]);

  const handleSearch = useCallback(
    (key: string) => {
      const text = removeAccents(key.toLowerCase());
      if (text) {
        const temp = dataEmployee?.filter((item: any) => {
          return removeAccents(item?.name?.toLowerCase())?.includes(text);
        });
        const userHaveName = temp
          .filter((i) => i.name)
          .sort((a, b) => {
            var nameA = a.name.toUpperCase(); // ignore upper and lowercase
            var nameB = b.name.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1; //nameA comes first
            }
            if (nameA > nameB) {
              return 1; // nameB comes first
            }
            return 0; // names must be equal
          });
        const userNoName = temp.filter((i) => !i.name);
        const sortData = [...userHaveName, ...userNoName];
        setDataEmployeeState(sortData);
      } else {
        const userHaveName = dataEmployee
          .filter((i) => i.name)
          .sort((a, b) => {
            var nameA = a.name.toUpperCase(); // ignore upper and lowercase
            var nameB = b.name.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1; //nameA comes first
            }
            if (nameA > nameB) {
              return 1; // nameB comes first
            }
            return 0; // names must be equal
          });
        const userNoName = dataEmployee.filter((i) => !i.name);
        const sortData = [...userHaveName, ...userNoName];
        setDataEmployeeState(sortData);
      }
    },
    [dataEmployee],
  );

  const loadMoreData = () => {};

  const filterUserWithPlace = (placeId: any) => {
    dispatch(checkCurrentPlace(placeId));
    setCurrentPlace(placeId);
    dispatch(getPersonCameraAI(placeId)); //get user after change place
  };
  const filterUserWithApp = (currentApp: any) => {
    dispatch(getPlace({ id: currentApp }));
    setCurrentApp(currentApp);
    dispatch(checkCurrentApp(currentApp));
  };

  function FooterList() {
    return (
      <ContainerFooter>
        <CustomButton
          aType="outline"
          style={{ width: '100%', height: 35 }}
          onClick={() => dispatch(setTypePerson(TypePerson.STRANGER))}
        >
          {t('masterConfig:manage_checkin.view_strangers')}
        </CustomButton>
      </ContainerFooter>
    );
  }

  return (
    <WrapperList>
      <>
        <ContainerHeader id="header_list">
          <TitleList>{t('employee:employee_manage.title')}</TitleList>
          <Tooltip
            align={{
              targetOffset: [0, 16],
              overflow: { adjustX: true, adjustY: true },
            }}
            placement="rightTop"
            title={
              <>
                <HeaderFilter>{t('masterConfig:manage_checkin.filter')}</HeaderFilter>
                <Divider style={{ margin: '12px 0 0 0' }} />
                <ContentFilter>
                  <WrapperFilter>
                    <Text strong>{t('masterConfig:manage_place.app')}:</Text>
                    <CustomSelect
                      placeholder={t('masterConfig:manage_place.please_select_app')}
                      dropdownStyle={{ zIndex: '1071' }}
                      value={currentApp}
                      onChange={filterUserWithApp}
                    >
                      {listAppCameraAI &&
                        listAppCameraAI?.map((app) => <Option value={app.id}>{app.name}</Option>)}
                    </CustomSelect>
                  </WrapperFilter>
                  <WrapperFilter>
                    <Text strong>{t('masterConfig:manage_place.place')}:</Text>
                    <CustomSelect
                      placeholder={t('masterConfig:manage_checkin.please_select_place')}
                      dropdownStyle={{ zIndex: '1071' }}
                      defaultValue={statePlaces?.[0]?.id}
                      value={currentPlace}
                      onChange={filterUserWithPlace}
                    >
                      {statePlaces &&
                        statePlaces?.map((place: any) => (
                          <Option value={place?.id}>{place?.place}</Option>
                        ))}
                    </CustomSelect>
                  </WrapperFilter>
                </ContentFilter>
              </>
            }
            trigger="click"
            color="white"
            overlayInnerStyle={{ padding: '1rem', width: 320 }}
          >
            <ContainerFilter>
              <FilterOutlined />
              <TextAddFilter>{t('masterConfig:manage_checkin.add_filter')}</TextAddFilter>
            </ContainerFilter>
          </Tooltip>
        </ContainerHeader>
        <ContainerSearch>
          <ITVSearch style={{ width: '100%' }} handleSearch={handleSearch} />
        </ContainerSearch>
      </>
      <ListComponent data={dataEmployeeState} loadMoreData={loadMoreData} height="700px">
        <List
          dataSource={dataEmployeeState}
          loading={!dataEmployeeState}
          renderItem={(item, key) => (
            <CustomListItem
              key={key}
              onClick={() => {
                dispatch(setCurrentEmployee(item));
                dispatch(setTypePerson(TypePerson.EMPLOYEE));
              }}
              currentEmployee={item?.employeeId === currentEmployee?.employeeId}
              typePerson={typePerson}
            >
              <Skeleton
                avatar
                loading={loadingParent}
                active
                paragraph={{ rows: 1 }}
                style={{ margin: '0 16px' }}
              >
                <List.Item.Meta
                  style={{ alignItems: 'center', marginLeft: '16px' }}
                  avatar={<Avatar size={40} src={item.avatar} />}
                  title={item.name}
                  description={`ID: ${item.employeeId}`}
                />
              </Skeleton>
            </CustomListItem>
          )}
        />
      </ListComponent>
      <FooterList />
    </WrapperList>
  );
}

export const CustomListItem = styled(List.Item)`
  cursor: pointer;
  transition: 0.3s;
  .ant-list-item-meta-content {
    font-family: 'Roboto';
  }
  .ant-list-item-meta-title {
    width: 90%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .ant-list-item-meta-description {
    width: 90%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &:hover {
    background-color: var(--cl_primary400);
    .ant-list-item-meta-title {
      color: white;
    }
    .ant-list-item-meta-description {
      color: white;
    }
    /* box-shadow: rgb(140 140 140 / 35%) 0px 0px 30px 4px; */
    /* .ant-list-item-meta-content {
      color: ${(props: { currentEmployee: boolean; typePerson: TypePerson }) =>
      props.typePerson !== TypePerson.STRANGER && props.currentEmployee && 'white'};
    } */
  }
  ${(props: { currentEmployee: boolean; typePerson: TypePerson }) =>
    props.typePerson !== TypePerson.STRANGER &&
    props.currentEmployee &&
    css`
      .ant-list-item-meta-title {
        color: white;
      }
      .ant-list-item-meta-description {
        color: white;
      }
      background-color: var(--cl_primary400);
    `};
`;

export const WrapperList = styled.div`
  @media screen and (max-width: 1170px) {
    width: 100%;
  }
  @media screen and (max-width: 1600px) {
    width: 25%;
  }
  width: 20%;
  box-shadow: rgb(0 0 0 / 20%) 0px 0px 4px;
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
`;

export const HeaderFilter = styled.div`
  color: black;
  font-size: 16px;
  font-weight: 500;
`;

export const ContentFilter = styled.div`
  color: black;
`;

export const ContainerHeader = styled.div`
  padding: 12px 16px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`;

export const ContainerFooter = styled.div`
  padding: 10px 16px;
  display: flex;
  justify-content: center;
`;

const CustomSelect = styled(Select)`
  width: 81%;
`;

const TitleList = styled.div`
  font-weight: 500;
  font-size: 20px;
`;
const ContainerFilter = styled.div`
  cursor: pointer;
  color: var(--cl_primary500);
  &:hover {
    color: var(--cl_primary600);
  }
`;

const TextAddFilter = styled.span`
  cursor: pointer;
  padding-left: 4px;
  text-transform: uppercase;
  user-select: none;
`;

const ContainerSearch = styled.div`
  padding: 0 16px 16px 16px;
`;

const WrapperFilter = styled.div`
  display: flex;
  gap: 0.6rem;
  flex-wrap: wrap;
  margin-top: 10px;
  align-items: center;
  justify-content: space-between;
`;

import styled, { css } from 'styled-components';
import { Form, Row, Col, Switch, Select } from 'antd';
import CustomFormItem from 'styles/formStyled';
import CustomInput from 'styles/inputStyled';
import CustomInputNumber from 'styles/inputNumberStyled';
import CustomDatePicker from 'styles/datePickerStyled';

export const ContainerStyled = styled.div`
  padding: 12px;
  min-height: 300px;
  max-height: 600px;
  overflow: auto;
`;

export const FormStyled = styled(Form)`
  padding-top: 6px;
  .ant-form-item-label > label.ant-form-item-required::before {
    content: none;
  }

  .ant-form-item-label > label.ant-form-item-required::after {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    padding-left: 2px;
    content: '*';
  }

  &::-webkit-scrollbar-track {
    margin-top: 20px;
  }
  &::-webkit-scrollbar {
    background-color: var(--cl_white);
  }
`;

export const HeaderContainerStyled = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 12px 8px 6px 8px;
`;

export const HeaderStyled = styled.div`
  font-size: var(--txt_font_size_h2);
  font-weight: var(--txt_font_weight_medium);
`;

interface ContainerItemStyledProps {
  disabledform?: 'yes' | 'no';
}

export const ContentStyled = styled.div`
  background-color: white;
  ${(props: ContainerItemStyledProps) =>
    props.disabledform === 'yes' &&
    css`
      pointer-events: none;
      opacity: 0.4;
    `};
`;

export const RowStyled = styled(Row)``;

export const ColStyled = styled(Col)``;

export const FormItemStyled = styled(Form.Item)``;

export const SelectStyled = styled(Select)`
  border-radius: 4px !important;
  .ant-select-selector {
    height: 39px !important;
    padding-top: 4px !important;
  }
`;

export const SelectOptionStyled = styled(Select.Option)`
  padding-top: 2px;
  padding-bottom: 2px;
`;

export const SwitchStyled = styled(Switch)`
  margin-left: 10px;
`;

export const ItemColorStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const DotColorStyled = styled.div`
  height: 10px;
  width: 10px;
  margin-right: 5px;
  border-radius: 5px;
`;

export const CustomFormItemHorizontal = styled(CustomFormItem)`
  width: 100%;
  height: 40px;
  justify-content: center;
  margin-bottom: 24px;
`;

export const CustomInputNumberHorizontal = styled(CustomInputNumber)`
  border: none;
  outline: none;
  box-shadow: none;
  height: 30px;
  max-width: 80px;
  border-bottom: 1px solid;
  border-radius: 0px;
  background-color: var(--cl_white) !important;
  &:focus {
    outline: none;
    border: none;
    box-shadow: none;
    border-bottom: 1px solid;
  }
  .ant-input-number-disabled {
    background-color: var(--cl_white) !important;
  }
`;

export const CustomInputHorizontal = styled(CustomInput)`
  border: none;
  outline: none;
  box-shadow: none;
  height: 30px;
  max-width: 80px;
  border-bottom: 1px solid;
  border-radius: 0px;
  &:focus {
    outline: none;
    border: none;
    box-shadow: none;
    border-bottom: 1px solid;
  }
`;

export const CustomInputHorizontalExtra = styled(CustomInput)`
  border: none;
  outline: none;
  box-shadow: none;
  height: 30px;
  max-width: 150px;
  border-bottom: 1px solid;
  border-radius: 0px;
  &:focus {
    outline: none;
    border: none;
    box-shadow: none;
    border-bottom: 1px solid;
  }
  .ant-input {
    outline: none;
    border: none;
    box-shadow: none;
    border-bottom: 1px solid;
  }
  .ant-input-group-addon {
    outline: none;
    border: none;
    box-shadow: none;
    background-color: var(--cl_white);
  }
`;

export const SkeletonContainerStyled = styled.div`
  margin-left: 12px;
  margin-right: 12px;
`;

export const CustomDatePickerStyled = styled(CustomDatePicker)`
  .custom-dropdown
    .ant-picker-panel-container
    .ant-picker-panel
    .ant-picker-date-panel
    .ant-picker-header
    > button.ant-picker-header-super-prev-btn,
  button.ant-picker-header-super-next-btn,
  button.ant-picker-year-btn {
    display: none !important;
  }
`;

import styled from 'styled-components';

export const ContainerStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0.5rem;
`;

export const LabelStyled = styled.div`
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  letter-spacing: 0.5px;
  color: black;
`;

export const IconStyled = styled.img`
  height: 20px;
  margin-right: 5px;
`;

import styled from 'styled-components';
import { Select } from 'antd';

const CustomSelect = styled(Select)`
  .ant-select-selector {
    width: 100%;
    height: 39px !important;
    border-radius: 4px !important;
    align-items: center !important;
  }

  & > input {
    font-size: 14px;
    color: var(--cl_gray900);
  }
  &[disabled] {
    background-color: hsl(0, 0%, 95%);
    border-color: hsl(0, 0%, 90%);
    color: var(--cl_gray900);
  }
`;

export default CustomSelect;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { GroupTemplateEmail } from 'hrm-common/extensions/interfaces/personel';
import { ResponseTemplateEmail } from './interfaces/templateEmail';
import {
  createAutoSendMailConfig,
  getAutoConfigMailIdListByModule,
  getAutoConfigSendMailByModule,
  getAutoSendMailConfig,
  getGroupTemplateEmail,
  getListAction,
  getListEmailServer,
  getListGroupEmail,
  getOnboard,
  getOnboardByEmployee,
  getOnboardStep,
  getProcess,
  getProcessStep,
  getTemplates,
  getVariablesEmail,
  updateAutoSendMailConfig,
} from './onboardAction';

export type OnBoardState = {
  isLoading: boolean;
  isLoadingGroupTemplate: boolean;
  isLoadingTemplate: boolean;
  isLoadingOnboard: boolean;
  isLoadingProcess: boolean;
  isLoadingEmployeeOnboard: boolean;
  isLoadingStep: boolean;
  onboards: [];
  error: string | null;
  templates: Array<ResponseTemplateEmail>;
  total: number;
  groupTemplateEmail: Array<GroupTemplateEmail>;
  variablesEmail: [];
  variableEmail: string;
  configAutoSendMailData: [];
  actionIdListByModule: [];
  mailIdList: [];
  listAction: [];
  listEmailServer: [];
  listGroupEmail: [];
};

const initialState: OnBoardState = {
  isLoading: false,
  isLoadingGroupTemplate: false,
  isLoadingTemplate: false,
  isLoadingOnboard: false,
  isLoadingProcess: false,
  isLoadingEmployeeOnboard: false,
  isLoadingStep: false,
  onboards: [],
  error: null,
  templates: [],
  total: 0,
  groupTemplateEmail: [],
  variablesEmail: [],
  variableEmail: '',
  configAutoSendMailData: [],
  actionIdListByModule: [],
  mailIdList: [],
  listAction: [],
  listEmailServer: [],
  listGroupEmail: [],
};

const onboardSlice = createSlice({
  name: 'onboard',
  initialState,
  reducers: {
    setVariableEmail: (state, action: PayloadAction<string>) => {
      state.variableEmail = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //_________________ template email_____________________
      .addCase(getGroupTemplateEmail.pending, (state) => {
        state.isLoadingGroupTemplate = true;
      })
      .addCase(
        getGroupTemplateEmail.fulfilled,
        (state, action: PayloadAction<GroupTemplateEmail[]>) => {
          state.isLoadingGroupTemplate = false;
          state.groupTemplateEmail = action.payload ? action.payload : [];
        },
      )
      .addCase(getGroupTemplateEmail.rejected, (state, action: any) => {
        state.isLoadingGroupTemplate = false;
        state.error = action.payload;
      })

      .addCase(getVariablesEmail.fulfilled, (state, action: any) => {
        state.variablesEmail = action.payload;
      })
      .addCase(getVariablesEmail.rejected, (state, action: any) => {
        state.error = action.payload;
      })

      .addCase(getTemplates.pending, (state) => {
        state.isLoadingTemplate = true;
        state.error = null;
      })
      .addCase(getTemplates.fulfilled, (state, action: any) => {
        state.isLoadingTemplate = false;
        state.error = null;
        state.templates = action?.payload?.result;
        state.total = action?.payload?.total;
      })
      .addCase(getTemplates.rejected, (state, action: any) => {
        state.isLoadingTemplate = false;
        state.error = action.payload;
      })
      .addCase(getAutoSendMailConfig.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAutoSendMailConfig.fulfilled, (state, action: any) => {
        state.configAutoSendMailData = action.payload;
        state.isLoading = false;
      })
      .addCase(getAutoSendMailConfig.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getAutoConfigSendMailByModule.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAutoConfigSendMailByModule.fulfilled, (state, action: any) => {
        state.actionIdListByModule = action.payload ? action.payload : [];
        state.isLoading = false;
      })
      .addCase(getAutoConfigSendMailByModule.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(createAutoSendMailConfig.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createAutoSendMailConfig.fulfilled, (state, action: any) => {
        state.isLoading = false;
      })
      .addCase(createAutoSendMailConfig.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getAutoConfigMailIdListByModule.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAutoConfigMailIdListByModule.fulfilled, (state, action: any) => {
        state.isLoading = false;
        state.mailIdList = action.payload;
      })
      .addCase(getAutoConfigMailIdListByModule.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(updateAutoSendMailConfig.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateAutoSendMailConfig.fulfilled, (state, action: any) => {
        state.isLoading = false;
      })
      .addCase(updateAutoSendMailConfig.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      //Action
      .addCase(getListAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getListAction.fulfilled, (state, action: any) => {
        state.isLoading = false;
        state.listAction = action.payload;
      })
      .addCase(getListAction.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      //Email Server
      .addCase(getListEmailServer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getListEmailServer.fulfilled, (state, action: any) => {
        state.listEmailServer = action.payload;
        state.isLoading = false;
      })
      .addCase(getListEmailServer.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      //Group Email
      .addCase(getListGroupEmail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getListGroupEmail.fulfilled, (state, action: any) => {
        state.listGroupEmail = action.payload;
        state.isLoading = false;
      })
      .addCase(getListGroupEmail.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      //_________________ get onboard______________________
      .addCase(getOnboard.pending, (state, action) => {
        state.isLoadingOnboard = true;
      })
      .addCase(getOnboard.fulfilled, (state, action: any) => {
        state.isLoadingOnboard = false;
        state.onboards = action.payload;
      })
      .addCase(getOnboard.rejected, (state, action: any) => {
        state.isLoadingOnboard = false;
        state.error = action.payload;
      })

      .addCase(getOnboardByEmployee.pending, (state, action) => {
        state.isLoadingEmployeeOnboard = true;
      })
      .addCase(getOnboardByEmployee.fulfilled, (state, action: any) => {
        state.isLoadingEmployeeOnboard = false;
      })
      .addCase(getOnboardByEmployee.rejected, (state, action: any) => {
        state.isLoadingEmployeeOnboard = false;
      })

      .addCase(getProcessStep.pending, (state, action) => {
        state.isLoadingStep = true;
      })
      .addCase(getProcessStep.fulfilled, (state, action: any) => {
        state.isLoadingStep = false;
      })
      .addCase(getProcessStep.rejected, (state, action: any) => {
        state.isLoadingStep = false;
      })

      .addCase(getOnboardStep.pending, (state, action) => {
        state.isLoadingStep = true;
      })
      .addCase(getOnboardStep.fulfilled, (state, action: any) => {
        state.isLoadingStep = false;
      })
      .addCase(getOnboardStep.rejected, (state, action: any) => {
        state.isLoadingStep = false;
      })

      .addCase(getProcess.pending, (state, action) => {
        state.isLoadingProcess = true;
      })
      .addCase(getProcess.fulfilled, (state, action: any) => {
        state.isLoadingProcess = false;
      })
      .addCase(getProcess.rejected, (state, action: any) => {
        state.isLoadingProcess = false;
      });
  },
});

export const { reducer, actions } = onboardSlice;
export const { setVariableEmail } = actions;
export const selectOnboard = (state: RootState) => state.onboard;
export default reducer;

import { Col, Input, Popconfirm, Row, Select, TimePicker, Tooltip } from 'antd';
import { useAppSelector } from 'app/hooks';
import editIcon from 'assets/icons/ic_edit_info.svg';
import { getTimeNotGMT, TIMEZONE_DEFAULT } from 'constants/commons';
import {
  TimeSheetApprovalStatus,
  TimeSheetStatus,
} from 'hrm-common/extensions/enums/personel/declare.enums';
import moment, { Moment } from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import CustomButton from 'styles/buttonStyled';
import CustomInput from 'styles/inputStyled';
import { getUser } from 'utils/auth';
import * as DateUtils from 'utils/date';
import { capitalizeFirstLetter } from 'utils/text';
import timesheetApi from 'api/timesheetApi';
import { setAllNotyfiError, setAllNotyfiSuccess } from '../contants/formatdate';
import {
  cancelAdjustTimeRequest,
  createAdjustTime,
  selectDisableAdjustTime,
  selectTimesheet,
  updateAdjustTime,
} from '../pages/timesheetSlice';
import 'styles/timePickerStyles.scss';
import { changeEndTime, changeStartTime } from '../utils/complexLogicTime';
import { MinusCircleOutlined } from '@ant-design/icons';
import { setGlobalLoading } from 'app/commonRedux/appSlice';
import { EditType } from '../interfaces';
import { memo } from 'react';
import { notificationToast } from 'components/notificationToast';
import { Notification } from 'constants/notification';

import locationCheckinIcon from 'assets/icons/locationCheckinIcon.png';
import locationCheckoutIcon from 'assets/icons/locationCheckoutIcon.png';

const { TextArea } = Input;
const { Option } = Select;

const UpdateTime = (props: any) => {
  if (props.editType === EditType.ADJUST) {
    return <UpdateAdjustTime {...props} />;
  }
  if (props.editType === EditType.OVERTIME) {
    return <UpdateOverTime {...props} />;
  }
  if (props.editType === EditType.LEAVE) {
    return <UpdateLeaveTime {...props} />;
  }
  return null;
};

const UpdateAdjustTime = (props: any) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['timesheet']);
  let startTime = props.currentWorkTime?.checkinTime ?? null;
  let endTime = props.currentWorkTime?.checkoutTime ?? null;
  const timesheet = useAppSelector(selectTimesheet);
  const calendarData = timesheet?.calendarData;
  const isConfirmed = calendarData.conFirmStatus !== TimeSheetApprovalStatus.DEFAULT;
  const timeZone = getUser().timeZone ? getUser().timeZone : TIMEZONE_DEFAULT;
  const limitDayEdit = props.limitDayEdit;
  const totalDayFromNow = Math.abs(props.value.diff(DateUtils.transMomentTimezone(), 'days'));
  const isDisableAdjustTime = useAppSelector(selectDisableAdjustTime);

  //Set default timezone in server
  moment.tz.setDefault(timeZone);

  useEffect(() => {
    setReason(props.reason);
  }, [props.reason]);

  useEffect(() => {
    let permissions = getUser()?.permissions;
    if (permissions.indexOf('TIMESHEET_SELF_APPROVE') !== -1) {
      setIsStart(TimeSheetStatus.APPROVED);
      setIsClickSave(true);
    } else {
      setIsClickSave(false);
      setIsStart(TimeSheetStatus.PENDING);
    }
  }, []);

  if (!startTime) startTime = endTime;
  if (!endTime) endTime = startTime;
  const isAdjustTime = !!props.currentWorkTime?.requestAdjustTime;
  const totalHours = Number(props.currentWorkTime?.requestAdjustTime?.totalHour);

  const totalHoursWorkTime = (() => {
    if (!isAdjustTime) {
      return Number(props.currentWorkTime?.totalHours);
    }
    return Number(props.currentWorkTime?.requestAdjustTime?.originalTotalHours);
  })();

  const startTimeAdjust = props.currentWorkTime?.requestAdjustTime?.startTime;
  const endTimeAdjust = props.currentWorkTime?.requestAdjustTime?.endTime;
  const originCheckIn = props.currentWorkTime?.requestAdjustTime?.originCheckin;
  const originCheckOut = props.currentWorkTime?.requestAdjustTime?.originCheckout;

  const [reason, setReason] = useState(props.reason);
  const [isEdit, setIsEdit] = useState(false);

  const [time1, setTime1] = useState(
    startTime
      ? moment(startTime).tz(timeZone)
      : moment(props.value).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
  );

  const [time2, setTime2] = useState(
    endTime
      ? moment(endTime).tz(timeZone)
      : moment(props.value).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
  );
  const [, setWorkingTimeEdit] = useState(0);

  const [is_start, setIsStart]: any = useState(false);
  const [is_clickSave, setIsClickSave]: any = useState(false);

  useEffect(() => {
    if (time1 && time2) {
      setWorkingTimeEdit(DateUtils.calculateDuration(time1.toDate(), time2.toDate()));
    }
  }, [time1, time2]);

  useEffect(() => {
    setTime1(
      startTime
        ? moment(startTime)
        : moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
    );
    setTime2(
      endTime ? moment(endTime) : moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
    );
  }, [startTime, endTime]);

  const handleSubmit = () => {
    dispatch(setGlobalLoading({ loading: true }));
    if (
      time1.hour() >= time2.hour() ||
      (time1.hour() === time2.hour() && time1.minute() >= time2.minute())
    ) {
      notificationToast(
        Notification.Type.Error,
        'Start time cannot higher than end time',
        Notification.Duration._3s,
      );
      dispatch(setGlobalLoading({ loading: false }));
      return;
    }
    if (props.status === TimeSheetApprovalStatus.REJECTED) {
      dispatch(
        updateAdjustTime({
          id: props.id,
          workingTimeId: Number(props.currentWorkTime.id),
          reason,
          startTime: time1.utc(),
          endTime: time2.utc(),
          status: is_start,
          userId: getUser().id,
          callBack: () => {
            setIsEdit(false);
            props.refreshData && props.refreshData();
            dispatch(setGlobalLoading({ loading: false }));
          },
        }),
      );
    } else {
      dispatch(
        createAdjustTime({
          workingTimeId: Number(props.id),
          reason,
          startTime: time1.utc(),
          endTime: time2.utc(),
          status: is_start,
          userId: getUser().id,
          callBack: () => {
            setIsEdit(false);
            props.refreshData && props.refreshData();
            props.handleVisible(false);
            props.isShow.current = false;
            dispatch(setGlobalLoading({ loading: false }));
          },
        }),
      );
    }

    setTime1(time1.tz(timeZone));
    setTime2(time2.tz(timeZone));
  };

  const CancelSubmit = async () => {
    // props.refreshData && props.refreshData();
    setIsEdit(false);
    setReason(props.reason);
    setTime1(
      startTime
        ? moment(startTime)
        : moment(props.value).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
    );
    setTime2(
      endTime
        ? moment(endTime)
        : moment(props.value).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
    );
    props.handleVisible(false);
    props.isShow.current = false;
  };

  const onChangeStart_time = (time: Moment | null) => {
    const optionTime = { timeSelect: time, currentDay: props.currentDay, timeEnd: time2 };
    changeStartTime(optionTime, setTime1, setTime2);
  };

  const onClearStartTime = () => {
    setTime1(moment(props.value).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }));
  };
  const onClearEndTime = () => {
    setTime2(moment(props.value).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }));
  };

  const onChangeEnd_time = (time: Moment | null) => {
    const optionTime = { timeSelect: time, currentDay: props.currentDay, timeStart: time1 };
    changeEndTime(optionTime, setTime2);
  };

  return (
    <Container>
      <DateValue>
        <span>{props.currentDay && moment(props.currentDay).format('dddd, DD MMMM YYYY')}</span>
        {props.status && !isEdit && (
          <StatusTag type={props?.status}>{capitalizeFirstLetter(props?.status)}</StatusTag>
        )}
        {!isDisableAdjustTime &&
          totalDayFromNow < limitDayEdit &&
          !isEdit &&
          !isConfirmed &&
          !props.status && (
            <img
              alt=""
              src={editIcon}
              onClick={() => {
                if (props.status === TimeSheetStatus.REJECTED) {
                  setTime1(moment(startTimeAdjust));
                  setTime2(moment(endTimeAdjust));
                }
                setIsEdit(true);
              }}
              style={{ cursor: 'pointer', marginLeft: 10 }}
            />
          )}
        {(isDisableAdjustTime || totalDayFromNow >= limitDayEdit) && !props.status && (
          <Tooltip
            title={
              <ul style={{ margin: 0, paddingLeft: 15 }}>
                {totalDayFromNow >= limitDayEdit && <li>{t('out_of_time_range_to_adjust')}</li>}
                {isDisableAdjustTime && <li>{t('over_number_to_adjust')}</li>}
              </ul>
            }
            color="red"
            key="red"
            placement="right"
          >
            <MinusCircleOutlined style={{ color: 'red' }} />
          </Tooltip>
        )}
      </DateValue>
      {!props.status ? (
        <>
          <WorkingTime>
            <label style={{ display: 'flex' }}>
              {t('Working_time')}
              <p style={{ color: 'red', marginLeft: 4, fontSize: 14, marginBottom: 0 }}>*</p>
            </label>
            <div>
              <TimePicker
                value={time1}
                onChange={onClearStartTime}
                defaultValue={moment('00:00', 'HH:mm')}
                disabled={!isEdit}
                showNow={false}
                format={'HH:mm'}
                onSelect={onChangeStart_time}
                popupClassName="hidden__footer"
                disabledMinutes={(selectedHour: number) => {
                  if (
                    selectedHour >= 0 &&
                    DateUtils.checkIsFirstOrLastOfTime('lastOfTime', selectedHour)
                  )
                    return [59];
                  return [];
                }}
              />
              <span> - </span>
              <TimePicker
                value={time2}
                onChange={onClearEndTime}
                defaultValue={moment('00:00', 'HH:mm')}
                showNow={false}
                disabled={!isEdit}
                format={'HH:mm'}
                onSelect={onChangeEnd_time}
                popupClassName="hidden__footer"
                disabledMinutes={(selectedHour: number) => {
                  if (
                    selectedHour >= 0 &&
                    DateUtils.checkIsFirstOrLastOfTime('firstOfTime', selectedHour)
                  )
                    return [0];
                  return [];
                }}
              />
            </div>
          </WorkingTime>
          <WorkingDuration>
            <label>{t('Working_duration')}</label>
            <p>
              {props.totalHours ? props.totalHours.toFixed(2) : Number(0).toFixed(2)} {t('hours')}
            </p>
          </WorkingDuration>
          <div style={{ maxWidth: 280 }}>
            {props?.currentWorkTime?.checkinAddress && (
              <div style={{ display: 'flex', gap: 3, marginBottom: 5 }}>
                <img
                  src={locationCheckinIcon}
                  alt=""
                  style={{ width: 12, height: 12, marginTop: 3 }}
                />
                <EllipsisAddressText>{props?.currentWorkTime?.checkinAddress}</EllipsisAddressText>
              </div>
            )}
            {props?.currentWorkTime?.checkoutAddress && (
              <div style={{ display: 'flex', gap: 3 }}>
                <img
                  src={locationCheckoutIcon}
                  alt=""
                  style={{ width: 12, height: 12, marginTop: 3 }}
                />
                <EllipsisAddressText>{props?.currentWorkTime?.checkoutAddress}</EllipsisAddressText>
              </div>
            )}
          </div>
        </>
      ) : (
        <ReviewInfo>
          <ReviewInfoLeft>
            <div className="title">{t('Current_information')}</div>
            <div style={{ padding: '5px 15px' }}>
              <WorkingTime>
                <label>{t('Working_time')}</label>
                <p>{`${DateUtils.formatHour(originCheckIn)} - ${DateUtils.formatHour(
                  originCheckOut,
                )}`}</p>
              </WorkingTime>
              <WorkingDuration>
                <label>{t('Working_duration')}</label>
                <p>
                  {totalHoursWorkTime.toFixed(2)} {t('hours')}
                </p>
              </WorkingDuration>
            </div>
          </ReviewInfoLeft>
          <ReviewInfoRight>
            <div className="title">{t('Adjusted_information')}</div>
            <div style={{ padding: '5px 15px' }}>
              <WorkingTime>
                <label>{t('Working_time')}</label>
                <p>{`${DateUtils.formatHour(startTimeAdjust)} - ${DateUtils.formatHour(
                  endTimeAdjust,
                )}`}</p>
              </WorkingTime>
              <WorkingDuration>
                <label>{t('Working_duration')}</label>
                <p>
                  {totalHours.toFixed(2)} {t('hours')}
                </p>
              </WorkingDuration>
            </div>
          </ReviewInfoRight>
        </ReviewInfo>
      )}

      {(isEdit || props.status) && (
        <Reason>
          <label style={{ display: 'flex' }}>
            {t('Reason_for_adjust')}
            <p style={{ color: 'red', marginLeft: 4, fontSize: 14, marginBottom: 0 }}>*</p>
          </label>
          <TextArea
            rows={3}
            style={{ borderRadius: 5 }}
            value={reason}
            onChange={(e: any) => setReason(e.target.value)}
            disabled={props.status && !isEdit}
          />
        </Reason>
      )}
      {isEdit && (
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20, gap: '0.6em' }}>
          <CustomButton onClick={CancelSubmit} aType="text">
            {t('Cancel')}
          </CustomButton>
          {is_clickSave === false ? (
            <CustomButton
              aType="primary"
              disabled={!reason || totalHours <= 0 || reason.trim() === ''}
              onClick={handleSubmit}
            >
              {t('Send_request')}
            </CustomButton>
          ) : (
            <CustomButton
              aType="primary"
              disabled={!reason || totalHours <= 0}
              onClick={handleSubmit}
            >
              {t('Save')}
            </CustomButton>
          )}
        </div>
      )}
      {[TimeSheetStatus.PENDING, TimeSheetStatus.REJECTED].includes(props.status) && (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
          <Popconfirm
            title={t('Are_you_Cancel_request')}
            onConfirm={() => {
              dispatch(
                cancelAdjustTimeRequest({
                  id: props.id,
                  callBack: () => {
                    setTime1(startTime ? moment(startTime) : moment().set({ hour: 0, minute: 0 }));
                    setTime2(endTime ? moment(endTime) : moment().set({ hour: 0, minute: 0 }));
                    props.refreshData && props.refreshData();
                    props.handleVisible(false);
                    props.isShow.current = false;
                  },
                }),
              );
            }}
            // onCancel={cancel}
            okText={t('Yes')}
            cancelText={t('No')}
          >
            <CustomButton aType="primary">{t('Cancel_request')}</CustomButton>
          </Popconfirm>
        </div>
      )}
    </Container>
  );
};

export const UpdateOverTime = (props: any) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['timesheet']);
  let startTime = props.startTime
    ? moment(props.startTime)
    : props.checkinTime
    ? moment(props.checkinTime)
    : null;
  let endTime = props.endTime
    ? moment(props.endTime)
    : props.checkoutTime
    ? moment(props.checkoutTime)
    : null;
  if (!startTime) startTime = endTime;
  if (!endTime) endTime = startTime;
  let [reason] = useState(props.reason);
  let [time1, setTime1] = useState(startTime);
  let [time2, setTime2] = useState(endTime);
  let [totalHours, setTotalHours] = useState(0);
  let [is_status, setIsStatus] = useState(true);
  let [istitle, setIsTitle] = useState(props.title || null);
  let [isreason, setIsReason] = useState(reason || null);

  useEffect(() => {
    if (time1 && time2) {
      getTimelHour();
      // setTotalHours(time2.diff(time1, 'minutes') / 60);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time1, time2]);

  useEffect(() => {
    setTime1(
      startTime
        ? moment(startTime)
        : moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
    );
    setTime2(
      endTime ? moment(endTime) : moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTime?.format(), endTime?.format()]);
  const getTimelHour = async () => {
    try {
      const params = {
        currentDate: moment(props.currentDay).format('YYYY-MM-DD'),
        startTime: getTimeNotGMT(props.currentDay, time1),
        endTime: getTimeNotGMT(props.currentDay, time2),
      };
      const respons = await timesheetApi.getTimeTotalHour(params);
      // @ts-ignore
      if (respons.statusCode === 200) {
        setTotalHours(respons.data.totalHour);
      } else {
        console.log('error');
      }
    } catch (e) {
      console.log(e);
    }
  };
  const onclickCancelRequest = async () => {
    dispatch(setGlobalLoading({ loading: true }));
    try {
      const params = {
        searchWorkingTime: {
          monthYear: moment(props.currentDay).format('YYYY-MM'),
          userId: getUser().id,
        },
        typeRequest: 'overtime',
        select: {
          key: 'id',
          value: [props.id],
        },
      };
      const respons = await timesheetApi.apiCancelRequest(params);
      // @ts-ignore
      if (respons.statusCode === 200) {
        setAllNotyfiSuccess(t('Cancel_successfully'));
        props.refreshData();
        props.handleVisibleOtPopUp(false);
        props.isShowOt.current = false;
        dispatch(setGlobalLoading({ loading: false }));
      } else {
        setAllNotyfiError(t('Cancel_Failure'));
        dispatch(setGlobalLoading({ loading: false }));
      }
    } catch (e) {
      dispatch(setGlobalLoading({ loading: false }));
      console.log('error');
    }
  };
  const onclickUpdateRequest = async () => {
    dispatch(setGlobalLoading({ loading: true }));
    let bodyover = {
      projectCode: props.projectCode,
      title: istitle,
      reason: isreason,
      userId: getUser().id,
      workingTimeId: props.currentWorkTime.id,
      totalHour: totalHours,
      startTime: getTimeNotGMT(props.currentDay, time1),
      endTime: getTimeNotGMT(props.currentDay, time2),
      id: props.id,
      type: props.type,
      status: TimeSheetStatus.PENDING,
    };

    try {
      const respons = await timesheetApi.putOvertimeRequest(bodyover);
      // @ts-ignore
      if (respons.statusCode === 200) {
        props.refreshData();
        setAllNotyfiSuccess(t('Update_successfully'));
        props.handleVisibleOtPopUp(false);
        props.isShowOt.current = false;
        dispatch(setGlobalLoading({ loading: false }));
      } else {
        setAllNotyfiError(t('Update_Failure'));
        dispatch(setGlobalLoading({ loading: false }));
      }
    } catch (e) {
      console.log('error');
      dispatch(setGlobalLoading({ loading: false }));
    }
  };

  return (
    <Container>
      <DateValue>
        <span>{props.currentDay && moment(props.currentDay).format('dddd, DD MMMM YYYY')}</span>
        <div style={{ paddingLeft: 10 }}>
          {props.status === TimeSheetStatus.REJECTED ? (
            <img
              alt=""
              src={editIcon}
              onClick={() => setIsStatus(false)}
              style={{ cursor: 'pointer' }}
            />
          ) : (
            <></>
          )}
        </div>
        {props.status && (
          <StatusTag type={props?.status}>{capitalizeFirstLetter(props?.status)}</StatusTag>
        )}
      </DateValue>
      <Item>
        <label>{t('overtime:project')}</label>
        <p>{`${props?.projectCode || ''}`}</p>
      </Item>
      <Item>
        <label>{t('title')}</label>
        <CustomInput
          type="text"
          onChange={(e) => setIsTitle(e.target.value)}
          disabled={is_status}
          value={istitle}
        />
      </Item>
      <Item>
        <label>{t('Time_of_Overtime')}</label>
        <div style={{ justifyItems: 'center' }}>
          <span style={{ fontSize: 12 }}>{t('from')}</span>
          <TimePicker
            value={time1}
            onChange={(time: any) => moment(time).isBefore(time2) && setTime1(time)}
            disabled={is_status}
            format={'HH:mm'}
            style={{ marginRight: 5, marginLeft: 5, borderRadius: 4 }}
          />
          <span style={{ fontSize: 12 }}> to </span>
          <TimePicker
            value={time2}
            onChange={(time: any) => moment(time).isAfter(time1) && setTime2(time)}
            disabled={is_status}
            format={'HH:mm'}
            style={{ marginLeft: 5, borderRadius: 4 }}
          />
        </div>
        {/*<p>{`${moment(props.startTime).format('HH:mm')} - ${moment(props.endTime).format(*/}
        {/*  'HH:mm',*/}
        {/*)}`}</p>*/}
      </Item>
      <Row>
        <Col span={14}>
          <Item>
            <label>{t('Total_Overtime')}</label>
            {is_status === false ? (
              <p>
                {`${totalHours.toFixed(2)}`} {t('hours')}
              </p>
            ) : (
              <p>
                {`${props.totalHour.toFixed(2)}`} {t('hours')}
              </p>
            )}
          </Item>
        </Col>
        <Col span={10}>
          <Item>
            {props?.isCompensatory && props?.compensatoryRate != null ? (
              <>
                <label>{t('compensatory_rate')}</label>
                <p>
                  {`${props?.compensatoryRate.toFixed(2)}`} {'%'}
                </p>
              </>
            ) : null}
          </Item>
        </Col>
      </Row>
      <Row>
        <Col span={14}>
          <Item>
            {props?.isCompensatory && props?.overtimeDuration != null ? (
              <>
                <label>{t('Overtime_duration')}</label>
                <p>
                  {`${props?.overtimeDuration.toFixed(2)}`} {t('hours')}
                </p>
              </>
            ) : null}
          </Item>
        </Col>
        <Col span={10}>
          <Item>
            {props?.isCompensatory && props?.compensatoryHour != null ? (
              <>
                <label>{t('compensatory_hour')}</label>
                <p>
                  {`${props?.compensatoryHour.toFixed(2)}`} {t('hours')}
                </p>
              </>
            ) : null}
          </Item>
        </Col>
      </Row>
      <Item>
        <label>{t('Type_of_overtime')}</label>
        <CustomInput disabled={true} value={props.type || null} />
      </Item>
      <Item>
        <label>{t('Reason')}</label>
        <TextArea
          rows={3}
          style={{ borderRadius: 5 }}
          value={isreason}
          disabled={is_status}
          onChange={(e) => {
            setIsReason(e.target.value);
          }}
        />
      </Item>
      <Item>
        <label>{t('Inform_to')}</label>
        <CustomInput disabled={true} value={props.supervisorsName?.join(', ') || null} />
      </Item>
      <Item>
        <label>{t('Approver')}</label>
        <CustomInput disabled={true} value={props?.currentProjectLeaderName || null} />
      </Item>
      {props.status === TimeSheetStatus.PENDING && (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
          <Popconfirm
            title={t('Are_you_Cancel_request')}
            onConfirm={() => onclickCancelRequest()}
            // onCancel={cancel}
            okText={t('Yes')}
            cancelText={t('No')}
          >
            <CustomButton aType="primary">{t('Cancel_request')}</CustomButton>
          </Popconfirm>
        </div>
      )}
      {props.status === TimeSheetStatus.REJECTED && (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
          <Popconfirm
            title={t('Are_you_update_request')}
            onConfirm={() => onclickUpdateRequest()}
            // onCancel={cancel}
            okText={t('Yes')}
            cancelText={t('No')}
          >
            <CustomButton aType="primary">{t('Send_request')}</CustomButton>
          </Popconfirm>
        </div>
      )}
    </Container>
  );
};

export const UpdateLeaveTime = (props: any) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['timesheet']);
  let startTime = props.startTime
    ? moment(props.startTime)
    : props.checkinTime
    ? moment(props.checkinTime)
    : null;
  let endTime = props.endTime
    ? moment(props.endTime)
    : props.checkoutTime
    ? moment(props.checkoutTime)
    : null;
  if (!startTime) startTime = endTime;
  if (!endTime) endTime = startTime;
  let [reason] = useState(props.reason);
  let [time1, setTime1] = useState(startTime);
  let [time2, setTime2] = useState(endTime);
  let [, setTotalHours] = useState(0);
  let [is_status, setIsStatus] = useState(true);
  let [istitle] = useState(props.title || null);
  let [isreason, setIsReason] = useState(reason || null);
  const [input_type, setInputType] = useState(props.requestLeaveType);

  useEffect(() => {
    if (time1 && time2) {
      setTotalHours(DateUtils.calculateDuration(time1.toDate(), time2.toDate()));
    }
  }, [time1, time2]);

  const handleChange = (value: any) => {
    setInputType(value);
  };
  const onclickCancelRequest = async () => {
    dispatch(setGlobalLoading({ loading: true }));
    try {
      const params = {
        searchWorkingTime: {
          monthYear: moment(props.currentDay).format('YYYY-MM'),
          userId: getUser().id,
        },
        typeRequest: 'leave',
        select: {
          key: 'id',
          value: [props.id],
        },
      };
      const respons = await timesheetApi.apiCancelRequest(params);
      // @ts-ignore
      if (respons.statusCode === 200) {
        props.refreshData();
        setAllNotyfiSuccess(t('Cancel_successfully'));
        props.handleVisibleLeavePopUp(false);
        props.isShowLeave.current = false;
        dispatch(setGlobalLoading({ loading: false }));
      } else {
        setAllNotyfiError(t('Cancel_Failure'));
        dispatch(setGlobalLoading({ loading: false }));
      }
    } catch (e) {
      dispatch(setGlobalLoading({ loading: false }));
      console.log('error');
    }
    props.handleVisibleLeavePopUp(false);
    props.isShowLeave.current = false;
  };
  const onclickUpdateRequest = async () => {
    dispatch(setGlobalLoading({ loading: true }));
    let bodyover = {
      title: istitle,
      reason: isreason,
      userId: getUser().id,
      workingTimeId: props.currentWorkTime.id,
      requestLeaveTypeId: input_type,
      startTime:
        moment(props.currentDay).format('YYYY-MM-DD') + 'T' + moment(time1).format('HH:mm:ss'),
      endTime:
        moment(props.currentDay).format('YYYY-MM-DD') + 'T' + moment(time2).format('HH:mm:ss'),
      id: props.id,
      status: TimeSheetStatus.PENDING,
    };
    try {
      const respons = await timesheetApi.putRequestLeave(bodyover);
      // @ts-ignore
      if (respons.statusCode === 200) {
        props.refreshData();
        setAllNotyfiSuccess(t('Update_successfully'));
        props.handleVisibleLeavePopUp(false);
        props.isShowLeave.current = false;
        dispatch(setGlobalLoading({ loading: false }));
      } else {
        setAllNotyfiError(t('Update_Failure'));
        dispatch(setGlobalLoading({ loading: false }));
      }
    } catch (e) {
      console.log('error');
      dispatch(setGlobalLoading({ loading: false }));
    }
    props.handleVisibleLeavePopUp(false);
    props.isShowLeave.current = false;
  };

  return (
    <Container>
      <DateValue>
        <span>
          {props.currentDay &&
            DateUtils.transMomentTimezone(props.currentDay).format('dddd, DD MMMM YYYY')}
        </span>
        <div style={{ paddingLeft: 10 }}>
          {props.status === TimeSheetStatus.REJECTED ? (
            <img
              alt=""
              src={editIcon}
              onClick={() => setIsStatus(false)}
              style={{ cursor: 'pointer' }}
            />
          ) : (
            <div></div>
          )}
        </div>
        {props.status && (
          <StatusTag type={props?.status}>{capitalizeFirstLetter(props?.status)}</StatusTag>
        )}
      </DateValue>
      {/* <Item>
        <label>{t('title')}</label>
        <CustomInput
          type="text"
          disabled={is_status}
          value={istitle}
          onChange={(e) => setIsTitle(e.target.value)}
        />
      </Item> */}
      <Item>
        <Item>
          <Item>
            <label>{t('leave_off_start_date')}</label>
            <div>
              <TimePicker
                value={time1}
                onChange={(time: any) => moment(time).isBefore(time2) && setTime1(time)}
                disabled={is_status}
                format={'HH:mm'}
                style={{ marginRight: 5, marginLeft: 5, borderRadius: 4 }}
              />
              {` - ${time1?.format('DD/MM/YYYY')}`}
            </div>
            {/*<p>{`${moment(props.startTime).format('HH:mm')} - ${formatUT_YYYY_MM_DD(time1)}`}</p>*/}
          </Item>
          <Item>
            <label>{t('Leave_off_end_date')}</label>
            <div>
              <TimePicker
                value={time2}
                onChange={(time: any) => moment(time).isAfter(time1) && setTime2(time)}
                disabled={is_status}
                format={'HH:mm'}
                style={{ marginLeft: 5, marginRight: 5, borderRadius: 4 }}
              />
              {` - ${time2?.format('DD/MM/YYYY')}`}
            </div>
            {/*<p>{`${moment(props.endTime).format('HH:mm')} - ${formatUT_YYYY_MM_DD(time2)}`}</p>*/}
          </Item>
        </Item>
        <Item>
          {is_status === true ? (
            <Item>
              <label>{t('Leave_off_duration')}</label>
              <p>
                {props.totalHour === null ? Number(0).toFixed(2) : `${props.totalHour.toFixed(2)}`}{' '}
                {t('hours')}
              </p>
              {/*<p>{props.totalHour} hours</p>*/}
            </Item>
          ) : null}
        </Item>
      </Item>
      <Item>
        <label>{t('Leave_off_type')}</label>
        <Select
          value={input_type}
          disabled={is_status === true}
          style={{ width: '100%', height: 30 }}
          onChange={handleChange}
        >
          <Option value={props.requestLeaveType}>{props.type + ' (' + props.remain + ')'}</Option>
        </Select>
      </Item>
      <Item>
        <label>{t('Reason')}</label>
        <TextArea
          rows={3}
          style={{ borderRadius: 5 }}
          value={isreason}
          disabled={is_status}
          onChange={(e) => {
            setIsReason(e.target.value);
          }}
        />
      </Item>
      <Item>
        <label>{t('Inform_to')}</label>
        <CustomInput disabled={true} value={props.supervisorsName?.join(', ') || null} />
      </Item>
      <Item>
        <label>{t('Approver')}</label>
        <CustomInput disabled={true} value={props?.currentWorkTime?.currentManager || null} />
      </Item>
      {props.status === TimeSheetStatus.PENDING && (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
          <Popconfirm
            title={t('Are_you_Cancel_request')}
            onConfirm={() => onclickCancelRequest()}
            okText={t('Yes')}
            cancelText={t('No')}
          >
            <CustomButton aType="primary">{t('Cancel_request')}</CustomButton>
          </Popconfirm>
        </div>
      )}
      {props.status === TimeSheetStatus.REJECTED && (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
          <Popconfirm
            title={t('Are_you_update_request')}
            onConfirm={() => onclickUpdateRequest()}
            okText={t('Yes')}
            cancelText={t('No')}
          >
            <CustomButton aType="primary">{t('Send_request')}</CustomButton>
          </Popconfirm>
        </div>
      )}
    </Container>
  );
};

export default memo(UpdateTime);

export const Container = styled.div``;

export const Item = styled.div`
  margin-bottom: 0px;

  label {
    font-size: 12px;
    color: var(--cl_gray500);
  }

  p {
    font-size: 16px;
    color: var(--cl_gray900);
    margin-bottom: 0;
  }
`;

export const DateValue = styled.div`
  span {
    font-size: 16px;
    color: var(--cl_info500);
    font-weight: 500;
  }

  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
`;

export const WorkingTime = styled.div`
  margin-bottom: 15px;

  label {
    font-size: 12px;
    color: var(--cl_gray500);
  }

  p {
    font-size: 16px;
    color: var(--cl_gray900);
  }
`;

export const WorkingDuration = styled.div`
  label {
    font-size: 12px;
    color: var(--cl_gray500);
  }

  p {
    font-size: 16px;
    color: var(--cl_gray900);
  }
`;

export const Reason = styled.div`
  label {
    font-size: 12px;
    color: var(--cl_gray500);
  }

  p {
    font-size: 16px;
    color: var(--cl_gray900);
  }
`;

interface StatusTagProps {
  type: string;
}

export const StatusTag = styled.div`
  border-radius: 5px;
  text-align: center;
  display: inline;
  padding: 0 5px;
  margin-left: 20px;
  ${(props: StatusTagProps) => {
    switch (props.type) {
      case 'working':
        return css``;
      case 'adjust':
        return css`
          background-color: var(--cl_info500);
        `;
      case 'approved':
        return css`
          background-color: var(--cl_success100);
        `;
      case 'pending':
        return css`
          background-color: var(--cl_warning100);
        `;
      case 'rejected':
        return css`
          background-color: var(--cl_error100);
        `;
      default:
        break;
    }
  }}
`;

export const ReviewInfo = styled.div`
  display: flex;
  border-radius: 5px;
  border: 2px solid var(--cl_gray300);
  margin-bottom: 10px;
`;

export const ReviewInfoLeft = styled.div`
  flex-grow: 1;
  border-right: 2px solid var(--cl_gray300);

  .title {
    background-color: var(--cl_gray100);
    padding: 5px 15px;
  }
`;

export const ReviewInfoRight = styled.div`
  flex-grow: 1;

  .title {
    background-color: var(--cl_gray100);
    padding: 5px 15px;
  }
`;

export const EllipsisAddressText = styled.span`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;;
  font-size: 11px;
}`;

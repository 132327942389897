import { SwapOutlined } from '@ant-design/icons';
import { Button, Form, Modal, Radio, Space } from 'antd';
import { useAppSelector } from 'app/hooks';
import { TIMEZONE_DEFAULT } from 'constants/commons';
import { actions, selectConfiguration } from 'features/configuration/configurationSlice';
import { leaveOffMoveDays } from 'features/configuration/leaveOffAction';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { getUser } from 'utils/auth';

const FormMoveDays = (props: any) => {
  const { setShow, setShowError } = props;
  const { t } = useTranslation(['leaveOff']);
  const { getPayloadSelect } = actions;
  const [option, setOption] = useState('');
  const [optionMoveDays, setOptionMoveDays] = useState('');
  const [data, setData] = useState([]);
  const [dataChange, setDataChange] = useState([]);
  const [keyMoveSelected, setKeyMoveSelected] = useState();
  const [keySelected, setKeySelected] = useState();
  const [codeSelected, setCodeSelected] = useState();
  const [defaultChoose, setDefaultChoose] = useState('default');
  const [defaultMove, setDefaultMove] = useState('default');
  const [isPaidLeave, setIsPaidLeave] = useState(false);
  const config = useAppSelector(selectConfiguration);
  const [, setSelectedDay] = useState();
  const dispatch = useDispatch();
  const timeZone = getUser().timeZone ? getUser().timeZone : TIMEZONE_DEFAULT;
  //Set default timezone in server
  moment.tz.setDefault(timeZone);

  useEffect(() => {
    dispatch(leaveOffMoveDays());
  }, [dispatch]);

  useEffect(() => {
    setData(config.dataLeaveOffGroup);
    if (isPaidLeave) {
      const paidArr = config.dataLeaveOffGroup?.map((item: any) => ({
        ...item,
        leaveTypes: item.leaveTypes.filter((v: any) => v.isSalary),
      }));
      setData(paidArr);
    } else {
      const unPaidArr = config.dataLeaveOffGroup?.map((item: any) => ({
        ...item,
        leaveTypes: item.leaveTypes.filter((v: any) => v.isSalary === false),
      }));
      setData(unPaidArr);
    }
  }, [config.dataLeaveOffGroup, isPaidLeave]);

  const handleChange = (e: any) => {
    const selectedIndex = e.target.options.selectedIndex;
    setDefaultChoose(e.target.value);
    setCodeSelected(e.target.options[selectedIndex].getAttribute('data-code'));
    setKeySelected(e.target.options[selectedIndex].getAttribute('data-key'));
    setSelectedDay(e.target.options[selectedIndex].getAttribute('data-day'));
    setOption(e.target.value);
    setDataChange(data);
    setOptionMoveDays('');
    setShowError(false);
  };

  useEffect(() => {
    if (isPaidLeave) {
      const paidArr = config.dataLeaveOffGroup?.map((item: any) => ({
        ...item,
        leaveTypes: item.leaveTypes.filter((v: any) => v.isSalary),
      }));
      setData(paidArr);
    } else {
      const unPaidArr = config.dataLeaveOffGroup?.map((item: any) => ({
        ...item,
        leaveTypes: item.leaveTypes.filter((v: any) => v.isSalary === false),
      }));
      setData(unPaidArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPaidLeave]);

  useEffect(() => {
    const newArr = data?.map((item: any) => ({
      ...item,
      leaveTypes: item.leaveTypes.filter(
        (value: any) => value.name !== option && value.code !== codeSelected,
      ),
    }));
    // const result = newArr?.filter((item: any) =>
    //   moment(item.startTime).isAfter(moment(selectedDay)),
    // );
    defaultChoose !== 'default' && setDataChange(newArr);
    setDefaultMove('default');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [option]);

  useEffect(() => {
    setShowError(false);
    setDefaultMove('default');
    setDefaultChoose('default');
    setOption('');
    setOptionMoveDays('');
    setDataChange([]);
    setKeySelected(undefined);
    setCodeSelected(undefined);
    setSelectedDay(undefined);
    setShow(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPaidLeave]);

  useEffect(() => {
    setShow(handleDisable());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [option, optionMoveDays]);

  useEffect(() => {
    const payloadData = {
      chooseId: keySelected,
      moveId: keyMoveSelected,
    };
    dispatch(getPayloadSelect(payloadData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keySelected, keyMoveSelected]);

  const handleChangeDays = (e: any) => {
    setShowError(false);
    setOptionMoveDays(e.target.value);
    setDefaultMove(e.target.value);
    const selectedMoveIndex = e.target.options.selectedIndex;
    setKeyMoveSelected(e.target.options[selectedMoveIndex].getAttribute('data-key'));
  };

  const handleDisable = () => {
    if (optionMoveDays !== '') {
      return false;
    } else {
      return true;
    }
  };

  const handleChangeOpt = (e: any) => {
    if (e.target.value === 2) {
      setIsPaidLeave(true);
    } else {
      setIsPaidLeave(false);
    }
  };

  return (
    <>
      <div>
        <Form name="dynamic_form_nest_item" autoComplete="off">
          <FormContent>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Radio.Group defaultValue={1} onChange={handleChangeOpt}>
                <Space direction="vertical">
                  <Radio value={1}>{t('leaveOff:move_days.unpaid_leave')}</Radio>
                  <Radio value={2}>{t('leaveOff:move_days.paid_leave')}</Radio>
                </Space>
              </Radio.Group>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', padding: '20px' }}>
              <Space
                style={{
                  display: 'flex',
                  marginBottom: 8,
                  alignItems: 'center',
                }}
              >
                <label style={{ display: 'flex', flexDirection: 'column' }}>
                  {t('leaveOff:move_days.days_of_leave_off_type_to_move')}
                  <CustomSelect
                    style={{ width: '200px' }}
                    onChange={handleChange}
                    defaultValue={'default'}
                    value={defaultChoose}
                  >
                    <option value={'default'} disabled>
                      {t('leaveOff:move_days.choose_an_option')}
                    </option>
                    {data?.map((item: any) => (
                      <optgroup label={item.name}>
                        {item?.leaveTypes.map((value: any) => (
                          <option
                            key={value.id}
                            data-key={value.id}
                            value={value.name}
                            data-day={item.startTime}
                          >
                            {value.name}
                          </option>
                        ))}
                      </optgroup>
                    ))}
                  </CustomSelect>
                </label>

                <SwapOutlined style={{ fontSize: '150%' }} />

                <label style={{ display: 'flex', flexDirection: 'column' }}>
                  {t('leaveOff:move_days.leave_off_type_to_move_days')}
                  <CustomSelect
                    style={{ width: '200px' }}
                    onChange={handleChangeDays}
                    defaultValue={'default'}
                    value={defaultMove}
                  >
                    <option value={'default'} disabled>
                      {t('leaveOff:move_days.choose_an_option')}
                    </option>
                    {dataChange?.map((item: any) => (
                      <optgroup label={item.name}>
                        {item?.leaveTypes.map((value: any) => (
                          <option
                            key={value.id}
                            data-key={value.id}
                            data-code={value.code}
                            value={value.name}
                          >
                            {value.name}
                          </option>
                        ))}
                      </optgroup>
                    ))}
                  </CustomSelect>
                </label>
              </Space>
            </div>
          </FormContent>
        </Form>
      </div>
    </>
  );
};

export default FormMoveDays;

export const FormContent = styled(Form.Item)`
  display: flex;
  flex-direction: row;
  .ant-form-item-control-input-content {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const CustomModal = styled(Modal)`
  .ant-modal-header {
    background: #f1592a;
  }
  .ant-modal-title,
  .ant-modal-close-icon {
    color: #fff;
  }
`;

export const CustomButton = styled(Button)`
  border-radius: 10px;
  margin-left: 30px;
`;

export const CustomSelect = styled.select`
  width: 200px;
  height: 35px;
  margin-top: 5px;
  border-radius: 4px;
  border-color: #ddd;
`;

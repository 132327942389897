import { Checkbox, Input, Select, Steps } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import EmployeeColumn from 'features/employee/components/EmployeeColumn';
import { ListAddUserCamera } from 'features/masterData/General/Interfaces/CameraAI';
import ModelImportImage from 'features/masterData/General/components/pages/CameraAI/components/ModalImportImage';
import { getCheckInPersonRegister } from 'features/masterData/masterDataAction';
import { selectMasterData } from 'features/masterData/masterDataSlice';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import CustomButton from 'styles/buttonStyled';
import { removeAccents } from 'utils/text';

const UserColumn = (props: any, ref: any) => {
  const { handleVisibleAddColumn, placeId } = props;
  const { t } = useTranslation(['masterConfig', 'employee']);
  const masterData = useAppSelector(selectMasterData);
  const employeeList: ListAddUserCamera[] = masterData.checkInUserRegister?.result || [];
  const dispatch = useAppDispatch();
  const [selectedEmployee, setSelectedEmployee] = useState();
  const { Step } = Steps;
  const [, setCheckedList] = useState<string[]>([]);
  const [, setIndeterminate] = useState(false);
  const [, setCheckAll] = useState(false);
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    dispatch(getCheckInPersonRegister({ placeId: placeId }));
  }, [dispatch, placeId]);

  useImperativeHandle(ref, () => ({
    resetChecklist: () => {
      setIndeterminate(false);
      setCheckAll(false);
      setCheckedList([]);
    },
  }));

  const handleChange = (value: any, option: any) => {
    setSelectedEmployee(value);
  };
  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const steps = [
    {
      title: t('masterConfig:manage_place.Choose_user'),
      description: t('masterConfig:manage_place.Choose_user_to_add_to_place'),
      content: (
        <Select
          style={{ display: 'flex' }}
          size="large"
          showSearch
          value={selectedEmployee || null}
          allowClear
          placeholder={'Select user'}
          onChange={handleChange}
          filterOption={(input: any, option: any) => {
            return (
              removeAccents(option?.title?.toLowerCase()).indexOf(
                removeAccents(input.toLowerCase()),
              ) >= 0
            );
          }}
        >
          {employeeList?.map((item) => {
            return (
              <CheckboxStyled key={item.employeeId} value={item.employeeId} title={item.name}>
                <EmployeeColumn avatar={item?.name} fullName={item?.name} email={item?.email} />
              </CheckboxStyled>
            );
          })}
        </Select>
      ),
    },
    {
      title: t('masterConfig:manage_place.Import_image'),
      description: t('masterConfig:manage_place.Import_image_for_user'),
      content: (
        <ModelImportImage
          placeId={placeId}
          setShowModal={handleVisibleAddColumn}
          prev={prev}
          selectedEmployee={employeeList?.find((item) => item?.employeeId === selectedEmployee)}
        />
      ),
    },
  ];

  return (
    <ContainerStyled>
      <CustomSteps current={current}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} description={item.description} />
        ))}
      </CustomSteps>
      <div className="steps-content" style={{ marginTop: 15 }}>
        {steps[current].content}
      </div>
      {current !== steps.length - 1 && (
        <FooterStyled style={{ marginTop: 15 }}>
          <div></div>
          <CustomButton
            style={{ justifyContent: 'flex-end' }}
            disabled={selectedEmployee ? false : true}
            onClick={next}
          >
            {t('masterConfig:manage_place.Import_image')}
          </CustomButton>
        </FooterStyled>
      )}
    </ContainerStyled>
  );
};

export default forwardRef(UserColumn);

export const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: white;
`;

export const FooterStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const TitleStyled = styled.div`
  font-size: var(--txt_font_size_h3);
  font-weight: var(--txt_font_weight_medium);
  padding-bottom: 10px;
`;

export const SearchStyled = styled(Input.Search)`
  .ant-input-group-addon {
    display: none;
  }
  .ant-input-affix-wrapper {
    height: 32px;
    input {
      font-size: 14px;
    }
  }
`;

export const IconImgStyled = styled.img`
  height: 15px;
  width: 15px;
  margin-right: 11px;
`;

export const CheckboxGroupStyled = styled(Checkbox.Group)`
  display: flex;
  flex-direction: column;
  height: 15rem;
  overflow-y: auto;
  margin-bottom: 0.5rem;

  &::-webkit-scrollbar-track {
    margin-top: -20px;
  }
`;

export const CheckboxStyled = styled(Select.Option)`
  font-size: var(--txt_font_size_body);
  color: var(--cl_gray900);
  margin-top: 10px;
`;

export const DoneButton = styled.div`
  text-align: center;
  padding: 4px 10px;
  border-radius: 3px;
  background-color: var(--cl_primary);
  color: white;
  font-weight: 700;
  cursor: context-menu;
  &:hover {
    background-color: var(--cl_primary_dark);
  }
`;

export const CancelButton = styled.div`
  text-align: center;
  padding: 4px 10px;
  border-radius: 3px;
  background-color: white;
  border: 1px var(--cl_gray300) solid;
  font-weight: 700;
  color: var(--cl_gray400);
  cursor: context-menu;
  &:hover {
    color: var(--cl_primary);
    border: 1px var(--cl_primary) solid;
  }
`;
export const CustomSteps = styled(Steps)`
  margin-bottom: 30px;
  .ant-steps-item-process .ant-steps-item-icon {
    background-color: #f1592a;
    border-color: #f1592a;
  }
  .ant-steps-item-finish {
    .ant-steps-item-icon {
      border-color: #f1592a;
    }
  }
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background-color: #f1592a;
  }
  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: #f1592a;
  }
`;

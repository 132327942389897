import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import {
  getCandidateManagement,
  getCandidateManagementInterviewer,
  getDetailCandidate,
  getDetailCandidateInJob,
  getJobHirringProcess,
  getJobTemplates,
  getListJob,
  getListJobAppliedEval,
  getListUserApproverOffer,
  getRecruitmentJob,
  getRecruitmentJobInterviewer,
  listJobTypes,
} from './recruitmentAction';

const initialState: any = {
  recruitmentJobData: null,
  listJobTypes: null,
  jobTemplates: null,
  jobHirringProcess: null,
  candidateManagement: null,
  loadingCandidateManagement: false,
  listJobApproved: null,
  detailCandidate: null,
  listJobAppliedEval: null,
  detailCandidateInJob: null,
  listUserApproverOffer: [],
};

const recruitmentDataSlice = createSlice({
  name: 'recruitmentData',
  initialState: initialState,
  reducers: {
    resetdetailCandidate(state, action) {
      state.detailCandidate = null;
    },
    resetcandidateManagement(state, action) {
      state.candidateManagement = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRecruitmentJob.pending, (state: any, action: any) => {})
      .addCase(getRecruitmentJob.fulfilled, (state: any, action: any) => {
        state.recruitmentJobData = action.payload?.data;
      })
      .addCase(getRecruitmentJob.rejected, (state: any, action: any) => {
        state.recruitmentJobData = null;
      })
      .addCase(listJobTypes.pending, (state: any, action: any) => {})
      .addCase(listJobTypes.fulfilled, (state: any, action: any) => {
        state.listJobTypes = action.payload?.data;
      })
      .addCase(listJobTypes.rejected, (state: any, action: any) => {
        state.listJobTypes = null;
      })
      .addCase(getJobTemplates.pending, (state: any, action: any) => {})
      .addCase(getJobTemplates.fulfilled, (state: any, action: any) => {
        state.jobTemplates = action.payload?.data;
      })
      .addCase(getJobTemplates.rejected, (state: any, action: any) => {
        state.jobTemplates = null;
      })
      .addCase(getJobHirringProcess.pending, (state: any, action: any) => {})
      .addCase(getJobHirringProcess.fulfilled, (state: any, action: any) => {
        state.jobHirringProcess = action.payload?.data;
      })
      .addCase(getJobHirringProcess.rejected, (state: any, action: any) => {
        state.jobHirringProcess = null;
        state.candidateManagement = null;
      })
      .addCase(getCandidateManagement.pending, (state: any, action: any) => {
        state.candidateManagement = null;
        state.loadingCandidateManagement = true;
      })
      .addCase(getCandidateManagement.fulfilled, (state: any, action: any) => {
        state.candidateManagement = action.payload?.data;
        state.loadingCandidateManagement = false;
      })
      .addCase(getCandidateManagement.rejected, (state: any, action: any) => {
        state.candidateManagement = null;
        state.loadingCandidateManagement = false;
      })
      .addCase(getListJob.pending, (state: any, action: any) => {})
      .addCase(getListJob.fulfilled, (state: any, action: any) => {
        state.listJobApproved = action.payload?.data;
      })
      .addCase(getListJob.rejected, (state: any, action: any) => {
        state.listJobApproved = null;
      })
      .addCase(getDetailCandidate.pending, (state: any, action: any) => {})
      .addCase(getDetailCandidate.fulfilled, (state: any, action: any) => {
        state.detailCandidate = action.payload?.data;
      })
      .addCase(getDetailCandidate.rejected, (state: any, action: any) => {
        state.detailCandidate = null;
      })
      .addCase(getListJobAppliedEval.pending, (state: any, action: any) => {})
      .addCase(getListJobAppliedEval.fulfilled, (state: any, action: any) => {
        state.listJobAppliedEval = action.payload?.data;
      })
      .addCase(getListJobAppliedEval.rejected, (state: any, action: any) => {
        state.listJobAppliedEval = null;
      })
      .addCase(getRecruitmentJobInterviewer.pending, (state: any, action: any) => {})
      .addCase(getRecruitmentJobInterviewer.fulfilled, (state: any, action: any) => {
        state.recruitmentJobData = action.payload?.data;
      })
      .addCase(getRecruitmentJobInterviewer.rejected, (state: any, action: any) => {
        state.recruitmentJobData = null;
      })
      .addCase(getCandidateManagementInterviewer.pending, (state: any, action: any) => {
        state.loadingCandidateManagement = true;
      })
      .addCase(getCandidateManagementInterviewer.fulfilled, (state: any, action: any) => {
        state.candidateManagement = action.payload?.data;
        state.loadingCandidateManagement = false;
      })
      .addCase(getCandidateManagementInterviewer.rejected, (state: any, action: any) => {
        state.candidateManagement = null;
        state.loadingCandidateManagement = false;
      })
      .addCase(getDetailCandidateInJob.pending, (state: any, action: any) => {})
      .addCase(getDetailCandidateInJob.fulfilled, (state: any, action: any) => {
        state.detailCandidateInJob = action.payload?.data;
      })
      .addCase(getDetailCandidateInJob.rejected, (state: any, action: any) => {
        state.candidateManagement = null;
      })

      .addCase(getListUserApproverOffer.pending, (state: any, action: any) => {})
      .addCase(getListUserApproverOffer.fulfilled, (state: any, action: any) => {
        var finalList = action.payload?.data.map((item: any) => {
          return {
            ...item,
            id: item.userId,
          };
        });
        state.listUserApproverOffer = finalList;
      })
      .addCase(getListUserApproverOffer.rejected, (state: any, action: any) => {
        state.listUserApproverOffer = [];
      });
  },
});

export const selectRecruitmentData = (state: RootState) => state.recruitment;
export const { reducer, actions } = recruitmentDataSlice;

export default reducer;

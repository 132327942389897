import onsiteEmployeeApi from 'api/onsiteEmployee';
import { useEffect, useState } from 'react';
import { ListParams, OnsiteEmployee } from 'types';

export const useOnsiteEmployeeList = (params: Partial<ListParams>) => {
  const [onsiteEmployeeList, setOnsiteEmployeeList] = useState<Array<OnsiteEmployee>>([]);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    totalResult: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<unknown>(undefined);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const fetchOnsiteEmployeeListAPI = async () => {
      try {
        setIsLoading(true);
        const response = await onsiteEmployeeApi.getAll(params);

        setOnsiteEmployeeList(response.data?.result || []);
        setPagination({
          page: response.data.offset,
          limit: response.data.limit,
          totalResult: response.data.totalResult,
        });
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOnsiteEmployeeListAPI();
  }, [params, refresh]);

  return { data: onsiteEmployeeList, isLoading, error, pagination, setRefresh };
};

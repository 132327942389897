import { Modal } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import ITVSearch from 'components/ITVSearch';
import ITVTable from 'components/ITVTable';
import { getListAction, updateAction } from 'features/onboard/onboardAction';
import { selectOnboard } from 'features/onboard/onboardSlice';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContainerBgWhite } from 'styles/containerBgWhite';
import { useDebounce } from 'use-debounce';
import { Container, ContainerMain, SearchContainer } from '../../../masterData/Position/styled';
import ViewAction from './component/ViewAction';

interface Department {
  id: number;
  name: string;
}

interface ItemProps {
  created: string;
  departments: Department[];
  id: number;
  label: string;
  name: string;
  value: number;
  visibleImp: boolean;
}

const ManageAction = () => {
  const dispatch = useAppDispatch();
  const [data, setData] = useState([]);
  const [keywordDebounce] = useDebounce('', 500);
  const [mode, setMode] = useState<string>('');
  const [currentItem, setCurrentItem] = useState<ItemProps | any>(null);
  const { t } = useTranslation(['onboard', 'insurance', 'timesheet', 'action']);
  const [sizePage, setSizePage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [text, setText] = useState('');
  const onboard = useAppSelector(selectOnboard);
  const listAction = onboard?.listAction;

  useEffect(() => {
    dispatch(getListAction());
  }, [dispatch]);

  useEffect(() => {
    if (listAction?.length) {
      const data = listAction
        ?.slice()
        ?.map((item: any, key: any) => ({ ...item, key }))
        ?.sort((a: any, b: any) => {
          if (a?.module > b?.module) return 1;
          if (a?.module < b?.module) return -1;
          return 0;
        });

      if (text) {
        const temp = data?.filter((item: any) => {
          return item.name?.toLowerCase().includes(text);
        });
        setData(temp);
      } else {
        setData(data);
      }
    }
  }, [listAction, text]);

  useEffect(() => {
    search(keywordDebounce);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keywordDebounce]);

  const search = (key: any) => {
    const text = key.toLowerCase();
    if (text) {
      const temp = listAction.filter((item: any) => {
        return item.name?.toString().toLowerCase().includes(text);
      });
      setText(text);
      setData(temp);
    } else {
      setText('');
      setData(listAction);
    }
  };

  const COLUMNS = [
    {
      title: t('onboard:LabelFormEmail.module'),
      dataIndex: 'module',
      key: 'module',
      width: 50,
      minWidth: 50,
      render: (value: string, record: any, index: any) => {
        const trueIndex = index + sizePage * (currentPage - 1);
        const obj: any = {
          children: value,
          props: {},
        };
        if (index >= 1 && record?.module === data[trueIndex - 1]?.module) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== data.length && record?.module === data[trueIndex + i]?.module;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }
        return obj;
      },
    },
    {
      title: t('onboard:name'),
      dataIndex: 'name',
      key: 'name',
      width: 100,
      minWidth: 100,
      render: function (text: any, record: any, index: any) {
        return record.name;
      },
    },
    {
      title: t('onboard:description'),
      dataIndex: 'description',
      key: 'description',
      width: 150,
      minWidth: 150,
    },
  ];

  const onSave = (value: any) => {
    dispatch(
      updateAction({
        actionId: value.actionId,
        name: value.name,
        description: value.description,
      }),
    );
    setMode('');
    setCurrentItem(null);
  };

  return (
    <ContainerMain>
      <ContainerBgWhite>
        <Container>
          <SearchContainer>
            <ITVSearch handleSearch={(value: any) => search(value)} />
          </SearchContainer>
        </Container>

        <ITVTable
          columns={COLUMNS}
          data={data}
          width={978}
          height={550}
          isRowSelect={false}
          totalIni={data?.length}
          totalResult={data?.length}
          setSelectedUser={null}
          setOffset={setCurrentPage}
          offset={currentPage}
          setLimit={setSizePage}
          limit={sizePage}
        />
      </ContainerBgWhite>

      {mode !== '' && (
        <Modal
          visible={mode !== ''}
          title="Edit"
          okText={t('timesheet:ok')}
          cancelText={t('timesheet:Cancel')}
          width={550}
          maskClosable={false}
          onCancel={() => {
            setMode('');
            setCurrentItem(null);
          }}
          footer={null}
        >
          <ViewAction current={currentItem} onSave={onSave} mode={mode} />
        </Modal>
      )}
    </ContainerMain>
  );
};

export default ManageAction;

import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Table, Tabs, Typography } from 'antd';
import { useAppSelector } from 'app/hooks';
import { colors } from 'assets/colors';
import ITVTable from 'components/ITVTable';
import { MONTH_NAMES } from 'constants/commons';
import { setCheckRow } from 'features/employee/employeeSlice';
import ModalAutoReject from 'features/requestTimesheet/components/ModalAutoReject';
import { PermissionType } from 'hrm-common/extensions/enums/personel';
import cond from 'lodash/cond';
import difference from 'lodash/difference';
import groupBy from 'lodash/groupBy';
import moment from 'moment';
import qs from 'query-string';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { FilterInsurance } from 'types';
import { useDebouncedCallback } from 'use-debounce';
import { getUser } from 'utils/auth';
import SearchPopup from '../../../../employee/components/SearchPopup';
import DropdownView from '../../../components/DropdownView';
import FunctionInsurance from '../../../components/FunctionInsurance';
import InsuranceViewColumn from '../../../components/InsuranceViewColumn';
import {
  formatCurrencyUnit,
  formatDateInsurances,
  formatMonths,
  labelKeyNameView,
  labelKeyStatus,
  labelUnit,
  stringCamelCase,
} from '../../../constants/common';
import {
  getAutoIncreaseDecrease,
  getDataInsuranceMaster,
  getGeneralSetting,
  getRecordPayment,
  getRecordPaymentYear,
  getScheduleDecrease,
  getSchedulePremium,
  getSchedulePremiumApproval,
  getTypeIncrease,
  getTypeInsurance,
} from '../../../insuranceAction';
import { selectInsurance } from '../../../insuranceSlice';
import { titleTable } from './HistoryParticipantList';
import { path } from 'constants/path';
import { INSURANCE_TAB } from 'constants/insurance';
import MaskedValueRenderer from 'components/MaskedValueRenderer';

const { TabPane } = Tabs;

const HrStaffComplusory = () => {
  let permissions = getUser()?.permissions;
  const dispatch = useDispatch();
  const { t } = useTranslation(['insurance', 'common', 'overtime']);
  const [showhideall, setShowHideAll] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const searchParams = qs.parse(location.search);

  useEffect(() => {
    setColumnsRecordPayment(Columns_Record_Payment);
    setColumnsRecordPaymentYear(Columns_Record_Payment_Year);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showhideall]);

  useEffect(() => {
    dispatch(getTypeInsurance());
    dispatch(getTypeIncrease());
    dispatch(getGeneralSetting());
    dispatch(getDataInsuranceMaster());
  }, [dispatch]);

  const insurance = useAppSelector(selectInsurance);
  const dataSchedulePremium = insurance?.dataSchedulePremium;
  const dataApproval = insurance?.dataApproval;
  const dataRecordParticipant = insurance?.dataRecordPayment;
  const dataRecordPaymentYear = insurance?.dataRecordPaymentYear;
  const dataRecordPay = insurance?.dataRecordPayment;
  const dataDecrease = insurance?.dataDecrease;
  const dataOverview = insurance?.dataOverview;
  const datagetAuto = insurance?.datagetAuto;
  const [isAutoReject, setIsAutoReject] = useState(false);
  const [tokenReject, setTokenReject] = useState('');

  const tab = (searchParams.tab as string) || '';
  const tokenByEmail = localStorage.getItem('tokenByEmail');
  const monthYearRecordPay = localStorage.getItem('monthYearRecordPay');
  const openRejectModal = localStorage.getItem('openRejectModal')
    ? JSON.parse(localStorage.getItem('openRejectModal') ?? '')
    : false;

  const EmployeeNameView = (text: any, record: any, index: any) => {
    // @ts-ignore
    return (
      record?.user && (
        <InsuranceViewColumn
          avatar={record?.user?.fullName}
          // @ts-ignore
          userName={record?.user?.fullName}
          email={record?.user?.email}
        />
      )
    );
  };

  const Columns_Decrease = [
    {
      title: function () {
        return titleTable(t('is_code'));
      },
      dataIndex: 'user',
      key: 'user',
      width: 80,
      minWidth: 80,
      render: function (text: any, record: any, index: any) {
        return <InfoTextCenter>{record?.user?.employeeId}</InfoTextCenter>;
      },
    },
    {
      title: function () {
        return titleTable(t('Employee'));
      },
      dataIndex: 'user',
      key: 'user',
      width: 300,
      minWidth: 300,
      render: function (text: any, record: any, index: any) {
        return EmployeeNameView(text, record, index);
      },
    },
    {
      title: function () {
        return titleTable(t('overtime:project_name'));
      },
      dataIndex: 'projectPrimaryName',
      key: 'projectPrimaryName',
      width: 150,
      minWidth: 150,
      sorter: {
        compare: (a: any, b: any) => {
          if (a.user?.projectPrimaryName && b.user?.projectPrimaryName)
            return a.user?.projectPrimaryName.localeCompare(b.user?.projectPrimaryName);
          return 0;
        },
        multiple: 1,
      },
      render: function (text: any, record: any, index: any) {
        return <div>{record?.user?.projectPrimaryName}</div>;
      },
    },
    {
      title: function () {
        return titleTable(t('position'));
      },
      dataIndex: 'positionName',
      key: 'positionName',
      width: 150,
      minWidth: 150,
      sorter: {
        compare: (a: any, b: any) => {
          if (a.user?.positionName && b.user?.positionName)
            return a.user?.positionName.localeCompare(b.user?.positionName);
          return 0;
        },
        multiple: 2,
      },
      render: function (text: any, record: any, index: any) {
        return <div>{record?.user?.positionName}</div>;
      },
    },
    {
      title: function () {
        return titleTable(t('Created_date'));
      },
      dataIndex: 'currentValidDate',
      key: 'currentValidDate',
      width: 100,
      minWidth: 100,
      render: function (text: any, record: any, index: any) {
        return <InfoTextCenter>{formatDateInsurances(record?.createdAt)}</InfoTextCenter>;
      },
    },
    {
      title: function () {
        return (
          <div>
            <InfoTextCenter>{t('Social_Insurance')}</InfoTextCenter>
            <InfoTextCenter>{t('Number')}</InfoTextCenter>
          </div>
        );
      },
      dataIndex: 'user',
      key: 'user',
      width: 150,
      minWidth: 150,
      render: function (text: any, record: any, index: any) {
        return <InfoTextCenter>{record?.user?.socialInsuranceNumber}</InfoTextCenter>;
      },
    },
    {
      title: function () {
        return titleTable(t('valid_date'));
      },
      dataIndex: 'newValidDate',
      key: 'newValidDate',
      width: 100,
      minWidth: 100,
      render: function (text: any, record: any, index: any) {
        return <>{formatDateInsurances(record?.newValidDate)}</>;
      },
    },
    {
      title: function () {
        return titleTable(t('type_of'));
      },
      dataIndex: 'typeOfPremium',
      key: 'typeOfPremium',
      width: 100,
      minWidth: 100,
      sorter: {
        compare: (a: any, b: any) => {
          if (a.typeOfPremium && b.typeOfPremium)
            return a.typeOfPremium.localeCompare(b.typeOfPremium);
          return 0;
        },
        multiple: 3,
      },
      render: function (text: any, record: any, index: any) {
        return <InfoTextCenter>{stringCamelCase(record?.typeOfPremium)}</InfoTextCenter>;
      },
    },
    {
      title: function () {
        return titleTable(t('status'));
      },
      dataIndex: 'status',
      key: 'status',
      width: 150,
      minWidth: 150,
      sorter: {
        compare: (a: any, b: any) => {
          if (a.status && b.status) return a.status.localeCompare(b.status);
          return 0;
        },
        multiple: 3,
      },
      render: function (text: any, record: any, index: any) {
        if (record?.status === labelKeyStatus.KEY_DRAFT) {
          return (
            <InfoTextCenter>
              <InfoButton style={{ background: '#D1D5DB', color: '#374151' }}>
                {record?.status === labelKeyStatus.KEY_DRAFT && 'Draft'}
              </InfoButton>
            </InfoTextCenter>
          );
        }
        if (record?.status === labelKeyStatus.KEY_WAITING_FOR_APPROVAL) {
          return (
            <InfoTextCenter>
              <InfoButton style={{ background: '#FEF3C7', color: '#D97706' }}>
                {record?.status === labelKeyStatus.KEY_WAITING_FOR_APPROVAL &&
                  'Waiting for approval'}
              </InfoButton>
            </InfoTextCenter>
          );
        }
        if (record?.status === labelKeyStatus.KEY_REJECTED) {
          return (
            <InfoTextCenter>
              <InfoButton style={{ background: '#FEE2E2', color: '#E80F0F' }}>
                {record?.status === labelKeyStatus.KEY_REJECTED && 'Reject'}
              </InfoButton>
            </InfoTextCenter>
          );
        }
        if (record?.status === labelKeyStatus.KEY_APPROVED) {
          return (
            <InfoTextCenter>
              <InfoButton style={{ background: '#D1FAE5', color: '#059669' }}>
                {record?.status === labelKeyStatus.KEY_APPROVED && 'Approved'}
              </InfoButton>
            </InfoTextCenter>
          );
        }
      },
    },
  ];
  const Columns_Approval = [
    {
      title: function () {
        return titleTable(t('is_code'));
      },
      dataIndex: 'user',
      key: 'user',
      width: 80,
      minWidth: 80,
      render: function (text: any, record: any, index: any) {
        return <InfoTextCenter>{record?.user?.employeeId}</InfoTextCenter>;
      },
    },
    {
      title: function () {
        return titleTable(t('Employee'));
      },
      dataIndex: 'name',
      key: 'name',
      width: 300,
      minWidth: 300,
      render: function (text: any, record: any, index: any) {
        return EmployeeNameView(text, record, index);
      },
    },
    {
      title: function () {
        return titleTable(t('position'));
      },
      dataIndex: 'positionName',
      key: 'positionName',
      width: 150,
      minWidth: 150,
      sorter: {
        compare: (a: any, b: any) => {
          if (a.user?.positionName && b.user?.positionName)
            return a.user?.positionName.localeCompare(b.user?.positionName);
          return 0;
        },
        multiple: 2,
      },
      render: function (text: any, record: any, index: any) {
        return <div>{record?.user?.positionName}</div>;
      },
    },
    {
      title: function () {
        return (
          <div>
            <InfoTextCenter>{t('Social_Insurance')}</InfoTextCenter>
            <InfoTextCenter>{t('Number')}</InfoTextCenter>
          </div>
        );
      },
      dataIndex: 'user',
      key: 'user',
      width: 150,
      minWidth: 150,
      render: function (text: any, record: any, index: any) {
        return <InfoTextCenter>{record?.user?.socialInsuranceNumber}</InfoTextCenter>;
      },
    },
    {
      title: function () {
        return titleTable(t('adjust_for'));
      },
      dataIndex: 'adjustFor',
      key: 'adjustFor',
      width: 120,
      minWidth: 120,
      sorter: {
        compare: (a: any, b: any) => {
          if (a.adjustFor && b.adjustFor) return a.adjustFor.localeCompare(b.adjustFor);
          return 0;
        },
        multiple: 3,
      },
      render: function (text: any, record: any, index: any) {
        return <InfoTextCenter>{record?.adjustFor}</InfoTextCenter>;
      },
    },
    {
      title: function () {
        return (
          <div>
            <InfoTextCenter>{t('Type_of_increase')}</InfoTextCenter>
            <InfoTextCenter>{t('decrease')}</InfoTextCenter>
          </div>
        );
      },
      dataIndex: 'typeOfPremium',
      key: 'typeOfPremium',
      width: 200,
      minWidth: 200,
      sorter: {
        compare: (a: any, b: any) => {
          if (a.typeOfPremium && b.typeOfPremium)
            return a.typeOfPremium.localeCompare(b.typeOfPremium);
          return 0;
        },
        multiple: 3,
      },
      render: function (text: any, record: any, index: any) {
        return <InfoTextCenter>{stringCamelCase(record?.typeOfPremium)}</InfoTextCenter>;
      },
    },
    {
      title: function () {
        return titleTable(t('valid_date'));
      },
      dataIndex: 'newValidDate',
      key: 'newValidDate',
      width: 100,
      minWidth: 100,
      render: function (text: any, record: any, index: any) {
        return <InfoTextCenter>{formatDateInsurances(record?.newValidDate)}</InfoTextCenter>;
      },
    },
    {
      title: function () {
        return titleTable(t('requested_date'));
      },
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 120,
      minWidth: 120,
      render: function (text: any, record: any, index: any) {
        return <InfoTextCenter>{formatDateInsurances(record?.createdAt)}</InfoTextCenter>;
      },
    },
    {
      title: function () {
        return titleTable(t('status'));
      },
      dataIndex: 'status',
      key: 'status',
      width: 150,
      minWidth: 150,
      sorter: {
        compare: (a: any, b: any) => {
          if (a.status && b.status) return a.status.localeCompare(b.status);
          return 0;
        },
        multiple: 3,
      },
      render: function (text: any, record: any, index: any) {
        if (record?.status === labelKeyStatus.KEY_APPROVED) {
          return (
            <InfoTextCenter>
              <InfoButton style={{ background: '#D1FAE5', color: '#059669' }}>
                {record?.status}
              </InfoButton>
            </InfoTextCenter>
          );
        }
        if (record?.status === labelKeyStatus.KEY_WAITING_FOR_APPROVAL) {
          return (
            <InfoTextCenter>
              <InfoButton style={{ background: '#FEF3C7', color: '#D97706' }}>
                {record?.status}
              </InfoButton>
            </InfoTextCenter>
          );
        }
        if (record?.status === labelKeyStatus.KEY_REJECTED) {
          return (
            <InfoTextCenter>
              <InfoButton style={{ background: '#FEE2E2', color: '#DC2626' }}>
                {record?.status}
              </InfoButton>
            </InfoTextCenter>
          );
        }
      },
    },
  ];
  const Columns_Participant_List = [
    {
      title: function () {
        return titleTable(t('is_code'));
      },
      dataIndex: 'employeeId',
      key: 'employeeId',
      width: 80,
      minWidth: 80,
      render: function (text: any, record: any, index: any) {
        return <InfoTextCenter>{record?.datarecor?.user?.employeeId}</InfoTextCenter>;
      },
    },
    {
      title: function () {
        return titleTable(t('Employee'));
      },
      dataIndex: 'schedulePremium',
      key: 'schedulePremium',
      width: 300,
      minWidth: 300,
      render: function (text: any, record: any, index: any) {
        return EmployeeNameView(text, record?.datarecor, index);
      },
    },
    {
      title: function () {
        return titleTable(t('position'));
      },
      dataIndex: 'positionName',
      key: 'positionName',
      width: 150,
      minWidth: 150,
      sorter: {
        compare: (a: any, b: any) => {
          if (a.datarecor?.user?.positionName && b.datarecor?.user?.positionName)
            return a.datarecor?.user?.positionName.localeCompare(b.datarecor?.user?.positionName);
          return 0;
        },
        multiple: 2,
      },
      render: function (text: any, record: any, index: any) {
        return <div>{record?.datarecor?.user?.positionName}</div>;
      },
    },
    {
      title: function () {
        return titleTable(t('Period'));
      },
      dataIndex: 'period',
      key: 'period',
      width: 100,
      minWidth: 100,
      render: function (text: any, record: any, index: any) {
        return <InfoTextCenter>{formatMonths(record?.datarecor?.period)}</InfoTextCenter>;
      },
    },
    {
      title: function () {
        return titleTable(t('type_of'));
      },
      dataIndex: 'typeOfPremium',
      key: 'typeOfPremium',
      width: 100,
      minWidth: 100,
      sorter: {
        compare: (a: any, b: any) => {
          if (a.typeOfPremium && b.typeOfPremium)
            return a.typeOfPremium.localeCompare(b.typeOfPremium);
          return 0;
        },
        multiple: 3,
      },
      render: function (text: any, record: any, index: any) {
        return (
          record?.datarecor?.typeOfPremium && (
            <InfoTextCenter>{stringCamelCase(record?.datarecor?.typeOfPremium)}</InfoTextCenter>
          )
        );
      },
    },
    {
      title: function () {
        return (
          <div>
            <InfoTextCenter>{t('Social_Insurance')}</InfoTextCenter>
            <InfoTextCenter>{t('Number')}</InfoTextCenter>
          </div>
        );
      },
      dataIndex: 'socialInsuranceNumber',
      key: 'socialInsuranceNumber',
      width: 150,
      minWidth: 150,
      render: function (text: any, record: any, index: any) {
        return <InfoTextCenter>{record?.datarecor?.user?.socialInsuranceNumber}</InfoTextCenter>;
      },
    },
    {
      title: function () {
        return titleTable(t('salary_premium'));
      },
      dataIndex: 'salaryPremium',
      key: 'salaryPremium',
      width: 150,
      minWidth: 150,
      render: function (text: any, record: any, index: any) {
        return (
          <InfoTextCenter>
            <Input
              type={record?.showall === true ? 'text' : 'password'}
              disabled
              style={{
                borderWidth: 0,
                margin: 0,
                padding: 0,
                textAlign: 'center',
                color: 'rgba(38,38,38,0.85)',
              }}
              value={formatCurrencyUnit(record?.datarecor?.salaryPremium, labelUnit)}
            />
          </InfoTextCenter>
        );
      },
    },
    {
      title: function () {
        return (
          <div>
            <InfoTextCenter>{t('Total_contribute')}</InfoTextCenter>
            <InfoTextCenter>{t('of_employee')}</InfoTextCenter>
          </div>
        );
      },
      dataIndex: 'totalContributeSelf',
      key: 'totalContributeSelf',
      width: 150,
      minWidth: 150,
      render: function (text: any, record: any, index: any) {
        return (
          <Input
            type={record?.showall === true ? 'text' : 'password'}
            disabled
            style={{ borderWidth: 0, textAlign: 'center', color: 'rgba(38,38,38,0.85)' }}
            value={formatCurrencyUnit(record?.datarecor?.totalContributeSelf, labelUnit)}
          />
        );
      },
    },
    {
      title: function () {
        return (
          <div>
            <InfoTextCenter>{t('Total_contribute')}</InfoTextCenter>
            <InfoTextCenter>{t('of_employer')}</InfoTextCenter>
          </div>
        );
      },
      dataIndex: 'totalContributeEmployer',
      key: 'totalContributeEmployer',
      width: 150,
      minWidth: 150,
      render: function (text: any, record: any, index: any) {
        return (
          <Input
            type={record?.showall === true ? 'text' : 'password'}
            disabled
            style={{ borderWidth: 0, textAlign: 'center', color: 'rgba(38,38,38,0.85)' }}
            value={formatCurrencyUnit(record?.datarecor?.totalContributeEmployer, labelUnit)}
          />
        );
      },
    },
    {
      title: function () {
        return (
          <>
            {showhideall === false ? (
              <Typography.Link onClick={() => handleOpenShowHideAll()}>
                {t('Show_all_money')}
              </Typography.Link>
            ) : (
              <Typography.Link onClick={() => handleOpenShowHideAll()}>
                {t('Hide_money')}
              </Typography.Link>
            )}
          </>
        );
      },
      dataIndex: 'showall',
      key: 'showall',
      width: 120,
      minWidth: 120,
      render: function (text: any, record: any, index: any) {
        return (
          <InfoTextCenter>
            {record?.showall === false ? (
              <Typography.Link onClick={() => handleOpenShow(record)}>
                {t('Show_money_is')}
              </Typography.Link>
            ) : (
              <Typography.Link onClick={() => handleOpenHide(record)}>
                {t('Hide_money_is')}
              </Typography.Link>
            )}
          </InfoTextCenter>
        );
      },
    },
  ];

  if (permissions?.includes(PermissionType.INSURANCE_PARTICIPANT_DETAIL_VIEW)) {
    Columns_Participant_List.push({
      title: function () {
        return <></>;
      },
      dataIndex: 'datarecor',
      key: 'datarecor',
      width: 40,
      minWidth: 40,
      render: function (text: any, record: any, index: any) {
        return (
          <DropdownView
            isShowFullMenu={false}
            item={record?.datarecor}
            permissions={permissions}
            checkview={labelKeyNameView.DROPDOWN_PARTICIPANT_LIST}
            activekey={tab}
            reSetData={reSetData}
          />
        );
      },
    });
  }

  const Columns_Record_Payment = [
    {
      title: function () {
        return titleTable(t('is_code'));
      },
      dataIndex: 'employeeId',
      key: 'employeeId',
      width: 80,
      minWidth: 80,
      fixed: 'left',
      render: function (text: any, record: any, index: any) {
        return <InfoTextCenter>{record?.datarecor?.user?.employeeId}</InfoTextCenter>;
      },
    },
    {
      title: function () {
        return titleTable(t('Employee'));
      },
      dataIndex: 'schedulePremium',
      key: 'schedulePremium',
      width: 300,
      minWidth: 300,
      fixed: 'left',
      render: function (text: any, record: any, index: any) {
        return EmployeeNameView(text, record?.datarecor, index);
      },
    },
    {
      title: function () {
        return (
          <div>
            <InfoTextCenter>{t('Social_Insurance')}</InfoTextCenter>
            <InfoTextCenter>{t('Number')}</InfoTextCenter>
          </div>
        );
      },
      dataIndex: 'socialinsurance',
      key: 'socialinsurance',
      width: 150,
      minWidth: 150,
      fixed: 'left',
      render: function (text: any, record: any, index: any) {
        return <InfoTextCenter>{record?.datarecor?.user?.socialInsuranceNumber}</InfoTextCenter>;
      },
    },
    {
      title: function () {
        return (
          <div>
            <InfoTextCenter>{t('Total_contribute')}</InfoTextCenter>
            <InfoTextCenter>{t('of_employee')}</InfoTextCenter>
          </div>
        );
      },
      dataIndex: 'totalContributeSelf',
      key: 'totalContributeSelf',
      width: 150,
      minWidth: 150,
      render: function (text: any, record: any, index: any) {
        return (
          <InfoTextCenter>
            <MaskedValueRenderer value={record?.datarecor?.totalContributeSelf} />
          </InfoTextCenter>
        );
      },
    },
    {
      title: function () {
        return (
          <div>
            <InfoTextCenter>{t('Total_contribute')}</InfoTextCenter>
            <InfoTextCenter>{t('of_employer')}</InfoTextCenter>
          </div>
        );
      },
      dataIndex: 'totalContributeEmployer',
      key: 'totalContributeEmployer',
      width: 150,
      minWidth: 150,
      render: function (text: any, record: any, index: any) {
        return (
          <InfoTextCenter>
            <MaskedValueRenderer value={record?.datarecor?.totalContributeEmployer} />
          </InfoTextCenter>
        );
      },
    },
    {
      title: function () {
        return titleTable(t('salary_premium'));
      },
      dataIndex: 'newSalaryPremium',
      key: 'newSalaryPremium',
      width: 150,
      minWidth: 150,
      render: function (text: any, record: any, index: any) {
        return (
          <InfoTextCenter>
            <MaskedValueRenderer value={record?.datarecor?.salaryPremium} />
          </InfoTextCenter>
        );
      },
    },
  ];
  const Columns_Record_Payment_Year = [
    {
      title: function () {
        return titleTable(t('is_code'));
      },
      dataIndex: 'employeeId',
      key: 'employeeId',
      width: 80,
      minWidth: 80,
      fixed: 'left',
      render: function (text: any, record: any, index: any) {
        return <InfoTextCenter>{record?.user?.employeeId}</InfoTextCenter>;
      },
    },
    {
      title: function () {
        return titleTable(t('Employee'));
      },
      dataIndex: 'schedulePremium',
      key: 'schedulePremium',
      width: 300,
      minWidth: 300,
      fixed: 'left',
      render: function (text: any, record: any, index: any) {
        return EmployeeNameView(text, record, index);
      },
    },
    {
      title: function () {
        return (
          <div>
            <InfoTextCenter>{t('Social_Insurance')}</InfoTextCenter>
            <InfoTextCenter>{t('Number')}</InfoTextCenter>
          </div>
        );
      },
      dataIndex: 'socialinsurance',
      key: 'socialinsurance',
      width: 150,
      minWidth: 150,
      fixed: 'left',
      render: function (text: any, record: any, index: any) {
        return <InfoTextCenter>{record?.user?.socialInsuranceNumber}</InfoTextCenter>;
      },
    },
  ];

  const [columns_decrease, setColumnsDecrease] = useState(Columns_Decrease);
  const [columns_approval, setColumnsApproval] = useState(Columns_Approval);
  const [columns_record_payment, setColumnsRecordPayment] = useState(Columns_Record_Payment);
  const [columns_record_payment_year, setColumnsRecordPaymentYear] = useState(
    Columns_Record_Payment_Year,
  );

  const [dataIncrease, setDataIncrease] = useState([]);
  const [totalResultIncrease, setTotalResultIncrease] = useState(0);

  const [datasdecrease, setDatasDecrease] = useState([]);
  const [totalResultDecrease, setTotalResultDecrease] = useState(0);

  const [datasApproval, setDatasApproval] = useState([]);
  const [totalResultApproval, setTotalResultApproval] = useState(0);

  const [dataParticipant, setDataParticipant] = useState([]);
  const [totalResultParticipant, setTotalResultParticipant] = useState(0);

  const [data_record_payment_year, setDataRecordPaymentYear] = useState([]);
  const [totalResultRecordYear, setTotalResultRecordYear] = useState(0);

  const [datasRecordPay, setDatasRecordPay] = useState([]);
  const [totalResultRecordPay, setTotalResultRecordPay] = useState(0);

  const [selectedUser, setSelectedUser] = useState([]);
  const [checkid, setClickID] = useState([]);
  const [dateIncrease, setDateIncrease] = useState({
    start: undefined,
    end: undefined,
  });
  const [dateDecrease, setDateDecrease] = useState({
    start: undefined,
    end: undefined,
  });
  const [datePart, setDatePart] = useState(moment().format('YYYY-MM'));
  const [dateRecord, setDateRecord] = useState(
    monthYearRecordPay ? monthYearRecordPay : moment().format('YYYY-MM'),
  );
  const [dateRecordYear, setDateRecordYear] = useState(moment().format('YYYY'));
  const [fromdateApproval, setFromDateApproval] = useState(undefined);
  const [todateApproval, setToDateApproval] = useState(undefined);
  const [datasSearch, setDatasSearch] = useState([]);
  const [dataDecreaseSearch, setDataDecreaseSearch] = useState([]);
  const [datasApprovalSearch, setdatasApprovalSearch] = useState([]);

  const [pageIncrease, setPageIncrease] = useState(1);
  const [limitIncrease, setLimitIncrease] = useState(10);
  const [searchIncrease, setSearchIncrease] = useState('');

  const [pageDecrease, setPageDecrease] = useState(1);
  const [limitDecrease, setLimitDecrease] = useState(10);
  const [searchDecrease, setSearchDecrease] = useState('');

  const [pageApproval, setPageApproval] = useState(1);
  const [limitApproval, setLimitApproval] = useState(10);
  const [searchApproval, setSearchApproval] = useState('');

  const [pageParticipant, setPageParticipant] = useState(1);
  const [limitParticipant, setLimitParticipant] = useState(10);
  const [searchParticipant, setSearchParticipant] = useState('');

  const [pageRecordPay, setPageRecordPay] = useState(1);
  const [limitRecordPay, setLimitRecordPay] = useState(10);
  const [searchRecordPay, setSearchRecordPay] = useState('');

  const [pageRecordYear, setPageRecordYear] = useState(1);
  const [limitRecordYear, setLimitRecordYear] = useState(10);
  const [searchRecordYear, setSearchRecordYear] = useState('');

  const filtersScheduleIncrease: Partial<FilterInsurance> = useMemo(() => {
    return {
      offset: pageIncrease,
      limit: limitIncrease,
      search: 'increase',
      fromDate: dateIncrease.start,
      toDate: dateIncrease.end,
      name: searchIncrease,
    };
  }, [pageIncrease, limitIncrease, dateIncrease.start, dateIncrease.end, searchIncrease]);

  const filtersScheduleDecrease: Partial<FilterInsurance> = useMemo(() => {
    return {
      offset: pageDecrease,
      limit: limitDecrease,
      search: 'decrease',
      fromDate: dateDecrease.start,
      toDate: dateDecrease.end,
      name: searchDecrease,
    };
  }, [dateDecrease.end, dateDecrease.start, limitDecrease, pageDecrease, searchDecrease]);

  const filtersApproval: Partial<FilterInsurance> = useMemo(() => {
    return {
      offset: pageApproval,
      limit: limitApproval,
      search: 'waiting-for-approved',
      fromDate: fromdateApproval,
      toDate: todateApproval,
      name: searchApproval,
    };
  }, [fromdateApproval, limitApproval, pageApproval, searchApproval, todateApproval]);

  const filtersParticipant: Partial<FilterInsurance> = useMemo(() => {
    return {
      offset: pageParticipant,
      limit: limitParticipant,
      search: moment(datePart).format('YYYY-MM'),
      name: searchParticipant,
    };
  }, [datePart, limitParticipant, pageParticipant, searchParticipant]);

  const filtersRecordPay: Partial<FilterInsurance> = useMemo(() => {
    return {
      offset: pageRecordPay,
      limit: limitRecordPay,
      search: monthYearRecordPay ? monthYearRecordPay : moment(dateRecord).format('YYYY-MM'),
      name: searchRecordPay,
    };
  }, [pageRecordPay, limitRecordPay, monthYearRecordPay, dateRecord, searchRecordPay]);

  const filtersRecordYear: Partial<FilterInsurance> = useMemo(() => {
    return {
      offset: pageRecordYear,
      limit: limitRecordYear,
      search: moment(dateRecordYear).format('YYYY'),
      name: searchRecordYear,
    };
  }, [dateRecordYear, limitRecordYear, pageRecordYear, searchRecordYear]);

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const alertUser = () => {
    history.replace(path.compulsoryInsurance, null);
  };

  useEffect(() => {
    if (!tab) {
      if (permissions?.includes(PermissionType.SCHEDULE_INCREASE_DECREASE_LIST)) {
        history.push(`${path.compulsoryInsurance}?tab=${INSURANCE_TAB.Increase}`);
      } else if (permissions?.includes(PermissionType.INSURANCE_APPROVAL_LIST_VIEW)) {
        history.push(`${path.compulsoryInsurance}?tab=${INSURANCE_TAB.Waiting}`);
      } else if (permissions?.includes(PermissionType.INSURANCE_PARTICIPANTS_LIST_VIEW)) {
        history.push(`${path.compulsoryInsurance}?tab=${INSURANCE_TAB.Participant}`);
      } else {
        history.push(`${path.compulsoryInsurance}?tab=${INSURANCE_TAB.Recordpayment}`);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  useEffect(() => {
    if (openRejectModal) {
      setIsAutoReject(true);
    } else {
      setIsAutoReject(false);
    }
  }, [openRejectModal]);

  useEffect(() => {
    if (tokenByEmail) {
      setTokenReject(tokenByEmail);
    } else {
      setTokenReject('');
    }
  }, [tokenByEmail]);

  const callback = (key: any) => {
    setSelectedUser([]);
    history.push(`${path.compulsoryInsurance}?tab=${key}`);
  };

  const debounced = useDebouncedCallback((value, type) => {
    onSearchList(value, type);
  }, 500);

  useEffect(() => {
    const showAll = dataParticipant.every((obj: any) => obj.showall);
    if (showAll) {
      setShowHideAll(true);
    } else {
      setShowHideAll(false);
    }
  }, [dataParticipant]);

  const setDataViewClickTab = (key: any) => {
    switch (key) {
      case INSURANCE_TAB.Waiting:
        dispatch(
          getSchedulePremiumApproval({
            fromDate: filtersApproval.fromDate,
            toDate: filtersApproval.toDate,
            status: filtersApproval.search,
            name: filtersApproval.name,
            limit: filtersApproval.limit,
            page: filtersApproval.offset,
          }),
        );
        break;
      case INSURANCE_TAB.Increase:
        dispatch(
          getSchedulePremium({
            fromDate: filtersScheduleIncrease.fromDate,
            toDate: filtersScheduleIncrease.toDate,
            search: filtersScheduleIncrease.search,
            name: filtersScheduleIncrease.name,
            limit: filtersScheduleIncrease.limit,
            page: filtersScheduleIncrease.offset,
          }),
        );
        break;
      case INSURANCE_TAB.Decrease:
        dispatch(
          getScheduleDecrease({
            fromDate: filtersScheduleDecrease.fromDate,
            toDate: filtersScheduleDecrease.toDate,
            search: filtersScheduleDecrease.search,
            name: filtersScheduleDecrease.name,
            limit: filtersScheduleDecrease.limit,
            page: filtersScheduleDecrease.offset,
          }),
        );
        break;
      case INSURANCE_TAB.Participant:
        dispatch(
          getRecordPayment({
            search: filtersParticipant.search,
            name: filtersParticipant.name,
            limit: filtersParticipant.limit,
            page: filtersParticipant.offset,
          }),
        );
        break;
      case INSURANCE_TAB.Recordpayment:
        dispatch(
          getRecordPayment({
            search: filtersRecordPay.search,
            name: filtersRecordPay.name,
            limit: filtersRecordPay.limit,
            page: filtersRecordPay.offset,
          }),
        );
        break;
      case INSURANCE_TAB.RecordPaymentYear:
        dispatch(
          getRecordPaymentYear({
            search: filtersRecordYear.search,
            name: filtersRecordYear.name,
            limit: filtersRecordYear.limit,
            page: filtersRecordYear.offset,
          }),
        );
    }
  };

  useEffect(() => {
    if (tab === INSURANCE_TAB.Increase || tab === INSURANCE_TAB.Decrease) {
      getDataOverview(tab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataOverview, tab]);

  useEffect(() => {
    dispatch(setCheckRow([]));
  }, [tab, dispatch]);

  const getDataOverview = (key: any) => {
    if (dataOverview?.data) {
      switch (key) {
        case INSURANCE_TAB.Increase:
          let bodyincrease = {
            fromDate: moment().startOf('month').format('YYYY-MM-DD'),
            toDate: moment().endOf('month').format('YYYY-MM-DD'),
            boardType: 'on',
            configId: dataOverview?.data?.id,
            adjustFor: 'increase',
          };
          dispatch(getAutoIncreaseDecrease(bodyincrease));
          break;
        case INSURANCE_TAB.Decrease:
          let bodydecrease = {
            fromDate: moment().startOf('month').format('YYYY-MM-DD'),
            toDate: moment().endOf('month').format('YYYY-MM-DD'),
            boardType: 'off',
            configId: dataOverview?.data?.id,
            adjustFor: 'decrease',
          };
          dispatch(getAutoIncreaseDecrease(bodydecrease));
          break;
      }
    }
  };

  useEffect(() => {
    setDataViewClickTab(tab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tab,
    filtersScheduleIncrease,
    filtersScheduleDecrease,
    filtersApproval,
    filtersParticipant,
    filtersRecordPay,
    filtersRecordYear,
  ]);

  useEffect(() => {
    let is_dataApproval = dataApproval?.data?.result;
    let obj: any = [];
    // eslint-disable-next-line array-callback-return
    is_dataApproval?.map((item: any) => {
      obj.push({
        key: item?.id,
        departmentName: item?.user?.departmentName,
        positionName: item?.user?.positionName,
        adjustFor: item?.adjustFor,
        typeOfPremium: item?.typeOfPremium,
      });
    });
    setdatasApprovalSearch(obj);
  }, [dataApproval]);

  useEffect(() => {
    if (Array.isArray(dataApproval?.data?.result)) {
      let is_dataApproval = dataApproval?.data?.result;
      let totalResultApproval = dataApproval?.data?.total;
      setDatasApproval(is_dataApproval);
      setTotalResultApproval(totalResultApproval);
      let obj: any = [];
      // eslint-disable-next-line array-callback-return
      is_dataApproval.map((item: any) => {
        obj.push({
          key: item.id,
          departmentName: item?.user?.departmentName,
          positionName: item?.user?.positionName,
          adjustFor: item?.adjustFor,
          typeOfPremium: item?.typeOfPremium,
        });
      });
      const positionName = groupBy(obj, 'positionName');
      const adjustFor = groupBy(obj, 'adjustFor');
      const typeOfPremium = groupBy(obj, 'typeOfPremium');
      const positionOptions = Object.keys(positionName);
      const adjustOptions = Object.keys(adjustFor);
      const typeOfPremiumOptions = Object.keys(typeOfPremium);
      const temp: any = columns_approval.map((item: any) => {
        //setdatasApprovalSearch(obj);
        if (item.dataIndex === 'positionName') {
          return {
            ...item,
            ...getColumnSearchProps('positionName', positionOptions),
          };
        }
        if (item.dataIndex === 'adjustFor') {
          return {
            ...item,
            ...getColumnSearchProps('adjustFor', adjustOptions),
          };
        }
        if (item.dataIndex === 'typeOfPremium') {
          return {
            ...item,
            ...getColumnSearchProps('typeOfPremium', typeOfPremiumOptions),
          };
        }
        return item;
      });
      setColumnsApproval(temp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataApproval, datasApprovalSearch]);

  const formatDataRecordPayment = (listData: any, types: any) => {
    if (Array.isArray(listData)) {
      let dataRecor = listData;
      let obj: any = [];
      for (let i = 0; i < dataRecor?.length; i++) {
        obj.push({
          id: dataRecor[i].id,
          datarecor: dataRecor[i],
          showall: false,
        });
      }
      types === 'part' ? setDataParticipant(obj) : setDatasRecordPay(obj);
    }
  };

  useEffect(() => {
    let is_dataSchedulePremium = dataSchedulePremium?.data?.result;
    let obj: any = [];
    // eslint-disable-next-line array-callback-return
    is_dataSchedulePremium?.map((item: any) => {
      obj.push({
        key: item.id,
        departmentName: item?.user?.departmentName,
        positionName: item?.user?.positionName,
        typeOfPremium: item?.typeOfPremium,
      });
    });
    setDatasSearch(obj);
  }, [dataSchedulePremium]);

  useEffect(() => {
    let is_dataDecrease = dataDecrease?.data?.result;
    let obj: any = [];
    // eslint-disable-next-line array-callback-return
    is_dataDecrease?.map((item: any) => {
      obj.push({
        key: item?.id,
        departmentName: item?.user?.departmentName,
        positionName: item?.user?.positionName,
        typeOfPremium: item?.typeOfPremium,
      });
    });
    setDataDecreaseSearch(obj);
  }, [dataDecrease]);

  useEffect(() => {
    if (tab === INSURANCE_TAB.Increase && Array.isArray(dataSchedulePremium?.data?.result)) {
      let is_dataSchedulePremium = dataSchedulePremium?.data?.result;
      let totalResultIncrease = dataSchedulePremium?.data?.total;
      setDataIncrease(is_dataSchedulePremium);
      setTotalResultIncrease(totalResultIncrease);
      let obj: any = [];
      // eslint-disable-next-line array-callback-return
      is_dataSchedulePremium?.map((item: any) => {
        obj.push({
          key: item.id,
          departmentName: item?.user?.departmentName,
          positionName: item?.user?.positionName,
          typeOfPremium: item?.typeOfPremium,
        });
      });
      const departments = groupBy(obj, 'departmentName');
      const positionName = groupBy(obj, 'positionName');
      const typeOfPremium = groupBy(obj, 'typeOfPremium');
      const departmentOptions = Object.keys(departments);
      const positionOptions = Object.keys(positionName);
      const typeOfPremiumOptions = Object.keys(typeOfPremium);
      const temp: any = columns_decrease?.map((item: any) => {
        //setDatasSearch(obj);
        if (item.dataIndex === 'departmentName') {
          return {
            ...item,
            ...getColumnSearchProps('departmentName', departmentOptions),
          };
        }
        if (item.dataIndex === 'positionName') {
          return {
            ...item,
            ...getColumnSearchProps('positionName', positionOptions),
          };
        }
        if (item.dataIndex === 'typeOfPremium') {
          return {
            ...item,
            ...getColumnSearchProps('typeOfPremium', typeOfPremiumOptions),
          };
        }
        return item;
      });
      setColumnsDecrease(temp);
    }
    if (tab === INSURANCE_TAB.Decrease && Array.isArray(dataDecrease?.data?.result)) {
      let is_dataDecrease = dataDecrease?.data?.result;
      let totalResultDecrease = dataDecrease?.data?.total;
      setDatasDecrease(is_dataDecrease);
      setTotalResultDecrease(totalResultDecrease);
      let obj: any = [];
      // eslint-disable-next-line array-callback-return
      is_dataDecrease?.map((item: any) => {
        obj.push({
          key: item?.id,
          departmentName: item?.user?.departmentName,
          positionName: item?.user?.positionName,
          typeOfPremium: item?.typeOfPremium,
        });
      });
      const departments = groupBy(obj, 'departmentName');
      const positionName = groupBy(obj, 'positionName');
      const typeOfPremium = groupBy(obj, 'typeOfPremium');
      const departmentOptions = Object.keys(departments);
      const positionOptions = Object.keys(positionName);
      const typeOfPremiumOptions = Object.keys(typeOfPremium);
      const temp: any = columns_decrease?.map((item: any) => {
        //setDataDecreaseSearch(obj);
        if (item.dataIndex === 'departmentName') {
          return {
            ...item,
            ...getColumnSearchProps('departmentName', departmentOptions),
          };
        }
        if (item.dataIndex === 'positionName') {
          return {
            ...item,
            ...getColumnSearchProps('positionName', positionOptions),
          };
        }
        if (item.dataIndex === 'typeOfPremium') {
          return {
            ...item,
            ...getColumnSearchProps('typeOfPremium', typeOfPremiumOptions),
          };
        }
        return item;
      });
      setColumnsDecrease(temp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSchedulePremium, dataDecrease, datasSearch, dataDecreaseSearch]);

  useEffect(() => {
    formatDataRecordPayment(dataRecordParticipant?.data?.result, 'part');
    setTotalResultParticipant(dataRecordParticipant?.data?.total);
  }, [dataRecordParticipant]);

  useEffect(() => {
    formatDataRecordPayment(dataRecordPay?.data?.result, 'recordpay');
    setTotalResultRecordPay(dataRecordPay?.data?.total);
  }, [dataRecordPay]);

  useEffect(() => {
    if (Array.isArray(dataRecordPaymentYear?.data?.result)) {
      let dataRecordYear = dataRecordPaymentYear?.data?.result;
      let obj: any = [];
      for (let i = 0; i < dataRecordYear?.length; i++) {
        obj.push({
          id: dataRecordYear[i].user.id,
          responseRecordPayment: dataRecordYear[i].responseRecordPayment,
          user: dataRecordYear[i].user,
        });
      }
      setDataRecordPaymentYear(obj);
    }
    setTotalResultRecordYear(dataRecordPaymentYear?.data?.total);
  }, [dataRecordPaymentYear]);

  const getBackgroundColore = (index: number) => {
    let rs = cond([
      [() => index === 1, () => '#FEA78C'],
      [() => index === 2, () => '#FFF5E9'],
      [() => index === 3, () => '#FEA78C'],
      [() => index === 4, () => '#FFF5E9'],
      [() => index === 5, () => '#FEA78C'],
      [() => index === 6, () => '#FFF5E9'],
      [() => index === 7, () => '#FEA78C'],
      [() => index === 8, () => '#FFF5E9'],
      [() => index === 9, () => '#FEA78C'],
      [() => index === 10, () => '#FFF5E9'],
      [() => index === 11, () => '#FEA78C'],
      [() => index === 12, () => '#FFF5E9'],
    ])();

    return rs;
  };

  useEffect(() => {
    const addColumn: any = [];
    MONTH_NAMES.forEach((item: any, index: any) => {
      addColumn.push({
        title: (
          <div style={{ padding: '8px', background: getBackgroundColore(index + 1) }}>
            {t(`common:month.${index}.name`)}
          </div>
        ),
        key: index,
        children: [
          {
            title: function () {
              return (
                <div style={{ padding: '8px', background: getBackgroundColore(index + 1) }}>
                  <InfoTextCenter>{t('Total_contribute')}</InfoTextCenter>
                  <InfoTextCenter>{t('of_employee')}</InfoTextCenter>
                </div>
              );
            },
            dataIndex: 'totalContributeSelf',
            key: 'totalContributeSelf',
            width: 150,
            minWidth: 150,
            render: function (text: any, record: any, idx: any) {
              const foundValue = record?.responseRecordPayment?.find(
                (item: any) => moment(item?.period, 'YYYY-MM').month() === index,
              )?.totalContributeSelf;
              return {
                props: {
                  style: {
                    background: getBackgroundColore(index + 1),
                  },
                },
                children: (
                  <InfoTextCenter>
                    <MaskedValueRenderer value={foundValue || 0} />
                  </InfoTextCenter>
                ),
              };
            },
          },
          {
            title: function () {
              return (
                <div style={{ padding: '8px', background: getBackgroundColore(index + 1) }}>
                  <InfoTextCenter>{t('Total_contribute')}</InfoTextCenter>
                  <InfoTextCenter>{t('of_employer')}</InfoTextCenter>
                </div>
              );
            },
            dataIndex: 'totalContributeEmployer',
            key: 'totalContributeEmployer',
            width: 150,
            minWidth: 150,
            render: function (text: any, record: any, idx: any) {
              const foundValue = record?.responseRecordPayment?.find(
                (item: any) => moment(item?.period, 'YYYY-MM').month() === index,
              )?.totalContributeEmployer;
              return {
                props: {
                  style: {
                    background: getBackgroundColore(index + 1),
                  },
                },
                children: (
                  <InfoTextCenter>
                    <MaskedValueRenderer value={foundValue || 0} />
                  </InfoTextCenter>
                ),
              };
            },
          },
          {
            title: function () {
              return (
                <div
                  style={{
                    padding: '8px',
                    height: '60px',
                    background: getBackgroundColore(index + 1),
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                  }}
                >
                  <InfoTextCenter>{t('salary_premium')}</InfoTextCenter>
                </div>
              );
            },
            dataIndex: 'newSalaryPremium',
            key: 'newSalaryPremium',
            width: 150,
            minWidth: 150,
            render: function (text: any, record: any, idx: any) {
              const foundValue = record?.responseRecordPayment?.find(
                (item: any) => moment(item?.period, 'YYYY-MM').month() === index,
              )?.salaryPremium;
              return {
                props: {
                  style: {
                    background: getBackgroundColore(index + 1),
                  },
                },
                children: (
                  <InfoTextCenter>
                    <MaskedValueRenderer value={foundValue || 0} />
                  </InfoTextCenter>
                ),
              };
            },
          },
        ],
      });
    });
    setColumnsRecordPaymentYear([...Columns_Record_Payment_Year, ...addColumn]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, data_record_payment_year]);

  useEffect(() => {
    setColumnsDecrease((columns: any) => [
      ...columns?.filter((item: any) => item?.key !== 'id'),
      {
        title: '',
        dataIndex: 'id',
        key: 'id',
        width: 40,
        minWidth: 40,
        render: function (text: any, record: any, index: any) {
          return (
            <DropdownView
              isShowFullMenu={false}
              item={record}
              permissions={permissions}
              checkview={labelKeyNameView.DROPDOWN_DECREASE}
              activekey={tab}
              reSetData={reSetData}
            />
          );
        },
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, filtersScheduleIncrease, filtersScheduleDecrease]);

  useEffect(() => {
    setColumnsApproval((columns: any) => [
      ...columns?.filter((item: any) => item?.key !== 'gender'),
      {
        title: '',
        dataIndex: 'gender',
        key: 'gender',
        width: 40,
        minWidth: 40,
        render: function (text: any, record: any, index: any) {
          return (
            <DropdownView
              isShowFullMenu={false}
              item={record}
              permissions={permissions}
              checkview={labelKeyNameView.DROPDOWN_APPROVAL}
              activekey={tab}
              reSetData={reSetData}
            />
          );
        },
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromdateApproval, todateApproval, tab, filtersApproval]);

  const reSetData = (key: string) => {
    setDataViewClickTab(tab);

    if (tab === INSURANCE_TAB.Increase || tab === INSURANCE_TAB.Decrease) {
      getDataOverview(tab);
    }
    setClickID([]);
  };

  const onSearchList = (key: string, nameTab: string) => {
    switch (nameTab) {
      case INSURANCE_TAB.Increase:
        if (key) {
          setSearchIncrease(key);
          setPageIncrease(1);
        } else {
          setSearchIncrease('');
          setPageIncrease(1);
        }
        break;
      case INSURANCE_TAB.Decrease:
        if (key) {
          setSearchDecrease(key);
          setPageDecrease(1);
        } else {
          setSearchDecrease('');
          setPageDecrease(1);
        }
        break;
      case INSURANCE_TAB.Waiting:
        if (key) {
          setSearchApproval(key);
          setPageApproval(1);
        } else {
          setSearchApproval('');
          setPageApproval(1);
        }
        break;
      case INSURANCE_TAB.Participant:
        if (key) {
          setSearchParticipant(key);
          setPageParticipant(1);
        } else {
          setSearchParticipant('');
          setPageParticipant(1);
        }
        break;
      case INSURANCE_TAB.Recordpayment:
        if (key) {
          setSearchRecordPay(key);
          setPageRecordPay(1);
        } else {
          setSearchRecordPay('');
          setPageRecordPay(1);
        }
        break;
      case INSURANCE_TAB.RecordPaymentYear:
        if (key) {
          setSearchRecordYear(key);
          setPageRecordYear(1);
        } else {
          setSearchRecordYear('');
          setPageRecordYear(1);
        }
        break;
    }
  };

  const addColumns = (cols: any) => {
    const dataIndexs = columns_record_payment?.map((item: any) => item?.dataIndex);
    const arr = columns_record_payment?.filter((item: any) => dataIndexs?.includes(item.dataIndex));

    // eslint-disable-next-line array-callback-return
    const temp = cols?.map((item: any) => {
      if (item.value === 'projectPrimaryName') {
        return {
          title: function () {
            return titleTable(t('overtime:project_name'));
          },
          dataIndex: item.value,
          key: 'projectPrimaryName',
          ellipsis: true,
          width: 150,
          minWidth: 150,
          render: (text: any, record: any, index: any) => {
            return <div>{record?.datarecor?.user?.projectPrimaryName}</div>;
          },
        };
      }
      if (item.value === 'socialRateEmployer') {
        return {
          title: function () {
            return (
              <div>
                <InfoTextCenter>{t('Social_Insurance')}</InfoTextCenter>
                <InfoTextCenter>{t('contribute_of_employer')}</InfoTextCenter>
              </div>
            );
          },
          dataIndex: item.value,
          key: item.value,
          ellipsis: true,
          width: 200,
          minWidth: 200,
          render: (text: any, record: any, index: any) => {
            return (
              <InfoTextCenter>
                {formatCurrencyUnit(record?.datarecor?.socialRateEmployer, labelUnit)}
              </InfoTextCenter>
            );
          },
        };
      }
      if (item.value === 'socialRateSelf') {
        return {
          title: function () {
            return (
              <div>
                <InfoTextCenter>{t('Social_Insurance')}</InfoTextCenter>
                <InfoTextCenter>{t('contribute_of_employee')}</InfoTextCenter>
              </div>
            );
          },
          dataIndex: item.value,
          key: item.value,
          ellipsis: true,
          width: 200,
          minWidth: 200,
          render: (text: any, record: any, index: any) => {
            return (
              <InfoTextCenter>
                {formatCurrencyUnit(record?.datarecor?.socialRateSelf, labelUnit)}
              </InfoTextCenter>
            );
          },
        };
      }
      if (item.value === 'unemploymentRateEmployer') {
        return {
          title: function () {
            return (
              <div>
                <InfoTextCenter>{t('Unemployment_insurance')}</InfoTextCenter>
                <InfoTextCenter>{t('contribute_of_employer')}</InfoTextCenter>
              </div>
            );
          },
          dataIndex: item.value,
          key: item.value,
          ellipsis: true,
          width: 200,
          minWidth: 200,
          render: (text: any, record: any, index: any) => {
            return (
              <InfoTextCenter>
                {formatCurrencyUnit(record?.datarecor?.unemploymentRateEmployer, labelUnit)}
              </InfoTextCenter>
            );
          },
        };
      }
      if (item.value === 'unemploymentRateSelf') {
        return {
          title: function () {
            return (
              <div>
                <InfoTextCenter>{t('Unemployment_insurance')}</InfoTextCenter>
                <InfoTextCenter>{t('contribute_of_employee')}</InfoTextCenter>
              </div>
            );
          },
          dataIndex: item.value,
          key: item.value,
          ellipsis: true,
          width: 200,
          minWidth: 200,
          render: (text: any, record: any, index: any) => {
            return (
              <InfoTextCenter>
                {formatCurrencyUnit(record?.datarecor?.unemploymentRateSelf, labelUnit)}
              </InfoTextCenter>
            );
          },
        };
      }
      if (item.value === 'healthRateEmployer') {
        return {
          title: function () {
            return (
              <div>
                <InfoTextCenter>{t('Health_insurance')}</InfoTextCenter>
                <InfoTextCenter>{t('contribute_of_employer')}</InfoTextCenter>
              </div>
            );
          },
          dataIndex: item.value,
          key: item.value,
          ellipsis: true,
          width: 200,
          minWidth: 200,
          render: (text: any, record: any, index: any) => {
            return (
              <InfoTextCenter>
                {formatCurrencyUnit(record?.datarecor?.healthRateEmployer, labelUnit)}
              </InfoTextCenter>
            );
          },
        };
      }
      if (item.value === 'healthRateSelf') {
        return {
          title: function () {
            return (
              <div>
                <InfoTextCenter>{t('Health_insurance')}</InfoTextCenter>
                <InfoTextCenter>{t('contribute_of_employee')}</InfoTextCenter>
              </div>
            );
          },
          dataIndex: item.value,
          key: item.value,
          ellipsis: true,
          width: 200,
          minWidth: 200,
          render: (text: any, record: any, index: any) => {
            return (
              <InfoTextCenter>
                {formatCurrencyUnit(record?.datarecor?.healthRateSelf, labelUnit)}
              </InfoTextCenter>
            );
          },
        };
      }
      if (item.value === 'unionDuesRateEmployer') {
        return {
          title: function () {
            return (
              <div>
                <InfoTextCenter>{t('Union_Dues')}</InfoTextCenter>
                <InfoTextCenter>{t('contribute_of_employer')}</InfoTextCenter>
              </div>
            );
          },
          dataIndex: item.value,
          key: item.value,
          ellipsis: true,
          width: 200,
          minWidth: 200,
          render: (text: any, record: any, index: any) => {
            return (
              <InfoTextCenter>
                {formatCurrencyUnit(record?.datarecor?.unionDuesRateEmployer, labelUnit)}
              </InfoTextCenter>
            );
          },
        };
      }
      if (item.value === 'unionDuesRateSelf') {
        return {
          title: function () {
            return (
              <div>
                <InfoTextCenter>{t('Union_Dues')}</InfoTextCenter>
                <InfoTextCenter>{t('contribute_of_employee')}</InfoTextCenter>
              </div>
            );
          },
          dataIndex: item.value,
          key: item.value,
          ellipsis: true,
          width: 200,
          minWidth: 200,
          render: (text: any, record: any, index: any) => {
            return (
              <InfoTextCenter>
                {formatCurrencyUnit(record?.datarecor?.unionDuesRateSelf, labelUnit)}
              </InfoTextCenter>
            );
          },
        };
      }
    });

    const arrNotNull = arr.filter(
      (item: any) => item.dataIndex === undefined || item.dataIndex === '',
    );
    const arrCheck = difference(arr, arrNotNull);
    if (cols?.length > 0) {
      setColumnsRecordPayment(arrCheck.slice(0, 6).concat(temp));
    } else {
      setColumnsRecordPayment(arrCheck.slice(0, 6));
    }
  };

  const handleOpenShow = (item: any) => {
    let obj: any = [];
    if (Array.isArray(dataRecordParticipant?.data?.result)) {
      for (let i = 0; i < dataParticipant?.length; i++) {
        if (dataParticipant[i]?.datarecor?.id === item?.datarecor?.id) {
          obj.push({
            id: dataParticipant[i].id,
            datarecor: dataParticipant[i].datarecor,
            showall: true,
          });
        } else {
          obj.push({
            id: dataParticipant[i]?.id,
            datarecor: dataParticipant[i]?.datarecor,
            showall: dataParticipant[i]?.showall,
          });
        }
      }
    }
    if (Array.isArray(obj) && obj?.length > 0) {
      setDataParticipant(obj);
    }
  };
  const handleOpenHide = (item: any) => {
    let obj: any = [];
    if (Array.isArray(dataRecordParticipant?.data?.result)) {
      for (let i = 0; i < dataParticipant?.length; i++) {
        if (dataParticipant[i]?.datarecor?.id === item?.datarecor?.id) {
          obj.push({
            id: dataParticipant[i]?.id,
            datarecor: dataParticipant[i]?.datarecor,
            showall: false,
          });
        } else {
          obj.push({
            id: dataParticipant[i]?.id,
            datarecor: dataParticipant[i]?.datarecor,
            showall: dataParticipant[i]?.showall,
          });
        }
      }
    }
    if (Array.isArray(obj) && obj?.length > 0) {
      setDataParticipant(obj);
    }
  };

  const handleOpenShowHideAll = () => {
    let obj: any = [];
    if (Array.isArray(dataRecordParticipant?.data?.result)) {
      for (let i = 0; i < dataParticipant?.length; i++) {
        if (showhideall === false) {
          obj.push({
            id: dataParticipant[i]?.id,
            datarecor: dataParticipant[i].datarecor,
            showall: true,
          });
        } else {
          obj.push({
            id: dataParticipant[i]?.id,
            datarecor: dataParticipant[i].datarecor,
            showall: false,
          });
        }
      }
    }
    setShowHideAll(!showhideall);
    if (Array.isArray(obj) && obj?.length > 0) {
      setDataParticipant(obj);
    }
  };

  // filter columns
  function getColumnSearchProps(dataIndex: any, options: any) {
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
        <SearchPopup
          searchText={selectedKeys[0]}
          onPressEnter={() => confirm()}
          options={options}
          confirm={confirm}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          onSearchTextChange={(e: any) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            onSearchList(e.target.value, tab);
          }}
          onClickFilter={(checkedValues: any) => onFilterPopupConfirm(selectedKeys, confirm)}
        />
      ),
      filterIcon: (filtered: any) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      onFilter: (value: any, record: any) => {
        return dataIndex === 'departmentName' || dataIndex === 'positionName'
          ? record?.user[dataIndex] === value
          : record?.[dataIndex] === value;
      },
    };
  }

  // handle onFilter clicked
  const onFilterPopupConfirm = (selectedKeys: any, confirm: any) => {
    confirm && confirm();
  };

  const functionDateTime = (e: any, item: any, nameScreen: string) => {
    switch (nameScreen) {
      case labelKeyNameView.DROPDOWN_DECREASE:
        setDateDecrease({
          ...dateDecrease,
          start: e ? moment(e).format('YYYY-MM-DD') : undefined,
          end: item ? moment(item).format('YYYY-MM-DD') : undefined,
        });
        break;
      case labelKeyNameView.DROPDOWN_INCREASE:
        setDateIncrease({
          ...dateIncrease,
          start: e ? moment(e).format('YYYY-MM-DD') : undefined,
          end: item ? moment(item).format('YYYY-MM-DD') : undefined,
        });
        break;
      case labelKeyNameView.DROPDOWN_APPROVAL:
        setFromDateApproval(e ? moment(e).format('YYYY-MM-DD') : undefined);
        setToDateApproval(item ? moment(item).format('YYYY-MM-DD') : undefined);
        break;
    }
  };

  const functionPackDateTime = (e: any, item: any, nameScreen: string) => {
    switch (nameScreen) {
      case labelKeyNameView.DROPDOWN_PARTICIPANT_LIST:
        setDatePart(e);
        break;
      case labelKeyNameView.RECORD_PAYMENT_VIEW:
        setDateRecord(e);
        break;
      case labelKeyNameView.RECORD_PAYMENT_VIEW_YEAR:
        setDateRecordYear(e);
        break;
    }
  };

  const TabViewSchedule = () => {
    if (permissions?.includes(PermissionType.SCHEDULE_INCREASE_DECREASE_LIST)) {
      return (
        <>
          <TabPane tab={t('Schedule_increase')} key={INSURANCE_TAB.Increase}>
            {tab === INSURANCE_TAB.Increase && (
              <>
                <FunctionInsurance
                  isShowFull={true}
                  handleSearch={(value: any) => debounced(value, 'increase')}
                  permissions={permissions}
                  checkview={labelKeyNameView.DROPDOWN_INCREASE}
                  selectedUser={selectedUser}
                  functionDateTime={functionDateTime}
                  reSetData={reSetData}
                  activekey={tab}
                  dateIncrease={dateIncrease}
                  checkAuto={datagetAuto?.data}
                  dataOverview={dataOverview}
                  data={dataIncrease}
                />

                <ITVTable
                  columns={columns_decrease}
                  data={dataIncrease}
                  totalResult={totalResultIncrease}
                  setOffset={setPageIncrease}
                  offset={pageIncrease}
                  setLimit={setLimitIncrease}
                  limit={limitIncrease}
                  setSelectedUser={setSelectedUser}
                />
              </>
            )}
          </TabPane>
          <TabPane tab={t('Schedule_decrease')} key={INSURANCE_TAB.Decrease}>
            {tab === INSURANCE_TAB.Decrease && (
              <>
                <FunctionInsurance
                  isShowFull={true}
                  handleSearch={(value: any) => debounced(value, 'decrease')}
                  permissions={permissions}
                  checkview={labelKeyNameView.DROPDOWN_DECREASE}
                  selectedUser={selectedUser}
                  functionDateTime={functionDateTime}
                  reSetData={reSetData}
                  activekey={tab}
                  dateDecrease={dateDecrease}
                  checkAuto={datagetAuto?.data}
                  dataOverview={dataOverview}
                  data={datasdecrease}
                />

                <ITVTable
                  columns={columns_decrease}
                  data={datasdecrease}
                  totalResult={totalResultDecrease}
                  setOffset={setPageDecrease}
                  offset={pageDecrease}
                  setLimit={setLimitDecrease}
                  limit={limitDecrease}
                  setSelectedUser={setSelectedUser}
                />
              </>
            )}
          </TabPane>
        </>
      );
    } else {
      return null;
    }
  };

  const TabViewApproval = () => {
    if (permissions?.includes(PermissionType.INSURANCE_APPROVAL_LIST_VIEW)) {
      return (
        <TabPane
          tab={
            <div style={{ display: 'flex' }}>
              <div>{t('Approval')}</div>
              <div
                style={{
                  color: '#DC2626',
                  marginTop: -12,
                  width: 20,
                  height: 20,
                  background: '#FEE2E2',
                  display: 'flex',
                  fontSize: 12,
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginLeft: 4,
                  borderRadius: 10,
                }}
              >
                {totalResultApproval}
              </div>
            </div>
          }
          key={INSURANCE_TAB.Waiting}
        >
          {tab === INSURANCE_TAB.Waiting && (
            <>
              <FunctionInsurance
                isShowFull={true}
                handleSearch={(value: any) => debounced(value, 'waiting-for-approved')}
                permissions={permissions}
                checkview={labelKeyNameView.DROPDOWN_APPROVAL}
                selectedUser={selectedUser}
                functionDateTime={functionDateTime}
                reSetData={reSetData}
                checkid={checkid}
                activekey={tab}
                dateApproval={[fromdateApproval, todateApproval]}
                data={datasApproval}
              />

              <ITVTable
                columns={columns_approval}
                data={datasApproval}
                totalResult={totalResultApproval}
                setOffset={setPageApproval}
                offset={pageApproval}
                setLimit={setLimitApproval}
                limit={limitApproval}
                setSelectedUser={setSelectedUser}
              />
            </>
          )}
        </TabPane>
      );
    } else {
      return null;
    }
  };

  const sumArray = (mang: any, key: any) => {
    if (mang) {
      let sum = 0;
      // eslint-disable-next-line array-callback-return
      mang.map(function (value: any) {
        switch (key) {
          case 'totalContributeSelf':
            sum += Number(value?.datarecor?.totalContributeSelf);
            break;
          case 'totalContributeEmployer':
            sum += Number(value?.datarecor?.totalContributeEmployer);
            break;
          case 'amountOfSalary':
            sum += Number(value?.datarecor?.amountOfSalary);
            break;
          case 'socialRateEmployer':
            sum += Number(value?.datarecor?.socialRateEmployer);
            break;
          case 'socialRateSelf':
            sum += Number(value?.datarecor?.socialRateSelf);
            break;
          case 'unemploymentRateEmployer':
            sum += Number(value?.datarecor?.unemploymentRateEmployer);
            break;
          case 'unemploymentRateSelf':
            sum += Number(value?.datarecor?.unemploymentRateSelf);
            break;
          case 'healthRateEmployer':
            sum += Number(value?.datarecor?.healthRateEmployer);
            break;
          case 'healthRateSelf':
            sum += Number(value?.datarecor?.healthRateSelf);
            break;
          case 'unionDuesRateEmployer':
            sum += Number(value?.datarecor?.unionDuesRateEmployer);
            break;
          case 'unionDuesRateSelf':
            sum += Number(value?.datarecor?.unionDuesRateSelf);
            break;
        }
      });
      return formatCurrencyUnit(sum, labelUnit);
    }
  };

  const sumArrayRecordYear = (mang: any, key: any, month: any) => {
    if (mang) {
      let sum = 0;
      // eslint-disable-next-line array-callback-return
      mang.map(function (value: any) {
        const foundValue = value?.responseRecordPayment?.find(
          (item: any) => moment(item?.period, 'YYYY-MM').month() === month,
        );
        switch (key) {
          case 'totalContributeSelf':
            sum += Number(foundValue?.totalContributeSelf || 0);
            break;
          case 'totalContributeEmployer':
            sum += Number(foundValue?.totalContributeEmployer || 0);
            break;
          case 'newSalaryPremium':
            sum += Number(foundValue?.salaryPremium || 0);
            break;
        }
      });
      return sum;
    }
  };

  const handleCloseAutoReject = () => {
    setIsAutoReject(false);
    localStorage.removeItem('tokenByEmail');
    localStorage.removeItem('openRejectModal');
    localStorage.removeItem('monthYearRecordPay');
  };

  return (
    <Container>
      <Tabs activeKey={tab} onChange={callback}>
        {TabViewSchedule()}
        {TabViewApproval()}
        {permissions?.includes(PermissionType.INSURANCE_PARTICIPANTS_LIST_VIEW) && (
          <TabPane tab={t('participant_list')} key={INSURANCE_TAB.Participant}>
            {tab === INSURANCE_TAB.Participant && (
              <>
                <FunctionInsurance
                  isShowFull={true}
                  handleSearch={(value: any) => debounced(value, 'participant')}
                  permissions={permissions}
                  checkview={labelKeyNameView.DROPDOWN_PARTICIPANT_LIST}
                  selectedUser={selectedUser}
                  functionPackDateTime={functionPackDateTime}
                  reSetData={reSetData}
                  datePart={datePart}
                  activekey={tab}
                  data={dataParticipant}
                />
                {dataParticipant?.length > 0 ? (
                  <ITVTable
                    columns={Columns_Participant_List}
                    data={dataParticipant}
                    totalResult={totalResultParticipant}
                    setOffset={setPageParticipant}
                    offset={pageParticipant}
                    setLimit={setLimitParticipant}
                    limit={limitParticipant}
                    setSelectedUser={setSelectedUser}
                    // isShowTotalFooter={true}
                  />
                ) : (
                  <ViewNotData>
                    <h3 style={{ color: colors.gray400, fontSize: '20px' }}>No data</h3>
                  </ViewNotData>
                )}
              </>
            )}
          </TabPane>
        )}
        {permissions?.includes(PermissionType.RECORD_INSURANCE_PAYMENT_VIEW) && (
          <>
            <TabPane tab={t('record_payment')} key={INSURANCE_TAB.Recordpayment}>
              {tab === INSURANCE_TAB.Recordpayment && (
                <>
                  <FunctionInsurance
                    isShowFull={true}
                    handleSearch={(value: any) => debounced(value, 'recordpayment')}
                    checkview={labelKeyNameView.RECORD_PAYMENT_VIEW}
                    addColumns={addColumns}
                    permissions={permissions}
                    selectedUser={selectedUser}
                    buttonfunction={false}
                    functionPackDateTime={functionPackDateTime}
                    reSetData={reSetData}
                    dateRecord={dateRecord}
                    activekey={tab}
                    data={datasRecordPay}
                  />
                  <ITVTable
                    columns={columns_record_payment}
                    data={datasRecordPay}
                    totalResult={totalResultRecordPay}
                    setOffset={setPageRecordPay}
                    offset={pageRecordPay}
                    setLimit={setLimitRecordPay}
                    limit={limitRecordPay}
                    setSelectedUser={setSelectedUser}
                    summary={() => (
                      <Table.Summary fixed={'top'}>
                        <Table.Summary.Row style={{ background: '#d8fff3' }}>
                          <Table.Summary.Cell index={0} colSpan={3}>
                            <strong>{t('total')} :</strong>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={3} colSpan={1}></Table.Summary.Cell>
                          <Table.Summary.Cell index={4}>
                            <TotalRecordPayment>
                              <MaskedValueRenderer
                                value={
                                  dataRecordPay?.data?.totalContributeAllEmployer
                                    ?.totalContributeEmployee
                                }
                              />
                            </TotalRecordPayment>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={4}>
                            <TotalRecordPayment>
                              <MaskedValueRenderer
                                value={
                                  dataRecordPay?.data?.totalContributeAllEmployer
                                    ?.totalContributeEmployer
                                }
                              />
                            </TotalRecordPayment>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={4}>
                            <TotalRecordPayment>
                              <MaskedValueRenderer
                                value={
                                  dataRecordPay?.data?.totalContributeAllEmployer?.amountOfSalary
                                }
                              />
                            </TotalRecordPayment>
                          </Table.Summary.Cell>
                          {
                            // eslint-disable-next-line array-callback-return
                            columns_record_payment.map((item: any) => {
                              switch (item.key) {
                                case 'projectPrimaryName':
                                  return (
                                    <Table.Summary.Cell index={4} colSpan={1}></Table.Summary.Cell>
                                  );
                                case 'socialRateEmployer':
                                  return (
                                    <Table.Summary.Cell index={4} colSpan={1}>
                                      <TotalRecordPayment>
                                        {sumArray(datasRecordPay, 'socialRateEmployer')}
                                      </TotalRecordPayment>
                                    </Table.Summary.Cell>
                                  );
                                case 'socialRateSelf':
                                  return (
                                    <Table.Summary.Cell index={4} colSpan={1}>
                                      <TotalRecordPayment>
                                        {sumArray(datasRecordPay, 'socialRateSelf')}
                                      </TotalRecordPayment>
                                    </Table.Summary.Cell>
                                  );
                                case 'unemploymentRateEmployer':
                                  return (
                                    <Table.Summary.Cell index={4} colSpan={1}>
                                      <TotalRecordPayment>
                                        {sumArray(datasRecordPay, 'unemploymentRateEmployer')}
                                      </TotalRecordPayment>
                                    </Table.Summary.Cell>
                                  );
                                case 'unemploymentRateSelf':
                                  return (
                                    <Table.Summary.Cell index={4} colSpan={1}>
                                      <TotalRecordPayment>
                                        {sumArray(datasRecordPay, 'unemploymentRateSelf')}
                                      </TotalRecordPayment>
                                    </Table.Summary.Cell>
                                  );
                                case 'healthRateEmployer':
                                  return (
                                    <Table.Summary.Cell index={4} colSpan={1}>
                                      <TotalRecordPayment>
                                        {sumArray(datasRecordPay, 'healthRateEmployer')}
                                      </TotalRecordPayment>
                                    </Table.Summary.Cell>
                                  );
                                case 'healthRateSelf':
                                  return (
                                    <Table.Summary.Cell index={4} colSpan={1}>
                                      <TotalRecordPayment>
                                        {sumArray(datasRecordPay, 'healthRateSelf')}
                                      </TotalRecordPayment>
                                    </Table.Summary.Cell>
                                  );
                                case 'unionDuesRateEmployer':
                                  return (
                                    <Table.Summary.Cell index={4} colSpan={1}>
                                      <TotalRecordPayment>
                                        {sumArray(datasRecordPay, 'unionDuesRateEmployer')}
                                      </TotalRecordPayment>
                                    </Table.Summary.Cell>
                                  );
                                case 'unionDuesRateSelf':
                                  return (
                                    <Table.Summary.Cell index={4} colSpan={1}>
                                      <TotalRecordPayment>
                                        {sumArray(datasRecordPay, 'unionDuesRateSelf')}
                                      </TotalRecordPayment>
                                    </Table.Summary.Cell>
                                  );
                              }
                            })
                          }
                        </Table.Summary.Row>
                      </Table.Summary>
                    )}
                    // isShowTotalFooter={true}
                  />
                </>
              )}
            </TabPane>
            <TabPane tab={t('record_payment_by_year')} key={INSURANCE_TAB.RecordPaymentYear}>
              {tab === INSURANCE_TAB.RecordPaymentYear && (
                <>
                  <FunctionInsurance
                    isShowFull={true}
                    handleSearch={(value: any) => debounced(value, 'recordPaymentYear')}
                    checkview={labelKeyNameView.RECORD_PAYMENT_VIEW_YEAR}
                    permissions={permissions}
                    selectedUser={selectedUser}
                    buttonfunction={false}
                    functionPackDateTime={functionPackDateTime}
                    reSetData={reSetData}
                    dateRecord={dateRecordYear}
                    activekey={tab}
                    data={data_record_payment_year}
                  />
                  <ITVTable
                    columns={columns_record_payment_year}
                    data={data_record_payment_year}
                    totalResult={totalResultRecordYear}
                    setOffset={setPageRecordYear}
                    offset={pageRecordYear}
                    setLimit={setLimitRecordYear}
                    limit={limitRecordYear}
                    setSelectedUser={setSelectedUser}
                    summary={() => {
                      return (
                        <Table.Summary fixed="top">
                          <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={4}>
                              <strong>{t('total')} :</strong>
                            </Table.Summary.Cell>

                            {columns_record_payment_year.map((i: any) => {
                              // eslint-disable-next-line array-callback-return
                              return i?.children?.map((item: any) => {
                                switch (item?.key) {
                                  case 'totalContributeSelf':
                                    return (
                                      <Table.Summary.Cell
                                        index={4}
                                        colSpan={1}
                                        className="ant-table-cell"
                                      >
                                        <TotalRecordPayment>
                                          <MaskedValueRenderer
                                            value={
                                              sumArrayRecordYear(
                                                data_record_payment_year,
                                                'totalContributeSelf',
                                                i?.key,
                                              ) || ''
                                            }
                                          />
                                        </TotalRecordPayment>
                                      </Table.Summary.Cell>
                                    );
                                  case 'totalContributeEmployer':
                                    return (
                                      <Table.Summary.Cell index={4} colSpan={1}>
                                        <TotalRecordPayment>
                                          <MaskedValueRenderer
                                            value={
                                              sumArrayRecordYear(
                                                data_record_payment_year,
                                                'totalContributeEmployer',
                                                i?.key,
                                              ) || ''
                                            }
                                          />
                                        </TotalRecordPayment>
                                      </Table.Summary.Cell>
                                    );
                                  case 'newSalaryPremium':
                                    return (
                                      <Table.Summary.Cell index={4} colSpan={1}>
                                        <TotalRecordPayment>
                                          <MaskedValueRenderer
                                            value={
                                              sumArrayRecordYear(
                                                data_record_payment_year,
                                                'newSalaryPremium',
                                                i?.key,
                                              ) || ''
                                            }
                                          />
                                        </TotalRecordPayment>
                                      </Table.Summary.Cell>
                                    );
                                }
                              });
                            })}
                          </Table.Summary.Row>
                        </Table.Summary>
                      );
                    }}
                    isRecordYear={true}
                    tabSelection={labelKeyNameView.RECORD_PAYMENT_VIEW_YEAR}
                    // isShowTotalFooter={true}
                  />
                </>
              )}
            </TabPane>
          </>
        )}
      </Tabs>
      <ModalAutoReject
        isVisible={isAutoReject}
        onClose={handleCloseAutoReject}
        reset={() => reSetData(tab)}
        token={tokenReject}
      />
    </Container>
  );
};

export default HrStaffComplusory;
export const Container = styled.div`
  width: 100%;
  margin-top: 10px;
  @media screen and (max-width: 1440px) {
    width: 100%;
  }
`;
export const InfoTextCenter = styled.div`
  display: flex;
  justify-content: center;
`;
export const InfoButton = styled(Button)`
  height: 24px;
  border-radius: 6px;
  font-size: 12px;
  padding-top: 2px;
`;
export const ViewNotData = styled.div`
  width: 100%;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const TotalRecordPayment = styled.div`
  display: flex;
  justify-content: center;
  font-weight: bold;
`;

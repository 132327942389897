import React, { useState } from 'react';
import { Modal, Form } from 'antd';
import { useTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';
import { useAppSelector } from 'app/hooks';
import { selectUser } from 'features/auth/authSlice';
import { approveEmployee } from 'features/employee/employeeAction';

import validateReject from 'features/employee/utils/validateReject';

import CustomFormItem from 'styles/formStyled';
import CustomInputTextArea from 'styles/inputTextAreaStyled';
import CustomButton from 'styles/buttonStyled';

const RejectFormModal = (props: any) => {
  const { isVisible, itemId, onClose, paramSortFilter } = props;
  const { t } = useTranslation(['validation', 'employee']);
  const dispatch = useDispatch();
  const userLogin = useAppSelector(selectUser);

  const [messageReject, setMessageReject] = useState('');

  const handleChangeInput = (event: any) => {
    const value = event.target.value;
    setMessageReject(value);
  };

  const handleReject = () => {
    const lstMessageValidate = validateReject({ messageReject });
    if (lstMessageValidate.length > 0) {
      Modal.error({
        title: t('validation:title_error'),
        content: (
          <div dangerouslySetInnerHTML={{ __html: `${lstMessageValidate.join('<br/>')}` }} />
        ),
      });
    } else {
      dispatch(
        approveEmployee({
          dataRequest: {
            idProfile: itemId,
            idUserCreate: userLogin.id,
            messageRequest: messageReject,
            isApproved: false,
          },
          paramSortFilter,
        }),
      );
      handleClose();
    }
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal
      visible={isVisible}
      maskClosable={false}
      onCancel={handleClose}
      style={{ top: 20 }}
      title={t('employee:employee_reject.title')}
      footer={[
        <CustomButton aType="primary" key="reject-form-reject" onClick={handleReject}>
          {t('employee:employee_reject.btn_reject')}
        </CustomButton>,
        <CustomButton aType="primary" key="cancel-form-reject" onClick={handleClose}>
          {t('employee:employee_reject.btn_cancel')}
        </CustomButton>,
      ]}
    >
      <Form layout="vertical">
        <CustomFormItem required label={t('employee:employee_reject.reason')}>
          <CustomInputTextArea
            placeholder={t('employee:employee_reject.reason_placeholder')}
            name="reason"
            value={messageReject}
            onChange={handleChangeInput}
            autoSize={{
              minRows: 4,
              maxRows: 6,
            }}
          />
        </CustomFormItem>
      </Form>
    </Modal>
  );
};

export default RejectFormModal;

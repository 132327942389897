import queryString from 'query-string';
import axiosClient from './axiosClient';

const payrollDashboardApi = {
  getLocation: () => {
    const response = axiosClient.get(`/locations`);
    return response;
  },
  getDepartment: () => {
    const response = axiosClient.get(`/departments`);
    return response;
  },
  getProject: () => {
    const response = axiosClient.get(`/project`);
    return response;
  },
  getTotalPayroll: (params: any) => {
    const response = axiosClient.get(`/dashboard/total-payroll?${queryString.stringify(params)}`);
    return response;
  },
  getTotalHeadcount: (params: any) => {
    const response = axiosClient.get(`/dashboard/total-headcount?${queryString.stringify(params)}`);
    return response;
  },
  getPayrollSummary: (params: any) => {
    const response = axiosClient.get(`/dashboard/payroll-summary?${queryString.stringify(params)}`);
    return response;
  },
  getPayrollDesignation: (params: any) => {
    const response = axiosClient.get(
      `/payroll/payroll-designation?${queryString.stringify(params)}`,
    );
    return response;
  },
  getPayrollInDetail: (params: any) => {
    const response = axiosClient.get(`/payroll/payroll-in-detail?${queryString.stringify(params)}`);
    return response;
  },
  getPayrollDepartment: (params: any) => {
    const response = axiosClient.get(
      `/payroll/payroll-department?${queryString.stringify(params)}`,
    );
    return response;
  },
  getPayrollLine: (params: any) => {
    const response = axiosClient.get(`/payroll/payroll-line?${queryString.stringify(params)}`);
    return response;
  },
  getHeadcountSummaryByEmployeeType: (params: any) => {
    const response = axiosClient.get(
      `/dashboard/headcount-summary?${queryString.stringify(params)}`,
    );
    return response;
  },
  getPayrollSummaryByEmployeeType: (params: any) => {
    const response = axiosClient.get(`/dashboard/payroll-summary?${queryString.stringify(params)}`);
    return response;
  },
  getPayrollSummaryByYear: (params: any) => {
    const response = axiosClient.get(`/payroll/payroll-summary?${queryString.stringify(params)}`);
    return response;
  },
  getPayrollHeadSummary: (params: any) => {
    const response = axiosClient.get(`/dashboard/head-summary?${queryString.stringify(params)}`);
    return response;
  },
  getDepartmentGroupByTeam: () => {
    const response = axiosClient.get(`/dashboard/dashboard-group-report?groupBy=team`);
    return response;
  },
};

export default payrollDashboardApi;

import { SearchOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import qs from 'query-string';
import { createRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { selectFormatDate } from 'app/commonRedux/appSlice';
import { useAppDispatch, useAppSelector, useQuery } from 'app/hooks';
import { selectPermissions } from 'features/auth/authSlice';
import { getCategoryWithType } from 'features/configuration/categoryAction';
import {
  downloadImportFile,
  exportEmployees,
  filterSortEmployeeList,
  getEmployeeDetail,
  getEmployeeListApproved,
  getManagerList,
} from 'features/employee/employeeAction';
import {
  selectEmployee,
  selectEmployeeFilterList,
  selectEmployeeList,
  selectEmployeeTotalResult,
  selectEmployeeTotalSearch,
  selectIsGettingEmployee,
  setEmployeeFilterList,
} from 'features/employee/employeeSlice';
import { getRoleList } from 'features/role/roleSlice';

import { PROFILE_STATUSES, PROFILE_STATUS_COLORS } from 'constants/profiles';
import { formatDate } from 'utils/date';

import ITVTable from 'components/ITVTable';
import TableResizableTitle from 'components/TableResizableTitle';
import EmployeeColumn from 'features/employee/components/EmployeeColumn';
import Functions from 'features/employee/components/Functions';
import Header from 'features/employee/components/HeaderEmployee';
import SearchPopup from 'features/employee/components/SearchPopup';
import StatusColumnCell from 'features/employee/components/StatusColumnCell';
import EmployeeAddModal from 'features/employee/pages/EmployeeAddModal';

import { DATE_PAYLOAD_FORMAT } from 'constants/commons';
import { selectConfiguration } from 'features/configuration/configurationSlice';
import { getProjectList } from 'features/overtime/overtimeAction';
import { selectOvertimeData } from 'features/overtime/overtimeSlice';
import { DEFAULT_CURRENT_PAGE, DEFAULT_SIZE_PAGE } from 'hrm-common/extensions/constant/personel';
import { UserStatus } from 'hrm-common/extensions/enums/personel';
import { IEmployee, IEmployeeDetail } from 'hrm-common/extensions/interfaces/personel';
import { ContainerBgWhite } from 'styles/containerBgWhite';
import { TitleColumnCenter } from 'styles/tableStyled';
import EmployeeUpdateModal from '../EmployeeUpdateModal';

const filterDefault = {
  sortBy: 'status',
  orderBy: 'asc',
};

const viewType = {
  ALL: 'all',
  ONBOARD: 'on',
  OFFBOARD: 'off',
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export function getColumnSearchProps( //filter for column
  { options, dataIndex }: any,
  onClickFilter?: (selectedKeys: any, dataIndex: any, paramSortFilter: any) => void | undefined,
  overrideProps?: any,
  filteredInfo?: any,
) {
  const colRef = createRef();
  return {
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
      <SearchPopup
        ref={colRef}
        selectedKeys={selectedKeys}
        dataIndex={dataIndex}
        onPressEnter={() => confirm()}
        confirm={confirm} //use this function to hide filter dropdown when confirm filter
        options={options}
        setSelectedKeys={setSelectedKeys}
        onClickFilter={onClickFilter}
        filteredInfo={filteredInfo}
      />
    ),
    filterIcon: (filtered: any) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    ...overrideProps,
    onFilterDropdownVisibleChange: (visible: any) => {
      //@ts-ignore
      colRef.current && colRef.current.effectWhenOpenOrHideFilterDropdown(visible);
      overrideProps &&
        overrideProps.onFilterDropdownVisibleChange &&
        overrideProps.onFilterDropdownVisibleChange(visible);
    },
  };
}

const EmployeeLists = ({
  viewMode = false,
  handleSubmit,
  submitText,
  selectionType = 'checkbox',
}: any) => {
  const query = useQuery();
  const { t } = useTranslation(['modal', 'employee', 'onboard', 'timesheet', 'overtime']);
  const dispatch = useAppDispatch();
  const dataFormatDate = useAppSelector(selectFormatDate);
  const permissions = useAppSelector(selectPermissions);
  const employee: IEmployee = useAppSelector(selectEmployee);
  const employeeList: IEmployeeDetail[] = useAppSelector(selectEmployeeList);
  const employeeFilterList = useAppSelector(selectEmployeeFilterList);
  const loading = useAppSelector(selectIsGettingEmployee);
  const employeeTotalResult = useAppSelector(selectEmployeeTotalResult);
  const employeeTotalSearch = useAppSelector(selectEmployeeTotalSearch);
  const [sizePage, setSizePage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [widthTable, setWidthTable] = useState(978);
  const stateSelectEmployee: any = useAppSelector(selectEmployee);
  const [selectedUser, setSelectedUser] = useState<string[]>([]);
  const [isVisibleEmployeeAdd, setIsVisibleEmployeeAdd] = useState(false);
  const [txt, setTxt] = useState('');
  const isCheckAll: boolean = useAppSelector(selectEmployee).isCheckAll;
  const selectedRowKey = useAppSelector(selectEmployee).selectRowKey;
  const positions = useAppSelector(selectConfiguration).dataPosition;
  const listProject = useAppSelector(selectOvertimeData).listProject;

  const [isVisibleEmployeeUpdate, setIsVisibleEmployeeUpdate] = useState(false);

  const defaultFilter = {
    projectPrimaryName: [],
    positionName: [],
    status: ['approved'],
  };
  const [filteredInfo, setFilteredInfo]: any = useState(defaultFilter);
  const defaultSorter = [
    {
      columnKey: 'employeeId',
      order: 'descend',
    },
  ];
  const [sortedInfo, setSortedInfo]: any = useState(defaultSorter);

  const location = useLocation();
  const employeeParams = qs.parse(location.search);

  const searchFilterSortParam = {
    sort: [{ employeeId: 'DESC' }],
    search: { name: '' },
    filter: {
      projectPrimaryName: [],
      positionName: [],
      status: ['on', 'off'].includes(employeeParams.viewType as string)
        ? ['approved', 'deactive']
        : ['approved'],
    },
    limit: sizePage,
    offset: 1,
    fromDate:
      (employeeParams.fromDate as string) ||
      moment(new Date()).subtract(30, 'day').format(DATE_PAYLOAD_FORMAT),
    toDate: (employeeParams.toDate as string) || moment(new Date()).format(DATE_PAYLOAD_FORMAT),
    viewType: (employeeParams.viewType as string) || viewType.ALL,
    isAdmin: true,
  };
  const [paramSortFilter, setParamSortFilter] = useState(searchFilterSortParam);
  const [enableClear, setEnableClear] = useState(true);

  const clearSortAndFilters = () => {
    setSortedInfo(defaultSorter);
    setFilteredInfo(defaultFilter);
    setCurrentPage(1);
    const paramIfSearchText = {
      ...searchFilterSortParam,
      search: { name: txt },
      limit: sizePage,
      offset: 1,
    };
    setParamSortFilter(paramIfSearchText);
    // dispatch(filterSortEmployeeList(paramIfSearchText));
  };

  useEffect(() => {
    if (Boolean(query.get('showModel'))) {
      dispatch(getEmployeeDetail({ idItem: query.get('userId') }));
      handleOpenEmployeeUpdate();
    }
  }, [query, dispatch]);

  useEffect(() => {
    if (isCheckAll) {
      const postParamFilterSort = {
        ...paramSortFilter,
        search: { name: txt },
        limit: sizePage,
        offset: currentPage,
      };
      //@ts-ignore
      delete postParamFilterSort.limit;
      //@ts-ignore
      delete postParamFilterSort.offset;
      dispatch(filterSortEmployeeList(postParamFilterSort));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCheckAll]);

  useEffect(() => {
    setSelectedUser(selectedRowKey);
  }, [selectedRowKey]);

  useEffect(() => {
    if (!isEqual(searchFilterSortParam, paramSortFilter)) {
      const postParamFilterSort = {
        ...paramSortFilter,
        search: { name: txt },
        limit: sizePage,
        offset: currentPage,
      };
      dispatch(filterSortEmployeeList(postParamFilterSort));
    } else {
      dispatch(
        filterSortEmployeeList({
          ...paramSortFilter,
          limit: sizePage,
          offset: currentPage,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramSortFilter, sizePage]);

  useEffect(() => {
    setParamSortFilter({
      ...paramSortFilter,
      limit: sizePage,
      offset: currentPage,
      fromDate: searchFilterSortParam.fromDate,
      toDate: searchFilterSortParam.toDate,
      viewType: searchFilterSortParam.viewType,
      filter: {
        ...paramSortFilter.filter,
        status: ['on', 'off'].includes(searchFilterSortParam.viewType)
          ? ['approved', 'deactive']
          : ['approved'],
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    sizePage,
    currentPage,
    searchFilterSortParam.fromDate,
    searchFilterSortParam.toDate,
    searchFilterSortParam.viewType,
  ]);

  useEffect(() => {
    // Get dataset department
    dispatch(getProjectList());
    dispatch(getManagerList());

    // Get dataset role
    dispatch(
      getRoleList({
        filters: filterDefault,
      }),
    );
    // Get dataset manager
    dispatch(getEmployeeListApproved());
    // Get dataset onboard status
    dispatch(
      getCategoryWithType({
        typeConfig: 'onboard_status',
      }),
    );
    // Get dataset position
    dispatch(
      getCategoryWithType({
        typeConfig: 'position',
      }),
    );
    // Get dataset level
    dispatch(
      getCategoryWithType({
        typeConfig: 'level',
      }),
    );
    // Get dataset contract type
    dispatch(
      getCategoryWithType({
        typeConfig: 'contract_type',
      }),
    );
    // Get dataset gender
    dispatch(
      getCategoryWithType({
        typeConfig: 'gender',
      }),
    );
    // Get dataset marital status
    dispatch(
      getCategoryWithType({
        typeConfig: 'marital_status',
      }),
    );
    // Get dataset religion
    dispatch(
      getCategoryWithType({
        typeConfig: 'religion',
      }),
    );
    // Get dataset ethnicity
    dispatch(
      getCategoryWithType({
        typeConfig: 'ethnicity',
      }),
    );
    // Get dataset relationship
    dispatch(
      getCategoryWithType({
        typeConfig: 'relationship',
      }),
    );
    // Get dataset degree
    dispatch(
      getCategoryWithType({
        typeConfig: 'degree',
      }),
    );

    return () => {
      setSizePage(DEFAULT_SIZE_PAGE);
      setCurrentPage(DEFAULT_CURRENT_PAGE);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (employee) {
      const positionOptions = positions?.map((item: any) => item.name);
      const lineOptions = listProject?.map((item: any) => item.name);

      const temp: any = colListEmployee.map((item: any) => {
        const dataIndex = item.dataIndex;
        switch (dataIndex) {
          case 'employeeId':
            return {
              ...item,
              sortOrder:
                sortedInfo?.find((item: any) => item.columnKey === 'employeeId')?.order || null,
            };
          case 'fullName':
            return {
              ...item,
              sortOrder:
                sortedInfo?.find((item: any) => item.columnKey === 'fullName')?.order || null,
            };
          case 'projectPrimaryName':
            return {
              ...item,
              ...getColumnSearchProps(
                { options: lineOptions, dataIndex },
                confirmFilter,
                _,
                filteredInfo,
              ),
              filteredValue: filteredInfo.projectPrimaryName || null,
              sortOrder:
                sortedInfo?.find((item: any) => item.columnKey === 'projectPrimaryName')?.order ||
                null,
            };
          case 'positionName':
            return {
              ...item,
              ...getColumnSearchProps(
                { options: positionOptions, dataIndex },
                confirmFilter,
                _,
                filteredInfo,
              ),
              filteredValue: filteredInfo.positionName || null,
              sortOrder:
                sortedInfo?.find((item: any) => item.columnKey === 'positionName')?.order || null,
            };
          case 'status':
            return {
              ...item,
              ...getColumnSearchProps(
                { options: Object.values(UserStatus), dataIndex },
                confirmFilter,
                _,
                filteredInfo,
              ),
              filteredValue: filteredInfo.status || null,
              sortOrder:
                sortedInfo?.find((item: any) => item.columnKey === 'status')?.order || null,
            };
          default:
            break;
        }
        if (item.key === 'action') {
          return {
            ...item,
            render: (status: any, record: any) => (
              <StatusColumnCell
                t={t}
                isShowFullMenu={true}
                item={record}
                permissions={permissions}
                paramSortFilter={paramSortFilter}
              />
            ),
          };
        }
        return item;
      });
      if (
        JSON.stringify(paramSortFilter.sort) !== JSON.stringify(searchFilterSortParam.sort) ||
        !isEqual(paramSortFilter.filter, searchFilterSortParam.filter)
      ) {
        setEnableClear(false);
      } else {
        setEnableClear(true);
      }
      setColListEmployee(temp);
      setWidthTable(978);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeList, paramSortFilter]);

  const COLUMNS: any = [
    {
      title: () => {
        return (
          <TitleColumnCenter>{t('employee:employee_information_fields.id')}</TitleColumnCenter>
        );
      },
      dataIndex: 'employeeId',
      key: 'employeeId',
      ellipsis: true,
      width: 60,
      minWidth: 60,
      align: 'center',
      fixed: 'left',
      sorter: {
        compare: (a: any, b: any) => {
          //just to apply sort multi column
        },
        multiple: 3, //priority to sort column same number same priority
      },
      defaultSortOrder: 'descend',
    },
    {
      title: () => {
        return (
          <TitleColumnCenter>
            {t('employee:employee_information_fields.employee')}
          </TitleColumnCenter>
        );
      },
      dataIndex: 'fullName',
      key: 'fullName',
      width: 220,
      minWidth: 220,
      ellipsis: true,
      fixed: 'left',
      sorter: {
        compare: (a: any, b: any) => {},
        multiple: 3,
      },
      render: function (text: any, record: any, index: any) {
        return (
          <EmployeeColumn
            avatar={record?.fullName}
            fullName={record?.fullName}
            email={record?.email}
          />
        );
      },
    },
    {
      title: () => {
        return <TitleColumnCenter>{t('overtime:project_name')}</TitleColumnCenter>;
      },
      dataIndex: 'projectPrimaryName',
      key: 'projectPrimaryName',
      width: 150,
      minWidth: 150,
      sorter: {
        compare: (a: any, b: any) => {},
        multiple: 3,
      },
    },
    {
      title: () => {
        return (
          <TitleColumnCenter>
            {t('employee:employee_information_fields.position')}
          </TitleColumnCenter>
        );
      },
      dataIndex: 'positionName',
      key: 'positionName',
      width: 200,
      minWidth: 200,
      ellipsis: true,
      sorter: {
        compare: (a: any, b: any) => {},
        multiple: 3,
      },
    },
    {
      title: () => {
        return (
          <TitleColumnCenter>
            {t('employee:employee_information_fields.record_status')}
          </TitleColumnCenter>
        );
      },
      key: 'status',
      dataIndex: 'status',
      width: 130,
      minWidth: 130,
      ellipsis: true,
      sorter: {
        compare: (a: any, b: any) => {},
        multiple: 3,
      },
      defaultFilteredValue: ['approved'],
      render: function (value: any, record: any, index: any) {
        return <StatusColumn status={value}>• {PROFILE_STATUSES[value]}</StatusColumn>;
      },
    },
  ];

  if (!viewMode)
    COLUMNS.push({
      title: () => {
        return (
          <TitleColumnCenter>{t('employee:employee_information_fields.action')}</TitleColumnCenter>
        );
      },
      dataIndex: 'action',
      key: 'action',
      width: 60,
      minWidth: 60,
      align: 'center',
      fixed: 'right',
      render: (status: any, record: any) => (
        <StatusColumnCell t={t} isShowFullMenu={false} item={record} permissions={permissions} />
      ),
    });

  const [colListEmployee, setColListEmployee] = useState(COLUMNS);

  useEffect(() => {
    switch (employeeParams.viewType) {
      case viewType.ONBOARD: {
        addColumns([
          {
            label: t('employee:employee_information_fields.employment_start_date'),
            value: 'startDate',
          },
        ]);
        break;
      }
      case viewType.OFFBOARD: {
        addColumns([
          {
            label: t('employee:employee_information_fields.employment_end_date'),
            value: 'endDate',
          },
        ]);
        break;
      }
      default: {
        addColumns([]);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeParams.viewType]);

  const confirmFilter = (selectedKeys: any, dataIndex: any) => {
    const paramObj = { ...paramSortFilter };
    switch (dataIndex) {
      case 'projectPrimaryName':
        paramObj.filter.projectPrimaryName = selectedKeys;
        setParamSortFilter(paramObj);
        break;
      case 'positionName':
        paramObj.filter.positionName = selectedKeys;
        setParamSortFilter(paramObj);
        break;
      case 'status':
        paramObj.filter.status = selectedKeys;
        setParamSortFilter(paramObj);
        break;
      default:
        break;
    }
    const postParamFilterSort = {
      ...paramSortFilter,
      offset: currentPage,
      limit: sizePage,
    };
    setParamSortFilter(postParamFilterSort);
  };

  const handleSearchIdName = (keySearch: string) => {
    setTxt(keySearch);
    const paramFilterSortWithSearchTxt = {
      ...paramSortFilter,
      search: { name: keySearch },
      offset: 1,
      limit: sizePage,
    };
    debounce(() => {
      setParamSortFilter(paramFilterSortWithSearchTxt);
    }, 500)();
  };

  const onSearchList = (text: string) => {
    setCurrentPage(DEFAULT_CURRENT_PAGE);
    if (text) {
      handleSearchIdName(text);
    } else {
      setTxt('');
      const paramFilterSortWithSearchTxt = {
        ...paramSortFilter,
        search: { name: '' },
        offset: 1,
        limit: sizePage,
      };
      setParamSortFilter(paramFilterSortWithSearchTxt);
      dispatch(setEmployeeFilterList([]));
    }
  };

  const addColumns = (cols: any) => {
    const dataIndexs = COLUMNS.map((item: any) => item.dataIndex);
    const arr = colListEmployee.filter((item: any) => dataIndexs.includes(item.dataIndex));

    const temp = cols.map((item: any) => {
      if (item.value === 'startDate') {
        return {
          title: () => {
            return <TitleColumnCenter>{item.label}</TitleColumnCenter>;
          },
          dataIndex: item.value,
          key: item.value,
          ellipsis: true,
          width: 200,
          minWidth: 200,
          render: (startDate: any) => {
            return <div>{startDate ? formatDate(startDate, null, true) : ''}</div>;
          },
        };
      }
      if (item.value === 'endDate') {
        return {
          title: () => {
            return <TitleColumnCenter>{item.label}</TitleColumnCenter>;
          },
          dataIndex: item.value,
          key: item.value,
          ellipsis: true,
          width: 200,
          minWidth: 200,
          render: (endDate: any) => {
            return <div>{endDate ? formatDate(endDate, null, true) : ''}</div>;
          },
        };
      }
      if (item.value === 'contractStartDate') {
        return {
          title: () => {
            return <TitleColumnCenter>{item.label}</TitleColumnCenter>;
          },
          dataIndex: item.value,
          key: item.value,
          ellipsis: true,
          width: 200,
          minWidth: 200,
          render: (contractStartDate: any) => {
            return <div>{contractStartDate ? formatDate(contractStartDate, null, true) : ''}</div>;
          },
        };
      }
      if (item.value === 'contractExpirationDate') {
        return {
          title: () => {
            return <TitleColumnCenter>{item.label}</TitleColumnCenter>;
          },
          dataIndex: item.value,
          key: item.value,
          ellipsis: true,
          width: 120,
          minWidth: 120,
          render: (contractExpirationDate: any) => {
            return (
              <div>
                {contractExpirationDate ? formatDate(contractExpirationDate, null, true) : ''}
              </div>
            );
          },
        };
      }
      if (item.value === 'dob') {
        return {
          title: () => {
            return <TitleColumnCenter>{item.label}</TitleColumnCenter>;
          },
          dataIndex: item.value,
          key: item.value,
          ellipsis: true,
          width: 120,
          minWidth: 120,
          render: (dob: any, record: any, index: any) => {
            return (
              <div>
                {dob || record?.dateOfBirth
                  ? formatDate(dob || record?.dateOfBirth, null, true)
                  : ''}
              </div>
            );
          },
        };
      }
      if (item.value === 'cidIssuedDate') {
        return {
          title: () => {
            return <TitleColumnCenter>{item.label}</TitleColumnCenter>;
          },
          dataIndex: item.value,
          key: item.value,
          ellipsis: true,
          width: 150,
          minWidth: 150,
          render: (cidIssuedDate: any) => {
            return <div>{cidIssuedDate ? formatDate(cidIssuedDate, null, true) : ''}</div>;
          },
        };
      }
      if (item.value === 'relativeValue') {
        return {
          title: () => {
            return <TitleColumnCenter>{item.label}</TitleColumnCenter>;
          },
          dataIndex: item.value,
          key: item.value,
          ellipsis: true,
          width: 200,
          minWidth: 200,
          render: (relativeValue: any) => {
            return <div>{relativeValue}</div>;
          },
        };
      }
      if (item.value === 'trainingPeriod') {
        return {
          title: () => {
            return <TitleColumnCenter>{item.label}</TitleColumnCenter>;
          },
          dataIndex: item.value,
          key: item.value,
          ellipsis: true,
          width: 200,
          minWidth: 200,
          render: (value: any, record: any) => {
            const trainingPeriodFrom = record.trainingPeriodFrom
              ? formatDate(record.trainingPeriodFrom, null, true)
              : '';
            const trainingPeriodTo = record.trainingPeriodTo
              ? formatDate(record.trainingPeriodTo, null, true)
              : '';
            const trainingPeriod =
              trainingPeriodFrom && trainingPeriodTo
                ? `${trainingPeriodFrom} - ${trainingPeriodTo}`
                : '';
            return <div>{trainingPeriod}</div>;
          },
        };
      }
      return {
        title: () => {
          return <TitleColumnCenter>{item.label}</TitleColumnCenter>;
        },
        dataIndex: item.value,
        key: item.value,
        width: 200,
        minWidth: 200,
        ellipsis: true,
      };
    });

    setColListEmployee([...arr.slice(0, arr.length - 1), ...temp, arr[arr.length - 1]]);
    if (cols.length > 0) {
      setWidthTable((width) => width + cols.length * 200);
    } else {
      setWidthTable(978);
    }
  };

  const onExport = () => {
    const curreentEmployeeList = employeeFilterList?.length > 0 ? employeeFilterList : employeeList;
    let queryObject: {
      search: { selectField: string[] };
      select: { key: string; value: string[] };
      formatDate: string;
    } = {
      search: {
        selectField: colListEmployee
          .map((item: any) => {
            if (item.key === 'positionName') return 'positions';
            if (item.key === 'projectPrimaryName') return 'projectPrimary';
            if (item.key === 'managerName') return 'managers';
            if (item.key === 'roleName') return 'roles';
            if (item.key === 'contractTypeValue') return 'contractType';
            if (item.key === 'sexValue') return 'sex';
            if (item.key === 'maritalStatusValue') return 'maritalStatus';
            if (item.key === 'religionValue') return 'religion';
            if (item.key === 'ethnicityValue') return 'ethnicity';
            if (item.key === 'degreeValue') return 'degree';
            if (item.key === 'onBoardStatusValue') return 'onBoardStatus';
            if (item.key === 'levelValue') return 'level';
            if (item.key === 'relativeValue') return 'relative';
            return item.key;
          })
          .filter((item: any) => item !== 'action'),
      },
      select: {
        key: 'employeeId',
        value: curreentEmployeeList.map((item: any) => item.employeeId),
      },
      formatDate: dataFormatDate,
    };

    if (stateSelectEmployee.selectRowKey?.length > 0) {
      const temp = stateSelectEmployee.selectRowKey;
      queryObject.select.value = temp;
      dispatch(exportEmployees({ query: queryObject }));
    } else {
      Modal.warning({
        title: t('modal:none_select'),
      });
    }
  };

  const onDownload = () => dispatch(downloadImportFile({}));

  const handleCloseModalEmployeeAdd = () => {
    setIsVisibleEmployeeAdd(false);
  };

  const handleOpenEmployeeUpdate = () => {
    setIsVisibleEmployeeUpdate(true);
  };

  const handleCloseEmployeeUpdate = () => {
    setIsVisibleEmployeeUpdate(false);
  };

  // Custom resize column
  const components = {
    header: {
      cell: TableResizableTitle,
    },
  };

  const handleResize =
    (index: any) =>
    (e: any, { size }: any) => {
      const nextColumns = [...columns];
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width,
      };

      setColListEmployee(nextColumns);
    };

  const columns = colListEmployee.map((col: any, index: any) => ({
    ...col,
    onHeaderCell: (column: any) => ({
      minWidth: column.minWidth,
      width: column.width,
      onResize: handleResize(index),
    }),
  }));
  //////////////////////////////////////////////

  const onChangeTableEmployee = (pagination: any, filters: any, sorter: any) => {
    //call api to sort here
    const param = { ...paramSortFilter };
    if (sorter.length) {
      const sortValue = sorter?.map((item: any) =>
        item.order
          ? {
              [item.columnKey]: item.order === 'ascend' ? 'ASC' : 'DESC',
            }
          : [],
      );
      const sortArr = sorter.map((item: any) => item);
      setSortedInfo(sortArr);
      param.sort = sortValue;
    } else {
      if (sorter.order) {
        const array: any = [
          {
            [sorter.columnKey]: sorter.order === 'ascend' ? 'ASC' : 'DESC',
          },
        ];
        param.sort = array;
      } else {
        param.sort = [];
      }
      setSortedInfo([sorter]);
    }
    setFilteredInfo(filters);

    const postParamFilterSort = {
      ...param,
      limit: sizePage,
    };
    setParamSortFilter(postParamFilterSort);
  };
  const handleFilter = (value: any) => {
    const newFilter = {
      ...paramSortFilter,
      filter: value,
    };
    setParamSortFilter(newFilter);
  };

  return (
    <Container>
      <Header
        total={employeeTotalResult}
        onAddNewButtonClick={() => setIsVisibleEmployeeAdd(true)}
        viewMode={viewMode}
      />
      <ContainerBgWhite>
        <Functions
          isShowFull={true}
          handleSearch={(value: any) => onSearchList(value)}
          onExportClick={onExport}
          addColumns={addColumns}
          viewMode={viewMode}
          handleSubmit={() => handleSubmit(selectedUser)}
          submitText={submitText}
          selectedUser={selectedUser}
          // clearFilters={clearFilters}
          // clearSort={clearSort}
          clearSortAndFilters={clearSortAndFilters}
          enableClear={enableClear}
          employeeFilters={{
            fromDate: paramSortFilter.fromDate,
            toDate: paramSortFilter.toDate,
            viewType: paramSortFilter.viewType,
            loading: loading,
          }}
          handleFilter={handleFilter}
          load
          onDownload={onDownload}
        />
        <ITVTable
          components={components}
          columns={columns}
          data={employeeList}
          width={widthTable}
          height={550}
          totalResult={employeeTotalSearch}
          setSelectedUser={setSelectedUser}
          selectionType={selectionType}
          isScroll={true}
          loading={loading}
          rowKey={['employeeId']}
          onChange={onChangeTableEmployee}
          setOffset={setCurrentPage}
          offset={currentPage}
          setLimit={setSizePage}
          limit={sizePage}
          scroll={{ y: 300 }}
        />
      </ContainerBgWhite>
      <EmployeeAddModal
        isVisibleEmployeeAdd={isVisibleEmployeeAdd}
        onClose={handleCloseModalEmployeeAdd}
        paramSortFilter={paramSortFilter}
      />
      <EmployeeUpdateModal
        isVisible={isVisibleEmployeeUpdate}
        isShowFullMenu={true}
        itemId={query.get('userId')}
        onClose={handleCloseEmployeeUpdate}
        paramSortFilter={paramSortFilter}
      />
    </Container>
  );
};

export default EmployeeLists;

interface StatusColumnProps {
  status: string;
}
export const StatusColumn = styled.div`
  color: ${(props: StatusColumnProps) => PROFILE_STATUS_COLORS[props.status]};
`;

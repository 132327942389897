import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactCodeInput from 'react-code-input';
import styled from 'styled-components';

const ITVPassCode = (props: any) => {
  const passcodeLength = props.passcode ? props.passcode.length : props.length;
  const { setResult } = props;
  const [value, setValue] = useState('');
  const [isValid, setValid] = useState(true);
  const isDisabled = props.isDisabled || false;

  const [widthCode, setWidthCode] = useState(window.innerWidth > 300 ? '36px' : '30px');
  const [heightCode, setHeightCode] = useState(window.innerWidth > 300 ? '40px' : '36px');

  useEffect(() => {
    const updateCodeDimensions = () => {
      const tmpWidthCode = window.innerWidth < 300 ? '30px' : '36px';
      const tmpHeightCode = window.innerWidth < 300 ? '36px' : '40px';
      setWidthCode(tmpWidthCode);
      setHeightCode(tmpHeightCode);
    };

    window.addEventListener('resize', updateCodeDimensions);

    return () => window.removeEventListener('resize', updateCodeDimensions);
  }, []);

  useEffect(() => {
    if (props.passcode) {
      if (value.length === passcodeLength && value !== props.passcode) {
        setValid(false);
      } else {
        setValid(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <ReactCodeInputStyled
      name="ITVPassCode"
      inputStyle={{
        fontFamily: 'monospace',
        borderRadius: '6px',
        border: '1px solid lightgrey',
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 10px 0px',
        margin: '4px',
        padding: 0,
        textAlign: 'center',
        width: widthCode,
        height: heightCode,
        fontSize: '28px',
        boxSizing: 'border-box',
        color: 'black',
        backgroundColor: 'white',
      }}
      inputStyleInvalid={{
        fontFamily: 'monospace',
        borderRadius: '6px',
        border: '1px solid rgb(238, 211, 215)',
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 10px 0px',
        margin: '4px',
        padding: 0,
        textAlign: 'center',
        width: widthCode,
        height: heightCode,
        fontSize: '28px',
        boxSizing: 'border-box',
        color: 'rgb(185, 74, 72)',
        backgroundColor: 'rgb(242, 222, 222)',
      }}
      type="number"
      fields={passcodeLength}
      value={value}
      disabled={isDisabled}
      isValid={isValid}
      inputMode="numeric"
      onChange={(e: string) => {
        if (e.length <= passcodeLength) setValue(e);
        if (e.length === passcodeLength) {
          if (props.passcode) {
            if (e === props.passcode) {
              setResult(true);
            } else {
              setResult(false);
            }
          } else {
            setResult(e);
          }
        }
      }}
    />
  );
};

ITVPassCode.propTypes = {
  passcode: PropTypes.string,
  length: PropTypes.number,
  setResult: PropTypes.func,
  isNumber: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

export default memo(ITVPassCode);

export const ReactCodeInputStyled = styled(ReactCodeInput)`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

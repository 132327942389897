import { Upload } from 'antd';
import CustomButton from 'styles/buttonStyled';
import { useState } from 'react';
import { DeleteFilled, InboxOutlined } from '@ant-design/icons';

export function SendPTIForm({ callBack }) {
  const [fileList, setFileList] = useState([]);

  const handleUpload = (info) => {
    const newFileList = info.fileList.slice(-1); // Chỉ giữ lại file cuối cùng được tải lên
    setFileList(newFileList);
  };

  const handleRemove = (file) => {
    setFileList([]);
  };

  const handleSend = () => {
    if (fileList.length > 0) {
      const file = fileList[0].originFileObj;
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = () => {
        const blob = new Blob([reader.result], { type: file.type });
        callBack(blob);
      };
    }
  };

  return (
    <>
      {fileList.length === 0 && (
        <Upload.Dragger
          fileList={fileList}
          onChange={handleUpload}
          onRemove={handleRemove}
          maxCount={1}
          showUploadList={false}
        >
          <div>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
          </div>
        </Upload.Dragger>
      )}
      {fileList.length > 0 && (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <a
            href={URL.createObjectURL(fileList[0]?.originFileObj)}
            target="_blank"
            rel="noopener noreferrer"
          >
            {fileList[0]?.name}
          </a>
          <DeleteFilled onClick={() => handleRemove(fileList[0])} />
        </div>
      )}
      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <CustomButton style={{ marginTop: 16 }} onClick={handleSend}>
          Send
        </CustomButton>
      </div>
    </>
  );
}

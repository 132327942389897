import React from 'react';
import { Handle, Position } from 'react-flow-renderer';

export default function StateNode({ data }) {
  return (
    <>
      <Handle type="source" position={Position.Left} id="4" />
      <Handle type="source" position={Position.Top} id="1" style={{ left: '16.8%' }} />
      <Handle type="source" position={Position.Top} id="2" style={{ left: '50.6%' }} />
      <Handle type="source" position={Position.Top} id="3" style={{ left: '82.4%' }} />
      <Handle type="source" position={Position.Right} id="8" />
      <div> {data.label} </div>
      <Handle type="source" position={Position.Bottom} id="5" style={{ left: '82.4%' }} />
      <Handle type="source" position={Position.Bottom} id="6" style={{ left: '50.6%' }} />
      <Handle type="source" position={Position.Bottom} id="7" style={{ left: '16.8%' }} />
    </>
  );
}

import { selectFormatDate } from 'app/commonRedux/appSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import ITVTable from 'components/ITVTable';
import StatusView from 'components/StatusView';
import { getHistoryProjectManageProjectDetail } from 'features/overtime/overtimeAction';
import { selectOvertimeData } from 'features/overtime/overtimeSlice';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const ViewProjectDetail = (props: any) => {
  const { record } = props;
  const dispatch = useAppDispatch();
  const [data, setData] = useState([]);
  const { t } = useTranslation(['validation', 'overtime', 'timesheet']);
  const [sizePage, setSizePage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const dateFormat = useAppSelector(selectFormatDate);
  const overtimeData = useAppSelector(selectOvertimeData);
  const dataHistoryProjectDetail = overtimeData?.dataHistoryProjectDetail;
  const loading = overtimeData?.loadingDataHistoryProjectDetail;

  useEffect(() => {
    if (record)
      dispatch(getHistoryProjectManageProjectDetail({ projectCode: record?.projectCode }));
  }, [record, dispatch]);

  useEffect(() => {
    if (dataHistoryProjectDetail) {
      setData(dataHistoryProjectDetail);
    }
  }, [dataHistoryProjectDetail]);

  const COLUMNS = [
    {
      title: t('overtime:project_code'),
      dataIndex: 'projectCode',
      key: 'projectCode',
      width: 100,
      minWidth: 100,
      align: 'left',
    },
    {
      title: t('overtime:project_name'),
      dataIndex: 'name',
      key: 'name',
      width: 100,
      minWidth: 100,
      align: 'left',
    },
    {
      title: t('timesheet:start_date'),
      dataIndex: 'startDate',
      key: 'startDate',
      width: 100,
      minWidth: 100,
      render: function (text: any, record: any, index: any) {
        return text ? moment(text).format(dateFormat) : null;
      },
    },
    {
      title: t('timesheet:End_date'),
      dataIndex: 'endDate',
      key: 'endDate',
      width: 100,
      minWidth: 100,
      render: function (text: any, record: any, index: any) {
        return text ? moment(text).format(dateFormat) : null;
      },
    },
    {
      title: t('overtime:description'),
      dataIndex: 'description',
      key: 'description',
      width: 100,
      minWidth: 100,
      align: 'left',
    },
    {
      title: t('timesheet:Status'),
      dataIndex: 'status',
      key: 'status',
      width: 120,
      minWidth: 120,
      align: 'left',
      render: function (text: any, record: any, index: any) {
        return <StatusView text={text} />;
      },
    },
    {
      title: 'Date modified',
      dataIndex: 'updatedAt',
      align: 'center',
      key: 'updatedAt',
      width: 100,
      minWidth: 100,
      render: function (text: any, record: any, index: any) {
        return text ? moment(text).format(dateFormat) : null;
      },
    },
  ];

  return (
    <>
      <ITVTable
        isRowSelect={false}
        loading={loading}
        columns={COLUMNS}
        height={300}
        data={data}
        setOffset={setCurrentPage}
        offset={currentPage}
        setLimit={setSizePage}
        limit={sizePage}
      />
    </>
  );
};

export default ViewProjectDetail;

export const SearchContainer = styled.div`
  width: 200px;
  margin-bottom: 20px;
`;

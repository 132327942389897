import styled from 'styled-components';
import HeadcountSummaryChart from './HeadcountSummaryChart';
import HeadcountSummaryFilter from './HeadcountSummaryFilter';
import { useEffect, useState } from 'react';
import moment from 'moment';
import payrollDashboardApi from 'api/payrollDashboardApi';
import { generateRandomColor } from 'constants/commons';

const StyledContainer = styled.div`
  padding: 16px 24px;
  background-color: var(--cl_white);
  border-radius: 12px;
  border: 0.5px solid var(--cl_gray200);
  height: 100%;
`;

const StyledHeaderTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto;
  line-height: 20px;
  color: #121926;
`;

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

interface IDepartments {
  code: string;
  name: string;
  count: number;
  color: string;
}
interface IHeadcountSummary {
  locationId: number;
  name: string;
  departments: Array<IDepartments>;
}

export function HeadcountSummary({ location, department, project }: any) {
  const [filterLocation, setFilterLocation] = useState(
    location && location[location?.length - 1]?.id,
  );
  const [filterDepartment, setFilterDepartment] = useState(
    department && department[department?.length - 1]?.id,
  );

  const [isLoading, setIsLoading] = useState(false);

  const [dataChart, setDataChart] = useState({
    labels: [],
    datasets: [],
  });
  const [filters, setFilters] = useState({
    monthYear: moment(new Date()).clone().subtract(1, 'months').format('YYYY-MM'),
    locationId: 0,
  });

  const formatDataAll = (data: Array<IHeadcountSummary>) => {
    const combinedLocation: IHeadcountSummary = {
      locationId: 0,
      name: 'All',
      departments: [],
    };

    data?.forEach((location) => {
      location.departments.forEach((department: any) => {
        const existingDept = combinedLocation?.departments?.find(
          (d: any) => d?.code === department?.code,
        );
        if (existingDept) {
          existingDept.count += department?.count;
        } else {
          combinedLocation?.departments?.push({
            code: department?.code,
            name: department?.name,
            count: department?.count,
            color: department?.color,
          });
        }
      });
    });

    const labels =
      combinedLocation?.departments?.length > 0
        ? combinedLocation?.departments?.map((i: any) => i?.name)
        : [];
    const datasets = [
      {
        data: combinedLocation?.departments?.length
          ? combinedLocation?.departments?.map((i: any) => i?.count)
          : [],
        backgroundColor: combinedLocation?.departments?.length
          ? combinedLocation?.departments?.map((i: any) => i?.color || generateRandomColor())
          : [],
        borderColor: ['rgb(246 250 253)'],
        borderWidth: 2,
      },
    ];
    return { labels, datasets };
  };

  const formatDataLocation = (data: Array<IHeadcountSummary>, locationId: number) => {
    if (locationId) {
      const labels =
        data?.[0]?.departments?.length > 0 ? data?.[0]?.departments?.map((i: any) => i?.name) : [];
      const datasets = [
        {
          data: data?.[0]?.departments?.length
            ? data?.[0]?.departments?.map((i: any) => i?.count)
            : [],
          backgroundColor: data?.[0]?.departments?.length
            ? data?.[0]?.departments?.map((i: any) => i?.color || generateRandomColor())
            : [],
          borderColor: ['rgb(246 250 253)'],
          borderWidth: 2,
        },
      ];
      return { labels, datasets };
    }
    return { labels: [], datasets: [] };
  };

  useEffect(() => {
    async function fetchReportEmployeeGrowth() {
      try {
        setIsLoading(true);
        const response = await payrollDashboardApi.getPayrollHeadSummary(filters);
        if (filters.locationId) {
          const { labels, datasets } = formatDataLocation(response.data, filters.locationId);
          setDataChart((prevState) => ({
            ...prevState,
            labels,
            datasets,
          }));
        } else {
          const { labels, datasets } = formatDataAll(response.data);
          setDataChart((prevState) => ({
            ...prevState,
            labels,
            datasets,
          }));
        }
      } catch (error) {
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    }

    fetchReportEmployeeGrowth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  // const handleSubmit = ({ year }: EmployeeGrowthPayload) => {
  //   const formattedYear = moment(year).format('YYYY');

  //   setFilters((prevFilters) => ({
  //     ...prevFilters,
  //     year: formattedYear,
  //   }));
  // };

  return (
    <StyledContainer>
      <StyledWrapper>
        <StyledHeaderTitle>Headcount Summary</StyledHeaderTitle>
        <HeadcountSummaryFilter
          initialValues={{
            locationId: filterLocation,
            monthYear: moment(new Date(filters.monthYear)),
          }}
          location={location}
          // department={department}
          onSubmit={(value) => {
            setFilters({ ...filters, ...value });
          }}
        />
      </StyledWrapper>
      <HeadcountSummaryChart loading={isLoading} dataChart={dataChart} />
    </StyledContainer>
  );
}

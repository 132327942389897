import { createAsyncThunk } from '@reduxjs/toolkit';
import { notification } from 'antd';
import axiosClient from 'api/axiosClient';
import { setGlobalLoading } from 'app/commonRedux/appSlice';
import queryString from 'query-string';

// export const getRecruitmentJob = createAsyncThunk(
//   '/recruitment/job',
//   async (params: any, thunkApi: any) => {
//     try {
//       const response = await axiosClient.get(`/recruitment/job?${queryString.stringify(params)}`);
//       return response;
//     } catch (error) {
//       return thunkApi.rejectWithValue(error);
//     }
//   },
// );

export const getRecruitmentJob = createAsyncThunk(
  '/recruitment/job',
  async (params: any, thunkApi: any) => {
    try {
      const response = await axiosClient.post(`/recruitment/job/search-job`, params);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const createJobForm = createAsyncThunk(
  '/recruitment/job/create',
  async (params: any, thunkApi: any) => {
    try {
      const { body, callBack } = params;
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.post(`/recruitment/job/create`, body);
      response && callBack && callBack();
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const createJobTemplate = createAsyncThunk(
  '/recruitment/job/create-by-template',
  async (params: any, thunkApi: any) => {
    try {
      const { body, callBack } = params;
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.post(`/recruitment/job`, body);
      response && callBack && callBack();
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const getJobHirringProcess = createAsyncThunk(
  '/recruitment/configuration/workflow-template',
  async (params: any, thunkApi: any) => {
    try {
      const response = await axiosClient.get(
        `/recruitment/configuration/workflow-template?${queryString.stringify(params)}`,
      );
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getJobTemplates = createAsyncThunk(
  'recruitment/configuration/job-template',
  async (params: any, thunkApi: any) => {
    try {
      const response = await axiosClient.get(
        `recruitment/configuration/job-template?${queryString.stringify(params)}`,
      );
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const updateJobStatus = createAsyncThunk(
  '/recruitment/job/status/:id',
  async (params: any, thunkApi: any) => {
    try {
      const { id, body, callBack } = params;
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.put(`/recruitment/job/status/${id}`, body);
      response && callBack && callBack();
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const listJobTypes = createAsyncThunk(
  '/recruitment/configuration/job-type',
  async (params: any, thunkApi: any) => {
    try {
      const response = await axiosClient.get(`/recruitment/configuration/job-type`);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getCandidateManagement = createAsyncThunk(
  '/recruitment/candidate/management',
  async (params: any, thunkApi: any) => {
    try {
      const response = await axiosClient.post(`/recruitment/candidate/management`, params);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getListJob = createAsyncThunk(
  '/recruitment/job/list',
  async (params: any, thunkApi: any) => {
    try {
      const response = await axiosClient.get(
        `/recruitment/job/list?${queryString.stringify(params)}`,
      );
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const createCandidate = createAsyncThunk(
  '/recruitment/candidate',
  async (params: any, thunkApi: any) => {
    try {
      const { body, callBack } = params;
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.post(`/recruitment/candidate`, body);
      response && callBack && callBack();
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const getDetailCandidate = createAsyncThunk(
  '/recruitment/candidate/detail/',
  async (params: any, thunkApi: any) => {
    try {
      const { id, ...rest } = params;
      const response = await axiosClient.get(
        `/recruitment/candidate/detail/${id}?${queryString.stringify(rest)}`,
      );
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getListJobAppliedEval = createAsyncThunk(
  '/recruitment/candidate/list-job-applied',
  async (params: any, thunkApi: any) => {
    try {
      const response = await axiosClient.get(
        `/recruitment/candidate/list-job-applied?${queryString.stringify(params)}`,
      );
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

// export const getRecruitmentJobInterviewer = createAsyncThunk(
//   '/recruitment/job/interviewer/list-job',
//   async (params: any, thunkApi: any) => {
//     try {
//       const response = await axiosClient.get(
//         `/recruitment/job/interviewer/list-job?${queryString.stringify(params)}`,
//       );
//       return response;
//     } catch (error) {
//       return thunkApi.rejectWithValue(error);
//     }
//   },
// );

export const getRecruitmentJobInterviewer = createAsyncThunk(
  '/recruitment/job/interviewer/search-job',
  async (params: any, thunkApi: any) => {
    try {
      const response = await axiosClient.post(`/recruitment/job/interviewer/search-job`, params);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const deleteRecruitmentJob = createAsyncThunk(
  'del/recruitment/job/',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.delete(`/recruitment/job/${params?.id}`);
      response && params?.callBack && params?.callBack();
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const deleteRecruitmentCandidate = createAsyncThunk(
  'del/recruitment/candidate/',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.delete(`recruitment/candidate/${params?.id}`);
      response && params?.callBack && params?.callBack();
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const updateCandidate = createAsyncThunk(
  'update/recruitment/candidate',
  async (params: any, thunkApi: any) => {
    try {
      const { id, body, callBack } = params;
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.put(`/recruitment/candidate/${id}`, body);
      response && callBack && callBack();
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const getCandidateManagementInterviewer = createAsyncThunk(
  '/recruitment/candidate/interviewer/list',
  async (params: any, thunkApi: any) => {
    try {
      const response = await axiosClient.get(
        `/recruitment/candidate/interviewer/list?${queryString.stringify(params)}`,
      );
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getDetailCandidateInJob = createAsyncThunk(
  '/recruitment/candidate/detail-cadidate-process/',
  async (params: any, thunkApi: any) => {
    try {
      const response = await axiosClient.get(
        `/recruitment/candidate/detail-cadidate-process/${params?.id}`,
      );
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getListUserApproverOffer = createAsyncThunk(
  '/recruitment/candidate/list-user-approve-offer',
  async (params: any, thunkApi: any) => {
    try {
      const response = await axiosClient.get(`/recruitment/candidate/list-user-approve-offer`);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const updateJob = createAsyncThunk(
  '/recruitment/job/:id',
  async (params: any, thunkApi: any) => {
    try {
      const { id, body, callBack } = params;
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.put(`/recruitment/job/${id}`, body);
      response && callBack && callBack();
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const updateJobCandidate = createAsyncThunk(
  '/recruitment/job/update-candidate',
  async (params: any, thunkApi: any) => {
    try {
      const { body, callBack } = params;
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.put(`/recruitment/job/update-candidate`, body);
      response && callBack && callBack();
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const updateDetailCandidateInJob = createAsyncThunk(
  '/recruitment/candidate/update-cadidate-process/:candidateProcessId',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.put(
        `/recruitment/candidate/update-candidate-process/${params?.id}`,
        params?.body,
      );
      response && params?.callBack && params?.callBack();
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const updateDetailCandidateEval = createAsyncThunk(
  '/recruitment/candidate/update-candidate-evaluation/candidate',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.put(
        `/recruitment/candidate/update-candidate-evaluation/candidate`,
        params?.body,
      );
      response && params?.callBack && params?.callBack();
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const uploadCV = createAsyncThunk(
  '/recruitment/candidate-file/upload',
  async (payload: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const { formData, callBack } = payload;
      const response = await axiosClient.post(`/recruitment/candidate-file/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      response && callBack && callBack(response);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const notifyInterviewer = createAsyncThunk(
  '/recruitment/candidate/notifi-interviewer/',
  async (params: any, thunkApi: any) => {
    try {
      const { id, body, callBack } = params;
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.post(
        `/recruitment/candidate/notifi-interviewer/${id}`,
        body,
      );
      notification.destroy();
      response && callBack && callBack();
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const sendMailOffer = createAsyncThunk(
  '/recruitment/candidate/send-mail-offer',
  async (params: any, thunkApi: any) => {
    try {
      const { body, callBack } = params;
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.post(`/recruitment/candidate/send-mail-offer`, body);
      response && callBack && callBack();
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);
export const sendMailApproverOffer = createAsyncThunk(
  '/recruitment/candidate/send-mail-approver-offer',
  async (params: any, thunkApi: any) => {
    try {
      const { body, callBack } = params;
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.post(
        `/recruitment/candidate/send-mail-approver-offer`,
        body,
      );
      response && callBack && callBack();
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const sendOfferApproverStatus = createAsyncThunk(
  '/recruitment/candidate/offer-approver-status/:id',
  async (params: any, thunkApi: any) => {
    try {
      const { id, body, callBack } = params;
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.post(
        `/recruitment/candidate/offer-approver-status/${id}`,
        body,
      );
      response && callBack && callBack();
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const updateCandidateNoteInterviewer = createAsyncThunk(
  'update/recruitment/candidate/interviewer',
  async (params: any, thunkApi: any) => {
    try {
      const { id, body, callBack } = params;
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.put(`/recruitment/candidate/interviewer/${id}`, body);
      response && callBack && callBack();
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const updateJobRequestStatus = createAsyncThunk(
  'recruitment/job/job-request-status',
  async (params: any, thunkApi: any) => {
    try {
      const { id, body, callBack } = params;
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.put(`recruitment/job/job-request-status/${id}`, body);
      response && callBack && callBack();
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

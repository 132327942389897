import Icon from '@ant-design/icons';
import { Checkbox, Input } from 'antd';
import IconSearch from 'assets/icons/ic_search_gray.svg';
import { ChangeEvent, forwardRef, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const IconSearchComponents = <Icon component={() => <IconImgStyled src={IconSearch} alt="" />} />;

const MedalAddColumn = (props: any, ref: any) => {
  const { handleVisibleAddColumn, addColumns, sendValueAddColumns } = props;
  const { t } = useTranslation(['insurance', 'overtime']);
  const colAppTable = [
    {
      label: t('overtime:project_name'),
      value: 'projectPrimaryName',
    },
    {
      label: t('Social_Insurance') + ' ' + t('contribute_of_employer'),
      value: 'socialRateEmployer',
    },
    {
      label: t('Social_Insurance') + ' ' + t('contribute_of_employee'),
      value: 'socialRateSelf',
    },
    {
      label: t('Unemployment_insurance') + ' ' + t('contribute_of_employer'),
      value: 'unemploymentRateEmployer',
    },
    {
      label: t('Unemployment_insurance') + ' ' + t('contribute_of_employee'),
      value: 'unemploymentRateSelf',
    },
    {
      label: t('Health_insurance') + ' ' + t('contribute_of_employer'),
      value: 'healthRateEmployer',
    },
    {
      label: t('Health_insurance') + ' ' + t('contribute_of_employee'),
      value: 'healthRateSelf',
    },
    {
      label: t('Union_Dues') + ' ' + t('contribute_of_employer'),
      value: 'unionDuesRateEmployer',
    },
    {
      label: t('Union_Dues') + ' ' + t('contribute_of_employee'),
      value: 'unionDuesRateSelf',
    },
  ];
  const [lstappItemCheck, setlstAppItemCheck] = useState<string[]>(() => {
    return colAppTable.map((item) => item.label);
  });
  const [checkedAppList, setCheckedAppList] = useState<string[]>([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);

  useImperativeHandle(ref, () => ({
    resetChecklist: () => {
      setIndeterminate(false);
      setCheckAll(false);
      setCheckedAppList([]);
    },
  }));

  const handleSearch = (keyword: string) => {
    const matchItems = colAppTable
      .filter((item) => item.label.toLowerCase().includes(keyword.toLowerCase()))
      .map((item) => item.label);
    setlstAppItemCheck(matchItems);
  };

  const handleSearchTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value.toLowerCase();
    if (text) {
      const matchAppItems = colAppTable
        .filter((item) => item.label.toLowerCase().includes(text.toLowerCase()))
        .map((item) => item.label);
      setlstAppItemCheck(matchAppItems);
    } else {
      setlstAppItemCheck(colAppTable.map((item) => item.label));
    }
  };

  const handleCheckChange = (list: any) => {
    setCheckedAppList(list);
    setIndeterminate(!!list.length && list.length < lstappItemCheck.length);
    setCheckAll(list.length === lstappItemCheck.length);
  };

  const handleCheckAllChange = (e: any) => {
    setCheckedAppList(e.target.checked ? lstappItemCheck : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const handleDone = () => {
    let lstCol: any = [];

    checkedAppList.forEach(
      (item) => (lstCol = [...lstCol, colAppTable.find((obj) => obj.label === item)]),
    );

    let lstColValue = colAppTable
      .filter((item: any) => checkedAppList?.some((i: any) => i === item.label))
      .map((item: any) => item.value);

    addColumns(lstCol);
    sendValueAddColumns(lstColValue);
    handleVisibleAddColumn();
  };
  return (
    <ContainerStyled>
      <TitleStyled>{t('sdd_columns')}</TitleStyled>
      <SearchStyled
        placeholder={t('Search')}
        prefix={IconSearchComponents}
        onSearch={handleSearch}
        onChange={handleSearchTextChange}
        enterButton
      />
      <CheckoxStyled
        indeterminate={indeterminate}
        onChange={handleCheckAllChange}
        checked={checkAll}
      >
        {t('select_all')}
      </CheckoxStyled>
      <CheckoxGroupStyled
        options={lstappItemCheck}
        value={checkedAppList}
        onChange={handleCheckChange}
      />
      <FooterStyled>
        <DoneButton onClick={handleDone}>{t('Yes')}</DoneButton>
        <CancelButton onClick={handleVisibleAddColumn}>{t('cancel')}</CancelButton>
      </FooterStyled>
    </ContainerStyled>
  );
};

export default forwardRef(MedalAddColumn);
export const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 20rem;
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: white;
  padding: 1rem;
  box-shadow: 0px 10px 18px rgba(9, 30, 66, 0.15), 0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 8px;
`;

export const FooterStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const TitleStyled = styled.div`
  font-size: var(--txt_font_size_h3);
  font-weight: var(--txt_font_weight_medium);
  padding-bottom: 10px;
`;

export const SearchStyled = styled(Input.Search)`
  .ant-input-group-addon {
    display: none;
  }
  .ant-input-affix-wrapper {
    height: 32px;
    input {
      font-size: 14px;
    }
  }
`;

export const IconImgStyled = styled.img`
  height: 15px;
  width: 15px;
  margin-right: 11px;
`;

export const CheckoxGroupStyled = styled(Checkbox.Group)`
  display: flex;
  flex-direction: column;
  height: 15rem;
  overflow-y: auto;
  margin-bottom: 0.5rem;

  &::-webkit-scrollbar-track {
    margin-top: -20px;
  }
`;

export const CheckoxStyled = styled(Checkbox)`
  font-size: var(--txt_font_size_body);
  color: var(--cl_gray900);
  margin-top: 10px;
`;

export const DoneButton = styled.div`
  text-align: center;
  padding: 4px 10px;
  border-radius: 3px;
  background-color: var(--cl_primary);
  color: white;
  font-weight: 700;
  cursor: context-menu;
  &:hover {
    background-color: var(--cl_primary_dark);
  }
`;

export const CancelButton = styled.div`
  text-align: center;
  padding: 4px 10px;
  border-radius: 3px;
  background-color: white;
  border: 1px var(--cl_gray300) solid;
  font-weight: 700;
  color: var(--cl_gray400);
  cursor: context-menu;
  &:hover {
    color: var(--cl_primary);
    border: 1px var(--cl_primary) solid;
  }
`;

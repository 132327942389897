import { useEffect, useState } from 'react';
import PageHeader from 'components/PageHeader';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useHistory, useLocation } from 'react-router-dom';
import { selectConfiguration } from 'features/configuration/configurationSlice';
import { selectTrainingData } from '../trainingSlice';
import ITVTable from 'components/ITVTable';
import { EyeOutlined } from '@ant-design/icons';
import MenuAction from 'components/MenuAction';
import ITVSearch from 'components/ITVSearch';
import { getTraineeList } from '../trainingAction';
import { useTranslation } from 'react-i18next';
import { TitleColumnCenter } from 'styles/tableStyled';

const TraineeManagement = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const history = useHistory();

  const trainData = useAppSelector(selectTrainingData);
  const traineeList = trainData?.traineeList;

  const { t } = useTranslation(['trainningManagement', 'timesheet']);
  const menuStore = useAppSelector(selectConfiguration);
  const dataScreenManage = menuStore?.dataScreenManage?.result;
  const [titleHeader, setTitleHeader] = useState('');
  const [sizePage, setSizePage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [searchName, setSearchName] = useState('');

  useEffect(() => {
    if (traineeList?.result) {
      setData(traineeList?.result);
    }
  }, [traineeList]);

  useEffect(() => {
    const temp = dataScreenManage
      ?.filter((item) => location.pathname === item.path)
      ?.map((item) => item.nameScreen);

    setTitleHeader(temp ? temp[0] : '');
  }, [dataScreenManage, location.pathname]);

  useEffect(() => {
    dispatch(
      getTraineeList({
        offset: currentPage,
        limit: sizePage,
        searchName,
      }),
    );
  }, [dispatch, currentPage, sizePage, searchName]);

  const menu = [
    {
      name: t('timesheet:View_Detail'),
      icon: <EyeOutlined style={{ color: '#1890FF' }} />,
      handleClick: (data: any) => {
        history.push({ pathname: `/training/trainee-management/${data?.userId}`, state: data });
      },
    },
  ];
  const columns = [
    {
      title: () => {
        return (
          <TitleColumnCenter>
            {t('trainningManagement:trainee_management.col_id')}
          </TitleColumnCenter>
        );
      },
      key: 'index',
      dataIndex: 'index',
      width: 80,
      minWidth: 80,
      align: 'center',
      render: (text: any, _: any, index: any) => {
        const trueIndex = index + sizePage * (currentPage - 1);
        return <>{trueIndex + 1}</>;
      },
    },
    {
      title: () => {
        return (
          <TitleColumnCenter>
            {t('trainningManagement:trainee_management.employee_name')}
          </TitleColumnCenter>
        );
      },
      key: 'fullName',
      dataIndex: 'fullName',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (text) => text,
    },
    {
      title: () => {
        return (
          <TitleColumnCenter>
            {t('trainningManagement:trainee_management.employee_id')}
          </TitleColumnCenter>
        );
      },
      key: 'employeeId',
      dataIndex: 'employeeId',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (text) => {
        return text;
      },
    },
    {
      title: () => {
        return (
          <TitleColumnCenter>
            {t('trainningManagement:trainee_management.job_title')}
          </TitleColumnCenter>
        );
      },
      key: 'jobTitle',
      dataIndex: 'jobTitle',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (text) => {
        return text;
      },
    },
    {
      title: () => {
        return (
          <TitleColumnCenter>{t('trainningManagement:trainee_management.store')}</TitleColumnCenter>
        );
      },
      key: 'budget',
      dataIndex: 'budget',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (text) => {
        return text;
      },
    },
    {
      title: 'Line',
      key: 'departmentName',
      dataIndex: 'departmentName',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (text) => {
        return text;
      },
    },
    {
      title: () => {
        return (
          <TitleColumnCenter>
            {t('trainningManagement:trainee_management.course_assigned')}
          </TitleColumnCenter>
        );
      },
      key: 'courseAssigned',
      dataIndex: 'courseAssigned',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (text) => {
        return text;
      },
    },
    {
      title: () => {
        return (
          <TitleColumnCenter>
            {t('trainningManagement:trainee_management.action')}
          </TitleColumnCenter>
        );
      },
      key: 'action',
      width: 80,
      minWidth: 80,
      align: 'center',
      fixed: 'right',
      render: (text, record) => {
        return <MenuAction menu={menu} data={record} />;
      },
    },
  ];

  return (
    <>
      <PageHeader title={titleHeader} />
      <ITVSearch style={{ marginBottom: '1rem' }} handleSearch={(value) => setSearchName(value)} />
      <ITVTable
        data={data}
        columns={columns}
        isRowSelect={false}
        setOffset={setCurrentPage}
        offset={currentPage}
        setLimit={setSizePage}
        limit={sizePage}
        totalResult={traineeList?.totalResult}
      />
    </>
  );
};

export default TraineeManagement;

import React, { useState } from 'react';
import { Col, Form } from 'antd';

import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isMobileOnly } from 'react-device-detect';
import { CountryPhoneInputValue } from 'antd-country-phone-input';

import { useDispatch } from 'react-redux';
import { loginWithPassword } from 'features/auth/loginAction';

import { REGEX_PHONE } from 'constants/regex';
import { setJson, setItem, getJson, getItem, removeItem } from 'utils/storage';

import EyeIcon from 'assets/icons/ic_eye_fill.svg';
import EyeSlashIcon from 'assets/icons/ic_eye_slash_fill.svg';

import AuthInputPhone from 'features/auth/components/AuthInputPhone';
import AuthInput from 'features/auth/components/AuthInput';
import {
  FormItemStyled,
  TitleStyled,
  RowStyled,
  ForgotPasswordStyled,
  CheckboxStyled,
  CustomButtonStyled,
} from 'features/auth/components/AuthSignIn/styled';
import { ModalStyled, ConatinerStyled, ContentStyled, FormStyled } from './styled';

const AuthSignInPhone = (props: any) => {
  const { isVisible, onClose } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation(['auth', 'modal', 'validation']);
  const [form] = Form.useForm();

  const accountStorage = getJson('account');
  const phoneStorage = accountStorage?.phone || '';
  const rememberStorage = getItem('remember');

  const [phoneData, setPhoneData] = useState<CountryPhoneInputValue>(
    phoneStorage
      ? phoneStorage
      : {
          code: 84,
          short: 'VN',
        },
  );
  const [password, setPassword] = useState('');
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [isRememberMe, setIsRememberMe] = useState(rememberStorage === 'yes' ? true : false);

  const [isValidPhone, setIsValidPhone] = useState(true);
  const [validateMessagePhone, setValidateMessagePhone] = useState('');
  const [isValidPassword, setIsValidPassword] = useState(true);
  const [validateMessagePassword, setValidateMessagePassword] = useState('');

  const resetData = () => {
    form.resetFields();
    setPhoneData(
      phoneStorage
        ? phoneStorage
        : {
            code: 84,
            short: 'VN',
          },
    );
    setPassword('');
    setVisiblePassword(false);
    setIsRememberMe(rememberStorage === 'yes' ? true : false);

    setIsValidPhone(true);
    setValidateMessagePhone('');
    setIsValidPassword(true);
    setValidateMessagePassword('');
  };

  const updateValidateStatus = () => {
    const phoneCode = phoneData?.code;
    const phoneNumber = phoneData?.phone;

    setIsValidPhone(true);
    setValidateMessagePhone('');
    setIsValidPassword(true);
    setValidateMessagePassword('');

    if (!phoneCode) {
      setIsValidPhone(false);
      setValidateMessagePhone(
        `${t('validation:required', { field: t('auth:sign_in.phone_code') })}`,
      );
      return false;
    } else if (!phoneNumber) {
      setIsValidPhone(false);
      setValidateMessagePhone(
        `${t('validation:required', { field: t('auth:sign_in.phone_number') })}`,
      );
      return false;
    } else {
      const regex = new RegExp(REGEX_PHONE);
      if (!regex.test(phoneNumber)) {
        setIsValidPhone(false);
        setValidateMessagePhone(
          `${t('validation:auth_invalid', {
            field: t('auth:sign_in.phone_number')?.toString()?.toLowerCase(),
          })}`,
        );
        return false;
      }
      if (phoneNumber.length < 7) {
        setIsValidPhone(false);
        setValidateMessagePhone(
          `${t('validation:min', {
            field: t('auth:sign_in.phone_number'),
            min: '7',
          })}`,
        );
        return false;
      }
      if (phoneNumber.length > 15) {
        setIsValidPhone(false);
        setValidateMessagePhone(
          `${t('validation:max', {
            field: t('auth:sign_in.phone_number'),
            max: '15',
          })}`,
        );
        return false;
      }
    }

    if (!password) {
      setIsValidPassword(false);
      setValidateMessagePassword(
        `${t('validation:required', { field: t('auth:sign_in.password_label') })}`,
      );
      return false;
    }

    return true;
  };

  const loginPassword = (
    isRememberMe: boolean,
    username: CountryPhoneInputValue,
    password: string,
  ) => {
    if (isRememberMe && username) {
      setJson('account', { email: '', phone: username });
    } else {
      removeItem('account');
    }
    setItem('remember', isRememberMe ? 'yes' : 'no');

    const phoneNumber = username?.phone || '';
    dispatch(
      loginWithPassword({
        phone: phoneNumber,
        password,
      }),
    );
  };

  const onChangePhoneNumber = (values: any) => {
    setPhoneData(values);
  };

  const onLoginButtonClick = (values: any) => {
    if (updateValidateStatus()) {
      loginPassword(isRememberMe, phoneData, password);
    }
  };

  const onToggleVisiblePassword = () => {
    setVisiblePassword((visible) => !visible);
  };

  const handleClose = () => {
    resetData();
    onClose();
  };

  return (
    <ModalStyled
      visible={isVisible}
      maskClosable={false}
      onCancel={handleClose}
      centered={true}
      footer={null}
    >
      <ConatinerStyled>
        <ContentStyled>
          {isMobileOnly ? (
            <TitleStyled className="top-up">{t('auth:sign_in.title_phone')}</TitleStyled>
          ) : (
            <TitleStyled>{t('auth:sign_in.title_phone')}</TitleStyled>
          )}
          <FormStyled id="LoginWithPhoneForm" form={form} onFinish={onLoginButtonClick}>
            <RowStyled>
              <Col md={24} xs={24}>
                <FormItemStyled
                  validateStatus={isValidPhone ? 'success' : 'error'}
                  help={validateMessagePhone}
                  initialValue={phoneData}
                >
                  <AuthInputPhone
                    label={t('auth:sign_in.phone_label')}
                    value={phoneData}
                    onChange={onChangePhoneNumber}
                    placeholder={t('auth:sign_in.placeholder_phone')}
                    isTopUp={true}
                  />
                </FormItemStyled>
              </Col>
            </RowStyled>
            <RowStyled>
              <Col md={24} xs={24}>
                <FormItemStyled
                  validateStatus={isValidPassword ? 'success' : 'error'}
                  help={validateMessagePassword}
                >
                  <AuthInput
                    label={t('auth:sign_in.password_label')}
                    value={password}
                    onChange={(e: any) => setPassword(e.target.value)}
                    placeholder={t('auth:sign_in.placeholder_passwrod')}
                    type={visiblePassword ? 'text' : 'password'}
                    isTopUp={true}
                  >
                    <div onClick={onToggleVisiblePassword} style={{ cursor: 'pointer' }}>
                      {visiblePassword ? (
                        <img src={EyeIcon} alt="hiden password" />
                      ) : (
                        <img src={EyeSlashIcon} alt="show password" />
                      )}
                    </div>
                  </AuthInput>
                </FormItemStyled>
              </Col>
            </RowStyled>
            <RowStyled className="line-up">
              <CheckboxStyled
                defaultChecked={isRememberMe}
                onChange={(e: any) => setIsRememberMe(e.target.checked)}
              >
                {t('auth:sign_in.rememeber_me')}
              </CheckboxStyled>
              <ForgotPasswordStyled
                onClick={() => {
                  history.push('/password-reset');
                }}
              >
                {t('auth:sign_in.forgot_password')}
              </ForgotPasswordStyled>
            </RowStyled>
          </FormStyled>
        </ContentStyled>

        <CustomButtonStyled
          form="LoginWithPhoneForm"
          key="login-with-phone"
          type="primary"
          htmlType="submit"
        >
          {t('auth:sign_in.login_button_label')}
        </CustomButtonStyled>
      </ConatinerStyled>
    </ModalStyled>
  );
};

export default AuthSignInPhone;

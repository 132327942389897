import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

export const useTimeSynchronizeSchema = () => {
  const { t } = useTranslation(['onsiteEmployee']);

  const schema = yup.object().shape({
    startDate: yup.date().nullable().required(t('time_synchronize_modal.require_start_date_rule')),
    endDate: yup.date().nullable().required(t('time_synchronize_modal.require_end_date_rule')),
  });

  return schema;
};

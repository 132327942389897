import { IconProps } from 'types';

export const BirthdayIcon = ({ width = '20px', height = '20px', className }: IconProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.66699 18.3334H18.3337"
      stroke="#121926"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.5918 18.3334V10.8334C2.5918 9.45004 3.83346 8.33337 5.3668 8.33337H14.6251C16.1585 8.33337 17.4001 9.45004 17.4001 10.8334V18.3334"
      stroke="#121926"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.63379 8.33329V5.97496C4.63379 4.97496 5.53379 4.16663 6.65046 4.16663H13.3588C14.4671 4.16663 15.3671 4.97496 15.3671 5.97496V8.33329"
      stroke="#121926"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.31738 11.0827C4.22816 11.0827 4.97893 11.8212 4.97893 12.7443V13.1258C4.97893 14.0366 5.71739 14.7874 6.64047 14.7874C7.55125 14.7874 8.30202 14.0489 8.30202 13.1258V12.7443C8.30202 11.8335 9.04048 11.0827 9.96356 11.0827C10.8743 11.0827 11.6251 11.8212 11.6251 12.7443V13.1258C11.6251 14.0366 12.3636 14.7874 13.2867 14.7874C14.1974 14.7874 14.9482 14.0489 14.9482 13.1258V12.7443C14.9482 11.8335 15.6867 11.0827 16.6097 11.0827"
      stroke="#121926"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.66699 4.16667V2.5"
      stroke="#121926"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.333 4.16667V2.5"
      stroke="#121926"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 4.16663V1.66663"
      stroke="#121926"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import React, { useEffect, useState } from 'react';
import { Card, Col, DatePicker, Input, Row, Select } from 'antd';
import { useController } from 'react-hook-form';
import styled from 'styled-components';
import TextArea from 'antd/es/input/TextArea';
import {
  DateRangePickerField,
  InputField,
  SelectField,
  TextAreaField,
} from 'components/FormFields';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { selectTrainingData } from 'features/training/trainingSlice';
import PageHeader from 'components/PageHeader';
import moment from 'moment';
import { YYYY_MM_DD } from 'constants/commons';
import { useParams } from 'react-router-dom';
import CustomButton from 'styles/buttonStyled';
import { confirmTraining } from 'features/training/trainingAction';
import { useTranslation } from 'react-i18next';

const StyledContainer = styled.div`
  .row {
    margin-bottom: 16px;
  }

  .label {
    text-align: left;
    padding-right: 8px;
    flex: 1 0 30px;
  }

  .input,
  .select,
  .textarea,
  .datepicker {
    flex: 2;
  }

  .select .ant-select {
    width: 100%;
  }

  .textarea .ant-input {
    width: 100%;
    min-height: 120px; /* Đặt chiều cao tối thiểu cho TextArea */
  }

  .datepicker .ant-picker {
    width: 100%;
  }

  .status .ant-select {
    width: 100%;
    min-height: 100px; /* Đặt chiều cao tối thiểu cho Select */
    display: flex;
    align-items: center; /* Căn giữa nội dung */
  }
`;

const CourseInformation = ({ getValues }) => {
  const { id } = useParams();
  const { t } = useTranslation(['trainningManagement', 'employee']);
  const dispatch = useAppDispatch();
  const confirm = () => {
    dispatch(confirmTraining({ courseIds: [id] }));
  };
  return (
    <StyledContainer>
      <Card
        title={t('employee:my_training.course_info')}
        bordered={false}
        extra={
          id && (
            <CustomButton onClick={confirm}>
              {t('employee:my_training.confirm_participation')}
            </CustomButton>
          )
        }
        headStyle={{
          fontSize: 24,
          fontWeight: 500,
        }}
      >
        <Row gutter={[100, 16]}>
          <Col span={16}>
            <Row className="row" align="middle">
              <Col className="label">{t('trainningManagement:course_management.course_name')}</Col>
              <Col className="input">{getValues && getValues('courseName')}</Col>
            </Row>
            <Row className="row" align="middle">
              <Col className="label">{t('employee:my_training.description')}</Col>
              <Col className="textarea">{getValues && getValues('description')}</Col>
            </Row>
            <Row className="row" align="middle">
              <Col className="label">{t('employee:my_training.course_cat')}</Col>
              <Col className="select">{getValues && getValues('categoryName')}</Col>
            </Row>
            <Row className="row" align="middle">
              <Col className="label">{t('employee:my_training.training_date')}</Col>
              <Col className="select">
                {getValues &&
                  `${
                    getValues('dateFrom') ? moment(getValues('dateFrom')).format(YYYY_MM_DD) : ''
                  } - ${getValues('dateTo') ? moment(getValues('dateTo')).format(YYYY_MM_DD) : ''}`}
              </Col>
            </Row>
            <Row className="row" align="middle">
              <Col className="label">{t('trainningManagement:course_management.trainer')}</Col>
              <Col className="select">{getValues && getValues('trainerName')}</Col>
            </Row>
            <Row className="row" align="middle">
              <Col className="label">{t('employee:my_training.status')}</Col>
              <Col className="input">{getValues && getValues('statusText')}</Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </StyledContainer>
  );
};

export default CourseInformation;

import { Col, Form, InputNumber, notification, Popconfirm, Row } from 'antd';
import { useAppSelector } from 'app/hooks';
import { actions, selectConfiguration } from 'features/configuration/configurationSlice';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import CustomButton from 'styles/buttonStyled';

enum MODE {
  VIEW = 'VIEW',
  ADD = 'ADD',
  UPDATE = 'UPDATE',
}

const ViewPIT = (props: any) => {
  const { current, onSave, mode } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation([
    'validation',
    'overtime',
    'timesheet',
    'employee',
    'action',
    'masterConfig',
    'insurance',
    'common',
    'payroll',
  ]);

  //@ts-ignore
  const [form] = Form.useForm();
  const dataStore = useAppSelector(selectConfiguration);
  const { setErrorMessage } = actions;
  const error = dataStore.error;

  useEffect(() => {
    if (error?.fieldErrors) {
      form.setFields([
        {
          name: 'minValue',
          errors: error?.fieldErrors?.minValue || error?.fieldErrors?.name,
        },
        {
          name: 'maxValue',
          errors: error?.fieldErrors?.maxValue || error?.fieldErrors?.name,
        },
        {
          name: 'taxRate',
          errors: error?.fieldErrors?.taxRate,
        },
      ]);
      notification.destroy();
    }
    return () => {
      dispatch(setErrorMessage(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, dispatch]);

  useEffect(() => {
    form.setFieldsValue({
      minValue: current?.minValue,
      maxValue: current?.maxValue,
      taxRate: current?.taxRate,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current]);

  const onFinish = () => {
    form.validateFields().then((values) => {
      if (mode === MODE.ADD) {
        onSave({ ...values, taxRate: values?.taxRate || 1, isProbationary: false });
      }
      if (mode === MODE.UPDATE) {
        onSave({ ...values, id: current?.id, isProbationary: false });
      }
    });
  };

  return (
    <Form form={form}>
      <Row gutter={[16, 16]}>
        <Col span={10} style={{ display: 'flex' }}>
          <p style={{ marginTop: '2%', display: 'flex' }}>
            {t('payroll:pit_per_month')}
            {/* <span style={{ color: 'red', paddingLeft: 5 }}> *</span> */}
          </p>
        </Col>
        <Col span={6}>
          <Form.Item
            name={'minValue'}
            // rules={[
            //   {
            //     validator: (rule: any, value: number, cb: (msg?: string) => void) => {
            //       form.getFieldValue('minValue') < form.getFieldValue('maxValue')
            //         ? cb('End date must after start date')
            //         : cb();
            //     },
            //   },
            // ]}
          >
            {/* {<CustomInputNumber defaultValue={0} style={{ width: '100%' }} min={0} />} */}
            <CustomInputNumber
              style={{ width: '100%' }}
              defaultValue={0}
              formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value: any) => (value ? value.replace(/\$\s?|(,*)/g, '') : 0)}
              min={0}
            />
          </Form.Item>
        </Col>
        <Col span={2} style={{ display: 'flex', justifyContent: 'center' }}>
          -
        </Col>
        <Col span={6}>
          <Form.Item name={'maxValue'}>
            <CustomInputNumber
              style={{ width: '100%' }}
              formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
              min={0}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={20} style={{ display: 'flex' }}>
          <p style={{ marginTop: '2%', display: 'flex' }}>
            {t('payroll:taxRate')}
            {/* <span style={{ color: 'red', paddingLeft: 5 }}> *</span> */}
          </p>
        </Col>
        <Col span={4}>
          <Form.Item name={'taxRate'}>
            <CustomInputNumber
              style={{ width: '100%' }}
              defaultValue={1}
              formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value: any) => (value ? value.replace(/\$\s?|(,*)/g, '') : 1)}
              min={1}
              max={100}
              addonAfter="%"
            />
            {/* {<CustomInputNumber defaultValue={0} style={{ width: '100%' }} min={0} />} */}
          </Form.Item>
        </Col>
      </Row>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: '2%' }}>
        <Popconfirm
          title={`${t('insurance:Are_you_sure_confirm_this_request')}`}
          onConfirm={() => {
            onFinish();
          }}
          okText={`${t('insurance:Yes')}`}
          cancelText={`${t('insurance:no')}`}
        >
          <CustomButton>{t('masterConfig:action.save')}</CustomButton>
        </Popconfirm>
      </div>
    </Form>
  );
};

export default ViewPIT;

export const CustomInputNumber = styled(InputNumber)`
  .ant-input-number-input {
    text-align: right;
    // padding-right:1px;
  }
  .ant-input-number-handler-wrap {
    padding-right: 5px;
  }
  .ant-input-number-handler-wrap {
    display: none;
  }
`;
export const RightAlignInputNumber = styled(InputNumber)`
  .ant-input-number-input {
    text-align: right;
  }
`;

import { DatePicker, Divider, Form } from 'antd';
import { selectFormatDate } from 'app/commonRedux/appSlice';
import { useAppSelector } from 'app/hooks';
import { getFindOneWorkflowType } from 'features/configuration/configurationAction';
import { selectConfiguration } from 'features/configuration/configurationSlice';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import CustomInput from 'styles/inputStyled';
import Workflow2View from './Workflow2View';

const { RangePicker } = DatePicker;

const ModalViewWorkflowType = (props: any) => {
  const dataStore = useAppSelector(selectConfiguration);
  const dispatch = useDispatch();
  const { form, modalType, currentItem } = props;

  const dataFindOneWorkflowType = dataStore?.dataFindOneWorkflowType;

  const [edges, setEdges] = useState();
  const [nodes, setNodes] = useState();
  const [selectAction, setSelectAction] = useState();
  const [selectStatus, setSelectStatus] = useState();
  const [, setIdRow] = useState();
  const format = useAppSelector(selectFormatDate);

  useEffect(() => {
    dispatch(getFindOneWorkflowType({ id: currentItem?.id }));
  }, [currentItem?.id, dispatch]);

  useEffect(() => {
    setSelectAction(dataFindOneWorkflowType?.workflowTypeDetail?.[0].actionGroup?.id); //Khi edit thi dropdown dc
    setIdRow(dataFindOneWorkflowType?.workflowTypeDetail?.[0]?.id);
    setSelectStatus(dataFindOneWorkflowType?.workflowTypeDetail?.[0].statusGroup?.id);
    form.setFieldsValue({
      businessGroup: dataFindOneWorkflowType?.businessModule?.businessCode,
      workflowId: dataFindOneWorkflowType?.workflowTypeDetail?.[0].workflowTypeCode,
      workflowName: dataFindOneWorkflowType?.workflowTypeDetail?.[0].workflowTypeName,
      status: dataFindOneWorkflowType?.workflowTypeDetail?.[0]?.statusGroup?.groupName,
      action: dataFindOneWorkflowType?.workflowTypeDetail?.[0]?.actionGroup?.groupName,
      description: dataFindOneWorkflowType?.workflowTypeDetail?.[0].description,
      date: [
        moment(dataFindOneWorkflowType?.workflowTypeDetail?.[0]?.startDate),
        moment(new Date(2999, 11, 31)),
      ],
      active: dataFindOneWorkflowType?.workflowTypeDetail?.[0]?.status,
    });
    setEdges(dataFindOneWorkflowType?.workflowTypeDetail?.[0]?.edges);
    setNodes(dataFindOneWorkflowType?.workflowTypeDetail?.[0]?.nodes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFindOneWorkflowType, modalType]);

  return (
    <>
      <Form form={form} layout="vertical">
        <EmployeeAddModalContent>
          <EmployeeAddModalContentLeft>
            <CustomFormItem name={'businessGroup'} label={<div>Business</div>}>
              <CustomInput disabled type="text" />
            </CustomFormItem>
            <CustomFormItem name={'workflowId'} label={`Workflow Id`}>
              <CustomInput disabled type="text" />
            </CustomFormItem>
            <CustomFormItem name={'workflowName'} label={`Workflow Name`}>
              <CustomInput disabled type="text" />
            </CustomFormItem>
            <CustomFormItem name={'status'} label={<div>Status Group</div>}>
              <CustomInput disabled type="text" />
            </CustomFormItem>
            <CustomFormItem name={'action'} label={<div>Action Group</div>}>
              <CustomInput disabled type="text" />
            </CustomFormItem>
            <CustomFormItem name={'date'} label={`Date`}>
              <RangePicker disabled format={format} style={{ width: '100%' }} />
            </CustomFormItem>
            <CustomFormItem name={'active'} label={<div>Status</div>}>
              <CustomInput disabled type="text" />
            </CustomFormItem>
            <CustomFormItem name={'description'} label={`Description`}>
              <CustomInput disabled type="text" />
            </CustomFormItem>
          </EmployeeAddModalContentLeft>
          <EmployeeAddModalDivider type="vertical" />
          <EmployeeAddModalContentRight>
            <Workflow2View
              setNodes={setNodes}
              setEdges={setEdges}
              edges={edges}
              nodes={nodes}
              currentItem={dataFindOneWorkflowType}
              modalType={modalType}
              selectAction={selectAction}
              selectStatus={selectStatus}
            />
          </EmployeeAddModalContentRight>
        </EmployeeAddModalContent>
      </Form>
    </>
  );
};

export default ModalViewWorkflowType;
export const CustomFormItem = styled(Form.Item)`
  margin-bottom: 5px;
  .ant-form-item-label {
    padding: 0px;
  }
`;
export const EmployeeAddModalContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  height: 100%;
  width: 100%;
  margin-right: -15px;
`;

export const EmployeeAddModalContentLeft = styled.div`
  padding-right: 10px;
  height: 531px;
  min-width: 200px;
  width: 32%%;
`;

export const EmployeeAddModalContentRight = styled.div`
  width: 100%;
  height: 446px;
  width: 82%;
`;

export const EmployeeAddModalDivider = styled(Divider)`
  height: 504px;
  border-left-width: 5px;
  border-radius: 2px;
`;

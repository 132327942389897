import styled from 'styled-components';
import { Modal, Form, Divider } from 'antd';
export const EmployeeModal = styled(Modal)`
  .ant-modal-content {
    .ant-modal-close-x {
      margin-top: -10px;
      margin-right: -10px;
    }
    .ant-modal-body {
      padding-bottom: 0px;
    }
  }
`;

export const EmployeeAddModalContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  height: 100%;
  margin-right: -15px;
`;

export const EmployeeAddModalContentLeft = styled.div`
  height: 630px;
  min-width: 220px;
`;

export const EmployeeAddModalContentRight = styled.div`
  width: 100%;
  height: 630px;
`;

export const EmployeeAddModalDivider = styled(Divider)`
  height: 620px;
  border-left-width: 5px;
  border-radius: 2px;
`;

export const EmployeeModalForm = styled(Form)`
  padding-top: 15px;
  padding-right: 15px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 630px;

  &::-webkit-scrollbar-track {
    margin-top: 20px;
  }
  &::-webkit-scrollbar {
    background-color: var(--cl_white);
  }
`;

export const ContainerLoading = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

export const LoaderItem = styled.div`
  top: 45%;
  left: 60%;
  position: absolute;
`;

import { Button, Col, Divider, Form, Input, Modal } from 'antd';
import insurancesApi from 'api/insurancesApi';
import { colors } from 'assets/colors';
import { notificationToast } from 'components/notificationToast';
import { Notification } from 'constants/notification';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { getUser } from 'utils/auth';
import { postApproved, resetSchedulePremium } from '../insuranceAction';
import { ContainerTop, InfoRow, InfoTitle } from './ModalCreate';
import { DivButton } from './ModalSendConfirmInsurance';

interface RejectModalProps {
  isVisible: boolean;
  isShowFullMenu: boolean;
  onClose: () => void;
  itemId: any;
  reSetData: () => void;
  mode?: string;
  isRejectRecordPay?: boolean;
  dateRecord?: any;
}
const { TextArea } = Input;
const ModalReject = (props: RejectModalProps) => {
  const { isVisible, onClose, mode = 'reject', isRejectRecordPay = false, dateRecord } = props;
  const [form] = Form.useForm();
  const [iswidth] = useState(540);
  const [input_reason, setInputReason] = useState();
  const { t }: any = useTranslation(['insurance', 'timesheet']);
  const dispatch = useDispatch();

  const handleClose = () => {
    onClose();
    form.resetFields();
  };

  const handleClickSure = async () => {
    form.validateFields().then(async () => {
      if (isRejectRecordPay) {
        try {
          const response = await insurancesApi.confirmRecordPayment({
            status: 'rejected',
            monthYear: dateRecord,
            reason: input_reason,
          });
          if (!response) return;
          notificationToast(Notification.Type.Success, response.message, Notification.Duration._3s);
          handleClose();
          props.reSetData && props.reSetData();
        } catch (error: any) {
          if (!error) return;
          console.log(error);
          notificationToast(Notification.Type.Error, error.message, Notification.Duration._3s);
        }
      } else {
        if (input_reason != null) {
          if (mode === 'reset') {
            dispatch(
              resetSchedulePremium({
                userId: props.itemId.user.id,
                period: props.itemId.newValidDate.split('-').slice(0, 2).join('-'),
                reason: input_reason,
                callBack: (response: any) => {
                  if (!response.error) {
                    props.reSetData && props.reSetData();
                    handleClose();
                  }
                },
              }),
            );
          } else {
            dispatch(
              postApproved({
                status: 'rejected',
                approvedBy: getUser()?.fullName,
                reason: input_reason,
                select: {
                  key: 'id',
                  value:
                    props?.itemId?.key === 'AppActionView'
                      ? props?.itemId?.id
                      : [props?.itemId?.id],
                },
                callBack: (response: any) => {
                  if (!response.error) {
                    props.reSetData && props.reSetData();
                    handleClose();
                    notificationToast(
                      Notification.Type.Success,
                      t('rejected_insurance_success'),
                      Notification.Duration._4s,
                    );
                  }
                },
              }),
            );
          }
        }
      }
    });
  };
  const onFinish = (values: any) => {
    console.log(values);
  };
  return (
    <>
      <InsuranceModal
        title={mode === 'reset' ? t('timesheet:Reset') : t('reject')}
        visible={isVisible}
        maskClosable={false}
        onCancel={handleClose}
        width={iswidth}
        style={{ top: 180 }}
        footer={null}
      >
        <Form form={form} name="control-hooks" onFinish={onFinish}>
          <Container>
            <ContainerTop style={{ width: '100%', height: '75%' }}>
              <InfoRow gutter={[16, 16]}>
                <Col span={24}>
                  <InfoTitle>
                    {t('reason')}
                    <InfoU>*</InfoU>
                  </InfoTitle>
                  <Form.Item name={t('reason')} rules={[{ required: true }]}>
                    <TextArea
                      rows={3}
                      value={input_reason}
                      onChange={(e: any) => setInputReason(e.target.value)}
                      placeholder={
                        mode === 'reset' ? t('Please_reset_record') : t('Please_rejected_record')
                      }
                      style={{
                        padding: 6,
                        width: '100%',
                        borderRadius: 6,
                        color: '#111827',
                      }}
                    />
                  </Form.Item>
                </Col>
              </InfoRow>
            </ContainerTop>
            <DivButton>
              <InfoButtonCancel onClick={handleClose}>{t('cancel')}</InfoButtonCancel>
              <InfoButton onClick={handleClickSure} htmlType="submit">
                {t('sure')}
              </InfoButton>
            </DivButton>
          </Container>
        </Form>
      </InsuranceModal>
    </>
  );
};

export default React.memo(ModalReject);
export const InsuranceModal = styled(Modal)`
  .ant-modal-header {
    background: ${colors.gray200};
  }

  .ant-modal-title {
    color: ${colors.gray900};
    font-size: 20px;
  }

  .ant-modal-close-x {
    color: ${colors.gray900};
    font-size: 20px;
  }

  .ant-modal-content {
    width: $iswidth;

    .ant-modal-close-x {
      margin-right: -10px;
    }

    .ant-modal-body {
      margin-top: -25px;
    }
  }
`;
export const Container = styled.div`
  height: 200px;
  width: $iswidth;
`;
export const InfoButton = styled(Button)`
  height: 32px;
  width: 131px;
  background: #f1592a;
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto;
  border-radius: 6px;
  padding: 4px 12px 6px 12px;
  color: #ffffff;
  margin-left: 10px;
`;
export const InfoU = styled.u`
  color: #f1592a;
  text-decoration: double;
`;
export const InfoButtonCancel = styled(Button)`
  height: 32px;
  width: 131px;
  background: #ffffff;
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto;
  border-radius: 6px;
  padding: 4px 12px 6px 12px;
  color: #6b7280;
  margin-right: 10px;
`;
export const EmployeeAddModalContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  height: 100%;
  margin-right: -15px;
`;

export const EmployeeAddModalDivider = styled(Divider)`
  height: 280px;
  border-left-width: 5px;
  border-radius: 2px;
`;

export const EmployeeModalForm = styled(Form)`
  padding-top: 15px;
  padding-right: 15px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 280px;

  &::-webkit-scrollbar-track {
    margin-top: 20px;
  }
  &::-webkit-scrollbar {
    background-color: var(--cl_white);
  }
`;

export const ContainerLoading = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

import EmployeeColumn from 'components/EmployeeColumn';
import ITVTable from 'components/ITVTable';
import { useState } from 'react';
import { dataHistoryPayroll } from './data';

const HistoryRequest = () => {
  const [sizePage, setSizePage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const COLUMNS = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      width: 60,
      minWidth: 60,
      align: 'center',
    },
    {
      title: 'Employee Id',
      dataIndex: 'employeeId',
      key: 'employeeId',
      width: 100,
      minWidth: 100,
      align: 'center',
    },
    {
      title: 'Employee',
      dataIndex: 'employee',
      key: 'employee',
      width: 200,
      minWidth: 200,
      fixed: 'left',
      render: function (text: any, record: any, index: any) {
        return (
          <EmployeeColumn
            avatar={record?.fullName}
            fullName={record?.fullName}
            email={record?.email}
          />
        );
      },
    },
    {
      title: 'Change',
      dataIndex: 'change',
      key: 'change',
      width: 100,
      minWidth: 100,
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      key: 'reason',
      width: 100,
      minWidth: 100,
    },
    {
      title: 'Updated at',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: 100,
      minWidth: 100,
    },
    {
      title: 'Updated by',
      dataIndex: 'updatedBy',
      key: 'updatedBy',
      width: 100,
      minWidth: 100,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 100,
      minWidth: 100,
    },
  ];
  return (
    <ITVTable
      isRowSelect={false}
      columns={COLUMNS}
      data={dataHistoryPayroll}
      height={550}
      setOffset={setCurrentPage}
      offset={currentPage}
      setLimit={setSizePage}
      limit={sizePage}
    />
  );
};

export default HistoryRequest;

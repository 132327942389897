import moment from 'moment';
import jwt_decode from 'jwt-decode';
export const INSURANCE_KEY_MENU = {};
export const INITIAL_INSURANCE = {};
export const labelKeyNameView = {
  HISTORY_PAYMENT: 'HistoryPayment',
  DROPDOWN_DECREASE: 'Decrease',
  DROPDOWN_INCREASE: 'Increase',
  DROPDOWN_PARTICIPANT_LIST: 'ParticipantList',
  DROPDOWN_APPROVAL: 'Approval',
  SET_VIEW: 'view',
  RECORD_PAYMENT_VIEW: 'RecordPayment',
  RECORD_PAYMENT_VIEW_YEAR: 'RecordPaymentYear',
};
export const labelInsurancesMenuActions = {
  WAITING_FOR_APPROVAL: 'Waiting for approval',
  APPROVED: 'Approved',
  DRAFT: 'draft',
  REJECTED: 'Rejected',
  ACTIVE: 'Active',
  PENDING: 'Pending',
  INACTIVE: 'Inactive',
};
export const labelKeyStatus = {
  KEY_WAITING_FOR_APPROVAL: 'waiting-for-approved',
  KEY_DRAFT: 'draft',
  KEY_ACTIVE: 'active',
  KEY_PENDING: 'pending',
  KEY_INACTIVE: 'inactive',
  KEY_REJECTED: 'rejected',
  KEY_APPROVED: 'approved',
};
export const labelKeyTable = {
  KEY_TABLE_CHECKBOX: 'Table-Check-Box',
  KEY_TABLE_NOT_CHECKBOX: 'Table-Not-Check-Box',
  KEY_TABLE_DATA_PARTICIPANT: 'Data_Participant',
};
//@ts-ignore
export const formatCurrency = (num) => {
  return parseInt(num)
    .toFixed(0)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};
export const labelUnit = 'VND';
//@ts-ignore
export const formatCurrencyUnit = (input_newsalary, key) => {
  //let str = Number(input_newsalary).toLocaleString('it-IT', { style: 'currency', currency: key });
  let str =
    key === 'VND'
      ? parseInt(input_newsalary)
          .toFixed(0)
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
          .concat(' VNĐ')
      : parseInt(input_newsalary)
          .toFixed(0)
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
          .concat(' $');
  return str;
};

export const formatCurrencyUnitPdf = (input_newsalary, key) => {
  let str =
    key === 'VND'
      ? parseInt(input_newsalary)
          .toFixed(0)
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
          .concat(' ')
      : parseInt(input_newsalary)
          .toFixed(0)
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
          .concat(' ');
  return str;
};

export const formatCurrencyUnitVND = (input_newsalary, key) => {
  //let str = Number(input_newsalary).toLocaleString('it-IT', { style: 'currency', currency: key });
  let str =
    key === 'VND'
      ? parseInt(input_newsalary)
          .toFixed(0)
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
          .concat(' VNĐ')
      : parseInt(input_newsalary)
          .toFixed(0)
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
          .concat('');
  return str;
};

//@ts-ignore
export const formatDateInsurances = (date) => {
  let str = moment(date).format('MM/DD/YYYY');
  return str;
};
export const formatISDate = (date) => {
  return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
};
//@ts-ignore
export const formatMonths = (date) => {
  let str = moment(date).format('MM/YYYY');
  return str;
};
export const stringCamelCase = (data) => {
  let string = data.split(/(?=[A-Z])/).join(' ');
  let str = string.toLowerCase();
  return str;
};
export const stringdecode = (token) => {
  const decoded = jwt_decode(token);
  return String(decoded?.password);
};

export const uuidv4 = () => {
  return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, (c) =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
  );
};
// formatVND

/* eslint-disable no-extend-native */
import React, { useEffect, useState } from 'react';
import { number, string } from 'prop-types';

let toRGB = (text: string): any => {
  var hash = 0;
  if (text.length === 0) return '#f1592a';
  var i = 0;
  for (i = 0; i < text.length; i++) {
    hash = text.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }
  var rgb = [0, 0, 0];
  for (i = 0; i < 3; i++) {
    var value = (hash >> (i * 8)) & 255;
    rgb[i] = value;
  }
  return `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`;
};

function Avatar(props: { size: number; name: null | string }) {
  const { size, name } = props;
  const arrayStringName = name?.trim().split(' ');
  const [nameAvatar, setNameAvatar] = useState('');

  useEffect(() => {
    if (!arrayStringName) {
      return;
    }
    const arrayFirstLetter = arrayStringName.map((stringName) => {
      return stringName.charAt(0).toUpperCase();
    });

    if (arrayFirstLetter.length >= 2) {
      setNameAvatar(
        arrayFirstLetter[arrayFirstLetter.length - 2] +
          arrayFirstLetter[arrayFirstLetter.length - 1],
      );
    }
    if (arrayFirstLetter.length <= 1) {
      setNameAvatar(arrayFirstLetter[arrayFirstLetter.length - 1]);
    }
  }, [arrayStringName]);

  return (
    <div>
      <div
        style={{
          width: size,
          height: size,
          backgroundColor: toRGB(nameAvatar),
          borderRadius: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'white',
        }}
      >
        {nameAvatar || 'O'}
      </div>
    </div>
  );
}

Avatar.propTypes = {
  size: number.isRequired,
  name: string.isRequired,
};

Avatar.defaultProps = {
  size: 32,
};

export default React.memo(Avatar);

import { DownOutlined } from '@ant-design/icons';
import { DatePicker, Spin } from 'antd';
import { Moment } from 'moment';
import { useState } from 'react';
import styled from 'styled-components';

interface StatisticListEmployeeItemProps {
  label: string;
  value: number;
  loading: boolean;
  initialMonthYear: Moment | null;
  onSubmit?: (monthYear: Moment | null) => void;
}

export function StatisticListEmployeeItem({
  label,
  value,
  loading,
  initialMonthYear,
  onSubmit,
}: StatisticListEmployeeItemProps) {
  const [monthYear, setMonthYear] = useState<Moment | null>(initialMonthYear || null);

  return (
    <StyledContainer>
      <StyledLabel>{label}</StyledLabel>
      <StyledWrapperValue>
        <StyledValue>{loading ? <Spin /> : value}</StyledValue>
        <StyledWrapperYear>
          <DatePicker
            picker="month"
            value={monthYear}
            allowClear={false}
            suffixIcon={<DownOutlined />}
            onChange={(value) => {
              setMonthYear(value);
              onSubmit?.(value);
            }}
            format={'MM-YYYY'}
          />
        </StyledWrapperYear>
      </StyledWrapperValue>
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  border: 2px solid #eceef6;
  border-radius: 10px;
  padding: 15px 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: var(--cl_white);
  cursor: pointer;
  width: 32%;
`;
const StyledLabel = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  font-family: Roboto;
  color: var(--cl_neutral700);
`;
const StyledWrapperValue = styled.div`
  display: flex;
  gap: 12px;
  justify-content: space-between;
  align-items: center;
`;
const StyledValue = styled.div`
  font-family: Roboto;
  font-size: 30px;
  font-weight: 600;
  line-height: 38px;
  color: var(--cl_neutral900);
`;
const StyledWrapperYear = styled.div`
  width: 80px;
  .ant-picker {
    border-radius: 6px;
    border: 1px solid var(--cl_neutral300);
  }
  .ant-picker-input > input {
    color: #1a2b88;
    font-weight: bold;
    font-size: 12px;
  }
  .ant-picker-suffix {
    font-size: 10px;
  }
  .ant-picker {
    background-color: #f6f6f6;
    padding: 5px;
  }
`;

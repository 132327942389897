import { DownOutlined } from '@ant-design/icons';
import { DatePicker } from 'antd';
import { Moment } from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import IconExport from 'assets/icons/ic_export_gray.svg';
import { CustomIcon } from 'components/ITVSearch';
import { HeaderRemove } from 'features/masterData/Department/styled';

const StyledContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const StyledWrapperYear = styled.div`
  width: 80px;
  .ant-picker {
    border-radius: 6px;
    border: 1px solid var(--cl_neutral300);
  }
  .ant-picker-input > input {
    color: #1a2b88;
    font-weight: bold;
    font-size: 12px;
  }
  .ant-picker-suffix {
    font-size: 10px;
  }
  .ant-picker {
    background-color: #f6f6f6;
    padding: 5px;
  }
`;

interface TimesheetAdjustmentFiltersProps {
  initialMonthYear: Moment | null;
  onSubmit?: (monthYear: Moment | null) => void;
  handleExport?: () => void;
  loadingExport?: boolean;
}

export default function HeadcountLocationFilters({
  initialMonthYear,
  onSubmit,
  handleExport,
  loadingExport = false,
}: TimesheetAdjustmentFiltersProps) {
  const { t } = useTranslation(['dashboard', 'action']);

  const IconExportComponents = (
    <CustomIcon type={'export'} component={() => <img src={IconExport} alt="" />} />
  );

  const [monthYear, setMonthYear] = useState<Moment | null>(initialMonthYear || null);

  return (
    <StyledContainer>
      <StyledWrapperYear>
        <DatePicker
          picker="month"
          value={monthYear}
          allowClear={false}
          suffixIcon={<DownOutlined />}
          onChange={(value) => {
            setMonthYear(value);
            onSubmit?.(value);
          }}
          format={'MM-YYYY'}
        />
      </StyledWrapperYear>

      <HeaderRemove
        onClick={handleExport}
        icon={IconExportComponents}
        loading={loadingExport}
        style={{
          backgroundColor: '#f6f6f6',
          fontSize: '12px',
          color: '#1a2b88',
          fontWeight: 'bold',
        }}
      >
        {t('action:export')}
      </HeaderRemove>
    </StyledContainer>
  );
}

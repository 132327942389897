import { Table } from 'antd';
import { selectFormatDatetime } from 'app/commonRedux/appSlice';
import { useAppSelector } from 'app/hooks';
import EmployeeColumn from 'components/EmployeeColumn';
import ITVSearch from 'components/ITVSearch';
import ITVTable from 'components/ITVTable';
import { getPayrollDetailHistory } from 'features/payroll/payrollAction';
import { selectPayroll } from 'features/payroll/payrollSlice';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { removeAccents } from 'utils/text';
import { dataHistoryOverall } from './data';

const OverallHistory = () => {
  const { id }: any = useParams();
  const dispatch = useDispatch();
  const [sizePage, setSizePage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState(dataHistoryOverall);
  const payroll = useAppSelector(selectPayroll);
  const payrollDetailHistoryList = useMemo(
    () => payroll?.payrollDetailHistory?.result || [],
    [payroll?.payrollDetailHistory?.result],
  );
  const loadingPayrollDetailHistory = payroll?.loadingPayrollDetailHistory;
  const formatDateTime = useAppSelector(selectFormatDatetime);
  const { t } = useTranslation(['payroll']);

  useEffect(() => {
    dispatch(getPayrollDetailHistory({ id }));
  }, [dispatch, id]);

  useEffect(() => {
    setData(payrollDetailHistoryList);
  }, [payrollDetailHistoryList]);

  const COLUMNS: any = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      width: 60,
      minWidth: 60,
      align: 'center',
      fixed: 'left',
      render: function (text: any, record: any, index: any) {
        const trueIndex = index + sizePage * (currentPage - 1);
        return trueIndex + 1;
      },
    },
    {
      title: t('modified_by'),
      dataIndex: 'updatedBy',
      key: 'updatedBy',
      width: 200,
      minWidth: 200,
      render: function (text: any, record: any, index: any) {
        return (
          <EmployeeColumn avatar={text?.fullName} fullName={text?.fullName} email={text?.email} />
        );
      },
    },
    {
      title: t('modified_time'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: 100,
      minWidth: 100,
      render: function (text: any, record: any, index: any) {
        return text ? moment(text).format(formatDateTime) : '';
      },
    },
    {
      title: t('status'),
      dataIndex: 'status',
      key: 'status',
      width: 200,
      minWidth: 200,
      ellipsis: true,
      render: function (text: any, record: any, index: any) {
        return text || '';
      },
    },
    {
      title: t('note'),
      dataIndex: 'note',
      key: 'note',
      ellipsis: true,
      width: 200,
      minWidth: 200,
    },
  ];

  const handleSearch = (value: string) => {
    const text = value && removeAccents(value.toLowerCase());
    if (text) {
      const temp = payrollDetailHistoryList?.filter((item: any) => {
        return (
          item?.updatedBy?.fullName &&
          removeAccents(item?.updatedBy?.fullName?.toLowerCase()).includes(text)
        );
      });
      setData(temp);
    } else {
      setData(payrollDetailHistoryList);
    }
  };

  return (
    <>
      <div></div>
      <Container>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <SearchContainer>
            <ITVSearch handleSearch={handleSearch} />
          </SearchContainer>
        </div>
        <ITVTable
          isRowSelect={false}
          loading={loadingPayrollDetailHistory}
          columns={COLUMNS}
          data={data}
          height={550}
          setOffset={setCurrentPage}
          offset={currentPage}
          setLimit={setSizePage}
          limit={sizePage}
        />
      </Container>
    </>
  );
};

export default OverallHistory;
export const SearchContainer = styled.div`
  width: 200px;
  margin-bottom: 20px;
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  background: white;
  width: 100%;
  height: max-content;
  .summary-last-column {
    right: 6px;
  }
`;
export const Cell = styled(Table.Summary.Cell)`
  .ant-table-cell {
    background: red !important;
    width: 100% !important;
  }
`;

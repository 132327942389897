import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import moment, { Moment } from 'moment';
import { Modal, Form } from 'antd';
import Select from 'react-select';

import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'app/hooks';
import { selectConfiguration } from 'features/configuration/configurationSlice';

import { INIT_RELATIVE_ITEM } from 'features/employee/constants/common';
import { REGEX_NUMBER, REGEX_ALPHANUMERIC } from 'constants/regex';

import CustomFormItem from 'styles/formStyled';
import CustomInput from 'styles/inputStyled';
import CustomDatePicker from 'styles/datePickerStyled';
import CustomButton from 'styles/buttonStyled';
import { RelativeModalForm } from 'features/employee/components/PersonalInformationFields/styled';

interface RelationshipType {
  id?: string | any;
  name?: string | any;
  label?: string | any;
  value?: string | any;
}
interface RelativeType {
  name: string;
  yearOfBirth: Moment;
  relationship: RelationshipType | null;
  occupation: string;
  phoneNumber: string;
  email: string;
}

const RelativeFormModal = (props: any, ref: any) => {
  const { isVisible, dataRelative, handleSaveRelative, handleCloseForm, itemIndex } = props;
  const { t } = useTranslation(['validation', 'employee']);
  const [form] = Form.useForm();
  const dataConfiguration = useAppSelector(selectConfiguration);

  const [relative, setRelative] = useState<RelativeType>(INIT_RELATIVE_ITEM);

  const initData = () => {
    const relationship = dataRelative
      ? {
          value: dataRelative.relationship?.id,
          label: dataRelative.relationship?.value,
        }
      : null;
    const initValues = dataRelative
      ? {
          name: dataRelative?.name,
          yearOfBirth: moment(dataRelative.yearOfBirth),
          occupation: dataRelative?.occupation,
          relationship: relationship,
          phoneNumber: dataRelative?.phoneNumber,
          email: dataRelative?.email,
        }
      : INIT_RELATIVE_ITEM;
    return initValues;
  };

  useEffect(() => {
    const initValues = initData();
    setRelative(initValues);
    form.setFieldsValue(initValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataRelative, form]);

  const onFinish = (values: any) => {
    const relationshipConvert = {
      id: values.relationship.value,
      value: values.relationship.label,
    };
    const tempRelative = {
      ...values,
      yearOfBirth: values.yearOfBirth.toISOString(),
      relationship: relationshipConvert,
    };
    handleSaveRelative(tempRelative, itemIndex);
    handleClose();
  };

  const onFinishFailed = (errorInfo: any) => {
    onUpdateStyleSelect();
    Modal.error({
      title: t('modal:message_invalid'),
    });
  };

  const handleClose = () => {
    const initValues = initData();
    setRelative(initValues);
    form.setFieldsValue(initValues);
    handleCloseForm();
  };

  // Custom CSS Components Select When Error
  const [customStyleInvalidRelationship, setCustomStyleInvalidRelationship] = useState({});

  const onUpdateStyleSelect = () => {
    if (form.getFieldError('relationship')?.length > 0) {
      setCustomStyleInvalidRelationship({
        control: (base: any, state: any) => ({
          ...base,
          borderColor: '#ff4d4f',
        }),
      });
    } else {
      setCustomStyleInvalidRelationship({
        control: (base: any, state: any) => ({
          ...base,
          borderColor: '#d9d9d9',
        }),
      });
    }
  };

  useImperativeHandle(ref, () => ({
    resetStyleSelect: () => {
      setCustomStyleInvalidRelationship({
        control: (base: any, state: any) => ({
          ...base,
          borderColor: '#d9d9d9',
        }),
      });
    },
    resetFieldsForm: () => {
      form.resetFields();
    },
  }));

  useEffect(() => {
    onUpdateStyleSelect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  const onChangeSelect = () => {
    onUpdateStyleSelect();
  };
  //////////////////////////////////////////////////////////////////////////

  return (
    <Modal
      visible={isVisible}
      maskClosable={false}
      onCancel={handleClose}
      style={{ top: 20 }}
      title={t('employee:employee_relative.title')}
      footer={[
        <CustomButton
          form="RelativeForm"
          aType="primary"
          key="save-relative-form"
          htmlType="submit"
        >
          {t('employee:employee_relative.btn_save')}
        </CustomButton>,
        <CustomButton aType="primary" key="close-relative-form" onClick={handleClose}>
          {t('employee:employee_relative.btn_close')}
        </CustomButton>,
      ]}
    >
      <RelativeModalForm
        form={form}
        layout="vertical"
        id="RelativeForm"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={relative}
      >
        <CustomFormItem
          label={t('employee:employee_relative.relative_name')}
          required={true}
          name="name"
          rules={[
            {
              required: true,
              message: t('HRM_VAD_REQUIRED', {
                field: t('employee:employee_relative.relative_name'),
              }),
            },
            {
              whitespace: true,
              message: t('HRM_VAD_BLANK_CHARACTERS', {
                field: t('employee:employee_relative.relative_name'),
              }),
            },
          ]}
        >
          <CustomInput placeholder={t('employee:employee_relative.relative_name_placeholder')} />
        </CustomFormItem>
        <CustomFormItem
          label={t('employee:employee_relative.relative_year_of_birth')}
          required={true}
          name="yearOfBirth"
        >
          <CustomDatePicker
            picker="year"
            placeholder={t('employee:employee_relative.relative_year_of_birth_placeholder')}
          />
        </CustomFormItem>
        <CustomFormItem
          label={t('employee:employee_relative.relationship_with_employee')}
          required={true}
          name="relationship"
          rules={[
            {
              required: true,
              message: t('HRM_VAD_REQUIRED', {
                field: t('employee:employee_relative.relationship_with_employee'),
              }),
            },
          ]}
        >
          <Select
            placeholder={t('employee:employee_relative.relationship_with_employee_placeholder')}
            maxMenuHeight={200}
            options={dataConfiguration.dataRelationship ? dataConfiguration.dataRelationship : []}
            cacheOptions
            defaultOptions
            styles={customStyleInvalidRelationship}
            onChange={onChangeSelect}
          />
        </CustomFormItem>
        <CustomFormItem
          label={t('employee:employee_relative.relative_occupation')}
          name="occupation"
          rules={[
            {
              pattern: new RegExp(REGEX_ALPHANUMERIC),
              message: t('HRM_VAD_FORMAT', {
                field: t('employee:employee_relative.relative_occupation'),
              }),
            },
            {
              whitespace: true,
              message: t('HRM_VAD_BLANK_CHARACTERS', {
                field: t('employee:employee_relative.relative_occupation'),
              }),
            },
          ]}
        >
          <CustomInput
            placeholder={t('employee:employee_relative.relative_occupation_placeholder')}
          />
        </CustomFormItem>
        <CustomFormItem
          label={t('employee:employee_relative.relative_phone_number')}
          name="phoneNumber"
          rules={[
            {
              pattern: new RegExp(REGEX_NUMBER),
              message: t('HRM_VAD_FORMAT', {
                field: t('employee:employee_relative.relative_phone_number'),
              }),
            },
            {
              max: 30,
              message: t('HRM_VAD_MAXIMUM_CHARACTERS', {
                field: t('employee:employee_relative.relative_phone_number'),
                numberLeast: 30,
              }),
            },
            {
              whitespace: true,
              message: t('HRM_VAD_BLANK_CHARACTERS', {
                field: t('employee:employee_relative.relative_phone_number'),
              }),
            },
          ]}
        >
          <CustomInput
            placeholder={t('employee:employee_relative.relative_phone_number_placeholder')}
          />
        </CustomFormItem>
        <CustomFormItem
          label={t('employee:employee_relative.relative_email')}
          name="email"
          rules={[
            {
              type: 'email',
              message: t('HRM_VAD_FORMAT', {
                field: t('employee:employee_relative.relative_email'),
              }),
            },
            {
              min: 6,
              message: t('HRM_VAD_LEAST_CHARACTERS', {
                field: t('employee:employee_relative.relative_email'),
                numberLeast: 6,
              }),
            },
            {
              max: 64,
              message: t('HRM_VAD_MAXIMUM_CHARACTERS', {
                field: t('employee:employee_relative.relative_email'),
                numberLeast: 64,
              }),
            },
            {
              whitespace: true,
              message: t('HRM_VAD_BLANK_CHARACTERS', {
                field: t('employee:employee_relative.relative_email'),
              }),
            },
          ]}
        >
          <CustomInput placeholder={t('employee:employee_relative.relative_email_placeholder')} />
        </CustomFormItem>
      </RelativeModalForm>
    </Modal>
  );
};

export default forwardRef(RelativeFormModal);

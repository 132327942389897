import { Form } from 'antd';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  Background,
  ConnectionLineType,
  Controls,
  ReactFlowProvider,
  applyEdgeChanges,
  applyNodeChanges,
} from 'react-flow-renderer';
import { CustomReactFlow } from '../styled';
import ButtonEdge from './ButtonEdge';
import SmartEdge from './SmartEdge';
import StateNode from './StateNode';
import './indexViewWl.css';

const edgeTypes = { buttonedge: ButtonEdge, smart: SmartEdge };

const nodeTypes = { state: StateNode };

const Workflow2ViewStatusPayroll = (props: any) => {
  const { setNodes, setEdges, edges, nodes, currentItem } = props;
  const reactFlowWrapper = useRef(null);

  useEffect(() => {
    setEdges(currentItem?.newEdges);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentItem]);

  useEffect(() => {
    setNodes(currentItem?.newNodes);
    setNodes((nds: any) =>
      currentItem?.newNodes.map((nodeId: any) => {
        if (currentItem?.currentNode === nodeId.id) {
          return {
            ...nodeId,
            style: {
              background: '#FE753E',
              color: '#fff',
              fontSize: '16px',
              fontWeight: 700,
            },
          };
        } else {
          return {
            ...nodeId,
          };
        }
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentItem]);

  const [, setReactFlowInstance] = useState(null);
  const [, setVisible] = useState(false);
  const [, setVisibleAction] = useState(false);
  const [form] = Form.useForm();
  const [captureElementClick] = useState(true);
  const [captureEdgeClick] = useState(true);

  const [, setValueNode] = useState('');
  const [, setValueEdge] = useState('');

  const [, setEdgeNextValueNode] = useState([]);
  const [, setEdgePrevValueNode] = useState([]);
  const [, setValueAction] = useState('');
  const [, setFromNode] = useState<any[]>([]);
  const [, setToNode] = useState({});

  const handleNodesChange = useCallback((changes: any) => {
    nodes &&
      setNodes((ns) => {
        return ns && applyNodeChanges(changes, ns);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEdgesChange = useCallback((changes: any) => {
    setEdges((es) => applyEdgeChanges(changes, es));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDragOver = useCallback((event: any) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onNodeClick = (event: any, node: any) => {
    const edgeNext = edges?.filter((item) => {
      return item.source === node.id;
    });
    const edgePrev = edges?.filter((item) => {
      return item.target === node.id;
    });

    setEdgeNextValueNode(edgeNext);
    setEdgePrevValueNode(edgePrev);

    if (node?.data?.label !== 'Start' && node?.data?.label !== 'End') {
      setVisible(true);
    }
    setValueNode(node);

    form.setFieldsValue({
      nameValue: node?.data.label,
      desValue: node?.data.label,
    });
  };

  const onEdgeClick = (event: any, edge: any) => {
    const from = nodes?.find((item) => {
      return edge.source === item.id;
    });
    setFromNode(from);
    const to = nodes?.find((item) => {
      return edge.target === item.id;
    });
    form.setFieldsValue({
      selectAction: edge.label,
    });
    setToNode(to);
    setValueEdge(edge);
    setValueAction(edge.label);

    setVisibleAction(true);
  };

  return (
    <div
      className="site-drawer-render-in-current-wrapper"
      style={{ display: 'flex', height: '100%', width: '100%', position: 'relative' }}
    >
      <div className="dndflow">
        <ReactFlowProvider>
          <div className="reactflow-wrapper" ref={reactFlowWrapper}>
            <CustomReactFlow
              nodes={nodes}
              edges={edges}
              onNodesChange={handleNodesChange}
              onEdgesChange={handleEdgesChange}
              // // onConnect={handleConnect}
              onInit={setReactFlowInstance}
              onNodeClick={captureElementClick ? onNodeClick : undefined}
              onEdgeClick={captureEdgeClick && onEdgeClick}
              // onDrop={handleDrop}
              onDragOver={handleDragOver}
              fitView
              edgeTypes={edgeTypes}
              deleteKeyCode={null}
              nodeTypes={nodeTypes}
              connectionLineType={ConnectionLineType.SmoothStep}
              connectionMode="loose"
            >
              <Background />
              <Controls />
            </CustomReactFlow>
          </div>
        </ReactFlowProvider>
      </div>
    </div>
  );
};

export default Workflow2ViewStatusPayroll;

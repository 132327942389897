import styled from 'styled-components';
import { Col, Row } from 'antd';
import { formatCurrencyUnit } from 'features/insurances/constants/common';

const ViewPayslip = ({ mode, record }: any) => {
  return (
    <div>
      {/* Personal information */}
      <Row gutter={[16, 16]}>
        <ColCenter span={24}>Personal information</ColCenter>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={12}>Fullname</Col>
        <ColRight span={12}>{record?.fullName}</ColRight>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={12}>Employee Id</Col> <ColRight span={12}>{record?.employeeId}</ColRight>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={12}>Position</Col> <ColRight span={12}>{record?.position}</ColRight>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={12}>Kind of contract</Col> <ColRight span={12}>{record?.contract}</ColRight>
      </Row>
      {/* Salary */}
      <Row gutter={[16, 16]}>
        <ColCenter span={24}>Salary</ColCenter>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={12}>Check gross salary</Col>
        <ColRight span={12}>{formatCurrencyUnit(record?.salary.check, 'VND')}</ColRight>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={12}>Gross salary</Col>
        <ColRight span={12}>{formatCurrencyUnit(record?.salary.gross, 'VND')}</ColRight>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={12}>Main salary</Col>
        <ColRight span={12}>{formatCurrencyUnit(record?.salary.main, 'VND')}</ColRight>
      </Row>
      {/* Timekepping */}
      <Row gutter={[16, 16]}>
        <ColCenter span={24}>Timekeeping</ColCenter>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={12}>Plan hours</Col>
        <ColRight span={12}>{record?.timekeeping.planning}</ColRight>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={12}>Actual hours</Col>
        <ColRight span={12}>{record?.timekeeping.actual}</ColRight>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={12}>Paid leave off</Col>
        <ColRight span={12}>{record?.timekeeping.paidLeave}</ColRight>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={12}>Total paid hours</Col>
        <ColRight span={12}>{record?.timekeeping.paidHours}</ColRight>
      </Row>
      {/* Timekeeping  and Allowance*/}
      <div style={{ display: 'flex', width: '100%' }}>
        <div style={{ width: '50%', marginRight: '40px' }}>
          {/* Allowance */}
          <Row gutter={[16, 16]}>
            <ColCenter span={24}>Allowance</ColCenter>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>Meal</Col>
            <ColRight span={12}>{formatCurrencyUnit(record?.allowance.meal, 'VND')}</ColRight>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>Cellphone</Col>
            <ColRight span={12}>{formatCurrencyUnit(record?.allowance.phone, 'VND')}</ColRight>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>Business fee</Col>
            <ColRight span={12}>
              {formatCurrencyUnit(record?.allowance.businessFee, 'VND')}
            </ColRight>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>Marketing</Col>
            <ColRight span={12}>{formatCurrencyUnit(record?.allowance.marketing, 'VND')}</ColRight>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>Order</Col>
            <ColRight span={12}>{formatCurrencyUnit(record?.allowance.other, 'VND')}</ColRight>
          </Row>
        </div>
        <div style={{ width: '50%' }}>
          {/* Overtime */}
          <Row gutter={[16, 16]}>
            <ColCenter span={24}>Overtime</ColCenter>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>x1.5</Col> <ColRight span={12}>{record?.overtime.taxable}</ColRight>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>x2</Col> <ColRight span={12}>{record?.overtime.taxable}</ColRight>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>x2.5</Col> <ColRight span={12}>{record?.overtime.taxable}</ColRight>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>x3</Col> <ColRight span={12}>{record?.overtime.taxable}</ColRight>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>Taxable OT Payment</Col>
            <ColRight span={12}>{record?.overtime.taxable}</ColRight>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>Non_Taxable OT Payment</Col>
            <ColRight span={12}>{record?.overtime.nonTaxable}</ColRight>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>Total income this period</Col>
            <ColRight span={12}>{formatCurrencyUnit(record?.overtime.total, 'VND')}</ColRight>
          </Row>
        </div>
      </div>

      {/* Insurance */}
      <Row gutter={[16, 16]}>
        <ColCenter span={24}>Insurance</ColCenter>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={12}>Employee contribution </Col>
        <ColRight span={12}>{record?.insurance}</ColRight>
      </Row>
      {/* Tax */}
      <Row gutter={[16, 16]}>
        <ColCenter span={24}>Tax</ColCenter>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={12}>Sum of PIT Deduction</Col>
        <ColRight span={12}>{record?.pitDeduction}</ColRight>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={12}>Taxable Income</Col>
        <ColRight span={12}>{formatCurrencyUnit(record?.taxableIncome, 'VND')}</ColRight>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={12}>Taxed Income</Col>
        <ColRight span={12}>{formatCurrencyUnit(record?.taxedIncome, 'VND')}</ColRight>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={12}>Personal Income tax (PIT)</Col> <ColRight span={12}>{record?.pit}</ColRight>
      </Row>
      {/* Prepaid salary */}
      <Row gutter={[16, 16]}>
        <ColCenter span={24}>Prepaid salary</ColCenter>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={12}>Advance payment </Col>
        <ColRight span={12}>{formatCurrencyUnit(record?.advancePayment, 'VND')}</ColRight>
      </Row>
      {/* Orders */}
      <Row gutter={[16, 16]}>
        <ColCenter span={24}>Orders</ColCenter>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={12}>Best performance </Col>
        <ColRight span={12}>{formatCurrencyUnit(record?.bestPerformance, 'VND')}</ColRight>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={12}>Bonus </Col>
        <ColRight span={12}>{formatCurrencyUnit(record?.bonus, 'VND')}</ColRight>
      </Row>
      {/* Result */}
      <Row gutter={[16, 16]}>
        <ColCenter span={24}>Result</ColCenter>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={12}>Actual take </Col>
        <ColRight span={12}>{formatCurrencyUnit(record?.actual, 'VND')}</ColRight>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={12}>Total cost </Col>
        <ColRight span={12} style={{ fontWeight: '700', fontSize: '20px' }}>
          {formatCurrencyUnit(record?.total, 'VND')}
        </ColRight>
      </Row>
    </div>
  );
};

export default ViewPayslip;

export const ColRight = styled(Col)`
  text-align: right;
`;
export const ColCenter = styled(Col)`
  text-align: center;
  background: #fea481;
  color: white;
  font-weight: 700;
`;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import offboardApi from 'api/offboardApi';
import { setGlobalLoading } from 'app/commonRedux/appSlice';

export const createOffboard = createAsyncThunk(
  'offboard/createOffboard',
  async ({ callBack, ...params }: any, thunkApi) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await offboardApi.createOffboard(params);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack && callBack(response.data);
      return response.data;
    } catch (error: any) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack && callBack({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const createOffboardJob = createAsyncThunk(
  'offboard/createOffboardJob',
  async ({ callBack, ...params }: any, thunkApi) => {
    try {
      const response = await offboardApi.createOffboardJob(params);
      callBack && callBack(response.data);
      return response.data;
    } catch (error: any) {
      callBack && callBack({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const updateOffboardJob = createAsyncThunk(
  'offboard/updateOffboardJob',
  async ({ callBack, ...params }: any, thunkApi) => {
    try {
      const response = await offboardApi.updateOffboardJob(params);
      callBack && callBack(response.data);
      return response.data;
    } catch (error: any) {
      callBack && callBack({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const deleteOffboardJob = createAsyncThunk(
  'offboard/deleteOffboardJob',
  async ({ callBack, ...params }: any, thunkApi) => {
    try {
      const response = await offboardApi.deleteOffboardJob(params);
      callBack && callBack(response.data);
      return response.data;
    } catch (error: any) {
      callBack && callBack({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getOffboardById = createAsyncThunk(
  'offboard/getOffboardById',
  async ({ callBack, ...params }: any, thunkApi: any) => {
    try {
      const response: any = await offboardApi.getOffboardById(params);
      callBack && callBack(response.data);
      return response.data;
    } catch (error) {
      callBack && callBack({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getOffboard = createAsyncThunk(
  'offboard/getOffboard',
  async ({ callBack, ...params }: any, thunkApi: any) => {
    try {
      const response: any = await offboardApi.getOffboard(params);
      callBack && callBack(response.data);
      return response.data;
    } catch (error) {
      callBack && callBack({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getOffboardByEmployee = createAsyncThunk(
  'offboard/getOffboardByEmployee',
  async ({ callBack, ...params }: any, thunkApi: any) => {
    try {
      const response: any = await offboardApi.getOffboardByEmployee(params);
      callBack && callBack(response.data);
      return response.data;
    } catch (error: any) {
      callBack && callBack({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const updateOffboardStatus = createAsyncThunk(
  'offboard/updateOffboardStatus',
  async ({ callBack, ...params }: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await offboardApi.updateOffboardStatus(params);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack && callBack(response.data);
      return response.data;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack && callBack({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const confirmOfManager = createAsyncThunk(
  'offboard/confirmOfManager',
  async ({ callBack, ...params }: any, thunkApi: any) => {
    try {
      // thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await offboardApi.confirmOfManager(params);
      // thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack && callBack(response.data);
      return response.data;
    } catch (error) {
      // thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack && callBack({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const confirmOfHR = createAsyncThunk(
  'offboard/confirmOfHR',
  async ({ callBack, ...params }: any, thunkApi: any) => {
    try {
      // thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await offboardApi.confirmOfHR(params);
      // thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack && callBack(response.data);
      return response.data;
    } catch (error) {
      // thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack && callBack({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const confirmOfIT = createAsyncThunk(
  'offboard/confirmOfIT',
  async ({ callBack, ...params }: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await offboardApi.confirmOfIT(params);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack && callBack(response.data);
      return response.data;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack && callBack({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const confirmInterview = createAsyncThunk(
  'offboard/conficonfirmInterviewrmOfIT',
  async ({ callBack, ...params }: any, thunkApi: any) => {
    try {
      // thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await offboardApi.confirmInterview(params);
      // thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack && callBack(response.data);
      return response.data;
    } catch (error) {
      // thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack && callBack({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const confirmJob = createAsyncThunk(
  'offboard/confirmJob',
  async ({ callBack, ...params }: any, thunkApi: any) => {
    try {
      // thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await offboardApi.confirmJob(params);
      // thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack && callBack(response.data);
      return response.data;
    } catch (error) {
      // thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack && callBack({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const confirmEquipment = createAsyncThunk(
  'offboard/confirmEquipment',
  async ({ callBack, ...params }: any, thunkApi: any) => {
    try {
      // thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await offboardApi.confirmEquipment(params);
      // thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack && callBack(response.data);
      return response.data;
    } catch (error) {
      // thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack && callBack({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const approveJob = createAsyncThunk(
  'offboard/approveJob',
  async ({ callBack, ...params }: any, thunkApi: any) => {
    try {
      // thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await offboardApi.approveJob(params);
      // thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack && callBack(response.data);
      return response.data;
    } catch (error) {
      // thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack && callBack({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const sendRequest = createAsyncThunk(
  'offboard/sendRequest',
  async ({ callBack, ...params }: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await offboardApi.sendRequest(params);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack && callBack(response.data);
      return response.data;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack && callBack({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const reject = createAsyncThunk(
  'offboard/reject',
  async ({ callBack, ...params }: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await offboardApi.reject(params);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack && callBack(response.data);
      return response.data;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack && callBack({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getEquipmentProcessing = createAsyncThunk(
  'offboard/getEquipmentProcessing',
  async ({ callBack, ...params }: any, thunkApi: any) => {
    try {
      const response: any = await offboardApi.getEquipmentProcessing(params);
      callBack && callBack(response.data);
      return response.data;
    } catch (error: any) {
      callBack && callBack({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

const initialState = {
  isLoading: false,
  isLoadingEmployeeOffboard: false,
  isLoadingOffboard: false,
  employeeOffboard: [],
  offboards: [],
  isLoadingOffboardById: false,
  offboardById: null,
  error: null,
  templates: [],
  total: 0,
};

const offboardSlice = createSlice({
  name: 'offboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOffboardByEmployee.pending, (state, action) => {
        state.isLoadingOffboard = true;
      })
      .addCase(getOffboardByEmployee.fulfilled, (state, action: any) => {
        state.isLoadingOffboard = false;
        state.offboards = action.payload;
      })
      .addCase(getOffboardByEmployee.rejected, (state, action: any) => {
        state.isLoadingOffboard = false;
        state.offboards = [];
      })
      .addCase(getOffboard.pending, (state, action) => {
        state.isLoadingEmployeeOffboard = true;
      })
      .addCase(getOffboard.fulfilled, (state, action: any) => {
        state.isLoadingEmployeeOffboard = false;
        state.employeeOffboard = action.payload.result;
      })
      .addCase(getOffboard.rejected, (state, action: any) => {
        state.isLoadingEmployeeOffboard = false;
        state.employeeOffboard = [];
      })
      .addCase(getOffboardById.pending, (state, action) => {
        state.isLoadingOffboardById = true;
      })
      .addCase(getOffboardById.fulfilled, (state, action: any) => {
        state.isLoadingOffboardById = false;
        state.offboardById = action.payload;
      })
      .addCase(getOffboardById.rejected, (state, action: any) => {
        state.isLoadingOffboardById = false;
        state.offboardById = null;
      });
  },
});

export default offboardSlice.reducer;

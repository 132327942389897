import { useTranslation } from 'react-i18next';
import AssignedBonusItems from './Components/AssignedBonusItems';
import { SkillInfoTitle } from './styled';

const BonusInformationFields = (props: any) => {
  const { isShowComponents, employeeData, callBack, monthYear } = props;
  const { t } = useTranslation(['employee', 'modal']);

  return (
    <div style={isShowComponents ? {} : { display: 'none' }}>
      <SkillInfoTitle>{t('employee:bonus_information_fields.bonus')}</SkillInfoTitle>
      <AssignedBonusItems employeeData={employeeData} callBack={callBack} monthYear={monthYear} />
    </div>
  );
};

export default BonusInformationFields;

import { EditOutlined } from '@ant-design/icons';
import { Card } from 'antd';
import { useForm } from 'react-hook-form';
import CustomButton from 'styles/buttonStyled';
import PersonalInfo from './PersonalInfo';
import ProfessionalAcademic from './ProfessionalAcademic';
import UploadResume from './UploadResume';
import { dataBonus } from 'features/payroll/pages/ManageRequest/data';
import { useState } from 'react';
import { isInterviewer } from 'features/recruitment/jobs/components/types';
import Skills from './Skills';
import Certificates from './Certificates';
import { getUser } from 'utils/auth';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

const Summary = ({ handleChange, data }) => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: undefined,
  });
  const { t } = useTranslation(['recruitment', 'action']);
  const { id, jobId } = useParams();

  const [visiblePersonalInfo, setVisiblePersonalInfo] = useState(false);
  const [visibleProfessionalAcademic, setVisibleProfessionalAcademic] = useState(false);
  const [visibleSkills, setVisibleSkills] = useState(false);
  const [visibleCert, setVisibleCert] = useState(false);
  const isShowEditPermissionsBase = getUser()?.permissions?.some(
    (i) => i === 'RECRUIT_JOB_APPROVE',
  );

  const contents = [
    {
      title: t('recruitment:personal_information'),
      component: (
        <PersonalInfo
          handleChange={handleChange}
          data={data}
          visible={visiblePersonalInfo}
          setVisible={setVisiblePersonalInfo}
        />
      ),
    },
    {
      title: t('recruitment:professional_and_academic_background'),
      component: (
        <ProfessionalAcademic
          handleChange={handleChange}
          data={data}
          visible={visibleProfessionalAcademic}
          setVisible={setVisibleProfessionalAcademic}
        />
      ),
    },
  ];

  const contentsSkillAndCert = [
    {
      title: t('recruitment:certificates'),
      component: (
        <Certificates
          handleChange={handleChange}
          data={data}
          visible={visibleCert}
          setVisible={setVisibleCert}
        />
      ),
    },
    {
      title: t('recruitment:skills'),
      component: (
        <Skills
          handleChange={handleChange}
          data={data}
          visible={visibleSkills}
          setVisible={setVisibleSkills}
        />
      ),
    },
  ];

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
      {!isInterviewer() &&
        contents.map(({ component, title }, index) => (
          <Card
            key={index}
            title={title}
            bordered={false}
            headStyle={{
              fontSize: 24,
              fontWeight: 500,
            }}
            extra={
              isShowEditPermissionsBase || id === 'draft' ? (
                <a
                  onClick={() =>
                    index === 0
                      ? setVisiblePersonalInfo(!visiblePersonalInfo)
                      : setVisibleProfessionalAcademic(!visibleProfessionalAcademic)
                  }
                >
                  <EditOutlined style={{ color: '#1890FF', marginRight: 8 }} />
                  {t('action:edit')}
                </a>
              ) : null
            }
          >
            {component}
          </Card>
        ))}
      <div style={{ display: 'flex', gap: 8, height: 'max-content' }}>
        {!isInterviewer() &&
          contentsSkillAndCert.map(({ component, title }, index) => (
            <div style={{ flex: 1 }} key={index}>
              <Card
                style={{ height: '100%' }}
                key={index}
                title={title}
                bordered={false}
                headStyle={{
                  fontSize: 24,
                  fontWeight: 500,
                }}
                extra={
                  isShowEditPermissionsBase || id === 'draft' ? (
                    <a
                      onClick={() =>
                        index === 0
                          ? setVisibleCert(!visibleCert)
                          : setVisibleSkills(!visibleSkills)
                      }
                    >
                      <EditOutlined style={{ color: '#1890FF', marginRight: 8 }} />
                      {t('action:edit')}
                    </a>
                  ) : null
                }
              >
                {component}
              </Card>
            </div>
          ))}
      </div>
      <UploadResume handleChange={handleChange} data={data} />
    </div>
  );
};

export default Summary;

import { Col, Form, Radio, Row, Select } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import Avatar from 'components/Avatar';
import { getEmployeeListApproved } from 'features/employee/employeeAction';
import { selectEmployee } from 'features/employee/employeeSlice';
import {
  getDepartmentList,
  getPermissionList,
  getRoleList,
} from 'features/masterData/masterDataAction';
import { selectMasterData } from 'features/masterData/masterDataSlice';
import { RoleDefault } from 'hrm-common/extensions/enums/personel';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import CustomButton from 'styles/buttonStyled';
import { removeAccents } from 'utils/text';
import { EmployeeOnboardSendMail } from '../interfaces/templateEmail';
import { getListEmailServer, getListGroupEmail } from '../onboardAction';
import { selectOnboard } from '../onboardSlice';
import EditorEmail from './EditorEmail';

const { Option, OptGroup } = Select;

interface SendTemplateProp {
  handleSubmit: (...props: any) => any;
  submitText: string;
  selectionType: string;
  actionVisible: boolean;
  rowKey: string[];
  disableByManager?: boolean;
  data?: any;
}
const SendTemplate = ({
  handleSubmit,
  submitText,
  selectionType = 'checkbox',
  actionVisible = true,
  disableByManager = false,
  data,
}: SendTemplateProp) => {
  const dispatch = useAppDispatch();
  const employee = useAppSelector(selectEmployee);
  const onboard = useAppSelector(selectOnboard);
  const employeeList: EmployeeOnboardSendMail[] = employee?.employeeListApproved;
  const listGroupEmailRedux = onboard?.listGroupEmail?.result;
  const stateRole: any = useAppSelector(selectMasterData);
  const [dataEmployee, setDataEmployee] = useState<EmployeeOnboardSendMail[]>([]);

  const [form] = Form.useForm();

  const [listVariableEmail] = useState([]);
  const [html, setHtml] = useState('');
  const [isDefaultHtml, setIsDefaultHtml] = useState(false);
  const [disableSend, setDisableSend] = useState(false);

  const { t } = useTranslation([
    'onboard',
    'insurance',
    'timesheet',
    'employee',
    'overtime',
    'menu',
  ]);

  const listEmailServer = onboard?.listEmailServer?.result;

  const [, setDefaultOptionTO] = useState<any[]>([]);
  const [optionTO, setOptionTO] = useState<EmployeeOnboardSendMail[]>([]);

  const [, setDefaultOptionCC] = useState<any[]>([]);
  const [optionCC, setOptionCC] = useState<EmployeeOnboardSendMail[]>([]);

  const [, setDefaultOptionBCC] = useState<any[]>([]);
  const [optionBCC, setOptionBCC] = useState<EmployeeOnboardSendMail[]>([]);

  const [defaultEmailServer, setDefaultEmailServer] = useState(listEmailServer[0]?.id);

  const listDefault: any = [
    {
      id: RoleDefault.CREATOR,
      fullName: `@${RoleDefault.CREATOR}`,
    },
    {
      id: RoleDefault.DIRECT_MANAGER,
      fullName: `@${RoleDefault.DIRECT_MANAGER}`,
    },
    {
      id: RoleDefault.PROJECT_LEAD,
      fullName: `@${RoleDefault.PROJECT_LEAD}`,
    },
    {
      id: RoleDefault.REQUESTER,
      fullName: `@${RoleDefault.REQUESTER}`,
    },
    {
      id: RoleDefault.ALL_STAFF,
      fullName: `@${RoleDefault.ALL_STAFF}`,
    },
    {
      id: RoleDefault.INFORMER,
      fullName: `@${RoleDefault.INFORMER}`,
    },
  ];
  const listRole: any = [];
  const listDepartment: any = [];
  const listGroupEmail: any = [];

  useEffect(() => {
    dispatch(getEmployeeListApproved());
    dispatch(getListEmailServer({}));
    dispatch(getRoleList());
    dispatch(getPermissionList());
    dispatch(getDepartmentList());
    dispatch(getListGroupEmail({}));
  }, [dispatch]);

  stateRole.listRole.forEach((item: any) => {
    listRole.push({
      id: item.id,
      fullName: `@${item.name}`,
    });
  });

  stateRole.listDepartment.forEach((item: any) => {
    listDepartment.push({
      id: `department_${item.id}`,
      fullName: `@Department_${item.name}`,
    });
  });

  listGroupEmailRedux?.forEach((item: any) => {
    listGroupEmail.push({
      id: `group_${item.id}`,
      fullName: item.groupName,
    });
  });

  useEffect(() => {
    if (employeeList) {
      setOptionTO(employeeList);
      setOptionCC(employeeList);
      setOptionBCC(employeeList);
      setDataEmployee(employeeList);
    }
  }, [employeeList]);

  const handleList = (list: any, key: string) => {
    let temp: any = [];
    list.forEach((item: any) => {
      temp.push(`${key}_${item}`);
    });
    return temp;
  };

  useEffect(() => {
    if (data) {
      setDefaultOptionTO(data.to);
      setDefaultOptionCC(data.cc);
      setDefaultOptionBCC(data.bcc);

      setHtml(data.body);
      setIsDefaultHtml(true);
      form.setFieldsValue({
        type: data.personalType ? 'personal' : 'company',
        to: [
          ...data.to,
          ...handleList(data.toDepartment, 'department'),
          ...handleList(data.toInvolverGroup, 'group'),
        ],
        cc: [
          ...data.cc,
          ...handleList(data.ccDepartment, 'department'),
          ...handleList(data.ccInvolverGroup, 'group'),
        ],
        bcc: [
          ...data.bcc,
          ...handleList(data.bccDepartment, 'department'),
          ...handleList(data.bccDepartment, 'group'),
        ],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const GroupSelect = (label: string, list: any) => {
    return (
      <OptGroup label={label}>
        {list?.map((item: any) => {
          return (
            <Select.Option key={item.email ?? item.id} value={item.id} name={item.fullName}>
              <Row>
                <div style={{ marginTop: '5px', marginRight: '5px' }}>
                  <Avatar name={label === t('timesheet:Employee') ? item.fullName : '@'} />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  <div
                    data-id="title"
                    style={{
                      fontWeight: 'bold',
                      fontSize: 14,
                    }}
                  >
                    {item.fullName}
                  </div>
                  <div
                    data-id="sub-title"
                    style={{
                      color: '#9CA3AF',
                      fontSize: 12,
                    }}
                  >
                    {label === t('timesheet:Employee') ? item.email : ''}
                  </div>
                </div>
              </Row>
            </Select.Option>
          );
        })}
      </OptGroup>
    );
  };

  const handleChangeOptionTO = (value: any) => {
    if (value.length > 0) {
      setDisableSend(false);
    } else {
      setDisableSend(true);
    }
  };

  return (
    <WrapContainer>
      <Form
        form={form}
        layout="horizontal"
        onFinish={(values: any) => {
          handleSubmit?.(
            defaultEmailServer,
            values.type,
            values.to,
            values.cc,
            values.bcc,
            dataEmployee,
          );
        }}
      >
        <Row>
          <Col span={3}>
            <div>From:&nbsp;</div>
          </Col>
          <Col span={21}>
            <div
              style={{
                width: 1000,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              <Select
                style={{ width: 300 }}
                showSearch
                filterOption={(input, option) =>
                  (option!.children?.props?.children as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                defaultValue={listEmailServer[0]?.id}
                onChange={(value) => {
                  setDefaultEmailServer(value);
                }}
              >
                {listEmailServer?.map((item: any) => {
                  return (
                    <Option value={item.id} key={item.id}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        {item.mailNameFrom}
                      </div>
                    </Option>
                  );
                })}
              </Select>
            </div>
          </Col>
        </Row>

        <Row style={{ marginBottom: 10 }}>
          <Col span={3}>
            <div>{t('onboard:LabelFormEmail.mail_name')}:&nbsp;</div>
          </Col>
          <Col span={21}>
            <div
              style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {data?.name}
            </div>
          </Col>
        </Row>

        <Row style={{ marginBottom: 10 }}>
          <Col span={3}>
            <div>{t('onboard:LabelFormEmail.subject_name')}:&nbsp;</div>
          </Col>
          <Col span={21}>
            <div
              style={{
                width: 1000,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {data?.subject}
            </div>
          </Col>
        </Row>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Form.Item label={t('mail_type')} name="type" initialValue="company">
            <Radio.Group>
              <Radio value={'company'}>{t('work_email')}</Radio>
              <Radio value={'personal'}>{t('personal_email')}</Radio>
            </Radio.Group>
          </Form.Item>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
          <div style={{ width: '100%' }}>
            <Form.Item label="To" name="to">
              <Select
                style={{ width: '100%' }}
                mode="multiple"
                allowClear
                placeholder={t('onboard:please_select')}
                showSearch
                filterOption={(input: any, option: any) => {
                  return (
                    removeAccents(option.name?.toLowerCase())?.indexOf(
                      removeAccents(input.toLowerCase()),
                    ) >= 0 ||
                    removeAccents(option.key?.toLowerCase())?.indexOf(
                      removeAccents(input.toLowerCase()),
                    ) >= 0
                  );
                }}
                optionLabelProp="name"
                onChange={handleChangeOptionTO}
              >
                {GroupSelect(t('timesheet:default_role'), listDefault)}
                {GroupSelect(t('timesheet:role'), listRole)}
                {GroupSelect(t('timesheet:department'), listDepartment)}
                {GroupSelect(t('menu:configuration_group_email'), listGroupEmail)}
                {GroupSelect(t('timesheet:Employee'), optionTO)}
              </Select>
            </Form.Item>
          </div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
          <div style={{ width: '100%' }}>
            <Form.Item label="Cc" name="cc">
              <Select
                style={{ width: '100%' }}
                mode="multiple"
                allowClear
                placeholder={t('onboard:please_select')}
                showSearch
                filterOption={(input: any, option: any) => {
                  return (
                    removeAccents(option.name?.toLowerCase())?.indexOf(
                      removeAccents(input.toLowerCase()),
                    ) >= 0 ||
                    removeAccents(option.key?.toLowerCase())?.indexOf(
                      removeAccents(input.toLowerCase()),
                    ) >= 0
                  );
                }}
                optionLabelProp="name"
              >
                {GroupSelect(t('timesheet:default_role'), listDefault)}
                {GroupSelect(t('timesheet:role'), listRole)}
                {GroupSelect(t('timesheet:department'), listDepartment)}
                {GroupSelect(t('menu:configuration_group_email'), listGroupEmail)}
                {GroupSelect(t('timesheet:Employee'), optionCC)}
              </Select>
            </Form.Item>
          </div>
        </div>

        <div style={{ display: 'none', justifyContent: 'space-between', marginBottom: 10 }}>
          <div style={{ width: '100%' }}>
            <Form.Item label="Bcc" name="bcc">
              <Select
                style={{ width: '100%' }}
                mode="multiple"
                allowClear
                placeholder={t('onboard:please_select')}
                showSearch
                filterOption={(input: any, option: any) => {
                  return (
                    removeAccents(option.name?.toLowerCase())?.indexOf(
                      removeAccents(input.toLowerCase()),
                    ) >= 0 ||
                    removeAccents(option.key?.toLowerCase())?.indexOf(
                      removeAccents(input.toLowerCase()),
                    ) >= 0
                  );
                }}
                optionLabelProp="name"
              >
                {GroupSelect(t('timesheet:default_role'), listDefault)}
                {GroupSelect(t('timesheet:role'), listRole)}
                {GroupSelect(t('timesheet:department'), listDepartment)}
                {GroupSelect(t('menu:configuration_group_email'), listGroupEmail)}
                {GroupSelect(t('timesheet:Employee'), optionBCC)}
              </Select>
            </Form.Item>
          </div>
        </div>

        <EditorEmail
          listVariableEmail={listVariableEmail}
          setHtml={setHtml}
          html={html}
          isDefaultHtml={isDefaultHtml}
          onlyView={true}
          isSelectedModule={false}
        />

        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10 }}>
          <Form.Item>
            <CustomButton htmlType="submit" disabled={disableSend} style={{ width: 100 }}>
              {submitText}
            </CustomButton>
          </Form.Item>
        </div>
      </Form>
    </WrapContainer>
  );
};

export default SendTemplate;

const WrapContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const ContainerLeft = styled.div``;

export const ContainerRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

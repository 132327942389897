import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Row, Col, Modal } from 'antd';

import Icon from '@ant-design/icons';
import IconSuccess from 'assets/icons/ic_success_orange.svg';
import IconCancel from 'assets/icons/ic_cancel_orange.svg';

import {
  SkillNameInput,
  SkillDescInput,
  SkillDescButtonContainer,
  SkillDescButton,
} from '../styled';
import { INIT_SOFT_SKILL_ITEM } from 'features/employee/constants/common';

const SoftSkillFields = (props: any) => {
  const {
    isCanUpdate,
    indexSoftSkillItem,
    valueSoftSkillItem,
    softSkill,
    setSoftSkill,
    onEmployeeUpdateSoftSkill,
    setIsAddingSoftSkill,
  } = props;
  const [form] = Form.useForm();
  const { t } = useTranslation(['validation', 'employee']);

  const [softSkillItem, setSoftSkillItem]: any = useState(INIT_SOFT_SKILL_ITEM);

  const initData = () => {
    const initValues = valueSoftSkillItem ? valueSoftSkillItem : INIT_SOFT_SKILL_ITEM;
    return initValues;
  };

  useEffect(() => {
    const initValues = initData();
    setSoftSkillItem(initValues);
    form.setFieldsValue(initValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueSoftSkillItem, form]);

  const onSaveSoftSkill = () => {
    form.submit();
  };

  const onCancelAddSoftSkill = () => {
    setSoftSkillItem(INIT_SOFT_SKILL_ITEM);
    setIsAddingSoftSkill(false);
  };

  const onFinish = (values: any) => {
    const udpSoftSkillItem = { ...softSkillItem, ...values };
    if (valueSoftSkillItem) {
      const udpSoftSkill = [...softSkill];
      udpSoftSkill[indexSoftSkillItem] = udpSoftSkillItem;
      setSoftSkill(udpSoftSkill);
      onEmployeeUpdateSoftSkill(udpSoftSkill);
    } else {
      const newSoftSkill = [...softSkill, udpSoftSkillItem];
      setSoftSkill(newSoftSkill);
      onEmployeeUpdateSoftSkill(newSoftSkill);
    }
    setSoftSkillItem(INIT_SOFT_SKILL_ITEM);
    setIsAddingSoftSkill(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    Modal.error({
      title: t('modal:message_invalid'),
    });
  };

  return (
    <Form
      form={form}
      id="SoftSkillForm"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={softSkillItem}
    >
      <Row gutter={[16, 16]}>
        <Col md={16} xs={24}>
          {isCanUpdate && (
            <Row align="middle" gutter={8}>
              <Col md={24} xs={24}>
                <Form.Item
                  required={true}
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: `${t('HRM_VAD_REQUIRED', { field: 'Soft skill name' })}`,
                    },
                    {
                      max: 250,
                      message: `${t('HRM_VAD_MAXIMUM_CHARACTERS', {
                        field: 'Soft skill name',
                        numberLeast: 250,
                      })}`,
                    },
                    {
                      whitespace: true,
                      message: `${t('HRM_VAD_BLANK_CHARACTERS', { field: 'Soft skill name' })}`,
                    },
                  ]}
                >
                  <SkillNameInput
                    placeholder={t('employee:skill_information_fields.enter_your_skill')}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          <Form.Item
            name="description"
            rules={[
              {
                required: true,
                message: `${t('HRM_VAD_REQUIRED', { field: 'Description' })}`,
              },
              {
                max: 250,
                message: `${t('HRM_VAD_MAXIMUM_CHARACTERS', {
                  field: 'Description',
                  numberLeast: 250,
                })}`,
              },
            ]}
          >
            <SkillDescInput
              placeholder={t('employee:skill_information_fields.description_your_skill')}
            />
          </Form.Item>
          {isCanUpdate && (
            <SkillDescButtonContainer>
              <SkillDescButton
                onClick={onSaveSoftSkill}
                icon={<Icon component={() => <img src={IconSuccess} alt="" />} />}
              ></SkillDescButton>
              <SkillDescButton
                onClick={onCancelAddSoftSkill}
                icon={<Icon component={() => <img src={IconCancel} alt="" />} />}
              ></SkillDescButton>
            </SkillDescButtonContainer>
          )}
        </Col>
        <Col md={8} xs={24}></Col>
      </Row>
    </Form>
  );
};

export default SoftSkillFields;

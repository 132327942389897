// My page
export const PERMISSION_PROFILE_ACCESS_PERSONAL = {
  PROFILE_ACCESS_PERSONAL: 'PROFILE_ACCESS_PERSONAL',
  PROFILE_VIEW_PERSONAL: 'PROFILE_VIEW_PERSONAL',
  PROFILE_PERSONAL_EDIT: 'PROFILE_PERSONAL_EDIT',
};
export const PERMISSION_TIMESHEET_ACCESS_PERSONAL = {
  TIMESHEET_ACCESS_PERSONAL: 'TIMESHEET_ACCESS_PERSONAL',
  TIMESHEET_VIEW_PERSONAL: 'TIMESHEET_VIEW_PERSONAL',
  TIMESHEET_VIEW_TIMECLOCK: 'TIMESHEET_VIEW_TIMECLOCK',
  TIMESHEET_VIEW_OVERTIME: 'TIMESHEET_VIEW_OVERTIME',
  TIMESHEET_VIEW_TIMEOFF: 'TIMESHEET_VIEW_TIMEOFF',
  TIMESHEET_ADJUST_TIMECLOCK: 'TIMESHEET_ADJUST_TIMECLOCK',
  TIMESHEET_OVERTIME_REQUEST: 'TIMESHEET_OVERTIME_REQUEST',
  TIMESHEET_TIMEOFF_REQUEST: 'TIMESHEET_TIMEOFF_REQUEST',
  TIMESHEET_CONFIRM: 'TIMESHEET_CONFIRM',
  TIMESHEET_CANCEL_ADJUST_TIMECLOCK: 'TIMESHEET_CANCEL_ADJUST_TIMECLOCK',
  TIMESHEET_CANCEL_OVERTIME: 'TIMESHEET_CANCEL_OVERTIME',
  TIMESHEET_CANCEL_TIMEOFF: 'TIMESHEET_CANCEL_TIMEOFF',
};
export const PERMISSION_PAYSLIP_ACCESS_PERSONAL = 'PAYSLIP_ACCESS_PERSONAL';
export const PERMISSION_TIMEKEEPING_ACCESS_PERSONAL = 'TIMEKEEPING_ACCESS_PERSONAL';
export const PERMISSION_ONBOARD_ACCESS_PERSONNEL = {
  ONBOARD_ACCESS_PERSONNEL: 'ONBOARD_ACCESS_PERSONNEL',
  ONBOARD_CONDUCT_TASK: 'ONBOARD_CONDUCT_TASK',
  ONBOARD_SELF_EVALUATE: 'ONBOARD_SELF_EVALUATE',
};
export const PERMISSION_OFFBOARD_ACCESS_PERSONNEL = {
  OFFBOARD_ACCESS_PERSONNEL: 'OFFBOARD_ACCESS_PERSONNEL',
  OFFBOARD_CREATE_REQUEST: 'OFFBOARD_CREATE_REQUEST',
  OFFBOARD_CANCEL_REQUEST: 'OFFBOARD_CANCEL_REQUEST',
  OFFBOARD_HANDOVER_JOB: 'OFFBOARD_HANDOVER_JOB',
};
export const PERMISSION_DEVICE_ACCESS_PERSONNEL = {
  DEVICE_ACCESS_PERSONNEL: 'DEVICE_ACCESS_PERSONNEL',
  DEVICE_RECEIVE_EQUIPMENT: 'DEVICE_RECEIVE_EQUIPMENT',
  DEVICE_RETURN_EQUIPMENT: 'DEVICE_RETURN_EQUIPMENT',
};
export const PERMISSION_LEAVE_OFF_ACCESS_PERSONAL = 'LEAVE_OFF_ACCESS_PERSONAL';

// Dashboard
export const PERMISSION_DASHBOARD_ACCESS = {
  DASHBOARD_ACCESS: 'DASHBOARD_ACCESS',
  DASHBOARD_ACCESS_HR: 'DASHBOARD_ACCESS_HR',
  DASHBOARD_ACCESS_MANAGER: 'DASHBOARD_ACCESS_MANAGER',
};

// Timesheet
export const PERMISSION_TIMESHEET_ACCESS_OBSERVATION_LIST = {
  TIMESHEET_ACCESS_OBSERVATION_LIST: 'TIMESHEET_ACCESS_OBSERVATION_LIST',
  TIMESHEET_VIEW_OBSERVATION: 'TIMESHEET_VIEW_OBSERVATION',
  TIMESHEET_OBSERVATION_VIEW_OVERTIME: 'TIMESHEET_OBSERVATION_VIEW_OVERTIME',
  TIMESHEET_OBSERVATION_VIEW_TIMEOFF: 'TIMESHEET_OBSERVATION_VIEW_TIMEOFF',
};
export const PERMISSION_TIMESHEET_REQUEST_APPROVAL = {
  TIMESHEET_REQUEST_APPROVAL: 'TIMESHEET_REQUEST_APPROVAL',
  TIMESHEET_VIEW_LIST_REQUEST: 'TIMESHEET_VIEW_LIST_REQUEST',
  TIMESHEET_OVERVIEW_LIST_REQUEST: 'TIMESHEET_OVERVIEW_LIST_REQUEST',
  TIMESHEET_ADJUST_TIMECLOCK_APPROVAL: 'TIMESHEET_ADJUST_TIMECLOCK_APPROVAL',
  TIMESHEET_OVERTIME_APPROVAL: 'TIMESHEET_OVERTIME_APPROVAL',
  TIMESHEET_TIMEOFF_APPROVAL: 'TIMESHEET_TIMEOFF_APPROVAL',
  TIMESHEET_REQUEST_PENDING: 'REQUEST_PENDING',
};
export const PERMISSION_TIMESHEET_MONTH_APPROVAL = 'TIMESHEET_MONTH_APPROVAL';
export const PERMISSION_TIMESHEET_MANAGE_EMPLOYEE = {
  TIMESHEET_MANAGE_EMPLOYEE: 'TIMESHEET_MANAGE_EMPLOYEE',
  TIMESHEET_VIEW_EMPLOYEE: 'TIMESHEET_VIEW_EMPLOYEE',
  TIMESHEET_EXPORT: 'TIMESHEET_EXPORT',
};
export const PERMISSION_TIMESHEET_RESET = 'TIMESHEET_RESET';
export const PERMISSION_TIMESHEET_SELF_APPROVE = 'TIMESHEET_SELF_APPROVE';

// Personnel
export const PERMISSION_PERSONNEL_MANAGE = {
  PERSONNEL_MANAGE: 'PERSONNEL_MANAGE',
  PERSONNEL_VIEW_LIST: 'PERSONNEL_VIEW_LIST',
  PERSONNEL_ADD_RECORD: 'PERSONNEL_ADD_RECORD',
  PERSONNEL_VIEW_RECORD: 'PERSONNEL_VIEW_RECORD',
  PERSONNEL_UPDATE_RECORD: 'PERSONNEL_UPDATE_RECORD',
  PERSONNEL_EXPORT: 'PERSONNEL_EXPORT',
};
export const PERMISSION_PERSONNEL_RECORD_REQUEST_APPROVAL = 'PERSONNEL_RECORD_REQUEST_APPROVAL';
export const PERMISSION_PERSONNEL_RECORD_APPROVAL = 'PERSONNEL_RECORD_APPROVAL';
export const PERMISSION_PERSONNEL_RECORD_ACTIVE_DEACTIVE = 'PERSONNEL_RECORD_ACTIVE_DEACTIVE';

// Watching list
export const PERMISSION_WATCHINGLIST_MANAGE = {
  WATCHINGLIST_MANAGE: 'WATCHINGLIST_MANAGE',
  WATCHINGLIST_VIEW: 'WATCHINGLIST_VIEW',
  WATCHINGLIST_ADD_EMPLOYEE: 'WATCHINGLIST_ADD_EMPLOYEE',
  WATCHINGLIST_REMOVE_EMPLOYEE: 'WATCHINGLIST_REMOVE_EMPLOYEE',
  WATCHINGLIST_EMPLOYEE_DETAIL: 'WATCHINGLIST_EMPLOYEE_DETAIL',
  WATCHINGLIST_MANAGER_REQUEST_LIST_VIEW: 'WATCHINGLIST_MANAGER_REQUEST_LIST_VIEW',
  WATCHINGLIST_HR_REQUEST_LIST_VIEW: 'WATCHINGLIST_HR_REQUEST_LIST_VIEW',
};

// Onboard
export const PERMISSION_ONBOARD_MANAGE_EMPLOYEE = {
  ONBOARD_MANAGE_EMPLOYEE: 'ONBOARD_MANAGE_EMPLOYEE',
  ONBOARD_LIST: 'ONBOARD_LIST',
  ONBOARD_EMPLOYEE_PROCESS: 'ONBOARD_EMPLOYEE_PROCESS',
};
export const PERMISSION_ONBOARD_MANAGE_PROCESS = {
  ONBOARD_MANAGE_PROCESS: 'ONBOARD_MANAGE_PROCESS',
  ONBOARD_HANDOVER_EQUIPMENT: 'ONBOARD_HANDOVER_EQUIPMENT',
  ONBOARD_MANAGE_TASK: 'ONBOARD_MANAGE_TASK',
  ONBOARD_EVALUATE: 'ONBOARD_EVALUATE',
  PERFORMANCE_MANAGE: 'PERFORMANCE_MANAGE',
  SETUP_GOAL: 'SETUP_GOAL',
};
export const PERMISSION_EMAIL_TEMPLATE_MANAGE = {
  EMAIL_TEMPLATE_MANAGE: 'EMAIL_TEMPLATE_MANAGE',
  EMAIL_TEMPLATE_VIEW: 'EMAIL_TEMPLATE_VIEW',
  EMAIL_TEMPLATE_ADD: 'EMAIL_TEMPLATE_ADD',
  EMAIL_TEMPLATE_UPDATE: 'EMAIL_TEMPLATE_UPDATE',
  EMAIL_TEMPLATE_DELETE: 'EMAIL_TEMPLATE_DELETE',
  EMAIL_TEMPLATE_SEND: 'EMAIL_TEMPLATE_SEND',
};

// Offboard
export const PERMISSION_OFFBOARD_MANAGE_EMPLOYEE = {
  OFFBOARD_MANAGE_EMPLOYEE: 'OFFBOARD_MANAGE_EMPLOYEE',
  OFFBOARD_LIST: 'OFFBOARD_LIST',
  OFFBOARD_EMPLOYEE_PROCESS: 'OFFBOARD_EMPLOYEE_PROCESS',
};
export const PERMISSION_OFFBOARD_MANAGE_PROCESS = {
  OFFBOARD_MANAGE_PROCESS: 'OFFBOARD_MANAGE_PROCESS',
  OFFBOARD_REQUEST_APPROVAL: 'OFFBOARD_REQUEST_APPROVAL',
  OFFBOARD_REQUEST_APPROVAL_FIRST_CONFIRM: 'OFFBOARD_REQUEST_APPROVAL_FIRST_CONFIRM',
  OFFBOARD_REQUEST_APPROVAL_SECOND_CONFIRM: 'OFFBOARD_REQUEST_APPROVAL_SECOND_CONFIRM',
  OFFBOARD_HANDOVER_EQUIPMENT: 'OFFBOARD_HANDOVER_EQUIPMENT',
  OFFBOARD_HANDOVER_JOB: 'OFFBOARD_HANDOVER_JOB',
  OFFBOARD_HANDOVER_JOB_CONFIRM: 'OFFBOARD_HANDOVER_JOB_CONFIRM',
  OFFBOARD_EXIST_INTERVIEW: 'OFFBOARD_EXIST_INTERVIEW',
  OFFBOARD_EXIST_INTERVIEW_INVITE: 'OFFBOARD_EXIST_INTERVIEW_INVITE',
};

// Device
export const PERMISSION_DEVICE_INVENTORY = {
  DEVICE_INVENTORY: 'DEVICE_INVENTORY',
};
export const PERMISSION_DEVICE_MANAGE = {
  DEVICE_MANAGE: 'DEVICE_MANAGE',
  DEVICE_HANDOVER: 'DEVICE_HANDOVER',
  DEVICE_RETRIEVE: 'DEVICE_RETRIEVE',
};

// Configuration
export const PERMISSION_CATEGORY_MANAGE = {
  CATEGORY_MANAGE: 'CATEGORY_MANAGE',
  CATEGORY_ADD: 'CATEGORY_ADD',
  CATEGORY_UPDATE: 'CATEGORY_UPDATE',
  CATEGORY_DELETE: 'CATEGORY_DELETE',
};

export const PERMISSION_GENERAL_MANAGE = {
  GENERAL_MANAGE: 'GENERAL_MANAGE',
};

// Leave off
export const PERMISSION_LEAVE_OFF_MANAGE = {
  LEAVE_OFF_MANAGE_ACCESS: 'LEAVE_OFF_MANAGE_ACCESS',
};

// Project
export const PERMISSION_PROJECT_MANAGE = {
  PROJECT_CREATE: 'PROJECT_CREATE',
  PROJECT_VIEW: 'PROJECT_VIEW',
  PROJECT_UPDATE: 'PROJECT_UPDATE',
  PROJECT_DELETE: 'PROJECT_DELETE',
};

//Overtime
export const PERMISSION_OVERTIME_MANAGE = {
  OVERTIME_PLAN_CREATE: 'OVERTIME_PLAN_CREATE',
  OVERTIME_PLAN_UPDATE: 'OVERTIME_PLAN_UPDATE',
  OVERTIME_PLAN_VIEW: 'OVERTIME_PLAN_VIEW',
  OVERTIME_PLAN_DELETE: 'OVERTIME_PLAN_DELETE',
};

export const PERMISSION_DEPARTMENT_MANAGE = {
  DEPARTMENT_MANAGE: 'DEPARTMENT_MANAGE',
  DEPARTMENT_VIEW: 'DEPARTMENT_VIEW',
  DEPARTMENT_UPDATE: 'DEPARTMENT_UPDATE',
  DEPARTMENT_DELETE: 'DEPARTMENT_DELETE',
};
export const PERMISSION_POSITION_MANAGE = {
  POSITION_MANAGE: 'POSITION_MANAGE',
  POSITION_VIEW: 'POSITION_VIEW',
  POSITION_UPDATE: 'POSITION_UPDATE',
  POSITION_DELETE: 'POSITION_DELETE',
};
export const PERMISSION_PERMISSION_MANAGE = {
  PERMISSION_MANAGE: 'PERMISSION_MANAGE',
  PERMISSION_VIEW_LIST: 'PERMISSION_VIEW_LIST',
  PERMISSION_UPDATE_ROLE: 'PERMISSION_UPDATE_ROLE',
};
export const PERMISSION_WORKSHIFT_MANAGE = {
  WORKSHIFT_MANAGE: 'WORKSHIFT_MANAGE',
};
export const SCHEDULE_INCREASE_HR = {
  SCHEDULE_INCREASE_DECREASE: 'SCHEDULE_INCREASE/DECREASE',
  SCHEDULE_INCREASE_DECREASE_LIST: 'SCHEDULE_INCREASE/DECREASE_LIST',
  SCHEDULE_INCREASE_DECREASE_CREATE: 'SCHEDULE_INCREASE/DECREASE_CREATE',
  SCHEDULE_INCREASE_DECREASE_EDIT: 'SCHEDULE_INCREASE/DECREASE_EDIT',
  SCHEDULE_INCREASE_DECREASE_SEND_REQUEST: 'SCHEDULE_INCREASE/DECREASE_SEND_REQUEST',
  SCHEDULE_INCREASE_DECREASE_DELETE: 'SCHEDULE_INCREASE/DECREASE_DELETE',
  SCHEDULE_INCREASE_DECREASE_EXPORT: 'SCHEDULE_INCREASE/DECREASE_EXPORT',
};
export const SCHEDULE_INCREASE_APPROVAL_HR = {
  APPROVAL: 'INSURANCE_APPROVAL',
  APPROVAL_LIST_VIEW: 'INSURANCE_APPROVAL_LIST_VIEW',
  APPROVAL_APPROVE_REJECT_REQUEST: 'INSURANCE_APPROVAL_APPROVE/REJECT_REQUEST',
  APPROVAL_DELETE: 'INSURANCE_APPROVAL_DELETE',
};
export const SCHEDULE_INCREASE_PARTICIPANT_LIST = {
  PARTICIPANT_LIST: 'INSURANCE_PARTICIPANTS_LIST',
  PARTICIPANT_LIST_VIEW: 'INSURANCE_PARTICIPANTS_LIST_VIEW',
  PARTICIPANT_DETAIL_VIEW: 'INSURANCE_PARTICIPANT_DETAIL_VIEW',
  PARTICIPANT_LIST_EXPORT: 'INSURANCE_PARTICIPANT_LIST_EXPORT',
};
export const SCHEDULE_INCREASE_RECORD_PAYMENT = {
  RECORD_PAYMENT: 'RECORD_INSURANCE_PAYMENT',
  RECORD_PAYMENT_VIEW: 'RECORD_INSURANCE_PAYMENT_VIEW',
  RECORD_PAYMENT_EXPORT: 'RECORD_INSURANCE_PAYMENT_EXPORT',
  RECORD_PAYMENT_CLOSE: 'RECORD_INSURANCE_PAYMENT_CLOSE',
};
export const COMPULSORY_INSURANCE_HR = {
  COMPULSORY_INSURANCE_SETTING: 'COMPULSORY_INSURANCE_SETTING',
  COMPULSORY_INSURANCE_SETTING_UPDATE: 'COMPULSORY_INSURANCE_SETTING_UPDATE',
  COMPULSORY_INSURANCE_SETTING_VIEW: 'COMPULSORY_INSURANCE_SETTING_VIEW',
  COMPULSORY_INSURANCE_VIEW_HISTORY: 'COMPULSORY_INSURANCE_VIEW_HISTORY',
};

export const PERMISSION_WORKFLOW = {
  SETUP_WORKFLOW: 'SETUP_WORKFLOW',
};

export const PERMISSION_PAYROLL = {
  SETUP_PAYROLL: 'SETUP_PAYROLL',
};

export const PERMISSION_LIST = {
  PERMISSION_PROFILE_ACCESS_PERSONAL: 'Access profile',
  [PERMISSION_TIMESHEET_ACCESS_PERSONAL.TIMESHEET_ACCESS_PERSONAL]: 'Access timesheet',
  [PERMISSION_PAYSLIP_ACCESS_PERSONAL]: 'Access payslip',
  [PERMISSION_TIMEKEEPING_ACCESS_PERSONAL]: 'Access timekeeping',
  [PERMISSION_TIMESHEET_ACCESS_OBSERVATION_LIST.TIMESHEET_ACCESS_OBSERVATION_LIST]:
    'Access watching list',
  [PERMISSION_TIMESHEET_REQUEST_APPROVAL.TIMESHEET_REQUEST_APPROVAL]: 'Request approval',
  [PERMISSION_TIMESHEET_REQUEST_APPROVAL.TIMESHEET_REQUEST_PENDING]: 'Request pending',
  [PERMISSION_TIMESHEET_MONTH_APPROVAL]: 'Timesheet approval',
  [PERMISSION_TIMESHEET_MANAGE_EMPLOYEE.TIMESHEET_MANAGE_EMPLOYEE]: 'Manage employee timesheet',
  [PERMISSION_DEPARTMENT_MANAGE.DEPARTMENT_MANAGE]: 'Manage Department',
  [PERMISSION_POSITION_MANAGE.POSITION_MANAGE]: 'Manage position',
  [PERMISSION_PERMISSION_MANAGE.PERMISSION_MANAGE]: 'Manage permission',
  [PERMISSION_PERSONNEL_MANAGE.PERSONNEL_MANAGE]: 'Manage employee',
  [PERMISSION_PERSONNEL_RECORD_REQUEST_APPROVAL]: 'Employee send request approval',
  [PERMISSION_PERSONNEL_RECORD_APPROVAL]: 'Employee record approval',
  [PERMISSION_PERSONNEL_RECORD_ACTIVE_DEACTIVE]: 'Employee active/deactive',
  [PERMISSION_WATCHINGLIST_MANAGE.WATCHINGLIST_MANAGE]: 'Access watching list',
  [PERMISSION_CATEGORY_MANAGE.CATEGORY_MANAGE]: 'Manage category',
};

import { Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const ContentCell = (props: any) => {
  const { t } = useTranslation(['performance']);
  const {
    rating,
    content,
    setValue,
    nameRating,
    nameContent,
    disabled = false,
    editParent,
  } = props;

  const [inputValue, setInputValue] = useState(String(rating));

  const handleChange = (e: any) => {
    let value = Number(e.target.value);

    if (!Number.isInteger(value)) {
      value = Math.round(value);
    }

    if (value > 5) {
      value = 5;
    }
    if (value < 0) {
      value = 0;
    }

    setInputValue(String(value));
    setValue(nameRating, String(value));
    setValue(editParent, true);
  };

  return (
    <div>
      <div>{t('performance:rating_point')}</div>
      <Input
        type="number"
        disabled={disabled}
        defaultValue={rating}
        value={inputValue}
        onChange={handleChange}
      />
      <div>{t('performance:accomplishment')}</div>
      <TextArea
        disabled={disabled}
        defaultValue={content}
        onChange={(e) => {
          setValue(nameContent, e.target.value);
          setValue(editParent, true);
        }}
      />
    </div>
  );
};

export default ContentCell;

import { Popover } from 'antd';
import { DateTag } from './Calenda_title';

type TCellCheckinOut = {
  children: React.ReactNode;
  onVisibleChange: (visible: boolean) => void;
  visible: boolean;
  content: React.ReactNode;
  isFullTime: boolean;
  requestAdjustTime: any;
};

export function CellCheckinOut(props: TCellCheckinOut) {
  const { children, content, isFullTime, onVisibleChange, visible, requestAdjustTime } = props;
  return (
    <Popover
      trigger="click"
      overlayClassName="itv-popover"
      onOpenChange={onVisibleChange}
      open={visible}
      content={content}
    >
      <DateTag
        style={{
          marginTop: 10,
          color: isFullTime === true ? 'black' : isFullTime === false ? 'black' : '#99ccff',
        }}
        type="adjust"
        workType={isFullTime === true ? 'FT' : isFullTime === false ? 'PT' : undefined}
        status={requestAdjustTime ? requestAdjustTime.status : 'working'}
        onClick={(e: any) => e.stopPropagation()}
      >
        {children}
      </DateTag>
    </Popover>
  );
}

import { notificationToast } from 'components/notificationToast';
import { Notification } from 'constants/notification';
import moment from 'moment';

export const fomatgetTime = (data: any) => {
  return new Date(data).getTime();
};

export const fomatetoUTCString = (data: any) => {
  return new Date(data).toUTCString();
};

export const fomatFromYYHH = (data: any, value: any) => {
  return moment(data).format(value);
};

export const formatDate = (value: any) => {
  let month = '' + value.months();
  let year = value.year();

  if (month.length < 2) month = '0' + month;
  return [year, month].join('-');
};
export const getNewDate = () => {
  const getday = new Date();
  return getday;
};
export const getNewDateT = () => {
  const getday = new Date();
  const str = getday.getMonth() + '/' + getday.getFullYear();
  return str;
};
export const formatHH = (value: any) => {
  let month = '' + value.months();
  let year = value.year();

  if (month.length < 2) month = '0' + month;
  return [year, month].join('-');
};

export const formatHoursMinutes = (value: any) => {
  let d = new Date(value);
  let ishours = '' + d.getUTCHours();
  let isminutes = '' + d.getUTCMinutes();
  if (ishours.length < 2) ishours = '0' + ishours;
  if (isminutes.length < 2) isminutes = '0' + isminutes;
  return [ishours, isminutes].join(':');
};

export const formatUT_YYYY_MM_DD = (value: any) => {
  let d = new Date(value);
  let setmoth = d.getUTCMonth() + 1;
  let ismonth = '' + setmoth;
  let isfullyear = '' + d.getUTCFullYear();
  let isday = '' + d.getUTCDate();

  if (isday.length < 2) isday = '0' + isday;
  if (ismonth.length < 2) ismonth = '0' + ismonth;
  return [isday, ismonth, isfullyear].join('/');
};

export const getTimeCheckin = (is_hh: any, is_mm: any) => {
  let ishours = '' + is_hh;
  let isminutes = '' + is_mm;
  if (ishours.length < 2) ishours = '0' + ishours;
  if (isminutes.length < 2) isminutes = '0' + isminutes;
  return [ishours, isminutes].join(':');
};

export const setAllNotyfiSuccess = (ad: any) => {
  notificationToast(Notification.Type.Success, ad, Notification.Duration._5s);
};

export const setAllNotyfiError = (ad: any) => {
  notificationToast(Notification.Type.Error, ad, Notification.Duration._5s);
};

import { Card, Collapse, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const { Panel } = Collapse;

export default function UserGuide() {
  const { t } = useTranslation(['userguide']);

  return (
    <>
      <CustomDivider orientation="left">{t('overview')}</CustomDivider>
      <p>• {t('overview_text1')}.</p>
      <p>• {t('overview_text2')}.</p>
      <p>• {t('overview_text3')}.</p>
      <p>• {t('overview_text4')}.</p>

      <CustomDivider orientation="left">{t('user_guide')}</CustomDivider>
      <Collapse>
        <Panel header={t('tag_name')} key="1">
          <p>
            <strong>{t('purpose')}: </strong>
            {t('purpose_text_tagname')}.
          </p>
          <p>
            <strong>{t('where')}: </strong> {t('where_text_tagname')}.
          </p>
          <p>
            <strong>{t('dependence')}: </strong>
            {t('dependence_text_tagname')}.
          </p>
          <Card title={t('available')} size="small">
            <p>
              <b>@Creator:</b> {t('available_text1_tagname')}.
            </p>
            <p>
              <b>@Direct Manager:</b> {t('available_text2_tagname')}.
            </p>
            <p>
              <b>@Project Lead:</b> {t('available_text3_tagname')}.
            </p>
            <p>
              <b>@Requester:</b> {t('available_text4_tagname')}.
            </p>
            <p>
              <b>@All Staff:</b> {t('available_all_staff_tagname')}.
            </p>
            <p>
              <b>@Informer:</b> {t('available_inform_tagname')}.
            </p>
          </Card>
          <p>
            <i>{t('case_tagname')}.</i>
          </p>
        </Panel>
      </Collapse>

      <Collapse>
        <Panel header={t('variable')} key="2">
          <p>
            <strong>{t('purpose')}: </strong>
            {t('purpose_text_variable')}.
          </p>
          <p>
            <strong>{t('where')}: </strong> {t('where_text_variable')}.
          </p>
          <p>
            <strong>{t('dependence')}: </strong>
            {t('dependence_text_variable')}.
          </p>
          <Card title={t('available')} size="small">
            <p>
              <b>Current Time:</b> {t('available_momentTime_variable')}.
            </p>
            <p>
              <b>Full Name:</b> {t('available_fullname_variable')}.
            </p>
            <p>
              <b>Full Name Western:</b> {t('available_fullname_west_variable')}.
            </p>
            <p>
              <b>First Name:</b> {t('available_firstname_variable')}.
            </p>
            <p>
              <b>Last Name:</b> {t('available_lastname_variable')}.
            </p>
            <p>
              <b>Email:</b> {t('available_email_variable')}.
            </p>
            <p>
              <b>Phone Number:</b> {t('available_phone_variable')}.
            </p>
            <p>
              <b>Role Name:</b> {t('available_role_variable')}.
            </p>
            <p>
              <b>Department Name:</b> {t('available_department_variable')}.
            </p>
            <p>
              <b>Position Name:</b> {t('available_position_variable')}.
            </p>
            <p>
              <b>Timezone:</b> {t('available_timezone_variable')}.
            </p>
            <p>
              <b>Permanent Address:</b> {t('available_permanent_variable')}.
            </p>
            <p>
              <b>Residential Address:</b> {t('available_residential_variable')}.
            </p>
            <p>
              <b>Requester Full Name:</b> {t('available_fullname_req_variable')}.
            </p>
            <p>
              <b>Requester Full Name Western::</b> {t('available_fullname_west_req_variable')}.
            </p>
            <p>
              <b>Requester First Name:</b> {t('available_firstname_req_variable')}.
            </p>
            <p>
              <b>Requester Last Name:</b> {t('available_lastname_req_variable')}.
            </p>
            <p>
              <b>Approval Full Name:</b> {t('available_fullname_apr_variable')}.
            </p>
            <p>
              <b>Approval Full Name Western:</b> {t('available_fullname_west_apr_variable')}.
            </p>
            <p>
              <b>Approval First Name:</b> {t('available_firstname_apr_variable')}.
            </p>
            <p>
              <b>Approval Last Name:</b> {t('available_lastname_apr_variable')}.
            </p>
            <p>
              <b>Date Request:</b> {t('available_datereq_variable')}.
            </p>
            <p>
              <b>Start Time:</b> {t('available_starttime_variable')}.
            </p>
            <p>
              <b>End Time:</b> {t('available_endtime_variable')}.
            </p>
            <p>
              <b>Reason Rejected:</b> {t('available_reason_reject_variable')}.
            </p>
            <p>
              <b>Month Year User Confirm:</b> {t('available_monthyear_variable')}.
            </p>
            <p>
              <b>Adjust Time Reason:</b> {t('available_adj_reason_variable')}.
            </p>
            <p>
              <b>Adjust Time Total Hours:</b> {t('available_adj_hours_variable')}.
            </p>
            <p>
              <b>Adjust Time Original Check In:</b> {t('available_adj_starttime_variable')}.
            </p>
            <p>
              <b>Adjust Time Original Check Out:</b> {t('available_adj_endtime_variable')}.
            </p>
            <p>
              <b>Adjust Time Original Total Hours:</b> {t('available_adj_hours_ori_variable')}.
            </p>
            <p>
              <b>Over Time Reason:</b> {t('available_ot_reason_variable')}.
            </p>
            <p>
              <b>Over Time Total Hours:</b> {t('available_ot_hours_variable')}.
            </p>
            <p>
              <b>Leave Off Reason:</b> {t('available_leave_reason_variable')}.
            </p>
            <p>
              <b>Leave Off Total Hours:</b> {t('available_leave_hours_variable')}.
            </p>
          </Card>
          <p>
            <i>{t('case_variable')}.</i>
          </p>
        </Panel>
      </Collapse>
    </>
  );
}

const CustomDivider = styled(Divider)`
  .ant-divider-inner-text {
    font-weight: bold;
  }
`;

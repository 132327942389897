export const findParentNode = (child: Record<string, any>, parentId: string): any => {
  if (child.employeeId === parentId) {
    return child;
  } else if (child.children) {
    let result = null;

    for (let i = 0; result === null && i < child.children.length; i++) {
      result = findParentNode(child.children[i], parentId);
    }

    return result;
  }

  return null;
};

import React, { useState, useEffect } from 'react';
import { Card, Col, DatePicker, Input, Row, Select, Upload, Button } from 'antd';
import { useController } from 'react-hook-form';
import styled from 'styled-components';
import TextArea from 'antd/es/input/TextArea';
import { DeleteFilled, InboxOutlined } from '@ant-design/icons';
import { InputField, TextAreaField } from 'components/FormFields';
import PageHeader from 'components/PageHeader';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const StyledContainer = styled.div`
  .row {
    margin-bottom: 16px;
  }

  .label {
    text-align: left;
    padding-right: 8px;
    flex: 1 0 30px;
  }

  .input,
  .select,
  .textarea,
  .datepicker {
    flex: 2;
  }

  .select .ant-select {
    width: 100%;
  }

  .textarea .ant-input {
    width: 100%;
    min-height: 120px;
  }

  .datepicker .ant-picker {
    width: 100%;
  }

  .status .ant-select {
    width: 100%;
    min-height: 110px;
    display: flex;
    align-items: center;
  }
`;

const CourseContent = ({ control, callBack, getValues }) => {
  const { id } = useParams();
  const [fileList, setFileList] = useState([]);
  const { t } = useTranslation(['trainningManagement']);

  useEffect(() => {
    const learningMaterial = getValues('learningMaterial');
    if (learningMaterial && learningMaterial.length > 0) {
      const files = learningMaterial.map((material, index) => {
        const blob = new Blob([material.buffer], { type: 'application/pdf' });
        return {
          uid: index,
          name: material.fileName,
          status: 'done',
          url: material?.fileUrl,
        };
      });
      setFileList(files);
    }
  }, [getValues('learningMaterial')]);

  useEffect(() => {
    const blobs = fileList.map((file) => file.originFileObj);
    callBack && callBack(blobs);
  }, [fileList, callBack]);

  const handleUpload = (info) => {
    callBack(info.fileList);
    setFileList(
      info.fileList.map((file) => ({
        uid: file.uid,
        name: file.name,
        status: file.status,
        url: file.url || URL.createObjectURL(file.originFileObj),
      })),
    );
  };

  const handleRemove = (file) => {
    const newFileList = fileList.filter((item) => item.uid !== file.uid);
    setFileList(newFileList);
    callBack(newFileList);
  };

  return (
    <StyledContainer>
      {/* <PageHeader title={'Course content'} /> */}
      <Card
        title={t('trainningManagement:course_management.course_content')}
        bordered={false}
        headStyle={{
          fontSize: 24,
          fontWeight: 500,
        }}
      >
        <Row gutter={[100, 16]}>
          <Col span={18}>
            <Row className="row" align="middle">
              <Col className="label">
                {t('trainningManagement:course_management.what_you_learn')}
              </Col>
              <Col className="input">
                <InputField disabled={true} control={control} name="contentLessonName" />
              </Col>
            </Row>
            <Row className="row" align="middle">
              <Col className="label">
                {t('trainningManagement:course_management.learning_material')}
              </Col>
              <Col className="select">
                {fileList.length > 0 ? (
                  <div style={{ marginTop: 16 }}>
                    {fileList.map((file) => (
                      <div
                        key={file.uid}
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          marginBottom: 8,
                        }}
                      >
                        <a href={file.url} target="_blank" rel="noopener noreferrer">
                          {file.name}
                        </a>
                        {!id && <DeleteFilled onClick={() => handleRemove(file)} />}
                      </div>
                    ))}
                  </div>
                ) : (
                  <Upload.Dragger
                    fileList={fileList}
                    onChange={handleUpload}
                    onRemove={handleRemove}
                    showUploadList={false}
                    multiple={true}
                    disabled={id}
                  >
                    {fileList.length === 0 && (
                      <div>
                        <p className="ant-upload-drag-icon">
                          <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">
                          {t(
                            'trainningManagement:course_management.click_or_drag_file_to_this_area_to_upload',
                          )}
                        </p>
                      </div>
                    )}
                  </Upload.Dragger>
                )}
              </Col>
            </Row>
            <Row className="row" align="middle">
              <Col className="label">{t('trainningManagement:course_management.learning_url')}</Col>
              <Col className="input">
                {/* <InputField disabled={true} control={control} name="contentLearningUrl" /> */}
                <a target="_blank" href={getValues('contentLearningUrl')}>
                  {getValues('contentLearningUrl')}
                </a>
              </Col>
            </Row>
            <Row className="row" align="middle">
              <Col className="label">
                {t('trainningManagement:course_management.learning_result')}
              </Col>
              <Col className="input">
                <InputField disabled={true} control={control} name="users[0].result" />
              </Col>
            </Row>
          </Col>
          <Col span={12}></Col>
        </Row>
      </Card>
    </StyledContainer>
  );
};

export default CourseContent;

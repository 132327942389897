import { createGlobalStyle } from 'styled-components';
import borders from './variables/borders';
import texts from './variables/texts';

interface ThemProps {
  colors: { [index: string]: string };
}

const GlobalStyles = createGlobalStyle<ThemProps>`
  html {
    --cl_primary: ${(props) => props.colors.cl_primary};
    --cl_primary_dark: ${(props) => props.colors.cl_primary_dark};
    --cl_primary_light: ${(props) => props.colors.cl_primary_light};
    --cl_border_primary: ${(props) => props.colors.cl_border_primary};
    --cl_text_primary: ${(props) => props.colors.cl_text_primary};
    --cl_text_second: ${(props) => props.colors.cl_text_second};
    --cl_white: ${(props) => props.colors.cl_white};
    --cl_grey: ${(props) => props.colors.cl_grey};
    --cl_shadow_2: ${(props) => props.colors.cl_shadow_2};
    --cl_shadow_3: ${(props) => props.colors.cl_shadow_3};
    --cl_primary050: ${(props) => props.colors.cl_primary050};
    --cl_primary100: ${(props) => props.colors.cl_primary100};
    --cl_primary200: ${(props) => props.colors.cl_primary200};
    --cl_primary300: ${(props) => props.colors.cl_primary300};
    --cl_primary400: ${(props) => props.colors.cl_primary400};
    --cl_primary500: ${(props) => props.colors.cl_primary500};
    --cl_primary600: ${(props) => props.colors.cl_primary600};
    --cl_primary700: ${(props) => props.colors.cl_primary700};
    --cl_primary800: ${(props) => props.colors.cl_primary800};
    --cl_primary900: ${(props) => props.colors.cl_primary900};
    --cl_gray050: ${(props) => props.colors.cl_gray050};
    --cl_gray100: ${(props) => props.colors.cl_gray100};
    --cl_gray200: ${(props) => props.colors.cl_gray200};
    --cl_gray250: ${(props) => props.colors.cl_gray250};
    --cl_gray300: ${(props) => props.colors.cl_gray300};
    --cl_gray400: ${(props) => props.colors.cl_gray400};
    --cl_gray500: ${(props) => props.colors.cl_gray500};
    --cl_gray600: ${(props) => props.colors.cl_gray600};
    --cl_gray700: ${(props) => props.colors.cl_gray700};
    --cl_gray800: ${(props) => props.colors.cl_gray800};
    --cl_gray900: ${(props) => props.colors.cl_gray900};
    --cl_info050: ${(props) => props.colors.cl_info050};
    --cl_info100: ${(props) => props.colors.cl_info100};
    --cl_info200: ${(props) => props.colors.cl_info200};
    --cl_info300: ${(props) => props.colors.cl_info300};
    --cl_info400: ${(props) => props.colors.cl_info400};
    --cl_info500: ${(props) => props.colors.cl_info500};
    --cl_info600: ${(props) => props.colors.cl_info600};
    --cl_info700: ${(props) => props.colors.cl_info700};
    --cl_info800: ${(props) => props.colors.cl_info800};
    --cl_info900: ${(props) => props.colors.cl_info900};
    --cl_success050: ${(props) => props.colors.cl_success050};
    --cl_success100: ${(props) => props.colors.cl_success100};
    --cl_success200: ${(props) => props.colors.cl_success200};
    --cl_success300: ${(props) => props.colors.cl_success300};
    --cl_success400: ${(props) => props.colors.cl_success400};
    --cl_success500: ${(props) => props.colors.cl_success500};
    --cl_success600: ${(props) => props.colors.cl_success600};
    --cl_success700: ${(props) => props.colors.cl_success700};
    --cl_success800: ${(props) => props.colors.cl_success800};
    --cl_success900: ${(props) => props.colors.cl_success900};

    --cl_success_notify100: ${(props) => props.colors.cl_success_notify100};
    --cl_success_notify200: ${(props) => props.colors.cl_success_notify200};

    --cl_error050: ${(props) => props.colors.cl_error050};
    --cl_error100: ${(props) => props.colors.cl_error100};
    --cl_error200: ${(props) => props.colors.cl_error200};
    --cl_error300: ${(props) => props.colors.cl_error300};
    --cl_error400: ${(props) => props.colors.cl_error400};
    --cl_error500: ${(props) => props.colors.cl_error500};
    --cl_error600: ${(props) => props.colors.cl_error600};
    --cl_error700: ${(props) => props.colors.cl_error700};
    --cl_error800: ${(props) => props.colors.cl_error800};
    --cl_error900: ${(props) => props.colors.cl_error900};
    --cl_warning050: ${(props) => props.colors.cl_warning050};
    --cl_warning100: ${(props) => props.colors.cl_warning100};
    --cl_warning200: ${(props) => props.colors.cl_warning200};
    --cl_warning300: ${(props) => props.colors.cl_warning300};
    --cl_warning400: ${(props) => props.colors.cl_warning400};
    --cl_warning500: ${(props) => props.colors.cl_warning500};
    --cl_warning600: ${(props) => props.colors.cl_warning600};
    --cl_warning700: ${(props) => props.colors.cl_warning700};
    --cl_warning800: ${(props) => props.colors.cl_warning800};
    --cl_warning900: ${(props) => props.colors.cl_warning900};

    --cl_neutral100: ${(props) => props.colors.cl_neutral100};
    --cl_neutral200: ${(props) => props.colors.cl_neutral200};
    --cl_neutral300: ${(props) => props.colors.cl_neutral300};
    --cl_neutral500: ${(props) => props.colors.cl_neutral500};
    --cl_neutral600: ${(props) => props.colors.cl_neutral600};
    --cl_neutral700: ${(props) => props.colors.cl_neutral700};
    --cl_neutral900: ${(props) => props.colors.cl_neutral900};

    --cl_secondary500: ${(props) => props.colors.cl_secondary500};

    --bd_radius_default: ${borders.bd_radius_default};
    --bd_radius_small: ${borders.bd_radius_small};
    --bd_radius_large: ${borders.bd_radius_large};

    --txt_font_family: ${texts.txt_font_family};
    --txt_font_weight_medium: ${texts.txt_font_weight_medium};
    --txt_font_weight_regular: ${texts.txt_font_weight_regular};
    --txt_font_size_h1: ${texts.txt_font_size_h1};
    --txt_font_size_h2: ${texts.txt_font_size_h2};
    --txt_font_size_h3: ${texts.txt_font_size_h3};
    --txt_font_size_body: ${texts.txt_font_size_body};
    --txt_font_size_caption: ${texts.txt_font_size_caption};
  }
`;

export default GlobalStyles;

import React from 'react';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { COLOR_AVATAR_USER } from 'constants/commons';

const AvatarUser = (props: any) => {
  const { url, firstName, lastName, size, icon, fontSize, fullName } = props;
  const chooseColorBg =
    (lastName && lastName.toString().length % 2) || (firstName && firstName.toString().length % 3);
  return (
    <>
      {!url && !firstName && !lastName && (
        <Avatar icon={icon ? icon : <UserOutlined />} size={size} />
      )}
      {url && <Avatar src={url} size={size}></Avatar>}
      {(firstName || lastName) && (
        <Avatar
          size={size}
          style={{
            fontSize: `${fontSize}`,
            backgroundColor: `${COLOR_AVATAR_USER[chooseColorBg]}`,
          }}
        >
          {fullName
            ? fullName.split(' ').splice(-2)[0].toString().charAt(0).toUpperCase() +
              '' +
              fullName.split(' ').splice(-1)[0].toString().charAt(0).toUpperCase()
            : lastName.toString().charAt(0).toUpperCase() +
              '' +
              firstName.toString().charAt(0).toUpperCase()}
        </Avatar>
      )}
    </>
  );
};

export default AvatarUser;

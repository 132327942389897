import styled from 'styled-components';

export const TitleColumnCenter = styled.div`
  display: flex;
  justify-content: center;
`;

export const TitleColumnLeft = styled.div`
  text-align: left;
`;
export const TitleColumnRight = styled.div`
  text-align: right;
`;

export const customPanelManager = (editor) => {
  const pnm = editor?.Panels;
  if (pnm) {
    const cmdPanel = pnm.getPanel('options');
    var cmdBtns = cmdPanel.get('buttons');
    cmdBtns.add([
      {
        id: 'undo',
        className: 'fa fa-undo',
        command: 'undo',
        attributes: { title: 'Undo' },
      },
      {
        id: 'redo',
        className: 'fa fa-repeat',
        command: 'redo',
        attributes: { title: 'Redo' },
      },
      {
        id: 'canvas-clear',
        className: 'fa fa-trash',
        command: 'canvas-clear',
        attributes: { title: 'Canvas Clear' },
      },
    ]);
  }
};

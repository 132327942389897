import { Skeleton } from 'antd';
import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useTranslation } from 'react-i18next';
import { getDepartmentList } from 'features/department/departmentSlice';
import { getEmployeeRecordHistory } from 'features/employee/employeeAction';
import { selectEmployee } from 'features/employee/employeeSlice';
import { removeAccents } from 'utils/text';
import { PROFILE_STATUSES } from 'constants/profiles';
import { formatDate, formatDateTime } from 'utils/date';
import ITVTable from 'components/ITVTable';
import EmployeeColumn from 'features/employee/components/EmployeeColumn';
import Functions from 'features/employee/components/Functions';
import { TitleColumnCenter } from 'styles/tableStyled';
import { RecordHistoryContainer, StatusColumn } from './styled';

const filterDefault = {
  sortBy: 'status',
  orderBy: 'asc',
};

const RecordHistory = (props: any) => {
  const { itemId } = props;
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['employee']);
  const stateEmployee = useAppSelector(selectEmployee);
  const employeeRecordHistory = stateEmployee.employeeRecordHistory;
  const isLoading = stateEmployee.isGettingHistory;

  const [total, setTotal] = useState(0);
  const [resultTotal, setResultTotal] = useState(0);
  const [widthTable, setWidthTable] = useState(500);
  const [dataHistory, setDataHistory] = useState<any>([]);
  const [dataRoot, setDataRoot] = useState<any>([]);

  const [searchingList, setSearchingList] = useState(false);

  useEffect(() => {
    dispatch(getEmployeeRecordHistory({ idItem: itemId }));
    dispatch(
      getDepartmentList({
        filters: filterDefault,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (employeeRecordHistory) {
      const dataRecordHistory = employeeRecordHistory?.map((item: any) => {
        return {
          ...item.valueChange,
          ordinalNumber: item.id,
          changeByFirstName: item?.editedByUser?.firstName,
          changeByLastName: item?.editedByUser?.lastName,
          changeByFullName: item?.editedByUser?.fullName,
          changeByEmail: item?.editedByUser?.email,
          changeDate: item.changeDate ? formatDateTime(item.changeDate) : null,
        };
      });

      setDataHistory(dataRecordHistory);
      setDataRoot(dataRecordHistory);
      setTotal(stateEmployee.totalRecordHistory);
      setResultTotal(stateEmployee.totalRecordHistory);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeRecordHistory]);

  let COLUMNS = [
    {
      title: () => {
        return (
          <TitleColumnCenter>{t('employee:employee_information_fields.no')}</TitleColumnCenter>
        );
      },
      dataIndex: 'ordinalNumber',
      key: 'ordinalNumber',
      ellipsis: true,
      width: 60,
      minWidth: 60,
    },
    {
      title: () => {
        return (
          <TitleColumnCenter>
            {t('employee:employee_information_fields.change_date')}
          </TitleColumnCenter>
        );
      },
      dataIndex: 'changeDate',
      key: 'changeDate',
      width: 180,
      minWidth: 180,
      sorter: {
        compare: (a: any, b: any) => {
          if (a.changeDate && b.changeDate) return a.changeDate?.localeCompare(b.changeDate);
          return 0;
        },
        multiple: 3,
      },
      //format date in BE
    },
    {
      title: () => {
        return (
          <TitleColumnCenter>
            {t('employee:employee_information_fields.record_status')}
          </TitleColumnCenter>
        );
      },
      key: 'status',
      width: 150,
      minWidth: 150,
      ellipsis: true,
      sorter: {
        compare: (a: any, b: any) => {
          if (a.status && b.status) return a.status?.localeCompare(b.status);
          return 0;
        },
        multiple: 3,
      },
      render: function (value: any, record: any, index: any) {
        return (
          <StatusColumn status={value.status}>• {PROFILE_STATUSES[value.status]}</StatusColumn>
        );
      },
    },
    {
      title: () => {
        return (
          <TitleColumnCenter>
            {t('employee:employee_information_fields.department_team')}
          </TitleColumnCenter>
        );
      },
      dataIndex: 'departmentName',
      key: 'departmentName',
      ellipsis: true,
      width: 150,
      minWidth: 150,
      sorter: {
        compare: (a: any, b: any) => {
          if (a.departmentName && b.departmentName)
            return a.departmentName.localeCompare(b.departmentName);
          return 0;
        },
        multiple: 3,
      },
    },
    {
      title: () => {
        return (
          <TitleColumnCenter>
            {t('employee:employee_information_fields.position')}
          </TitleColumnCenter>
        );
      },
      dataIndex: 'positionName',
      key: 'positionName',
      width: 200,
      minWidth: 200,
      ellipsis: true,
      sorter: {
        compare: (a: any, b: any) => {
          if (a.positionName && b.positionName)
            return a.positionName?.localeCompare(b.positionName);
          return 0;
        },
        multiple: 3,
      },
    },
    {
      title: () => {
        return (
          <TitleColumnCenter>
            {t('employee:employee_information_fields.onboard_status')}
          </TitleColumnCenter>
        );
      },
      dataIndex: 'onBoardStatusValue',
      key: 'onBoardStatusValue',
      ellipsis: true,
      width: 150,
      minWidth: 150,
      sorter: {
        compare: (a: any, b: any) => {
          if (a.onBoardStatusValue && b.onBoardStatusValue)
            return a.onBoardStatusValue?.localeCompare(b.onBoardStatusValue);
          return 0;
        },
        multiple: 3,
      },
    },
    {
      title: () => {
        return (
          <TitleColumnCenter>
            {t('employee:employee_information_fields.direct_manager')}
          </TitleColumnCenter>
        );
      },
      dataIndex: 'managerName',
      key: 'managerName',
      ellipsis: true,
      width: 180,
      minWidth: 180,
      sorter: {
        compare: (a: any, b: any) => {
          if (a.managerName && b.managerName) return a.managerName?.localeCompare(b.managerName);
          return 0;
        },
        multiple: 3,
      },
      render: function (value: any, record: any, index: any) {
        return record?.managers[0]?.fullName || '';
      },
    },
    {
      title: () => {
        return (
          <TitleColumnCenter>
            {t('employee:personnel_information_fields.permanent_address')}
          </TitleColumnCenter>
        );
      },
      dataIndex: 'permanentAddress',
      key: 'permanentAddress',
      ellipsis: true,
      width: 200,
      minWidth: 200,
      sorter: {
        compare: (a: any, b: any) => {
          if (a.permanentAddress && b.permanentAddress)
            return a.permanentAddress?.localeCompare(b.permanentAddress);
          return 0;
        },
        multiple: 3,
      },
    },
    {
      title: () => {
        return (
          <TitleColumnCenter>
            {t('employee:employee_information_fields.change_by')}
          </TitleColumnCenter>
        );
      },
      dataIndex: 'changeBy',
      key: 'changeBy',
      width: 250,
      minWidth: 250,
      ellipsis: true,
      render: function (text: any, record: any, index: any) {
        return (
          <EmployeeColumn
            avatar={record?.changeByFullName}
            fullName={record?.changeByFullName}
            email={record?.changeByEmail}
          />
        );
      },
      sorter: {
        compare: (a: any, b: any) => {
          if (a.changeByFullName && b.changeByFullName)
            return a.changeByFullName?.localeCompare(b.changeByFullName);
          return 0;
        },
        multiple: 3,
      },
    },
  ];

  const [columns, setColumns] = useState(COLUMNS);

  const onSearchList = (key: string) => {
    setSearchingList(true);
    const text = removeAccents(key.toLowerCase());
    if (text) {
      const temp = dataRoot.filter((item: any) => {
        return (
          removeAccents(item.changeByFirstName?.toLowerCase()).includes(text) ||
          removeAccents(item.changeByLastName?.toLowerCase()).includes(text)
        );
      });
      setResultTotal(temp.length);
      setDataHistory(temp);
    } else {
      setDataHistory(dataRoot);
      setResultTotal(dataRoot?.length);
    }
    setSearchingList(false);
  };

  const addColumns = (cols: any) => {
    const dataIndexs = COLUMNS.map((item) => item.dataIndex);
    const arr = columns.filter((item) => dataIndexs.includes(item.dataIndex));

    const temp = cols.map((item: any) => {
      if (item.value === 'startDate') {
        return {
          title: item.label,
          dataIndex: item.value,
          key: item.value,
          ellipsis: true,
          width: 200,
          render: (startDate: any) => {
            return <div>{startDate ? formatDate(startDate, null, true) : ''}</div>;
          },
        };
      }
      if (item.value === 'endDate') {
        return {
          title: item.label,
          dataIndex: item.value,
          key: item.value,
          ellipsis: true,
          width: 200,
          render: (endDate: any) => {
            return <div>{endDate ? formatDate(endDate, null, true) : ''}</div>;
          },
        };
      }
      if (item.value === 'contractStartDate') {
        return {
          title: item.label,
          dataIndex: item.value,
          key: item.value,
          ellipsis: true,
          width: 200,
          render: (contractStartDate: any) => {
            return <div>{contractStartDate ? formatDate(contractStartDate, null, true) : ''}</div>;
          },
        };
      }
      if (item.value === 'contractExpirationDate') {
        return {
          title: item.label,
          dataIndex: item.value,
          key: item.value,
          ellipsis: true,
          width: 120,
          render: (contractExpirationDate: any) => {
            return (
              <div>
                {contractExpirationDate ? formatDate(contractExpirationDate, null, true) : ''}
              </div>
            );
          },
        };
      }
      if (item.value === 'dob') {
        return {
          title: item.label,
          dataIndex: item.value,
          key: item.value,
          ellipsis: true,
          width: 120,
          render: (dob: any) => {
            return <div>{dob ? formatDate(dob, null, true) : ''}</div>;
          },
        };
      }
      if (item.value === 'cidIssuedDate') {
        return {
          title: item.label,
          dataIndex: item.value,
          key: item.value,
          ellipsis: true,
          width: 150,
          render: (cidIssuedDate: any) => {
            return <div>{cidIssuedDate ? formatDate(cidIssuedDate, null, true) : ''}</div>;
          },
        };
      }
      if (item.value === 'relativeValue') {
        return {
          title: item.label,
          dataIndex: item.value,
          key: item.value,
          ellipsis: true,
          width: 200,
          render: (relativeValue: any) => {
            return <div>{relativeValue}</div>;
          },
        };
      }
      if (item.value === 'trainingPeriod') {
        return {
          title: item.label,
          dataIndex: item.value,
          key: item.value,
          ellipsis: true,
          width: 200,
          render: (value: any, record: any) => {
            const trainingPeriodFrom = record.trainingPeriodFrom
              ? formatDate(record.trainingPeriodFrom, null, true)
              : '';
            const trainingPeriodTo = record.trainingPeriodTo
              ? formatDate(record.trainingPeriodTo, null, true)
              : '';
            const trainingPeriod =
              trainingPeriodFrom && trainingPeriodTo
                ? `${trainingPeriodFrom} - ${trainingPeriodTo}`
                : '';
            return <div>{trainingPeriod}</div>;
          },
        };
      }
      return {
        title: item.label,
        dataIndex: item.value,
        key: item.value,
        ellipsis: true,
        width: 150,
      };
    });
    setColumns([...arr.slice(0, arr.length - 1), ...temp, arr[arr.length - 1]]);
    setWidthTable((width) => width + cols.length * 150);
  };

  return (
    <RecordHistoryContainer>
      <Functions
        isShowFull={false}
        handleSearch={(value: any) => onSearchList(value)}
        onExportClick={() => null}
        addColumns={addColumns}
        employeeFilters={null}
        onDownload={() => null}
      />
      {isLoading || searchingList ? (
        <Skeleton />
      ) : (
        <ITVTable
          columns={columns}
          data={dataHistory}
          width={widthTable}
          totalIni={total}
          totalResult={resultTotal}
          isRowSelect={false}
        />
      )}
    </RecordHistoryContainer>
  );
};

export default RecordHistory;

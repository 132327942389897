import React from 'react';
import { List } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

const RelativePeopleInformationItems = (props: any) => {
  const { employeeData, handleEditRelativeInfo, handleRemoveRelative } = props;
  return (
    <>
      <List
        itemLayout="horizontal"
        dataSource={employeeData.relative}
        renderItem={(item: any, index: number) => (
          <List.Item
            //@ts-ignore
            actions={[
              <EditOutlined key={`edit-${index}`} onClick={() => handleEditRelativeInfo(index)} />,
              <DeleteOutlined
                key={`delete-${index}`}
                onClick={() => handleRemoveRelative(index)}
              />,
            ]}
          >
            {item.relationship.value} - {item.name}
          </List.Item>
        )}
      />
    </>
  );
};

export default RelativePeopleInformationItems;

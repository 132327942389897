import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const OvertimeFooter = () => {
  const { t } = useTranslation(['timesheet']);
  return (
    <>
      <StatusFooter>
        <StatusItem>
          <StatusIcon style={{ backgroundColor: '#D1FAE5' }} />
          <span
            style={{
              marginLeft: 5,
            }}
          >{`${t('approved')}`}</span>
        </StatusItem>
        <StatusItem>
          <StatusIcon style={{ backgroundColor: '#fcc059' }} />
          <span
            style={{
              marginLeft: 5,
            }}
          >{`${t('Pending')}`}</span>
        </StatusItem>
        <StatusItem>
          <StatusIcon style={{ backgroundColor: '#EF4444' }} />
          <span
            style={{
              marginLeft: 5,
            }}
          >{`${t('Rejected')}`}</span>
        </StatusItem>
      </StatusFooter>
    </>
  );
};

export default OvertimeFooter;

export const StatusFooter = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

export const StatusItem = styled.div`
  margin-right: 40px;
  display: flex;
  align-items: center;
`;

export const StatusIcon = styled.div`
  width: 17px;
  height: 17px;
  border-radius: 4px;
  box-shadow: 0 0 4px rgb(0 0 0 / 20%);
`;

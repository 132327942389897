import { ReadOutlined } from '@ant-design/icons';
import { Card, Col, Form, message, Modal, Radio, Row, Select, Tooltip } from 'antd';
import onboardApi from 'api/onboardApi';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import Avatar from 'components/Avatar';
import { MODE } from 'constants/types';
import { filterSortEmployeeList } from 'features/employee/employeeAction';
import { selectEmployee } from 'features/employee/employeeSlice';
import {
  getDepartmentList,
  getPermissionList,
  getRoleList,
} from 'features/masterData/masterDataAction';
import { selectMasterData } from 'features/masterData/masterDataSlice';
import {
  HRMModules,
  RoleDefault,
  TemplateEmailStatus,
} from 'hrm-common/extensions/enums/personel/declare.enums';
import { GroupTemplateEmail } from 'hrm-common/extensions/interfaces/personel';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import CustomSelect from 'styles/inputSelectStyled';
import CustomInput from 'styles/inputStyled';
import { removeAccents } from 'utils/text';
import TypeUtils from 'utils/typeUtils';
import { EmployeeOnboardSendMail, ResponseTemplateEmail } from '../interfaces/templateEmail';
import { createTemplate, getListGroupEmail, getTemplates, updateTemplate } from '../onboardAction';
import { selectOnboard } from '../onboardSlice';
import EditorEmail from './EditorEmail';
import UserGuide from './UserGuide';
const { Option, OptGroup } = Select;

message.config({
  maxCount: 1,
});
interface TemplateEmailModalProps {
  newTemplateVisible: boolean;
  setNewTemplateVisible: React.Dispatch<React.SetStateAction<boolean>>;
  type: MODE;
  onlyView: boolean;
  dataTemplateEmail: any;
  listGroupEmailTemplate: Array<GroupTemplateEmail>;
}

type FormTemplateEmail = {
  templateName: string;
  subject: string;
  group: string;
  module: { label: React.ReactNode; value: string; key: string };
  status: TemplateEmailStatus;
  description: string;
  to: [];
  cc: [];
  bcc: [];
};

const TemplateEmailModal = (props: TemplateEmailModalProps) => {
  const dispatch = useAppDispatch();
  const {
    onlyView = false,
    dataTemplateEmail,
    type,
    listGroupEmailTemplate,
    newTemplateVisible,
    setNewTemplateVisible,
  } = props;
  const { t } = useTranslation(['validation', 'onboard', 'timesheet', 'employee', 'menu']);
  const titleModalEmailTemplate =
    type === MODE.ADD
      ? t('onboard:create_email_template')
      : type === MODE.EDIT
      ? t('onboard:edit_email_template')
      : t('onboard:view_email_template');

  const [form] = Form.useForm();

  const stateOnboard = useAppSelector(selectOnboard);
  const employee = useAppSelector(selectEmployee);
  const stateRole: any = useAppSelector(selectMasterData);
  const employeeList: EmployeeOnboardSendMail[] = employee?.employeeList;
  const templateList: ResponseTemplateEmail[] = stateOnboard.templates;
  const listGroupEmailRedux = stateOnboard?.listGroupEmail?.result;
  const listNameGroup = listGroupEmailTemplate.map((group) => group.name);
  const uniqueNameGroup = listNameGroup.filter(
    (name, index, array) => array.indexOf(name) === index,
  );

  const [selectedModuleName, setSelectedModuleName] = useState<string>('');
  const [isSelectedModule, setIsSelectedModule] = useState(false);
  const [listVariableEmail, setListVariableEmail] = useState([]);
  const [listGroup, setListGroup] = useState<GroupTemplateEmail[]>([]);
  const [html, setHtml] = useState('');
  const [isDefaultHtml, setIsDefaultHtml] = useState(false);
  const [, setIdGroup] = useState<string>(listGroup[0]?.name);
  const [open, setOpen] = useState(false);

  const patternBody = /<body[^>]*>((.|[\n\r])*)<\/body>/im;
  const patternBodyHtml = patternBody.exec(html);
  const visibleInnerBodyHtml = patternBodyHtml && patternBodyHtml[1];
  const searchFilterSortParam = {
    sort: [{ employeeId: 'DESC' }],
    filter: {
      departmentName: [],
      positionName: [],
      status: ['approved'],
    },
  };
  const [paramSortFilter] = useState(searchFilterSortParam);
  const [optionTO, setOptionTO] = useState<EmployeeOnboardSendMail[]>([]);
  const [optionCC, setOptionCC] = useState<EmployeeOnboardSendMail[]>([]);
  const [optionBCC, setOptionBCC] = useState<EmployeeOnboardSendMail[]>([]);
  const [emailType, setEmailType] = useState(false);
  const listDefault: any = [
    {
      id: RoleDefault.CREATOR,
      fullName: `@${RoleDefault.CREATOR}`,
    },
    {
      id: RoleDefault.DIRECT_MANAGER,
      fullName: `@${RoleDefault.DIRECT_MANAGER}`,
    },
    {
      id: RoleDefault.PROJECT_LEAD,
      fullName: `@${RoleDefault.PROJECT_LEAD}`,
    },
    {
      id: RoleDefault.REQUESTER,
      fullName: `@${RoleDefault.REQUESTER}`,
    },
    {
      id: RoleDefault.ALL_STAFF,
      fullName: `@${RoleDefault.ALL_STAFF}`,
    },
    {
      id: RoleDefault.INFORMER,
      fullName: `@${RoleDefault.INFORMER}`,
    },
  ];
  const listRole: any = [];
  const listDepartment: any = [];
  const listGroupEmail: any = [];

  useEffect(() => {
    dispatch(getRoleList());
    dispatch(getPermissionList());
    dispatch(getDepartmentList());
    dispatch(getListGroupEmail({}));
  }, [dispatch]);

  stateRole.listRole.forEach((item: any) => {
    listRole.push({
      id: item.id,
      fullName: `@${item.name}`,
    });
  });

  stateRole.listDepartment.forEach((item: any) => {
    listDepartment.push({
      id: `department_${item.id}`,
      fullName: `@Department_${item.name}`,
    });
  });

  listGroupEmailRedux?.forEach((item: any) => {
    listGroupEmail.push({
      id: `group_${item.id}`,
      fullName: item.groupName,
    });
  });

  const getVariablesEmail = async (module: string) => {
    const response = await onboardApi.getVariablesEmail(module);
    setListVariableEmail(response.data);
  };

  useEffect(() => {
    dispatch(
      filterSortEmployeeList({
        ...paramSortFilter,
      }),
    );
  }, [paramSortFilter, dispatch]);

  useEffect(() => {
    if (employeeList) {
      setOptionTO(employeeList);
      setOptionCC(employeeList);
      setOptionBCC(employeeList);
    }
  }, [employeeList]);

  useEffect(() => {
    if (selectedModuleName && type !== MODE.VIEW) getVariablesEmail(selectedModuleName);
  }, [selectedModuleName, type]);

  const handleList = (list: any, key: string) => {
    let temp: any = [];
    temp = list?.map((item: any) => {
      return `${key}_${item}`;
    });
    return temp;
  };

  useEffect(() => {
    if (dataTemplateEmail && type !== MODE.ADD) {
      form.setFieldsValue({
        templateName: dataTemplateEmail.name,
        subject: dataTemplateEmail.subject,
        group: dataTemplateEmail.group.name,
        module: dataTemplateEmail.group.module,
        // to: dataTemplateEmail.to,
        to: [
          ...handleList(dataTemplateEmail.toDepartment, 'department'),
          ...handleList(dataTemplateEmail.toInvolverGroup, 'group'),
          ...dataTemplateEmail.to,
        ],
        // cc: dataTemplateEmail.cc,
        cc: [
          ...handleList(dataTemplateEmail.ccDepartment, 'department'),
          ...handleList(dataTemplateEmail.ccInvolverGroup, 'group'),
          ...dataTemplateEmail.cc,
        ],
        // bcc: dataTemplateEmail.bcc,
        bcc: [
          ...handleList(dataTemplateEmail.bccDepartment, 'department'),
          ...handleList(dataTemplateEmail.bccInvolverGroup, 'group'),
          ...dataTemplateEmail.bcc,
        ],
        type: dataTemplateEmail.personalType ?? false,
      });
      setHtml(dataTemplateEmail.body);
      setIsDefaultHtml(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTemplateEmail, type]);

  useEffect(() => {
    let defaultGroup: GroupTemplateEmail[] = [];
    if (dataTemplateEmail && type !== MODE.ADD) {
      defaultGroup = listGroupEmailTemplate.filter(
        (item) => item.module === dataTemplateEmail.group.module,
      );
    } else {
      defaultGroup = listGroupEmailTemplate.filter(
        (item) => item.module === Object.values(HRMModules)[0],
      );
      defaultGroup && form.setFieldsValue({ module: defaultGroup[0]?.module });
      defaultGroup && form.setFieldsValue({ group: defaultGroup[0]?.name });
    }

    setListGroup(defaultGroup);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listGroupEmailTemplate, dataTemplateEmail, type]);

  useEffect(() => {
    if (listGroup.length && type !== MODE.EDIT) {
      setSelectedModuleName(listGroup[0].module);
      form.setFieldsValue({ subject: `[${listGroup[0].name}]` });
    } else if (dataTemplateEmail && type === MODE.EDIT) {
      setSelectedModuleName(dataTemplateEmail?.group.module);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listGroup, dataTemplateEmail, type]);

  const handleChangeGroup = (value: any) => {
    setIdGroup(value);
    form.setFieldsValue({
      subject: `[${value}]`,
    });
  };

  const handleChangeModule = (value: any) => {
    setSelectedModuleName(value.value);
    setIsSelectedModule(true);
    const filterGroupWithModule = listGroupEmailTemplate.filter(
      (group) => group.module === value.value,
    );
    setListGroup(filterGroupWithModule);
    form.setFieldsValue({ group: filterGroupWithModule[0]?.name });
  };

  const handleChangeSampleTemplate = (value: any) => {
    const selectTemplate = templateList && templateList.find((item) => item.id === value);
    selectTemplate && setHtml(selectTemplate.body);
    setIsDefaultHtml(true);
  };

  const handleListId = (value: any, type: string) => {
    let temp: any = [];
    let listId: any = [];
    temp = value.filter((item: any) => {
      return TypeUtils.isString(item) ? item.includes(type) : null;
    });
    temp.map((item: any) => {
      const temp = item.split('_');
      return listId.push(parseInt(temp[1]));
    });
    return listId;
  };

  const onFinish = (values: FormTemplateEmail) => {
    const group = listGroup.filter((item) => item.name === form.getFieldValue('group'));
    // ITemplateMail
    let data: any = {
      id: dataTemplateEmail?.id,
      name: values.templateName,
      subject: values.subject,
      body: html,
      descriptions: values.subject,
      to: values.to
        .filter((item) => (TypeUtils.isString(item) ? !item.includes('department_') : null))
        .filter((item) => (TypeUtils.isString(item) ? !item.includes('group_') : null)),
      toRole: values.to.filter((item) => TypeUtils.isNumber(item)),
      toGroup: handleListId(values.to, 'group_'),
      toDepartment: handleListId(values.to, 'department_'),
      cc: values.cc
        .filter((item) => (TypeUtils.isString(item) ? !item.includes('department_') : null))
        .filter((item) => (TypeUtils.isString(item) ? !item.includes('group_') : null)),
      ccRole: values.cc.filter((item) => TypeUtils.isNumber(item)),
      ccDepartment: handleListId(values.cc, 'department_'),
      ccGroup: handleListId(values.cc, 'group_'),
      bcc: values.bcc
        .filter((item) => (TypeUtils.isString(item) ? !item.includes('department_') : null))
        .filter((item) => (TypeUtils.isString(item) ? !item.includes('group_') : null)),
      bccRole: values.bcc.filter((item) => TypeUtils.isNumber(item)),
      bccDepartment: handleListId(values.bcc, 'department_'),
      bccGroup: handleListId(values.bcc, 'group_'),
      personalType: emailType,
      attachment: [''],
      linkModule: values.subject,
      groupId: group[0].id,
    };

    if (type === MODE.ADD) {
      dispatch(
        createTemplate({
          data,
          callback: () => {
            setNewTemplateVisible(false);
            getTemplates({});
            form.resetFields();
          },
        }),
      );
    } else {
      dispatch(
        updateTemplate({
          data,
          callback: () => {
            setNewTemplateVisible(false);
            getTemplates({});
          },
        }),
      );
    }
  };

  const showDrawer = () => {
    setOpen(!open);
  };

  const GroupSelect = (label: string, list: any) => {
    return (
      <OptGroup label={label}>
        {list?.map((item: any) => {
          return (
            <Select.Option key={item.email ?? item.id} value={item.id} name={item.fullName}>
              <Row>
                <div style={{ marginTop: '5px', marginRight: '5px' }}>
                  <Avatar name={label === t('timesheet:Employee') ? item.fullName : '@'} />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  <div
                    data-id="title"
                    style={{
                      fontWeight: 'bold',
                      fontSize: 14,
                    }}
                  >
                    {item.fullName}
                  </div>
                  <div
                    data-id="sub-title"
                    style={{
                      color: '#9CA3AF',
                      fontSize: 12,
                    }}
                  >
                    {label === t('timesheet:Employee') ? item.email : ''}
                  </div>
                </div>
              </Row>
            </Select.Option>
          );
        })}
      </OptGroup>
    );
  };

  return (
    <>
      <Modal
        style={{ position: 'relative', marginRight: open ? '30%' : '' }}
        cancelText={t('timesheet:Cancel')}
        okText={t('timesheet:Save')}
        title={titleModalEmailTemplate}
        visible={newTemplateVisible}
        width={1000}
        destroyOnClose={true}
        maskClosable={false}
        onCancel={() => setNewTemplateVisible(false)}
        onOk={() => {
          form
            .validateFields()
            .then((values: FormTemplateEmail) => {
              if (!visibleInnerBodyHtml) {
                return message.error(t('onboard:check_input_editor'));
              }
              onFinish(values);
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}
        okButtonProps={{ hidden: onlyView }}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            group: uniqueNameGroup[0] ? uniqueNameGroup[0] : undefined,
            status: true,
          }}
        >
          <Manual onClick={showDrawer} style={{ display: onlyView ? 'none' : '' }}>
            <div style={{ border: '1px solid black', borderRadius: '50%', padding: '0 5px' }}>
              <Tooltip title={t('userguide:user_guide')} placement="left">
                <ReadOutlined />
              </Tooltip>
            </div>
          </Manual>
          {/* Form Input */}
          <CustomRow justify="space-around" gutter={[48, 48]}>
            <Col span={8}>
              <Form.Item
                label={t('onboard:LabelFormEmail.module')}
                name="module"
                initialValue={HRMModules.TIME_SHEET}
                rules={[
                  {
                    required: true,
                    message: t('HRM_VAD_REQUIRED', {
                      field: t('onboard:LabelFormEmail.module'),
                    }),
                  },
                ]}
              >
                <CustomSelect
                  placeholder={t('onboard:form_group.please_select_module')}
                  disabled={onlyView}
                  onChange={handleChangeModule}
                  labelInValue
                >
                  {Object.values(HRMModules).map((modules, key) => (
                    <Option key={key} value={modules}>
                      {modules}
                    </Option>
                  ))}
                </CustomSelect>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label={t('onboard:LabelFormEmail.group')}
                name="group"
                initialValue={listGroup[0]?.name}
                rules={[
                  {
                    required: true,
                    message: t('HRM_VAD_REQUIRED', {
                      field: t('onboard:LabelFormEmail.group'),
                    }),
                  },
                ]}
              >
                <CustomSelect
                  placeholder={t('onboard:LabelFormEmail.please_select_group')}
                  disabled={onlyView}
                  onChange={handleChangeGroup}
                >
                  {listGroup &&
                    listGroup.map((item) => (
                      <Option key={item.id} value={item.name}>
                        {item.name}
                      </Option>
                    ))}
                </CustomSelect>
              </Form.Item>
            </Col>
          </CustomRow>

          <CustomRow justify="space-around" gutter={[48, 48]}>
            <Col span={8}>
              <Form.Item
                label={t('onboard:LabelFormEmail.template_name')}
                name="templateName"
                rules={[
                  {
                    required: true,
                    message: t('HRM_VAD_REQUIRED', {
                      field: t('onboard:LabelFormEmail.template_name'),
                    }),
                  },
                ]}
              >
                <CustomInput
                  type="text"
                  placeholder={t('onboard:please_input_template_name')}
                  disabled={onlyView}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={t('onboard:LabelFormEmail.subject_name')}
                name="subject"
                rules={[
                  {
                    required: true,
                    message: t('HRM_VAD_REQUIRED', {
                      field: t('onboard:LabelFormEmail.subject_name'),
                    }),
                  },
                ]}
              >
                <CustomInput
                  type="text"
                  placeholder={t('onboard:no_input_over_65_characters')}
                  disabled={onlyView}
                />
              </Form.Item>
            </Col>
          </CustomRow>

          <CustomRow justify="space-around" gutter={[48, 48]}>
            <Col span={8}>
              <Form.Item label={t('onboard:LabelFormEmail.sample_template')} name="sample_template">
                <CustomSelect
                  placeholder={t('onboard:LabelFormEmail.sample_template')}
                  disabled={onlyView}
                  onSelect={handleChangeSampleTemplate}
                >
                  {templateList &&
                    templateList.map((template) => (
                      <Option value={template.id} key={template.id}>
                        {template.name}
                      </Option>
                    ))}
                </CustomSelect>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={t('onboard:mail_type')} name="type" initialValue={false}>
                <Radio.Group
                  onChange={(e: any) => setEmailType(e.target.value)}
                  disabled={onlyView}
                >
                  <Radio value={false}>{t('onboard:work_email')}</Radio>
                  <Radio value={true}>{t('onboard:personal_email')}</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </CustomRow>

          <CustomRow justify="space-around" gutter={[48, 48]}>
            <Col span={20}>
              <Form.Item
                label={t('onboard:LabelFormEmail.to')}
                name="to"
                initialValue={[]}
                rules={[
                  {
                    required: true,
                    message: t('HRM_VAD_REQUIRED', {
                      field: t('onboard:LabelFormEmail.to'),
                    }),
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  allowClear
                  placeholder={t('onboard:please_select')}
                  showSearch
                  filterOption={(input: any, option: any) => {
                    return (
                      removeAccents(option.name?.toLowerCase())?.indexOf(
                        removeAccents(input.toLowerCase()),
                      ) >= 0 ||
                      removeAccents(option.key?.toLowerCase())?.indexOf(
                        removeAccents(input.toLowerCase()),
                      ) >= 0
                    );
                  }}
                  disabled={onlyView}
                  optionLabelProp="name"
                >
                  {GroupSelect(t('timesheet:default_role'), listDefault)}
                  {GroupSelect(t('timesheet:role'), listRole)}
                  {GroupSelect(t('timesheet:department'), listDepartment)}
                  {GroupSelect(t('menu:configuration_group_email'), listGroupEmail)}
                  {GroupSelect(t('timesheet:Employee'), optionTO)}
                </Select>
              </Form.Item>
            </Col>
          </CustomRow>

          <CustomRow justify="space-around" gutter={[48, 48]}>
            <Col span={20}>
              <Form.Item label="Cc" name="cc" initialValue={[]}>
                <Select
                  mode="multiple"
                  allowClear
                  placeholder={t('onboard:please_select')}
                  filterOption={(input: any, option: any) => {
                    return (
                      removeAccents(option.name?.toLowerCase())?.indexOf(
                        removeAccents(input.toLowerCase()),
                      ) >= 0 ||
                      removeAccents(option.key?.toLowerCase())?.indexOf(
                        removeAccents(input.toLowerCase()),
                      ) >= 0
                    );
                  }}
                  disabled={onlyView}
                  optionLabelProp="name"
                >
                  {GroupSelect(t('timesheet:default_role'), listDefault)}
                  {GroupSelect(t('timesheet:role'), listRole)}
                  {GroupSelect(t('timesheet:department'), listDepartment)}
                  {GroupSelect(t('menu:configuration_group_email'), listGroupEmail)}
                  {GroupSelect(t('timesheet:Employee'), optionCC)}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8} style={{ display: 'none' }}>
              <Form.Item label="Bcc" name="bcc" initialValue={[]}>
                <Select
                  mode="multiple"
                  allowClear
                  placeholder={t('onboard:please_select')}
                  filterOption={(input: any, option: any) => {
                    return (
                      removeAccents(option.name?.toLowerCase())?.indexOf(
                        removeAccents(input.toLowerCase()),
                      ) >= 0 ||
                      removeAccents(option.key?.toLowerCase())?.indexOf(
                        removeAccents(input.toLowerCase()),
                      ) >= 0
                    );
                  }}
                  disabled={onlyView}
                  optionLabelProp="name"
                >
                  {GroupSelect(t('timesheet:default_role'), listDefault)}
                  {GroupSelect(t('timesheet:role'), listRole)}
                  {GroupSelect(t('timesheet:department'), listDepartment)}
                  {GroupSelect(t('menu:configuration_group_email'), listGroupEmail)}
                  {GroupSelect(t('timesheet:Employee'), optionBCC)}
                </Select>
              </Form.Item>
            </Col>
          </CustomRow>

          {/* Editor */}
          <CustomRow gutter={[10, 0]}>
            <Col span={24}>
              <EditorEmail
                listVariableEmail={listVariableEmail}
                setHtml={setHtml}
                html={html}
                isDefaultHtml={isDefaultHtml}
                onlyView={onlyView}
                isSelectedModule={isSelectedModule}
              />
            </Col>
          </CustomRow>
        </Form>
        {open && (
          <div style={{ position: 'absolute', top: 0, left: '100%' }}>
            <Card style={{ width: 350, maxHeight: 1070, overflow: 'auto' }}>
              <UserGuide />
            </Card>
          </div>
        )}
      </Modal>
    </>
  );
};

export default TemplateEmailModal;

export const TitleEmailTemplate = styled.h3`
  text-transform: uppercase;
  font-weight: bold;
`;

export const CustomRow = styled(Row)`
  .ant-form-item {
    margin-bottom: 10px;
  }
`;

const Manual = styled.div`
  font-size: 20px;
  cursor: pointer;
  margin-right: 15px;
  display: flex;
  justify-content: flex-end;
`;

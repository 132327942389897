import i18next from 'i18next';
import { REGEX_NUMBER } from 'constants/regex';

const validateUpdateEmployeeId = (_UpdateEmployeeIdData: any) => {
  let messageValidate: Array<string> = [];

  if (!_UpdateEmployeeIdData?.employeeIdNew) {
    messageValidate.push(
      `${i18next.t('validation:required', {
        field: i18next.t('employee:employee_update_id.employee_id_new'),
      })}`,
    );
  } else {
    const pattern = new RegExp(REGEX_NUMBER);
    const isValid = pattern.test(_UpdateEmployeeIdData?.employeeIdNew);
    if (!isValid) {
      messageValidate.push(
        `${i18next.t('validation:is_valid', {
          field: i18next
            .t('employee:employee_update_id.employee_id_new')
            ?.toString()
            ?.toLowerCase(),
        })}`,
      );
    }
    if (_UpdateEmployeeIdData?.employeeIdNew === _UpdateEmployeeIdData?.employeeIdOld) {
      messageValidate.push(
        `${i18next.t('validation:equal_old_value', {
          field: i18next.t('employee:employee_update_id.employee_id_new'),
        })}`,
      );
    }
  }
  return messageValidate;
};

export default validateUpdateEmployeeId;

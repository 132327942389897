import { Form, Input, InputNumber, Popconfirm } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useAppSelector } from 'app/hooks';
import { actions, selectConfiguration } from 'features/configuration/configurationSlice';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import CustomButton from 'styles/buttonStyled';

enum MODE {
  VIEW = 'VIEW',
  ADD = 'ADD',
  UPDATE = 'UPDATE',
}
const ViewAction = (props: any) => {
  const { current, onSave, mode } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation(['validation', 'masterConfig', 'insurance', 'common']);

  const dataStore = useAppSelector(selectConfiguration);
  const { setErrorMessage } = actions;
  const error = dataStore.error;

  useEffect(() => {
    if (error?.fieldErrors) {
      form.setFields([
        {
          name: 'name',
          errors: error?.fieldErrors?.name,
        },
        {
          name: 'description',
          errors: error?.fieldErrors?.description,
        },
      ]);
    }
    return () => {
      dispatch(setErrorMessage(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (mode === MODE.UPDATE && current) {
      form.setFieldsValue({
        actionId: current?.actionId,
        name: current?.name,
        module: current?.module,
        description: current?.description,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current]);

  const onFinish = () => {
    form.validateFields().then((values) => {
      if (mode === MODE.UPDATE) {
        onSave({
          ...values,
        });
      }
    });
  };

  return (
    <Form form={form} layout="vertical">
      <Form.Item name={'module'} label={t('onboard:LabelFormEmail.module')}>
        <Input maxLength={50} disabled />
      </Form.Item>

      <Form.Item
        name={'actionId'}
        label={<div>{t('onboard:name')}</div>}
        rules={[
          {
            required: true,
            message: t('HRM_VAD_REQUIRED', {
              field: t('onboard:name'),
            }),
          },
          {
            whitespace: true,
            message: t('HRM_VAD_BLANK_CHARACTERS', {
              field: t('onboard:name'),
            }),
          },
        ]}
        style={{ marginTop: '2%', display: 'none' }}
      >
        <Input maxLength={50} />
      </Form.Item>

      <Form.Item
        name={'name'}
        label={<div>{t('onboard:name')}</div>}
        rules={[
          {
            required: true,
            message: t('HRM_VAD_REQUIRED', {
              field: t('onboard:name'),
            }),
          },
          {
            whitespace: true,
            message: t('HRM_VAD_BLANK_CHARACTERS', {
              field: t('onboard:name'),
            }),
          },
        ]}
        style={{ marginTop: '2%' }}
      >
        <Input maxLength={50} />
      </Form.Item>

      <Form.Item
        name={'description'}
        label={<div>{t('onboard:description')}</div>}
        rules={[
          {
            whitespace: true,
            message: t('HRM_VAD_BLANK_CHARACTERS', {
              field: t('employee:adjustment_information_fields.description'),
            }),
          },
        ]}
        style={{ marginTop: '2%' }}
      >
        <TextArea rows={4} />
      </Form.Item>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: '2%' }}>
        <Popconfirm
          title={`${t('insurance:Are_you_sure_confirm_this_request')}`}
          onConfirm={() => {
            onFinish();
          }}
          okText={`${t('insurance:Yes')}`}
          cancelText={`${t('insurance:no')}`}
        >
          <CustomButton>{t('masterConfig:action.save')}</CustomButton>
        </Popconfirm>
      </div>
    </Form>
  );
};

export default ViewAction;

export const CustomInputNumber = styled(InputNumber)`
  .ant-input-number-input {
    text-align: right;
    // padding-right:1px;
  }
  .ant-input-number-handler-wrap {
    padding-right: 5px;
  }
  .ant-input-number-handler-wrap {
    display: none;
  }
`;

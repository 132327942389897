import Icon, { DownOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, DatePicker, Dropdown, Input, Menu, Modal, Popconfirm, Popover } from 'antd';
import payrollApi from 'api/payrollApi';
import { selectFormatDate } from 'app/commonRedux/appSlice';
import { useAppSelector } from 'app/hooks';
import { colors } from 'assets/colors';
import IconAdd from 'assets/icons/ic_add_gray.svg';
import IconExport from 'assets/icons/ic_export_gray.svg';
import { ReasonForm } from 'components/Forms';
import { notificationToast } from 'components/notificationToast';
import { MONTH_YEAR_FORMAT } from 'constants/commons';
import { Notification } from 'constants/notification';
import { selectUser } from 'features/auth/authSlice';
import { PermissionType } from 'hrm-common/extensions/enums/personel';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import { default as ITVButton } from 'styles/buttonStyled';
import { Reason } from 'types';
import { getErrorMessage } from 'utils/getErrorMessage';
import { labelKeyNameView } from '../constants/common';
import {
  getCreateAuto,
  getExportParticipant,
  getExportPaymentByYear,
  getExportRecordPayment,
  listApprovedInsurance,
  postExportPdf,
  scheduleAdjust,
} from '../insuranceAction';
import { selectInsurance } from '../insuranceSlice';
import AppActionView from './AppActionView';
import MedalAddColumn from './MedalAddColumn';
import ModalCreate from './ModalCreate';
import ModalSendConfirmInsurance from './ModalSendConfirmInsurance';

const { RangePicker } = DatePicker;
const { Search } = Input;

export const CustomIcon = styled(Icon)`
  ${(props) =>
    props.type === 'search' &&
    css`
      img {
        height: 15px;
        width: 15px;
      }
    `}
  ${(props) =>
    props.type === 'add' &&
    css`
      img {
        padding-bottom: 6px;
        height: 15px;
        width: 15px;
      }
    `}
  ${(props) =>
    props.type === 'export' &&
    css`
      img {
        padding-bottom: 6px;
        height: 20px;
        width: 16px;
      }
    `}
`;

export enum InsuranceExport {
  PARTICIPANT = 'parcitipant',
  SCHEDULE_INCREASE = 'schedule_increase',
  SCHEDULE_DECREASE = 'schedule_decrease',
  SCHEDULE_APPROVAL = 'schedule_approval',
  RECORD_PAYMENT = 'record_payment',
  RECORD_PAYMENT_BY_YEAR = 'record_payment_by_year',
}

const IconAddComponents = (
  <CustomIcon type={'add'} component={() => <img src={IconAdd} alt="" />} />
);
const IconExportComponents = (
  <CustomIcon type={'export'} component={() => <img src={IconExport} alt="" />} />
);

const FunctionInsurance = (props: any) => {
  const {
    handleSearch,
    viewMode,
    submitText,
    handleSubmit,
    selectedUser,
    permissions,
    checkview,
    activekey,
    dateIncrease,
    dateDecrease,
    dateApproval,
    dateRecord,
    datePart,
    data = [],
  }: any = props;
  const insurance = useAppSelector(selectInsurance);
  const dataRecordPay = insurance?.dataRecordPayment;

  const [isVisibleAddColumn, setIsVisibleAddColumn] = useState(false);
  const childRefAddColumn = useRef<any>();
  const dispatch = useDispatch();
  const { t }: any = useTranslation(['insurance', 'timesheet', 'common', 'action', 'payroll']);
  const [, setIsVisibleInsuranceClose] = useState(false);
  const [isVisibleInsuranceCreate, setIsVisibleInsuranceCreate] = useState(false);
  const [is_checkid, setIsCheckID] = useState(false);
  const [textSearch, setTextSearch] = useState<any>();
  const [, setListUser] = useState<any[]>();
  const dateFormat = useAppSelector(selectFormatDate);
  const [listAddColumn, setListAddColumn] = useState([]);
  const [opennModalConfirm, setOpennModalConfirm] = useState(false);
  const [isLoadingConfirm, setIsLoadingConfirm] = useState(false);

  const handleCloseInsuranceCreate = () => {
    setIsVisibleInsuranceCreate(false);
    setIsVisibleInsuranceClose(false);
  };

  useEffect(() => {
    if (activekey) {
      setTextSearch('');
    }
    setListUser([]);
  }, [activekey]);

  useEffect(() => {
    if (Array.isArray(selectedUser) && selectUser.length > 0) {
      setListUser(selectedUser);
    }
  }, [selectedUser]);

  useEffect(() => {
    handleSearch(textSearch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textSearch]);

  const handleHideAddColumn = () => {
    setIsVisibleAddColumn(false);
  };

  const handleVisibleChange = (visible: any) => {
    setIsVisibleAddColumn(visible);
  };

  const handleOpenModalCreate = () => {
    setIsVisibleInsuranceCreate(true);
  };
  const onClickAutoCreate = () => {
    if (props.dataOverview) {
      switch (activekey) {
        case 'increase':
          dispatch(
            getCreateAuto({
              fromDate: moment().startOf('month').format('YYYY-MM-DD'), //dateIncrease.start,
              toDate: moment().endOf('month').format('YYYY-MM-DD'), //dateIncrease.end,
              boardType: 'on',
              configId: props.dataOverview?.data?.id,
              adjustFor: 'increase',
              callBack: (response: any) => {
                if (!response.error) {
                  props.reSetData && props.reSetData(activekey);
                }
              },
            }),
          );
          break;
        case 'decrease':
          dispatch(
            getCreateAuto({
              fromDate: moment().startOf('month').format('YYYY-MM-DD'), //dateDecrease.start,
              toDate: moment().endOf('month').format('YYYY-MM-DD'), //dateDecrease.end,
              boardType: 'off',
              configId: props.dataOverview?.data?.id,
              adjustFor: 'decrease',
              callBack: (response: any) => {
                if (!response.error) {
                  props.reSetData && props.reSetData(activekey);
                }
              },
            }),
          );
          break;
      }
    }
    // props.getClickAutoCreate && props.getClickAutoCreate(true)
  };
  const getAutoCreate = () => {
    if (props.checkAuto === true) {
      return (
        <Popconfirm
          title={t('Are_you_auto_create_request')}
          onConfirm={() => {
            onClickAutoCreate();
          }}
          // onCancel={cancel}
          okText={t('yes')}
          cancelText={t('no')}
        >
          <CustomButtonStyled style={{ background: '#F1592A', color: '#FFFFFF' }}>
            {t('auto_create')}
          </CustomButtonStyled>
        </Popconfirm>
      );
    } else {
      return <CustomButtonStyled disabled>{t('auto_create')}</CustomButtonStyled>;
    }
  };
  const showButtonCreate = () => {
    if (
      permissions?.indexOf(PermissionType.SCHEDULE_INCREASE_DECREASE_CREATE) !== -1 &&
      (checkview === labelKeyNameView.DROPDOWN_DECREASE ||
        checkview === labelKeyNameView.DROPDOWN_INCREASE)
    ) {
      return (
        <>
          {getAutoCreate()}
          <CustomButtonStyled
            style={{ background: '#F1592A', color: '#FFFFFF' }}
            onClick={handleOpenModalCreate}
          >
            {t('create')}
          </CustomButtonStyled>
        </>
      );
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (Array.isArray(props.checkid) && props.checkid.length > 0) {
      setIsCheckID(true);
    } else {
      setIsCheckID(false);
    }
  }, [props.checkid]);

  const showButtonAction = () => {
    if (
      (permissions?.includes(PermissionType.INSURANCE_APPROVAL_APPROVE_REJECT_REQUEST) ||
        permissions?.includes(PermissionType.INSURANCE_APPROVAL_DELETE)) &&
      checkview === labelKeyNameView.DROPDOWN_APPROVAL
    ) {
      return !selectedUser || selectedUser.length === 0 ? (
        <CustomButtonStyled
          style={{ background: '#ef9d84', color: '#FFFFFF', cursor: 'not-allowed' }}
        >
          {t('action')}
        </CustomButtonStyled>
      ) : (
        <Popover
          placement="bottom"
          content={
            <AppActionView
              ref={childRefAddColumn}
              handleVisibleAddColumn={handleHideAddColumn}
              addColumns={props.addColumns}
              checkid={selectedUser}
              reSetData={() => props.reSetData && props.reSetData(activekey)}
              permissions={permissions}
            />
          }
          trigger="click"
          open={isVisibleAddColumn}
          onOpenChange={handleVisibleChange}
        >
          <CustomButtonStyled
            disabled={!selectedUser || selectedUser.length === 0}
            style={{ background: '#F1592A', color: '#FFFFFF' }}
          >
            {t('action')}
          </CustomButtonStyled>
        </Popover>
      );
    } else {
      return null;
    }
  };

  const showButtonAddColumns = () => {
    if (
      permissions?.includes(PermissionType.RECORD_INSURANCE_PAYMENT_VIEW) &&
      checkview === labelKeyNameView.RECORD_PAYMENT_VIEW
    ) {
      return (
        <Popover
          placement="bottom"
          content={
            <MedalAddColumn
              ref={childRefAddColumn}
              handleVisibleAddColumn={handleHideAddColumn}
              addColumns={props.addColumns}
              sendValueAddColumns={setListAddColumn}
            />
          }
          trigger="click"
          visible={isVisibleAddColumn}
          onVisibleChange={handleVisibleChange}
        >
          <CustomButtonStyled style={{ marginRight: 8 }} icon={IconAddComponents}>
            {t('sdd_columns')}
          </CustomButtonStyled>
        </Popover>
      );
    } else {
      return null;
    }
  };

  const handleExportExcel = async () => {
    if (checkview === labelKeyNameView.HISTORY_PAYMENT) {
    } else {
      switch (activekey) {
        case 'increase':
          const dataIncre = {
            from: dateIncrease?.start || '',
            end: dateIncrease?.end || '',
            selected: selectedUser,
          };
          await dispatch(
            scheduleAdjust({
              type: 'incre',
              data: dataIncre,
            }),
          );
          break;
        case 'decrease':
          let dataDecre = {
            from: dateDecrease?.start || '',
            end: dateDecrease?.end || '',
            selected: selectedUser,
          };
          await dispatch(
            scheduleAdjust({
              type: 'decre',
              data: dataDecre,
            }),
          );
          break;
        case 'waiting-for-approved':
          let dataApproval = {
            from: dateApproval[0] || '',
            end: dateApproval[1] || '',
            selected: selectedUser,
          };
          await dispatch(listApprovedInsurance(dataApproval));
          break;
        case 'participant':
          dispatch(
            getExportParticipant({
              str: `Participant_${moment(datePart).format('YYYY-MM')}.xlsx`,
              type: InsuranceExport.PARTICIPANT,
              monthYear: datePart || '',
              userIds: data
                ?.filter((item: any) => selectedUser?.some((i: any) => i === item?.datarecor?.id))
                ?.map((item: any) => item?.datarecor?.user?.id),
            }),
          );
          break;
        case 'recordpayment':
          dispatch(
            getExportRecordPayment({
              str: `Record_payment_${moment(dateRecord).format('YYYY-MM')}.xlsx`,
              year: dateRecord || '',
              ids: data
                ?.filter((item: any) => selectedUser?.some((i: any) => i === item?.datarecor?.id))
                ?.map((item: any) => item?.datarecor?.user?.id),
              includeKeys: listAddColumn,
            }),
          );
          break;
        case 'recordPaymentYear':
          dispatch(
            getExportPaymentByYear({
              str: `Record_payment_by_year_${moment(dateRecord).format('YYYY-MM')}.xlsx`,
              year: dateRecord || '',
              ids: data
                ?.filter((item: any) => selectedUser?.some((i: any) => i === item?.user?.id))
                ?.map((item: any) => item?.user?.id),
            }),
          );
          break;
      }
    }
  };

  const handleExportPdf = async () => {
    if (checkview === labelKeyNameView.HISTORY_PAYMENT) {
    } else {
      switch (activekey) {
        case 'increase':
          await dispatch(
            postExportPdf({
              str: `Schedule_increase.pdf`,
              type: InsuranceExport.SCHEDULE_INCREASE,
              fromDate: dateIncrease?.start || '',
              toDate: dateIncrease?.end || '',
              typeExport: 'increase',
              ids: selectedUser,
            }),
          );
          break;
        case 'decrease':
          await dispatch(
            postExportPdf({
              str: `Schedule_decrease.pdf`,
              type: InsuranceExport.SCHEDULE_DECREASE,
              fromDate: dateDecrease?.start || '',
              toDate: dateDecrease?.end || '',
              typeExport: 'decrease',
              ids: selectedUser,
            }),
          );
          break;
        case 'waiting-for-approved':
          await dispatch(
            postExportPdf({
              str: `Schedule_approval.pdf`,
              type: InsuranceExport.SCHEDULE_APPROVAL,
              fromDate: dateApproval[0] || '',
              toDate: dateApproval[1] || '',
              ids: selectedUser,
            }),
          );
          break;
        case 'participant':
          dispatch(
            postExportPdf({
              str: `Participant_${moment(datePart).format('YYYY-MM')}.pdf`,
              type: InsuranceExport.PARTICIPANT,
              monthYear: datePart || '',
              userIds: data
                ?.filter((item: any) => selectedUser?.some((i: any) => i === item?.datarecor?.id))
                ?.map((item: any) => item?.datarecor?.user?.id),
            }),
          );
          break;
        case 'recordpayment':
          dispatch(
            postExportPdf({
              str: `Record_payment_${moment(dateRecord).format('YYYY-MM')}.pdf`,
              type: InsuranceExport.RECORD_PAYMENT,
              monthYear: dateRecord || '',
              userIds: data
                ?.filter((item: any) => selectedUser?.some((i: any) => i === item?.datarecor?.id))
                ?.map((item: any) => item?.datarecor?.user?.id),
            }),
          );
          break;
      }
    }
  };

  const showButtonExport = () => {
    if (
      (permissions?.includes(PermissionType.INSURANCE_PARTICIPANT_LIST_EXPORT) &&
        checkview === labelKeyNameView.DROPDOWN_PARTICIPANT_LIST) ||
      (permissions?.includes(PermissionType.SCHEDULE_INCREASE_DECREASE_EXPORT) &&
        (checkview === labelKeyNameView.DROPDOWN_DECREASE ||
          checkview === labelKeyNameView.DROPDOWN_INCREASE)) ||
      (permissions?.includes(PermissionType.INSURANCE_APPROVAL_EXPORT) &&
        checkview === labelKeyNameView.DROPDOWN_APPROVAL) ||
      (permissions?.includes(PermissionType.RECORD_INSURANCE_PAYMENT_EXPORT) &&
        (checkview === labelKeyNameView.RECORD_PAYMENT_VIEW ||
          checkview === labelKeyNameView.RECORD_PAYMENT_VIEW_YEAR))
    ) {
      return (
        <Dropdown overlay={menu} disabled={!selectedUser || selectedUser.length === 0}>
          <CustomButtonStyled icon={IconExportComponents}>
            {t('Export')} <DownOutlined />
          </CustomButtonStyled>
        </Dropdown>
      );
    } else {
      return null;
    }
  };
  const menu = (
    <Menu>
      {
        <Menu.Item key="1" onClick={handleExportExcel}>
          Excel
        </Menu.Item>
      }
      {checkview !== labelKeyNameView.RECORD_PAYMENT_VIEW_YEAR && (
        <Menu.Item key="2" onClick={handleExportPdf}>
          PDF
        </Menu.Item>
      )}
    </Menu>
  );
  const onChangeDate = (dates: any, dateStrings: any) => {
    localStorage.removeItem('monthYearRecordPay');
    props.functionPackDateTime &&
      props.functionPackDateTime(dateStrings, dateStrings, props?.checkview);
  };
  const onChangeDateTime = (dates: any, dateStrings: any) => {
    if (dateFormat === 'DD/MM/YYYY') {
      let startDate = dateStrings[0].split('/').reverse();
      let endDate = dateStrings[1].split('/').reverse();

      props.functionDateTime &&
        props.functionDateTime(startDate.join('/'), endDate.join('/'), props?.checkview);
    } else if (dateFormat === 'DD-MM-YYYY') {
      let startDate = dateStrings[0].split('-').reverse();
      let endDate = dateStrings[1].split('-').reverse();

      props.functionDateTime &&
        props.functionDateTime(startDate.join('/'), endDate.join('/'), props?.checkview);
    } else {
      props.functionDateTime &&
        props.functionDateTime(dateStrings[0], dateStrings[1], props?.checkview);
    }
  };

  const getDatePicker = () => {
    if (checkview === labelKeyNameView.HISTORY_PAYMENT) {
      return <ContainerLeft></ContainerLeft>;
    } else if (checkview === labelKeyNameView.DROPDOWN_APPROVAL) {
      return (
        <div>
          <RangePicker
            allowClear={true}
            defaultValue={[
              dateApproval?.[0] ? moment(dateApproval?.[0]) : null,
              dateApproval?.[1] ? moment(dateApproval?.[1]) : null,
            ]}
            format={dateFormat}
            onChange={onChangeDateTime}
          />
        </div>
      );
    } else if (checkview === labelKeyNameView.RECORD_PAYMENT_VIEW_YEAR) {
      return (
        <DatePicker
          defaultValue={datePart || dateRecord ? moment(datePart ?? dateRecord) : moment()}
          allowClear={false}
          onChange={onChangeDate}
          picker="year"
        />
      );
    } else {
      return (
        <div>
          {checkview === labelKeyNameView.DROPDOWN_PARTICIPANT_LIST ||
          checkview === labelKeyNameView.RECORD_PAYMENT_VIEW ? (
            <DatePicker
              value={datePart || dateRecord ? moment(datePart ?? dateRecord) : moment()}
              allowClear={false}
              format={'YYYY-MM'}
              onChange={onChangeDate}
              picker="month"
            />
          ) : (
            <RangePicker
              value={[
                dateIncrease?.start || dateDecrease?.start
                  ? moment(dateIncrease?.start ?? dateDecrease?.start)
                  : null,
                dateIncrease?.end || dateDecrease?.end
                  ? moment(dateIncrease?.end ?? dateDecrease?.end)
                  : null,
              ]}
              format={dateFormat}
              allowClear={true}
              onChange={onChangeDateTime}
            />
          )}
        </div>
      );
    }
  };

  const handleSendConfirm = () => {
    setOpennModalConfirm(true);
  };
  const handleCloseModalConfirm = () => {
    setOpennModalConfirm(false);
  };

  const handleReset = async (reason: string) => {
    try {
      const params = {
        monthYear: moment(dateRecord).format(MONTH_YEAR_FORMAT),
        timesheetType: 'insurance',
        reason,
      };

      setIsLoadingConfirm(true);
      const response = await payrollApi.resetConfirmPayroll(params);
      setIsLoadingConfirm(false);
      notificationToast(Notification.Type.Success, response.message, Notification.Duration._3s);
      props.reSetData && props.reSetData(activekey);
    } catch (error) {
      setIsLoadingConfirm(false);
      const message = getErrorMessage(error);
      notificationToast(Notification.Type.Error, message, Notification.Duration._3s);
    }
  };

  const handleResetConfirm = () => {
    let reason = '';
    Modal.confirm({
      title: <div style={{ fontWeight: 'bold' }}>{t('action:reset_reason')}</div>,
      content: (
        <>
          <ReasonForm
            onSubmit={(payload: Reason) => {
              if (!payload.reason) return;
              reason = payload.reason;
            }}
          />
          <p style={{ margin: 0, color: 'red', fontStyle: 'italic' }}>
            {t('payroll:payroll_deleted', { monthYear: dateRecord })}
          </p>
        </>
      ),
      okButtonProps: { loading: isLoadingConfirm },
      okText: t('action:ok'),
      cancelText: t('action:cancel'),
      onOk: () => handleReset(reason),
      onCancel: () => (reason = ''),
    });
  };

  const showButtonSendConfirm = () => {
    if (dataRecordPay?.data?.actionActive) {
      if (
        permissions?.includes(PermissionType.RECORD_INSURANCE_PAYMENT_APPROVE) &&
        checkview === labelKeyNameView.RECORD_PAYMENT_VIEW
      ) {
        if (dataRecordPay?.data?.status === 'confirm') {
          return (
            <CustomButtonStyled
              style={{ background: '#F1592A', color: '#FFFFFF' }}
              onClick={handleSendConfirm}
            >
              {t('send_confirm')}
            </CustomButtonStyled>
          );
        } else if (dataRecordPay?.data?.status === 'waiting-for-confirm') {
          return (
            <CustomButtonStyled
              style={{ background: '#F1592A', color: '#FFFFFF' }}
              onClick={handleSendConfirm}
            >
              {t('waiting_for_approval')}
            </CustomButtonStyled>
          );
        } else if (dataRecordPay?.data?.status === 'approved') {
          return (
            <>
              <CustomButtonStyled
                style={{ background: '#F1592A', color: '#FFFFFF', marginRight: '8px' }}
                onClick={handleResetConfirm}
              >
                {t('action:reset')}
              </CustomButtonStyled>
              <ButtonApproved onClick={handleSendConfirm}>{t('common:approved')}</ButtonApproved>
            </>
          );
        } else if (dataRecordPay?.data?.status === 'rejected') {
          return (
            <>
              <CustomButtonStyled
                style={{ background: '#F1592A', color: '#FFFFFF' }}
                onClick={handleResetConfirm}
              >
                {t('action:reset')}
              </CustomButtonStyled>
              <ButtonRejected onClick={handleSendConfirm}>{t('common:rejected')}</ButtonRejected>
            </>
          );
        } else {
          return null;
        }
      }

      if (
        permissions?.includes(PermissionType.RECORD_INSURANCE_PAYMENT_VIEW) &&
        checkview === labelKeyNameView.RECORD_PAYMENT_VIEW
      ) {
        if (dataRecordPay?.data?.status === 'confirm') {
          return (
            <CustomButtonStyled
              style={{ background: '#F1592A', color: '#FFFFFF' }}
              onClick={handleSendConfirm}
            >
              {t('send_confirm')}
            </CustomButtonStyled>
          );
        } else if (dataRecordPay?.data?.status === 'waiting-for-confirm') {
          return (
            <ButtonApproved onClick={handleSendConfirm}>{t('timesheet:Confirmed')}</ButtonApproved>
          );
        } else if (dataRecordPay?.data?.status === 'approved') {
          return (
            <ButtonApproved onClick={handleSendConfirm}>{t('common:approved')}</ButtonApproved>
          );
        } else if (dataRecordPay?.data?.status === 'rejected') {
          return (
            <ButtonRejected onClick={handleSendConfirm}>{t('common:rejected')}</ButtonRejected>
          );
        } else {
          return null;
        }
      }
    }
  };

  // @ts-ignore
  return (
    <>
      <Container>
        {getDatePicker()}
        {!viewMode ? (
          <ContainerRight>
            {checkview === labelKeyNameView.HISTORY_PAYMENT ? (
              <></>
            ) : (
              <>
                {showButtonSendConfirm()}
                {showButtonCreate()}
                {showButtonAction()}
                {showButtonExport()}
                {showButtonAddColumns()}
                <Input
                  style={{ width: 232 }}
                  prefix={<SearchOutlined />}
                  value={textSearch}
                  onChange={(e: any) => setTextSearch(e.target.value)}
                />
              </>
            )}
          </ContainerRight>
        ) : (
          <ITVButton
            disabled={!selectedUser || selectedUser.length === 0}
            style={{ width: 100 }}
            onClick={() => handleSubmit && handleSubmit()}
          >
            {submitText}
          </ITVButton>
        )}
      </Container>
      <ModalCreate
        isVisible={isVisibleInsuranceCreate}
        isShowFullMenu={false}
        reSetData={() => props.reSetData && props.reSetData(activekey)}
        onClose={handleCloseInsuranceCreate}
        activekey={activekey}
      />
      <ModalSendConfirmInsurance
        isVisible={opennModalConfirm}
        onClose={handleCloseModalConfirm}
        dateRecord={dateRecord}
        totalContributeAllEmployee={dataRecordPay?.data?.totalContributeAllEmployer}
        reSetData={() => props.reSetData && props.reSetData(activekey)}
        permissions={permissions}
      />
    </>
  );
};

export default FunctionInsurance;

export const Container = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const ContainerLeft = styled.div``;

export const ContainerRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const CustomSearch = styled(Search)`
  .ant-input-group-addon {
    display: none;
  }
  .ant-input-affix-wrapper {
    height: 32px;
    input {
      font-size: 14px;
    }
  }
`;

export const CustomButtonStyled = styled(Button)`
  font-size: 14px;
  font-weight: 500;
  background-color: #ffffff;
  color: ${colors.gray900};
  border-radius: 4px;
  height: 32px;
  margin-left: 8px;
`;
export const ButtonApproved = styled(Button)`
  background-color: var(--cl_success100);
  color: var(--cl_success600);
  font-weight: bold;
  border-radius: 5px;
  text-align: center;
  display: inline;
  padding: 5px 10px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 4px;
`;
export const ButtonRejected = styled(Button)`
  background-color: rgb(254, 226, 226);
  color: rgb(232, 15, 15);
  font-weight: bold;
  border-radius: 5px;
  text-align: center;
  display: inline;
  padding: 5px 10px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 4px;
`;

import { Input, Tabs, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { useAppSelector } from 'app/hooks';
import InsuranceTable from '../../../components/InsuranceTable';
import {
  formatCurrencyUnit,
  formatDateInsurances,
  formatMonths,
  labelKeyStatus,
  labelKeyTable,
  labelUnit,
  stringCamelCase,
} from '../../../constants/common';
import { createScheduleInsurances } from '../../../constants/label';
import { getPaymentHistory, getPaymentHistoryRecord } from '../../../insuranceAction';
import { selectInsurance } from '../../../insuranceSlice';
import { InfoTextCenter } from './HrStaffComplusory';
import ItemHistoryParticipant from './ItemHistoryParticipant';
import { selectConfiguration } from 'features/configuration/configurationSlice';

const { TabPane } = Tabs;

const HistoryParticipantList = (props: any) => {
  const location: any = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation(['insurance']);
  const menuStore = useAppSelector(selectConfiguration);
  const dataScreenManage = menuStore?.dataScreenManage?.result;

  const [showhideall, setShowHideAll] = useState(false);
  const [showpayment, setShowPayment] = useState(false);
  const [titleHeader, setTitleHeader] = useState('');

  useEffect(() => {
    const temp = dataScreenManage
      ?.filter((item: any) => location.pathname.includes(item.path))
      ?.map((item: any) => item.nameScreen);

    setTitleHeader(temp ? temp[0] : '');
  }, [dataScreenManage, location.pathname]);

  useEffect(() => {
    setColumnsPayment(Columns_Payment);
    setColumnsAdjustment(Columns_Adjustment);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showhideall, showpayment]);

  const Columns_Adjustment = [
    {
      title: function () {
        return titleTable(t('Adjust_date'));
      },
      dataIndex: 'datarecor',
      key: 'datarecor',
      width: 150,
      render: function (text: any, record: any, index: any) {
        return (
          <InfoTextCenter>{formatDateInsurances(record?.datarecor?.createdAt)}</InfoTextCenter>
        );
      },
    },
    {
      title: function () {
        return titleTable(t('Adjust_by'));
      },
      dataIndex: 'datarecor ',
      key: 'datarecor',
      width: 150,
      render: function (text: any, record: any, index: any) {
        return <InfoTextCenter>{record?.datarecor?.schedulePremium?.createdBy}</InfoTextCenter>;
      },
    },
    {
      title: function () {
        return titleTable(t('salary_premium'));
      },
      dataIndex: 'datarecor',
      key: 'datarecor',
      width: 150,
      render: function (text: any, record: any, index: any) {
        return (
          <Input
            type={record?.showall === true ? 'text' : 'password'}
            disabled
            style={{ borderWidth: 0, textAlign: 'center', color: 'rgba(38,38,38,0.85)' }}
            value={formatCurrencyUnit(
              record?.datarecor?.schedulePremium?.newSalaryPremium,
              labelUnit,
            )}
          />
        );
      },
    },
    {
      title: function () {
        return titleTable(t('employee_contribute'));
      },
      dataIndex: 'datarecor',
      key: 'datarecor',
      width: 160,
      render: function (text: any, record: any, index: any) {
        return (
          <Input
            type={record?.showall === true ? 'text' : 'password'}
            disabled
            style={{ borderWidth: 0, textAlign: 'center', color: 'rgba(38,38,38,0.85)' }}
            value={formatCurrencyUnit(record?.datarecor?.totalContributeSelf, labelUnit)}
          />
        );
      },
    },
    {
      title: function () {
        return titleTable(t('employer_contribute'));
      },
      dataIndex: 'datarecor',
      key: 'datarecor',
      width: 150,
      render: function (text: any, record: any, index: any) {
        return (
          <Input
            type={record?.showall === true ? 'text' : 'password'}
            disabled
            style={{ borderWidth: 0, textAlign: 'center', color: 'rgba(38,38,38,0.85)' }}
            value={formatCurrencyUnit(record?.datarecor?.totalContributeEmployer, labelUnit)}
          />
        );
      },
    },
    {
      title: function () {
        return titleTable(t('adjust_for'));
      },
      dataIndex: 'datarecor',
      key: 'datarecor',
      width: 150,
      render: function (text: any, record: any, index: any) {
        return <InfoTextCenter>{record?.datarecor?.schedulePremium?.adjustFor}</InfoTextCenter>;
      },
    },
    {
      title: function () {
        return (
          <div>
            <InfoTextCenter>{t('Type_of_increase')}</InfoTextCenter>
            <InfoTextCenter>{t('decrease')}</InfoTextCenter>
          </div>
        );
      },
      dataIndex: 'datarecor',
      key: 'datarecor',
      width: 150,
      render: function (text: any, record: any, index: any) {
        return (
          <InfoTextCenter>
            {stringCamelCase(record?.datarecor?.schedulePremium?.typeOfPremium)}
          </InfoTextCenter>
        );
      },
    },
    {
      title: function () {
        return titleTable(t('valid_date'));
      },
      dataIndex: 'datarecor',
      key: 'datarecor',
      width: 150,
      render: function (text: any, record: any, index: any) {
        return (
          <InfoTextCenter>
            {formatDateInsurances(record?.datarecor?.schedulePremium?.newValidDate)}
          </InfoTextCenter>
        );
      },
    },
    {
      title: function () {
        return titleTable(t('Approved_date'));
      },
      dataIndex: 'datarecor',
      key: 'datarecor',
      width: 150,
      render: function (text: any, record: any, index: any) {
        return (
          <InfoTextCenter>{formatDateInsurances(record?.datarecor?.approvedDate)}</InfoTextCenter>
        );
      },
    },
    {
      title: function () {
        return titleTable(t('Approved_by'));
      },
      dataIndex: 'datarecor',
      key: 'datarecor',
      width: 150,
      render: function (text: any, record: any, index: any) {
        return <InfoTextCenter>{record?.datarecor?.approvedBy}</InfoTextCenter>;
      },
    },
    {
      title: function (text: any, record: any, index: any) {
        return (
          <>
            {showhideall === false ? (
              <Typography.Link onClick={() => handleOpenShowHideAll()}>
                {t('Show_all_money')}
              </Typography.Link>
            ) : (
              <Typography.Link onClick={() => handleOpenShowHideAll()}>
                {t('Hide_money')}
              </Typography.Link>
            )}
          </>
        );
      },
      dataIndex: 'showall',
      key: 'showall',
      width: 120,
      // render: function (text: any, record: any, index: any) {
      //   return record?.showall === false ? (
      //     <Typography.Link onClick={() => handleOpenShow(record)}>Show</Typography.Link>
      //   ) : (
      //     <Typography.Link onClick={() => handleOpenHide(record)}>Hide</Typography.Link>
      //   );
      // },
    },
  ];
  const Columns_Payment = [
    {
      title: function () {
        return titleTable(t('Period'));
      },
      dataIndex: 'datarecor',
      key: 'datarecor',
      width: 150,
      render: function (text: any, record: any, index: any) {
        return <InfoTextCenter>{formatMonths(record?.datarecor?.period)}</InfoTextCenter>;
      },
    },
    {
      title: function () {
        return titleTable(t('salary_premium'));
      },
      dataIndex: 'datarecor ',
      key: 'datarecor',
      width: 150,
      render: function (text: any, record: any, index: any) {
        return (
          <Input
            type={record?.showall === true ? 'text' : 'password'}
            disabled
            style={{ borderWidth: 0, textAlign: 'center', color: 'rgba(38,38,38,0.85)' }}
            value={formatCurrencyUnit(
              record?.datarecor?.schedulePremium?.newSalaryPremium,
              labelUnit,
            )}
          />
        );
      },
    },
    {
      title: function () {
        return titleTable(t('employee_contribute'));
      },
      dataIndex: 'datarecor',
      key: 'datarecor',
      width: 150,
      render: function (text: any, record: any, index: any) {
        return (
          <Input
            type={record?.showall === true ? 'text' : 'password'}
            disabled
            style={{ borderWidth: 0, textAlign: 'center', color: 'rgba(38,38,38,0.85)' }}
            value={formatCurrencyUnit(record?.datarecor?.totalContributeSelf, labelUnit)}
          />
        );
      },
    },
    {
      title: function () {
        return titleTable(t('employer_contribute'));
      },
      dataIndex: 'datarecor',
      key: 'datarecor',
      width: 150,
      render: function (text: any, record: any, index: any) {
        return (
          <Input
            type={record?.showall === true ? 'text' : 'password'}
            disabled
            style={{ borderWidth: 0, textAlign: 'center', color: 'rgba(38,38,38,0.85)' }}
            value={formatCurrencyUnit(record?.datarecor?.totalContributeEmployer, labelUnit)}
          />
        );
      },
    },
    {
      title: function (text: any, record: any, index: any) {
        return (
          <>
            {showpayment === false ? (
              <Typography.Link onClick={() => handlePaymentShowHideAll()}>
                {t('Show_all_money')}
              </Typography.Link>
            ) : (
              <Typography.Link onClick={() => handlePaymentShowHideAll()}>
                {t('Hide_money')}
              </Typography.Link>
            )}
          </>
        );
      },
      dataIndex: 'showall',
      key: 'showall',
      width: 120,
      // render: function (text: any, record: any, index: any) {
      //   return record?.showall === false ? (
      //     <Typography.Link onClick={() => handleOpenShow(record)}>Show</Typography.Link>
      //   ) : (
      //     <Typography.Link onClick={() => handleOpenHide(record)}>Hide</Typography.Link>
      //   );
      // },
    },
  ];
  const insurance = useAppSelector(selectInsurance);
  const dataPaymentHistory = insurance?.dataPaymentHistory;
  const dataPaymentHistoryRecord = insurance?.dataPaymentHistoryRecord;
  const params = location.state?.params;
  const userName = params;
  const [, setColumnsAdjustment] = useState(Columns_Adjustment);
  const [, setColumnsPayment] = useState(Columns_Payment);
  const [widthTable] = useState(978);
  const [total] = useState(0);

  const [datas, setDatas] = useState<any[]>([]);
  const [dataRecord, setDataRecord] = useState<any[]>([]);
  const [, setSelectedUser] = useState([]);
  const [resultTotal] = useState(0);

  const callback = (key: any) => {
    // console.log(key);
  };
  useEffect(() => {
    dispatch(
      getPaymentHistory({
        search: userName?.user?.id,
      }),
    );
    dispatch(
      getPaymentHistoryRecord({
        id: userName?.user?.id,
      }),
    );
  }, [dispatch, userName?.user?.id]);

  useEffect(() => {
    if (Array.isArray(dataPaymentHistory?.data?.result)) {
      let dataRecor = dataPaymentHistory?.data?.result;
      let obj: any = [];
      for (let i = 0; i < dataRecor.length; i++) {
        obj.push({
          datarecor: dataRecor[i],
          showall: false,
        });
      }
      setDatas(obj);
    }
  }, [dataPaymentHistory]);

  useEffect(() => {
    if (Array.isArray(dataPaymentHistoryRecord?.data?.result)) {
      let dataRecord = dataPaymentHistoryRecord?.data?.result;
      let obj: any = [];
      for (let i = 0; i < dataRecord.length; i++) {
        obj.push({
          datarecor: dataRecord[i],
          showall: false,
        });
      }
      setDataRecord(obj);
    }
  }, [dataPaymentHistoryRecord]);

  const handleOpenShowHideAll = () => {
    let obj: any = [];
    if (Array.isArray(dataPaymentHistory?.data?.result)) {
      for (let i = 0; i < datas.length; i++) {
        if (showhideall === false) {
          obj.push({
            datarecor: datas[i].datarecor,
            showall: true,
          });
        } else {
          obj.push({
            datarecor: datas[i].datarecor,
            showall: false,
          });
        }
      }
    }
    setShowHideAll(!showhideall);
    if (Array.isArray(obj) && obj.length > 0) {
      setDatas(obj);
    }
  };
  const handlePaymentShowHideAll = () => {
    let obj: any = [];
    if (Array.isArray(dataPaymentHistoryRecord?.data?.result)) {
      for (let i = 0; i < dataRecord.length; i++) {
        if (showpayment === false) {
          obj.push({
            datarecor: dataRecord[i].datarecor,
            showall: true,
          });
        } else {
          obj.push({
            datarecor: dataRecord[i].datarecor,
            showall: false,
          });
        }
      }
    }
    setShowPayment(!showpayment);
    if (Array.isArray(obj) && obj.length > 0) {
      setDataRecord(obj);
    }
  };
  const history = useHistory();
  const getBackInsurance = () => {
    history.push('complusory-insurance', { type: 'participant' });
  };

  return (
    <Container>
      <ContainerTitle>
        <div
          style={{
            display: 'flex',
            fontSize: 15,
            color: '#6B7280',
            fontFamily: 'Roboto',
            marginLeft: 20,
          }}
        >
          <u onClick={getBackInsurance} style={{ marginRight: 4, cursor: 'pointer' }}>
            {titleHeader}
          </u>
          {' > '}
          <div style={{ marginLeft: 10, color: '#111827' }}>{userName?.user?.fullName}</div>
        </div>
        <InfoTitle>{titleHeader + ' / ' + userName?.user?.fullName}</InfoTitle>
      </ContainerTitle>
      <ContainerBody>
        <ContainerRow>
          <InfoIDText>
            {createScheduleInsurances.ID} : <InfoText>{userName?.user?.employeeId}</InfoText>
          </InfoIDText>
          <InfoIDText>
            {t('position')} :<InfoText>{userName?.user?.positionName}</InfoText>
          </InfoIDText>
          <InfoIDText>
            {t('department')} :<InfoText>{userName?.user?.departmentName}</InfoText>
          </InfoIDText>
        </ContainerRow>
        <ContainerTop>
          <ItemHistoryParticipant params={params} datas={datas} />
        </ContainerTop>
        <ContainerButton>
          <Tabs defaultActiveKey="" onChange={callback}>
            <TabPane tab={t('adjustment_history')} key="1">
              {
                <InsuranceTable
                  // @ts-ignore
                  columns={Columns_Adjustment}
                  data={datas}
                  width={widthTable}
                  totalIni={total}
                  totalResult={resultTotal}
                  setSelectedUser={setSelectedUser}
                  setrowSelection={labelKeyTable.KEY_TABLE_NOT_CHECKBOX}
                  showPagination={false}
                />
              }
            </TabPane>
            <TabPane tab={t('payment_history')} key="2">
              {/* <FunctionInsurance
                checkview={checkview}
                isShowFull={true}
                selectedUser={selectedUser}
                dataRecord={dataRecord}
                datapdf={datapdf}
                handleSearch={(value: any) => {}}
              /> */}
              {
                <InsuranceTable
                  // @ts-ignore
                  columns={Columns_Payment}
                  data={dataRecord}
                  width={widthTable}
                  totalIni={total}
                  totalResult={resultTotal}
                  setSelectedUser={setSelectedUser}
                  setrowSelection={labelKeyTable.KEY_TABLE_NOT_CHECKBOX}
                  showPagination={false}
                />
              }
            </TabPane>
          </Tabs>
        </ContainerButton>
      </ContainerBody>
    </Container>
  );
};

export default HistoryParticipantList;
export const titleTable = (item: any) => {
  return <div style={{ display: 'flex', justifyContent: 'center' }}>{item}</div>;
};
export const Container = styled.div`
  width: 100%;
  margin-top: 15px;
  height: 98%;
  @media screen and (max-width: 1440px) {
    width: 100%;
  }
`;
export const ContainerBody = styled.div`
  width: 97%;
  margin: 0px 1.5%;
  @media screen and (max-width: 1135px) {
    width: 97%;
  }
`;
export const ContainerTop = styled.div`
  width: 100%;
  padding: 10px 15px 15px;
  background: #fff;
  @media screen and (max-width: 1135px) {
    width: 100%;
  }
`;
export const ContainerRow = styled.div`
  display: flex;
  margin: 15px 0px;
`;
export const InfoIDText = styled.div`
  font-size: 16px;
  font-weight: 500;
  font-family: Roboto;
  color: #9ca3af;
  display: flex;
  margin-left: 15px;
  margin-right: 10px;
`;
export const ContainerButton = styled.div`
  width: 100%;
  padding: 5px 15px 15px;
  background: #fff;
  margin-top: 10px;
  @media screen and (max-width: 1135px) {
    width: 100%;
    height: 100%;
  }
`;
export const ContainerTitle = styled.div`
  width: 100%;
  @media screen and (max-width: 1440px) {
    width: 100%;
  }
`;
export const InfoTitle = styled('h1')`
  font-size: 28px;
  font-weight: 500;
  font-family: Roboto;
  height: 32px;
  margin: 15px 0px 15px 20px;
`;
export const RowTitle = styled('h1')`
  font-size: 16px;
  font-weight: 500;
  font-family: Roboto;
  //height: 24px;
  color: #9ca3af;
`;
export const InfoText = styled.div`
  font-size: 16px;
  font-weight: 400;
  //height: 24px;
  color: #000000;
  margin-left: 2px;
`;
export const ContainerTabs = styled.div`
  font-size: 14px;
  font-weight: 400;
  font-family: Roboto;
  height: 20px;
`;
interface InfoTextProps {
  type: string;
  al?: boolean;
}
export const InfoButtonCheckText = styled.div`
  margin-left: 10px;
  padding: 0px 15px;
  color: #000000;
  font-size: 14px;
  border-radius: 4px;
  ${(props: InfoTextProps) => {
    // @ts-ignore
    switch (props?.type) {
      case labelKeyStatus.KEY_INACTIVE:
        return css`
          background: #fee2e2;
          color: #dc2626;
        `;
      case labelKeyStatus.KEY_PENDING:
        return css`
          background: #fef3c7;
          color: #d97706;
        `;
      case labelKeyStatus.KEY_ACTIVE:
        return css`
          background: #d1fae5;
          color: #059669;
        `;
      case labelKeyStatus.KEY_REJECTED:
        return css`
          background: #fee2e2;
          color: #dc2626;
        `;
      case labelKeyStatus.KEY_WAITING_FOR_APPROVAL:
        return css`
          background: #fef3c7;
          color: #d97706;
        `;
      case labelKeyStatus.KEY_APPROVED:
        return css`
          background: #d1fae5;
          color: #059669;
        `;
      case labelKeyStatus.KEY_DRAFT:
        return css`
          background: #d1d5db;
          color: #374151;
        `;
      default:
        break;
    }
  }};
`;

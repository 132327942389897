import moment from 'moment';
import { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { EmployeeGrowthPayload, EmployeeGrowthParams, EmployeeGrowthReport } from 'types';
import ITVTable from 'components/ITVTable';
import PayrollCostByDesignationFilters from './PayrollCostByDesignationFilters';
import payrollDashboardApi from 'api/payrollDashboardApi';
import { useDispatch } from 'react-redux';
import {
  selectDataPayrollCostByDesignation,
  setDataPayrollCostByDesignationSlice,
} from 'features/payroll/payrollSlice';
import { formatCurrency } from 'features/insurances/constants/common';

const StyledContainer = styled.div`
  padding: 16px 24px;
  background-color: var(--cl_white);
  border-radius: 12px;
  border: 0.5px solid var(--cl_gray200);
  height: 100%;
`;

const StyledHeaderTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto;
  line-height: 20px;
  color: #121926;
`;

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export function PayrollCostByDesignation() {
  const { t } = useTranslation(['dashboard']);

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState({
    monthYear: moment(new Date()).clone().subtract(1, 'months').format('YYYY-MM'),
  });

  const [sizePage, setSizePage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPayrollCostByDesignation, setDataPayrollCostByDesignation] = useState<any>([]);

  const handleSubmit = ({ monthYear }: any) => {
    const formattedMonthYear = moment(monthYear).format('YYYY-MM');

    setFilters({
      monthYear: formattedMonthYear,
    });
  };

  let COLUMNS = [
    {
      title: 'Designation',
      width: 150,
      minWidth: 150,
      dataIndex: 'designation',
      key: 'designation',
      align: 'left',
      render: (text: any, record: any, index: any) => {
        return text;
      },
    },
    {
      title: 'Number of Employees',
      width: 130,
      minWidth: 130,
      dataIndex: 'employeeCount',
      key: 'employeeCount',
      align: 'center',
      render: (text: any, record: any, index: any) => {
        return text && formatCurrency(text);
      },
    },
    {
      title: 'Total Salary Cost',
      width: 150,
      minWidth: 150,
      dataIndex: 'totalSalaryCost',
      key: 'totalSalaryCost',
      align: 'right',
      render: (text: any, record: any, index: any) => {
        return text && formatCurrency(parseFloat(text).toFixed(2));
      },
    },
  ];

  useEffect(() => {
    const fetchDataPayrollDesignation = async () => {
      setIsLoading(true);
      const params = {
        period: filters.monthYear,
      };
      const response = await payrollDashboardApi.getPayrollDesignation(params);
      setDataPayrollCostByDesignation(response?.data);
      dispatch(setDataPayrollCostByDesignationSlice(response?.data));
      setIsLoading(false);
    };
    fetchDataPayrollDesignation();
  }, [filters]);

  return (
    <StyledContainer>
      <StyledWrapper>
        <StyledHeaderTitle>Payroll Cost by Designation</StyledHeaderTitle>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <p style={{ margin: 0, padding: 0, marginRight: 10, fontWeight: 600 }}>Pay Period</p>
          <PayrollCostByDesignationFilters
            initialValues={{ monthYear: moment(new Date(filters.monthYear)) }}
            loading={isLoading}
            onSubmit={handleSubmit}
          />
        </div>
      </StyledWrapper>

      <ITVTable
        // loading={loading}
        columns={COLUMNS}
        data={dataPayrollCostByDesignation}
        isRowSelect={false}
        setOffset={setCurrentPage}
        offset={currentPage}
        setLimit={setSizePage}
        limit={sizePage}
        height={400}
        scroll={{ x: 100, y: 100 }}
        loading={isLoading}
      />
    </StyledContainer>
  );
}

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import dashboardApi from 'api/dashboardApi';
import { RootState } from 'app/store';
import { IHoliday } from 'hrm-common/extensions/interfaces/personel';

export const getDashboard = createAsyncThunk(
  'dashboard/getDashboard',
  async ({ callBack, ...params }: any, thunkApi) => {
    try {
      const response = await dashboardApi.getDashboard(params);
      callBack && callBack(response.data);
      return response.data;
    } catch (error) {
      callBack && callBack({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getHoliday = createAsyncThunk(
  'dashboard/getHoliday',
  async ({ callBack, isCurrentMonth = false, ...params }: any, thunkApi) => {
    try {
      const response = await dashboardApi.getHoliday(params);
      callBack && callBack(response.data);
      return { data: response.data, isCurrentMonth };
    } catch (error) {
      callBack && callBack({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getBirthday = createAsyncThunk(
  'dashboard/getBirthday',
  async ({ callBack, ...params }: any, thunkApi) => {
    try {
      const response = await dashboardApi.getBirthday(params);
      callBack && callBack(response.data);
      return response.data;
    } catch (error) {
      callBack && callBack({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getEmployeeChart = createAsyncThunk(
  'dashboard/getEmployeeChart',
  async ({ callBack, ...params }: any, thunkApi) => {
    try {
      const response = await dashboardApi.getEmployeeChart(params);
      callBack && callBack(response.data);
      return response.data;
    } catch (error) {
      callBack && callBack({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getLeaveOffChart = createAsyncThunk(
  'dashboard/getLeaveOffChart',
  async ({ callBack, ...params }: any, thunkApi) => {
    try {
      const response = await dashboardApi.getLeaveOffChart(params);
      callBack && callBack(response.data);
      return response.data;
    } catch (error) {
      callBack && callBack({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getContractExpire = createAsyncThunk(
  'dashboard/getContractExpire',
  async ({ callBack, ...params }: any, thunkApi) => {
    try {
      const response = await dashboardApi.getExpiredContract(params);
      callBack && callBack(response.data);
      return response.data;
    } catch (error) {
      callBack && callBack({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getRequestLeaveOff = createAsyncThunk(
  'dashboard/getRequestLeaveOff',
  async ({ callBack, ...params }: any, thunkApi) => {
    try {
      const response = await dashboardApi.getRequestLeaveOff(params);
      callBack && callBack(response.data);
      return response.data;
    } catch (error) {
      callBack && callBack({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getGrowthChart = createAsyncThunk(
  'dashboard/getGrowthChart',
  async ({ callBack, ...params }: any, thunkApi) => {
    try {
      const response = await dashboardApi.getGrowthChart(params);
      callBack && callBack(response.data);
      return response.data;
    } catch (error) {
      callBack && callBack({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getGrowthPercent = createAsyncThunk(
  'dashboard/getGrowthPercent',
  async ({ callBack, ...params }: any, thunkApi) => {
    try {
      const response = await dashboardApi.getGrowthPercent(params);
      callBack && callBack(response.data);
      return response.data;
    } catch (error) {
      callBack && callBack({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getAllAgeEmployee = createAsyncThunk(
  '/dashboard/all-age-employee',
  async ({ callBack, ...params }: any, thunkApi) => {
    try {
      const response = await dashboardApi.getAllAgeEmployee(params);
      callBack && callBack(response.data);
      return response.data;
    } catch (error) {
      callBack && callBack({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getAllContractsEmployee = createAsyncThunk(
  '/dashboard/contract',
  async ({ callBack, ...params }: any, thunkApi) => {
    try {
      const response = await dashboardApi.getAllEmployeeContracts(params);
      callBack && callBack(response.data);
      return response.data;
    } catch (error) {
      callBack && callBack({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getNewEmployee = createAsyncThunk(
  '/dashboard/new-employee',
  async ({ callBack, ...params }: any, thunkApi) => {
    try {
      const response = await dashboardApi.getNewEmployee(params);
      callBack && callBack(response.data);
      return response.data;
    } catch (error) {
      callBack && callBack({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

const initialState = {
  isLoading: false,
  isLoadingRequestLeaveOff: false,
  error: null,
  data: {},
  dashboardData: {},
  holidaysInMonth: [] as IHoliday[],
  total: 0,
  isLoadingNewEmployees: false,
  isLoadingEmployeeBirthday: false,
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDashboard.pending, (state, action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getDashboard.fulfilled, (state, action: any) => {
        state.isLoading = false;
        state.error = null;
        state.dashboardData = action.payload;
      })
      .addCase(getDashboard.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getRequestLeaveOff.pending, (state, action) => {
        state.isLoadingRequestLeaveOff = true;
      })
      .addCase(getRequestLeaveOff.fulfilled, (state, action: any) => {
        state.isLoadingRequestLeaveOff = false;
      })
      .addCase(getRequestLeaveOff.rejected, (state, action: any) => {
        state.isLoadingRequestLeaveOff = false;
      })
      .addCase(getHoliday.pending, (state, action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        getHoliday.fulfilled,
        (state, action: PayloadAction<{ data: IHoliday[]; isCurrentMonth: boolean }>) => {
          state.isLoading = false;
          if (action.payload.isCurrentMonth) state.holidaysInMonth = action.payload.data;
          state.data = action.payload.data;
        },
      )
      .addCase(getHoliday.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getNewEmployee.pending, (state, action) => {
        state.isLoadingNewEmployees = true;
        state.error = null;
      })
      .addCase(getNewEmployee.fulfilled, (state, action: any) => {
        state.isLoadingNewEmployees = false;
        state.error = null;
      })
      .addCase(getNewEmployee.rejected, (state, action: any) => {
        state.isLoadingNewEmployees = false;
        state.error = action.payload;
      })
      .addCase(getBirthday.pending, (state, action) => {
        state.isLoadingEmployeeBirthday = true;
        state.error = null;
      })
      .addCase(getBirthday.fulfilled, (state, action: any) => {
        state.isLoadingEmployeeBirthday = false;
        state.error = null;
      })
      .addCase(getBirthday.rejected, (state, action: any) => {
        state.isLoadingEmployeeBirthday = false;
        state.error = action.payload;
      });
  },
});

export const selectHolidaysInMonth = (state: RootState) => state.dashboard.holidaysInMonth;
export const selectDashboard = (state: RootState) => state.dashboard;

export default dashboardSlice.reducer;

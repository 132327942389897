import { Button, Checkbox, Col, Divider, Form, Input, InputNumber, Row, Select } from 'antd';
import moment from 'moment';
import { useEffect, useState, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import { useAppSelector } from 'app/hooks';
import { colors } from 'assets/colors';
import CustomButton from 'styles/buttonStyled';
import { getUser } from 'utils/auth';
import {
  formatCurrencyUnit,
  formatDateInsurances,
  formatISDate,
  labelKeyNameView,
  labelKeyStatus,
  labelUnit,
  stringCamelCase,
} from '../constants/common';
import {
  createScheduleInsurances,
  labelInsurancesComplusory,
  labelMessage,
} from '../constants/label';
import { postEditSchedule } from '../insuranceAction';
import { selectInsurance } from '../insuranceSlice';
import {
  InfoCheckbox,
  InfoDatePicker,
  InfoInput,
  InfoRadio,
  InfoRow,
  InfoSelect,
  InfoTitle,
  InsuranceModal,
} from './ModalCreate';
import ModalHistory from './ModalHistory';
import { InfoU } from './ModalReject';
import { InfoButtonReject, InfoText } from './ModalView';

const { Option } = Select;
const { TextArea } = Input;

interface EditModalProps {
  isVisible: boolean;
  isShowFullMenu: boolean;
  itemId: any;
  checkview: any;
  onClose: () => void;
  reSetData: () => void;
}

const ModalEdit = (props: EditModalProps) => {
  const { isVisible, onClose, itemId } = props;
  const dispatch = useDispatch();
  const { t }: any = useTranslation(['insurance']);
  const [form] = Form.useForm();
  const insurance = useAppSelector(selectInsurance);
  const dataType = insurance?.dataTypeInsurance?.data?.result;
  const dataTypeOf = insurance?.dataTypeOf?.data;
  const [is_dataTypeOfIncrease, setISDataTypeOfIncrease] = useState(dataTypeOf?.increase);
  const [data, setData] = useState(dataType);
  const [iswidth] = useState(561);
  const [input_note, setInputNote] = useState();
  const [, setInputID] = useState();
  const [input_newsalary, setInputNewSalary] = useState();
  const [titleType, setTitleType] = useState();
  const [textTitle, setTextTitle] = useState();
  const [input_current, setInputCurrrent] = useState(0);
  const [input_date, setInputDate] = useState();
  const [radiovalue, setRadioValue] = useState(1);
  const [input_TypeOf, setInputTypeOf] = useState();
  const [checkedList, setCheckedList] = useState([]);
  const [input_checkbox, setChecobox] = useState([]);
  const [adjustFor, setadjustFor] = useState();
  const [checkHistory, setCheckHistory] = useState(true);
  const [buttondisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    if (dataTypeOf) {
      setISDataTypeOfIncrease(dataTypeOf?.increase);
    }
    if (itemId?.adjustFor === 'increase') {
      setTextTitle(`${t('increase_premium')}`);
      setRadioValue(createScheduleInsurances.INCREASE_PREMIUM);
    } else {
      setTextTitle(`${t('decrease_premium')}`);
      setRadioValue(createScheduleInsurances.DECREASE_PREMIUM);
    }
    if (itemId !== undefined) {
      setInputNewSalary(itemId?.newSalaryPremium);
    }
    setInputNote(itemId?.note);
    setInputDate(moment(itemId?.newValidDate));
    setInputCurrrent(itemId?.currentSalaryPremium);
    setCheckHistory(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId]);

  useEffect(() => {
    if (props.checkview !== labelKeyNameView.DROPDOWN_PARTICIPANT_LIST) {
      if (dataType) {
        let typeInsurance = itemId?.typeInsurance;
        let obj: any = [];
        let m_obj: any = [];
        for (let i = 0; i < dataType.length; i++) {
          let is_obj: any = [];
          // eslint-disable-next-line array-callback-return
          typeInsurance.map((item: any) => {
            if (String(dataType[i].insuranceCode) === item) {
              obj.push({
                id: dataType[i].insuranceCode,
                insuranceName: dataType[i].insuranceName,
                check: true,
              });
              is_obj.push({ check: true });
              m_obj.push(Number(dataType[i].insuranceCode));
            }
          });
          if (is_obj.length < 1) {
            obj.push({
              id: dataType[i].insuranceCode,
              insuranceName: dataType[i].insuranceName,
              check: false,
            });
          }
        }
        setData(obj);
        setCheckedList(m_obj);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataType]);

  useEffect(() => {
    if (
      input_newsalary &&
      input_date !== undefined &&
      checkedList?.length > 0 &&
      input_newsalary > 0
    ) {
      if (input_date === '') {
        setButtonDisabled(true);
      } else {
        setButtonDisabled(false);
      }
    } else {
      setButtonDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input_TypeOf, input_newsalary, input_note, input_date, input_checkbox]);

  useEffect(() => {
    if (dataType) {
      setData(dataType);
    }
  }, [dataType]);

  useEffect(() => {
    if (radiovalue === createScheduleInsurances.INCREASE_PREMIUM) {
      setTitleType(createScheduleInsurances.INCREASE_PREMIUM);
      setISDataTypeOfIncrease(dataTypeOf?.increase);
      setInputTypeOf(dataTypeOf?.increase[0]);
      setTextTitle(`${t('increase_premium')}`);
      setadjustFor('increase');
    } else {
      setISDataTypeOfIncrease(dataTypeOf?.decrease);
      setTitleType(createScheduleInsurances.DECREASE_PREMIUM);
      setadjustFor('decrease');
      setTextTitle(`${t('decrease_premium')}`);
      setInputTypeOf(dataTypeOf?.decrease[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [radiovalue, dataTypeOf]);

  const handleClose = () => {
    setInputCurrrent(0);
    setInputNewSalary();
    setCheckHistory(true);
    onClose();
  };

  const handleChange = (e: any) => {
    setInputTypeOf(e);
  };
  const handleRadio = (e: any) => {
    setRadioValue(e.target.value);
  };
  const handleOnChangeDate = (date: any, dateString: any) => {
    setInputDate(dateString);
  };
  const handleSendRequest = () => {
    switch (adjustFor) {
      case 'increase':
        if (Number(input_newsalary) >= Number(input_current)) {
          setCallAPiSendRequest();
        }
        break;
      case 'decrease':
        if (Number(input_newsalary) <= Number(input_current)) {
          setCallAPiSendRequest();
        }
        break;
    }
  };
  const setCallAPiSendRequest = () => {
    dispatch(
      postEditSchedule({
        adjustFor: adjustFor,
        typeOfPremium: input_TypeOf,
        newSalaryPremium: String(input_newsalary),
        newValidDate: formatISDate(input_date),
        status: labelKeyStatus.KEY_WAITING_FOR_APPROVAL,
        note: input_note,
        typeInsurance: checkedList,
        userId: itemId?.user?.id,
        createdBy: getUser()?.fullName,
        id: itemId?.id,
        callBack: (response: any) => {
          if (!response.error) {
            props.reSetData && props.reSetData();
            handleClose();
          }
        },
      }),
    );
  };
  const handleSaveDraft = () => {
    switch (adjustFor) {
      case 'increase':
        if (Number(input_newsalary) >= Number(input_current)) {
          setCallAPiSaveDraftt();
        }
        break;
      case 'decrease':
        if (Number(input_newsalary) <= Number(input_current)) {
          setCallAPiSaveDraftt();
        }
        break;
    }
  };
  const setCallAPiSaveDraftt = () => {
    dispatch(
      postEditSchedule({
        adjustFor: adjustFor,
        typeOfPremium: input_TypeOf,
        newSalaryPremium: String(input_newsalary),
        newValidDate: formatISDate(input_date),
        status: labelKeyStatus.KEY_DRAFT,
        note: input_note,
        typeInsurance: checkedList,
        userId: itemId?.user?.id,
        createdBy: getUser()?.fullName,
        id: itemId?.id,
        callBack: (response: any) => {
          if (!response.error) {
            props.reSetData && props.reSetData();
            handleClose();
          }
        },
      }),
    );
  };

  const onFinish = (values: any) => {
    console.log(values);
  };
  const handleNewSalary = (value: any) => {
    setInputNewSalary(value);
  };
  const onChange = (e: any) => {
    setChecobox(e);
    setCheckedList(e);
  };
  const getClickCheckHistory = () => {
    setCheckHistory(!checkHistory);
  };
  const validatorNewPremium = () => {
    return (
      <Form.Item
        name={labelMessage.MESSAGE_NEW_SALARY}
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value) {
              let srt: any = value === undefined ? Number(itemId?.newSalaryPremium) : Number(value);
              let is_str: any = Number(input_current);
              switch (titleType) {
                case createScheduleInsurances.INCREASE_PREMIUM:
                  if (srt === 0) {
                    return Promise.reject(new Error(t('new_salary_required')));
                  } else if (srt >= is_str) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(new Error(t('salary_greater_current')));
                  }
                case createScheduleInsurances.DECREASE_PREMIUM:
                  if (srt === 0) {
                    return Promise.reject(new Error(t('new_salary_required')));
                  } else if (srt <= is_str) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(new Error(t('salary_smaller_current')));
                  }
              }
            },
          }),
        ]}
        style={{ margin: 0 }}
      >
        <InputNumber
          defaultValue={input_newsalary}
          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
          onChange={handleNewSalary}
          style={{ width: '100%', height: 32, fontSize: 14, borderRadius: 6 }}
          maxLength={16}
          // type={'number'}
        />
      </Form.Item>
    );
  };

  return (
    <>
      <InsuranceModal
        title={labelInsurancesComplusory.SCHEDULE + itemId?.user?.fullName}
        visible={isVisible}
        maskClosable={false}
        onCancel={handleClose}
        width={iswidth}
        style={{ top: 40 }}
        footer={null}
      >
        <Form form={form} name="control-hooks" onFinish={onFinish} style={{ width: '100%' }}>
          <Container style={{ marginRight: '-5%', marginLeft: '-4%' }}>
            <ContainerTop type={itemId?.status}>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <div style={{ display: 'flex' }}>
                    <InfoTitle style={{ marginTop: 15 }}>{t('status')} :</InfoTitle>
                    <InfoText type={itemId?.status}>{itemId?.status}</InfoText>
                  </div>
                </Col>
              </Row>
              <InfoRow gutter={[16, 16]}>
                <Col span={12}>
                  <InfoTitle>{t('name')}</InfoTitle>
                  <InfoInput
                    value={itemId?.user?.fullName}
                    disabled
                    style={{ background: colors.gray300 }}
                    onChange={(e: any) => setInputID(e.target.value)}
                    type="text"
                  />
                </Col>
                <Col span={12}>
                  <InfoTitle>{createScheduleInsurances.ID}</InfoTitle>
                  <InfoInput
                    value={itemId?.user?.employeeId}
                    disabled
                    style={{ background: colors.gray300 }}
                    onChange={(e: any) => setInputID(e.target.value)}
                    type="text"
                  />
                </Col>
              </InfoRow>
              <InfoRow gutter={[16, 16]}>
                <Col span={12}>
                  <InfoTitle>{t('position')}</InfoTitle>
                  <InfoInput
                    value={itemId?.user?.positionName}
                    disabled
                    style={{ background: colors.gray300 }}
                    onChange={(e: any) => setInputID(e.target.value)}
                    type="text"
                  />
                </Col>
                <Col span={12}>
                  <InfoTitle>{t('social_insurance_number')}</InfoTitle>
                  <InfoInput
                    value={itemId?.user?.socialInsuranceNumber}
                    disabled
                    style={{ background: colors.gray300 }}
                    onChange={(e: any) => setInputID(e.target.value)}
                    type="text"
                  />
                </Col>
              </InfoRow>
              <InfoRow gutter={[16, 16]}>
                <Col span={12}>
                  <InfoTitle>{t('current_salary_premium')}</InfoTitle>
                  <InfoInput
                    value={formatCurrencyUnit(itemId?.currentSalaryPremium, labelUnit)}
                    disabled
                    style={{ background: colors.gray300 }}
                    onChange={(e: any) => setInputID(e.target.value)}
                    type="text"
                  />
                </Col>
                <Col span={12}>
                  <InfoTitle>{t('last_join_social_insurance_date')}</InfoTitle>
                  <InfoInput
                    value={
                      itemId?.currentValidDate ? formatDateInsurances(itemId?.currentValidDate) : ''
                    }
                    disabled
                    style={{ background: colors.gray300 }}
                    onChange={(e: any) => setInputID(e.target.value)}
                    type="text"
                  />
                </Col>
              </InfoRow>
              <InfoRow gutter={[16, 16]}>
                <Col span={12}>
                  <InfoTitle>{t('adjust_for')}</InfoTitle>
                  <InfoRadio.Group onChange={handleRadio} value={radiovalue}>
                    <InfoRadio value={createScheduleInsurances.INCREASE_PREMIUM}>
                      {t('increase_premium')}
                    </InfoRadio>
                    <InfoRadio
                      value={createScheduleInsurances.DECREASE_PREMIUM}
                      style={{ marginTop: 6 }}
                      disabled={itemId?.currentSalaryPremium > 0 ? false : true}
                    >
                      {t('decrease_premium')}
                    </InfoRadio>
                  </InfoRadio.Group>
                </Col>
                <Col span={12}>
                  <InfoTitle>{t('insurance_policy')}</InfoTitle>
                  <div style={{ height: 80, width: '100%', overflowY: 'scroll' }}>
                    <div style={{ padding: 10 }}>
                      <Checkbox.Group onChange={onChange} value={checkedList}>
                        {data !== undefined
                          ? data.map((item: any) => (
                              <InfoCheckbox
                                key={item?.id}
                                style={{ margin: -0.5 }}
                                value={item?.insuranceCode}
                              >
                                {item?.insuranceName}
                              </InfoCheckbox>
                            ))
                          : null}
                      </Checkbox.Group>
                    </div>
                  </div>
                </Col>
              </InfoRow>
              <InfoRow gutter={[16, 16]}>
                <Col span={24}>
                  <InfoTitle>
                    {t('type_of') + ' ' + textTitle} <InfoU>*</InfoU>
                  </InfoTitle>
                  <InfoSelect
                    defaultValue={input_TypeOf}
                    value={input_TypeOf}
                    onChange={handleChange}
                  >
                    {is_dataTypeOfIncrease &&
                      is_dataTypeOfIncrease.map((item: any) => (
                        <Option value={item} key={item}>
                          {stringCamelCase(item)}
                        </Option>
                      ))}
                  </InfoSelect>
                </Col>
              </InfoRow>
              <InfoRow gutter={[16, 16]}>
                <Col span={12}>
                  <InfoTitle>
                    {t('new_salary_premium')} <InfoU>*</InfoU>
                  </InfoTitle>
                  {validatorNewPremium()}
                </Col>
                <Col span={12}>
                  <InfoTitle>
                    {t('new_valid_date')} <InfoU>*</InfoU>
                  </InfoTitle>
                  <Form.Item
                    name={t('new_valid_date')}
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value1) {
                          let srt: any = value1 === undefined ? input_date : value1;
                          if (srt !== null && srt !== 'Invalid date') {
                            return Promise.resolve();
                          } else {
                            return Promise.reject(new Error(t('new_valid_date_required')));
                          }
                        },
                      }),
                    ]}
                    style={{ margin: 0 }}
                  >
                    <InfoDatePicker
                      defaultValue={input_date}
                      onChange={(date, dateString) => handleOnChangeDate(date, dateString)}
                      format={'DD/MM/YYYY'}
                    />
                  </Form.Item>
                </Col>
              </InfoRow>
              <InfoRow gutter={[16, 16]}>
                <Col span={24}>
                  <InfoTitle>{t('note')}</InfoTitle>
                  <TextArea
                    rows={2}
                    value={input_note}
                    onChange={(e: any) => setInputNote(e.target.value)}
                    placeholder={'Note'}
                    style={{
                      padding: 6,
                      width: '100%',
                      borderRadius: 6,
                      color: '#111827',
                    }}
                  />
                  {/*</Form.Item>*/}
                </Col>
              </InfoRow>
              {props.itemId && (
                <div style={{ marginTop: -6 }}>
                  <CustomButton
                    aType="text"
                    style={{
                      backgroundColor: '#fff',
                      boxShadow: 'none',
                      marginTop: 10,
                    }}
                    onClick={() => getClickCheckHistory()}
                  >
                    {checkHistory === false ? (
                      <div style={{ display: 'flex', marginLeft: -12 }}>
                        <InfoTitle style={{ color: '#F1592A', margin: 0, marginTop: 3 }}>
                          {t('view_history')}
                        </InfoTitle>
                      </div>
                    ) : (
                      <div style={{ display: 'flex', marginLeft: -12 }}>
                        <InfoTitle style={{ color: '#F1592A', margin: 0, marginTop: 3 }}>
                          {t('hide_history')}
                        </InfoTitle>
                      </div>
                    )}
                  </CustomButton>
                </div>
              )}
              {checkHistory === false ? null : itemId?.status === labelKeyStatus.KEY_REJECTED ? (
                <div style={{ height: 400, width: '100%', marginTop: 20 }}>
                  <ModalHistory itemId={props.itemId} />
                </div>
              ) : null}
            </ContainerTop>
            <ContainerButton style={{ marginTop: 15 }}>
              <InfoButtonReject
                disabled={buttondisabled}
                htmlType="submit"
                onClick={handleSaveDraft}
              >
                {t('save_draft')}
              </InfoButtonReject>

              {buttondisabled === false ? (
                <InfoButton
                  style={{ marginRight: 35 }}
                  onClick={handleSendRequest}
                  htmlType="submit"
                >
                  {t('send_request')}
                </InfoButton>
              ) : (
                <InfoButton
                  style={{ marginRight: 35, background: '#eab0b0', color: '#ffffff' }}
                  disabled
                  onClick={handleSendRequest}
                  htmlType="submit"
                >
                  {t('send_request')}
                </InfoButton>
              )}
            </ContainerButton>
          </Container>
        </Form>
      </InsuranceModal>
    </>
  );
};

export default memo(ModalEdit);
export const EmployeeAddModalContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  height: 100%;
  margin-right: -15px;
`;
interface InfoTextProps {
  type?: string;
  al?: boolean;
}
export const ContainerTop = styled.div`
  height: 92%;
  margin-top: -10px;
  ${(props: InfoTextProps) => {
    // @ts-ignore
    switch (props?.type) {
      case labelKeyStatus.KEY_REJECTED:
        return css`
          overflow-y: scroll;
          overflow-x: hidden;
          padding-right: 4%;
        `;
      default:
        return css`
          padding-right: 4%;
        `;
    }
  }};
`;

export const Container = styled.div`
  height: 620px;
  padding: 5%;
`;
export const ContainerButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
export const InfoButton = styled(Button)`
  height: 32px;
  background: ${colors.primary500};
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto;
  border-radius: 6px;
  margin: 0px 15px 0px 4px;
`;

export const EmployeeAddModalDivider = styled(Divider)`
  height: 752px;
  border-left-width: 5px;
  border-radius: 2px;
`;

export const EmployeeModalForm = styled(Form)`
  padding-top: 15px;
  padding-right: 15px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 752px;

  &::-webkit-scrollbar-track {
    margin-top: 20px;
  }

  &::-webkit-scrollbar {
    background-color: var(--cl_white);
  }
`;

export const ContainerLoading = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

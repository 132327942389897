import { Col, Radio, Row, InputNumber, Select } from 'antd';
import { DatePickerField, InputField, SelectField } from 'components/FormFields';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import CertificationsField from './CertificationsField';
import SkillsField from './SkillsField';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface IItemInput {
  label: string;
  name: string;
  options?: Array<any>;
  fieldType: 'select' | 'input' | 'datePicker' | 'radio' | 'certifications' | 'skills' | 'salary';
}

const Skills = ({ handleChange, data, visible }) => {
  const { t } = useTranslation(['recruitment', 'action']);
  const methods = useForm({
    resolver: undefined,
    defaultValues: data || {},
  });

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (data) reset(data || {});
  }, [data, reset]);

  const onSubmit = (data: any) => {
    handleChange && handleChange(data);
  };
  const itemsInputSkill: Array<IItemInput> = [
    {
      label: t('recruitment:skills'),
      name: 'skills',
      fieldType: 'skills',
    },
  ];

  const renderItemEdit = (
    fieldType: 'select' | 'input' | 'datePicker' | 'radio' | 'certifications' | 'skills' | 'salary',
    item: IItemInput,
  ) => {
    switch (fieldType) {
      case 'select':
        return (
          <SelectField
            disabled={!visible}
            control={control}
            options={item?.options || []}
            name={item.name}
            onChange={handleSubmit(onSubmit)}
          />
        );
      case 'input':
        return (
          <InputField
            disabled={!visible}
            control={control}
            name={item.name}
            onChange={handleSubmit(onSubmit)}
          />
        );
      case 'datePicker':
        return (
          <DatePickerField
            disabled={!visible}
            style={{ width: '100%' }}
            control={control}
            name={item.name}
            onChange={handleSubmit(onSubmit)}
          />
        );
      case 'radio':
        return (
          <Controller
            name={item.name}
            control={control}
            render={({ field }) => (
              <Radio.Group
                {...field}
                disabled={!visible}
                options={item?.options || []}
                onChange={handleSubmit(onSubmit)}
              />
            )}
          />
        );
      case 'certifications':
        return (
          <CertificationsField
            disabled={!visible}
            name={item.name}
            onChange={handleSubmit(onSubmit)}
          />
        );
      case 'skills':
        return (
          <SkillsField disabled={!visible} name={item.name} onChange={handleSubmit(onSubmit)} />
        );
      case 'salary':
        return (
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Controller
              name={item.name}
              control={control}
              render={({ field }) => (
                <InputNumber
                  {...field}
                  disabled={!visible}
                  style={{ width: '100%' }}
                  min={0}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                  onChange={(value) => {
                    field.onChange(value);
                    handleSubmit(onSubmit)();
                  }}
                />
              )}
            />
            <Controller
              name={`currencySymbol`}
              control={control}
              defaultValue="VND"
              render={({ field }) => (
                <Select
                  {...field}
                  disabled={!visible}
                  style={{ width: 100 }}
                  options={[
                    { label: 'USD', value: 'USD' },
                    { label: 'VND', value: 'VND' },
                  ]}
                  onChange={(value) => {
                    field.onChange(value);
                    handleSubmit(onSubmit)();
                  }}
                />
              )}
            />
          </div>
        );
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row gutter={64}>
          <Col span={24}>
            {itemsInputSkill.map((item) => (
              <Row key={item.name} style={{ marginBottom: 8 }}>
                <Col span={8}>
                  <label>{item.label}</label>
                </Col>
                <Col span={16}>{renderItemEdit(item.fieldType, item)}</Col>
              </Row>
            ))}
          </Col>
        </Row>
      </form>
    </FormProvider>
  );
};

export default Skills;

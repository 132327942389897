import React from 'react';
import { Col } from 'antd';
import KingworkIconWhite from 'assets/icons/kingworkiconwhite.png';
import PhoneWhite from 'assets/icons/phonewhite.png';
import MailWhite from 'assets/icons/mailwhite.png';
import MapWhite from 'assets/icons/mapwhite.png';
import FacebookIcon from 'assets/icons/facebookicon.png';
import InlinkIcon from 'assets/icons/inlinkicon.png';
import YoutubeIcon from 'assets/icons/youtubeicon.png';

import {
  ImageStyled,
  FooterTopStyled,
  FooterButtomStyled,
  FooterTitleStyled,
  FooterLiStyled,
} from './styled';

const AuthFooter = () => {
  return (
    <>
      <FooterTopStyled>
        <Col md={8} xs={6} style={{ padding: 10 }}>
          <div style={{ width: 183, height: 32, marginBottom: 10 }}>
            <ImageStyled src={KingworkIconWhite} alt="logo" style={{ width: 183, height: 32 }} />
          </div>
          <FooterLiStyled>
            Strive for the creation, innovation, development advanced solutions
          </FooterLiStyled>
          <div style={{ display: 'flex', paddingBottom: 2 }}>
            <div style={{ marginRight: 10 }}>
              <ImageStyled src={PhoneWhite} alt="logo" />
            </div>
            <FooterLiStyled>+84 (028) 3999-1895</FooterLiStyled>
          </div>
          <div style={{ display: 'flex', paddingBottom: 2 }}>
            <div style={{ marginRight: 10 }}>
              <ImageStyled src={MailWhite} alt="logo" />
            </div>
            <FooterLiStyled>info@innotech-vn.com</FooterLiStyled>
          </div>
          <div style={{ display: 'flex', paddingBottom: 2 }}>
            <div style={{ marginRight: 10 }}>
              <ImageStyled src={MapWhite} alt="logo" />
            </div>
            <FooterLiStyled>33 Ba Vi Street, Ward 4, Tan Binh District, HCMC.</FooterLiStyled>
          </div>
        </Col>
        <Col md={16} xs={16} style={{ display: 'flex' }}>
          <Col md={5} xs={5} style={{ padding: 10 }}>
            <FooterTitleStyled>Product</FooterTitleStyled>
            <FooterLiStyled>Personnel</FooterLiStyled>
            <FooterLiStyled>Timesheet</FooterLiStyled>
            <FooterLiStyled>Product</FooterLiStyled>
            <FooterLiStyled>Product</FooterLiStyled>
            <FooterLiStyled>Product</FooterLiStyled>
          </Col>
          <Col md={5} xs={5} style={{ padding: 10 }}>
            <FooterTitleStyled>Solutions</FooterTitleStyled>
            <FooterLiStyled>Time tracking</FooterLiStyled>
            <FooterLiStyled>Reporting</FooterLiStyled>
            <FooterLiStyled>Time tracking</FooterLiStyled>
          </Col>
          <Col md={5} xs={5} style={{ padding: 10 }}>
            <FooterTitleStyled>Intergrations</FooterTitleStyled>
            <FooterLiStyled>Jira</FooterLiStyled>
            <FooterLiStyled>Microsoft 365</FooterLiStyled>
            <FooterLiStyled>Gmail</FooterLiStyled>
          </Col>
          <Col md={5} xs={5} style={{ padding: 10 }}>
            <FooterTitleStyled>Learn</FooterTitleStyled>
            <FooterLiStyled>Guide line</FooterLiStyled>
            <FooterLiStyled>Product update</FooterLiStyled>
          </Col>
          <Col md={5} xs={5} style={{ padding: 10 }}>
            <FooterTitleStyled>Company</FooterTitleStyled>
            <FooterLiStyled>About us</FooterLiStyled>
            <FooterLiStyled>Pricing</FooterLiStyled>
            <FooterLiStyled>Tour</FooterLiStyled>
            <FooterLiStyled>Demo</FooterLiStyled>
            <FooterLiStyled>Contact</FooterLiStyled>
          </Col>
        </Col>
      </FooterTopStyled>
      <FooterButtomStyled>
        <div style={{ marginLeft: 60, display: 'flex', alignItems: 'center', width: '85%' }}>
          <div style={{ color: '#fff', padding: 10 }}>© 2021 Kingwork</div>
          <div style={{ color: '#fff' }}>|</div>
          <div style={{ color: '#fff', padding: 10 }}>Terms</div>
          <div style={{ color: '#fff' }}>|</div>
          <div style={{ color: '#fff', padding: 10 }}>Privacy</div>
          <div style={{ color: '#fff' }}>|</div>
          <div style={{ color: '#fff', padding: 10 }}>Cookies</div>
          <div style={{ color: '#fff' }}>|</div>
          <div style={{ color: '#fff', padding: 10 }}>Sitemap</div>
        </div>
        <div style={{ width: '15%', display: 'flex', alignItems: 'center' }}>
          <div style={{ width: 40, height: 22 }}>
            <a href="#facebook">
              <ImageStyled src={FacebookIcon} alt="facebook" />
            </a>
          </div>
          <div style={{ width: 40, height: 22 }}>
            <a href="#inlink">
              <ImageStyled src={InlinkIcon} alt="inlink" />
            </a>
          </div>
          <div style={{ width: 40, height: 22 }}>
            <a href="#youtube">
              <ImageStyled src={YoutubeIcon} alt="youtube" />
            </a>
          </div>
        </div>
      </FooterButtomStyled>
    </>
  );
};

export default AuthFooter;

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'app/hooks';
import { Badge, Skeleton } from 'antd';
import EmployeeColumn from 'components/EmployeeColumn';
import ITVTable from 'components/ITVTable';
import { TIMEZONE_DEFAULT } from 'constants/commons';
import { selectConfiguration } from 'features/configuration/configurationSlice';
import { selectEmployee } from 'features/employee/employeeSlice';
import moment from 'moment';
import { getUser } from 'utils/auth';
import { Container } from './styled';

const ConfirmPreview = () => {
  const { t } = useTranslation(['leaveOff']);
  const dataStore = useAppSelector(selectConfiguration);
  const employee = useAppSelector(selectEmployee);
  const selectRowKey = employee.selectRowKey;
  const loading = dataStore.isLoading;
  const previewData = dataStore.previewData;
  const [dataPreview, setDataPreview] = useState<any>({});
  const [currentPage, setCurrentPage] = useState(1);
  const [sizePage, setSizePage] = useState(10);
  const [data, setData] = useState<any>([]);
  const timeZone = getUser().timeZone ? getUser().timeZone : TIMEZONE_DEFAULT;
  //Set default timezone in server
  moment.tz.setDefault(timeZone);

  useEffect(() => {
    if (previewData) {
      setDataPreview(previewData);
    }
  }, [previewData]);

  useEffect(() => {
    if (selectRowKey.length)
      setData(
        previewData?.userDetails?.filter((value: any) =>
          selectRowKey?.includes(value?.previewUserLeaveOffChoose?.userId),
        ),
      );
    else setData([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectRowKey]);

  const countRequestLeave = (array: any, name: string) => {
    return array?.filter((obj: any) => obj?.status === name).length;
  };

  let COLUMNS: any = [
    {
      title: t('insurance:is_code'),
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      ellipsis: true,
      width: 60,
      render: function (text: any, record: any, index: any) {
        return <div>{record?.previewUserLeaveOffChoose?.employeeId}</div>;
      },
    },
    {
      title: t('insurance:Employee'),
      dataIndex: 'fullName',
      key: 'fullName',
      width: 200,
      ellipsis: true,
      render: function (text: any, record: any, index: any) {
        return (
          <EmployeeColumn
            avatar={record?.previewUserLeaveOffChoose?.fullName}
            fullName={record?.previewUserLeaveOffChoose?.fullName}
          />
        );
      },
    },
    {
      title: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <p style={{ marginBottom: '0px' }}>{dataPreview?.groupChoose?.nameType}</p>
          <p style={{ marginBottom: '0px' }}>{`${dataPreview?.groupChoose?.nameGroup}(${moment(
            dataPreview?.groupChoose?.startTime,
          ).format('YYYY/MM')}-${moment(dataPreview?.groupChoose?.endTime).format('YYYY/MM')})`}</p>
        </div>
      ),
      dataIndex: 'moveFrom',
      key: 'moveFrom',
      width: 120,
      ellipsis: true,
      render: function (text: any, record: any, index: any) {
        return (
          <div style={{ width: 150 }}>
            <p style={{ marginBottom: '0px' }}>{t('leaveOff:preview.after')}</p>
            <p style={{ marginBottom: '0px' }}>{`${t('leaveOff:preview.remaining_leave_days')} ${
              record?.previewUserLeaveOffChoose?.afterMove?.remain
                ? record?.previewUserLeaveOffChoose?.afterMove?.remain
                : 0
            }`}</p>
            <p style={{ marginBottom: '0px' }}>{`${t(
              'leaveOff:preview.leave_application_number',
            )}`}</p>
            <div>
              <Badge
                color="#F59E0B"
                text={`${t('leaveOff:preview.pending')} ${
                  countRequestLeave(
                    record?.previewUserLeaveOffChoose?.afterMove?.requestLeave,
                    'pending',
                  ) || 0
                }`}
              />
            </div>
            <div>
              <Badge
                color="#10B981"
                text={`${t('leaveOff:preview.approved')} ${
                  countRequestLeave(
                    record?.previewUserLeaveOffChoose?.afterMove?.requestLeave,
                    'approved',
                  ) || 0
                }`}
              />
            </div>
            <div>
              <Badge
                color="#EF4444"
                text={`${t('leaveOff:preview.rejected')} ${
                  countRequestLeave(
                    record?.previewUserLeaveOffChoose?.afterMove?.requestLeave,
                    'rejected',
                  ) || 0
                }`}
              />
            </div>
          </div>
        );
      },
    },
    {
      title: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <p style={{ marginBottom: '0px' }}>{dataPreview?.groupMove?.nameType}</p>
          <p style={{ marginBottom: '0px' }}>{`${dataPreview?.groupMove?.nameGroup}(${moment(
            dataPreview?.groupMove?.startTime,
          ).format('YYYY/MM')}-${moment(dataPreview?.groupMove?.endTime).format('YYYY/MM')})`}</p>
        </div>
      ),
      //align: 'center',
      dataIndex: 'moveTo',
      key: 'moveTo',
      width: 120,
      ellipsis: true,
      render: function (text: any, record: any, index: any) {
        return (
          <div style={{ width: 150 }}>
            <p style={{ marginBottom: '0px' }}>{t('leaveOff:preview.after')}</p>
            <p style={{ marginBottom: '0px' }}>{`${t('leaveOff:preview.remaining_leave_days')} ${
              record?.previewUserLeaveOffMove?.afterMove?.remain
                ? record?.previewUserLeaveOffMove?.afterMove?.remain
                : 0
            }`}</p>
            <p style={{ marginBottom: '0px' }}>{`${t(
              'leaveOff:preview.leave_application_number',
            )}`}</p>
            <div>
              <Badge
                color="#F59E0B"
                text={`${t('leaveOff:preview.pending')} ${
                  countRequestLeave(
                    record?.previewUserLeaveOffMove?.afterMove?.requestLeave,
                    'pending',
                  ) || 0
                }`}
              />
            </div>
            <div>
              <Badge
                color="#10B981"
                text={`${t('leaveOff:preview.approved')} ${
                  countRequestLeave(
                    record?.previewUserLeaveOffMove?.afterMove?.requestLeave,
                    'approved',
                  ) || 0
                }`}
              />
            </div>
            <div>
              <Badge
                color="#EF4444"
                text={`${t('leaveOff:preview.rejected')} ${
                  countRequestLeave(
                    record?.previewUserLeaveOffMove?.afterMove?.requestLeave,
                    'rejected',
                  ) || 0
                }`}
              />
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <Container>
      {loading ? (
        <Skeleton />
      ) : (
        <ITVTable
          columns={COLUMNS}
          data={data}
          width={978}
          isRowSelect={false}
          //totalIni={data.length}
          totalResult={data ? data.length : 0}
          limit={sizePage}
          setLimit={setSizePage}
          offset={currentPage}
          setOffset={setCurrentPage}
          // selectionType={'checkbox'}
        />
      )}
    </Container>
  );
};

export default ConfirmPreview;

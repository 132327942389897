import styled from 'styled-components';
import { Form, Checkbox } from 'antd';
import CustomButton from 'styles/buttonStyled';

export const ContainserStyled = styled.div`
  height: 100vh;
  width: 100%;
`;

export const FormStyled = styled(Form)`
  width: 100%;
`;

export const FormItemStyled = styled(Form.Item)`
  margin: 0px;
  margin-top: 6px;
`;

export const TitleStyled = styled.div`
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;
  text-align: center;
  letter-spacing: 0.5px;
  color: var(--cl_text_primary);
  &.top-up {
    margin-top: -20px;
  }
`;

export const RowStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  &.bettween {
    justify-content: space-between;
  }
  &.top-up {
    margin-top: 15px;
  }
  &.top-down {
    margin-top: -20px;
  }
  &.line-up {
    line-height: 32px;
  }
`;

export const CheckboxStyled = styled(Checkbox)`
  width: 65%;
`;

export const ForgotPasswordStyled = styled.a`
  width: 100%;
  text-align: right;
  cursor: pointer;
  &:hover {
    color: var(--cl_primary);
    text-decoration: underline;
  }
`;

export const LineStyled = styled.div`
  height: 1px;
  background-color: var(--cl_border_primary);
  width: 100%;
  opacity: 0.3;
`;

export const LoginOptionLabelStyled = styled.div`
  font-size: 1rem;
  color: var(--cl_text_primary);
  text-align: center;
  min-width: max-content;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`;

export const SignUpLinkStyled = styled.a`
  font-size: 1rem;
  text-align: center;
  min-width: max-content;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  padding-top: 6px;
  &:hover {
    color: var(--cl_primary);
    text-decoration: underline;
  }
`;

export const ImageStyled = styled.img`
  width: 1.5rem;
  height: 1.5rem;
`;

export const SloganTextStyled = styled.div`
  color: var(--cl_primary);
  font-size: 1rem;
`;

export const LogoStyled = styled.img`
  height: 1rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`;

export const CustomButtonStyled = styled(CustomButton)`
  width: 100%;
  font-size: 1rem !important;
  border-radius: 0.9rem !important;
  height: 40px !important;
  margin-top: 6px !important;
`;

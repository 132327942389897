import { notification } from 'antd';
import moment from 'moment';
import queryString from 'query-string';

import { ItemOverviewFiltersParams, RequestApprovalOverview, TimesheetYear } from 'types';

import { EmployeeTimesheetParams, EmployeeTimesheetResponse, SuccessResponse } from 'types';
import { fomatetoUTCString } from '../features/timesheet/contants/formatdate';
import axiosClient from './axiosClient';

const timesheetApi = {
  resetTotalDayLeaveOff: async (params: any) => {
    const response = axiosClient.put(`/timesheet/reset-day-leave-off`, params);
    return response;
  },
  apiManagerResetMonth: async (params: any) => {
    const response = await axiosClient.delete(`/timesheet/working-month/${params.id}`);
    return response;
  },
  apiManagerResetRequest: async (params: any) => {
    const response = await axiosClient.post(`/timesheet/manager-approval-request`, params);
    return response;
  },
  apiRequestPending: async (params: any) => {
    const response = await axiosClient.post(`/timesheet/manager-approved-request`, params);
    return response;
  },
  apiHandleRequestTimesheet: async (params: { token: string }) => {
    const response = await axiosClient.post(`/timesheet/manager-approval-request-token`, params);
    return response;
  },
  apiApproveMultiRequest: async (params: any) => {
    const response = await axiosClient.post(
      `/timesheet/manager-approval-request-multi-employee`,
      params,
    );
    return response;
  },
  apiCheckAutoCheckIn: async () => {
    const response = await axiosClient.get(`/configurations`);
    return response;
  },
  apiTotalHoursInWeek: async (params: any) => {
    const response = await axiosClient.get(
      `/timesheet/working-month/total-hours-in-week?${queryString.stringify(params)}`,
    );
    return response;
  },
  apiDayOfMonth: async (params: any) => {
    const response = await axiosClient.get(
      `/timesheet/working-month/day-of-month?${queryString.stringify(params)}`,
    );
    return response;
  },
  apiCancelRequest: async (params: any) => {
    const response = await axiosClient.post(`/timesheet/cancel-request`, params);
    return response;
  },
  apiInformTo: async (params: any) => {
    const response = await axiosClient.get(
      `/timesheet/working-month/inform-to?userId=${params.userId}&monthYear=${params.monthYear}&offset=${params.offset}&limit=${params.limit}`,
    );
    return response;
  },
  apiStatusApproved: async () => {
    const response = await axiosClient.get(`/employee?status=approved`);
    return response;
  },
  apiCreateApprovalRequest: async (params: any) => {
    const response = await axiosClient.post(`/timesheet/manager-approval-request`, params);
    return response;
  },
  remindStaffConfirm(body: {
    monthYear: string;
    userIds: string[];
  }): Promise<SuccessResponse<any>> {
    return axiosClient.post('/timesheet/working-month-remind/remind-staff-confirm', body);
  },
  confirmStaff(body: { monthYear: string; userIds: string[] }): Promise<SuccessResponse<any>> {
    return axiosClient.post('/timesheet/edit-list-timesheet-working-month', body);
  },
  apiEditRequestIdUser(params: {
    userIds: string[];
    status: string;
    reason?: string;
    monthYear: string;
  }): Promise<SuccessResponse<any>> {
    return axiosClient.post(`/timesheet/edit-list-timesheet-working-month`, params);
  },
  getHistoryWorkingMonth: async (params: { monthYear: string; status: string }) => {
    const data = {
      status: params.status,
      monthYear: params.monthYear,
    };
    const response = await axiosClient.get(
      `/timesheet/working-month/get-history-timesheet-working-month?status=${data.status}&monthYear=${data.monthYear}`,
    );
    return response;
  },
  putRequestLeave: async (params: any) => {
    const response = await axiosClient.put(`/timesheet/request-leave/${params.id}`, params);
    return response;
  },
  getRequestLeave: async (params: any) => {
    const response = await axiosClient.post(`/timesheet/request-leave`, params);
    return response;
  },
  getRequestLeaveType: async (params: any) => {
    const response = await axiosClient.get(`/timesheet/request-leave-type?id=${params.userId}`);
    return response;
  },
  putOvertimeRequest: async (params: any) => {
    const response = await axiosClient.put(`/timesheet/request-over-time/${params.id}`, params);
    return response;
  },
  getOvertimeRequest: async (params: any) => {
    const response = await axiosClient.post(`/timesheet/request-over-time`, params);
    return response;
  },
  GetListUserTime: async ({ cancelToken, ...params }: any) => {
    const response = await axiosClient.get(
      `/timesheet/working-month/request-approval-list?${queryString.stringify(params)}`,
    );
    return response;
  },
  getRequestApprovalList(
    params: ItemOverviewFiltersParams,
  ): Promise<SuccessResponse<{ data: RequestApprovalOverview[]; total: number }>> {
    return axiosClient.get('/dashboard/enhance-request-approval', {
      params: params,
    });
  },
  GetListUserTimeManager: async ({ cancelToken, ...params }: any) => {
    const response = await axiosClient.get(
      `/timesheet/working-month/manager-request-approval-list?${queryString.stringify(params)}`,
      { cancelToken: cancelToken?.token }, //Pass the cancel token to the current request
    );
    return response;
  },

  getRequestApprovalDetail: async (params: any) => {
    const response = await axiosClient.get(
      `timesheet/working-month/request-approval-detail?${queryString.stringify(params)}`,
    );
    return response;
  },
  getRequestPending: async (params: any) => {
    const response = await axiosClient.post(
      `timesheet/working-month/request-approval-list-mobile`,
      params,
    );
    return response;
  },
  getTimeTotalHour: async (params: any) => {
    const response = await axiosClient.post(`/timesheet/request-over-time/type-over-time`, params);
    notification.destroy();
    return response;
  },
  PostCheckInTime: async (idItem: any, dataRequest: any) => {
    const response = await axiosClient.post(`timesheet/working-time/${idItem.UserId}`, dataRequest);
    return response;
  },
  PutCheckOutTimeSheet: async (idItem: any, dataRequest: any) => {
    const response = await axiosClient.put(`/timesheet/working-time/${idItem.UserId}`, dataRequest);
    return response;
  },
  checkin: async ({ userId, ...params }: any) => {
    const response = await axiosClient.post(`timesheet/working-time/${userId}`, params);
    return response;
  },
  checkout: async ({ userId, ...params }: any) => {
    const response = await axiosClient.put(`timesheet/working-time/${userId}`, params);
    return response;
  },
  GetListCheckIn: async (dataRequest: any) => {
    const response = await axiosClient.get(
      `/timesheet/working-time/time-keeping?${queryString.stringify(dataRequest)}`,
    );
    return response;
  },
  getEmployeeTimesheet: async (params: any) => {
    const response = await axiosClient.get(
      `/timesheet/working-month/list-timesheet-employee?${queryString.stringify(params)}`,
    );
    return response;
  },
  getEmployeeTimesheetPagination: async ({ cancelToken, ...params }: any) => {
    const data = {
      monthYear: params.monthYear,
      offset: params.offset,
      limit: params.limit,
      ...params,
    };

    const response = await axiosClient.get(
      `/timesheet/working-month/get-list-timesheet-working-month?${queryString.stringify(data)}`,
      { cancelToken: cancelToken?.token }, //Pass the cancel token to the current request
    );
    return response;
  },
  getEmployeeTimesheetPaginationV2(
    params: EmployeeTimesheetParams,
  ): Promise<SuccessResponse<EmployeeTimesheetResponse>> {
    return axiosClient.get('/timesheet/working-month/rp-timesheet', {
      params: params,
    });
  },
  exportTimeSheet: async (params: any) => {
    try {
      let str: any = `Overview_Timesheet_${moment().format('YYYY-MM-DD HH:mm')}.xlsx`;
      const response: any = await axiosClient.get(
        `/timesheet/working-month/export-timesheet-overview?${queryString.stringify(params)}`,
        { responseType: 'blob' },
      );
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', str);
      document.body.appendChild(link);
      link.click();
      return response;
    } catch (error) {
      return error;
    }
  },
  exportTimeSheetDay: async (params: any) => {
    try {
      let str: any = `Timesheet_By_Actual_Workday_${moment().format('YYYY-MM-DD HH:mm')}.xlsx`;
      const response: any = await axiosClient.get(
        `/timesheet/working-month/export-timesheet-by-actual-day?${queryString.stringify(params)}`,
        { responseType: 'blob' },
      );
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', str);
      document.body.appendChild(link);
      link.click();
      return response;
    } catch (error) {
      return error;
    }
  },
  exportTimeSheetHours: async (params: any) => {
    try {
      let str: any = `Timesheet_By_Hours_${moment().format('YYYY-MM-DD HH:mm')}.xlsx`;
      const response: any = await axiosClient.get(
        `/timesheet/working-month/export-timesheet-by-hours?${queryString.stringify(params)}`,
        { responseType: 'blob' },
      );
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', str);
      document.body.appendChild(link);
      link.click();
      return response;
    } catch (error) {
      return error;
    }
  },
  exportTimeSheetCheckin: async (params: any) => {
    try {
      let str: any = `Timesheet_By_Check_in_out_${moment().format('YYYY-MM-DD HH:mm')}.xlsx`;
      const response: any = await axiosClient.get(
        `/timesheet/working-month/export-timesheet-by-check-in-out?${queryString.stringify(
          params,
        )}`,
        { responseType: 'blob' },
      );
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', str);
      document.body.appendChild(link);
      link.click();
      return response;
    } catch (error) {
      return error;
    }
  },
  exportTimeSheetAdjust: async (params: any) => {
    try {
      let str: any = `Timesheet_By_Adjust_${moment().format('YYYY-MM-DD HH:mm')}.xlsx`;
      const response: any = await axiosClient.get(
        `/timesheet/working-month/export-timesheet-by-adjust-time?${queryString.stringify(params)}`,
        { responseType: 'blob' },
      );
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', str);
      document.body.appendChild(link);
      link.click();
      return response;
    } catch (error) {
      return error;
    }
  },
  getTimesheetByUser: async (params: { monthYear: string; userId: string }) => {
    const data = {
      monthYear: params.monthYear,
      userId: params.userId,
    };
    const response = await axiosClient.get(
      `/timesheet/working-month/employee-timesheet-month?${queryString.stringify(data)}`,
    );
    return response;
  },
  createAdjustTime: async (params: any) => {
    const data = {
      workingTimeId: params.workingTimeId,
      reason: params.reason,
      startTime: fomatetoUTCString(params.startTime),
      endTime: fomatetoUTCString(params.endTime),
      status: params.status,
      userId: params.userId,
    };
    const response = await axiosClient.post(`/timesheet/request-adjust-time`, data);
    return response;
  },
  updateAdjustTime: async (params: any) => {
    const data = {
      workingTimeId: params.workingTimeId,
      reason: params.reason,
      startTime: fomatetoUTCString(params.startTime),
      endTime: fomatetoUTCString(params.endTime),
      status: params.status,
      userId: params.userId,
    };
    const response = await axiosClient.put(`/timesheet/request-adjust-time/${params.id}`, data);
    return response;
  },
  cancelOverTimeRequest: async (params: any) => {
    const response = await axiosClient.put(`/timesheet/request-over-time/${params.id}`);
    if (params.callBack) params.callBack();
    return response;
  },
  cancelLeaveTimeRequest: async ({ id }: any) => {
    const response = await axiosClient.delete(`/timesheet/request-leave/${id}`);
    return response;
  },
  cancelAdjustTimeRequest: async ({ id }: any) => {
    const response = await axiosClient.delete(`/timesheet/request-adjust-time/${id}`);
    return response;
  },
  createLeaveOff: async (params: any) => {
    const response = await axiosClient.post(`/timesheet/request-leave`, params);
    return response;
  },
  getLeaveOffById: async (params: any) => {
    const response = await axiosClient.get(`/timesheet/request-leave/${params.id}`);
    return response;
  },
  getAdjustTimeById: async (params: any) => {
    const response = await axiosClient.get(`/timesheet/request-adjust-time/detail/${params.id}`);
    return response;
  },
  createOverTime: async (params: any) => {
    const response = await axiosClient.post(`/timesheet/request-over-time`, params);
    return response;
  },
  getOverTimeById: async (params: any) => {
    const response = await axiosClient.get(`/timesheet/request-over-time/detail/${params.id}`);
    return response;
  },
  getAlertBeforeCreateOt: async (params: any) => {
    const response = await axiosClient.post(`/timesheet/alert-request-over-time`, params);
    return response;
  },
  confirmTimesheetByMonth(payload: {
    monthYear: string;
    status: string;
  }): Promise<SuccessResponse<any>> {
    return axiosClient.post(`/confirm/timesheet`, payload);
  },
  getEmployeeTimesheetByYear(payload: {
    monthYear: string;
    search?: string;
    orderBy?: string;
    typeSort?: string;
  }): Promise<SuccessResponse<TimesheetYear[]>> {
    return axiosClient.post(`/confirm/timesheet-year`, payload);
  },

  checkRemindAllTimesheet(monthYear: string): Promise<SuccessResponse<{ isRemindAll: boolean }>> {
    return axiosClient.get(`/timesheet/working-month-remind/check-remind`, {
      params: {
        monthYear,
      },
    });
  },

  exportTimesheetSummaryKeeping(params: {
    typeExport: string;
    dateRange: { start: string; end: string };
  }): Promise<SuccessResponse<any>> {
    return axiosClient.post(`/timesheet/summary-time-keeping/export`, params);
  },
};

export default timesheetApi;

import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosClient from 'api/axiosClient';
import { RootState } from 'app/store';
import {
  DATE_FORMAT,
  DATETIME_FORMAT,
  MONTH_FORMAT,
  DATEMONTH_FORMAT,
  DATE_PAYLOAD_FORMAT,
  HOUR_FORMAT,
  CURRENCY_FORMAT,
} from 'constants/commons';

export const saveCompanyInfo = createAsyncThunk(
  '/company',
  async ({ payload, callBack }: any, thunkApi) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.post(`/company`, payload);
      callBack && callBack(response.data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const getCompanyInfo = createAsyncThunk('/company', async (_: any, thunkApi) => {
  try {
    thunkApi.dispatch(setGlobalLoading({ loading: true }));
    const response: any = await axiosClient.get(`/company`);
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  } finally {
    thunkApi.dispatch(setGlobalLoading({ loading: false }));
  }
});

const appSlice = createSlice({
  name: 'app',
  initialState: {
    globalLoading: false,
    formatDate: DATE_FORMAT,
    formatDatetime: DATETIME_FORMAT,
    formatMonth: MONTH_FORMAT,
    formatDateMonth: DATEMONTH_FORMAT,
    formatDatePayload: DATE_PAYLOAD_FORMAT,
    formatHour: HOUR_FORMAT,
    currencyFormat: CURRENCY_FORMAT,
    companyInfo: null,
  },
  reducers: {
    setGlobalLoading: (state, action: PayloadAction<any>) => {
      state.globalLoading = action.payload.loading;
    },
    setFormatDatetime: (state, action: PayloadAction<any>) => {
      state.formatDate = action.payload.formatDate ? action.payload.formatDate : DATE_FORMAT;
      state.formatHour = action.payload.formatHour ? action.payload.formatHour : HOUR_FORMAT;
      state.formatDatetime = action.payload.formatDatetime
        ? action.payload.formatDatetime
        : DATETIME_FORMAT;
      state.formatMonth = action.payload.formatMonth ? action.payload.formatMonth : MONTH_FORMAT;
      state.formatDateMonth = action.payload.formatDateMonth
        ? action.payload.formatDateMonth
        : DATEMONTH_FORMAT;
      state.formatDatePayload = action.payload.formatDatePayload
        ? action.payload.formatDatePayload
        : DATE_PAYLOAD_FORMAT;
    },
    setFormatCurrency: (state, action: any) => {
      state.currencyFormat = action.payload.currencyFormat
        ? action.payload.currencyFormat
        : CURRENCY_FORMAT;
    },
    setCompanyInfo: (state, action: PayloadAction<any>) => {
      state.companyInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCompanyInfo.fulfilled, (state: any, action: any) => {
        state.companyInfo = action.payload;
      })
      .addCase(getCompanyInfo.rejected, (state: any, action: any) => {
        state.companyInfo = {};
      });
  },
});

export const selectGlobalLoading = (state: RootState) => state.app.globalLoading;
export const selectFormatDate = (state: RootState) => state.app.formatDate;
export const selectFormatHour = (state: RootState) => state.app.formatHour;
export const selectFormatDatetime = (state: RootState) => state.app.formatDatetime;
export const selectFormatMonth = (state: RootState) => state.app.formatMonth;
export const selectFormatDateMonth = (state: RootState) => state.app.formatDateMonth;
export const selectFormatDatePayload = (state: RootState) => state.app.formatDatePayload;

export const selectCurrencyFormat = (state: RootState) => state.app.currencyFormat;

export const selectApp = (state: RootState) => state.app;

export const { setGlobalLoading, setFormatDatetime, setFormatCurrency } = appSlice.actions;
export default appSlice.reducer;

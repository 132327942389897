import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Row, Col, Modal } from 'antd';
import Icon from '@ant-design/icons';
import IconSuccess from 'assets/icons/ic_success_orange.svg';
import IconCancel from 'assets/icons/ic_cancel_orange.svg';
import {
  SkillNameInput,
  MainSkillYoeLabel,
  MainSkillYoeInput,
  SkillDescInput,
  SkillDescButtonContainer,
  SkillDescButton,
} from '../styled';

import { INIT_MAIN_SKILL_ITEM } from 'features/employee/constants/common';
import { REGEX_NUMBER } from 'constants/regex';

const MainSkillFieldsAdd = (props: any) => {
  const {
    isCanUpdate,
    indexMainSkillItem,
    valueMainSkillItem,
    mainSkill,
    setMainSkill,
    onEmployeeUpdateMainSkill,
    setIsAddingMainSkill,
  } = props;
  const [form] = Form.useForm();
  const { t } = useTranslation(['validation', 'employee']);

  const [mainSkillItem, setMainSkillItem]: any = useState(INIT_MAIN_SKILL_ITEM);

  const initData = () => {
    const initValues = valueMainSkillItem ? valueMainSkillItem : INIT_MAIN_SKILL_ITEM;
    return initValues;
  };

  useEffect(() => {
    const initValues = initData();
    setMainSkillItem(initValues);
    form.setFieldsValue(initValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueMainSkillItem, form]);

  const onSaveMainSkill = () => {
    form.submit();
  };

  const onCancelAddMainSkill = () => {
    setMainSkillItem(INIT_MAIN_SKILL_ITEM);
    setIsAddingMainSkill(false);
  };

  const onFinish = (values: any) => {
    const udpMainSkillItem = { ...mainSkillItem, ...values };
    if (valueMainSkillItem) {
      const udpMainSkill = [...mainSkill];
      udpMainSkill[indexMainSkillItem] = udpMainSkillItem;
      setMainSkill(udpMainSkill);
      onEmployeeUpdateMainSkill(udpMainSkill);
    } else {
      const newMainSkill = [...mainSkill, udpMainSkillItem];
      setMainSkill(newMainSkill);
      onEmployeeUpdateMainSkill(newMainSkill);
    }
    setMainSkillItem(INIT_MAIN_SKILL_ITEM);
    setIsAddingMainSkill(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    Modal.error({
      title: t('modal:message_invalid'),
    });
  };

  return (
    <Form
      form={form}
      id="MainSkillForm"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={mainSkillItem}
    >
      <Row gutter={[16, 16]}>
        <Col md={24} xs={24}>
          {isCanUpdate && (
            <Row align="middle" gutter={8}>
              <Col md={11} xs={24}>
                <Form.Item
                  required={true}
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: t('HRM_VAD_REQUIRED', {
                        field: t('employee:skill_information_fields.main_skill_name'),
                      }),
                    },
                    {
                      max: 250,
                      message: t('HRM_VAD_MAXIMUM_CHARACTERS', {
                        field: t('employee:skill_information_fields.main_skill_name'),
                        numberLeast: 250,
                      }),
                    },
                    {
                      whitespace: true,
                      message: t('HRM_VAD_BLANK_CHARACTERS', {
                        field: t('employee:skill_information_fields.main_skill_name'),
                      }),
                    },
                  ]}
                >
                  <SkillNameInput
                    placeholder={t('employee:skill_information_fields.enter_your_skill')}
                  />
                </Form.Item>
              </Col>
              <Col md={5} xs={24}>
                <MainSkillYoeLabel>{`${t(
                  'employee:skill_information_fields.year_of_experience',
                )}:`}</MainSkillYoeLabel>
              </Col>
              <Col md={8} xs={24}>
                <Form.Item
                  required={true}
                  name="yearOfExperience"
                  rules={[
                    {
                      required: true,
                      message: t('HRM_VAD_REQUIRED', {
                        field: t('employee:skill_information_fields.year_of_experience'),
                      }),
                    },
                    {
                      pattern: new RegExp(REGEX_NUMBER),
                      message: t('HRM_VAD_FORMAT', {
                        field: t('employee:skill_information_fields.year_of_experience'),
                      }),
                    },
                    {
                      max: 2,
                      message: t('HRM_VAD_MAXIMUM_CHARACTERS', {
                        field: t('employee:skill_information_fields.year_of_experience'),
                        numberLeast: 2,
                      }),
                    },
                    {
                      whitespace: true,
                      message: t('HRM_VAD_BLANK_CHARACTERS', {
                        field: t('employee:skill_information_fields.year_of_experience'),
                      }),
                    },
                  ]}
                >
                  <MainSkillYoeInput
                    placeholder={t(
                      'employee:skill_information_fields.enter_year_year_of_experience',
                    )}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          <Form.Item
            name="description"
            rules={[
              {
                required: true,
                message: t('HRM_VAD_REQUIRED', {
                  field: t('employee:skill_information_fields.description'),
                }),
              },
              {
                max: 250,
                message: t('HRM_VAD_MAXIMUM_CHARACTERS', {
                  field: t('employee:skill_information_fields.description'),
                  numberLeast: 250,
                }),
              },
            ]}
          >
            <SkillDescInput
              placeholder={t('employee:skill_information_fields.description_your_skill')}
            ></SkillDescInput>
          </Form.Item>
          {isCanUpdate && (
            <SkillDescButtonContainer>
              <SkillDescButton
                onClick={onSaveMainSkill}
                icon={<Icon component={() => <img src={IconSuccess} alt="" />} />}
              ></SkillDescButton>
              <SkillDescButton
                onClick={onCancelAddMainSkill}
                icon={<Icon component={() => <img src={IconCancel} alt="" />} />}
              ></SkillDescButton>
            </SkillDescButtonContainer>
          )}
        </Col>
      </Row>
    </Form>
  );
};

export default MainSkillFieldsAdd;

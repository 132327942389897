import { Tabs } from 'antd';
import { HeaderTitle } from 'features/watchingList/components/styled';
import { useTranslation } from 'react-i18next';
import { Report } from './Report';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from 'app/hooks';
import { selectConfiguration } from 'features/configuration/configurationSlice';
import { useEffect, useState } from 'react';
import PageHeader from 'components/PageHeader';

const { TabPane } = Tabs;

const TaxReport = () => {
  const { t } = useTranslation(['report']);
  const location: any = useLocation();
  const menuStore = useAppSelector(selectConfiguration);
  const dataScreenManage = menuStore?.dataScreenManage?.result;
  const [titleHeader, setTitleHeader] = useState('');

  useEffect(() => {
    const temp = dataScreenManage
      ?.filter((item: any) => location.pathname === item.path)
      ?.map((item: any) => item.nameScreen);

    setTitleHeader(temp ? temp[0] : '');
  }, [dataScreenManage, location.pathname]);

  const tabList = [
    {
      key: 1,
      component: <Report data={true} />,
      tabName: 'Tax report',
    },
  ];

  return (
    <div>
      <PageHeader title={titleHeader} />

      <Tabs defaultActiveKey="1">
        {tabList.map((tab) => (
          <TabPane tab={tab.tabName} key={tab.key}>
            {tab.component}
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default TaxReport;

export const labelInsurancesComplusory = {
  TITLE: 'Compulsory Insurance List',
  CLOSE_NOW: 'Close now?',
  CREATE_A_SCHEDULE: 'Create a Schedule',
  SCHEDULE: 'Schedule / ',
  ARE_APPROVE: 'Are you sure to Approve this request?',
  ARE_AUTO_CREATE: 'Are you sure to Auto Create this request?',
  ARE_REJECT: 'Are you sure to Reject this request?',
  ARE_DELETE: 'Are you sure to Delete this request?',
  PLEASE_REJECTED_RECORD: 'Please enter the reason why you rejected this record',
};
export const TabInsurancesComplusory = {
  INCREASE_DECREASE: 'Schedule increase',
  SCHEDULE_INCREASE_DECREASE: 'Schedule decrease',
  PARTICIPANT_LIST: 'Participant list',
  RECORD_PAYMENT: 'Record payment',
  APPROVAL: 'Approval',
  ADJUSTMENT_HISTORY: 'Adjustment history',
  PAYMENT_HISTORY: 'Payment history',
};

export const labelInsurancesAddColumns = {
  SEARCH: 'Search',
  DONE: 'Done',
  CANCEL: 'Cancel',
  SELECT_ALL: 'Select all',
};
export const buttonInsurances = {
  VIEW_INFORMATION: 'View information',
  ADD_COLUMNS: 'Add Columns',
  CREATE: 'Create',
  EXPORT: 'Export',
  CLOSE: 'Close',
  CLOSED: 'Closed',
  ACTION: 'Action',
  SURE: 'Sure',
  REJECT: 'Reject',
  APPROVE: 'Approve',
  SAVE_DRAFT: 'Save draft',
  SEND_REQUEST: 'Send request',
  DELETE: 'Delete',
  EDIT: 'Edit',
  YES: 'Yes',
  NO: 'No',
  SEND: 'Send',
  RESET: 'Reset',
};
export const textInsurances = {
  TEXT_CLOSE: "If close, you can't change any information",
  CREATE: 'Create',
  EXPORT: 'Export',
  CLOSE: 'Close',
  ACTION: 'Action',
  SURE: 'Sure',
};
export const createScheduleInsurances = {
  ID: 'ID',
  POSITION: 'Position',
  CURRENT_SALARY: 'Current Salary Premium',
  ADJUST_FOR: 'Adjust for',
  NEW_SALARY: 'New Salary Premium',
  TYPE_INCREASE: 'Type of ',
  NAME: 'Name',
  SOCIAL_INSURANCE: 'Social Insurance Number',
  VALID_DATE: 'Valid date',
  INCREASE_PREMIUM: 'Increase premium',
  DECREASE_PREMIUM: 'Decrease premium',
  NEW_VALID_DATE: 'New valid date',
  NOTE: 'Note',
  STATUS: 'Status',
  EMPLOYEE: 'Employee',
  HAVE_INSURANCE: 'Have insurance?',
  SALARY_PREMIUM: 'Salary Premium',
  ALLOWANCES_PAYMENTS: 'Allowances & Additional payments',
  EMPLOYEE_CONTRIBUTE: 'Employee contribute',
  EMPLOYER_CONTRIBUTE: 'Employer contribute',
  SOCIAL_INSURANCE_IS: 'Social insurance',
  UNEMPLOYMENT_INSURANCE: 'Unemployment insurance',
  HEALTH_INSURANCE: 'Health insurance',
  REASON: 'Reason',
  DEPARTMENT: 'Department',
  TOTAL_CONTRIBUTE_EMPLOYEE: 'Total contribute of employee: ',
  TOTAL_CONTRIBUTE_EMPLOYER: 'Total contribute of employer: ',
  INSURANCE_POLICY: 'Insurance policy',
};

export const labelInsurancesColumnsTable = {
  ONBOARD_STATUS: 'Onboard status',
  HEALTH_OF_EMPLOYEE: 'Health insurance contribute of employee',
  HEALTH_CONTRIBUTE_EMPLOYEE: 'Health insurance contribute of employee',
  HEALTH_UNEMPLOYMENT: 'Unemployment insurance contribute of employer',
  HEALTH_UNEMPLOYMENT_EMPLOYEE: 'Unemployment insurance contribute of employee',
  HEALTH_SOCIAL: 'Social insurance contribute of employer',
  HEALTH_SOCIAL_EMPLOYEE: 'Social insurance contribute of employee',
  HEALTH_ALLOWANCES: 'Allowances & Additional payments',
  HEALTH_DEPARTMENT: 'Department',
};

export const labelSetting = {
  GENERAL_SETTING: 'General Setting',
  ADJUST_PREMIUM: 'Increase/ Decrease Premium',
};
export const labelMessage = {
  MESSAGE_NEW_SALARY: 'New Salary Premium is required.',
  MESSAGE_SALARY_GREATER: 'Salary greater current salary premium.',
  MESSAGE_SALARY_SMALLER: 'Salary smaller current salary premium.',
  MESSAGE_NEW_VALID_DATE: 'New valid date is required.',
};

export const statusSetting = {
  ACTIVE: 'Active',
  PENDING: 'Pending',
  INACTIVE: 'Inactive',
};

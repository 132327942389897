import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons';
import ITVTable from 'components/ITVTable';
import MenuAction from 'components/MenuAction';
import { MODE } from 'constants/types';
import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ContentCell from './ContentCell';
import React from 'react';

function TextWithLineBreaks({ children }) {
  const textWithBreaks = children?.split('\n')?.map((text, index) => (
    <React.Fragment key={index}>
      {text}
      <br />
    </React.Fragment>
  ));

  return <div>{textWithBreaks}</div>;
}

const PerformanceWeightTable = (props: any) => {
  const { t } = useTranslation(['performance', 'insurance', 'action']);
  const {
    control,
    error,
    getValues,
    setValue,
    section,
    data,
    isMainResponse,
    setMode,
    setCurrentItem,
    mainResponsibilities,
    deleteGoals,
    isEdit,
  } = props;

  const [columns, setColumns] = useState<any>([]);
  const [tableData, setTableData] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [sizePage, setSizePage] = useState(100);

  const MENU = [
    {
      name: t('insurance:edit'),
      icon: <EditTwoTone twoToneColor="#F1592A" />,
      handleClick: (value: any) => {
        setMode(MODE.EDIT);
        const selectedValue = mainResponsibilities.filter((ele: any) => ele.id === value.id)[0];
        setCurrentItem(selectedValue);
      },
    },
    {
      name: t('action:delete'),
      icon: <DeleteTwoTone twoToneColor="#B7B7B7" />,
      type: 'delete',
      handleClick: (value: any) => {
        const selectedValue = mainResponsibilities.filter((ele: any) => ele.id === value.id)[0];
        setCurrentItem(selectedValue);
        deleteGoals(selectedValue);
      },
      checkDisabled: () => (!isEdit ? !isEdit : false),
    },
  ];

  useEffect(() => {
    if (data) {
      // Extract unique goals using id as identifier
      const goals = Array.from(
        new Set(
          data?.flatMap((item: any) =>
            item?.step?.map((s: any) => ({ id: s?.id, goalName: s?.goalName })),
          ),
        ),
      );
      // Generate columns dynamically
      const col = [
        {
          title: t('performance:goals'),
          key: 'goalName',
          dataIndex: 'goalName',
          width: 200,
          align: 'center',
          render: (_: any, record: any) => {
            return `${record?.goalName} ${
              record?.weight ? `(${t('performance:weight')}: ${record?.weight}%)` : ''
            }`;
          },
        },
        ...data
          ?.map((i: any, idx: any) => ({ ...i, indexNew: idx }))
          ?.filter((field: any) => field?.isShow)
          .map((field: any, index: any) => ({
            title: () => <TextWithLineBreaks>{field?.stepName}</TextWithLineBreaks>,
            key: field?.stepName,
            dataIndex: field?.stepName,
            width: 200,
            align: 'center',
            render: (_: any, record: any) => {
              const step = field?.step?.find((s: any) => s?.id === record?.id) || {};
              return (
                <Controller
                  name={`${section}.data[${field?.indexNew}].step[${goals.findIndex(
                    (g: any) => g.id === record?.id,
                  )}]`}
                  control={control}
                  render={({ field: f }) => (
                    <ContentCell
                      editParent={`${section}.data[${field?.indexNew}].isEdit`}
                      rating={step.point}
                      content={step.commoment}
                      setValue={setValue}
                      nameRating={`${section}.data[${field?.indexNew}].step[${goals.findIndex(
                        (g: any) => g.id === record?.id,
                      )}].point`}
                      nameContent={`${section}.data[${field?.indexNew}].step[${goals.findIndex(
                        (g: any) => g.id === record?.id,
                      )}].commoment`}
                      disabled={!field?.allowEdit}
                    />
                  )}
                />
              );
            },
          })),
      ];

      if (isMainResponse) {
        col.push({
          title: t('action:action'),
          dataIndex: 'action',
          align: 'center',
          key: 'action',
          width: 50,
          minWidth: 50,
          render: function (text: any, record: any, index: any) {
            return <MenuAction menu={MENU} data={record} />;
          },
        });
      }

      // Prepare table data
      const tableData = goals?.map((goal: any) => {
        const row: any = { id: goal?.id, goalName: goal?.goalName };
        data
          ?.filter((field: any) => field?.isShow)
          ?.forEach((field: any) => {
            const step = field.step.find((s: any) => s.id === goal.id) || {};
            row[field.id] = {
              rating: step.point,
              content: step.commoment,
            };
            if (step.weight !== undefined) {
              row.weight = step.weight;
            }
          });
        return row;
      });

      const uniqueFieldIds = tableData.filter((item: any, index: any, self: any) => {
        const firstIndex = self.findIndex((i: any) => i.id === item.id);
        return firstIndex === index;
      });

      setColumns(col);
      setTableData(uniqueFieldIds);
    }
  }, [data, control, section, setValue, t]);

  return (
    <ITVTable
      data={tableData}
      columns={columns}
      isRowSelect={false}
      showFooter={false}
      limit={sizePage}
      setLimit={setSizePage}
      offset={currentPage}
      setOffset={setCurrentPage}
    />
  );
};

export default PerformanceWeightTable;

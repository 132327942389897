import ITVSearch from 'components/ITVSearch';
import ITVTable from 'components/ITVTable';
import { YYYY_MM_DD } from 'constants/commons';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Candidate {
  candidateName: string;
  email: string;
  phone: string;
  positionName: string;
  appliedDate: string;
  createdAt: string;
  candidateId: string;
}

interface ListCandidateProps {
  jobRequirements: string;
  candidates: Candidate[];
  questions: any[];
}

const ListCandidate: React.FC<ListCandidateProps> = ({ data }) => {
  const { t } = useTranslation(['recruitment', 'payroll', 'action']);
  const { candidates } = data;
  const [searchTerm, setSearchTerm] = useState('');

  const columns = [
    {
      title: t('recruitment:candidate_name'),
      dataIndex: 'candidateName',
      key: 'candidateName',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: t('recruitment:phone_number'),
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: t('recruitment:position_name'),
      dataIndex: 'positionName',
      key: 'positionName',
    },
    {
      title: t('payroll:status'),
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: t('recruitment:applied_date'),
      dataIndex: 'appliedDate',
      key: 'appliedDate',
      render: (date: string) => (date ? moment(date).format(YYYY_MM_DD) : ''),
    },
  ];

  const filteredCandidates = candidates?.filter(
    (candidate) =>
      candidate?.candidateName?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
      candidate?.email?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
      candidate?.phone?.includes(searchTerm) ||
      candidate?.positionName?.toLowerCase()?.includes(searchTerm?.toLowerCase()),
  );

  return (
    <div>
      <ITVSearch
        placeholder={t('recruitment:search_candidates')}
        handleSearch={(value) => setSearchTerm(value)}
        style={{ marginBottom: 16 }}
      />
      <ITVTable columns={columns} data={filteredCandidates} rowKey={['candidateId']} />
    </div>
  );
};

export default ListCandidate;

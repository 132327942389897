import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import equipmentApi from 'api/equipmentApi';
import { setGlobalLoading } from 'app/commonRedux/appSlice';

export const getEquipments = createAsyncThunk(
  'equipment/getEquipments',
  async (params: any, thunkApi) => {
    try {
      const response = await equipmentApi.getEquipments();
      params.callBack && params.callBack();
      return response.data;
    } catch (error) {
      return error;
    }
  },
);

export const createEquipment = createAsyncThunk(
  'equipment/addNew',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await equipmentApi.createEquipment(params);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      params.callBack && params.callBack(response.message);
      return response.data;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      params.callBack && params.callBack({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getEquipmentsByEmployee = createAsyncThunk(
  'equipment/getEquipmentsByEmployee',
  async (params: any, thunkApi) => {
    try {
      const response = await equipmentApi.getEquipmentsByEmployee(params);
      params.callBack && params.callBack(response.data);
      return response.data;
    } catch (error) {
      return error;
    }
  },
);

export const createEquipmentDetail = createAsyncThunk(
  'equipment/createEquipmentDetail',
  async ({ callBack, ...rest }: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await equipmentApi.createEquipmentDetail(rest);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack && callBack(response.data);
      return response.data;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack && callBack({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);
export const confirmEquipmentDetail = createAsyncThunk(
  'equipment/confirmEquipmentDetail',
  async ({ callBack, ...rest }: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await equipmentApi.confirmEquipmentDetail(rest);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack && callBack(response.data);
      return response.data;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack && callBack({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const updateEquipmentDetailStatus = createAsyncThunk(
  'equipment/updateEquipmentDetailStatus',
  async ({ callBack, ...params }: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await equipmentApi.updateEquipmentDetailStatus(params);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack && callBack(response.data);
      return response.data;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack && callBack({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const updateEquipmentDetail = createAsyncThunk(
  'equipment/updateEquipmentDetail',
  async ({ callBack, ...params }: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await equipmentApi.updateEquipmentDetail(params);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack && callBack(response.data);
      return response.data;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack && callBack({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const deleteEquipmentDetail = createAsyncThunk(
  'equipment/deleteEquipmentDetail',
  async ({ callBack, ...params }: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const deleteOne = (id: string) =>
        new Promise((resolve, reject) => {
          resolve(equipmentApi.deleteEquipmentDetail({ id }));
        });
      const response = await Promise.all(params.ids?.map((id: string) => deleteOne(id)))
        .then((values) => {
          callBack && callBack();
        })
        .catch((error) => {});
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return response;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack && callBack({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

const initialState = {
  isLoading: false,
  error: null,
  equipments: [],
  total: 0,
};

const equipmentSlice = createSlice({
  name: 'equipment',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getEquipments.pending, (state, action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getEquipments.fulfilled, (state, action: any) => {
        state.isLoading = false;
        state.error = null;
        state.equipments = action.payload.result;
        state.total = action.payload.total;
      })
      .addCase(getEquipments.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(createEquipment.pending, (state, action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createEquipment.fulfilled, (state, action: any) => {
        state.isLoading = false;
        state.error = null;
        let newEquipments: any = [...state.equipments, action.payload];
        state.equipments = newEquipments;
        state.total = state.total + 1;
      })
      .addCase(createEquipment.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getEquipmentsByEmployee.pending, (state, action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getEquipmentsByEmployee.fulfilled, (state, action: any) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(getEquipmentsByEmployee.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default equipmentSlice.reducer;

import styled from 'styled-components';
import { Modal, Form } from 'antd';

export const ModalStyled = styled(Modal)`
  .ant-modal-content {
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    box-sizing: border-box;
  }
  .ant-modal-body {
    padding: 6px;
  }
`;

export const ConatinerStyled = styled.div`
  width: 100%;
  height: 550px;
  background-color: white;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-right: 5.5%;
`;

export const ContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;

export const FormStyled = styled(Form)`
  width: 100%;
`;

import { Form, Modal } from 'antd';
import { DatePickerField, InputField, SelectField } from 'components/FormFields';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CustomButton from 'styles/buttonStyled';

interface IItemInput {
  label: string;
  name: string;
  options?: Array<any>;
  fieldType: 'select' | 'input' | 'datePicker';
}
const itemsInput: Array<IItemInput> = [
  {
    label: 'Job Template Name',
    name: 'name',
    fieldType: 'input',
  },
];

function ModalConfirm({
  visible,
  setVisible,
  callBack,
}: {
  visible: boolean;
  setVisible: React.SetStateAction<any>;
  callBack?: (value?: any) => void;
}) {
  const [appear, setAppear] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    setAppear(false);
    reset({ name: '' });
  }, [visible]);
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    resolver: undefined,
    defaultValues: {
      name: '',
    },
  });

  const { t } = useTranslation([
    'validation',
    'masterConfig',
    'setup_menu_screen',
    'insurance',
    'common',
    'overtime',
    'employee',
    'menu',
    'recruitment',
    'timesheet',
  ]);

  const renderItemEdit = (fieldType: 'select' | 'input' | 'datePicker', item: IItemInput) => {
    switch (fieldType) {
      case 'select':
        return <SelectField control={control} options={item?.options || []} name={item.name} />;
      case 'input':
        return <InputField control={control} name={item.name} />;
      case 'datePicker':
        return <DatePickerField style={{ width: '100%' }} control={control} name={item.name} />;
    }
  };

  const onSubmit = (data: any) => {
    form.validateFields().then((values) => {
      callBack && callBack(data?.name);
    });
  };
  return (
    <Modal
      open={visible}
      title={t('recruitment:job_tmplt_quest')}
      width={500}
      maskClosable={false}
      onCancel={() => {
        setVisible && setVisible(false);
      }}
      destroyOnClose
      centered
      footer={null}
    >
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 8 }}>
        <div style={{ display: 'flex', justifyContent: 'center', gap: 8 }}>
          <CustomButton
            onClick={() => {
              callBack && callBack();
            }}
          >
            {t('timesheet:No')}
          </CustomButton>
          <CustomButton onClick={() => setAppear(true)}>{t('timesheet:Yes')}</CustomButton>
        </div>
        {appear && (
          <>
            <Form form={form} style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
              {itemsInput.map((item) => (
                <>
                  <div style={{ display: 'flex' }}>
                    <span style={{ color: 'red', marginRight: 3 }}>* </span>
                    <div style={{ alignSelf: 'center' }}>{item.label + ':'}</div>
                  </div>

                  <Form.Item
                    name={item?.name}
                    rules={[
                      {
                        whitespace: true,
                        required: true,
                        message: t('HRM_VAD_REQUIRED', {
                          field: item?.label,
                        }),
                      },
                    ]}
                  >
                    {renderItemEdit(item.fieldType, item)}
                  </Form.Item>
                </>
              ))}
              <CustomButton
                onClick={handleSubmit(onSubmit)}
                style={{ width: 'fit-content', alignSelf: 'center' }}
              >
                {t('action:save')}
              </CustomButton>
            </Form>
          </>
        )}
      </div>
    </Modal>
  );
}

export default ModalConfirm;

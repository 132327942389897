import { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect, useLocation } from 'react-router-dom';
import 'app/i18n';

import GlobalStyles from '../styles/globalStyled';
import Backdrop from 'components/Backdrop';
import { useAppSelector } from './hooks';
import { selectGlobalLoading } from 'app/commonRedux/appSlice';
import LayoutDesktop from '../components/LayoutView/LayoutDesktop';
import { useTranslation } from 'react-i18next';
import { ConfigProvider } from 'antd';
import vi_VN from 'antd/lib/locale/vi_VN';
import en_US from 'antd/lib/locale/en_US';
import { isMobileOnly } from 'react-device-detect';
import LayoutMobile from '../components/LayoutView/LayoutMobile';
import moment from 'moment';
import 'moment/locale/vi';
import 'moment/locale/en-gb';

import SignIn from 'features/auth/pages/SignIn';
import PageRequestHandleTimesheet from 'features/requestTimesheet';
import { PERMISSION_DASHBOARD_ACCESS } from 'constants/permissions';
const SignUp = lazy(() => import('features/auth/pages/SignUp'));
const GetStarted = lazy(() => import('features/auth/pages/GetStarted'));
const PasswordReset = lazy(() => import('features/auth/pages/PasswordReset'));
const PageNotFound = lazy(() => import('components/PageNotFound'));

function App() {
  const iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

  // if (iOS) {
  //   // Just replace `https://` with `itms://` on your app's AppStore link.
  //   window.location.href = 'https://apps.apple.com/us/app/kingwork/id6445890275';
  // }

  // In this example I'm redirecting to Google Maps app page on AppStore.

  const colors = useAppSelector((state) => state.theme.colors);
  const globalLoading = useAppSelector(selectGlobalLoading);
  const user = useAppSelector((state) => state.auth?.user);
  const { i18n } = useTranslation();

  useEffect(() => {
    localStorage.setItem('hrm.language', 'en');
    const OneSignal = window.OneSignal || [];
    if (user && user.id) {
      OneSignal.push(function () {
        OneSignal.init({
          restKey: 'NWJmNzYyN2UtOWQ2OS00N2UwLThkYWUtZDg5NjRlMTIxYzFj',
          appId: '78aef314-3d6c-45dc-a61c-f34f724514f0',
        });

        OneSignal.on('notificationDisplay', function (event: any) {
          console.warn('OneSignal notification displayed:', event);
        });
      });
    }

    i18n.changeLanguage(localStorage.getItem('hrm.language') || 'en');
    moment.locale(localStorage.getItem('hrm.language') === 'vi' ? 'vi' : 'en-gb');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const OneSignal = window.OneSignal || [];
    if (user && user.id) {
      OneSignal.push(function () {
        OneSignal.on('popoverShown', function () {
          if (user && user.id) {
            console.log('popoverShown: ', user);
            OneSignal.setExternalUserId(user.id);
          }
        });
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const NotFoundRedirect = () => <Redirect to="/not-found" />;
  return (
    <ConfigProvider locale={localStorage.getItem('hrm.language') === 'vi' ? vi_VN : en_US}>
      <GlobalStyles colors={colors} />
      {globalLoading && <Backdrop />}

      <Router>
        <ScrollToTop />
        <Suspense fallback={null}>
          <Switch>
            {isMobileOnly ? (
              <Redirect exact from="/" to="/home" />
            ) : (
              <Redirect
                exact
                from="/"
                to={
                  user?.permissions?.includes(PERMISSION_DASHBOARD_ACCESS.DASHBOARD_ACCESS_HR)
                    ? '/dashboard'
                    : '/my-page/timesheet'
                }
              />
            )}
            <Route path="/login">
              <SignIn />
            </Route>
            <Route path="/register">
              <SignUp typeShow={'get_started'} />
            </Route>
            <Route path="/register-phone">
              <SignUp typeShow={'register_phone'} />
            </Route>
            <Route path="/phone-verify">
              <SignUp typeShow={'phone_verify'} />
            </Route>
            <Route path="/register-email">
              <SignUp typeShow={'register_email'} />
            </Route>
            <Route path="/email-verify">
              <SignUp typeShow={'email_verify'} />
            </Route>
            <Route path="/get-started">
              <GetStarted />
            </Route>
            <Route path="/verify-email">
              <GetStarted />
            </Route>
            <Route path="/password-reset">
              <PasswordReset isResetWithMail={false} />
            </Route>
            <Route path="/reset-pass">
              <PasswordReset isResetWithMail={true} />
            </Route>
            <Route path="/handle-request-timesheet">
              <PageRequestHandleTimesheet />
            </Route>
            <Route path="/not-found">
              <PageNotFound />
            </Route>
            {isMobileOnly ? <LayoutMobile /> : <LayoutDesktop />}
            <Route component={NotFoundRedirect} />
          </Switch>
        </Suspense>
      </Router>
    </ConfigProvider>
  );
}

export function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default App;

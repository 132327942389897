import axiosClient from './axiosClient';

const configurationApi = {
  getCategoryList: () => {
    const response = axiosClient.get('/configurations');
    return response;
  },
  getCategoryWithId: (idConfig: any) => {
    const response = axiosClient.get(`/configurations/detail/${idConfig}`);
    return response;
  },
  getCategoryWithType: (typeConfig: any) => {
    const response = axiosClient.get(`/configurations/config-type/${typeConfig}`);
    return response;
  },
  getListSetupWorkday: () => {
    const response = axiosClient.get('configurations/config-type/working_days_type');
    return response;
  },
  getListSetupHoliday: (year: any) => {
    const response = axiosClient.get(`configurations/config-type/holidays/${year}`);
    return response;
  },
  getConfigTypes: (params: any) => {
    const response = axiosClient.get(`/configurations/config-type/${params.type}`);
    return response;
  },
  getPositionManage: () => {
    const response = axiosClient.get('configurations/config-type/position');
    return response;
  },
  createDetailSetupWorkday: (datas: any) => {
    const { params, data } = datas.datas;
    const response = axiosClient.put(`configurations/update-working-days-type/2/${params}`, data);
    return response;
  },
  updateHolidays: (datas: any) => {
    const { configId, typeId, data } = datas.datas;
    const response = axiosClient.put(`configurations/update-holidays/${configId}/${typeId}`, data);
    return response;
  },

  // Leave off
  leaveOffGroupGetList: () => {
    const response = axiosClient.get(`configurations/get-config-leave-off`);
    return response;
  },
  leaveOffGroupGetListActive: () => {
    const response = axiosClient.get(`configurations/get-active-config-leave-off`);
    return response;
  },
  leaveOffGroupGet: (id: any) => {
    const response = axiosClient.get(`/timesheet/leave-off-type-group/${id}`);
    return response;
  },
  leaveOffGroupAdd: (data: any) => {
    const response = axiosClient.post(`/timesheet/create-new-leave-off-group`, data);
    return response;
  },
  leaveOffGroupDeactiveChangeType: (data: any) => {
    const response = axiosClient.post(`/configurations/create-new-and-deactive-group`, data);
    return response;
  },
  leaveOffGroupUpdate: (params: any) => {
    const response = axiosClient.put(
      `/configurations/group-leave-off-effective-time/update`,
      params,
    );
    return response;
  },
  leaveOffGroupDeactive: (params: any) => {
    const response = axiosClient.put(
      `/configurations/group-leave-off-effective-time/deactive`,
      params,
    );
    return response;
  },
  leaveOffGroupUpdateReview: (params: any) => {
    const data = {
      id: params.id,
      name: params.name,
      startTime: params.startTime,
      endTime: params.endTime,
    };
    const response = axiosClient.post(`configurations/update-group-effective-time/preview`, data);
    return response;
  },
  leaveOffGroupReviewDetail: (params: any) => {
    const data = {
      id: params.id,
      name: params.name,
      startTime: params.startTime,
      endTime: params.endTime,
    };
    const response = axiosClient.post(`configurations/detail-group-effective-time`, data);
    return response;
  },
  leaveOffGroupReviewDetailDeactiveFuture: (params: any) => {
    const data = {
      id: params.id,
      name: params.name,
      startTime: params.startTime,
      endTime: params.endTime,
    };
    const response = axiosClient.post(`configurations/detail-group-deactive`, data);
    return response;
  },
  leaveOffGroupAddItem: (data: any) => {
    const response = axiosClient.post(`/configurations/create-config-leave-off`, data);
    return response;
  },
  leaveOffGroupUpdateItem: (data: any) => {
    const response = axiosClient.put(`/configurations/update-config-leave-off`, data);
    return response;
  },
  leaveOffGetHistory: () => {
    const response = axiosClient.get(`/configurations/history-change-leave-off`);
    return response;
  },
  leaveOffGetHistoryDetails: (id: any) => {
    const response = axiosClient.get(`/configurations/history-change-leave-off-detail/${id}`);
    return response;
  },
  leaveOffDuplicate: (params: any) => {
    const { id, data } = params;
    const response = axiosClient.put(`/timesheet/duplicate-leave-off-type-group/${id}`, data);
    return response;
  },
  leaveOffMoveDate: () => {
    const response = axiosClient.get(`/configurations/group-leave-off`);
    return response;
  },
  reviewData: (params: any) => {
    const response = axiosClient.post('/configurations/preview-move-leave-off', params);
    return response;
  },
  submitData: (params: any) => {
    const response = axiosClient.post('/configurations/move-leave-off', params);
    return response;
  },
  getHistoryMoveData: () => {
    const response = axiosClient.get(`/configurations/history-move-leave-off`);
    return response;
  },

  getDetailJobTemplate: (params: any) => {
    const response = axiosClient.get(
      `/recruitment/configuration/job-template/detail/${params?.id}`,
    );
    return response;
  },

  getJobType: () => {
    const response = axiosClient.get(`/recruitment/configuration/job-type`);
    return response;
  },

  getDetailInterviewTemplate: (params: any) => {
    const response = axiosClient.get(
      `/recruitment/configuration/interview-template/detail/${params?.id}`,
    );
    return response;
  },

  getMachineData: (params: any) => {
    const response = axiosClient.get(`/check-in-out/configuration/${params?.type}`);
    return response;
  },
};

export default configurationApi;

export const customRichTextEditor = (editor, options) => {
  const { listVariableEmail } = options;
  const literalsSelectOpt = ` 
                              <select class="tree-select__type">
                              <option disabled style="color: gray" selected="selected">Please select variable</option>
                                ${
                                  listVariableEmail &&
                                  listVariableEmail.map(
                                    (variable) =>
                                      `${
                                        variable.children.length === 0 &&
                                        `<option value="${variable.value}" style="color: black">${variable.descriptions}</option>`
                                      }
                                    ${
                                      variable.children.length > 0 &&
                                      `<optgroup label="${
                                        variable.descriptions
                                      }" style="color: black">
                                      ${variable.children.map(
                                        (item) =>
                                          `<option value="${item.value}">${item.descriptions}</option>`,
                                      )}
                                    </optgroup>`
                                    }`,
                                  )
                                }`;

  editor?.RichTextEditor.add('SelectVariable', {
    icon: literalsSelectOpt,
    event: 'change',
    result: (rte, action) => {
      rte.insertHTML(
        `<variable data-gjs-type="text">${action.btn.firstElementChild.value}</variable>`,
      );
    },
  });

  editor?.on('rte:enable', () => {
    const elRteAction = document.querySelectorAll('.gjs-rte-action');
    if (elRteAction) elRteAction[6].style.width = '40%';
  });
};

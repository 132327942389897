import { Spin } from 'antd';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import { memo } from 'react';
import { Pie } from 'react-chartjs-2';
import styled from 'styled-components';

import { TimesheetAdjustmentReport } from 'types';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: true,
      position: 'bottom' as const,
      labels: {
        boxWidth: 10,
      },
    },
    datalabels: {
      display: false,
    },
  },
  maintainAspectRatio: false,
};

interface HeadcountLineChartProps {
  loading: boolean;
  dataChart: TimesheetAdjustmentReport;
}

const StyledWrapperChart = styled.div`
  width: 100%;
  height: 350px;
`;

function HeadcountContractTypeChart({ loading, dataChart }: HeadcountLineChartProps) {
  return (
    <Spin spinning={loading}>
      <StyledWrapperChart>
        <Pie options={options} data={dataChart} />
      </StyledWrapperChart>
    </Spin>
  );
}

export default memo(HeadcountContractTypeChart);

import styled from 'styled-components';
import { Input } from 'antd';

const CustomInputTextArea = styled(Input.TextArea)`
  width: 100%;
  height: 39px;
  border-radius: 4px;
  & > input {
    font-size: 14px;
    color: var(--cl_gray900);
  }
`;

export default CustomInputTextArea;

import contractManagementApi from 'api/contractManagementApi';
import { useEffect, useState } from 'react';
import { ListParams } from 'types';

export const useEmployeeListForContract = (params: Partial<ListParams>) => {
  const [employeeList, setEmployeeList] = useState<Array<any>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<unknown>(undefined);

  useEffect(() => {
    const fetchEmployeeListAPI = async () => {
      try {
        setIsLoading(true);
        const response = await contractManagementApi.getEmployeeList(params);
        setEmployeeList(response?.data?.result || []);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchEmployeeListAPI();
  }, [params]);

  return { data: employeeList, isLoading: isLoading, error: error };
};

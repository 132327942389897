import Icon, {
  DownOutlined,
  DownloadOutlined,
  ExportOutlined,
  FilterOutlined,
  ImportOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, Input, Menu, Popover, Tooltip } from 'antd';
import IconAdd from 'assets/icons/ic_add_gray.svg';
import moment from 'moment';
import qs from 'query-string';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

import ITVSearch from 'components/ITVSearch';
import { DATE_PAYLOAD_FORMAT } from 'constants/commons';
import { path } from 'constants/path';
import AddColumn from 'features/employee/components/AddColumn';
import ImportEmployees from 'features/employee/pages/EmployeeImportModal';
import { useForm } from 'react-hook-form';
import ITVButton from 'styles/buttonStyled';
import { EmployeePayload } from 'types';
import EmployeeFilters from './EmployeeFilters';
import { default as AltCustomBtn } from 'styles/buttonStyled';
import EmployeeSimpleFilters from './EmployeeSimpleFilters';

const { Search } = Input;

export const CustomIcon = styled(Icon)`
  ${(props) =>
    props.type === 'search' &&
    css`
      img {
        height: 15px;
        width: 15px;
      }
    `}
  ${(props) =>
    props.type === 'add' &&
    css`
      img {
        padding-bottom: 6px;
        height: 15px;
        width: 15px;
      }
    `}
  ${(props) =>
    props.type === 'export' &&
    css`
      img {
        padding-bottom: 6px;
        height: 20px;
        width: 16px;
      }
    `}
`;

const IconAddComponents = (
  <CustomIcon type={'add'} component={() => <img src={IconAdd} alt="" />} />
);

const FunctionComponents = (props: any) => {
  const {
    isShowFull,
    handleSearch,
    onExportClick,
    viewMode,
    submitText,
    handleSubmit,
    selectedUser,
    clearSortAndFilters,
    enableClear,
    employeeFilters,
    handleFilter,
    onDownload,
  }: any = props;

  const { t } = useTranslation(['timesheet', 'action', 'status', 'employee']);

  const history = useHistory();
  const { control } = useForm();
  const childRefAddColumn = useRef<any>();

  const [isVisibleAddColumn, setIsVisibleAddColumn] = useState(false);
  const [isVisibleEmployeeImport, setIsVisibleEmployeeImport] = useState(false);
  const [isOpenSimpleFilter, setIsOpenSimpleFilter] = useState(false);

  const handleHideAddColumn = () => {
    setIsVisibleAddColumn(false);
  };

  const handleVisibleChange = (visible: any) => {
    setIsVisibleAddColumn(visible);
  };

  const handleOpenModalEmployeeImport = () => {
    setIsVisibleEmployeeImport(true);
  };

  const handleCloseModalEmployeeImport = () => {
    setIsVisibleEmployeeImport(false);
  };

  const handleOpenSimpleFilter = (visible: any) => {
    setIsOpenSimpleFilter(visible);
  };

  // const handleFiltersSubmit = ({ dateRange, viewType }: EmployeePayload) => {
  //   const fromDate = dateRange?.from ? moment(dateRange.from).format(DATE_PAYLOAD_FORMAT) : '';
  //   const toDate = dateRange?.to ? moment(dateRange.to).format(DATE_PAYLOAD_FORMAT) : '';

  //   history.push({
  //     pathname: path.employee,
  //     search: qs.stringify({
  //       ...employeeFilters,
  //       fromDate,
  //       toDate,
  //       viewType,
  //     }),
  //   });
  // };

  const handleFiltersSubmit = (values: any) => {
    handleFilter(values);
  };

  const buttonMenu = (
    <Menu>
      <Menu.Item onClick={onDownload}>
        {isShowFull && <Tooltip title="Download import file">{'Download'}</Tooltip>}
      </Menu.Item>
      <Menu.Item onClick={handleOpenModalEmployeeImport}>
        {isShowFull && t('action:import')}
      </Menu.Item>
      <Menu.Item onClick={onExportClick}>{isShowFull && t('action:export')}</Menu.Item>
    </Menu>
  );

  return (
    <Container>
      <ContainerLeft>
        <ITVSearch handleSearch={handleSearch} />

        {/* {employeeFilters && (
          <EmployeeFilters
            initialValues={{
              dateRange: {
                from: new Date(employeeFilters?.fromDate),
                to: new Date(employeeFilters?.toDate),
              },
              viewType: employeeFilters?.viewType,
            }}
            loading={employeeFilters?.loading}
            onSubmit={handleFiltersSubmit}
          />
        )} */}

        <Button type="primary" disabled={enableClear} onClick={clearSortAndFilters}>
          {t('action:reset_sort_filter')}
        </Button>
      </ContainerLeft>

      {!viewMode ? (
        <ContainerRight>
          <Dropdown overlay={buttonMenu}>
            <AltCustomBtn style={{ marginLeft: 5 }} aType="outline">
              {t('employee:employee_information_fields.action')} <DownOutlined />
            </AltCustomBtn>
          </Dropdown>

          <Popover
            placement="bottom"
            content={<EmployeeSimpleFilters onFilterChange={handleFiltersSubmit} />}
            trigger="click"
            open={isOpenSimpleFilter}
            onOpenChange={handleOpenSimpleFilter}
          >
            <CustomButtonFilter style={{ marginLeft: 8 }}>
              <FilterOutlined />
              {t('employee:filter.title')}
              <DownOutlined />
            </CustomButtonFilter>
          </Popover>

          <Popover
            placement="bottom"
            content={
              <AddColumn
                ref={childRefAddColumn}
                handleVisibleAddColumn={handleHideAddColumn}
                addColumns={props.addColumns}
                isHistory={isShowFull ? false : true}
              />
            }
            trigger="click"
            open={isVisibleAddColumn}
            onOpenChange={handleVisibleChange}
          >
            <CustomButton icon={IconAddComponents}>{t('action:add_column')}</CustomButton>
          </Popover>
        </ContainerRight>
      ) : (
        <ITVButton
          disabled={!selectedUser || selectedUser.length === 0}
          style={{ width: 100 }}
          onClick={() => handleSubmit && handleSubmit()}
        >
          {submitText}
        </ITVButton>
      )}
      <ImportEmployees
        isVisibleEmployeeImport={isVisibleEmployeeImport}
        onClose={handleCloseModalEmployeeImport}
      />
    </Container>
  );
};

export default FunctionComponents;

export const Container = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 1rem;
  flex-wrap: wrap;
  row-gap: 10px;
`;

export const ContainerLeft = styled.div`
  display: flex;
  gap: 12px;
`;

export const ContainerRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const CustomSearch = styled(Search)`
  .ant-input-group-addon {
    display: none;
  }
  .ant-input-affix-wrapper {
    height: 32px;
    input {
      font-size: 14px;
    }
  }
`;

export const CustomButton = styled(Button)`
  font-size: 14px;
  font-weight: 500;
  background-color: #ffffff;
  color: #111827;
  border-radius: 4px;
  height: 32px;
  margin-left: 8px;
`;

export const CustomButtonFilter = styled(Button)`
  font-size: 14px;
  font-weight: 500;
  background-color: #ffffff;
  color: #111827;
  border-radius: 4px;
  height: 32px;
  margin-left: auto;
`;

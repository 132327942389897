import { Card, Col, Row } from 'antd';
import { useAppSelector } from 'app/hooks';
import { DatePickerField } from 'components/FormFields';
import { YYYY_MM_DD } from 'constants/commons';
import { selectPerformanceData } from '../performanceSlice';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

const Scheduler = ({ control, watch, getValues, viewMode }) => {
  const { t } = useTranslation(['performance']);
  const performanceStore = useAppSelector(selectPerformanceData);
  const performanceTemplateDetail = performanceStore?.performanceTemplateDetail;
  const steps = performanceTemplateDetail?.steps;
  const cycleDetail = performanceStore?.cycleDetail;
  const shouldDisable = getValues()?.status !== 'New';

  return (
    <Card title={t('performance:performance_review.scheduler')} bordered={false}>
      <Row gutter={[16, 16]}>
        <Col span={12}>{t('performance:performance_review.review_level')}</Col>
        <Col span={12}>{t('performance:performance_review.end_date')}</Col>
      </Row>

      {viewMode && cycleDetail?.stepScheduler && !steps
        ? cycleDetail?.stepScheduler?.map((item: any, index: any) => {
            return (
              <Row gutter={[0, 16]} id={item?.stepNumber} style={{ marginBottom: 8, marginTop: 8 }}>
                <Col span={12}>{item?.stepName}</Col>
                <Col span={12}>
                  <DatePickerField
                    value={item?.endDate ? moment(item?.endDate, 'YYYY-MM-DD hh:mm:ss') : undefined}
                    defaultValue={
                      item?.endDate ? moment(item?.endDate, 'YYYY-MM-DD hh:mm:ss') : undefined
                    }
                    style={{ width: '100%' }}
                    picker="date"
                    format={YYYY_MM_DD}
                    name={`stepId_${item?.stepName}_${item?.stepNumber}`}
                    control={control}
                    onChange={(v) => {}}
                    disabled={viewMode && shouldDisable}
                  />
                </Col>
              </Row>
            );
          })
        : steps?.map((item) => (
            <Row gutter={[0, 16]} id={item?.stepNumber} style={{ marginBottom: 8, marginTop: 8 }}>
              <Col span={12}>{item?.name}</Col>
              <Col span={12}>
                <DatePickerField
                  style={{ width: '100%' }}
                  picker="date"
                  format={YYYY_MM_DD}
                  name={`stepId_${item?.name}_${item?.stepNumber}`}
                  control={control}
                  disabled={viewMode && shouldDisable}
                />
              </Col>
            </Row>
          ))}
    </Card>
  );
};

export default Scheduler;

import { memo } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import styled from 'styled-components';
import { Spin } from 'antd';

ChartJS.register(ArcElement, Tooltip, Legend);

const StyledWrapperChart = styled.div`
  width: 60%;
  height: 60%;
  margin: auto;
`;

function HeadcountSummaryChart({ loading, dataChart }: any) {
  const data = {
    labels: ['Store A', 'Store B', 'Store C', 'HQ'],
    datasets: [
      {
        label: '# of Votes',
        data: [15, 16, 3, 19],
        backgroundColor: [
          'rgb(68 114 196)',
          'rgb(237 125 49)',
          'rgb(165 165 165)',
          'rgb(38 68 120)',
        ],
        borderColor: ['rgb(246 250 253)'],
        borderWidth: 2,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: true,
        position: 'bottom', // Place legend at the bottom
        labels: {
          boxWidth: 20,
        },
        title: { display: true, padding: 10 },
      },
    },
    layout: {
      padding: {
        top: 30, // Adjust the bottom padding to create more space
      },
    },
  };

  return (
    <Spin spinning={loading}>
      <StyledWrapperChart>
        <Pie data={dataChart} style={{ width: '100%', height: '100%' }} options={options} />
      </StyledWrapperChart>
    </Spin>
  );
}

export default memo(HeadcountSummaryChart);

import { IconProps } from 'types';

export const SearchIcon = ({ width = '16px', height = '16px', className }: IconProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.36214 11.3905C9.13939 11.3905 11.3908 9.13914 11.3908 6.3619C11.3908 3.58465 9.13939 1.33325 6.36214 1.33325C3.5849 1.33325 1.3335 3.58465 1.3335 6.3619C1.3335 9.13914 3.5849 11.3905 6.36214 11.3905Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.6669 14.6667L10.1709 10.1707"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

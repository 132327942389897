import { CalendarOutlined } from '@ant-design/icons';
import { FilterValue, SorterResult, TablePaginationConfig } from 'antd/lib/table/interface';
import moment from 'moment';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TitleColumnCenter } from 'styles/tableStyled';

import { selectFormatDate, selectFormatMonth } from 'app/commonRedux/appSlice';
import { useAppSelector } from 'app/hooks';
import { StatusColumn } from 'components/EmployeeList';
import ITVTable from 'components/ITVTable';
import { PROFILE_STATUSES } from 'constants/profiles';
import { selectPermissions } from 'features/auth/authSlice';
import { formatAdvanceCurrencyUnit } from 'features/employee/components/AdvancesInformantionFields/components/AdvancesTable';
import ViewPaymentPlan from 'features/employee/components/AdvancesInformantionFields/components/ViewPaymentPlan';
import EmployeeColumn from 'features/employee/components/EmployeeColumn';
import StatusColumnCell from 'features/payroll/pages/ViewEmployee/components/StatusColumnCell';
import { DateType } from 'hrm-common/extensions/enums/personel';
import { IAdvanceList } from 'types';
import MaskedValueRenderer from 'components/MaskedValueRenderer';

interface AdvanceListProps {
  loading: boolean;
  data: IAdvanceList[];
  totalResult: number;
  offset: number;
  limit: number;
  setOffset: Dispatch<SetStateAction<number>>;
  setLimit: Dispatch<SetStateAction<number>>;
  onTableChange?: (
    pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue | null>,
    sorter: SorterResult<any>,
  ) => void;
  onRefresh?: () => void;
  monthYear: string;
}

enum MODE {
  VIEW = 'VIEW',
  ADD = 'ADD',
  UPDATE = 'UPDATE',
}

export function AdvanceList({
  loading,
  data,
  totalResult,
  offset,
  limit,
  setOffset,
  setLimit,
  onTableChange,
  onRefresh,
  monthYear,
}: AdvanceListProps) {
  const { t } = useTranslation(['employee']);

  const dataFormatDate = useAppSelector(selectFormatDate);
  const dataFormatMonth = useAppSelector(selectFormatMonth);
  const permissions = useAppSelector(selectPermissions);

  const [mode, setMode] = useState<string>('');
  const [currentItem, setCurrentItem] = useState<any>(null);

  const COLUMNS: any = [
    {
      title: () => {
        return (
          <TitleColumnCenter>{t('employee:employee_information_fields.id')}</TitleColumnCenter>
        );
      },
      dataIndex: 'employeeId',
      key: 'employeeId',
      ellipsis: true,
      width: 60,
      minWidth: 60,
      align: 'center',
      fixed: 'left',
      sorter: true,
      render: function (text: any, record: any, index: any) {
        const trueIndex =
          offset === 1 ? index + limit * (offset - 1) : index + limit * (offset - 1) - limit;
        const obj: any = {
          children: record?.user?.employeeId,
          props: {},
        };
        if (index >= 1 && record?.userId === data?.[trueIndex - 1]?.userId) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== data?.length && record?.userId === data?.[trueIndex + i]?.userId;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }
        return obj;
      },
    },
    {
      title: () => {
        return (
          <TitleColumnCenter>
            {t('employee:employee_information_fields.employee')}
          </TitleColumnCenter>
        );
      },
      dataIndex: 'fullName',
      key: 'fullName',
      width: 220,
      minWidth: 220,
      ellipsis: true,
      fixed: 'left',
      sorter: true,
      render: function (text: any, record: any, index: any) {
        const trueIndex =
          offset === 1 ? index + limit * (offset - 1) : index + limit * (offset - 1) - limit;
        const obj: any = {
          children: (
            <EmployeeColumn
              avatar={record?.user?.fullName}
              fullName={record?.user?.fullName}
              email={record?.user?.email}
            />
          ),
          props: {},
        };
        if (index >= 1 && record?.userId === data?.[trueIndex - 1]?.userId) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== data?.length && record?.userId === data?.[trueIndex + i]?.userId;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }
        return obj;
      },
    },
    {
      title: t('employee:advance_information_fields.advance_name'),
      dataIndex: 'name',
      key: 'name',
      width: 200,
      minWidth: 200,
      ellipsis: true,
      render: function (text: any, record: any, index: any) {
        return record?.advanceConfigurationHistory?.name;
      },
    },
    {
      title: t('employee:advance_information_fields.effective_date'),
      dataIndex: 'effectiveDate',
      key: 'effectiveDate',
      width: 150,
      minWidth: 150,
      render: function (text: any, record: any, index: any) {
        return text
          ? moment(text).format(
              checkDateType(record?.type) === 'date'
                ? dataFormatDate
                : checkDateType(record?.type) === 'month'
                ? dataFormatMonth
                : checkDateType(record?.type) === 'quarter'
                ? 'YYYY-Qo'
                : 'YYYY',
            )
          : '';
      },
    },
    {
      title: t('employee:advance_information_fields.advance_payment'),
      children: [
        {
          title: t('employee:advance_information_fields.disbursement'),
          dataIndex: 'amount',
          key: 'amount',
          width: 135,
          align: 'right',
          render: function (text: any, record: any, index: any) {
            return <MaskedValueRenderer value={text} />;
          },
        },
        {
          title: t('employee:advance_information_fields.repayment'),
          dataIndex: 'paidAmount',
          key: 'paidAmount',
          width: 135,
          align: 'right',
          render: function (text: any, record: any, index: any) {
            return <MaskedValueRenderer value={text} />;
          },
        },
        {
          title: t('employee:advance_information_fields.outstanding_balance'),
          dataIndex: 'remain',
          key: 'remain',
          width: 135,
          align: 'right',
          render: function (text: any, record: any, index: any) {
            return <MaskedValueRenderer value={text} />;
          },
        },
      ],
    },
    {
      title: (
        <div>
          <p style={{ marginBottom: 0 }}>{t('employee:advance_information_fields.term')}</p>
        </div>
      ),
      dataIndex: 'term',
      key: 'term',
      width: 50,
      align: 'right',
      render: function (text: any, record: any, index: any) {
        return text;
      },
    },
    {
      title: () => {
        return (
          <TitleColumnCenter>{t('employee:advance_information_fields.status')}</TitleColumnCenter>
        );
      },
      key: 'status',
      dataIndex: 'status',
      width: 130,
      minWidth: 130,
      render: function (value: any, record: any, index: any) {
        let status = '';
        if (value === true) {
          status = 'complete';
        } else if (value === false) {
          status = 'inprogress';
        } else if (value === 'draft') {
          status = 'draft';
        } else if (value === 'unsave') {
          status = 'unsave';
        }
        return <StatusColumn status={status}>• {PROFILE_STATUSES[status]}</StatusColumn>;
      },
    },
    {
      title: t('employee:advance_information_fields.plan'),
      dataIndex: 'plan',
      key: 'plan',
      width: 80,
      minWidth: 80,
      align: 'center',
      render: function (text: any, record: any, index: any) {
        return (
          <span
            onClick={() => {
              setMode(MODE.UPDATE);
              setCurrentItem(record);
            }}
          >
            <CalendarOutlined />
          </span>
        );
      },
    },
    {
      title: () => {
        return (
          <TitleColumnCenter>{t('employee:employee_information_fields.action')}</TitleColumnCenter>
        );
      },
      dataIndex: 'action',
      key: 'action',
      width: 60,
      minWidth: 60,
      align: 'center',
      fixed: 'right',
      render: (status: any, record: any, index: any) => {
        const trueIndex =
          offset === 1 ? index + limit * (offset - 1) : index + limit * (offset - 1) - limit;
        const obj: any = {
          children: (
            <StatusColumnCell
              showMenu={'advances'}
              t={t}
              isShowFullMenu={true}
              item={record?.user}
              permissions={permissions}
              monthYear={monthYear}
              callBack={() => onRefresh?.()}
            />
          ),
          props: {},
        };
        if (index >= 1 && record?.userId === data?.[trueIndex - 1]?.userId) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== data?.length && record?.userId === data?.[trueIndex + i]?.userId;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }
        return obj;
      },
    },
  ];

  const checkDateType = (value: any) => {
    switch (value) {
      case DateType.DATE:
        return 'date';
      case DateType.MONTH:
        return 'month';
      case DateType.YEAR:
        return 'year';
      case DateType.QUARTER:
        return 'quarter';
      default:
        return 'date';
    }
  };

  return (
    <>
      <ITVTable
        columns={COLUMNS}
        data={data}
        rowKeyMerge={'userId'}
        setSelectedUser={() => {}}
        loading={loading}
        totalResult={totalResult}
        setOffset={setOffset}
        offset={offset}
        setLimit={setLimit}
        limit={limit}
        onChange={onTableChange}
      />
      {mode === MODE.UPDATE && (
        <ViewPaymentPlan mode={mode} recordItem={currentItem} setMode={setMode} />
      )}
    </>
  );
}

import { Checkbox, Col, Form, Modal, Row, Select } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { selectFormatDate } from 'app/commonRedux/appSlice';
import { useAppSelector } from 'app/hooks';
import ITVTable from 'components/ITVTable';
import { actions, selectConfiguration } from 'features/configuration/configurationSlice';
import { getFindOnePlanUser } from 'features/employee/employeeAction';
import { selectEmployee } from 'features/employee/employeeSlice';
import { formatCurrencyUnit, labelUnit } from 'features/insurances/constants/common';
import { formatAdvanceCurrencyUnit } from './AdvancesTable';
import { CustomDatePicker } from './ViewPlanEdit';

const { Option } = Select;

enum MODE {
  VIEW = 'VIEW',
  ADD = 'ADD',
  UPDATE = 'UPDATE',
}

const ViewPaymentPlan = ({ mode, recordItem, setMode }: any) => {
  const [form] = Form.useForm();
  const { t } = useTranslation([
    'validation',
    'masterConfig',
    'insurance',
    'common',
    'employee',
    'overtime',
  ]);
  const dataStore: any = useAppSelector(selectConfiguration);
  const dispatch = useDispatch();
  const { setErrorMessage } = actions;
  const error: any = dataStore.error;
  const formatDate = useAppSelector(selectFormatDate);
  const employee: any = useAppSelector(selectEmployee);
  const dataFindOnePlanUser = employee?.dataFindOnePlanUser?.data?.result;

  const [sizePage, setSizePage] = useState(12);
  const [currentPage, setCurrentPage] = useState(1);
  const [valueRemain, setValueRemain] = useState();
  const [dataTablePlan, setDataTablePlan] = useState<any>([]);

  useEffect(() => {
    if (recordItem?.id) {
      dispatch(getFindOnePlanUser({ id: recordItem.id }));
    }
  }, [recordItem, dispatch]);

  useEffect(() => {
    if (error?.fieldErrors) {
      form.setFields([
        {
          name: 'name',
          errors: error?.fieldErrors?.name,
        },
      ]);
    }
    return () => {
      dispatch(setErrorMessage(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const handleFormChange = () => {};

  const COLUMNS: any = [
    {
      title: 'Month',
      dataIndex: 'month',
      key: 'month',
      width: 150,
      minWidth: 150,
      render: function (text: any, record: any, index: any) {
        return record?.month;
      },
    },

    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      align: 'right',
      render: function (text: any, record: any, index: any) {
        return formatAdvanceCurrencyUnit(record?.amount, '');
      },
    },
    {
      title: 'Paid',
      dataIndex: 'paidStatus',
      key: 'paidStatus',
      width: 150,
      minWidth: 150,
      align: 'center',
      render: function (text: any, record: any, index: any) {
        return <Checkbox disabled={true} checked={record?.paidStatus}></Checkbox>;
      },
    },
  ];

  useEffect(() => {
    setDataTablePlan(
      dataFindOnePlanUser?.map((item: any, index: any) => {
        return {
          ...item,
          isLock: item?.paidStatus ? 'isLocked' : '',
          idFake: item?.id,
        };
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFindOnePlanUser]);

  useEffect(() => {
    let filterIsPaid = dataTablePlan?.filter((item: any, idx: number) => {
      return !item.paidStatus;
    });
    const moneyIsPaid = filterIsPaid?.reduce((acc: any, curr: any) => {
      return acc + curr.amount;
    }, 0);
    setValueRemain(moneyIsPaid);
  }, [dataTablePlan]);

  const handleCancelPlan = () => {
    setMode('');
  };

  const monthFormat = 'MM/YYYY';
  const [startMonth, setStartMonth] = useState();

  useEffect(() => {
    let sortDataPlan =
      recordItem &&
      [...recordItem?.paymentPlan]?.sort((a, b) => {
        return a?.id - b?.id;
      });

    setStartMonth(sortDataPlan.length > 0 ? sortDataPlan?.[0]?.month : dataTablePlan?.[0]?.month);
  }, [recordItem, dataTablePlan]);

  return (
    <Modal
      visible={mode === MODE.UPDATE}
      title={`Payment Plan`}
      okText={t('timesheet:Save')}
      cancelText={t('timesheet:Cancel')}
      width={550}
      maskClosable={false}
      onCancel={handleCancelPlan}
      onOk={() => {}}
      centered
      footer={null}
    >
      <Form form={form} onFieldsChange={handleFormChange} layout="vertical">
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Row gutter={[16, 16]}>
              <ColLeft span={24}>{t('employee:advance_information_fields.effective_date')}</ColLeft>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={24}>{moment(recordItem?.effectiveDate).format(formatDate)}</Col>
            </Row>
          </Col>
          <Col span={8}>
            <Row gutter={[16, 16]}>
              <ColLeft span={24}>{t('employee:advance_information_fields.type_advance')}</ColLeft>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={24}>{recordItem?.advanceConfigurationHistory?.name}</Col>
            </Row>
          </Col>
          <Col span={8}>
            <Row gutter={[16, 16]}>
              <ColLeft span={24}>{t('employee:advance_information_fields.term')}</ColLeft>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={24}>{recordItem?.term} months</Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={16}>
            <Row gutter={[16, 16]}>
              <ColLeft span={24}>{t('employee:advance_information_fields.advance_amount')}</ColLeft>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={24}>{formatCurrencyUnit(recordItem?.amount, labelUnit)}</Col>
            </Row>
          </Col>
          <Col span={8}>
            <Row gutter={[16, 16]}>
              <ColLeft span={24}>{t('employee:advance_information_fields.remaining')}</ColLeft>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={24}>{formatCurrencyUnit(valueRemain, labelUnit)}</Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={16}>
            <Form.Item
              name={'start_month'}
              label={
                <span style={{ fontWeight: 700 }}>
                  {' '}
                  {t('employee:advance_information_fields.startMonth')}
                </span>
              }
              rules={[
                {
                  required: true,
                  message: t('HRM_VAD_REQUIRED', { field: `Start Month` }),
                },
              ]}
            >
              {startMonth && (
                <CustomDatePicker
                  disabled={true}
                  style={{ width: '35%' }}
                  format={monthFormat}
                  picker="month"
                  defaultValue={recordItem && moment(startMonth, monthFormat)}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <div>
              <span>{t('employee:advance_information_fields.currency_unit')}: </span>
              <Select defaultValue="VND" style={{ width: 80 }}>
                <Option value="vnd">VND</Option>
              </Select>
            </div>
          </Col>
        </Row>
        <div>
          <ITVTable
            columns={COLUMNS}
            data={dataTablePlan}
            isRowSelect={false}
            setOffset={setCurrentPage}
            offset={currentPage}
            setLimit={setSizePage}
            limit={sizePage}
            width={450}
            height={350}
          />
        </div>
      </Form>
    </Modal>
  );
};

export default ViewPaymentPlan;

export const ColCenter = styled(Col)`
  text-align: center;
  background: #fea481;
  color: white;
  font-weight: 700;
`;
export const ColLeft = styled(Col)`
  text-align: left;
  font-weight: 700;
  margin-top: 5px;
`;

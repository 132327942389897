import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import Icon, { ExclamationCircleOutlined } from '@ant-design/icons';
import IconAdd from 'assets/icons/ic_add_orange.svg';
import SoftSkillFieldsAdd from './Components/SoftSkillFieldsAdd';
import MainSkillItems from './Components/MainSkillItems';
import MainSkillFieldsAdd from './Components/MainSkillFieldsAdd';
import SoftSkillItems from './Components/SoftSkillItems';
import { SkillInfoTitle, SkillInfoTitleButton, SkillInfoButton } from './styled';
import CertFieldsAdd from './Components/CertFieldsAdd';
import CertItems from './Components/CertItems';

const SkillInformationFields = (props: any) => {
  const { isShowComponents, isCanUpdate, status, employeeData, setEmployeeData } = props;
  const { t } = useTranslation(['employee', 'modal']);

  const [mainSkill, setMainSkill] = useState([]);
  const [softSkill, setSoftSkill] = useState([]);
  const [certificate, setCertificate] = useState([]);
  const [isAddingMainSkill, setIsAddingMainSkill] = useState(false);
  const [isAddingSoftSkill, setIsAddingSoftSkill] = useState(false);
  const [isAddingCertificate, setIsAddingCertificate] = useState(false);

  useEffect(() => {
    if (employeeData?.skill) {
      setMainSkill(employeeData?.skill?.mainSkill);
      setSoftSkill(employeeData?.skill?.softSkill);
      setCertificate(employeeData?.skill?.certificates);
    } else {
      setMainSkill([]);
      setSoftSkill([]);
      setCertificate([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeData?.skill]);

  const onEmployeeUpdateMainSkill = (_MainSkill: any) => {
    setEmployeeData({
      ...employeeData,
      skill: { ...employeeData?.skill, mainSkill: _MainSkill, softSkill },
    });
  };
  const onEmployeeUpdateSoftSkill = (_SoftSkill: any) => {
    setEmployeeData({
      ...employeeData,
      skill: { ...employeeData?.skill, softSkill: _SoftSkill, mainSkill },
    });
  };

  const onEmployeeUpdateCertificate = (_Certificate: any) => {
    setEmployeeData({
      ...employeeData,
      skill: { ...employeeData?.skill, certificates: _Certificate },
    });
  };

  const onDeleteMainSkill = (index: any) => () => {
    Modal.confirm({
      title: t('modal:confirm_delete', { field: 'main skill' }),
      icon: <ExclamationCircleOutlined />,
      okText: t('modal:btn_ok'),
      okType: 'danger',
      cancelText: t('modal:btn_cancel'),
      onOk: () => {
        const new_skill = mainSkill.filter((v, i) => i !== index);
        setMainSkill(new_skill);
        setEmployeeData({
          ...employeeData,
          skill: { ...employeeData?.skill, mainSkill: new_skill, softSkill },
        });
      },
    });
  };
  const onDeleteSoftSkill = (index: any) => () => {
    Modal.confirm({
      title: t('modal:confirm_delete', { field: 'soft skill' }),
      icon: <ExclamationCircleOutlined />,
      okText: t('modal:btn_ok'),
      okType: 'danger',
      cancelText: t('modal:btn_cancel'),
      onOk: () => {
        const new_skill = softSkill.filter((v, i) => i !== index);
        setSoftSkill(new_skill);
        setEmployeeData({
          ...employeeData,
          skill: { ...employeeData?.skill, softSkill: new_skill, mainSkill },
        });
      },
    });
  };
  const onDeleteCertificate = (index: any) => () => {
    Modal.confirm({
      title: t('modal:confirm_delete', { field: 'certificate' }),
      icon: <ExclamationCircleOutlined />,
      okText: t('modal:btn_ok'),
      okType: 'danger',
      cancelText: t('modal:btn_cancel'),
      onOk: () => {
        const new_cert = certificate.filter((v, i) => i !== index);
        setCertificate(new_cert);
        setEmployeeData({
          ...employeeData,
          skill: { ...employeeData?.skill, certificates: new_cert },
        });
      },
    });
  };

  return (
    <div style={isShowComponents ? {} : { display: 'none' }}>
      <SkillInfoTitle>
        {t('employee:skill_information_fields.main_skill')}
        {isCanUpdate && (
          <SkillInfoTitleButton>
            <SkillInfoButton
              disabled={status === 'deleted' ? true : false}
              icon={<Icon component={() => <img src={IconAdd} alt="" />} />}
              onClick={() => setIsAddingMainSkill(true)}
            >
              {t('employee:skill_information_fields.add_skill')}
            </SkillInfoButton>
          </SkillInfoTitleButton>
        )}
      </SkillInfoTitle>
      {isAddingMainSkill && (
        <MainSkillFieldsAdd
          isCanUpdate={isCanUpdate}
          indexMainSkillItem={null}
          valueMainSkillItem={null}
          mainSkill={mainSkill}
          setMainSkill={setMainSkill}
          onEmployeeUpdateMainSkill={onEmployeeUpdateMainSkill}
          setIsAddingMainSkill={setIsAddingMainSkill}
        />
      )}
      {mainSkill?.map((skill, index) => {
        return (
          <MainSkillItems
            key={'MainSkillItems' + index}
            isCanUpdate={isCanUpdate}
            indexMainSkillItem={index}
            valueMainSkillItem={skill}
            mainSkill={mainSkill}
            setMainSkill={setMainSkill}
            onEmployeeUpdateMainSkill={onEmployeeUpdateMainSkill}
            onDeleteMainSkill={onDeleteMainSkill}
          />
        );
      })}

      <SkillInfoTitle>
        {t('employee:skill_information_fields.soft_skill')}
        {isCanUpdate && (
          <SkillInfoTitleButton>
            <SkillInfoButton
              disabled={status === 'deleted' ? true : false}
              icon={<Icon component={() => <img src={IconAdd} alt="" />} />}
              onClick={() => setIsAddingSoftSkill(true)}
            >
              {t('employee:skill_information_fields.add_skill')}
            </SkillInfoButton>
          </SkillInfoTitleButton>
        )}
      </SkillInfoTitle>
      {isAddingSoftSkill && (
        <SoftSkillFieldsAdd
          isCanUpdate={isCanUpdate}
          indexSoftSkillItem={null}
          valueSoftSkillItem={null}
          softSkill={softSkill}
          setSoftSkill={setSoftSkill}
          onEmployeeUpdateSoftSkill={onEmployeeUpdateSoftSkill}
          setIsAddingSoftSkill={setIsAddingSoftSkill}
        />
      )}
      {softSkill?.map((skill, index) => {
        return (
          <SoftSkillItems
            key={'SoftSkillItems' + index}
            isCanUpdate={isCanUpdate}
            indexSoftSkillItem={index}
            valueSoftSkillItem={skill}
            softSkill={softSkill}
            setSoftSkill={setSoftSkill}
            onEmployeeUpdateSoftSkill={onEmployeeUpdateSoftSkill}
            onDeleteSoftSkill={onDeleteSoftSkill}
          />
        );
      })}

      {/* <SkillInfoTitle>
        Certificate
        {isCanUpdate && (
          <SkillInfoTitleButton>
            <SkillInfoButton
              disabled={status === 'deleted' ? true : false}
              icon={<Icon component={() => <img src={IconAdd} alt="" />} />}
              onClick={() => setIsAddingCertificate(true)}
            >
              {t('employee:skill_information_fields.add_skill')}
            </SkillInfoButton>
          </SkillInfoTitleButton>
        )}
      </SkillInfoTitle>
      {isAddingCertificate && (
        <CertFieldsAdd
          isCanUpdate={isCanUpdate}
          indexCertificateItem={null}
          valueCertificateItem={null}
          certificate={certificate}
          setCertificate={setCertificate}
          onEmployeeUpdateCertificate={onEmployeeUpdateCertificate}
          setIsAddingCertificate={setIsAddingCertificate}
        />
      )}
      {certificate?.map((cert, index) => {
        return (
          <CertItems
            key={'CertificateItems' + index}
            isCanUpdate={isCanUpdate}
            indexCertificateItem={index}
            valueCertificateItem={cert}
            certificate={certificate}
            setCertificate={setCertificate}
            onEmployeeUpdateCertificate={onEmployeeUpdateCertificate}
            onDeleteCertificate={onDeleteCertificate}
          />
        );
      })} */}
    </div>
  );
};

export default SkillInformationFields;

import { DownOutlined } from '@ant-design/icons';
import { DatePicker } from 'antd';
import { Moment } from 'moment';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import IconExport from 'assets/icons/ic_export_gray.svg';
import { CustomIcon } from 'components/ITVSearch';
import { HeaderRemove } from 'features/masterData/Department/styled';

const StyledForm = styled.form`
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const StyledWrapperYear = styled.div`
  width: 80px;
  .ant-picker {
    border-radius: 6px;
    border: 1px solid var(--cl_neutral300);
  }
  .ant-picker-input > input {
    color: #1a2b88;
    font-weight: bold;
    font-size: 12px;
  }
  .ant-picker-suffix {
    font-size: 10px;
  }
  .ant-picker {
    background-color: #f6f6f6;
    padding: 5px;
  }
`;

export interface HeadcountLinePayload {
  monthYear: Moment | null;
}

interface HeadcountLineFiltersProps {
  onSubmit?: (payload: Partial<HeadcountLinePayload>) => void;
  handleExport?: () => void;
  loadingExport?: boolean;

  initialValues?: Partial<HeadcountLinePayload>;
}

export default function HeadcountPositionTableFilter({
  onSubmit,
  handleExport,
  loadingExport = false,
  initialValues,
}: HeadcountLineFiltersProps) {
  const { t } = useTranslation(['dashboard', 'action']);

  const IconExportComponents = (
    <CustomIcon type={'export'} component={() => <img src={IconExport} alt="" />} />
  );

  const [monthYear, setMonthYear] = useState<Moment | null>(initialValues?.monthYear || null);
  const { handleSubmit } = useForm<HeadcountLinePayload>({
    defaultValues: initialValues,
  });

  const handleFiltersSubmit = (formValues: HeadcountLinePayload) => {
    onSubmit?.({ ...formValues, monthYear: monthYear });
  };

  const handleExportLine = () => {
    handleExport?.();
  };

  return (
    <StyledForm onSubmit={handleSubmit(handleFiltersSubmit)}>
      <StyledContainer>
        <StyledWrapperYear>
          <DatePicker
            picker="month"
            value={monthYear}
            allowClear={false}
            suffixIcon={<DownOutlined />}
            onChange={(value) => {
              setMonthYear(value);
              onSubmit?.({ monthYear: value });
            }}
            format={'MM-YYYY'}
          />
        </StyledWrapperYear>

        <HeaderRemove
          onClick={handleExportLine}
          icon={IconExportComponents}
          loading={loadingExport}
          style={{
            backgroundColor: '#f6f6f6',
            fontSize: '12px',
            color: '#1a2b88',
            fontWeight: 'bold',
          }}
        >
          {t('action:export')}
        </HeaderRemove>
      </StyledContainer>
    </StyledForm>
  );
}

import { IconProps } from 'types';

export const ArrowLeftIcon = ({
  width = '24px',
  height = '24px',
  className,
  onClick,
}: IconProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    onClick={onClick}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.57 5.92993L3.5 11.9999L9.57 18.0699"
      stroke="#121926"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.4999 12H3.66992"
      stroke="#121926"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

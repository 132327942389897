import * as yup from 'yup';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { OnsiteEmployeePayload } from 'types';

export const useAddOnsiteEmployeeSchema = (initialValues?: Partial<OnsiteEmployeePayload>) => {
  const { t } = useTranslation(['onsiteEmployee']);

  const schema = yup.object().shape({
    lineCategory: yup
      .string()
      .test('test-required', t('add_onsite_employee_modal.line_category_rule'), (value: any) => {
        if (Boolean(initialValues?.id) || Boolean(value)) return true;

        return false;
      }),
    employee: yup
      .array()
      .of(yup.string())
      .min(1, t('add_onsite_employee_modal.onsite_employee_rule'))
      .required(t('add_onsite_employee_modal.onsite_employee_rule')),
    startDate: yup
      .date()
      .nullable()
      .test('test-onsite-start-date', function (startDate, context) {
        if (startDate) {
          const { from } = this;
          const formValue = from?.[0].value;
          const endDate: Date = formValue.endDate;

          if (endDate && moment(startDate).isAfter(endDate)) {
            return context.createError({
              message: t('add_onsite_employee_modal.test_onsite_start_date_rule'),
            });
          }
          return true;
        }
        return context.createError({
          message: t('add_onsite_employee_modal.require_onsite_start_date_rule'),
        });
      }),
    endDate: yup
      .date()
      .nullable()
      .test('test-onsite-end-date', function (endDate, context) {
        if (endDate) {
          const { from } = this;
          const formValue = from?.[0].value;
          const startDate: Date = formValue.startDate;

          if (moment(endDate).isBefore(startDate)) {
            return context.createError({
              message: t('add_onsite_employee_modal.test_onsite_end_date_rule'),
            });
          }
          return true;
        }
        return context.createError({
          message: t('add_onsite_employee_modal.require_onsite_end_date_rule'),
        });
      }),
  });

  return schema;
};

import { List } from 'antd';
import { useAppSelector } from 'app/hooks';
import { selectPayroll } from 'features/payroll/payrollSlice';
import React, { useEffect, useState } from 'react';

const PayslipDetail = ({ name }: any) => {
  const payslipDetail = useAppSelector(selectPayroll)?.payslipDetail;

  const [data, setData] = useState<any>([
    {
      id: 1,
      title: 'Personal Information',
      child: [
        {
          id: 1.1,
          title: 'Employee ID',
          mapField: 'employeeId',
          value: '',
        },
        {
          id: 1.2,
          title: 'Employee name',
          mapField: 'fullName',
          value: '',
        },
        {
          id: 1.3,
          title: 'Gross salary',
          mapField: 'grossSalary',
          value: 0,
        },
        {
          id: 1.4,
          title: 'Main salary',
          mapField: 'mainSalary',
          value: 0,
        },
        {
          id: 1.5,
          title: 'Standard working hours',
          mapField: 'standardHours',
          value: 0,
        },
        {
          id: 1.6,
          title: 'Actual working hours',
          mapField: 'actualHours',
          value: 0,
        },
        {
          id: 1.7,
          title: 'Total paid time off',
          mapField: 'leaveOffHours',
          value: 0,
        },
        {
          id: 1.8,
          title: 'Overtime hours',
          mapField: 'totalHoursOvertime',
          value: 0,
        },
      ],
    },
    {
      id: 2,
      title: 'Total monthly income (2.1 + 2.2 + 2.3 + 2.4 + 2.5 + 2.6)',
      value: 0,
      mapField: 'totalSectionTwo',
      child: [
        {
          id: 2.1,
          title: 'The total income salary based on working days',
          mapField: 'totalSalaryByActualHours',
          value: 0,
        },
        {
          id: 2.2,
          title: 'The adjusted salary amount (if any)',
          mapField: 'totalIncomeAdjustment',
          value: 0,
        },
        {
          id: 2.3,
          title: 'Total overtime pay(taxable, non-taxable)',
          mapField: 'totalSalaryOvertime',
          value: 0,
        },
        {
          id: '2.3.1',
          title: 'Non-taxable overtime pay',
          mapField: 'nonTaxable',
          value: 0,
        },
        {
          id: '2.3.2',
          title: 'Taxable overtime pay',
          mapField: 'taxable',
          value: 0,
        },
        {
          id: 2.4,
          title: 'Business Fee (Onsite)',
          mapField: 'totalOnsiteFee',
          value: 0,
        },
        {
          id: 2.5,
          title: 'Bonus+13th salary (if applicable)',
          mapField: 'totalSalaryBonus',
          value: 0,
        },
        {
          id: 2.6,
          title: 'Other allowances (if applicable)',
          mapField: 'totalAllowanceNotGrossTax',
          value: 0,
        },
      ],
    },
    {
      id: 3,
      title: 'Deductions & Tax Reductions',
      value: 0,
      mapField: 'totalSectionThree',
      child: [
        {
          id: 3.1,
          title: 'Deductions for personal and dependent allowances',
          mapField: 'dependantForPerson',
          value: 0,
        },
        {
          id: 3.2,
          title: 'Lunch allowance and other allowances',
          mapField: 'totalAllowaceNonTax',
          value: 0,
        },
        {
          id: 3.3,
          title: 'Non-taxable overtime pay',
          mapField: 'nonTaxableOvertime',
          value: 0,
        },
        {
          id: 3.4,
          title: 'Social Insurance deduction 10.5% (mandatory)',
          mapField: 'contribuEmployee',
          value: 0,
        },
      ],
    },
    {
      id: 4,
      title: 'Personal Income Tax',
      value: 0,
      mapField: 'totalTaxPersonIncome',
      child: [
        {
          id: 4.1,
          title: 'Taxable income',
          mapField: 'taxableIncome',
          value: 0,
        },
      ],
    },
    {
      id: 5,
      mapField: 'totalIncomeAdvance',
      title: 'Advances',
      value: 0,
    },
    {
      id: 6,
      title: 'Net income',
      mapField: 'actualReceiveSalary',
      value: 0,
    },
  ]);

  // Hàm để ánh xạ dữ liệu từ object vào các trường mapField của state
  const mapDataToObject = (data: any, dataObj: any) => {
    return data.map((section: any) => {
      if (dataObj.hasOwnProperty(section.mapField)) {
        // Nếu có trường mapField tương ứng trong dataObj của parent, gán giá trị vào trường value của parent
        section.value = dataObj[section.mapField];
      }
      return {
        ...section,
        child: section.child?.map((field: any) => {
          // Nếu có trường mapField tương ứng trong dataObj, gán giá trị vào trường value của child
          if (dataObj.hasOwnProperty(field.mapField)) {
            return {
              ...field,
              value: dataObj[field.mapField],
            };
          } else {
            return field;
          }
        }),
      };
    });
  };

  useEffect(() => {
    if (payslipDetail) {
      const updatedData = mapDataToObject(data, payslipDetail);
      setData(updatedData);
    }
  }, [payslipDetail]);

  return (
    <List
      dataSource={data}
      renderItem={(item: any) => (
        <div key={item.id}>
          <List.Item style={{ backgroundColor: '#CDD5DF' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                fontWeight: 'bold',
              }}
            >
              <span style={{ flex: 1, textAlign: 'center' }}>{item.id}</span>
              <span style={{ flex: 3, textAlign: 'left' }}>{item.title}</span>
              <span style={{ flex: 2, textAlign: 'right', paddingRight: 10 }}>{item.value}</span>
            </div>
          </List.Item>
          {item?.child && (
            <List
              dataSource={item.child}
              renderItem={(child: any) => (
                <List.Item key={child.id}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <span style={{ flex: 1, textAlign: 'center' }}>{child.id}</span>
                    <span style={{ flex: 3, textAlign: 'left' }}>{child.title}</span>
                    <span style={{ flex: 2, textAlign: 'right', paddingRight: 10 }}>
                      {child.value}
                    </span>
                  </div>
                </List.Item>
              )}
            />
          )}
        </div>
      )}
    />
  );
};

export default PayslipDetail;

import { setGlobalLoading } from 'app/commonRedux/appSlice';
import { createAsyncThunk } from '@reduxjs/toolkit';
import configurationApi from 'api/configurationApi';
import { notificationToast } from 'components/notificationToast';
import { Notification } from 'constants/notification';

export const leaveOffGroupGetList = createAsyncThunk(
  'configuration/leaveOffGroupGetList',
  async ({ callBack, ...params }: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await configurationApi.leaveOffGroupGetList();
      if (!response?.error && response?.statusCode === 200) {
        thunkApi.dispatch(leaveOffGroupGetListActive({}));
        thunkApi.dispatch(leaveOffGetHistory({}));
      }
      callBack && callBack(response);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const leaveOffGroupGetListActive = createAsyncThunk(
  'configuration/leaveOffGroupGetListActive',
  async ({ callBack, ...params }: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await configurationApi.leaveOffGroupGetListActive();
      callBack && callBack(response);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const leaveOffGroupGet = createAsyncThunk(
  'configuration/leaveOffGroupGet',
  async ({ callBack, ...params }: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await configurationApi.leaveOffGroupGet(params.id);
      callBack && callBack(response);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const leaveOffGroupAdd = createAsyncThunk(
  'configuration/leaveOffGroupAdd',
  async ({ callBack, ...body }: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await configurationApi.leaveOffGroupAdd(body);
      if (!response?.error && response?.statusCode === 200) {
        thunkApi.dispatch(leaveOffGroupGetList({}));
        thunkApi.dispatch(leaveOffGetHistory({}));
        // notificationWithIcon({
        //   type: 'success',
        //   description: response.message,
        //   duration: 3,
        // });
        notificationToast(Notification.Type.Success, response.message, Notification.Duration._3s);
      }
      callBack && callBack(response);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const leaveOffGroupDeactiveChangeType = createAsyncThunk(
  'configuration/leaveOffGroupAdd',
  async ({ callBack, ...body }: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await configurationApi.leaveOffGroupDeactiveChangeType(body);
      if (!response?.error && response?.statusCode === 200) {
        thunkApi.dispatch(leaveOffGroupGetList({}));
        thunkApi.dispatch(leaveOffGetHistory({}));
      }
      callBack && callBack(response);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const leaveOffGroupUpdate = createAsyncThunk(
  'configuration/leaveOffGroupUpdate',
  async ({ callBack, ...params }: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await configurationApi.leaveOffGroupUpdate(params);
      if (!response?.error && response?.statusCode === 200) {
        thunkApi.dispatch(leaveOffGroupGetList({}));
        thunkApi.dispatch(leaveOffGetHistory({}));
      }
      callBack && callBack(response);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);
export const leaveOffGroupDeactive = createAsyncThunk(
  'configuration/leaveOffGroupDeactive',
  async ({ callBack, ...params }: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await configurationApi.leaveOffGroupDeactive(params);
      if (!response?.error && response?.statusCode === 200) {
        thunkApi.dispatch(leaveOffGroupGetList({}));
        thunkApi.dispatch(leaveOffGetHistory({}));
      }
      callBack && callBack(response);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const leaveOffDuplicate = createAsyncThunk(
  'configuration/leaveOffDuplicate',
  async ({ callBack, ...params }: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await configurationApi.leaveOffDuplicate(params);
      if (!response?.error && response?.statusCode === 200) {
        thunkApi.dispatch(leaveOffGroupGetList({}));
        thunkApi.dispatch(leaveOffGetHistory({}));
      }
      callBack && callBack(response);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const leaveOffGroupAddItem = createAsyncThunk(
  'configuration/leaveOffGroupAddItem',
  async ({ callBack, ...params }: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await configurationApi.leaveOffGroupAddItem(params);
      if (!response?.error && response?.statusCode === 200) {
        thunkApi.dispatch(leaveOffGroupGetList({}));
        thunkApi.dispatch(leaveOffGetHistory({}));
      }
      callBack && callBack(response);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const leaveOffGroupUpdateItem = createAsyncThunk(
  'configuration/leaveOffGroupUpdateItem',
  async ({ callBack, ...params }: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await configurationApi.leaveOffGroupUpdateItem(params);
      if (!response?.error && response?.statusCode === 200) {
        thunkApi.dispatch(leaveOffGroupGetList({}));
        thunkApi.dispatch(leaveOffGetHistory({}));
      }
      callBack && callBack(response);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const leaveOffGetHistory = createAsyncThunk(
  'configuration/leaveOffGetHistory',
  async ({ callBack, ...params }: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await configurationApi.leaveOffGetHistory();
      callBack && callBack(response);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const leaveOffGetHistoryDetails = createAsyncThunk(
  'configuration/leaveOffGetHistoryDetails',
  async ({ callBack, ...params }: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await configurationApi.leaveOffGetHistoryDetails(params.id);
      callBack && callBack(response);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const leaveOffMoveDays = createAsyncThunk('/configurations/group-leave-off', async () => {
  try {
    const response = await configurationApi.leaveOffMoveDate();
    return response;
  } catch (error) {
    return error;
  }
});

export const previewMoveDays = createAsyncThunk(
  '/configurations/preview-move-leave-off',
  async ({ callBack, ...params }: any, thunkApi: any) => {
    try {
      const { checkDaysLeaveOffUser, chooseId, moveId } = params;
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await configurationApi.reviewData({ chooseId: +chooseId, moveId: +moveId });
      response && checkDaysLeaveOffUser(response);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const submitMoveDays = createAsyncThunk(
  '/configurations/move-leave-off',
  async ({ callBack, ...params }: any, thunkApi: any) => {
    try {
      const { saveSuccess } = params;
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await configurationApi.submitData(params);
      response && saveSuccess(response);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const getHistoryMoveData = createAsyncThunk(
  '/configurations/history-move-leave-off',
  async () => {
    try {
      const response = await configurationApi.getHistoryMoveData();
      return response;
    } catch (error) {
      return error;
    }
  },
);

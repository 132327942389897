import { IContractManagement, IEmployee, ListParams, ListResponse, SuccessResponse } from 'types';
import axiosClient from './axiosClient';

const contractManagementApi = {
  getAll(
    params: Partial<any>,
  ): Promise<SuccessResponse<{ data: IContractManagement[]; total: number }>> {
    return axiosClient.post('/contract-employee/contract-management', {
      ...params,
    });
  },

  getEmployeeList: (
    payload: Partial<ListParams>,
  ): Promise<SuccessResponse<ListResponse<IEmployee>>> => {
    return axiosClient.post(`/employee/search-employee`, payload);
  },

  getContractManagementByFilter(
    params: any,
  ): Promise<SuccessResponse<{ data: IContractManagement[]; total: number }>> {
    return axiosClient.post('/contract-employee/contract-management', {
      ...params,
    });
  },
};

export default contractManagementApi;

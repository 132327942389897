import { Table, TablePaginationConfig } from 'antd';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import payrollApi from 'api/payrollApi';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { DownTrendIcon, UpTrendIcon } from 'assets/icons';
import EmployeeColumn from 'components/EmployeeColumn';
import ITVTable from 'components/ITVTable';
import { selectPermissions } from 'features/auth/authSlice';
import { setCheckExpand } from 'features/employee/employeeSlice';
import { formatCurrencyUnit, labelUnit, uuidv4 } from 'features/insurances/constants/common';
import StatusColumnCell from 'features/payroll/pages/ViewEmployee/components/StatusColumnCell';
import { TitleColumnCenter } from 'styles/tableStyled';
import { AllowanceDetails, AllowanceSumDetail, IAllowanceList } from 'types';
import MaskedInputNumberRenderer from 'components/MaskedInputNumberRenderer';
import MaskedValueRenderer from 'components/MaskedValueRenderer';

interface AllowanceListProps {
  loading: boolean;
  data: IAllowanceList[];
  totalResult: number;
  offset: number;
  limit: number;
  setOffset: Dispatch<SetStateAction<number>>;
  setLimit: Dispatch<SetStateAction<number>>;
  onRefresh: () => void;
  onTableChange?: (
    pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue | null>,
    sorter: SorterResult<any>,
  ) => void;
  sumAllowance: AllowanceSumDetail[];
  sumTotal: number;
  monthYear: string;
}

export function AllowanceList({
  loading,
  data,
  totalResult,
  offset,
  limit,
  setOffset,
  setLimit,
  onRefresh,
  onTableChange,
  sumAllowance,
  sumTotal,
  monthYear,
}: AllowanceListProps) {
  const { t } = useTranslation(['employee']);
  const permissions = useAppSelector(selectPermissions);
  const dispatch = useAppDispatch();

  const [widthTable] = useState(978);
  const [listData, setListData] = useState<IAllowanceList[]>([]);
  const [, setSelectedUser] = useState<string[]>([]);
  const [isLoadingGetHistory, setIsLoadingGetHistory] = useState(false);
  const selectRowExpand = useAppSelector((state) => state.employee.selectExpand) as string[];

  const COLUMNS: any = [
    {
      title: () => {
        return (
          <TitleColumnCenter>{t('employee:employee_information_fields.id')}</TitleColumnCenter>
        );
      },
      dataIndex: 'employeeId',
      key: 'employeeId',
      ellipsis: true,
      width: 120,
      minWidth: 120,
      maxWidth: 120,
      align: 'center',
      fixed: 'left',
      sorter: true,
      render: function (text: any, record: IAllowanceList, index: any) {
        return record.isChildren ? '' : record.user.employeeId;
      },
    },
    {
      title: () => {
        return (
          <TitleColumnCenter>
            {t('employee:employee_information_fields.employee')}
          </TitleColumnCenter>
        );
      },
      dataIndex: 'fullName',
      key: 'fullName',
      width: 220,
      minWidth: 220,
      maxWidth: 220,
      ellipsis: true,
      fixed: 'left',
      sorter: true,
      render: function (text: any, record: IAllowanceList, index: any) {
        return record.isChildren ? (
          record.nameMonth
        ) : (
          <EmployeeColumn
            avatar={record?.user?.fullName}
            fullName={record?.user?.fullName}
            email={record?.user?.email}
          />
        );
      },
    },
  ];

  const [columnsAllowance, setColumnsAllowance] = useState(COLUMNS);

  useEffect(() => {
    const addColumn: any = [];
    sumAllowance.forEach((item: AllowanceSumDetail) => {
      addColumn.push({
        title: <div style={{ padding: '8px' }}>{item.name}</div>,
        dataIndex: `allowance${item.id}`,
        key: `allowance${item.id}`,
        width: 150,
        minWidth: 150,
        align: 'center',
        render: function (text: any, record: IAllowanceList, index: any) {
          const moneyAllowance = record.allowances
            ?.filter((obj: AllowanceDetails) => obj.id === item.id)
            .map((obj: AllowanceDetails) => obj.money)[0];

          const status = record.allowances
            ?.filter((obj: AllowanceDetails) => obj.id === item.id)
            .map((obj: AllowanceDetails) => obj.isChange)[0];

          return (
            <div
              style={{
                display: 'flex',
                gap: '4px',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {status === 'increase' ? (
                <>
                  <UpTrendIcon />
                  <StyledPercentage showUpTrendIcon={true} showDownTrendIcon={false}>
                    <MaskedValueRenderer value={moneyAllowance} />
                  </StyledPercentage>
                </>
              ) : status === 'decrease' ? (
                <>
                  <DownTrendIcon />
                  <StyledPercentage showUpTrendIcon={false} showDownTrendIcon={true}>
                    <MaskedValueRenderer value={0} />
                    {/* {formatCurrencyUnit(0, labelUnit)} */}
                  </StyledPercentage>
                </>
              ) : (
                <MaskedValueRenderer value={moneyAllowance} />
              )}
            </div>
          );
        },
      });
    });

    addColumn.push(
      {
        title: <div style={{ padding: '8px' }}>Sum</div>,
        dataIndex: 'sum',
        key: 'sum',
        width: 150,
        minWidth: 150,
        align: 'center',
        render: function (text: any, record: IAllowanceList, index: any) {
          return <MaskedValueRenderer value={record.sumMoney} />;
        },
      },
      {
        title: () => {
          return (
            <TitleColumnCenter>
              {t('employee:employee_information_fields.action')}
            </TitleColumnCenter>
          );
        },
        dataIndex: 'action',
        key: 'action',
        width: 60,
        minWidth: 60,
        align: 'center',
        fixed: 'right',
        render: (status: any, record: IAllowanceList, index: any) => {
          return record.isChildren ? (
            ''
          ) : (
            <StatusColumnCell
              showMenu={'allowance'}
              t={t}
              isShowFullMenu={true}
              item={record?.user}
              permissions={permissions}
              callBack={() => onRefresh()}
              monthYear={monthYear}
            />
          );
        },
      },
    );
    setColumnsAllowance([...COLUMNS, ...addColumn]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monthYear, permissions, sumAllowance]);

  useEffect(() => {
    setListData(data);
  }, [data]);

  const handleExpand = (expanded: boolean, record: any) => {
    if (expanded) {
      dispatch(setCheckExpand([...selectRowExpand, ...[record.userId]]));

      const fetchAllowanceYearAPI = async () => {
        try {
          setIsLoadingGetHistory(true);
          const response = await payrollApi.getAllowanceYearByUserId({
            userId: record.userId,
            monthYear: monthYear,
          });
          const customResponse = response.data.map((obj: AllowanceDetails, index: number) => {
            return { ...obj, userId: uuidv4() };
          });
          const updatedArrayOfObjects = listData.map((obj) =>
            record.userId === obj.userId ? { ...obj, children: customResponse } : obj,
          );
          setListData(updatedArrayOfObjects);
          setIsLoadingGetHistory(false);
        } catch (error) {
          setIsLoadingGetHistory(false);
        }
      };

      fetchAllowanceYearAPI();
    } else {
      dispatch(setCheckExpand(selectRowExpand.filter((id) => id !== record.userId)));

      const updatedArrayOfObjects = listData.map((obj) => {
        if (record.userId === obj.userId) {
          const { children, ...rest } = obj;
          return { ...rest, children: [] };
        }
        return obj;
      });

      setListData(updatedArrayOfObjects);
    }
  };

  return (
    <ITVTable
      columns={columnsAllowance}
      data={listData}
      totalResult={totalResult}
      loading={isLoadingGetHistory || loading}
      width={widthTable}
      rowKey={['userId']}
      setOffset={setOffset}
      offset={offset}
      setLimit={setLimit}
      limit={limit}
      setSelectedUser={setSelectedUser}
      summary={() => {
        return (
          <Table.Summary fixed="top">
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={3}>
                <strong>{t('insurance:total')} :</strong>
              </Table.Summary.Cell>
              {sumAllowance.map((item: AllowanceSumDetail) => {
                return (
                  <Table.Summary.Cell index={4} colSpan={1} className="ant-table-cell">
                    <TotalRecordPayment>
                      <MaskedValueRenderer value={item.sum} />
                    </TotalRecordPayment>
                  </Table.Summary.Cell>
                );
              })}
              <Table.Summary.Cell index={4} colSpan={1} className="ant-table-cell">
                <TotalRecordPayment>
                  <MaskedValueRenderer value={sumTotal} />
                </TotalRecordPayment>
              </Table.Summary.Cell>
              <Table.Summary.Cell
                index={sumAllowance.length + 4}
                colSpan={1}
                className="ant-table-cell"
              >
                <TotalRecordPayment></TotalRecordPayment>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        );
      }}
      onChange={onTableChange}
      isRecordYear={true}
      isFixedLast={true}
      expandable={{
        onExpand: handleExpand,
        expandedRowKeys: selectRowExpand,
        rowExpandable: (record: any) => record.children && record.children.length > 0,
      }}
    />
  );
}

interface StyledPercentageProps {
  showUpTrendIcon?: boolean;
  showDownTrendIcon?: boolean;
}

const StyledPercentage = styled.div`
  ${(props: StyledPercentageProps) => {
    return css`
      color: ${props.showUpTrendIcon && 'var(--cl_success500)'};
      color: ${props.showDownTrendIcon && 'var(--cl_error500)'};
    `;
  }}
`;

const TotalRecordPayment = styled.div`
  display: flex;
  justify-content: center;
  font-weight: bold;
`;

import { ExportOutlined } from '@ant-design/icons';
import { Modal, TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import omit from 'lodash/omit';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import payrollApi from 'api/payrollApi';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { ReasonForm } from 'components/Forms';
import { notificationToast } from 'components/notificationToast';
import { MONTH_YEAR_FORMAT } from 'constants/commons';
import { Notification } from 'constants/notification';
import {
  CONTRACT_SALARY_SORT_FIELDS,
  ContractSalaryConfirmStatus,
  SORT_TYPES,
} from 'constants/payroll';
import CustomButton from 'styles/buttonStyled';
import { ContainerBgWhite } from 'styles/containerBgWhite';
import {
  ContractSalaryPayload,
  ContractSalary as IContractSalary,
  IContractSalaryFilters,
  Reason,
} from 'types';
import { exportToCSV } from 'utils/common';
import { getErrorMessage } from 'utils/getErrorMessage';
import { ContractSalaryFilters } from './ContractSalaryFilters';
import { ContractSalaryList } from './ContractSalaryList';
import { selectEmployee } from 'features/employee/employeeSlice';
import { syncPayrollPrevMonthContactSalary } from 'features/payroll/payrollAction';

const StyledWrapperFilters = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const StyledWrapperActionBtn = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const ConfirmedButton = styled.div`
  background-color: var(--cl_success100);
  color: var(--cl_success600);
  font-weight: bold;
  border-radius: 5px;
  text-align: center;
  display: inline;
  padding: 5px 10px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 4px;
`;

interface ContractSalaryProps {
  monthYear: string;
  fetchListStatusTabPayrollAPI: () => void;
}

export function ContractSalary({ monthYear, fetchListStatusTabPayrollAPI }: ContractSalaryProps) {
  const { t } = useTranslation(['action', 'payroll']);
  const selectRowKey = useAppSelector((state) => state.employee.selectRowKey) as string[];
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [isConfirm, setIsConfirm] = useState(false);
  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const [contractSalaryList, setContractSalaryList] = useState<Array<IContractSalary>>([]);
  const [totalResult, setTotalResult] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState<Partial<IContractSalaryFilters>>({
    search: '',
    taxProfile: '',
    line: '',
  });
  const isCheckAll: boolean = useAppSelector(selectEmployee).isCheckAll;
  const dispatch = useAppDispatch();

  const filtersMemo = useMemo(() => {
    if (isCheckAll) {
      return {
        ...filters,
        monthYear: monthYear,
      };
    }
    return {
      ...filters,
      monthYear: monthYear,
      offset: page,
      limit,
    };
  }, [filters, monthYear, page, limit, isCheckAll]);

  const isConfirmContractSalary =
    contractSalaryList.length === 0
      ? false
      : contractSalaryList.every((x) => x.isConfirm === ContractSalaryConfirmStatus.APPROVED);

  const disabledConfirmBtn =
    contractSalaryList.length === 0 || isConfirmContractSalary || isLoading;

  useEffect(() => {
    fetchContractSalaryListAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersMemo]);

  const fetchContractSalaryListAPI = async () => {
    try {
      setIsLoading(true);
      const response = Boolean(filtersMemo.year)
        ? await payrollApi.getContractSalaryListByYear(omit(filtersMemo, 'monthYear'))
        : await payrollApi.getContractSalaryListByMonth(filtersMemo);
      setIsLoading(false);

      setContractSalaryList(response.data?.result || []);
      setTotalResult(response.data?.total || 0);
    } catch (error) {
      setIsLoading(false);
      const message = getErrorMessage(error);
      notificationToast(Notification.Type.Error, message, Notification.Duration._3s);
    }
  };

  const handleConfirmSalaryOk = async () => {
    try {
      const payload = {
        monthYear: filtersMemo.monthYear,
        status: ContractSalaryConfirmStatus.APPROVED,
      };

      setIsConfirm(true);
      const response = await payrollApi.confirmContractSalary(payload);
      setIsConfirm(false);

      notificationToast(Notification.Type.Success, response.message, Notification.Duration._3s);
      fetchListStatusTabPayrollAPI();
      fetchContractSalaryListAPI();
    } catch (error) {
      setIsConfirm(false);
      const message = getErrorMessage(error);
      notificationToast(Notification.Type.Error, message, Notification.Duration._3s);
    }
  };

  const handleConfirmSalary = () => {
    Modal.confirm({
      title: <div style={{ fontWeight: 'bold' }}>{t('payroll:confirm_contract_salary_title')}</div>,
      okButtonProps: { loading: isConfirm },
      okText: t('action:ok'),
      cancelText: t('action:cancel'),
      onOk: handleConfirmSalaryOk,
    });
  };

  const handleExport = async () => {
    try {
      const payload = {
        monthYear: filtersMemo.monthYear,
      };

      if (filters.line) {
        Object.assign(payload, { line: filters.line });
      }

      if (filters.taxProfile) {
        Object.assign(payload, { taxProfile: filters.taxProfile });
      }

      setIsLoadingExport(true);
      const response = Boolean(filtersMemo.year)
        ? await payrollApi.exportContractSalaryByYear({ year: filtersMemo.year }, selectRowKey)
        : await payrollApi.exportContractSalaryByMonth(payload, selectRowKey);
      setIsLoadingExport(false);

      const exportFileName = `contract_salary_${new Date().getTime()}.xlsx`;
      exportToCSV(response, exportFileName);
    } catch (error) {
      setIsLoadingExport(false);
    }
  };

  const handleFiltersChange = ({ isYearMode, ...payload }: ContractSalaryPayload) => {
    setPage(1);

    setFilters((prevFilter) => {
      if (isYearMode) {
        delete prevFilter.taxProfile;
      } else {
        delete prevFilter.year;
      }

      return {
        ...prevFilter,
        ...payload,
      };
    });
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue | null>,
    sorter: SorterResult<any>,
  ) => {
    if (pagination?.current === page) {
      setPage(1);

      setFilters((prevFilters) => ({
        ...prevFilters,
        viewBy: sorter.column
          ? CONTRACT_SALARY_SORT_FIELDS[sorter.field as keyof typeof CONTRACT_SALARY_SORT_FIELDS]
          : CONTRACT_SALARY_SORT_FIELDS.fullName,
        sortBy: sorter.column
          ? SORT_TYPES[sorter.order as keyof typeof SORT_TYPES]
          : SORT_TYPES.ascend,
      }));
    }
  };

  const handleReset = async (reason: string) => {
    try {
      const params = {
        monthYear: moment(filtersMemo.monthYear).format(MONTH_YEAR_FORMAT),
        timesheetType: 'salary',
        reason,
      };

      setIsConfirm(true);
      const response = await payrollApi.resetConfirmPayroll(params);
      setIsConfirm(false);
      notificationToast(Notification.Type.Success, response.message, Notification.Duration._3s);
      fetchListStatusTabPayrollAPI();
      fetchContractSalaryListAPI();
    } catch (error) {
      setIsConfirm(false);
      const message = getErrorMessage(error);
      notificationToast(Notification.Type.Error, message, Notification.Duration._3s);
    }
  };

  const handleResetContractSalary = () => {
    let reason = '';
    Modal.confirm({
      title: <div style={{ fontWeight: 'bold' }}>{t('reset_reason')}</div>,
      content: (
        <>
          <ReasonForm
            onSubmit={(payload: Reason) => {
              if (!payload.reason) return;
              reason = payload.reason;
            }}
          />
          <p style={{ margin: 0, color: 'red', fontStyle: 'italic' }}>
            {t('payroll:payroll_deleted', { monthYear })}
          </p>
        </>
      ),
      okButtonProps: { loading: isLoading },
      okText: t('action:ok'),
      cancelText: t('action:cancel'),
      onOk: () => handleReset(reason),
      onCancel: () => (reason = ''),
    });
  };

  const handleSync = () => {
    dispatch(
      syncPayrollPrevMonthContactSalary({
        monthYear: monthYear ?? moment(new Date()).format('YYYY-MM'),
        callBack: () => fetchContractSalaryListAPI(),
      }),
    );
  };

  return (
    <ContainerBgWhite>
      <StyledWrapperFilters>
        <ContractSalaryFilters
          initialValues={{
            search: filters.search,
            taxProfile: filters.taxProfile,
            year: moment(new Date()),
          }}
          onSubmit={handleFiltersChange}
        />

        <StyledWrapperActionBtn>
          <CustomButton onClick={handleSync}>{t('payroll:sync_prev_month')}</CustomButton>
          {!filtersMemo.year && isConfirmContractSalary && (
            <>
              <CustomButton loading={false} onClick={handleResetContractSalary}>
                {t('reset')}
              </CustomButton>
              <ConfirmedButton>{t('Confirmed')}</ConfirmedButton>
            </>
          )}

          {!filtersMemo.year && !isConfirmContractSalary && (
            <CustomButton
              loading={false}
              disabled={disabledConfirmBtn}
              onClick={handleConfirmSalary}
            >
              {t('Confirm')}
            </CustomButton>
          )}

          <CustomButton
            icon={<ExportOutlined />}
            loading={isLoadingExport}
            disabled={selectRowKey.length === 0}
            onClick={handleExport}
          >
            {t('export')}
          </CustomButton>
        </StyledWrapperActionBtn>
      </StyledWrapperFilters>

      <ContractSalaryList
        monthYear={monthYear}
        isYearMode={Boolean(filtersMemo.year)}
        loading={isLoading}
        contractSalaryList={contractSalaryList}
        totalResult={totalResult}
        page={page}
        limit={limit}
        setPage={setPage}
        setLimit={setLimit}
        onTableChange={handleTableChange}
        onRefresh={fetchContractSalaryListAPI}
      />
    </ContainerBgWhite>
  );
}

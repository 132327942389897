import styled from 'styled-components';
import { Input } from 'antd';

const CustomInput = styled(Input)`
  width: 100%;
  height: 39px;
  border-radius: 4px;
  & > input {
    font-size: 14px;
    color: var(--cl_gray900);
  }
  &[disabled] {
    background-color: hsl(0, 0%, 95%);
    border-color: hsl(0, 0%, 90%);
    color: var(--cl_gray900);
  }
`;

export default CustomInput;

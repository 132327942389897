import { DatePicker, Select } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import IconExport from 'assets/icons/ic_export_gray.svg';
import { CustomIcon } from 'components/ITVSearch';
import ITVTable from 'components/ITVTable';
import { YYYY_MM_DD } from 'constants/commons';
import { exportReport } from 'features/employee/employeeAction';
import { ContainerMain } from 'features/masterData/Category/styled';
import { HeaderRemove } from 'features/masterData/Department/styled';
import { useDispatch } from 'react-redux';
import moment from 'moment';
const { RangePicker } = DatePicker;
interface PayloadReport {
  start_date: string;
  end_date: string;
  report_type: string;
  year: string;
  export_type: 'pdf' | 'excel';
}
interface ReportItem {
  id: number;
  label: string;
  start_date: string;
  end_date: string;
  exportTypes: 'excel' | 'pdf';
}
export const Report = (tax: any) => {
  const listReports = tax?.data
    ? [
        {
          id: 2,
          label:
            'Detailed list of individuals subject to tax calculation according to the progressive partial schedule (Form 05/QTT-TNCN)',
          start_date: undefined,
          end_date: undefined,
          exportTypes: 'excel',
        },
      ]
    : [
        {
          id: 1,
          label: 'Report on Labor Utilization (Form 01/PLI)',
          start_date: undefined,
          end_date: undefined,
          exportTypes: 'excel',
        },
        // {
        //   id: 2,
        //   label: 'Mẫu import thông tin NLD lên BHXH',
        //   start_date: undefined,
        //   end_date: undefined,
        //   exportTypes: 'excel',
        // },
        // {
        //   id: 3,
        //   label: 'Báo cáo tình hình tăng giảm BHXH (Mẫy số 0002/PLI)',
        //   start_date: undefined,
        //   end_date: undefined,
        //   exportTypes: 'excel',
        // },
      ];
  // init variable
  const { t } = useTranslation(['report']);
  const [data, setData] = useState(listReports);
  const [yearChoose, setYearChoose] = useState(moment());
  const IconExportComponents = (
    <CustomIcon type={'export'} component={() => <img src={IconExport} alt="" />} />
  );

  const dispatch = useDispatch();

  const handleExport = (record: ReportItem) => {
    const body: PayloadReport = {
      start_date: record.start_date || '',
      end_date: record.end_date || '',
      report_type: record.id + '',
      export_type: record.exportTypes,
      year: yearChoose.format('YYYY'),
    };
    dispatch(exportReport({ body }));
  };

  const exportTypes = [
    {
      value: 'excel',
      label: 'Excel',
    },
    {
      value: 'pdf',
      label: 'PDF',
    },
  ];

  const onChangeDateTime = (_: any, dateStrings: any, record: ReportItem) => {
    const foundIndex = data.findIndex((report) => report.id === record.id);
    if (foundIndex !== -1) {
      const newData = data;
      newData[foundIndex].start_date = dateStrings[0];
      newData[foundIndex].end_date = dateStrings[1];
      setData(newData);
    }
  };

  const disabledYear = (current: any) => {
    return current && current.format('YYYY') >= moment().format('YYYY');
  };

  const onSelectTypes = (value: any, record: ReportItem) => {
    const foundIndex = data.findIndex((report) => report.id === record.id);
    if (foundIndex !== -1) {
      const newData = data;
      newData[foundIndex].exportTypes = value;
      setData(newData);
    }
  };

  const preYear = moment().add(-1, 'years').format('YYYY');
  let COLUMNS = [
    {
      title: 'Report name',
      dataIndex: 'label',
      key: 'label',
      ellipsis: true,
      width: 100,
      minWidth: 100,
    },
    {
      title: 'Filter period',
      dataIndex: 'filterPeriod',
      key: 'filterPeriod',
      ellipsis: true,
      align: 'center',
      width: 100,
      minWidth: 100,
      render: function (text: any, record: ReportItem, index: any) {
        return tax.data ? (
          <DatePicker
            disabledDate={disabledYear}
            picker="year"
            onChange={(e: any) => setYearChoose(e)}
          ></DatePicker>
        ) : (
          <RangePicker
            format={YYYY_MM_DD}
            style={{ marginLeft: '1%' }}
            onChange={(date, dateString) => onChangeDateTime(date, dateString, record)}
          />
        );
      },
    },
    {
      title: 'Export format',
      dataIndex: 'exportFormat',
      key: 'exportFormat',
      ellipsis: true,
      align: 'center',
      width: 60,
      minWidth: 60,
      render: function (text: any, record: ReportItem, index: any) {
        return (
          <Select
            defaultValue={record.exportTypes}
            style={{ width: 130 }}
            onSelect={(value: string) => {
              onSelectTypes(value, record);
            }}
            options={exportTypes}
          />
        );
      },
    },
    {
      title: t('action:action'),
      dataIndex: 'action',
      align: 'center',
      key: 'action',
      width: 50,
      minWidth: 50,
      render: function (text: any, record: ReportItem, index: any) {
        return (
          <HeaderRemove
            onClick={() => handleExport(record)}
            style={{ marginLeft: 5 }}
            icon={IconExportComponents}
          >
            {t('action:export')}
          </HeaderRemove>
        );
      },
    },
  ];

  return (
    <ContainerMain>
      <ITVTable columns={COLUMNS} data={data} isRowSelect={false} />
    </ContainerMain>
  );
};

export const StyledContainer = styled.div`
  --row-height: 320px;
  --gap: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
`;
function dangerouslySetInnerHTML(arg0: string) {
  throw new Error('Function not implemented.');
}

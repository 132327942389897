import { Avatar } from 'antd';
import styled, { css } from 'styled-components';

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
`;

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const StyledWrapperName = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const StyledTitle = styled.div`
  color: var(--cl_neutral900);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

const StyledSubTitle = styled.div`
  color: var(--cl_neutral700);
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
`;

const StyledTag = styled.div`
  padding: 4px 10px;
  border: 1px solid var(--cl_neutral200);
  color: var(--cl_neutral900);
  border-radius: 999px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;

  ${(props: StyledTagProps) =>
    props.tagColor &&
    css`
      border-color: ${props.tagColor};
      color: ${props.tagColor};
    `}
`;

interface StyledTagProps {
  tagColor?: string;
}

interface ListingItemProps {
  imageUrl?: string;
  title: string;
  subTitle?: string;
  tag: string;
  tagColor?: string;
}

export function ListingItem({ imageUrl, title, subTitle, tag, tagColor }: ListingItemProps) {
  const wordList = imageUrl?.split(' ') || [];
  const avatar =
    wordList?.[0]?.charAt(0).toUpperCase() +
    wordList?.[wordList.length - 1]?.charAt(0).toUpperCase();

  return (
    <StyledContainer>
      <StyledWrapper>
        {imageUrl && <Avatar style={{ backgroundColor: '#620600' }}>{avatar}</Avatar>}
        <StyledWrapperName>
          <StyledTitle>{title}</StyledTitle>
          {subTitle && <StyledSubTitle>{subTitle}</StyledSubTitle>}
        </StyledWrapperName>
      </StyledWrapper>

      {tag && <StyledTag tagColor={tagColor}>{tag}</StyledTag>}
    </StyledContainer>
  );
}

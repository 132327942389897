import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

import { contractGet } from 'features/contract/contractAction';

const contract = createSlice({
  name: 'contract',
  initialState: {
    isLoading: false,
    error: null,
    dataListContract: null,
    totalContract: 0,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(contractGet.pending, (state, action: any) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(contractGet.fulfilled, (state, action: any) => {
        state.isLoading = false;
        state.dataListContract = action.payload?.data?.result;
        state.totalContract = action.payload?.data?.total;
        state.error = null;
      })
      .addCase(contractGet.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload?.message;
      });
  },
});

export const selectListContract = (state: RootState) => state.dataListContract;

export const { reducer, actions } = contract;

export default reducer;

import {
  ArrowRightOutlined,
  ColumnWidthOutlined,
  InfoCircleOutlined,
  UserAddOutlined,
} from '@ant-design/icons';
import { Card, Form, Input, Popconfirm, Select } from 'antd';

import { List, Space } from 'antd';
import MenuItem from 'antd/lib/menu/MenuItem';
import { useAppSelector } from 'app/hooks';
import ITVSelect from 'components/ITVSelect';
import { MODE } from 'constants/types';
import { getCategoryWithType } from 'features/configuration/categoryAction';
import {
  DotColorStyled,
  ItemColorStyled,
  SelectOptionStyled,
  SelectStyled,
} from 'features/configuration/components/LeaveOffItem/styled';
import { selectConfiguration } from 'features/configuration/configurationSlice';
import { selectDepartment } from 'features/department/departmentSlice';
import { filterSortEmployeeList, getEmployeeDetail } from 'features/employee/employeeAction';
import { actions, selectEmployee, selectEmployeeList } from 'features/employee/employeeSlice';
import { IEmployee, IEmployeeDetail } from 'hrm-common/extensions/interfaces/personel';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch } from 'react-redux';
import CustomButton from 'styles/buttonStyled';
import { capitalizeFirstLetter, removeAccents } from 'utils/text';
import { CustomDrawer } from '../../ManagePayroll/components/Workflow2';
import { CustomCard, CustomFormItem, CustomMenu, CustomTag } from '../styled';
import EmployeeColumn from './EmployeeColumn';
import ITVSelectDepartment from './ITVSelectDepartment';
import './indexAddEditWL.css';

const { Option } = Select;

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}
const ModalNodeEdit = (props: any) => {
  const {
    form,
    edgenextValueNode,
    edgePrevValueNode,
    valueNode,
    initialEdges,
    nodes,
    loadMoreData,
    currentItem,
    modalType,
    selectStatus,
    onSave,
    openKeys,
    setOpenKeys,
    errMessageStatusNode,
    setErrMessageStatusNode,
    errMessageDesNode,
    setErrMessageDesNode,
    visible,
    onClose,
    onDelete,
  } = props;

  const { t } = useTranslation([
    'validation',
    'overtime',
    'action',
    'onboard',
    'validation',
    'timesheet',
  ]);
  const dataStore = useAppSelector(selectConfiguration);
  const [, setDataEmployee] = useState([]);
  const [colorNode, setColorNode] = useState('red');
  const [, setDataSelectDepartment] = useState([]);
  const [dataSelecListEmployee, setDataSelecListEmployee] = useState([]);
  const [, setDataListIdEmployee] = useState([]);
  const [, setLoadingData] = useState(false);
  const { resetStateEmployee, resetStateEmployeeList } = actions;
  const [arrColor, setArrColor] = useState([]);

  const dispatch = useDispatch();
  const [valueNode1, setValueNode1] = useState();

  useEffect(() => {
    setValueNode1(valueNode);
  }, [valueNode]);

  useEffect(() => {
    const arrEmployee = nodes?.find((item: any, idx: any) => {
      return valueNode1?.id === item?.id;
    })?.data?.employee;
    setDataEmployee(arrEmployee);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueNode1]);

  const dataDepartment = useAppSelector(selectDepartment);
  const dataFindOneStatus = selectStatus !== undefined ? dataStore?.dataGroupStatus : [];

  useEffect(() => {
    if (modalType === MODE.EDIT) {
      let listEmployee = valueNode1?.data?.employees;

      setDataSelecListEmployee(listEmployee || []);
      if (typeof valueNode1?.data?.status === 'object') {
        form.setFieldsValue({
          status: valueNode1?.data?.status?.id,
          listEmployee: listEmployee,
          // statusName: valueNode1?.data?.statusName,
          colorNode: valueNode1?.style?.background,
        });
      } else {
        form.setFieldsValue({
          status: valueNode1?.data?.status,
          listEmployee: listEmployee,
          // statusName: valueNode1?.data?.statusName,
          colorNode: valueNode1?.style?.background,
        });
      }
    }

    form.resetFields(['listDepartment', 'employeeId']);
    setDataSelectDepartment([]);

    dispatch(resetStateEmployeeList({}));
    return () => {
      dispatch(resetStateEmployee({}));
      dispatch(resetStateEmployeeList({}));
      setDataSelectDepartment([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueNode1, currentItem]);

  const employeeList: IEmployeeDetail[] = useAppSelector(selectEmployeeList);
  const employee: IEmployee = useAppSelector(selectEmployee);

  const handleSelectDepartment = (departmentId) => {
    if (departmentId !== 'All') {
      dispatch(
        filterSortEmployeeList({
          filter: {
            departmentName: [departmentId],
          },
        }),
      );
    } else {
      dispatch(
        filterSortEmployeeList({
          filter: {
            departmentName: [],
          },
        }),
      );
    }
    setLoadingData(true);
    form.setFieldsValue({ departmentId, employeeId: undefined });
    // await (onChangeEmployeeId && onChangeEmployeeId(employeeId));
    setLoadingData(false);
  };

  const onAddEmployee = () => {
    if (!_.isEmpty(employee?.employeeDetail)) {
      let newList =
        dataSelecListEmployee && dataSelecListEmployee?.concat(employee?.employeeDetail);

      let uniq =
        newList &&
        Object?.values(newList?.reduce((acc, cur) => Object?.assign(acc, { [cur?.id]: cur }), {}));
      const listIdEmployee = uniq?.map((item: any) => {
        return item?.id;
      });
      setDataSelecListEmployee(uniq); //To view screen
      setDataListIdEmployee(listIdEmployee); //To send BE
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      listEmployee: dataSelecListEmployee, //send BE
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSelecListEmployee]);

  const onDeleteEmployee = (id: any) => {
    let newList = dataSelecListEmployee.filter(function (item) {
      return item.id !== id?.id;
    });
    const listIdEmployee = newList?.map((item: any) => {
      return item?.id;
    });
    setDataSelecListEmployee(newList); //To view screen
    setDataListIdEmployee(listIdEmployee); //To send BE
  };

  useEffect(() => {
    dispatch(
      getCategoryWithType({
        typeConfig: 'color',
        callBack: (response: any) => {
          if (!response?.error && response?.statusCode === 200) {
            setArrColor(response?.data?.value);
          }
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [, setValueStatusNode] = useState('');

  const handleSelectStatusNode = (value) => {
    let checkDuplicateStatusNode = nodes
      ?.filter((item) => item?.id !== valueNode1?.id)
      ?.find((item: any) => {
        return (item?.data?.status?.id || item?.data?.status) === value;
      });

    if (!checkDuplicateStatusNode) {
      setValueStatusNode(value);
      setErrMessageStatusNode(undefined);
    } else {
      setErrMessageStatusNode(checkDuplicateStatusNode);
    }
  };
  const handleDescriptionNode = (value) => {
    let checkDuplicateDescriptionNode = nodes
      ?.filter((item) => item?.id !== valueNode?.id)
      ?.find((item: any) => {
        return item?.data?.label.trim() === value.trim();
      });

    if (!checkDuplicateDescriptionNode) {
      setValueStatusNode(value);
      setErrMessageDesNode(undefined);
    } else {
      setErrMessageDesNode(checkDuplicateDescriptionNode);
    }
  };
  const items: MenuItem[] = [
    getItem('Info Process', 'sub0', <InfoCircleOutlined />, [
      getItem(
        <Card style={{ marginBottom: '30px' }}>
          <Form.Item name={'colorNode'} label={<div>Color Process</div>}>
            <SelectStyled
              placeholder={'Please select color'}
              value={colorNode}
              onChange={(value: any) => {
                setColorNode(value);
              }}
            >
              {arrColor.map((item: any) => {
                return (
                  <SelectOptionStyled
                    key={item.id}
                    value={item.code}
                    // label={capitalizeFirstLetter(item.name)}
                  >
                    <ItemColorStyled>
                      <DotColorStyled
                        style={{
                          backgroundColor: item.code,
                        }}
                      />
                      {capitalizeFirstLetter(item.name)}
                    </ItemColorStyled>
                  </SelectOptionStyled>
                );
              })}{' '}
            </SelectStyled>
          </Form.Item>

          <Form.Item
            name={'desValue'}
            label={<div>Description</div>}
            rules={[
              {
                required: true,
                message: t('HRM_VAD_REQUIRED', { field: 'Description' }),
              },
            ]}
            style={{ marginBottom: errMessageDesNode ? '0px' : '30px' }}
          >
            <Input
              onChange={(e: any) => handleDescriptionNode(e.target.value)}
              placeholder={'Please input description'}
              maxLength={255}
            />
          </Form.Item>
          {errMessageDesNode && <p style={{ color: 'red' }}>Description is duplicate</p>}

          {/* status */}
          {valueNode1?.id !== '1' && (
            <>
              <Form.Item
                name="status"
                rules={[
                  {
                    required: true,
                    message: t('HRM_VAD_REQUIRED', { field: 'Status' }),
                  },
                ]}
                label={<div>Status</div>}
                style={{ marginBottom: errMessageStatusNode ? '0px' : '30px' }}
              >
                <Select onChange={handleSelectStatusNode} disabled={valueNode1?.data?.deleteFlag}>
                  {dataFindOneStatus?.statusDetails?.map((item, key) => {
                    return (
                      <Option key={key} value={item.id}>
                        {item.status}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              {errMessageStatusNode && <p style={{ color: 'red' }}>Status is duplicate</p>}
            </>
          )}
        </Card>,
        '0',
      ),
    ]),
    getItem('Assignee', 'sub1', <UserAddOutlined />, [
      getItem(
        <Card title="List User" style={{ marginBottom: '30px' }}>
          <CustomFormItem name="listDepartment" label={`Department`}>
            <ITVSelectDepartment
              dataSource={dataDepartment.data}
              placeholder={`Select department`}
              width={280}
              filterOption={(input, option) => {
                return removeAccents(
                  (option!.children! as any)!.props?.children?.props?.children?.props
                    ?.children as unknown as string,
                )
                  ?.toLowerCase()
                  ?.includes(removeAccents(input)?.toLowerCase());
              }}
              valueSelected={form.getFieldValue('listDepartment')}
              onChangeSelect={async (departmentId: any) => {
                if (departmentId !== 'All') {
                  setDataSelectDepartment(departmentId);
                } else {
                  setDataSelectDepartment([]);
                }
                setLoadingData(true);
                form.setFieldsValue({ departmentId, employeeId: undefined });
                // await (onChangeEmployeeId && onChangeEmployeeId(employeeId));
                setLoadingData(false);
                handleSelectDepartment(departmentId);
              }}
            />
          </CustomFormItem>
          <CustomFormItem name="employeeId" label={`Employee`} shouldUpdate>
            <ITVSelect
              dataSource={employeeList}
              placeholder={`Select employee`}
              width={280}
              filterOption={(input, option) => {
                return removeAccents(
                  (option!.children! as any)!.props.children[1].props.children[0].props
                    .children as unknown as string,
                )
                  .toLowerCase()
                  .includes(removeAccents(input).toLowerCase());
              }}
              valueSelected={form.getFieldValue('employeeId')}
              onChangeSelect={async (employeeId: string) => {
                dispatch(getEmployeeDetail({ idItem: employeeId }));
                form.setFieldsValue({ employeeId });
              }}
            />
          </CustomFormItem>
          <div
            style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: '2%' }}
          >
            <Popconfirm
              title={`Are you sure`}
              onConfirm={() => {
                onAddEmployee();
              }}
              okText={`Yes`}
              cancelText={`No`}
            >
              <CustomButton>{`Add`}</CustomButton>
            </Popconfirm>
          </div>
          <Form.Item
            name={'listEmployee'}
            rules={[
              {
                required: dataSelecListEmployee.length ? false : true,
                message: t('HRM_VAD_REQUIRED', { field: 'Employee' }),
              },
            ]}
          >
            <div
              id="scrollableDiv"
              style={{
                height: 200,
                overflowY: 'auto',
                overflowX: 'hidden',
                padding: '0 16px',
                border: '1px solid rgba(140, 140, 140, 0.35)',
                marginTop: '10px',
              }}
            >
              <InfiniteScroll
                dataLength={dataSelecListEmployee?.length}
                next={loadMoreData}
                hasMore={dataSelecListEmployee?.length < 2}
              >
                <List
                  dataSource={dataSelecListEmployee}
                  renderItem={(item) => (
                    <List.Item key={item.email}>
                      <EmployeeColumn
                        id={item?.id}
                        avatar={item?.fullName}
                        fullName={item?.fullName}
                        email={item?.email}
                        minWidth={'0px'}
                        onDelete={onDeleteEmployee}
                        form={form}
                        valueNode1={valueNode1}
                        dataSelecListEmployee={dataSelecListEmployee}
                        setDataSelecListEmployee={setDataSelecListEmployee}
                        record={item}
                      />
                    </List.Item>
                  )}
                />
              </InfiniteScroll>
            </div>
          </Form.Item>
        </Card>,
        '1',
      ),
    ]),
    getItem('View Transition', 'sub2', <ColumnWidthOutlined />, [
      getItem(
        <div>
          {edgenextValueNode?.map((item: any, idx: any) => {
            const valueNext = nodes?.find((itemNode: any) => {
              return itemNode?.id === item?.target;
            });

            return (
              <CustomCard>
                <CustomTag color="green">{valueNode1?.data?.label}</CustomTag>
                <ArrowRightOutlined />{' '}
                <CustomTag color="purple">{valueNext?.data?.label}</CustomTag>
                <CustomFormItem name="selectNextAction">
                  <Select defaultValue={item.label} disabled>
                    {initialEdges?.map((item: any) => {
                      return <Option value={item?.label}>{item?.label}</Option>;
                    })}
                  </Select>
                </CustomFormItem>
              </CustomCard>
            );
          })}
          {edgePrevValueNode?.map((itemEdge: any, idx: any) => {
            const valuePrev = nodes?.find((itemNode: any) => {
              return itemNode?.id === itemEdge?.source;
            });

            return (
              <CustomCard>
                <CustomTag color="purple">{valuePrev?.data?.label}</CustomTag>
                <ArrowRightOutlined />
                <CustomTag color="green">{valueNode1?.data?.label}</CustomTag>
                <CustomFormItem name="selectPrevAction">
                  <Select disabled defaultValue={itemEdge.label}>
                    {initialEdges?.map((item: any) => {
                      return <Option value={item?.label}>{item?.label}</Option>;
                    })}
                  </Select>
                </CustomFormItem>
              </CustomCard>
            );
          })}
        </div>,
        '2',
      ),
    ]),
  ];
  const onClick: MenuProps['onClick'] = (e) => {
    // console.log('click ', e);
  };
  const onOpenChange: MenuProps['onOpenChange'] = (keys) => {
    setOpenKeys(keys);
  };

  return (
    <CustomDrawer
      title={valueNode?.data?.label}
      placement="right"
      closable={false}
      onClose={onClose}
      visible={visible}
      getContainer={false}
      extra={
        <>
          <Space style={{ marginTop: '20px' }}>
            <Popconfirm
              title={`Are you sure`}
              onConfirm={() => {
                onSave(dataSelecListEmployee);
              }}
              okText={`Yes`}
              cancelText={`No`}
              // disabled={valueNode1?.data?.deleteFlag}
            >
              <CustomButton>{`Save`}</CustomButton>
            </Popconfirm>

            {valueNode?.id !== '1' && (
              <Popconfirm
                title={`Are you sure`}
                onConfirm={() => {
                  onDelete();
                }}
                okText={`Yes`}
                cancelText={`No`}
              >
                <CustomButton>{`Delete`}</CustomButton>
              </Popconfirm>
            )}
          </Space>
        </>
      }
      style={{ position: 'absolute' }}
    >
      <Form form={form} layout="vertical">
        <CustomMenu
          onClick={onClick}
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          mode="inline"
          items={items}
        />

        {/* List User */}

        {/* List action */}
      </Form>{' '}
    </CustomDrawer>
  );
};

export default ModalNodeEdit;

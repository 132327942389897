import styled from 'styled-components';
import { Form } from 'antd';

export const EmployeeUpdateIdStyled = styled(Form)`
  .ant-form-item-label > label.ant-form-item-required::before {
    content: none;
  }

  .ant-form-item-label > label.ant-form-item-required::after {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
`;

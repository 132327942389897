import { memo, useEffect, useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import styled from 'styled-components';
import payrollDashboardApi from 'api/payrollDashboardApi';
import HeadcountSummaryFilter from '../HeadcountSummary/HeadcountSummaryFilter';
import moment from 'moment';

ChartJS.register(ArcElement, Tooltip, Legend);

const StyledWrapperChart = styled.div`
  width: 60%;
  height: 60%;
  margin: auto;
`;

export interface IHeadcountSummaryByEmployeeType {
  count: number;
  color: string;
  name: string;
}

function HeadcountSummaryEmployeeTypeChart({
  filters,
}: {
  filters: { monthYear: string; locationId: number };
}) {
  const [dataHeadcountSummaryByEmployeeType, setDataHeadcountSummaryByEmployeeType] = useState<
    IHeadcountSummaryByEmployeeType[]
  >([]);

  useEffect(() => {
    const fetchDataHeadcountSummaryByEmployeeType = async () => {
      const response = await payrollDashboardApi.getHeadcountSummaryByEmployeeType(filters);
      setDataHeadcountSummaryByEmployeeType(response?.data);
    };
    fetchDataHeadcountSummaryByEmployeeType();
  }, [filters]);

  const data = {
    labels: dataHeadcountSummaryByEmployeeType.map((i) => i.name),
    datasets: [
      {
        label: '# of Votes',
        data: dataHeadcountSummaryByEmployeeType.map((i) => i.count),
        backgroundColor: dataHeadcountSummaryByEmployeeType.map((i) => i.color),
        borderColor: ['rgb(246 250 253)'],
        borderWidth: 2,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: true,
        position: 'bottom', // Place legend at the bottom
        labels: {
          boxWidth: 20,
        },
        title: { display: true, padding: 10 },
      },
    },
    layout: {
      padding: {
        top: 30, // Adjust the bottom padding to create more space
      },
    },
  };

  return (
    <StyledWrapperChart>
      <Pie data={data} style={{ width: '100%', height: '100%' }} options={options} />
    </StyledWrapperChart>
  );
}

export default memo(HeadcountSummaryEmployeeTypeChart);

import { FC } from 'react';
import styled from 'styled-components';
import PageHeaderTitle from './PageHeaderTitle';

type TPageHeader = { title: string; subTitle?: string };

const PageHeader: FC<TPageHeader> = ({ title, subTitle }) => (
  <PageHeaderStyled>
    <PageHeaderTitle title={title} subTitle={subTitle} />
  </PageHeaderStyled>
);

const PageHeaderStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  position: relative;
  min-height: 50px;
  border: 1px solid transparent;
  border-radius: 5px;
`;

export default PageHeader;

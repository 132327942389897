import { Spin } from 'antd';
import { DownTrendIcon, UpTrendIcon } from 'assets/icons';
import { ReactNode } from 'react';
import styled, { css } from 'styled-components';

interface StyledPercentageProps {
  showUpTrendIcon?: boolean;
  showDownTrendIcon?: boolean;
}

interface StatisticItemProps {
  icon: ReactNode;
  label: string;
  value: string;
  percentage?: string;
  isOnboard?: boolean;
  isOffboard?: boolean;
  loading?: boolean;
  onClick?: () => void;
}

const StyledContainer = styled.div`
  border: 0.5px solid var(--cl_gray200);
  border-radius: 12px;
  padding: 16px 24px;
  display: flex;
  align-items: center;
  gap: 16px;
  background-color: var(--cl_white);
  cursor: pointer;
  width: max-content;
`;

const StyledWrapperIcon = styled.div`
  padding: 8px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cl_neutral100);
`;

const StyledLabel = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: Roboto;
  color: var(--cl_neutral700);
  margin-bottom: 4px;
`;

const StyledWrapperValue = styled.div`
  display: flex;
  align-items: baseline;
  gap: 12px;
`;

const StyledValue = styled.div`
  font-family: Roboto;
  font-size: 27px;
  font-weight: 600;
  line-height: 38px;
  color: var(--cl_neutral900);
`;

const StyledWrapperPercentage = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const StyledPercentage = styled.div`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;

  ${(props: StyledPercentageProps) => {
    return css`
      color: ${props.showUpTrendIcon && 'var(--cl_success500)'};
      color: ${props.showDownTrendIcon && 'var(--cl_error500)'};
    `;
  }}
`;

export function StatisticPayrollItem({
  icon,
  label,
  value,
  percentage,
  isOnboard,
  isOffboard,
  loading,
  onClick,
}: StatisticItemProps) {
  const showUpTrendIcon = isOnboard;
  const showDownTrendIcon = isOffboard;

  return (
    <StyledContainer onClick={onClick}>
      <div>
        <StyledLabel>{label}</StyledLabel>
        <StyledWrapperValue>
          <StyledValue>{loading ? <Spin /> : value}</StyledValue>
          {percentage && (
            <StyledWrapperPercentage>
              {showUpTrendIcon && <UpTrendIcon />}
              {showDownTrendIcon && <DownTrendIcon />}

              <StyledPercentage
                showUpTrendIcon={showUpTrendIcon}
                showDownTrendIcon={showDownTrendIcon}
              >{`${percentage}%`}</StyledPercentage>
            </StyledWrapperPercentage>
          )}
        </StyledWrapperValue>
      </div>
    </StyledContainer>
  );
}

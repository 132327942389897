import React from 'react';
import { Link } from 'react-router-dom';
import KingworkLogo from 'assets/icons/kingwork_logo.png';
import phoneOrange from 'assets/icons/phoneorange.png';
import mailOrange from 'assets/icons/mailorange.png';

import VietnamCircleFlag from 'assets/icons/vietnam-circle-flag.png';
import USCircleFlag from 'assets/icons/us-circle-flag.png';
import KoreaCircleFlag from 'assets/icons/korea-circle-flag.png';
import JapanCircleFlag from 'assets/icons/japan-circle-flag.png';

import { Navbar, Nav, Container } from 'react-bootstrap';

import SelectLanguage from 'components/LayoutPage/SelectLanguage';

import { ImageStyled, HeaderTopStyled, LogoImageStyled } from './styled';
import { Dropdown } from 'antd';

const AuthHeader = () => {
  const languages = [
    {
      code: 'en',
      src: USCircleFlag,
      moment: 'en-gb',
    },
    {
      code: 'vi',
      src: VietnamCircleFlag,
      moment: 'vi',
    },
    {
      code: 'ko',
      src: KoreaCircleFlag,
      moment: 'ko',
    },
    {
      code: 'ja',
      src: JapanCircleFlag,
      moment: 'ja',
    },
  ];

  return (
    <>
      <HeaderTopStyled>
        <Link to="/dashboard">
          <LogoImageStyled src={KingworkLogo} alt="ITV logo" />
        </Link>
      </HeaderTopStyled>
      <div style={{ width: '70%', display: 'flex', alignItems: 'center' }}>
        <Navbar bg="light" expand="lg">
          <Container>
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto" style={{ display: 'flex' }}>
                <Nav.Link
                  href="#Product"
                  style={{ paddingLeft: 20, paddingRight: 20, fontSize: 16 }}
                >
                  Product
                </Nav.Link>
                <Nav.Link href="#Tour" style={{ paddingLeft: 20, paddingRight: 20, fontSize: 16 }}>
                  Tour
                </Nav.Link>
                <Nav.Link
                  href="#Intergations"
                  style={{ paddingLeft: 20, paddingRight: 20, fontSize: 16 }}
                >
                  Intergations
                </Nav.Link>
                <Nav.Link
                  href="#Pricing"
                  style={{ paddingLeft: 20, paddingRight: 20, fontSize: 16 }}
                >
                  Pricing
                </Nav.Link>
                <Nav.Link style={{ paddingLeft: 20, paddingRight: 20, fontSize: 16 }}>
                  <Dropdown
                    overlayStyle={{ boxShadow: '0 0 4px rgb(0 0 0 / 20%)' }}
                    overlay={<SelectLanguage />}
                    trigger={['click']}
                  >
                    <img
                      alt="LANGUAGE"
                      src={
                        languages.find(
                          (item: any) => localStorage.getItem('hrm.language') === item.code,
                        )?.src
                      }
                      width={25}
                      style={{ cursor: 'pointer' }}
                    />
                  </Dropdown>
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      <div style={{ width: '20%', marginTop: 10 }}>
        <div style={{ display: 'flex' }}>
          <div style={{ width: 16, height: 15, marginRight: 10 }}>
            <ImageStyled src={phoneOrange} alt="logo" style={{ width: 14, height: 15 }} />
          </div>
          <div style={{ fontSize: 14 }}>+84(028)3999-1895</div>
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ width: 16, height: 15, marginRight: 10 }}>
            <ImageStyled src={mailOrange} alt="logo" style={{ width: 16, height: 15 }} />
          </div>
          <div style={{ fontSize: 14 }}>info@innotech-vn.com</div>
        </div>
      </div>
    </>
  );
};

export default AuthHeader;

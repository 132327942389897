import { Collapse } from 'antd';
import { useTranslation } from 'react-i18next';

const { Panel } = Collapse;

export default function UserGuideForEmailServer() {
  const { t } = useTranslation(['userguide']);

  return (
    <>
      <Collapse>
        <Panel header="Gmail" key="1">
          <p style={{ fontWeight: 'bold' }}>{t('userguide:email_server.user_guide_enable')}</p>
          <p>
            1. {t('userguide:email_server.user_guide_link')}{' '}
            <a href="https://myaccount.google.com/">https://myaccount.google.com/</a>
          </p>
          <p>2. {t('userguide:email_server.user_guide_security')}</p>
          <p>3. {t('userguide:email_server.user_guide_2_step')}</p>
          <p>4. {t('userguide:email_server.user_guide_back_security')}</p>
          <p>5. {t('userguide:email_server.user_guide_other')}</p>
          <p>6. {t('userguide:email_server.user_guide_copy')}.</p>
        </Panel>
        <Panel header="Outlook" key="2">
          <p style={{ fontWeight: 'bold' }}>
            {t('userguide:email_server.user_guide_outlook_turnoff')}
          </p>
          <p>
            1. {t('userguide:email_server.user_guide_outlook_signin')}{' '}
            <a href="https://account.live.com/proofs/Manage">
              {t('userguide:email_server.user_guide_outlook_page')}
            </a>
            , {t('userguide:email_server.user_guide_outlook_verify')}
          </p>
          <p>2. {t('userguide:email_server.user_guide_outlook_turnoff_two_step')}</p>
          <p>3. {t('userguide:email_server.user_guide_outlook_add')}</p>
        </Panel>
      </Collapse>
    </>
  );
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { ReasonLeaveOff, SetUpPolicy } from 'hrm-common/extensions/interfaces/masterData';
import {
  AppCameraAI,
  DataCheckInOutCamera,
  Device,
  ListUserCamera,
  Place,
  TypePerson,
} from './General/Interfaces/CameraAI';
import {
  addCategory,
  addConfigType,
  addDepartment,
  addRole,
  deleteConfigType,
  deleteDepartment,
  deleteRole,
  getAccessCheckInOut,
  getCategoryList,
  getConfigTypeList,
  getDataConfigSetting,
  getDataOvertimeSetting,
  getDataReasonLeaveOff,
  getDepartmentList,
  getListOptionPosition,
  getPermissionList,
  getRoleList,
  updateAccessCheckInOut,
  updateTypeofConfigType,
  getPersonCameraAI,
  getPlace,
  getDevices,
  getListCheckInUser,
  getCheckInPersonRegister,
  getPersonalAccessCheckInHistory,
  getHistoryTimesheetConfig,
  postImgCheckInPersonRegister,
  getAppCameraAI,
  getAllEmployeeAssignOt,
  getAllEmployeeNotAssignOt,
  updateEmployeeAssignOt,
} from './masterDataAction';
import { DataConfigOvertime, EmployeeListNotAssignOt } from './Policy/policy.types';

type MasterData = {
  listPosition: [];
  listCategory: [];
  listRole: [];
  listPermission: [];
  listDepartment: [];
  listOption: [];
  dataSetting: Array<SetUpPolicy>;
  dataOvertimeSetting: DataConfigOvertime;
  listReasonLeaveOff: Array<ReasonLeaveOff>;
  informationPosition: {};
  error: string | null;
  loading: boolean;
  loadingParent: boolean;
  dataCheckIn: [];
  dataCheckInOutCamera: DataCheckInOutCamera;
  dataPlace: Place[];
  haveCurrentUser: boolean;
  currentPlace: string;
  currentApp: any;
  dataEmployee: ListUserCamera[];
  dataDevices: Device[];
  checkInUserRegister: [];
  personalHistory: [];
  timesheetConfigHistory: [];
  typePerson: TypePerson;
  listAppCameraAI: AppCameraAI[];
  employeeListAssignOt: any;
  employeeListNotAssignOt: EmployeeListNotAssignOt[];
};

const initialState: MasterData = {
  listPosition: [],
  listCategory: [],
  listRole: [],
  listPermission: [],
  listDepartment: [],
  listOption: [],
  dataSetting: [],
  dataOvertimeSetting: {
    month: 0,
    day: 0,
    year: 0,
  },
  employeeListAssignOt: [],
  employeeListNotAssignOt: [],
  listReasonLeaveOff: [],
  informationPosition: {},
  error: null,
  loading: false,
  loadingParent: false,
  dataCheckIn: [],
  dataCheckInOutCamera: {
    cameraType: '',
    checkin: '',
    checkout: '',
    date: '',
    name: '',
    place: '',
    result: [],
  },
  haveCurrentUser: false,
  currentPlace: '',
  currentApp: '',
  dataPlace: [],
  dataEmployee: [],
  dataDevices: [],
  checkInUserRegister: [],
  personalHistory: [],
  timesheetConfigHistory: [],
  listAppCameraAI: [],
  typePerson: TypePerson.EMPLOYEE,
};

const masterDataSlice = createSlice({
  name: 'masterData',
  initialState: initialState,
  reducers: {
    checkCurrentUser(state, action) {
      state.haveCurrentUser = action.payload;
    },
    checkCurrentPlace(state, action) {
      state.currentPlace = action.payload;
    },
    checkCurrentApp(state, action) {
      state.currentApp = action.payload;
    },
    resetEmployee(state, action) {
      state.dataEmployee = action.payload;
    },
    resetDataCheckInOutCamera(state, action) {
      state.dataCheckInOutCamera = action.payload;
    },
    resetDataDevice(state, action) {
      state.dataDevices = action.payload;
    },
    setTypePerson(state, action: PayloadAction<TypePerson>) {
      state.typePerson = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPermissionList.pending, (state, action: any) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(getPermissionList.fulfilled, (state, action) => {
        state.listPermission = action.payload.data.result || [];
        state.loading = false;
      })
      .addCase(getPermissionList.rejected, (state, action: any) => {
        state.error = action.error.message;
        state.listPermission = [];
        state.loading = false;
      })

      .addCase(getRoleList.pending, (state, action: any) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(getRoleList.fulfilled, (state, action) => {
        state.listRole = action.payload.data.result || [];
        state.loading = false;
      })
      .addCase(getRoleList.rejected, (state, action: any) => {
        state.error = action.error.message;
        state.listRole = [];
        state.loading = false;
      })

      .addCase(getDepartmentList.pending, (state, action: any) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(getDepartmentList.fulfilled, (state, action) => {
        state.listDepartment = action.payload.data || [];
        state.loading = false;
      })
      .addCase(getDepartmentList.rejected, (state, action: any) => {
        state.error = action.error.message;
        state.listDepartment = [];
        state.loading = false;
      })

      .addCase(getCategoryList.pending, (state, action: any) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(getCategoryList.fulfilled, (state, action) => {
        state.listCategory = action.payload.data || [];
        state.loading = false;
      })
      .addCase(getCategoryList.rejected, (state, action: any) => {
        state.error = action.error.message;
        state.listCategory = [];
        state.loading = false;
      })

      .addCase(getConfigTypeList.pending, (state, action: any) => {
        state.error = null;
        state.loading = true;
        state.informationPosition = {};
      })
      .addCase(getConfigTypeList.fulfilled, (state, action) => {
        state.listPosition = action.payload.data?.value || [];
        state.informationPosition = action.payload.data;
        state.loading = false;
      })
      .addCase(getConfigTypeList.rejected, (state, action: any) => {
        state.error = action.error.message;
        state.listPosition = [];
        state.informationPosition = {};
        state.loading = false;
      })

      .addCase(getListOptionPosition.pending, (state, action: any) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(getListOptionPosition.fulfilled, (state, action) => {
        state.listOption = action.payload.data.result || [];
        state.loading = false;
      })
      .addCase(getListOptionPosition.rejected, (state, action: any) => {
        state.error = action.error.message;
        state.listOption = [];
        state.loading = false;
      })

      .addCase(addConfigType.rejected, (state, action: any) => {
        state.error = action.error.message;
      })

      .addCase(updateTypeofConfigType.rejected, (state, action: any) => {
        state.error = action.error.message;
      })

      .addCase(deleteConfigType.rejected, (state, action: any) => {
        state.error = action.error.message;
      })

      .addCase(addCategory.rejected, (state, action: any) => {
        state.error = action.error.message;
      })

      .addCase(addRole.rejected, (state, action: any) => {
        state.error = action.error.message;
      })

      .addCase(addDepartment.rejected, (state, action: any) => {
        state.error = action.error.message;
      })

      .addCase(deleteDepartment.rejected, (state, action: any) => {
        state.error = action.error.message;
      })

      .addCase(deleteRole.rejected, (state, action: any) => {
        state.error = action.error.message;
      })

      .addCase(getDataConfigSetting.pending, (state, action: any) => {
        state.loading = true;
        state.error = null;
        state.dataSetting = [];
      })

      .addCase(getDataConfigSetting.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = null;
        state.dataSetting = action.payload?.data?.value ? action.payload.data.value : []; //cover error screen in qa
      })
      .addCase(getDataConfigSetting.rejected, (state, action: any) => {
        state.loading = false;
        state.error = action.error.message;
        state.dataSetting = [];
      })

      .addCase(getDataOvertimeSetting.pending, (state, action: any) => {
        state.loading = true;
        state.error = null;
        state.dataOvertimeSetting = {
          month: 0,
          day: 0,
          year: 0,
        };
      })

      .addCase(getDataOvertimeSetting.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = null;
        state.dataOvertimeSetting = action.payload?.value;
      })
      .addCase(getDataOvertimeSetting.rejected, (state, action: any) => {
        state.loading = false;
        state.error = action.error.message;
        state.dataOvertimeSetting = {
          month: 0,
          day: 0,
          year: 0,
        };
      })
      .addCase(getAllEmployeeAssignOt.pending, (state, action: any) => {
        state.loading = true;
        state.error = null;
        state.employeeListAssignOt = [];
      })

      .addCase(getAllEmployeeAssignOt.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = null;
        state.employeeListAssignOt = action.payload?.result; //cover error screen in qa
      })
      .addCase(getAllEmployeeAssignOt.rejected, (state, action: any) => {
        state.loading = false;
        state.error = action.error.message;
        state.employeeListAssignOt = [];
      })

      .addCase(getAllEmployeeNotAssignOt.pending, (state, action: any) => {
        state.loading = true;
        state.error = null;
        state.employeeListNotAssignOt = [];
      })
      .addCase(getAllEmployeeNotAssignOt.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = null;
        state.employeeListNotAssignOt = action.payload?.result; //cover error screen in qa
      })
      .addCase(getAllEmployeeNotAssignOt.rejected, (state, action: any) => {
        state.loading = false;
        state.error = action.error.message;
        state.employeeListNotAssignOt = [];
      })

      .addCase(updateEmployeeAssignOt.pending, (state, action: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateEmployeeAssignOt.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(updateEmployeeAssignOt.rejected, (state, action: any) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getDataReasonLeaveOff.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.listReasonLeaveOff = [];
      })
      .addCase(getDataReasonLeaveOff.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = null;
        state.listReasonLeaveOff = action.payload?.value;
      })
      .addCase(getDataReasonLeaveOff.rejected, (state, action: any) => {
        state.loading = false;
        state.error = action.error.message;
        state.listReasonLeaveOff = [];
      })

      .addCase(getAccessCheckInOut.pending, (state, action: any) => {
        state.loading = true;
        state.error = null;
        state.dataCheckIn = [];
      })
      .addCase(getAccessCheckInOut.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = null;
        state.dataCheckIn = action.payload?.data?.result;
      })
      .addCase(getAccessCheckInOut.rejected, (state, action: any) => {
        state.loading = false;
        state.error = action.error.message;
        state.dataCheckIn = [];
      })
      .addCase(updateAccessCheckInOut.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateAccessCheckInOut.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = action.payload?.dataResponse?.message;
      })
      .addCase(updateAccessCheckInOut.rejected, (state, action: any) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getPersonCameraAI.pending, (state, action) => {
        state.loadingParent = true;
        state.error = null;
        state.dataEmployee = [];
      })
      .addCase(getPersonCameraAI.fulfilled, (state, action: any) => {
        state.loadingParent = false;
        state.error = null;
        state.dataEmployee = action.payload.data?.result;
      })
      .addCase(getPersonCameraAI.rejected, (state, action: any) => {
        state.loadingParent = false;
        state.error = action.error.message;
        state.dataEmployee = [];
      })
      .addCase(getPlace.pending, (state, action) => {
        state.loadingParent = true;
        state.error = null;
        state.dataPlace = [];
      })
      .addCase(getPlace.fulfilled, (state, action: any) => {
        state.loadingParent = false;
        state.error = null;
        state.dataPlace = action.payload?.data?.result;
      })
      .addCase(getPlace.rejected, (state, action: any) => {
        state.loadingParent = false;
        state.error = action.error.message;
        state.dataPlace = [];
      })
      .addCase(getDevices.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.dataDevices = [];
      })
      .addCase(getDevices.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = null;
        state.dataDevices = action.payload?.data?.result;
      })
      .addCase(getDevices.rejected, (state, action: any) => {
        state.loading = false;
        state.error = action.error.message;
        state.dataDevices = [];
      })
      .addCase(getListCheckInUser.pending, (state, action) => {
        state.loading = true;
        state.error = null;
        state.dataCheckInOutCamera = {
          cameraType: '',
          checkin: '',
          checkout: '',
          date: '',
          name: '',
          place: '',
          result: [],
        };
      })
      .addCase(getListCheckInUser.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = null;
        state.dataCheckInOutCamera = action.payload.data;
      })
      .addCase(getListCheckInUser.rejected, (state, action: any) => {
        state.loading = false;
        state.error = action.error.message;
        state.dataCheckInOutCamera = {
          cameraType: '',
          checkin: '',
          checkout: '',
          date: '',
          name: '',
          place: '',
          result: [],
        };
      })

      .addCase(getCheckInPersonRegister.pending, (state) => {
        state.error = null;
        state.checkInUserRegister = [];
      })
      .addCase(getCheckInPersonRegister.fulfilled, (state, action: any) => {
        state.error = null;
        state.checkInUserRegister = action.payload.data;
      })
      .addCase(getCheckInPersonRegister.rejected, (state, action: any) => {
        state.error = action.error.message;
        state.checkInUserRegister = [];
      })

      .addCase(getAppCameraAI.pending, (state) => {
        state.error = null;
        state.loading = true;
        state.listAppCameraAI = [];
      })
      .addCase(getAppCameraAI.fulfilled, (state, action: any) => {
        state.error = null;
        state.loading = false;

        state.listAppCameraAI = action.payload.data;
      })
      .addCase(getAppCameraAI.rejected, (state, action: any) => {
        state.error = action.error.message;
        state.loading = false;

        state.listAppCameraAI = [];
      })

      .addCase(postImgCheckInPersonRegister.pending, (state) => {
        state.loading = true;
      })
      .addCase(postImgCheckInPersonRegister.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(postImgCheckInPersonRegister.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getPersonalAccessCheckInHistory.pending, (state, action) => {
        state.error = null;
        state.personalHistory = [];
      })
      .addCase(getPersonalAccessCheckInHistory.fulfilled, (state, action: any) => {
        state.loading = false;
        state.error = null;
        state.personalHistory = action.payload;
      })
      .addCase(getPersonalAccessCheckInHistory.rejected, (state, action: any) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getHistoryTimesheetConfig.pending, (state, action) => {
        state.error = null;
        state.timesheetConfigHistory = [];
      })
      .addCase(getHistoryTimesheetConfig.fulfilled, (state, action: any) => {
        state.error = null;
        state.timesheetConfigHistory = action?.payload;
      })
      .addCase(getHistoryTimesheetConfig.rejected, (state, action: any) => {
        state.error = action.error.message;
      });
  },
});

export const selectMasterData = (state: RootState) => state.masterData;
export const { reducer, actions } = masterDataSlice;

export const selectMasterAdjust = (state: RootState) =>
  state.masterData?.dataSetting?.find(({ type }: { type: string }) => type === 'adjust');

export const selectMasterLeaveOff = (state: RootState) =>
  state.masterData?.dataSetting?.find(({ type }: { type: string }) => type === 'leave');

export default reducer;

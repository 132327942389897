import axiosClient from './axiosClient';
import queryString from 'query-string';

const offboardApi = {
  createOffboard: (params: any) => {
    const response = axiosClient.post(`/offboard`, {
      reason: params.reason,
      expectedDate: params.expectedDate,
      user: params.user,
      approver: params.approver,
    });
    return response;
  },
  createOffboardJob: (params: any) => {
    const response = axiosClient.post(`/offboard-job`, {
      name: params.name,
      description: params.description,
      status: params.status,
      handoverDate: params.handoverDate,
      reciever: params.reciever,
      offboard: params.offboard,
    });
    return response;
  },
  updateOffboardJob: (params: any) => {
    const response = axiosClient.put(`/offboard-job/${params.id}`, {
      name: params.name,
      description: params.description,
      status: params.status,
      handoverDate: params.handoverDate,
      // reciever: params.reciever,
      // offboard: params.offboard,
    });
    return response;
  },
  deleteOffboardJob: (params: any) => {
    const response = axiosClient.delete(`/offboard-job/${params.id}`);
    return response;
  },
  getOffboardByEmployee: (params: any) => {
    const response = axiosClient.get(`/offboard/employee/${params.id}`);
    return response;
  },
  getOffboard: (params: any) => {
    const response = axiosClient.get(`/offboard?${queryString.stringify(params)}`);
    return response;
  },
  updateOffboardStatus: (params: any) => {
    const response = axiosClient.put(`/offboard/${params.id}/status`, {
      status: params.status,
    });
    return response;
  },
  sendRequest: (params: any) => {
    const response = axiosClient.put(`/offboard/${params.id}/send-request`);
    return response;
  },
  reject: (params: any) => {
    const response = axiosClient.put(`/offboard/${params.id}/reject`, {
      reject: params.reject,
    });
    return response;
  },
  confirmOfManager: (params: any) => {
    const response = axiosClient.put(`/offboard/${params.id}/confirm-manager`, {
      approvedDate: params.approvedDate,
      recieverHandovers: params.recieverHandovers,
      itHandover: params.itHandover,
      discussedSupervisor: params.discussedSupervisor,
    });
    return response;
  },
  approveJob: (params: any) => {
    const response = axiosClient.put(`/offboard/${params.id}/confirm-job-manager`, {
      discussedReciever: true,
    });
    return response;
  },
  confirmJob: (params: any) => {
    const response = axiosClient.put(`/offboard/${params.id}/send-request-job`);
    return response;
  },
  confirmEquipment: (params: any) => {
    const response = axiosClient.put(`/offboard/${params.id}/send-request-equipment`);
    return response;
  },
  confirmOfHR: (params: any) => {
    const response = axiosClient.put(`/offboard/${params.id}/confirm-hr`);
    return response;
  },
  confirmOfIT: (params: any) => {
    const response = axiosClient.put(`/offboard/${params.id}/confirm-it`);
    return response;
  },
  confirmInterview: (params: any) => {
    const response = axiosClient.put(`/offboard/${params.id}/confirm-interview`, {
      attendes: params.attendes,
      describle: params.describle,
      mettingNote: params.mettingNote,
      interviewDate: params.interviewDate,
      interviewTime: params.interviewTime,
    });
    return response;
  },
  getOffboardById: (params: any) => {
    const response = axiosClient.get(`/offboard/${params.id}`);
    return response;
  },
  getEquipmentProcessing: (params: any) => {
    const response = axiosClient.get(`/offboard/equipment-processing/${params.id}`);
    return response;
  },
};

export default offboardApi;

import { RecruitJobDetailType } from 'features/recruitment/jobs/components/types';
import CandidatesBoard from './CandidateBoard';
import { isInterviewer } from 'features/recruitment/jobs/components/types';
import ListCandidate from './ListCandidate';

const Candidates = ({
  reload,
  data,
  handleChange,
}: {
  reload?: () => void;
  data?: RecruitJobDetailType;
  handleChange: (value: any) => void;
}) => {
  return isInterviewer() ? (
    <ListCandidate data={data} />
  ) : (
    <CandidatesBoard reload={reload} data={data} handleChange={handleChange} />
  );
};

export default Candidates;

import { setGlobalLoading } from 'app/commonRedux/appSlice';
import { createAsyncThunk } from '@reduxjs/toolkit';
import contractApi from 'api/contractApi';

export const contractGet = createAsyncThunk(
  'contract/contractGet',
  async ({ callBack, ...params }: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await contractApi.contractGet();
      callBack && callBack(response);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

import { Tooltip } from 'antd';
import { FillPdfIcon, RegularPdfIcon } from 'assets/icons';
import { ChangeEvent } from 'react';
import { Control, useController, FieldValues, Path } from 'react-hook-form';
import styled, { CSSProperties, css } from 'styled-components';

export type UploadFileFieldProps<T extends FieldValues> = {
  requiredIcon?: boolean;
  label?: string;
  name: Path<T>;
  control: Control<T>;
  directionLabel?: 'horizontal' | 'vertical';
  labelStyle?: CSSProperties;
  accept: string;
  disabled?: boolean;
  fileNamePreview?: string;
};

interface StyledWrapperProps {
  directionLabel: 'horizontal' | 'vertical';
}

interface StyledIconFileNameProps {
  isError?: boolean;
}

interface StyledIconLabelProps {
  disabled: boolean;
}

const StyledContainer = styled.div``;

const StyledWrapper = styled.div`
  ${(props: StyledWrapperProps) =>
    props?.directionLabel === 'horizontal' &&
    css`
      display: flex;
      align-items: center;
      gap: 8px;
    `}
`;

const StyledLabel = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: var(--cl_gray900);
  ${(props: StyledWrapperProps) =>
    props?.directionLabel === 'vertical' &&
    css`
      margin-bottom: 6px;
    `}
`;

const StyledWrapperIcon = styled.div`
  text-align: center;
`;

const StyledIconLabel = styled.label`
  cursor: pointer;
  ${(props: StyledIconLabelProps) =>
    props.disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.3;
    `}
`;

const StyledIconFileName = styled.div`
  margin-top: 8px;
  font-weight: bold;
  ${(props: StyledIconFileNameProps) =>
    props?.isError
      ? css`
          color: var(--cl_error500);
        `
      : css`
          color: var(--cl_success500);
        `}
`;

const StyledErrorMessage = styled.div`
  color: var(--cl_error500);
  margin-top: 6px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
`;

export function UploadFileField<T extends FieldValues>({
  label,
  name,
  control,
  directionLabel = 'vertical',
  labelStyle,
  accept,
  disabled = false,
  fileNamePreview,
}: UploadFileFieldProps<T>) {
  const {
    field: { onChange, value, ref },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const fileNameList: string[] = value?.map((file: File) => file.name) || [];
  const inputId = `upload-file-field-${name}`;

  if (fileNamePreview) {
    fileNameList.push(fileNamePreview);
  }

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    const fileListToArray = Array.from(fileList || []);
    if (!Array.isArray(fileListToArray) || fileListToArray.length === 0) return;

    onChange(fileListToArray);
  };

  return (
    <StyledContainer>
      <StyledWrapper directionLabel={directionLabel}>
        {label && (
          <StyledLabel style={labelStyle} directionLabel={directionLabel}>
            {label}
          </StyledLabel>
        )}

        <StyledWrapperIcon>
          <StyledIconLabel disabled={disabled} htmlFor={disabled ? undefined : inputId} ref={ref}>
            {disabled && (
              <div>
                {fileNameList.length > 0 ? (
                  <FillPdfIcon width="150px" height="150px" />
                ) : (
                  <RegularPdfIcon width="150px" height="150px" />
                )}
              </div>
            )}

            {!disabled && (
              <Tooltip title="Click to upload">
                <div>
                  {fileNameList.length > 0 ? (
                    <FillPdfIcon width="150px" height="150px" />
                  ) : (
                    <RegularPdfIcon width="150px" height="150px" />
                  )}
                </div>
              </Tooltip>
            )}
          </StyledIconLabel>

          {fileNameList.map((fileName) => (
            <StyledIconFileName key={fileName} isError={!!error}>
              {fileName}
            </StyledIconFileName>
          ))}
        </StyledWrapperIcon>

        <input
          id={inputId}
          type="file"
          accept={accept}
          hidden
          onChange={handleFileChange}
          multiple
        />
      </StyledWrapper>

      {error?.message && <StyledErrorMessage>{error?.message}</StyledErrorMessage>}
    </StyledContainer>
  );
}

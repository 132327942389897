import { notification } from 'antd';
import { createAsyncThunk } from '@reduxjs/toolkit';
import onboardApi from 'api/onboardApi';
import { setGlobalLoading } from 'app/commonRedux/appSlice';
import { ITemplateMail } from 'hrm-common/extensions/interfaces/personel';
import { notificationToast } from 'components/notificationToast';
import { Notification } from 'constants/notification';
import axiosClient from 'api/axiosClient';
import { actions } from 'features/configuration/configurationSlice';

export const getGroupTemplateEmail = createAsyncThunk('mail/getGroupTemplateEmail', async () => {
  try {
    const response = await onboardApi.getGroupTemplateEmail();
    return response.data;
  } catch (error) {
    return error;
  }
});

export const createGroupTemplateEmail = createAsyncThunk(
  'mail/createGroupTemplateEmail',
  async (
    params: { data: { name: string; module: string; descriptions: string }; callBack?: any },
    thunkApi,
  ) => {
    try {
      const response: any = await onboardApi.createGroupTemplateEmail(params.data);
      thunkApi.dispatch(getGroupTemplateEmail());
      notificationToast(Notification.Type.Success, response.message, Notification.Duration._3s);
      response && params.callBack();
      return response.data;
    } catch (error) {
      return error;
    }
  },
);

export const updateGroupTemplateEmail = createAsyncThunk(
  'mail/updateGroupTemplateEmail',
  async (params: any, thunkApi) => {
    try {
      const response: any = await onboardApi.updateGroupTemplateEmail(params.data);
      thunkApi.dispatch(getGroupTemplateEmail());
      notificationToast(Notification.Type.Success, response.message, Notification.Duration._3s);
      response && params.callBack();
      return response.data;
    } catch (error) {
      params.callBack && params.callBack({ error });
      return error;
    }
  },
);
export const deleteGroupTemplateEmail = createAsyncThunk(
  'mail/updateGroupTemplateEmail',
  async (params: any, thunkApi) => {
    try {
      const response: any = await onboardApi.deleteGroupTemplateEmail(params.id);
      thunkApi.dispatch(getGroupTemplateEmail());
      notificationToast(Notification.Type.Success, response.message, Notification.Duration._3s);
      return response.data;
    } catch (error) {
      params.callBack && params.callBack({ error });
      return error;
    }
  },
);

export const getVariablesEmail = createAsyncThunk(
  'mail/getVariablesEmail',
  async (module: string) => {
    try {
      const response = await onboardApi.getVariablesEmail(module);
      return response.data;
    } catch (error) {
      return error;
    }
  },
);

export const createTemplate = createAsyncThunk(
  'mail/createTemplate',
  async (params: { data: ITemplateMail; callback?: any }, thunkApi) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await onboardApi.createTemplate(params.data);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      thunkApi.dispatch(getTemplates({}));
      notificationToast(Notification.Type.Success, response.message, Notification.Duration._3s);
      params.callback && params.callback(response);
      return response.data;
    } catch (error) {
      params.callback && params.callback({ error });
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return error;
    }
  },
);

export const updateTemplate = createAsyncThunk(
  'mail/updateTemplate',
  async (params: { data: any; callback?: any }, thunkApi) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await onboardApi.updateTemplate(params.data);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      thunkApi.dispatch(getTemplates({}));
      notificationToast(Notification.Type.Success, response.message, Notification.Duration._3s);
      params.callback && params.callback(response);
      return response.data;
    } catch (error) {
      params.callback && params.callback({ error });
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return error;
    }
  },
);

export const getTemplates = createAsyncThunk(
  'mail/getTemplates',
  async ({ callBack, params }: any) => {
    try {
      const response = await onboardApi.getTemplates(params);
      callBack && callBack();
      return response.data;
    } catch (error) {
      return error;
    }
  },
);

export const deleteTemplateById = createAsyncThunk(
  'mail/deleteTemplateById',
  async (params: any, thunkApi) => {
    try {
      const response: any = await onboardApi.deleteTemplateById(params);
      params.callBack && params.callBack();
      thunkApi.dispatch(getTemplates({}));
      notificationToast(Notification.Type.Success, response.message, Notification.Duration._3s);
      return response;
    } catch (error) {
      return error;
    }
  },
);

export const sendMail = createAsyncThunk('mail/sendMailManual', async (params: any, thunkApi) => {
  try {
    thunkApi.dispatch(setGlobalLoading({ loading: true }));
    const response = await onboardApi.sendMailManual(params);
    thunkApi.dispatch(setGlobalLoading({ loading: false }));
    params.callBack && params.callBack(response.data);
    return response.data;
  } catch (error) {
    params.callBack && params.callBack({ error });
    thunkApi.dispatch(setGlobalLoading({ loading: false }));
    return error;
  }
});

export const getAutoSendMailConfig = createAsyncThunk(
  '/configurations/config-email',
  async (params: any, thunkApi) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await onboardApi.getAutoSendMailConfig();
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return response.data;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return error;
    }
  },
);

export const createAutoSendMailConfig = createAsyncThunk(
  '/configurations/config-email/create',
  async (params: any, thunkApi) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await onboardApi.createAutoSendMailConfig(params);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      params.callBack && params.callBack(response.data);
      notificationToast(Notification.Type.Success, response.message, Notification.Duration._3s);
      thunkApi.dispatch(getAutoSendMailConfig({}));
      return response;
    } catch (error) {
      params.callBack && params.callBack({ error });
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return error;
    }
  },
);

export const updateAutoSendMailConfig = createAsyncThunk(
  '/configurations/config-email/update',
  async (params: any, thunkApi) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await onboardApi.updateAutoSendMailConfig(params);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      params.callBack && params.callBack(response.data);
      notificationToast(Notification.Type.Success, response.message, Notification.Duration._3s);
      thunkApi.dispatch(getAutoSendMailConfig({}));
      return response.data;
    } catch (error) {
      params.callBack && params.callBack({ error });
      return error;
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const getAutoConfigSendMailByModule = createAsyncThunk(
  '/configurations/module-action',
  async (params: any, thunkApi) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await onboardApi.getAutoConfigSendMailByModule(params);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return response;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return error;
    }
  },
);

export const getAutoConfigMailIdListByModule = createAsyncThunk(
  '/email/get-all-temaplate-main-info',
  async (params: any, thunkApi) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await onboardApi.getAutoConfigMailIdListByModule(params);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return response.data;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return error;
    }
  },
);

//Action
export const getListAction = createAsyncThunk(
  'configurations/getListAction',
  async (_, thunkApi: any) => {
    try {
      const response = await axiosClient.get(`/configurations/action-module`);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
    }
  },
);

export const updateAction = createAsyncThunk(
  'configurations/updateAction',
  async (params: { actionId: string; name: string; description: string }, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.put(`/configurations/action-module/${params.actionId}`, {
        name: params.name,
        description: params.description,
      });
      notificationToast(Notification.Type.Success, response.message, Notification.Duration._3s);
      thunkApi.dispatch(getListAction());
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

//______________________Email server_______________________
export const getListEmailServer = createAsyncThunk(
  '/configurations/email-server/get-list',
  async (params: any, thunkApi) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.get(`/configurations/config-email-server`);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return response.data;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return error;
    }
  },
);

export const createEmailServer = createAsyncThunk(
  '/configurations/email-server/create',
  async (params: any, thunkApi) => {
    const { setErrorMessage } = actions;
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.post(`/configurations/config-email-server`, params.values);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      response && params.callBack(response.data);
      notificationToast(Notification.Type.Success, response.message, Notification.Duration._3s);
      thunkApi.dispatch(getListEmailServer({}));
      return response.data;
    } catch (error) {
      thunkApi.dispatch(setErrorMessage(error));
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const updateEmailServer = createAsyncThunk(
  '/configurations/email-server/update',
  async (params: any, thunkApi) => {
    const { setErrorMessage } = actions;
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.put(
        `/configurations/config-email-server/${params.id}`,
        params,
      );
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      response && params.callBack(response.data);
      notificationToast(Notification.Type.Success, response.message, Notification.Duration._3s);
      thunkApi.dispatch(getListEmailServer({}));
      return response.data;
    } catch (error) {
      thunkApi.dispatch(setErrorMessage(error));
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const deleteEmailServer = createAsyncThunk(
  '/configurations/email-server/delete',
  async (params: { id: string; callBack?: any }, thunkApi) => {
    try {
      const response: any = await axiosClient.delete(
        `/configurations/config-email-server/${params.id}`,
      );
      thunkApi.dispatch(getListEmailServer({}));
      notificationToast(Notification.Type.Success, response.message, Notification.Duration._3s);
      return response.data;
    } catch (error) {
      params.callBack && params.callBack({ error });
      return error;
    }
  },
);

export const testConnectEmailServer = createAsyncThunk(
  '/configurations/email-server/test',
  async (params: any, thunkApi) => {
    const { setErrorMessage } = actions;
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.post(
        `/configurations/config-email-server/send-test-mail`,
        {
          mailUser: params.values.mailUser,
          mailPassword: params.values.mailPassword,
          mailNameFrom: params.values.mailNameFrom,
          mailType: params.mailType,
        },
      );
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      response && params.callBack(response.data);
      notification.destroy();
      return response.data;
    } catch (error) {
      thunkApi.dispatch(setErrorMessage(error));
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

//______________________GroupEmail____________________
export const getListGroupEmail = createAsyncThunk(
  '/email/involver-group/get',
  async (params: any, thunkApi) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.get(`/email/involver-group`);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return response.data;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return error;
    }
  },
);

export const createGroupEmail = createAsyncThunk(
  '/email/involver-group/create',
  async (params: any, thunkApi) => {
    const { setErrorMessage } = actions;
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.post(`/email/involver-group`, params.values);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      response && params.callBack(response.data);
      notificationToast(Notification.Type.Success, response.message, Notification.Duration._3s);
      thunkApi.dispatch(getListGroupEmail({}));
      return response.data;
    } catch (error) {
      thunkApi.dispatch(setErrorMessage(error));
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const updateGroupEmail = createAsyncThunk(
  '/email/involver-group/update',
  async (params: any, thunkApi) => {
    const { setErrorMessage } = actions;
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await axiosClient.put(`/email/involver-group/${params.id}`, params);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      response && params.callBack(response.data);
      notificationToast(Notification.Type.Success, response.message, Notification.Duration._3s);
      thunkApi.dispatch(getListGroupEmail({}));
      return response.data;
    } catch (error) {
      thunkApi.dispatch(setErrorMessage(error));
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const deleteGroupEmail = createAsyncThunk(
  '/email/involver-group/delete',
  async (params: { id: string; callBack?: any }, thunkApi) => {
    try {
      const response: any = await axiosClient.delete(`/email/involver-group/${params.id}`);
      thunkApi.dispatch(getListGroupEmail({}));
      params.callBack && params.callBack(response);
      notificationToast(Notification.Type.Success, response.message, Notification.Duration._3s);
      return response.data;
    } catch (error) {
      params.callBack && params.callBack({ error });
      return error;
    }
  },
);

//______________________process_______________________
export const confirmStep = createAsyncThunk(
  'onboard/confirmStep',
  async ({ callBack, ...params }: any, thunkApi) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response = await onboardApi.confirmStep(params);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack && callBack(response.data);
      return response.data;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return error;
    }
  },
);

export const createProcess = createAsyncThunk(
  'onboard/createProcess',
  async ({ callBack, ...params }: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await onboardApi.createProcess(params);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack && callBack(response.data);
      return response.data;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack && callBack({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const updateProcessStatus = createAsyncThunk(
  'onboard/updateProcessStatus',
  async ({ callBack, ...params }: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await onboardApi.updateProcessStatus(params);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack && callBack(response.data);
      return response.data;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack && callBack({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const updateTaskTemplate = createAsyncThunk(
  'onboard/updateTaskTemplate',
  async ({ callBack, ...params }: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await onboardApi.updateTaskTemplate(params);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack && callBack(response.data);
      return response.data;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack && callBack({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const updateSelfEvaluate = createAsyncThunk(
  'onboard/updateSelfEvaluate',
  async ({ callBack, ...params }: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await onboardApi.updateSelfEvaluate(params);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack && callBack(response.data);
      return response.data;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack && callBack({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const updateManagerEvaluate = createAsyncThunk(
  'onboard/updateManagerEvaluate',
  async ({ callBack, ...params }: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await onboardApi.updateManagerEvaluate(params);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack && callBack(response.data);
      return response.data;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack && callBack({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const createTaskTemplate = createAsyncThunk(
  'onboard/createTaskTemplate',
  async ({ callBack, ...params }: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await onboardApi.createTaskTemplate(params);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack && callBack(response.data);
      return response.data;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack && callBack({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const createEvaluate = createAsyncThunk(
  'onboard/createEvaluate',
  async ({ callBack, ...params }: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await onboardApi.createEvaluate(params);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack && callBack(response.data);
      return response.data;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack && callBack({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const sendProcess = createAsyncThunk(
  'onboard/sendProcess',
  async ({ callBack, ...params }: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await onboardApi.sendProcess(params);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack && callBack(response.data);
      return response.data;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack && callBack({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getProcessById = createAsyncThunk(
  'onboard/getProcessById',
  async ({ callBack, ...params }: any, thunkApi: any) => {
    try {
      const response: any = await onboardApi.getProcessById(params);
      callBack && callBack(response.data);
      return response.data;
    } catch (error) {
      callBack && callBack({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getProcessStep = createAsyncThunk(
  'onboard/getProcessStep',
  async ({ callBack, ...params }: any, thunkApi: any) => {
    try {
      const response: any = await onboardApi.getProcessById(params);
      callBack && callBack(response.data);
      return response.data;
    } catch (error) {
      callBack && callBack({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getOnboard = createAsyncThunk(
  'onboard/getOnboard',
  async ({ callBack, filters, ...params }: any, thunkApi: any) => {
    try {
      const response: any = await onboardApi.getOnboard(params);
      let result = response.data.result;
      // if (filters) {
      //   for (let i in filters) {
      //     result = result.filter((item: any) => item[i] === filters[i]);
      //   }
      // }
      callBack && callBack(result);
      return result;
    } catch (error) {
      callBack && callBack({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getOnboardByEmployee = createAsyncThunk(
  'onboard/getOnboardByEmployee',
  async ({ callBack, ...params }: any, thunkApi: any) => {
    try {
      const response: any = await onboardApi.getOnboardByEmployee(params);
      callBack && callBack(response.data);
      return response.data;
    } catch (error: any) {
      callBack && callBack({ error: error.response.data });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getOnboardStep = createAsyncThunk(
  'onboard/getOnboardStep',
  async ({ callBack, ...params }: any, thunkApi: any) => {
    try {
      const response: any = await onboardApi.getOnboardByEmployee(params);
      callBack && callBack(response.data);
      return response.data;
    } catch (error: any) {
      callBack && callBack({ error: error.response.data });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const updateOnboardStatus = createAsyncThunk(
  'onboard/updateOnboardStatus',
  async ({ callBack, ...params }: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await onboardApi.updateOnboardStatus(params);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack && callBack(response.data);
      return response.data;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack && callBack({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const deleteProcessById = createAsyncThunk(
  'onboard/deleteProcessById',
  async ({ callBack, ...params }: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await onboardApi.deleteProcessById(params);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack && callBack(response.data);
      return response.data;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack && callBack({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getProcess = createAsyncThunk(
  'onboard/getProcess',
  async ({ callBack, ...params }: any, thunkApi: any) => {
    try {
      const response: any = await onboardApi.getProcess();
      callBack && callBack(response.data);
      return response.data;
    } catch (error) {
      callBack && callBack({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

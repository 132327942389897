import styled from 'styled-components';
import { Input, Button } from 'antd';

export const ContainerMain = styled.div`
  background-color: $grey;
  overflow-y: hidden;
`;

export const CustomSearch = styled(Input.Search)`
  width: 190px;
  margin-bottom: 20px;
  .ant-input-group-addon {
    display: none;
  }
  .ant-input-affix-wrapper {
    height: 32px;
    input {
      font-size: 14px;
    }
  }
`;

export const SearchContainer = styled.div`
  width: 200px;
  margin-bottom: 20px;
`;

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const ContainerLeft = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: column;
`;

export const ContainerRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const HeaderTitle = styled.div`
  font-size: var(--txt_font_size_h1);
  font-weight: var(--txt_font_weight_medium);
  color: black;
`;

export const HeaderDetail = styled.div`
  font-size: var(--txt_font_size_caption);
  font-weight: var(--txt_font_weight_regular);
`;

export const HeaderAdd = styled(Button)`
  font-size: var(--txt_font_size_body);
  background-color: var(--cl_primary500);
  color: var(--cl_white);
  border-radius: var(--bd_radius_default);
  margin-right: 10px;
  img {
    padding-bottom: 6px;
  }
  &:hover {
    background-color: var(--cl_primary500);
    color: var(--cl_white);
  }
`;

export const HeaderRemove = styled(Button)`
  font-size: var(--txt_font_size_body);
  /* color: var(--cl_black); */
  border-radius: var(--bd_radius_default);
  img {
    padding-bottom: 6px;
  }
`;

export const Content = styled.div`
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

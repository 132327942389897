import {
  ArrowRightOutlined,
  ColumnWidthOutlined,
  InfoCircleOutlined,
  UserAddOutlined,
} from '@ant-design/icons';
import { Card, Form, Input, Select } from 'antd';

import { List } from 'antd';
import MenuItem from 'antd/lib/menu/MenuItem';
import { useAppSelector } from 'app/hooks';
import { MODE } from 'constants/types';
import { getCategoryWithType } from 'features/configuration/categoryAction';
import {
  DotColorStyled,
  ItemColorStyled,
  SelectOptionStyled,
  SelectStyled,
} from 'features/configuration/components/LeaveOffItem/styled';
import { selectConfiguration } from 'features/configuration/configurationSlice';
import { filterSortEmployeeList } from 'features/employee/employeeAction';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch } from 'react-redux';
import { capitalizeFirstLetter } from 'utils/text';
import { CustomCard, CustomFormItem, CustomMenu, CustomTag } from '../styled';
import EmployeeColumnView from './EmployeeColumnView';
import './indexViewWl.css';

const { Option } = Select;

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}
const ModalNodeView = (props: any) => {
  const {
    form,
    edgenextValueNode,
    edgePrevValueNode,
    valueNode,
    initialEdges,
    nodes,
    loadMoreData,
    currentItem,
    modalType,
  } = props;

  const dataStore = useAppSelector(selectConfiguration);
  const [, setDataEmployee] = useState([]);
  const [colorNode, setColorNode] = useState('red');
  const [dataSelectDepartment, setDataSelectDepartment] = useState([]);
  const [dataSelecListEmployee, setDataSelecListEmployee] = useState([]);
  const [dataListIdEmployee] = useState([]);
  const [, setValueStatus] = useState('');
  const [arrColor, setArrColor] = useState([]);
  const [, setValueNode1] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    const arrEmployee = nodes?.find((item: any, idx: any) => {
      return valueNode?.id === item?.id;
    })?.data?.employee;
    setDataEmployee(arrEmployee);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueNode]);

  useEffect(() => {
    setValueNode1(valueNode);
  }, [valueNode]);

  const dataFindOneStatus = dataStore?.dataGroupStatus;

  const handleSelectStatus = (value: string) => {
    setValueStatus(value);
  };

  useEffect(() => {
    if (modalType === MODE.VIEW || modalType === 'VIEWWL') {
      let listEmployee = valueNode?.data?.employees;
      setDataSelecListEmployee(listEmployee || []);

      if (typeof valueNode?.data?.status === 'object') {
        form.setFieldsValue({
          status: valueNode?.data?.status?.id,
          listEmployee: listEmployee,
          statusName: valueNode?.data?.statusName,
          colorNode: valueNode?.style?.background,
        });
      } else {
        form.setFieldsValue({
          status: valueNode?.data?.status,
          listEmployee: listEmployee,
          statusName: valueNode?.data?.statusName,
          colorNode: valueNode?.style?.background,
        });
      }
      return () => {
        setDataSelectDepartment([]);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueNode, currentItem]);

  useEffect(() => {
    if (dataSelectDepartment.length > 0) {
      dataSelectDepartment &&
        dispatch(
          filterSortEmployeeList({
            filter: {
              departmentName: [dataSelectDepartment],
            },
          }),
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueNode, dataSelectDepartment]);

  useEffect(() => {
    form.setFieldsValue({
      listEmployee: dataSelecListEmployee, //send BE
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataListIdEmployee]);

  useEffect(() => {
    dispatch(
      getCategoryWithType({
        typeConfig: 'color',
        callBack: (response: any) => {
          if (!response?.error && response?.statusCode === 200) {
            setArrColor(response?.data?.value);
          }
        },
      }),
    );
  }, [dispatch]);

  const items: MenuItem[] = [
    getItem('Info Process', 'sub0', <InfoCircleOutlined />, [
      getItem(
        <Card style={{ marginBottom: '30px' }}>
          <Form.Item name={'colorNode'} label={<div>Color Process</div>}>
            <SelectStyled
              placeholder={'Please select color'}
              value={colorNode}
              onChange={(value: any) => {
                setColorNode(value);
              }}
              disabled
            >
              {arrColor.map((item: any) => {
                return (
                  <SelectOptionStyled
                    key={item.id}
                    value={item.code}
                    // label={capitalizeFirstLetter(item.name)}
                  >
                    <ItemColorStyled>
                      <DotColorStyled
                        style={{
                          backgroundColor: item.code,
                        }}
                      />
                      {capitalizeFirstLetter(item.name)}
                    </ItemColorStyled>
                  </SelectOptionStyled>
                );
              })}
            </SelectStyled>
          </Form.Item>
          <Form.Item name={'desValue'} label={<div>Description</div>}>
            <Input disabled placeholder={'Please input description'} maxLength={255} />
          </Form.Item>
          {valueNode?.id !== '1' && (
            <Form.Item name={'status'} label={<div>Status</div>}>
              <CustomFormItem name="status">
                <Select disabled onChange={handleSelectStatus}>
                  {dataFindOneStatus?.statusDetails?.map((item, key) => {
                    return (
                      <Option key={key} value={item.id}>
                        {item.status}
                      </Option>
                    );
                  })}
                </Select>
              </CustomFormItem>
            </Form.Item>
          )}
        </Card>,
        '0',
      ),
    ]),
    getItem('Assignee', 'sub1', <UserAddOutlined />, [
      getItem(
        <Card title="List User" style={{ marginBottom: '30px' }}>
          <Form.Item name={'listEmployee'}>
            <div
              id="scrollableDiv"
              style={{
                height: 200,
                overflowY: 'auto',
                overflowX: 'hidden',
                padding: '0 16px',
                border: '1px solid rgba(140, 140, 140, 0.35)',
                marginTop: '10px',
              }}
            >
              <InfiniteScroll
                dataLength={dataSelecListEmployee?.length}
                next={loadMoreData}
                hasMore={dataSelecListEmployee?.length < 2}
                // loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
                // scrollableTarget="scrollableDiv"
              >
                <List
                  dataSource={dataSelecListEmployee}
                  renderItem={(item) => (
                    <List.Item key={item.email}>
                      <EmployeeColumnView
                        id={item?.id}
                        avatar={item?.fullName}
                        fullName={item?.fullName}
                        email={item?.email}
                        minWidth={'0px'}
                        record={item}
                      />
                    </List.Item>
                  )}
                />
              </InfiniteScroll>
            </div>
          </Form.Item>
        </Card>,
        '1',
      ),
    ]),
    getItem('View Transition', 'sub2', <ColumnWidthOutlined />, [
      getItem(
        <div>
          {edgenextValueNode?.map((item: any, idx: any) => {
            const valueNext = nodes?.find((itemNode: any) => {
              return itemNode.id === item.target;
            });

            return (
              <CustomCard>
                <CustomTag color="green">{valueNode?.data?.label}</CustomTag>
                <ArrowRightOutlined />{' '}
                <CustomTag color="purple">{valueNext?.data?.label}</CustomTag>
                <CustomFormItem name="selectNextAction">
                  <Select defaultValue={item.label} disabled>
                    {initialEdges?.map((item: any) => {
                      return <Option value={item?.label}>{item?.label}</Option>;
                    })}
                  </Select>
                </CustomFormItem>
              </CustomCard>
            );
          })}
          {edgePrevValueNode?.map((itemEdge: any, idx: any) => {
            const valuePrev = nodes?.find((itemNode: any) => {
              return itemNode?.id === itemEdge?.source;
            });

            return (
              <CustomCard>
                <CustomTag color="purple">{valuePrev?.data?.label}</CustomTag>
                <ArrowRightOutlined />
                <CustomTag color="green">{valueNode?.data?.label}</CustomTag>
                <CustomFormItem name="selectPrevAction">
                  <Select disabled defaultValue={itemEdge.label}>
                    {initialEdges?.map((item: any) => {
                      return <Option value={item?.label}>{item?.label}</Option>;
                    })}
                  </Select>
                </CustomFormItem>
              </CustomCard>
            );
          })}
        </div>,
        '2',
      ),
    ]),
  ];
  const onClick: MenuProps['onClick'] = (e) => {};

  return (
    <Form form={form} layout="vertical">
      <CustomMenu onClick={onClick} mode="inline" items={items} />
    </Form>
  );
};

export default ModalNodeView;

import { useState } from 'react';
import { Button, Dropdown } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import MenuActionsInsurance from './MenuActionsInsurance';

interface StatusColumnCellProps {
  isShowFullMenu: boolean;
  item: any;
  checkview: any;
  permissions: Array<any>;
  reSetData: any;
  activekey?: string;
}

const DropdownView = (props: StatusColumnCellProps) => {
  const { isShowFullMenu, item, permissions, checkview, activekey } = props;
  const [isVisibleAddColumn, setIsVisibleAddColumn] = useState(false);

  const handleHideMenuActions = () => {
    setIsVisibleAddColumn(false);
  };
  const handleVisibleChange = (visible: any) => {
    setIsVisibleAddColumn(visible);
  };
  return (
    <Dropdown
      overlay={
        <MenuActionsInsurance
          isShowFullMenu={isShowFullMenu}
          item={item}
          checkview={checkview}
          activekey={activekey}
          permissions={permissions}
          handleHideMenuActions={handleHideMenuActions}
          reSetData={() => props.reSetData(activekey)}
        />
      }
      overlayStyle={{ zIndex: 1020 }}
      placement="bottomRight"
      trigger={['click']}
      visible={isVisibleAddColumn}
      onVisibleChange={handleVisibleChange}
    >
      <Button
        type="link"
        style={{ cursor: 'pointer' }}
        className="float-right"
        icon={<EllipsisOutlined style={{ fontSize: '150%', color: '#111827' }} />}
      />
    </Dropdown>
  );
};

export default DropdownView;

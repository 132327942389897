const texts: { [index: string]: string } = {
  txt_font_family: 'Roboto',
  txt_font_weight_large: '600',
  txt_font_weight_medium: '500',
  txt_font_weight_regular: '400',
  txt_font_size_h1: '28px',
  txt_font_size_h2: '20px',
  txt_font_size_h3: '16px',
  txt_font_size_body: '14px',
  txt_font_size_caption: '14px',
};
export default texts;

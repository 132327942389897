import { lazy, useEffect, Suspense } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { readNotifications } from 'app/commonRedux/notificationSlice';
import queryString from 'query-string';
import { PrivateRoute } from 'routes/PrivateRoute';

import { Layout } from 'antd';
import styled from 'styled-components';
import { PERMISSION_TIMESHEET_ACCESS_PERSONAL } from 'constants/permissions';
const { Content } = Layout;

const Headers = lazy(() => import('../LayoutPage/Headers'));

const Home = lazy(() => import('features/mobile/home/pages/Home'));
const PageNotFound = lazy(() => import('components/PageNotFound'));
const LeaveOff = lazy(() => import('features/mobile/timeSheet/pages/LeaveOff'));
const LeaveOffList = lazy(() => import('features/mobile/timeSheet/pages/LeaveOffList'));
const LeaveOffApproval = lazy(() => import('features/mobile/timeSheet/pages/LeaveOffApproval'));
const AdjustTimeApproval = lazy(() => import('features/mobile/timeSheet/pages/AdjustTimeApproval'));
const AdjustTime = lazy(() => import('features/mobile/timeSheet/pages/AdjustTime'));
const OnboardList = lazy(() => import('features/mobile/timeSheet/pages/OnboardList'));
const OverTimeApproval = lazy(() => import('features/mobile/timeSheet/pages/OverTimeApproval'));
const OverTime = lazy(() => import('features/mobile/timeSheet/pages/OverTime'));
const TimeSheet = lazy(() => import('features/mobile/timeSheet/pages/TimeSheet'));

const LayoutMobile = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userId = useSelector((state: { auth: { user: { id: string } | null } }) =>
    state.auth.user ? state.auth.user.id : '',
  );

  const handleReadNotification = async (path: string) => {
    const params: queryString.ParsedQuery<string> = queryString.parse(path);

    if (params.notiCode && !Array.isArray(params.notiCode)) {
      await dispatch(readNotifications({ userId, notiId: params.notiCode }));
      const newUrl = window.location.href.replace(
        `notiCode=${params.notiCode}${Object.keys(params).length > 1 ? '&' : ''}`,
        '',
      );
      const newPathname = newUrl.replace(window.location.origin, '');
      history.replace(newPathname);
      // dispatch(getNotifications({ userId }));
    }
  };

  useEffect(() => {
    if (history.location.search.search('notiCode=') !== 0 && userId !== '') {
      handleReadNotification(history.location.search);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.search]);

  return (
    <LayoutStyled>
      <Headers />
      <Content>
        <Suspense fallback={null}>
          <Switch>
            <PrivateRoute
              path="/home"
              permissions={[PERMISSION_TIMESHEET_ACCESS_PERSONAL.TIMESHEET_ACCESS_PERSONAL]}
            >
              <Home />
            </PrivateRoute>
            <PrivateRoute
              path="/time-sheet"
              permissions={[PERMISSION_TIMESHEET_ACCESS_PERSONAL.TIMESHEET_ACCESS_PERSONAL]}
            >
              <TimeSheet />
            </PrivateRoute>
            <PrivateRoute
              path="/leave-off/create"
              permissions={[PERMISSION_TIMESHEET_ACCESS_PERSONAL.TIMESHEET_ACCESS_PERSONAL]}
            >
              <LeaveOff />
            </PrivateRoute>
            <PrivateRoute
              path="/leave-off/view/:id"
              permissions={[PERMISSION_TIMESHEET_ACCESS_PERSONAL.TIMESHEET_ACCESS_PERSONAL]}
            >
              <LeaveOff mode="view" />
            </PrivateRoute>
            <PrivateRoute
              path="/leave-off/list"
              permissions={[PERMISSION_TIMESHEET_ACCESS_PERSONAL.TIMESHEET_ACCESS_PERSONAL]}
            >
              <LeaveOffList />
            </PrivateRoute>
            <PrivateRoute
              path="/leave-off/approval"
              permissions={[PERMISSION_TIMESHEET_ACCESS_PERSONAL.TIMESHEET_ACCESS_PERSONAL]}
            >
              <LeaveOffApproval />
            </PrivateRoute>
            <PrivateRoute
              path="/adjust-time/approval"
              permissions={[PERMISSION_TIMESHEET_ACCESS_PERSONAL.TIMESHEET_ACCESS_PERSONAL]}
            >
              <AdjustTimeApproval />
            </PrivateRoute>
            <PrivateRoute
              path="/adjust-time/view/:id"
              permissions={[PERMISSION_TIMESHEET_ACCESS_PERSONAL.TIMESHEET_ACCESS_PERSONAL]}
            >
              <AdjustTime mode="view" />
            </PrivateRoute>

            <PrivateRoute
              path="/over-time/approval"
              permissions={[PERMISSION_TIMESHEET_ACCESS_PERSONAL.TIMESHEET_ACCESS_PERSONAL]}
            >
              <OverTimeApproval />
            </PrivateRoute>
            <PrivateRoute
              path="/over-time/create"
              permissions={[PERMISSION_TIMESHEET_ACCESS_PERSONAL.TIMESHEET_ACCESS_PERSONAL]}
            >
              <OverTime />
            </PrivateRoute>
            <PrivateRoute
              path="/over-time/view/:id"
              permissions={[PERMISSION_TIMESHEET_ACCESS_PERSONAL.TIMESHEET_ACCESS_PERSONAL]}
            >
              <OverTime mode="view" />
            </PrivateRoute>
            <PrivateRoute
              path="/onboard/list"
              permissions={[PERMISSION_TIMESHEET_ACCESS_PERSONAL.TIMESHEET_ACCESS_PERSONAL]}
            >
              <OnboardList />
            </PrivateRoute>
            <Route component={PageNotFound} />
          </Switch>
        </Suspense>
      </Content>
    </LayoutStyled>
  );
};

export default LayoutMobile;

export const LayoutStyled = styled(Layout)`
  min-height: 100vh;
  .ant-table.ant-table-small .ant-table-thead > tr > th {
    /* padding: 8px 5px; */
    text-align: center;
  }
  .ant-table.ant-table-small .ant-table-tbody > tr > td {
    /* padding: 8px 5px; */
  }
`;

import { Card, Col, Input, Row } from 'antd';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const Reminder = ({ control, viewMode, getValues }) => {
  const { t } = useTranslation(['performance']);
  const {
    field: notifyBeforeReviewPeriodField,
    fieldState: { error: notifyBeforeReviewPeriodError },
  } = useController({
    name: 'notifyBeforeReviewPeriod',
    control,
  });

  const {
    field: notifyBeforeReviewDateField,
    fieldState: { error: notifyBeforeReviewDateError },
  } = useController({
    name: 'notifyBeforeReviewDate',
    control,
  });
  const shouldDisable = getValues()?.status !== 'New';

  return (
    <Card title={t('performance:performance_review.reminder')} bordered={false}>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <div>
            {t('performance:performance_review.send_notification_before')}{' '}
            <Input
              disabled={viewMode && shouldDisable}
              type="number"
              style={{ width: '100px' }}
              {...notifyBeforeReviewPeriodField}
            />{' '}
            {t('performance:performance_review.days_before_review_period')}
          </div>
          {notifyBeforeReviewPeriodError && <span>{notifyBeforeReviewPeriodError.message}</span>}
        </Col>
        <Col span={24}>
          <div>
            {t('performance:performance_review.send_notification_before')}{' '}
            <Input
              disabled={viewMode && shouldDisable}
              type="number"
              style={{ width: '100px' }}
              {...notifyBeforeReviewDateField}
            />{' '}
            {t('performance:performance_review.days_before_review_date')}
          </div>
          {notifyBeforeReviewDateError && <span>{notifyBeforeReviewDateError.message}</span>}
        </Col>
      </Row>
    </Card>
  );
};

export default Reminder;

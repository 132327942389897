import { DeleteTwoTone } from '@ant-design/icons';
import { Card, Modal } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import EmployeeColumn from 'components/EmployeeColumn';
import ITVTable from 'components/ITVTable';
import MenuAction from 'components/MenuAction';
import { MODE } from 'constants/types';
import { getEmployeeListApproved } from 'features/employee/employeeAction';
import { selectEmployee, setCheckRow } from 'features/employee/employeeSlice';
import { ViewAddMember } from 'features/payroll/pages/PayRun/components/PayRuns/ViewAddMember';
import { useEmployeeListForContractV2 } from 'hooks/fetchers/useEmployeeListForContractV2';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TitleColumnCenter } from 'styles/tableStyled';

const EmployeeLists = ({ setSelectedUser, inputData, viewMode, getValues, setValue }) => {
  const { t } = useTranslation(['performance', 'action', 'overtime']);
  const dispatch = useAppDispatch();
  const [showModal, setShowModal] = useState(false);
  const [selectUser, setSelectUser] = useState<any>([]);
  const [dataOri, setDataOri] = useState<any>([]);
  const [datas, setDatas] = useState<any>([]);
  const shouldDisable = getValues()?.status !== 'New';

  const dataEmployee: any = useAppSelector(selectEmployee);
  const selectRowKey = dataEmployee?.selectRowKey;
  const filters = useMemo(() => ({ offset: 1, limit: 1000 }), []);
  const { data, isLoading } = useEmployeeListForContractV2(filters);
  const employeeListApproved = data;
  const loadingListApproved = isLoading;

  useEffect(() => {
    setSelectUser(selectRowKey);
  }, [selectRowKey]);

  useEffect(() => {
    //// TODO
    ////add dataOrigin lấy khi có API view/edit
    // if (employeeListApproved) {
    //   const copyArr = employeeListApproved?.filter(
    //     (item: any) => !dataOrigin?.some((value: any) => value.id === item?.id),
    //   );
    //   setData(copyArr);
    //   setDataOri(copyArr);
    // }
    // return () => {
    //   dispatch(setCheckRow([]));
    // };
    ////add dataOrigin lấy khi có API view/edit
    if (inputData && viewMode) {
      setSelectUser(inputData);
      const temp = employeeListApproved?.filter((i: any) => inputData?.includes(i?.id));
      setDatas(temp);
      setDataOri(temp);
    }
    return () => {
      dispatch(setCheckRow([]));
    };
  }, [employeeListApproved, inputData]);

  const menu = [
    {
      name: t('action:delete'),
      icon: <DeleteTwoTone twoToneColor="#B7B7B7" />,
      type: 'delete',
      handleClick: (value: any) => {
        const temp = datas?.filter((i: any) => i?.id !== value?.id);
        setValue &&
          setValue(
            'userIds',
            temp?.map((i: any) => i?.id),
          );
        setDatas(temp);
        setDataOri(temp);
        setSelectedUser && setSelectedUser(temp);
      },
    },
  ];

  const columns = [
    {
      title: t('performance:pending_review.employee_name'),
      key: 'fullName',
      dataIndex: 'fullName',
      width: 100,
      minWidth: 100,
      render: (_: any, record: any, index: any) => {
        return (
          <EmployeeColumn
            avatar={
              record?.fullName ? `${record?.fullName}` : `${record?.lastName} ${record?.firstName}`
            }
            fullName={
              record?.fullName ? `${record?.fullName}` : `${record?.lastName} ${record?.firstName}`
            }
            email={record?.email}
          />
        );
      },
    },
    {
      title: t('performance:pending_review.department'),
      key: 'departmentName',
      dataIndex: 'departmentName',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (text: any, record: any, index: any) => {
        return <div>{text}</div>;
      },
    },
    {
      title: t('performance:pending_review.job_title'),
      key: 'positionName',
      dataIndex: 'positionName',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (text: any, record: any, index: any) => {
        return <div>{text}</div>;
      },
    },
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'email',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (text: any, record: any, index: any) => {
        return <div>{text}</div>;
      },
    },
    ...(!(viewMode && shouldDisable)
      ? [
          {
            title: () => {
              return (
                <TitleColumnCenter>{t('performance:performance_review.action')}</TitleColumnCenter>
              );
            },
            key: 'action',
            dataIndex: 'action',
            width: 80,
            minWidth: 80,
            align: 'center',
            render: (_: any, record: any, index: any) => {
              return <MenuAction menu={menu} data={record} />;
            },
          },
        ]
      : []),
  ];

  return (
    <Card
      title={t('performance:performance_review.employee_list')}
      bordered={false}
      extra={
        !(viewMode && shouldDisable) ? (
          <a onClick={() => setShowModal(true)}>
            + {t('performance:performance_review.edit_employee_list')}
          </a>
        ) : null
      }
    >
      <ITVTable
        loading={viewMode ? loadingListApproved : false}
        data={datas}
        columns={columns}
        isRowSelect={false}
        setSelectedUser={setSelectUser}
      />
      <Modal
        open={showModal}
        style={{ top: 0 }}
        title={t('overtime:add_member')}
        okText={t('action:apply')}
        cancelButtonProps={{ style: { display: 'none' } }}
        width={800}
        maskClosable={false}
        onCancel={() => {
          setShowModal(false);
        }}
        okButtonProps={{ disabled: !selectUser || selectUser?.length === 0 }}
        onOk={() => {
          const temp = employeeListApproved?.filter((i: any) => selectUser?.includes(i?.id));
          setDatas([...datas, ...temp]);
          setDataOri([...dataOri, ...temp]);
          setSelectedUser && setSelectedUser([...dataOri, ...temp]);
          setShowModal(false);
        }}
        destroyOnClose
      >
        <ViewAddMember dataOrigin={dataOri} setSelectUser={setSelectUser} />
      </Modal>
    </Card>
  );
};

export default EmployeeLists;

import { createAsyncThunk } from '@reduxjs/toolkit';
import authApi from 'api/authApi';
import axiosClient from 'api/axiosClient';
import employeeApi from 'api/employeeApi';
import { setGlobalLoading } from 'app/commonRedux/appSlice';
import queryString from 'query-string';

import {
  DATE_EXPORT_FORMAT,
  DATE_REPORT_FORMAT,
  MONTH_YEAR_FORMAT,
  YYYY_MM_DD,
} from 'constants/commons';
import { formatDate, formatMonth } from 'utils/date';
import { actions } from './employeeSlice';

export const getEmployeeList = createAsyncThunk(
  'employee/getEmployeeList',
  async ({ callBack, ...params }: any, thunkApi: any) => {
    try {
      const employeeList: any = await employeeApi.getEmployeeList(params);
      callBack && callBack(employeeList.data);
      return employeeList;
    } catch (error) {
      callBack && callBack({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getEmployeeByPermission = createAsyncThunk(
  'employee/getEmployeeByPermission',
  async ({ callBack, ...params }: any, thunkApi: any) => {
    try {
      const employeeList: any = await employeeApi.getEmployeeByPermission(params);
      callBack && callBack(employeeList.data);
      return employeeList;
    } catch (error) {
      callBack && callBack({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const searchEmployee = createAsyncThunk(
  'employee/searchEmployee',
  async ({ callBack, ...params }: any, thunkApi) => {
    try {
      const response = await employeeApi.searchEmployee(params);
      callBack && callBack(response.data);
      return response.data;
    } catch (error) {
      callBack && callBack({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getEmployeeListApproved = createAsyncThunk(
  'employee/getListApproved',
  async (_, thunkApi: any) => {
    try {
      const employeeListApproved: any = await employeeApi.getEmployeeListApproved();
      return employeeListApproved;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getEmployeeDetail = createAsyncThunk(
  'employee/getEmployeeDetail',
  async ({ callBack, ...params }: any, thunkApi: any) => {
    try {
      const employeeDetails: any = await employeeApi.getEmployeeDetail(params.idItem);
      callBack?.(employeeDetails.data);
      return employeeDetails;
    } catch (error) {
      callBack?.({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getEmployeeRecordHistory = createAsyncThunk(
  'employee/getEmployeeRecordHistory',
  async (params: any, thunkApi: any) => {
    try {
      const employeeRecordHistory: any = await employeeApi.getEmployeeRecordHistory(params.idItem);
      return employeeRecordHistory;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const exportEmployees = createAsyncThunk(
  'employee/export',
  async (params: any, thunkApi: any) => {
    try {
      const res: any = await axiosClient.get(
        `/employee/download-file?params=${JSON.stringify(params.query)}`,
        { responseType: 'blob' },
      );
      const url = window.URL.createObjectURL(new Blob([res]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `Personnal_Information_${formatDate(null, DATE_EXPORT_FORMAT, true)}.xlsx`,
      );
      document.body.appendChild(link);
      link.click();
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const createEmployee = createAsyncThunk(
  'employee/createEmployee',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      // Save
      const dataResponse: any = await employeeApi.createEmployee(params.dataRequest);
      // Prepare data update list employee
      let employeeListNew: any | null = null;
      if (dataResponse && dataResponse.statusCode === 200) {
        const res: any = await employeeApi.filterSortEmployeeList(params.paramSortFilter);
        if (res) {
          employeeListNew = res.data?.result;
        }
      }
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return { dataResponse, employeeListNew };
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const updateEmployee = createAsyncThunk(
  'employee/updateEmployee',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      // Update
      const dataResponse: any = await employeeApi.updateEmployee(params.idItem, params.dataRequest);
      // Prepare data update list employee
      let employeeListNew: any | null = null;
      let totalResult = 0;
      if (dataResponse && dataResponse.statusCode === 200) {
        const res: any = await employeeApi.filterSortEmployeeList(
          params.paramSortFilter ? params.paramSortFilter : {},
        );
        if (res) {
          employeeListNew = res.data?.result;
          totalResult = res.data?.totalResult;
        }
      }
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return { dataResponse, employeeListNew, totalResult };
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const updateEmployeeInsurance = createAsyncThunk(
  'employee/updateEmployeeInsurance',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      // Update
      const dataResponse: any = await employeeApi.updateEmployee(params.idItem, params.dataRequest);
      // Prepare data update list employee
      let employeeListNew: any | null = null;
      let totalResult = 0;

      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return { dataResponse, employeeListNew, totalResult };
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const updateEmployeeId = createAsyncThunk(
  'employee/updateEmployeeId',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      // Update
      const dataResponse: any = await employeeApi.updateEmployeeId(
        params.idItem,
        params.dataRequest,
      );
      // Prepare data update list employee
      let employeeListNew: any | null = null;
      if (dataResponse && dataResponse.statusCode === 200) {
        const res: any = await employeeApi.getEmployeeList({});
        if (res && res.statusCode === 200) {
          employeeListNew = res.data?.result;
        }
      }
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return { dataResponse, employeeListNew };
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const deleteEmployee = createAsyncThunk(
  'employee/deleteEmployee',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      // Delete
      const dataResponse: any = await employeeApi.deleteEmployee(params.idItem);
      // Prepare data update list employee
      let newEmployeeList: any | null = null;
      if (dataResponse && dataResponse.statusCode === 200) {
        const res: any = await employeeApi.filterSortEmployeeList(params.paramSortFilter);
        if (res) {
          newEmployeeList = res.data?.result;
        }
      }
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return { dataResponse, newEmployeeList };
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const requestApproveEmployee = createAsyncThunk(
  'employee/requestApproveEmployee',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      // Request approve
      const dataResponse: any = await employeeApi.requestApproveEmployee(params.dataRequest);
      // Prepare data update list employee
      let employeeListNew: any | null = null;
      if (dataResponse && dataResponse.statusCode === 200) {
        const res: any = await employeeApi.filterSortEmployeeList(params.paramSortFilter);
        if (res) {
          employeeListNew = res.data?.result;
        }
      }
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return { dataResponse, employeeListNew };
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const saveAndRequestApproveEmployee = createAsyncThunk(
  'employee/saveAndRequestApproveEmployee',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      let dataResponseSendRequest: any | null = null;
      let dataResponseSave: any | null = null;

      // Save
      if (params.dataSendRequest.idProfile) {
        dataResponseSave = await employeeApi.updateEmployee(
          params.dataSendRequest.idProfile,
          params.dataSave,
        );
      } else {
        dataResponseSave = await employeeApi.createEmployee(params.dataSave);
      }

      // Send request
      if (dataResponseSave && dataResponseSave.data) {
        const dataSendRequest = {
          ...params.dataSendRequest,
          idProfile: dataResponseSave.data?.id,
        };
        dataResponseSendRequest = await employeeApi.requestApproveEmployee(dataSendRequest);
      }

      // Prepare data update list employee
      let employeeListNew: any | null = null;
      if (dataResponseSendRequest && dataResponseSendRequest.statusCode === 200) {
        const res: any = await employeeApi.filterSortEmployeeList(params.paramSortFilter);
        if (res) {
          employeeListNew = res.data?.result;
        }
      }
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return { dataResponse: dataResponseSendRequest, employeeListNew };
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const approveEmployee = createAsyncThunk(
  'employee/approveEmployee',
  async ({ callBack, ...params }: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      // Approve
      const dataResponse: any = await employeeApi.approveEmployee(params.dataRequest);
      // Prepare data update list employee
      let employeeListNew: any | null = null;
      if (dataResponse && dataResponse.statusCode === 200) {
        const res: any = await employeeApi.filterSortEmployeeList(params.paramSortFilter);
        if (res) {
          employeeListNew = res.data?.result;
        }
      }
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack?.(dataResponse);
      return { dataResponse, employeeListNew };
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack?.({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const saveAndApproveEmployee = createAsyncThunk(
  'employee/saveAndApproveEmployee',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      let dataResponseApprove: any | null = null;
      let dataResponseSave: any | null = null;

      // Save
      if (params.dataApprove.idProfile) {
        dataResponseSave = await employeeApi.updateEmployee(
          params.dataApprove.idProfile,
          params.dataSave,
        );
      } else {
        dataResponseSave = await employeeApi.createEmployee(params.dataSave);
      }

      // Send request
      if (dataResponseSave && dataResponseSave.data) {
        const dataApprove = {
          ...params.dataApprove,
          idProfile: dataResponseSave.data?.id,
        };
        dataResponseApprove = await employeeApi.approveEmployee(dataApprove);
      }

      // Prepare data update list employee
      let employeeListNew: any | null = null;
      if (dataResponseApprove && dataResponseApprove.statusCode === 200) {
        const res: any = await employeeApi.filterSortEmployeeList(params.paramSortFilter);
        if (res) {
          employeeListNew = res.data?.result;
        }
      }
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return { dataResponse: dataResponseApprove, employeeListNew };
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const activeDeactiveEmployee = createAsyncThunk(
  'employee/activeDeactiveEmployee',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      // Active Deactive
      const dataResponse: any = await employeeApi.activeDeactiveEmployee(params.dataRequest);
      // Prepare data update list employee
      let employeeListNew: any | null = null;
      let isDeactiveMySelf = false;
      if (dataResponse && dataResponse.statusCode === 200) {
        const res: any = await employeeApi.filterSortEmployeeList(params.paramSortFilter);
        if (res && res.statusCode === 200) {
          employeeListNew = res.data?.result;
        }
        // Logout if deactive myself
        if (
          !params.dataRequest?.isActive &&
          params.dataRequest?.idProfile === params.dataRequest?.idUserCreate
        ) {
          await authApi.logout(params.dataRequest?.idProfile);
          isDeactiveMySelf = true;
        }
      }
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return { dataResponse, employeeListNew, isDeactiveMySelf };
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const filterSortEmployeeList = createAsyncThunk(
  '/employee/search-employee',
  async (params: any, thunkApi: any) => {
    try {
      // thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const dataResponse: any = await employeeApi.filterSortEmployeeList(params);
      // thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return dataResponse;
    } catch (error) {
      // thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return thunkApi.rejectWithValue(error);
    }
  },
);

//Allowance assign
export const getAllAllowanceUser = createAsyncThunk(
  '/allowance-details/search',
  async (params: any, thunkApi: any) => {
    try {
      const dataResponse: any = await axiosClient.get(
        `/allowance-details/search/${params.userId}/${params.monthYear}`,
      );
      return dataResponse;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getAllAllowanceListForUser = createAsyncThunk(
  '/configurations/allowance/latest-history',
  async (params: any, thunkApi: any) => {
    try {
      const { month } = params;
      // thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const dataResponse: any = await axiosClient.get(
        `/configurations/allowance/latest-history?monthYear=${month}`,
      );
      // thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return dataResponse;
    } catch (error) {
      // thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const updateAllowanceUser = createAsyncThunk(
  '/allowance-details/:id',
  async (params: any, thunkApi: any) => {
    const { setErrorMessage } = actions;
    try {
      const { id, data, callBack } = params;
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const dataResponse: any = await axiosClient.put(`/allowance-details/${id}`, data);
      // thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack && callBack();
      return dataResponse;
    } catch (error) {
      thunkApi.dispatch(setErrorMessage(error));
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const getAllUsersHaveAllowance = createAsyncThunk(
  '/allowance-details/search?monthYear',
  async (params: any, thunkApi: any) => {
    try {
      const { start, end, allowanceId, departmentId } = params;
      // thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const dataResponse: any = await axiosClient.get(
        `/allowance-details/search?${start ? `start=${start}` : ''}${end ? `&end=${end}` : ''}${
          allowanceId ? `&allowanceId=${allowanceId}` : ''
        }
        ${departmentId ? `&departmentId=${departmentId}` : ''}
        `,
      );
      // thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return dataResponse;
    } catch (error) {
      // thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return thunkApi.rejectWithValue(error);
    }
  },
);

//Advances assign
export const updateAdvancesUser = createAsyncThunk(
  '/advance-details/:id',
  async (params: any, thunkApi: any) => {
    const { setErrorMessage } = actions;
    try {
      const { id, data, callBack } = params;
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const dataResponse: any = await axiosClient.put(`/user-advance/${id}`, data);
      // thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack && callBack();
      return dataResponse;
    } catch (error) {
      thunkApi.dispatch(setErrorMessage(error));
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);
export const getAllAdvanceUser = createAsyncThunk(
  '/advances-details/search',
  async (params: any, thunkApi: any) => {
    try {
      // thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const dataResponse: any = await axiosClient.get(
        `/user-advance?${queryString.stringify(params)}`,
      );
      // thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return dataResponse;
    } catch (error) {
      // thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return thunkApi.rejectWithValue(error);
    }
  },
);
export const getFindOnePlanUser = createAsyncThunk(
  '/plan-details/search',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const { id } = params;
      const response: any = await axiosClient.get(`/payment-plan/user-advance/${id}`);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);
export const getAllAdvanceListForUser = createAsyncThunk(
  '/configurations/advance-configuration/latest-history',
  async (params: any, thunkApi: any) => {
    try {
      // thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const dataResponse: any = await axiosClient.get(
        `/configurations/advance-configuration/latest-history`,
      );
      // thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return dataResponse;
    } catch (error) {
      // thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getAllUsersHaveAdvance = createAsyncThunk(
  '/advance-details/search?monthYear',
  async (params: any, thunkApi: any) => {
    try {
      const { start, end, advanceId, departmentId } = params;
      // thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const dataResponse: any = await axiosClient.get(
        `/user-advance/search?${start ? `start=${start}` : ''}${end ? `&end=${end}` : ''}${
          advanceId ? `&advanceId=${advanceId}` : ''
        }
        ${departmentId ? `&departmentId=${departmentId}` : ''}
        `,
      );
      // thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return dataResponse;
    } catch (error) {
      // thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return thunkApi.rejectWithValue(error);
    }
  },
);
//Bonus assign
export const getAllBonusUser = createAsyncThunk(
  '/bonus-details/search',
  async (params: any, thunkApi: any) => {
    try {
      // thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const dataResponse: any = await axiosClient.get(
        `/bonus-details/search?${queryString.stringify(params)}`,
      );
      // thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return dataResponse;
    } catch (error) {
      // thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getAllBonusListForUser = createAsyncThunk(
  '/configurations/bonus/latest-history',
  async (params: any, thunkApi: any) => {
    try {
      // thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const dataResponse: any = await axiosClient.get(`/configurations/bonus/latest-history`);
      // thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return dataResponse;
    } catch (error) {
      // thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const updateBonusUser = createAsyncThunk(
  '/bonus-details/:id',
  async (params: any, thunkApi: any) => {
    const { setErrorMessage } = actions;
    try {
      const { id, data, callBack } = params;
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const dataResponse: any = await axiosClient.put(`/bonus-details/${id}`, data);
      // thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack && callBack();
      return dataResponse;
    } catch (error) {
      thunkApi.dispatch(setErrorMessage(error));
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const getAllUsersHaveBonus = createAsyncThunk(
  '/bonus-details/search?monthYear',
  async (params: any, thunkApi: any) => {
    try {
      const { start, end, bonusId, departmentId } = params;
      // thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const dataResponse: any = await axiosClient.get(
        `/bonus-details/search?${start ? `start=${start}` : ''}${end ? `&end=${end}` : ''}${
          bonusId ? `&bonusId=${bonusId}` : ''
        }
        ${departmentId ? `&departmentId=${departmentId}` : ''}
        `,
      );
      // thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return dataResponse;
    } catch (error) {
      // thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return thunkApi.rejectWithValue(error);
    }
  },
);

//Dependents assign
export const getAllDependentsUser = createAsyncThunk(
  '/dependant/search',
  async (params: any, thunkApi: any) => {
    try {
      // thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const dataResponse: any = await axiosClient.get(
        `/dependant/search?${queryString.stringify(params)}`,
      );
      // thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return dataResponse;
    } catch (error) {
      // thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const updateDependentsUser = createAsyncThunk(
  '/dependant/:id',
  async (params: any, thunkApi: any) => {
    const { setErrorMessage } = actions;
    try {
      const { id, data, monthYear, callBack } = params;
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const dataResponse: any = await axiosClient.put(`/dependant/${id}/${monthYear}`, data);
      // thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack && callBack();
      return dataResponse;
    } catch (error) {
      thunkApi.dispatch(setErrorMessage(error));
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const getAllUsersHaveDependents = createAsyncThunk(
  '/dependant/search?monthYear',
  async (params: any, thunkApi: any) => {
    try {
      const { start, end, departmentId } = params;
      // thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const dataResponse: any = await axiosClient.get(`/dependant/search?${
        start ? `start=${start}` : ''
      }${end ? `&end=${end}` : ''}
      ${departmentId ? `&departmentId=${departmentId}` : ''}
      `);
      // thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return dataResponse;
    } catch (error) {
      // thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return thunkApi.rejectWithValue(error);
    }
  },
);

//Contract
export const getContractUser = createAsyncThunk(
  '/contract/:userId',
  async (params: any, thunkApi: any) => {
    try {
      const { userId, ...other } = params;
      const dataResponse: any = await axiosClient.get(
        `/contract-configure/user/${userId}?${queryString.stringify(other)}`,
      );
      return dataResponse;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const createUpdateContractUser = createAsyncThunk(
  '/contract/:userId',
  async (params: any, thunkApi: any) => {
    const { setErrorMessage } = actions;
    try {
      const { userId, data, callBack } = params;
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const dataResponse: any = await axiosClient.put(`/contract-configure/${userId}`, data);
      callBack && callBack();
      return dataResponse;
    } catch (error) {
      thunkApi.dispatch(setErrorMessage(error));
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

//Adjustment
export const getAllAdjustmentUser = createAsyncThunk(
  '/user-adjust-salary/details/search',
  async (params: any, thunkApi: any) => {
    try {
      const dataResponse: any = await axiosClient.get(
        `/user-adjust-salary/search?${queryString.stringify(params)}`,
      );
      return dataResponse;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const updateAdjustmentUser = createAsyncThunk(
  '/user-adjust-salary/:id',
  async (params: any, thunkApi: any) => {
    const { setErrorMessage } = actions;
    try {
      const { id, data, callBack } = params;
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const dataResponse: any = await axiosClient.put(`/user-adjust-salary/${id}`, data);

      callBack?.();
      return dataResponse;
    } catch (error) {
      thunkApi.dispatch(setErrorMessage(error));
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const getAllUsersHaveAdjustment = createAsyncThunk(
  '/user-adjust-salary/search',
  async (params: any, thunkApi: any) => {
    try {
      const { monthYear, adjustSalaryTypeId, departmentId } = params;
      // thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const dataResponse: any = await axiosClient.get(
        `/user-adjust-salary/search?${monthYear ? `monthYear=${monthYear}` : ''}${
          adjustSalaryTypeId ? `&adjustSalaryTypeId=${adjustSalaryTypeId}` : ''
        }${departmentId ? `&departmentId=${departmentId}` : ''}
        `,
      );
      // thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return dataResponse;
    } catch (error) {
      // thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return thunkApi.rejectWithValue(error);
    }
  },
);

//Export
export const exportUsersHaveDependents = createAsyncThunk(
  '/dependant/export-dependant',
  async (params: any, thunkApi: any) => {
    try {
      let str: any = `Users_have_dependents.xlsx`;
      const response: any = await axiosClient.post(`/dependant/export-dependant`, params, {
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', str);
      document.body.appendChild(link);
      link.click();
      return response;
    } catch (error) {
      return error;
    }
  },
);

export const exportUsersHaveBonus = createAsyncThunk(
  '/bonus-details/export',
  async (params: any, thunkApi: any) => {
    try {
      let str: any = `Users_have_bonus.xlsx`;
      const response: any = await axiosClient.post(`/bonus-details/export`, params, {
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', str);
      document.body.appendChild(link);
      link.click();
      return response;
    } catch (error) {
      return error;
    }
  },
);

export const exportUsersHaveAllowance = createAsyncThunk(
  '/allowance-details/export',
  async (params: any, thunkApi: any) => {
    try {
      let str: any = `Users_have_allowance.xlsx`;
      const response: any = await axiosClient.post(`/allowance-details/export`, params, {
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', str);
      document.body.appendChild(link);
      link.click();
      return response;
    } catch (error) {
      return error;
    }
  },
);

export const exportUsersHaveAdvance = createAsyncThunk(
  '/user-advance/export',
  async (params: any, thunkApi: any) => {
    try {
      let str: any = `Users_have_advances.xlsx`;
      const response: any = await axiosClient.post(`/user-advance/export`, params, {
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', str);
      document.body.appendChild(link);
      link.click();
      return response;
    } catch (error) {
      return error;
    }
  },
);

export const exportUsersHaveContract = createAsyncThunk(
  '/contract-configure/export',
  async (params: any, thunkApi: any) => {
    try {
      let str: any = `Users_have_contract_salary.xlsx`;
      const response: any = await axiosClient.post(`/contract-configure/export`, params, {
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', str);
      document.body.appendChild(link);
      link.click();
      return response;
    } catch (error) {
      return error;
    }
  },
);

export const exportUsersHaveAdjustment = createAsyncThunk(
  '/user-adjust-salary/export',
  async (params: any, thunkApi: any) => {
    try {
      let str: any = `Users_have_adjust.xlsx`;
      const response: any = await axiosClient.post(`/user-adjust-salary/export`, params, {
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', str);
      document.body.appendChild(link);
      link.click();
      return response;
    } catch (error) {
      return error;
    }
  },
);

export const exportReport = createAsyncThunk(
  '/report',
  async (
    params: {
      body: {
        start_date: string;
        end_date: string;
        report_type: string;
        export_type: 'pdf' | 'excel';
      };
    },
    thunkApi: any,
  ) => {
    const { body } = params;
    const { export_type } = body;
    try {
      const subFixName = body?.report_type == '1' ? `Labor_report` : 'Tax_report';
      let str: any = `${subFixName}_${formatDate(null, DATE_REPORT_FORMAT, true)}.${
        export_type === 'excel' ? 'xlsx' : 'pdf'
      }`;
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await axiosClient.post(`report`, body, {
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', str);
      document.body.appendChild(link);
      link.click();
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

//Export
export const downloadImportFile = createAsyncThunk(
  '/employee/download-file-sample',
  async (params: any, thunkApi: any) => {
    try {
      let str: any = `Import_Employee_Sample.xlsx`;
      const response: any = await axiosClient.get(`/employee/download-file-sample`, {
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', str);
      document.body.appendChild(link);
      link.click();
      return response;
    } catch (error) {
      return error;
    }
  },
);

export const exportUpComingBirthday = createAsyncThunk(
  'export/upcomingbirthday',
  async (params: any, thunkApi: any) => {
    try {
      const res: any = await axiosClient.post(
        `/dashboard/report-birth-day/export`,
        {},
        {
          responseType: 'blob',
        },
      );
      const url = window.URL.createObjectURL(new Blob([res]));

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `Upcoming_Birthday_${formatMonth(null, MONTH_YEAR_FORMAT, true)}.xlsx`,
      );
      document.body.appendChild(link);
      link.click();
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const exportHolidayListing = createAsyncThunk(
  'export/holidayListing',
  async (params: any, thunkApi: any) => {
    try {
      const res: any = await axiosClient.post(
        `/dashboard/report-holiday/export?${queryString.stringify(params)}`,
        {},
        {
          responseType: 'blob',
        },
      );
      const url = window.URL.createObjectURL(new Blob([res]));

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `Holiday_Listing_${formatMonth(null, MONTH_YEAR_FORMAT, true)}.xlsx`,
      );
      document.body.appendChild(link);
      link.click();
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const exportUpComingExpiredContract = createAsyncThunk(
  'export/upComingExpiredContract',
  async (params: any, thunkApi: any) => {
    try {
      const res: any = await axiosClient.post(
        `/dashboard/report-expired-contract/export`,
        {},
        {
          responseType: 'blob',
        },
      );
      const url = window.URL.createObjectURL(new Blob([res]));

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `Upcoming_Expired_Contract_${formatMonth(null, MONTH_YEAR_FORMAT, true)}.xlsx`,
      );
      document.body.appendChild(link);
      link.click();
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const exportEmployeeGrowth = createAsyncThunk(
  'export/employeeGrowth',
  async (params: any, thunkApi: any) => {
    try {
      const res: any = await axiosClient.post(
        `/dashboard/report-emp-growth/export?${queryString.stringify(params)}`,
        {},
        {
          responseType: 'blob',
        },
      );
      const url = window.URL.createObjectURL(new Blob([res]));

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Employee_Growth_${formatMonth(null, 'YYYY', true)}.xlsx`);
      document.body.appendChild(link);
      link.click();
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const exportLeaveoffStatistic = createAsyncThunk(
  'export/leaveoffStatistic',
  async (params: any, thunkApi: any) => {
    try {
      const { filters } = params;

      const res: any = await axiosClient.post(
        `/dashboard/compare-leave-off-chart/export`,
        {
          fromDate: filters.fromDate,
          toDate: filters.toDate,
          countBy: filters.countBy,
          viewBy: filters.viewBy,
        },
        {
          responseType: 'blob',
        },
      );
      const url = window.URL.createObjectURL(new Blob([res]));

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `Leaveoff_Statistic_${formatMonth(filters.fromDate, YYYY_MM_DD, true)}_${formatMonth(
          filters.toDate,
          YYYY_MM_DD,
          true,
        )}_${filters.countBy}_${filters.viewBy}.xlsx`,
      );
      document.body.appendChild(link);
      link.click();
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const exportLeaveoffSummary = createAsyncThunk(
  'export/leaveoffSummary',
  async (params: any, thunkApi: any) => {
    try {
      const { filters, selectedObjectName } = params;

      const res: any = await axiosClient.post(
        `/dashboard/leave-off-chart-by-year/export`,
        {
          year: filters.year,
          countBy: filters.countBy,
          viewBy: filters.viewBy,
          objectId: filters.objectId,
          objectName: selectedObjectName,
        },
        {
          responseType: 'blob',
        },
      );
      const url = window.URL.createObjectURL(new Blob([res]));

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `Leaveoff_Summary_${filters.year}_${filters.countBy}_${filters.viewBy}_${selectedObjectName}.xlsx`,
      );
      document.body.appendChild(link);
      link.click();
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const exportDiligenceSummary = createAsyncThunk(
  'export/diligenceSummary',
  async (params: any, thunkApi: any) => {
    try {
      const { filters, selectedObjectName } = params;

      const res: any = await axiosClient.post(
        `/dashboard/diligence-chart-by-year/export?${queryString.stringify(filters)}`,
        {},
        {
          responseType: 'blob',
        },
      );
      const url = window.URL.createObjectURL(new Blob([res]));

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `Diligence_Summary_${filters.year}_${filters.viewBy}_${selectedObjectName}.xlsx`,
      );
      document.body.appendChild(link);
      link.click();
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const exportDiligence = createAsyncThunk(
  'export/diligence',
  async (params: any, thunkApi: any) => {
    try {
      const { filters } = params;

      const res: any = await axiosClient.post(
        `/dashboard/report-diligence/export?${queryString.stringify(filters)}`,
        {},
        {
          responseType: 'blob',
        },
      );
      const url = window.URL.createObjectURL(new Blob([res]));

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `Diligence_${filters.fromDate}_${filters.toDate}_${filters.viewBy}.xlsx`,
      );
      document.body.appendChild(link);
      link.click();
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const exportTimesheetAdjust = createAsyncThunk(
  'export/timesheetAdjust',
  async (params: any, thunkApi: any) => {
    try {
      const { filters } = params;

      const res: any = await axiosClient.post(
        `/dashboard/report-adjust/export?${queryString.stringify(filters)}`,
        {},
        {
          responseType: 'blob',
        },
      );
      const url = window.URL.createObjectURL(new Blob([res]));

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `Timesheet_Adjust_${filters.fromDate}_${filters.toDate}_${filters.countBy}_${filters.viewBy}.xlsx`,
      );
      document.body.appendChild(link);
      link.click();
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getManagerList = createAsyncThunk(
  '/employee/list-user-manager',
  async (_, thunkApi: any) => {
    try {
      const employeeList: any = await employeeApi.getManagerList();

      return employeeList;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export enum EditType {
  ADJUST = 'adjust',
  LEAVE = 'leave',
  OVERTIME = 'overtime',
}

export enum ViewListType {
  MANAGE_VIEW = 'ManageView',
  ITEM_HR_EXPORT_DETAILS = 'ItemHrExportDetails',
  PERSONNEL_VIEW = 'PersonnelView',
}

export interface EditTimeProps {
  checkinTime: string | moment.Moment;
  checkoutTime: string | moment.Moment;
  editType: EditType;
  currentDay: string;
  refreshData: any;
  currentWorkTime: any;
  calendarValue: any;
  dataType: any;
  viewList: ViewListType;
  userId: string;
  isShowAdjust?: React.MutableRefObject<boolean>;
  isShowOt?: React.MutableRefObject<boolean>;
  isShowLeave?: React.MutableRefObject<boolean>;
  handleVisibleAdjustTime: (visible: boolean) => void;
  handleVisibleOtPopUp: (visible: boolean) => void;
  handleVisibleLeavePopUp: (visible: boolean) => void;
  reloadListUser: any;
  projectCode?: any;
}

export interface TotalHoursPerWeek {
  totalHoursWork: number;
  totalHoursOt: number;
  totalHoursNoWork: number;
}

export interface DataTypeLeaveOff {
  id: number;
  name: string;
  remain: number;
}

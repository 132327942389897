import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { EmployeeModalDetail } from './styled';

interface EmployeeDetailModalProps {
  isVisible: boolean;
  item: any;
  onClose: () => void;
}

interface RelativeProps {
  name: string;
  email: string;
  occupation: string;
  phoneNumber: string;
  yearOfBirth: string;
  relationship: {
    id: number;
    value: string;
  };
}

const EmployeeDetailModal = (props: EmployeeDetailModalProps) => {
  const { isVisible, item, onClose } = props;

  const { t } = useTranslation(['employee']);

  return (
    <EmployeeModalDetail
      visible={isVisible}
      maskClosable={false}
      onCancel={onClose}
      width={1000}
      style={{ top: 20 }}
      footer={null}
    >
      <FormEmployeeDetail>
        <EmployeeInfor>
          <div className="infor_left">
            <p className="employee_name">{item.fullName}</p>
            <p className="employee_position">{item.roleName}</p>

            <div className="wrapper_infor">
              <p className="employee_title">
                {t('employee:personnel_information_fields.phone_number')}:
              </p>
              <p className="employee_value">{item.phoneNumber}</p>
            </div>
            <div className="wrapper_infor">
              <p className="employee_title">
                {t('employee:employee_information_fields.work_email')}:
              </p>
              <p className="employee_value">{item.email}</p>
            </div>
            <div className="wrapper_infor">
              <p className="employee_title">
                {t('employee:personnel_information_fields.work_location')}:
              </p>
              <p className="employee_value"></p>
            </div>
            <div className="wrapper_infor">
              <p className="employee_title">
                {t('employee:employee_information_fields.line_category')}:
              </p>
              <p className="employee_value">{item.projectPrimaryName}</p>
            </div>
            <div className="wrapper_infor">
              <p className="employee_title">
                {t('employee:employee_information_fields.direct_manager')}:
              </p>
              <p className="employee_value">{item.managerName}</p>
            </div>
          </div>
          {/* <div className="infor_right">
            <img alt="avatar" src={Avatar} />
          </div> */}
        </EmployeeInfor>
        <WorkInfor>
          <div className="header_workinfo">
            <div className="header_workinfo_left">
              {t('employee:personnel_information_fields.work_information')}
            </div>
            <div className="header_workinfo_right">{t('employee:employee_manage.skill_info')}</div>
          </div>
          <div className="wrapper_workinfo">
            <div className="wrapper_workinfo_left">
              <div className="wrapper_workinfo_box">
                <p className="workinfo_title">
                  {t('employee:employee_information_fields.employment_start_date')}:
                </p>
                <p className="workinfo_value">
                  {item.startDate ? moment(item.startDate).format('DD/MM/YYYY') : ''}
                </p>
              </div>
              <div className="wrapper_workinfo_box">
                <p className="workinfo_title">
                  {t('employee:employee_information_fields.contract_type')}:
                </p>
                <p className="workinfo_value">{item.contractTypeValue}</p>
              </div>
              <div className="wrapper_workinfo_box">
                <p className="workinfo_title">
                  {t('employee:personnel_information_fields.duration_contract')}:
                </p>
                <p className="workinfo_value">{item.durationContract}</p>
              </div>
              <div className="wrapper_workinfo_box">
                <p className="workinfo_title">
                  {t('employee:employee_information_fields.contract_start_date')}:
                </p>
                <p className="workinfo_value">
                  {item.contractStartDate
                    ? moment(item.contractStartDate).format('DD/MM/YYYY')
                    : ''}
                </p>
              </div>
              <div className="wrapper_workinfo_box">
                <p className="workinfo_title">
                  {t('employee:employee_information_fields.expiration_date')}:
                </p>
                <p className="workinfo_value">
                  {item.contractExpirationDate
                    ? moment(item.contractExpirationDate).format('DD/MM/YYYY')
                    : ''}
                </p>
              </div>
            </div>
            <div className="wrapper_workinfo_right">
              <div className="wrapper_workinfo_main_skill">
                {item.skill?.mainSkill?.length > 0 && <strong>Main skills:</strong>}
                {item.skill?.mainSkill?.map((skill: any) => (
                  <div className="wrapper_workinfo_box" key={skill.name}>
                    <p className="workinfo_title">{skill.name}:</p>
                    <p className="workinfo_value">{`${skill.yearOfExperience} year${
                      skill.yearOfExperience > 1 ? 's' : ''
                    }`}</p>
                  </div>
                ))}
              </div>

              <div className="wrapper_workinfo_soft_skill">
                {item.skill?.softSkill?.length > 0 && <strong>Soft skills:</strong>}
                {item.skill?.softSkill?.map((skill: any) => (
                  <div className="wrapper_workinfo_box" key={skill.name}>
                    <p className="workinfo_title">{skill.name}</p>
                  </div>
                ))}
              </div>
              {/* <div className="wrapper_workinfo_box">
                <p className="workinfo_title">Time management:</p>
                <p className="workinfo_value"></p>
              </div>
              <div className="wrapper_workinfo_box">
                <p className="workinfo_title">Problem-solving:</p>
                <p className="workinfo_value"></p>
              </div>
              <div className="wrapper_workinfo_box">
                <p className="workinfo_title">Communication:</p>
                <p className="workinfo_value"></p>
              </div> */}
            </div>
          </div>
        </WorkInfor>
        <PersonalInfo>
          <div className="header_personalinfo">
            <div className="header_personalinfo_left">
              {t('employee:personnel_information_fields.personal_information')}
            </div>
            <div className="header_personalinfo_right">
              {t('employee:personnel_information_fields.resident')}
            </div>
          </div>
          <div className="wrapper_personalinfo">
            <div className="wrapper_personalinfo_left">
              <div className="wrapper_personalinfo_address">
                <div className="wrapper_personalinfo_box">
                  <p className="wrapper_personalinfo_box_title">
                    {t('employee:personnel_information_fields.permanent_address')}
                  </p>
                  <p className="wrapper_personalinfo_box_value">{item.permanentAddress}</p>
                </div>
                <div className="wrapper_personalinfo_box">
                  <p className="wrapper_personalinfo_box_title">
                    {t('employee:personnel_information_fields.personal_email')}:
                  </p>
                  <p className="wrapper_personalinfo_box_value">{item.personalEmail}</p>
                </div>
                <div className="wrapper_personalinfo_box">
                  <p className="wrapper_personalinfo_box_title">
                    {t('employee:personnel_information_fields.km')}:
                  </p>
                  <p className="wrapper_personalinfo_box_value"></p>
                </div>
              </div>
              <div className="wrapper_personalinfo_type">
                <p className="wrapper_personalinfo_type_title">
                  {t('employee:personnel_information_fields.marital_status')}
                </p>
                <div className="wrapper_personalinfo_box">
                  <p className="wrapper_personalinfo_box_title">
                    {t('employee:personnel_information_fields.marital_status')}:
                  </p>
                  <p className="wrapper_personalinfo_box_value">{item.maritalStatusValue}</p>
                </div>
              </div>
              <div className="wrapper_personalinfo_type">
                <p className="wrapper_personalinfo_type_title">
                  {t('employee:personnel_information_fields.emergency')}
                </p>
                {item?.relative?.length > 0 ? (
                  item.relative.map((obj: RelativeProps) => {
                    return (
                      <>
                        <div className="wrapper_personalinfo_box">
                          <p className="wrapper_personalinfo_box_title">
                            {t('employee:employee_relative.relative_name')}:
                          </p>
                          <p className="wrapper_personalinfo_box_value">{obj.name}</p>
                        </div>
                        <div className="wrapper_personalinfo_box">
                          <p className="wrapper_personalinfo_box_title">
                            {t('employee:employee_relative.relationship_with_employee')}:
                          </p>
                          <p className="wrapper_personalinfo_box_value">{obj.relationship.value}</p>
                        </div>
                        <div className="wrapper_personalinfo_box">
                          <p className="wrapper_personalinfo_box_title">
                            {t('employee:employee_relative.relative_phone_number')}:
                          </p>
                          <p className="wrapper_personalinfo_box_value">{obj.phoneNumber}</p>
                        </div>
                      </>
                    );
                  })
                ) : (
                  <>
                    <div className="wrapper_personalinfo_box">
                      <p className="wrapper_personalinfo_box_title">
                        {t('employee:employee_relative.relative_name')}:
                      </p>
                      <p className="wrapper_personalinfo_box_value"></p>
                    </div>
                    <div className="wrapper_personalinfo_box">
                      <p className="wrapper_personalinfo_box_title">
                        {t('employee:employee_relative.relationship_with_employee')}:
                      </p>
                      <p className="wrapper_personalinfo_box_value"></p>
                    </div>
                    <div className="wrapper_personalinfo_box">
                      <p className="wrapper_personalinfo_box_title">
                        {t('employee:employee_relative.relative_phone_number')}:
                      </p>
                      <p className="wrapper_personalinfo_box_value"></p>
                    </div>
                  </>
                )}
              </div>
              <div className="wrapper_personalinfo_type">
                <p className="wrapper_personalinfo_type_title">
                  {t('employee:personnel_information_fields.education_information')}
                </p>
                <div className="wrapper_personalinfo_box">
                  <p className="wrapper_personalinfo_box_title">
                    {t('employee:personnel_information_fields.academic_standard')}:
                  </p>
                  <p className="wrapper_personalinfo_box_value">{item.degreeValue}</p>
                </div>
                <div className="wrapper_personalinfo_box">
                  <p className="wrapper_personalinfo_box_title">
                    {t('employee:personnel_information_fields.major')}:
                  </p>
                  <p className="wrapper_personalinfo_box_value">{item.schoolMajor}</p>
                </div>
                <div className="wrapper_personalinfo_box">
                  <p className="wrapper_personalinfo_box_title">
                    {t('employee:personnel_information_fields.institute_training_school')}:
                  </p>
                  <p className="wrapper_personalinfo_box_value">{item.school}</p>
                </div>
              </div>
            </div>
            <div className="wrapper_personalinfo_right">
              <div className="wrapper_personalinfo_right_address">
                <div className="wrapper_personalinfo_right_box">
                  <p className="wrapper_personalinfo_right_box_title">
                    {t('employee:dependant_information_fields.col_nationality')}:
                  </p>
                  <p className="wrapper_personalinfo_right_box_value">{item.nationality}</p>
                </div>
                <div className="wrapper_personalinfo_right_box">
                  <p className="wrapper_personalinfo_right_box_title">
                    {t('employee:personnel_information_fields.identity_card_number')}:
                  </p>
                  <p className="wrapper_personalinfo_right_box_value">{item.citizenId}</p>
                </div>
                <div className="wrapper_personalinfo_right_box">
                  <p className="wrapper_personalinfo_right_box_title">
                    {t('employee:personnel_information_fields.passport')}:
                  </p>
                  <p className="wrapper_personalinfo_right_box_value"></p>
                </div>
                <div className="wrapper_personalinfo_right_box">
                  <p className="wrapper_personalinfo_right_box_title">
                    {t('employee:personnel_information_fields.gender')}:
                  </p>
                  <p className="wrapper_personalinfo_right_box_value">{item.sexValue}</p>
                </div>
                <div className="wrapper_personalinfo_right_box">
                  <p className="wrapper_personalinfo_right_box_title">
                    {t('employee:personnel_information_fields.date_of_birth')}:
                  </p>
                  <p className="wrapper_personalinfo_right_box_value">
                    {item.dateOfBirth ? moment(item.dateOfBirth).format('DD/MM/YYYY') : ''}
                  </p>
                </div>
                <div className="wrapper_personalinfo_right_box">
                  <p className="wrapper_personalinfo_right_box_title">
                    {t('employee:personnel_information_fields.place_of_birth')}:
                  </p>
                  <p className="wrapper_personalinfo_right_box_value">{item.originalPlace}</p>
                </div>
              </div>
              <div className="wrapper_personalinfo_type">
                <p className="wrapper_personalinfo_type_title">
                  {t('employee:personnel_information_fields.work_permit')}
                </p>
                <div className="wrapper_personalinfo_right_box">
                  <p className="wrapper_personalinfo_right_box_title">
                    {t('employee:personnel_information_fields.visa_number')}:
                  </p>
                  <p className="wrapper_personalinfo_right_box_value"></p>
                </div>
                <div className="wrapper_personalinfo_right_box">
                  <p className="wrapper_personalinfo_right_box_title">
                    {t('employee:personnel_information_fields.work_permit_number')}:
                  </p>
                  <p className="wrapper_personalinfo_right_box_value"></p>
                </div>
                <div className="wrapper_personalinfo_right_box">
                  <p className="wrapper_personalinfo_right_box_title">
                    {t('employee:personnel_information_fields.visa_expiration_date')}:
                  </p>
                  <p className="wrapper_personalinfo_right_box_value"></p>
                </div>
              </div>
              <div className="wrapper_personalinfo_type">
                <p className="wrapper_personalinfo_type_title">
                  {t('employee:personnel_information_fields.other')}
                </p>
                <div className="wrapper_personalinfo_right_box">
                  <p className="wrapper_personalinfo_right_box_title">
                    {t('employee:personnel_information_fields.discipline')}:
                  </p>
                  <p className="wrapper_personalinfo_right_box_value"></p>
                </div>
                <div className="wrapper_personalinfo_right_box">
                  <p className="wrapper_personalinfo_right_box_title">
                    {t('employee:personnel_information_fields.bonus')}:
                  </p>
                  <p className="wrapper_personalinfo_right_box_value"></p>
                </div>
                <div className="wrapper_personalinfo_right_box">
                  <p className="wrapper_personalinfo_right_box_title">
                    {t('employee:personnel_information_fields.customer_feedback')}:
                  </p>
                  <p className="wrapper_personalinfo_right_box_value"></p>
                </div>
              </div>
            </div>
          </div>
        </PersonalInfo>
        <TimelineInfo>
          <b>{t('employee:personnel_information_fields.working_phase')}</b>
          {/* <Timeline mode="left" style={{ marginTop: '15px' }}>
            <Timeline.Item label="23/08/2023">Thử việc</Timeline.Item>
            <Timeline.Item label="23/10/2023">Chính thức</Timeline.Item>
            <Timeline.Item label="01/11/2023">Xclaim</Timeline.Item>
            <Timeline.Item label="20/11/2023">Kingwork</Timeline.Item>
            <Timeline.Item label="23/08/2024">1 năm</Timeline.Item>
          </Timeline> */}
        </TimelineInfo>
      </FormEmployeeDetail>
    </EmployeeModalDetail>
  );
};

export default React.memo(EmployeeDetailModal);

const FormEmployeeDetail = styled.div`
  width: 100%;
  color: black;
  p {
    margin: 0;
  }
`;
const EmployeeInfor = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .infor_left {
    width: 60%;
  }
  .infor_left .employee_name {
    font-size: 20px;
    font-weight: 600;
  }
  .infor_left .employee_position {
    margin-bottom: 15px;
    font-size: 16px;
  }
  .wrapper_infor {
    display: flex;
  }
  .wrapper_infor .employee_title {
    width: 40%;
  }
  .wrapper_infor .employee_value {
    width: 60%;
  }
  .infor_right {
    width: 40%;
  }
  .infor_right img {
    width: 100%;
    height: 150px;
    object-fit: contain;
  }
`;
const WorkInfor = styled.div`
  width: 100%;
  .header_workinfo {
    background: #d0cece;
    display: flex;
    margin-top: 15px;
    font-size: 18px;
  }
  .header_workinfo_left {
    width: 60%;
  }
  .header_workinfo_right {
    width: 40%;
    border-left: 1px solid black;
  }
  .wrapper_workinfo {
    display: flex;
    justify-content: space-between;
  }
  .wrapper_workinfo .wrapper_workinfo_left {
    width: 60%;
  }
  .wrapper_workinfo .wrapper_workinfo_left .wrapper_workinfo_box {
    display: flex;
  }
  .wrapper_workinfo .wrapper_workinfo_left .wrapper_workinfo_box .workinfo_title {
    width: 40%;
  }
  .wrapper_workinfo .wrapper_workinfo_left .wrapper_workinfo_box .workinfo_value {
    width: 60%;
  }
  .wrapper_workinfo .wrapper_workinfo_right {
    width: 40%;
  }

  .wrapper_workinfo .wrapper_workinfo_right .wrapper_workinfo_box {
    display: flex;
  }
  .wrapper_workinfo .wrapper_workinfo_right .wrapper_workinfo_box .workinfo_title {
    width: 50%;
  }
  .wrapper_workinfo .wrapper_workinfo_right .wrapper_workinfo_box .workinfo_value {
    width: 50%;
  }
`;
const PersonalInfo = styled.div`
  width: 100%;
  .header_personalinfo {
    background: #d0cece;
    display: flex;
    margin-top: 15px;
    font-size: 18px;
  }
  .header_personalinfo_left {
    width: 60%;
  }
  .header_personalinfo_right {
    width: 40%;
    border-left: 1px solid black;
  }

  .wrapper_personalinfo {
    width: 100%;
    display: flex;
  }
  .wrapper_personalinfo_left {
    width: 60%;
  }
  .wrapper_personalinfo_left .wrapper_personalinfo_box {
    display: flex;
  }
  .wrapper_personalinfo_left .wrapper_personalinfo_box .wrapper_personalinfo_box_title {
    width: 40%;
  }
  .wrapper_personalinfo_left .wrapper_personalinfo_box .wrapper_personalinfo_box_value {
    width: 60%;
  }

  .wrapper_personalinfo_type {
    margin-top: 15px;
  }
  .wrapper_personalinfo_type .wrapper_personalinfo_type_title {
    font-weight: 700;
  }

  .wrapper_personalinfo_right {
    width: 40%;
  }

  .wrapper_personalinfo_right_box {
    display: flex;
  }
  .wrapper_personalinfo_right_box .wrapper_personalinfo_right_box_title {
    width: 50%;
  }
  .wrapper_personalinfo_right_box .wrapper_personalinfo_right_box_value {
    width: 50%;
  }
`;

const TimelineInfo = styled.div`
  width: 100%;
  margin-top: 15px;
  .ant-timeline-item {
    padding-bottom: 40px;
  }
  .ant-timeline.ant-timeline-label .ant-timeline-item-label {
    width: calc(20% - 12px);
    text-align: center;
  }
  .ant-timeline-item-tail {
    left: 20% !important;
  }
  .ant-timeline.ant-timeline-label .ant-timeline-item-head {
    left: 20% !important;
  }
  .ant-timeline-item-content {
    left: calc(20% - 4px) !important;
    width: calc(70% - 14px) !important;
  }
`;

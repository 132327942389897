import styled, { css } from 'styled-components';

interface ContainerStyledProps {
  istopup?: boolean;
}

export const ContainerStyled = styled.div`
  border: 1px solid var(--cl_border_primary);
  box-sizing: border-box;
  border-radius: 5px;
  position: relative;
  padding: 10px;
  ${(props: ContainerStyledProps) =>
    props.istopup &&
    css`
      margin-top: 1rem;
    `}
`;

export const LabelStyled = styled.div`
  position: absolute;
  top: -0.5rem;
  left: 1rem;
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 19px;
  letter-spacing: 0.5px;
  color: var(--cl_text_primary);
  background-color: white;
`;

export const InputStyled = styled.input`
  border: none;
  outline: none;
  width: 100%;
  &:focus {
    outline: none;
    border: none;
  }
`;

export const InputContainerStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

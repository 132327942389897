import { useEffect, useState } from 'react';
import employeeContractApi from 'api/employeeContractApi';

interface ContractType {
  id: number;
  name: string;
}

export const useContractTypeList = () => {
  const [contractTypeList, setContractTypeList] = useState<Array<ContractType>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<unknown>(undefined);

  useEffect(() => {
    const fetchContractTypeListAPI = async () => {
      try {
        setIsLoading(true);
        const response = await employeeContractApi.getTypeList();
        setContractTypeList(response?.data?.value || []);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchContractTypeListAPI();
  }, []);

  return { data: contractTypeList, isLoading, error };
};

import { Modal } from 'antd';
import styled from 'styled-components';
import { ReactNode } from 'react';

import { CloseIcon } from 'assets/icons';

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-height: 500px;
  overflow-y: auto;
`;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 12px;
    .ant-modal-header {
      border-bottom: none;
      border-radius: 12px;
      padding: 16px 24px 8px;
    }
    .ant-modal-body {
      padding: 8px 24px 16px;
    }
  }
`;

interface ListingModalProps {
  title: ReactNode;
  isOpen?: boolean;
  children: ReactNode;
  onCancel?: () => void;
}

export function ListingModal({ title, isOpen, children, onCancel }: ListingModalProps) {
  return (
    <StyledModal
      title={title}
      open={isOpen}
      centered
      closeIcon={<CloseIcon />}
      onCancel={onCancel}
      width={400}
      footer={null}
    >
      <StyledContent>{children}</StyledContent>
    </StyledModal>
  );
}

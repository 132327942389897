import debounce from 'lodash/debounce';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import moment from 'moment';

import { SearchIcon } from 'assets/icons';
import { DatePickerField, InputField, SelectField, SwitchField } from 'components/FormFields';
import { ContractSalaryPayload } from 'types';
import { useLineCategoryList } from 'hooks';
import { TaxProfiles } from 'hrm-common/extensions/enums/personel';
import { YEAR_FORMAT } from 'constants/payroll';

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const StyledWrapperYear = styled.div`
  width: 80px;
`;

interface ContractSalaryFiltersProps {
  initialValues?: Partial<ContractSalaryPayload>;
  onSubmit?: (payload: ContractSalaryPayload) => void;
}

export function ContractSalaryFilters({ initialValues, onSubmit }: ContractSalaryFiltersProps) {
  const { t } = useTranslation(['employee', 'payroll', 'action']);
  const { control, watch, handleSubmit } = useForm<ContractSalaryPayload>({
    defaultValues: {
      search: '',
      ...initialValues,
    },
  });

  const { data: lineCategoryList, isLoading } = useLineCategoryList();

  const watchIsYearMode = watch('isYearMode');

  const lineCategoryOptions = lineCategoryList.map((x) => ({
    label: x.name,
    value: x.projectCode,
  }));

  const taxProfileOptions = [
    {
      label: t('employee:contract_information_fields.all_tax_profile'),
      value: '',
    },
    {
      label: t('employee:contract_information_fields.more_than_month'),
      value: TaxProfiles.MORE_THAN_THREE_MONTH,
    },
    {
      label: t('employee:contract_information_fields.less_than_month'),
      value: TaxProfiles.LESS_THAN_THREE_MONTH,
    },
    {
      label: t('employee:contract_information_fields.intern'),
      value: TaxProfiles.INTERN,
    },
    {
      label: t('employee:contract_information_fields.probation'),
      value: TaxProfiles.PROBATION,
    },
  ];

  const handleFiltersSubmit = (formValues: ContractSalaryPayload) => {
    if (formValues.isYearMode) {
      delete formValues.taxProfile;
      formValues.year = moment(formValues.year).format(YEAR_FORMAT);
    } else {
      delete formValues.year;
    }

    onSubmit?.(formValues);
  };

  const debounceSearchChange = debounce(handleSubmit(handleFiltersSubmit), 350);

  return (
    <form onSubmit={handleSubmit(handleFiltersSubmit)}>
      <StyledContainer>
        <InputField
          name="search"
          control={control}
          placeholder={t('action:search')}
          prefix={<SearchIcon />}
          onChange={() => debounceSearchChange()}
        />

        <SwitchField
          name="isYearMode"
          control={control}
          checkedChildren={t('payroll:year')}
          unCheckedChildren={t('payroll:month')}
          onChange={() => handleSubmit(handleFiltersSubmit)()}
        />

        {watchIsYearMode ? (
          <StyledWrapperYear>
            <DatePickerField
              name="year"
              control={control}
              picker="year"
              allowClear={false}
              onChange={() => handleSubmit(handleFiltersSubmit)()}
            />
          </StyledWrapperYear>
        ) : (
          <SelectField
            name="taxProfile"
            placeholder={t('payroll:contract_salary.tax_profile_placeholder')}
            control={control}
            options={taxProfileOptions}
            showSearch
            allowClear={false}
            onChange={handleSubmit(handleFiltersSubmit)}
          />
        )}

        <SelectField
          name="line"
          placeholder={t('payroll:contract_salary.line_category_placeholder')}
          control={control}
          options={lineCategoryOptions}
          showSearch
          allowClear
          loading={isLoading}
          onChange={handleSubmit(handleFiltersSubmit)}
        />
      </StyledContainer>
    </form>
  );
}

import React from 'react';
import { useWindowSize } from 'app/hooks';
import { useTranslation } from 'react-i18next';

import KingworkLogo from 'assets/icons/kingwork_logo.png';
import phoneBlue from 'assets/icons/phoneblue.svg';
import mailBlue from 'assets/icons/mailblue.svg';

import AuthLabel from 'features/auth/components/AuthLabel';
import { RowStyled, LagerLofoStyled, VideoStyled } from './styled';

const AuthVideo = () => {
  const { width } = useWindowSize();
  const { t } = useTranslation(['layout']);
  return (
    <>
      {width && width < 600 ? (
        <>
          <VideoStyled
            src="https://www.youtube.com/embed/HOu_n0KWXhM"
            title="How to use cloud"
            // @ts-ignore
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></VideoStyled>
          <AuthLabel label={t('layout:introduce.introduce_first')} />
          <AuthLabel label={t('layout:introduce.introduce_second')} />
          <AuthLabel label={t('layout:introduce.introduce_third')} />
          <RowStyled className="bettween">
            <AuthLabel label={t('layout:introduce.introduce_fourth')} />
            <AuthLabel label={t('layout:introduce.introduce_fifth')} />
          </RowStyled>
          <RowStyled className="bettween">
            <AuthLabel icon={phoneBlue} label="+84(028)3999-1895" />
            <AuthLabel icon={mailBlue} label="info@innotech-vn.com" />
          </RowStyled>
        </>
      ) : (
        <>
          <RowStyled>
            <LagerLofoStyled src={KingworkLogo} alt="lager logo" />
          </RowStyled>
          <AuthLabel label={t('layout:introduce.introduce_first')} />
          <AuthLabel label={t('layout:introduce.introduce_second')} />
          <AuthLabel label={t('layout:introduce.introduce_third')} />
          <VideoStyled
            src="https://www.youtube.com/embed/HOu_n0KWXhM"
            title="How to use cloud"
            // @ts-ignore
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></VideoStyled>
          <RowStyled className="bettween">
            <AuthLabel label={t('layout:introduce.introduce_fourth')} />
            <AuthLabel label={t('layout:introduce.introduce_fifth')} />
          </RowStyled>
          <RowStyled className="bettween">
            <AuthLabel icon={phoneBlue} label="+84(028)3999-1895" />
            <AuthLabel icon={mailBlue} label="info@innotech-vn.com" />
          </RowStyled>
        </>
      )}
    </>
  );
};

export default AuthVideo;

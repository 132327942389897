import { Skeleton } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import ITVTable from 'components/ITVTable';
import { PROFILE_STATUSES, PROFILE_STATUS_COLORS } from 'constants/profiles';
import { getDepartmentList } from 'features/department/departmentSlice';
import EmployeeColumn from 'features/employee/components/EmployeeColumn';
import { searchEmployee } from 'features/employee/employeeAction';
import { selectEmployee } from 'features/employee/employeeSlice';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ITVButton from 'styles/buttonStyled';
import ITVSearch from './ITVSearch';

const filterDefault = {
  sortBy: 'status',
  orderBy: 'asc',
};

const EmployeeList = ({
  viewMode = false,
  handleSubmit,
  submitText,
  selectionType = 'checkbox',
  filters = {},
}: any) => {
  const dispatch = useAppDispatch();
  const employee = useAppSelector(selectEmployee);
  const employeeList = employee.employeeSearchList;
  const loading = employee.isSearchingEmployee;
  const { t } = useTranslation(['timesheet', 'insurance', 'employee', 'onboard']);
  const [total, setTotal] = useState(0);
  const [resultTotal, setResultTotal] = useState(0);
  const [widthTable] = useState(978);
  const [selectedUser, setSelectedUser] = useState<string[]>([]);
  const [data, setData] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [sizePage, setSizePage] = useState(10);

  const [searching, setSearching] = useState(false);
  const selectRowKey = employee.selectRowKey;

  useEffect(() => {
    setSelectedUser(selectRowKey);
  }, [selectRowKey]);

  useEffect(() => {
    dispatch(
      searchEmployee({
        ...filters,
      }),
    );
    dispatch(
      getDepartmentList({
        filters: filterDefault,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (employee) {
      setData(employeeList);
      setTotal(employee.total);
      setResultTotal(employee.total);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employee]);

  let COLUMNS: any = [
    {
      title: t('insurance:is_code'),
      dataIndex: 'employeeId',
      key: 'employeeId',
      ellipsis: true,
      width: 60,
      minWidth: 60,
    },
    {
      title: t('Employee'),
      dataIndex: 'fullName',
      key: 'fullName',
      width: 200,
      minWidth: 200,
      ellipsis: true,
      render: function (text: any, record: any, index: any) {
        return (
          <EmployeeColumn
            avatar={record?.fullName}
            fullName={record?.fullName}
            email={record?.email}
          />
        );
      },
    },
    {
      title: t('department'),
      dataIndex: 'departmentName',
      key: 'departmentName',
      width: 120,
      minWidth: 120,
      sorter: {
        compare: (a: any, b: any) => {
          if (a.departmentName && b.departmentName)
            return a.departmentName.localeCompare(b.departmentName);
          return 0;
        },
        multiple: 3,
      },
    },
    {
      title: t('insurance:position'),
      dataIndex: 'positionName',
      key: 'positionName',
      ellipsis: true,
      width: 120,
      minWidth: 120,
      sorter: {
        compare: (a: any, b: any) => {
          if (a.positionName && b.positionName) return a.positionName.localeCompare(b.positionName);
          return 0;
        },
        multiple: 3,
      },
    },
    {
      title: t('insurance:record_status'),
      // dataIndex: 'status',
      key: 'status',
      width: 120,
      minWidth: 120,
      ellipsis: true,
      sorter: {
        compare: (a: any, b: any) => {
          if (a.status && b.status) return a.status.localeCompare(b.status);
          return 0;
        },
        multiple: 3,
      },
      render: function (value: any, record: any, index: any) {
        return (
          <StatusColumn status={value.status}>• {PROFILE_STATUSES[value.status]}</StatusColumn>
        );
      },
    },
  ];

  const [columns] = useState(COLUMNS);

  const search = (key: string) => {
    setSearching(true);
    const text = key.toLowerCase();
    if (text) {
      const temp = employeeList.filter((item: any) => {
        return (
          item.employeeId?.toString().toLowerCase().includes(text) ||
          item.firstName?.toLowerCase().includes(text) ||
          item.lastName.toLowerCase().includes(text)
        );
      });
      setResultTotal(temp.length);
      setData(temp);
    } else {
      setData(employeeList);
      setResultTotal(employeeList?.length);
    }
    setSearching(false);
  };

  return (
    <WrapContainer>
      {/* {total} */}
      <Container>
        <ContainerLeft>
          <ITVSearch handleSearch={(value: any) => search(value)} />
        </ContainerLeft>
        <ITVButton
          disabled={!selectedUser || selectedUser.length === 0}
          style={{ width: 100 }}
          onClick={() => handleSubmit && handleSubmit(selectedUser)}
        >
          {submitText}
        </ITVButton>
      </Container>

      {loading || searching ? (
        <Skeleton />
      ) : (
        <ITVTable
          columns={columns}
          data={data}
          width={widthTable}
          totalIni={total}
          totalResult={resultTotal}
          setSelectedUser={setSelectedUser}
          selectionType={selectionType}
          limit={sizePage}
          setLimit={setSizePage}
          offset={currentPage}
          setOffset={setCurrentPage}
        />
      )}
    </WrapContainer>
  );
};

export default EmployeeList;

interface StatusColumnProps {
  status: string;
}
export const StatusColumn = styled.div`
  color: ${(props: StatusColumnProps) => PROFILE_STATUS_COLORS[props.status]};
`;

export const StyledTest = styled.div`
  .epl-employee-list {
    height: 86vh;
    text-align: center;
    background-color: $grey;
    overflow-y: hidden;
  }

  .epl-employee-container {
    display: flex;
    font-size: 30px;
    flex-direction: column;
    height: 100%;

    &__item-header {
      flex: 5%;
      height: 100vh;
    }

    &__item-functions {
      flex: 10%;
      height: 100vh;
    }

    &__item-table {
      flex: 85%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: 100vh;
      .ant-btn .ant-dropdown-trigger {
        top: 100%;
      }
    }
  }
  .ant-layout-content {
    max-height: 100vh;
    overflow: hidden;
    & > div {
      padding: 0 !important;
      min-height: 0;
    }
  }
  .info {
    margin-left: 1rem;
  }
`;

const WrapContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const ContainerLeft = styled.div``;

export const ContainerRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

import { Button, Modal, Popconfirm } from 'antd';
import insurancesApi from 'api/insurancesApi';
import { useAppSelector } from 'app/hooks';
import { notificationToast } from 'components/notificationToast';
import { Notification } from 'constants/notification';
import { PermissionType } from 'hrm-common/extensions/enums/personel';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { formatCurrencyUnit, labelUnit } from '../constants/common';
import { selectInsurance } from '../insuranceSlice';
import ModalReject from './ModalReject';

interface TotalContributeAllEmployeeProps {
  amountOfSalary: number;
  totalContributeEmployee: number;
  totalContributeEmployer: number;
}

interface ModalSendConfirmInsuranceProps {
  isVisible: boolean;
  onClose: () => void;
  totalContributeAllEmployee: TotalContributeAllEmployeeProps;
  dateRecord: any;
  reSetData: () => void;
  permissions: any;
}

const ModalSendConfirmInsurance = (props: ModalSendConfirmInsuranceProps) => {
  const { isVisible, onClose, totalContributeAllEmployee, dateRecord, reSetData, permissions } =
    props;
  const insurance = useAppSelector(selectInsurance);
  const dataRecordPay = insurance?.dataRecordPayment;

  const [iswidth] = useState(540);
  const { t } = useTranslation(['insurance', 'timesheet']);
  const [openModalReject, setOpenModalReject] = useState(false);

  const handleClose = () => {
    onClose();
  };

  const handleClickSure: any = async (status: string) => {
    try {
      const response = await insurancesApi.confirmRecordPayment({
        status: status,
        monthYear: dateRecord,
      });
      notificationToast(Notification.Type.Success, response.message, Notification.Duration._3s);
      reSetData();
      onClose();
    } catch (error: any) {
      console.log(error);
      notificationToast(Notification.Type.Error, error?.message, Notification.Duration._3s);
    }
  };

  const handleOpenModalReject = () => {
    setOpenModalReject(true);
  };
  const handleCloseModalReject = () => {
    setOpenModalReject(false);
  };
  const handleResetAfterReject = () => {
    reSetData();
    onClose();
  };

  const showButtonFooter = () => {
    if (permissions.includes(PermissionType.RECORD_INSURANCE_PAYMENT_APPROVE)) {
      if (dataRecordPay?.data?.status === 'confirm') {
        return (
          <DivButton>
            <InfoButtonCancel onClick={handleClose}>{t('cancel')}</InfoButtonCancel>
            <InfoButton onClick={() => handleClickSure('waiting-for-confirm')}>
              {t('send_request')}
            </InfoButton>
          </DivButton>
        );
      }
      if (dataRecordPay?.data?.status === 'waiting-for-confirm') {
        return (
          <DivButton>
            <InfoButtonApprove onClick={() => handleClickSure('approved')}>
              {t('approve')}
            </InfoButtonApprove>
            <InfoButtonCancel onClick={handleOpenModalReject}>{t('reject')}</InfoButtonCancel>
          </DivButton>
        );
      }
      if (
        dataRecordPay?.data?.status === 'approved' ||
        dataRecordPay?.data?.status === 'rejected'
      ) {
        return (
          <DivButton>
            <Popconfirm
              title={t('timesheet:Are_you_reset_request')}
              onConfirm={() => handleClickSure('reset')}
            >
              <InfoButton>{t('timesheet:Reset')}</InfoButton>
            </Popconfirm>
          </DivButton>
        );
      }
    } else if (permissions.includes(PermissionType.INSURANCE_APPROVAL_APPROVE_REJECT_REQUEST)) {
      if (dataRecordPay?.data?.status === 'confirm') {
        return (
          <DivButton>
            <InfoButtonCancel onClick={handleClose}>{t('cancel')}</InfoButtonCancel>
            <InfoButton onClick={() => handleClickSure('waiting-for-confirm')}>
              {t('send_request')}
            </InfoButton>
          </DivButton>
        );
      }
      if (dataRecordPay?.data?.status === 'rejected') {
        return (
          <DivButton>
            <Popconfirm
              title={t('timesheet:Are_you_reset_request')}
              onConfirm={() => handleClickSure('reset')}
            >
              <InfoButton>{t('timesheet:Reset')}</InfoButton>
            </Popconfirm>
          </DivButton>
        );
      }
    } else {
      return null;
    }
  };

  return (
    <>
      <InsuranceModal
        visible={isVisible}
        maskClosable={false}
        onCancel={handleClose}
        width={iswidth}
        style={{ top: 180 }}
        footer={null}
      >
        <Container>
          <div>
            <Row>
              <p>
                {t('Total_contribute')} {t('of_employee')}
              </p>
              <p>
                {formatCurrencyUnit(totalContributeAllEmployee?.totalContributeEmployee, labelUnit)}
              </p>
            </Row>
            <Row>
              <p>
                {t('Total_contribute')} {t('of_employer')}
              </p>
              <p>
                {formatCurrencyUnit(totalContributeAllEmployee?.totalContributeEmployer, labelUnit)}
              </p>
            </Row>
            <Row>
              <p>
                <b>{t('total')}</b>
              </p>
              <p>
                <b>
                  {formatCurrencyUnit(
                    totalContributeAllEmployee?.totalContributeEmployee +
                      totalContributeAllEmployee?.totalContributeEmployer,
                    labelUnit,
                  )}
                </b>
              </p>
            </Row>
          </div>

          {showButtonFooter()}
        </Container>
      </InsuranceModal>

      <ModalReject
        isVisible={openModalReject}
        itemId={null}
        reSetData={handleResetAfterReject}
        isShowFullMenu={false}
        onClose={handleCloseModalReject}
        isRejectRecordPay={openModalReject}
        dateRecord={dateRecord}
      />
    </>
  );
};

export default React.memo(ModalSendConfirmInsurance);

export const InsuranceModal = styled(Modal)`
  .ant-modal-content {
    width: $iswidth;
    .ant-modal-close-x {
      margin-top: -10px;
      margin-right: -10px;
    }
  }
`;
export const Container = styled.div`
  padding: 20px;
`;
export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid gray;
  p {
    margin: 1em 0;
  }
`;
export const DivButton = styled.div`
  height: 35px;
  display: flex;
  justify-content: center;
`;
export const InfoButtonApprove = styled(Button)`
  height: 32px;
  width: 131px;
  background: var(--cl_success100);
  font-size: 14px;
  font-weight: 500;
  margin-top: 15px;
  font-family: Roboto;
  border-radius: 6px;
  padding: 4px 12px 6px 12px;
  color: var(--cl_success600);
  margin-right: 10px;
`;
export const InfoButton = styled(Button)`
  height: 32px;
  width: 131px;
  background: #f1592a;
  font-size: 14px;
  font-weight: 500;
  margin-top: 15px;
  font-family: Roboto;
  border-radius: 6px;
  padding: 4px 12px 6px 12px;
  color: #ffffff;
  margin-left: 10px;
`;
export const InfoButtonCancel = styled(Button)`
  height: 32px;
  width: 131px;
  background: #ffffff;
  font-size: 14px;
  font-weight: 500;
  margin-top: 15px;
  font-family: Roboto;
  border-radius: 6px;
  padding: 4px 12px 6px 12px;
  color: #6b7280;
  margin-right: 10px;
`;

import { Collapse } from 'antd';

const { Panel } = Collapse;

const QuestionCandidate = ({ data: dataParent }: any) => {
  return (
    <>
      {dataParent?.questions?.map((item: any, key: any) => {
        return (
          <div style={{ position: 'relative' }}>
            <Collapse style={{ marginBottom: 20, borderRadius: 5 }}>
              <Panel header={item?.name} key={key} style={{ fontWeight: '700' }}>
                {item?.questions?.map((item: any, key: any) => {
                  return (
                    <div style={{ display: 'flex', fontWeight: '400' }}>
                      <p>{key + 1}.</p>
                      <p>&nbsp;{item}</p>
                    </div>
                  );
                })}
              </Panel>
            </Collapse>
          </div>
        );
      })}
    </>
  );
};

export default QuestionCandidate;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Dropdown } from 'antd';
import MenuActions from './MenuActions';
import { EllipsisOutlined } from '@ant-design/icons';
import { TFunction } from 'react-i18next';

interface StatusColumnCellProps {
  isShowFullMenu: boolean;
  item: any;
  permissions: Array<any>;
  t?: TFunction<('modal' | 'employee' | 'onboard' | 'timesheet')[], undefined>;
  paramSortFilter?: any;
}

function StatusColumnCell(props: StatusColumnCellProps) {
  const { isShowFullMenu, item, permissions, t, paramSortFilter } = props;
  const [isVisibleAddColumn, setIsVisibleAddColumn] = useState(false);

  const handleHideMenuActions = () => {
    setIsVisibleAddColumn(false);
  };
  const handleVisibleChange = (visible: any) => {
    setIsVisibleAddColumn(visible);
  };

  return (
    <>
      <Dropdown
        overlay={
          <MenuActions
            isShowFullMenu={isShowFullMenu}
            item={item}
            permissions={permissions}
            handleHideMenuActions={handleHideMenuActions}
            t={t}
            paramSortFilter={paramSortFilter}
          />
        }
        overlayStyle={{ zIndex: 1020 }}
        placement="bottomRight"
        trigger={['click']}
        visible={isVisibleAddColumn}
        onVisibleChange={handleVisibleChange}
      >
        <Button
          type="link"
          className="float-right"
          icon={<EllipsisOutlined style={{ fontSize: '150%', color: '#111827' }} />}
        />
      </Dropdown>
    </>
  );
}

StatusColumnCell.propTypes = {
  isShowFullMenu: PropTypes.bool.isRequired,
  item: PropTypes.any.isRequired,
  permissions: PropTypes.any.isRequired,
};

export default StatusColumnCell;

class TypeUtils {
  static isString(value: any) {
    return typeof value === 'string';
  }
  static isNumber(value: any) {
    return typeof value === 'number';
  }
  static isObject(value: any) {
    return typeof value === 'object';
  }
}

export default TypeUtils;

import { useEffect, useState } from 'react';
import PageHeader from 'components/PageHeader';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { selectConfiguration } from 'features/configuration/configurationSlice';
import { selectTrainingData } from '../trainingSlice';
import ITVTable from 'components/ITVTable';
import { EyeOutlined } from '@ant-design/icons';
import MenuAction from 'components/MenuAction';
import ITVSearch from 'components/ITVSearch';
import { getTraineeDetail, getTraineeList } from '../trainingAction';
import moment from 'moment';
import { YYYY_MM_DD } from 'constants/commons';
import ButtonBack from 'components/ButtonBack';
import { useTranslation } from 'react-i18next';

const TraineeManagementDetail = () => {
  const { id } = useParams();
  const { t } = useTranslation(['trainningManagement', 'employee']);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const history = useHistory();
  const stateURL = location.state;

  const trainData = useAppSelector(selectTrainingData);
  const traineeDetail = trainData?.traineeDetail;

  const menuStore = useAppSelector(selectConfiguration);
  const [sizePage, setSizePage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [searchName, setSearchName] = useState('');

  useEffect(() => {
    if (traineeDetail?.result) {
      setData(traineeDetail?.result);
    }
  }, [traineeDetail]);

  useEffect(() => {
    dispatch(
      getTraineeDetail({
        userId: id,
        offset: currentPage,
        limit: sizePage,
        searchName,
      }),
    );
  }, [dispatch, id, currentPage, sizePage, searchName]);

  const columns = [
    {
      title: '#',
      key: 'index',
      dataIndex: 'index',
      width: 80,
      minWidth: 80,
      align: 'center',
      render: (text: any, _: any, index: any) => {
        const trueIndex = index + sizePage * (currentPage - 1);
        return <>{trueIndex + 1}</>;
      },
    },
    {
      title: t('trainningManagement:course_management.course_name'),
      key: 'courseName',
      dataIndex: 'courseName',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (text) => text,
    },
    {
      title: t('trainningManagement:course_management.training_date'),
      key: 'categoryName',
      dataIndex: 'categoryName',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (text, record) => {
        const dateFrom = record?.dateFrom ? moment(record?.dateFrom).format(YYYY_MM_DD) : '';
        const dateTo = record?.dateTo ? moment(record?.dateTo).format(YYYY_MM_DD) : '';
        return `${dateFrom} - ${dateTo}`;
      },
    },
    {
      title: t('trainningManagement:course_management.description'),
      key: 'description',
      dataIndex: 'description',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (text) => {
        return text;
      },
    },
    {
      title: t('employee:my_training.result'),
      key: 'result',
      dataIndex: 'result',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (text, record) => {
        return record?.userDetail?.result;
      },
    },
    {
      title: t('trainningManagement:course_management.status'),
      key: 'status',
      dataIndex: 'status',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (text, record) => {
        return record?.userDetail?.status;
      },
    },
  ];

  return (
    <>
      <ButtonBack handleBack={() => history.replace('/training/trainee-management')} />
      <PageHeader title={stateURL?.fullName} />
      <ITVSearch style={{ marginBottom: '1rem' }} handleSearch={(value) => setSearchName(value)} />
      <ITVTable
        data={data}
        columns={columns}
        isRowSelect={false}
        setOffset={setCurrentPage}
        offset={currentPage}
        setLimit={setSizePage}
        limit={sizePage}
        totalResult={traineeDetail?.totalResult}
      />
    </>
  );
};

export default TraineeManagementDetail;

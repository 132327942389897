import { PlusOutlined } from '@ant-design/icons';
import { useAppSelector } from 'app/hooks';
import PageHeader from 'components/PageHeader';
import { selectConfiguration } from 'features/configuration/configurationSlice';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import CustomButton from 'styles/buttonStyled';

interface Props {
  total?: number;
  onAddNewButtonClick: () => void;
  viewMode?: boolean;
  buttonTitle?: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Header = (props: Props) => {
  const { total, onAddNewButtonClick, viewMode, buttonTitle } = props;
  const { t } = useTranslation(['employee']);
  const location: any = useLocation();

  const menuStore = useAppSelector(selectConfiguration);
  const dataScreenManage = menuStore?.dataScreenManage?.result;

  const [titleHeader, setTitleHeader] = useState('');

  useEffect(() => {
    const temp = dataScreenManage
      ?.filter((item: any) => location.pathname.includes(item.path))
      ?.map((item: any) => item.nameScreen);
    setTitleHeader(temp ? temp[0] : '');
  }, [dataScreenManage, location.pathname]);

  return (
    <Container>
      <TitleContainer>
        <PageHeader
          title={titleHeader}
          subTitle={
            typeof total === 'number'
              ? t('employee:employee_manage.total_employee', { data: total })
              : ''
          }
        />
      </TitleContainer>
      {!viewMode && (
        <CustomButton onClick={onAddNewButtonClick} icon={<PlusOutlined />}>
          {buttonTitle ? buttonTitle : t('employee:employee_manage.new_employee')}
        </CustomButton>
        // <ButtonContainer >
        //   <img src={IconAdd} alt="Add icon" />
        //   <ButtonTitle></ButtonTitle>
        // </ButtonContainer>
      )}
    </Container>
  );
};

export default Header;

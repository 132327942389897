import { memo } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut, Pie } from 'react-chartjs-2';
import styled from 'styled-components';
import { useAppSelector } from 'app/hooks';
import { selectPayroll } from 'features/payroll/payrollSlice';

ChartJS.register(ArcElement, Tooltip, Legend);

const StyledWrapperChart = styled.div`
  width: 75%;
  height: 75%;
  margin: auto;
`;

const COLORS_CHART = [
  { id: 1, value: '#FF5733' }, // Red
  { id: 2, value: '#33FF57' }, // Green
  { id: 3, value: '#3357FF' }, // Blue
  { id: 4, value: '#FF33A8' }, // Pink
  { id: 5, value: '#FF8C33' }, // Orange
  { id: 6, value: '#FFFF33' }, // Yellow
  { id: 7, value: '#33FFF6' }, // Cyan
  { id: 8, value: '#B833FF' }, // Purple
  { id: 9, value: '#33FFB5' }, // Mint
  { id: 10, value: '#FF3333' }, // Dark Red
  { id: 11, value: '#339FFF' }, // Light Blue
  { id: 12, value: '#A833FF' }, // Violet
  { id: 13, value: '#FFB533' }, // Light Orange
  { id: 14, value: '#33FF99' }, // Light Green
  { id: 15, value: '#FF33D1' }, // Magenta
];

const StyledHeaderTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto;
  line-height: 20px;
  color: #121926;
  text-align: center;
  margin-top: 15px;
`;

const StyledWrapper = styled.div`
  margin-bottom: 8px;
`;

function PayrollCostByDesignationChart() {
  const dataPayrollCostByDesignation = useAppSelector(selectPayroll)?.dataPayrollCostByDesignation;

  const data = {
    labels:
      dataPayrollCostByDesignation &&
      dataPayrollCostByDesignation?.map((item) => item?.designation),
    datasets: [
      {
        label: '# of Votes',
        data:
          dataPayrollCostByDesignation &&
          dataPayrollCostByDesignation?.map((item) => item?.totalSalaryCost),
        backgroundColor:
          dataPayrollCostByDesignation &&
          dataPayrollCostByDesignation?.map((item, index) => COLORS_CHART[index]?.value),
        borderColor: ['rgb(246 250 253)'],
        borderWidth: 2,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: true,
        position: 'right', // Place legend at the bottom
        labels: {
          boxWidth: 20,
          padding: 10,
        },
        title: { display: true, padding: 0 },
      },
    },
    layout: {
      padding: {
        top: 30, // Adjust the bottom padding to create more space
      },
    },
  };

  return (
    <StyledContainer>
      <StyledWrapperChart>
        <StyledWrapper>
          <StyledHeaderTitle>Payroll Cost by Designation</StyledHeaderTitle>
        </StyledWrapper>
        <Doughnut data={data} style={{ width: '100%', height: '100%' }} options={options} />
      </StyledWrapperChart>
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  padding: 16px 24px;
  background-color: var(--cl_white);
  border-radius: 12px;
  border: 0.5px solid var(--cl_gray200);
  height: 100%;
`;

export default memo(PayrollCostByDesignationChart);

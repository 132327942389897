import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal, notification } from 'antd';
import i18n from 'app/i18n';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { notificationToast } from 'components/notificationToast';
import { ROOT_API } from 'constants/commons';
import { Notification } from 'constants/notification';
import { getToken, getUser, removeStorage } from 'utils/auth';
import { handleMessage } from 'utils/message';

let checkExpired = false;

notification.config({
  maxCount: 1,
});

const axiosClient = axios.create({
  baseURL: ROOT_API,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 50000,
});

const tenantMap = new Map([
  ['itv', 'itv'],
  ['api-dev', 'dev'],
  ['api-uat', 'uat'],
]);

// Add a request interceptor
axiosClient.interceptors.request.use(
  function (config: AxiosRequestConfig) {
    // Do something before request is sent
    const token = getToken();
    const isRegister = localStorage.getItem('hrm.register') === 'yes';
    const subdomain = ROOT_API?.split('.')[0].split('//')[1] || 'itv';
    const rootTenant = localStorage.getItem('hrm.tenant') || tenantMap.get(subdomain) || subdomain;

    if (token) config.headers.common['Authorization'] = `Bearer ${token}`;
    config.headers.common['accept-language'] = 'vi-VN';
    config.headers.common['x-tenant-id'] = isRegister ? 'core' : rootTenant;
    config.headers.common['lang'] = i18n.language;
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);

// Add a response interceptor
axiosClient.interceptors.response.use(
  function (response: AxiosResponse<any>) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    handleMessage({ ...response.data, method: response.config.method, url: response.config.url });
    return response.data;
  },
  async function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    // if (process.env.NODE_ENV === 'production') console.clear();
    if (checkExpired) {
      notificationToast(Notification.Type.Error, 'Session expired', Notification.Duration._3s);
    } else if (error?.response?.status === 503) {
      //catch 503 status internal server error
      let err = 'Server is busy. Please try again later!';
      notificationToast(Notification.Type.Error, err, Notification.Duration._3s);
    }
    // else if (error?.response?.status === 400 && error?.response?.data?.statusCode === 400) {
    //   const f = error?.response?.data?.fieldErrors as Object;
    //   const errGlobalKey = '__global';
    //   if (f.hasOwnProperty(errGlobalKey)) {
    //     const globalErrors = (f as any)[errGlobalKey] as Array<string>;
    //     notificationToast('error', globalErrors.join('\n'), 3);
    //   }
    //   console.log('Skip error');
    // }
    else if (
      error?.response?.status !== 401 ||
      (error?.response?.status === 401 &&
        !getUser() &&
        !window.location.pathname.includes('/handle-request-timesheet'))
    ) {
      let err = error.response?.data.message;
      let errorFromBlob =
        error.response?.data instanceof Blob
          ? JSON.parse(await error.response?.data?.text())
          : null;
      if (errorFromBlob?.message) {
        err = errorFromBlob?.message;
      }
      //handle for blob file
      else if (err === undefined && !axios.isCancel(error)) {
        err = 'Something wrong please try it again';
      }

      if (!axios.isCancel(error)) {
        if (error !== 'Unauthorized') {
          notificationToast(Notification.Type.Error, err, Notification.Duration._3s);
        }
      }
    } else if (
      (error?.response?.status === 401 && getUser()) ||
      (error?.response?.status === 401 &&
        !getUser() &&
        window.location.pathname.includes('/handle-request-timesheet'))
    ) {
      checkExpired = true;
      removeStorage();
      localStorage.removeItem('persist:root');
      localStorage.removeItem('current_date_choose');
      localStorage.removeItem('isVerifyOTP');
      Modal.info({
        closable: false,
        title: 'Your token has expired',
        content: 'You will be redirected to the login page',
        icon: <ExclamationCircleOutlined />,
        onOk: () => {
          checkExpired = false;
          const backUrl = `${window.location.pathname}${window.location.search}`;
          var newURL =
            window.location.protocol + '//' + window.location.host + `/login?backUrl=${backUrl}`;
          if (backUrl === '/login') {
            newURL = window.location.protocol + '//' + window.location.host + '/login';
          }
          window.location.replace(newURL);
        },
      });
    }
    return Promise.reject(error?.response?.data);
  },
);

export default axiosClient;

import { Skeleton } from 'antd';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import styled from 'styled-components';

interface ListUserProps {
  data: any;
  loadMoreData: any;
  height: string;
  children: React.ReactNode;
  [propName: string]: any;
}

export default function ListComponent(props: ListUserProps) {
  const { data, loadMoreData, height, children } = props;
  return (
    <ContainerList height={height} id="containerList">
      <InfiniteScroll
        // {...props}
        dataLength={data.length}
        next={loadMoreData}
        hasMore={false}
        loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
        scrollableTarget="scrollableDiv"
      >
        {children}
      </InfiniteScroll>
    </ContainerList>
  );
}

const ContainerList = styled.div`
  font-family: Roboto;
  scroll-behavior: smooth;
  height: ${(props: { height: string }) => props.height};
  overflow: auto;
  border-top: 1px solid rgba(140, 140, 140, 0.35);
  border-bottom: 1px solid rgba(140, 140, 140, 0.35);
`;

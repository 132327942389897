import { Col, Row } from 'antd';
import { RichTextField } from 'components/FormFields';
import { RecruitJobDetailType } from 'features/recruitment/jobs/components/types';
import { Dispatch, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';

const JobRequirements = ({
  data,
  handleChange,
  visible,
  setVisible,
}: {
  data?: RecruitJobDetailType;
  handleChange?: (value: any) => void;
  visible: boolean;
  setVisible: Dispatch<React.SetStateAction<boolean>>;
}) => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
    getValues,
  } = useForm({
    resolver: undefined,
    defaultValues: {
      requirements:
        data?.jobTemplate?.requirements + '' === 'undefined'
          ? ''
          : data?.jobTemplate?.requirements + '',
    },
  });

  useEffect(() => {
    reset({
      requirements:
        data?.jobTemplate?.requirements + '' === 'undefined'
          ? ''
          : data?.jobTemplate?.requirements + '',
    });
  }, [data]);

  const onSubmit = (data: any) => {
    handleChange &&
      handleChange((value: any) => {
        return {
          ...value,
          jobTemplate: { ...value?.jobTemplate, ...data },
        };
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col span={24}>
          <RichTextField
            disabled={!visible}
            onChange={handleSubmit(onSubmit)}
            control={control}
            name="requirements"
          />
        </Col>
      </Row>
    </form>
  );
};
export default JobRequirements;

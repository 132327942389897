import axiosClient from './axiosClient';

const contractApi = {
  contractGet: () => {
    const response = axiosClient.get(`/contract`);
    return response;
  },
};

export default contractApi;

import { Card, Col, Input, Row } from 'antd';
import { useController } from 'react-hook-form';
import FormCourse from '../components/FormCourse';
import { useState } from 'react';
import CourseScreen from '../components/CourseScreen';

const CourseManagement = () => {
  const [created, setCreated] = useState(false);
  return !created ? (
    <CourseScreen setCreated={setCreated} />
  ) : (
    <FormCourse setCreated={setCreated} />
  );
};

export default CourseManagement;

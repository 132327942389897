import { Control, FieldValues, Path, useController } from 'react-hook-form';
import { DatePicker } from 'antd';
import type { DatePickerProps } from 'antd';
import styled, { css } from 'styled-components';
import { DatePickerIcon } from 'assets/icons';
import { CSSProperties } from 'react';

interface StyledWrapperProps {
  directionLabel: 'horizontal' | 'vertical';
}

export type DatePickerFieldProps<T extends FieldValues> = DatePickerProps & {
  name: Path<T>;
  control: Control<T>;
  directionLabel?: 'horizontal' | 'vertical';
  label?: string;
  datePickerStyle?: CSSProperties;
};

const StyledContainer = styled.div`
  .ant-picker {
    border-radius: 6px;
  }
`;

const StyledWrapper = styled.div`
  ${(props: StyledWrapperProps) =>
    props?.directionLabel === 'horizontal' &&
    css`
      display: flex;
      align-items: center;
    `}
`;

const StyledLabel = styled.p`
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: var(--cl_gray900);
  ${(props: StyledWrapperProps) =>
    props?.directionLabel === 'vertical' &&
    css`
      margin-bottom: 6px;
    `}
`;

const StyledErrorMessage = styled.div`
  color: var(--cl_error500);
  margin-top: 6px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
`;

export function DatePickerField<T extends FieldValues>({
  name,
  control,
  directionLabel = 'vertical',
  label,
  datePickerStyle,
  value: externalValue,
  onChange: externalOnChange,
  ...rest
}: DatePickerFieldProps<T>) {
  const {
    field: { onChange, value, ref },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  return (
    <StyledContainer>
      <StyledWrapper directionLabel={directionLabel}>
        {label && <StyledLabel directionLabel={directionLabel}>{label}</StyledLabel>}

        <DatePicker
          style={datePickerStyle}
          status={error?.message ? 'error' : undefined}
          ref={ref}
          value={value}
          suffixIcon={<DatePickerIcon />}
          onChange={(date, dateString) => {
            onChange(date);
            externalOnChange?.(date, dateString);
          }}
          {...rest}
        />
      </StyledWrapper>

      {error?.message && <StyledErrorMessage>{error?.message}</StyledErrorMessage>}
    </StyledContainer>
  );
}

import { EmployeeModalDetail } from 'features/employee/pages/EmployeeDetailModal/styled';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Password, PasswordForm } from '../components/FormPassword';
import PayslipDetail from '../components/PayslipDetail';
import { Modal, notification } from 'antd';
import { useAppDispatch } from 'app/hooks';
import { getPaySlipDetail } from 'features/payroll/payrollAction';

interface PayslipDetailModalProps {
  isVisible: boolean;
  item: any;
  onClose: () => void;
}

const PayslipDetailModal = (props: PayslipDetailModalProps) => {
  const { isVisible, item, onClose } = props;
  const dispatch = useAppDispatch();

  const { t } = useTranslation(['employee']);
  const [allowAccess, setAllowAccess] = useState(false);
  const [error, setError] = useState('');
  const checkAccess = (values: Password) => {
    const body = {
      payslipId: item?.payslipId,
      password: values.password,
    };
    dispatch(
      getPaySlipDetail({
        body,
        callBack: () => {
          setAllowAccess(true);
          notification.destroy();
        },
      }),
    );
  };

  return (
    <StyledModal
      open={isVisible}
      maskClosable={false}
      onCancel={() => {
        onClose();
        setAllowAccess(false);
      }}
      style={{ top: 100, textAlign: '-webkit-center' }}
      width={allowAccess ? null : 1000}
      title={item?.payslipName}
      footer={null}
      allowAccess={allowAccess}
      destroyOnClose={true}
    >
      {allowAccess ? (
        <FormPayslipDetail>
          <PayslipDetail name={item?.payslipName} />
        </FormPayslipDetail>
      ) : (
        <PasswordForm onSubmit={checkAccess} error={error} />
      )}
    </StyledModal>
  );
};

export default React.memo(PayslipDetailModal);

const FormPayslipDetail = styled.div`
  width: 100%;
  color: black;
  p {
    margin: 0;
  }
`;

const StyledModal = styled<any>(Modal)`
    .ant-modal-content {
    width: ${(props) => (props.allowAccess ? '1000px' : 'fit-content')};
    .ant-modal-close-x {
      margin-top: -10px;
      margin-right: -10px;
    }
    .ant-modal-body {
    }
  }
  }
`;

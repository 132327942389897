import PropTypes from 'prop-types';
export const customTypeComponent = (editor, options) => {
  const { onlyView } = options;
  editor?.Components.addType('cell', {
    model: {
      defaults: {
        attributes: { title: 'column' },
        resizable: true,
      },
    },
  });
  editor?.Components.addType('table', {
    model: {
      defaults: {
        attributes: { cellspacing: 5 },
      },
    },
  });
  editor?.Components.addType('image', {
    model: {
      defaults: {
        draggable: '[title=column], [data-gjs-type=link]',
        styles: `img {max-width: 100%}`,
      },
    },
  });
  editor?.Components.addType('text', {
    model: {
      defaults: {
        draggable: '[title=column], [data-gjs-type=link]',
      },
    },
  });
  editor?.Components.addType('button', {
    model: {
      defaults: {
        draggable: '[title=column], [data-gjs-type=link]',
      },
    },
  });
  editor?.Components.addType('quote', {
    model: {
      defaults: {
        draggable: '[title=column], [data-gjs-type=link]',
      },
    },
  });
  editor?.Components.addType('link', {
    model: {
      defaults: {
        draggable: '[title=column], [data-gjs-type=text]',
      },
    },
  });
  editor?.Components.addType('link-block', {
    model: {
      defaults: {
        draggable: '[title=column]',
      },
    },
  });
  // View template email
  if (onlyView) {
    editor?.Components.addType('text', {
      model: {
        defaults: {
          editable: false,
        },
      },
    });
    editor?.Components.addType('text-inline', {
      model: {
        defaults: {
          editable: false,
        },
      },
    });
  }
};

customTypeComponent.propTypes = {
  editor: PropTypes.any,
  options: PropTypes.shape({
    isTypeTable: PropTypes.bool,
    componentTypeTable: PropTypes.any,
    defaultValueCellspacing: PropTypes.number,
    onlyView: PropTypes.bool,
  }),
};

import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import dashboardEmployeeApi from 'api/dashboardEmployeeApi';
import { StatisticListEmployeeItem } from './StatisticListEmployeeItem';

export function StatisticListEmployee() {
  const { t } = useTranslation(['dashboard']);
  const [isLoadingTotalEmployee, setIsLoadingTotalEmployee] = useState(false);
  const [isLoadingNewEmployee, setIsLoadingNewEmployee] = useState(false);
  const [isLoadingResignedEmployee, setIsLoadingResignedEmployee] = useState(false);

  const [totalEmployee, setTotalEmployee] = useState(0);
  const [totalEmployee_monthYear, setTotalEmployee_monthYear] = useState<Moment | null>(
    moment(new Date()),
  );
  const [newEmployee, setNewEmployee] = useState(0);
  const [newEmployee_monthYear, setNewEmployee_monthYear] = useState<Moment | null>(
    moment(new Date()),
  );
  const [resignedEmployee, setResignedEmployee] = useState(0);
  const [resignedEmployee_monthYear, setResignedEmployee_monthYear] = useState<Moment | null>(
    moment(new Date()),
  );

  const STATISTIC_LIST = [
    {
      label: t('total_employees'),
      value: totalEmployee,
      initialMonthYear: totalEmployee_monthYear,
      onSubmit: (monthYear: Moment | null) => setTotalEmployee_monthYear(monthYear),
      loading: isLoadingTotalEmployee,
    },
    {
      label: t('dashboard_employee.new_epls'),
      value: newEmployee,
      initialMonthYear: newEmployee_monthYear,
      onSubmit: (monthYear: Moment | null) => setNewEmployee_monthYear(monthYear),
      loading: isLoadingNewEmployee,
    },
    {
      label: t('dashboard_employee.resigned_epls'),
      value: resignedEmployee,
      initialMonthYear: resignedEmployee_monthYear,
      onSubmit: (monthYear: Moment | null) => setResignedEmployee_monthYear(monthYear),
      loading: isLoadingResignedEmployee,
    },
  ];

  useEffect(() => {
    async function fetchStatisticTotalEmployees() {
      try {
        if (!totalEmployee_monthYear) return;

        setIsLoadingTotalEmployee(true);
        const response = await dashboardEmployeeApi.getTotalEmployeeDashboard({
          month: totalEmployee_monthYear.month() + 1,
          year: totalEmployee_monthYear.year(),
        });
        setTotalEmployee(response?.data?.totalEmployees?.data ?? 0);
        setIsLoadingTotalEmployee(false);
      } catch (error) {
        setIsLoadingTotalEmployee(false);
      }
    }
    fetchStatisticTotalEmployees();
  }, [totalEmployee_monthYear]);

  useEffect(() => {
    async function fetchStatisticNewEmployees() {
      try {
        if (!newEmployee_monthYear) return;

        setIsLoadingNewEmployee(true);
        const response = await dashboardEmployeeApi.getNewEmployeeDashboard({
          month: newEmployee_monthYear.month() + 1,
          year: newEmployee_monthYear.year(),
        });
        setNewEmployee(response?.data?.newEmployees ?? 0);
        setIsLoadingNewEmployee(false);
      } catch (error) {
        setIsLoadingNewEmployee(false);
      }
    }
    fetchStatisticNewEmployees();
  }, [newEmployee_monthYear]);

  useEffect(() => {
    async function fetchStatisticResignedEmployees() {
      try {
        if (!resignedEmployee_monthYear) return;

        setIsLoadingResignedEmployee(true);
        const response = await dashboardEmployeeApi.getResignedEmployeeDashboard({
          month: resignedEmployee_monthYear.month() + 1,
          year: resignedEmployee_monthYear.year(),
        });
        setResignedEmployee(response?.data?.resignedEmployees ?? 0);
        setIsLoadingResignedEmployee(false);
      } catch (error) {
        setIsLoadingResignedEmployee(false);
      }
    }
    fetchStatisticResignedEmployees();
  }, [resignedEmployee_monthYear]);

  return (
    <StyledContainer>
      {STATISTIC_LIST.map((item) => (
        <StatisticListEmployeeItem {...item} />
      ))}
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

import { DeleteTwoTone, ExportOutlined, EyeTwoTone } from '@ant-design/icons';
import { Card, Col, Input, Modal, Radio, Row, Select } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import EmployeeColumn from 'components/EmployeeColumn';
import { InputField } from 'components/FormFields';
import ITVTable from 'components/ITVTable';
import MenuAction from 'components/MenuAction';
import { MODE } from 'constants/types';
import { filterSortEmployeeList, getEmployeeListApproved } from 'features/employee/employeeAction';
import { selectEmployee, setCheckRow } from 'features/employee/employeeSlice';
import { ViewAddMember } from 'features/payroll/pages/PayRun/components/PayRuns/ViewAddMember';
import { exportTrainingDetail } from 'features/training/trainingAction';
import { useEffect, useState } from 'react';
import { useController } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import CustomButton from 'styles/buttonStyled';
import AddTrainee from './AddTrainee';
import { selectTrainingData } from 'features/training/trainingSlice';
import { useTranslation } from 'react-i18next';

interface Props {
  setSelectedUser: any;
  inputData: any;
  viewMode: Boolean;
  title?: string;
  addTitle?: string;
  control?: any;
  getValues?: any;
  setValue?: any;
  reset?: any;
  setDataResult?: any;
}

const EmployeeLists = ({
  setSelectedUser,
  inputData,
  viewMode,
  title,
  addTitle,
  control,
  getValues,
  setValue,
  reset,
  setDataResult,
}: Props) => {
  const { id } = useParams();
  const { t } = useTranslation(['trainningManagement', 'action', 'performance', 'employee']);
  const dispatch = useAppDispatch();
  const [showModal, setShowModal] = useState(false);
  const [selectUser, setSelectUser] = useState<any>([]);
  const [showModalFeedBack, setShowModalFeedBack] = useState(false);
  const [selectCurrent, setSelectCurrent] = useState<any>(null);
  const [dataOri, setDataOri] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  // const [dataResult, setDataResult] = useState<any>([]);

  // const loadingListApproved = dataEmployee?.loadingListApproved;
  const trainData = useAppSelector(selectTrainingData);
  const filterTrainee = trainData?.filterTrainee?.result;

  useEffect(() => {
    if (selectUser?.length) {
      setData(selectUser);
      setSelectedUser && setSelectedUser(selectUser);
      setSelectUser([]);
    } else if (filterTrainee?.length) {
      setData(filterTrainee);
      setSelectedUser && setSelectedUser(filterTrainee);
    } else if (inputData && viewMode) {
      // const userIds = inputData?.map((user: any) => user?.userId);
      setData(inputData);
      // setData(employeeListApproved?.filter((i: any) => userIds?.includes(i?.id)));
      // setDataOri(employeeListApproved?.filter((i: any) => userIds?.includes(i?.id)));
    }
    return () => {
      dispatch(setCheckRow([]));
    };
  }, [inputData, selectUser, filterTrainee]);

  const menu = [
    {
      name: t('action:delete'),
      type: 'delete',
      icon: <DeleteTwoTone twoToneColor="#B7B7B7" />,
      handleClick: (value: any) => {
        const temp = data?.filter((i: any) => !(i?.id === value?.id));
        setData(temp);
        setDataOri(temp);
        setSelectedUser && setSelectedUser(temp);
      },
    },
  ];

  const menuDetail = [
    {
      name: t('trainningManagement:course_management.view_feedback'),
      icon: <EyeTwoTone />,
      handleClick: (dt: any) => {
        setShowModalFeedBack(true);
        const userIds = inputData?.map((user: any) => user?.userId);
        const pos = userIds?.indexOf(dt?.userId);
        setSelectCurrent(pos);
      },
    },
  ];
  const handleChangeResult = (userId: string, result: string) => {
    setDataResult((prevDataResult: any) => {
      const existingIndex = prevDataResult.findIndex((item: any) => item.userId === userId);
      if (existingIndex !== -1) {
        const newDataResult = [...prevDataResult];
        newDataResult[existingIndex].result = result;
        return newDataResult;
      } else {
        return [...prevDataResult, { userId, result }];
      }
    });
  };

  const columns = [
    {
      title: '#',
      key: 'departmentName',
      dataIndex: 'departmentName',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (text: any, record: any, index: any) => <div>{index + 1}</div>,
    },
    {
      title: t('performance:pending_review.employee_name'),
      key: 'fullName',
      dataIndex: 'fullName',
      width: 100,
      minWidth: 100,
      render: (_: any, record: any) => (
        <EmployeeColumn
          avatar={
            record?.fullName ? `${record?.fullName}` : `${record?.lastName} ${record?.firstName}`
          }
          fullName={
            record?.fullName ? `${record?.fullName}` : `${record?.lastName} ${record?.firstName}`
          }
          email={record?.email}
        />
      ),
    },
    {
      title: t('trainningManagement:trainee_management.store'),
      key: 'storeName',
      dataIndex: 'storeName',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (text: any) => <div>{text}</div>,
    },
    {
      title: t('performance:pending_review.department'),
      key: 'departmentName',
      dataIndex: 'departmentName',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (text: any) => <div>{text}</div>,
    },
    {
      title: t('performance:pending_review.job_title'),
      key: 'jobTitle',
      dataIndex: 'jobTitle',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (text: any) => <div>{text}</div>,
    },
    {
      title: t('trainningManagement:course_management.status'),
      key: 'status',
      dataIndex: 'status',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (text: any) => <div>{text}</div>,
    },
    {
      title: t('employee:my_training.result'),
      key: 'result',
      dataIndex: 'result',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (text: any, record: any) => {
        return (
          <div>
            {id ? (
              <Select
                defaultValue={record?.result || ''}
                style={{ width: '100%' }}
                onSelect={(value) => {
                  handleChangeResult(record?.userId, value);
                }}
              >
                <Select.Option value="Pass">Pass</Select.Option>
                <Select.Option value="Fail">Fail</Select.Option>
              </Select>
            ) : (
              text
            )}
          </div>
        );
      },
    },
    {
      title: t('action:action'),
      key: 'action',
      dataIndex: 'action',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (text: any, record: any) => (
        <MenuAction menu={id ? menuDetail : menu} data={record} />
      ),
    },
  ];

  const { field } = useController({
    name: selectCurrent != null ? `users[${selectCurrent}].userFeedback` : '',
    control,
    defaultValue: '',
  });

  const handleExport = () => {
    dispatch(exportTrainingDetail({ id }));
  };

  return (
    <Card
      title={title ? title : 'Employee List'}
      bordered={false}
      extra={
        <>
          {id && (
            <CustomButton icon={<ExportOutlined />} loading={false} onClick={handleExport}>
              {t('trainningManagement:course_management.export')}
            </CustomButton>
          )}
          {!viewMode ? (
            <a onClick={() => setShowModal(true)}>+ {addTitle ? addTitle : `Edit employee list`}</a>
          ) : null}
        </>
      }
      headStyle={{
        fontSize: 24,
        fontWeight: 500,
      }}
    >
      <ITVTable
        loading={false}
        data={data}
        columns={columns}
        isRowSelect={false}
        setSelectedUser={setSelectedUser}
      />
      <Modal
        open={showModal}
        style={{ top: 0 }}
        title={
          <div style={{ fontSize: 18, fontWeight: 'bold', textAlign: 'center' }}>
            {t('trainningManagement:course_management.add_trainees')}
          </div>
        }
        okText={'Apply'}
        cancelButtonProps={{ style: { display: 'none' } }}
        width={800}
        maskClosable={false}
        onCancel={() => {
          setShowModal(false);
        }}
        footer={null}
        // okButtonProps={{ disabled: !selectUser || selectUser?.length === 0 }}
        // onOk={() => {
        //   const temp = employeeListApproved?.filter((i: any) => selectUser?.includes(i?.id));
        //   setData([...data, ...temp]);
        //   setDataOri([...dataOri, ...temp]);
        //   setSelectedUser && setSelectedUser([...dataOri, ...temp]);
        //   setShowModal(false);
        // }}
        destroyOnClose
      >
        {/* <ViewAddMember dataOrigin={dataOri} setSelectUser={setSelectUser} /> */}
        <AddTrainee
          selectUser={data}
          setSelectUser={setSelectUser}
          callBack={() => {
            setShowModal(false);
          }}
        />
      </Modal>
      <Modal
        open={showModalFeedBack}
        onCancel={() => {
          setShowModalFeedBack(false);
        }}
        footer={null}
        destroyOnClose
      >
        <div style={{ display: 'flex', gap: 16, flexDirection: 'column' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              fontWeight: 'bold',
              fontSize: 24,
              marginBottom: 8,
            }}
          >
            Employee Feedback
          </div>
          <div>How do you feel about this course</div>
          <Radio.Group disabled={id} value={getValues(`users[${selectCurrent}].userFeedback`)}>
            <Radio value="very good">Very Good</Radio>
            <Radio value="good">Good</Radio>
            <Radio value="fair">Fair</Radio>
            <Radio value="poor">Poor</Radio>
            <Radio value="very poor">Very Poor</Radio>
          </Radio.Group>
          <Col>
            <Row align="middle" style={{ marginBottom: 8 }}>
              <Col span={12}>What did you like most about this course?</Col>
              <Col span={12}>
                <InputField
                  disabled={id}
                  control={control}
                  name={`users[${selectCurrent}].userFeedbackLike`}
                />
              </Col>
            </Row>
            <Row align="middle">
              <Col span={12}>What should be improved?</Col>
              <Col span={12}>
                <InputField
                  disabled={id}
                  control={control}
                  name={`users[${selectCurrent}].userFeedbackImproved`}
                />
              </Col>
            </Row>
          </Col>
        </div>
      </Modal>
    </Card>
  );
};

export default EmployeeLists;

import { DatePickerField, InputField, SelectField } from 'components/FormFields';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { YYYY_MM_DD } from 'constants/commons';
import { Button, Input, Select } from 'antd';
import moment from 'moment';
import CustomButton from 'styles/buttonStyled';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { getEmployeeSearch } from 'features/insurances/insuranceAction';
import { useEffect, useState } from 'react';
import { selectInsurance } from 'features/insurances/insuranceSlice';
import { useDebouncedCallback } from 'use-debounce';

const validationSchema = Yup.object().shape({
  insurancePremium: Yup.string(),
  startDate: Yup.string(),
  endDate: Yup.string(),
  registrationType: Yup.string(),
  employeeGroup: Yup.string().oneOf(['employee', 'parent']),
});

const mockVal = (data: any, repeat = 1) => ({
  value: data?.fullName,
  key: data?.id,
});

export function Registration({ onSubmit, item }) {
  const { t } = useTranslation(['action', 'payroll']);
  const dispatch = useAppDispatch();
  const insurance = useAppSelector(selectInsurance);
  const checkUserId = insurance?.dataCheckUserID?.data;

  const [options, setOptions] = useState([]);
  const [id_user, setIDUser] = useState('');

  const debounced = useDebouncedCallback((value) => {
    onSearch(value);
  }, 350);

  const onSearch = (searchText: any) => {
    dispatch(
      getEmployeeSearch({
        keyword: searchText,
        status: 'approved',
      }),
    );
  };

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      insurancePremium: '1400000',
      startDate: '',
      endDate: '',
      registrationType: 'increase',
      employeeGroup: 'employee',
    },
  });

  useEffect(() => {
    dispatch(getEmployeeSearch({ keyword: '', status: 'approved' }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFiltersSubmit = (formValues: any) => {
    onSubmit &&
      onSubmit({
        ...formValues,
        startDate: formValues?.startDate ? moment(formValues?.startDate).format(YYYY_MM_DD) : '',
        endDate: formValues?.endDate ? moment(formValues?.endDate).format(YYYY_MM_DD) : '',
        userId: id_user,
      });
  };

  useEffect(() => {
    if (Array.isArray(checkUserId?.result)) {
      let obj: any = [];
      obj = checkUserId?.result.map((item: any) => ({
        ...mockVal(item),
      }));
      setOptions(obj || []);
    }
  }, [checkUserId]);

  const onSelect = (data: any, obj: any) => {
    if (Array.isArray(checkUserId?.result)) {
      // eslint-disable-next-line array-callback-return
      checkUserId?.result.map((item: any) => {
        if (item.id === obj?.value) {
          setIDUser(item?.id);
        }
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(handleFiltersSubmit)}>
      <StyledContainer>
        {!item && (
          <>
            <label htmlFor="userId">User</label>
            <Select
              showSearch
              allowClear
              onClear={() => {
                dispatch(
                  getEmployeeSearch({
                    keyword: '',
                    status: 'approved',
                  }),
                );
                setIDUser('');
              }}
              filterOption={false}
              style={{
                width: '100%',
              }}
              value={id_user || undefined}
              onChange={onSelect}
              onSearch={(value: any) => debounced(value)}
              placeholder={t('name')}
            >
              {options?.map((item: any) => (
                <Select.Option value={item?.key}>{item?.value}</Select.Option>
              ))}
            </Select>
          </>
        )}

        <label>Employee group</label>
        <SelectField
          options={[
            { label: 'Employee', value: 'employee' },
            { label: 'Parent', value: 'parent' },
          ]}
          control={control}
          name="employeeGroup"
        ></SelectField>

        <label htmlFor="insurancePremium">Insurance Premium</label>
        <InputField name="insurancePremium" control={control} />

        <label htmlFor="startDate">Start Date</label>
        <DatePickerField
          style={{ width: '100%' }}
          picker="date"
          format={YYYY_MM_DD}
          name="startDate"
          control={control}
        />

        <label htmlFor="endDate">End Date</label>
        <DatePickerField
          style={{ width: '100%' }}
          picker="date"
          format={YYYY_MM_DD}
          name="endDate"
          control={control}
        />

        <label>Registration Type</label>
        <SelectField
          options={[
            { label: 'Increase', value: 'increase' },
            { label: 'Decrease', value: 'decrease' },
          ]}
          control={control}
          name="registrationType"
        ></SelectField>
        <FooterContainer>
          <CustomButton type="primary" htmlType="submit">
            {t('action:save')}
          </CustomButton>
        </FooterContainer>
      </StyledContainer>
    </form>
  );
}
const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const FooterContainer = styled.div`
  display: flex;
  gap: 16px;
  justify-content: end;
`;

import { RightOutlined } from '@ant-design/icons';
import { Badge, Skeleton } from 'antd';
import { useAppSelector } from 'app/hooks';
import EmployeeColumn from 'components/EmployeeColumn';
import ITVTable from 'components/ITVTable';
import { TIMEZONE_DEFAULT } from 'constants/commons';
import { actions, selectConfiguration } from 'features/configuration/configurationSlice';
import { selectEmployee, setCheckRow } from 'features/employee/employeeSlice';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getUser } from 'utils/auth';
import { Container } from './styled';

const Preview = (props: any) => {
  const { setShow } = props;
  const { t } = useTranslation(['leaveOff']);
  const dispatch = useDispatch();
  const { getUserIds } = actions;
  const dataStore = useAppSelector(selectConfiguration);
  const employee = useAppSelector(selectEmployee);
  const selectRowKey = employee.selectRowKey;
  const loading = dataStore.isLoading;
  const previewData = dataStore.previewData;
  const [dataPreview, setDataPreview] = useState<any>({});
  const [data, setData] = useState<any>([]);
  const [, setSelectedUser] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [sizePage, setSizePage] = useState(10);
  const timeZone = getUser().timeZone ? getUser().timeZone : TIMEZONE_DEFAULT;

  //Set default timezone in server
  moment.tz.setDefault(timeZone);

  useEffect(() => {
    if (previewData?.userDetails) {
      setData(
        previewData?.userDetails?.map((i: any) => ({
          ...i,
          userId: i?.previewUserLeaveOffChoose?.userId,
        })),
      );
      //select all from the start
      dispatch(
        setCheckRow(
          previewData?.userDetails?.map((i: any) => i?.previewUserLeaveOffChoose?.userId),
        ),
      );
    }
  }, [previewData, dispatch]);

  useEffect(() => {
    !selectRowKey.length && setShow(true);
    selectRowKey.length && setShow(false);
    //dispatch array of userId
    dispatch(getUserIds(selectRowKey));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectRowKey?.length, dispatch]);

  useEffect(() => {
    if (previewData) {
      setDataPreview(previewData);
    }
  }, [previewData]);

  const countRequestLeave = (array: any, name: string) => {
    return array?.filter((obj: any) => obj?.status === name).length;
  };

  let COLUMNS: any = [
    {
      title: t('insurance:is_code'),
      dataIndex: 'userId',
      key: 'userId',
      align: 'center',
      ellipsis: true,
      width: 50,
      render: function (text: any, record: any, index: any) {
        return <div>{record?.previewUserLeaveOffChoose?.employeeId}</div>;
      },
    },
    {
      title: t('insurance:Employee'),
      dataIndex: 'fullName',
      key: 'fullName',
      width: 120,
      ellipsis: true,
      render: function (text: any, record: any, index: any) {
        return (
          <EmployeeColumn
            avatar={record?.previewUserLeaveOffChoose?.fullName}
            fullName={record?.previewUserLeaveOffChoose?.fullName}
          />
        );
      },
    },
    {
      title: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <p style={{ marginBottom: '0px' }}>{dataPreview?.groupChoose?.nameType}</p>
          <p style={{ marginBottom: '0px' }}>{`${dataPreview?.groupChoose?.nameGroup}(${moment(
            dataPreview?.groupChoose?.startTime,
          ).format('YYYY/MM')}-${moment(dataPreview?.groupChoose?.endTime).format('YYYY/MM')})`}</p>
        </div>
      ),
      dataIndex: 'moveFrom',
      key: 'moveFrom',
      width: 200,
      ellipsis: true,
      render: function (text: any, record: any, index: any) {
        return (
          <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
            <div style={{ width: 150 }}>
              <h3 style={{ marginBottom: '0px' }}>{t('leaveOff:preview.before')}</h3>
              <p style={{ marginBottom: '0px' }}>{`${t('leaveOff:preview.remaining_leave_days')} ${
                record?.previewUserLeaveOffChoose?.beforeMove?.remain
                  ? record?.previewUserLeaveOffChoose?.beforeMove?.remain
                  : 0
              }`}</p>
              <p style={{ marginBottom: '0px' }}>{`${t(
                'leaveOff:preview.leave_application_number',
              )}`}</p>
              <div>
                <Badge
                  color="#F59E0B"
                  text={`${t('leaveOff:preview.pending')} ${
                    countRequestLeave(
                      record?.previewUserLeaveOffChoose?.beforeMove?.requestLeave,
                      'pending',
                    ) || 0
                  }`}
                />
              </div>
              <div>
                <Badge
                  color="#10B981"
                  text={`${t('leaveOff:preview.approved')} ${
                    countRequestLeave(
                      record?.previewUserLeaveOffChoose?.beforeMove?.requestLeave,
                      'approved',
                    ) || 0
                  }`}
                />
              </div>
              <div>
                <Badge
                  color="#EF4444"
                  text={`${t('leaveOff:preview.rejected')} ${
                    countRequestLeave(
                      record?.previewUserLeaveOffChoose?.beforeMove?.requestLeave,
                      'rejected',
                    ) || 0
                  }`}
                />
              </div>
            </div>
            <RightOutlined />
            <div style={{ width: 150 }}>
              <h3 style={{ marginBottom: '0px' }}>{t('leaveOff:preview.after')}</h3>
              <p style={{ marginBottom: '0px' }}>{`${t('leaveOff:preview.remaining_leave_days')} ${
                record?.previewUserLeaveOffChoose?.afterMove?.remain
                  ? record?.previewUserLeaveOffChoose?.afterMove?.remain
                  : 0
              }`}</p>
              <p style={{ marginBottom: '0px' }}>{`${t(
                'leaveOff:preview.leave_application_number',
              )}`}</p>
              <div>
                <Badge
                  color="#F59E0B"
                  text={`${t('leaveOff:preview.pending')} ${
                    countRequestLeave(
                      record?.previewUserLeaveOffChoose?.afterMove?.requestLeave,
                      'pending',
                    ) || 0
                  }`}
                />
              </div>
              <div>
                <Badge
                  color="#10B981"
                  text={`${t('leaveOff:preview.approved')} ${
                    countRequestLeave(
                      record?.previewUserLeaveOffChoose?.afterMove?.requestLeave,
                      'approved',
                    ) || 0
                  }`}
                />
              </div>
              <div>
                <Badge
                  color="#EF4444"
                  text={`${t('leaveOff:preview.rejected')} ${
                    countRequestLeave(
                      record?.previewUserLeaveOffChoose?.afterMove?.requestLeave,
                      'rejected',
                    ) || 0
                  }`}
                />
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <p style={{ marginBottom: '0px' }}>{dataPreview?.groupMove?.nameType}</p>
          <p style={{ marginBottom: '0px' }}>{`${dataPreview?.groupMove?.nameGroup}(${moment(
            dataPreview?.groupMove?.startTime,
          ).format('YYYY/MM')}-${moment(dataPreview?.groupMove?.endTime).format('YYYY/MM')})`}</p>
        </div>
      ),
      dataIndex: 'moveTo',
      key: 'moveTo',
      width: 200,
      ellipsis: true,
      render: function (text: any, record: any, index: any) {
        return (
          <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
            <div style={{ width: 150 }}>
              <h3 style={{ marginBottom: '0px' }}>{t('leaveOff:preview.before')}</h3>
              <p style={{ marginBottom: '0px' }}>{`${t('leaveOff:preview.remaining_leave_days')} ${
                record?.previewUserLeaveOffMove?.beforeMove?.remain
                  ? record?.previewUserLeaveOffMove?.beforeMove?.remain
                  : 0
              }`}</p>
              <p style={{ marginBottom: '0px' }}>{`${t(
                'leaveOff:preview.leave_application_number',
              )}`}</p>
              <div>
                <Badge
                  color="#F59E0B"
                  text={`${t('leaveOff:preview.pending')} ${
                    countRequestLeave(
                      record?.previewUserLeaveOffMove?.beforeMove?.requestLeave,
                      'pending',
                    ) || 0
                  }`}
                />
              </div>
              <div>
                <Badge
                  color="#10B981"
                  text={`${t('leaveOff:preview.approved')} ${
                    countRequestLeave(
                      record?.previewUserLeaveOffMove?.beforeMove?.requestLeave,
                      'approved',
                    ) || 0
                  }`}
                />
              </div>
              <div>
                <Badge
                  color="#EF4444"
                  text={`${t('leaveOff:preview.rejected')} ${
                    countRequestLeave(
                      record?.previewUserLeaveOffMove?.beforeMove?.requestLeave,
                      'rejected',
                    ) || 0
                  }`}
                />
              </div>
            </div>
            <RightOutlined />
            <div style={{ width: 150 }}>
              <h3 style={{ marginBottom: '0px' }}>{t('leaveOff:preview.after')}</h3>
              <p style={{ marginBottom: '0px' }}>{`${t('leaveOff:preview.remaining_leave_days')} ${
                record?.previewUserLeaveOffMove?.afterMove?.remain
                  ? record?.previewUserLeaveOffMove?.afterMove?.remain
                  : 0
              }`}</p>
              <p style={{ marginBottom: '0px' }}>{`${t(
                'leaveOff:preview.leave_application_number',
              )}`}</p>
              <div>
                <Badge
                  color="#F59E0B"
                  text={`${t('leaveOff:preview.pending')} ${
                    countRequestLeave(
                      record?.previewUserLeaveOffMove?.afterMove?.requestLeave,
                      'pending',
                    ) || 0
                  }`}
                />
              </div>
              <div>
                <Badge
                  color="#10B981"
                  text={`${t('leaveOff:preview.approved')} ${
                    countRequestLeave(
                      record?.previewUserLeaveOffMove?.afterMove?.requestLeave,
                      'approved',
                    ) || 0
                  }`}
                />
              </div>
              <div>
                <Badge
                  color="#EF4444"
                  text={`${t('leaveOff:preview.rejected')} ${
                    countRequestLeave(
                      record?.previewUserLeaveOffMove?.afterMove?.requestLeave,
                      'rejected',
                    ) || 0
                  }`}
                />
              </div>
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <Container>
      {loading ? (
        <Skeleton />
      ) : (
        <>
          <div style={{ margin: '0 15%' }}>
            <p style={{ fontWeight: 'bold', fontSize: 20 }}>{t('leaveOff:preview.overview')}</p>
            <p>
              {`• ${t('leaveOff:preview.total_employee_effect')} `}
              <span style={{ fontWeight: 'bold', fontSize: 20, color: 'red' }}>
                {+dataPreview?.totalUser < 10
                  ? `0${dataPreview?.totalUser}`
                  : dataPreview?.totalUser}
              </span>
            </p>
            <p>
              {`• ${t('leaveOff:preview.system_will_move')}`}
              <span style={{ fontWeight: 'bold', fontSize: 15 }}>
                {' '}
                {dataPreview?.groupChoose?.nameGroup}{' '}
              </span>
              <span>{t('leaveOff:preview.to_policy')}</span>
              <span style={{ fontWeight: 'bold', fontSize: 15 }}>
                {' '}
                {dataPreview?.groupMove?.nameGroup}{' '}
              </span>
              <span>{t('leaveOff:preview.in_all_employees')}</span>
            </p>
          </div>
          <ITVTable
            columns={COLUMNS}
            data={data}
            width={978}
            limit={sizePage}
            setLimit={setSizePage}
            offset={currentPage}
            setOffset={setCurrentPage}
            totalResult={data ? data.length : 0}
            setSelectedUser={setSelectedUser}
            selectionType={'checkbox'}
            rowKey={['userId']}
          />
        </>
      )}
    </Container>
  );
};

export default Preview;

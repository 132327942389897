import { Checkbox, Col, DatePicker, Form, Modal, Popconfirm, Row, Select } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { selectFormatDate } from 'app/commonRedux/appSlice';
import { useAppSelector } from 'app/hooks';
import ITVTable from 'components/ITVTable';
import { getFindOnePlanUser } from 'features/employee/employeeAction';
import { selectEmployee } from 'features/employee/employeeSlice';
import { formatCurrencyUnit, labelUnit } from 'features/insurances/constants/common';
import CustomButton from 'styles/buttonStyled';
import { CustomInputNumber } from './AdvancesTable';

const { Option } = Select;

enum MODE {
  VIEW = 'VIEW',
  ADD = 'ADD',
  UPDATE = 'UPDATE',
}

const ViewPlan = ({
  mode,
  recordItem,
  onSaveEdit,
  dataTablePlan,
  setDataTablePlan,
  dataTablePlanOri,
  setDataTablePlanOri,
  data,
  setData,
  setMode,
}: any) => {
  const [form] = Form.useForm();
  const { t } = useTranslation([
    'validation',
    'masterConfig',
    'insurance',
    'common',
    'employee',
    'overtime',
  ]);
  const dispatch = useDispatch();
  const formatDate = useAppSelector(selectFormatDate);
  const [sizePage, setSizePage] = useState(12);
  const [currentPage, setCurrentPage] = useState(1);

  const [errMsg, setErrMsg] = useState<any>('');
  const [valueRemain, setValueRemain] = useState();
  const employee = useAppSelector<any>(selectEmployee);

  const dataFindOnePlanUser = employee?.dataFindOnePlanUser?.data?.result;
  const MINIMUM_STEP = 100000;

  useEffect(() => {
    dispatch(getFindOnePlanUser({ id: recordItem?.id }));
  }, [recordItem, dispatch]);

  const handleFormChange = () => {};
  useEffect(() => {
    if (mode === MODE.UPDATE && recordItem) {
      form.setFieldsValue({
        name: recordItem?.name,
        description: recordItem?.description,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordItem]);

  function foo(l: number[], target: number) {
    const roundedList = l.map((x) => Math.round(x));
    const sumRoundedList = roundedList.reduce((acc, x) => acc + x, 0);
    const off = target - sumRoundedList;

    const sortedList = roundedList.sort((a, b) => a - b);

    return sortedList.map((x, i) => Math.round(x) + (off > i) - (i >= l.length + off));
  }

  function getApportionStep(amount: any) {
    let step = MINIMUM_STEP;
    if (amount < step) {
      return amount;
    }
    if (amount >= 100000 && amount <= 999999) {
      step = 1000;
    } else if (amount >= 1000000 && amount <= 9999999) {
      step = 10000;
    } else if (amount >= 10000000 && amount <= 36000000) {
      step = 100000;
    } else if (amount >= 36000001 && amount <= 99999999) {
      step = 1000000;
    } else if (amount >= 100000000 && amount <= 999999999) {
      step = 1000000;
    } else if (amount >= 1000000000 && amount <= 9999999999) {
      step = 10000000;
    }
    return step;
  }
  function recursiveApportion(remainingAmount: any, amountPerMonths: any) {
    if (!remainingAmount) return amountPerMonths;
    let step = getApportionStep(remainingAmount);
    const updated = amountPerMonths?.map((item) => {
      if (remainingAmount > 0 && remainingAmount > step) {
        item.amount += step;
        remainingAmount -= step;
      } else {
        item.amount += remainingAmount;
        remainingAmount -= remainingAmount;
      }
      return item;
    });
    const rs = recursiveApportion(remainingAmount >= 0 ? remainingAmount : 0, updated);
    return rs;
  }
  const handleCheckPaidStatus = (e, record) => {
    if (e.target.checked) {
      let temp1: any = dataTablePlan?.map((obj: any, objIndex: any) => {
        if (obj?.id === record?.id) {
          return {
            ...obj,
            paidStatus: e.target.checked,
            indexDisable: record?.idFake + 1,
          };
        }
        return { ...obj, indexDisable: record?.idFake + 1 };
      });
      let temp2: any = dataTablePlanOri?.map((obj: any, objIndex: any) =>
        obj?.id === record?.id
          ? { ...obj, paidStatus: e.target.checked }
          : { ...obj, paidStatus: false },
      );
      setDataTablePlan(temp1);
      setDataTablePlanOri(temp2);
    } else {
      let temp1: any = dataTablePlan?.map((obj: any, objIndex: any) => {
        if (obj?.id >= record?.id) {
          return { ...obj, paidStatus: false, indexDisable: record?.id };
        }
        return { ...obj, indexDisable: record?.id };
      });
      let temp2: any = dataTablePlanOri?.map((obj: any, objIndex: any) =>
        obj?.id === record?.id
          ? { ...obj, paidStatus: e.target.checked }
          : { ...obj, paidStatus: false },
      );
      setDataTablePlan(temp1);
      setDataTablePlanOri(temp2);
    }
  };
  const handleOnChangeAmount = (value, trueIndex) => {
    let temp1: any = dataTablePlan?.map((obj: any, objIndex: any) =>
      objIndex === trueIndex ? { ...obj, amount: Number(value) ?? 0 } : obj,
    );

    let arrUpdateMoneyAfter = [];
    let arrUpdateMoneyBefore = [];
    let arrUpdateMoney = [];
    for (let index = 0; index < temp1.length; index++) {
      if (index > trueIndex) {
        arrUpdateMoneyAfter.push(temp1[index]);
      } else {
        arrUpdateMoneyBefore.push(temp1[index]);
      }
    }
    const moneyBefore =
      recordItem?.amount -
      arrUpdateMoneyBefore?.reduce((acc: any, curr: any) => {
        return acc + curr.amount;
      }, 0);

    let arrUpdateMoneyAfter1 = arrUpdateMoneyAfter?.map((item, idx) => {
      return {
        ...item,
        amount:
          Math.floor(moneyBefore / arrUpdateMoneyAfter.length) > 0
            ? Math.floor(moneyBefore / arrUpdateMoneyAfter.length)
            : 0,
      };
    });

    let arrayAmount = arrUpdateMoneyAfter1?.map((item, idx) => {
      return item.amount;
    });

    let arrUpdateMoneyAfter2 = foo(arrayAmount, moneyBefore)?.map((item, idx) => {
      return {
        id: arrUpdateMoneyAfter1[idx]?.id,
        month: arrUpdateMoneyAfter1[idx]?.month,
        amount: Math.floor(item) >= 0 ? Math.floor(item) : 0,
        paidStatus: arrUpdateMoneyAfter1[idx]?.paidStatus,
      };
    });

    arrUpdateMoney = [...arrUpdateMoneyBefore, ...arrUpdateMoneyAfter2];

    setDataTablePlan(arrUpdateMoney);
  };
  const COLUMNS: any = [
    {
      title: t('employee:advance_information_fields.month'),
      dataIndex: 'month',
      key: 'month',
      width: 150,
      minWidth: 150,
      render: function (text: any, record: any, index: any) {
        return record?.month;
      },
    },

    {
      title: t('employee:advance_information_fields.amount'),
      dataIndex: 'amount',
      key: 'amount',
      // width: 200,
      render: function (text: any, record: any, index: any) {
        const trueIndex = index + sizePage * (currentPage - 1);
        return (
          <CustomInputNumber
            disabled={record?.paidStatus}
            max={10000000000}
            min={0}
            style={{ width: '100%' }}
            onChange={(value: any) => {
              handleOnChangeAmount(value, trueIndex);
            }}
            value={record?.amount}
            formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value: any) => (value ? value.replace(/\$\s?|(,*)/g, '') : 0)}
          />
        );
      },
    },
    {
      title: t('employee:advance_information_fields.paid'),
      dataIndex: 'paidStatus',
      key: 'paidStatus',
      width: 150,
      minWidth: 150,
      align: 'center',
      render: function (text: any, record: any) {
        return (
          <Checkbox
            checked={record?.paidStatus}
            onChange={(e: any) => {
              handleCheckPaidStatus(e, record);
            }}
          ></Checkbox>
        );
      },
    },
  ];

  useEffect(() => {
    for (let index = 0; index < data.length; index++) {
      if (
        data[index]?.status === 'unsave' &&
        data[index]?.key === recordItem?.key &&
        data[index]?.term === dataFindOnePlanUser?.length
      ) {
        if (data[index]?.editedPlan) {
          return setDataTablePlan(
            data[index]?.paymentPlan?.map((item: any, index: any) => ({
              ...item,
              isLock: item?.isLock === 'isLocked' && item?.paidStatus ? 'isLocked' : '',
              idFake: item?.id,
              amount: Number(item?.amount),
            })),
          );
        } else {
          let dataFindOnePlanUserUPDATE = dataFindOnePlanUser?.map((item, idx) => {
            return { ...item, key: idx, amount: Number(item?.amount) };
          });

          let monthRecord = Number(dataFindOnePlanUser?.[0]?.month?.substring(0, 2));

          let yearRecord = Number(dataFindOnePlanUser?.[0]?.month?.substring(3));

          let startDate = monthRecord && yearRecord && new Date(`${monthRecord}/01/${yearRecord}`);

          var arr = Array.from(new Array(recordItem?.term), (x, i) => i + 1);
          let monthUpdate = arr?.map((item, idx) => {
            return {
              month: moment(startDate && startDate?.toISOString())
                .add(idx, 'months')
                .format('MM/YYYY'),
              amount: 0,
              paidStatus: false,
              indexDisable: 0,
              key: idx,
            };
          });

          const resultArrayTablePlan = dataFindOnePlanUserUPDATE && [
            ...dataFindOnePlanUserUPDATE,
            ...monthUpdate?.filter(
              (el1) => !dataFindOnePlanUserUPDATE?.some((el2) => el2.key === el1.key),
            ),
          ];

          let resultIsPaiArrayTablePlan = resultArrayTablePlan?.filter((item) => item.paidStatus);

          let resultNotIsPaiArrayTablePlan = resultArrayTablePlan?.filter(
            (item) => !item.paidStatus,
          );
          let resultNotIsPaiArrayTablePlan1 = resultNotIsPaiArrayTablePlan?.map((item, idx) => {
            return {
              ...item,
              amount: 0,
            };
          });

          let moneyNotIsPaid =
            resultNotIsPaiArrayTablePlan &&
            resultNotIsPaiArrayTablePlan?.reduce((acc: any, curr: any) => {
              return acc + curr.amount;
            }, 0);

          let arrNotIsPaid = recursiveApportion(
            Number(moneyNotIsPaid),
            resultNotIsPaiArrayTablePlan1,
          );

          const arrTablePlanFinal = arrNotIsPaid && [...resultIsPaiArrayTablePlan, ...arrNotIsPaid];

          let arrayIdFindOnePlanUser: any = dataFindOnePlanUser?.map((item, idx) => {
            return item?.id;
          });
          let idMax = arrayIdFindOnePlanUser && Math.min(...arrayIdFindOnePlanUser);
          let arrTablePlanFinal1 = arrTablePlanFinal?.map((item, idx) => {
            return { ...item, idFake: idMax++ };
          });

          let idCheckDisable = undefined;
          setDataTablePlan(
            arrTablePlanFinal1?.map((item: any, index: any) => {
              if (item?.paidStatus) {
                idCheckDisable = item?.idFake;
              }
              return {
                ...item,
                isLock: item?.paidStatus ? 'isLocked' : '',
                indexDisable: idCheckDisable + 1,
                amount: Number(item?.amount),
              };
            }),
          );
        }
      } else if (
        data[index]?.status === 'unsave' &&
        data[index]?.key === recordItem?.key &&
        data[index]?.term !== dataFindOnePlanUser?.length
      ) {
        if (data[index]?.editedPlan) {
          return setDataTablePlan(
            data[index]?.paymentPlan?.map((item: any, index: any) => ({
              ...item,
              isLock: item?.isLock === 'isLocked' && item?.paidStatus ? 'isLocked' : '',
              idFake: item?.id,
              amount: Number(item?.amount),
            })),
          );
        } else {
          let dataFindOnePlanUserUPDATE = dataFindOnePlanUser?.map((item, idx) => {
            return { ...item, key: idx, amount: Number(item?.amount) };
          });

          let monthRecord = Number(dataFindOnePlanUser?.[0]?.month?.substring(0, 2));

          let yearRecord = Number(dataFindOnePlanUser?.[0]?.month?.substring(3));

          let startDate = monthRecord && yearRecord && new Date(`${monthRecord}/01/${yearRecord}`);

          let monthUpdate = arr?.map((item, idx) => {
            return {
              month: moment(startDate && startDate?.toISOString())
                .add(idx, 'months')
                .format('MM/YYYY'),
              amount: 0,
              paidStatus: false,
              indexDisable: 0,
              key: idx,
            };
          });

          const resultArrayTablePlan = dataFindOnePlanUserUPDATE && [
            ...dataFindOnePlanUserUPDATE,
            ...monthUpdate?.filter(
              (el1) => !dataFindOnePlanUserUPDATE?.some((el2) => el2.key === el1.key),
            ),
          ];

          let resultIsPaiArrayTablePlan = resultArrayTablePlan?.filter((item) => item.paidStatus);

          let resultNotIsPaiArrayTablePlan = resultArrayTablePlan?.filter(
            (item) => !item.paidStatus,
          );
          let resultNotIsPaiArrayTablePlan1 = resultNotIsPaiArrayTablePlan?.map((item, idx) => {
            return {
              ...item,
              amount: 0,
            };
          });

          let moneyNotIsPaid =
            resultNotIsPaiArrayTablePlan &&
            resultNotIsPaiArrayTablePlan?.reduce((acc: any, curr: any) => {
              return acc + curr.amount;
            }, 0);

          let arrNotIsPaid = recursiveApportion(
            Number(moneyNotIsPaid),
            resultNotIsPaiArrayTablePlan1,
          );

          const arrTablePlanFinal = arrNotIsPaid && [...resultIsPaiArrayTablePlan, ...arrNotIsPaid];

          let arrayIdFindOnePlanUser: any = dataFindOnePlanUser?.map((item, idx) => {
            return item?.id;
          });
          let idMax = arrayIdFindOnePlanUser && Math.min(...arrayIdFindOnePlanUser);
          let arrTablePlanFinal1 = arrTablePlanFinal?.map((item, idx) => {
            return { ...item, idFake: idMax++ };
          });

          let idCheckDisable = undefined;
          setDataTablePlan(
            arrTablePlanFinal1?.map((item: any, index: any) => {
              if (item?.paidStatus) {
                idCheckDisable = item?.idFake;
              }
              return {
                ...item,
                isLock: item?.paidStatus ? 'isLocked' : '',
                indexDisable: idCheckDisable + 1,
                amount: Number(item?.amount),
              };
            }),
          );
        }
      } else if (data[index]?.status !== 'unsave' && data[index]?.key === recordItem?.key) {
        let idCheckDisable = undefined;
        setDataTablePlan(
          dataFindOnePlanUser?.map((item: any, index: any) => {
            if (item?.paidStatus) {
              idCheckDisable = item?.idFake;
            }
            return {
              ...item,
              isLock: item?.paidStatus ? 'isLocked' : '',
              indexDisable: idCheckDisable + 1,
              amount: Number(item?.amount),
            };
          }),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFindOnePlanUser]);

  useEffect(() => {
    let filterIsPaid = dataTablePlan?.filter((item, idx) => {
      return !item.paidStatus;
    });

    const moneyIsPaid = filterIsPaid?.reduce((acc: any, curr: any) => {
      return acc + Number(curr.amount);
    }, 0);
    setValueRemain(moneyIsPaid);
  }, [dataTablePlan]);

  const onFinish = () => {
    if (mode === MODE.UPDATE) {
      const moneyDataPlan = dataTablePlan?.reduce((acc: any, curr: any) => {
        return acc + curr.amount;
      }, 0);

      if (moneyDataPlan === recordItem?.amount) {
        onSaveEdit(dataTablePlan, recordItem?.key);
      } else {
        setErrMsg([{ message: 'Your payment plan does not match with your Advance Amount' }]);
      }
    }
  };
  const handleStartMonth = (value: any) => {
    // var arr = Array.from(new Array(recordItem?.term), (x, i) => i + 1);
    let month = dataTablePlan?.map((item: any, idx: number) => {
      return {
        ...item,
        month: moment(value).add(idx, 'months').format('MM/YYYY'),
      };
    });
    setDataTablePlan(month);
  };

  const handleCancelPlan = () => {
    setMode('');
  };

  const monthFormat = 'MM/YYYY';
  const [startMonth, setStartMonth] = useState();
  useEffect(() => {
    let sortDataPlan =
      recordItem &&
      [...recordItem?.paymentPlan]?.sort((a, b) => {
        return a?.id - b?.id;
      });

    setStartMonth(sortDataPlan.length > 0 ? sortDataPlan?.[0]?.month : dataTablePlan?.[0]?.month);
  }, [recordItem, dataTablePlan]);

  return (
    <Modal
      visible={mode === MODE.UPDATE}
      title={`Payment Plan`}
      okText={t('timesheet:Save')}
      cancelText={t('timesheet:Cancel')}
      width={550}
      maskClosable={false}
      onCancel={handleCancelPlan}
      onOk={() => {}}
      centered
      footer={null}
    >
      <Form form={form} onFieldsChange={handleFormChange} layout="vertical">
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Row gutter={[16, 16]}>
              <ColLeft span={24}>{t('employee:advance_information_fields.effective_date')}</ColLeft>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={24}>{moment(recordItem?.effectiveDate).format(formatDate)}</Col>
            </Row>
          </Col>
          <Col span={8}>
            <Row gutter={[16, 16]}>
              <ColLeft span={24}>{t('employee:advance_information_fields.type_advance')}</ColLeft>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={24}>{recordItem?.advanceConfigurationHistory?.name}</Col>
            </Row>
          </Col>
          <Col span={8}>
            <Row gutter={[16, 16]}>
              <ColLeft span={24}>{t('employee:advance_information_fields.term')}</ColLeft>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={24}>{recordItem?.term} months</Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={16}>
            <Row gutter={[16, 16]}>
              <ColLeft span={24}>{t('employee:advance_information_fields.advance_amount')}</ColLeft>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={24}>{formatCurrencyUnit(recordItem?.amount, labelUnit)}</Col>
            </Row>
          </Col>
          <Col span={8}>
            <Row gutter={[16, 16]}>
              <ColLeft span={24}>{t('employee:advance_information_fields.remaining')}</ColLeft>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={24}>{formatCurrencyUnit(valueRemain, labelUnit)}</Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={16}>
            <Form.Item
              name={'start_month'}
              label={
                <span style={{ fontWeight: 700 }}>
                  {' '}
                  {t('employee:advance_information_fields.startMonth')}
                </span>
              }
              rules={[
                {
                  required: true,
                  message: t('HRM_VAD_REQUIRED', { field: `Start Month` }),
                },
              ]}
            >
              {startMonth && (
                <CustomDatePicker
                  disabled={recordItem?.paidAmount !== 0}
                  style={{ width: '35%' }}
                  format={monthFormat}
                  picker="month"
                  onChange={handleStartMonth}
                  disabledDate={(current) => {
                    return current && current < moment(recordItem?.effectiveDate).add(0, 'month');
                  }}
                  defaultValue={recordItem && moment(startMonth, monthFormat)}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <div>
              <span>{t('employee:advance_information_fields.currency_unit')}: </span>
              <Select defaultValue="VND" style={{ width: 80 }}>
                <Option value="vnd">VND</Option>
              </Select>
            </div>
          </Col>
        </Row>
        <div>
          <ITVTable
            columns={COLUMNS}
            data={dataTablePlan}
            isRowSelect={false}
            setOffset={setCurrentPage}
            offset={currentPage}
            setLimit={setSizePage}
            limit={sizePage}
            width={450}
            height={350}
          />
        </div>
        {errMsg && (
          <div style={{ color: 'red', height: 24, overflowY: 'scroll' }}>
            {errMsg?.flat(1)?.map((item: any, i: any, arr: any) => {
              let divider = i < arr.length - 1 && <div></div>;
              return (
                <span key={i}>
                  <div>{item?.message}</div>
                  {divider}
                </span>
              );
            })}
          </div>
        )}
        {recordItem?.status !== true && (
          <div
            style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: '2%' }}
          >
            <Popconfirm
              title={`Are you sure`}
              onConfirm={() => {
                onFinish();
              }}
              okText={`${t('insurance:Yes')}`}
              cancelText={`${t('insurance:no')}`}
            >
              <CustomButton>Confirm</CustomButton>
            </Popconfirm>
          </div>
        )}
      </Form>
    </Modal>
  );
};

export default ViewPlan;

export const ColCenter = styled(Col)`
  text-align: center;
  background: #fea481;
  color: white;
  font-weight: 700;
`;
export const ColLeft = styled(Col)`
  text-align: left;
  font-weight: 700;
  margin-top: 5px;
`;
export const CustomDatePicker = styled(DatePicker)`
  .ant-picker-clear {
    display: none;
  }
`;

import debounce from 'lodash/debounce';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { SearchIcon } from 'assets/icons';
import { InputField, SelectField } from 'components/FormFields';
import { useLineCategoryList } from 'hooks';
import { AdjustmentSalaryPayload } from 'types';

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

interface AdjustmentSalaryFiltersProps {
  initialValues?: Partial<AdjustmentSalaryPayload>;
  onSubmit?: (payload: AdjustmentSalaryPayload) => void;
}

export function AdjustmentSalaryFilters({ initialValues, onSubmit }: AdjustmentSalaryFiltersProps) {
  const { t } = useTranslation(['employee', 'payroll', 'action']);
  const { control, handleSubmit } = useForm<AdjustmentSalaryPayload>({
    defaultValues: {
      search: '',
      ...initialValues,
    },
  });

  const { data: lineCategoryList, isLoading } = useLineCategoryList();
  const lineCategoryOptions = lineCategoryList.map((x) => ({
    label: x.name,
    value: x.projectCode,
  }));

  const handleFiltersSubmit = (formValues: AdjustmentSalaryPayload) => {
    onSubmit?.(formValues);
  };

  const debounceSearchChange = debounce(handleSubmit(handleFiltersSubmit), 350);

  return (
    <form onSubmit={handleSubmit(handleFiltersSubmit)}>
      <StyledContainer>
        <InputField
          name="search"
          control={control}
          placeholder={t('action:search')}
          prefix={<SearchIcon />}
          onChange={() => debounceSearchChange()}
        />

        <SelectField
          name="line"
          placeholder={t('payroll:contract_salary.line_category_placeholder')}
          control={control}
          options={lineCategoryOptions}
          showSearch
          allowClear
          loading={isLoading}
          onChange={handleSubmit(handleFiltersSubmit)}
        />
      </StyledContainer>
    </form>
  );
}

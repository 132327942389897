import * as yup from 'yup';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

export const useEmployeeContractSchema = () => {
  const { t } = useTranslation(['employeeContract']);

  const schema = yup.object().shape({
    contractCode: yup.string().required(t('add_edit_contract_modal.contract_code_rule')),
    contractType: yup.string().required(t('add_edit_contract_modal.contract_type_rule')),
    file: yup
      .mixed()
      .nullable()
      .test('accept-file', t('add_edit_contract_modal.require_file_rule'), (value: any) => {
        if (!value) return true;

        const ACCEPT_FILE_TYPES = ['application/pdf'];
        const isAcceptFile = Boolean(
          value?.every((file: File) => ACCEPT_FILE_TYPES.includes(file.type)),
        );

        return isAcceptFile;
      })
      .test('test-size', t('add_edit_contract_modal.max-size_file_rule'), (value: any) => {
        const fileSize =
          value?.reduce((size: number, currentFile: File) => {
            return size + currentFile.size;
          }, 0) || 0;
        const MB_TO_BYTES = 1024 * 1024;
        const MAX_SIZE = 10 * MB_TO_BYTES; // 10MB

        return fileSize <= MAX_SIZE;
      }),
    startDate: yup
      .date()
      .nullable()
      .test('test-contract-start-date', function (startDate, context) {
        if (startDate) {
          const { from } = this;
          const formValue = from?.[0].value;
          const endDate: Date = formValue.endDate;

          if (endDate && moment(startDate).isAfter(endDate)) {
            return context.createError({
              message: t('add_edit_contract_modal.test_contract_start_date_rule'),
            });
          }

          return true;
        }

        return context.createError({
          message: t('add_edit_contract_modal.require_contract_start_date_rule'),
        });
      }),
    endDate: yup
      .date()
      .nullable()
      .test('test-contract-end-date', function (endDate, context) {
        if (endDate) {
          const { from } = this;
          const formValue = from?.[0].value;
          const startDate: Date = formValue.startDate;

          if (moment(endDate).isBefore(startDate)) {
            return context.createError({
              message: t('add_edit_contract_modal.test_contract_end_date_rule'),
            });
          }
          return true;
        }

        return true;
      }),
  });

  return schema;
};

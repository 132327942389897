import React from 'react';
import { CountryPhoneInputValue, ConfigProvider } from 'antd-country-phone-input';
import en from 'world_countries_lists/data/countries/en/world.json';
import 'antd/dist/antd.css';
import 'antd-country-phone-input/dist/index.css';

import ITVPhone from 'components/ITVPhone';
import { ContainerStyled, LabelStyled, InputContainerStyled } from './styled';

interface Props {
  label: string;
  value?: CountryPhoneInputValue;
  onChange?: (e: any) => void;
  placeholder?: string;
  isTopUp?: boolean;
}

function AuthInputPhone(props: Props) {
  const { label, value, onChange, placeholder, isTopUp = true } = props;
  const getFlag = (short: string) => {
    const data = require(`world_countries_lists/data/flags/24x24/${short.toLowerCase()}.png`);
    if (typeof data === 'string') {
      return data;
    }
    return data.default;
  };
  return (
    <ConfigProvider
      locale={en}
      areaMapper={(area) => {
        return {
          ...area,
          emoji: (
            <img
              alt="flag"
              style={{ width: 18, height: 18, verticalAlign: 'sub' }}
              src={getFlag(area.short)}
            />
          ),
        };
      }}
    >
      <ContainerStyled istopup={isTopUp}>
        <LabelStyled>{label}</LabelStyled>
        <InputContainerStyled>
          <ITVPhone
            isNoneBorder={true}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
          />
        </InputContainerStyled>
      </ContainerStyled>
    </ConfigProvider>
  );
}

export default AuthInputPhone;

import queryString from 'query-string';

import { SuccessResponse } from 'types';
import axiosClient from './axiosClient';
import { notification } from 'antd';

const dashboardEmployeeApi = {
  getTotalEmployeeDashboard: (params: { month: number; year: number }) => {
    const response = axiosClient.get(`/dashboard/total-employees?${queryString.stringify(params)}`);
    return response;
  },
  getNewEmployeeDashboard: (params: { month: number; year: number }) => {
    const response = axiosClient.get(
      `/dashboard/total-new-employees?${queryString.stringify(params)}`,
    );
    return response;
  },
  getResignedEmployeeDashboard: (params: { month: number; year: number }) => {
    const response = axiosClient.get(
      `/dashboard/total-resigned-employees?${queryString.stringify(params)}`,
    );
    return response;
  },
  getEmployeeSummaryChart(params: {
    year?: string;
    type: string;
    fromDate?: string;
    toDate?: string;
  }): Promise<SuccessResponse<any>> {
    return axiosClient.get(`/dashboard/employee-summary?${queryString.stringify(params)}`);
  },
  exportEmployeeSummaryChart(params: {
    year?: string;
    type: string;
    fromDate?: string;
    toDate?: string;
  }): Promise<SuccessResponse<any>> {
    return axiosClient.post(
      `/dashboard/employee-summary/export?${queryString.stringify(params)}`,
      {},
      {
        responseType: 'blob',
      },
    );
  },
  getUpComingOnboardEmployees(): Promise<SuccessResponse<any>> {
    return axiosClient.get(`/dashboard/upcoming-onboard-employees`);
  },
  exportUpComingOnboardEmployees(): Promise<SuccessResponse<any>> {
    return axiosClient.post(
      `/dashboard/upcoming-onboard-employees/export`,
      {},
      {
        responseType: 'blob',
      },
    );
  },
  getHeadcountLocation(params: { month: string; year: number }): Promise<SuccessResponse<any>> {
    return axiosClient.get(`/dashboard/head-count-location?${queryString.stringify(params)}`);
  },
  exportHeadcountLocation(params: { month: string; year: number }): Promise<SuccessResponse<any>> {
    return axiosClient.post(
      `/dashboard/head-count-location/export?${queryString.stringify(params)}`,
      {},
      {
        responseType: 'blob',
      },
    );
  },
  getHeadcountLine(params: {
    monthYear: string;
    locationId: string;
  }): Promise<SuccessResponse<any>> {
    return axiosClient
      .post(`/dashboard/headcount-by-line?${queryString.stringify(params)}`)
      .then((response: any) => {
        notification.destroy();
        return response;
      });
  },
  exportHeadcountLine(params: {
    monthYear: string;
    locationId: string;
  }): Promise<SuccessResponse<any>> {
    return axiosClient.post(
      `/dashboard/headcount-by-line/export?${queryString.stringify(params)}`,
      {},
      {
        responseType: 'blob',
      },
    );
  },
  getHeadcountContractType(params: {
    monthYear: string;
    locationId: string;
  }): Promise<SuccessResponse<any>> {
    return axiosClient
      .post(`/dashboard/headcount-by-contract-type?${queryString.stringify(params)}`)
      .then((response: any) => {
        notification.destroy();
        return response;
      });
  },
  exportHeadcountContractType(params: {
    monthYear: string;
    locationId: string;
  }): Promise<SuccessResponse<any>> {
    return axiosClient.post(
      `/dashboard/headcount-by-contract-type/export?${queryString.stringify(params)}`,
      {},
      {
        responseType: 'blob',
      },
    );
  },
  getHeadcountGender(params: {
    monthYear: string;
    locationId: string;
  }): Promise<SuccessResponse<any>> {
    return axiosClient
      .post(`/dashboard/headcount-by-gender?${queryString.stringify(params)}`)
      .then((response: any) => {
        notification.destroy();
        return response;
      });
  },
  exportHeadcountGender(params: {
    monthYear: string;
    locationId: string;
  }): Promise<SuccessResponse<any>> {
    return axiosClient.post(
      `/dashboard/headcount-by-gender/export?${queryString.stringify(params)}`,
      {},
      {
        responseType: 'blob',
      },
    );
  },
  getHeadcountWorkingTime(params: { locationId: string }): Promise<SuccessResponse<any>> {
    return axiosClient
      .post(`/dashboard/headcount-by-working-time?${queryString.stringify(params)}`)
      .then((response: any) => {
        notification.destroy();
        return response;
      });
  },
  exportHeadcountWorkingTime(params: { locationId: string }): Promise<SuccessResponse<any>> {
    return axiosClient.post(
      `/dashboard/headcount-by-working-time/export?${queryString.stringify(params)}`,
      {},
      {
        responseType: 'blob',
      },
    );
  },

  //headcountByPostion
  getDataHeadcountByPositionTable: (params: { period: any }) => {
    const response = axiosClient.get(
      `/dashboard/head-count-position-table?${queryString.stringify(params)}`,
    );
    return response;
  },
  exportHeadcountByPositionTable: (params: { period: any }) => {
    const response = axiosClient.post(
      `/dashboard/head-count-position-table/export?${queryString.stringify(params)}`,
      {},
      {
        responseType: 'blob',
      },
    );
    return response;
  },

  getDataHeadcountByPositionChart: (params: { period: any }) => {
    const response = axiosClient.get(
      `/dashboard/head-count-position-pie?${queryString.stringify(params)}`,
    );
    return response;
  },
  exportHeadcountByPositionChart: (params: { period: any }) => {
    const response = axiosClient.post(
      `/dashboard/head-count-position-pie/export?${queryString.stringify(params)}`,
      {},
      {
        responseType: 'blob',
      },
    );
    return response;
  },
};

export default dashboardEmployeeApi;

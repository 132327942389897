import { useAppSelector } from 'app/hooks';
import { selectShowMoney } from 'features/auth/authSlice';
import { useState, useEffect } from 'react';

export const useMaskValue = (value: any, condition?: Boolean) => {
  const isShowMoney = useAppSelector(selectShowMoney);
  const [displayValue, setDisplayValue] = useState(value);

  useEffect(() => {
    if (condition || isShowMoney) {
      setDisplayValue(value);
    } else {
      setDisplayValue('*****');
    }
  }, [value, condition, isShowMoney]);

  return displayValue;
};

// src/FormTableComponent.jsx

import { InboxOutlined } from '@ant-design/icons';
import { Button, Col, Row, Upload, message } from 'antd';
import { getCompanyInfo, saveCompanyInfo, selectApp } from 'app/commonRedux/appSlice';
import { useAppSelector } from 'app/hooks';
import { DatePickerField, InputField } from 'components/FormFields';
import PageHeader from 'components/PageHeader';
import { YYYY_MM_DD } from 'constants/commons';
import { selectConfiguration } from 'features/configuration/configurationSlice';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

const BusinessRegistrationForm = () => {
  const { t } = useTranslation(['configuration', 'status', 'trainningManagement']);
  const dispatch = useDispatch();
  const location: any = useLocation();
  const menuStore = useAppSelector(selectConfiguration);
  const dataScreenManage = menuStore?.dataScreenManage?.result;
  const companyInfo = useAppSelector(selectApp).companyInfo;
  const [titleHeader, setTitleHeader] = useState('');
  const [logoUrl, setLogoUrl] = useState(null);
  const [showLogoUpload, setShowLogoUpload] = useState(true);

  useEffect(() => {
    const temp = dataScreenManage
      ?.filter((item: any) => location.pathname === item.path)
      ?.map((item: any) => item.nameScreen);

    setTitleHeader(temp ? temp[0] : '');
  }, [dataScreenManage, location.pathname]);

  useEffect(() => {
    dispatch(getCompanyInfo({}));
  }, []);

  // Initialize the form with react-hook-form
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: undefined,
    defaultValues: {
      companyName: '',
      taxCode: '',
      dateOfEstablishment: '',
      businessRegistrationNumber: '',
      dateOfIssue: '',
      placeOfIssue: '',
      legalRepresentative: '',
      legalRepresentativeJobTitile: '',
      address: '',
      district: '',
      city: '',
      phone: '',
      fax: '',
      email: '',
      website: '',
      logo: '',
      slogan: '',
    },
  });

  function setValueDefault(object: any, key: any, value: any) {
    if (object.hasOwnProperty(key)) {
      object[key] = value;
    } else {
      console.log('Key not found in object.');
    }
  }

  function setCompanyInfo(companyInfo: any) {
    const keys = Object.keys(companyInfo);
    for (const key of keys) {
      if (
        (key === 'dateOfIssue' && companyInfo[key]) ||
        (key === 'dateOfEstablishment' && companyInfo[key])
      ) {
        setValue(key, moment(companyInfo[key]));
      } else setValue(key, companyInfo[key]);
    }
    if (companyInfo.logo) {
      setLogoUrl(companyInfo.logo);
      setShowLogoUpload(false);
    }
  }

  useEffect(() => {
    if (companyInfo) {
      setCompanyInfo(companyInfo);
    }
  }, [companyInfo]);

  // Form submit handler
  const onSubmit = (data: any) => {
    const payload = {
      ...data,
      dateOfEstablishment: data?.dateOfEstablishment
        ? moment(data?.dateOfEstablishment).format(YYYY_MM_DD)
        : '',
      dateOfIssue: data?.dateOfIssue ? moment(data?.dateOfIssue).format(YYYY_MM_DD) : '',
      logo: data?.logo ? data?.logo : null,
    };
    dispatch(saveCompanyInfo({ payload }));
  };

  const handleLogoUpload = (info) => {
    const file = info.file;
    const reader = new FileReader();
    reader.onload = (e) => {
      const base64 = e.target?.result as string;
      setLogoUrl(base64);
      setShowLogoUpload(false);
      setValue('logo', base64);
    };
    reader.readAsDataURL(file);
  };

  const handleLogoRemove = () => {
    setLogoUrl(null);
    setShowLogoUpload(true);
    setValue('logo', undefined);
  };

  const beforeLogoUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ marginRight: 32 }}>
      <PageHeader title={titleHeader} />

      <Row gutter={[16, 16]} style={{ marginTop: 8 }}>
        <Col span={6} style={{ fontWeight: 'bold' }}>
          {t('configuration:companyInfo.company')}
        </Col>
        <Col span={6} style={{ fontWeight: 'bold' }}>
          <label htmlFor="companyName">{t('configuration:companyInfo.full_name')}</label>
        </Col>
        <Col span={12}>
          <InputField name="companyName" control={control} />
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{ marginTop: 8 }}>
        <Col span={6}></Col>
        <Col span={6} style={{ fontWeight: 'bold' }}>
          <label htmlFor="taxCode">{t('configuration:companyInfo.tax_code')}</label>
        </Col>
        <Col span={12}>
          <InputField name="taxCode" control={control} />
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{ marginTop: 8 }}>
        <Col span={6}></Col>
        <Col span={6} style={{ fontWeight: 'bold' }}>
          <label htmlFor="dateOfEstablishment">
            {t('configuration:companyInfo.date_of_establishment')}
          </label>
        </Col>
        <Col span={12}>
          <DatePickerField
            style={{ width: '100%' }}
            picker="date"
            format={YYYY_MM_DD}
            name="dateOfEstablishment"
            control={control}
          />
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{ marginTop: 8 }}>
        <Col span={6}></Col>
        <Col span={6} style={{ fontWeight: 'bold' }}>
          <label htmlFor="slogan">{t('configuration:companyInfo.slogan')}</label>
        </Col>
        <Col span={12}>
          <InputField name="slogan" control={control} />
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{ marginTop: 8 }}>
        <Col span={6}></Col>
        <Col span={6} style={{ fontWeight: 'bold' }}>
          <label htmlFor="logo">Logo</label>
        </Col>
        <Col span={12}>
          {showLogoUpload ? (
            <Upload.Dragger
              showUploadList={false}
              multiple={false}
              accept="image/jpeg,image/png"
              beforeUpload={beforeLogoUpload}
              customRequest={({ file, onSuccess }) => {
                setTimeout(() => {
                  onSuccess?.('ok');
                }, 0);
                handleLogoUpload({ file });
              }}
            >
              <div>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  {t(
                    'trainningManagement:course_management.click_or_drag_file_to_this_area_to_upload',
                  )}
                </p>
              </div>
            </Upload.Dragger>
          ) : (
            <>
              {logoUrl && (
                <img
                  src={logoUrl}
                  alt="Company Logo"
                  style={{ maxWidth: '100%', marginTop: '10px' }}
                />
              )}
              <Button onClick={handleLogoRemove} style={{ marginTop: '20px' }}>
                {t('status:remove')}
              </Button>
            </>
          )}
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{ marginTop: 32 }}>
        <Col span={6} style={{ fontWeight: 'bold' }}>
          {t('configuration:companyInfo.business_registration')}
        </Col>
        <Col span={6} style={{ fontWeight: 'bold' }}>
          <label htmlFor="businessRegistrationNumber">
            {t('configuration:companyInfo.business_registration_number')}
          </label>
        </Col>
        <Col span={12}>
          <InputField name="businessRegistrationNumber" control={control} />
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{ marginTop: 8 }}>
        <Col span={6}></Col>
        <Col span={6} style={{ fontWeight: 'bold' }}>
          <label htmlFor="dateOfIssue">{t('configuration:companyInfo.date_of_issue')}</label>
        </Col>
        <Col span={12}>
          <DatePickerField
            style={{ width: '100%' }}
            picker="date"
            format={YYYY_MM_DD}
            name="dateOfIssue"
            control={control}
          />
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{ marginTop: 8 }}>
        <Col span={6}></Col>
        <Col span={6} style={{ fontWeight: 'bold' }}>
          <label htmlFor="placeOfIssue">{t('configuration:companyInfo.place_of_issue')}</label>
        </Col>
        <Col span={12}>
          <InputField name="placeOfIssue" control={control} />
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{ marginTop: 8 }}>
        <Col span={6}></Col>
        <Col span={6} style={{ fontWeight: 'bold' }}>
          <label htmlFor="legalRepresentative">
            {t('configuration:companyInfo.legal_representative')}
          </label>
        </Col>
        <Col span={12}>
          <InputField name="legalRepresentative" control={control} />
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{ marginTop: 8 }}>
        <Col span={6}></Col>
        <Col span={6} style={{ fontWeight: 'bold' }}>
          <label htmlFor="legalRepresentativeJobTitile">
            {t('configuration:companyInfo.job_title')}
          </label>
        </Col>
        <Col span={12}>
          <InputField name="legalRepresentativeJobTitile" control={control} />
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{ marginTop: 32 }}>
        <Col span={6} style={{ fontWeight: 'bold' }}>
          {t('configuration:companyInfo.contact')}
        </Col>
        <Col span={6} style={{ fontWeight: 'bold' }}>
          <label htmlFor="address">{t('configuration:companyInfo.address')}</label>
        </Col>
        <Col span={12}>
          <InputField name="address" control={control} />
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{ marginTop: 8 }}>
        <Col span={6}></Col>
        <Col span={6} style={{ fontWeight: 'bold' }}>
          <label htmlFor="district">{t('configuration:companyInfo.district')}</label>
        </Col>
        <Col span={12}>
          <InputField name="district" control={control} />
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{ marginTop: 8 }}>
        <Col span={6}></Col>
        <Col span={6} style={{ fontWeight: 'bold' }}>
          <label htmlFor="city">{t('configuration:companyInfo.city')}</label>
        </Col>
        <Col span={12}>
          <InputField name="city" control={control} />
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{ marginTop: 8 }}>
        <Col span={6}></Col>
        <Col span={6} style={{ fontWeight: 'bold' }}>
          <label htmlFor="phone">{t('configuration:companyInfo.phone')}</label>
        </Col>
        <Col span={12}>
          <InputField name="phone" control={control} />
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{ marginTop: 8 }}>
        <Col span={6}></Col>
        <Col span={6} style={{ fontWeight: 'bold' }}>
          <label htmlFor="fax">{t('configuration:companyInfo.fax')}</label>
        </Col>
        <Col span={12}>
          <InputField name="fax" control={control} />
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{ marginTop: 8 }}>
        <Col span={6}></Col>
        <Col span={6} style={{ fontWeight: 'bold' }}>
          <label htmlFor="email">{t('configuration:companyInfo.email')}</label>
        </Col>
        <Col span={12}>
          <InputField name="email" control={control} />
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{ marginTop: 8 }}>
        <Col span={6}></Col>
        <Col span={6} style={{ fontWeight: 'bold' }}>
          <label htmlFor="website">{t('configuration:companyInfo.website')}</label>
        </Col>
        <Col span={12}>
          <InputField name="website" control={control} />
        </Col>
      </Row>

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button type="primary" htmlType="submit" style={{ marginTop: 16 }}>
          {t('configuration:companyInfo.submit')}
        </Button>
      </div>
    </form>
  );
};

export default BusinessRegistrationForm;

import {
  DownOutlined,
  EditTwoTone,
  ExclamationCircleOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { Badge, Button, Dropdown, Empty, Menu, Modal, Popconfirm, Select, Space } from 'antd';
import timesheetApi from 'api/timesheetApi';
import { useAppSelector } from 'app/hooks';
import { colors } from 'assets/colors';
import AssignList from 'components/AssignList';
import ITVSearch from 'components/ITVSearch';
import ITVTable from 'components/ITVTable';
import ModalConfirm from 'components/ModalConfirm';
import { notificationToast } from 'components/notificationToast';
import { Notification } from 'constants/notification';
import {
  ILeaveType,
  IPolicyGroup,
} from 'features/configuration/interfaces/LeaveOffConfigInterfaces';
import EmployeeColumn from 'features/employee/components/EmployeeColumn';
import {
  selectEmployee,
  selectSizePageAndCurrentPage,
  setCheckAllStatus,
  setCheckRow,
} from 'features/employee/employeeSlice';
import { ContainerButton } from 'features/insurances/components/ModalEdit';
import { debounce } from 'lodash';
import moment, { Moment } from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import CustomButton from 'styles/buttonStyled';
import { ContainerBgWhite } from 'styles/containerBgWhite';
import { getEndDateOfCurrentMonth, getStartDateOfCurrentMonth } from 'utils/date';
import { formatNumber } from 'utils/function';
import { isLangEn, removeAccents } from 'utils/text';
import { IUserRecord } from '../interfaces/leaveManageInterfaces';
import {
  assignManualStaffManager,
  assignStaffManager,
  exportLeaveOffTypeByEmployee,
  getListManage,
} from '../leaveOffAction';
import { selectLeaveOff } from '../leaveOffSlice';
import ModalListOfPolicy from './ModalListOfPolicy';

function LeaveManage(props: any) {
  //const { year, month } = props;
  const MONTH_YEAR = 'YYYY-MM';
  const monthYearFormat = moment(props.monthYear).format(MONTH_YEAR);
  const dataLeaveType = useAppSelector(selectLeaveOff).dataListTypeLeave;
  const dataLeaveTypeNormalize = useMemo(
    () =>
      dataLeaveType?.result?.reduce((prevValue: ILeaveType[], curValue: IPolicyGroup) => {
        return prevValue?.concat(...(curValue?.leaveTypes || []))?.sort((a: any, b: any) => {
          //sort option and set default option
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
      }, [] as ILeaveType[]),
    [dataLeaveType?.result],
  );

  const dispatch = useDispatch();
  const { t } = useTranslation(['timesheet', 'leaveOff', 'insurance', 'overtime']);
  const dataManage = useAppSelector(selectLeaveOff).dataManage;
  const loading = useAppSelector(selectLeaveOff).loadingDataManage;
  const [dataType, setDataType] = useState<number | null>(null);
  const [data, setData] = useState<any>([]);
  const [arrStaff, setArrStaff] = useState([]);
  const [visibleModalConfirm, setVisibleModalConfirm] = useState(false);
  const [assignVisible, setAssignVisible] = useState(false);
  const [textSearch, setTextSearch] = useState('');
  const [leaveDaysImmediately, setLeaveDaysImmediately] = useState(false);
  const [userEdited, setUserEdited] = useState<IUserRecord | null>(null);
  const monthYearSelect = localStorage.getItem('monthYearSelect');
  let year = moment().year(),
    month = moment().month();
  const monthNow = moment(props.monthYear).format(MONTH_YEAR) === moment().format(MONTH_YEAR);
  const disabledBtn = !monthNow || data.length === 0 || arrStaff.length === 0;
  const selectedRowKey = useAppSelector(selectEmployee).selectRowKey;
  const page = useAppSelector(selectSizePageAndCurrentPage);
  const [currentPage, setCurrentPage] = useState(1);
  const [sizePage, setSizePage] = useState(10);
  const searchFilterSortParam = {
    sort: [{ employeeId: 'DESC' }],
    search: { name: '' },
    filter: {
      departmentName: [],
    },
    limit: 10,
    offset: 1,
  };
  const [paramSortFilter, setParamSortFilter] = useState(searchFilterSortParam);

  useEffect(() => {
    setCurrentPage(1);
    const newParam = {
      ...paramSortFilter,
      offset: 1,
      limit: sizePage,
    };
    setParamSortFilter(newParam);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sizePage]);

  useEffect(() => {
    setArrStaff(selectedRowKey);
  }, [selectedRowKey]);

  const handleSetDetail = (item: any) => {
    const monthYearSelect = localStorage.getItem('monthYearSelect');
    if (moment(monthYearSelect).isBefore(moment().format(MONTH_YEAR))) {
      return notificationToast(
        Notification.Type.Error,
        `You can't edit previous month`,
        Notification.Duration._3s,
      );
    }
    setUserEdited(item);
  };

  useEffect(() => {
    if (dataManage) {
      if (textSearch) {
        setData(
          dataManage.filter(
            (item: any) =>
              item.firstName.toLowerCase().includes(textSearch) ||
              item.lastName.toLowerCase().includes(textSearch) ||
              removeAccents((item?.lastName + item?.firstName).replace(/ /g, ''))
                .toLowerCase()
                .includes(removeAccents(textSearch).replace(/ /g, '').toLowerCase()),
          ),
        );
      } else {
        setData((dataLeaveType?.result?.length || 0) > 0 ? dataManage : []);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataManage, dataLeaveType]);

  const getListManageLeaveOff = () => {
    const monthYearValue = monthYearSelect?.split('-');
    let _startDate;
    let _endDate;

    if (monthYearSelect && monthYearValue) {
      month = Number(monthYearValue[1]);
      year = Number(monthYearValue[0]);

      _startDate = getStartDateOfCurrentMonth(`${year}-${month}-01`);
      _endDate = getEndDateOfCurrentMonth(`${year}-${month}-01`);
    } else {
      _startDate = getStartDateOfCurrentMonth(`${year}-${month}-01`);
      _endDate = getEndDateOfCurrentMonth(`${year}-${month}-01`);
    }
    if (dataType) {
      const params = {
        startDate: _startDate,
        endDate: _endDate,
        type: dataType,
        groupId: dataLeaveTypeNormalize?.find((item: ILeaveType) => item?.id === dataType)?.groupId,
      };

      dispatch(getListManage(params));
      dispatch(setCheckRow([]));
      dispatch(setCheckAllStatus(false));
    }
  };

  useEffect(() => {
    getListManageLeaveOff();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataType]);

  const autoAddLeaveDaysAssigned = (value: string[]) => {
    const _startDate = getStartDateOfCurrentMonth(`${year}-${month + 1}-01`);
    const _endDate = getEndDateOfCurrentMonth(`${year}-${month + 1}-01`);

    dataType &&
      dispatch(
        assignStaffManager({
          startDate: _startDate,
          endDate: _endDate,
          typeId: dataType,
          userIds: value,
          monthYear: props.monthYear,
        }),
      );
  };

  const manualAddLeaveDaysAssigned = (value: { data: any; dateAddLeaveDays: Moment | null }[]) => {
    const _startDate = getStartDateOfCurrentMonth(`${year}-${month + 1}-01`);
    const _endDate = getEndDateOfCurrentMonth(`${year}-${month + 1}-01`);

    dataType &&
      dispatch(
        assignManualStaffManager({
          startDate: _startDate,
          endDate: _endDate,
          typeId: dataType,
          users: value.map((user: { data: any; dateAddLeaveDays: Moment | null }) => ({
            userId: user.data.userId,
            dateAddLeaveDays: user.dateAddLeaveDays!,
          })),
          monthYear: props.monthYear,
        }),
      );
  };

  const onShowModalConfirm = () => {
    setVisibleModalConfirm(true);
  };

  const handleReset = async () => {
    const data = {
      leaveTypeId: dataType,
      userIds: arrStaff,
      thisMonth: monthYearFormat,
    };
    const response: any = await timesheetApi.resetTotalDayLeaveOff(data);
    if (response.data) {
      // notificationWithIcon({ type: 'success', description: response.message, duration: 3 });
      notificationToast(Notification.Type.Success, response.message, Notification.Duration._3s);
      getListManageLeaveOff();
    } else {
      // notificationWithIcon({ type: 'error', description: response.message, duration: 5 });
      notificationToast(Notification.Type.Error, response.message, Notification.Duration._5s);
    }
    setVisibleModalConfirm(false);
  };

  const columns = [
    {
      title: t('insurance:is_code'),
      key: 'employeeCode',
      dataIndex: 'employeeCode',
      width: 80,
      minWidth: 80,
      align: 'center',
      sorter: {
        compare: () => {},
        multiple: 3,
      },
      defaultSortOrder: 'descend',
      render: (text: any, record: any, index: any) => {
        return <>{text}</>;
      },
    },
    {
      title: t('Staff_name'),
      key: 'user',
      dataIndex: 'user',
      width: 250,
      sorter: {
        compare: (a: any, b: any) => {},
        multiple: 3,
      },
      render: (text: any, record: any, index: any) => {
        return (
          <EmployeeColumn
            avatar={record?.fullName}
            fullName={record?.fullName}
            email={record?.email}
          />
        );
      },
    },
    {
      title: t('overtime:project_name'),
      key: 'projectPrimaryName',
      dataIndex: 'projectPrimaryName',
      ellipsis: true,
      width: 100,
      minWidth: 100,
      sorter: {
        compare: () => {},
        multiple: 3,
      },
      render: (text: any, record: any, index: any) => {
        return <>{text}</>;
      },
    },
    {
      title: t('leaveOff:common:Compilation_date'),
      key: 'availableDays',
      dataIndex: 'availableDays',
      ellipsis: true,
      width: 100,
      minWidth: 100,
      render: (text: any) => {
        return (
          <>
            {formatNumber(text)}{' '}
            {t('day', {
              s: isLangEn() && Number(text) > 1 ? 's' : '',
            })}
          </>
        );
      },
    },
    {
      title: t('leaveOff:common:Date_allow_current'),
      key: 'current',
      dataIndex: 'current',
      ellipsis: true,
      width: 180,
      minWidth: 180,
      render: (text: any, record: any, index: any) => {
        return (
          <>
            <div>
              {t('leaveOff:Remaining_leave_days') +
                formatNumber(record?.availableDays - record?.usedDays)}{' '}
              {t('day', {
                s: isLangEn() && Number(record?.availableDays - record?.usedDays) > 1 ? 's' : '',
              })}
            </div>
            <Badge
              status="default"
              text={
                t('leaveOff:Used') +
                formatNumber(record?.usedDays) +
                ' ' +
                t('day', {
                  s: isLangEn() && Number(record?.usedDays) > 1 ? 's' : '',
                })
              }
              style={{ color: colors.gray400 }}
            />
          </>
        );
      },
    },
    {
      title: t('leaveOff:common:date_add_leave_days'),
      key: 'DateAddLeaveDays',
      dataIndex: 'DateAddLeaveDays',
      ellipsis: true,
      width: 100,
      minWidth: 100,
    },
    {
      title: t('insurance:action'),
      key: 'action',
      width: 50,
      minWidth: 50,
      align: 'center',
      fixed: 'right',
      render: (text: any, record: any, index: any) => (
        <Button type="link" disabled={!monthNow}>
          <EditTwoTone
            twoToneColor={monthNow ? '#F1592A' : '#d9d9d9'}
            style={{ fontSize: '20px' }}
            onClick={() => handleSetDetail(record)}
          />
        </Button>
      ),
    },
  ];

  const search = (key: string) => {
    const text = key.toLowerCase().trim();
    setTextSearch(key);
    if (text !== '') {
      setData(
        dataManage.filter(
          (item: any) =>
            item.firstName.toLowerCase().includes(text) ||
            item.lastName.toLowerCase().includes(text) ||
            removeAccents((item?.lastName + item?.firstName).replace(/ /g, ''))
              .toLowerCase()
              .includes(removeAccents(text).replace(/ /g, '').toLowerCase()),
        ),
      );
      setCurrentPage(1); //keep from this and del above
      const paramFilterSortWithSearchTxt = {
        ...paramSortFilter,
        search: { name: key },
        offset: 1,
        limit: page.sizePage,
      };
      debounce(() => {
        setParamSortFilter(paramFilterSortWithSearchTxt);
      }, 500)();
    } else {
      setData(dataManage);
    }
  };

  const onExport = () => {
    dispatch(
      exportLeaveOffTypeByEmployee({
        monthYear: props.monthYear,
        leaveTypeId: dataType,
        employeeIds: arrStaff,
      }),
    );
    getListManageLeaveOff();
  };

  useEffect(() => {
    if (dataLeaveTypeNormalize?.find((item: any) => item.status)?.id !== dataType) {
      setDataType(dataLeaveTypeNormalize?.find((item: any) => item.status)?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataLeaveTypeNormalize]);

  const menuAssign = (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() => {
          setLeaveDaysImmediately(true);
          setAssignVisible(true);
        }}
      >
        {t('leaveOff:modal_item.add_leave_days_immediately')}
      </Menu.Item>
      <Menu.Item
        disabled={
          dataLeaveTypeNormalize?.find((leaveType: any) => leaveType.id === dataType)?.addTime ===
          'repeat'
        }
        key="2"
        onClick={() => {
          setLeaveDaysImmediately(false);
          setAssignVisible(true);
        }}
      >
        {t('leaveOff:modal_item.choose_day_to_add_leave_days')}
      </Menu.Item>
    </Menu>
  );

  const onChangeTableLeaveOff = (pagination: any, filters: any, sorter: any) => {
    //call api to sort here
    const param = { ...paramSortFilter };
    if (sorter.length) {
      const sortValue = sorter?.map((item: any) =>
        item.order
          ? {
              [item.columnKey === 'employeeCode'
                ? 'employeeId'
                : item.columnKey === 'departmentNames'
                ? 'departmentName'
                : item.columnKey === 'user'
                ? 'fullName'
                : item.columnKey]: item.order === 'ascend' ? 'ASC' : 'DESC',
            }
          : [],
      );
      // const sortArr = sorter.map((item: any) => item);
      param.sort = sortValue;
    } else {
      if (sorter.order) {
        const array: any = [
          {
            [sorter.columnKey === 'employeeCode'
              ? 'employeeId'
              : sorter.columnKey === 'departmentNames'
              ? 'departmentName'
              : sorter.columnKey === 'user'
              ? 'fullName'
              : sorter.columnKey]: sorter.order === 'ascend' ? 'ASC' : 'DESC',
          },
        ];
        param.sort = array;
      } else {
        param.sort = [];
      }
    }

    const postParamFilterSort = {
      ...param,
      filter: {
        departmentName: filters.departmentNames ? filters.departmentNames : [],
      },
      offset: pagination.current,
      // limit: pagination.pageSize,
    };
    setParamSortFilter(postParamFilterSort);
  };

  return (
    <ContainerBgWhite>
      {dataLeaveType?.result?.some((value: any) =>
        value.leaveTypes.some((item: any) => item.status),
      ) ? (
        <>
          <Header>
            <HeaderLeft>
              {
                <Select
                  defaultValue={dataLeaveTypeNormalize?.find((item: any) => item.status)?.id}
                  onChange={(value: any) => {
                    setDataType(value);
                  }}
                  value={dataLeaveTypeNormalize?.find((item: any) => item.id === dataType)?.id}
                  style={{ minWidth: 200 }}
                >
                  {dataLeaveType?.result
                    ?.filter(
                      (item: any) =>
                        item.leaveTypes.length > 0 &&
                        item.leaveTypes.some((leaveType: ILeaveType) => leaveType.status),
                    )
                    ?.sort((a: any, b: any) => {
                      const nameA = a.name.toUpperCase();
                      const nameB = b.name.toUpperCase();
                      if (nameA < nameB) {
                        return -1;
                      }
                      if (nameA > nameB) {
                        return 1;
                      }
                      return 0;
                    })
                    ?.map((value: any) => {
                      return (
                        <Select.OptGroup key={value.id} label={value.name}>
                          {value.leaveTypes
                            .filter((item: ILeaveType) => item.status)
                            .sort((a: any, b: any) => {
                              const nameA = a.name.toUpperCase();
                              const nameB = b.name.toUpperCase();
                              if (nameA < nameB) {
                                return -1;
                              }
                              if (nameA > nameB) {
                                return 1;
                              }
                              return 0;
                            })
                            .map((item: ILeaveType) => (
                              <Select.Option key={item.id} value={item.id} disabled={!item.status}>
                                {item.name}
                              </Select.Option>
                            ))}
                        </Select.OptGroup>
                      );
                    })}
                </Select>
              }
            </HeaderLeft>
            <HeaderRight>
              <Space>
                <CustomButton onClick={onShowModalConfirm} disabled={disabledBtn}>
                  {t('timesheet:Reset')}
                </CustomButton>
                <HeaderRemove
                  icon={<UploadOutlined />}
                  onClick={onExport}
                  disabled={data.length === 0 || arrStaff.length === 0}
                >
                  {t('insurance:Export')}
                </HeaderRemove>
                <Dropdown
                  overlay={menuAssign}
                  disabled={
                    dataLeaveType?.result?.length === 0 ||
                    props.monthYear < moment().startOf('month')
                  }
                >
                  <CustomButton aType="primary">
                    {t('leaveOff:common:Assign')} <DownOutlined />
                  </CustomButton>
                </Dropdown>
                <ITVSearch handleSearch={(value: any) => search(value)} />
              </Space>
            </HeaderRight>
          </Header>
          <ITVTable
            width={1300}
            columns={columns}
            isScroll={true}
            data={data}
            loading={loading}
            rowKey={['userId']}
            setSelectedUser={setArrStaff}
            selectionType="checkbox"
            limit={sizePage}
            setLimit={setSizePage}
            offset={currentPage}
            setOffset={setCurrentPage}
            onChange={onChangeTableLeaveOff}
          />
        </>
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
      {assignVisible && (
        <Modal
          visible={assignVisible}
          onCancel={() => setAssignVisible(false)}
          closable={true}
          footer={null}
          width={1100}
          centered={true}
          title={
            t('leaveOff:Assign_type_leave_off') +
            ': ' +
            dataLeaveTypeNormalize?.find((item: any) => item.id === dataType)?.name
          }
        >
          <AssignList
            viewMode={true}
            handleAutoAddLeaveDays={(value: string[]) => {
              setAssignVisible(false);
              autoAddLeaveDaysAssigned(value);
            }}
            handleManualAddLeaveDays={(value: { data: any; dateAddLeaveDays: Moment | null }[]) => {
              setAssignVisible(false);
              manualAddLeaveDaysAssigned(value);
            }}
            rowKey={['userId']}
            type={dataType}
            startDate={getStartDateOfCurrentMonth(`${year}-${month + 1}-01`)}
            endDate={getEndDateOfCurrentMonth(`${year}-${month + 1}-01`)}
            submitText={t('leaveOff:common:Assign')}
            stepText={t('leaveOff:modal_item.next')}
            backText={t('leaveOff:modal_item.back')}
            leaveDaysImmediately={leaveDaysImmediately}
          />
        </Modal>
      )}
      {userEdited && (
        <ModalListOfPolicy
          userEdited={userEdited}
          setUserEdited={setUserEdited}
          dataLeave={dataLeaveTypeNormalize}
          idLeaveOffSelected={dataType}
        />
      )}
      {visibleModalConfirm && (
        <ModalConfirm
          isModalVisible={visibleModalConfirm}
          heading={t('leaveOff:modal_item.reconfirm')}
          onCancel={() => setVisibleModalConfirm(false)}
          footer={
            <ContainerButton>
              <CustomButton onClick={() => setVisibleModalConfirm(false)}>
                {t('leaveOff:btn_cancel')}
              </CustomButton>
              <Popconfirm
                title={t('leaveOff:modal_item.are_you_sure_reset_type')}
                onConfirm={handleReset}
              >
                <CustomButton>Ok</CustomButton>
              </Popconfirm>
            </ContainerButton>
          }
          styleContent={{ width: '85%' }}
          content={
            <div>
              <div>{t('leaveOff:modal_item.if_reset')}</div>
              <TextWarning>
                <div>• {t('leaveOff:modal_item.warning_reset_change_remain_zero')}</div>
                <div>• {t('leaveOff:modal_item.warning_reset_change_status')}</div>
              </TextWarning>
            </div>
          }
          icon={
            <ExclamationCircleOutlined
              style={{ fontSize: '22px', color: 'var(--cl_primary400)' }}
            />
          }
        />
      )}
    </ContainerBgWhite>
  );
}

export default LeaveManage;

export const Header = styled.span`
  padding: 0 0 15px 0;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;
const TextWarning = styled.div`
  margin-left: 12px;
`;

export const SectionAssign = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding-right: 15px;
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  background: ${colors.white};
  width: 100%;
`;
export const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const AssignButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const AssignSectionSwitch = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;
export const SelectLeft = styled(Select)`
  width: 43% !important;
  margin-left: 3%;
  padding-left: 10px;
`;
const HeaderRemove = styled(Button)`
  font-size: var(--txt_font_size_body);
  /* color: var(--cl_black); */
  border-radius: var(--bd_radius_default);
  img {
    padding-bottom: 6px;
  }
`;

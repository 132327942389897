import styled from 'styled-components';
import { TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ContainerBgWhite } from 'styles/containerBgWhite';
import { AdjustmentSalaryFilters } from './AdjustmentSalaryFilters';
import { AdjustmentSalaryList } from './AdjustmentSalaryList';
import { ADJUSTMENT_SALARY_SORT_FIELDS, SORT_TYPES } from 'constants/payroll';
import { Adjustment, AdjustmentSalaryMap, AdjustmentSalaryPayload, IAdjustmentSalary } from 'types';
import payrollApi from 'api/payrollApi';
import CustomButton from 'styles/buttonStyled';
import { ExportOutlined } from '@ant-design/icons';
import { useAppSelector } from 'app/hooks';
import { AdjustmentSalaryConfirm } from './AdjustmentSalaryConfirm';
import { exportToCSV } from 'utils/common';
import { selectEmployee } from 'features/employee/employeeSlice';

const StyledWrapperFilters = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const StyledWrapperActionBtn = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

interface AdjustmentSalaryProps {
  monthYear: string;
  fetchListStatusTabPayrollAPI: () => void;
}

export function AdjustmentSalary({
  monthYear,
  fetchListStatusTabPayrollAPI,
}: AdjustmentSalaryProps) {
  const { t } = useTranslation(['action', 'payroll']);
  const selectRowKey = useAppSelector((state) => state.employee.selectRowKey) as number[];
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [adjustmentSalaryList, setAdjustmentSalaryList] = useState<Array<AdjustmentSalaryMap>>([]);
  const [totalResult, setTotalResult] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState<Partial<AdjustmentSalaryPayload>>({
    search: '',
    line: '',
  });
  const isCheckAll: boolean = useAppSelector(selectEmployee).isCheckAll;

  const filtersMemo = useMemo(() => {
    if (isCheckAll) {
      return {
        ...filters,
        monthYear: monthYear,
      };
    }
    return {
      ...filters,
      monthYear,
      page,
      limit,
    };
  }, [filters, monthYear, page, limit, isCheckAll]);

  const fetchAdjustmentSalaryListAPI = async () => {
    try {
      setIsLoading(true);
      const response = await payrollApi.getAdjustmentSalaryList(filtersMemo);

      const adjustmentSalaryList: AdjustmentSalaryMap[] = [];

      response.data?.result.forEach((item: IAdjustmentSalary) => {
        const children = item.adjustments.map((obj: Adjustment, index: number) => {
          return {
            ...obj,
            key: index,
            emailParent: item.email,
            userId: item.id,
            fullNameParent: item.fullName,
            employeeIdParent: item.employeeId,
            sumAdjustment: item.sumAdjustment,
          };
        });
        adjustmentSalaryList.push(...children);
      });

      setIsLoading(false);
      setAdjustmentSalaryList(adjustmentSalaryList);
      setTotalResult(response.data?.total || 0);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAdjustmentSalaryListAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersMemo]);

  const handleFiltersChange = (payload: AdjustmentSalaryPayload) => {
    setPage(1);

    setFilters((prevFilter) => {
      return {
        ...prevFilter,
        ...payload,
      };
    });
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue | null>,
    sorter: SorterResult<any>,
  ) => {
    if (pagination?.current === page) {
      setPage(1);

      setFilters((prevFilters) => ({
        ...prevFilters,
        viewBy: sorter.column
          ? ADJUSTMENT_SALARY_SORT_FIELDS[
              sorter.field as keyof typeof ADJUSTMENT_SALARY_SORT_FIELDS
            ]
          : ADJUSTMENT_SALARY_SORT_FIELDS.fullName,
        sortBy: sorter.column
          ? SORT_TYPES[sorter.order as keyof typeof SORT_TYPES]
          : SORT_TYPES.ascend,
      }));
    }
  };

  const handleExport = async () => {
    const userIds = adjustmentSalaryList
      .filter((item) => selectRowKey.includes(item.id))
      .map((x) => x.userId);

    const response = await payrollApi.exportAdjustmentSalary({
      monthYear: monthYear,
      userIds: userIds,
    });

    const exportFileName = `adjustment_salary_${new Date().getTime()}.xlsx`;
    exportToCSV(response, exportFileName);
  };

  return (
    <ContainerBgWhite>
      <StyledWrapperFilters>
        <AdjustmentSalaryFilters
          initialValues={{
            search: filters.search,
          }}
          onSubmit={handleFiltersChange}
        />

        <StyledWrapperActionBtn>
          <AdjustmentSalaryConfirm
            monthYear={monthYear}
            fetchListStatusTabPayrollAPI={fetchListStatusTabPayrollAPI}
          />

          <CustomButton
            icon={<ExportOutlined />}
            loading={false}
            disabled={selectRowKey.length === 0}
            onClick={handleExport}
          >
            {t('export')}
          </CustomButton>
        </StyledWrapperActionBtn>
      </StyledWrapperFilters>

      <AdjustmentSalaryList
        loading={isLoading}
        adjustmentSalaryList={adjustmentSalaryList}
        totalResult={totalResult}
        page={page}
        limit={limit}
        setPage={setPage}
        setLimit={setLimit}
        onTableChange={handleTableChange}
        onRefresh={fetchAdjustmentSalaryListAPI}
      />
    </ContainerBgWhite>
  );
}

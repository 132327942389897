import moment, { Moment } from 'moment';
import { checkIsFirstOrLastOfTime } from 'utils/date';

export const changeStartTime = (
  optionTime: {
    timeSelect: Moment | null;
    currentDay: string;
    timeEnd: Moment;
  },
  setTimeStart: (timeStart: Moment) => void,
  setTimeEnd: (timeEnd: Moment) => void,
) => {
  const { timeSelect, currentDay, timeEnd } = optionTime;
  const hourTimeStart = timeSelect?.hour() || 0;
  const minuteTimeStart = timeSelect?.minute() || 0;
  const momentTimeStart = moment(currentDay).set({
    hour: hourTimeStart,
    second: 0,
    millisecond: 0,
  });

  if (!timeSelect || timeSelect >= timeEnd) {
    setTimeEnd(
      momentTimeStart.clone().set({
        minute: checkIsFirstOrLastOfTime('lastOfTime', hourTimeStart, minuteTimeStart)
          ? minuteTimeStart
          : minuteTimeStart + 1,
      }),
    );
  }

  setTimeStart(
    momentTimeStart.set({
      minute: checkIsFirstOrLastOfTime('lastOfTime', hourTimeStart, minuteTimeStart)
        ? minuteTimeStart - 1
        : minuteTimeStart,
    }),
  );
};

export const changeEndTime = (
  optionTime: {
    timeSelect: Moment | null;
    currentDay: string;
    timeStart: Moment;
  },
  setTimeEnd: (timeEnd: Moment) => void,
) => {
  const { timeSelect, currentDay } = optionTime;
  const hourTimeEnd = timeSelect?.hour() || 0;
  const minuteTimeEnd = timeSelect?.minute() || 0;
  const momentTimeEnd = moment(currentDay).set({
    hour: hourTimeEnd,
    second: 0,
    millisecond: 0,
  });

  setTimeEnd(
    momentTimeEnd.set({
      minute: checkIsFirstOrLastOfTime('firstOfTime', hourTimeEnd, minuteTimeEnd)
        ? minuteTimeEnd + 1
        : minuteTimeEnd,
    }),
  );
};

import { useState, ChangeEvent } from 'react';
import { Input } from 'antd';
import Icon from '@ant-design/icons';
import IconSearch from 'assets/icons/ic_search_gray.svg';
import styled from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';
import { useTranslation } from 'react-i18next';

const { Search } = Input;

export const CustomIcon = styled(Icon)`
  img {
    height: 15px;
    width: 15px;
  }
`;

const IconSearchPrefix = <CustomIcon component={() => <img src={IconSearch} alt="" />} />;

interface ITVSearchProps {
  handleSearch?: any;
  search?: string;
  setLoading?(isLoading: boolean): void;
}

const ITVSearch = (props: any) => {
  const { handleSearch, search, setLoading, ...rest }: ITVSearchProps = props;

  const { t } = useTranslation(['action', 'onboard']);
  const [value, setValue] = useState('setValue');
  const debounced = useDebouncedCallback((value) => {
    handleSearch && handleSearch(value);
    setLoading && setLoading(false);
    setValue(value);
  }, 500);

  const handleOnChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setLoading && setLoading(true);
    debounced(e.target.value);
  };

  return (
    <CustomSearch
      placeholder={search === 'mail' ? t('onboard:LabelFormEmail.search') : t('action:search')}
      size="large"
      prefix={IconSearchPrefix}
      onSearch={() => handleSearch(value)}
      onChange={handleOnChangeSearch}
      {...rest}
    />
  );
};

export default ITVSearch;

export const CustomSearch = styled(Search)`
  width: 300px;
  .ant-input-group-addon {
    display: none;
  }
  .ant-input-affix-wrapper {
    height: 32px;
    input {
      font-size: 14px;
    }
  }
`;

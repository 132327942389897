import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import departmentApi from 'api/departmentApi';
import { RootState } from 'app/store';

export const getDepartmentList = createAsyncThunk(
  'department/getList',
  async ({ page, limit, filters }: any) => {
    const departmentList: any = await departmentApi.getList({
      page,
      limit,
      ...filters,
    });
    return departmentList;
  },
);

const department = createSlice({
  name: 'department',
  initialState: {
    isRequesting: false,
    error: null,
    data: [],
    total: 0,
    searchCriteria: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDepartmentList.pending, (state, action: any) => {
        state.isRequesting = true;
        state.error = null;
        state.searchCriteria = { ...action.payload };
      })
      .addCase(getDepartmentList.fulfilled, (state, action) => {
        state.isRequesting = false;
        state.error = null;
        state.data = action.payload.data.result;
        state.total = action.payload.data.total;
      })
      .addCase(getDepartmentList.rejected, (state, action: any) => {
        state.isRequesting = false;
        state.error = action.payload;
        state.data = [];
      });
  },
});

export const selectDepartment = (state: RootState) => state.department;

export const { reducer, actions } = department;

export default reducer;

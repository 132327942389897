import {
  DeleteTwoTone,
  EditTwoTone,
  ExportOutlined,
  EyeOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { useAppSelector } from 'app/hooks';
import ITVTable from 'components/ITVTable';
import MenuAction from 'components/MenuAction';
import PageHeader from 'components/PageHeader';
import { selectConfiguration } from 'features/configuration/configurationSlice';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import CustomButton from 'styles/buttonStyled';
import {
  cancelReviewCycle,
  deleteReviewCycle,
  exportPerformanceReviewCycle,
  getDetailCycle,
  getReviewCycle,
  startReviewCycle,
} from './performanceAction';
import { actions, selectPerformanceData } from './performanceSlice';
import { YYYY_MM_DD } from 'constants/commons';
import moment from 'moment';
import { getUser } from 'utils/auth';
import ITVSearch from 'components/ITVSearch';
import { useTranslation } from 'react-i18next';
import { TitleColumnCenter } from 'styles/tableStyled';
import { exportToCSV } from 'utils/common';

enum Status {
  NEW = 'New',
  IN_PROGRESS = 'In progress',
}

export default function PerformanceReview({ setCreateMode, setViewMode }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const location: any = useLocation();
  const menuStore = useAppSelector(selectConfiguration);
  const dataScreenManage = menuStore?.dataScreenManage?.result;
  const performanceStore = useAppSelector(selectPerformanceData);
  const reviewCycleData = performanceStore?.reviewCycleData;
  const [titleHeader, setTitleHeader] = useState('');
  const [sizePage, setSizePage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchName, setSearchName] = useState('');
  const { t } = useTranslation(['performance', 'action']);
  const { resetperformanceTemplateDetail } = actions;

  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const selectRowKey = useAppSelector((state) => state.employee.selectRowKey) as string[];

  const COLUMNS = [
    {
      title: () => {
        return <TitleColumnCenter>{t('performance:performance_review.col_id')}</TitleColumnCenter>;
      },
      key: 'employeeCode',
      dataIndex: 'employeeCode',
      width: 80,
      minWidth: 80,
      align: 'center',
      render: (text: any, record: any, index: any) => {
        const trueIndex = index + sizePage * (currentPage - 1);
        return <div>{trueIndex + 1}</div>;
      },
    },
    {
      title: () => {
        return (
          <TitleColumnCenter>
            {t('performance:performance_review.review_cycle_name')}
          </TitleColumnCenter>
        );
      },
      key: 'name',
      dataIndex: 'name',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (text: any, record: any) => {
        return (
          <a
            onClick={() => {
              history.push({
                pathname: `/performance/performance-review/${record?.id}`,
                state: { name: record?.name },
              });
            }}
          >
            {text}
          </a>
        );
      },
    },
    {
      title: () => {
        return (
          <TitleColumnCenter>
            {t('performance:performance_review.total_assignees')}
          </TitleColumnCenter>
        );
      },
      key: 'totalAssignees',
      dataIndex: 'totalAssignees',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (text: any) => {
        return text;
      },
    },
    {
      title: () => {
        return <TitleColumnCenter>{t('performance:performance_review.status')}</TitleColumnCenter>;
      },
      key: 'status',
      dataIndex: 'status',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (text: any) => {
        return text;
      },
    },
    {
      title: () => {
        return (
          <TitleColumnCenter>{t('performance:performance_review.created_on')}</TitleColumnCenter>
        );
      },
      key: 'createdAt',
      dataIndex: 'createdAt',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (_: any, record: any, index: any) => {
        return record?.createdAt ? moment(record?.createdAt).format(YYYY_MM_DD) : '';
      },
    },
    {
      title: () => {
        return (
          <TitleColumnCenter>{t('performance:performance_review.start_date')}</TitleColumnCenter>
        );
      },
      key: 'periodFrom',
      dataIndex: 'periodFrom',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (text: any) => {
        return text ? moment(text).format(YYYY_MM_DD) : '';
      },
    },
    {
      title: () => {
        return (
          <TitleColumnCenter>{t('performance:performance_review.end_date')}</TitleColumnCenter>
        );
      },
      key: 'periodTo',
      dataIndex: 'periodTo',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (text: any) => {
        return text ? moment(text).format(YYYY_MM_DD) : '';
      },
    },
    {
      title: () => {
        return <TitleColumnCenter>{t('performance:performance_review.action')}</TitleColumnCenter>;
      },
      key: 'action',
      dataIndex: 'action',
      width: 80,
      minWidth: 80,
      align: 'center',
      render: (_: any, record: any, index: any) => {
        return (
          getUser()?.permissions?.includes('VIEW_PERFORMANCE_REVIEW') && (
            <MenuAction menu={renderMenu(record)} data={record} />
          )
        );
      },
    },
  ];
  const [columns, setColumns] = useState(COLUMNS);

  useEffect(() => {
    setColumns(COLUMNS);
  }, [t, sizePage, currentPage, searchName]);

  const callApi = () => {
    dispatch(getReviewCycle({ offset: currentPage, limit: sizePage, searchName }));
  };

  useEffect(() => {
    callApi();
  }, [dispatch, currentPage, sizePage, searchName]);

  useEffect(() => {
    const temp = dataScreenManage
      ?.filter((item: any) => location.pathname?.includes(item.path))
      ?.map((item: any) => item.nameScreen);

    setTitleHeader(temp ? temp[0] : '');
  }, [dataScreenManage, location.pathname]);

  const menu = [
    {
      name: t('performance:performance_review.start_review_cycle'),
      // icon: <EditTwoTone twoToneColor="#F1592A" />,
      handleClick: (value: any) => {
        dispatch(startReviewCycle({ id: value?.id, callBack: () => callApi() }));
      },
    },
    {
      name: t('performance:performance_review.view_detail'),
      icon: <EyeOutlined style={{ color: '#1890FF' }} />,
      handleClick: (value: any) => {
        dispatch(getDetailCycle({ id: value?.id }));
        setCreateMode && setCreateMode(true);
        setViewMode && setViewMode(value?.id);
        // Handle the detail view action here
      },
    },
    {
      name: t('action:delete'),
      icon: <DeleteTwoTone twoToneColor="#B7B7B7" />,
      type: 'delete',
      handleClick: (value: any) => {
        dispatch(deleteReviewCycle({ id: value?.id, callBack: () => callApi() }));
      },
    },
  ];

  const renderMenu = (record: any) => {
    let newMenu: any = menu;
    if (record?.status === Status.NEW) {
      if (!getUser()?.permissions?.includes('START_CYCLE_REVIEW')) {
        newMenu = menu.filter(
          (i) => i?.name !== t('performance:performance_review.start_review_cycle'),
        );
      }
      if (!getUser()?.permissions?.includes('UPDATE_CYCLE_REVIEW')) {
        newMenu = newMenu.filter(
          (i) => i?.name !== t('performance:performance_review.view_detail'),
        );
      }
      if (!newMenu?.length) {
        setColumns(columns.filter((col) => col.key !== 'action'));
      }
      return newMenu;
    } else {
      const menuWithoutDelete = menu.filter((i) => i?.name !== t('action:delete'));
      newMenu = menuWithoutDelete.filter(
        (i) => i?.name !== t('performance:performance_review.start_review_cycle'),
      );
      if (!getUser()?.permissions?.includes('UPDATE_CYCLE_REVIEW')) {
        newMenu = newMenu.filter(
          (i) => i?.name !== t('performance:performance_review.view_detail'),
        );
      }
      if (!newMenu?.length) {
        setColumns(columns.filter((col) => col.key !== 'action'));
      }
      if (record?.status === Status.IN_PROGRESS) {
        newMenu = [
          ...newMenu,
          {
            name: t('action:cancel'),
            handleClick: (value: any) => {
              dispatch(cancelReviewCycle({ id: value?.id, callBack: () => callApi() }));
            },
          },
        ];
      }
    }
    return newMenu;
  };

  const handleExport = async () => {
    setIsLoadingExport(true);
    dispatch(exportPerformanceReviewCycle(selectRowKey));
    setIsLoadingExport(false);
  };

  return (
    <>
      <PageHeader title={titleHeader} />
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {getUser()?.permissions?.includes('CREATE_CYCLE_REVIEW') && (
          <CustomButton
            icon={<PlusOutlined />}
            style={{ marginBottom: 15, width: 200 }}
            onClick={() => {
              dispatch(resetperformanceTemplateDetail({}));
              setCreateMode && setCreateMode(true);
            }}
          >
            {t('performance:performance_review.create_review_cycle')}
          </CustomButton>
        )}
        <CustomButton
          style={{ marginBottom: 15, marginLeft: 'auto' }}
          icon={<ExportOutlined />}
          loading={isLoadingExport}
          disabled={selectRowKey.length === 0}
          onClick={handleExport}
        >
          {t('action:export')}
        </CustomButton>
      </div>
      <ITVSearch style={{ marginBottom: '1rem' }} handleSearch={(value) => setSearchName(value)} />
      <ITVTable
        rowKey={['id']}
        data={reviewCycleData?.result}
        columns={columns}
        setOffset={setCurrentPage}
        offset={currentPage}
        setLimit={setSizePage}
        limit={sizePage}
        totalResult={reviewCycleData?.totalResult}
      />
    </>
  );
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { ILeaveTypeOff, IUserRecord } from './interfaces/leaveManageInterfaces';
import {
  getHistoryLeaveOff,
  getListSlideLeaveoff,
  getTotalLeaveOffPaid,
  getListManageExclude,
  getListManage,
  getLeaveTypeList,
  getHistoryManager,
  getTotalYearLeaveOffPaid,
  getMultiGroupLeaveoff,
} from './leaveOffAction';

const leaveOffSlice = createSlice({
  name: 'leaveOff',
  initialState: {
    dataSlideLeaveoff: [],
    dataHistoryLeave: [],
    dataLeavePaid: [],
    dataYearLeavePaid: [],
    dataManage: [],
    loadingDataManage: false,
    loadingDataHistoryManager: false,
    dataHistoryManager: [],
    dataListTypeLeave: [],
    listStaffAssign: [],
    error: null,
    loading: false,
    loadingStaff: false,
    totalAssign: 0,
  },
  reducers: {
    editCompilationDateUser: (
      state,
      action: PayloadAction<{ totalLeaveOff: number; userId: string }>,
    ) => {
      const { userId = '', totalLeaveOff = 0 } = action.payload;
      const userDetails: any = state.dataManage.find((user: any) => user.userId === userId);
      if (userDetails) userDetails.availableDays = totalLeaveOff;
    },
    deleteUserSameLeaveOffType: (state, action: PayloadAction<{ userId: string }>) => {
      state.dataManage = state.dataManage.filter(
        (user: any) => user.userId !== action.payload.userId,
      );
    },
    addUserSameLeaveOffType: (
      state,
      action: PayloadAction<{ user: IUserRecord; leaveType: ILeaveTypeOff | null }>,
    ) => {
      const { user, leaveType } = action.payload;
      const hasUser = state.dataManage.find((u: any) => u.userId === user.userId);

      !hasUser &&
        leaveType &&
        (state.dataManage as IUserRecord[]).push({
          ...user,
          availableDays: leaveType.total_leave_off,
          usedDays: leaveType.total_used,
        });
    },
    resetDataSlideLeaveOff: (state) => {
      state.dataSlideLeaveoff = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getListSlideLeaveoff.pending, (state, action: any) => {
        state.dataSlideLeaveoff = [];
        state.loading = true;
        state.error = null;
      })
      .addCase(getListSlideLeaveoff.fulfilled, (state, action: any) => {
        state.dataSlideLeaveoff = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getListSlideLeaveoff.rejected, (state, action: any) => {
        state.dataSlideLeaveoff = [];
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getMultiGroupLeaveoff.pending, (state, action: any) => {
        state.dataSlideLeaveoff = [];
        state.loading = true;
        state.error = null;
      })
      .addCase(getMultiGroupLeaveoff.fulfilled, (state, action: any) => {
        state.dataSlideLeaveoff = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getMultiGroupLeaveoff.rejected, (state, action: any) => {
        state.dataSlideLeaveoff = [];
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getListManageExclude.pending, (state, action: any) => {
        state.loadingStaff = true;
        state.error = null;
      })
      .addCase(getListManageExclude.fulfilled, (state, action: any) => {
        state.listStaffAssign = action?.payload?.data?.result;
        state.totalAssign = action?.payload?.data?.totalResult;
        state.loadingStaff = false;
        state.error = null;
      })
      .addCase(getListManageExclude.rejected, (state, action: any) => {
        state.loadingStaff = false;
        state.error = action.error.message;
      })
      .addCase(getHistoryLeaveOff.pending, (state, action: any) => {
        state.dataHistoryLeave = [];
        state.loading = true;
        state.error = null;
      })
      .addCase(getHistoryLeaveOff.fulfilled, (state, actions: any) => {
        state.dataHistoryLeave = actions.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getHistoryLeaveOff.rejected, (state, actions: any) => {
        state.dataHistoryLeave = [];
        state.loading = false;
        state.error = actions.error.message;
      })
      .addCase(getTotalLeaveOffPaid.pending, (state, action: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTotalLeaveOffPaid.fulfilled, (state, actions: any) => {
        state.dataLeavePaid = actions.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getTotalLeaveOffPaid.rejected, (state, actions: any) => {
        state.dataLeavePaid = [];
        state.loading = false;
        state.error = actions.error.message;
      })
      .addCase(getTotalYearLeaveOffPaid.pending, (state, action: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTotalYearLeaveOffPaid.fulfilled, (state, actions: any) => {
        state.dataYearLeavePaid = actions.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getTotalYearLeaveOffPaid.rejected, (state, actions: any) => {
        state.dataYearLeavePaid = [];
        state.loading = false;
        state.error = actions.error.message;
      })
      .addCase(getListManage.pending, (state, action: any) => {
        state.loadingDataManage = true;
        state.error = null;
      })
      .addCase(getListManage.fulfilled, (state, actions: any) => {
        state.dataManage = actions.payload?.data || [];
        state.loadingDataManage = false;
        state.error = null;
      })
      .addCase(getListManage.rejected, (state, actions: any) => {
        state.dataManage = [];
        state.loadingDataManage = false;
        state.error = actions.error.message;
      })
      .addCase(getHistoryManager.pending, (state, action: any) => {
        state.loadingDataHistoryManager = true;
        state.error = null;
      })
      .addCase(getHistoryManager.fulfilled, (state, actions: any) => {
        state.dataHistoryManager = actions.payload?.data || [];
        state.loadingDataHistoryManager = false;
        state.error = null;
      })
      .addCase(getHistoryManager.rejected, (state, actions: any) => {
        state.dataHistoryManager = [];
        state.loadingDataHistoryManager = false;
        state.error = actions.error.message;
      })
      .addCase(getLeaveTypeList.pending, (state, action: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getLeaveTypeList.fulfilled, (state, actions: any) => {
        state.dataListTypeLeave = actions.payload?.data || [];
        state.loading = false;
        state.error = null;
      })
      .addCase(getLeaveTypeList.rejected, (state, actions: any) => {
        state.dataListTypeLeave = [];
        state.loading = false;
        state.error = actions.error.message;
      });
  },
});

export const selectLeaveOff = (state: RootState) => state.leaveOff;
export const {
  editCompilationDateUser,
  deleteUserSameLeaveOffType,
  resetDataSlideLeaveOff,
  addUserSameLeaveOffType,
} = leaveOffSlice.actions;
export const { reducer, actions } = leaveOffSlice;

export default reducer;

import styled from 'styled-components';
import { Col, Row, Spin } from 'antd';
import { StatisticListPayroll } from './components/StatisticPayroll';
import { PayrollSummary } from './components/PayrollSummary';
import { PayrollSummaryEmployeeType } from './components/PayrollSummaryEmployeeType';
import { HeadcountSummary } from './components/HeadcountSummary';
import { HeadcountSummaryEmployeeType } from './components/HeadcountSummaryEmployeeType';
import { PayrollSummaryLocationDepartment } from './components/PayrollSummaryLocationDepartment';
import { PayrollCostByDesignation } from './components/PayrollCostByDesignation';
import PayrollCostByDesignationChart from './components/PayrollCostByDesignation/PayrollCostByDesignationChart';
import { PayrollCostByDepartment } from './components/PayrollCostByDepartment';
import { PayrollInDetail } from './components/PayrollInDetail';
import { useEffect, useState } from 'react';
import payrollDashboardApi from 'api/payrollDashboardApi';

const DashboardPayroll = () => {
  const [location, setLocation] = useState();
  // const [department, setDepartment] = useState();
  const [project, setProject] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchDataPayrollDashboard = async () => {
      setIsLoading(true);
      const responseDataLocation = await payrollDashboardApi.getLocation();
      // const responseDataDepartment = await payrollDashboardApi.getDepartment();
      const responseDataProject = await payrollDashboardApi.getProject();
      setLocation([...responseDataLocation?.data?.result, { id: 0, name: 'All' }]);
      // setDepartment([...responseDataDepartment?.data?.result, { id: 0, name: 'All' }]);
      setProject([...responseDataProject?.data?.result, { projectCode: '', name: 'All' }]);
      setIsLoading(false);
    };
    fetchDataPayrollDashboard();
  }, []);

  return isLoading ? (
    <div style={{ textAlign: 'center' }}>
      <Spin />
    </div>
  ) : (
    <StyledContainer>
      <Row gutter={[16, 0]} style={{ marginBottom: '16px' }}>
        <Col span={16}>
          <StyledWrapperPayrollSummaryAndStatistic>
            <StatisticListPayroll />
          </StyledWrapperPayrollSummaryAndStatistic>
        </Col>
      </Row>
      <Row gutter={[16, 0]} style={{ marginBottom: '16px' }}>
        <Col span={12}>
          <PayrollSummary />
        </Col>
        <Col span={12}>
          <HeadcountSummary location={location} />
        </Col>
      </Row>
      <Row gutter={[16, 0]} style={{ marginBottom: '16px' }}>
        <Col span={12}>
          <PayrollSummaryEmployeeType />
        </Col>
        <Col span={12}>
          <HeadcountSummaryEmployeeType location={location} />
        </Col>
      </Row>
      <Row gutter={[16, 0]} style={{ marginBottom: '16px' }}>
        <Col span={24}>
          <PayrollSummaryLocationDepartment location={location} department={project} />
        </Col>
      </Row>
      <Row gutter={[16, 0]} style={{ marginBottom: '16px' }}>
        <Col span={12}>
          <PayrollCostByDesignation />
        </Col>
        <Col span={12}>
          <PayrollCostByDesignationChart />
        </Col>
      </Row>
      <Row gutter={[16, 0]} style={{ marginBottom: '16px' }}>
        <Col span={24}>
          <PayrollCostByDepartment location={location} department={project} />
        </Col>
      </Row>
      <Row gutter={[16, 0]} style={{ marginBottom: '16px' }}>
        <Col span={24}>
          <PayrollInDetail location={location} />
        </Col>
      </Row>
    </StyledContainer>
  );
};

export default DashboardPayroll;

export const StyledContainer = styled.div`
  --row-height: 320px;
  --gap: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
`;
const StyledWrapperPayrollSummaryAndStatistic = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--gap);
  height: 100%;
`;

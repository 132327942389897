import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useAppDispatch } from 'app/hooks';
import PageHeader from 'components/PageHeader';
import { getLocations } from 'features/configuration/categoryAction';
import { useEffect } from 'react';
import {
  EmployeeSummary,
  HeadcountByContractType,
  HeadcountByGender,
  HeadcountByLine,
  HeadcountByLocation,
  HeadcountByWorkingTime,
  StatisticListEmployee,
  UpComingOnboardEmployees,
} from '../components';
import { HeadcountPositionChart, HeadcountPositionTable } from '../components/HeadcountByPosition';

export function EmployeeDashboard() {
  const { t } = useTranslation(['dashboard']);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getLocations({}));
  }, []);

  return (
    <>
      <StyledHeading>
        <PageHeader title={t('dashboard_employee.title')} />
      </StyledHeading>
      <StyledContainer>
        <Row gutter={[16, 0]} style={{ marginBottom: '16px' }}>
          <Col span={14}>
            <StyledWrapperEmployeeSummaryAndStatistic>
              <StatisticListEmployee />
              <EmployeeSummary />
            </StyledWrapperEmployeeSummaryAndStatistic>
          </Col>

          <Col span={10}>
            <StyledWrapperUpComingAndLocation>
              <UpComingOnboardEmployees />
              <HeadcountByLocation />
            </StyledWrapperUpComingAndLocation>
          </Col>
        </Row>
        <Row gutter={[16, 0]} style={{ marginBottom: '16px' }}>
          <Col span={14}>
            <HeadcountByLine />
          </Col>
          <Col span={10}>
            <HeadcountByContractType />
          </Col>
        </Row>
        <Row gutter={[16, 0]} style={{ marginBottom: '16px' }}>
          <Col span={14}>
            <HeadcountByWorkingTime />
          </Col>
          <Col span={10}>
            <HeadcountByGender />
          </Col>
        </Row>
        <Row gutter={[16, 0]} style={{ marginBottom: '16px' }}>
          <Col span={14}>
            <HeadcountPositionTable />
          </Col>
          <Col span={10}>
            <HeadcountPositionChart />
          </Col>
        </Row>
      </StyledContainer>
    </>
  );
}

const StyledHeading = styled.div`
  margin: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const StyledContainer = styled.div`
  --row-height: 320px;
  --gap: 16px;
  padding-bottom: 16px;
`;
export const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const StyledCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const StyledWrapperEmployeeSummaryAndStatistic = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--gap);
  height: 100%;
`;
const StyledWrapperUpComingAndLocation = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  gap: var(--gap);
  height: 525px;
`;

import styled, { css } from 'styled-components';
import { Button } from 'antd';
interface CustomButtonProps {
  aType?: 'text' | 'primary' | 'outline';
  disabled?: boolean;
}

const CustomButton = styled(Button)`
  border-radius: 5px;
  ${(props: CustomButtonProps) =>
    props.aType === 'text' &&
    css`
      background-color: var(--cl_white) !important;
      /* color: var(--cl_) !important; */
      /* border: 0 !important; */
      &:hover {
        /* background-color: var(--cl_white) !important; */
        color: var(--cl_primary600) !important;
        border: 1px solid var(--cl_primary600);
      }
      &:focus {
        background-color: var(--cl_white) !important;
        border: 1px solid var(--cl_primary600);
      }
    `}
  ${(props: CustomButtonProps) =>
    (props.aType === 'primary' || !props.aType) &&
    css`
      background-color: var(--cl_primary500) !important;
      color: var(--cl_white);
      border: 1px solid var(--cl_primary500) !important;
      span {
        color: var(--cl_white) !important;
      }
      &:hover {
        background-color: var(--cl_primary600) !important;
        border: 1px solid var(--cl_primary600) !important;
      }
      &:focus {
        background-color: var(--cl_primary600) !important;
        border: 1px solid var(--cl_primary600) !important;
      }
      &[disabled] {
        background-color: hsl(0, 0%, 95%) !important;
        border-color: hsl(0, 0%, 90%) !important;
        color: var(--cl_gray900) !important;
      }
      &[disabled] > span {
        color: var(--cl_gray900) !important;
      }
    `}
  ${(props: CustomButtonProps) => {
    if (props.aType === 'outline' && props.disabled)
      return css`
        background-color: rgba(0, 0, 0, 0.25) !important;
        color: snow !important;
        border: 1px solid snow !important;
        box-shadow: 0 0 1px rgba(0, 0, 0, 1) !important;
      `;
    if (props.aType === 'outline')
      return css`
        background-color: transparent;
        color: var(--cl_primary500) !important;
        border: 1px solid var(--cl_primary500) !important;
        &:hover {
          background-color: var(--cl_primary500) !important;
          color: var(--cl_white) !important;
          border: 1px solid var(--cl_primary500) !important;
        }
        &:focus {
          background-color: var(--cl_primary500) !important;
          color: var(--cl_white) !important;
          border: 1px solid var(--cl_primary500) !important;
        }
      `;
  }}
`;
export default CustomButton;

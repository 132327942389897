import styled, { css } from 'styled-components';
import CountryPhoneInput from 'antd-country-phone-input';

interface ContainerStyledProps {
  istopup?: boolean;
}

export const ContainerStyled = styled.div`
  border: 1px solid var(--cl_border_primary);
  box-sizing: border-box;
  border-radius: 5px;
  position: relative;
  padding: 10px 0px 2px;
  ${(props: ContainerStyledProps) =>
    props.istopup &&
    css`
      margin-top: 1rem;
    `}
`;

export const LabelStyled = styled.div`
  position: absolute;
  top: -0.5rem;
  left: 1rem;
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 19px;
  letter-spacing: 0.5px;
  color: var(--cl_text_primary);
  background-color: white;
`;

export const CountryPhoneInputStyled = styled(CountryPhoneInput)`
  border: none !important;
  outline: none !important;
  border-right-width: 0px;
  box-shadow: none !important;
  width: 100%;
  padding: 0px;
  &:focus {
    outline: none !important;
    border: none !important;
    border-right-width: 0px;
    box-shadow: none !important;
  }
  .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    cursor: pointer;
  }
  .ant-form-item-has-error
    .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
    .ant-select-selector {
    border: none;
    box-shadow: none;
    outline: none;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    box-shadow: none;
    outline: none;
  }
`;

export const InputContainerStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

import { Form, Modal } from 'antd';
import { useAppSelector } from 'app/hooks';
import { getFindOneWorkflowTypeByPayroll } from 'features/configuration/configurationAction';
import { selectConfiguration } from 'features/configuration/configurationSlice';
import {
  StatusFooter,
  StatusIcon,
  StatusItem,
} from 'features/timesheet/components/TimesheetFooter';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { getUser } from 'utils/auth';
import Workflow2ViewStatusPayroll from './Workflow2ViewStatusPayroll';

const ModalViewWorkflowByPayroll = (props: any) => {
  const dispatch = useDispatch();
  const dataStore = useAppSelector(selectConfiguration);
  const { form, setMode, mode, currentItem } = props;

  const { t } = useTranslation([
    'validation',
    'overtime',
    'action',
    'onboard',
    'validation',
    'timesheet',
  ]);

  const dataFindOneWorkflowTypeByPayroll = dataStore?.dataFindOneWorkflowTypeByPayroll?.result?.[0];
  const [edges, setEdges] = useState(dataFindOneWorkflowTypeByPayroll?.newEdges);
  const [nodes, setNodes] = useState(dataFindOneWorkflowTypeByPayroll?.newNodes);
  const [selectAction, setSelectAction] = useState();
  const [selectStatus, setSelectStatus] = useState();

  useEffect(() => {
    let idUser = currentItem.id.split('_')[1];
    const params = {
      payrollDetailId: idUser,
      userId: getUser().id,
      payrollDetailStatus: currentItem?.status,
    };
    dispatch(getFindOneWorkflowTypeByPayroll(params));
  }, [currentItem?.id, currentItem?.status, dispatch]);

  useEffect(() => {
    setSelectAction(dataFindOneWorkflowTypeByPayroll?.actionGroupId); //Khi edit thi dropdown dc
    setSelectStatus(dataFindOneWorkflowTypeByPayroll?.actionGroupId);
  }, [dataFindOneWorkflowTypeByPayroll, mode]);

  return (
    <>
      <Form form={form} layout="vertical">
        <CustomModalWL
          style={{ top: 0 }}
          title={'View workflow type'}
          cancelText={t('timesheet:Cancel')}
          okText={t('timesheet:Save')}
          visible={true}
          onCancel={() => {
            setMode('');
            // setCurrentItem(null);
          }}
          width={1950}
          onOk={() => {}}
          footer={null}
          destroyOnClose
        >
          <Workflow2ViewStatusPayroll
            setNodes={setNodes}
            setEdges={setEdges}
            edges={edges}
            nodes={nodes}
            currentItem={dataFindOneWorkflowTypeByPayroll}
            modalType={mode}
            selectAction={selectAction}
            selectStatus={selectStatus}
          />
          <StatusFooter>
            <StatusItem>
              <StatusIcon style={{ backgroundColor: 'rgb(254, 117, 62)' }} />
              <span
                style={{
                  marginLeft: 5,
                }}
              >
                Node active
              </span>
            </StatusItem>
          </StatusFooter>
        </CustomModalWL>
      </Form>
    </>
  );
};

export default ModalViewWorkflowByPayroll;
export const CustomFormItem = styled(Form.Item)`
  margin-bottom: 5px;
  .ant-form-item-label {
    padding: 0px;
  }
`;
export const CustomModalWL = styled(Modal)`
  .ant-modal-body {
    padding: 0px 24px !important;
    height: 500px !important;
  }
  .ant-modal-content {
    height: 600px !important;
  }
`;

import { useTranslation } from 'react-i18next';
import AdjustmentSalaryTable from './components/AdjustmentSalaryTable';
import { SkillInfoTitle } from './styled';

const AdjustmentInformationFields = (props: any) => {
  const { isShowComponents, employeeData, callBack, monthYear, payrollUpdate } = props;
  const { t } = useTranslation(['employee', 'modal']);

  return (
    <div style={isShowComponents ? {} : { display: 'none' }}>
      <SkillInfoTitle>{t('employee:adjustment_information_fields.information')}</SkillInfoTitle>
      <AdjustmentSalaryTable
        employeeData={employeeData}
        callBack={callBack}
        monthYear={monthYear}
        payrollUpdate={payrollUpdate}
      />
    </div>
  );
};

export default AdjustmentInformationFields;

import {
  getSmartEdge,
  pathfindingJumpPointNoDiagonal,
  svgDrawSmoothLinePath,
} from '@tisoap/react-flow-smart-edge';
import { BezierEdge, useNodes } from 'react-flow-renderer';
import './indexAddEditWL.css';

const foreignObjectSize = 30;

// export default function ButtonEdge(props) {
const ButtonEdge = (props: any) => {
  const {
    sourcePosition,
    targetPosition,
    sourceX,
    sourceY,
    targetX,
    targetY,
    style,
    markerStart,
    markerEnd,
    label,
  } = props;
  const nodes = useNodes();

  const getSmartEdgeResponse = getSmartEdge({
    sourcePosition,
    targetPosition,
    sourceX,
    sourceY,
    targetX,
    targetY,
    nodes,
    options: {
      nodePadding: 20,
      gridRatio: 5,
      drawEdge: svgDrawSmoothLinePath,
      generatePath: pathfindingJumpPointNoDiagonal,
    },
  });

  // If the value returned is null, it means "getSmartEdge" was unable to find
  // a valid path, and you should do something else instead
  if (getSmartEdgeResponse === null) {
    return <BezierEdge {...props} />;
  }

  const { edgeCenterX, edgeCenterY, svgPathString } = getSmartEdgeResponse;

  return (
    <>
      <path
        style={style}
        className="react-flow__edge-path"
        d={svgPathString}
        markerEnd={markerEnd}
        markerStart={markerStart}
      />
      {label && (
        <foreignObject
          width={foreignObjectSize}
          height={foreignObjectSize}
          x={edgeCenterX - foreignObjectSize / 2}
          y={edgeCenterY - foreignObjectSize / 2}
          className="edgebutton-foreignobject"
          requiredExtensions="http://www.w3.org/1999/xhtml"
          style={{ overflow: 'inherit' }}
        >
          <p
            style={{
              fontSize: '14px',
              fontWeight: 700,
              background: 'white',
              border: '1px solid black',
              borderRadius: '10px',
              cursor: 'pointer',
              whiteSpace: 'nowrap',
              display: 'inline-block',
              padding: '3px',
            }}
            className="labelAdd"
          >
            {label}
          </p>
        </foreignObject>
      )}
    </>
  );
};
export default ButtonEdge;

import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import {
  createIncreaseDecrease,
  getGeneralSetting,
  getHistorySetting,
  getSchedulePremium,
  getTypeIncrease,
  getTypeInsurance,
  getLeaveTypeActive,
  getRecordPayment,
  getPaymentHistory,
  getSchedulePremiumApproval,
  getTotalSchedule,
  getDataInsuranceMaster,
  getRecordPay,
  getRejectedHistory,
  getScheduleDecrease,
  getEmployeeSearch,
  getAutoIncreaseDecrease,
  getPaymentHistoryRecord,
  postExportPdf,
  getRecordPaymentYear,
  getPtiParticipant,
} from './insuranceAction';

const insuranceSlice = createSlice({
  name: 'insurance',
  initialState: {
    dataGeneralSetting: [],
    dataHistorySetting: [],
    dataTypeLeave: [],
    dataTypeInsurance: [],
    dataCheckUserID: [],
    dataTypeOf: [],
    dataSchedulePremium: [],
    dataApproval: [],
    dataRecordPayment: [],
    dataRecordPaymentYear: [],
    dataPaymentHistory: [],
    dataPaymentHistoryRecord: [],
    dataOverview: [],
    is_respont: [],
    dataRecordPay: [],
    datarejectHistory: [],
    dataDecrease: [],
    datagetAuto: [],
    dataExport: [],
    error: null,
    loading: false,
    dataPti: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getGeneralSetting.pending, (state, actions: any) => {
        state.loading = true;
        state.dataGeneralSetting = [];
        state.error = null;
      })
      .addCase(getGeneralSetting.fulfilled, (state, actions: any) => {
        state.loading = false;
        state.dataGeneralSetting = actions.payload;
        state.error = null;
      })
      .addCase(getGeneralSetting.rejected, (state, actions: any) => {
        state.loading = false;
        state.dataGeneralSetting = [];
        state.error = actions.error.message;
      })
      .addCase(getHistorySetting.pending, (state, actions: any) => {
        state.loading = true;
        state.dataHistorySetting = [];
        state.error = null;
      })
      .addCase(getHistorySetting.fulfilled, (state, actions: any) => {
        state.loading = false;
        state.dataHistorySetting = actions.payload;
        state.error = null;
      })
      .addCase(getHistorySetting.rejected, (state, actions: any) => {
        state.loading = false;
        state.dataHistorySetting = [];
        state.error = actions.error.message;
      })
      .addCase(getLeaveTypeActive.pending, (state, actions: any) => {
        state.loading = true;
        state.dataTypeLeave = [];
        state.error = null;
      })
      .addCase(getLeaveTypeActive.fulfilled, (state, actions: any) => {
        state.loading = false;
        state.dataTypeLeave = actions.payload;
        state.error = null;
      })
      .addCase(getLeaveTypeActive.rejected, (state, actions: any) => {
        state.loading = false;
        state.dataTypeLeave = [];
      })
      .addCase(getTypeInsurance.pending, (state, actions: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTypeInsurance.fulfilled, (state, actions: any) => {
        state.loading = false;
        state.dataTypeInsurance = actions.payload;
        state.error = null;
      })
      .addCase(getTypeInsurance.rejected, (state, actions: any) => {
        state.loading = false;
        state.error = actions.error.message;
      })
      .addCase(getEmployeeSearch.pending, (state, actions: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getEmployeeSearch.fulfilled, (state, actions: any) => {
        state.loading = false;
        state.dataCheckUserID = actions.payload;
        state.error = null;
      })
      .addCase(getEmployeeSearch.rejected, (state, actions: any) => {
        state.loading = false;
        state.error = actions.error.message;
      })
      .addCase(getTypeIncrease.pending, (state, actions: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTypeIncrease.fulfilled, (state, actions: any) => {
        state.loading = false;
        state.dataTypeOf = actions.payload;
        state.error = null;
      })
      .addCase(getTypeIncrease.rejected, (state, actions: any) => {
        state.loading = false;
        state.error = actions.error.message;
      })
      .addCase(createIncreaseDecrease.pending, (state, actions) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createIncreaseDecrease.fulfilled, (state, actions: any) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(createIncreaseDecrease.rejected, (state, actions: any) => {
        state.loading = false;
        state.error = actions.payload;
      })

      .addCase(getSchedulePremium.pending, (state, actions: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSchedulePremium.fulfilled, (state, actions: any) => {
        state.loading = false;
        state.dataSchedulePremium = actions.payload;
        state.error = null;
      })
      .addCase(getSchedulePremium.rejected, (state, actions: any) => {
        state.loading = false;
        state.error = actions.error.message;
      })

      .addCase(getSchedulePremiumApproval.pending, (state, actions: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSchedulePremiumApproval.fulfilled, (state, actions: any) => {
        state.loading = false;
        state.dataApproval = actions.payload;
        state.error = null;
      })
      .addCase(getSchedulePremiumApproval.rejected, (state, actions: any) => {
        state.loading = false;
        state.error = actions.error.message;
      })

      .addCase(getScheduleDecrease.pending, (state, actions: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getScheduleDecrease.fulfilled, (state, actions: any) => {
        state.loading = false;
        state.dataDecrease = actions.payload;
        state.error = null;
      })
      .addCase(getScheduleDecrease.rejected, (state, actions: any) => {
        state.loading = false;
        state.error = actions.error.message;
      })

      .addCase(getRecordPayment.pending, (state, actions: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getRecordPayment.fulfilled, (state, actions: any) => {
        state.loading = false;
        state.dataRecordPayment = actions.payload;
        state.error = null;
      })
      .addCase(getRecordPayment.rejected, (state, actions: any) => {
        state.loading = false;
        state.error = actions.error.message;
      })

      .addCase(getRecordPaymentYear.pending, (state, actions: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getRecordPaymentYear.fulfilled, (state, actions: any) => {
        state.loading = false;
        state.dataRecordPaymentYear = actions.payload;
        state.error = null;
      })
      .addCase(getRecordPaymentYear.rejected, (state, actions: any) => {
        state.loading = false;
        state.error = actions.error.message;
      })
      .addCase(getRecordPay.pending, (state, actions: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getRecordPay.fulfilled, (state, actions: any) => {
        state.loading = false;
        state.dataRecordPay = actions.payload;
        state.error = null;
      })
      .addCase(getRecordPay.rejected, (state, actions: any) => {
        state.loading = false;
        state.error = actions.error.message;
      })

      .addCase(getPaymentHistory.pending, (state, actions: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPaymentHistory.fulfilled, (state, actions: any) => {
        state.loading = false;
        state.dataPaymentHistory = actions.payload;
        state.error = null;
      })
      .addCase(getPaymentHistory.rejected, (state, actions: any) => {
        state.loading = false;
        state.error = actions.error.message;
      })
      .addCase(getPaymentHistoryRecord.pending, (state, actions: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPaymentHistoryRecord.fulfilled, (state, actions: any) => {
        state.loading = false;
        state.dataPaymentHistoryRecord = actions.payload;
        state.error = null;
      })
      .addCase(getPaymentHistoryRecord.rejected, (state, actions: any) => {
        state.loading = false;
        state.error = actions.error.message;
      })
      .addCase(getDataInsuranceMaster.pending, (state, actions: any) => {
        state.loading = true;
        state.dataOverview = [];
        state.error = null;
      })
      .addCase(getDataInsuranceMaster.fulfilled, (state, actions: any) => {
        state.loading = false;
        state.dataOverview = actions.payload;
        state.error = null;
      })
      .addCase(getDataInsuranceMaster.rejected, (state, actions: any) => {
        state.loading = false;
        state.dataOverview = [];
        state.error = actions.message;
      })
      .addCase(getTotalSchedule.pending, (state, actions: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTotalSchedule.fulfilled, (state, actions: any) => {
        state.loading = false;
        state.is_respont = actions.payload;
        state.error = null;
      })
      .addCase(getTotalSchedule.rejected, (state, actions: any) => {
        state.loading = false;
        state.error = actions.error.message;
      })
      .addCase(getRejectedHistory.pending, (state, actions: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getRejectedHistory.fulfilled, (state, actions: any) => {
        state.loading = false;
        state.datarejectHistory = actions.payload;
        state.error = null;
      })
      .addCase(getRejectedHistory.rejected, (state, actions: any) => {
        state.loading = false;
        state.error = actions.error.message;
      })
      .addCase(getAutoIncreaseDecrease.pending, (state, actions: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAutoIncreaseDecrease.fulfilled, (state, actions: any) => {
        state.loading = false;
        state.datagetAuto = actions.payload;
        state.error = null;
      })
      .addCase(getAutoIncreaseDecrease.rejected, (state, actions: any) => {
        state.loading = false;
        state.error = actions.error.message;
      })
      .addCase(postExportPdf.pending, (state, actions: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(postExportPdf.fulfilled, (state, actions: any) => {
        state.loading = false;
        state.dataExport = actions.payload;
        state.error = null;
      })
      .addCase(postExportPdf.rejected, (state, actions: any) => {
        state.loading = false;
        state.error = actions.error.message;
      })
      .addCase(getPtiParticipant.pending, (state, actions: any) => {
        state.error = null;
      })
      .addCase(getPtiParticipant.fulfilled, (state, actions: any) => {
        state.dataPti = actions.payload?.data;
        state.error = null;
      })
      .addCase(getPtiParticipant.rejected, (state, actions: any) => {
        state.loading = false;
        state.error = actions.error.message;
      });
  },
});
export const selectInsurance = (state: RootState) => state.insurance;
export const { reducer, actions } = insuranceSlice;

export default reducer;

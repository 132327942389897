import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import CustomFormItem from 'styles/formStyled';

interface ModalFormReasonProps {
  title: string;
  isModalReason: boolean;
  setIsModalReason: React.Dispatch<React.SetStateAction<boolean>>;
  handleFinish: any;
  moreField?: boolean;
  defaultReason?: string;
}
export default function ModalFormReason({
  title,
  isModalReason,
  setIsModalReason,
  handleFinish,
  moreField,
  defaultReason,
}: ModalFormReasonProps) {
  const { t } = useTranslation(['timesheet', 'onboard', 'validation']);

  const [form] = Form.useForm();

  return (
    <CustomModal
      okText={t('Save')}
      width={350}
      visible={isModalReason}
      title={title}
      onCancel={() => {
        setIsModalReason(false);
        form.resetFields();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            handleFinish(values);
            form.resetFields();
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form form={form} name="dynamic_form_item" initialValues={{ reason: defaultReason }}>
        {moreField ? (
          <Form.List name="reason">
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field) => (
                  <CustomFormItem key={field.key} required={false}>
                    <Form.Item
                      {...field}
                      validateTrigger={['onChange', 'onBlur']}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: t('please_enter_the_reason'),
                        },
                      ]}
                      noStyle
                    >
                      <CustomInput.TextArea placeholder={t('please_enter_the_reason')} />
                    </Form.Item>
                    {fields.length > 1 ? (
                      <ButtonDeletedField onClick={() => remove(field.name)} />
                    ) : null}
                  </CustomFormItem>
                ))}
                <CustomFormItem>
                  <Button
                    type="dashed"
                    onClick={() => add(null, 0)}
                    style={{ width: '100%' }}
                    icon={<PlusOutlined />}
                  >
                    Add field
                  </Button>
                  <Form.ErrorList errors={errors} />
                </CustomFormItem>
              </>
            )}
          </Form.List>
        ) : (
          <Form.Item
            rules={[
              {
                required: true,
                message: t('validation:required', {
                  field: t('Reason'),
                }),
              },
              {
                whitespace: true,
                message: t('validation:is_whitespace', {
                  field: t('Reason'),
                }),
              },
            ]}
            name="reason"
            style={{ marginBottom: 'unset' }}
          >
            <CustomInput.TextArea placeholder={t('please_enter_the_reason')} />
          </Form.Item>
        )}
      </Form>
    </CustomModal>
  );
}
const CustomModal = styled(Modal)`
  .ant-modal {
    &-content {
      font-family: 'Roboto';
      .ant-modal-header {
        padding: 16px 18px;
      }
      .ant-modal-body {
        padding: 18px;
      }
    }
    &-footer {
      border-top: unset;
      .ant-btn {
        border-radius: var(--bd_radius_small);
        box-shadow: unset;
        padding: 0 30px;
        height: 34px;
      }
      .ant-btn-default {
        display: none;
      }
    }
  }
`;
const CustomInput = styled(Input)`
  border-radius: var(--bd_radius_small);
  margin-right: 5px;
`;
const ButtonDeletedField = styled(MinusCircleOutlined)`
  transition: all 0.3s;
  font-size: 16px;
  position: absolute;
  right: -24px;
  top: 8px;
  color: var(--cl_border_primary);
  &:hover {
    color: black;
  }
`;

import { memo } from 'react';
import { Spin } from 'antd';
import styled from 'styled-components';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import { EmployeeGrowthReport } from 'types';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const options = {
  plugins: {
    legend: {
      display: false,
      position: 'bottom' as const,
    },
  },
  maintainAspectRatio: false,
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      beginAtZero: true,
      grid: {
        borderDash: [8, 6],
        color: '#CDD5DF',
      },
    },
  },
  borderWidth: 1.5,
  pointRadius: 4,
  pointBackgroundColor: '#fff',
};

interface EmployeeGrowthChartProps {
  loading: boolean;
  dataChart: EmployeeGrowthReport;
  onLineClick?: (label: string, type: string) => void;
}

const StyledWrapperChart = styled.div`
  width: 100%;
  height: 290px;
`;

function PayrollSummaryChart({ loading, dataChart, onLineClick }: EmployeeGrowthChartProps) {
  return (
    <Spin spinning={loading}>
      <StyledWrapperChart>
        <Line
          data={dataChart}
          options={options}
          getElementAtEvent={(elements, event) => {
            if (event.type === 'click' && elements.length) {
              const label = dataChart.labels[elements[0].index];
              const type = dataChart.datasets[elements[0].datasetIndex].label;

              onLineClick?.(label, type);
            }
          }}
        />
      </StyledWrapperChart>
    </Spin>
  );
}

export default memo(PayrollSummaryChart);

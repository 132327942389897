import employeeApi from 'api/employeeApi';
import { useEffect, useState } from 'react';
import { LineCategory } from 'types';

export const useLineCategoryList = () => {
  const [lineCategoryList, setLineCategoryList] = useState<Array<LineCategory>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<unknown>(undefined);

  useEffect(() => {
    const fetchLineCategoryListAPI = async () => {
      try {
        setIsLoading(true);
        const response = await employeeApi.getLineCategory();

        setLineCategoryList(response.data?.result || []);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchLineCategoryListAPI();
  }, []);

  return { data: lineCategoryList, isLoading, error };
};

import { CaretRightOutlined, UpCircleOutlined } from '@ant-design/icons';
import { Card, Collapse } from 'antd';
import iconAppointment from 'assets/images/appointment.png';
import iconRight from 'assets/images/icon_right.png';
import BgrContainer from 'assets/images/istock-1140524580_3.jpg';
import iconMedicalApp from 'assets/images/medical-app.png';
import iconstet from 'assets/images/stethoscope.png';
import iconbed from 'assets/images/xclaim_bed.png';
import iconBill from 'assets/images/xclaim_bills.png';
import iconEnergy from 'assets/images/xclaim_bulk_energy.png';
import iconCCCD from 'assets/images/xclaim_cccd.png';
import iconExit from 'assets/images/xclaim_exit.png';
import iconHlth from 'assets/images/xclaim_hlth_quote.png';
import iconHome from 'assets/images/xclaim_homes.png';
import iconHospital from 'assets/images/xclaim_hospital.png';
import iconCardInsurance from 'assets/images/xclaim_insurance_card.png';
import iconBillInternet from 'assets/images/xclaim_internet_bill.png';
import iconBillInternet2 from 'assets/images/xclaim_internet_bill_2.png';
import iconEmail from 'assets/images/xclaim_mail.png';
import iconMoney from 'assets/images/xclaim_money.svg';
import iconPhone from 'assets/images/xclaim_phone.png';
import iconPregnant from 'assets/images/xclaim_pregnant.png';
import iconTooth from 'assets/images/xclaim_tooth.png';
import iconUser from 'assets/images/xclaim_user.png';
import iconUsers from 'assets/images/xclaim_users.png';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

const { Panel } = Collapse;

const PageXclaim = () => {
  const listGeneral = [
    {
      left: true,
      icon: iconHome,
      title: 'Nhà bảo hiểm​',
      listText: [
        {
          text: 'Bảo hiểm Bưu Điện (PTI) - Bảo hiểm thuộc top 2 thị trường Việt Nam',
        },
      ],
    },
    {
      left: false,
      icon: iconEnergy,
      title: 'Phạm vi bảo hiểm​​',
      listText: [
        {
          text: 'Tai nạn: Toàn cầu​',
        },
        {
          text: 'Sức khỏe: Việt Nam​',
        },
      ],
    },
    {
      left: true,
      icon: iconAppointment,
      title: 'Thời hạn bảo hiểm​',
      listText: [
        {
          text: '17/08/2023-16/08/2024​',
        },
      ],
    },
    {
      left: false,
      icon: iconUsers,
      title: 'Đối tượng tham gia​​',
      listText: [
        {
          text: 'Nhân viên: 18 – 65 tuổi​​',
        },
        {
          text: 'Người thân: vợ/chồng: 18 – 65 tuổi​',
        },
        {
          text: 'Con từ 15 ngày tuổi – 18 tuổi, hoặc 25 tuổi nếu đang học dài hạn, chưa đi làm và chưa kết hôn​',
        },
      ],
    },
    {
      left: true,
      icon: iconHospital,
      title: 'Nơi điều trị bệnh/ tai nạn/ thai sản/ nha khoa hợp lệ​​',
      listText: [
        {
          text: 'Tất cả các cơ sở y tế có giấy phép đăng ký thành lập và kinh doanh hợp pháp và xuất được hóa đơn VAT theo quy định của Bộ Tài Chính ​​​',
        },
      ],
    },
    {
      left: false,
      icon: iconExit,
      title: 'Nơi điều trị không hợp lệ​',
      listText: [
        {
          text: 'Phòng mạch bác sĩ tư​​',
        },
        {
          text: 'Các cơ sở y tế trong danh sách bị loại trừ của PTI​​​',
        },
      ],
    },
    {
      left: true,
      icon: iconHlth,
      title: 'Điều kiện tham gia:​​',
      listText: [
        {
          text: 'Không bị bệnh tâm thần, bệnh động kinh​',
        },
        {
          text: 'Không bị ung thư ( người tham gia mới)​​​​',
        },
        {
          text: 'Không bị thương tật vĩnh viễn từ 80% trở lên​',
        },
      ],
    },
  ];

  const listHospital = [
    {
      province: 'Bình Dương',
      name: 'PKĐK Nguyễn Trãi - Thủ Dầu Một',
      address: ['303 Đại Lộ Bình Dương, Phường Chánh Nghĩa, TP Thủ Dầu Một, Tỉnh Bình Dương​'],
    },
    {
      province: 'Bình Dương',
      name: 'PKĐK Đại Tín',
      address: ['306 Đại Lộ Bình Dương, Phú Hoà, Thủ Dầu Một, Tỉnh Bình Dương​​'],
    },
    {
      province: 'Cần Thơ​',
      name: 'PKĐK Thế Kỷ Mới​',
      address: ['133A Trần Hưng Đạo, Quận Ninh Kiều, TP Cần Thơ​​​'],
    },
    {
      province: 'Đà Nẵng​​',
      name: 'Nha Khoa Happy/ Bác sĩ Vũ Duy Hưng​',
      address: ['26 Hàm Nghi, TP Đà Nẵng​​'],
    },
    {
      province: 'Đồng Nai​​',
      name: 'PK Âu Mỹ Việt​',
      address: ['203A Phạm Văn Thuận, Phường Tân Tiến, TP Biên Hòa, Tỉnh Đồng Nai​​'],
    },
    {
      province: 'Đồng Nai​​',
      name: 'Nha khoa Nam Sài Gòn​',
      address: ['141/7 Phạm Văn Thuận, Biên Hòa, Tỉnh Đồng Nai​'],
    },
    {
      province: 'Hà Nội​​',
      name: 'Nha khoa Hòa An​​',
      address: ['6 Phạm Ngọc Thạch, Đống Đa, Hà Nội​​'],
    },
    {
      province: 'Hà Nội​​',
      name: 'Phòng khám Medita​',
      address: ['376 Xã Đàn, Nam Đồng, Quận Đống Đa, Hà Nội​​'],
    },
    {
      province: 'Hà Nội​​',
      name: 'PKĐK Thiên Tâm​​',
      address: ['212 Nguyễn Lương Bằng, Đống Đa, Hà Nội​​​'],
    },
    {
      province: 'Hà Nội​​',
      name: 'Nha khoa Shinbi​​',
      address: ['33, Trần Quốc Toản, Phường Trần Hưng Đạo, Q, Hoàn Kiếm, Hà Nội​​​​'],
    },
    {
      province: 'Hà Nội​​',
      name: 'PKĐK Đông Phương​​',
      address: ['497 Quang Trung, Hà Đông, Hà Nội​​​​​'],
    },
    {
      province: 'TP HCM​​​',
      name: 'Nha Khoa Cali',
      address: ['303 Cách Mạng Tháng 8, Phương 12, Quận 10, TP HCM​​​​​​'],
    },
    {
      province: 'TP HCM​​​',
      name: 'Nha khoa Nhật Mỹ 2​',
      address: ['Số 9 Vĩnh Hội, Phường 4, Quận 4, TP HCM​'],
    },
    {
      province: 'TP HCM​​​',
      name: 'Nha Khoa Đakao (Quách Thị Bích Trâm)​​',
      address: ['123B Nơ Trang Long, Phường 11, Quận Bình Thạnh, TP HCM​'],
    },
    {
      province: 'TP HCM​​​',
      name: 'Nha khoa Việt Hưng ​​',
      address: ['17-19 Lê Văn Việt, Phường Hiệp Phú, Quận 9, TP HCM​​'],
    },
    {
      province: 'TP HCM​​​',
      name: 'PKĐK Elizabet / PKĐK Khang Thái / PKĐK Thành Thái / PKĐK Hồng Cường',
      address: ['87-89 Thành Thái, Phường 14, Quận 10, TP HCM​​'],
    },
    {
      province: 'TP HCM​​​',
      name: 'PKĐK MaYO / PKĐK Hà Đô​',
      address: ['35B-35C Ba Tháng Hai, Phường 11, Quận 10, TP HCM​'],
    },
    {
      province: 'TP HCM​​​',
      name: 'PKĐK Hoàn Cầu​',
      address: ['80-82 Châu Văn Liêm, Phường 11, Quận 5, TP HCM​'],
    },
    {
      province: 'TP HCM​​​',
      name: 'Hệ thống Chi nhánh PKĐK Liên Tâm​​',
      address: [
        '67 Liên Tỉnh 5, Phường 5, Quận 8, TP HCM',
        'R4-08 Hưng Phước 2, Phường Tân Phong, Quận 7, Thành Phố Hồ Chí Minh.​',
        '59/1 Huỳnh Tấn Phát, khu phố 4, Thị Trấn Nhà Bè, Huyện Nhà Bè, TP Hồ Chí Minh​​',
      ],
    },
    {
      province: 'TP HCM​​​',
      name: 'PKĐK Nguyễn Trãi​',
      address: ['277 Nguyễn Trãi, Phường Nguyễn Cư Trinh, Quận 1, TP HCM​​​'],
    },
    {
      province: 'TP HCM​​​',
      name: 'PKĐK Apollo (Công ty TNHH Y Tế Apollo) / PKĐK Phú Khang​​',
      address: ['228 – 228A, Trần Hưng Đạo, Phường Nguyễn Cư Trinh, Quận 1, TP HCM​​'],
    },
    {
      province: 'TP HCM​​​',
      name: 'PKĐK Thái Bình Dương / PKĐK Đinh Tiên Hoàng​​​',
      address: ['34-36 Đinh Tiên Hoàng, Phường Đakao, Quận 1, TP HCM​​'],
    },
    {
      province: 'TP HCM​​​',
      name: 'PKĐK Thế Giới​​',
      address: ['648 Võ Văn Kiệt, Phường 1, Quận 5, TP HCM​​'],
    },
    {
      province: 'TP HCM​​​',
      name: 'PKĐK Thăng Long​​',
      address: ['575 Sư Vạn Hạnh, Phường 13, Quận 10, TP HCM​​'],
    },
    {
      province: 'TP HCM​​​',
      name: 'PKĐK Kiều Tiên​​',
      address: ['323-325, Lê Quang Định, Phường 5, Quận Bình Thạnh, TP HCM​​'],
    },
    {
      province: 'TP HCM​​​',
      name: 'Phòng khám Nam Khoa - Phụ Khoa Đại Đông/ PKĐK Đại Đông​​',
      address: ['461 Cộng Hòa, Phường 15, Quận Tân Bình, TP HCM​​​'],
    },
    {
      province: 'TP HCM​​​',
      name: 'PKĐK Hồng Bàng​​​',
      address: ['877-879 Hồng Bàng, Quận 6, TP HCM​​​​'],
    },
    {
      province: 'TP HCM​​​',
      name: 'PKĐK Baylor (thuộc Công ty TNHH đầu tư y tế Quốc tế Đông Á) / PKĐK Nam Việt​​​​',
      address: ['202 Tô Hiến Thành, Phường 15, Quận 10, TP HCM​​​​​'],
    },
    {
      province: 'TP HCM​​​',
      name: 'Nha khoa Tâm Đức​​​​​',
      address: [
        'Cs1: 125/2 Nguyễn Ảnh Thủ, Xã Trung Chánh, Huyện Hóc Môn, TP HCM​',
        'Cs2: 363A-364A Nguyễn Ảnh Thủ, Phường Trung Mỹ Tây, Q12, TP HCM',
      ],
    },
    {
      province: 'TP HCM​​​',
      name: 'PKĐK Bác Ái​​​​​​',
      address: [
        'Cs1: Số 601B, Đường Cách Mạng Tháng Tám, Phường 15, Quận 10, TP HCM​',
        'Cs2: 35 Đường O, Mỹ Giang 2B, Phú Mỹ Hưng, Phường Tân Phong, Quận 7, TP HCM​',
      ],
    },
    {
      province: 'TP HCM​​​',
      name: 'PKĐK Hồng Phong​​​​​​​',
      address: ['160-162 Lê Hồng Phong, Phường 3, Quận 5, TP HCM​​'],
    },
    {
      province: 'TP HCM​​​',
      name: 'PKĐK Âu Á​​',
      address: ['425 Nguyễn Văn Luông, Phường 12, Quận 6, TP HCM​​'],
    },
    {
      province: 'TP HCM​​​',
      name: 'PKĐK Đại Việt/ PKĐK Ba Tháng Hai​',
      address: ['1503-1505-1507-1509 Đường Ba Tháng Hai, Phường 16, Quận 11, TP HCM​​​'],
    },
    {
      province: 'TP HCM​​​',
      name: 'PKĐK Quốc tế​',
      address: ['221 Nguyễn Thị Minh Khai, Phường Nguyễn Cư Trinh, Q1, TP HCM​​​​'],
    },
    {
      province: 'TP HCM​​​',
      name: 'PKĐK Thái Việt​​',
      address: ['165 Nguyễn Văn Cừ, Phường 2, Quận 5, TP HCM​​'],
    },
  ];

  const listWaiting = [
    {
      waiting: 'Tai nạn​',
      employee: 'Không áp dụng​',
      people: 'Không áp dụng​',
    },
    {
      waiting: 'Điều trị do bệnh thông thường​​',
      employee: 'Không áp dụng​',
      people: 'Không áp dụng​',
    },
    {
      waiting: 'Điều trị do bệnh có sẵn và bệnh đặc biệt​​​',
      employee: 'Không áp dụng​',
      people: '365 ngày​​',
    },
    {
      waiting: 'Biến chứng thai sản, sảy thai, nạo thai​​​​',
      employee: '60 ngày – bồi thường theo tỷ lệ​​',
      people: '90 ngày​​​',
    },
    {
      waiting: 'Sinh con​​',
      employee: '270 ngày – bồi thường theo tỷ lệ​​',
      people: '270 ngày​​​',
    },
    {
      waiting: 'Tử vong/ TTVV do bệnh thông thường​​',
      employee: '30 ngày​​',
      people: '30 ngày​​​​',
    },
    {
      waiting: 'Tử vong/ TTVV do bệnh đặc biệt, bệnh có sẵn và thai sản​​​',
      employee: '365 ngày​​',
      people: '365 ngày​',
    },
  ];

  const listAdvanceSumary = [
    {
      advance: (
        <>
          <p>
            <b>A. Tử vong/ Tàn tật toàn bộ vĩnh viễn do bệnh, thai sản​</b>
          </p>
          <p>- Tàn tật bộ phận vĩnh viễn do bệnh/ thai sản: chi trả theo bảng tỷ lệ thương tật​</p>
        </>
      ),
      employee: <p>50.000.000/ người​</p>,
      people: <p>50.000.000/ người​</p>,
    },
    {
      advance: (
        <>
          <p>
            <b>B. Tử vong/ thương tật vĩnh viễn do tai nạn​</b>
          </p>
          <p>- Thương tật bộ phận vĩnh viễn do tai nạn: : chi trả theo bảng tỷ lệ thương tật​​</p>
        </>
      ),
      employee: <p>50.000.000/ người​</p>,
      people: <p>50.000.000/ người​</p>,
    },
    {
      advance: (
        <>
          <p>
            <b>C. Chi phí y tế do tai nạn​​</b>
          </p>
          <p>
            Bao gồm cả chi phí vận khẩn cấp (loại trừ vận chuyển bằng hàng không và dịch vụ SOS/
            IPA)​​​
          </p>
        </>
      ),
      employee: <p>50.000.000/ người​</p>,
      people: <p>50.000.000/ người​</p>,
    },
    {
      advance: (
        <p>
          <b>D. Phẫu thuật và nằm viện do bệnh tật, thai sản</b> (bao gồm điều trị trong ngày)​
        </p>
      ),
      employee: <p>50.000.000/ người​</p>,
      people: <p>50.000.000/ người​</p>,
    },
    {
      advance: (
        <p>
          <b>E. Điều trị ngoại trú do ốm bệnh, thai sản</b> (bao gồm chăm sóc răng cơ bản)​​
        </p>
      ),
      employee: <p>7.500.000/ năm​​</p>,
      people: <p>7.000.000/ người​</p>,
    },
    {
      advance: (
        <p>
          <b>F. Trợ cấp mất giảm thu nhập do bị tai nạn và/hoặc bị ốm bệnh</b>
        </p>
      ),
      employee: (
        <>
          <p>200.000/ ngày​</p>
          <p>Tối đa 90 ngày/ năm, 18.000.000/ năm​​</p>
        </>
      ),
      people: <p>N.A​</p>,
    },
  ];

  const listDetailsFirst = [
    {
      advance: (
        <p>
          <b>D. Phẫu thuật và nằm viện do bệnh tật, thai sản (bao gồm điều trị trong ngày)​</b>
        </p>
      ),
      employee: (
        <p>
          <b>50.000.000/ năm​</b>
        </p>
      ),
      people: (
        <p>
          <b>50.000.000/ năm​</b>
        </p>
      ),
    },
    {
      advance: (
        <>
          <p>
            <b>1. Viện phí/ngày</b> (bao gồm điều trị trong ngày) ​
          </p>
          <p>- Tiền giường, phòng/ ngày: Giới hạn phòng đơn tiêu chuẩn​</p>
          <p>- Suất ăn theo tiêu chuẩn của bệnh viện​</p>
          <p>- Phòng chăm sóc đặc biệt (ICU)​​</p>
          <p>- Chi phí hành chính, chi phí máu, huyết tương​​</p>
          <p>- Thuốc trong thời gian nằm viện​​</p>
          <p>- Tiêm tĩnh mạch​​​</p>
          <p>- Chi phí nạng, nẹp và bó bột, Vật lí trị liệu​​​</p>
          <p>
            - Chụp X-quang, MRI, CT, PET và các xét nghiệm chẩn đoán khác theo chỉ định của bác sĩ​​
          </p>
          <p>- Chi phí sinh thường​​​</p>
          <p>- Chi phí y tế khác theo chỉ định của bác sĩ​​​​</p>
        </>
      ),
      employee: (
        <>
          <p>2.500.000/ ngày​</p>
          <p>Tối đa 60 ngày/ năm​</p>
        </>
      ),
      people: (
        <>
          <p>2.500.000/ ngày​</p>
          <p>Tối đa 60 ngày/ năm​</p>
        </>
      ),
    },
    {
      advance: (
        <>
          <p>
            <b>2. Chi phí phẫu thuật và thủ thuât/ năm​</b>
          </p>
          <p>
            - Chi phí phẫu thuật, thủ thuật điều trị (bao gồm thủ thuật điều trị trong ngày và nội
            trú)​​
          </p>
          <p>- Chi phí thuốc điều trị, thuốc dùng trong phẫu thuật​​</p>
          <p>- Những bộ phận giả thiết yếu cần được cấy ghép vào cơ thể để duy trì sự sống​​​</p>
          <p>- Vật tư tiêu hao, thiết bị cần thiết​​​</p>
          <p>- Chi phí phòng mổ, Chi phí gây mê​​​</p>
          <p>- Chi phí chẩn đoán trước phẫu thuật, Chi phí hồi sức sau phẫu thuật​​​​</p>
          <p>- Chi phí cấy ghép nội tạng​​​​</p>
          <p>- Chi phí phẫu thuật lại​</p>
        </>
      ),
      employee: <p>Tối đa hạn mức D​​</p>,
      people: <p>Tối đa hạn mức D​​</p>,
    },
    {
      advance: (
        <p>
          <b>3. Các quyền lợi khác</b>​
        </p>
      ),
      employee: <></>,
      people: <></>,
    },
    {
      advance: (
        <p>a. Chi phí điều trị trước khi nhập viện (trong vòng 30 ngày trước khi nhập viện)​​</p>
      ),
      employee: <p>2.500.000/ năm​</p>,
      people: <p>2.500.000/ năm​</p>,
    },
    {
      advance: (
        <p>b. Chi phí tái khám ngay sau khi xuất viện (trong vòng 30 ngày sau khi xuất viện)​​​</p>
      ),
      employee: <p>2.500.000/ năm​</p>,
      people: <p>2.500.000/ năm​</p>,
    },
    {
      advance: <p>c. Chi phí y tá chăm sóc tại nhà (trong vòng 30 ngày sau khi xuất viện)​​​​</p>,
      employee: <p>2.500.000/ năm​</p>,
      people: <p>2.500.000/ năm​</p>,
    },
    {
      advance: (
        <>
          <p>d. Trợ cấp nằm viện (60 ngày/ năm)​​​​​</p>
          <p>Trường hợp nằm viện có kết hợp sử dụng BHYT nhà nước​​</p>
        </>
      ),
      employee: (
        <>
          <p>50.000/ ngày​​</p>
          <p>100.000/ ngày​​​</p>
        </>
      ),
      people: (
        <>
          <p>50.000/ ngày​​</p>
          <p>100.000/ ngày​​​</p>
        </>
      ),
    },
    {
      advance: <p>e. Chi phí phục hồi chức năng​​​</p>,
      employee: <p>4.000.000/ năm​</p>,
      people: <p>4.000.000/ năm</p>,
    },
    {
      advance: (
        <p>
          f. Vận chuyển khẩn cấp/ năm​ Tối đa VND 500,000/ người/ năm cho chi phí taxi (loại trừ vận
          chuyển bằng đường hàng không và/ hoặc dịch vụ SOS/ IPA)​​
        </p>
      ),
      employee: <p>Đến hạn mức tối đa​​​</p>,
      people: <p>Đến hạn mức tối đa​​</p>,
    },
    {
      advance: <p>g. Trợ cấp mai táng ​​​</p>,
      employee: <p>2.500.000​​​​</p>,
      people: <p>2.500.000​​​</p>,
    },
  ];

  const listDetailsSecond = [
    {
      advance: (
        <>
          <p>
            <b>4. Thai sản và sinh đẻ​</b>
          </p>
          <p>
            (Sinh thường, sinh mổ, biến chứng thai sản: dọa sinh non, động thai, sẩy thai, thiếu ối
            nặng, vô ối, suy thai…)​
          </p>
        </>
      ),
      employee: (
        <>
          <p>Tối đa 25.000.000/ năm​</p>
          <p>
            Được bảo hiểm từ giới hạn phụ 1 đến 3 (trừ 3c, 3d, 3e) của quyền lợi Điều trị Nội trú.​​
          </p>
          <p>- Viện phí/ngày​</p>
          <p>- Chi phí phẫu thuật​​</p>
          <p>- Chi phí trước nhập viện & sau xuất viện​​​</p>
          <p>- Vận chuyển cấp cứu​​</p>
          <p>- Trợ cấp mai táng​​​</p>
        </>
      ),
      people: (
        <>
          <p>Tối đa 25.000.000/ năm​</p>
          <p>
            Được bảo hiểm từ giới hạn phụ 1 đến 3 (trừ 3c, 3d, 3e) của quyền lợi Điều trị Nội trú.​​
          </p>
          <p>- Viện phí/ngày​</p>
          <p>- Chi phí phẫu thuật​​</p>
          <p>- Chi phí trước nhập viện & sau xuất viện​​​</p>
          <p>- Vận chuyển cấp cứu​​</p>
          <p>- Trợ cấp mai táng​​​</p>
        </>
      ),
    },
    {
      advance: (
        <p>
          <b>- Chi phí dưỡng nhi</b> trong vòng 7 ngày sau khi sinh tại bệnh viện (loại trừ chi phí
          xét nghiệm tầm soát, chi phí điều trị cho em bé, vật dụng cá nhân và chi phí thức ăn cho
          em bé)​
        </p>
      ),
      employee: <p>600.000​​</p>,
      people: <p>600.000​​</p>,
    },
  ];

  const listDefine = [
    {
      color: '#48086f',
      text: 'Một lần khám/ điều trị Là một lần người bệnh được Bác sï khám, chỉ định thực hiện xét nghiệm, Chẩn đoán hình ảnh và thuốc điều trị nhằm mục đích chuẩn đoán bệnh và điều trị​',
    },
    {
      color: '#C900AC',
      text: 'Trường hợp nhiều Bác sĩ chuyên khoa cùng hội Chuẩn trước một người bệnh theo quy chế bệnh viện chỉ được tính là một lần khám/ điều trị;​​',
    },
    {
      color: '#F6517F',
      text: 'Trường hợp người bệnh khám một chuyên khoa nhiều lần trong ngày thì chỉ tính là một lần khám bệnh;​​',
    },
    {
      color: '#FF8204',
      text: 'Trường hợp người bệnh khám nhiều chuyên khoa trong cùng một cơ sở y tế, nếu có chỉ định và chuẩn đoán bệnh của Bác sĩ thì mỗi chuyên khoa được tính là một lần khám/ điều trị.​​',
    },
    {
      color: '#3ADCC9',
      text: 'Chi phí tái khám ngay sau đó theo chỉ định của bác sĩ dù không còn bệnh và không cần điều trị nữa sẽ được coi là một lần khám mới và được bảo hiểm dưới đơn này.​​',
    },
    {
      color: '#7816B4',
      text: 'Riêng trường hợp người được bảo hiểm phải khám thêm một số chuyên khoa khác dù có chỉ định của bác sỹ trong cùng một cơ sở y tế, tương ứng với mỗi khoa được tính là 1 lần khám bệnh.​',
    },
  ];

  const listDetailsThird = [
    {
      advance: (
        <p>
          <b>E. Điều trị ngoại trú do ốm bệnh, thai sản​</b> (bao gồm chăm sóc răng cơ bản)
        </p>
      ),
      employee: (
        <p>
          <b>7.500.000/ năm​</b>
        </p>
      ),
      people: (
        <p>
          <b>7.000.000/ năm​</b>
        </p>
      ),
    },
    {
      advance: (
        <>
          <p>
            <b>1. Chi phí y tế phát sinh một lần khám ngoại trú</b> bao gồm:​
          </p>
          <p>- Chi phí Khám bệnh</p>
          <p>- Chi phí thuốc điều trị theo chỉ định của bác sĩ</p>
          <p>- Chi phí xét nghiệm, chẩn đoán hình ảnh và điều trị bệnh do bác sĩ chỉ định​</p>
          <p>- Biến chứng thai sản (ngoại trú)​</p>
          <p>- Thủ thuật ngoại trú​​</p>
          <p>- Chi phí khám Vật lý trị liệu lần đầu tiên đối với mỗi đợt (lộ trình) điều trị​​​</p>
        </>
      ),
      employee: (
        <>
          <p>1.800.000/ lần khám​</p>
          <p>Không giới hạn số lần khám/ năm​</p>
        </>
      ),
      people: (
        <>
          <p>1.700.000/ lần khám​</p>
          <p>Không giới hạn số lần khám/ năm​</p>
        </>
      ),
    },
    {
      advance: (
        <>
          <p>
            <b>2. Điều trị bằng các phương pháp xạ trị, liệu pháp nhiệt, liệu pháp quang tuyến</b>{' '}
            hoặc các trị liệu tương ứng khác do bác sĩ chỉ định thực hiện tại cơ sở y tế hợp pháp,
            tối đa 60 ngày/ năm​
          </p>
          <p>(loại trừ hệ thống phòng khám ACC, Prochiro và Maple Healthcare)​</p>
        </>
      ),
      employee: (
        <>
          <p>120.000/ ngày​​</p>
        </>
      ),
      people: (
        <>
          <p>120.000/ ngày​​</p>
        </>
      ),
    },
  ];

  const listDetailsFour = [
    {
      advance: (
        <>
          <p>
            <b>Điều trị răng/ năm​</b>
          </p>
        </>
      ),
      employee: <></>,
      people: <></>,
    },
    {
      advance: (
        <>
          <p>- Chi phí khám, xét nghiệm, Xquang</p>
          <p>- Điều trị viêm lợi (nướu), nha chu</p>
          <p>
            - Hàn (trám) răng bệnh lý bằng amalgam, composite, fuji, GIC hoặc các chất trám tương tự
            khác
          </p>
          <p>- Điều trị tủy răng</p>
          <p>- Cạo vôi răng (lấy cao răng)​</p>
          <p>- Răng mọc lệch, mọc ngầm (bao gồm răng khôn)​​</p>
          <p>- Nhổ răng bệnh lý (bao gồm tiểu phẫu, phẫu thuật giới hạn 500.000/ năm)​</p>
        </>
      ),
      employee: (
        <>
          <p>1.500.000/ năm​</p>
          <p>(trong đó Cạo vôi răng tối đa 2 lần/năm)​​</p>
        </>
      ),
      people: (
        <>
          <p>1.400.000/ năm​​</p>
          <p>(trong đó Cạo vôi răng tối đa 2 lần/năm)​​</p>
        </>
      ),
    },
  ];

  const listExclusion = [
    {
      text: 'Vi phạm pháp luật, nội quy, quy định của chính quyền​',
    },
    {
      text: 'Ảnh hưởng của Rượu, bia, chất kích thích​​',
    },
    {
      text: 'Hành động đánh nhau, trừ tự vệ​​',
    },
    {
      text: 'Thuốc không có chỉ định, đơn thuốc, tiêm vacxin, thực phẩm chức năng​​',
    },
    {
      text: 'Điều trị bệnh bẩm sinh, bệnh di truyền​​​',
    },
    {
      text: 'Điều trị tâm thần, Stress, rối  loạn tâm thần, suy giảm trí nhớ,  lo âu, suy nhược​',
    },
    {
      text: 'Bệnh lây lan qua đường tình dục, HIV, AIDS….​​',
    },
    {
      text: 'Điều trị tại nhà, spa, viện dưỡng lão, an dưỡng….​​​',
    },
    {
      text: 'Hoạt động thể thao mạo hiểm, chuyên nghiệp​​',
    },
    {
      text: 'Kiểm tra thị lực thông thường, phẫu thuật/ điều trị thoái hóa/phẫu thuật phục hồi  thị giác, tật khúc xạ, mỏi mắt điều tiết​​​',
    },
    {
      text: 'Bộ phận giả, thiết bị hỗ trợ thị / thính giác, máy khí rung, đai thoát vị DD​​​​',
    },
    {
      text: 'Điều trị thẩm mỹ và hậu quả​​',
    },
  ];

  const listRecoup = [
    {
      icon: iconPhone,
      title: 'Nộp hồ sơ online qua Xclaim​',
      text: 'Áp dụng cho hồ sơ bồi thường khám ngoại trú và khám răng​',
    },
    {
      icon: iconEmail,
      title: 'Nộp hồ sơ online qua email​​',
      text: 'Áp dụng cho hồ sơ bồi thường khám ngoại trú do ốm bệnh và khám răng​​',
    },
  ];

  const listNote = [
    {
      icon: iconHome,
      title: 'Nộp hồ sơ',
      text: <p>Ngay sau khi khám chữa bệnh, tối đa 180 ngày</p>,
    },
    {
      icon: iconHlth,
      title: 'Mua thuốc',
      text: (
        <>
          <p>Trong vòng 05 ngày kể từ ngày kê toa.</p>
          <p>Số lượng thuốc tối đa 30 ngày</p>
        </>
      ),
    },
    {
      icon: iconCardInsurance,
      title: 'Hóa đơn',
      text: (
        <>
          <p>{'Lấy hóa đơn VAT đối với các chi phí >200,000đ'}</p>
          <p>Xuất hóa đơn trong vòng 30 ngày kể từ ngày khám/ mua thuốc</p>
        </>
      ),
    },
    {
      icon: iconBill,
      title: 'Yêu cầu',
      text: <p>Chứng từ phải có mộc của cơ sở y tế</p>,
    },
    {
      icon: iconstet,
      title: 'Xét nghiệm',
      text: <p>Phải có chỉ định và kết quả</p>,
    },
  ];

  const [showsScrolBtn, setShowScrolBtn] = useState(false);

  useEffect(() => {
    const handleButtonVisibility = () => {
      window.pageYOffset > 300 ? setShowScrolBtn(true) : setShowScrolBtn(false);
    };

    window.addEventListener('scroll', handleButtonVisibility);
    return () => {
      window.addEventListener('scroll', handleButtonVisibility);
    };
  }, []);

  return (
    <section>
      {showsScrolBtn && (
        <div
          id="scrolToTop"
          onClick={() => {
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth',
            });
          }}
          style={{
            position: 'fixed',
            bottom: '60px',
            right: '60px',
            color: 'gray',
            textAlign: 'center',
            lineHeight: 0,
            textDecoration: 'none',
            borderRadius: '50%',
            backgroundColor: 'white',
            zIndex: '100',
          }}
        >
          <StyledButton />
        </div>
      )}

      <Container>
        <ContainerPink></ContainerPink>
        <ContainerPurple></ContainerPurple>
        <ContainerImg>
          <img src={BgrContainer} alt="background container" />
        </ContainerImg>
        <ContainerTitle>
          <Title>SỔ TAY BẢO HIỂM</Title>
          <SubTitle>Bảo hiểm Tai nạn kết hợp Chăm sóc sức khỏe​</SubTitle>
        </ContainerTitle>
      </Container>
      <Collapse
        accordion
        collapsible="header"
        style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}
      >
        <CustomPanel
          header={
            <div>
              <CollapseTitle>Giới thiệu chung về chương trình bảo hiểm</CollapseTitle>
              <CollapseSubTitle>
                Thông tin chung về hợp đồng bảo hiểm, thời gian chờ, định nghĩa bệnh đặc biệt, có
                sẵn​
              </CollapseSubTitle>
            </div>
          }
          key="1"
        >
          <Collapse
            accordion
            bordered={false}
            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
          >
            <Panel header={<CollapseSmallTitle>Thông Tin Chung</CollapseSmallTitle>} key="1">
              <SectionGeneral>
                <div className="body_general">
                  {listGeneral.map((item, index) => {
                    return item.left ? (
                      <div className="row_general">
                        <div className="row_general_left">
                          <p className="title">{item.title}​</p>
                          {item.listText.map((text) => {
                            return <p className="text">{text.text}</p>;
                          })}
                        </div>
                        <div className="row_general_right">
                          <div
                            style={{
                              width: '50px',
                              height: '50px',
                            }}
                          >
                            <img
                              src={item.icon}
                              alt="icon"
                              style={{ width: '100%', height: '100%' }}
                            />
                          </div>
                          <div className="title_number">0{index + 1}</div>
                        </div>
                      </div>
                    ) : (
                      <div className="row_general" style={{ flexDirection: 'row-reverse' }}>
                        <div className="row_general_left" style={{ textAlign: 'left' }}>
                          <p className="title">{item.title}​</p>
                          {item.listText.map((text) => {
                            return <p className="text">{text.text}</p>;
                          })}
                        </div>
                        <div className="row_general_right">
                          <div
                            style={{
                              width: '50px',
                              height: '50px',
                            }}
                          >
                            <img
                              src={item.icon}
                              alt="icon"
                              style={{ width: '100%', height: '100%' }}
                            />
                          </div>
                          <div className="title_number">0{index + 1}</div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </SectionGeneral>
            </Panel>
            <Panel
              header={
                <CollapseSmallTitle>
                  Danh sách cơ sở y tế loại trừ thanh toán của PTI
                </CollapseSmallTitle>
              }
              key="2"
            >
              <SectionTable>
                <p className="text_section">
                  được cập nhật thường xuyên trên website PTI:{' '}
                  <a
                    href="https://www.pti.com.vn/danh-sach-co-so-y-te-lien-ket-va-bao-lanh-vien-phi-2024-the-direct-billing-list-2024.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.pti.com.vn/danh-sach-co-so-y-te-lien-ket-va-bao-lanh-vien-phi-2024-the-direct-billing-list-2024.html
                  </a>
                </p>
                <table className="listHospital">
                  <tr>
                    <th>STT</th>
                    <th>Tỉnh / Thành</th>
                    <th>Tên CSYT</th>
                    <th>Địa chỉ CSYT</th>
                  </tr>
                  {listHospital.map((item, index) => {
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{item.province}</td>
                        <td>{item.name}</td>
                        <td>
                          {item.address.map((address) => {
                            return <p>{address}</p>;
                          })}
                        </td>
                      </tr>
                    );
                  })}
                </table>
              </SectionTable>
            </Panel>
            <Panel header={<CollapseSmallTitle>Thời gian chờ</CollapseSmallTitle>} key="3">
              <SectionWaitingTime>
                <div className="body_section">
                  <div className="box_section">Ngày tham gia BH</div>
                  <div className="img_section">
                    <p>Thời gian chờ</p>
                    <img src={iconRight} alt="icon" />
                  </div>
                  <div className="box_section">BH có hiệu lực</div>
                </div>
                <table className="listWaiting">
                  <tr>
                    <th>
                      Thời gian chờ tính từ ngày tham gia bảo hiểm lần
                      <br />
                      đầu tiên liên tục (bao gồm hợp đồng năm trước)​
                    </th>
                    <th style={{ textAlign: 'center' }}>Nhân viên​</th>
                    <th style={{ textAlign: 'center' }}>Người thân​</th>
                  </tr>
                  {listWaiting.map((item) => {
                    return (
                      <tr>
                        <td>{item.waiting}</td>
                        <td style={{ textAlign: 'center' }}>{item.employee}</td>
                        <td style={{ textAlign: 'center' }}>{item.people}</td>
                      </tr>
                    );
                  })}
                </table>
              </SectionWaitingTime>
            </Panel>
            <Panel
              header={<CollapseSmallTitle>Định nghĩa bệnh đặc biệt, có sẵn</CollapseSmallTitle>}
              key="4"
            >
              <SectionSpecialDisease>
                <CardCustom title="BỆNH ĐẶC BIỆT">
                  là các bệnh ung thư, u các loại, huyết áp, các bệnh về tim, loét dạ dày, viêm đa
                  khớp mãn tính, loét ruột, viêm gan (A, B, C), viêm màng trong dạ con, trĩ, sỏi các
                  loại trong hệ thống tiết niệu và đường mật, đục thủy tinh thể, bệnh tiểu đường,
                  viêm xoang, viêm amidan quá phát, viêm VA quá phát, hen phế quản, Parkinson, viêm
                  thận (những ca viêm thận cấp tính cần điều trị khẩn cấp không được coi là bệnh đặc
                  biệt, viêm thận cấ tính không bao gồm đợt cấp của viêm thận mãn tính), các bệnh và
                  triệu chứng liên quan đến hệ thống tạo máu (bao gồm nhưng không giới hạn suy tủy,
                  bạch cầu cấp, bạch cầu mãn)
                </CardCustom>
                <CardCustom title="BỆNH CÓ SẴN/ TÌNH TRẠNG CÓ SẴN">
                  <p>
                    Là bệnh tật hay thương tật có từ trước ngày bắt đầu bảo hiểm ghi trên Hợp đồng
                    bảo hiểm hoặc sửa đổi bổ sung và là bệnh/ thương tật mà người được bảo hiểm:​
                  </p>
                  <p>a{')'} đã phải điều trị trong 3 năm gần đây ​</p>
                  <p>
                    b{')'} nhận biết triệu chứng bệnh tật/ thương tật đó hay nhận biết bệnh tật/
                    thương tật đã tồn tại trước Ngày bắt đầu bảo hiểm cho dù Người được bảo hiểm có
                    thực sự khám, điều trị hay không
                  </p>
                </CardCustom>
              </SectionSpecialDisease>
            </Panel>
          </Collapse>
        </CustomPanel>
        <CustomPanel
          header={
            <div>
              <CollapseTitle>Quyền lợi bảo hiểm và Loại trừ bảo hiểm</CollapseTitle>
              <CollapseSubTitle>
                Thông tin chi tiết về các quyền lợi bảo hiểm và các điểm loại trừ chính, thường gặp​
              </CollapseSubTitle>
            </div>
          }
          key="2"
        >
          <Collapse
            accordion
            bordered={false}
            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
          >
            <Panel header={<CollapseSmallTitle>Quyền lợi bảo hiểm</CollapseSmallTitle>} key="1">
              <SectionAdvanceInsurance>
                <div className="wrapper_advance">
                  <div className="left_advance">
                    <div className="left_advance_top">
                      <p className="title">Bảo hiểm tai nạn​</p>
                      <p className="text">Tử vong, thương tât vĩnh viễn</p>
                      <p className="text">Chi phí y tế do tai nạn​</p>
                      <p className="text">
                        Trợ cấp mất giảm thu nhập do bị tai nạn và/hoặc bị ốm bệnh ​
                      </p>
                    </div>
                    <div className="left_advance_bottom">
                      <p className="title">Nội trú​​</p>
                      <p className="text">Điều trị nội trú, phẫu thuật do bệnh, thai sản​</p>
                    </div>
                  </div>
                  <div style={{ height: 'inherit', display: 'flex', alignItems: 'center' }}>
                    <div className="center_advance">
                      <div className="box_advance" style={{ backgroundColor: '#47DAC8' }}>
                        <img src={iconUser} alt="" />
                      </div>
                      <div className="box_advance" style={{ backgroundColor: '#824308' }}>
                        <img src={iconMedicalApp} alt="" />
                      </div>
                      <div className="box_advance" style={{ backgroundColor: '#C814AE' }}>
                        <img src={iconbed} alt="" />
                      </div>
                      <div className="box_advance" style={{ backgroundColor: '#F9B93A' }}>
                        <img src={iconstet} alt="" />
                      </div>
                    </div>
                  </div>

                  <div className="right_advance">
                    <div className="right_advance_top">
                      <p className="title">Bảo hiểm nhân mạng​​</p>
                      <p className="text">Tử vong, tàn tật vĩnh viễn do Bệnh, thai sản​</p>
                    </div>
                    <div className="right_advance_bottom">
                      <p className="title">Ngoại trú​​​</p>
                      <p className="text">Điều trị ngoại trú do bệnh, thai sản​​</p>
                      <p className="text">Nha khoa​​​</p>
                    </div>
                  </div>
                </div>
              </SectionAdvanceInsurance>
            </Panel>
            <Panel
              header={<CollapseSmallTitle>Tóm tắt quyền lợi bảo hiểm</CollapseSmallTitle>}
              key="2"
            >
              <TableSectionAdvanceInsurance>
                <table className="listAdvance">
                  <tr>
                    <th>Quyền lợi</th>
                    <th style={{ textAlign: 'center' }}>Nhân viên</th>
                    <th style={{ textAlign: 'center' }}>Người thân</th>
                  </tr>
                  {listAdvanceSumary.map((item) => {
                    return (
                      <tr>
                        <td>{item.advance}</td>
                        <td style={{ textAlign: 'center' }}>{item.employee}</td>
                        <td style={{ textAlign: 'center' }}>{item.people}</td>
                      </tr>
                    );
                  })}
                </table>
              </TableSectionAdvanceInsurance>
            </Panel>
            <Panel
              header={<CollapseSmallTitle>Quyền lợi bảo hiểm chi tiết (D & E)</CollapseSmallTitle>}
              key="3"
            >
              <SectionAdvanceInsuranceDetail>
                <div className="wrapper_detail">
                  <a href="#first" className="box_detail">
                    <div className="box_detail_img">
                      <img src={iconbed} alt="" />
                    </div>
                    <p>Điều trị nội trú​</p>
                  </a>
                  <a href="#second" className="box_detail">
                    <div className="box_detail_img">
                      <img src={iconPregnant} alt="" />
                    </div>
                    <p>Thai sản​​</p>
                  </a>
                  <a href="#third" className="box_detail">
                    <div className="box_detail_img">
                      <img src={iconstet} alt="" />
                    </div>
                    <p>Điều trị ngoại trú​​</p>
                  </a>
                  <a href="#four" className="box_detail">
                    <div className="box_detail_img">
                      <img src={iconTooth} alt="" />
                    </div>
                    <p>Điều trị răng​​</p>
                  </a>
                </div>
              </SectionAdvanceInsuranceDetail>
              <SectionInsuranceDetailFirst id="first">
                <p className="title_section">Điều trị nội trú do ốm đau, bệnh tật, thai sản​​</p>
                <table className="listAdvanceFirst">
                  <tr>
                    <th>Quyền lợi</th>
                    <th style={{ textAlign: 'center' }}>Nhân viên</th>
                    <th style={{ textAlign: 'center' }}>Người thân</th>
                  </tr>
                  {listDetailsFirst.map((item) => {
                    return (
                      <tr>
                        <td>{item.advance}</td>
                        <td style={{ textAlign: 'center' }}>{item.employee}</td>
                        <td style={{ textAlign: 'center' }}>{item.people}</td>
                      </tr>
                    );
                  })}
                </table>
              </SectionInsuranceDetailFirst>
              <SectionInsuranceDetailFirst id="second">
                <p className="title_section">Thai sản​​​</p>
                <p className="subTitle_section">
                  <i>Bao gồm trong quyền lợi điều trị nội trú do ốm đau, bệnh tật, thai sản​</i>​​​
                </p>
                <table className="listAdvanceFirst">
                  <tr>
                    <th>Quyền lợi</th>
                    <th style={{ textAlign: 'center' }}>Nhân viên</th>
                    <th style={{ textAlign: 'center' }}>Người thân</th>
                  </tr>
                  {listDetailsSecond.map((item) => {
                    return (
                      <tr>
                        <td>{item.advance}</td>
                        <td style={{ textAlign: 'center' }}>{item.employee}</td>
                        <td style={{ textAlign: 'center' }}>{item.people}</td>
                      </tr>
                    );
                  })}
                </table>
              </SectionInsuranceDetailFirst>
              <SectionInsuranceDefine id="third">
                <p className="title_section">Định nghĩa một lần khám ngoại trú​</p>
                <div className="wrapper">
                  {listDefine.map((item, index) => {
                    return (
                      <div className="box">
                        <div className="box_number" style={{ backgroundColor: `${item.color}` }}>
                          0{index + 1}
                        </div>
                        <div className="box_text" style={{ color: `${item.color}` }}>
                          {item.text}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </SectionInsuranceDefine>
              <SectionInsuranceDetailFirst>
                <p className="title_section">Điều trị ngoại trú do ốm đau, bệnh tật, thai sản</p>
                <table className="listAdvanceFirst">
                  <tr>
                    <th>Quyền lợi</th>
                    <th style={{ textAlign: 'center' }}>Nhân viên</th>
                    <th style={{ textAlign: 'center' }}>Người thân</th>
                  </tr>
                  {listDetailsThird.map((item) => {
                    return (
                      <tr>
                        <td>{item.advance}</td>
                        <td style={{ textAlign: 'center' }}>{item.employee}</td>
                        <td style={{ textAlign: 'center' }}>{item.people}</td>
                      </tr>
                    );
                  })}
                </table>
              </SectionInsuranceDetailFirst>
              <SectionInsuranceDetailFirst id="four">
                <p className="title_section">Điều trị răng​​</p>
                <p className="subTitle_section">
                  <i>Bao gồm trong quyền lợi điều trị ngoại trú do ốm đau, bệnh tật, thai sản</i>
                </p>
                <table className="listAdvanceFirst">
                  <tr>
                    <th>Quyền lợi</th>
                    <th style={{ textAlign: 'center' }}>Nhân viên</th>
                    <th style={{ textAlign: 'center' }}>Người thân</th>
                  </tr>
                  {listDetailsFour.map((item) => {
                    return (
                      <tr>
                        <td>{item.advance}</td>
                        <td style={{ textAlign: 'center' }}>{item.employee}</td>
                        <td style={{ textAlign: 'center' }}>{item.people}</td>
                      </tr>
                    );
                  })}
                </table>
              </SectionInsuranceDetailFirst>
            </Panel>
            <Panel
              header={<CollapseSmallTitle>Một số điểm loại trừ cơ bản</CollapseSmallTitle>}
              key="4"
            >
              <SectionExclusion>
                <div className="wrapper">
                  {listExclusion.map((item, index) => {
                    return (
                      <div className="box">
                        <div className="box_number">{index + 1}</div>
                        <div className="box_text">{item.text}</div>
                      </div>
                    );
                  })}
                </div>
              </SectionExclusion>
            </Panel>
          </Collapse>
        </CustomPanel>
        <CustomPanel
          header={
            <div>
              <CollapseTitle>Hướng dẫn sử dụng bảo hiểm</CollapseTitle>
              <CollapseSubTitle>
                Sử dụng dịch vụ bảo lãnh viện phí, hướng dẫn chứng từ hồ sơ bồi thường​
              </CollapseSubTitle>
            </div>
          }
          key="3"
        >
          <Collapse
            accordion
            bordered={false}
            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
          >
            <Panel
              header={<CollapseSmallTitle>Dịch vụ bảo lãnh viện phí</CollapseSmallTitle>}
              key="1"
            >
              <SectionGuarantee>
                <div className="wrapper_guarantee">
                  <div className="guarantee_card">
                    <div className="guarantee_card_box">
                      <div className="img">
                        <img src={iconCardInsurance} alt="card" />
                      </div>
                      <p>Thẻ bảo hiểm</p>
                    </div>
                    <p className="add">+</p>
                    <div className="guarantee_card_box">
                      <div className="img">
                        <img src={iconCCCD} alt="card" />
                      </div>
                      <p>CMND/CCCD/ Giấy Khai sinh​</p>
                    </div>
                    <p className="add">+</p>
                    <div className="guarantee_card_box">
                      <div className="img">
                        <img src={iconMoney} alt="card" />
                      </div>
                      <p>Tiền cọc (nếu cần)​</p>
                    </div>
                  </div>
                  <div className="guarantee_steps">
                    <div className="guarantee_steps_left">
                      <p>
                        <b>Bước 1:</b> Tham khảo Danh sách BLVP trong sổ tay bảo hiểm​ <br />
                        Link tra cứu:{' '}
                        <a
                          href="https://www.pti.com.vn/danh-sach-co-so-y-te-lien-ket-va-bao-lanh-vien-phi-2024-the-direct-billing-list-2024.html​"
                          target="_blank"
                          rel="noreferrer"
                        >
                          https://www.pti.com.vn/danh-sach-co-so-y-te-lien-ket-va-bao-lanh-vien-phi-2024-the-direct-billing-list-2024.html
                        </a>
                        <br />
                        Sản phẩm: PTI Care
                      </p>
                      <p>
                        <b>Bước 2:</b> Gọi điện để kiểm tra giờ làm việc của bộ phận BLVP của cơ sở
                        y tế và quy định về tạm ứng (nếu có)​
                      </p>
                      <p>
                        <b>Bước 3:</b> Ký tên vào các biên nhận, biên lai để xác nhận việc điều trị
                        hay​ các dịch vụ y tế đã sử dụng.
                        <br />
                        Thanh toán các chi phí y tế không thuộc phạm vi bảo hiểm hoặc vượt quá hạn
                        mức trách nhiệm cho phép trước khi xuất viện.​​
                      </p>
                    </div>
                    <div className="guarantee_steps_right">
                      <div>Thời gian làm việc BLVP:​</div>
                      <p>
                        - Thứ 2 đến Thứ 6: 8h00 – 12h00; 13h15 – 19h30​ <br />
                        (từ 17h30 – 19h30 chỉ bảo lãnh cho Ngoại trú và Nha Khoa)
                      </p>
                      <div>Lưu ý​</div>
                      <p>- Không BLVP cho tai nạn, cấp cứu</p>
                      <p>
                        - Không BLVP cho các trường hợp ốm bệnh chưa đủ cơ sở xác định phạm vi bảo
                        hiểm
                      </p>
                      <p>- Khám thai định kỳ​</p>
                      <p>- Trợ cấp lương, trợ cấp ngày nằm viện​</p>
                    </div>
                  </div>
                </div>
              </SectionGuarantee>
            </Panel>
            <Panel
              header={
                <CollapseSmallTitle>Các phương thức nộp hồ sơ bồi thường​</CollapseSmallTitle>
              }
              key="2"
            >
              <SectionGuarantee>
                <div className="wrapper_recoup">
                  {listRecoup.map((item) => {
                    return (
                      <div className="recoup_box">
                        <div className="img">
                          <img src={item.icon} alt="icon" />
                        </div>
                        <div className="text">
                          <p>{item.title}​</p>
                          <p>{item.text}​</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <p className="subTitle_section">
                  Quy trình giải quyết bồi thường (10 – 15 ngày làm việc) kể từ ngày PTI nhận hồ sơ
                  đầy đủ và hợp lệ​
                </p>
                <div className="wrapper_day">
                  <div className="day_box" style={{ backgroundColor: '#C900AC' }}>
                    Ngày 0
                    <div
                      className="box_bottom"
                      style={{ border: '1px solid #C900AC', color: '#C900AC' }}
                    >
                      <p>PTI tiếp nhận HS</p>
                    </div>
                  </div>
                  <div className="img_right">
                    <img src={iconRight} alt="icon" />
                  </div>
                  <div className="day_box" style={{ backgroundColor: '#F6517F' }}>
                    Ngày 1
                    <div
                      className="box_bottom"
                      style={{ border: '1px solid #F6517F', color: '#F6517F' }}
                    >
                      <p>PTI Gửi email thông báo nhận HS</p>
                    </div>
                  </div>
                  <div className="img_right">
                    <img src={iconRight} alt="icon" />
                  </div>
                  <div className="day_box" style={{ backgroundColor: '#FF8204' }}>
                    Ngày 3 - 5
                    <div
                      className="box_bottom"
                      style={{ border: '1px solid #FF8204', color: '#FF8204' }}
                    >
                      <p>PTI gửi thư thông báo bổ sung chứng từ (nếu có)</p>
                    </div>
                  </div>
                  <div className="img_right">
                    <img src={iconRight} alt="icon" />
                  </div>
                  <div className="day_box" style={{ backgroundColor: '#FFB92A' }}>
                    Ngày 10 - 15
                    <div
                      className="box_bottom"
                      style={{ border: '1px solid #FFB92A', color: '#FFB92A' }}
                    >
                      <p>PTI gửi thư TBBT cho NĐBH</p>
                    </div>
                  </div>
                  <div className="img_right">
                    <img src={iconRight} alt="icon" />
                  </div>
                  <div className="day_box" style={{ backgroundColor: '#3ADCC9' }}>
                    Sau 3 - 5 (*) ngày
                    <div
                      className="box_bottom"
                      style={{ border: '1px solid #3ADCC9', color: '#3ADCC9' }}
                    >
                      <p>PTI Chuyển khoản thanh toán cho NĐBH</p>
                    </div>
                  </div>
                </div>
                <div className="note">
                  <p>{'(*)Trường hợp số tiền bồi thường < Số tiền yêu cầu bồi thường:'}​</p>
                  <p>
                    Người được bảo hiểm cần xác nhận đồng ý để PTI tiến hành chuyển khoản trong vòng
                    3 – 5 ngày làm việc kể từ ngày nhận được email xác nhận​​
                  </p>
                </div>
              </SectionGuarantee>
            </Panel>
            <Panel
              header={<CollapseSmallTitle>Hướng dẫn chứng từ bồi thường</CollapseSmallTitle>}
              key="3"
            >
              <SectionGuarantee>
                <div className="wrapper_prepayment">
                  <div className="prepayment_left">
                    <div className="prepayment_box" style={{ backgroundColor: '#D8D7DF' }}>
                      <div className="title" style={{ backgroundColor: '#48086F' }}>
                        Điều trị nội trú
                      </div>
                      <div className="list">
                        <p>- Giấy ra viện</p>
                        <p>- Giấy chứng nhận phẫu thuật (nếu có)​</p>
                        <p>- Bảng kê chi tiết viện phí​</p>
                        <p>- Toa thuốc xuất viện (nếu có)​</p>
                        <p>- Kết quả xét nghiệm & chẩn đoán hình ảnh (nếu có)​</p>
                      </div>
                    </div>
                    <div className="prepayment_box" style={{ backgroundColor: '#FDDCE5' }}>
                      <div className="title" style={{ backgroundColor: '#F6517F' }}>
                        Điều trị răng
                      </div>
                      <div className="list">
                        <p>- Phiếu điều trị răng​</p>
                        <p>- Phim chụp răng (nếu chữa tủy, nhổ răng,…)​​</p>
                      </div>
                    </div>
                  </div>
                  <div className="prepayment_center">
                    <div className="prepayment_box" style={{ backgroundColor: '#B9D3F9' }}>
                      <div className="title" style={{ backgroundColor: '#327FEF' }}>
                        Điều trị tai nạn​
                      </div>
                      <div className="list">
                        <p>- Bản tường trình tai nạn​​</p>
                        <p>
                          - Kết quả xét nghiệm nồng độ cồn. Hoặc xác nhận của cơ sở y tế về việc
                          không thực hiện xét nghiệm nồng độ cồn.​
                        </p>
                        <p>- Giấy tờ xe & Giấy phép lái xe (trường hợp tai nạn giao thông)​​</p>
                        <p>- Biên bản kết luận tai nạn của công an giao thông (nếu có)​​</p>
                        <p>- Giấy chứng nhận thương tật (nếu có)</p>
                        <p>- Các giấy tờ y tế như trong trường hợp điều trị nội trú/ ngoại trú​</p>
                      </div>
                    </div>
                  </div>
                  <div className="prepayment_right">
                    <div className="prepayment_box" style={{ backgroundColor: '#FFF1D4' }}>
                      <div className="title" style={{ backgroundColor: '#FFB92A' }}>
                        Trợ cấp ngày nghỉ​
                      </div>
                      <div className="list">
                        <p>- Giấy ra viện (trường hợp điều trị nội trú)​</p>
                        <p>- Giấy nghỉ hưởng BHXH (trường hợp điều trị ngọai trú)​​</p>
                        <p>- Chỉ định nghỉ của bác sĩ​</p>
                        <p>- Bảng chấm công​</p>
                        <p>- Chứng từ điều trị y tế/ toa thuốc cho các lần khám/ tái khám​​</p>
                      </div>
                    </div>
                    <div className="prepayment_box" style={{ backgroundColor: '#FFC1F6' }}>
                      <div className="title" style={{ backgroundColor: '#C900AC' }}>
                        Điều trị ngoại trú​
                      </div>
                      <div className="list">
                        <p>- Toa thuốc/ sổ khám bệnh​​</p>
                        <p>- Chỉ định & kết quả xét nghiệm & chẩn đoán hình ảnh​​​</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="total_pay">
                  <div className="total">
                    <div className="total_top" style={{ border: '1px solid black' }}>
                      <div
                        className="total_box"
                        style={{
                          color: '#48086F',
                          borderRight: '1px solid black',
                          fontWeight: 'bold',
                        }}
                      >
                        Tổng chi phí
                      </div>
                      <div className="total_box" style={{ borderRight: '1px solid black' }}>
                        <p>Tổng chi phí </p>
                        <p>
                          <b style={{ color: '#48086F' }}>dưới VND 200,000​</b>
                        </p>
                      </div>
                      <div className="total_box">
                        <p>Tổng chi phí </p>
                        <p>
                          <b style={{ color: '#48086F' }}>từ VND 200,000​</b>
                        </p>
                      </div>
                    </div>
                    <div
                      className="total_top"
                      style={{
                        borderBottom: '1px solid black',
                        borderRight: '1px solid black',
                        borderLeft: '1px solid black',
                      }}
                    >
                      <div
                        className="total_box"
                        style={{
                          color: '#48086F',
                          borderRight: '1px solid black',
                          fontWeight: 'bold',
                        }}
                      >
                        Các chứng từ cần có​
                      </div>
                      <div className="total_box" style={{ borderRight: '1px solid black' }}>
                        Biên lai, phiếu thu (tiền khám, xét nghiệm, mua thuốc)​​
                      </div>
                      <div className="total_box">
                        Hóa đơn VAT/ biên lai đặc thù, kèm theo bảng kê chi tiết hóa đơn​​
                      </div>
                    </div>
                  </div>
                  <div className="note">
                    <p className="title">Lưu ý</p>
                    <ul>
                      <li>
                        Trường hợp không bổ sung hóa đơn VAT thì bồi thường tối đa VND 200,000​
                      </li>
                      <li>
                        Hóa đơn mua thuốc: <b>xuất ngay khi mua thuốc.</b> Nếu nhà thuốc xuất hóa
                        đơn sau 05 ngày (nhưng không vượt quá 30 ngày kể từ ngày kê toa), cần bổ
                        sung phiếu thu ban đầu.​
                      </li>
                      <li>
                        Hóa đơn: xuất trong vòng <b>30 ngày</b> kể từ ngày điều trị​​
                      </li>
                      <li>
                        Phải có <b>con dấu</b> của cơ sở y tế/ nhà thuốc (dấu mộc tròn)​​
                      </li>
                      <li>
                        Phải có <b>chữ ký của người bán hàng, người mua hàng</b>​​
                      </li>
                      <li>
                        Phải nộp kèm <b>biên lai tiền khám</b> nếu chi phí khám không xuất hóa đơn​​
                      </li>
                    </ul>
                    <p className="warning">
                      Hóa đơn <b>không</b> xuất kèm theo thông tin công ty, <b>chỉ</b> xuất theo
                      thông tin cá nhân​
                    </p>
                  </div>
                </div>
                <div className="wrapper_bill">
                  <div className="left">
                    <p>Hóa đơn Điện tử</p>
                    <div>
                      <img src={iconBillInternet} alt="bill" />
                    </div>
                  </div>
                  <div className="left">
                    <p>Hóa đơn đã chuyển đổi</p>
                    <div>
                      <img src={iconBillInternet2} alt="bill" />
                    </div>
                  </div>
                  <div className="right">
                    <div className="box">
                      <p>Lưu ý về hóa đơn điện tử</p>
                      <ul>
                        <li>
                          <b>Hóa đơn chuyển đổi:</b> hồ sơ y tế nộp bản photocopy.
                        </li>
                        <li>
                          <b>Hóa đơn điện tử</b> xuất theo thông tin cá nhân của người được bảo
                          hiểm: hồ sơ y tế bản gốc.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </SectionGuarantee>
            </Panel>
            <Panel
              header={<CollapseSmallTitle>Hướng dẫn nộp hồ sơ bồi thường</CollapseSmallTitle>}
              key="4"
            >
              <SectionGuarantee>
                <div className="wrapper_apply">
                  <div className="apply_left">
                    <div className="title">Hình thức nộp hồ sơ</div>
                    <ul className="ul_list">
                      <li className="li_list">
                        <p>Hồ sơ YCBT Chi phí điều trị y tế (Hồ sơ ngoại trú – nha khoa):​</p>
                        <ul>
                          <li>
                            Hồ sơ có thể gửi qua:{' '}
                            <a href="https://itv.xclaim.vn/" target="_blank" rel="noreferrer">
                              https://itv.xclaim.vn/
                            </a>
                          </li>
                          <li>
                            Video hướng dẫn:{' '}
                            <a href="https://xclaim.vn/" target="_blank" rel="noreferrer">
                              https://xclaim.vn/
                            </a>
                            ​
                          </li>
                        </ul>
                        ​
                      </li>
                      <li className="li_list">
                        <p>Hoặc có thể gửi qua email (online)​</p>
                        <ul>
                          <li>
                            <b>Email tiếp nhận:</b>{' '}
                            <a href="mailto:nhanhsbt.mn@pti.com.vn">nhanhsbt.mn@pti.com.vn</a>​
                          </li>
                          <li>
                            <b>Cc email:</b>{' '}
                            <a href="mailto:tai.phan@wtwco.com">tai.phan@wtwco.com</a>
                            ​​
                          </li>
                          <li>
                            <b>Tiêu đề email:</b> {'<Nộp HS YCBT> - '} <b>{'<INNOTECH>'}</b> -{' '}
                            {'<Tên Người được bảo hiểm>​'}​
                          </li>
                          <li>
                            <b>Dung lượng file đính kèm tối đa:</b> 10MB​​​
                          </li>
                        </ul>
                        ​
                      </li>
                    </ul>
                  </div>
                  <div className="apply_right">
                    <p>
                      <b>
                        <u>Lưu ý</u>
                      </b>
                    </p>
                    <p style={{ marginBottom: '30px' }}>
                      Thời hạn nộp hồ sơ: <b>180 ngày</b> kể từ ngày điều trị cuối cùng.​
                    </p>
                    <p>
                      Tuy nhiên, NĐBH nên nộp hồ sơ <b>trong vòng 30 ngày</b> kể từ ngày điều trị
                      cuối cùng để có thể dễ dàng bổ sung hồ sơ nếu được yêu cầu.​​
                    </p>
                  </div>
                </div>
                <p className="subTitle_section">Tóm tắt những điểm cần lưu ý​​</p>
                <div className="wrapper_note">
                  {listNote.map((item, index) => {
                    return (
                      <div className="note_box">
                        <div className="top">
                          <div className="left">
                            0{index + 1}. {item.title}
                          </div>
                          <div className="right">
                            <img src={item.icon} alt="icon" />
                          </div>
                        </div>
                        <div className="bottom">
                          <p className="text">{item.text}</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </SectionGuarantee>
            </Panel>
          </Collapse>
        </CustomPanel>
        <CustomPanel
          header={
            <div>
              <CollapseTitle>Thông tin liên hệ​</CollapseTitle>
              <CollapseSubTitle>
                Thông tin liên lạc và địa chỉ nộp hồ sơ bồi thường​
              </CollapseSubTitle>
            </div>
          }
          key="4"
        >
          <SectionContact>
            <div className="wrapper_contact">
              <p className="title">Thông tin liên hệ</p>
              <div className="box">
                <div className="top">
                  <p>Mọi thắc mắc vui lòng liên hệ:</p>
                  <a href="mailto:Admin@xclaim.vn">Admin@xclaim.vn</a>
                </div>
                <div className="top">
                  <p>Hoặc phòng Nhân sự</p>
                  <a href="mailto:itv_hr@innotech-vn.com">itv_hr@innotech-vn.com</a>
                </div>
              </div>
            </div>
          </SectionContact>
        </CustomPanel>
      </Collapse>
      {/* </Space> */}
    </section>
  );
};

export default PageXclaim;

export const CustomButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: none;
`;
export const StyledButton = styled(UpCircleOutlined)`
  font-size: 40px;
  color: var(--cl_info500);
  cursor: pointer;
`;
export const CustomPanel = styled(Collapse.Panel)`
  .ant-collapse-header {
    background-color: #f3f2f2;
  }
`;
export const Container = styled.div`
  margin: 15px 0;
  width: 100%;
  height: 300px;
  position: relative;
  p {
    margin: 0;
  }
`;
export const ContainerPink = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 92%;
  height: 85%;
  background-color: #f85982;
`;
export const ContainerPurple = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 92%;
  height: 85%;
  margin: 0 0 20px 40px;
  background-color: #7d35b3;
`;
export const ContainerImg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 92%;
  height: 85%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;
export const ContainerTitle = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 500px;
  height: 200px;
  background-color: white;
  padding-left: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
`;
export const Title = styled.div`
  font-size: 35px;
  font-weight: bold;
`;
export const SubTitle = styled.div`
  font-size: 18px;
  color: #774774;
`;
export const CollapseTitle = styled.div`
  font-size: 24px;
  color: black;
  font-weight: bold;
`;
export const CollapseSmallTitle = styled.div`
  font-size: 18px;
  color: black;
`;
export const CollapseSubTitle = styled.div`
  font-size: 18px;
  color: black;
  font-weight: 400;
`;
export const CollapseSmallSubTitle = styled.div`
  font-size: 16px;
  color: black;
  font-weight: 400;
`;
export const TitleSection = styled.p`
  font-size: 24px;
  color: black;
  font-weight: bold;
`;
export const SectionGeneral = styled.div`
  p {
    margin: 0;
  }
  .title_general {
    font-size: 32px;
    color: black;
    font-weight: bold;
  }
  .body_general {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    flex-wrap: wrap;
  }
  .row_general {
    width: 49%;
    display: flex;
    justify-content: space-between;
    border: 1px solid black;
    border-radius: 10px;
    padding: 7px;
  }
  .row_general_left {
    text-align: right;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80%;
    font-size: 16px;
    .title {
      font-weight: bold;
    }
  }
  .row_general_right {
    width: 17%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 3%;
    border-radius: 20px;
    .title_number {
      font-weight: bold;
      font-size: 16px;
    }
    img {
      border-radius: 50%;
    }
  }
  /* .row_general_right {
    display: flex;
    width: 45%;
    text-align: left;
    justify-content: center;
    flex-direction: column;
    font-size: 16px;
    padding: 0 20px;
    .title {
      font-weight: bold;
    }
  } */
`;

export const SectionTable = styled.div`
  margin-top: 20px;
  p {
    margin: 0;
  }
  .title_section {
    font-size: 32px;
    color: black;
    font-weight: bold;
  }
  .text_section {
    font-size: 18px;
    color: black;
  }
  .listHospital {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  .listHospital td,
  .listHospital th {
    border: 1px solid #ddd;
    padding: 8px;
  }

  .listHospital tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  .listHospital tr:hover {
    background-color: #ddd;
  }

  .listHospital th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #48086f;
    color: white;
  }
`;

export const SectionWaitingTime = styled.div`
  margin-top: 20px;
  p {
    margin: 0;
  }
  .title_section {
    font-size: 32px;
    color: black;
    font-weight: bold;
  }
  .body_section {
    display: flex;
    justify-content: space-around;
    .box_section {
      padding: 20px;
      background-color: #00a94e;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      font-weight: 700;
      width: 150px;
      text-align: center;
      border-radius: 20px;
    }
    .img_section {
      width: 150px;
      height: 80px;
      font-size: 18px;
      text-align: center;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .listWaiting {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin-top: 20px;
  }

  .listWaiting td,
  .listWaiting th {
    border: 1px solid #ddd;
    padding: 8px;
  }

  .listWaiting tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  .listWaiting tr:hover {
    background-color: #ddd;
  }

  .listWaiting th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #48086f;
    color: white;
  }
  .listWaiting th:first-child() {
    max-width: 100px;
  }
`;

export const SectionSpecialDisease = styled.div`
  margin-top: 20px;
  p {
    margin: 0;
  }
  .title_section {
    font-size: 32px;
    color: black;
    font-weight: bold;
  }
`;

export const CardCustom = styled(Card)`
  .ant-card-head {
    background-color: #48086f;
    font-size: 22px;
    color: white;
    border-radius: 10px;
  }
  .ant-card-body {
    font-size: 18px;
  }
`;

export const SectionAdvanceInsurance = styled.div`
  p {
    margin: 0;
  }
  .title_section {
    font-size: 32px;
    color: black;
    font-weight: bold;
  }
  .wrapper_advance {
    display: flex;
    justify-content: space-between;
    .left_advance {
      width: 35%;
      padding: 10px 0;
      .left_advance_top {
        height: 50%;
        width: 100%;
        text-align: right;
        .title {
          font-size: 20px;
          color: #00af50;
          font-weight: bold;
        }
        .text {
          font-size: 16px;
        }
      }
      .left_advance_bottom {
        height: 50%;
        width: 100%;
        text-align: right;
        display: flex;
        flex-direction: column;
        justify-content: end;
        .title {
          font-size: 20px;
          color: #c800ac;
          font-weight: bold;
        }
        .text {
          font-size: 16px;
        }
      }
    }
    .center_advance {
      width: 200px;
      height: 200px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      .box_advance {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
        }
      }
    }
    .right_advance {
      width: 35%;
      padding: 10px 0;
      .right_advance_top {
        height: 50%;
        width: 100%;
        .title {
          font-size: 20px;
          color: #ff8204;
          font-weight: bold;
        }
        .text {
          font-size: 16px;
        }
      }
      .right_advance_bottom {
        height: 50%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: end;
        .title {
          font-size: 20px;
          color: #ffb82a;
          font-weight: bold;
        }
        .text {
          font-size: 16px;
        }
      }
    }
  }
`;

export const TableSectionAdvanceInsurance = styled.div`
  margin-top: 20px;
  p {
    margin: 0;
  }
  .title_section {
    font-size: 32px;
    color: black;
    font-weight: bold;
  }
  .listAdvance {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  .listAdvance td,
  .listAdvance th {
    border: 1px solid #ddd;
    padding: 8px;
  }

  .listAdvance tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  .listAdvance tr:hover {
    background-color: #ddd;
  }

  .listAdvance th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #48086f;
    color: white;
  }
`;

export const SectionAdvanceInsuranceDetail = styled.div`
  margin-top: 20px;
  p {
    margin: 0;
  }
  .title_section {
    font-size: 32px;
    color: black;
    font-weight: bold;
  }
  .wrapper_detail {
    display: flex;
    justify-content: center;
    gap: 100px;
    .box_detail {
      color: black;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .box_detail_img {
        width: 150px;
        height: 150px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      p {
        font-size: 18px;
        font-weight: bold;
      }
    }
  }
`;

export const SectionInsuranceDetailFirst = styled.div`
  margin-top: 20px;
  p {
    margin: 0;
  }
  .title_section {
    font-size: 32px;
    color: black;
    font-weight: bold;
  }
  .subTitle_section {
    font-size: 20px;
    color: black;
  }

  .listAdvanceFirst {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  .listAdvanceFirst td,
  .listAdvanceFirst th {
    border: 1px solid #ddd;
    padding: 8px;
  }

  .listAdvanceFirst tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  .listAdvanceFirst tr:hover {
    background-color: #ddd;
  }

  .listAdvanceFirst th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #48086f;
    color: white;
  }
`;

export const SectionInsuranceDefine = styled.div`
  margin-top: 20px;
  p {
    margin: 0;
  }
  .title_section {
    font-size: 32px;
    color: black;
    font-weight: bold;
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .box {
      display: flex;
      gap: 10px;
      .box_number {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        padding: 15px;
        border-radius: 50px;
        font-size: 20px;
        color: white;
      }
      .box_text {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
`;

export const SectionExclusion = styled.div`
  margin-top: 20px;
  p {
    margin: 0;
  }
  .title_section {
    font-size: 32px;
    color: black;
    font-weight: bold;
  }
  .wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    .box {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 150px;
      .box_number {
        height: 50px;
        border-style: double;
        border-width: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 22px;
        font-weight: bold;
      }
      .box_text {
        color: white;
        background-color: #48086f;
        padding: 10px;
        text-align: center;
        font-size: 14px;
      }
    }
  }
`;

export const SectionGuarantee = styled.div`
  margin-top: 20px;
  p {
    margin: 0;
  }
  .title_section {
    font-size: 32px;
    color: black;
    font-weight: bold;
  }
  .subTitle_section {
    font-size: 20px;
    color: black;
  }
  .wrapper_guarantee {
    .guarantee_card {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 50px;
      .guarantee_card_box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .img {
          width: 100px;
          height: 100px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        p {
          color: #006fc0;
          font-size: 18px;
        }
      }
      .add {
        font-size: 30px;
        color: #006fc0;
      }
    }
    .guarantee_steps {
      color: black;
      font-size: 18px;
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      .guarantee_steps_left {
        width: 50%;
      }
      .guarantee_steps_right {
        width: 45%;
        border: 2px solid red;
        div {
          font-weight: bold;
          text-align: center;
        }
      }
    }
  }
  .wrapper_recoup {
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .recoup_box {
      display: flex;
      gap: 30px;
      .img {
        width: 70px;
        height: 70px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .text {
        font-size: 18px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        p:first-child {
          font-weight: bold;
        }
      }
    }
  }
  .wrapper_day {
    margin: 20px 0 150px 0;
    display: flex;
    flex-wrap: wrap;
    column-gap: 50px;
    row-gap: 150px;
    .day_box {
      font-size: 18px;
      width: 150px;
      height: 100px;
      padding: 10px;
      color: white;
      border-radius: 10px;
      position: relative;
      .box_bottom {
        position: absolute;
        bottom: -120%;
        left: 20%;
        background-color: white;
        border-radius: 10px;
        height: 150px;
        width: 150px;
        padding: 10px;
      }
    }
    .img_right {
      width: 50px;
      height: 50px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .note {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
    p:first-child {
      color: #3adcc9;
    }
  }
  .wrapper_prepayment {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    .prepayment_left {
      width: 32%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 50px;
    }
    .prepayment_center {
      width: 32%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .prepayment_right {
      width: 32%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 50px;
    }
    .prepayment_box {
      border-radius: 10px;
      .title {
        color: white;
        font-size: 20px;
        padding: 15px;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        text-align: center;
      }
      .list {
        font-size: 16px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 10px;
      }
    }
  }
  .total_pay {
    margin-top: 40px;
    .total {
      display: flex;
      flex-direction: column;
      align-items: center;
      .total_top {
        display: flex;
        width: 100%;
        .total_box {
          background-color: #e6e6e6;
          font-size: 18px;
          width: 34%;
          height: 70px;
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 10px;
        }
      }
    }
    .note {
      margin-top: 20px;
      font-weight: normal;
      .title {
        color: black;
        font-weight: bold;
        font-size: 18px;
      }
      ul {
        padding-left: 50px;
        margin: 0;
        li {
          list-style-type: disc;
        }
      }
      .warning {
        color: red;
      }
    }
  }
  .wrapper_apply {
    padding: 10px 40px;
    border: 4px solid #48086f;
    display: flex;
    justify-content: space-between;
    border-radius: 50px;
    margin-bottom: 20px;
    .apply_left {
      width: 60%;
      .title {
        font-size: 20px;
        color: #48086f;
        text-align: center;
        font-weight: bold;
        margin-bottom: 10px;
      }
      .ul_list {
        margin: 0;
        .li_list {
          list-style-type: disclosure-closed;
          color: #48086f;
          font-size: 18px;
          font-weight: bold;
          ul {
            padding-left: 40px;
            li {
              list-style-type: disc;
              color: black;
              font-weight: normal;
            }
          }
        }
      }
    }
    .apply_right {
      width: 35%;
      border: 3px solid black;
      font-size: 18px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
  .wrapper_note {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 20px;
    .note_box {
      border: 1px solid black;
      border-radius: 10px;
      width: 45%;
      padding: 15px;
      .top {
        height: 50px;
        display: flex;
        justify-content: space-between;
        .left {
          width: 70%;
          font-size: 26px;
          font-weight: bold;
        }
        .right {
          width: 30%;
          display: flex;
          justify-content: flex-end;
          img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
          }
        }
      }
      .bottom {
        .title {
          font-size: 26px;
          font-weight: bold;
        }
        .text {
          font-size: 18px;
        }
      }
    }
  }
  .wrapper_bill {
    display: flex;
    justify-content: space-between;
    .left {
      width: 30%;
      display: flex;
      flex-direction: column;
      align-items: center;
      p {
        font-weight: bold;
        font-size: 18px;
        background-color: #d8d7df;
        padding: 5px 10px;
      }
      div {
        width: 300px;
        height: 450px;
        img {
          width: 100%;
          height: 100%;
          object-fit: fill;
        }
      }
    }
    .right {
      width: 30%;
      font-size: 18px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .box {
        background-color: #d8f8f4;
        padding: 10px;
        p {
          font-weight: bold;
        }
        ul {
          margin: 0;
          padding-left: 50px;
          li {
            list-style-type: square;
          }
        }
      }
    }
  }
`;

export const SectionContact = styled.div`
  margin-top: 20px;
  p {
    margin: 0;
  }
  .wrapper_contact {
    color: black;
    width: 50%;
    padding-left: 50px;
    .title {
      font-size: 32px;
      font-weight: bold;
    }
    .box {
      display: flex;
      flex-direction: column;
      gap: 20px;
      font-size: 18px;
    }
  }
`;

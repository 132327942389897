const colors: { [index: string]: string } = {
  cl_primary: '#FE753E',
  cl_primary_dark: '#c5450f',
  cl_primary_light: '#ffa66b',
  cl_border_primary: '#979797',
  cl_text_primary: '#404852',
  cl_text_second: '#8D8D8D',

  cl_white: '#ffffff',
  cl_grey: '#f3f4f6',
  cl_shadow_2: 'rgba(0, 0, 0, 0.2)',
  cl_shadow_3: 'rgba(0, 0, 0, 0.3)',

  cl_primary050: '#fef0ec',
  cl_primary100: '#fce1d8',
  cl_primary200: '#fbd2c5',
  cl_primary300: '#fac3b2',
  cl_primary400: '#f6977a',
  cl_primary500: '#f1592a',
  cl_primary600: '#e8430f',
  cl_primary700: '#c7390d',
  cl_primary800: '#a6300b',
  cl_primary900: '#852609',

  cl_gray050: '#f9fafb',
  cl_gray100: '#f3f4f6',
  cl_gray200: '#e5e7eb',
  cl_gray250: '#d6d9e0',
  cl_gray300: '#d1d5db',
  cl_gray400: '#9ca3af',
  cl_gray500: '#6b7280',
  cl_gray600: '#4b5563',
  cl_gray700: '#374151',
  cl_gray800: '#1f2937',
  cl_gray900: '#111827',

  cl_info050: '#eff6ff',
  cl_info100: '#dbeafe',
  cl_info200: '#bfdbfe',
  cl_info300: '#93c5fd',
  cl_info400: '#60a5fa',
  cl_info500: '#3b82f6',
  cl_info600: '#2563eb',
  cl_info700: '#1d4ed8',
  cl_info800: '#1e40af',
  cl_info900: '#1e3a8a',

  cl_success050: '#ecfdf5',
  cl_success100: '#d1fae5',
  cl_success200: '#a7f3d0',
  cl_success300: '#6ee7b7',
  cl_success400: '#34d399',
  cl_success500: '#10b981',
  cl_success600: '#059669',
  cl_success700: '#047857',
  cl_success800: '#065f46',
  cl_success900: '#064e3b',

  cl_success_notify100: '#28a745',
  cl_success_notify200: '#208337',

  cl_error050: '#fef2f2',
  cl_error100: '#fee2e2',
  cl_error200: '#fecaca',
  cl_error300: '#fca5a5',
  cl_error400: '#f87171',
  cl_error500: '#ef4444',
  cl_error600: '#dc2626',
  cl_error700: '#b91c1c',
  cl_error800: '#991b1b',
  cl_error900: '#7f1d1d',

  cl_warning050: '#fffbeb',
  cl_warning100: '#fef3c7',
  cl_warning200: '#fde68a',
  cl_warning300: '#fcd34d',
  cl_warning400: '#fbbf24',
  cl_warning500: '#f59e0b',
  cl_warning600: '#d97706',
  cl_warning700: '#b45309',
  cl_warning800: '#92400e',
  cl_warning900: '#78350f',

  cl_neutral100: '#EEF2F6',
  cl_neutral200: '#E3E8EF',
  cl_neutral300: '#CDD5DF',
  cl_neutral500: '#697586',
  cl_neutral600: '#4B5565',
  cl_neutral700: '#364152',
  cl_neutral900: '#121926',

  cl_secondary500: '#124EDB',
};

export default colors;

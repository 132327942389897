const colors: { [index: string]: string } = {
  primaryColor: '#FE753E',
  primaryDarkColor: '#c5450f',
  primaryLightColor: '#ffa66b',
  borderPrimaryColor: '#979797',
  textPrimaryColor: '#404852',
  textSecondColor: '#8D8D8D',

  white: '#ffffff',
  grey: '#f3f4f6',
  primary050: '#fef0ec',
  primary100: '#fce1d8',
  primary200: '#fbd2c5',
  primary300: '#fac3b2',
  primary400: '#f6977a',
  primary500: '#f1592a',
  primary600: '#e8430f',
  primary700: '#c7390d',
  primary800: '#a6300b',
  primary900: '#852609',

  gray050: '#f9fafb',
  gray100: '#f3f4f6',
  gray200: '#e5e7eb',
  gray300: '#d1d5db',
  gray400: '#9ca3af',
  gray500: '#6b7280',
  gray600: '#4b5563',
  gray700: '#374151',
  gray800: '#1f2937',
  gray900: '#111827',

  info050: '#eff6ff',
  info100: '#dbeafe',
  info200: '#bfdbfe',
  info300: '#93c5fd',
  info400: '#60a5fa',
  info500: '#3b82f6',
  info600: '#2563eb',
  info700: '#1d4ed8',
  info800: '#1e40af',
  info900: '#1e3a8a',

  success050: '#ecfdf5',
  success100: '#d1fae5',
  success200: '#a7f3d0',
  success300: '#6ee7b7',
  success400: '#34d399',
  success500: '#10b981',
  success600: '#059669',
  success700: '#047857',
  success800: '#065f46',
  success900: '#064e3b',

  error050: '#fef2f2',
  error100: '#fee2e2',
  error200: '#fecaca',
  error300: '#fca5a5',
  error400: '#f87171',
  error500: '#ef4444',
  error600: '#dc2626',
  error700: '#b91c1c',
  error800: '#991b1b',
  error900: '#7f1d1d',

  warning050: '#fffbeb',
  warning100: '#fef3c7',
  warning200: '#fde68a',
  warning300: '#fcd34d',
  warning400: '#fbbf24',
  warning500: '#f59e0b',
  warning600: '#d97706',
  warning700: '#b45309',
  warning800: '#92400e',
  warning900: '#78350f',
};

export { colors };

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import i18n from 'i18next';

export const modalRedirect = (callback: () => void) =>
  Modal.info({
    closable: false,
    title: i18n.t('common:your_token_has_expired'),
    content: i18n.t('common:you_will_be_redirected_to_the_login_page'),
    icon: <ExclamationCircleOutlined />,
    onOk: () => {
      callback();
      const backUrl = `${window.location.pathname}${window.location.search}`;
      var newURL =
        window.location.protocol + '//' + window.location.host + `/login?backUrl=${backUrl}`;
      if (backUrl === '/login') {
        newURL = window.location.protocol + '//' + window.location.host + '/login';
      }
      window.location.replace(newURL);
    },
  });

import React, { useState } from 'react';
import Icon from '@ant-design/icons';
import IconTrash from 'assets/icons/ic_trash_gray.svg';

import SoftSkillFieldsAdd from './SoftSkillFieldsAdd';
import {
  SoftSkillInfoItem,
  SoftSkillContainerInfo,
  SkillInfoItemButton,
  SkillDescInputContainer,
} from '../styled';

const SoftSkillItems = (props: any) => {
  const {
    isCanUpdate,
    indexSoftSkillItem,
    valueSoftSkillItem,
    softSkill,
    setSoftSkill,
    onEmployeeUpdateSoftSkill,
    onDeleteSoftSkill,
  } = props;

  const [isUpdatingSoftSkill, setIsUpdatingSoftSkill] = useState(false);

  const onShowUpdatingSoftSkill = () => {
    setIsUpdatingSoftSkill(!isUpdatingSoftSkill);
  };

  return (
    <>
      <SoftSkillInfoItem key={'SoftSkillItems' + indexSoftSkillItem}>
        <SoftSkillContainerInfo onClick={onShowUpdatingSoftSkill}>
          {valueSoftSkillItem.name}
        </SoftSkillContainerInfo>
        {isCanUpdate && (
          <SkillInfoItemButton
            onClick={onDeleteSoftSkill(indexSoftSkillItem)}
            icon={<Icon component={() => <img src={IconTrash} alt="" />} />}
          />
        )}
      </SoftSkillInfoItem>
      {isUpdatingSoftSkill && (
        <SkillDescInputContainer>
          <SoftSkillFieldsAdd
            isCanUpdate={isCanUpdate}
            indexSoftSkillItem={indexSoftSkillItem}
            valueSoftSkillItem={valueSoftSkillItem}
            softSkill={softSkill}
            setSoftSkill={setSoftSkill}
            onEmployeeUpdateSoftSkill={onEmployeeUpdateSoftSkill}
            setIsAddingSoftSkill={setIsUpdatingSoftSkill}
          />
        </SkillDescInputContainer>
      )}
    </>
  );
};

export default SoftSkillItems;

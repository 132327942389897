import Icon, { DeleteTwoTone, EditTwoTone, PlusOutlined, ReadOutlined } from '@ant-design/icons';
import { Drawer, Form, Modal, Popconfirm } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import ITVSearch from 'components/ITVSearch';
import ITVTable from 'components/ITVTable';
import PageHeader from 'components/PageHeader';
import { selectConfiguration } from 'features/configuration/configurationSlice';
import { HeaderAdd } from 'features/masterData/Department/styled';
import UserGuideForEmailServer from 'features/onboard/components/UserGuideForEmailServer';
import {
  createEmailServer,
  deleteEmailServer,
  getListEmailServer,
  updateEmailServer,
} from 'features/onboard/onboardAction';
import { selectOnboard } from 'features/onboard/onboardSlice';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { ContainerBgWhite } from 'styles/containerBgWhite';
import { Header } from '../ManageEmailTemplate/ManageTemplate';
import ViewConfigServerEmail from './ViewConfigServerEmail';

enum MODE {
  VIEW = 'VIEW',
  ADD = 'ADD',
  UPDATE = 'UPDATE',
}

const DefineNoti = () => {
  const { t } = useTranslation(['onboard', 'validation', 'timesheet', 'menu', 'userguide']);
  const dispatch = useAppDispatch();
  const location: any = useLocation();
  const [form] = Form.useForm();
  const [currentItem, setCurrentItem] = useState<any>(null);
  const [mode, setMode] = useState<string>('');
  const [currentPage, setCurrentPage] = useState(1);
  const [sizePage, setSizePage] = useState(10);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');

  const onboard = useAppSelector(selectOnboard);
  const listEmailServer = onboard?.listEmailServer?.result;
  const [open, setOpen] = useState(false);

  const menuStore = useAppSelector(selectConfiguration);
  const dataScreenManage = menuStore?.dataScreenManage?.result;
  const [titleHeader, setTitleHeader] = useState('');

  useEffect(() => {
    const temp = dataScreenManage
      ?.filter((item: any) => location.pathname === item.path)
      ?.map((item: any) => item.nameScreen);

    setTitleHeader(temp ? temp[0] : '');
  }, [dataScreenManage, location.pathname]);

  const columns: any = [
    {
      title: t('onboard:email_type'),
      dataIndex: 'mailType',
      key: 'mailType',
      width: 100,
      minWidth: 100,
      render: (value: string, record: any, index: any) => {
        return value;
      },
    },
    {
      title: t('onboard:email_name'),
      dataIndex: 'mailNameFrom',
      key: 'mailNameFrom',
      width: 100,
      minWidth: 100,
      render: (value: string, record: any, index: any) => {
        return value;
      },
    },
    {
      title: t('onboard:email_address'),
      dataIndex: 'mailUser',
      key: 'mailUser',
      width: 100,
      minWidth: 100,
      render: (value: string, record: any, index: any) => {
        return value;
      },
    },
    {
      title: t('timesheet:action'),
      width: 100,
      minWidth: 100,
      key: 'isDefault',
      dataIndex: 'isDefault',
      align: 'center',
      render: (text: string, record: any) => {
        return record.isDefault ? (
          <></>
        ) : (
          <>
            <Icon
              component={() => <EditTwoTone twoToneColor="#F1592A" style={{ fontSize: 20 }} />}
              onClick={() => onShowModalUpdate(record)}
            />
            <Popconfirm
              title={t('onboard:are_you_sure_to_delete_this')}
              onConfirm={() => onDelete(record)}
              okText={t('timesheet:Yes')}
              cancelText={t('timesheet:No')}
            >
              <Icon
                component={() => (
                  <DeleteTwoTone twoToneColor="#B7B7B7" style={{ marginLeft: 20, fontSize: 20 }} />
                )}
              />
            </Popconfirm>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(getListEmailServer({}));
  }, [dispatch]);

  useEffect(() => {
    setCurrentPage(1);
    if (listEmailServer?.length) {
      if (search) {
        const temp = listEmailServer?.filter((item: any) => {
          return (
            item.mailNameFrom?.toLowerCase().includes(search) ||
            item.mailUser?.toLowerCase().includes(search)
          );
        });
        setData(temp);
      } else {
        setData(listEmailServer);
      }
    }
  }, [listEmailServer, search]);

  const onShowModalUpdate = (record: any) => {
    setCurrentItem(record);
    setMode(MODE.UPDATE);
  };

  const onDelete = (record: any) => {
    dispatch(deleteEmailServer({ id: record.id }));
  };

  const handleSearch = (key: string) => {
    const text = key.toLowerCase();
    if (text) {
      const temp = listEmailServer?.filter((item: any) => {
        return (
          item.mailNameFrom?.toLowerCase().includes(text) ||
          item.mailUser?.toLowerCase().includes(text)
        );
      });
      setSearch(text);
      setData(temp);
    } else {
      setSearch('');
      setData(listEmailServer);
    }
  };

  const onSave = (values: any) => {
    if (mode === MODE.ADD) {
      dispatch(
        createEmailServer({
          values,
          callBack: () => {
            setMode('');
            setCurrentItem(null);
          },
        }),
      );
    }
    if (mode === MODE.UPDATE) {
      dispatch(
        updateEmailServer({
          ...values,
          id: currentItem?.id,
          callBack: () => {
            setMode('');
            setCurrentItem(null);
          },
        }),
      );
    }
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ContainerWrapper>
        <PageHeader title={titleHeader} />
      </ContainerWrapper>
      <ContainerBgWhite>
        <Header>
          <ITVSearch style={{ width: 300 }} handleSearch={handleSearch} />
          <div>
            <HeaderAdd style={{ marginLeft: '10px' }} icon={<ReadOutlined />} onClick={showDrawer}>
              {t('userguide:user_guide')}
            </HeaderAdd>
            <HeaderAdd
              style={{ margin: 0 }}
              icon={<PlusOutlined />}
              onClick={() => {
                setMode(MODE.ADD);
              }}
            >
              {t('add_new')}
            </HeaderAdd>
          </div>
        </Header>
        <ITVTable
          columns={columns}
          data={data}
          isRowSelect={false}
          setOffset={setCurrentPage}
          offset={currentPage}
          setLimit={setSizePage}
          limit={sizePage}
        />
      </ContainerBgWhite>

      {mode !== '' && (
        <Modal
          visible={mode !== ''}
          title={
            mode === MODE.ADD ? t('onboard:add_new_email_server') : t('onboard:update_email_server')
          }
          okText={t('timesheet:Save')}
          cancelText={t('timesheet:Cancel')}
          width={550}
          maskClosable={false}
          onCancel={() => {
            setMode('');
            form.resetFields();
          }}
          onOk={() => {}}
          centered
          footer={null}
        >
          <ViewConfigServerEmail record={currentItem} mode={mode} onSave={onSave} />
        </Modal>
      )}

      <Drawer
        title={t('userguide:email_server.user_guide_title')}
        placement="left"
        width={500}
        onClose={onClose}
        open={open}
      >
        <UserGuideForEmailServer />
      </Drawer>
    </>
  );
};

export default DefineNoti;

const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

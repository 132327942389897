import { DeleteTwoTone, PlusOutlined } from '@ant-design/icons';
import { Form, Input, Popconfirm } from 'antd';
import ITVTable from 'components/ITVTable';
import MenuAction from 'components/MenuAction';
import { notificationToast } from 'components/notificationToast';
import { Notification } from 'constants/notification';
import { MODE } from 'constants/types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomButton from 'styles/buttonStyled';
import CustomFormItem from 'styles/formStyled';
import CustomInput from 'styles/inputStyled';

const AddNewResponsibility = ({ mode, record, onSave, data }: any) => {
  const [form] = Form.useForm();
  const { t } = useTranslation([
    'validation',
    'masterConfig',
    'insurance',
    'setup_menu_screen',
    'common',
    'overtime',
    'employee',
    'menu',
    'goals',
  ]);

  const [dataCompetenciesState, setDataCompetenciesState] = useState<any>([]);

  const [sizePage, setSizePage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (mode === MODE.EDIT && record) {
      setDataCompetenciesState([record]);
      form.setFieldsValue({
        [`competencyName0`]: record?.goal,
        [`description0`]: record?.description,
        [`weight0`]: parseInt(record?.weight),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record]);

  const menuCompetency = [
    {
      name: t('action:delete'),
      type: 'delete',
      icon: <DeleteTwoTone twoToneColor="#B7B7B7" />,
      handleClick: async (value: any, key: any) => {
        const updatedItems = dataCompetenciesState.filter((item: any) => item.id !== value?.id);

        setDataCompetenciesState(updatedItems);
        updatedItems?.map((element: any, index: any) => {
          form.setFieldsValue({
            [`competencyName${index}`]: element?.goal,
            [`description${index}`]: element?.description,
            [`weight${index}`]: element?.weight,
          });
        });
      },
    },
  ];

  const handleChangeWeight = (e: any, trueIndex: number) => {
    let value = Number(e.target.value);

    if (value < 1) {
      value = 1;
    }
    if (value > 55) {
      value = 55;
    }

    form.setFieldsValue({ [`weight${trueIndex}`]: value });
  };

  let COLUMNS_COMPETENCIES = [
    {
      title: '#',
      dataIndex: 'no',
      key: 'no',
      width: 50,
      minWidth: 50,
      align: 'center',
      render: function (text: any, record: any, index: any) {
        return dataCompetenciesState.map((obj: any) => obj.id).indexOf(record.id) + 1;
      },
    },
    {
      title: t('goals:goal_name'),
      width: 150,
      minWidth: 150,
      dataIndex: 'competencyName',
      key: 'competencyName',
      align: 'left',
      render: (value: string, record: any, index: any) => {
        const trueIndex = index + sizePage * (currentPage - 1);
        return (
          <CustomFormItem
            name={`competencyName${trueIndex}`}
            rules={[
              {
                required: true,
                message: t('HRM_VAD_REQUIRED', {
                  field: t('goals:goal_name'),
                }),
              },
              {
                whitespace: true,
                message: t('HRM_VAD_BLANK_CHARACTERS', {
                  field: t('goals:goal_name'),
                }),
              },
            ]}
          >
            <CustomInput type="text" placeholder={t('goals:goal_name_placeholder')} />
          </CustomFormItem>
        );
      },
    },
    {
      title: t('performance_review:description'),
      width: 150,
      minWidth: 150,
      dataIndex: 'competencyDescription',
      key: 'competencyDescription',
      align: 'left',
      render: (value: string, record: any, index: any) => {
        const trueIndex = index + sizePage * (currentPage - 1);
        return (
          <CustomFormItem
            name={`description${trueIndex}`}
            rules={[
              {
                required: false,
                message: t('HRM_VAD_REQUIRED', { field: t('performance_review:description') }),
              },
              {
                whitespace: true,
                message: t('HRM_VAD_BLANK_CHARACTERS', {
                  field: t('performance_review:description'),
                }),
              },
            ]}
          >
            <CustomInput type="text" />
          </CustomFormItem>
        );
      },
    },
    {
      title: `${t('performance_review:weight')} (%)`,
      width: 150,
      minWidth: 150,
      dataIndex: 'weight',
      key: 'weight',
      align: 'left',
      render: (value: string, record: any, index: any) => {
        const trueIndex = index + sizePage * (currentPage - 1);
        return (
          <CustomFormItem
            name={`weight${trueIndex}`}
            rules={[
              {
                required: true,
                message: t('HRM_VAD_REQUIRED', { field: t('performance_review:weight') }),
              },
            ]}
          >
            <Input
              type="number"
              placeholder={t('performance_review:weight_placeholder')}
              onChange={(e) => handleChangeWeight(e, trueIndex)}
            />
          </CustomFormItem>
        );
      },
    },
  ];

  if (mode !== MODE.EDIT) {
    COLUMNS_COMPETENCIES.push({
      title: t('action:action'),
      dataIndex: 'action',
      key: 'action',
      width: 60,
      minWidth: 60,
      align: 'center',
      render: function (text: any, record: any, index: any) {
        return <MenuAction menu={menuCompetency} data={record} />;
      },
    });
  }

  const handleFormChange = (changedFields: any) => {
    dataCompetenciesState?.forEach((_: any, index: any) => {
      if (`competencyName${index}` === changedFields[0]?.name[0]) {
        const newDataCompetenciesState = [...dataCompetenciesState];
        newDataCompetenciesState[index] = {
          ...newDataCompetenciesState[index],
          goal: changedFields[0]?.value,
        };
        setDataCompetenciesState(newDataCompetenciesState);
      }
      if (`description${index}` === changedFields[0]?.name[0]) {
        const newDataCompetenciesState = [...dataCompetenciesState];
        newDataCompetenciesState[index] = {
          ...newDataCompetenciesState[index],
          description: changedFields[0]?.value,
        };
        setDataCompetenciesState(newDataCompetenciesState);
      }
      if (`weight${index}` === changedFields[0]?.name[0]) {
        const newDataCompetenciesState = [...dataCompetenciesState];
        newDataCompetenciesState[index] = {
          ...newDataCompetenciesState[index],
          weight: Number(changedFields[0]?.value),
        };
        setDataCompetenciesState(newDataCompetenciesState);
      }
    });
  };

  const onFinish = () => {
    form.validateFields().then(async (values) => {
      if (dataCompetenciesState.length === 0) {
        notificationToast(
          Notification.Type.Warning,
          t('goals:goal_empty'),
          Notification.Duration._3s,
        );
      } else {
        if (mode === MODE.EDIT) {
          const arr = data?.mainResponsibilities?.goals.filter(
            (item: any) => item.id !== record.id,
          );

          const total = arr?.reduce((total: any, item: any) => total + item.weight, 0) || 0;
          const totalWeight =
            dataCompetenciesState?.reduce((total: any, item: any) => total + item.weight, 0) || 0;
          if (total + totalWeight > 55) {
            notificationToast(
              Notification.Type.Warning,
              t('goals:goal_percentage'),
              Notification.Duration._3s,
            );
          } else {
            onSave(dataCompetenciesState);
          }
        } else {
          const total =
            data?.mainResponsibilities?.goals?.reduce(
              (total: any, item: any) => total + item.weight,
              0,
            ) || 0;
          const totalWeight =
            dataCompetenciesState?.reduce((total: any, item: any) => total + item.weight, 0) || 0;

          if (total + totalWeight > 55) {
            notificationToast(
              Notification.Type.Warning,
              t('goals:goal_percentage'),
              Notification.Duration._3s,
            );
          } else {
            onSave(dataCompetenciesState);
          }
        }
      }
    });
  };

  const generateUniqueID = () => {
    const timestamp = Date.now();
    const randomNumber = Math.floor(Math.random() * 1001);
    const uniqueID = `${timestamp}${randomNumber}`;
    return uniqueID;
  };

  const onAddCompetenciesRow = () => {
    const newList = dataCompetenciesState.concat({
      id: Number(generateUniqueID()),
      goal: undefined,
      description: undefined,
      weight: undefined,
    });

    setDataCompetenciesState(newList);
  };

  return (
    <>
      <Form form={form} onFieldsChange={handleFormChange} layout="vertical">
        {mode !== MODE.EDIT && (
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 10 }}>
            <CustomButton icon={<PlusOutlined />} onClick={onAddCompetenciesRow}>
              {t('menu:add_row')}
            </CustomButton>
          </div>
        )}

        <ITVTable
          columns={COLUMNS_COMPETENCIES}
          data={dataCompetenciesState}
          isRowSelect={false}
          setOffset={setCurrentPage}
          offset={currentPage}
          setLimit={setSizePage}
          limit={sizePage}
          height={400}
        />
        <div
          style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: '2%' }}
        >
          <Popconfirm
            title={t('overtime:are_you_sure')}
            onConfirm={() => {
              onFinish();
            }}
            okText={`${t('insurance:Yes')}`}
            cancelText={`${t('insurance:no')}`}
          >
            <CustomButton>{t('masterConfig:action.save')}</CustomButton>
          </Popconfirm>
        </div>
      </Form>
    </>
  );
};

export default AddNewResponsibility;

import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import dashboardEmployeeApi from 'api/dashboardEmployeeApi';
import { MONTH_FORMAT, MONTH_YEAR_FORMAT } from 'constants/commons';
import { TimesheetAdjustmentReport } from 'types';
import { exportToCSV } from 'utils/common';
import HeadcountGenderFilters from '../HeadcountGender/HeadcountGenderFilters';
import HeadcountGenderChart from '../HeadcountGender/HeadcountGenderChart';

const StyledContainer = styled.div`
  padding: 16px 24px;
  border: 0.5px solid var(--cl_gray200);
  border-radius: 12px;
  background-color: var(--cl_white);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 25px;
`;

const StyledHeaderTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto;
  line-height: 20px;
  color: #121926;
`;

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  flex-wrap: wrap;
  gap: 10px;
`;

const CHART_COLORS = [
  '#4472C4',
  '#F37725',
  '#A5A5A5',
  '#FFC000',
  '#5B9BD5',
  '#70AD47',
  '#9E480E',
  '#D32F2F',
  '#1976D2',
  '#388E3C',
  '#FBC02D',
  '#8E24AA',
  '#E64A19',
  '#0288D1',
  '#C2185B',
  '#7B1FA2',
  '#00796B',
];

export function HeadcountPositionChart() {
  const { t } = useTranslation(['dashboard']);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const [dataChart, setDataChart] = useState<TimesheetAdjustmentReport>({
    labels: [t('dashboard_employee.male'), t('dashboard_employee.female')],
    datasets: [],
  });

  const [filters, setFilters] = useState<{ period: string; locationId: string }>({
    period: moment(new Date()).format(MONTH_YEAR_FORMAT),
    locationId: '0',
  });

  useEffect(() => {
    async function fetchHeadcountByLine() {
      try {
        setIsLoading(true);
        const response = await dashboardEmployeeApi.getDataHeadcountByPositionChart(filters);
        const labels = response?.data?.result?.map((item) => item?.position);

        const datasets = [
          {
            label: '',
            data: response?.data?.result?.map((item) => item?.count),
            fill: true,
            backgroundColor: response?.data?.result?.map((_, index) => CHART_COLORS[index]),
            borderColor: 'white',
            barThickness: 20,
            borderRadius: 4,
          },
        ];

        setIsLoading(false);
        setDataChart({
          labels,
          datasets,
        });
      } catch (error) {
        setIsLoading(false);
      }
    }

    if (filters?.locationId) {
      fetchHeadcountByLine();
    }
  }, [filters, filters?.locationId]);

  const handleSubmit = ({ monthYear, ...payload }: any) => {
    const formattedYear = moment(monthYear).format(MONTH_YEAR_FORMAT);

    setFilters((prevFilters) => ({
      ...prevFilters,
      ...payload,
      period: formattedYear,
    }));
  };

  const handleExport = async (location: { value: string; label: string }) => {
    try {
      setIsLoadingExport(true);
      const response = await dashboardEmployeeApi.exportHeadcountByPositionChart(filters);
      setIsLoadingExport(false);

      const exportFileName = `Headcount_By_Position_${moment(filters.period).format(
        MONTH_FORMAT,
      )}_${location.label}.xlsx`;
      exportToCSV(response, exportFileName);
    } catch (error) {
      setIsLoadingExport(false);
    }
  };

  return (
    <StyledContainer>
      <StyledWrapper>
        <StyledHeaderTitle>{t('dashboard_employee.headcount_by_position')}</StyledHeaderTitle>

        <HeadcountGenderFilters
          onSubmit={handleSubmit}
          loadingExport={isLoadingExport}
          handleExport={handleExport}
          initialValues={{
            monthYear: moment(new Date(filters.period)),
          }}
        />
      </StyledWrapper>

      <div style={{ marginBottom: 'auto', marginTop: 'auto' }}>
        <HeadcountGenderChart
          loading={isLoading}
          dataChart={dataChart}
          chartName="headcountPosition"
        />
      </div>
    </StyledContainer>
  );
}

import { Spin } from 'antd';
import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{ fontSize: 32 }} spin />;

export const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #f8f8f8ad;
  z-index: 9999;
`;

export const Loader = styled.div`
  top: 45%;
  left: 48%;
  position: absolute;
`;

const Backdrop = ({ isSuspense }: any) => {
  return (
    <Container>
      <Loader>{isSuspense ? <Spin indicator={antIcon} /> : <Spin size="large" />}</Loader>
    </Container>
  );
};

export default Backdrop;

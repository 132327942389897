import styled from 'styled-components';
import { Modal } from 'antd';
export const EmployeeModal = styled(Modal)`
  .ant-modal-content {
    width: 600px;
    .ant-modal-close-x {
      margin-top: -14px;
      margin-right: -16px;
    }
  }
`;

import { Button, Form, Input, Popconfirm, Radio, RadioChangeEvent } from 'antd';
import { useAppSelector } from 'app/hooks';
import { REGEX_GMAIL, REGEX_OUTLOOK } from 'constants/regex';
import { actions, selectConfiguration } from 'features/configuration/configurationSlice';
import { testConnectEmailServer } from 'features/onboard/onboardAction';
import { HOST_NAME_EMAIL } from 'hrm-common/extensions/enums/personel';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import CustomButton from 'styles/buttonStyled';

enum MODE {
  VIEW = 'VIEW',
  ADD = 'ADD',
  UPDATE = 'UPDATE',
}

const ViewConfigServerEmail = ({ mode, record, onSave }: any) => {
  const dataStore = useAppSelector(selectConfiguration);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation([
    'validation',
    'masterConfig',
    'insurance',
    'common',
    'overtime',
    'employee',
    'onboard',
    'auth',
  ]);
  const { setErrorMessage } = actions;
  const error = dataStore.error;

  const [valueHost, setValueHost] = useState(HOST_NAME_EMAIL.GMAIL);
  const [disable, setDisable] = useState(true);
  const [disableField, setDisableField] = useState(false);
  const [text, setText] = useState('');

  useEffect(() => {
    if (error?.fieldErrors) {
      form.setFields([
        {
          name: 'mailNameFrom',
          errors: error?.fieldErrors?.name,
        },
      ]);
    }
    return () => {
      dispatch(setErrorMessage(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, dispatch]);

  useEffect(() => {
    if (mode === MODE.UPDATE && record) {
      form.setFieldsValue({
        mailNameFrom: record?.mailNameFrom,
        mailUser: record?.mailUser,
        mailPassword: record?.mailPassword,
      });
      setValueHost(record?.mailType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record]);

  const onChangeHost = (e: RadioChangeEvent) => {
    setValueHost(e.target.value);
    form.validateFields().then(() => {
      form.setFields([
        {
          name: 'mailNameFrom',
          errors: [],
        },
        {
          name: 'mailUser',
          errors: [],
        },
        {
          name: 'mailPassword',
          errors: [],
        },
      ]);
    });
    setText('');
  };

  const onFinish = () => {
    form.validateFields().then((values) => {
      onSave({ ...values, mailType: valueHost });
    });
  };

  const handleTestConnection = () => {
    form.validateFields().then((values) => {
      dispatch(
        testConnectEmailServer({
          values,
          mailType: valueHost,
          callBack: (res: any) => {
            if (!res.status) {
              setText(res.message);
              setDisable(true);
            } else {
              setText('Successfully');
              setDisable(false);
              setDisableField(true);
            }
          },
        }),
      );
    });
  };

  const handleChangeEmailAddress = () => {
    setDisable(true);
    setText('');
  };

  const handleChangePassword = () => {
    setDisable(true);
    setText('');
  };

  return (
    <Form form={form} layout="vertical">
      <div>
        <span style={{ color: 'red' }}>* </span>
        {t('onboard:email_type')}
      </div>
      <InfoRadio.Group
        style={{ marginBottom: '20px' }}
        onChange={onChangeHost}
        value={valueHost}
        disabled={disableField}
      >
        <InfoRadio value={HOST_NAME_EMAIL.GMAIL}>Gmail</InfoRadio>
        <InfoRadio value={HOST_NAME_EMAIL.OUTLOOK}>Outlook</InfoRadio>
      </InfoRadio.Group>

      <Form.Item
        name={'mailNameFrom'}
        label={<div> {t('onboard:email_name')}</div>}
        rules={[
          {
            required: true,
            message: t('HRM_VAD_REQUIRED', { field: t('onboard:email_name') }),
          },
          {
            whitespace: true,
            message: t('HRM_VAD_BLANK_CHARACTERS', { field: t('onboard:email_name') }),
          },
        ]}
      >
        <Input
          placeholder={t('onboard:placeholder_email_name')}
          maxLength={50}
          disabled={disableField}
        />
      </Form.Item>

      <Form.Item
        name={'mailUser'}
        label={<div> {t('onboard:email_address')}</div>}
        rules={[
          {
            required: true,
            message: t('HRM_VAD_REQUIRED', {
              field: t('onboard:email_address'),
            }),
          },
          {
            pattern:
              valueHost === HOST_NAME_EMAIL.GMAIL
                ? new RegExp(REGEX_GMAIL)
                : new RegExp(REGEX_OUTLOOK),
            message: t('HRM_VAD_FORMAT', {
              field: t('onboard:email_address'),
            }),
          },
          {
            whitespace: true,
            message: t('HRM_VAD_BLANK_CHARACTERS', { field: t('onboard:email_address') }),
          },
        ]}
      >
        <Input
          placeholder={t('auth:sign_in.placeholder_passwrod')}
          maxLength={50}
          onChange={handleChangeEmailAddress}
          disabled={disableField}
        />
      </Form.Item>

      <Form.Item
        name={'mailPassword'}
        label={<div>{t('auth:sign_in.password_label')}</div>}
        rules={[
          {
            required: true,
            message: t('HRM_VAD_REQUIRED', { field: t('auth:sign_in.password_label') }),
          },
          {
            whitespace: true,
            message: t('HRM_VAD_BLANK_CHARACTERS', { field: t('auth:sign_in.password_label') }),
          },
        ]}
      >
        <Input.Password
          placeholder={t('auth:sign_in.placeholder_passwrod')}
          autoComplete="new-password"
          onChange={handleChangePassword}
          disabled={disableField}
        />
      </Form.Item>

      <div style={{ color: `${disable ? 'red' : 'green'}`, fontStyle: 'italic' }}>{text}</div>

      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: '2%' }}>
        <Popconfirm
          title={`${t('insurance:Are_you_sure_confirm_this_request')}`}
          onConfirm={() => {
            onFinish();
          }}
          okText={`${t('insurance:Yes')}`}
          cancelText={`${t('insurance:no')}`}
          disabled={disable}
        >
          {disable ? (
            <Button type="primary" onClick={handleTestConnection}>
              Test Connection
            </Button>
          ) : (
            <CustomButton disabled={disable}>{t('masterConfig:action.save')}</CustomButton>
          )}
        </Popconfirm>
      </div>
    </Form>
  );
};

export default ViewConfigServerEmail;

export const InfoRadio = styled(Radio)`
  .ant-radio-disabled .ant-radio-inner {
    border-color: #f1592a;
  }
  .ant-radio-inner::after {
    background: #f1592a;
    border-color: #f1592a;
  }
`;

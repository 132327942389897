import payrollApi from 'api/payrollApi';
import { useEffect, useState } from 'react';
import { IAllowanceList, AllowanceSumDetail, IContractSalaryFilters } from 'types';

export const useAllowanceList = (params: Partial<IContractSalaryFilters>) => {
  const [allowanceList, setAllowanceList] = useState<Array<IAllowanceList>>([]);
  const [allowanceListSum, setAllowanceListSum] = useState<Array<AllowanceSumDetail>>([]);
  const [totalResult, setTotalResult] = useState(0);
  const [sumTotal, setSumTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<unknown>(undefined);
  const [refresh, setRefresh] = useState(false);
  const [statusConfirm, setStatusConfirm] = useState('');

  useEffect(() => {
    const fetchAllowanceListAPI = async () => {
      try {
        setIsLoading(true);
        const response = await payrollApi.getAllUserHaveAllowance(params);

        setAllowanceList(
          response.data?.result
            ? response.data?.result?.map((item: any) => ({
                ...item,
                userId: item?.user?.id,
                children: [],
              }))
            : [],
        );
        setAllowanceListSum(response.data?.listAllowanceSum || []);
        setSumTotal(response.data?.sumTotal || 0);
        setTotalResult(response.data?.total || 0);
        setStatusConfirm(response.data?.status || '');
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAllowanceListAPI();
  }, [params, refresh]);

  return {
    data: { allowanceList, totalResult, sumTotal, allowanceListSum },
    statusConfirm,
    isLoading,
    error,
    setRefresh,
  };
};

import axiosClient from './axiosClient';

const checkinApi = {
  getOnlineAccessByMonth: (params: { userId: string; month: string }) => {
    const response = axiosClient.get(
      `/online-access?userId=${params.userId}&month=${params.month}`,
    );
    return response;
  },
};

export default checkinApi;

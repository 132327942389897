export const PROFILE_DRAFT = 'draft';
export const PROFILE_PENDING = 'pending';
export const PROFILE_APPROVED = 'approved';
export const PROFILE_DENIED = 'rejected';
export const PROFILE_INACTIVE = 'terminated';
export const PROFILE_DEACTIVE = 'deactive';
export const PROFILE_CANCELLED = 'cancelled';
export const PROFILE_CANCELED = 'canceled';
export const PROFILE_DELETED = 'deleted';
export const PROFILE_INPROGRESS = 'inprogress';
export const PROFILE_UNSAVE = 'unsave';
export const PROFILE_COMPLETED = 'complete';
export const PROFILE_ACTIVE = 'active';
export const PROFILE_STATUSES: { [index: string]: string } = {
  [PROFILE_DRAFT]: 'Draft',
  [PROFILE_PENDING]: 'Pending',
  [PROFILE_APPROVED]: 'Approved',
  [PROFILE_DENIED]: 'Rejected',
  [PROFILE_INACTIVE]: 'Terminated',
  [PROFILE_DEACTIVE]: 'Deactive',
  [PROFILE_CANCELLED]: 'Cancelled',
  [PROFILE_CANCELED]: 'Canceled',
  [PROFILE_DELETED]: 'Deleted',
  [PROFILE_INPROGRESS]: 'In Progress',
  [PROFILE_COMPLETED]: 'Complete',
  [PROFILE_ACTIVE]: 'Active',
  [PROFILE_UNSAVE]: 'Unsave',
};

export const PROFILE_STATUS_COLORS: { [index: string]: string } = {
  [PROFILE_DRAFT]: '#111827',
  [PROFILE_PENDING]: '#d97706',
  [PROFILE_APPROVED]: '#059669',
  [PROFILE_DENIED]: '#dc2626',
  [PROFILE_INACTIVE]: '#9ca3af',
  [PROFILE_DEACTIVE]: '#9ca3af',
  [PROFILE_CANCELLED]: '#9ca3af',
  [PROFILE_CANCELED]: '#9ca3af',
  [PROFILE_INPROGRESS]: '#059669',
  [PROFILE_COMPLETED]: '#d97706',
  [PROFILE_ACTIVE]: '#059669',
  [PROFILE_UNSAVE]: '#9ca3af',
};

import { CalendarOutlined, DeleteTwoTone, PlusOutlined } from '@ant-design/icons';
import { DatePicker, InputNumber, Popconfirm, Select } from 'antd';
import { selectFormatDate, selectFormatMonth } from 'app/commonRedux/appSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { StatusColumn } from 'components/EmployeeList';
import { DatePickerField } from 'components/FormFields';
import ITVTable from 'components/ITVTable';
import MenuAction from 'components/MenuAction';
import { ContractSalaryConfirmStatus, MONTH_YEAR_FORMAT } from 'constants/payroll';
import { PROFILE_STATUSES } from 'constants/profiles';
import {
  getAllAdvanceListForUser,
  getAllAdvanceUser,
  updateAdvancesUser,
} from 'features/employee/employeeAction';
import { selectEmployee } from 'features/employee/employeeSlice';
import { DateType } from 'hrm-common/extensions/enums/personel/declare.enums';
import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import CustomButton from 'styles/buttonStyled';
import { TitleColumnCenter } from 'styles/tableStyled';
import { removeAccents } from 'utils/text';
import ViewPaymentPlan from './ViewPaymentPlan';
import ViewPlan from './ViewPlan';
import ViewPlanEdit from './ViewPlanEdit';

const { Option } = Select;

enum MODE {
  VIEW = 'VIEW',
  ADD = 'ADD',
  UPDATE = 'UPDATE',
}

const AdvancesTable = (props: any) => {
  const { employeeData, callBack, monthYear } = props;

  const { control } = useForm();
  const dispatch = useAppDispatch();
  const formatDate = useAppSelector(selectFormatDate);
  const formatMonth = useAppSelector(selectFormatMonth);
  const employee = useAppSelector<any>(selectEmployee);
  const loadingAllowance = employee?.loadingAllowance;
  const dataAssignAdvance = employee?.dataAssignAdvance?.result;
  const statusConfirmAdvance = employee?.dataAssignAdvance?.status;
  const dataAdvances = employee?.dataListAdvances?.result;

  const [sizePage, setSizePage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState<any>([]);
  const [dataOri, setDataOri] = useState<any>([]);
  const [, setErrMsg] = useState<any>('');
  const [errList, setErrList] = useState<any>([]);
  const [mode, setMode] = useState<string>('');
  const [currentItem, setCurrentItem] = useState<any>(null);
  const [dataAdvanceHistory, setDataAdvanceHistory] = useState<any>([]);
  const [dataAdvanceHistoryOld, setDataAdvanceHistoryOld] = useState<any>([]);
  const [dataTablePlan, setDataTablePlan] = useState([]);
  const [dataTablePlanOri, setDataTablePlanOri] = useState([]);
  const [errMsgFE, setErrMsgFE] = useState<any>([]);
  const [, setValueAmount] = useState();
  const [currency, setCurrency]: any = useState('VND');
  const [month, setMonth] = useState(monthYear ?? moment().format(MONTH_YEAR_FORMAT));

  let currencies = [{ id: 1, value: 'VND' }];

  const { t } = useTranslation(['menu', 'action', 'overtime', 'timesheet', 'employee', 'leaveOff']);

  useEffect(() => {
    if (employeeData?.id) {
      dispatch(getAllAdvanceListForUser({}));
      dispatch(getAllAdvanceUser({ userId: employeeData?.id, monthYear: month }));
    }
  }, [dispatch, employeeData?.id, month]);

  useEffect(() => {
    if (dataAssignAdvance?.length)
      setData(
        dataAssignAdvance?.map((item: any, index: any) => ({
          ...item,
          key: index,
          amount: Number(item?.amount),
          paymentPlan: item?.paymentPlan?.map((item: any) => {
            return { ...item, amount: Number(item?.amount) };
          }),
        })),
      );
    else setData([]);
  }, [dataAssignAdvance]);

  const uniqueArray = (arr: any) =>
    arr.filter((value: any, index: any) => {
      const _value = value?.id;
      return (
        index ===
        arr.findIndex((obj: any) => {
          return obj?.id === _value;
        })
      );
    });

  useEffect(() => {
    setDataAdvanceHistory(dataAdvances);
    if (dataAdvances?.length >= 0 && dataAssignAdvance?.length >= 0) {
      const isNewData = dataAssignAdvance?.filter(
        (item: any) =>
          !dataAdvances?.some((i: any) => item?.advanceConfigurationHistory?.id === i?.id),
      ); // check if allowance update new allowance but current is old -> if >0 then current is old id
      if (isNewData?.length) {
        const oldData = isNewData?.map((item: any) => {
          return {
            ...item,
            name: item?.advanceConfigurationHistory?.name,
            id: item?.advanceConfigurationHistory?.id,
          };
        });
        setDataAdvanceHistory([...dataAdvances, ...uniqueArray(oldData)]);
        setDataAdvanceHistoryOld(uniqueArray(oldData));
      }
    }
  }, [dataAdvances, dataAssignAdvance]);

  const checkDateType = (value: any) => {
    switch (value) {
      case DateType.DATE:
        return 'date';
      case DateType.MONTH:
        return 'month';
      case DateType.YEAR:
        return 'year';
      case DateType.QUARTER:
        return 'quarter';
      default:
        return 'date';
    }
  };

  const handleSelect = (value: any) => {
    setCurrency(value);
    let temp1: any = data?.map((obj: any, objIndex: any) => {
      return {
        ...obj,
        amount: value === 'VND' ? Math.round(obj?.amount / 1000) * 1000 : obj?.amount ?? 100000000,
      };
    });
    setData(temp1);
  };
  function checkActionDelete(record: any) {
    return record
      ? {}
      : {
          name: t('action:delete'),
          type: 'delete',
          icon: <DeleteTwoTone twoToneColor="#B7B7B7" disabled={true} />,
          handleClick: (value: any) => {
            let copyArr = JSON.parse(JSON.stringify(data));
            copyArr.splice(
              copyArr.findIndex((item: any) => item?.key === value?.key),
              1,
            );
            setData(copyArr?.map((item: any, index: any) => ({ ...item, key: index })));
          },
        };
  }
  function checkDataAdd(item: any) {
    return item?.id
      ? {
          id: item?.id,
          effectiveDate: item?.effectiveDate,
          amount: Number(item?.amount),
          term: item?.term,
          status: item?.status,
          user: item?.user,
          advanceConfigurationHistoryId:
            item?.advanceConfigurationHistoryId ?? item?.advanceConfigurationHistory?.id,
          paymentPlanDto: item?.paymentPlan?.map((item: any) => {
            return {
              ...item,
              amount: Number(item?.amount),
            };
          }),
        }
      : {
          amount: Number(item?.amount),
          paymentPlanDto: item?.paymentPlan?.map((item: any) => {
            return {
              month: item?.month,
              amount: Number(item?.amount),
              paidStatus: item?.paidStatus,
            };
          }),
          status: item?.status,
          term: item?.term,
          user: item?.user,
          effectiveDate: item?.effectiveDate,
          advanceConfigurationHistoryId:
            item?.advanceConfigurationHistoryId ?? item?.advanceConfigurationHistory?.id,
        };
  }
  const handleOnChangeTypeAdvance = (value, trueIndex) => {
    let temp1: any = data?.map((obj: any, objIndex: any) =>
      objIndex === trueIndex ? { ...obj, advanceConfigurationHistoryId: value } : obj,
    );
    let temp2: any = dataOri?.map((obj: any, objIndex: any) =>
      objIndex === trueIndex ? { ...obj, advanceConfigurationHistoryId: value } : obj,
    );
    setData(temp1);
    setDataOri(temp2);
  };
  const showItemTypeAdvance = (record: any) => {
    return record?.advanceConfigurationHistory?.id
      ? (dataAdvanceHistoryOld //check if have old allowance data
          ?.some(
            (item: any) =>
              item?.id === record?.advanceConfigurationHistoryId ||
              item?.id === record?.advanceConfigurationHistory?.id,
          )
          ? [
              ...dataAdvances,
              dataAdvanceHistory
                ?.filter((item: any) => dataAdvanceHistoryOld?.some((i: any) => i?.id === item?.id))
                ?.find(
                  (item: any) =>
                    item?.id === record?.advanceConfigurationHistoryId ||
                    item?.id === record?.advanceConfigurationHistory?.id,
                ),
            ] //array of old allowance data
          : dataAdvanceHistory //array of new allowance data
              ?.filter((item: any) => !dataAdvanceHistoryOld?.some((i: any) => i?.id === item?.id))
        )?.map((item: any) => {
          return (
            <Option key={item?.id} value={item?.id}>
              {item?.name}
            </Option>
          );
        })
      : (dataAdvanceHistoryOld //check if have old allowance data
          ?.some(
            (item: any) =>
              item?.id === record?.advanceConfigurationHistoryId ||
              item?.id === record?.advanceConfigurationHistory?.id,
          )
          ? [
              ...dataAdvances,
              dataAdvanceHistory
                ?.filter((item: any) => dataAdvanceHistoryOld?.some((i: any) => i?.id === item?.id))
                ?.find(
                  (item: any) =>
                    item?.id === record?.advanceConfigurationHistoryId ||
                    item?.id === record?.advanceConfigurationHistory?.id,
                ),
            ] //array of old allowance data
          : dataAdvanceHistory //array of new allowance data
              ?.filter((item: any) => !dataAdvanceHistoryOld?.some((i: any) => i?.id === item?.id))
        )
          // eslint-disable-next-line array-callback-return
          ?.map((item: any) => {
            if (!item?.deleteFlag) {
              return (
                <Option key={item?.id} value={item?.id}>
                  {item?.name}
                </Option>
              );
            }
          });
  };
  const handleOnChangeTypeEffectiveDate = (value, trueIndex) => {
    let temp1: any = data?.map((obj: any, objIndex: any) =>
      objIndex === trueIndex
        ? {
            ...obj,
            effectiveDate: value ? moment(value) : null,
            endDate: null,
            paymentPlan: [],
            editedPlan: false,
          }
        : obj,
    );
    let temp2: any = dataOri?.map((obj: any, objIndex: any) =>
      objIndex === trueIndex
        ? {
            ...obj,
            effectiveDate: value ? moment(value) : null,
            endDate: null,
            paymentPlan: [],
            editedPlan: false,
          }
        : obj,
    );
    setData(temp1);
    setDataOri(temp2);
  };
  const handleOnChangeTypeDisbursement = (value, trueIndex) => {
    let temp1: any = data?.map((obj: any, objIndex: any) =>
      objIndex === trueIndex
        ? {
            ...obj,
            amount: currency === 'VND' ? value : value ?? 100000000,
            remain: value,
            paymentPlan: undefined,
            editedPlan: false,
          }
        : obj,
    );
    let temp2: any = dataOri?.map((obj: any, objIndex: any) =>
      objIndex === trueIndex
        ? {
            ...obj,
            amount: Math.round(value / 100000) * 100000 ?? 100000000,
            remain: Math.round(value / 100000) * 100000 ?? 100000000,
            paymentPlan: undefined,
            editedPlan: false,
          }
        : obj,
    );
    setData(temp1);
    setDataOri(temp2);
    setValueAmount(value);
  };
  const handleOnChangeTerm = (record, value, trueIndex, index) => {
    let temp1: any = data?.map((obj: any, objIndex: any) =>
      objIndex === trueIndex
        ? {
            ...obj,
            term: record?.advanceConfigurationHistory?.id
              ? value >= dataAssignAdvance[index]?.term
                ? value
                : dataAssignAdvance[index]?.term
              : value ?? 1,
            paymentPlan: [],
            editedPlan: false,
            status: record?.advanceConfigurationHistory?.id ? 'unsave' : 'draft',
          }
        : obj,
    );
    let temp2: any = dataOri?.map((obj: any, objIndex: any) =>
      objIndex === trueIndex
        ? { ...obj, term: value ?? 1, paymentPlan: [], editedPlan: false }
        : obj,
    );
    setData(temp1);
    setDataOri(temp2);
  };
  const handleClickPlan = (record) => {
    if (record?.advanceConfigurationHistory?.id) {
      setMode(MODE.UPDATE);
      setCurrentItem(record);
    } else {
      setMode(MODE.ADD);
      setCurrentItem(record);
    }
  };

  const disabledDate = (current: moment.Moment | null) => {
    if (!current) return false;

    const startOfMonth = moment(month).startOf('month');
    return current.isBefore(startOfMonth);
  };

  const COLUMNS: any = [
    {
      title: t('employee:bonus_information_fields.col_id'),
      dataIndex: 'id',
      key: 'id',
      width: 40,
      minWidth: 40,
      align: 'center',
      fixed: errList.length > 0 ? '' : 'left',
      render: function (text: any, record: any, index: any) {
        return <div>{record?.key + 1}</div>;
      },
    },
    {
      title: t('employee:advance_information_fields.type_advance'),
      dataIndex: 'type',
      key: 'type',
      width: 150,
      minWidth: 150,
      ellipsis: true,
      fixed: errList.length > 0 ? '' : 'left',
      render: function (text: any, record: any, index: any) {
        const trueIndex = index + sizePage * (currentPage - 1);
        return (
          <Select
            disabled={record?.advanceConfigurationHistory?.id ? true : false}
            showSearch
            onChange={(value: any) => {
              handleOnChangeTypeAdvance(value, trueIndex);
            }}
            defaultValue={
              (record?.advanceConfigurationHistoryId || record?.advanceConfigurationHistory?.id) ??
              null
            }
            style={{ width: '100%' }}
            filterOption={(input, option) => {
              return removeAccents(option!.children as unknown as string)
                ?.toLowerCase()
                ?.includes(removeAccents(input)?.toLowerCase());
            }}
          >
            {showItemTypeAdvance(record)}
          </Select>
        );
      },
    },
    {
      title: t('employee:advance_information_fields.effective_date'),
      dataIndex: 'effectiveDate',
      key: 'effectiveDate',
      width: 150,
      minWidth: 150,
      render: function (text: any, record: any, index: any) {
        const trueIndex = index + sizePage * (currentPage - 1);
        return (
          <DatePicker
            disabled={record?.advanceConfigurationHistory?.id ? true : false}
            allowClear={false}
            picker={checkDateType(record?.type)}
            format={
              checkDateType(record?.type) === 'date'
                ? formatDate
                : checkDateType(record?.type) === 'month'
                ? formatMonth
                : checkDateType(record?.type) === 'quarter'
                ? 'YYYY-Qo'
                : 'YYYY'
            }
            disabledDate={(current) => disabledDate(current)}
            onChange={(value: any) => {
              handleOnChangeTypeEffectiveDate(value, trueIndex);
            }}
            value={record?.effectiveDate ? moment(record?.effectiveDate) : undefined}
            style={{ width: '95s%' }}
          ></DatePicker>
        );
      },
    },
    {
      title: t('employee:advance_information_fields.advance_payment'),
      children: [
        {
          title: t('employee:advance_information_fields.disbursement'),
          dataIndex: 'amount',
          key: 'amount',
          width: 138,
          render: function (text: any, record: any, index: any) {
            const trueIndex = index + sizePage * (currentPage - 1);

            return (
              <CustomInputNumber
                disabled={record?.advanceConfigurationHistory?.id ? true : false}
                max={10000000000}
                min={1000}
                style={{ width: '100%' }}
                onChange={(value: any) => {
                  handleOnChangeTypeDisbursement(value, trueIndex);
                }}
                defaultValue={text || 0}
                formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value: any) => (value ? value.replace(/\$\s?|(,*)/g, '') : 100000)}
              />
            );
          },
        },
        {
          title: t('employee:advance_information_fields.repayment'),
          dataIndex: 'paidAmount',
          key: 'paidAmount',
          width: 120,
          align: 'right',
          render: function (text: any, record: any, index: any) {
            let filterPaidAmount = record?.paymentPlan?.filter((item) => {
              return item.paidStatus;
            });

            const moneyPaidAmount =
              filterPaidAmount?.reduce((acc: any, curr: any) => {
                return acc + curr.amount;
              }, 0) ?? 0;

            return currency === 'VND'
              ? `${Number(moneyPaidAmount)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',').split('.')[0]
              : currency === 'USD'
              ? Intl.NumberFormat('en-US', {
                  // style: 'currency',
                  currency: 'USD',
                  minimumFractionDigits: 2,
                }).format(moneyPaidAmount)
              : moneyPaidAmount;
          },
        },
        {
          title: t('employee:advance_information_fields.outstanding_balance'),
          dataIndex: 'remain',
          key: 'remain',
          width: 120,
          align: 'right',
          render: function (text: any, record: any, index: any) {
            let filterRemainAmount = record?.paymentPlan?.filter((item) => {
              return !item.paidStatus;
            });

            const moneyRemainAmount =
              filterRemainAmount?.reduce((acc: any, curr: any) => {
                return acc + curr.amount;
              }, 0) ?? 0;
            return formatAdvanceCurrencyUnit(moneyRemainAmount, '');
          },
        },
      ],
    },
    {
      title: (
        <div>
          <p style={{ marginBottom: 0 }}>{t('employee:advance_information_fields.term')} </p>
        </div>
      ),
      dataIndex: 'term',
      key: 'term',
      width: 150,
      render: function (text: any, record: any, index: any) {
        const trueIndex = index + sizePage * (currentPage - 1);
        return (
          <CustomInputNumber
            disabled={
              statusConfirmAdvance === ContractSalaryConfirmStatus.APPROVED
                ? true
                : record?.status === true
                ? true
                : false
            }
            max={36}
            min={1}
            style={{ width: '100%' }}
            onChange={(value: any) => {
              handleOnChangeTerm(record, parseInt(value), trueIndex, index);
            }}
            value={text}
            addonAfter="months"
          />
        );
      },
    },
    {
      title: () => {
        return (
          <TitleColumnCenter>{t('employee:advance_information_fields.status')}</TitleColumnCenter>
        );
      },
      key: 'status',
      dataIndex: 'status',
      width: 130,
      minWidth: 130,
      render: function (value: any, record: any, index: any) {
        let status = '';
        if (value === true) {
          status = 'complete';
        } else if (value === false) {
          status = 'inprogress';
        } else if (value === 'draft') {
          status = 'draft';
        } else if (value === 'unsave') {
          status = 'unsave';
        }
        return <StatusColumn status={status}>• {PROFILE_STATUSES[status]}</StatusColumn>;
      },
    },
    {
      title: t('employee:advance_information_fields.plan'),
      dataIndex: 'plan',
      key: 'plan',
      width: 80,
      minWidth: 80,
      align: 'center',
      render: function (text: any, record: any, index: any) {
        return (
          <span
            onClick={() => {
              handleClickPlan(record);
            }}
          >
            <CalendarOutlined />
          </span>
        );
      },
    },
    {
      title: t('employee:employee_information_fields.action'),
      dataIndex: 'action',
      key: 'action',
      width: 60,
      minWidth: 60,
      align: 'center',
      render: function (text: any, record: any, index: any) {
        return (
          <MenuAction
            menu={[checkActionDelete(record?.advanceConfigurationHistory?.id)]}
            data={record}
            disabled={record?.advanceConfigurationHistory?.id ? true : false}
          />
        );
      },
    },
  ];

  const onAddRow = () => {
    if (data?.length >= 0) {
      const newList = data.concat({
        key: data?.length,
        advanceConfigurationHistoryId: dataAdvances?.[0]?.id,
        effectiveDate: new Date(),
        amount: 1000000,
        term: 1,
        status: 'draft',
        remain: 1000000,
        paidAmount: 0,
      });
      setData(newList);
    }
  };
  const onSave = (dataTablePlan, recordId) => {
    let dataUpdate = data?.map((item, idx) => {
      if (recordId === item?.key) {
        return { ...item, paymentPlan: dataTablePlan, editedPlan: true };
      } else {
        return { ...item };
      }
    });
    setData(dataUpdate);
    setMode('');
  };

  const onSaveEdit = (dataTablePlan, recordId) => {
    let sortDataPlan = dataTablePlan.sort((a, b) => a.id - b.id);

    let dataUpdate = data?.map((item, idx) => {
      if (recordId === item?.key) {
        return {
          ...item,
          paymentPlan: sortDataPlan,
          editedPlan: true,
          status: 'unsave',
        };
      } else {
        return { ...item };
      }
    });
    setData(dataUpdate);
    setMode('');
  };

  useEffect(() => {
    props.onReceiveData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onSaveConfirm = () => {
    let errPlanEmpty = [];
    let errborderEmpty = [];
    for (let index = 0; index < data.length; index++) {
      if (data[index].paymentPlan <= 0 || !data[index].paymentPlan) {
        errPlanEmpty.push({
          id: data[index].key + 1,
          idType:
            data[index]?.advanceConfigurationHistory?.id ||
            data[index]?.advanceConfigurationHistoryId,
        });
        errborderEmpty.push(data[index].key);
      }
    }
    setErrList(errborderEmpty);
    if (errPlanEmpty.length <= 0) {
      const updateData = data?.map((item: any) => {
        return checkDataAdd(item);
      });

      dispatch(
        updateAdvancesUser({
          id: employeeData?.id,
          data: updateData,
          callBack: () => {
            dispatch(getAllAdvanceUser({ userId: employeeData?.id, monthYear: month }));
            callBack && callBack();
          },
        }),
      );
    }

    setErrMsgFE(errPlanEmpty);
  };

  const handleChangeMonth = (value: Moment | null) => {
    if (value) {
      setMonth(moment(value).format(MONTH_YEAR_FORMAT));
    }
  };

  return (
    <div style={{ backgroundColor: '#fff', padding: 10, display: 'grid' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', gap: '16px' }}>
          <div>
            <span>{t('employee:advance_information_fields.currency_unit')}: </span>
            <Select defaultValue="VND" style={{ width: 80 }} onChange={handleSelect}>
              {currencies.map((data: any, key: any) => {
                return (
                  <Select.Option defaultValue="VND" value={data.value}>
                    {data.value}
                  </Select.Option>
                );
              })}
            </Select>
          </div>
          <DatePickerField
            name="monthYear"
            control={control}
            picker="month"
            allowClear={false}
            value={moment(month)}
            defaultValue={moment(month)}
            onChange={handleChangeMonth}
            disabledDate={(currentMonth) => moment() < currentMonth}
          />
        </div>
        {statusConfirmAdvance !== ContractSalaryConfirmStatus.APPROVED && (
          <CustomButton icon={<PlusOutlined />} onClick={onAddRow}>
            {t('action:add_new')}
          </CustomButton>
        )}
      </div>
      <div style={{ overflow: 'scroll' }}>
        <ITVTable
          height={350}
          loading={loadingAllowance}
          columns={COLUMNS}
          data={data}
          isRowSelect={false}
          setOffset={setCurrentPage}
          offset={currentPage}
          setLimit={setSizePage}
          limit={sizePage}
          errorList={errList}
        />
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {/* {errMsgFE.length > 0 && ( */}
        <div style={{ color: 'red', height: 80, overflowY: 'auto' }}>
          {errMsgFE?.map((item: any, i: any, arr: any) => {
            let divider = i < arr.length - 1 && <div></div>;
            return (
              <span key={i}>
                <div>
                  {item?.id}
                  {' - '}
                  {
                    dataAdvances?.find((itemAdvance) => itemAdvance?.id === item?.idType)?.name
                  }: {t('employee:advance_information_fields.error_review')}
                </div>

                {divider}
              </span>
            );
          })}
        </div>
        {statusConfirmAdvance !== ContractSalaryConfirmStatus.APPROVED && (
          <div>
            <Popconfirm
              title={t('leaveOff:modal_item.are_you_sure_save')}
              onConfirm={() => {
                setErrList([]);
                setErrMsg(null);
                onSaveConfirm();
              }}
              okText={t('Yes')}
              cancelText={t('No')}
              placement="topRight"
            >
              <CustomButton>{t('action:save')}</CustomButton>
            </Popconfirm>
          </div>
        )}
      </div>
      {mode === MODE.ADD && (
        <ViewPlan
          recordItem={currentItem}
          onSave={onSave}
          mode={mode}
          dataTablePlan={dataTablePlan}
          setDataTablePlan={setDataTablePlan}
          dataTablePlanOri={dataTablePlanOri}
          setDataTablePlanOri={setDataTablePlanOri}
          data={data}
          setData={setData}
          setMode={setMode}
          currency={currency}
        />
      )}
      {mode === MODE.UPDATE && statusConfirmAdvance !== ContractSalaryConfirmStatus.APPROVED && (
        <ViewPlanEdit
          recordItem={currentItem}
          onSaveEdit={onSaveEdit}
          mode={mode}
          dataTablePlan={dataTablePlan}
          setDataTablePlan={setDataTablePlan}
          dataTablePlanOri={dataTablePlanOri}
          setDataTablePlanOri={setDataTablePlanOri}
          data={data}
          setData={setData}
          setMode={setMode}
        />
      )}
      {mode === MODE.UPDATE && statusConfirmAdvance === ContractSalaryConfirmStatus.APPROVED && (
        <ViewPaymentPlan mode={mode} recordItem={currentItem} setMode={setMode} />
      )}
    </div>
  );
};

export default AdvancesTable;

export const CustomInputNumber = styled(InputNumber)`
  .ant-input-number-input {
    text-align: right;
  }
  .ant-input-number-handler-wrap {
    padding-right: 5px;
  }
  .ant-input-number-handler-wrap {
    display: none;
  }
`;

export const formatAdvanceCurrencyUnit = (input_newsalary: any, key: any) => {
  let str =
    key === 'VND'
      ? parseInt(input_newsalary)
          .toFixed(0)
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
          .concat(' VNĐ')
      : parseInt(input_newsalary)
          .toFixed(0)
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
          .concat('');
  return str;
};

import { Popconfirm } from 'antd';
import { notificationToast } from 'components/notificationToast';
import { Notification } from 'constants/notification';
import { PermissionType } from 'hrm-common/extensions/enums/personel';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getUser } from 'utils/auth';
import { deleteSchedulePremium, postApproved } from '../insuranceAction';
import { InfoButton } from './ModalEdit';
import ModalReject from './ModalReject';
import { InfoButtonReject } from './ModalView';

const AppActionView = (props: any) => {
  const dispatch = useDispatch();
  const { t }: any = useTranslation(['insurance']);

  const { permissions } = props;

  const [isVisibleInsuranceReject, setIsVisibleInsuranceReject] = useState(false);
  const handleRejectInsurance = () => {
    setIsVisibleInsuranceReject(false);
  };
  const onclickApprovel = () => {
    dispatch(
      postApproved({
        status: 'approved',
        approvedBy: getUser()?.fullName,
        reason: '',
        select: {
          key: 'id',
          value: props.checkid,
        },
        callBack: (response: any) => {
          if (!response.error) {
            notificationToast(
              Notification.Type.Success,
              t('approved_ansurance_success'),
              Notification.Duration._4s,
            );
            props.reSetData && props.reSetData();
            props.handleHideMenuActions && props.handleHideMenuActions();
          }
        },
      }),
    );
  };
  const onclickDelete = () => {
    dispatch(
      deleteSchedulePremium({
        listId: props.checkid,
        callBack: (response: any) => {
          if (!response.error) {
            props.handleHideMenuActions && props.handleHideMenuActions();
            props.reSetData && props.reSetData();
          }
        },
      }),
    );
  };
  const onclickRejected = () => {
    props.handleVisibleAddColumn && props.handleVisibleAddColumn();
    setIsVisibleInsuranceReject(true);
  };
  return (
    <div style={{ width: 100 }}>
      {permissions?.includes(PermissionType.INSURANCE_APPROVAL_APPROVE_REJECT_REQUEST) && (
        <>
          <Popconfirm
            title={t('Are_you_approve_request')}
            onConfirm={() => {
              onclickApprovel();
            }}
            okText={t('yes')}
            cancelText={t('no')}
          >
            <InfoButton style={{ width: 90 }}>{t('approve')}</InfoButton>
          </Popconfirm>

          <InfoButtonReject style={{ width: 85, margin: 6 }} onClick={onclickRejected}>
            {t('reject')}
          </InfoButtonReject>
        </>
      )}

      {permissions?.includes(PermissionType.INSURANCE_APPROVAL_DELETE) && (
        <Popconfirm
          title={t('Are_you_delete_request')}
          onConfirm={() => {
            onclickDelete();
          }}
          okText={t('yes')}
          cancelText={t('no')}
        >
          <InfoButton style={{ width: 90, background: '#865959' }}>{t('delete')}</InfoButton>
        </Popconfirm>
      )}

      <ModalReject
        isVisible={isVisibleInsuranceReject}
        itemId={{ id: props.checkid, key: 'AppActionView' }}
        reSetData={props.reSetData}
        isShowFullMenu={false}
        onClose={handleRejectInsurance}
      />
    </div>
  );
};

export default AppActionView;

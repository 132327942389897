import { DownOutlined } from '@ant-design/icons';
import { DatePicker } from 'antd';
import { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useAppSelector } from 'app/hooks';
import IconExport from 'assets/icons/ic_export_gray.svg';
import { SelectField } from 'components/FormFields';
import { CustomIcon } from 'components/ITVSearch';
import { selectConfiguration } from 'features/configuration/configurationSlice';
import { HeaderRemove } from 'features/masterData/Department/styled';

const StyledForm = styled.form`
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const StyledWrapperYear = styled.div`
  width: 80px;
  .ant-picker {
    border-radius: 6px;
    border: 1px solid var(--cl_neutral300);
  }
  .ant-picker-input > input {
    color: #1a2b88;
    font-weight: bold;
    font-size: 12px;
  }
  .ant-picker-suffix {
    font-size: 10px;
  }
  .ant-picker {
    background-color: #f6f6f6;
    padding: 5px;
  }
`;
const StyledWrapperObjectId = styled.div`
  width: 150px;
  .ant-select-selector {
    background-color: #f6f6f6 !important;
  }
  .ant-select-selection-item {
    color: #1a2b88 !important;
    font-weight: bold !important;
    font-size: 12px !important;
  }
`;

interface SelectOption {
  label: string;
  value: string;
}

export interface HeadcountContractTypePayload {
  monthYear: Moment | null;
  locationId: string;
}

interface HeadcountContractTypeFiltersProps {
  onSubmit?: (payload: Partial<HeadcountContractTypePayload>) => void;
  handleExport?: (value: { label: string; value: string }) => void;
  loadingExport?: boolean;
  initialValues?: Partial<HeadcountContractTypePayload>;
}

export default function HeadcountContractTypeFilters({
  onSubmit,
  handleExport,
  loadingExport = false,
  initialValues,
}: HeadcountContractTypeFiltersProps) {
  const { t } = useTranslation(['dashboard', 'action']);
  const dataConfiguration = useAppSelector(selectConfiguration);

  const IconExportComponents = (
    <CustomIcon type={'export'} component={() => <img src={IconExport} alt="" />} />
  );

  const [monthYear, setMonthYear] = useState<Moment | null>(initialValues?.monthYear || null);
  const [isLoading, setIsLoading] = useState(false);
  const [locationOptions, setLocationOptions] = useState<Array<SelectOption>>([]);

  const { control, handleSubmit, getValues, setValue } = useForm<HeadcountContractTypePayload>({
    defaultValues: initialValues,
  });

  useEffect(() => {
    async function fetchSubViewBy() {
      try {
        setIsLoading(true);
        const options = dataConfiguration.dataLocation.map((x: any) => ({
          label: x.label,
          value: String(x.id),
        }));

        options.unshift({
          label: `${t('dashboard:dashboard_employee.all_locations')}`,
          value: '0',
        });

        if (options[0].value) {
          setValue('locationId', options[0].value);
        }

        setLocationOptions(options);

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }

    fetchSubViewBy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataConfiguration.dataLocation]);

  const handleFiltersSubmit = (formValues: HeadcountContractTypePayload) => {
    onSubmit?.({ ...formValues, monthYear: monthYear });
  };

  const handleExportLine = () => {
    const locationId = getValues('locationId');
    const selectedLocation = locationOptions.filter(
      (item: { value: string; label: string }) => item.value === locationId,
    );
    handleExport?.(selectedLocation[0]);
  };

  return (
    <StyledForm onSubmit={handleSubmit(handleFiltersSubmit)}>
      <StyledContainer>
        <StyledWrapperYear>
          <DatePicker
            picker="month"
            value={monthYear}
            allowClear={false}
            suffixIcon={<DownOutlined />}
            onChange={(value) => {
              setMonthYear(value);
              onSubmit?.({ monthYear: value });
            }}
            format={'MM-YYYY'}
          />
        </StyledWrapperYear>

        <StyledWrapperObjectId>
          <SelectField
            name="locationId"
            control={control}
            options={locationOptions}
            defaultValue={'0'}
            showSearch
            allowClear={false}
            loading={isLoading}
            onChange={handleSubmit(handleFiltersSubmit)}
          />
        </StyledWrapperObjectId>

        <HeaderRemove
          onClick={handleExportLine}
          icon={IconExportComponents}
          loading={loadingExport}
          style={{
            backgroundColor: '#f6f6f6',
            fontSize: '12px',
            color: '#1a2b88',
            fontWeight: 'bold',
          }}
        >
          {t('action:export')}
        </HeaderRemove>
      </StyledContainer>
    </StyledForm>
  );
}

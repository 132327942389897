import { DeleteTwoTone, PlusOutlined } from '@ant-design/icons';
import { DatePicker, Select, InputNumber, Popconfirm } from 'antd';
import { selectFormatDate } from 'app/commonRedux/appSlice';
import { useAppSelector } from 'app/hooks';
import ITVTable from 'components/ITVTable';
import MenuAction from 'components/MenuAction';
import { selectOvertimeData } from 'features/overtime/overtimeSlice';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomButton from 'styles/buttonStyled';
import styled from 'styled-components';
import { CustomInputNumber } from 'features/employee/components/ManagePayroll/components/ViewBonus';

const { Option } = Select;

const MOCK_SALARY_LIST = [
  {
    id: 1,
    positions: 'Manager',
    type: 'Full Time',
    grossSalary: 30000000,
    mainSalary: 30000000,
    startDate: new Date(),
    endDate: new Date(),
    fullSalary: 0,
    rate: 100,
  },
  {
    id: 2,
    positions: 'Fresher',
    type: 'Probationary',
    grossSalary: 15000000,
    mainSalary: 15000000,
    startDate: new Date(),
    endDate: new Date(),
    fullSalary: 1,
    rate: 90,
  },
  {
    id: 3,
    positions: 'Senior',
    type: 'Full Time',
    grossSalary: 5000000,
    mainSalary: 5000000,
    startDate: new Date(),
    endDate: new Date(),
    fullSalary: 0,
    rate: 100,
  },
];

const MOCK_TYPE_CONFIG = [
  {
    id: 1,
    name: 'Full time >= 3 month',
    action: '',
  },
  {
    id: 2,
    name: 'Probationary < 3 month',
    action: '',
  },
  {
    id: 3,
    name: 'Partime',
    action: '',
  },
];

const AssignedSalaryItems = () => {
  const [sizePage, setSizePage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const overtimeData = useAppSelector(selectOvertimeData);
  const loading = overtimeData?.loading;
  const dateFormat = useAppSelector(selectFormatDate);
  const [salaryList, setSalaryList] = useState(MOCK_SALARY_LIST);
  const { t } = useTranslation(['menu', 'action', 'overtime', 'timesheet', 'employee', 'leaveOff']);

  const menu = [
    {
      name: t('action:delete'),
      type: 'delete',
      icon: <DeleteTwoTone twoToneColor="#B7B7B7" />,
      handleClick: (value: any) => {
        let copyArr = JSON.parse(JSON.stringify(salaryList));
        copyArr.splice(
          copyArr.findIndex((item: any) => item?.id === value?.id),
          1,
        );
        setSalaryList(copyArr);
      },
    },
  ];

  let COLUMNS = [
    {
      title: t('employee:salary_information_fields.col_id'),
      dataIndex: 'id',
      key: 'id',
      width: 50,
      minWidth: 50,
      align: 'center',
    },
    // {
    //   title: t('employee:salary_information_fields.col_position'),
    //   dataIndex: 'position',
    //   key: 'position',
    //   width: 150,
    //   minWidth: 150,
    //   ellipsis: true,
    //   render: function (text: any, record: any, index: any) {
    //     return (
    //       <Select
    //         disabled={false}
    //         defaultValue={MOCK_POSITION_CONFIG[0]}
    //         style={{ width: '100%' }}
    //         options={dataConfiguration.dataPosition ? dataConfiguration.dataPosition : []}
    //       ></Select>
    //     );
    //   },
    // },
    {
      title: t('employee:salary_information_fields.col_type'),
      dataIndex: 'type',
      key: 'type',
      width: 130,
      minWidth: 130,
      render: function (text: any, record: any, index: any) {
        return (
          <Select disabled={false} defaultValue={MOCK_TYPE_CONFIG[0]} style={{ width: '100%' }}>
            {MOCK_TYPE_CONFIG.map((item: any) => (
              <Option value={item?.name}>{item?.name}</Option>
            ))}
          </Select>
        );
      },
    },
    {
      title: t('employee:salary_information_fields.col_gross_salary'),
      dataIndex: 'grossSalary',
      key: 'grossSalary',
      width: 200,
      minWidth: 200,
      render: function (text: any, record: any, index: any) {
        return (
          <CustomInputNumber
            style={{ width: '100%' }}
            defaultValue={text}
            formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
            addonAfter="VNĐ"
          />
        );
      },
    },
    {
      title: t('employee:salary_information_fields.col_main_salary'),
      dataIndex: 'mainSalary',
      key: 'mainSalary',
      width: 200,
      minWidth: 200,
      render: function (text: any, record: any, index: any) {
        return (
          <CustomInputNumber
            style={{ width: '100%' }}
            defaultValue={text}
            formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
            addonAfter="VNĐ"
          />
        );
      },
    },
    {
      title: t('employee:salary_information_fields.col_start_date'),
      dataIndex: 'startDate',
      key: 'startDate',
      width: 150,
      minWidth: 150,
      render: function (text: any, record: any, index: any) {
        return (
          <DatePicker
            onChange={(val) => {
              console.log(val);
            }}
            format={dateFormat}
          />
        );
      },
    },
    {
      title: t('employee:salary_information_fields.col_end_date'),
      dataIndex: 'endDate',
      key: 'endDate',
      width: 150,
      minWidth: 150,
      render: function (text: any, record: any, index: any) {
        return (
          <DatePicker
            onChange={(val) => {
              console.log(val);
            }}
            format={dateFormat}
          />
        );
      },
    },
    // {
    //   title: t('employee:salary_information_fields.col_full_salary'),
    //   dataIndex: 'fullSalary',
    //   key: 'fullSalary',
    //   width: 120,
    //   minWidth: 120,
    //   align: 'center',
    //   render: function (text: any, record: any, index: any) {
    //     return <Checkbox></Checkbox>;
    //   },
    // },
    {
      title: t('employee:salary_information_fields.col_rate'),
      dataIndex: 'rate',
      key: 'rate',
      width: 120,
      minWidth: 120,
      align: 'center',
      render: function (text: any, record: any, index: any) {
        return (
          <CustomInputNumber
            defaultValue={100}
            min={0}
            max={100}
            formatter={(value: any) => `${value}%`}
            parser={(value: any) => value.replace('%', '')}
          />
        );
      },
    },
    {
      title: t('employee:employee_information_fields.action'),
      dataIndex: 'action',
      key: 'action',
      width: 60,
      minWidth: 60,
      align: 'center',
      render: function (text: any, record: any, index: any) {
        return <MenuAction menu={menu} data={record} />;
      },
    },
  ];

  const onAddRow = () => {
    const newList = salaryList.concat({
      id: salaryList.length + 1,
      positions: 'Manager',
      type: 'Full Time',
      grossSalary: 0,
      mainSalary: 0,
      startDate: new Date(),
      endDate: new Date(),
      fullSalary: 0,
      rate: 100,
    });

    setSalaryList(newList);
  };

  return (
    <div style={{ padding: 10, display: 'grid' }}>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <CustomButton icon={<PlusOutlined />} onClick={onAddRow}>
          Add Row
        </CustomButton>
      </div>
      <div style={{ overflow: 'scroll' }}>
        <ITVTable
          loading={loading}
          columns={COLUMNS}
          data={salaryList}
          height={350}
          isRowSelect={false}
          setOffset={setCurrentPage}
          offset={currentPage}
          setLimit={setSizePage}
          limit={sizePage}
        />
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Popconfirm
            title={t('leaveOff:modal_item.are_you_sure_save')}
            onConfirm={() => {}}
            okText={t('Yes')}
            cancelText={t('No')}
            placement="topRight"
          >
            <CustomButton>Save</CustomButton>
          </Popconfirm>
        </div>
      </div>
    </div>
  );
};

export default AssignedSalaryItems;

export const RightAlignInputNumber = styled(InputNumber)`
  .ant-input-number-input {
    text-align: right;
  }
`;

import { Menu } from 'antd';
import { useEffect, useState } from 'react';

import { MoneyCollectOutlined, UserOutlined } from '@ant-design/icons';

import { useAppSelector } from 'app/hooks';
import { PERMISSION_PERSONNEL_RECORD_APPROVAL } from 'constants/permissions';
import { selectPermissions } from 'features/auth/authSlice';
import { useTranslation } from 'react-i18next';

import AvatarBig from 'components/AvatarBig';
import {
  EmployeeAvatar,
  StyledEmployeeAddModalAvatar,
} from 'features/employee/components/SideBarMenu/styled';
import EmployeeUpdateId from 'features/employee/pages/EmployeeUpdateId';

interface SideBarMenuProps {
  isShowFullMenu: boolean;
  isShowHistory?: boolean;
  employeeData: any;
  openKeys: string;
  employeeMenuKey: string;
  personalMenuKey: string;
  skillMenuKey: string;
  historyMenuKey: string;
  allowanceMenuKey: string;
  bonusMenuKey: string;
  salaryMenuKey: string;
  dependantsMenuKey: string;
  contractMenuKey: string;
  adjustmentMenuKey: string;
  advancesMenuKey: string;
  certificatesMenuKey: string;
  handleClickMenu: (keys: any) => void;
  onCloseModal: () => void;
  status?: any;
  showMenu?: any;
}

const SideBarMenu = (props: SideBarMenuProps) => {
  const {
    employeeData,
    openKeys,
    allowanceMenuKey,
    bonusMenuKey,
    dependantsMenuKey,
    advancesMenuKey,
    contractMenuKey,
    adjustmentMenuKey,
    handleClickMenu,
    onCloseModal,
    showMenu,
  } = props;
  const { t } = useTranslation(['employee']);
  const dataPermissions = useAppSelector(selectPermissions);

  const [isVisibleUpdateId, setIsVisibleUpdateId] = useState(false);
  const [, setIsAllowEditEmployeeId] = useState(false);

  useEffect(() => {
    if (dataPermissions && dataPermissions.includes(PERMISSION_PERSONNEL_RECORD_APPROVAL)) {
      setIsAllowEditEmployeeId(true);
    }
  }, [dataPermissions]);
  let renderAvatar;
  if (employeeData) {
    renderAvatar = (
      <>
        <AvatarBig
          size={87}
          fontSize={'28px'}
          firstName={employeeData.firstName}
          lastName={employeeData.lastName}
          fullName={employeeData.fullName}
        />
        <p>
          {t('employee:employee_manage.employee_id')} : {employeeData.employeeId}
        </p>
      </>
    );
  } else {
    renderAvatar = (
      <>
        <EmployeeAvatar size={74} icon={<UserOutlined />} />
        <h2>{t('employee:employee_manage.unknown')}</h2>
        <p>
          {t('employee:employee_manage.employee_id')} : {t('employee:employee_manage.unknown')}
        </p>
      </>
    );
  }

  return (
    <>
      <StyledEmployeeAddModalAvatar>{renderAvatar}</StyledEmployeeAddModalAvatar>
      <Menu mode="inline" selectedKeys={[openKeys]} style={{ border: 'none' }}>
        {(!showMenu || showMenu === 'allowance') && (
          <Menu.Item
            key={allowanceMenuKey}
            icon={<MoneyCollectOutlined />}
            onClick={handleClickMenu}
            style={{ border: 'none' }}
          >
            {t('employee:employee_manage.menu_allowance_info')}
          </Menu.Item>
        )}
        {/* {(!showMenu || showMenu === 'salary') && (
          <Menu.Item
            key={salaryMenuKey}
            icon={<MoneyCollectOutlined />}
            onClick={handleClickMenu}
            style={{ border: 'none' }}
          >
            {t('employee:employee_manage.menu_salary_info')}
          </Menu.Item>
        )} */}
        {(!showMenu || showMenu === 'bonus') && (
          <Menu.Item
            key={bonusMenuKey}
            icon={<MoneyCollectOutlined />}
            onClick={handleClickMenu}
            style={{ border: 'none' }}
          >
            {t('employee:employee_manage.menu_bonus_info')}
          </Menu.Item>
        )}

        {(!showMenu || showMenu === 'dependent') && (
          <Menu.Item
            key={dependantsMenuKey}
            icon={<MoneyCollectOutlined />}
            onClick={handleClickMenu}
            style={{ border: 'none' }}
          >
            {t('employee:employee_manage.menu_dependants_info')}
          </Menu.Item>
        )}
        {(!showMenu || showMenu === 'advances') && (
          <Menu.Item
            key={advancesMenuKey}
            icon={<MoneyCollectOutlined />}
            onClick={handleClickMenu}
            style={{ border: 'none' }}
          >
            {t('employee:advance_information_fields.advance')}
          </Menu.Item>
        )}
        {/* {isShowFullMenu && isShowHistory && (
          <Menu.Item
            key={historyMenuKey}
            icon={<HistoryOutlined />}
            onClick={handleClickMenu}
            style={{ border: 'none' }}
          >
            {t('employee:employee_manage.record_history')}
          </Menu.Item>
        )} */}
        {(!showMenu || showMenu === 'contract') && (
          <Menu.Item
            key={contractMenuKey}
            icon={<MoneyCollectOutlined />}
            onClick={handleClickMenu}
            style={{ border: 'none' }}
          >
            {t('employee:contract_information_fields.contract')}
          </Menu.Item>
        )}
        {(!showMenu || showMenu === 'adjustment') && (
          <Menu.Item
            key={adjustmentMenuKey}
            icon={<MoneyCollectOutlined />}
            onClick={handleClickMenu}
            style={{ border: 'none' }}
          >
            {t('employee:adjustment_information_fields.adjustment')}
          </Menu.Item>
        )}
      </Menu>
      <EmployeeUpdateId
        recordId={employeeData?.id}
        employeeIdOld={employeeData?.employeeId}
        isVisible={isVisibleUpdateId}
        onClose={() => {
          setIsVisibleUpdateId(false);
          onCloseModal();
        }}
      />
    </>
  );
};

export default SideBarMenu;

import { memo } from 'react';
import { Spin } from 'antd';
import styled from 'styled-components';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import { TimesheetAdjustmentReport } from 'types';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const options = {
  plugins: {
    legend: {
      display: false,
      position: 'bottom' as const,
    },
    datalabels: {
      display: true,
      color: 'black',
      anchor: 'end' as const,
      align: 'top' as const,
      offset: 4,
      formatter: (value: any) => {
        return value;
      },
    },
  },
  layout: {
    padding: {
      top: 30,
    },
  },
  maintainAspectRatio: false,
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      stacked: true,
    },
  },
};

interface HeadcountLineChartProps {
  loading: boolean;
  dataChart: TimesheetAdjustmentReport;
}

const StyledWrapperChart = styled.div`
  width: 100%;
  height: 300px;
`;

function HeadcountWorkingTimeChart({ loading, dataChart }: HeadcountLineChartProps) {
  return (
    <Spin spinning={loading}>
      <StyledWrapperChart>
        <Bar options={options} data={dataChart} />
      </StyledWrapperChart>
    </Spin>
  );
}

export default memo(HeadcountWorkingTimeChart);

import { useAppDispatch, useAppSelector } from 'app/hooks';
import BtnBack from 'assets/icons/btn_back.png';
import SuccessIcon from 'assets/icons/ic_success_green.svg';
import ErrorIcon from 'assets/icons/ic_times_orange.svg';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import qs from 'query-string';

import employeeContractApi from 'api/employeeContractApi';
import insurancesApi from 'api/insurancesApi';
import watchingListApi from 'api/watchingListApi';
import { PERMISSION_WATCHINGLIST_MANAGE } from 'constants/permissions';
import {
  getRequestPending,
  handleRequestTimesheet,
  selectCurrentMonth,
} from 'features/timesheet/pages/timesheetSlice';
import { PermissionType } from 'hrm-common/extensions/enums/personel';
import { getUser } from 'utils/auth';
import { transMomentTimezone } from 'utils/date';
import { isMobileOnly } from 'react-device-detect';
import { path } from 'constants/path';
import recruitApi from 'api/recruitApi';
import { Modal, notification } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useTranslation } from 'react-i18next';
import { notificationToast } from 'components/notificationToast';

const PageRequestHandleTimesheet = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const id = getUser()?.employeeId;
  const [isSuccess, setIsSuccess] = useState<any>(undefined);
  const [text, setText] = useState('');
  const [objText, setObjText] = useState(null);
  const currentMonth: string = useAppSelector(selectCurrentMonth);
  const timeFromParent = moment(moment(currentMonth)).format('YYYY-MM');
  const time = timeFromParent
    ? transMomentTimezone(timeFromParent)
    : localStorage.getItem('current_date_choose')
    ? transMomentTimezone(localStorage.getItem('current_date_choose') || undefined)
    : transMomentTimezone();
  const { t } = useTranslation(['timesheet', 'action']);

  const urlSearchString = window.location.search;
  const params = new URLSearchParams(urlSearchString);
  const type = params.get('type');
  const token = params.get('token');
  const status = params.get('status');
  const monthYear = params.get('monthYear');

  useEffect(() => {
    if (!token) return;

    switch (type) {
      case 'otp':
        const fetchEmployeeContractListAPI = async () => {
          try {
            const response = await employeeContractApi.approveOTPContract({
              token: token,
            });
            setIsSuccess(true);
            setText(response.message);

            setTimeout(() => {
              isMobileOnly ? history.replace('/') : history.push('/contract/request-contract');
            }, 2000);
          } catch (error: any) {
            setIsSuccess(false);
            setText(error.message);
          }
        };
        fetchEmployeeContractListAPI();
        break;
      case 'contract':
        if (getUser()?.permissions.includes(PermissionType.APPROVE_CONTRACT)) {
          if (status === 'approve') {
            const fetchApproveContractAPI = async () => {
              try {
                const response = await employeeContractApi.approveRequestContract({
                  token: token,
                });
                setIsSuccess(true);
                setText(response.message);

                setTimeout(() => {
                  isMobileOnly ? history.replace('/') : history.push('/contract/request-contract');
                }, 2000);
              } catch (error: any) {
                setIsSuccess(false);
                setText(error.message);
              }
            };
            fetchApproveContractAPI();
          }
          if (status === 'reject') {
            localStorage.setItem('tokenByEmail', token);
            localStorage.setItem('openRejectModal', JSON.stringify(true));
            isMobileOnly ? history.replace('/') : history.push('/contract/request-contract');
          }
        } else {
          const fetchApproveContractAPI = async () => {
            try {
              const response = await employeeContractApi.approveRequestContract({
                token: token,
              });
              setIsSuccess(true);
              setText(response.message);

              setTimeout(() => {
                isMobileOnly ? history.replace('/') : history.push('/contract/request-contract');
              }, 2000);
            } catch (error: any) {
              setIsSuccess(false);
              setText(error.message);
            }
          };
          fetchApproveContractAPI();
        }

        break;
      case 'line-management':
        const fetchHandleRequestChangeLine = async () => {
          try {
            const response = await watchingListApi.approveRequestChangeLineByEmail({
              token: token,
            });
            setIsSuccess(true);
            setText(response.message);

            if (
              getUser()?.permissions.includes(
                PERMISSION_WATCHINGLIST_MANAGE.WATCHINGLIST_ADD_EMPLOYEE,
              ) &&
              getUser()?.permissions.includes(
                PERMISSION_WATCHINGLIST_MANAGE.WATCHINGLIST_REMOVE_EMPLOYEE,
              ) &&
              getUser()?.permissions.includes(
                PERMISSION_WATCHINGLIST_MANAGE.WATCHINGLIST_HR_REQUEST_LIST_VIEW,
              )
            ) {
              setTimeout(() => {
                isMobileOnly
                  ? history.replace('/')
                  : history.push('/manage-watching-list/hr-request-list');
              }, 2000);
            }

            if (
              getUser()?.permissions.includes(
                PERMISSION_WATCHINGLIST_MANAGE.WATCHINGLIST_ADD_EMPLOYEE,
              ) &&
              getUser()?.permissions.includes(
                PERMISSION_WATCHINGLIST_MANAGE.WATCHINGLIST_REMOVE_EMPLOYEE,
              ) &&
              getUser()?.permissions.includes(
                PERMISSION_WATCHINGLIST_MANAGE.WATCHINGLIST_MANAGER_REQUEST_LIST_VIEW,
              )
            ) {
              setTimeout(() => {
                isMobileOnly
                  ? history.replace('/')
                  : history.push('/manage-watching-list/manager-request-list');
              }, 2000);
            }
          } catch (error: any) {
            setIsSuccess(false);
            setText(error.message);
          }
        };
        fetchHandleRequestChangeLine();
        break;
      case 'insurance':
        if (
          getUser()?.permissions.includes(PermissionType.INSURANCE_APPROVAL_APPROVE_REJECT_REQUEST)
        ) {
          if (status === 'approve') {
            const handleRequestInsurance = async () => {
              try {
                const response = await insurancesApi.approveRequestInsuranceByEmail({
                  token: token,
                  approvedBy: getUser()?.fullName,
                });
                setIsSuccess(true);
                setText(response.message);

                setTimeout(() => {
                  isMobileOnly
                    ? history.replace('/')
                    : history.push({
                        pathname: path.compulsoryInsurance,
                        search: qs.stringify({
                          tab: 'waiting-for-approved',
                        }),
                      });
                }, 2000);
              } catch (error: any) {
                setIsSuccess(false);
                setText(error.message);
              }
            };
            handleRequestInsurance();
          }
          if (status === 'reject') {
            localStorage.setItem('tokenByEmail', token);
            localStorage.setItem('openRejectModal', JSON.stringify(true));
            isMobileOnly
              ? history.replace('/')
              : history.push({
                  pathname: path.compulsoryInsurance,
                  search: qs.stringify({
                    tab: 'waiting-for-approved',
                  }),
                });
          }
        } else {
          const handleRequestInsurance = async () => {
            try {
              const response = await insurancesApi.approveRequestInsuranceByEmail({
                token: token,
                approvedBy: getUser()?.fullName,
              });
              setIsSuccess(true);
              setText(response.message);

              setTimeout(() => {
                isMobileOnly
                  ? history.replace('/')
                  : history.push({
                      pathname: path.compulsoryInsurance,
                      search: qs.stringify({
                        tab: 'waiting-for-approved',
                      }),
                    });
              }, 2000);
            } catch (error: any) {
              setIsSuccess(false);
              setText(error.message);
            }
          };
          handleRequestInsurance();
        }
        break;
      case 'confirm-future':
        if (getUser()?.permissions.includes(PermissionType.RECORD_INSURANCE_PAYMENT_APPROVE)) {
          if (status === 'approve') {
            const handleRequestInsuranceRecordPay = async () => {
              try {
                const response = await insurancesApi.approveRequestInsuranceConfirmRecordPayByEmail(
                  {
                    token: token,
                  },
                );
                setIsSuccess(true);
                setText(response.message);
                localStorage.setItem('monthYearRecordPay', monthYear ? monthYear : '');
                setTimeout(() => {
                  isMobileOnly
                    ? history.replace('/')
                    : history.push({
                        pathname: path.compulsoryInsurance,
                        search: qs.stringify({
                          tab: 'recordpayment',
                        }),
                      });
                }, 2000);
              } catch (error: any) {
                setIsSuccess(false);
                setText(error.message);
              }
            };
            handleRequestInsuranceRecordPay();
          }
          if (status === 'reject') {
            localStorage.setItem('tokenByEmail', token);
            localStorage.setItem('openRejectModal', JSON.stringify(true));
            localStorage.setItem('monthYearRecordPay', monthYear ? monthYear : '');
            isMobileOnly
              ? history.replace('/')
              : history.push({
                  pathname: path.compulsoryInsurance,
                  search: qs.stringify({
                    tab: 'recordpayment',
                  }),
                });
          }
        } else {
          const handleRequestInsuranceRecordPay = async () => {
            try {
              const response = await insurancesApi.approveRequestInsuranceConfirmRecordPayByEmail({
                token: token,
              });
              setIsSuccess(true);
              setText(response.message);
              localStorage.setItem('monthYearRecordPay', monthYear ? monthYear : '');
              setTimeout(() => {
                isMobileOnly
                  ? history.replace('/')
                  : history.push({
                      pathname: path.compulsoryInsurance,
                      search: qs.stringify({
                        tab: 'recordpayment',
                      }),
                    });
              }, 2000);
            } catch (error: any) {
              setIsSuccess(false);
              setText(error.message);
            }
          };
          handleRequestInsuranceRecordPay();
        }
        break;
      // case 'cost-management':
      //   if (getUser()?.permissions.includes(PermissionType.APPROVE_CONTRACT)) {
      //     if (status === 'approve') {
      //       const fetchApproveContractAPI = async () => {
      //         try {
      //           const response = await employeeContractApi.approveRequestContract({
      //             token: token,
      //           });
      //           setIsSuccess(true);
      //           setText(response.message);

      //           setTimeout(() => {
      //             isMobileOnly ? history.replace('/') : history.push('/cost-management');
      //           }, 2000);
      //         } catch (error: any) {
      //           setIsSuccess(false);
      //           setText(error.message);
      //         }
      //       };
      //       fetchApproveContractAPI();
      //     }
      //     if (status === 'reject') {
      //       localStorage.setItem('tokenByEmail', token);
      //       localStorage.setItem('openRejectModal', JSON.stringify(true));
      //       isMobileOnly ? history.replace('/') : history.push('/cost-management');
      //     }
      //   } else {
      //     const fetchApproveContractAPI = async () => {
      //       try {
      //         const response = await employeeContractApi.approveRequestContract({
      //           token: token,
      //         });
      //         setIsSuccess(true);
      //         setText(response.message);

      //         setTimeout(() => {
      //           isMobileOnly ? history.replace('/') : history.push('/cost-management');
      //         }, 2000);
      //       } catch (error: any) {
      //         setIsSuccess(false);
      //         setText(error.message);
      //       }
      //     };
      //     fetchApproveContractAPI();
      //   }

      case 'recruit':
        if (status === 'approved' || status === 'rejected') {
          const handleRequestApi = async () => {
            try {
              const response = await recruitApi.recruitAction({ token });
              setIsSuccess(true);
              setObjText(response?.data);

              // setTimeout(() => {
              //   isMobileOnly
              //     ? history.replace('/')
              //     : history.push({
              //         pathname: path.compulsoryInsurance,
              //         search: qs.stringify({
              //           tab: 'waiting-for-approved',
              //         }),
              //       });
              // }, 2000);
            } catch (error: any) {
              setIsSuccess(false);
              setText(error.message);
            } finally {
              notification.destroy();
            }
          };
          handleRequestApi();
        }
        break;
      case 'recruit-job':
        if (status === 'Approved' || status === 'Rejected') {
          const handleRequestApi = async () => {
            try {
              let reason = '';
              // open modal and enter reason before continue call api bellow
              Modal.confirm({
                title: t('timesheet:Reason'),
                content: (
                  <TextArea
                    name="reason"
                    placeholder={t('timesheet:Reason')}
                    onChange={(e) => (reason = e.target.value)}
                  />
                ),
                okText: t('action:ok'),
                cancelText: t('action:cancel'),
                onOk: async () => {
                  const response = await recruitApi.recruitJobAction({ token, reason });
                  setIsSuccess(true);
                  history.push('/recruitment/jobs');
                  notificationToast('success', response?.data?.message, 5);
                  // setObjText(response?.data);
                },
              });
            } catch (error: any) {
              setIsSuccess(false);
              setText(error.message);
            } finally {
              notification.destroy();
            }
          };
          handleRequestApi();
        }
        break;
      case 'recruit-offer':
        if (status === 'Approved' || status === 'Rejected') {
          const handleRequestApi = async () => {
            try {
              let reason = '';
              // open modal and enter reason before continue call api bellow
              Modal.confirm({
                title: t('timesheet:Reason'),
                content: (
                  <TextArea
                    name="reason"
                    placeholder={t('timesheet:Reason')}
                    onChange={(e) => (reason = e.target.value)}
                  />
                ),
                okText: t('action:ok'),
                cancelText: t('action:cancel'),
                onOk: async () => {
                  const response = await recruitApi.recruitOfferAction({ token, reason });
                  setIsSuccess(true);
                  history.push('/recruitment/candidates');
                  notificationToast('success', response?.data?.message, 5);
                  // setObjText(response?.data);
                },
              });
            } catch (error: any) {
              setIsSuccess(false);
              setText(error.message);
            } finally {
              notification.destroy();
            }
          };
          handleRequestApi();
        }
        break;
      case 'recruit-candidate':
        if (status === 'Approved' || status === 'Rejected') {
          const handleRequestApi = async () => {
            try {
              if (status === 'Approved') {
                const response = await recruitApi.recruitProgressAction({ token });
                setIsSuccess(true);
                setObjText(response?.data);
              } else {
                let reason = '';
                Modal.confirm({
                  title: t('timesheet:Reason'),
                  content: (
                    <TextArea
                      name="reason"
                      placeholder={t('timesheet:Reason')}
                      onChange={(e) => (reason = e.target.value)}
                    />
                  ),
                  okText: t('action:ok'),
                  cancelText: t('action:cancel'),
                  onOk: async () => {
                    const response = await recruitApi.recruitProgressAction({ token, reason });
                    setIsSuccess(true);
                    setObjText(response?.data);
                  },
                });
              }
            } catch (error: any) {
              setIsSuccess(false);
              setText(error.message);
            } finally {
              notification.destroy();
            }
          };
          handleRequestApi();
        }
        break;
      case 'recruit-interviewer':
        if (status === 'Approved' || status === 'Rejected') {
          const handleRequestApi = async () => {
            try {
              const response = await recruitApi.recruitAttendInterviewAction({ token });
              setIsSuccess(true);
              notificationToast('success', response?.data?.message, 5);
              history.push('/recruitment/candidates');
            } catch (error: any) {
              setIsSuccess(false);
              setText(error.message);
            }
          };
          handleRequestApi();
        }
        break;
      default:
        dispatch(
          handleRequestTimesheet({
            token: token,
            callBack: (response: any) => {
              if (response.error) {
                setIsSuccess(false);
                setText(response.error.message);
              } else {
                setIsSuccess(true);
                setText(response.message);
                dispatch(
                  getRequestPending({
                    managerId: id,
                    monthYear: moment(time).format('MM/YYYY'),
                    typetime: ['overtime', 'leave', 'adjust'],
                    limit: 100,
                    offset: 1,
                    statusRequest: ['pending'],
                    searchName: '',
                    callBack: (res: any) => {
                      if (!res.error) {
                        let list = res.result.map(function (obj: any) {
                          obj['user_id'] = obj['id'];
                          delete obj['id'];
                          obj['id'] = obj['request_id'];
                          delete obj['request_id'];
                          return obj;
                        });
                        const listAdjust = list.filter(
                          (item: any) => item.typetime === 'adjust',
                        ).length;
                        const listLeave = list.filter(
                          (item: any) => item.typetime === 'leave',
                        ).length;
                        const listOT = list.filter(
                          (item: any) => item.typetime === 'overtime',
                        ).length;
                        let defaultActiveKey = 1;
                        if (response.data === 'adjust') {
                          if (listAdjust > 0) {
                            defaultActiveKey = 1;
                          } else {
                            if (listLeave > 0) {
                              defaultActiveKey = 2;
                            } else if (listOT > 0) {
                              defaultActiveKey = 3;
                            } else {
                              defaultActiveKey = 1;
                            }
                          }
                        }
                        if (response.data === 'leave') {
                          if (listLeave > 0) {
                            defaultActiveKey = 2;
                          } else {
                            if (listAdjust > 0) {
                              defaultActiveKey = 1;
                            } else if (listOT > 0) {
                              defaultActiveKey = 3;
                            } else {
                              defaultActiveKey = 1;
                            }
                          }
                        }
                        if (response.data === 'overtime') {
                          if (listOT > 0) {
                            defaultActiveKey = 3;
                          } else {
                            if (listAdjust > 0) {
                              defaultActiveKey = 1;
                            } else if (listLeave > 0) {
                              defaultActiveKey = 2;
                            } else {
                              defaultActiveKey = 1;
                            }
                          }
                        }
                        setTimeout(() => {
                          isMobileOnly
                            ? history.replace('/')
                            : history.push('/manage/request_pending', {
                                defaultActiveKey: defaultActiveKey.toString(),
                              });
                        }, 2000);
                      }
                    },
                  }),
                );
              }
            },
          }),
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, token]);

  return (
    <>
      <Container>
        <div className="container">
          {isSuccess === true && (
            <div className="container_img">
              {objText && status === 'rejected' ? null : (
                <img src={SuccessIcon} alt="Success icon" width={100} height={100} />
              )}
              {objText ? (
                <div>
                  <div
                    className="title-obj-text"
                    style={{
                      color: status === 'rejected' ? 'var(--cl_error500)' : 'var(--cl_success500)',
                      marginTop: status === 'rejected' ? 100 : 0,
                    }}
                  >
                    {objText?.title}
                  </div>
                  <div className="title-obj-body" style={{ textAlign: 'center' }}>
                    {objText?.message}
                  </div>
                </div>
              ) : (
                <div>{text}</div>
              )}
            </div>
          )}
          {isSuccess === false && (
            <div className="container_img">
              <img src={ErrorIcon} alt="Error icon" width={100} height={100} />
              <div>{text}</div>
              <img
                src={BtnBack}
                alt="button back"
                width={150}
                style={{ cursor: 'pointer' }}
                onClick={() => history.push('/dashboard')}
              />
            </div>
          )}
        </div>
      </Container>
    </>
  );
};

export default PageRequestHandleTimesheet;

export const Container = styled.div`
  padding: 40px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--cl_text_primary);
  font-family: Mulish;

  .container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .container_img {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .container_img div {
    margin-bottom: 50px;
  }
  .title-obj-text {
    font-weight: bold;
    text-align: center;
    font-size: larger;
  }
  .title-obj-body {
    width: 600px;
    text-align: justify;
  }
`;

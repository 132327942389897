import { EditOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, Input, Modal, Popconfirm } from 'antd';
import { useAppSelector } from 'app/hooks';
import ITVSearch from 'components/ITVSearch';
import ITVTable from 'components/ITVTable';
import MenuAction from 'components/MenuAction';
import { Device } from 'features/masterData/General/Interfaces/CameraAI';
import { editDevice, getDevices } from 'features/masterData/masterDataAction';
import { selectMasterData } from 'features/masterData/masterDataSlice';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import CustomButton from 'styles/buttonStyled';
import { TitleColumnCenter } from 'styles/tableStyled';
import { removeAccents } from 'utils/text';

const ListOfDevices = (props: any) => {
  const { placeId } = props;
  const [form] = Form.useForm();
  const { t } = useTranslation(['masterConfig', 'employee', 'leaveOff', 'action']);
  const [, setResultTotal] = useState(0);
  const [, setSearching] = useState(false);
  const [textSearch, setTextSearch] = useState('');
  const masterData = useAppSelector(selectMasterData);
  const dataDevices: Device[] = masterData.dataDevices;
  const [dataOri, setDataOri] = useState<Device[]>([]);
  const loading: boolean = masterData.loading;
  const [sizePage, setSizePage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentDevice, setCurrentDevice] = useState<any>();
  const [, setShowPopconfirm] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDevices(placeId));
  }, [placeId, dispatch]);

  useEffect(() => {
    if (dataDevices) {
      setDataOri(dataDevices);
    }
    if (textSearch) {
      handleSearchIdName(textSearch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataDevices]);

  const menu = [
    {
      name: t('action:edit'),
      width: 100,
      minWidth: 100,
      icon: <EditOutlined style={{ color: 'red' }} />,
      handleClick: (data: any) => {
        setCurrentDevice({ ...data, placeId });
        form.setFieldsValue({
          deviceId: data?.deviceID,
          name: data?.deviceName,
          placeId: placeId,
        });
      },
    },
  ];

  const handleSearchIdName = (keySearch: string) => {
    const temp = dataDevices.filter((item: any) => {
      return (
        item?.deviceName?.toString().toLowerCase().includes(keySearch) ||
        removeAccents((item?.deviceName).replace(/ /g, ''))
          .toLowerCase()
          .includes(removeAccents(keySearch).replace(/ /g, '').toLowerCase())
      );
    });

    setResultTotal(temp.length);
    setDataOri(temp);
  };

  let COLUMNS = [
    {
      title: () => {
        return <TitleColumnCenter>{t('masterConfig:manage_place.camera_id')}</TitleColumnCenter>;
      },
      dataIndex: 'deviceID',
      key: 'deviceID',
      ellipsis: true,
      width: 80,
      minWidth: 80,
      align: 'center',
      render: function (text: any, record: any, index: any) {
        return <div>{`${record?.deviceID}`}</div>;
      },
    },
    {
      title: () => {
        return <TitleColumnCenter>{t('masterConfig:manage_place.device_name')}</TitleColumnCenter>;
      },
      dataIndex: 'deviceName',
      key: 'deviceName',
      ellipsis: true,
      width: 80,
      minWidth: 80,
      align: 'center',
      render: function (text: any, record: any, index: any) {
        return <div>{`${record?.deviceName}`}</div>;
      },
    },
    {
      title: () => {
        return <TitleColumnCenter>{t('masterConfig:general.status')}</TitleColumnCenter>;
      },
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      width: 80,
      minWidth: 80,
      align: 'center',
      render: function (text: any, record: any, index: any) {
        return (
          <div>{`${
            !record?.status ? t('employee:employee_manage.deactive') : t('leaveOff:history.active')
          }`}</div>
        );
      },
    },
    {
      title: () => {
        return (
          <TitleColumnCenter>{t('employee:employee_information_fields.action')}</TitleColumnCenter>
        );
      },
      dataIndex: 'action',
      key: 'action',
      width: 80,
      minWidth: 80,
      align: 'center',
      ellipsis: true,
      render: (text: string, row: any) => {
        return <MenuAction menu={menu} data={row} />;
      },
    },
  ];

  const onSearchList = (key: string) => {
    setSearching(true);
    const text = key.toLowerCase().trim();
    if (text) {
      setTextSearch(text);
      handleSearchIdName(text);
    } else {
      setTextSearch(text);
      setDataOri(dataDevices);
      setResultTotal(dataOri?.length);
    }
    setSearching(false);
  };

  const onFinish = (values: any) => {
    dispatch(
      editDevice({
        ...values,
        callBack: () => {
          setShowPopconfirm(false);
          setCurrentDevice(null);
          dispatch(getDevices(placeId));
        },
      }),
    );
  };
  return (
    <>
      <Modal
        visible={currentDevice ? true : false}
        onCancel={() => setCurrentDevice(null)}
        title={`${t('action:edit')} ${t('masterConfig:manage_place.device')}`}
        footer={[
          <InfoButtonCancel
            onClick={() => {
              setCurrentDevice(null);
            }}
          >
            {t('employee:employee_manage.cancel')}
          </InfoButtonCancel>,
          <Popconfirm
            title={t('leaveOff:preview.are_you_sure')}
            onConfirm={() => {
              onFinish(form.getFieldsValue());
            }}
            onCancel={() => {
              setShowPopconfirm(false);
            }}
            okText={t('masterConfig:action.yes')}
            cancelText={t('masterConfig:action.no')}
          >
            <CustomButton>{t('employee:employee_relative.btn_save')}</CustomButton>
          </Popconfirm>,
        ]}
      >
        <Form id="editDevice" form={form} name="control-hooks" onFinish={onFinish}>
          {
            <>
              <Titles>
                Id {t('masterConfig:manage_place.place')}
                <span style={{ color: 'red', marginLeft: '5px' }}></span>
              </Titles>
              <Form.Item name="placeId">
                <Input disabled />
              </Form.Item>
            </>
          }
          <Titles>
            Id {t('masterConfig:manage_place.device')}
            <span style={{ color: 'red', marginLeft: '5px' }}></span>
          </Titles>
          <Form.Item name="deviceId">
            <Input disabled />
          </Form.Item>
          <Titles>{t('masterConfig:manage_place.device_name')}</Titles>
          <Form.Item name="name">
            <Input placeholder="Please input name" />
          </Form.Item>
        </Form>
      </Modal>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <SearchBar handleSearch={(value: any) => onSearchList(value)} />
      </div>
      <ITVTable
        columns={COLUMNS}
        data={dataOri}
        isScroll={true}
        loading={loading}
        isRowSelect={false}
        setOffset={setCurrentPage}
        offset={currentPage}
        setLimit={setSizePage}
        limit={sizePage}
      />
    </>
  );
};

export default ListOfDevices;

export const DatePickerCustom = styled(DatePicker)`
  width: 100%;
`;

export const DatePickerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const FormContainer = styled.div`
  width: 80%;
  margin: 20px;
`;

export const FormContent = styled.div`
  display: flex;
  align-content: space-between;
  flex-direction: column;
  padding: 20px 30px 10px 30px;
  background-color: white;
`;

export const CustomApprove = styled(Button)`
  background: #f1592a;
  border-radius: 4px;
  padding: 4px 12px;
  margin-left: 10px;
  height: 30px;
  color: #ffffff;
`;

export const SearchBar = styled(ITVSearch)`
  width: 40%;
  margin-bottom: 10px;
`;

export const Titles = styled.div`
  display: flex;
  height: 20px;
  font-size: 14px;
  font-family: Roboto;
`;

export const ItemBody = styled.div`
  margin-bottom: 6px;
`;

export const InfoButtonCancel = styled(Button)`
  border-radius: 4px;
  padding-left: 12px;
  padding-right: 12px;
  height: 30px;
`;

import { Spin } from 'antd';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { memo } from 'react';
import { Line } from 'react-chartjs-2';
import styled from 'styled-components';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { EmployeeGrowthReport } from 'types';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
);

const options = {
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      display: true,
      color: 'black',
      anchor: 'end' as const,
      align: 'top' as const,
      offset: 4,
      formatter: (value: any) => {
        return value;
      },
    },
  },
  layout: {
    padding: {
      top: 30,
    },
  },
  maintainAspectRatio: false,
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      grid: {
        display: false,
      },
      suggestedMin: 0,
      beginAtZero: true,
    },
  },
  borderWidth: 1.5,
  pointRadius: 4,
  pointBackgroundColor: '#a9a1e5',
};

interface EmployeeSummaryChartProps {
  loading: boolean;
  dataChart: EmployeeGrowthReport;
}

const StyledWrapperChart = styled.div`
  width: 100%;
  height: 290px;
`;

function EmployeeSummaryChart({ loading, dataChart }: EmployeeSummaryChartProps) {
  return (
    <Spin spinning={loading}>
      <StyledWrapperChart>
        <Line data={dataChart} options={options} />
      </StyledWrapperChart>
    </Spin>
  );
}

export default memo(EmployeeSummaryChart);

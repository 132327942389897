import { Modal, Select, Spin } from 'antd';
import styled from 'styled-components';
import { ArrowDownIcon, CloseIcon } from 'assets/icons';
import React, { useRef, useState } from 'react';
import { SignatureFilled } from '@ant-design/icons';
import SignatureCanvas from 'react-signature-canvas';
import CustomButton from 'styles/buttonStyled';
import { PDFDocument } from 'pdf-lib';
import signatureImg from './signature.png';
import employeeContractApi from 'api/employeeContractApi';
import { notificationToast } from 'components/notificationToast';
import { Notification } from 'constants/notification';
import { useTranslation } from 'react-i18next';
import { showError } from 'utils/function';

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 12px;
    .ant-modal-header {
      border-bottom: none;
      border-radius: 12px;
      padding: 16px 24px 8px;
    }
    .ant-modal-body {
      padding: 8px 24px 16px;
    }
  }
`;

interface ViewFilePDFModalProps {
  pdfURL: any;
  isOpen: boolean;
  onCancel?: () => void;
  fromScreen?: string;
  contractDetail?: any;
}

export function ViewFilePDFModal({
  pdfURL,
  isOpen,
  onCancel,
  fromScreen,
  contractDetail,
}: ViewFilePDFModalProps) {
  const [pdfUrl, setPdfUrl] = useState(pdfURL);
  const [blobData, setblobData] = useState();
  const { t } = useTranslation(['contractManagement']);
  const sigCanvas = useRef({});

  const [isShowSelectSignature, setIsShowSelectSignature] = useState(false);
  const [isLoadingShowSelectSignature, setIsLoadingShowSelectSignature] = useState(false);

  const handleClearDigitalSignature = () => {
    fromScreen === 'employee' && sigCanvas.current.clear();
    setPdfUrl(pdfURL);
  };

  const saveSignature = async () => {
    if (fromScreen === 'employee') {
      const signatureUrl = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
      addSignatureToPdf(signatureUrl);
    } else {
      const pngImageBytes = await fetch(signatureImg).then((res) => res.arrayBuffer());
      addSignatureToPdf(pngImageBytes);
    }
  };

  const addSignatureToPdf = async (signatureUrl) => {
    const existingPdfBytes = await fetch(pdfUrl).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    const pages = pdfDoc.getPages();
    const firstPage = pages[3];
    const { width, height } = firstPage.getSize();

    const pngImage = await pdfDoc.embedPng(signatureUrl);
    const pngDims = pngImage.scale(0.5);

    firstPage.drawImage(pngImage, {
      x: fromScreen === 'employee' ? width / 2 - 170 : width / 2 + 50,
      y: height / 2 - 210,
      width: fromScreen === 'employee' ? pngDims.width : pngDims.width / 10,
      height: fromScreen === 'employee' ? pngDims.height : pngDims.height / 10,
    });

    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    setblobData(blob);
    setPdfUrl(URL.createObjectURL(blob));
    console.log('URL.createObjectURL(blob)', URL.createObjectURL(blob));
  };

  const blobToBase64 = (blob) => {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  };

  const handleSubmit = async () => {
    const payload = new FormData();
    // const file = await fetch(blobData).then(r => r.blob()).then(blobFile => new File([blobFile], "asdasd34234.pdf", { type: blobFile.type }))
    payload.set('id', contractDetail.id);
    const file = await blobToBase64(blobData);
    payload.append('contractFile', file);
    payload.set('start_date', contractDetail.start_date);
    payload.set('end_date', contractDetail.end_date);
    payload.set('isNotify', true);

    await onSubmit?.(payload);
  };

  const onSubmit = async (payload) => {
    await employeeContractApi.update(payload);
    notificationToast(
      Notification.Type.Success,
      t('update_contract_success_message'),
      Notification.Duration._3s,
    );
  };

  const options = [{ value: '1', label: 'Simple signature' }];

  const handlePincodeOnchange = (event: any) => {
    if (event.target.value === '111111') {
      setIsLoadingShowSelectSignature(true);
      setTimeout(() => {
        setIsLoadingShowSelectSignature(false);
        setIsShowSelectSignature(true);
      }, 2000);
    } else {
      setIsShowSelectSignature(false);
    }

    if (event.target.value !== '111111' && event.target.value.length === 6) {
      setIsLoadingShowSelectSignature(true);
      setTimeout(() => {
        setIsLoadingShowSelectSignature(false);
        showError('Pin Code Invalid');
      }, 2000);
    }
  };

  return (
    <React.Fragment>
      <StyledModal
        title="View Document"
        open={isOpen}
        closeIcon={<CloseIcon />}
        width={1500}
        footer={null}
        maskClosable={false}
        onCancel={onCancel}
        style={{ top: '5%' }}
      >
        <div style={{ display: 'flex' }}>
          <embed src={pdfUrl} width="70%" height="600px" />
          <div style={{ marginLeft: '30px' }}>
            {fromScreen !== 'employee' && (
              <div>
                <h2>Enter Pin Code</h2>
                <input
                  type="password"
                  style={{
                    border: '1px solid',
                    borderRadius: 4,
                    height: 40,
                    width: '100%',
                    padding: 6,
                  }}
                  maxLength={6}
                  onChange={(event) => handlePincodeOnchange(event)}
                  disabled={isLoadingShowSelectSignature}
                />
              </div>
            )}
            {fromScreen === 'employee' && (
              <div
                style={{
                  width: '350px',
                  height: '370px',
                  border: '1px solid',
                  borderRadius: '6px',
                  marginTop: 20,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: 20,
                  }}
                >
                  <SignatureFilled
                    style={{
                      fontSize: 20,
                      color: '#2c6df9',
                      borderRadius: '50%',
                      backgroundColor: '#e3edff',
                      padding: 6,
                    }}
                  />
                  <h3 style={{ marginTop: 5, marginLeft: 10 }}>
                    {fromScreen === 'employee'
                      ? 'Your Digital Signature'
                      : 'Company Digital Signature'}{' '}
                  </h3>
                </div>
                <div style={{ textAlign: 'center', marginTop: 15 }}>
                  <SignatureCanvas
                    ref={sigCanvas}
                    penColor="black"
                    canvasProps={{ width: 300, height: 230, className: 'sigCanvas' }}
                  />
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                  <CustomButton
                    aType="text"
                    style={{ marginRight: 20 }}
                    onClick={handleClearDigitalSignature}
                  >
                    Clear
                  </CustomButton>
                  <CustomButton onClick={saveSignature}>
                    {fromScreen === 'employee' ? 'Confirm' : 'Sign'}
                  </CustomButton>
                </div>
              </div>
            )}
            {fromScreen !== 'employee' && isShowSelectSignature && (
              <div
                style={{
                  marginTop: 20,
                  border: '1px solid',
                  borderRadius: '6px',
                  padding: 15,
                }}
              >
                <h2>Select E-Signature format</h2>
                <StyledContainer>
                  <Select
                    suffixIcon={<ArrowDownIcon />}
                    onChange={(value, option) => {}}
                    value={'1'}
                  >
                    {options.map((option) => (
                      <Select.Option
                        key={option.value as string}
                        value={option.value}
                        label={option.label}
                      >
                        {option.label}
                      </Select.Option>
                    ))}
                  </Select>
                </StyledContainer>

                <img style={{ marginTop: 25 }} src={signatureImg} width={'100%'} height={100}></img>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                  <CustomButton
                    aType="text"
                    style={{ marginRight: 20 }}
                    onClick={handleClearDigitalSignature}
                  >
                    Clear
                  </CustomButton>
                  <CustomButton onClick={saveSignature}>
                    {fromScreen === 'employee' ? 'Confirm' : 'Sign'}
                  </CustomButton>
                </div>
              </div>
            )}
            {isLoadingShowSelectSignature && (
              <div style={{ textAlign: 'center' }}>
                <Spin style={{ marginTop: 20 }} />
              </div>
            )}

            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
              <CustomButton aType="text" style={{ marginRight: 20 }} onClick={onCancel}>
                Cancel
              </CustomButton>
              <CustomButton onClick={handleSubmit}>Submit</CustomButton>
            </div>
          </div>
        </div>
      </StyledModal>
      <style>
        {`
      .sigCanvas {
        border: 1px dashed #d6dce4;
      }
      `}
      </style>
    </React.Fragment>
  );
}

const StyledContainer = styled.div`
  .ant-select {
    width: 100%;
    .ant-select-selector {
      border-radius: 6px;
      border: 1px solid var(--cl_neutral300);
    }
  }
`;

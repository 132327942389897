import { ArrowRightOutlined } from '@ant-design/icons';
import { DatePicker, Divider, Form, Popconfirm, Select } from 'antd';
import { selectFormatDate } from 'app/commonRedux/appSlice';
import { useAppSelector } from 'app/hooks';
import { notificationToast } from 'components/notificationToast';
import { Notification } from 'constants/notification';
import {
  getFindOneAction,
  getFindOneActionAdd,
  getFindOneWorkflowType,
} from 'features/configuration/configurationAction';
import { actions, selectConfiguration } from 'features/configuration/configurationSlice';
import sortBy from 'lodash/sortBy';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import CustomButton from 'styles/buttonStyled';
import CustomSelect from 'styles/inputSelectStyled';
import CustomInput from 'styles/inputStyled';
import Workflow2Edit from './Workflow2Edit';

const { RangePicker } = DatePicker;

function getNodeByListId(nodes, listIdNode) {
  let ArrayNode = [];
  for (let i = 0; i <= nodes?.length; i++) {
    for (let j = 0; j < listIdNode?.length; j++) {
      if (nodes[i]?.id === listIdNode[j]) {
        ArrayNode.push(nodes[i]);
      }
    }
  }
  return ArrayNode;
}

function getNodeById(nodes, IdNode) {
  for (let i = 0; i <= nodes?.length; i++) {
    if (nodes[i]?.id === IdNode) {
      return nodes[i];
    }
  }
}

const ModalEditWorkflowType = (props: any) => {
  const uniqueIds: any = [];
  const { Option } = Select;
  const dataStore = useAppSelector(selectConfiguration);
  const dispatch = useDispatch();
  const {
    form,
    onSave,
    setModalType,
    modalType,
    currentItem,
    dataListBusinessWorkflowType,
    dataListStatus,
    dataListAction,
  } = props;

  const { t } = useTranslation([
    'validation',
    'overtime',
    'action',
    'onboard',
    'validation',
    'timesheet',
  ]);
  const dataFindOneWorkflowType = dataStore?.dataFindOneWorkflowType;
  const [edges, setEdges] = useState(dataFindOneWorkflowType?.workflowTypeDetail?.[0].edges);
  const [nodes, setNodes] = useState(dataFindOneWorkflowType?.workflowTypeDetail?.[0].nodes);
  const [selectValue, setSelectValue] = useState();
  const [selectAction, setSelectAction] = useState();
  const [selectStatus, setSelectStatus] = useState();
  const [selectActive, setSelectActive] = useState();

  const [idRow, setIdRow] = useState();
  const format = useAppSelector(selectFormatDate);
  const [group] = useState<any>(dataListBusinessWorkflowType);

  const [checkNodeEdgeEnd, setCheckNodeEdgeEnd] = useState([]); //View error ko co edge end
  const [checkNodeEdgeStart, setCheckNodeEdgeStart] = useState([]); //View error ko co edge end

  const [errMsg, setErrMsg] = useState([]); //View error ko co end
  const [errMsgProcessDontStart, setErrMsgProcessDontStart] = useState([]); //View error
  const [sourceTargetForErrMesOfAction, setSourceTargetForErrMesOfAction] = useState([]); //View error ko co action
  const [detailNodeAlone, setDetailNodeAlone] = useState([]); //View error node alone
  const [checkErr1Process, setCheckErr1Process] = useState(false); //View error process status employees
  const [findActionIdOfEdge, setFindActionIdOfEdge] = useState<any>();
  const [findStatusOfNode, setFindStatusOfNode] = useState<any>(); //status and employee
  const [findEmployeesOfNodeOpen, setFindEmployeesOfNodeOpen] = useState<any>();
  const stateConfiguration = useAppSelector(selectConfiguration);
  const error = stateConfiguration.error;
  const { setErrorMessage } = actions;

  useEffect(() => {
    if (error?.fieldErrors) {
      form.setFields([
        {
          name: 'action',
          errors: error?.fieldErrors?.workflowType,
        },
      ]);
    }
    return () => {
      dispatch(setErrorMessage(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    dispatch(getFindOneWorkflowType({ id: currentItem?.id }));
  }, [currentItem?.id, dispatch]);

  const handleSelectedValue = async (value: any) => {
    setSelectValue(value);
  };

  useEffect(() => {
    if (group?.length) {
      const selectIndex = group.length - 1;
      setSelectValue(group[selectIndex].businessGroup);
      form.setFieldsValue({
        businessGroup: group[selectIndex].businessGroup,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group]);

  useEffect(() => {
    setSelectAction(dataFindOneWorkflowType?.workflowTypeDetail?.[0].actionGroup?.id); //Khi edit thi dropdown dc
    setIdRow(dataFindOneWorkflowType?.id);
    setSelectStatus(dataFindOneWorkflowType?.workflowTypeDetail?.[0].statusGroup?.id);
    form.setFieldsValue({
      businessGroup: dataFindOneWorkflowType?.businessModule?.businessCode,
      workflowId: dataFindOneWorkflowType?.workflowTypeDetail?.[0].workflowTypeCode,
      workflowName: dataFindOneWorkflowType?.workflowTypeDetail?.[0].workflowTypeName,
      status: dataFindOneWorkflowType?.workflowTypeDetail?.[0]?.statusGroup?.id,
      action: dataFindOneWorkflowType?.workflowTypeDetail?.[0]?.actionGroup?.id,
      description: dataFindOneWorkflowType?.workflowTypeDetail?.[0].description,
      date: [
        moment(dataFindOneWorkflowType?.workflowTypeDetail?.[0]?.startDate),
        moment(dataFindOneWorkflowType?.workflowTypeDetail?.[0]?.endDate),
      ],
      active: dataFindOneWorkflowType?.workflowTypeDetail?.[0]?.status,
    });
    setEdges(dataFindOneWorkflowType?.workflowTypeDetail?.[0]?.edges);
    setNodes(dataFindOneWorkflowType?.workflowTypeDetail?.[0]?.nodes);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFindOneWorkflowType, modalType]);

  const onFinish = () => {
    let checkNodeEnd = edges?.find((item) => {
      return item?.target === '2';
    });
    setCheckNodeEdgeEnd(checkNodeEnd);
    //check node start
    let checkNodeStart = edges?.find((item) => {
      return item?.source === '1';
    });
    setCheckNodeEdgeStart(checkNodeStart);
    //check workflow

    let data = edges;
    sortBy(data, 'source');
    let sortedObjs = sortBy(data, 'source');
    let checkEdge = [];
    for (let i = 0; i <= sortedObjs.length - 1; i++) {
      for (let j = 0; j <= sortedObjs.length - 1; j++) {
        if (sortedObjs[i]?.target === sortedObjs[j]?.source) {
          checkEdge.push(sortedObjs[i]?.target);
        }
      }
    }
    let uniquecheckEdge = [...new Set(checkEdge?.map((item) => item))].sort(function (a, b) {
      return a - b;
    });
    let uniqueSource = [...new Set(sortedObjs?.map((item) => item?.source))];
    let uniqueSource2 = uniqueSource
      ?.filter((item) => {
        return item !== '0';
      })
      .sort(function (a, b) {
        return a - b;
      });
    let checkNode = isEqual(uniquecheckEdge, uniqueSource2);
    //Check element dont go target(NOT START)
    let checkNotGoTarget = uniqueSource2.filter((val) => !uniquecheckEdge.includes(val));
    setErrMsgProcessDontStart(getNodeByListId(nodes, checkNotGoTarget)); //Check node k start
    //Check element dont go source(NOT END)
    let removeEdgeEnd = edges
      ?.filter((item) => {
        return item?.target !== '2' && item?.target !== '1';
      })
      ?.map((item) => item.target);

    let checkNotGoSource = removeEdgeEnd.filter((val) => !uniquecheckEdge.includes(val));
    let uniqueCheckNotGoSource = [...new Set(checkNotGoSource)];
    setErrMsg(getNodeByListId(nodes, uniqueCheckNotGoSource)); //Check node k end
    //Check action id of edge
    let filterEdge = edges?.filter((item) => {
      return item?.id !== '0';
    });
    let actionIdOfEdge = filterEdge?.map((item) => {
      return item?.actionId;
    });
    //To color
    let FindActionIdOfEdge = filterEdge?.filter((item) => {
      return item?.actionId === undefined;
    });
    let getIdSourceTargetForErrMesOfAction = FindActionIdOfEdge?.map((item) => {
      return item;
    });
    let getDetailSourceTargetForErrMesOfAction = getIdSourceTargetForErrMesOfAction?.map((item) => {
      return {
        ...item,
        source: getNodeById(nodes, item?.source),
        target: getNodeById(nodes, item?.target),
      };
    });
    setSourceTargetForErrMesOfAction(getDetailSourceTargetForErrMesOfAction);
    setFindActionIdOfEdge(FindActionIdOfEdge);
    let checkActionIdOfEdge = actionIdOfEdge.includes(undefined);
    //Check status employees of nodes
    let filterNodes = nodes?.filter((item) => {
      return item?.id !== '0' && item?.id !== '1' && item?.id !== '2';
    });
    let statusOfNode = filterNodes?.map((item) => {
      return item?.data?.status;
    });
    let FindStatusOfNode = filterNodes?.filter((item) => {
      return item?.data?.status === undefined;
    });
    setFindStatusOfNode(FindStatusOfNode); //To color node
    let employeesOfNode = filterNodes?.map((item) => {
      return item?.data?.employees;
    });

    let checkStatusOfNode = statusOfNode.includes(undefined);
    let checkEmployeesOfNode = employeesOfNode.includes(undefined);
    //Check employees of Node Open
    let filterNodeOpen = nodes?.filter((item) => {
      return item?.id === '1';
    });
    let checkEmployeeOfNodeOpen = filterNodeOpen?.find((item) => {
      return item?.data?.employees;
    });
    let findEmployeeOfNodeOpen = filterNodeOpen?.filter((item) => {
      return item?.data?.employees === undefined;
    });

    setFindEmployeesOfNodeOpen(findEmployeeOfNodeOpen);
    //Check dung mot minh
    let uniqueTarget = [...new Set(sortedObjs?.map((item) => item?.target))];
    let collectIdSourceTargetOfEdge = [...new Set([...uniqueSource, ...uniqueTarget])];
    let collectIdNodes = [...new Set(nodes?.map((item) => item?.id))];
    let nodeAlone = collectIdNodes
      .filter((val) => !collectIdSourceTargetOfEdge.includes(val))
      ?.filter((item) => {
        return item !== '2';
      });
    setDetailNodeAlone(getNodeByListId(nodes, nodeAlone));
    //Check it nhat phai co 1 process duoc tao
    let checkError1Process = false;
    if (nodes.length <= 3) {
      checkError1Process = true;
    } else {
      checkError1Process = false;
    }
    setCheckErr1Process(checkError1Process);
    //Check status da complete het chua
    // let checkStatusComplete1 = JSON.parse(localStorage.getItem('dataPayroll'))?.filter(
    //   (item) =>
    //     item?.workflowId === dataFindOneWorkflowType?.workflowTypeDetail?.[0]?.workflowTypeCode,
    // );
    // let checkStatusComplete = checkStatusComplete1?.every((item) => item?.status === 'Close');

    // if (checkStatusComplete) {
    if (
      edges.length > 2 &&
      nodes.length > 3 &&
      checkNodeEnd !== undefined &&
      checkNode &&
      checkNotGoSource.length === 0
    ) {
      if (!checkActionIdOfEdge) {
        if (!checkStatusOfNode && !checkEmployeesOfNode && checkEmployeeOfNodeOpen !== undefined) {
          form.validateFields().then((values: any) => {
            if (typeof nodes[3]?.data?.status === 'object') {
              let nodeNew = nodes?.map((item) => {
                return {
                  ...item,
                  data: {
                    ...item.data,
                    status: item?.data?.status?.id,
                    employees: item?.data?.employees?.map((itemId) => {
                      return {
                        id: itemId?.id,
                        permissionCreate: itemId?.permissionCreate || false,
                        permissionEdit: itemId?.permissionEdit || false,
                        permissionDelete: itemId?.permissionDelete || false,
                      };
                    }),
                  },
                };
              });

              let edgeNew = edges?.map((item) => {
                return {
                  ...item,
                  actionId: item?.actionId?.id,
                };
              });
              onSave({
                id: idRow,
                businessModuleId:
                  Number(values.businessGroup) || dataFindOneWorkflowType?.businessModule?.id,
                createWorkflowTypeDetail: {
                  id: dataFindOneWorkflowType?.workflowTypeDetail?.[0]?.id,
                  workflowTypeCode: values.workflowId,
                  workflowTypeName: values.workflowName,
                  actionCodeGroupId:
                    selectAction ||
                    dataFindOneWorkflowType?.workflowTypeDetail?.[0]?.actionGroup?.id,
                  statusCodeGroupId:
                    selectStatus ||
                    dataFindOneWorkflowType?.workflowTypeDetail?.[0]?.statusGroup?.id,
                  description: values.description || '',
                  nodes: nodeNew,
                  edges: edgeNew,
                  status: values?.active,
                  startDate: moment(values?.date?.[0], format).utc(),
                  endDate: dataFindOneWorkflowType?.workflowTypeDetail?.[0]?.endDate,
                },
              });
              setModalType('');
              // localStorage.removeItem('dataPayroll');
            } else {
              let nodeNew = nodes?.map((item) => {
                return {
                  ...item,
                  data: {
                    ...item.data,
                    employees: item?.data?.employees?.map((itemId) => {
                      return {
                        id: itemId?.id,
                        permissionCreate: itemId?.permissionCreate || false,
                        permissionEdit: itemId?.permissionEdit || false,
                        permissionDelete: itemId?.permissionDelete || false,
                      };
                    }),
                  },
                };
              });

              onSave({
                id: idRow,
                businessModuleId:
                  Number(values.businessGroup) || dataFindOneWorkflowType?.businessModule?.id,
                createWorkflowTypeDetail: {
                  id: dataFindOneWorkflowType?.workflowTypeDetail?.[0]?.id,
                  workflowTypeCode: values.workflowId,
                  workflowTypeName: values.workflowName,
                  actionCodeGroupId:
                    selectAction ||
                    dataFindOneWorkflowType?.workflowTypeDetail?.[0]?.actionGroup?.id,
                  statusCodeGroupId:
                    selectStatus ||
                    dataFindOneWorkflowType?.workflowTypeDetail?.[0]?.statusGroup?.id,
                  description: values.description,
                  status: values?.active,
                  nodes: nodeNew,
                  edges: edges,
                  startDate: moment(values?.date?.[0], format).utc(),
                  endDate: dataFindOneWorkflowType?.workflowTypeDetail?.[0]?.endDate,
                },
              });
              setModalType('');
              // localStorage.removeItem('dataPayroll');
            }
          });
        } else {
          notificationToast(
            Notification.Type.Error,
            'You need to enter full information for the process',
            Notification.Duration._3s,
          );
        }
      } else {
        notificationToast(
          Notification.Type.Error,
          'You need to enter full action information for the workflow',
          Notification.Duration._3s,
        );
      }
    } else {
      notificationToast(
        Notification.Type.Error,
        'You need to complete the workflow',
        Notification.Duration._3s,
      );
    }
    // }
    // else {
    //   notificationToast('error', 'Status is incomplete. You can not edit information workflow', 3);
    // }
  };
  const [formAction] = Form.useForm();

  const handleSelectActionGroup = (value: any) => {
    if (value === dataFindOneWorkflowType?.workflowTypeDetail?.[0]?.actionGroup?.id) {
      dispatch(getFindOneAction({ id: value }));
    } else {
      dispatch(getFindOneActionAdd({ id: value }));
    }

    setSelectAction(value);
    formAction.setFieldsValue({
      selectAction: undefined,
    });
    setEdges((edgs) =>
      edgs?.map((edge) => {
        return { ...edge, label: '', actionId: undefined };
      }),
    );
  };
  const handleSelectStatusGroup = (value: any) => {
    setSelectStatus(value);
  };
  return (
    <>
      <Form form={form} layout="vertical">
        <EmployeeAddModalContent>
          <EmployeeAddModalContentLeft>
            <CustomFormItem
              name={'businessGroup'}
              label={<div>Business</div>}
              rules={[
                {
                  required: true,
                  message: t('HRM_VAD_REQUIRED', { field: 'Business Code' }),
                },
              ]}
            >
              <CustomSelect
                disabled
                allowClear
                showSearch
                placeholder={'Please input business code'}
                onChange={
                  handleSelectedValue
                  //   (value: any) => {
                  //   setSelectValue(value);
                  // }
                }
                value={selectValue}
              >
                {group?.map((data: any, key: any) => {
                  return (
                    <Option key={key} value={data.id}>
                      {data?.businessCode} - {data?.businessName}
                    </Option>
                  );
                })}
              </CustomSelect>
            </CustomFormItem>
            <CustomFormItem
              name={'workflowId'}
              label={`Workflow Id`}
              rules={[
                {
                  required: true,
                  message: t('HRM_VAD_REQUIRED', { field: 'Workflow Id' }),
                },
                {
                  whitespace: true,
                  message: t('HRM_VAD_BLANK_CHARACTERS', { field: 'Workflow Id' }),
                },
              ]}
            >
              <CustomInput disabled type="text" placeholder={`Please input workflow id`} />
            </CustomFormItem>
            <CustomFormItem
              name={'workflowName'}
              label={`Workflow Name`}
              rules={[
                {
                  required: true,
                  message: t('HRM_VAD_REQUIRED', { field: 'Workflow Name' }),
                },
                {
                  whitespace: true,
                  message: t('HRM_VAD_BLANK_CHARACTERS', { field: 'Workflow Name' }),
                },
              ]}
            >
              <CustomInput disabled type="text" placeholder={`Please input workflow name`} />
            </CustomFormItem>
            <CustomFormItem
              name={'action'}
              label={<div>Action Group</div>}
              rules={[
                {
                  required: true,
                  message: t('HRM_VAD_REQUIRED', { field: 'Action' }),
                },
              ]}
            >
              <CustomSelect
                allowClear
                showSearch
                placeholder={'Please select action'}
                onChange={(value: any) => {
                  handleSelectActionGroup(value);
                }}
                value={selectAction}
              >
                {dataListAction?.map((data: any, key: any) => (
                  <Option key={key} value={data.id}>
                    {data?.groupCode} - {data?.groupName}
                  </Option>
                ))}
              </CustomSelect>
            </CustomFormItem>
            <CustomFormItem
              name={'status'}
              label={<div>Status Group</div>}
              rules={[
                {
                  required: true,
                  message: t('HRM_VAD_REQUIRED', { field: 'Status' }),
                },
              ]}
            >
              <CustomSelect
                allowClear
                showSearch
                placeholder={'Please select status'}
                onChange={(value: any) => {
                  handleSelectStatusGroup(value);
                }}
                value={selectStatus}
              >
                {dataListStatus
                  ?.filter((element: any) => {
                    const isDuplicate = uniqueIds.includes(element.groupName);

                    if (!isDuplicate) {
                      uniqueIds.push(element.groupName);
                      return true;
                    }
                    return false;
                  })
                  ?.map((data: any, key: any) => (
                    <Option key={key} value={data.id}>
                      {data?.groupCode}- {data?.groupName}
                    </Option>
                  ))}
              </CustomSelect>
            </CustomFormItem>
            <CustomFormItem
              name={'date'}
              label={`Date`}
              rules={[
                // {
                //   required: true,
                //   message: t('HRM_VAD_REQUIRED', { field: 'Date' }),
                // },
                () => ({
                  validator(_, value) {
                    if (value[0] === null) {
                      return Promise.reject(
                        new Error(t('HRM_VAD_REQUIRED', { field: 'Start Date' })),
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <RangePicker disabled={[false, true]} format={format} style={{ width: '100%' }} />
            </CustomFormItem>
            <CustomFormItem
              name={'active'}
              label={<div>Status</div>}
              rules={[
                {
                  required: true,
                  message: t('HRM_VAD_REQUIRED', { field: 'status' }),
                },
              ]}
            >
              <CustomSelect
                allowClear
                showSearch
                placeholder={'Please input status'}
                onChange={(value: any) => {
                  setSelectActive(value);
                }}
                value={selectActive}
                // disabled
              >
                {[
                  { id: 1, name: 'Active' },
                  { id: 2, name: 'Deactive' },
                ]?.map((data: any, key: any) => {
                  return (
                    <Option key={key} value={data.name}>
                      {data?.name}
                    </Option>
                  );
                })}
              </CustomSelect>
            </CustomFormItem>
            <CustomFormItem name={'description'} label={`Description`}>
              <CustomInput type="text" placeholder={`Please input description`} />
            </CustomFormItem>
          </EmployeeAddModalContentLeft>
          <EmployeeAddModalDivider type="vertical" />
          <EmployeeAddModalContentRight>
            <Workflow2Edit
              setNodes={setNodes}
              setEdges={setEdges}
              edges={edges}
              nodes={nodes}
              currentItem={dataFindOneWorkflowType}
              modalType={modalType}
              selectAction={selectAction}
              selectStatus={selectStatus}
              //
              findActionIdOfEdge={findActionIdOfEdge}
              findStatusOfNode={findStatusOfNode}
              findEmployeesOfNodeOpen={findEmployeesOfNodeOpen}
              formAction={formAction}
            />
            <div style={{ display: 'flex' }}>
              {errMsg && (
                <div
                  style={{
                    width: '80%',
                    color: 'red',
                    height: 80,
                    overflowY: 'scroll',
                    marginTop: '1%',
                  }}
                >
                  {/* It nhat 1 process duoc tao ra */}
                  {checkErr1Process && <div>Workflow must have at least 1 process created</div>}
                  {/* error of node not edge end */}
                  {!checkErr1Process && checkNodeEdgeEnd === undefined && (
                    <div>Workflow need an end</div>
                  )}
                  {!checkErr1Process && checkNodeEdgeStart === undefined && (
                    <div>Workflow need to start from open</div>
                  )}
                  {/* error of node not end */}
                  {errMsg?.map((item: any, i: any, arr: any) => {
                    return (
                      <div>
                        ({item?.id}){item?.data?.label}: Need an end
                      </div>
                    );
                  })}
                  {/* error of node not start */}
                  {errMsgProcessDontStart?.map((item: any, i: any, arr: any) => {
                    return (
                      <div>
                        ({item?.id}){item?.data?.label}: Need an start
                      </div>
                    );
                  })}
                  {/* error of edge not actionid */}
                  {sourceTargetForErrMesOfAction?.map((item: any, i: any, arr: any) => {
                    return (
                      <div>
                        ({item?.source?.id}){item?.source?.data?.label} <ArrowRightOutlined /> (
                        {item?.target?.id}){item?.target?.data?.label}: ActionId is required
                      </div>
                    );
                  })}
                  {/* error of process NODE OPEN status and employees */}
                  {findEmployeesOfNodeOpen?.map((item: any, i: any, arr: any) => {
                    return (
                      <div>
                        ({item?.id}){item?.data?.label}: Status or employees is required
                      </div>
                    );
                  })}
                  {/* error of process status and employees */}
                  {findStatusOfNode?.map((item: any, i: any, arr: any) => {
                    return (
                      <div>
                        ({item?.id}){item?.data?.label}: Status or employees is required
                      </div>
                    );
                  })}
                  {/* error node alone */}
                  {detailNodeAlone?.map((item: any, i: any, arr: any) => {
                    return (
                      <div>
                        ({item?.id}){item?.data?.label}: Start and end is required
                      </div>
                    );
                  })}
                </div>
              )}
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '1%',
                }}
              >
                <Popconfirm
                  title={`Are you sure`}
                  onConfirm={() => {
                    onFinish();
                  }}
                  okText={`Yes`}
                  cancelText={`No`}
                >
                  <CustomButton>{`Save All`}</CustomButton>
                </Popconfirm>
              </div>
            </div>
          </EmployeeAddModalContentRight>
        </EmployeeAddModalContent>
      </Form>
    </>
  );
};

export default ModalEditWorkflowType;
export const CustomFormItem = styled(Form.Item)`
  margin-bottom: 5px;
  .ant-form-item-label {
    padding: 0px;
  }
`;
export const EmployeeAddModalContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  height: 100%;
  width: 100%;
  margin-right: -15px;
`;

export const EmployeeAddModalContentLeft = styled.div`
  padding-right: 10px;
  min-width: 200px;
  width: 32%%;
`;

export const EmployeeAddModalContentRight = styled.div`
  width: 100%;
  height: 446px;
  width: 82%;
`;

export const EmployeeAddModalDivider = styled(Divider)`
  height: 504px;
  border-left-width: 5px;
  border-radius: 2px;
`;

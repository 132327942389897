import { setGlobalLoading } from 'app/commonRedux/appSlice';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import axiosClient from 'api/axiosClient';

export const getStandardHour = createAsyncThunk(
  '/standard-hour',
  async (params: any, thunkApi: any) => {
    try {
      const response: any = await axiosClient.get('/standard-hour');
      if (params.callBack) params.callBack();
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const createStandardHour = createAsyncThunk(
  '/standard-hour/create-standard-hour',
  async (params: any, thunkApi: any) => {
    try {
      const { name, standardHour, standardHourHalfDay } = params;
      const response: any = await axiosClient.post('/standard-hour/create-standard-hour', {
        name,
        standardHour,
        standardHourHalfDay,
      });
      if (params.callBack) params.callBack();
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);
export const updateStandardHour = createAsyncThunk(
  '/standard-hour/update-standard-hour',
  async (params: any, thunkApi: any) => {
    try {
      const { id, name, standardHour, standardHourHalfDay } = params;
      const response: any = await axiosClient.put('/standard-hour/update-standard-hour', {
        id,
        name,
        standardHour,
        standardHourHalfDay,
      });
      if (params.callBack) params.callBack();
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const deleteStandardHour = createAsyncThunk(
  '/standard-hour/delete-standard-hour',
  async (params: any, thunkApi: any) => {
    try {
      const { id } = params;
      const response: any = await axiosClient.put(`/standard-hour/delete-standard-hour?id=${id}`);
      if (params.callBack) params.callBack();
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getStandardHourHistory = createAsyncThunk(
  '/standard-hour/history/find-by-standard-hour-id',
  async (params: any, thunkApi: any) => {
    try {
      const { id, limit, offset } = params;
      const response: any = await axiosClient.get(
        `/standard-hour/history/find-by-standard-hour-id?standardHourId=${id}&limit=${limit}&offset=${offset}`,
      );
      if (params.callBack) params.callBack();
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getConfigWorkingDayWeek = createAsyncThunk(
  '/configurations/working-day-week',
  async (params: any, thunkApi: any) => {
    try {
      const response: any = await axiosClient.get('/configurations/working-day-week/28');
      if (params.callBack) params.callBack();
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);
export const getWorkshift = createAsyncThunk('/workshift', async (params: any, thunkApi: any) => {
  try {
    const response: any = await axiosClient.get('/workshift');
    if (params.callBack) params.callBack();
    return response;
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});
export const getHistoryWorkshift = createAsyncThunk(
  '/workshift/history/find-by-workshift-id',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const { workshiftId } = params;
      const response: any = await axiosClient.get(
        `/workshift/history/find-by-workshift-id?workshiftId=${workshiftId}`,
      );
      if (params.callBack) params.callBack();
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);
export const updateWorkshift = createAsyncThunk(
  '/workshift',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const { id, name, startTime, endTime, workDates, isFullTime, breakTimes, minuteMinCheckin } =
        params;
      const response: any = await axiosClient.put('/workshift', {
        id,
        name,
        startTime,
        endTime,
        workDates,
        isFullTime,
        breakTimes,
        minuteMinCheckin,
      });

      if (params.callBack) params.callBack(response);
      return response;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const createWorkshift = createAsyncThunk(
  '/workshift',
  async (params: any, thunkApi: any) => {
    try {
      const { name, startTime, endTime, workDates, breakTimes, isFullTime, minuteMinCheckin } =
        params;
      const response: any = await axiosClient.post('/workshift', {
        name,
        startTime,
        endTime,
        workDates,
        isFullTime,
        breakTimes,
        minuteMinCheckin,
      });
      if (params.callBack && response) params.callBack(response);
      return response;
    } catch (error) {
      console.log(error);
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const deleteWorkshift = createAsyncThunk(
  '/workshift/delete-workshift',
  async (params: any, thunkApi: any) => {
    try {
      const { id } = params;
      const response: any = await axiosClient.put(`/workshift/delete-workshift?id=${id}`);
      if (params.callBack) params.callBack();
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getAssignUserWorkshift = createAsyncThunk(
  '/workshift/assign-user-workshift/find-all',
  async (params: any, thunkApi: any) => {
    try {
      const response: any = await axiosClient.get(`/workshift/assign-user-workshift/find-all`);
      if (params.callBack) params.callBack();
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);
export const assignUserWorkshift = createAsyncThunk(
  '/workshift/assign-user-workshift',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const { userIds, startDate, endDate, workshiftIds, standHourId } = params;
      const response: any = await axiosClient.post(`/workshift/assign-user-workshift`, {
        userIds,
        startDate,
        endDate,
        workshiftIds,
        standHourId,
      });
      if (params.callBack) params.callBack();
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const updateAssignUserWorkshift = createAsyncThunk(
  '/workshift/update-assign-user-workshift',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const { id, startDate, endDate, isDelete } = params;
      const response: any = await axiosClient.post(`/workshift/update-assign-user-workshift`, {
        id,
        startDate,
        endDate,
        isDelete,
      });
      if (params.callBack) params.callBack();
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);
export const deleteAssignUserWorkshift = createAsyncThunk(
  '/workshift/remove-assign-user-workshift',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const { id } = params;
      const response: any = await axiosClient.get(
        `/workshift/remove-assign-user-workshift?id=${id}`,
      );
      if (params.callBack) params.callBack();
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);
const workshift = createSlice({
  name: 'workshift',
  initialState: {
    error: null,
    standardHourData: [],
    isLoadingStandardHour: false,
    standardHourHistoryData: [],
    isLoadingHistory: false,
    workshiftData: [],
    workingDayWeekData: [],
    workshiftHistoryData: [],
    isLoadingWorkshift: false,
    assignUserWorkshiftData: [],
    isLoading: false,
    isModalShow: false,
  },
  reducers: {
    setModalShow(state, action) {
      state.isModalShow = action.payload;
    },
    resetWorkshiftHistoryData(state, action) {
      state.workshiftHistoryData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStandardHour.pending, (state, action: any) => {
        state.isLoadingStandardHour = true;
        state.standardHourData = [];
        state.error = null;
      })
      .addCase(getStandardHour.fulfilled, (state, action) => {
        state.isLoadingStandardHour = false;
        state.error = null;
        state.standardHourData = action.payload.data;
      })
      .addCase(getStandardHour.rejected, (state, action: any) => {
        state.isLoadingStandardHour = false;
        state.standardHourData = [];
        state.error = action.payload;
      })
      .addCase(getStandardHourHistory.pending, (state, action: any) => {
        state.isLoadingHistory = true;
        state.standardHourHistoryData = [];
        state.error = null;
      })
      .addCase(getStandardHourHistory.fulfilled, (state, action) => {
        state.isLoadingHistory = false;
        state.error = null;
        state.standardHourHistoryData = action.payload.data;
      })
      .addCase(getStandardHourHistory.rejected, (state, action: any) => {
        state.isLoadingHistory = false;
        state.standardHourHistoryData = [];
        state.error = action.payload;
      })
      .addCase(getWorkshift.pending, (state, action: any) => {
        state.isLoadingWorkshift = true;
        state.workshiftData = [];
        state.error = null;
      })
      .addCase(getWorkshift.fulfilled, (state, action) => {
        state.isLoadingWorkshift = false;
        state.error = null;
        state.workshiftData = action.payload.data;
      })
      .addCase(getWorkshift.rejected, (state, action: any) => {
        state.isLoadingWorkshift = false;
        state.workshiftData = [];
        state.error = action.payload;
      })
      .addCase(getHistoryWorkshift.pending, (state, action: any) => {
        state.workshiftHistoryData = [];
        state.error = null;
      })
      .addCase(getHistoryWorkshift.fulfilled, (state, action) => {
        state.error = null;
        state.workshiftHistoryData = action.payload.data;
      })
      .addCase(getHistoryWorkshift.rejected, (state, action: any) => {
        state.workshiftHistoryData = [];
        state.error = action.payload;
      })
      .addCase(getAssignUserWorkshift.pending, (state, action: any) => {
        state.isLoading = true;
        state.assignUserWorkshiftData = [];
        state.error = null;
      })
      .addCase(getAssignUserWorkshift.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.assignUserWorkshiftData = action.payload.data;
      })
      .addCase(getAssignUserWorkshift.rejected, (state, action: any) => {
        state.isLoading = false;
        state.assignUserWorkshiftData = [];
        state.error = action.payload;
      })
      .addCase(getConfigWorkingDayWeek.pending, (state, action: any) => {
        state.isLoadingWorkshift = true;
        state.workingDayWeekData = [];
        state.error = null;
      })
      .addCase(getConfigWorkingDayWeek.fulfilled, (state, action) => {
        state.isLoadingWorkshift = false;
        state.error = null;
        state.workingDayWeekData = action.payload.data;
      })
      .addCase(getConfigWorkingDayWeek.rejected, (state, action: any) => {
        state.isLoadingWorkshift = false;
        state.workingDayWeekData = [];
        state.error = action.payload;
      });
  },
});

export const selectWorkshift = (state: RootState) => state.workshift;
export const { reducer, actions } = workshift;
export default reducer;

import { Select } from 'antd';
import payrollDashboardApi from 'api/payrollDashboardApi';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { RangePickerField, SelectField } from 'components/FormFields';
import ITVSelect from 'components/ITVSelect';
import { selectEmployee } from 'features/employee/employeeSlice';
import { addAssignee, searchAssignee } from 'features/training/trainingAction';
import { selectTrainingData } from 'features/training/trainingSlice';
import { useEmployeeListForContractV2 } from 'hooks/fetchers/useEmployeeListForContractV2';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import CustomButton from 'styles/buttonStyled';
import { useDebounce, useDebouncedCallback } from 'use-debounce';

const AddTrainee = ({ callBack, setSelectUser, selectUser }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['trainningManagement', 'action', 'performance', 'recruitment']);
  const [selectedGroup, setSelectedGroup] = useState('');
  const [search, setSearch] = useState('');

  const [location, setLocation] = useState<any>([]);
  const [department, setDepartment] = useState<any>([]);
  const [specificEmployee, setSpecificEmployee] = useState<any>([]);
  const trainData = useAppSelector(selectTrainingData);
  const filterTrainee = trainData?.filterTrainee?.result;
  const dataEmployee: any = useAppSelector(selectEmployee);
  const searchTrainee = trainData?.searchTrainee?.result;
  const [data, setData] = useState([]);
  const filters = useMemo(() => ({ offset: 1, limit: 1000 }), []);
  const { data: dataFull, isLoading } = useEmployeeListForContractV2(filters);

  const debounced = useDebouncedCallback((value) => {
    setSearch(value);
  }, 350);

  useEffect(() => {
    if (searchTrainee) {
      const newData = searchTrainee?.filter(
        (i) => !selectUser?.some((alreadySelectUser: any) => alreadySelectUser?.id === i?.id),
      );
      setData(newData);
    }
  }, [searchTrainee]);

  useEffect(() => {
    dispatch(
      searchAssignee({
        status: 'approved',
        search,
        limit: 10,
        offset: 1,
      }),
    );
  }, [dispatch, search]);

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: undefined,
    defaultValues: {},
  });
  const onSubmit = (data: any) => {
    const filteredData = {};

    if (selectedGroup === 'Location' || selectedGroup === 'Department') {
      filteredData.locationId = data?.locationId;
    }

    if (selectedGroup === 'Department') {
      filteredData.departmentId = data?.departmentId;
    }

    if (selectedGroup === 'Specific Employee') {
      const users = dataFull?.filter((user: any) => specificEmployee?.includes(user?.id));
      setSelectUser && setSelectUser([...selectUser, ...users]);
      callBack && callBack();
      return;
    }

    if (selectedGroup === 'Employee Start Date') {
      filteredData.startDate = +moment(data?.period?.[0]);
      filteredData.endDate = +moment(data?.period?.[1]);
    }
    dispatch(addAssignee({ filteredData, callBack: () => callBack && callBack() }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [responseDataLocation, responseDataDepartment] = await Promise.all([
          payrollDashboardApi.getLocation(),
          payrollDashboardApi.getDepartmentGroupByTeam(),
        ]);

        const locationArrs = (responseDataLocation?.data?.result || []).map((i) => ({
          label: i?.name,
          value: i?.id,
        }));

        const departmentArrs = (responseDataDepartment?.data || []).map((i) => ({
          label: i?.name,
          value: i?.id,
        }));

        setLocation(locationArrs);
        setDepartment(departmentArrs);
      } catch (error) {
        console.error('Failed to fetch data', error);
      }
    };
    fetchData();
  }, []);

  const groupOpt = [
    { label: 'Location', value: 'Location' },
    { label: 'Department', value: 'Department' },
    { label: 'Specific Employee', value: 'Specific Employee' },
    { label: 'Employee Start Date', value: 'Employee Start Date' },
  ];

  const handleGroupChange = (value) => {
    setSelectedGroup(value);
  };

  const handleChangeEmployee = (value: any, key: any) => {
    let is_obj = [];
    for (let i = 0; i < value.length; i++) {
      is_obj.push(key[i].key);
    }
    setSpecificEmployee(is_obj);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <SectionWrapper>
        <label>{t('trainningManagement:course_management.select_employee_group')}</label>
        <Select style={{ width: '100%' }} options={groupOpt} onChange={handleGroupChange} />
      </SectionWrapper>
      <>
        <SectionWrapper>
          <label>{t('recruitment:location')}</label>
          <SelectField
            style={{ width: '100%' }}
            disabled={!(selectedGroup === 'Location' || selectedGroup === 'Department')}
            control={control}
            name="locationId"
            options={location}
          />
        </SectionWrapper>
        <SectionWrapper>
          <label>{t('recruitment:department')}</label>
          <SelectField
            style={{ width: '100%' }}
            disabled={selectedGroup !== 'Department'}
            control={control}
            name="departmentId"
            options={department}
          />
        </SectionWrapper>
      </>

      <SectionWrapper>
        <label>{t('trainningManagement:course_management.specific_employee')}</label>
        <ITVSelect
          disabled={selectedGroup !== 'Specific Employee'}
          dataSource={data}
          mode="multiple"
          valueSelected={specificEmployee}
          width={752}
          size="middle"
          filterOption={(input, option) => {
            debounced(input);
            return true;
          }}
          onChangeSelect={handleChangeEmployee}
        />
        {/* <SelectField
          style={{ width: '100%' }}
          disabled={selectedGroup !== 'Specific Employee'}
          control={control}
          name="specificEmployeeId"
          options={[]}
        /> */}
      </SectionWrapper>

      <SectionWrapper>
        <label>{t('trainningManagement:course_management.employee_start_date')}</label>
        <RangePickerField
          style={{ width: '100%' }}
          disabled={selectedGroup !== 'Employee Start Date'}
          control={control}
          name="period"
        />
      </SectionWrapper>
      <div style={{ display: 'flex', justifyContent: 'center', gap: 16 }}>
        <CustomButton
          aType="outline"
          onClick={() => {
            callBack && callBack();
          }}
        >
          {t('action:cancel')}
        </CustomButton>
        <CustomButton
          disabled={!selectedGroup}
          onClick={() => {
            onSubmit(getValues());
          }}
        >
          {t('action:add')}
        </CustomButton>
      </div>
    </form>
  );
};

export default AddTrainee;

export const SectionWrapper = styled.div`
  display: flex;
  margin-bottom: 16px;
  flex-direction: column;
  gap: 8px;
`;

import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import {
  getAssessmentDetail,
  getCycleList,
  getDetailCycle,
  getHRPerformanceView,
  getListAssessmentEmployee,
  getManagerView,
  getPendingReviewPerformance,
  getPerformanceTemplate,
  getPerformanceTemplates,
  getReviewCycle,
} from './performanceAction';

const initialState: any = {
  managerViewData: [],
  performanceTemplate: {},
  reviewCycleData: null,
  performanceTemplateDetail: null,
  assessmentDetail: {},
  cycleList: [],
  cycleDetail: null,
  listAssessmentEmployee: null,
  pendingReviewPerformance: null,
  performanceDataHR: null,
};

const performanceDataSlice = createSlice({
  name: 'performanceData',
  initialState: initialState,
  reducers: {
    resetcycleDetail(state, action) {
      state.cycleDetail = null;
    },
    resetpendingReviewPerformance(state, action) {
      state.pendingReviewPerformance = null;
    },
    resetperformanceTemplateDetail(state, action) {
      state.performanceTemplateDetail = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getManagerView.fulfilled, (state: any, action: any) => {
        state.managerViewData = action.payload?.data;
      })
      .addCase(getManagerView.rejected, (state: any, action: any) => {
        state.managerViewData = [];
      })
      .addCase(getPerformanceTemplates.pending, (state: any, action: any) => {})
      .addCase(getPerformanceTemplates.fulfilled, (state: any, action: any) => {
        state.performanceTemplate = action.payload?.data;
      })
      .addCase(getPerformanceTemplates.rejected, (state: any, action: any) => {
        state.performanceTemplate = null;
      })
      .addCase(getReviewCycle.pending, (state: any, action: any) => {
        state.reviewCycleData = null;
      })
      .addCase(getReviewCycle.fulfilled, (state: any, action: any) => {
        state.reviewCycleData = action.payload?.data;
      })
      .addCase(getReviewCycle.rejected, (state: any, action: any) => {
        state.reviewCycleData = null;
      })
      .addCase(getPerformanceTemplate.pending, (state: any, action: any) => {
        state.performanceTemplateDetail = null;
      })
      .addCase(getPerformanceTemplate.fulfilled, (state: any, action: any) => {
        state.performanceTemplateDetail = action.payload?.data;
      })
      .addCase(getPerformanceTemplate.rejected, (state: any, action: any) => {
        state.performanceTemplateDetail = null;
      })
      .addCase(getAssessmentDetail.pending, (state: any, action: any) => {
        state.assessmentDetail = {};
      })
      .addCase(getAssessmentDetail.fulfilled, (state: any, action: any) => {
        state.assessmentDetail = action.payload?.data;
      })
      .addCase(getAssessmentDetail.rejected, (state: any, action: any) => {
        state.assessmentDetail = null;
      })
      .addCase(getCycleList.pending, (state: any, action: any) => {
        state.cycleList = [];
      })
      .addCase(getCycleList.fulfilled, (state: any, action: any) => {
        state.cycleList = action.payload?.data;
      })
      .addCase(getCycleList.rejected, (state: any, action: any) => {
        state.cycleList = [];
      })
      .addCase(getDetailCycle.pending, (state: any, action: any) => {
        state.cycleDetail = null;
      })
      .addCase(getDetailCycle.fulfilled, (state: any, action: any) => {
        state.cycleDetail = action.payload?.data;
      })
      .addCase(getDetailCycle.rejected, (state: any, action: any) => {
        state.cycleDetail = null;
      })
      .addCase(getListAssessmentEmployee.pending, (state: any, action: any) => {})
      .addCase(getListAssessmentEmployee.fulfilled, (state: any, action: any) => {
        state.listAssessmentEmployee = action.payload?.data;
      })
      .addCase(getListAssessmentEmployee.rejected, (state: any, action: any) => {
        state.listAssessmentEmployee = null;
      })
      .addCase(getPendingReviewPerformance.pending, (state: any, action: any) => {})
      .addCase(getPendingReviewPerformance.fulfilled, (state: any, action: any) => {
        state.pendingReviewPerformance = action.payload?.data;
      })
      .addCase(getPendingReviewPerformance.rejected, (state: any, action: any) => {
        state.pendingReviewPerformance = null;
      })
      .addCase(getHRPerformanceView.pending, (state: any, action: any) => {})
      .addCase(getHRPerformanceView.fulfilled, (state: any, action: any) => {
        state.performanceDataHR = action.payload?.data;
      })
      .addCase(getHRPerformanceView.rejected, (state: any, action: any) => {
        state.performanceDataHR = null;
      });
    //getHRPerformanceView
  },
});

export const selectPerformanceData = (state: RootState) => state.performance;
export const { reducer, actions } = performanceDataSlice;

export default reducer;

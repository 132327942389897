import { Spin } from 'antd';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { memo } from 'react';
import { Bar } from 'react-chartjs-2';

import { TimesheetAdjustmentReport } from 'types';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

const options = {
  indexAxis: 'y' as const,
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      display: true,
      color: 'black',
      anchor: 'end' as const,
      align: 'right' as const,
      offset: 4,
      formatter: (value: any) => {
        return value;
      },
    },
  },
  maintainAspectRatio: false,
  scales: {
    x: {
      grid: {
        display: true,
      },
      ticks: {
        padding: 10,
        callback: function (value: any) {
          if (Number.isInteger(value)) {
            return value;
          }
        },
        stepSize: 1, // Ensure the scale steps by 1 unit
      },
    },
    y: {
      stacked: true,
      grid: {
        display: false,
      },
      barPercentage: 0.5, // Optional: You can try adjusting this along with barThickness
      categoryPercentage: 0.5, // Optional: You can try adjusting this along with barThickness
      ticks: {
        padding: 10,
      },
    },
  },
};

interface HeadcountLineChartProps {
  loading: boolean;
  dataChart: TimesheetAdjustmentReport;
}

function HeadcountLineChart({ loading, dataChart }: HeadcountLineChartProps) {
  const height =
    dataChart.labels.length > 3 ? dataChart.labels.length * 40 : dataChart.labels.length * 40 + 40; // 40px cho mỗi label

  return (
    <Spin spinning={loading}>
      <div style={{ height: `${height}px`, width: '100%' }}>
        <Bar options={options} data={dataChart} />
      </div>
    </Spin>
  );
}

export default memo(HeadcountLineChart);

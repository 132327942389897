import { Layout, Tabs } from 'antd';
import { useAppSelector } from 'app/hooks';
import PageHeader from 'components/PageHeader';
import { selectConfiguration } from 'features/configuration/configurationSlice';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import HistoryWorkflowType from './components/HistoryWorkflowType';
import ManageWorkflowType from './components/ManageWorkflowType';
import { useTranslation } from 'react-i18next';

const { Content } = Layout;

function SetupWorkflowType() {
  const { TabPane } = Tabs;
  const [, setActiveTab] = useState<'workflowtype' | 'history' | 'setting_workflow'>('request');
  const location: any = useLocation();
  const menuStore = useAppSelector(selectConfiguration);
  const dataScreenManage = menuStore?.dataScreenManage?.result;
  const [titleHeader, setTitleHeader] = useState('');
  const { t } = useTranslation(['configuration']);

  useEffect(() => {
    const temp = dataScreenManage
      ?.filter((item: any) => location.pathname === item.path)
      ?.map((item: any) => item.nameScreen);

    setTitleHeader(temp ? temp[0] : '');
  }, [dataScreenManage, location.pathname]);

  return (
    <>
      <Container>
        <PageHeader title={titleHeader} />
      </Container>
      <Content>
        <Tabs
          defaultActiveKey="1"
          onTabClick={(key: string) => {
            if (key === '1') {
              setActiveTab('workflowtype');
            }
            if (key === '2') {
              setActiveTab('history');
            }
            if (key === '3') {
              setActiveTab('setting_workflow');
            }
          }}
        >
          <TabPane tab={t('workflowType.creat_workflow_type')} key="1">
            <ManageWorkflowType />
          </TabPane>
          {/* <TabPane tab={`Setting Workflow Type`} key="3">
            <SettingWorkflowType />
          </TabPane> */}
          <TabPane tab={t('workflowType.history')} key="2">
            <HistoryWorkflowType />
          </TabPane>
        </Tabs>
      </Content>
    </>
  );
}

export default SetupWorkflowType;
const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

import { Select } from 'antd';
import { selectFormatDate, selectFormatDatetime, selectFormatHour } from 'app/commonRedux/appSlice';
import { useAppSelector } from 'app/hooks';
import ITVSearch from 'components/ITVSearch';
import ITVTable from 'components/ITVTable';
import EmployeeColumn from 'features/employee/components/EmployeeColumn';
import { getHistoryPlanUserDetail, getProjectList } from 'features/overtime/overtimeAction';
import { selectOvertimeData } from 'features/overtime/overtimeSlice';
import { TimeSheetStatus } from 'hrm-common/extensions/enums/personel';
import cond from 'lodash/cond';
import stubTrue from 'lodash/stubTrue';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { removeAccents } from 'utils/text';

const { Option } = Select;

const ViewUser = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState<any>([]);
  const { t } = useTranslation(['validation', 'overtime', 'timesheet', 'employee']);
  const [sizePage, setSizePage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const dateFormat = useAppSelector(selectFormatDate);
  const formatDatetime = useAppSelector(selectFormatDatetime);
  const formatHour = useAppSelector(selectFormatHour);
  const overtimeData = useAppSelector(selectOvertimeData);
  const listProject = overtimeData?.listProject;
  const dataHistoryPlanUserDetail = overtimeData?.dataHistoryPlanUserDetail;
  const loadingDataHistoryPlanUserDetail = overtimeData?.loadingDataHistoryPlanUserDetail;
  const [project, setProject] = useState('');
  const [projectStatusState] = useState('');
  const [text, setText] = useState('');

  const searchFilterSortParam = {
    projectCode: project,
    status: projectStatusState,
    limit: sizePage,
    offset: currentPage,
    search: text,
  };
  const [paramSortFilter, setParamSortFilter] = useState(searchFilterSortParam);

  useEffect(() => {
    dispatch(getProjectList());
  }, [dispatch]);

  useEffect(() => {
    if (dataHistoryPlanUserDetail?.result) {
      setData(dataHistoryPlanUserDetail?.result);
    }
  }, [dataHistoryPlanUserDetail]);

  useEffect(() => {
    setParamSortFilter({
      ...paramSortFilter,
      projectCode: project,
      status: projectStatusState,
      limit: sizePage,
      offset: currentPage,
      search: text,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectStatusState, project, sizePage, currentPage, text]);

  useEffect(() => {
    dispatch(getHistoryPlanUserDetail(paramSortFilter));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramSortFilter]);

  const getColor = cond([
    [(x: string) => x === TimeSheetStatus.APPROVED, () => '#10B981'],
    [(x: string) => x === TimeSheetStatus.PENDING, () => '#fcc059'],
    [(x: string) => x === TimeSheetStatus.REJECTED, () => '#EF4444'],
    [stubTrue, () => 'black'],
  ]);

  const COLUMNS: any = [
    {
      title: 'ID',
      dataIndex: 'employeeId',
      key: 'employeeId',
      width: 60,
      minWidth: 60,
      ellipsis: true,
      align: 'center',
      render: function (text: any, record: any, index: any) {
        const obj: any = {
          children: record?.overtimeUserRequest?.user?.employeeId,
          props: {
            style: {
              verticalAlign: 'top',
            },
          },
        };
        if (
          index >= 1 &&
          record?.overtimeUserRequest?.user?.employeeId ===
            data[index - 1]?.overtimeUserRequest?.user?.employeeId
        ) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            index + i !== data.length &&
            record?.overtimeUserRequest?.user?.employeeId ===
              data[index + i]?.overtimeUserRequest?.user?.employeeId;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }
        return obj;
      },
    },
    {
      title: t('employee:employee_information_fields.employee'),
      dataIndex: 'fullName',
      key: 'fullName',
      width: 100,
      minWidth: 100,
      ellipsis: true,
      render: function (text: any, record: any, index: any) {
        const obj: any = {
          children: (
            <EmployeeColumn
              avatar={
                record?.overtimeUserRequest?.user?.fullName
                  ? `${record?.overtimeUserRequest?.user?.fullName}`
                  : `${record?.overtimeUserRequest?.user?.lastName} ${record?.overtimeUserRequest?.user?.firstName}`
              }
              fullName={
                record?.overtimeUserRequest?.user?.fullName
                  ? `${record?.overtimeUserRequest?.user?.fullName}`
                  : `${record?.overtimeUserRequest?.user?.lastName} ${record?.overtimeUserRequest?.user?.firstName}`
              }
              email={record?.overtimeUserRequest?.user?.email}
            />
          ),
          props: {
            style: {
              verticalAlign: 'top',
            },
          },
        };
        if (
          index >= 1 &&
          record?.overtimeUserRequest?.user?.employeeId ===
            data[index - 1]?.overtimeUserRequest?.user?.employeeId
        ) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            index + i !== data.length &&
            record?.overtimeUserRequest?.user?.employeeId ===
              data[index + i]?.overtimeUserRequest?.user?.employeeId;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }
        return obj;
      },
    },

    {
      title: t('overtime:project'),
      dataIndex: 'project',
      key: 'project',
      width: 150,
      minWidth: 150,
      align: 'center',
      render: function (text: any, record: any, index: any) {
        const obj: any = {
          children:
            record?.overtimeUserRequest?.overtimeRequestApprove?.project?.projectCode +
            '-' +
            record?.overtimeUserRequest?.overtimeRequestApprove?.project?.name,
          props: {
            style: {
              verticalAlign: 'top',
            },
          },
        };
        if (
          index >= 1 &&
          record?.overtimeUserRequest?.user?.employeeId ===
            data[index - 1]?.overtimeUserRequest?.user?.employeeId &&
          data[index - 1]?.overtimeUserRequest?.overtimeRequestApprove?.project?.projectCode ===
            record?.overtimeUserRequest?.overtimeRequestApprove?.project?.projectCode
        ) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            index + i !== data.length &&
            record?.overtimeUserRequest?.user?.employeeId ===
              data[index + i]?.overtimeUserRequest?.user?.employeeId &&
            data[index + i]?.overtimeUserRequest?.overtimeRequestApprove?.project?.projectCode ===
              record?.overtimeUserRequest?.overtimeRequestApprove?.project?.projectCode;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }
        return obj;
      },
    },

    {
      title: t('overtime:information'),
      children: [
        {
          title: t('overtime:updated_at'),
          dataIndex: 'updatedAt',
          align: 'center',
          key: 'updatedAt',
          width: 150,
          minWidth: 150,
          render: function (text: any, record: any, index: any) {
            const obj: any = {
              children: text ? moment(text).format(formatDatetime) : null,
              props: {
                style: {},
              },
            };
            return obj;
          },
        },
        {
          title: t('overtime:overtime_period'),
          dataIndex: 'overtimetPeriod',
          key: 'overtimetPeriod',
          align: 'center',
          width: 150,
          minWidth: 150,
          render: function (text: any, record: any, index: any) {
            const obj: any = {
              children: (
                <>
                  {record?.overtimeStartDate || record?.overtimeEndDate ? (
                    <p>
                      {moment(record.overtimeStartDate).format(dateFormat)} <br />{' '}
                      {moment(record.overtimeStartDate).format(formatHour)} -{' '}
                      {moment(record.overtimeEndDate).format(formatHour)}
                    </p>
                  ) : (
                    ''
                  )}
                </>
              ),
              props: {
                style: {},
              },
            };
            return obj;
          },
        },
        {
          title: t('overtime:overtime_hour'),
          dataIndex: 'overtimeHour',
          key: 'overtimeHour',
          width: 150,
          minWidth: 150,
          align: 'center',
          render: function (text: any, record: any, index: any) {
            const obj: any = {
              children: (
                <div
                  style={{
                    color: getColor(record.status),
                  }}
                >
                  {record?.overtimeHour?.toFixed(2) || '0.00'}
                </div>
              ),
              props: {
                style: {},
              },
            };
            return obj;
          },
        },
        {
          title: t('overtime:overtime_total'),
          dataIndex: 'actualHour',
          key: 'actualHour',
          width: 150,
          minWidth: 150,
          align: 'center',
          render: function (text: any, record: any, index: any) {
            const obj: any = {
              children: <div>{record?.actualHour?.toFixed(2) || '0.00'}</div>,
              props: {
                style: {},
              },
            };
            return obj;
          },
        },
      ],
    },

    {
      title: t('overtime:updated_by'),
      dataIndex: 'updatedBy',
      key: 'updatedBy',
      width: 100,
      minWidth: 100,
      render: function (text: any, record: any, index: any) {
        return (
          <EmployeeColumn avatar={text?.fullName} fullName={text?.fullName} email={text?.email} />
        );
      },
    },
  ];

  const onSearchList = (key: string) => {
    const txt = removeAccents(key.toLowerCase());
    if (txt) {
      setText(txt);
    } else {
      setText('');
    }
  };

  return (
    <Container>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ width: '100%', display: 'flex' }}>
          <SearchContainer>
            <ITVSearch
              style={{ width: 'inherit' }}
              handleSearch={(value: any) => {
                onSearchList(value);
              }}
            />
          </SearchContainer>
          <div style={{ marginLeft: 10 }}>
            <Select
              showSearch
              style={{ width: 200 }}
              defaultValue={project}
              onChange={(value: any) => setProject(value)}
              filterOption={(input, option: any) =>
                (option!.value as unknown as string).toLowerCase().includes(input.toLowerCase())
              }
            >
              <Option value="">{t('overtime:all_projects')}</Option>
              {listProject?.map((item: any) => {
                return (
                  <Option value={item?.projectCode} title={item?.name}>
                    {item?.projectCode} - {item?.name}
                  </Option>
                );
              })}
            </Select>
          </div>
        </div>
      </div>
      <ITVTable
        isRowSelect={false}
        loading={loadingDataHistoryPlanUserDetail}
        columns={COLUMNS}
        isScroll={true}
        width={'max-content'}
        data={data}
        isRowLight={true}
        setOffset={setCurrentPage}
        offset={currentPage}
        setLimit={setSizePage}
        limit={sizePage}
        totalResult={dataHistoryPlanUserDetail?.totalResult}
        totalIni={dataHistoryPlanUserDetail?.totalResult}
      />
    </Container>
  );
};

export default ViewUser;
export const SearchContainer = styled.div`
  width: 200px;
  margin-bottom: 20px;
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  background: white;
  width: 100%;
  height: max-content;
`;

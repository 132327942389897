import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import { DateRangePickerField, SelectField } from 'components/FormFields';
import { DiligencePayload } from 'types';
import { DATE_FORMAT } from 'constants/commons';
import { DatePicker } from 'antd';
import { DatePickerIcon } from 'assets/icons';
import { useState } from 'react';
import { Moment } from 'moment';

const StyledForm = styled.form`
  display: flex;
  gap: 12px;
`;

const StyledWrapperDateRange = styled.div`
  width: 226px;
`;

interface DiligenceFiltersProps {
  loading?: boolean;
  initialValues?: any;
  onSubmit?: (payload: any) => void;
  location?: any;
  department?: any;
}

export default function PayrollSummaryLocationDepartmentFilters({
  initialValues,
  onSubmit,
  location,
  department,
}: any) {
  const { t } = useTranslation(['dashboard']);
  const [monthYear, setMonthYear] = useState<Moment | null>(initialValues?.monthYear || null);

  const { control, handleSubmit, getValues } = useForm<any>({
    defaultValues: initialValues,
  });

  const handleFiltersSubmit = (value: any) => {
    onSubmit?.(value);
  };

  const viewByLocation = location?.map((item) => ({ label: item?.name, value: item?.id }));
  const viewByDepartment = department?.map((item) => ({
    label: item?.name,
    value: item?.projectCode,
  }));

  return (
    <StyledWrapperYear style={{ display: 'flex' }}>
      <div style={{ display: 'flex', alignItems: 'center', marginRight: 20 }}>
        <p style={{ padding: 0, margin: 0, marginRight: 10, fontWeight: 600 }}>Location</p>
        <SelectField
          style={{
            width: 100,
          }}
          name="viewByLocation"
          control={control}
          options={viewByLocation || []}
          showSearch
          allowClear={false}
          onChange={handleSubmit(handleFiltersSubmit)}
        />
      </div>
      <div style={{ display: 'flex', alignItems: 'center', marginRight: 20 }}>
        <p style={{ margin: 0, padding: 0, marginRight: 10, fontWeight: 600 }}>Pay Period</p>
        <DatePicker
          style={{ width: 110 }}
          picker="month"
          value={monthYear}
          allowClear={false}
          suffixIcon={<DatePickerIcon />}
          onChange={(value) => {
            setMonthYear(value);
            onSubmit?.({
              monthYear: value,
              from: 'monthYear',
              viewByDepartment: getValues('viewByDepartment'),
              viewByLocation: getValues('viewByLocation'),
            });
          }}
        />
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <p style={{ padding: 0, margin: 0, marginRight: 10, fontWeight: 600 }}>Line</p>
        <SelectField
          style={{
            width: 100,
          }}
          name="viewByDepartment"
          control={control}
          options={viewByDepartment || []}
          showSearch
          allowClear={false}
          onChange={handleSubmit(handleFiltersSubmit)}
        />
      </div>
    </StyledWrapperYear>
  );
}

const StyledWrapperYear = styled.div`
  .ant-picker {
    border-radius: 6px;
    border: 1px solid var(--cl_neutral300);
  }
`;

import moment from 'moment';
import { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';

import dashboardApi from 'api/dashboardApi';
import { EmployeeGrowthPayload, EmployeeGrowthParams, EmployeeGrowthReport } from 'types';
import PayrollSummaryFilters from './PayrollSummaryFilters';
import PayrollSummaryChart from './PayrollSummaryChart';
import { DATE_PAYLOAD_FORMAT, MONTH_NAMES } from 'constants/commons';
import { path } from 'constants/path';
import payrollDashboardApi from 'api/payrollDashboardApi';

const StyledContainer = styled.div`
  padding: 16px 24px;
  background-color: var(--cl_white);
  border-radius: 12px;
  border: 0.5px solid var(--cl_gray200);
  height: 100%;
`;

const StyledHeaderTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto;
  line-height: 20px;
  color: #121926;
`;

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export function PayrollSummary() {
  const { t } = useTranslation(['dashboard']);
  const history = useHistory();
  const labels = MONTH_NAMES.map((month) => month.slice(0, 3));

  const [isLoading, setIsLoading] = useState(false);
  const [dataChart, setDataChart] = useState<EmployeeGrowthReport>({
    labels: [],
    datasets: [],
  });
  const [filters, setFilters] = useState<EmployeeGrowthParams>({
    year: moment(new Date()).format('YYYY'),
  });

  useEffect(() => {
    async function fetchReportEmployeeGrowth() {
      try {
        setIsLoading(true);
        const response = await payrollDashboardApi.getPayrollSummaryByYear(filters);
        const datasets = [
          {
            label: 'Total',
            data: response?.data?.length > 0 ? response?.data?.map((i: any) => i?.total) : [],
            fill: false,
            backgroundColor: '#124EDB',
            borderColor: '#124EDB',
          },
          // {
          //   label: 'Part time',
          //   data:
          //     response?.data?.parttime?.totalPayroll !== 0
          //       ? response?.data?.parttime?.totalPayroll
          //       : [10, 22, 40, 50, 35, 45, 4, 70, 10, 50, 10, 65],
          //   fill: false,
          //   backgroundColor: '#F68340',
          //   borderColor: '#F68340',
          // },
        ];
        setIsLoading(false);
        setDataChart((prevState) => ({
          ...prevState,
          labels,
          datasets,
        }));
      } catch (error) {
        setIsLoading(false);
      }
    }

    fetchReportEmployeeGrowth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const handleSubmit = ({ year }: EmployeeGrowthPayload) => {
    const formattedYear = moment(year).format('YYYY');

    setFilters((prevFilters) => ({
      ...prevFilters,
      year: formattedYear,
    }));
  };

  return (
    <StyledContainer>
      <StyledWrapper>
        <StyledHeaderTitle>Payroll Summary</StyledHeaderTitle>

        <PayrollSummaryFilters
          initialValues={{ year: moment(new Date(filters.year)) }}
          loading={isLoading}
          onSubmit={handleSubmit}
        />
      </StyledWrapper>

      <PayrollSummaryChart loading={isLoading} dataChart={dataChart} />
    </StyledContainer>
  );
}

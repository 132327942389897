import { Table, TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction } from 'react';
import styled, { css } from 'styled-components';

import EmployeeColumn from 'components/EmployeeColumn';
import ITVTable from 'components/ITVTable';
import { MONTH_NAMES } from 'constants/commons';
import { PayrollYear } from 'types';
import { formatCurrencyUnitVND } from 'features/insurances/constants/common';
import { PayrollByYearTotalRow } from './PayrollByYearTotalRow';
import { DownTrendIcon, UpTrendIcon } from 'assets/icons';
import MaskedValueRenderer from 'components/MaskedValueRenderer';

interface PayrollByYearListProps {
  employeePayrollList: PayrollYear[];
  totalResult: number;
  loading?: boolean;
  page: number;
  limit: number;
  setPage: Dispatch<SetStateAction<number>>;
  setLimit: Dispatch<SetStateAction<number>>;
  onTableChange?: (
    pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue | null>,
    sorter: SorterResult<any>,
  ) => void;
}

const StyledWrapperTable = styled.div`
  .ant-table-summary {
    background: #d8fff3 !important;
  }
  .ant-table-summary tr td:first-child {
    background: #d8fff3 !important;
  }
`;

export function PayrollByYearList({
  loading,
  employeePayrollList,
  totalResult,
  page,
  limit,
  setPage,
  setLimit,
  onTableChange,
}: PayrollByYearListProps) {
  const { t } = useTranslation(['employee', 'payroll', 'common']);

  const monthColumnList = MONTH_NAMES.map((_, index) => ({
    title: t(`common:month.${index}.name`),
    children: [
      {
        title: t('payroll:company_cost'),
        dataIndex: 'array',
        key: 'array',
        align: 'right',
        width: 150,
        minWidth: 150,
        render: (data: any) => {
          const companyCost = data?.[index]?.dataPayroll?.totalSalaryCost || 0;
          return (
            <div
              style={{
                display: 'flex',
                gap: '4px',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {data?.[index]?.changeDirectionCost === 'up' ? (
                <>
                  <UpTrendIcon />
                  <StyledPercentage showUpTrendIcon={true} showDownTrendIcon={false}>
                    <MaskedValueRenderer value={companyCost} /> VNĐ
                  </StyledPercentage>
                </>
              ) : data?.[index]?.changeDirectionCost === 'down' ? (
                <>
                  <DownTrendIcon />
                  <StyledPercentage showUpTrendIcon={false} showDownTrendIcon={true}>
                    <MaskedValueRenderer value={companyCost} /> VNĐ
                  </StyledPercentage>
                </>
              ) : (
                <>
                  <MaskedValueRenderer value={companyCost} /> VNĐ
                </>
              )}
            </div>
          );
        },
      },
      {
        title: t('payroll:net_pay'),
        dataIndex: 'array',
        key: 'array',
        align: 'right',
        ellipsis: true,
        width: 150,
        minWidth: 150,
        render: (data: any) => {
          const netPay = data?.[index]?.dataPayroll?.actualReceiveSalary || 0;
          return (
            <div
              style={{
                display: 'flex',
                gap: '4px',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {data?.[index]?.changeDirectionSalary === 'up' ? (
                <>
                  <UpTrendIcon />
                  <StyledPercentage showUpTrendIcon={true} showDownTrendIcon={false}>
                    <MaskedValueRenderer value={netPay} /> VNĐ
                  </StyledPercentage>
                </>
              ) : data?.[index]?.changeDirectionSalary === 'down' ? (
                <>
                  <DownTrendIcon />
                  <StyledPercentage showUpTrendIcon={false} showDownTrendIcon={true}>
                    <MaskedValueRenderer value={netPay} /> VNĐ
                  </StyledPercentage>
                </>
              ) : (
                <>
                  <MaskedValueRenderer value={netPay} /> VNĐ
                </>
              )}
            </div>
          );
        },
      },
    ],
  }));

  const cumulativeTotalColumn = {
    title: t('payroll:cumulative_total'),
    children: [
      {
        title: t('payroll:company_cost'),
        dataIndex: 'array',
        key: 'array',
        align: 'right',
        width: 150,
        minWidth: 150,
        render: (data: any) => {
          const totalCompanyCost12Months = data?.reduce((acc: number, month: any) => {
            return acc + (month?.dataPayroll?.totalSalaryCost || 0);
          }, 0);

          return (
            <div>
              <MaskedValueRenderer value={totalCompanyCost12Months} /> VNĐ
            </div>
          );
        },
      },
      {
        title: t('payroll:net_pay'),
        dataIndex: 'array',
        key: 'array',
        align: 'right',
        ellipsis: true,
        width: 150,
        minWidth: 150,
        render: (data: any) => {
          const totalNetPay12Months = data?.reduce((acc: number, month: any) => {
            return acc + (month?.dataPayroll?.actualReceiveSalary || 0);
          }, 0);

          return (
            <div>
              <MaskedValueRenderer value={totalNetPay12Months} /> VNĐ
            </div>
          );
        },
      },
    ],
  };

  const COLUMNS: any = [
    {
      title: 'ID',
      dataIndex: 'employee_id',
      key: 'employee_id',
      align: 'center',
      width: 70,
      minWidth: 70,
      ellipsis: true,
      sorter: true,
      fixed: 'left',
      render: (text: any) => <div>{text}</div>,
    },
    {
      title: t('Employee'),
      dataIndex: 'full_name',
      key: 'full_name',
      width: 220,
      minWidth: 220,
      ellipsis: true,
      sorter: true,
      fixed: 'left',
      render: function (text: any, record: any) {
        return (
          <EmployeeColumn
            avatar={record?.full_name}
            fullName={record?.full_name}
            email={record?.email}
          />
        );
      },
    },
    ...monthColumnList,
    cumulativeTotalColumn,
  ];

  return (
    <StyledWrapperTable>
      <ITVTable
        columns={COLUMNS}
        data={employeePayrollList}
        totalResult={totalResult}
        loading={loading}
        rowKey={['employee_id']}
        setSelectedUser={() => {}}
        setOffset={setPage}
        offset={page}
        setLimit={setLimit}
        limit={limit}
        onChange={onTableChange}
        summary={() => (
          <Table.Summary fixed="top">
            <PayrollByYearTotalRow employeePayrollList={employeePayrollList} />
          </Table.Summary>
        )}
      />
    </StyledWrapperTable>
  );
}

interface StyledPercentageProps {
  showUpTrendIcon?: boolean;
  showDownTrendIcon?: boolean;
}

const StyledPercentage = styled.div`
  ${(props: StyledPercentageProps) => {
    return css`
      color: ${props.showUpTrendIcon && 'var(--cl_success500)'};
      color: ${props.showDownTrendIcon && 'var(--cl_error500)'};
    `;
  }}
`;

import {
  ApproveRequestContractPayload,
  ContractDetail,
  ContractTypeListResponse,
  IEmployeeContract,
  ListParams,
  OTPVerifyContractPayload,
  RequestContractDetail,
  SendOTPContractPayload,
  SuccessResponse,
} from 'types';
import axiosClient from './axiosClient';
import { notification } from 'antd';

const employeeContractApi = {
  getAll(
    params: Partial<any>,
  ): Promise<SuccessResponse<{ data: IEmployeeContract[]; total: number }>> {
    return axiosClient.post('/contract-employee/search', {
      ...params,
    });
  },

  get(contractId: string): Promise<SuccessResponse<{ data: ContractDetail[] }>> {
    return axiosClient.get(`/contract-employee/details/${contractId}`);
  },

  add(payload: FormData): Promise<SuccessResponse<any>> {
    return axiosClient.post('/contract-employee', payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  update(payload: FormData): Promise<SuccessResponse<any>> {
    return axiosClient.patch(`/contract-employee/${payload.get('id')}`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  getTypeList(): Promise<SuccessResponse<ContractTypeListResponse>> {
    return axiosClient.get('/configurations/config-type/contract_type');
  },

  sendOTPViewContractDocument(
    payload: SendOTPContractPayload,
  ): Promise<SuccessResponse<{ id: string; userId: string }>> {
    return axiosClient.post('/user-otp/otp-upload', payload);
  },

  verifyOTPGetContractDocument(
    payload: OTPVerifyContractPayload,
  ): Promise<SuccessResponse<string>> {
    return axiosClient.post('/user-otp/verify-otp-contract', payload);
  },

  approveRequestContract(
    payload: ApproveRequestContractPayload,
  ): Promise<
    SuccessResponse<{ data: RequestContractDetail; message: string; statusCode: number }>
  > {
    return axiosClient.post('/contract-employee/token', payload);
  },

  approveOTPContract(
    payload: ApproveRequestContractPayload,
  ): Promise<SuccessResponse<{ data: any; message: string; statusCode: number }>> {
    return axiosClient.post('/user-otp/approved-rejected-otp', payload);
  },

  checkViewAdmin(contractId: string): Promise<SuccessResponse<any>> {
    return axiosClient
      .post('/user-otp/view-admin', {
        contractId,
      })
      .then((response: any) => {
        notification.destroy();
        return response;
      });
  },

  exportEmployeeContract(filters: Partial<ListParams>, userIds: string[]): Promise<any> {
    return axiosClient.post(
      '/contract-employee/export',
      {
        ...filters,
        userIds,
      },
      {
        responseType: 'blob',
      },
    );
  },

  getEmployeeContractByFilter(
    params: any,
  ): Promise<SuccessResponse<{ data: IEmployeeContract[]; total: number }>> {
    return axiosClient.post('/contract-employee/search', {
      ...params,
    });
  },
};

export default employeeContractApi;

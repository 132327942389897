import React from 'react';
import { Dropdown, Button, Menu, Popconfirm, MenuItemProps } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

export interface ItemMenuProps extends MenuItemProps {
  type?: string;
  checkDisabled?: (...props: any) => boolean;
  name?: React.ReactNode;
  [propName: string]: any;
}
export interface MenuActionProps {
  menu: Array<ItemMenuProps>;
  data?: any;
  [propName: string]: any;
}

const MenuAction = (props: MenuActionProps) => {
  const { t } = useTranslation(['timesheet', 'onboard']);

  return (
    <Dropdown
      {...props}
      overlay={
        <Menu>
          {props.menu.map((item, index) => {
            if (item.type === 'delete') {
              return (
                <Popconfirm
                  key={index}
                  title={t('onboard:are_you_sure_to_delete_this')}
                  onConfirm={() => {
                    item.handleClick && item.handleClick(props.data);
                  }}
                  okText={t('Yes')}
                  cancelText={t('No')}
                  placement="topRight"
                >
                  <Menu.Item
                    key={index}
                    icon={item.icon}
                    disabled={item.checkDisabled && item.checkDisabled(props.data)}
                    {...item}
                  >
                    {item.name}
                  </Menu.Item>
                </Popconfirm>
              );
            } else {
              return (
                <Menu.Item
                  key={index}
                  icon={item.icon}
                  disabled={item.checkDisabled && item.checkDisabled(props.data)}
                  onClick={() => item.handleClick && item.handleClick(props.data)}
                  {...item}
                >
                  {item.name}
                </Menu.Item>
              );
            }
          })}
        </Menu>
      }
      placement="bottomRight"
      trigger={['click']}
    >
      <Button
        type="link"
        icon={<EllipsisOutlined style={{ fontSize: '150%', color: '#111827' }} />}
      />
    </Dropdown>
  );
};

export default MenuAction;

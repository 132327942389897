import { DeleteTwoTone, EditTwoTone, PlusOutlined } from '@ant-design/icons';
import { Button, Form, InputNumber, Modal, Popconfirm } from 'antd';
import { useAppSelector } from 'app/hooks';
import ITVTable from 'components/ITVTable';
import MenuAction from 'components/MenuAction';
import {
  createPIT,
  deletePIT,
  getAllPIT,
  updatePIT,
} from 'features/configuration/configurationAction';
import { selectConfiguration } from 'features/configuration/configurationSlice';
import { formatCurrency } from 'features/insurances/constants/common';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import CustomButton from 'styles/buttonStyled';
import { ContainerBgWhite } from 'styles/containerBgWhite';
import ViewPIT from './ViewPIT';

enum MODE {
  VIEW = 'VIEW',
  ADD = 'ADD',
  UPDATE = 'UPDATE',
}

const SetupPIT = () => {
  const [form] = Form.useForm();
  const [sizePage, setSizePage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [mode, setMode] = useState<string>('');
  const [currentItem, setCurrentItem] = useState<any>(null);
  const { t } = useTranslation([
    'menu',
    'action',
    'overtime',
    'timesheet',
    'insurance',
    'masterConfig',
    'payroll',
    'configuration',
  ]);
  const dataStore = useAppSelector(selectConfiguration);
  const dataPIT = dataStore?.dataPIT?.result;
  const dispatch = useDispatch();
  const [data, setData] = useState([]); //isProbationary=false thì nằm table, true thì nằm input

  useEffect(() => {
    dispatch(getAllPIT({}));
  }, [dispatch]);

  useEffect(() => {
    if (dataPIT) {
      setData(dataPIT?.filter((item: any) => !item?.isProbationary));
      const probationaryStaff = dataPIT?.find((item: any) => item?.isProbationary);
      probationaryStaff &&
        form.setFieldsValue({
          taxRate: probationaryStaff?.taxRate,
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPIT]);

  const MENU = [
    {
      name: t('insurance:edit'),
      icon: <EditTwoTone twoToneColor="#F1592A" />,
      handleClick: (value: any) => {
        setCurrentItem(value);
        setMode(MODE.UPDATE);
      },
    },
    {
      name: t('action:delete'),
      icon: <DeleteTwoTone twoToneColor="#B7B7B7" />,
      type: 'delete',
      handleClick: (data: any) => {
        dispatch(
          deletePIT({
            data,
            callBack: () => {
              dispatch(getAllPIT({}));
            },
          }),
        );
      },
    },
  ];
  const COLUMNS: any = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      width: 60,
      minWidth: 60,
      align: 'center',
      render: (text: any, record: any, index: any) => {
        const trueIndex = index + sizePage * (currentPage - 1);
        return trueIndex + 1;
      },
    },
    {
      title: t('payroll:pit_per_month'),
      dataIndex: 'tax',
      key: 'tax',
      render: (text: any, record: any, index: any) => {
        return (
          `${formatCurrency(record?.minValue || 0)}` +
          (record?.maxValue ? ' - ' + formatCurrency(record?.maxValue) : '')
        );
      },
    },
    {
      title: `${t('payroll:taxRate')} (%)`,
      dataIndex: 'taxRate',
      key: 'taxRate',
      width: 100,
      minWidth: 100,
      align: 'right',
    },
    {
      title: t('action:action'),
      dataIndex: 'action',
      align: 'center',
      key: 'action',
      width: 100,
      minWidth: 100,
      render: function (text: any, record: any, index: any) {
        return <MenuAction menu={MENU} data={record} />;
      },
    },
  ];

  const onSave = (values: any) => {
    if (mode === MODE.ADD) {
      dispatch(
        createPIT({
          values,
          callBack: () => {
            dispatch(getAllPIT({}));
            setMode('');
            setCurrentItem(null);
          },
        }),
      );
    }
    if (mode === MODE.UPDATE) {
      dispatch(
        updatePIT({
          values,
          callBack: () => {
            dispatch(getAllPIT({}));
            setMode('');
            setCurrentItem(null);
          },
        }),
      );
    }
  };

  return (
    <Container>
      <ContainerBgWhite style={{ marginTop: '20px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <div style={{ fontWeight: 'bold', fontSize: 16 }}>
              {t('payroll:fulltime_employees')}{' '}
            </div>
          </div>
          <CustomButton
            style={{ marginBottom: 15 }}
            onClick={() => {
              setMode(MODE.ADD);
            }}
          >
            <PlusOutlined /> {t('action:add_new')}
          </CustomButton>
        </div>
        <ITVTable
          isRowSelect={false}
          columns={COLUMNS}
          data={data}
          width={1080}
          height={550}
          setOffset={setCurrentPage}
          offset={currentPage}
          setLimit={setSizePage}
          limit={sizePage}
        />
        <Form form={form} style={{ marginTop: 15 }}>
          <div style={{ fontWeight: 'bold', fontSize: 16 }}>{t('payroll:probStaff')} </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {t('payroll:pitProb')}:
              <Form.Item name={'taxRate'} style={{ marginLeft: 5 }}>
                <CustomInputNumber
                  defaultValue={0}
                  min={0}
                  max={100}
                  formatter={(value: any) => `${value}`}
                  parser={(value: any) => (value ? value.replace('%', '') : 0)}
                />
              </Form.Item>
              <span style={{ marginLeft: 10 }}>{t('payroll:taxRate')} (%)</span>
            </div>
            <Popconfirm
              title={`${t('insurance:Are_you_sure_confirm_this_request')}`}
              onConfirm={() => {
                form.validateFields().then((vals) => {
                  dispatch(
                    updatePIT({
                      values: {
                        minValue: 0,
                        maxValue: 0,
                        taxRate: vals?.taxRate || 0,
                        isProbationary: true,
                        id: 0, //any number
                      },
                      callBack: () => {
                        setMode('');
                        setCurrentItem(null);
                      },
                    }),
                  );
                });
              }}
              okText={`${t('insurance:Yes')}`}
              cancelText={`${t('insurance:no')}`}
            >
              <CustomButton>{t('masterConfig:action.save')}</CustomButton>
            </Popconfirm>
          </div>
          <div style={{ fontWeight: 'bold', fontSize: 16 }}>{t('payroll:titleNonResident')}</div>
          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
            {t('payroll:pitProb')}:
            <Form.Item name={'taxRate'} style={{ marginLeft: 5 }}>
              <CustomInputNumber
                defaultValue={0}
                min={0}
                max={100}
                formatter={(value: any) => `${20}`}
              />
            </Form.Item>
            <span style={{ marginLeft: 10 }}>{t('payroll:taxRate')} (%)</span>
          </div>
        </Form>
      </ContainerBgWhite>
      {mode !== '' && (
        <Modal
          visible={mode !== ''}
          title={
            mode === MODE.UPDATE
              ? `${t('action:edit')} ${t('configuration:payroll.set_up_pit')}`
              : `${t('action:add_new')} ${t('configuration:payroll.set_up_pit')}`
          }
          okText={t('timesheet:ok')}
          cancelText={t('timesheet:Cancel')}
          width={850}
          maskClosable={false}
          onCancel={() => {
            setMode('');
            setCurrentItem(null);
          }}
          footer={null}
        >
          <ViewPIT current={currentItem} onSave={onSave} mode={mode} />
        </Modal>
      )}
    </Container>
  );
};

export default SetupPIT;
export const SearchContainer = styled.div`
  width: 200px;
  margin-bottom: 20px;
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: max-content;
`;
export const ContainerLeft = styled.div`
  display: flex;
`;

export const ContainerRight = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;
export const HeaderRemove = styled(Button)`
  font-size: var(--txt_font_size_body);
  border-radius: var(--bd_radius_default);
  img {
    padding-bottom: 6px;
  }
`;
const CustomInputNumber = styled(InputNumber)`
  .ant-input-number-input {
    text-align: right;
  }
  .ant-input-number-handler-wrap {
    padding-right: 5px;
  }
  .ant-input-number-handler-wrap {
    display: none;
  }
`;

import { useEffect, useState } from 'react';

export const useViewport = () => {
  const [widthDevice, setWidthDevice] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => setWidthDevice(window.innerWidth);
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  // Return the width so we can use it in our components
  return { widthDevice };
};

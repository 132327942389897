import { ArrowRightOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { Form, Popconfirm, Select } from 'antd';

import { Button, Space } from 'antd';
import { useAppSelector } from 'app/hooks';
import { notificationToast } from 'components/notificationToast';
import { Notification } from 'constants/notification';
import { MODE } from 'constants/types';
import { getFindOneAction, getFindOneStatus } from 'features/configuration/configurationAction';
import { selectConfiguration } from 'features/configuration/configurationSlice';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  Background,
  ConnectionLineType,
  Controls,
  MarkerType,
  ReactFlowProvider,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
} from 'react-flow-renderer';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import CustomButton from 'styles/buttonStyled';
import CustomInput from 'styles/inputStyled';
import {
  CustomCard,
  CustomDrawer,
  CustomFormItem,
  CustomFormItem1,
  CustomModal,
  CustomReactFlow,
  CustomTag,
} from '../styled';
import ButtonEdge from './ButtonEdge';
import ModalNodeEdit from './ModalNodeEdit';
import SmartEdge from './SmartEdge';
import StateNode from './StateNode';
import './indexAddEditWL.css';

const { Option } = Select;

const edgeTypes = { buttonedge: ButtonEdge, smart: SmartEdge };
const nodeTypes = { state: StateNode };
const initialEdges = [
  {
    id: '0',
    source: '0', //1
    target: '1',
    label: 'Send payroll',
    type: 'smart',
    markerEnd: { type: MarkerType.Arrow },
  },
  {
    id: '1',
    source: '1',
    target: '2',
    label: 'Approve',
    className: 'normal-edge',
    type: 'smart',
    markerEnd: { type: MarkerType.Arrow },
  },
  {
    id: '2',
    source: '2',
    target: '1',
    label: 'Approve eeeeeeeeee',
    className: 'normal-edge',
    type: 'smart',
    markerEnd: { type: MarkerType.Arrow },
  },
];

const labelName = [
  { id: 1, name: 'PRL_WF_CREATE_1' },
  { id: 2, name: 'PRL_WF_CREATE_2' },
  { id: 3, name: 'PRL_WF_CREATE_3' },
  { id: 4, name: 'PRL_WF_CREATE_4' },
  { id: 5, name: 'PRL_WF_REQUEST1' },
  { id: 6, name: 'PRL_WF_CREATE_2' },
  { id: 7, name: 'PRL_WF_CREATE_3' },
  { id: 8, name: 'PRL_WF_CREATE_4' },
  { id: 9, name: 'PRL_WF_CONFIRM_1' },
  { id: 10, name: 'PRL_WF_CONFIRM_2' },
  { id: 11, name: 'PRL_WF_CONFIRM_3' },
  { id: 12, name: 'PRL_WF_CONFIRM_4' },
];

const Workflow2Edit = (props: any) => {
  const {
    setNodes,
    setEdges,
    edges,
    nodes,
    selectStatus,
    selectAction,
    currentItem,
    modalType,
    findActionIdOfEdge,
    findStatusOfNode,
    findEmployeesOfNodeOpen,
    formAction,
  } = props;

  const reactFlowWrapper = useRef(null);
  const { t } = useTranslation([
    'validation',
    'overtime',
    'action',
    'onboard',
    'validation',
    'timesheet',
  ]);

  let arrayIdNode: any =
    nodes &&
    nodes?.map((item, idx) => {
      return item?.id;
    });

  let idNodeMax = arrayIdNode && Math.max(...arrayIdNode);
  const getId = () => `${++idNodeMax}`;
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [visible, setVisible] = useState(false);
  const [visibleAction, setVisibleAction] = useState(false);
  const [formNode] = Form.useForm();
  const [formNameProcess] = Form.useForm();
  const [captureElementClick] = useState(true);
  const [captureEdgeClick] = useState(true);
  const [valueNode, setValueNode] = useState('');
  const [valueEdge, setValueEdge] = useState('');
  const [edgenextValueNode, setEdgeNextValueNode] = useState([]);
  const [edgePrevValueNode, setEdgePrevValueNode] = useState([]);
  const [valueAction, setValueAction] = useState('');
  const [fromNode, setFromNode] = useState<any[]>([]);
  const [toNode, setToNode] = useState({});
  const [newNode, setNewNode] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openKeys, setOpenKeys] = useState(['sub0', 'sub1', 'sub2']);
  const dataStore = useAppSelector(selectConfiguration);
  const dataFindOneAction = selectAction !== undefined ? dataStore?.dataGroupActionAdd : [];
  const [errMessageAction, setErrMessageAction] = useState();
  const [errMessageStatusNode, setErrMessageStatusNode] = useState();
  const [errMessageDesNode, setErrMessageDesNode] = useState();

  const dispatch = useDispatch();

  const handleNodesChange = useCallback((changes: any) => {
    nodes &&
      setNodes((ns) => {
        return ns && applyNodeChanges(changes, ns);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEdgesChange = useCallback((changes: any) => {
    setEdges((es) => applyEdgeChanges(changes, es));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleConnect = useCallback((params) => {
    if (params?.source !== '0') {
      if (params?.source === '1' && params?.target === '2') {
        notificationToast(
          Notification.Type.Error,
          'You cannot drag from the open node to end node',
          Notification.Duration._3s,
        );
      } else {
        if (params?.source === params?.target && params?.sourceHandle === params?.targetHandle) {
          notificationToast('error', 'You cannot drag to your own point', 3);
        } else {
          setEdges((eds) => {
            return addEdge(
              {
                ...params,
                type: 'buttonedge',
                labelStyle: { fill: '#fff', fontWeight: 500 },
                labelBgPadding: [8, 4],
                labelBgBorderRadius: 8,
                labelBgStyle: { fill: '#FFCC00', color: '#fff' },
                markerEnd: { type: MarkerType.ArrowClosed, color: 'black' },
                style: { strokeWidth: '2px' },
              },
              eds,
            );
          });
        }
      }
    } else {
      notificationToast(
        Notification.Type.Error,
        'You cannot drag from the start node',
        Notification.Duration._3s,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
    formNameProcess.resetFields();
  };

  const handleOk = () => {
    let CheckName = nodes?.map((item, idx) => {
      return item?.data?.label.trim();
    });

    formNameProcess.validateFields().then((values: any) => {
      if (CheckName.includes(values?.nameProcess.trim())) {
        formNameProcess.setFields([
          {
            name: 'nameProcess',
            errors: ['Already exists. Try a different name.'],
          },
        ]);
      } else {
        setNodes((nds: any) => nds.concat({ ...newNode, data: { label: values?.nameProcess } }));
        setIsModalOpen(false);
      }
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.effectAllowed = 'move';
  };
  const handleDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const handleDrop = useCallback(
    (event) => {
      event.preventDefault();

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const type = event.dataTransfer.getData('application/reactflow');

      // check if the dropped element is valid
      if (typeof type === 'undefined' || !type) {
        return;
      }

      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });

      const newNode = {
        id: getId(),
        // type,
        position,
        data: { label: `${type} node` },
        type: 'state',
        style: {
          color: '#333',
          fontWeight: 700,
          fontSize: '16px',
        },
        targetPosition: 'left',
        sourcePosition: 'right',
      };
      setNewNode(newNode);
      showModal();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reactFlowInstance],
  );

  const onClose = () => {
    setVisible(false);
    setEdgePrevValueNode([]);
    setEdgeNextValueNode([]);
    setNodes((nds: any) =>
      nds.map((node: any) => {
        if (node?.id !== '0' && node?.id !== '2') {
          return {
            ...node,
            style: {
              ...node.style,
              border: '1px solid #222138',
              color: '#333',
              fontSize: '16px',
              fontWeight: 700,
            },
          };
        } else {
          return { ...node };
        }
      }),
    );
  };
  const onCloseAction = () => {
    setVisibleAction(false);
    setEdgePrevValueNode([]);
    setEdgeNextValueNode([]);
    setEdges((edgs) =>
      edgs.map((edgeId) => {
        if (edgeId?.id !== '0') {
          return {
            ...edgeId,
            style: {
              stroke: '#555',
              fontWeight: 700,
              fontSize: '16px',
            },
          };
        } else {
          return { ...edgeId };
        }
      }),
    );
  };
  const handleFormChange = () => {};
  const onNodeClick = (event: any, node: any) => {
    // if (node?.data?.status === undefined) {
    //   formNode.resetFields(['status']);
    // }
    if (node?.style?.background === undefined) {
      formNode.resetFields(['colorNode']);
    }
    const edgeNext = edges?.filter((item) => {
      return item.source === node.id;
    });
    const edgePrev = edges?.filter((item) => {
      return item.target === node.id;
    });

    setEdgeNextValueNode(edgeNext);
    setEdgePrevValueNode(edgePrev);

    if (node?.data?.label !== 'Start' && node?.data?.label !== 'End') {
      setVisible(true);
    }
    setValueNode(node);

    formNode.setFieldsValue({
      nameValue: node?.data.label,
      desValue: node?.data.label,
    });
    setErrMessageDesNode(undefined);

    setErrMessageStatusNode(undefined);
    setNodes((nds: any) =>
      nds.map((nodeId: any) => {
        if (node.id === nodeId.id && nodeId?.id !== '0' && nodeId?.id !== '2') {
          return {
            ...node,
            style: {
              background: node?.style?.background,
              border: '2px solid #FE753E',
              color: '#333',
              fontSize: '16px',
              fontWeight: 700,
            },
          };
        } else {
          return {
            ...nodeId,
          };
        }
      }),
    );
  };
  const onEdgeClick = (event: any, edge: any) => {
    if (edge?.actionId === undefined) {
      formAction.resetFields(['selectAction']);
    }
    const from = nodes?.find((item) => {
      return edge.source === item.id;
    });
    setFromNode(from);
    const to = nodes?.find((item) => {
      return edge.target === item.id;
    });
    formAction.setFieldsValue({
      selectAction: edge.actionId?.id || edge.actionId,
      selectAction1: edge.actionId?.id || edge.actionId,
    });
    setToNode(to);
    setValueEdge(edge);
    setValueAction(edge.actionId?.id || edge.actionId);
    edge?.id !== '0' && setVisibleAction(true);
    setErrMessageAction(undefined);
    setEdges((edgs) =>
      edgs.map((edgeId) => {
        if (edge.id === edgeId.id && edgeId?.id !== '0') {
          return {
            ...edgeId,
            style: {
              stroke: '#FE753E',
              fontWeight: 700,
              fontSize: '16px',
            },
          };
        } else {
          return { ...edgeId };
        }
      }),
    );
  };

  const onSave = (dataSelecListEmployee) => {
    if (!errMessageStatusNode && !errMessageDesNode) {
      formNode
        .validateFields()
        .then((values: any) => {
          setNodes((nds: any) => {
            return nds.map((node: any) => {
              if (valueNode.id === node.id) {
                return {
                  ...node,
                  data: {
                    ...node.data,
                    label: values.desValue,
                    status: values.status,
                    employees: dataSelecListEmployee,
                    // statusName: values.statusName,
                  },
                  style: {
                    background: values.colorNode,
                    color: '#333',
                    border: '1px solid #222138',
                    fontWeight: 700,
                    fontSize: '16px',
                  },
                  // deleteFlag: values.desValue === 'A1' ? true : false,
                };
              } else {
                return {
                  ...node,
                  data: {
                    ...node.data,
                    status: node?.data?.status?.id || node?.data?.status,
                    employees: node?.data?.employees,
                  },
                };
              }
            });
          });

          setEdges((edgs) =>
            edgs.map((edge) => {
              return { ...edge, actionId: edge?.actionId?.id || edge?.actionId };
            }),
          );
          setVisible(false);
        })
        .catch((error) => {
          let err = error?.errorFields
            ?.map((item) => {
              return item?.name;
            })
            .flat(Infinity);
          setOpenKeys([
            err[0] === 'desValue' || err[1] || err[0] === 'status' ? 'sub0' : '',
            err[2] || err[0] === 'listEmployee' || err[2] || err[1] === 'listEmployee'
              ? 'sub1'
              : '',
          ]);
        });
    } else {
      setOpenKeys(['sub0']);
    }
  };
  const onSaveAction = () => {
    if (!errMessageAction) {
      formAction.validateFields().then((values: any) => {
        let nameAction = dataFindOneAction?.actionDetails?.find((item) => {
          return item.id === valueAction;
        });
        setNodes((nds: any) => {
          return nds.map((node: any) => {
            return {
              ...node,
              data: {
                ...node.data,
                status: node?.data?.status?.id || node?.data?.status,
                employees: node?.data?.employees,
              },
            };
          });
        });
        setEdges((edgs) =>
          edgs.map((edge) => {
            if (valueEdge.id === edge.id) {
              return {
                ...edge,
                label: nameAction?.actionName,
                actionId: valueAction,
                style: {
                  stroke: '#555',
                  fontWeight: 700,
                  fontSize: '16px',
                },
              };
            } else {
              if (modalType === MODE.EDIT) {
                return { ...edge, actionId: edge?.actionId?.id || edge?.actionId };
              }
              return edge;
            }
          }),
        );
        setVisibleAction(false);
      });
    }
  };

  const [loading, setLoading] = useState(false);

  const loadMoreData = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    setLoading(false);
  };
  useEffect(() => {
    loadMoreData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleSelectAction = (value: string) => {
    let checkDuplicateAction = edges
      ?.filter((item) => item?.id !== valueEdge?.id)
      ?.find((item: any) => {
        return (item?.actionId?.id || item?.actionId) === value;
      });

    if (!checkDuplicateAction) {
      setValueAction(value);
      setErrMessageAction(undefined);
    } else {
      setErrMessageAction(checkDuplicateAction);
    }
  };

  const onDelete = () => {
    setVisible(false);
    setEdgePrevValueNode([]);
    setEdgeNextValueNode([]);
    setNodes((nds: any) => nds.filter((node: any) => node.id !== valueNode?.id));
    setEdges((edges: any) => edges.filter((edge: any) => edge.source !== valueNode?.id)); // delete edge prev
    setEdges((edges: any) => edges.filter((edge: any) => edge.target !== valueNode?.id)); // delete edge next
  };
  const onDeleteEdge = () => {
    setVisibleAction(false);
    setEdgePrevValueNode([]);
    setEdgeNextValueNode([]);
    setEdges((edges: any) => edges.filter((edge: any) => edge.id !== valueEdge?.id));
  };

  useEffect(() => {
    if (selectStatus !== undefined) {
      dispatch(getFindOneStatus({ id: selectStatus }));
    }
    if (selectStatus !== currentItem?.workflowTypeDetail?.[0]?.statusGroup?.id) {
      formNode.setFieldsValue({
        status: undefined,
      });
      setNodes((nds: any) =>
        nds?.map((node: any) => {
          return {
            ...node,
            data: {
              ...node.data,
              status: undefined,
            },
          };
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectStatus]);

  useEffect(() => {
    if (selectAction !== undefined) {
      if (selectAction === currentItem?.workflowTypeDetail?.[0]?.actionGroup?.id) {
        dispatch(getFindOneAction({ id: selectAction }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectAction]);

  //Error
  useEffect(() => {
    for (let i = 0; i <= edges?.length; i++) {
      for (let j = 0; j < findActionIdOfEdge?.length; j++) {
        if (edges[i]?.id === findActionIdOfEdge[j]?.id) {
          setEdges((edgs) =>
            edgs.map((edge) => {
              if (findActionIdOfEdge[j]?.id === edge.id) {
                return { ...edge, style: { stroke: 'red', fontWeight: 700, fontSize: '16px' } };
              } else {
                return edge;
              }
            }),
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [findActionIdOfEdge]);

  useEffect(() => {
    for (let i = 0; i <= nodes?.length; i++) {
      for (let j = 0; j < findStatusOfNode?.length; j++) {
        if (nodes[i]?.id === findStatusOfNode[j]?.id) {
          setNodes((nds: any) => {
            return nds.map((node: any) => {
              if (findStatusOfNode[j]?.id === node.id) {
                return {
                  ...node,
                  data: {
                    ...node.data,
                  },
                  style: { border: '1px solid red', fontWeight: 700, fontSize: '16px' },
                };
              } else {
                return node;
              }
            });
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [findStatusOfNode]);

  useEffect(() => {
    for (let i = 0; i <= nodes?.length; i++) {
      for (let j = 0; j < findEmployeesOfNodeOpen?.length; j++) {
        if (nodes[i]?.id === findEmployeesOfNodeOpen[j]?.id) {
          setNodes((nds: any) => {
            return nds.map((node: any) => {
              if (findEmployeesOfNodeOpen[j]?.id === node.id) {
                return {
                  ...node,
                  data: {
                    ...node.data,
                  },
                  style: { border: '1px solid red', fontWeight: 700, fontSize: '16px' },
                };
              } else {
                return node;
              }
            });
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [findEmployeesOfNodeOpen]);

  const onEdgeMouseEnter = (event, edge) => {
    let parentElement = document.getElementsByClassName('react-flow__edges');
    let g = parentElement?.[0].childNodes[1];
    let arrayG = parentElement[0].childNodes[1].childNodes;
    arrayG.forEach((rect) => {
      rect.addEventListener('mouseenter', (e) => {
        g.appendChild(rect);
      });
    });
  };

  return (
    <>
      <div
        className="site-drawer-render-in-current-wrapper"
        style={{ display: 'flex', height: '100%', width: '100%', position: 'relative' }}
      >
        <div className="dndflow">
          <ReactFlowProvider>
            <aside>
              <div
                className="dndnode"
                // onDragStart={(event) => onDragStart(event, 'default')}
                onDragStart={(event) => onDragStart(event, 'default')}
                draggable
              >
                <PlusSquareOutlined style={{ fontSize: '22px' }} />
                <span style={{ fontSize: '18px' }}>To-do status</span>
              </div>
            </aside>
            <div className="reactflow-wrapper" ref={reactFlowWrapper}>
              <CustomReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={handleNodesChange}
                onEdgesChange={handleEdgesChange}
                onConnect={handleConnect}
                onInit={setReactFlowInstance}
                onNodeClick={captureElementClick ? onNodeClick : undefined}
                onEdgeClick={captureEdgeClick && onEdgeClick}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                fitView
                edgeTypes={edgeTypes}
                deleteKeyCode={null}
                // style={rfStyle}
                nodeTypes={nodeTypes}
                connectionLineType={ConnectionLineType.SmoothStep}
                connectionMode="loose"
                onEdgeMouseEnter={onEdgeMouseEnter}
              >
                <Background />
                <Controls />
              </CustomReactFlow>
            </div>
          </ReactFlowProvider>
        </div>

        {/* Modal node */}

        <ModalNodeEdit
          labelName={labelName}
          form={formNode}
          edgenextValueNode={edgenextValueNode}
          edgePrevValueNode={edgePrevValueNode}
          valueNode={valueNode}
          initialEdges={initialEdges}
          nodes={nodes}
          loadMoreData={loadMoreData}
          currentItem={currentItem}
          modalType={modalType}
          selectStatus={selectStatus}
          visibleAction={visibleAction}
          openKeys={openKeys}
          setOpenKeys={setOpenKeys}
          errMessageStatusNode={errMessageStatusNode}
          setErrMessageStatusNode={setErrMessageStatusNode}
          errMessageDesNode={errMessageDesNode}
          setErrMessageDesNode={setErrMessageDesNode}
          onSave={onSave}
          onClose={onClose}
          visible={visible}
          onDelete={onDelete}
        />

        {/* Modal action */}
        <CustomDrawer
          title="Transition"
          placement="right"
          closable={false}
          onClose={onCloseAction}
          visible={visibleAction}
          getContainer={false}
          extra={
            <Space>
              <Button onClick={onCloseAction}>Cancel</Button>
              <Popconfirm
                title={`Are you sure`}
                onConfirm={() => {
                  onSaveAction();
                }}
                okText={`Yes`}
                cancelText={`No`}
              >
                <CustomButton>{`Save`}</CustomButton>
              </Popconfirm>
              <Popconfirm
                title={`Are you sure`}
                onConfirm={() => {
                  onDeleteEdge();
                }}
                okText={`Yes`}
                cancelText={`No`}
              >
                <CustomButton>{`Delete`}</CustomButton>
              </Popconfirm>
            </Space>
          }
          style={{ position: 'absolute' }}
        >
          <Form form={formAction} onFieldsChange={handleFormChange} layout="vertical">
            {/* List action */}
            <Form.Item
              name="selectAction"
              label={<div>Name</div>}
              rules={[
                {
                  required: true,
                  message: t('HRM_VAD_REQUIRED', { field: 'Action' }),
                },
              ]}
              style={{ marginBottom: errMessageAction ? '0px' : '30px' }}
            >
              <Select onChange={handleSelectAction}>
                {dataFindOneAction?.actionDetails?.map((item: any) => {
                  return <Option value={item?.id}>{item?.actionName}</Option>;
                })}
              </Select>
            </Form.Item>
            {errMessageAction && (
              <p style={{ color: 'red', height: 50 }}>{errMessageAction?.label} is duplicate</p>
            )}
            <div>
              <CustomCard>
                <CustomTag color="green">{fromNode?.data?.label}</CustomTag>
                <ArrowRightOutlined />
                <CustomTag color="purple">{toNode?.data?.label}</CustomTag>
                <CustomFormItem name="selectAction1">
                  <Select disabled onChange={handleSelectAction}>
                    {dataFindOneAction?.actionDetails?.map((item: any) => {
                      return <Option value={item?.id}>{item?.actionName}</Option>;
                    })}
                  </Select>
                </CustomFormItem>
              </CustomCard>
            </div>
          </Form>
        </CustomDrawer>
      </div>

      {/* Modal input process */}
      <CustomModal
        title="Add a to-do status"
        cancelText={t('timesheet:Cancel')}
        okText={t('timesheet:Save')}
        visible={isModalOpen}
        onCancel={handleCancel}
        width={600}
        onOk={handleOk}
        // footer={null}
        maskClosable={false}
      >
        <p style={{ fontSize: '17px' }}>
          Statuses capture the stages of your working process. Issues in a to-do status represent
          work that hasn't started yet.
        </p>
        <p style={{ fontSize: '17px' }}>
          Add more to-do statuses to represent different stages in your team's planning process.
        </p>
        <Form form={formNameProcess} layout="vertical">
          <CustomFormItem1
            name={'nameProcess'}
            rules={[
              {
                required: true,
                message: t('HRM_VAD_REQUIRED', { field: 'Name Process' }),
              },
            ]}
          >
            <CustomInput placeholder={'Type to create'} maxLength={255} />
          </CustomFormItem1>
        </Form>
      </CustomModal>
    </>
  );
};

export default Workflow2Edit;

import {
  EditTwoTone,
  ExclamationCircleOutlined,
  EyeOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Form, Modal } from 'antd';
import { selectFormatDate } from 'app/commonRedux/appSlice';
import { useAppSelector } from 'app/hooks';
import { StatusColumn } from 'components/EmployeeList';
import ITVSearch from 'components/ITVSearch';
import ITVTable, { COLUMNS } from 'components/ITVTable';
import MenuAction from 'components/MenuAction';
import { notificationToast } from 'components/notificationToast';
import { PROFILE_STATUSES } from 'constants/profiles';
import { MODE } from 'constants/types';
import {
  createWorkflowType,
  getAllAction,
  getAllStatus,
  getAllWorkflowType,
  getBusinessWorkflowType,
  getCheckEditWorkflowByPayroll,
  updateWorkflowType,
} from 'features/configuration/configurationAction';
import {
  selectConfiguration,
  setEmptyFindOneWorkflowType,
} from 'features/configuration/configurationSlice';
import { Container } from 'features/leaveOff/components/LeaveManage';
import { HeaderAdd } from 'features/masterData/Department/styled';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ContainerBgWhite } from 'styles/containerBgWhite';
import { removeAccents } from 'utils/text';
import ModalAddWorkflowType from './ModalAddWorkflowType';
import ModalEditWorkflowType from './ModalEditWorkflowType';
import ModalViewWorkflowType from './ModalViewWorkflowType';
import { TitleColumnCenter } from 'styles/tableStyled';

export default function ManageWorkflowType() {
  const dataStore = useAppSelector(selectConfiguration);
  const dispatch = useDispatch();

  const dataListWorkflowType = dataStore?.dataListWorkflowType;
  const dataListBusinessWorkflowType = dataStore?.dataListBusinessWorkflowType;
  const dataListStatus = dataStore?.dataListStatus;
  const dataListAction = dataStore?.dataListAction;

  useEffect(() => {
    dispatch(getAllWorkflowType({}));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getBusinessWorkflowType({}));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllStatus({}));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllAction({}));
  }, [dispatch]);

  const { t } = useTranslation(['onboard', 'validation', 'timesheet', 'configuration']);
  const [formAdd] = Form.useForm();
  const [formEdit] = Form.useForm();

  const [sizePage, setSizePage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [modalType, setModalType] = useState<MODE>();
  const [dataListWorkflowTypeState, setDataGroupStatusState] = useState<any>(dataListWorkflowType);
  const [currentItem, setCurrentItem] = useState<any>(null);
  const [idRecord] = useState<any>(null);
  const format = useAppSelector(selectFormatDate);
  const [dataSource, setDataSource] = useState<any>([]);
  const [arrayFinal, setArrayFinal] = useState<any>([]);

  useEffect(() => {
    if (dataListWorkflowType?.length > 0) {
      const dataFlat = dataListWorkflowType?.map((item: any) => {
        return item?.workflowTypeDetail?.map((itemWL: any) => {
          return { ...item, workflowTypeDetail: itemWL };
        });
      });
      setDataGroupStatusState(dataFlat);
    }
  }, [dataListWorkflowType]);

  useEffect(() => {
    const dataSetId = dataListWorkflowTypeState?.flat(Infinity) || [];
    dataListWorkflowTypeState && setArrayFinal(dataSetId);
  }, [dataListWorkflowTypeState]);

  useEffect(() => {
    arrayFinal && setDataSource(arrayFinal);
  }, [arrayFinal]);

  const dataCheckWorkflowTypeByPayroll = dataStore?.dataCheckWorkflowTypeByPayroll;
  const [clickEdit, setClickEdit] = useState<any>(false);

  const MENU = [
    {
      name: t('timesheet:View_Detail'),
      icon: <EyeOutlined style={{ color: '#1890FF' }} />,
      handleClick: (value: any) => {
        setModalType(MODE.VIEW);
        setCurrentItem(value);
      },
    },
    {
      name: t('insurance:edit'),
      icon: <EditTwoTone twoToneColor="#F1592A" />,
      handleClick: (value: any) => {
        setModalType(MODE.EDIT);
        setCurrentItem(value);
        // dispatch(getCheckEditWorkflowByPayroll({ id: value?.id }));

        dispatch(
          getCheckEditWorkflowByPayroll({
            id: value?.workflowTypeDetail?.id,
          }),
        );

        setClickEdit(!clickEdit);
      },
    },
  ];
  const columns: COLUMNS[] = [
    {
      title: () => {
        return <TitleColumnCenter>{t('configuration:workflowType.workflow_id')}</TitleColumnCenter>;
      },
      width: 130,
      minWidth: 130,
      dataIndex: 'workflowTypeDetail',
      key: 'workflowTypeDetail',
      render: (text: any, record: any, index: any) => {
        return text?.workflowTypeCode;
      },
    },
    {
      title: () => {
        return (
          <TitleColumnCenter>{t('configuration:workflowType.workflow_name')}</TitleColumnCenter>
        );
      },
      width: 180,
      minWidth: 180,
      dataIndex: 'workflowTypeDetail',
      key: 'workflowTypeDetail',
      render: (text: any, record: any, index: any) => {
        return text?.workflowTypeName;
      },
    },
    {
      title: () => {
        return (
          <TitleColumnCenter>{t('configuration:workflowType.Business_code')}</TitleColumnCenter>
        );
      },
      width: 110,
      minWidth: 110,
      dataIndex: 'businessModule',
      key: 'businessModule',
      render: (value: string, record: any, index: any) => {
        const trueIndex = index + sizePage * (currentPage - 1);
        const obj: any = {
          children: value?.businessCode,
          props: {},
        };
        if (
          index >= 1 &&
          record?.businessModule?.businessCode ===
            arrayFinal[trueIndex - 1]?.businessModule?.businessCode
        ) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== arrayFinal.length &&
            record?.businessModule?.businessCode ===
              arrayFinal[trueIndex + i]?.businessModule?.businessCode;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }
        return obj;
      },
    },
    {
      title: () => {
        return (
          <TitleColumnCenter>{t('configuration:workflowType.status_group')}</TitleColumnCenter>
        );
      },
      width: 250,
      minWidth: 200,
      dataIndex: 'workflowTypeDetail',
      key: 'workflowTypeDetail',
      render: (text: any, record: any, index: any) => {
        return text?.statusGroup?.groupCode + '-' + text?.statusGroup?.groupName;
      },
    },
    {
      title: () => {
        return (
          <TitleColumnCenter>{t('configuration:workflowType.action_group')}</TitleColumnCenter>
        );
      },
      width: 250,
      minWidth: 200,
      dataIndex: 'workflowTypeDetail',
      key: 'workflowTypeDetail',
      render: (text: any, record: any, index: any) => {
        return text?.actionGroup?.groupCode + '-' + text?.actionGroup?.groupName;
      },
    },
    {
      title: () => {
        return <TitleColumnCenter>{t('configuration:workflowType.start_day')}</TitleColumnCenter>;
      },
      width: 110,
      minWidth: 110,
      ellipsis: true,
      align: 'left',
      dataIndex: 'workflowTypeDetail',
      key: 'workflowTypeDetail',
      render: (text: any, record: any, index: any) => {
        return <div>{text?.startDate ? moment(text?.startDate).format(format) : ''}</div>;
      },
    },
    {
      title: () => {
        return <TitleColumnCenter>{t('configuration:workflowType.end_day')}</TitleColumnCenter>;
      },
      width: 110,
      minWidth: 110,
      ellipsis: true,
      align: 'left',
      dataIndex: 'workflowTypeDetail',
      key: 'workflowTypeDetail',
      render: (text: any, record: any, index: any) => {
        return <div>{text?.endDate ? moment(text?.endDate).format(format) : ''}</div>;
      },
    },
    {
      title: () => {
        return <TitleColumnCenter>{t('configuration:workflowType.status')}</TitleColumnCenter>;
      },
      width: 100,
      minWidth: 100,
      ellipsis: true,
      align: 'center',
      dataIndex: 'workflowTypeDetail',
      key: 'workflowTypeDetail',
      render: (text: any, record: any, index: any) => {
        return (
          <StatusColumn status={text?.status === 'Active' ? 'active' : 'deactive'}>
            • {PROFILE_STATUSES[text?.status === 'Active' ? 'active' : 'deactive']}
          </StatusColumn>
        );
      },
    },
    {
      title: () => {
        return <TitleColumnCenter>{t('configuration:workflowType.description')}</TitleColumnCenter>;
      },
      width: 200,
      minWidth: 200,
      dataIndex: 'workflowTypeDetail',
      key: 'workflowTypeDetail',
      render: (text: any, record: any, index: any) => {
        return text?.description;
      },
    },
    {
      title: () => {
        return <TitleColumnCenter>{t('configuration:workflowType.action')}</TitleColumnCenter>;
      },
      width: 100,
      minWidth: 100,
      key: 'action',
      dataIndex: 'action',
      fixed: 'right',
      align: 'center',
      render: function (text: any, record: any, index: any) {
        return <MenuAction menu={MENU} data={record} />;
      },
    },
  ];
  const titleModal = modalType === MODE.ADD ? 'Add workflow type' : 'Edit workflow type';

  const onSave = (values: any) => {
    let dataCreate = values;
    let dataUpdate = values;
    if (modalType === MODE.ADD) {
      dispatch(
        createWorkflowType({
          dataCreate,
          callBack: () => {
            dispatch(getAllWorkflowType({}));
            setModalType('');
          },
        }),
      );
    }
    if (modalType === MODE.EDIT) {
      dispatch(
        updateWorkflowType({
          dataUpdate,
          callBack: () => {
            dispatch(getAllWorkflowType({}));
            setModalType('');
          },
        }),
      );
    }
  };
  const handleSearch = (value: string) => {
    const text = value && removeAccents(value.toLowerCase());

    if (text) {
      const temp = arrayFinal.filter((item: any) => {
        return (
          item?.workflowTypeDetail?.workflowTypeName &&
          removeAccents(
            item?.workflowTypeDetail?.workflowTypeName.toString().toLowerCase(),
          ).includes(text)
        );
      });

      setDataSource(temp);
    } else {
      setDataSource(arrayFinal);
    }
  };
  useEffect(() => {
    if (dataCheckWorkflowTypeByPayroll === true) {
      setModalType('');
    }
  }, [dataCheckWorkflowTypeByPayroll]);

  let checkOpenEditWorkflow = modalType === MODE.EDIT && dataCheckWorkflowTypeByPayroll === false;
  let checkCloseEditWorkflow = modalType === MODE.EDIT && dataCheckWorkflowTypeByPayroll === true;
  const handleCancel = () => {
    setModalType('');
    setCurrentItem(null);
    dispatch(setEmptyFindOneWorkflowType({}));
  };
  return (
    <>
      <ContainerBgWhite>
        <Header>
          <ITVSearch style={{ width: 300 }} handleSearch={handleSearch} />
          <HeaderAdd
            style={{ margin: 0 }}
            icon={<PlusOutlined />}
            onClick={() => {
              setModalType(MODE.ADD);
              formAdd.resetFields();
            }}
          >
            {t('add_new')}
          </HeaderAdd>
        </Header>
        <ITVTable
          columns={columns}
          data={dataSource}
          i9sRowLight={true}
          totalResult={dataSource?.length || 0} //Check number of object with same name and take that length of return object
          isRowSelect={false}
          //   loading={isLoading}
          isScroll={true}
          setOffset={setCurrentPage}
          offset={currentPage}
          setLimit={setSizePage}
          limit={sizePage}
        />
      </ContainerBgWhite>
      {/* ADD Workflow Type */}
      {modalType === MODE.ADD && (
        <CustomModalWL
          style={{ top: 0 }}
          title={titleModal}
          cancelText={t('timesheet:Cancel')}
          okText={t('timesheet:Save')}
          visible={true}
          onCancel={() => {
            // console.log('d');

            // setModalType('');
            Modal.confirm({
              title: 'Are you sure current change will lost',
              icon: <ExclamationCircleOutlined />,
              content: '',
              okText: 'OK',
              okType: 'danger',
              cancelText: 'Cancel',
              onOk: async () => {
                setModalType('');
              },
            });
          }}
          width={1950}
          onOk={() => {}}
          footer={null}
          maskClosable={false}
        >
          <Container>
            <ModalAddWorkflowType
              form={formAdd}
              onSave={onSave}
              setModalType={setModalType}
              modalType={modalType}
              dataListBusinessWorkflowType={dataListBusinessWorkflowType}
              dataListStatus={dataListStatus}
              dataListAction={dataListAction}
            />
          </Container>
        </CustomModalWL>
      )}
      {/* Edit Workflow Type */}
      {/* {modalType === MODE.EDIT ? (
        dataCheckWorkflowTypeByPayroll === false ? (
          <CustomModalWL
            style={{ top: 0 }}
            title={titleModal}
            cancelText={t('timesheet:Cancel')}
            okText={t('timesheet:Save')}
            visible={true}
            onCancel={() => {
              setModalType('');
              setCurrentItem(null);
              // dispatch(setEmptyFindOneWorkflowType({}));
            }}
            width={1950}
            onOk={() => {}}
            footer={null}
            destroyOnClose
            maskClosable={false}
          >
            <Container>
              <ModalEditWorkflowType
                currentItem={currentItem}
                form={formEdit}
                onSave={onSave}
                setModalType={setModalType}
                modalType={modalType}
                idRecord={idRecord}
                setCurrentItem={setCurrentItem}
                dataListBusinessWorkflowType={dataListBusinessWorkflowType}
                dataListStatus={dataListStatus}
                dataListAction={dataListAction}
              />
            </Container>
          </CustomModalWL>
        ) : (
          notificationToast('error', 'Payroll has not been closed', 3)
        )
      ) : (
        ''
      )} */}
      {checkOpenEditWorkflow && (
        <CustomModalWL
          style={{ top: 0 }}
          title={titleModal}
          cancelText={t('timesheet:Cancel')}
          okText={t('timesheet:Save')}
          visible={true}
          onCancel={() => {
            handleCancel();
          }}
          width={1950}
          onOk={() => {}}
          footer={null}
          destroyOnClose
          maskClosable={false}
        >
          <Container>
            <ModalEditWorkflowType
              currentItem={currentItem}
              form={formEdit}
              onSave={onSave}
              setModalType={setModalType}
              modalType={modalType}
              idRecord={idRecord}
              setCurrentItem={setCurrentItem}
              dataListBusinessWorkflowType={dataListBusinessWorkflowType}
              dataListStatus={dataListStatus}
              dataListAction={dataListAction}
            />
          </Container>
        </CustomModalWL>
      )}
      {checkCloseEditWorkflow && notificationToast('error', 'Payroll has not been closed', 3)}

      {/* View Workflow Type */}
      {modalType === MODE.VIEW && (
        <CustomModalWL
          style={{ top: 0 }}
          title={'View workflow type'}
          cancelText={t('timesheet:Cancel')}
          okText={t('timesheet:Save')}
          visible={true}
          onCancel={() => {
            handleCancel();
          }}
          width={1950}
          onOk={() => {}}
          footer={null}
          destroyOnClose
        >
          <Container>
            <ModalViewWorkflowType
              currentItem={currentItem}
              form={formEdit}
              onSave={onSave}
              setModalType={setModalType}
              modalType={modalType}
              idRecord={idRecord}
              setCurrentItem={setCurrentItem}
            />
          </Container>
        </CustomModalWL>
      )}
    </>
  );
}

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;
export const CustomModalWL = styled(Modal)`
  .ant-modal-body {
    padding: 0px 24px !important;
  }
`;

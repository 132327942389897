import styled from 'styled-components';
import { Avatar } from 'antd';
export const StyledEmployeeAddModalAvatar = styled.div`
  display: flex;
  background-color: var(--cl_white);
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 165px;
  & > h2 {
    font-size: var(--txt_font_size_h2);
    font-weight: var(--txt_font_weight_medium);
    line-height: 23px;
  }
`;
export const EmployeeAvatar = styled(Avatar)`
  width: 100%;
`;

import { ArrowLeftOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

interface ButtonBackProps {
  backLink?: string;
  handleBack?: any;
  title?: string;
}

const ButtonBack = ({ backLink, handleBack, title }: ButtonBackProps) => {
  const history = useHistory();
  const { t } = useTranslation(['header']);
  return (
    <div style={{ margin: '10px 0' }}>
      <span
        onClick={() => {
          if (handleBack) {
            handleBack();
          } else {
            backLink ? history.push(backLink) : history.goBack();
          }
        }}
        style={{
          fontSize: 14,
          fontWeight: 500,
          cursor: 'pointer',
          color: '#F1592A',
          marginRight: 70,
        }}
      >
        <ArrowLeftOutlined />
        <span style={{ marginLeft: 10 }}>{title ? title : t('header:back')}</span>
      </span>
    </div>
  );
};

export default ButtonBack;

import { CalculatorOutlined, InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Checkbox, Form, InputNumber, Layout, Select, Table, Tag } from 'antd';
import PageHeader from 'components/PageHeader';
import { testPayroll } from 'features/configuration/configurationAction';
import { formatCurrency } from 'features/insurances/constants/common';
import { TaxProfiles } from 'hrm-common/extensions/enums/personel';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import CustomButton from 'styles/buttonStyled';
import CustomSelect from 'styles/inputSelectStyled';

const { Content } = Layout;

//Tinh TotalIncomeThisPeriod
function calculateSoGioLamViecThucTe(
  actualGross,
  totalPaidLeaveOffHours,
  holidayPeriod,
  standardHours,
) {
  return actualGross + totalPaidLeaveOffHours + holidayPeriod > standardHours
    ? standardHours
    : actualGross + totalPaidLeaveOffHours + holidayPeriod;
}
function calculateActualGross(grossSalary, ratio) {
  return grossSalary * ratio;
}

function calculateSalaryThisPeriod(
  actualGross,
  soGioLamViecThucTe,
  standardHours,
  allowanceNotGross,
) {
  return actualGross * (soGioLamViecThucTe / standardHours) + allowanceNotGross || 0;
}
function calculateOTTienLuongGrossTrenMotGio(actualGross, standardHours) {
  return actualGross / standardHours || 0;
}
function calculateOTChiuThue(contractType, tienLuongGrossTrenMotGio, tongGioOT, taxCoefficient) {
  // if (contractType === TaxProfiles.MORE_THAN_THREE_MONTH) {
  return tienLuongGrossTrenMotGio * tongGioOT * taxCoefficient;
  // } else {
  // return 0;
  // }
}
function calculateOTKhongChiuThue(
  contractType,
  tienLuongGrossTrenMotGio,
  tongGioOTSauKhiTinhTheoHeSo,
  tienOTChiuThue,
) {
  // if (contractType === TaxProfiles.MORE_THAN_THREE_MONTH) {
  return tienLuongGrossTrenMotGio * tongGioOTSauKhiTinhTheoHeSo - tienOTChiuThue;
  // } else {
  // return 0;
  // }
}

function calculateOT(OTchiuThue, OTKhongChiuThue) {
  return OTchiuThue + OTKhongChiuThue;
}
function calculateTotalIncomeThisPeriod(salaryThisPeriod, totalOT, totalBonus, totalAdjusment) {
  return salaryThisPeriod + totalOT + totalBonus + totalAdjusment;
}
//Tinh PIT Deduction
function calculatePITDeduction(
  contractType,
  allowanceNotTax,
  tienOTKhongChiuThue,
  employeeContribution,
  mainsalary,
) {
  if (contractType === TaxProfiles.MORE_THAN_THREE_MONTH) {
    return allowanceNotTax + tienOTKhongChiuThue + employeeContribution * mainsalary;
  } else if (
    contractType === TaxProfiles.LESS_THAN_THREE_MONTH ||
    contractType === TaxProfiles.INTERN
  ) {
    return 0;
  }
}
//Tinh Taxed income
function calculateTaxableIncome(totalIncomeThisPeriod, PITDeduction) {
  return totalIncomeThisPeriod - PITDeduction;
}
function calculateTaxIncome(
  contractType,
  taxableIncome,
  dependantForPerson,
  dependant,
  totalPersonDependant,
) {
  if (contractType === TaxProfiles.MORE_THAN_THREE_MONTH) {
    let A = taxableIncome - (dependantForPerson + dependant * totalPersonDependant);

    if (A <= 0) {
      return 0;
    } else {
      return A;
    }
  } else if (contractType === TaxProfiles.LESS_THAN_THREE_MONTH) {
    return taxableIncome;
  } else if (contractType === TaxProfiles.INTERN) {
    return 0;
  }
}
let dataFakeConfigurationPIT = [
  {
    maxValue: 5000000,
    minValue: 0,
    rate: 5,
    isProbationary: false,
  },
  {
    maxValue: 10000000,
    minValue: 5000000,
    rate: 10,
    isProbationary: false,
  },
  {
    maxValue: 0,
    minValue: 0,
    rate: 10,
    isProbationary: true,
  },
  {
    maxValue: 18000000,
    minValue: 10000000,
    rate: 15,
    isProbationary: false,
  },
  {
    maxValue: 32000000,
    minValue: 18000000,
    rate: 20,
    isProbationary: false,
  },
  {
    maxValue: 52000000,
    minValue: 32000000,
    rate: 25,
    isProbationary: false,
  },
  {
    maxValue: 80000000,
    minValue: 52000000,
    rate: 30,
    isProbationary: false,
  },
  {
    minValue: 80000000,
    // maxValue: taxedIncome,
    rate: 35,
    isProbationary: false,
  },
];
let dataFakeConfigurationPITBE = [
  {
    maxValue: 5000000,
    minValue: 0,
    rate: 5,
    isProbationary: false,
  },
  {
    maxValue: 10000000,
    minValue: 5000000,
    rate: 10,
    isProbationary: false,
  },
  {
    rate: 10,
    isProbationary: true,
  },
  {
    maxValue: 18000000,
    minValue: 10000000,
    rate: 15,
    isProbationary: false,
  },
  {
    maxValue: 32000000,
    minValue: 18000000,
    rate: 20,
    isProbationary: false,
  },
  {
    maxValue: 52000000,
    minValue: 32000000,
    rate: 25,
    isProbationary: false,
  },
  {
    maxValue: 80000000,
    minValue: 52000000,
    rate: 30,
    isProbationary: false,
  },
  {
    minValue: 80000000,
    // maxValue: taxedIncome,
    rate: 35,
    isProbationary: false,
  },
];
//Tinh PIT
function calculatePIT(contractType, taxedIncome) {
  let PIT = 0;
  dataFakeConfigurationPIT = [
    {
      maxValue: 5000000,
      minValue: 0,
      rate: 5,
      isProbationary: false,
    },
    {
      maxValue: 10000000,
      minValue: 5000000,
      rate: 10,
      isProbationary: false,
    },
    {
      maxValue: 0,
      minValue: 0,
      rate: 10,
      isProbationary: true,
    },
    {
      maxValue: 18000000,
      minValue: 10000000,
      rate: 15,
      isProbationary: false,
    },
    {
      maxValue: 32000000,
      minValue: 18000000,
      rate: 20,
      isProbationary: false,
    },
    {
      maxValue: 52000000,
      minValue: 32000000,
      rate: 25,
      isProbationary: false,
    },
    {
      maxValue: 80000000,
      minValue: 52000000,
      rate: 30,
      isProbationary: false,
    },
    {
      minValue: 80000000,
      maxValue: taxedIncome,
      rate: 35,
      isProbationary: false,
    },
  ];
  for (let item of dataFakeConfigurationPIT) {
    if (taxedIncome > item.maxValue) {
      PIT = PIT + (item.maxValue - item.minValue) * (item.rate / 100);
    }
    if (taxedIncome > item.minValue && taxedIncome <= item?.maxValue) {
      PIT = PIT + (taxedIncome - item.minValue) * (item.rate / 100);
    }
  }

  if (contractType === TaxProfiles.MORE_THAN_THREE_MONTH) {
    if (taxedIncome <= 0) {
      return 0;
    } else {
      return PIT;
    }
  } else if (
    contractType === TaxProfiles.LESS_THAN_THREE_MONTH ||
    contractType === TaxProfiles.INTERN
  ) {
    return taxedIncome * 0.1;
  }
}
//Tinh Total insurance
function calculateTotalSHU(mainSalary, employeeContribution) {
  return mainSalary * employeeContribution;
}
function calculateTotalInsurance(contractType, totalSHU) {
  if (contractType === TaxProfiles.MORE_THAN_THREE_MONTH) {
    return 1 * totalSHU;
  } else if (
    contractType === TaxProfiles.LESS_THAN_THREE_MONTH ||
    contractType === TaxProfiles.INTERN
  ) {
    return 0 * totalSHU;
  }
}
//Tinh Actual receive salary
function calculateActualReceiveSalary(TotalIncomeThisPeriod, PIT, TotalInsurance, otherAdvance) {
  return TotalIncomeThisPeriod - PIT - TotalInsurance - otherAdvance;
}
//Tinh Actual receive salary
function calculateActualPaidSalary(
  contractType,
  totalInComeThisPeriod,
  otherFee,
  mainsalary,
  employerRate,
) {
  if (contractType === TaxProfiles.MORE_THAN_THREE_MONTH) {
    return totalInComeThisPeriod + otherFee + mainsalary * employerRate;
  } else {
    return totalInComeThisPeriod + otherFee + 0;
  }
}

const dataOTFake = [
  { id: '1', rate: 0, overtimeHours: 0 },
  { id: '2', rate: 0, overtimeHours: 0 },
  { id: '3', rate: 0, overtimeHours: 0 },
  { id: '4', rate: 0, overtimeHours: 0 },
];
const dataAllowanceFake = [
  { id: '1', name: 'a', isGross: false, isTaxed: false, money: 0 },
  { id: '2', name: 'b', isGross: false, isTaxed: false, money: 0 },
  { id: '3', name: 'c', isGross: false, isTaxed: false, money: 0 },
  { id: '4', name: 'd', isGross: false, isTaxed: false, money: 0 },
];
const dataAdjustmentFake = [
  { id: '1', name: 'a', increaseFlag: false, isTax: false, amount: 0 },
  { id: '2', name: 'b', increaseFlag: false, isTax: false, amount: 0 },
  { id: '3', name: 'c', increaseFlag: false, isTax: false, amount: 0 },
  { id: '4', name: 'd', increaseFlag: false, isTax: false, amount: 0 },
];
const dataAdvance = [
  { id: '1', name: 'a', amount: 0 },
  { id: '2', name: 'b', amount: 0 },
  { id: '3', name: 'c', amount: 0 },
  { id: '4', name: 'd', amount: 0 },
];
const dataBonusFake = [
  { id: '1', name: 'a', isTaxed: false, money: 0 },
  { id: '2', name: 'b', isTaxed: false, money: 0 },
  { id: '3', name: 'c', isTaxed: false, money: 0 },
  { id: '4', name: 'd', isTaxed: false, money: 0 },
];
function TestPayroll() {
  const [form] = Form.useForm();
  const { t } = useTranslation([
    'validation',
    'overtime',
    'action',
    'onboard',
    'validation',
    'timesheet',
    'employee',
  ]);
  let listTaxProfile = [
    {
      id: 1,
      name: t('employee:contract_information_fields.more_than_month'),
      value: TaxProfiles.MORE_THAN_THREE_MONTH,
    },
    {
      id: 2,
      name: t('employee:contract_information_fields.less_than_month'),
      value: TaxProfiles.LESS_THAN_THREE_MONTH,
    },
    {
      id: 3,
      name: t('employee:contract_information_fields.intern'),
      value: TaxProfiles.INTERN,
    },
  ];

  const [dataOTFakeState, setDataOTFakeState] = useState(dataOTFake);
  const [dataAllowanceFakeState, setDataAllowanceFakeState] = useState(dataAllowanceFake);
  const [dataAdjustmentFakeState, setDataAdjustmentFakeState] = useState(dataAdjustmentFake);
  const [dataAdvanceFakeState, setDataAdvanceFakeState] = useState(dataAdvance);
  const [dataBonusFakeState, setDataBonusFakeState] = useState(dataBonusFake);

  const [, setStandardHourState] = useState(0);

  useEffect(() => {
    form.setFieldsValue({
      actualHours: 0,
      standardHours: 0,
      actualGross: 0,
      totalPaidLeaveOff: 0,
      contractType: 'more-than-three-months',
      mainsalary: 0,
      grossSalary: 0,
      dependant: 0,
      dependantForPerson: 11000000,
      totalPersonDependant: 0,
      ratio: 100,
      holidayPeriod: 0,
      taxCoefficient: 1,
      SHU: 10.5,
      employerRate: 20.5,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOT = (value, idx) => {
    let temp1: any = dataOTFakeState?.map((obj: any, objIndex: any) =>
      objIndex === idx ? { ...obj, overtimeHours: value ?? 0 } : obj,
    );
    setDataOTFakeState(temp1);
  };
  const handleRateOT = (value, idx) => {
    let temp1: any = dataOTFakeState?.map((obj: any, objIndex: any) =>
      objIndex === idx ? { ...obj, rate: value ?? 0 } : obj,
    );
    setDataOTFakeState(temp1);
  };
  const COLUMNSOT: any = [
    {
      title: 'OT hours',
      dataIndex: 'overtimeHours',
      key: 'overtimeHours',
      width: 150,
      minWidth: 150,
      render: function (text: any, record: any, index: any) {
        return (
          <CustomInputNumber
            max={10000000000}
            min={0}
            style={{ width: '80%' }}
            onChange={(value: any) => {
              handleOT(value, index);
            }}
            defaultValue={0}
            formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value: any) => (value ? value.replace(/\$\s?|(,*)/g, '') : 0)}
          />
        );
      },
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      key: 'rate',
      width: 150,
      minWidth: 150,
      render: function (text: any, record: any, index: any) {
        return (
          <CustomInputNumber
            max={10000000000}
            min={0}
            style={{ width: '80%' }}
            onChange={(value: any) => {
              handleRateOT(value, index);
            }}
            defaultValue={0}
            formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value: any) => (value ? value.replace(/\$\s?|(,*)/g, '') : 0)}
          />
        );
      },
    },
  ];
  const handleMoneyAllowance = (value, idx) => {
    let temp1: any = dataAllowanceFakeState?.map((obj: any, objIndex: any) =>
      objIndex === idx ? { ...obj, money: value } : obj,
    );
    setDataAllowanceFakeState(temp1);
  };
  const handleOnChangeGross = (e, idx) => {
    let temp1: any = dataAllowanceFakeState?.map((obj: any, objIndex: any) =>
      objIndex === idx ? { ...obj, isGross: e.target.checked } : obj,
    );
    setDataAllowanceFakeState(temp1);
  };
  const handleOnChangeTax = (e, idx) => {
    let temp1: any = dataAllowanceFakeState?.map((obj: any, objIndex: any) =>
      objIndex === idx ? { ...obj, isTaxed: e.target.checked } : obj,
    );
    setDataAllowanceFakeState(temp1);
  };
  const COLUMNSAllowance: any = [
    {
      title: 'Money',
      dataIndex: 'money',
      key: 'money',
      width: 150,
      minWidth: 150,
      render: function (text: any, record: any, index: any) {
        return (
          <CustomInputNumber
            max={10000000000}
            min={0}
            style={{ width: '80%' }}
            onChange={(value: any) => {
              handleMoneyAllowance(value, index);
            }}
            defaultValue={0}
            formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value: any) => (value ? value.replace(/\$\s?|(,*)/g, '') : 0)}
          />
        );
      },
    },
    {
      title: 'Gross',
      dataIndex: 'gross',
      key: 'gross',
      width: 30,
      minWidth: 30,
      render: function (text: any, record: any, index: any) {
        return (
          <Checkbox
            onChange={(e: any) => {
              handleOnChangeGross(e, index);
            }}
          ></Checkbox>
        );
      },
    },
    {
      title: 'Tax',
      dataIndex: 'tax',
      key: 'tax',
      width: 30,
      minWidth: 30,
      render: function (text: any, record: any, index: any) {
        return (
          <Checkbox
            onChange={(e: any) => {
              handleOnChangeTax(e, index);
            }}
          ></Checkbox>
        );
      },
    },
  ];

  const handleMoneyAdjustment = (value, idx) => {
    let temp1: any = dataAdjustmentFakeState?.map((obj: any, objIndex: any) =>
      objIndex === idx ? { ...obj, amount: value } : obj,
    );
    setDataAdjustmentFakeState(temp1);
  };
  const handleOnChangeIncreaseFlagAdjustment = (e, idx) => {
    let temp1: any = dataAdjustmentFakeState?.map((obj: any, objIndex: any) =>
      objIndex === idx ? { ...obj, increaseFlag: e.target.checked } : obj,
    );
    setDataAdjustmentFakeState(temp1);
  };
  const handleOnChangeTaxAdjustment = (e, idx) => {
    let temp1: any = dataAdjustmentFakeState?.map((obj: any, objIndex: any) =>
      objIndex === idx ? { ...obj, isTax: e.target.checked } : obj,
    );
    setDataAdjustmentFakeState(temp1);
  };
  const COLUMNSAdjustment: any = [
    {
      title: 'Money',
      dataIndex: 'money',
      key: 'money',
      width: 150,
      minWidth: 150,
      render: function (text: any, record: any, index: any) {
        return (
          <CustomInputNumber
            max={10000000000}
            min={0}
            style={{ width: '80%' }}
            onChange={(value: any) => {
              handleMoneyAdjustment(value, index);
            }}
            defaultValue={0}
            formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value: any) => (value ? value.replace(/\$\s?|(,*)/g, '') : 0)}
          />
        );
      },
    },
    {
      title: 'IncreaseFlag',
      dataIndex: 'increaseFlag',
      key: 'increaseFlag',
      width: 30,
      minWidth: 30,
      render: function (text: any, record: any, index: any) {
        return (
          <Checkbox
            onChange={(e: any) => {
              handleOnChangeIncreaseFlagAdjustment(e, index);
            }}
          ></Checkbox>
        );
      },
    },
    {
      title: 'Tax',
      dataIndex: 'tax',
      key: 'tax',
      width: 30,
      minWidth: 30,
      render: function (text: any, record: any, index: any) {
        return (
          <Checkbox
            onChange={(e: any) => {
              handleOnChangeTaxAdjustment(e, index);
            }}
          ></Checkbox>
        );
      },
    },
  ];

  const handleMoneyAdvance = (value, idx) => {
    let temp1: any = dataAdvanceFakeState?.map((obj: any, objIndex: any) =>
      objIndex === idx ? { ...obj, amount: value } : obj,
    );
    setDataAdvanceFakeState(temp1);
  };
  const COLUMNSAdvance: any = [
    {
      title: 'Money',
      dataIndex: 'money',
      key: 'money',
      width: 150,
      minWidth: 150,
      render: function (text: any, record: any, index: any) {
        return (
          <CustomInputNumber
            max={10000000000}
            min={0}
            style={{ width: '80%' }}
            onChange={(value: any) => {
              handleMoneyAdvance(value, index);
            }}
            defaultValue={0}
            formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value: any) => (value ? value.replace(/\$\s?|(,*)/g, '') : 0)}
          />
        );
      },
    },
  ];

  const handleMoneyBonus = (value, idx) => {
    let temp1: any = dataBonusFakeState?.map((obj: any, objIndex: any) =>
      objIndex === idx ? { ...obj, money: value } : obj,
    );
    setDataBonusFakeState(temp1);
  };
  const handleOnChangeTaxBonus = (e, idx) => {
    let temp1: any = dataBonusFakeState?.map((obj: any, objIndex: any) =>
      objIndex === idx ? { ...obj, isTaxed: e.target.checked } : obj,
    );
    setDataBonusFakeState(temp1);
  };
  const COLUMNSBonus: any = [
    {
      title: 'Money',
      dataIndex: 'money',
      key: 'money',
      width: 150,
      minWidth: 150,
      render: function (text: any, record: any, index: any) {
        return (
          <CustomInputNumber
            max={10000000000}
            min={0}
            style={{ width: '80%' }}
            onChange={(value: any) => {
              handleMoneyBonus(value, index);
            }}
            defaultValue={0}
            formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value: any) => (value ? value.replace(/\$\s?|(,*)/g, '') : 0)}
          />
        );
      },
    },

    {
      title: 'Tax',
      dataIndex: 'tax',
      key: 'tax',
      width: 30,
      minWidth: 30,
      render: function (text: any, record: any, index: any) {
        return (
          <Checkbox
            onChange={(e: any) => {
              handleOnChangeTaxBonus(e, index);
            }}
          ></Checkbox>
        );
      },
    },
  ];
  const [contractType, setContractType] = useState('');
  const [actualGross, setActualGross] = useState(0);
  const [soGioLamViecThucTe, setSoGioLamViecThucTe] = useState(0);
  const [salaryThisPeriod, setSalaryThisPeriod] = useState(0);
  const [tienLuongGrossTrenMotGio, setTienLuongGrossTrenMotGio] = useState(0);
  const [tongGioOT, setTongGioOT] = useState(0);
  const [tienOTChiuThue, setTienOTChiuThue] = useState(0);
  const [tongGioOTSauKhiTinhTheoHeSo, setTongGioOTSauKhiTinhTheoHeSo] = useState(0);
  const [tienOTKhongChiuThue, setTienOTKhongChiuThue] = useState(0);
  const [tienOT, setTienOT] = useState(0);
  const [tienBonus, setTienBonus] = useState(0);
  const [tienAdjustment, setTienAdjustment] = useState(0);
  const [totalInComeThisPeriod, setTotalInComeThisPeriod] = useState(0);
  const [totalPITDeduction, setTotalPITDeduction] = useState(0);
  const [totalTaxableIncome, setTotalTaxableIncome] = useState(0);
  const [totalTaxIncome, setTotalTaxIncome] = useState(0);
  const [totalPIT, setTotalPIT] = useState(0);
  const [totalSHU, setTotalSHU] = useState(0);
  const [totalInsurance, setTotalInsurance] = useState(0);
  const [actualReceiveSalary, setActualReceiveSalary] = useState(0);
  const [actualPaidSalary, setActualPaidSalary] = useState(0);

  const [dataPayrollAPI, setDataPayrollAPI] = useState({});

  const dispatch = useDispatch();

  const handleCalculateAll = () => {
    form.validateFields().then((values) => {
      setContractType(values.contractType);
      let actualGross = calculateActualGross(values?.grossSalary, values?.ratio / 100);
      setActualGross(actualGross);
      // So gio lam viec thuc te
      let soGioLamViecThucTe = calculateSoGioLamViecThucTe(
        values?.actualHours,
        values?.totalPaidLeaveOff,
        values?.holidayPeriod,
        values?.standardHours,
      );
      setSoGioLamViecThucTe(soGioLamViecThucTe);
      //SalaryThisPeriod
      let allowanceNotGross = dataAllowanceFakeState
        ?.filter((item) => !item.isGross)
        .reduce((accum, obj) => accum + obj.money, 0);

      let salaryThisPeriod = calculateSalaryThisPeriod(
        actualGross,
        soGioLamViecThucTe,
        values?.standardHours,
        allowanceNotGross,
      );
      setSalaryThisPeriod(salaryThisPeriod);

      // Tien luong gross tren 1 gio
      let tienLuongGrossTrenMotGio = calculateOTTienLuongGrossTrenMotGio(
        actualGross,
        values?.standardHours,
      );
      setTienLuongGrossTrenMotGio(tienLuongGrossTrenMotGio);
      // Tong gio OT
      let tongGioOT = dataOTFakeState.reduce((accum, obj) => accum + obj.overtimeHours, 0);
      setTongGioOT(tongGioOT);
      // TienOT Chiu Thue
      let tienOTChiuThue = calculateOTChiuThue(
        values.contractType,
        tienLuongGrossTrenMotGio,
        tongGioOT,
        values.taxCoefficient,
      );
      setTienOTChiuThue(tienOTChiuThue);
      // Tong gio OT sau khi tinh theo he so
      let tongGioOTSauKhiTinhTheoHeSo = dataOTFakeState.reduce(
        (accum, obj) => accum + obj.overtimeHours * obj.rate,
        0,
      );
      setTongGioOTSauKhiTinhTheoHeSo(tongGioOTSauKhiTinhTheoHeSo);
      // TienOT KHONG Chiu Thue
      let tienOTKhongChiuThue = calculateOTKhongChiuThue(
        values.contractType,
        tienLuongGrossTrenMotGio,
        tongGioOTSauKhiTinhTheoHeSo,
        tienOTChiuThue,
      );
      setTienOTKhongChiuThue(tienOTKhongChiuThue);
      // TienOT
      let tienOT = calculateOT(tienOTChiuThue, tienOTKhongChiuThue);
      setTienOT(tienOT);
      // Tien Bonus
      let tienBonus = dataBonusFakeState.reduce((accum, obj) => accum + obj.money, 0);
      setTienBonus(tienBonus);
      // Tien Adjustment
      let tienAdjustment = dataAdjustmentFakeState.reduce((accum, obj) => {
        if (obj.increaseFlag) {
          return accum + obj.amount;
        } else {
          return accum - obj.amount;
        }
      }, 0);
      setTienAdjustment(tienAdjustment);
      // Salary this period
      let totalInComeThisPeriod = calculateTotalIncomeThisPeriod(
        salaryThisPeriod,
        tienOT,
        tienBonus,
        tienAdjustment,
      );
      setTotalInComeThisPeriod(totalInComeThisPeriod);
      // PIT Deduction
      let allowanceNotTax = dataAllowanceFakeState
        ?.filter((item) => !item.isTaxed)
        .reduce((accum, obj) => accum + obj.money, 0);
      let totalPITDeduction = calculatePITDeduction(
        values.contractType,
        allowanceNotTax,
        tienOTKhongChiuThue,
        values.SHU / 100,
        values.mainsalary,
      );
      setTotalPITDeduction(totalPITDeduction);
      // Taxable income
      let totalTaxableIncome = calculateTaxableIncome(totalInComeThisPeriod, totalPITDeduction);
      setTotalTaxableIncome(totalTaxableIncome);
      // Tax Income
      let totalTaxIncome = calculateTaxIncome(
        values.contractType,
        totalTaxableIncome,
        values.dependantForPerson,
        values.dependant,
        values.totalPersonDependant,
      );
      setTotalTaxIncome(totalTaxIncome);
      // PIT
      let totalPIT = calculatePIT(values.contractType, totalTaxIncome);
      setTotalPIT(totalPIT);
      // SHU
      let totalSHU = calculateTotalSHU(values.mainsalary, values.SHU / 100);
      setTotalSHU(totalSHU);
      //Total Insurance
      let totalInsurance = calculateTotalInsurance(values.contractType, totalSHU);
      setTotalInsurance(totalInsurance);
      let otherAdvance = dataAdvanceFakeState.reduce((accum, obj) => accum + obj.amount, 0);
      //ActualReceiveSalary
      let actualReceiveSalary = calculateActualReceiveSalary(
        totalInComeThisPeriod,
        totalPIT,
        totalInsurance,
        otherAdvance,
      );
      setActualReceiveSalary(actualReceiveSalary);
      //ActualPaidSalary
      let actualPaidSalary = calculateActualPaidSalary(
        values.contractType,
        totalInComeThisPeriod,
        0,
        values.mainsalary,
        values.employerRate / 100,
      );
      setActualPaidSalary(actualPaidSalary);
    });
  };
  const handleGetAPICalculate = () => {
    form.validateFields().then((values) => {
      let totalSHU = calculateTotalSHU(values.mainsalary, values.SHU / 100);
      let totalInsurance = calculateTotalInsurance(values.contractType, totalSHU);
      let soGioLamViecThucTe = calculateSoGioLamViecThucTe(
        values?.actualHours,
        0,
        values?.holidayPeriod,
      );
      let dataPayroll = {
        timeSheet: {
          actualHours: soGioLamViecThucTe,
          leaveOffHours: values?.totalPaidLeaveOff,
          standardHours: values.standardHours,
        },
        salary: {
          mainSalary: values.mainsalary,
          grossSalary: values.grossSalary,
          name: values.contractType,
          allowances: dataAllowanceFakeState,
        },
        adjustment: dataAdjustmentFakeState,
        advance: dataAdvanceFakeState,
        bonus: dataBonusFakeState,
        deduction: {
          dependant: values.dependant,
          dependantForPerson: values.dependantForPerson,
          totalPersonDependant: values.totalPersonDependant,
        },
        overtime: dataOTFakeState,
        configurationPIT: dataFakeConfigurationPITBE,
        insuranceIncomeTax: {
          contribuEmployee: totalInsurance,
          contribuEmployer: (values.employerRate * values.mainsalary) / 100,
        },
        user: {},
        ratio: values.ratio,
      };
      dispatch(
        testPayroll({
          dataPayroll,
          callBack: (data) => {
            setDataPayrollAPI(data);
            console.log('🚀 ~ file: TestPayroll.tsx ~ line 790 ~ form.validateFields ~ data', data);
          },
        }),
      );
    });
  };

  const handleStandardHours = (value) => {
    setStandardHourState(value);
  };
  const onAddRowOT = () => {
    let arrayIdActionDetail: any = dataOTFakeState?.map((item, idx) => {
      return item?.id;
    });
    let idMax = 0;
    if (arrayIdActionDetail.length === 0) {
      idMax = 0;
    } else {
      idMax = arrayIdActionDetail && Math.max(...arrayIdActionDetail);
    }
    const getId = () => `${++idMax}`;
    const newList = dataOTFakeState.concat({
      id: getId(),
      rate: 0,
      overtimeHours: 0,
    });
    setDataOTFakeState(newList);
  };
  const onAddRowAllowance = () => {
    // let arrayIdActionDetail: any = dataAllowanceFakeState?.map((item, idx) => {
    //   return item?.id;
    // });
    // let idMax = 0;
    // if (arrayIdActionDetail.length === 0) {
    //   idMax = 0;
    // } else {
    //   idMax = arrayIdActionDetail && Math.max(...arrayIdActionDetail);
    // }
    // const getId = () => `${++idMax}`;
    // const newList = dataAllowanceFakeState.concat({
    //   id: getId(),
    //   name: 'e',
    //   isGross: false,
    //   isTaxed: false,
    //   money: 0,
    // });
    // setDataAllowanceFakeState(newList);
  };

  return (
    <>
      <Container>
        <PageHeader title={`Test payroll`} />
      </Container>
      <Content style={{ background: '#fff', padding: '10px 30px' }}>
        <Form form={form} layout="vertical">
          <div style={{ display: 'flex' }}>
            <div style={{ width: '20%', marginRight: '20px' }}>
              <div style={{ display: 'flex' }}>
                <CustomFormItem name={'actualHours'} label={'ActualHours'}>
                  <CustomInputNumber
                    max={10000000000}
                    min={0}
                    style={{ width: '80%' }}
                    onChange={(value: any) => {}}
                    defaultValue={0}
                    formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value: any) => (value ? value.replace(/\$\s?|(,*)/g, '') : 0)}
                  />
                </CustomFormItem>
              </div>
              <div style={{ display: 'flex' }}>
                <CustomFormItem name={'standardHours'} label={'Standard Hours'}>
                  <CustomInputNumber
                    max={10000000000}
                    min={0}
                    style={{ width: '80%' }}
                    onChange={(value: any) => {
                      handleStandardHours(value);
                    }}
                    defaultValue={0}
                    formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value: any) => (value ? value.replace(/\$\s?|(,*)/g, '') : 0)}
                  />
                </CustomFormItem>
              </div>
              <div style={{ display: 'flex' }}>
                <CustomFormItem name={'totalPaidLeaveOff'} label={'Total paid leave off hours'}>
                  <CustomInputNumber
                    max={10000000000}
                    min={0}
                    style={{ width: '80%' }}
                    onChange={(value: any) => {}}
                    defaultValue={0}
                    formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value: any) => (value ? value.replace(/\$\s?|(,*)/g, '') : 100000)}
                  />
                </CustomFormItem>
              </div>
              <div style={{ display: 'flex' }}>
                <CustomFormItem name={'holidayPeriod'} label={'Holiday Period'}>
                  <CustomInputNumber
                    max={10000000000}
                    min={0}
                    style={{ width: '80%' }}
                    onChange={(value: any) => {}}
                    defaultValue={0}
                    formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value: any) => (value ? value.replace(/\$\s?|(,*)/g, '') : 0)}
                  />
                </CustomFormItem>
              </div>
              <div style={{ display: 'flex' }}>
                <CustomFormItem name={'SHU'} label={'Employee contribution'}>
                  <CustomInputNumber
                    max={10000000000}
                    min={0}
                    style={{ width: '80%' }}
                    onChange={(value: any) => {}}
                    defaultValue={10.5}
                    formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value: any) => (value ? value.replace(/\$\s?|(,*)/g, '') : 10.5)}
                  />
                </CustomFormItem>
              </div>
              <div style={{ display: 'flex' }}>
                <CustomFormItem name={'ratio'} label={'Ratio'}>
                  <CustomInputNumber
                    max={10000000000}
                    min={0}
                    style={{ width: '80%' }}
                    defaultValue={100}
                    formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value: any) => (value ? value.replace(/\$\s?|(,*)/g, '') : 0)}
                    addonAfter="%"
                  />
                </CustomFormItem>
              </div>
              <div style={{ display: 'flex' }}>
                <CustomFormItem name={'taxCoefficient'} label={'Tax coefficient'}>
                  <CustomInputNumber
                    max={10000000000}
                    min={0}
                    style={{ width: '80%' }}
                    defaultValue={1}
                    formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value: any) => (value ? value.replace(/\$\s?|(,*)/g, '') : 0)}
                    addonAfter="%"
                  />
                </CustomFormItem>
              </div>

              <div style={{ display: 'flex' }}>
                <CustomFormItem name={'employerRate'} label={'Employer Rate'}>
                  <CustomInputNumber
                    max={10000000000}
                    min={0}
                    style={{ width: '80%' }}
                    onChange={(value: any) => {}}
                    defaultValue={10.5}
                    formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value: any) => (value ? value.replace(/\$\s?|(,*)/g, '') : 10.5)}
                  />
                </CustomFormItem>
              </div>
            </div>
            <div style={{ width: '80%' }}>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CustomFormItem
                  style={{ width: '40%' }}
                  name={'contractType'}
                  label={'Contract Type'}
                  defaultValue="more-than-three-months"
                >
                  <CustomSelect
                    style={{ width: '80%' }}
                    allowClear
                    showSearch
                    placeholder={'Please select action'}
                  >
                    {listTaxProfile.map((data: any, key: any) => {
                      return (
                        <Select.Option key={key} value={data.value}>
                          {data?.name}
                        </Select.Option>
                      );
                    })}
                  </CustomSelect>
                </CustomFormItem>

                <CustomFormItem name={'mainsalary'} label={'Main salary'}>
                  <CustomInputNumber
                    max={10000000000}
                    min={0}
                    style={{ width: '80%' }}
                    onChange={(value: any) => {}}
                    defaultValue={0}
                    formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value: any) => (value ? value.replace(/\$\s?|(,*)/g, '') : 0)}
                  />
                </CustomFormItem>
                <CustomFormItem name={'grossSalary'} label={'Gross salary'}>
                  <CustomInputNumber
                    max={10000000000}
                    min={0}
                    style={{ width: '80%' }}
                    onChange={(value: any) => {}}
                    defaultValue={0}
                    formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value: any) => (value ? value.replace(/\$\s?|(,*)/g, '') : 0)}
                  />
                </CustomFormItem>

                <CustomFormItem name={'dependant'} label={'Dependant'}>
                  <CustomInputNumber
                    max={10000000000}
                    min={0}
                    style={{ width: '80%' }}
                    onChange={(value: any) => {}}
                    defaultValue={0}
                    formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value: any) => (value ? value.replace(/\$\s?|(,*)/g, '') : 0)}
                  />
                </CustomFormItem>
                <CustomFormItem name={'dependantForPerson'} label={'Dependant For Person'}>
                  <CustomInputNumber
                    max={10000000000}
                    min={0}
                    style={{ width: '80%' }}
                    onChange={(value: any) => {}}
                    defaultValue={11000000}
                    formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value: any) => (value ? value.replace(/\$\s?|(,*)/g, '') : 0)}
                  />
                </CustomFormItem>
                <CustomFormItem
                  style={{ width: '30%' }}
                  name={'totalPersonDependant'}
                  label={'Total Person Dependant'}
                >
                  <CustomInputNumber
                    max={10000000000}
                    min={0}
                    style={{ width: '80%' }}
                    onChange={(value: any) => {}}
                    defaultValue={0}
                    formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value: any) => (value ? value.replace(/\$\s?|(,*)/g, '') : 0)}
                  />
                </CustomFormItem>
              </div>
              <div style={{ display: 'flex', width: '100%' }}>
                {/* Allowance */}
                <div style={{ width: '50%', background: '#e6ffff', padding: '10px' }}>
                  <div style={{ display: 'flex' }}>
                    <h3>Allowance</h3>
                    <CustomButton icon={<PlusOutlined />} onClick={onAddRowAllowance}>
                      Add row
                    </CustomButton>
                  </div>
                  <Table
                    columns={COLUMNSAllowance}
                    dataSource={dataAllowanceFakeState}
                    pagination={false}
                  />
                </div>
                {/* OT */}
                <div style={{ width: '50%', background: '#e6ffff', padding: '10px' }}>
                  <div style={{ display: 'flex' }}>
                    <h3>Overtime hours</h3>
                    <CustomButton icon={<PlusOutlined />} onClick={onAddRowOT}>
                      Add row
                    </CustomButton>
                  </div>
                  <Table columns={COLUMNSOT} dataSource={dataOTFakeState} pagination={false} />
                  <h4>Tổng giờ OT: {tongGioOT}</h4>
                </div>
              </div>
              <div style={{ display: 'flex', width: '100%' }}>
                {/* Adjustment */}
                <div style={{ width: '50%', background: '#e6ffff', padding: '10px' }}>
                  <h3>Adjustment</h3>
                  <Table
                    columns={COLUMNSAdjustment}
                    dataSource={dataAdjustmentFakeState}
                    pagination={false}
                  />
                </div>
                {/* Advance */}
                <div style={{ width: '50%', background: '#e6ffff', padding: '10px' }}>
                  <h3>Advance</h3>
                  <Table
                    columns={COLUMNSAdvance}
                    dataSource={dataAdvanceFakeState}
                    pagination={false}
                  />
                </div>
                {/* Bonus */}
                <div style={{ width: '50%', background: '#e6ffff', padding: '10px' }}>
                  <h3>Bonus</h3>
                  <Table
                    columns={COLUMNSBonus}
                    dataSource={dataBonusFakeState}
                    pagination={false}
                  />
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
            <CustomButton style={{ marginRight: '30px' }} onClick={handleCalculateAll}>
              Calculate
            </CustomButton>
            <CustomButton onClick={handleGetAPICalculate}>Get API</CustomButton>
          </div>
        </Form>
      </Content>
      <h3>AUTOGENARATE FORMULA</h3>
      <Content style={{ background: '#fff', padding: '10px 20px' }}>
        <CustomRectangle>
          <b style={{ color: 'red' }}>(1) Total Income this period</b> = <b>Salary this period</b> +
          <b>Tiền OT</b> + <b>Bonus</b> + <b>Adjustment</b> ={' '}
          <Tag color="cyan">{formatCurrency(totalInComeThisPeriod)}</Tag>
          <Tag color="red">{formatCurrency(dataPayrollAPI?.totalIncomeBeforeTax || 0)}</Tag>
          {/* Salary this period */}
          <CustomP>
            <CalculatorOutlined /> <b>Salary this period</b> = Actual Gross x (Số giờ làm việc thực
            tế / Standard Hours ) + (Allowance not gross) ={' '}
            <Tag color="cyan">{formatCurrency(salaryThisPeriod)}</Tag>
            <CustomP style={{ marginLeft: '20px' }}>
              <InfoCircleOutlined /> Actual Gross: = Gross salary * Ratio ={' '}
              <Tag color="cyan">{formatCurrency(actualGross)}</Tag>
              <br />
              <CalculatorOutlined /> Số giờ làm việc thực tế = ActualHours + Total paid leave off
              hours + Holiday Period = <Tag color="cyan">{soGioLamViecThucTe}</Tag>
              <br />
            </CustomP>
          </CustomP>
          {/* Tiền OT */}
          <CustomP>
            <CalculatorOutlined /> <b>Tiền OT</b> = Tiền OT chịu thuế + Tiền OT không chịu thuế ={' '}
            <Tag color="cyan">{formatCurrency(tienOT)}</Tag>{' '}
            <Tag color="red">{formatCurrency(dataPayrollAPI?.totalIncomeOvertime || 0)}</Tag>
            <CustomP style={{ marginLeft: '20px' }}>
              <CalculatorOutlined /> Tiền OT chịu thuế = Tiền lương Gross trên một giờ công x Tổng
              giờ OT x Tax coefficient = <Tag color="cyan">{formatCurrency(tienOTChiuThue)}</Tag>{' '}
              <Tag color="red">{formatCurrency(dataPayrollAPI?.taxableOvertimePayment || 0)}</Tag>
              <CustomP style={{ marginLeft: '20px' }}>
                <CalculatorOutlined /> Tiền lương Gross trên một giờ công = Actual Gross / Standard
                Hours = <Tag color="cyan">{formatCurrency(tienLuongGrossTrenMotGio)}</Tag>
                <Tag color="red">{formatCurrency(dataPayrollAPI?.totalSalaryPerHour || 0)}</Tag>
                <br /> <InfoCircleOutlined /> Tổng giờ OT, Tax coefficient: Lấy từ timesheet và
                manage overtime type
              </CustomP>
              <CalculatorOutlined /> Tiền OT không chịu thuế = Tiền lương Gross trên một giờ công x
              Tổng giờ OT sau khi tính theo hệ số giờ - tiền OT chịu thuế ={' '}
              <Tag color="cyan">{formatCurrency(tienOTKhongChiuThue)}</Tag>
              <Tag color="red">
                {formatCurrency(dataPayrollAPI?.nonTaxableOvertimePayment || 0)}
              </Tag>
              <CustomP style={{ marginLeft: '20px' }}>
                <CalculatorOutlined /> Tổng giờ OT sau khi tính theo hệ số giờ= (Giờ OT * hệ số)
                +(Giờ OT * hệ số) + ... = <Tag color="cyan">{tongGioOTSauKhiTinhTheoHeSo}</Tag>
              </CustomP>
            </CustomP>
          </CustomP>
          <CustomP>
            <CalculatorOutlined /> <b>Bonus</b> =<Tag color="cyan">{formatCurrency(tienBonus)}</Tag>
            <Tag color="red">{formatCurrency(dataPayrollAPI?.totalIncomeBonus || 0)}</Tag>
          </CustomP>
          <CustomP>
            <CalculatorOutlined /> <b>Adjustment</b> =
            <Tag color="cyan">{formatCurrency(tienAdjustment)}</Tag>
            <Tag color="red">{formatCurrency(dataPayrollAPI?.totalIncomeAdjustment || 0)}</Tag>
          </CustomP>
          {/* <InfoCircleOutlined /> <b>Bonus, Adjustment</b>: Nhập tay */}
        </CustomRectangle>
        <CustomRectangle>
          <b style={{ color: 'red' }}>(2) PIT Deduction</b>
          <CustomP style={{ marginLeft: '20px' }}>
            Nếu contract type {'>='} 3 tháng
            <CustomP style={{ marginLeft: '20px' }}>
              PIT deduction = Allowance not tax + Tiền OT không chịu thuế + Employee contribution
              (SI, HI, UI (10.5%)) * Main salary ={' '}
              {contractType === TaxProfiles.MORE_THAN_THREE_MONTH ? (
                <span>
                  <Tag color="cyan">{formatCurrency(totalPITDeduction)}</Tag>
                  <Tag color="red">{formatCurrency(dataPayrollAPI?.totalPITDeduction)}</Tag>
                </span>
              ) : (
                <span>
                  <Tag color="cyan">Khong co</Tag>
                  <Tag color="red">Khong co</Tag>
                </span>
              )}
            </CustomP>
          </CustomP>
          <CustomP style={{ marginLeft: '20px' }}>
            Nếu contract type {'<'} 3 tháng{' '}
            <CustomP style={{ marginLeft: '20px' }}>PIT deduction = 0</CustomP>
          </CustomP>
        </CustomRectangle>
        <CustomRectangle>
          <b style={{ color: 'red' }}>(3) Taxed income</b>
          <CustomP style={{ marginLeft: '20px' }}>
            Nếu contract type {'>='} 3 tháng
            <CustomP style={{ marginLeft: '20px' }}>
              A = Taxable income {`– `} Total (personal deduction + dependent * Number of
              dependants) ={' '}
              <Tag color="cyan">
                {contractType === TaxProfiles.MORE_THAN_THREE_MONTH
                  ? formatCurrency(totalTaxIncome)
                  : 'Khong co'}
              </Tag>
              <Tag color="red">
                {contractType === TaxProfiles.MORE_THAN_THREE_MONTH
                  ? formatCurrency(dataPayrollAPI?.taxedIncome)
                  : 'Khong co'}
              </Tag>
              <CustomP style={{ marginLeft: '20px' }}>
                Nếu A {'<='} 0 {`=>`} Taxed income = 0{' '}
              </CustomP>
              <CustomP style={{ marginLeft: '20px' }}>
                Nếu A {'>'} 0 {`=>`} Taxed income = A ={' '}
                <Tag color="cyan">
                  {contractType === TaxProfiles.MORE_THAN_THREE_MONTH
                    ? formatCurrency(totalTaxIncome)
                    : 'Khong co'}
                </Tag>
                <Tag color="red">
                  {contractType === TaxProfiles.MORE_THAN_THREE_MONTH
                    ? formatCurrency(dataPayrollAPI?.taxedIncome)
                    : 'Khong co'}
                </Tag>{' '}
              </CustomP>
            </CustomP>
          </CustomP>
          <CustomP style={{ marginLeft: '20px' }}>
            Nếu contract type {'<'} 3 tháng{' '}
            <CustomP style={{ marginLeft: '20px' }}>
              Taxed income = Taxable income ={' '}
              {contractType === TaxProfiles.LESS_THAN_THREE_MONTH ? (
                <span>
                  <Tag color="cyan">{formatCurrency(totalTaxableIncome)}</Tag>
                  <Tag color="red">{formatCurrency(dataPayrollAPI?.taxableIncome || 0)}</Tag>
                </span>
              ) : (
                <span>
                  <Tag color="cyan">Khong co</Tag>
                  <Tag color="red">Khong co</Tag>
                </span>
              )}
            </CustomP>
          </CustomP>
          <CustomP style={{ marginLeft: '20px' }}>
            Nếu contract type {'='} intern{' '}
            <CustomP style={{ marginLeft: '20px' }}>Taxed income = 0</CustomP>
          </CustomP>
          <CustomP style={{ marginLeft: '0px' }}>
            <InfoCircleOutlined /> Taxable income = Total income this period(1) {`–`} PIT deduction
            = <Tag color="cyan">{formatCurrency(totalTaxableIncome)}</Tag>{' '}
            <Tag color="red">{formatCurrency(dataPayrollAPI?.taxableIncome || 0)}</Tag>
          </CustomP>
        </CustomRectangle>
        <CustomRectangle>
          <b style={{ color: 'red' }}>(4) PIT</b>
          <CustomP>
            Nếu contract type {'>='} 3 tháng
            <CustomP>
              <CustomP style={{ marginLeft: '20px' }}>
                Nếu taxed income {'<='} 0 {`=>`} PIT = 0
              </CustomP>
              <CustomP style={{ marginLeft: '20px' }}>
                Nếu taxed income {'>'} 0 {`=>`} PIT = 5,000,000 * 5% + [(10,000,000 - 5,000,000) x
                10%]+ [(18,000,000 - 10,000,000) x 15%]+ [(32,000,000 - 18,000,000) x 20%]+
                [(52,000,000 - 32,000,000) x 25%]+ [(80,000,000 - 52,000,000) x 30%] + [(Taxed
                income - 80,000,000) x 35%] ={' '}
                <Tag color="cyan">
                  {contractType === TaxProfiles.MORE_THAN_THREE_MONTH
                    ? formatCurrency(totalPIT)
                    : 'Khong co'}
                </Tag>
                <Tag color="red">
                  {contractType === TaxProfiles.MORE_THAN_THREE_MONTH
                    ? formatCurrency(dataPayrollAPI?.totalTaxPersonIncome)
                    : 'Khong co'}
                </Tag>
              </CustomP>
            </CustomP>
          </CustomP>
          <CustomP>
            Nếu contract type {'<'} 3 tháng
            <CustomP style={{ marginLeft: '20px' }}>
              PIT = Taxed income x 0.1 ={' '}
              <Tag color="cyan">
                {contractType === TaxProfiles.LESS_THAN_THREE_MONTH ||
                contractType === TaxProfiles.INTERN
                  ? formatCurrency(totalPIT)
                  : 'Khong co'}
              </Tag>
              <Tag color="red">
                {contractType === TaxProfiles.LESS_THAN_THREE_MONTH ||
                contractType === TaxProfiles.INTERN
                  ? formatCurrency(dataPayrollAPI?.totalTaxPersonIncome)
                  : 'Khong co'}
              </Tag>
            </CustomP>
          </CustomP>
        </CustomRectangle>
        <CustomRectangle>
          <b style={{ color: 'red' }}>(5) Total insurance</b>
          <CustomP>
            <CustomP style={{ marginLeft: '20px' }}>
              Total Insurance = Contract type x (Social insurance + Health insurance + unemploment
              insurance) = <Tag color="cyan">{formatCurrency(totalInsurance)}</Tag>
              <CustomP style={{ marginLeft: '40px' }}>
                <div>
                  <span style={{ marginLeft: '-20px' }}>
                    <InfoCircleOutlined /> Nếu contract type {'>='} 3 tháng
                  </span>
                  <CustomP style={{ marginLeft: '20px' }}>Contract type = 1</CustomP>
                  <span>Nếu contract type {'<'} 3 tháng</span>
                  <CustomP style={{ marginLeft: '20px' }}>Contract type = 0</CustomP>
                </div>
                <div>
                  <span style={{ marginLeft: '-20px' }}>
                    <InfoCircleOutlined /> (Social Insurance + health Insurance + Unemployment
                    Insurance) = Lương sẽ nộp bảo hiểm đã được thoả thuận trong hợp đồng(main
                    salary) x Employee contribution ={' '}
                    <Tag color="cyan">{formatCurrency(totalSHU)}</Tag>
                  </span>
                </div>
              </CustomP>
            </CustomP>
          </CustomP>
        </CustomRectangle>

        {/* <div style={{ display: 'flex', justifyContent: 'center' }}> */}
        <CustomRectangleOutput>
          <p style={{ textAlign: 'center' }}>Output</p>
          <p>
            Actual receive salary = <b style={{ color: 'red' }}> Total Income this period(1)</b> -{' '}
            <b style={{ color: 'red' }}> PIT(4)</b> -{' '}
            <b style={{ color: 'red' }}> Total insurance(5)</b> -{' '}
            <b style={{ color: 'red' }}> Total Advance</b> ={' '}
            <Tag color="cyan">{formatCurrency(actualReceiveSalary)}</Tag>
            <Tag color="red">{formatCurrency(dataPayrollAPI?.actualReceiveSalary || 0)}</Tag>
          </p>
          <p>
            Actual paid salary = <b style={{ color: 'red' }}> Total Income this period(1)</b> +
            Other fee + Main Salary x Employer Rate ={' '}
            <Tag color="cyan">{formatCurrency(actualPaidSalary)}</Tag>
            <Tag color="red">{formatCurrency(dataPayrollAPI?.totalSalaryCost || 0)}</Tag>
          </p>
        </CustomRectangleOutput>
        {/* </div> */}
      </Content>
    </>
  );
}

export default TestPayroll;
const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
export const CustomInputNumber = styled(InputNumber)`
  .ant-input-number-input {
    text-align: right;
    // padding-right:1px;
  }
  .ant-input-number-handler-wrap {
    padding-right: 5px;
  }
  .ant-input-number-handler-wrap {
    display: none;
  }
`;
export const CustomFormItem = styled(Form.Item)`
  margin-bottom: 5px;
  .ant-form-item-label {
    padding: 0px;
  }
`;
export const CustomRectangle = styled.div`
  width: 100%;
  background: #f2f2f2;
  padding: 10px 20px;
  margin-bottom: 10px;
`;
export const CustomRectangleOutput = styled.div`
  width: 100%;
  background: #ccffe6;
  padding: 10px 20px;
  margin-bottom: 10px;
`;
export const CustomP = styled.p`
  margin-bottom: 0px;
`;
export const ValueFE = styled.b`
  color: #00ff00;
`;

import { useEffect, useState } from 'react';
import { Checkbox, Col, Row } from 'antd';
import { InfoText, RowTitle } from './HistoryParticipantList';
import styled from 'styled-components';
import { formatCurrencyUnit, formatDateInsurances, labelUnit } from '../../../constants/common';
import { useAppSelector } from 'app/hooks';
import { selectInsurance } from '../../../insuranceSlice';
import { useTranslation } from 'react-i18next';

const ItemHistoryParticipant = (props: any) => {
  const { params, datas } = props;
  const { t } = useTranslation(['insurance']);
  const insurance = useAppSelector(selectInsurance);
  const dataType = insurance?.dataTypeInsurance?.data?.result;
  const [data, setData] = useState(dataType);
  const [checkedList, setCheckedList]: any = useState([]);

  useEffect(() => {
    if (dataType) {
      let typeInsurance = params?.typeInsurance;
      let obj: any = [];
      let m_obj: any = [];
      for (let i = 0; i < dataType.length; i++) {
        let is_obj: any = [];
        // eslint-disable-next-line array-callback-return
        typeInsurance?.map((item: any) => {
          if (String(dataType[i].id) === item) {
            obj.push({ id: dataType[i].id, insuranceName: dataType[i].insuranceName, check: true });
            is_obj.push({ check: true });
            m_obj.push(Number(dataType[i].id));
          }
        });
        if (is_obj.length < 1) {
          obj.push({ id: dataType[i].id, insuranceName: dataType[i].insuranceName, check: false });
        }
      }
      setData(obj);
      setCheckedList(m_obj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataType]);
  return (
    <div style={{ width: '100%' }}>
      <InfoTextTitle>{t('insurance_information')}</InfoTextTitle>
      <Row gutter={[16, 16]}>
        <Col span={6}>
          <RowTitle>{t('social_insurance_number')}</RowTitle>
        </Col>
        <Col span={6}>
          <InfoText>{params?.user?.socialInsuranceNumber}</InfoText>
        </Col>
        <Col span={6}></Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={6}>
          <RowTitle>{t('valid_date')}</RowTitle>
        </Col>
        <Col span={6}>
          <InfoText>
            {formatDateInsurances(datas[0]?.datarecor.schedulePremium.newValidDate)}
          </InfoText>
        </Col>
        <Col span={6}>
          <RowTitle>{t('employee_contribute')}</RowTitle>
        </Col>
        <Col span={6}>
          <InfoText>{formatCurrencyUnit(params?.totalContributeSelf, labelUnit)}</InfoText>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={6}>
          <RowTitle>{t('salary_premium')}</RowTitle>
        </Col>
        <Col span={6}>
          <InfoText>{formatCurrencyUnit(params?.salaryPremium, labelUnit)}</InfoText>
        </Col>
        <Col span={6}>
          <RowTitle>{t('employer_contribute')}</RowTitle>
        </Col>
        <Col span={6}>
          <InfoText>{formatCurrencyUnit(params?.totalContributeEmployer, labelUnit)}</InfoText>
        </Col>
      </Row>
      <hr style={{ background: '#E5E7EB' }} />
      <Row gutter={[16, 16]}>
        <Col span={6}>
          <RowTitle>{t('insurance_policy')}</RowTitle>
        </Col>
        <Col span={18}>
          <Checkbox.Group value={checkedList}>
            {data !== undefined
              ? data.map((item: any) => (
                  <InfoCheckbox disabled key={item?.id} style={{ margin: -0.5 }} value={item?.id}>
                    {item?.insuranceName}
                  </InfoCheckbox>
                ))
              : null}
          </Checkbox.Group>
        </Col>
      </Row>
    </div>
  );
};

export default ItemHistoryParticipant;
export const InfoCheckbox = styled(Checkbox)`
  font-size: 16px;
  font-weight: 400;
  .ant-checkbox-checked .ant-checkbox-inner {
    background: #f1c5b8;
    border-color: #f1c5b8;
  }
`;
export const InfoTextTitle = styled('h2')`
  font-size: 20px;
  font-family: Roboto;
  font-weight: 500;
`;

import { Checkbox, TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import ITVTable from 'components/ITVTable';
import EmployeeColumn from 'components/EmployeeColumn';
import { TaxProfiles } from 'hrm-common/extensions/enums/personel';
import { formatCurrencyUnitVND } from 'features/insurances/constants/common';
import StatusColumnCell from 'features/payroll/pages/ViewEmployee/components/StatusColumnCell';
import { useAppSelector } from 'app/hooks';
import { selectPermissions } from 'features/auth/authSlice';
import { ContractSalary } from 'types';
import { MONTH_NAMES } from 'constants/commons';
import { DownTrendIcon, UpTrendIcon } from 'assets/icons';
import moment from 'moment';
import MaskedValueRenderer from 'components/MaskedValueRenderer';

interface StyledSalaryProps {
  isConfirm?: boolean;
  salaryChange: string;
}

const SalaryChangeStatus = {
  UP: 'up',
  DOWN: 'down',
};

const StyledItemCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledSalary = styled.div`
  ${(props: StyledSalaryProps) => {
    return css`
      color: ${props.isConfirm
        ? 'var(--cl_primary)'
        : props.salaryChange === SalaryChangeStatus.UP
        ? 'var(--cl_success500)'
        : props.salaryChange === SalaryChangeStatus.DOWN
        ? 'var(--cl_error500)'
        : 'inherit'};
    `;
  }}
`;

interface ContractSalaryListProps {
  monthYear?: string;
  loading: boolean;
  totalResult: number;
  contractSalaryList: ContractSalary[];
  page: number;
  limit: number;
  isYearMode?: boolean;
  setPage: Dispatch<SetStateAction<number>>;
  setLimit: Dispatch<SetStateAction<number>>;
  onTableChange?: (
    pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue | null>,
    sorter: SorterResult<any>,
  ) => void;
  onRefresh?: () => void;
}

export function ContractSalaryList({
  monthYear,
  loading,
  totalResult,
  contractSalaryList,
  page,
  limit,
  isYearMode = false,
  setPage,
  setLimit,
  onTableChange,
  onRefresh,
}: ContractSalaryListProps) {
  const { t } = useTranslation(['employee']);
  const permissions = useAppSelector(selectPermissions);

  const MONTH_COLUMNS = [
    {
      title: t('employee:employee_information_fields.id'),
      dataIndex: 'employeeId',
      key: 'employeeId',
      ellipsis: true,
      width: 60,
      minWidth: 60,
      align: 'center',
      sorter: true,
      render: (text: any) => <div>{text}</div>,
    },
    {
      title: t('employee:employee_information_fields.employee'),
      dataIndex: 'fullName',
      key: 'fullName',
      width: 220,
      minWidth: 220,
      ellipsis: true,
      sorter: true,
      render: (text: any, record: any) => (
        <EmployeeColumn
          avatar={record?.fullName}
          fullName={record?.fullName}
          email={record?.email}
        />
      ),
    },
    {
      title: t('employee:contract_information_fields.reside_in_Vietnam'),
      dataIndex: 'resideStatus',
      key: 'resideStatus',
      width: 100,
      minWidth: 100,
      align: 'center',
      sorter: true,
      render: (text: any, record: any) => (
        <StyledItemCenter>
          <Checkbox disabled checked={record.data?.[0]?.resideStatus ?? false} />
        </StyledItemCenter>
      ),
    },
    {
      title: t('employee:contract_information_fields.tax_profile'),
      dataIndex: 'taxProfile',
      key: 'taxProfile',
      sorter: true,
      width: 200,
      minWidth: 200,
      align: 'center',
      render: (text: any, record: any) => {
        const tax = record.data?.[0]?.taxProfile;

        return tax
          ? tax === TaxProfiles.MORE_THAN_THREE_MONTH
            ? t('employee:contract_information_fields.more_than_month')
            : tax === TaxProfiles.LESS_THAN_THREE_MONTH
            ? t('employee:contract_information_fields.less_than_month')
            : tax === TaxProfiles.PROBATION
            ? t('employee:contract_information_fields.probation')
            : t('employee:contract_information_fields.intern')
          : null;
      },
    },
    {
      title: t('employee:contract_information_fields.salary'),
      dataIndex: 'salary',
      key: 'salary',
      width: 150,
      minWidth: 150,
      sorter: true,
      align: 'center',
      render: (text: any, record: any) => {
        formatCurrencyUnitVND(record.data?.[0]?.salary || 0, '');
        const salaryChange = record?.salaryChange;
        const salary = record.data?.[0]?.salary || 0;

        return (
          <StyledSalary salaryChange={salaryChange}>
            {salaryChange === SalaryChangeStatus.UP && <UpTrendIcon />}
            {salaryChange === SalaryChangeStatus.DOWN && <DownTrendIcon />}
            <MaskedValueRenderer value={salary} />
          </StyledSalary>
        );
      },
    },
    {
      title: t('employee:contract_information_fields.ratio'),
      dataIndex: 'ratio',
      key: 'ratio',
      width: 100,
      minWidth: 100,
      sorter: true,
      align: 'center',
      render: (text: any, record: any) => `${record.data?.[0]?.ratio || 100}%`,
    },
    {
      title: t('employee:employee_information_fields.action'),
      dataIndex: 'action',
      key: 'action',
      width: 60,
      minWidth: 60,
      align: 'center',
      fixed: 'right',
      render: (text: any, record: any) => (
        <StatusColumnCell
          showMenu={'contract'}
          t={t}
          isShowFullMenu={true}
          item={{
            id: record.userId,
            fullName: record.fullName,
            employeeId: record.employeeId,
            email: record.email,
          }}
          permissions={permissions}
          callBack={() => onRefresh?.()}
          monthYear={moment(monthYear)}
        />
      ),
    },
  ];

  const YEAR_COLUMNS: any = [
    {
      title: t('employee:employee_information_fields.id'),
      dataIndex: 'employeeId',
      key: 'employeeId',
      ellipsis: true,
      width: 60,
      minWidth: 60,
      align: 'center',
      fixed: 'left',
      sorter: true,
      render: (text: any) => <div>{text}</div>,
    },
    {
      title: t('employee:employee_information_fields.employee'),
      dataIndex: 'fullName',
      key: 'fullName',
      fixed: 'left',
      width: 220,
      minWidth: 220,
      ellipsis: true,
      sorter: true,
      render: (text: any, record: any) => (
        <EmployeeColumn
          avatar={record?.fullName}
          fullName={record?.fullName}
          email={record?.email}
        />
      ),
    },
    {
      title: t('employee:contract_information_fields.salary'),
      children: MONTH_NAMES.map((_, index) => ({
        title: t(`common:month.${index}.name`),
        dataIndex: 'data',
        key: 'data',
        align: 'center',
        ellipsis: true,
        width: 150,
        minWidth: 150,
        render: (data: any) => {
          const isConfirm = Boolean(data[index]?.isConfirm);
          const salaryChange = data[index]?.salaryChange;
          const salary = data[index]?.salary || 0;

          return (
            <StyledSalary salaryChange={salaryChange} isConfirm={isConfirm}>
              {salaryChange === SalaryChangeStatus.UP && !isConfirm && <UpTrendIcon />}
              {salaryChange === SalaryChangeStatus.DOWN && !isConfirm && <DownTrendIcon />}
              <MaskedValueRenderer value={salary} />
            </StyledSalary>
          );
        },
      })),
    },
  ];

  return (
    <ITVTable
      columns={isYearMode ? YEAR_COLUMNS : MONTH_COLUMNS}
      data={contractSalaryList}
      rowKey={['userId']}
      setSelectedUser={() => {}}
      loading={loading}
      totalResult={totalResult}
      setOffset={setPage}
      offset={page}
      setLimit={setLimit}
      limit={limit}
      onChange={onTableChange}
    />
  );
}

import i18n from 'app/i18n';
import { languages } from 'components/LayoutPage/SelectLanguage';
import { DISPLAY_HEADER, TYPE_HEADER } from 'constants/notification';

export function capitalizeFirstLetter(text: string) {
  if (!text) return null;
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export const covertTitleNotification = (type: string) => {
  switch (type) {
    case TYPE_HEADER.Notification:
      return DISPLAY_HEADER.Personnel;

    case TYPE_HEADER.Reminder:
      return DISPLAY_HEADER.Reminder;

    default:
      return '';
  }
};

export function removeAccents(str: any) {
  var AccentsMap = [
    'aàảãáạăằẳẵắặâầẩẫấậ',
    'AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬ',
    'dđ',
    'DĐ',
    'eèẻẽéẹêềểễếệ',
    'EÈẺẼÉẸÊỀỂỄẾỆ',
    'iìỉĩíị',
    'IÌỈĨÍỊ',
    'oòỏõóọôồổỗốộơờởỡớợ',
    'OÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢ',
    'uùủũúụưừửữứự',
    'UÙỦŨÚỤƯỪỬỮỨỰ',
    'yỳỷỹýỵ',
    'YỲỶỸÝỴ',
  ];
  for (var i = 0; i < AccentsMap.length; i++) {
    var re = new RegExp('[' + AccentsMap[i].substring(1) + ']', 'g');
    var char = AccentsMap[i][0];
    if (str) str = str.replace(re, char);
  }
  return str;
}

export const getListCharacterFirst = (str: any) => {
  const matches = str.match(/\b(\w)/g) || [];
  const rs = matches.join('');
  return rs;
};

export const isLangEn = () => {
  const langEnCode = languages.find((lang) => lang.code === 'en')?.code || 'en';

  if (i18n.language === langEnCode) return true;

  return false;
};

import React, { useEffect, useState } from 'react';
import { Radio, Select, Button, Form, Input, Modal } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import {
  InfoButtonCancel,
  ItemBody,
  SmallCustomModal,
  Titles,
} from 'features/masterData/General/components/pages/ManagePlace/ManagePlace';
import { useTranslation } from 'react-i18next';
import CustomButton from 'styles/buttonStyled';
import { useDispatch } from 'react-redux';
import { postAppCameraAI } from 'features/masterData/masterDataAction';
import {
  editTimekeepingConfig,
  timekeepingConfig,
} from 'features/configuration/configurationAction';
import { useAppSelector } from 'app/hooks';
import { selectConfiguration } from 'features/configuration/configurationSlice';
import PageHeader from 'components/PageHeader';
import configurationApi from 'api/configurationApi';

const { Option } = Select;

enum DeviceType {
  FACEID = 'faceID',
  CAMERAAI = 'cameraAI',
  ONLINE = 'online',
  MACHINE = 'machine',
}

const StyledForm = styled(Form)`
  max-width: 600px;
  margin: 0 auto;
`;

const TimeKeepingConfig: React.FC = () => {
  const [configType, setConfigType] = useState<'manual' | 'auto' | ''>('');
  const [deviceType, setDeviceType] = useState<string>('');
  const [showAddAppModal, setShowAddAppModal] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const location: any = useLocation();
  const menuStore = useAppSelector(selectConfiguration);
  const dataScreenManage = menuStore?.dataScreenManage?.result;
  const [titleHeader, setTitleHeader] = useState('');
  const [form] = Form.useForm();
  const [isEditable, setIsEditable] = useState(false);
  const [machineType, setMachineType] = useState<string>('');
  const [id, setId] = useState('');

  useEffect(() => {
    const temp = dataScreenManage
      ?.filter((item: any) => location.pathname === item.path)
      ?.map((item: any) => item.nameScreen);

    setTitleHeader(temp ? temp[0] : '');
  }, [dataScreenManage, location.pathname]);

  const handleConfigTypeChange = (e: any) => {
    setConfigType(e.target.value);
    setDeviceType('');
  };

  const handleSave = (values: any) => {
    if (isEditable)
      dispatch(
        editTimekeepingConfig({
          type: id,
          body: { ...values, dbPort: Number(values?.dbPort) },
        }),
      );
    else dispatch(timekeepingConfig({ body: { ...values, dbPort: Number(values?.dbPort) } }));
  };

  const handleDeviceTypeChange = (dt: any) => {
    setDeviceType(dt);
    if (dt === DeviceType.CAMERAAI) {
      setShowAddAppModal(true);
    } else if (dt === DeviceType.ONLINE) {
      history.push('/configuration/general-setting/check-in-method');
    }
  };

  const fetchMachineData = async (machineType: string) => {
    try {
      const response = await configurationApi.getMachineData({ type: machineType });
      const data = await response?.data;
      if (data) {
        setIsEditable(true);
        setId(data.id);
        form.setFieldsValue({
          name: data.name,
          machineType: data.machineType,
          connectType: data.connectType,
          sqlType: data.sqlType,
          dbHost: data.dbHost,
          dbPort: data.dbPort,
          dbDatabase: data.dbDatabase,
          dbUsername: data.dbUsername,
          dbPassword: data.dbPassword,
          cronTime: data.cronTime,
        });
      } else setIsEditable(false);
    } catch (error) {
      console.error('Error fetching machine data:', error);
    }
  };

  return (
    <StyledForm form={form} onFinish={handleSave}>
      <PageHeader title={titleHeader} />

      <Form.Item name="configType" label="Configuration Type">
        <Radio.Group onChange={handleConfigTypeChange} value={configType}>
          <Radio value="manual">Manual Config</Radio>
          <Radio value="auto">Auto Config</Radio>
        </Radio.Group>
      </Form.Item>

      {configType === 'manual' && (
        <Form.Item name="deviceType" label="Timekeeping Device Type">
          <Select onChange={handleDeviceTypeChange}>
            <Option value={DeviceType.CAMERAAI}>Camera AI</Option>
            <Option value={DeviceType.FACEID}>Face ID</Option>
            <Option value={DeviceType.ONLINE}>Online</Option>
            <Option value={DeviceType.MACHINE}>Machine</Option>
          </Select>
        </Form.Item>
      )}

      {(deviceType === DeviceType.FACEID || deviceType === DeviceType.MACHINE) && (
        <ConfigDevice
          deviceType={deviceType}
          fetchMachineData={fetchMachineData}
          setMachineType={setMachineType}
        />
      )}

      <AddAppModal visible={showAddAppModal} onClose={() => setShowAddAppModal(false)} />

      {(deviceType === DeviceType.FACEID || deviceType === DeviceType.MACHINE) && (
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Save Config
          </Button>
        </Form.Item>
      )}
    </StyledForm>
  );
};

const ConfigDevice: React.FC<{
  deviceType: DeviceType;
  fetchMachineData: (machineType: string) => void;
  setMachineType: any;
}> = ({ deviceType, fetchMachineData, setMachineType }) => {
  const formItems = [
    { name: 'name', label: 'Name', type: 'text' },
    {
      name: 'machineType',
      label: 'Machine Type',
      type: 'select',
      options:
        deviceType === DeviceType.FACEID
          ? [{ value: 'hkvision', label: 'FaceID (HKVision)' }]
          : [{ value: 'mita_pro_v2', label: 'Vân tay (Mita Pro V2)' }],
    },
    { name: 'connectType', label: 'Connect Type', type: 'text' },
    {
      name: 'sqlType',
      label: 'SQL Type',
      type: 'select',
      options: [
        { value: 'mssql', label: 'SQL' },
        { value: 'mysql', label: 'MySQL' },
      ],
    },
    { name: 'dbHost', label: 'IP', type: 'text' },
    { name: 'dbPort', label: 'Port', type: 'number' },
    { name: 'dbDatabase', label: 'Database Name', type: 'text' },
    { name: 'dbUsername', label: 'Database Username', type: 'text' },
    { name: 'dbPassword', label: 'Database Password', type: 'password' },
    { name: 'cronTime', label: 'Cron Time', type: 'text' },
  ];

  useEffect(() => {
    if (deviceType === DeviceType.FACEID) {
      fetchMachineData('hkvision');
    }
    if (deviceType === DeviceType.MACHINE) {
      fetchMachineData('mita_pro_v2');
    }
  }, [deviceType]);

  return (
    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '16px' }}>
      {formItems.map((item) => (
        <Form.Item
          key={item.name}
          name={item.name}
          label={item.label}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          {item.type === 'select' ? (
            <Select defaultValue={item?.options?.[0]?.value}>
              {item?.options?.map((option) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          ) : (
            <Input type={item.type} />
          )}
        </Form.Item>
      ))}
    </div>
  );
};

const AddAppModal: React.FC<{ visible: boolean; onClose: () => void }> = ({ visible, onClose }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation(['masterConfig', 'validation', 'action', 'employee']);
  const dispatch = useDispatch();

  const onFinish = (values: any) => {
    dispatch(
      postAppCameraAI({
        ...values,
        callBack: () => {},
      }),
    );
    onClose();
    form.resetFields();
  };

  return (
    <SmallCustomModal
      visible={visible}
      centered
      onCancel={() => {
        form.resetFields();
        onClose();
      }}
      title={t('manage_app.create_app')}
      footer={[
        <InfoButtonCancel
          onClick={() => {
            form.resetFields();
            onClose();
          }}
        >
          {t('employee:employee_manage.cancel')}
        </InfoButtonCancel>,
        <CustomButton form="addNew" key="submit" htmlType="submit">
          {t('employee:employee_relative.btn_save')}
        </CustomButton>,
      ]}
    >
      <Form id="addNew" form={form} name="control-hooks" onFinish={onFinish}>
        <ItemBody>
          <Titles>{t('manage_app.name_app')}</Titles>
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: t('validation:required', {
                  field: t('manage_app.name_app'),
                }),
              },
            ]}
          >
            <Input placeholder={t('manage_app.please_input_name')} />
          </Form.Item>
          <Titles>Client ID</Titles>
          <Form.Item
            name="clientId"
            rules={[
              {
                required: true,
                message: t('validation:required', {
                  field: 'client ID',
                }),
              },
            ]}
          >
            <Input placeholder={t('manage_app.please_input_clientId')} />
          </Form.Item>
          <Titles>Client secret</Titles>
          <Form.Item
            name="clientSecret"
            rules={[
              {
                required: true,
                message: t('validation:required', {
                  field: 'client secret',
                }),
              },
            ]}
          >
            <Input placeholder={t('manage_app.please_input_client_secret')} />
          </Form.Item>
          <Titles>Access token</Titles>
          <Form.Item
            name="accessToken"
            rules={[
              {
                required: true,
                message: t('validation:required', {
                  field: 'access token',
                }),
              },
            ]}
          >
            <Input.TextArea placeholder={t('manage_app.please_input_access_token')} rows={4} />
          </Form.Item>
          <Titles>{t('manage_app.description')}</Titles>
          <Form.Item
            name="description"
            rules={[
              {
                required: true,
                message: t('validation:required', {
                  field: t('manage_app.description'),
                }),
              },
            ]}
          >
            <Input.TextArea placeholder={t('manage_app.please_input_description')} />
          </Form.Item>
        </ItemBody>
      </Form>
    </SmallCustomModal>
  );
};

export default TimeKeepingConfig;

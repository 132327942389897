import { createContext, ReactNode, useContext, useMemo, useState } from 'react';

interface ILayoutDesktopContext {
  collapsedslidebar: boolean;
  onCollapsedSlideBar(collapsed: boolean): void;
}

interface LayoutDesktopProviderProps {
  children: ReactNode;
}

const initialContext: ILayoutDesktopContext = {
  collapsedslidebar: false,
  onCollapsedSlideBar: () => {},
};

const LayoutDesktopContext = createContext<ILayoutDesktopContext>(initialContext);

export const LayoutDesktopProvider = ({ children }: LayoutDesktopProviderProps) => {
  const [collapsedslidebar, setCollapsedSlideBar] = useState(false);
  const value = useMemo(() => {
    const onCollapsedSlideBar = (collapsed: boolean) => {
      setCollapsedSlideBar(collapsed);
    };

    return {
      collapsedslidebar,
      onCollapsedSlideBar,
    };
  }, [collapsedslidebar]);
  return <LayoutDesktopContext.Provider value={value}>{children}</LayoutDesktopContext.Provider>;
};

export const LayoutDesktopConsumer = ({
  children,
}: {
  children: (context: ILayoutDesktopContext) => ReactNode;
}) => {
  return (
    <LayoutDesktopContext.Consumer>
      {(context) => {
        return children(context);
      }}
    </LayoutDesktopContext.Consumer>
  );
};

export const useLayoutDesktopContext = () => useContext(LayoutDesktopContext);

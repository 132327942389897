import { useTranslation } from 'react-i18next';
import EmployeeLists from './EmployeeLists';

const AssignTrainee = ({
  selectedUser,
  setSelectedUser,
  inputData,
  viewMode,
  control,
  getValues,
  setValue,
  reset,
  setDataResult,
}) => {
  const { t } = useTranslation(['trainningManagement']);

  return (
    <EmployeeLists
      setValue={setValue}
      reset={reset}
      control={control}
      getValues={getValues}
      setSelectedUser={setSelectedUser}
      inputData={inputData}
      viewMode={viewMode}
      title={t('course_management.assign_trainees')}
      addTitle={t('course_management.add_trainees')}
      setDataResult={setDataResult}
    />
  );
};

export default AssignTrainee;

import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Row, Col } from 'antd';
import Select from 'react-select';

import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'app/hooks';
import { selectConfiguration } from 'features/configuration/configurationSlice';
import { selectPermissions } from 'features/auth/authSlice';
import { selectFormatDate } from 'app/commonRedux/appSlice';

import { PERMISSION_PERSONNEL_MANAGE } from 'constants/permissions';
import { DATE_FORMAT } from 'constants/commons';
import { REGEX_NUMBER, REGEX_ALPHANUMERIC } from 'constants/regex';

import CustomInput from 'styles/inputStyled';
import CustomFormItem from 'styles/formStyled';
import CustomDatePicker from 'styles/datePickerStyled';

const PersonnelInfomationItems = (props: any) => {
  const { isMyProfile, isRequriedCheckSendRequest, isRequriedCheckApprove, status, formData } =
    props;
  const { t } = useTranslation(['validation', 'employee']);

  const dataFormatDate = useAppSelector(selectFormatDate) || DATE_FORMAT;
  const dataPermissions = useAppSelector(selectPermissions);
  const dataConfiguration = useAppSelector(selectConfiguration);

  const [isFullEdit, setIsFullEdit] = useState(isMyProfile);

  useEffect(() => {
    if (isRequriedCheckSendRequest || isRequriedCheckApprove) {
      formData.validateFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRequriedCheckSendRequest, isRequriedCheckApprove]);

  useEffect(() => {
    if (
      dataPermissions &&
      dataPermissions.includes(PERMISSION_PERSONNEL_MANAGE.PERSONNEL_UPDATE_RECORD)
    ) {
      setIsFullEdit(false);
    } else {
      setIsFullEdit(isMyProfile);
    }
  }, [dataPermissions, isMyProfile]);

  const disabledBirthDate = (_Current: any) => {
    return _Current && _Current > moment().subtract(16, 'year').endOf('day');
  };

  const disabledFutureDate = (_Current: any) => {
    return _Current && _Current > moment().endOf('day');
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col md={6} xs={24}>
          <CustomFormItem
            label={t('employee:personnel_information_fields.first_name')}
            required={true}
            name="firstName"
            rules={[
              {
                // required: isRequriedCheckSendRequest || isRequriedCheckApprove,
                required: true,
                message: t('HRM_VAD_REQUIRED', {
                  field: t('employee:personnel_information_fields.first_name'),
                }),
              },
              {
                pattern: new RegExp(REGEX_ALPHANUMERIC),
                message: t('HRM_VAD_FORMAT', {
                  field: t('employee:personnel_information_fields.first_name'),
                  // ?.toString()
                  // ?.toLowerCase(),
                }),
              },
              {
                whitespace: true,
                message: t('HRM_VAD_BLANK_CHARACTERS', {
                  field: t('employee:personnel_information_fields.first_name'),
                }),
              },
            ]}
          >
            <CustomInput
              disabled={status === 'deleted' || isFullEdit ? true : false}
              placeholder={t('employee:personnel_information_fields.first_name_placeholder')}
            />
          </CustomFormItem>
        </Col>
        <Col md={6} xs={24}>
          <CustomFormItem
            label={t('employee:personnel_information_fields.last_name')}
            required={true}
            name="lastName"
            rules={[
              {
                // required: isRequriedCheckSendRequest || isRequriedCheckApprove,
                required: true,
                message: t('HRM_VAD_REQUIRED', {
                  field: t('employee:personnel_information_fields.last_name'),
                }),
              },
              {
                pattern: new RegExp(REGEX_ALPHANUMERIC),
                message: t('HRM_VAD_FORMAT', {
                  field: t('employee:personnel_information_fields.last_name'),
                }),
              },
              {
                whitespace: true,
                message: t('HRM_VAD_BLANK_CHARACTERS', {
                  field: t('employee:personnel_information_fields.last_name'),
                }),
              },
            ]}
          >
            <CustomInput
              disabled={status === 'deleted' || isFullEdit ? true : false}
              placeholder={t('employee:personnel_information_fields.last_name_placeholder')}
            />
          </CustomFormItem>
        </Col>
        <Col md={12} xs={24}>
          <CustomFormItem
            label={t('employee:personnel_information_fields.date_of_birth')}
            required={false}
            name="dob"
          >
            <CustomDatePicker
              disabled={status === 'deleted' ? true : false}
              placeholder={dataFormatDate.toString().toLowerCase()}
              format={dataFormatDate}
              disabledDate={disabledBirthDate}
            />
          </CustomFormItem>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col md={12} xs={24}>
          <CustomFormItem
            label={t('employee:personnel_information_fields.phone_number')}
            required={false}
            name="phoneNumber"
            rules={[
              {
                pattern: new RegExp(REGEX_NUMBER),
                message: t('HRM_VAD_FORMAT', {
                  field: t('employee:personnel_information_fields.phone_number'),
                }),
              },
              {
                max: 30,
                message: t('HRM_VAD_MAXIMUM_CHARACTERS', {
                  field: t('employee:personnel_information_fields.phone_number'),
                  numberLeast: 30,
                }),
              },
              {
                whitespace: true,
                message: t('HRM_VAD_BLANK_CHARACTERS', {
                  field: t('employee:personnel_information_fields.phone_number'),
                }),
              },
            ]}
          >
            <CustomInput
              disabled={status === 'deleted' ? true : false}
              placeholder={t('employee:personnel_information_fields.phone_number_placeholder')}
            />
          </CustomFormItem>
        </Col>
        <Col md={12} xs={24}>
          <CustomFormItem
            label={t('employee:personnel_information_fields.personal_email')}
            required={true}
            name="personalEmail"
            rules={[
              {
                // required: isRequriedCheckSendRequest || isRequriedCheckApprove,
                required: true,
                message: t('HRM_VAD_REQUIRED', {
                  field: t('employee:personnel_information_fields.personal_email'),
                }),
              },
              {
                type: 'email',
                message: t('HRM_VAD_FORMAT', {
                  field: t('employee:personnel_information_fields.personal_email'),
                }),
              },
              {
                min: 6,
                message: t('HRM_VAD_LEAST_CHARACTERS', {
                  field: t('employee:personnel_information_fields.personal_email'),
                  numberLeast: 6,
                }),
              },
              {
                max: 64,
                message: t('HRM_VAD_MAXIMUM_CHARACTERS', {
                  field: t('employee:personnel_information_fields.personal_email'),
                  numberLeast: 64,
                }),
              },
              {
                whitespace: true,
                message: t('HRM_VAD_BLANK_CHARACTERS', {
                  field: t('employee:personnel_information_fields.personal_email'),
                }),
              },
            ]}
          >
            <CustomInput
              disabled={status === 'deleted' ? true : false}
              placeholder={t('employee:personnel_information_fields.personal_email_placeholder')}
            />
          </CustomFormItem>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col md={12} xs={24}>
          <CustomFormItem
            label={t('employee:personnel_information_fields.gender')}
            required={false}
            name="sex"
          >
            <Select
              isDisabled={status === 'deleted' ? true : false}
              placeholder={t('employee:personnel_information_fields.gender_placeholder')}
              maxMenuHeight={200}
              options={dataConfiguration.dataSex ? dataConfiguration.dataSex : []}
              cacheOptions
              defaultOptions
            />
          </CustomFormItem>
        </Col>
        <Col md={12} xs={24}>
          <CustomFormItem
            label={t('employee:personnel_information_fields.place_of_birth')}
            required={false}
            name="placeOfBirth"
            rules={[
              {
                pattern: new RegExp(REGEX_ALPHANUMERIC),
                message: t('HRM_VAD_FORMAT', {
                  field: t('employee:personnel_information_fields.place_of_birth'),
                }),
              },
              {
                whitespace: true,
                message: t('HRM_VAD_BLANK_CHARACTERS', {
                  field: t('employee:personnel_information_fields.place_of_birth'),
                }),
              },
            ]}
          >
            <CustomInput
              disabled={status === 'deleted' || isFullEdit ? true : false}
              placeholder={t('employee:personnel_information_fields.place_of_birth_placeholder')}
            />
          </CustomFormItem>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col md={12} xs={24}>
          <CustomFormItem
            label={t('employee:personnel_information_fields.hometown')}
            required={false}
            name="originalPlace"
            rules={[
              {
                pattern: new RegExp(REGEX_ALPHANUMERIC),
                message: `${t('HRM_VAD_FORMAT', {
                  field: t('employee:personnel_information_fields.hometown'),
                })}`,
              },
              {
                whitespace: true,
                message: t('HRM_VAD_BLANK_CHARACTERS', {
                  field: t('employee:personnel_information_fields.hometown'),
                }),
              },
            ]}
          >
            <CustomInput
              placeholder={t('employee:personnel_information_fields.hometown_placeholder')}
              disabled={status === 'deleted' || isFullEdit ? true : false}
            />
          </CustomFormItem>
        </Col>
        <Col md={12} xs={24}>
          <CustomFormItem
            label={t('employee:personnel_information_fields.marital_status')}
            required={false}
            name="maritalStatus"
          >
            <Select
              isDisabled={status === 'deleted' ? true : false}
              placeholder={t('employee:personnel_information_fields.marital_status_placeholder')}
              maxMenuHeight={200}
              options={
                dataConfiguration.dataMaritalStatus ? dataConfiguration.dataMaritalStatus : []
              }
              cacheOptions
              defaultOptions
            />
          </CustomFormItem>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col md={12} xs={24}>
          <CustomFormItem
            label={t('employee:personnel_information_fields.permanent_address')}
            required={false}
            name="permanentAddress"
            rules={[
              {
                whitespace: true,
                message: t('HRM_VAD_BLANK_CHARACTERS', {
                  field: t('employee:personnel_information_fields.permanent_address'),
                }),
              },
            ]}
          >
            <CustomInput
              disabled={status === 'deleted' ? true : false}
              placeholder={t('employee:personnel_information_fields.permanent_address_placeholder')}
            />
          </CustomFormItem>
        </Col>
        <Col md={12} xs={24}>
          <CustomFormItem
            label={t('employee:personnel_information_fields.residential_address')}
            required={false}
            name="temporaryAddress"
            rules={[
              {
                whitespace: true,
                message: t('HRM_VAD_BLANK_CHARACTERS', {
                  field: t('employee:personnel_information_fields.residential_address'),
                }),
              },
            ]}
          >
            <CustomInput
              disabled={status === 'deleted' ? true : false}
              placeholder={t(
                'employee:personnel_information_fields.residential_address_placeholder',
              )}
            />
          </CustomFormItem>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col md={12} xs={24}>
          <CustomFormItem
            label={t('employee:personnel_information_fields.nationality')}
            required={false}
            name="nationality"
            rules={[
              {
                whitespace: true,
                message: t('HRM_VAD_BLANK_CHARACTERS', {
                  field: t('employee:personnel_information_fields.nationality'),
                }),
              },
            ]}
          >
            <CustomInput
              placeholder={t('employee:personnel_information_fields.nationality_placeholder')}
              disabled={status === 'deleted' || isFullEdit ? true : false}
            />
          </CustomFormItem>
        </Col>
        <Col md={6} xs={24}>
          <CustomFormItem
            label={t('employee:personnel_information_fields.religion')}
            required={false}
            name="religion"
          >
            <Select
              isDisabled={status === 'deleted' ? true : false}
              placeholder={t('employee:personnel_information_fields.religion_placeholder')}
              maxMenuHeight={200}
              options={dataConfiguration.dataReligion ? dataConfiguration.dataReligion : []}
              cacheOptions
              defaultOptions
            />
          </CustomFormItem>
        </Col>
        <Col md={6} xs={24}>
          <CustomFormItem
            label={t('employee:personnel_information_fields.ethnicity')}
            name="ethnicity"
          >
            <Select
              isDisabled={status === 'deleted' || isFullEdit ? true : false}
              placeholder={t('employee:personnel_information_fields.ethnicity_placeholder')}
              maxMenuHeight={200}
              options={dataConfiguration.dataEthnicity ? dataConfiguration.dataEthnicity : []}
              cacheOptions
              defaultOptions
            />
          </CustomFormItem>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col md={12} xs={24}>
          <CustomFormItem
            label={t('employee:personnel_information_fields.identity_card_number')}
            required={false}
            name="citizenId"
            rules={[
              {
                pattern: new RegExp(REGEX_NUMBER),
                message: t('HRM_VAD_FORMAT', {
                  field: t('employee:personnel_information_fields.identity_card_number'),
                }),
              },
              {
                max: 30,
                message: t('HRM_VAD_MAXIMUM_CHARACTERS', {
                  field: t('employee:personnel_information_fields.identity_card_number'),
                  numberLeast: 30,
                }),
              },
              {
                whitespace: true,
                message: t('HRM_VAD_BLANK_CHARACTERS', {
                  field: t('employee:personnel_information_fields.identity_card_number'),
                }),
              },
            ]}
          >
            <CustomInput
              placeholder={t(
                'employee:personnel_information_fields.identity_card_number_placeholder',
              )}
              disabled={status === 'deleted' || isFullEdit ? true : false}
            />
          </CustomFormItem>
        </Col>
        <Col md={6} xs={24}>
          <CustomFormItem
            label={t('employee:personnel_information_fields.date_of_issue')}
            required={false}
            name="cidIssuedDate"
          >
            <CustomDatePicker
              placeholder={dataFormatDate.toString().toLowerCase()}
              format={dataFormatDate}
              disabledDate={disabledFutureDate}
              disabled={status === 'deleted' || isFullEdit ? true : false}
            />
          </CustomFormItem>
        </Col>
        <Col md={6} xs={24}>
          <CustomFormItem
            label={t('employee:personnel_information_fields.place_of_issue')}
            required={false}
            name="cidPlaceOfIssue"
            rules={[
              {
                pattern: new RegExp(REGEX_ALPHANUMERIC),
                message: t('HRM_VAD_FORMAT', {
                  field: t('employee:personnel_information_fields.place_of_issue'),
                }),
              },
              {
                whitespace: true,
                message: t('HRM_VAD_BLANK_CHARACTERS', {
                  field: t('employee:personnel_information_fields.place_of_issue'),
                }),
              },
            ]}
          >
            <CustomInput
              placeholder={t('employee:personnel_information_fields.place_of_issue_placeholder')}
              disabled={status === 'deleted' || isFullEdit ? true : false}
            />
          </CustomFormItem>
        </Col>
      </Row>
    </>
  );
};

export default PersonnelInfomationItems;

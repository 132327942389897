import React from 'react';
import { Collapse } from 'antd';
import styled from 'styled-components';
import ITVTable from 'components/ITVTable';
import { useTranslation } from 'react-i18next';

const { Panel } = Collapse;

const InterviewQuestion = ({ data }) => {
  const { t } = useTranslation(['recruitment']);
  const { questions = [] } = data;

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 50,
      maxWitdh: 50,
      align: 'center',
      render: (text, record, index) => index + 1,
    },
    {
      title: t('recruitment:interview_question'),
      dataIndex: 'name',
      key: 'name',
    },
  ];

  return (
    <StyledCollapse>
      {questions?.map((question, index) => (
        <Panel header={question.name} key={index}>
          <ITVTable
            limit={1000}
            data={question.questions.map((q, i) => ({ key: i, name: q }))}
            columns={columns}
            showFooter={false}
            isRowSelect={false}
          />
        </Panel>
      ))}
    </StyledCollapse>
  );
};

export default InterviewQuestion;

const StyledCollapse = styled(Collapse)`
  margin-bottom: 16px;
`;

export interface IEmployee {
  user: string;
  userName: string;
  statusRequest: { pending: number; approved: number; rejected: number };
  email: string;
  requestAdjustTime: [];
  requestLeave: [];
  requestOverTime: [];
}

export enum Page {
  MANAGE_VIEW = 'manage-view',
  TIMESHEET_APPROVAL = 'timesheet_approval',
}

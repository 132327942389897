import { Form, Modal } from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';

import { useAppSelector } from 'app/hooks';
import { selectPermissions, selectUserLoginId } from 'features/auth/authSlice';
import { selectConfiguration } from 'features/configuration/configurationSlice';
import { useTranslation } from 'react-i18next';

import {
  createEmployee,
  saveAndApproveEmployee,
  saveAndRequestApproveEmployee,
} from 'features/employee/employeeAction';
import { useDispatch } from 'react-redux';

import { EMPLOYEE_KEY_MENU, INITIAL_EMPLOYEE } from 'features/employee/constants/common';

import FooterButtons from 'features/employee/components//FooterButtons';
import EmployeeInformationFields from 'features/employee/components/EmployeeInformationFields';
import PersonalInformationFields from 'features/employee/components/PersonalInformationFields';
import SideBarMenu from 'features/employee/components/SideBarMenu';
import SkillInformationFields from 'features/employee/components/SkillInformationFields';
import {
  EmployeeAddModalContent,
  EmployeeAddModalContentLeft,
  EmployeeAddModalContentRight,
  EmployeeAddModalDivider,
  EmployeeModal,
  EmployeeModalForm,
} from './styled';
import CertificatesFields from 'features/employee/components/CertificatesFields';

const {
  employeeMenuKey,
  personalMenuKey,
  skillMenuKey,
  historyMenuKey,
  allowanceMenuKey,
  bonusMenuKey,
  salaryMenuKey,
  dependantsMenuKey,
  certificatesMenuKey,
} = EMPLOYEE_KEY_MENU;

interface EmployeeAddModalProps {
  isVisibleEmployeeAdd: boolean;
  onClose: () => void;
  paramSortFilter?: any;
}

const EmployeeAddModal = (props: EmployeeAddModalProps) => {
  const { isVisibleEmployeeAdd, onClose, paramSortFilter } = props;
  const dispatch = useDispatch();
  const childRefEmployeeInformationFields = useRef<any>();
  const [form] = Form.useForm();
  const { t } = useTranslation(['validation']);

  const dataConfiguration = useAppSelector(selectConfiguration);
  const dataUserLoginId = useAppSelector(selectUserLoginId);
  const dataPermissions = useAppSelector(selectPermissions);

  const [openKeys, setOpenKeys] = useState(employeeMenuKey);
  const [employeeInit, setEmployeeInit] = useState(INITIAL_EMPLOYEE);
  const [isRequriedCheckSave, setIsRequriedCheckSave] = useState(false);
  const [isRequriedCheckSendRequest, setIsRequriedCheckSendRequest] = useState(false);
  const [isRequriedCheckApprove, setIsRequriedCheckApprove] = useState(false);
  const [isInvalidData, setIsInvalidData] = useState(false);

  const loadData = () => {
    const initValues =
      dataConfiguration.dataOnBoardStatus &&
      dataConfiguration.dataLevel &&
      dataConfiguration.dataSex
        ? {
            ...INITIAL_EMPLOYEE,
            onBoardStatus: dataConfiguration.dataOnBoardStatus[0],
            level: dataConfiguration.dataLevel[0],
            sex: dataConfiguration.dataSex[0],
            location: dataConfiguration?.dataLocation?.[0],
          }
        : INITIAL_EMPLOYEE;
    setEmployeeInit(initValues);
    form.setFieldsValue(initValues);
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataConfiguration]);

  const initDataSubmit = (values: any) => {
    const departmentConvert = values?.departments?.value
      ? {
          id: values?.departments?.value,
          name: values?.departments?.label,
        }
      : null;
    const managerConvert = values?.managers?.value
      ? {
          id: values?.managers?.value,
          name: values?.managers?.label,
        }
      : null;
    const positionConvert = values?.positions?.value
      ? {
          id: values?.positions?.value,
          name: values?.positions?.label,
        }
      : null;
    const roleConvert = values?.roles?.value
      ? {
          id: values?.roles?.value,
          name: values?.roles?.label,
        }
      : null;
    const tempEmployee = {
      ...values,
      startDate: values.startDate ? moment(values.startDate).toISOString() : null,
      endDate: values.endDate ? moment(values.endDate).toISOString() : null,
      dob: values.dob ? moment(values.dob).toISOString() : null,
      cidIssuedDate: values.cidIssuedDate ? moment(values.cidIssuedDate).toISOString() : null,
      contractStartDate: values.contractStartDate
        ? moment(values.contractStartDate).toISOString()
        : null,
      contractExpirationDate: values.contractExpirationDate
        ? moment(values.contractExpirationDate).toISOString()
        : null,
      trainingPeriodFrom: values.trainingPeriodFrom
        ? moment(values.trainingPeriodFrom).toISOString()
        : null,
      trainingPeriodTo: values.trainingPeriodTo
        ? moment(values.trainingPeriodTo).toISOString()
        : null,

      onBoardStatusId: values?.onBoardStatus?.value?.toString(),
      levelId: values?.level?.value?.toString(),
      contractTypeId: values?.contractType?.value?.toString(),

      sexId: values?.sex?.value?.toString(),
      maritalStatusId: values?.maritalStatus?.value?.toString(),
      religionId: values?.religion?.value?.toString(),
      ethnicityId: values?.ethnicity?.value?.toString(),
      degreeId: values?.degree?.value?.toString(),

      departments: departmentConvert ? [departmentConvert] : [],
      managers: managerConvert ? [managerConvert] : [],
      positions: positionConvert ? [positionConvert] : [],
      roles: roleConvert ? [roleConvert] : [],
      locationId: values?.location?.value,

      skill: employeeInit.skill,
      relative: employeeInit.relative,
    };
    return tempEmployee;
  };

  const handleClickMenu = (keys: any) => {
    setOpenKeys(keys.key);
  };

  const handleResetAll = () => {
    if (childRefEmployeeInformationFields.current) {
      childRefEmployeeInformationFields.current.resetStyleSelect();
    }
    setOpenKeys(employeeMenuKey);
    setIsRequriedCheckSave(false);
    setIsRequriedCheckSendRequest(false);
    setIsRequriedCheckApprove(false);
    form.resetFields();
    loadData();
  };

  const handleClose = () => {
    handleResetAll();
    onClose();
  };

  const handleSubmit = (typeSubmit: string, values: any) => {
    values.firstName = values.firstName.trim(); // handle trim before post api
    values.lastName = values.lastName.trim();
    const dataSubmit = initDataSubmit(values);
    const { employeeStatus, ...newDataSubmit } = dataSubmit;
    switch (typeSubmit) {
      case 'save':
        dispatch(
          createEmployee({
            dataRequest: newDataSubmit,
            paramSortFilter: paramSortFilter,
          }),
        );
        break;
      case 'send_request_approve':
        dispatch(
          saveAndRequestApproveEmployee({
            dataSave: newDataSubmit,
            dataSendRequest: {
              idProfile: null,
              idUserCreate: dataUserLoginId,
              messageRequest: '',
            },
            paramSortFilter: paramSortFilter,
          }),
        );
        break;
      case 'approve':
        dispatch(
          saveAndApproveEmployee({
            dataSave: newDataSubmit,
            dataApprove: {
              idProfile: null,
              idUserCreate: dataUserLoginId,
              messageRequest: '',
              isApproved: true,
            },
            paramSortFilter: paramSortFilter,
          }),
        );
        break;
      default:
        break;
    }
  };

  const handleSave = () => {
    setIsRequriedCheckSave(true);
    setIsRequriedCheckSendRequest(false);
    setIsRequriedCheckApprove(false);
    setIsInvalidData(false);
    form
      .validateFields()
      .then((success) => {
        //console.log('Validate success', success);
        handleSubmit('save', success);
        handleClose();
      })
      .catch((error) => {
        //console.log('Validate error', error);
        if (error?.errorFields?.length > 0) {
          Modal.error({
            title: t('modal:message_invalid'),
          });
          setIsInvalidData(true);
        } else {
          handleSubmit('save', error?.values);
          handleClose();
        }
      });
  };

  const handleSaveAndSendRequestApprove = () => {
    setIsRequriedCheckSendRequest(true);
    setIsRequriedCheckSave(false);
    setIsRequriedCheckApprove(false);
    setIsInvalidData(false);
    form
      .validateFields()
      .then((success) => {
        //console.log('Validate success', success);
        handleSubmit('send_request_approve', success);
        handleClose();
      })
      .catch((error) => {
        //console.log('Validate error', error);
        if (error?.errorFields?.length > 0) {
          Modal.error({
            title: t('modal:message_invalid'),
          });
          setIsInvalidData(true);
        } else {
          handleSubmit('send_request_approve', error?.values);
          handleClose();
        }
      });
  };

  const handleSaveAndApproveProfile = () => {
    setIsRequriedCheckApprove(true);
    setIsRequriedCheckSave(false);
    setIsRequriedCheckSendRequest(false);
    setIsInvalidData(false);
    form
      .validateFields()
      .then((success) => {
        //console.log('Validate success', success);
        handleSubmit('approve', success);
        handleClose();
      })
      .catch((error) => {
        //console.log('Validate error', error);
        if (error?.errorFields?.length > 0) {
          Modal.error({
            title: t('modal:message_invalid'),
          });
          setIsInvalidData(true);
        } else {
          handleSubmit('approve', error?.values);
          handleClose();
        }
      });
  };

  return (
    <>
      <EmployeeModal
        visible={isVisibleEmployeeAdd}
        maskClosable={false}
        onCancel={handleClose}
        width={1000}
        style={{ top: 20 }}
        footer={
          <FooterButtons
            profileStatus={'IsAddEmployee'}
            permissions={dataPermissions}
            handleSave={handleSave}
            handleResetAll={handleResetAll}
            handleSaveAndSendRequestApprove={handleSaveAndSendRequestApprove}
            handleSaveAndApproveProfile={handleSaveAndApproveProfile}
            handleSendRequestApprove={() => null}
            handleApproveProfile={() => null}
            handleRejectProfile={() => null}
            handleDeleteProfile={() => null}
          />
        }
      >
        <EmployeeAddModalContent>
          <EmployeeAddModalContentLeft>
            <SideBarMenu
              isShowFullMenu={true}
              isShowHistory={false}
              employeeData={null}
              openKeys={openKeys}
              employeeMenuKey={employeeMenuKey}
              personalMenuKey={personalMenuKey}
              skillMenuKey={skillMenuKey}
              allowanceMenuKey={allowanceMenuKey}
              bonusMenuKey={bonusMenuKey}
              salaryMenuKey={salaryMenuKey}
              dependantsMenuKey={dependantsMenuKey}
              historyMenuKey={historyMenuKey}
              certificatesMenuKey={certificatesMenuKey}
              handleClickMenu={handleClickMenu}
              onCloseModal={handleClose}
            />
          </EmployeeAddModalContentLeft>
          <EmployeeAddModalDivider type="vertical" />
          <EmployeeAddModalContentRight>
            <EmployeeModalForm
              form={form}
              id="EmployeeForm"
              layout="vertical"
              initialValues={employeeInit}
            >
              <EmployeeInformationFields
                isShowComponents={openKeys === employeeMenuKey}
                isRequriedCheckSave={isRequriedCheckSave}
                isRequriedCheckSendRequest={isRequriedCheckSendRequest}
                isRequriedCheckApprove={isRequriedCheckApprove}
                isInvalidData={isInvalidData}
                formData={form}
                ref={childRefEmployeeInformationFields}
              />
              <PersonalInformationFields
                isShowComponents={openKeys === personalMenuKey}
                isMyProfile={false}
                isRequriedCheckSave={isRequriedCheckSave}
                isRequriedCheckSendRequest={isRequriedCheckSendRequest}
                isRequriedCheckApprove={isRequriedCheckApprove}
                employeeData={employeeInit}
                setEmployeeData={setEmployeeInit}
                formData={form}
              />
              <SkillInformationFields
                isShowComponents={openKeys === skillMenuKey}
                isCanUpdate={true}
                employeeData={employeeInit}
                setEmployeeData={setEmployeeInit}
              />
              <CertificatesFields
                isShowComponents={openKeys === certificatesMenuKey}
                isCanUpdate={true}
                employeeData={employeeInit}
                setEmployeeData={setEmployeeInit}
              />
            </EmployeeModalForm>
          </EmployeeAddModalContentRight>
        </EmployeeAddModalContent>
      </EmployeeModal>
    </>
  );
};

export default React.memo(EmployeeAddModal);

import { DeleteTwoTone, PlusOutlined } from '@ant-design/icons';
import { DatePicker, Input, Popconfirm, Select } from 'antd';
import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { selectFormatDate, selectFormatMonth } from 'app/commonRedux/appSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { DatePickerField } from 'components/FormFields';
import ITVTable from 'components/ITVTable';
import MenuAction from 'components/MenuAction';
import { ContractSalaryConfirmStatus, MONTH_YEAR_FORMAT } from 'constants/payroll';
import { getAllDependentsUser, updateDependentsUser } from 'features/employee/employeeAction';
import { actions, selectEmployee } from 'features/employee/employeeSlice';
import CustomButton from 'styles/buttonStyled';
import { Dependent, DependentDetails, RealDependentList } from 'types';
import { LIST_DEPENDENTS } from 'constants/commons';

const DependantsTable = (props: any) => {
  const { employeeData, callBack, monthYear } = props;
  const { t } = useTranslation([
    'validation',
    'menu',
    'action',
    'overtime',
    'timesheet',
    'employee',
    'leaveOff',
  ]);
  const dispatch = useAppDispatch();
  const formatDate = useAppSelector(selectFormatDate);
  const formatMonth = useAppSelector(selectFormatMonth);
  const employee = useAppSelector<any>(selectEmployee);
  const dataAssignDependents = employee?.dataAssignDependents?.result;
  const statusConfirmDependent = employee?.dataAssignDependents?.status;
  const loadingDependents = employee?.loadingDependents;
  const error = employee.error;
  const { setErrorMessage } = actions;
  const { control } = useForm();

  const [sizePage, setSizePage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState<Array<RealDependentList>>([]);
  const [dataOri, setDataOri] = useState<Array<RealDependentList>>([]);
  const [month, setMonth] = useState(monthYear ?? moment().format(MONTH_YEAR_FORMAT));
  const [errMsg, setErrMsg] = useState<any>('');
  const [errList, setErrList] = useState<any>([]);

  useEffect(() => {
    if (error?.fieldErrors?.depedantErrors) {
      const err = JSON.parse(error?.fieldErrors?.depedantErrors);
      const msg = Object.entries(err).map((entry) => entry?.[1]);
      setErrMsg(msg);
      const errorListArray = data
        ?.filter((item: any) => msg?.some((i: any) => i?.index === item?.key))
        ?.map(({ key }: any) => key);
      setErrList(errorListArray);
    }
    return () => {
      dispatch(setErrorMessage(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (employeeData?.id) {
      dispatch(getAllDependentsUser({ userId: employeeData.id, monthYear: month }));
    }
  }, [dispatch, employeeData.id, month]);

  useEffect(() => {
    if (dataAssignDependents?.length > 0) {
      let arr: RealDependentList[] = [];
      dataAssignDependents.forEach((item: Dependent) => {
        let array = item.dependents.map((obj: DependentDetails, index: number) => {
          return {
            ...obj,
            key: index,
            emailParent: item.email,
            userId: item.id,
            fullNameParent: item.fullName,
            employeeIdParent: item.employeeId,
            sumDependents: item.sumDependents,
            isChange: item.isChange,
          };
        });
        arr.push(...array);
      });
      setData(arr);
      setDataOri(arr);
    } else {
      setData([]);
      setDataOri([]);
    }
  }, [dataAssignDependents]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const menu = [
    {
      name: t('action:delete'),
      type: 'delete',
      icon: <DeleteTwoTone twoToneColor="#B7B7B7" />,
      handleClick: (value: any) => {
        let copyArr = JSON.parse(JSON.stringify(data));
        copyArr.splice(
          copyArr.findIndex((item: any) => item?.key === value?.key),
          1,
        );
        setData(copyArr?.map((item: any, index: number) => ({ ...item, key: index })));
      },
    },
  ];

  const disabledDate = (current: moment.Moment | null) => {
    if (!current) return false;

    const startOfMonth = moment(month).startOf('month');
    return current.isBefore(startOfMonth);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const COLUMNS: any = [
    {
      title: t('employee:bonus_information_fields.col_id'),
      dataIndex: 'id',
      key: 'id',
      width: 80,
      minWidth: 80,
      align: 'center',
      render: function (text: any, record: any, index: any) {
        return <div>{index + 1}</div>;
      },
    },
    {
      title: t('employee:dependant_information_fields.col_name'),
      dataIndex: 'fullName',
      key: 'fullName',
      width: 150,
      minWidth: 150,
      ellipsis: true,
      render: function (text: any, record: any, index: any) {
        const trueIndex = index + sizePage * (currentPage - 1);
        return (
          <Input
            maxLength={50}
            placeholder={t('employee:dependant_information_fields.col_name')}
            type={'text'}
            value={record?.fullName ?? ''}
            onChange={(e: any) => {
              let temp1: any = data?.map((obj: any, objIndex: any) =>
                objIndex === trueIndex ? { ...obj, fullName: e?.target?.value } : obj,
              );
              let temp2: any = dataOri?.map((obj: any, objIndex: any) =>
                objIndex === trueIndex ? { ...obj, fullName: e?.target?.value } : obj,
              );
              setData(temp1);
              setDataOri(temp2);
            }}
            disabled={statusConfirmDependent === ContractSalaryConfirmStatus.APPROVED}
          />
        );
      },
    },
    {
      title: t('employee:dependant_information_fields.col_dob'),
      dataIndex: 'dob',
      key: 'dob',
      width: 100,
      render: function (text: any, record: any, index: any) {
        const trueIndex = index + sizePage * (currentPage - 1);
        return (
          <DatePicker
            format={formatDate}
            style={{ width: '100%' }}
            value={record?.dateOfBirth ? moment(record?.dateOfBirth) : null}
            onChange={(value) => {
              let temp1: any = data?.map((obj: any, objIndex: any) =>
                objIndex === trueIndex
                  ? { ...obj, dateOfBirth: value ? moment(value) : null }
                  : obj,
              );
              let temp2: any = dataOri?.map((obj: any, objIndex: any) =>
                objIndex === trueIndex
                  ? { ...obj, dateOfBirth: value ? moment(value) : null }
                  : obj,
              );
              setData(temp1);
              setDataOri(temp2);
            }}
            disabled={statusConfirmDependent === ContractSalaryConfirmStatus.APPROVED}
          />
        );
      },
    },
    {
      title: t('employee:dependant_information_fields.col_tax_number'),
      dataIndex: 'taxNumber',
      key: 'taxNumber',
      width: 100,
      render: function (text: any, record: any, index: any) {
        const trueIndex = index + sizePage * (currentPage - 1);
        return (
          <Input
            maxLength={20}
            style={{ width: '100%' }}
            placeholder={t('employee:dependant_information_fields.col_tax_number')}
            value={record?.PITCode ?? ''}
            onChange={(e: any) => {
              let temp1: any = data?.map((obj: any, objIndex: any) =>
                objIndex === trueIndex ? { ...obj, PITCode: e?.target?.value } : obj,
              );
              let temp2: any = dataOri?.map((obj: any, objIndex: any) =>
                objIndex === trueIndex ? { ...obj, PITCode: e?.target?.value } : obj,
              );
              setData(temp1);
              setDataOri(temp2);
            }}
            disabled={statusConfirmDependent === ContractSalaryConfirmStatus.APPROVED}
          />
        );
      },
    },
    {
      title: t('employee:dependant_information_fields.col_nationality'),
      dataIndex: 'nationality',
      key: 'nationality',
      width: 100,
      render: function (text: any, record: any, index: any) {
        const trueIndex = index + sizePage * (currentPage - 1);
        return (
          <Input
            maxLength={50}
            style={{ width: '100%' }}
            placeholder={t('employee:dependant_information_fields.col_nationality')}
            value={record?.nationality ?? ''}
            onChange={(e: any) => {
              let temp1: any = data?.map((obj: any, objIndex: any) =>
                objIndex === trueIndex ? { ...obj, nationality: e?.target?.value } : obj,
              );
              let temp2: any = dataOri?.map((obj: any, objIndex: any) =>
                objIndex === trueIndex ? { ...obj, nationality: e?.target?.value } : obj,
              );
              setData(temp1);
              setDataOri(temp2);
            }}
            disabled={statusConfirmDependent === ContractSalaryConfirmStatus.APPROVED}
          />
        );
      },
    },
    {
      title: t('employee:dependant_information_fields.col_identity_card'),
      dataIndex: 'identity',
      key: 'identity',
      width: 100,
      render: function (text: any, record: any, index: any) {
        const trueIndex = index + sizePage * (currentPage - 1);
        return (
          <Input
            maxLength={50}
            style={{ width: '100%' }}
            value={record?.identity ?? ''}
            placeholder={t('employee:dependant_information_fields.col_identity_card')}
            onChange={(e: any) => {
              let temp1: any = data?.map((obj: any, objIndex: any) =>
                objIndex === trueIndex ? { ...obj, identity: e?.target?.value } : obj,
              );
              let temp2: any = dataOri?.map((obj: any, objIndex: any) =>
                objIndex === trueIndex ? { ...obj, identity: e?.target?.value } : obj,
              );
              setData(temp1);
              setDataOri(temp2);
            }}
            disabled={statusConfirmDependent === ContractSalaryConfirmStatus.APPROVED}
          />
        );
      },
    },
    {
      title: t('employee:dependant_information_fields.col_relationship'),
      dataIndex: 'relationship',
      key: 'relationship',
      width: 120,
      minWidth: 120,
      ellipsis: true,
      render: function (text: any, record: any, index: any) {
        const depen = LIST_DEPENDENTS.filter((item) => item.value === record?.relationship);
        const trueIndex = index + sizePage * (currentPage - 1);
        return (
          <Select
            options={LIST_DEPENDENTS}
            value={depen.length > 0 ? depen[0].value : ''}
            style={{ width: 100 }}
            maxLength={50}
            onChange={(value: any) => {
              let temp1: any = data?.map((obj: any, objIndex: any) =>
                objIndex === trueIndex ? { ...obj, relationship: value } : obj,
              );
              let temp2: any = dataOri?.map((obj: any, objIndex: any) =>
                objIndex === trueIndex ? { ...obj, relationship: value } : obj,
              );
              setData(temp1);
              setDataOri(temp2);
            }}
            disabled={statusConfirmDependent === ContractSalaryConfirmStatus.APPROVED}
          />
          // <Input
          //   maxLength={50}
          //   value={record?.relationship ?? ''}
          //   placeholder={t('employee:dependant_information_fields.col_relationship')}
          //   type={'text'}
          //   onChange={(e: any) => {
          //     let temp1: any = data?.map((obj: any, objIndex: any) =>
          //       objIndex === trueIndex ? { ...obj, relationship: e?.target?.value } : obj,
          //     );
          //     let temp2: any = dataOri?.map((obj: any, objIndex: any) =>
          //       objIndex === trueIndex ? { ...obj, relationship: e?.target?.value } : obj,
          //     );
          //     setData(temp1);
          //     setDataOri(temp2);
          //   }}
          //   disabled={statusConfirmDependent === ContractSalaryConfirmStatus.APPROVED}
          // />
        );
      },
    },
    {
      title: t('employee:dependant_information_fields.col_start_month'),
      dataIndex: 'startMonth',
      key: 'startMonth',
      width: 120,
      minWidth: 120,
      render: function (text: any, record: any, index: any) {
        const trueIndex = index + sizePage * (currentPage - 1);
        return (
          <DatePicker
            disabledDate={(current) => disabledDate(current)}
            disabled={record?.isNew ? false : true}
            allowClear={false}
            format={formatMonth}
            onChange={(value: any) => {
              let temp1: any = data?.map((obj: any, objIndex: any) =>
                objIndex === trueIndex
                  ? {
                      ...obj,
                      startMonth: value ? moment(value).format(MONTH_YEAR_FORMAT) : null,
                      endMonth: null,
                    }
                  : obj,
              );
              let temp2: any = dataOri?.map((obj: any, objIndex: any) =>
                objIndex === trueIndex
                  ? {
                      ...obj,
                      startMonth: value ? moment(value).format(MONTH_YEAR_FORMAT) : null,
                      endMonth: null,
                    }
                  : obj,
              );
              setData(temp1);
              setDataOri(temp2);
            }}
            value={record?.startMonth ? moment(record?.startMonth) : undefined}
            style={{ width: '100%' }}
            picker="month"
          ></DatePicker>
        );
      },
    },
    {
      title: t('employee:dependant_information_fields.col_end_month'),
      dataIndex: 'endMonth',
      key: 'endMonth',
      width: 120,
      minWidth: 120,
      render: function (text: any, record: any, index: any) {
        const trueIndex = index + sizePage * (currentPage - 1);
        return (
          <DatePicker
            format={formatMonth}
            disabledDate={(currentMonth) =>
              record.isNew ? moment(record.startMonth) > currentMonth : moment() > currentMonth
            }
            onChange={(value: any) => {
              let temp1: any = data?.map((obj: any, objIndex: any) =>
                objIndex === trueIndex
                  ? { ...obj, endMonth: value ? moment(value).format(MONTH_YEAR_FORMAT) : null }
                  : obj,
              );
              let temp2: any = dataOri?.map((obj: any, objIndex: any) =>
                objIndex === trueIndex
                  ? { ...obj, endMonth: value ? moment(value).format(MONTH_YEAR_FORMAT) : null }
                  : obj,
              );
              setData(temp1);
              setDataOri(temp2);
            }}
            value={record?.endMonth ? moment(record?.endMonth) : undefined}
            style={{ width: '100%' }}
            picker="month"
            disabled={statusConfirmDependent === ContractSalaryConfirmStatus.APPROVED}
          ></DatePicker>
        );
      },
    },
  ];

  useEffect(() => {
    if (statusConfirmDependent !== ContractSalaryConfirmStatus.APPROVED) {
      COLUMNS.push({
        title: t('employee:employee_information_fields.action'),
        dataIndex: 'action',
        key: 'action',
        width: 60,
        minWidth: 60,
        align: 'center',
        render: function (text: any, record: any, index: any) {
          return <MenuAction menu={menu} data={record} />;
        },
      });
    }
  }, [COLUMNS, menu, statusConfirmDependent, t]);

  const onAddRow = () => {
    if (data?.length >= 0) {
      const newList = data.concat({
        key: data?.length,
        dateOfBirth: null,
        fullName: '',
        PITCode: '',
        nationality: '',
        identity: '',
        relationship: '',
        startMonth: moment().format(MONTH_YEAR_FORMAT),
        endMonth: null,
        isNew: true,
      });
      setData(newList);
      setDataOri(newList);
    }
  };

  const onSave = () => {
    const updateData = data?.map((item: any) => {
      const { key, isNew, ...rest } = item;
      return {
        ...rest,
      };
    });
    const deleteData = dataOri
      ?.filter((item: any) => !updateData?.some((i: any) => i?.id === item?.id))
      .map((item: any) => {
        const { key, isNew, ...rest } = item;
        return {
          ...rest,
          deleteFlag: true,
        };
      })
      .filter((item: any) => item.id);

    const childData = [...updateData, ...deleteData]?.map((item: RealDependentList) => {
      const {
        emailParent,
        userId,
        fullNameParent,
        employeeIdParent,
        sumDependents,
        isChange,
        ...rest
      } = item;
      return {
        ...rest,
      };
    });

    const checkError = data?.filter((item: any) => item?.fullName?.trim()?.length === 0);
    if (checkError?.length) {
      const error = data?.filter((item: any) => checkError?.some((i: any) => i?.key === item?.key));
      const errorMessage = error.map((item: any) => ({
        index: item?.index,
        message: [
          `${t('HRM_VAD_REQUIRED', {
            field: t('employee:dependant_information_fields.col_name'),
          })}`,
        ],
        name: '',
      }));
      setErrMsg(errorMessage);
      const errorListArray = error?.map(({ key }: any) => key);
      setErrList(errorListArray);
      return;
    }

    dispatch(
      updateDependentsUser({
        id: employeeData?.id,
        data: childData,
        monthYear: month,
        callBack: () => {
          dispatch(getAllDependentsUser({ userId: employeeData?.id, monthYear: month }));
          callBack && callBack();
        },
      }),
    );
  };

  const handleChangeMonth = (value: Moment | null) => {
    if (value) {
      setMonth(moment(value).format(MONTH_YEAR_FORMAT));
    }
  };

  return (
    <div style={{ backgroundColor: '#fff', padding: 10, display: 'grid' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <DatePickerField
          name="monthYear"
          control={control}
          picker="month"
          allowClear={false}
          value={moment(month)}
          defaultValue={moment(month)}
          onChange={handleChangeMonth}
          disabledDate={(currentMonth) => moment() < currentMonth}
        />
        {statusConfirmDependent !== ContractSalaryConfirmStatus.APPROVED && (
          <CustomButton icon={<PlusOutlined />} onClick={onAddRow}>
            {t('action:add_new')}
          </CustomButton>
        )}
      </div>
      <div style={{ overflow: 'scroll' }}>
        <ITVTable
          loading={loadingDependents}
          columns={COLUMNS}
          data={data}
          height={350}
          scroll={{ x: 100, y: 100 }}
          isScroll={true}
          isRowSelect={false}
          setOffset={setCurrentPage}
          offset={currentPage}
          setLimit={setSizePage}
          limit={sizePage}
          width={200}
          errorList={errList}
          dis
        />
      </div>
      {errMsg && (
        <div style={{ color: 'red', height: 80, overflowY: 'scroll' }}>
          {errMsg?.flat(1)?.map((item: any, i: any, arr: any) => {
            let divider = i < arr.length - 1 && <div></div>;
            return (
              <span key={i}>
                <div>{item?.name}</div>
                <ul style={{ listStylePosition: 'inside' }}>
                  {item?.message?.map((childItem: any) => (
                    <li style={{ listStyleType: 'disc' }}>{childItem}</li>
                  ))}
                </ul>
                {divider}
              </span>
            );
          })}
        </div>
      )}
      {statusConfirmDependent !== ContractSalaryConfirmStatus.APPROVED && (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Popconfirm
            title={t('leaveOff:modal_item.are_you_sure_save')}
            onConfirm={() => {
              setErrList([]);
              setErrMsg(null);
              onSave();
            }}
            okText={t('Yes')}
            cancelText={t('No')}
            placement="topRight"
          >
            <CustomButton>{t('action:save')}</CustomButton>
          </Popconfirm>
        </div>
      )}
    </div>
  );
};

export default DependantsTable;

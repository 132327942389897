import { useState, useEffect, forwardRef, useImperativeHandle, ChangeEvent } from 'react';
import Icon from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import IconSearch from 'assets/icons/ic_search_gray.svg';

import { removeAccents } from 'utils/text';
import {
  ContainerStyled,
  FooterStyled,
  TitleStyled,
  SearchStyled,
  IconImgStyled,
  CheckoxGroupStyled,
  CheckoxStyled,
  DoneButton,
  CancelButton,
} from 'features/employee/components/AddColumn/styled';
import { useAppSelector } from 'app/hooks';
import { selectCurrencyFormat } from 'app/commonRedux/appSlice';

const IconSearchComponents = <Icon component={() => <IconImgStyled src={IconSearch} alt="" />} />;

const AddColumn = (props: any, ref: any) => {
  const { handleVisibleAddColumn, addColumns, isHistory, colEmployeeTableRoot } = props;
  const { t } = useTranslation(['employee', 'payroll', 'action']);
  const handleHideColumn = () => {
    if (isHistory) {
      return colEmployeeTableRoot?.filter(
        (item: any) =>
          item?.value !== 'permanentAddress' &&
          item?.value !== 'onBoardStatusValue' &&
          item?.value !== 'managerName',
      );
    }
    return colEmployeeTableRoot;
  };

  const [colEmployeeTable, setcolEmployeeTable] = useState(handleHideColumn());
  const [lstItemCheck, setlstItemCheck] = useState<any>(() => {
    return colEmployeeTable.map((item: any) => ({ value: item.value, label: item.label }));
  });
  const [checkedList, setCheckedList] = useState<string[]>([]);
  const [searchText, setSearchText] = useState('');
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(true);
  const currencyFormat = useAppSelector(selectCurrencyFormat);

  useEffect(() => {
    if (checkAll) {
      setCheckedList(colEmployeeTable.map((item: any) => item?.value) || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkAll]);

  useEffect(() => {
    setcolEmployeeTable(handleHideColumn());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHistory]);

  useEffect(() => {
    if (currencyFormat && checkedList?.length) handleDone();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currencyFormat]);

  useEffect(() => {
    setcolEmployeeTable(colEmployeeTableRoot);
    if (searchText) {
      const matchItems = colEmployeeTable
        .filter((item: any) =>
          removeAccents(item.label.toLowerCase()).includes(removeAccents(searchText)),
        )
        .map((item: any) => ({ value: item.value, label: item.label }));
      setlstItemCheck(matchItems);
    } else
      setlstItemCheck(
        colEmployeeTable.map((item: any) => ({ value: item.value, label: item.label })),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleVisibleAddColumn, t, searchText]);

  useImperativeHandle(ref, () => ({
    resetChecklist: () => {
      setIndeterminate(false);
      setCheckAll(false);
      setCheckedList([]);
    },
  }));

  const handleSearch = (keyword: string) => {
    const matchItems = colEmployeeTable
      .filter((item: any) => item.label.toLowerCase().includes(keyword.toLowerCase()))
      .map((item: any) => ({ value: item.value, label: item.label }));
    setlstItemCheck(matchItems);
  };

  const handleSearchTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value.toLowerCase();
    if (text) {
      const matchItems = colEmployeeTable
        .filter((item: any) =>
          removeAccents(item.label.toLowerCase()).includes(removeAccents(text)),
        )
        .map((item: any) => ({ value: item.value, label: item.label }));
      setlstItemCheck(matchItems);
      setSearchText(text);
    } else {
      setlstItemCheck(
        colEmployeeTable.map((item: any) => ({ value: item.value, label: item.label })),
      );
      setSearchText('');
    }
  };

  const removeDuplicates = (arr: Array<string>) => {
    let s = new Set(arr);
    let it = s.values();
    return Array.from(it);
  };

  const handleCheckChange = (list: any) => {
    //handle when search
    if (searchText) {
      //when select all
      if (checkAll) {
        //handle deselect from select all
        if (list.length !== lstItemCheck.length) {
          const removeData = lstItemCheck
            .filter((item: any) => !list.includes(item?.value))
            .map((item: any) => item?.value);
          //deselect from parent
          const dataRemain = colEmployeeTable
            .map((item: any) => ({ value: item.value, label: item.label }))
            .filter((item: any) => !removeData.includes(item.value))
            .map((item: any) => item.value);
          setCheckedList(dataRemain);
          setIndeterminate(true);
          setCheckAll(
            list.length === lstItemCheck.length && lstItemCheck.length === colEmployeeTable?.length,
          );
        }
      } else {
        if (list.length !== lstItemCheck.length) {
          const matchItems = colEmployeeTable
            .filter((item: any) =>
              removeAccents(item.label.toLowerCase()).includes(removeAccents(searchText)),
            )
            .map((item: any) => ({ value: item.value, label: item.label }));
          const dataDeselect = matchItems.filter(
            (item: any) => !list.some((i: any) => item.value === i),
          );

          const dataToAdd = colEmployeeTable
            .map((item: any) => item.value)
            .filter((item: any) => !dataDeselect.some((i: any) => i.value === item))
            .filter((item: any) => checkedList.some((i) => i === item));
          setCheckedList(removeDuplicates([...dataToAdd, ...list]));
          setIndeterminate(
            !(removeDuplicates([...dataToAdd, ...list]).length === colEmployeeTable?.length),
          );
          setCheckAll(
            removeDuplicates([...dataToAdd, ...list]).length === colEmployeeTable?.length,
          );
        } else {
          const foundData = lstItemCheck
            .filter((item: any) => list.some((i: any) => i === item?.value))
            .map((item: any) => item?.value);
          setCheckedList(removeDuplicates([...checkedList, ...foundData]));
          setIndeterminate(
            !(removeDuplicates([...checkedList, ...foundData]).length === colEmployeeTable?.length),
          );
          setCheckAll(
            removeDuplicates([...checkedList, ...foundData]).length === colEmployeeTable?.length,
          );
        }
      }
    } else {
      setCheckedList(removeDuplicates(list));
      setIndeterminate(!!list.length && list.length < lstItemCheck.length);
      setCheckAll(list.length === lstItemCheck.length);
    }
  };

  const handleCheckAllChange = (e: any) => {
    const value = e.target.checked ? colEmployeeTable.map((item: any) => item?.value) : [];
    setCheckedList(value);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const handleDone = () => {
    let lstCol: any = [];
    checkedList.forEach(
      (item) => (lstCol = [...lstCol, colEmployeeTable.find((obj: any) => obj?.value === item)]),
    );
    addColumns(lstCol);
    handleVisibleAddColumn();
  };

  return (
    <ContainerStyled>
      <TitleStyled>{t('action:add_column')}</TitleStyled>
      <SearchStyled
        placeholder={t('employee:employee_manage.search')}
        prefix={IconSearchComponents}
        onSearch={handleSearch}
        onChange={handleSearchTextChange}
        enterButton
      />
      <CheckoxStyled
        indeterminate={indeterminate}
        onChange={handleCheckAllChange}
        checked={checkAll}
      >
        {t('employee:employee_manage.select_all')}
      </CheckoxStyled>
      <CheckoxGroupStyled options={lstItemCheck} value={checkedList} onChange={handleCheckChange} />
      <FooterStyled>
        <DoneButton onClick={handleDone}>{t('employee:employee_manage.done')}</DoneButton>
        <CancelButton onClick={handleVisibleAddColumn}>
          {t('employee:employee_manage.cancel')}
        </CancelButton>
      </FooterStyled>
    </ContainerStyled>
  );
};

export default forwardRef(AddColumn);

import Icon, {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Card, Col, Modal, Row, Tabs, Tooltip } from 'antd';
import { useAppSelector } from 'app/hooks';
import ITVTable from 'components/ITVTable';
import PageHeader from 'components/PageHeader';
import { selectConfiguration } from 'features/configuration/configurationSlice';
import { HiringProcessEntity, JobStatus } from 'features/recruitment/jobs/components/types';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router';
import CustomButton from 'styles/buttonStyled';
import { getUser } from 'utils/auth';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { MODE } from 'constants/types';
import AddStage from './AddStage';

const ListStage = ({
  data,
  handleChange,
  isShow,
  viewStageInterview,
}: {
  data?: HiringProcessEntity[];
  handleChange: (value: any) => void;
  isShow?: boolean;
  viewStageInterview: (value: any) => void;
}) => {
  const [sizePage, setSizePage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const { t } = useTranslation(['recruitment', 'action', 'timesheet', 'onboard']);
  const { confirm } = Modal;
  const [currentItem, setCurrentItem] = useState<any>(null);
  const [action, setAction] = useState<string>('');
  const [isAddItemBelow, setIsAddItemBelow] = useState(false);
  const [stageIdCurrentItem, setStageIdCurrentItem] = useState(0);

  const handleDelete = (record: HiringProcessEntity) => {
    confirm({
      title: t('onboard:are_you_sure_to_delete_this_record'),
      icon: <ExclamationCircleOutlined />,
      okText: t('timesheet:Yes'),
      okType: 'danger',
      cancelText: t('timesheet:No'),
      onOk() {
        handleChange((prevValue: any) => {
          const newData = prevValue.hiringProcess.filter(
            (item) => item.stageOrder !== record.stageOrder,
          );
          const updatedData = newData.map((item, index) => ({
            ...item,
            stageOrder: index,
          }));
          return { ...prevValue, hiringProcess: updatedData };
        });
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const onSaveNewStage = (values, mode) => {
    if (mode === MODE.ADD) {
      if (isAddItemBelow) {
        const updateProcessTemplateData: any = [...data];
        updateProcessTemplateData.splice(values?.stageOrder, 0, values);
        const updatedData = updateProcessTemplateData.map((item, index) => ({
          ...item,
          stageOrder: index,
        }));
        handleChange && handleChange((value: any) => ({ ...value, hiringProcess: updatedData }));
      } else {
        const newStageOrder = data?.length || 0;
        const newValues = { ...values, stageOrder: newStageOrder };
        handleChange &&
          handleChange((value: any) => ({ ...value, hiringProcess: [...data, newValues] }));
      }
    } else {
      const updateProcessTemplateData: any = [...data];
      updateProcessTemplateData[currentItem?.stageOrder] = values;
      handleChange &&
        handleChange((value: any) => ({ ...value, hiringProcess: updateProcessTemplateData }));
    }
    setAction('');
    setCurrentItem(null);
  };

  const columns = [
    {
      title: '#',
      width: 100,
      minWidth: 100,
      dataIndex: 'no',
      key: 'no',
      align: 'center',
      render: function (text: any, record: any, index: any) {
        return index + 1;
      },
    },
    {
      title: t('recruitment:stage_name'),
      width: 200,
      minWidth: 200,
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      render: function (text: any, record: any, index: any) {
        return text;
      },
    },
    {
      title: t('recruitment:assigners'),
      width: 100,
      minWidth: 100,
      dataIndex: 'assigners',
      key: 'assigners',
      align: 'left',

      render: function (text: any, record: any, index: any) {
        return record?.assigners?.map((item, index) => {
          return `${item?.fullName}${
            record?.assigners?.length == 1 || index == record?.assigners?.length - 1 ? '' : ', '
          }`;
        });
      },
    },
    {
      title: t('recruitment:interviewer_external'),
      width: 100,
      minWidth: 100,
      dataIndex: 'externalInterviewers',
      key: 'externalInterviewers',
      align: 'left',

      render: function (text: any, record: any, index: any) {
        return text;
      },
    },
    {
      title: t('recruitment:candidate_status'),
      width: 100,
      minWidth: 100,
      dataIndex: 'statusCandidate',
      key: 'statusCandidate',
      align: 'left',
      render: function (text: any, record: any, index: any) {
        return text;
      },
    },
    {
      title: t('action:action'),
      dataIndex: 'action',
      align: 'center',
      key: 'action',
      width: 100,
      minWidth: 100,
      render: (text: any, record: any, index: any) => {
        return (
          <StyledWrapperAction>
            {record?.eventType == 'Interview' && (
              <Tooltip placement="top" title={t('recruitment:view_job_template')}>
                <StyledEyeOutlined
                  onClick={() => {
                    viewStageInterview(record);
                  }}
                />
              </Tooltip>
            )}
            <Tooltip placement="top" title={t('recruitment:edit_interview_template_name')}>
              <StyledEditOutlined
                onClick={() => {
                  setCurrentItem(record);
                  setIsAddItemBelow(false);
                  setAction(MODE.EDIT);
                  viewStageInterview(record);
                }}
              />
            </Tooltip>

            <Tooltip placement="top" title={'recruitment:delete_interview_template_name'}>
              <StyledDeleteOutlined
                onClick={() => {
                  handleDelete(record);
                  viewStageInterview(record);
                }}
              />
            </Tooltip>
          </StyledWrapperAction>
        );
      },
    },
  ];
  const [col, setCol] = useState(columns);

  useEffect(() => {
    // filter action if isShow false, other show all column
    if (!isShow) {
      const newCol = columns.filter((item) => item.dataIndex !== 'action');
      setCol(newCol);
    } else {
      setCol(columns);
    }
  }, [isShow]);

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 10 }}>
        {isShow && (
          <CustomButton
            icon={<PlusOutlined />}
            onClick={() => {
              setAction(MODE.ADD);
              setIsAddItemBelow(false);
              viewStageInterview('');
            }}
          >
            {t('action:add_row')}
          </CustomButton>
        )}
      </div>
      <ITVTable
        columns={col}
        data={data}
        isRowSelect={false}
        setOffset={setCurrentPage}
        offset={currentPage}
        setLimit={setSizePage}
        limit={sizePage}
        height={400}
        scroll={{ x: 100, y: 100 }}
      />
      {action !== '' && (
        <Modal
          visible={action !== ''}
          title={action === MODE.ADD ? t('recruitment:add_new_stage') : `Edit ${currentItem?.name}`}
          okText={t('timesheet:Save')}
          cancelText={t('timesheet:Cancel')}
          width={950}
          maskClosable={false}
          onCancel={() => {
            setAction('');
          }}
          centered
          footer={null}
        >
          <AddStage
            onSaveNewStage={onSaveNewStage}
            stageOrder={
              isAddItemBelow
                ? stageIdCurrentItem + 1
                : action === MODE.ADD
                ? data?.length || 0
                : currentItem?.stageOrder
            }
            mode={action}
            record={currentItem}
          />
        </Modal>
      )}
    </>
  );
};

const StyledWrapperAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const StyledEyeOutlined = styled(EyeOutlined)`
  font-size: 18px;
  color: var(--cl_info500);
  cursor: pointer;
`;

const StyledEditOutlined = styled(EditOutlined)`
  font-size: 18px;
  color: var(--cl_primary500);
  cursor: pointer;
`;

const StyledDeleteOutlined = styled(DeleteOutlined)`
  font-size: 15px;
  color: var(--cl_neutral500);
  cursor: pointer;
`;

export default ListStage;

import { Input } from 'antd';
import { selectCurrencyFormat } from 'app/commonRedux/appSlice';
import { useAppSelector } from 'app/hooks';
import { selectShowMoney } from 'features/auth/authSlice';
import { useMaskValue } from 'hooks';
import numeral from 'numeral';
import InputNumber from 'rc-input-number';
import styled from 'styled-components';

const MaskedInputNumberRenderer = ({
  value,
  disabled = false,
}: {
  value: number;
  disabled?: boolean;
}) => {
  const isShowMoney = useAppSelector(selectShowMoney);
  const currencyFormat = useAppSelector(selectCurrencyFormat);
  const maskedValue = useMaskValue(value, isShowMoney);
  const inputValue = numeral(maskedValue).format(currencyFormat);
  return maskedValue !== '*****' ? (
    <CustomInputNumber maxLength={255} value={inputValue} disabled={disabled} />
  ) : (
    <CustomInput style={{ textAlign: 'right' }} value={maskedValue} disabled={disabled} />
  );
};

export default MaskedInputNumberRenderer;

export const CustomInputNumber = styled(InputNumber)`
  &.ant-input-number {
    width: 100%;
  }
  .rc-input-number-input {
    width: 100%;
    text-align: right;
    padding-right: 5px;
    height: 32px;
  }
  .ant-input-number-input {
    text-align: right;

    // padding-right:1px;
  }
  .ant-input-number-handler-wrap {
    padding-right: 5px;
  }
  .ant-input-number-handler-wrap {
    display: none;
  }
`;

export const CustomInput = styled(Input)`
  &.ant-input-number {
    width: 100%;
  }
  .inputMask {
    text-align: right !important;
  }
  .ant-input {
    text-align: right !important;

    // padding-right:1px;
  }
  .ant-input-disabled {
    text-align: right;

    // padding-right:1px;
  }
  .ant-input-number-handler-wrap {
    padding-right: 5px;
  }
  .ant-input-number-handler-wrap {
    display: none;
  }
`;

import { useEffect, useState } from 'react';
import { Table } from 'antd';
import styled from 'styled-components';
import { colors } from 'assets/colors';
import { labelKeyTable } from '../constants/common';
import { useTranslation } from 'react-i18next';

const InsuranceTable = (props: any) => {
  let { columns, data, width, setSelectedUser, clickcheckbox, setrowSelection, showPagination } =
    props;
  const [sizePage, setSizePage] = useState(50);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { t } = useTranslation(['insurance']);

  const onSelectChange = (selectedRowKeys: any) => {
    setSelectedRowKeys(selectedRowKeys);
    setSelectedUser(selectedRowKeys);
    props.UserIds && props.UserIds(selectedRowKeys);
  };

  useEffect(() => {
    setSelectedRowKeys([]);
  }, [clickcheckbox]);

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE],
  };

  const itemRender = (current: any, type: any, originalElement: any) => {
    if (type === 'prev') {
      return (
        <span style={{ height: 24, minWidth: 24, padding: '0 6px', marginRight: 10 }}>{`< ${t(
          'Prev',
        )}`}</span>
      );
    }
    if (type === 'next') {
      return (
        <span style={{ height: 24, minWidth: 24, padding: '0 6px', marginLeft: 10 }}>{`${t(
          'Next',
        )} >`}</span>
      );
    }
    return originalElement;
  };

  const handleChange = (e: any) => {
    setSizePage(e.target.value);
  };

  const handleSetRowKey = (record: any) => {
    if (setrowSelection === labelKeyTable.KEY_TABLE_DATA_PARTICIPANT) {
      return record?.datarecor?.id;
    } else {
      return record?.id;
    }
  };

  function showTotal() {
    return (
      <ShowTotal>
        <SelectTotal id="dropdownSize" value={sizePage} onChange={handleChange}>
          <option value="50">50</option>
          <option value="100">100</option>
          <option value="100">1000</option>
        </SelectTotal>
        {t('total')}: {data.length}
      </ShowTotal>
    );
  }

  const handleViewTable = () => {
    if (setrowSelection === labelKeyTable.KEY_TABLE_NOT_CHECKBOX) {
      return (
        <CustomTable
          tableLayout={'fixed'}
          size={'small'}
          columns={columns}
          dataSource={data}
          // @ts-ignore
          rowKey={(record) => record.id}
          pagination={
            showPagination === false
              ? false
              : {
                  position: ['bottomRight'],
                  total: data?.length,
                  showTotal: () => showTotal(),
                  showSizeChanger: false,
                  pageSize: sizePage,
                  itemRender: itemRender,
                }
          }
          // onRow={handleCheckRowOverView}
          // @ts-ignore
          scroll={{ y: window.innerHeight - 325, x: width }}
          bordered={true}
        />
      );
    } else {
      return (
        <CustomTable
          tableLayout={'fixed'}
          size={'small'}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={data}
          // @ts-ignore
          rowKey={(record) => handleSetRowKey(record)}
          pagination={{
            position: ['bottomRight'],
            total: data?.length,
            showTotal: () => showTotal(),
            showSizeChanger: false,
            pageSize: sizePage,
            itemRender: itemRender,
          }}
          // onRow={handleCheckRowOverView}
          // @ts-ignore
          bordered={true}
          scroll={{ y: window.innerHeight - 325, x: width }}
        />
      );
    }
  };
  // @ts-ignore
  return handleViewTable();
};
export default InsuranceTable;

export const ShowTotal = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${colors.gray900};
  width: 150px;
  right: 100%;
  flex-wrap: wrap;
  align-items: center;
  font-weight: bold;
`;

export const SelectTotal = styled.select`
  /* width: 60px; */
  padding: 4px 7px;
  border-radius: 4px;
  background-color: $white;
  outline: none;
  font-weight: bold;
`;

export const CustomTable = styled(Table)`
  .ant-pagination {
    display: flex;
    padding-right: 12px;

    &-total-text {
      flex-grow: 7;
    }
  }

  .ant-pagination-item-active {
    background-color: var(--cl_primary);
    border-color: var(--cl_primary);

    a {
      color: white;
    }
  }

  .ant-table-small .ant-table-thead > tr > th {
    background-color: var(--cl_gray200);
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--cl_primary);
    border-color: var(--cl_primary);
  }

  .ant-checkbox-inner {
    border-color: var(--cl_primary);
  }

  .ant-checkbox-indeterminate {
    background-color: var(--cl_primary);
  }
`;

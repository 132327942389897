import queryString from 'query-string';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosClient from 'api/axiosClient';
import { setGlobalLoading } from 'app/commonRedux/appSlice';
import { notificationToast } from 'components/notificationToast';
import { actions } from './performanceSlice';
import { Notification } from 'constants/notification';

export const getManagerView = createAsyncThunk(
  '/performance/manager-view/underline-employees',
  async (params: any, thunkApi: any) => {
    try {
      const response = await axiosClient.get(
        `/performance/manager-view/underline-employees?${queryString.stringify(params)}`,
      );
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const createReviewCycle = createAsyncThunk(
  '/performance/review',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const { body, callBack } = params;

      const response = await axiosClient.post(`/performance/review`, body);
      callBack && callBack();
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const getPerformanceTemplates = createAsyncThunk(
  '/performance/configuration/templates',
  async (params: any, thunkApi: any) => {
    try {
      const response = await axiosClient.get(`/performance/configuration/templates?status=true`);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getPerformanceTemplate = createAsyncThunk(
  'performance/configuration/templates/detail/:id',
  async (params: any, thunkApi: any) => {
    try {
      const response = await axiosClient.get(
        `performance/configuration/templates/detail/${params?.id}`,
      );
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getReviewCycle = createAsyncThunk(
  '/performance/review',
  async (params: any, thunkApi: any) => {
    try {
      const response = await axiosClient.get(
        `/performance/review?${queryString.stringify(params)}`,
      );
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getAssessmentDetail = createAsyncThunk(
  '/performance/review/assessment/detail',
  async (params: any, thunkApi: any) => {
    try {
      const response = await axiosClient.get(
        `/performance/review/assessment/detail?${queryString.stringify(params)}`,
      );
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getPendingReviewPerformance = createAsyncThunk(
  '/performance/pending-review',
  async (params: any, thunkApi: any) => {
    try {
      const response = await axiosClient.get(
        `/performance/pending-review?${queryString.stringify(params)}`,
      );
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getCycleList = createAsyncThunk(
  '/performance/review/cycles',
  async (_: any, thunkApi: any) => {
    try {
      const response = await axiosClient.get(`/performance/review/cycles`);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const startReviewCycle = createAsyncThunk(
  '/performance/review/start-review/',
  async (params: any, thunkApi: any) => {
    try {
      const { id, callBack } = params;
      const response = await axiosClient.post(`/performance/review/start-review/${id}`);
      callBack && callBack();
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getDetailCycle = createAsyncThunk(
  '/performance/review/detail/',
  async (params: any, thunkApi: any) => {
    try {
      const { id } = params;
      const response = await axiosClient.get(`/performance/review/detail/${id}`);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getListAssessmentEmployee = createAsyncThunk(
  '/performance/review/assessment-all',
  async (params: any, thunkApi: any) => {
    try {
      const response = await axiosClient.get(
        `/performance/review/assessment?${queryString.stringify(params)}`,
      );
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const updateAssessmentForm = createAsyncThunk(
  '/performance/review/assessment/:assessmentId',
  async (params: any, thunkApi: any) => {
    try {
      const { id, body, callBack } = params;
      const response = await axiosClient.put(`/performance/review/assessment/${id}`, body);
      callBack && callBack();
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getHRPerformanceView = createAsyncThunk(
  '/performance/review/assessment/list-by-cycle',
  async (params: any, thunkApi: any) => {
    try {
      const response = await axiosClient.get(
        `/performance/review/assessment/list-by-cycle?${queryString.stringify(params)}`,
      );
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const deleteReviewCycle = createAsyncThunk(
  '/performance/review/delete',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const { id, callBack } = params;
      const response = await axiosClient.delete(`/performance/review/${id}`);
      callBack && callBack();
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const updateReviewCycle = createAsyncThunk(
  '/performance/review/:id',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const { body, callBack, id } = params;

      const response = await axiosClient.put(`/performance/review/${id}`, body);
      callBack && callBack();
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const updateGoalMainResponsibilities = createAsyncThunk(
  '/performance/review/step/:id',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const { goals, callBack, id } = params;

      const response = await axiosClient.put(`/performance/review/step/${id}`, { goals });
      callBack && callBack();
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const cancelReviewCycle = createAsyncThunk(
  '/performance/review/status/:id',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const { id, callBack } = params;
      const response = await axiosClient.put(`/performance/review/status/${id}`);
      callBack && callBack();
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
    }
  },
);

export const exportPerformanceReviewCycle = createAsyncThunk(
  'performance/review/review-cycle/export',
  async (params: any, thunkApi: any) => {
    try {
      const res: any = await axiosClient.post(
        `performance/review/review-cycle/export`,
        { ids: params },
        {
          responseType: 'blob',
        },
      );
      const url = window.URL.createObjectURL(new Blob([res]));

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Performance_Review_Cycle.xlsx`);
      document.body.appendChild(link);
      link.click();
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

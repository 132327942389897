import styled from 'styled-components';

export const ImageStyled = styled.img`
  width: 1.5rem;
  height: 1.5rem;
`;

export const HeaderTopStyled = styled.div`
  margin: 0px 60px;
  padding: 1% 0px;
`;

export const LogoImageStyled = styled.img`
  max-height: 30px;
  max-width: 166px;
`;

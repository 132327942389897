import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import {
  addAssignee,
  getMyTrainingList,
  getTraineeDetail,
  getTraineeList,
  getTrainingCategory,
  getTrainingDetail,
  getTrainingList,
  getTrainingStatus,
  searchAssignee,
} from './trainingAction';

const initialState: any = {
  trainingCategory: null,
  trainingStatus: null,
  trainingList: null,
  trainingDetail: null,
  myTrainingList: null,
  traineeList: null,
  traineeDetail: null,
  filterTrainee: null,
  searchTrainee: null,
};

const trainingDataSlice = createSlice({
  name: 'trainingData',
  initialState: initialState,
  reducers: {
    resettrainingDetail(state, action) {
      state.trainingDetail = null;
    },
    resetfilterTrainee(state, action) {
      state.filterTrainee = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTrainingCategory.pending, (state: any, action: any) => {})
      .addCase(getTrainingCategory.fulfilled, (state: any, action: any) => {
        state.trainingCategory = action.payload?.data;
      })
      .addCase(getTrainingCategory.rejected, (state: any, action: any) => {
        state.trainingCategory = null;
      })
      .addCase(getTrainingStatus.pending, (state: any, action: any) => {})
      .addCase(getTrainingStatus.fulfilled, (state: any, action: any) => {
        state.trainingStatus = action.payload?.data;
      })
      .addCase(getTrainingStatus.rejected, (state: any, action: any) => {
        state.trainingStatus = null;
      })
      .addCase(getTrainingList.pending, (state: any, action: any) => {})
      .addCase(getTrainingList.fulfilled, (state: any, action: any) => {
        state.trainingList = action.payload?.data;
      })
      .addCase(getTrainingList.rejected, (state: any, action: any) => {
        state.trainingList = null;
      })
      .addCase(getTrainingDetail.pending, (state: any, action: any) => {})
      .addCase(getTrainingDetail.fulfilled, (state: any, action: any) => {
        state.trainingDetail = action.payload?.data;
      })
      .addCase(getTrainingDetail.rejected, (state: any, action: any) => {
        state.trainingDetail = null;
      })
      .addCase(getMyTrainingList.pending, (state: any, action: any) => {})
      .addCase(getMyTrainingList.fulfilled, (state: any, action: any) => {
        state.myTrainingList = action.payload?.data;
      })
      .addCase(getMyTrainingList.rejected, (state: any, action: any) => {
        state.myTrainingList = null;
      })
      //getTraineeList
      .addCase(getTraineeList.pending, (state: any, action: any) => {})
      .addCase(getTraineeList.fulfilled, (state: any, action: any) => {
        state.traineeList = action.payload?.data;
      })
      .addCase(getTraineeList.rejected, (state: any, action: any) => {
        state.traineeList = null;
      })
      .addCase(getTraineeDetail.pending, (state: any, action: any) => {})
      .addCase(getTraineeDetail.fulfilled, (state: any, action: any) => {
        state.traineeDetail = action.payload?.data;
      })
      .addCase(getTraineeDetail.rejected, (state: any, action: any) => {
        state.traineeDetail = null;
      })
      .addCase(addAssignee.pending, (state: any, action: any) => {})
      .addCase(addAssignee.fulfilled, (state: any, action: any) => {
        state.filterTrainee = action.payload?.data;
      })
      .addCase(addAssignee.rejected, (state: any, action: any) => {
        state.filterTrainee = null;
      })
      .addCase(searchAssignee.pending, (state: any, action: any) => {})
      .addCase(searchAssignee.fulfilled, (state: any, action: any) => {
        state.searchTrainee = action.payload?.data;
      })
      .addCase(searchAssignee.rejected, (state: any, action: any) => {
        state.searchTrainee = null;
      });
    //searchAssignee
  },
});

export const selectTrainingData = (state: RootState) => state.training;
export const { reducer, actions } = trainingDataSlice;

export default reducer;

import React, { HTMLAttributes, ReactNode } from 'react';
import { ContainerStyled, LabelStyled } from './styled';

interface Props extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  label: string;
}

function SignInTypeButton(props: Props) {
  const { children, label, ...rest } = props;
  return (
    <ContainerStyled {...rest}>
      {children}
      <LabelStyled>{label}</LabelStyled>
    </ContainerStyled>
  );
}

export default SignInTypeButton;

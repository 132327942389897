import { DatePicker, Tooltip } from 'antd';
import { selectFormatDate } from 'app/commonRedux/appSlice';
import { useAppSelector } from 'app/hooks';
import ITVSearch from 'components/ITVSearch';
import { TIMEZONE_DEFAULT } from 'constants/commons';
import { TFunction } from 'i18next';
import moment, { Moment } from 'moment';
import { FC, useRef, useCallback, useEffect, useReducer } from 'react';
import { getUser } from 'utils/auth';
import { removeAccents } from 'utils/text';
import {
  Abbreviations,
  ChooseLeaveDayListStyled,
  ChooseLeaveDayListWrapper,
  ContentAbbreviations,
  ItemContent,
  ItemFullName,
  ItemGrid,
  SearchWrapper,
  SelectAllDays,
  WarningOutlinedStyled,
} from './ChooseLeaveDayListStyles';

type TChooseLeaveDayList = {
  selectUsersAssigned: { data: any; dateAddLeaveDays: Moment | null }[];
  sizeList?: 'large' | 'small';
  handleUpdateUser: (
    user: { data: any; dateAddLeaveDays: Moment | null },
    dateAddLeaveDays: Moment | null,
  ) => void;
  t: TFunction;
};

interface IPageSelectAssignedReducer {
  users: {
    data: any;
    dateAddLeaveDays: Moment | null;
  }[];
  usersFilter: {
    data: any;
    dateAddLeaveDays: Moment | null;
  }[];
  limit: number;
  offset: number;
  page: number;
}

const initialState: IPageSelectAssignedReducer = {
  users: [],
  usersFilter: [],
  limit: 5,
  offset: 0,
  page: 1,
};

const pageSelectAssignedReducer = (
  state: IPageSelectAssignedReducer,
  action: { payload?: any; type: string },
) => {
  switch (action.type) {
    case 'addData': {
      return { ...state, users: state.users.concat(action.payload) };
    }
    case 'nextPage': {
      return { ...state, page: state.page + 1, offset: state.offset + state.limit };
    }
    case 'changeAllDateLeaveDays': {
      const newData = state.users.map((user) => ({ ...user, dateAddLeaveDays: action.payload }));
      const newDataFilter = state.usersFilter.map((user) => ({
        ...user,
        dateAddLeaveDays: action.payload,
      }));

      return {
        ...state,
        users: newData,
        usersFilter: state.usersFilter.length > 0 ? newDataFilter : [],
      };
    }
    case 'changeOneDateLeaveDays': {
      const indexUser = state.users.findIndex((user) => user.data.userId === action.payload.userId);
      const indexUserFilter = state.usersFilter.findIndex(
        (user) => user.data.userId === action.payload.userId,
      );
      const newData = {
        ...state.users[indexUser],
        dateAddLeaveDays: action.payload.dateAddLeaveDays,
      };
      const newDataFilter = {
        ...state.usersFilter[indexUserFilter],
        dateAddLeaveDays: action.payload.dateAddLeaveDays,
      };

      return {
        ...state,
        users: state.users[indexUser]
          ? [...state.users.slice(0, indexUser), newData, ...state.users.slice(indexUser + 1)]
          : state.users,
        usersFilter:
          state.usersFilter.length > 0
            ? [
                ...state.usersFilter.slice(0, indexUserFilter),
                newDataFilter,
                ...state.usersFilter.slice(indexUserFilter + 1),
              ]
            : [],
      };
    }
    case 'addDataFilter': {
      return { ...state, usersFilter: [...action.payload] };
    }
    default:
      return state;
  }
};

const ChooseLeaveDayList: FC<TChooseLeaveDayList> = ({
  selectUsersAssigned,
  sizeList = 'large',
  handleUpdateUser,
  t,
}) => {
  const [state, dispatch] = useReducer(pageSelectAssignedReducer, initialState);
  const catchInfinityRef = useRef<HTMLDivElement>(null);
  const formatDate = useAppSelector(selectFormatDate);
  const timeZone = getUser().timeZone ? getUser().timeZone : TIMEZONE_DEFAULT;
  //Set default timezone in server
  moment.tz.setDefault(timeZone);

  useEffect(() => {
    dispatch({
      payload: selectUsersAssigned.filter(
        (_, index) => index >= state.offset && index < state.offset + state.limit,
      ),
      type: 'addData',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, state.offset, state.limit]);

  const handleObserver = useCallback(
    (entry: IntersectionObserverEntry[]) => {
      const target = entry[0];

      if (target.isIntersecting) {
        dispatch({ type: 'nextPage' });
      }
    },
    [dispatch],
  );

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '20px',
      threshold: 0,
    };
    const observer = new IntersectionObserver(handleObserver, options);
    if (catchInfinityRef.current) observer.observe(catchInfinityRef.current);
  }, [handleObserver]);

  const handleSearch = (key: string) => {
    const text = key.toLowerCase().trim();
    if (text) {
      const temp = selectUsersAssigned.filter((item: any) => {
        return (
          item.data.employeeId?.toString().toLowerCase().includes(text) ||
          item.data.firstName?.toLowerCase().includes(text) ||
          item.data.lastName.toLowerCase().includes(text) ||
          removeAccents((item.data?.lastName + item.data?.firstName).replace(/ /g, ''))
            .toLowerCase()
            .includes(removeAccents(text).replace(/ /g, '').toLowerCase())
        );
      });
      dispatch({
        payload: temp,
        type: 'addDataFilter',
      });
    } else {
      dispatch({
        payload: [],
        type: 'addDataFilter',
      });
    }
  };

  const disableDateBeforeDateNow = (currentDate: Moment) => {
    return currentDate <= moment().endOf('day');
  };

  return (
    <>
      <SelectAllDays>
        <SearchWrapper>
          <ITVSearch handleSearch={(value: any) => handleSearch(value)} />
        </SearchWrapper>
        <label>
          {t('leaveOff:modal_item.select_all_days')}{' '}
          <DatePicker
            disabledDate={disableDateBeforeDateNow}
            format={formatDate}
            onChange={(date) => {
              dispatch({
                payload: date,
                type: 'changeAllDateLeaveDays',
              });
              selectUsersAssigned.forEach((userAssigned) => {
                handleUpdateUser(userAssigned, date);
              });
            }}
          />
        </label>
      </SelectAllDays>
      <ChooseLeaveDayListWrapper>
        <ChooseLeaveDayListStyled sizeList={sizeList!}>
          {state[state.usersFilter.length > 0 ? 'usersFilter' : 'users'].map((user: any) => (
            <ItemGrid key={user.data.userId}>
              <Abbreviations sizeList={sizeList!}>
                <ContentAbbreviations sizeList={sizeList!}>
                  {user.data.lastName.charAt(0) + user.data.firstName.charAt(0)}
                </ContentAbbreviations>
              </Abbreviations>
              <ItemContent sizeList={sizeList!}>
                <ItemFullName>{user.data.lastName + ' ' + user.data.firstName}</ItemFullName>
                <DatePicker
                  format={formatDate}
                  value={user.dateAddLeaveDays}
                  disabledDate={disableDateBeforeDateNow}
                  onChange={(date) => {
                    dispatch({
                      payload: { dateAddLeaveDays: date, userId: user.data.userId },
                      type: 'changeOneDateLeaveDays',
                    });
                    handleUpdateUser(user, date);
                  }}
                />
                {user.dateAddLeaveDays === null && (
                  <Tooltip
                    title={t('leaveOff:modal_item.require_select_date_add_leave_days')}
                    color={'orange'}
                  >
                    <WarningOutlinedStyled />
                  </Tooltip>
                )}
              </ItemContent>
            </ItemGrid>
          ))}
          <div ref={catchInfinityRef} />
        </ChooseLeaveDayListStyled>
      </ChooseLeaveDayListWrapper>
    </>
  );
};

export default ChooseLeaveDayList;

export const blockHeader = `
          <table
            cellspacing="0"
            id="i4n8"
            style="
              box-sizing: border-box;
              width: 100%;
            "
            width="100%"
          >
            <tbody style="box-sizing: border-box">
              <tr style="box-sizing: border-box">
                <td
                  title="column"
                  id="ixh5"
                  style="box-sizing: border-box; padding: 0; margin: 0; vertical-align: middle"
                  valign="middle"
                >
                  <div style="background-color: #fa8c16; text-align: center;">
                    <div
                      id="i0bi"
                      style="
                        box-sizing: border-box;
                        color: white;
                        font-family: Comic Sans MS, cursive, sans-serif;
                        font-size: 30px;
                      "
                    >INNOTECH WELCOME</div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>`;

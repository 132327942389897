import { IconProps } from 'types';

export const UpTrendIcon = ({ width = '12px', height = '12px', className }: IconProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.83301 9.5L4.41488 5.74801C4.84238 5.13201 5.60176 5.09 6.06301 5.671L6.59738 6.33601C7.05863 6.91001 7.81801 6.875 8.24551 6.259L10.833 2.5M10.833 2.5V4.65385M10.833 2.5H9.03301"
      stroke="#12B76A"
      strokeWidth="1.2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

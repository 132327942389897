import { PlusOutlined } from '@ant-design/icons';
import { Collapse, Modal, Tabs } from 'antd';
import { useAppSelector } from 'app/hooks';
import ButtonBack from 'components/ButtonBack';
import { STATUS_PERFORMANCE } from 'constants/commons';
import { MODE } from 'constants/types';
import { selectConfiguration } from 'features/configuration/configurationSlice';
import { HeaderAdd } from 'features/masterData/Department/styled';
import {
  getAssessmentDetail,
  updateAssessmentForm,
  updateGoalMainResponsibilities,
} from 'features/performance/performanceAction';
import { selectPerformanceData } from 'features/performance/performanceSlice';
import { selectRole } from 'features/role/roleSlice';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import CustomButton from 'styles/buttonStyled';
import { getUser } from 'utils/auth';
import { Container } from '../styled';
import AddNewResponsibility from './AddNewResponsibility';
import HeaderPerformance from './HeaderPerformance';
import PerformanceWeightTable from './PerformanceWeightTable';
import axiosClient from 'api/axiosClient';
import queryString from 'query-string';

const { Panel } = Collapse;

const PerformanceDetail = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation([
    'action',
    'dashboard',
    'employee',
    'onboard',
    'timesheet',
    'payroll',
    'performance',
    'performance_review',
    'goals',
  ]);
  const { id, detailId }: any = useParams();

  const history = useHistory();
  const menuStore = useAppSelector(selectConfiguration);
  const performanceStore = useAppSelector(selectPerformanceData);
  const assessmentDetail = performanceStore?.assessmentDetail;
  const role = useAppSelector(selectRole).data;
  const level = menuStore.dataLevel || [];

  const roleOptions = role?.map((x: any) => ({
    label: x?.name,
    value: x?.id,
  }));

  const [disable, setDisable] = useState(false);
  const [mode, setMode] = useState<string>('');
  const [currentItem, setCurrentItem] = useState<any>(null);

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    resolver: undefined,
    defaultValues: useMemo(() => {
      return assessmentDetail;
    }, [assessmentDetail]),
  });

  useEffect(() => {
    reset(assessmentDetail);
    setValue('steps', assessmentDetail?.steps);
    setValue('mainResponsibilities.goals', assessmentDetail?.mainResponsibilities?.goals);
    setValue('isShowAddMainResponsibilities', assessmentDetail?.isShowAddMainResponsibilities);
    const checkIsSomeAllowEdit = assessmentDetail?.steps?.some((step: any) =>
      step?.data?.some((dt: any) => dt?.allowEdit),
    );
    const checkIncludeInStep = assessmentDetail?.steps?.some((step: any) =>
      step?.data?.some((dt: any) => dt?.assessorId === getUser()?.id),
    );
    if (
      !checkIsSomeAllowEdit &&
      checkIncludeInStep &&
      !(
        assessmentDetail?.status === STATUS_PERFORMANCE.WAITTNG_TO_ACCEPT &&
        getValues('isProcessStep') == false
      )
    ) {
      if (!getValues('isShowAccept')) setDisable(true);
    }
  }, [assessmentDetail]);

  const callApi = () =>
    dispatch(
      getAssessmentDetail({
        reviewCycleId: detailId ? detailId : id,
        userId: detailId ? id : getUser()?.id,
      }),
    );
  useEffect(() => {
    if (detailId || id) {
      callApi();
    }
  }, [id, detailId, dispatch]);

  const showSubmitOrSend = () => {
    const isSubmit = assessmentDetail?.steps?.some((step: any) =>
      step?.data?.some((dt: any) => dt?.stepNumber == 1 && dt?.allowEdit == true),
    );
    if (isSubmit) return t('action:submit');
    return t('onboard:send');
  };

  const onSubmit = (data: any, _?: any, isSave = false) => {
    const newRole =
      roleOptions
        ?.map((i: any) => ({ id: i?.value, name: i?.label }))
        ?.find((option) => option?.id === data?.newRole) || null;
    const newLevel =
      level
        ?.map((i: any) => ({ id: i?.id, name: i?.name }))
        .find((option) => option?.id === data?.newLevel) || null;
    const body = {
      id: data?.id,
      action: isSave ? 'Save' : 'Submit',
      totalRating: data?.totalRating,
      isPromoted: data?.isPromoted,
      newRole,
      newLevel,
      userInform: data?.userInform,
      general: data?.general,
      steps: data?.steps,
      maxRatingScore: data?.maxRatingScore,
      status: getValues('isShowAccept') ? STATUS_PERFORMANCE.ACCEPT : '',
    };
    dispatch(
      updateAssessmentForm({
        id: data?.id,
        body,
        callBack: () => {
          callApi();
        },
      }),
    );
  };

  const callBack = async () => {
    const response = await axiosClient.get(
      `/performance/review/assessment/detail?${queryString.stringify({
        reviewCycleId: detailId ? detailId : id,
        userId: detailId ? id : getUser()?.id,
      })}`,
    );
    const currentValues = getValues();
    reset({
      ...response?.data,
      steps: currentValues?.steps,
    });
    setValue('steps[0]', response?.data?.steps[0] || []);
  };

  const onSaveMainResponsibility = (values: any) => {
    var initalArray = [...(getValues('mainResponsibilities.goals') || [])];

    if (mode === MODE.EDIT) {
      const index = initalArray.findIndex((item: any) => item.id === values[0].id);
      if (index !== -1) {
        const updatedArray = [...initalArray];
        updatedArray.splice(index, 1, values[0]);
        dispatch(
          updateGoalMainResponsibilities({
            id: assessmentDetail?.id,
            goals: updatedArray,
            callBack: async () => {
              await callBack();
              setMode('');
              setCurrentItem(null);
            },
          }),
        );
      }
    } else {
      const mergedArray = [...initalArray, ...values];

      dispatch(
        updateGoalMainResponsibilities({
          id: assessmentDetail?.id,
          goals: mergedArray,
          callBack: async () => {
            await callBack();
            setMode('');
            setCurrentItem(null);
          },
        }),
      );
    }
  };

  const handleDeleteGoals = (selectedValue: any) => {
    var initalArray = [...(getValues('mainResponsibilities.goals') || [])];
    var newArray = initalArray.filter((ele: any) => ele.id !== selectedValue.id);

    dispatch(
      updateGoalMainResponsibilities({
        id: assessmentDetail?.id,
        goals: newArray,
        callBack: async () => {
          await callBack();
          setMode('');
          setCurrentItem(null);
        },
      }),
    );
  };

  return (
    <>
      <Container>
        {<ButtonBack handleBack={() => history.goBack()} />}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 16, margin: '16px 0' }}>
            {!disable && (
              <>
                <CustomButton type="primary" htmlType="submit">
                  {!getValues('isShowAccept') || getValues('allowEditAsessment')
                    ? showSubmitOrSend()
                    : t('performance:accept_evaluate')}
                </CustomButton>
                {!getValues('isShowAccept') && (
                  <CustomButton
                    aType="outline"
                    onClick={() => {
                      onSubmit(getValues(), undefined, true);
                    }}
                  >
                    {t('action:save')}
                  </CustomButton>
                )}
              </>
            )}
            <StatusWrapper style={{ color: getValues('statusColor') || 'inherit' }}>
              • {getValues('status')}
            </StatusWrapper>
          </div>
          <EmployeeInfoWrapper>
            {t('performance:pending_review.employee_name')}:{' '}
            {getValues ? getValues('user.fullName') : ''}
          </EmployeeInfoWrapper>

          {getValues('isProcessStep') === false ? (
            <>
              <HeaderPerformance
                control={control}
                errors={errors}
                getValues={getValues}
                setValue={setValue}
              />
              <Collapse>
                {getValues('steps')?.map((step: any, index: any) => {
                  return (
                    <>
                      <Panel
                        header={`${step?.competency?.name} (${t('performance:total_weight')}: ${
                          step?.competency?.weightPercent
                        })`}
                        key={index}
                      >
                        <PerformanceWeightTable
                          section={`steps[${index}]`}
                          control={control}
                          errors={errors}
                          getValues={getValues}
                          setValue={setValue}
                          data={step?.data}
                        />
                      </Panel>
                    </>
                  );
                })}
              </Collapse>
            </>
          ) : (
            <Tabs>
              {getValues('steps')?.map((step: any, index: any) => {
                if (index === 0) {
                  return (
                    <Tabs.TabPane
                      tab={`${step?.competency?.name} (${t('performance:total_weight')}: ${
                        step?.competency?.weightPercent
                      })`}
                      key={index}
                    >
                      {assessmentDetail.isShowAddMainResponsibilities && (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginBottom: '16px',
                          }}
                        >
                          <HeaderAdd
                            style={{ margin: 0 }}
                            icon={<PlusOutlined />}
                            onClick={() => {
                              setMode(MODE.ADD);
                            }}
                          >
                            {t('menu:add_new')}
                          </HeaderAdd>
                        </div>
                      )}

                      <PerformanceWeightTable
                        isMainResponse={true}
                        section={`steps[${index}]`}
                        control={control}
                        errors={errors}
                        getValues={getValues}
                        setValue={setValue}
                        data={step?.data}
                        mainResponsibilities={getValues('mainResponsibilities.goals')}
                        setMode={setMode}
                        setCurrentItem={setCurrentItem}
                        deleteGoals={handleDeleteGoals}
                        isEdit={getValues('isShowAddMainResponsibilities')}
                      />
                    </Tabs.TabPane>
                  );
                } else {
                  return (
                    <Tabs.TabPane
                      tab={`${step?.competency?.name} (${t('performance:total_weight')}: ${
                        step?.competency?.weightPercent
                      })`}
                      key={index}
                    >
                      <PerformanceWeightTable
                        section={`steps[${index}]`}
                        control={control}
                        errors={errors}
                        getValues={getValues}
                        setValue={setValue}
                        data={step?.data}
                      />
                    </Tabs.TabPane>
                  );
                }
              })}
            </Tabs>
          )}
        </form>
      </Container>

      {mode !== '' && (
        <Modal
          visible={mode !== ''}
          title={mode === MODE.ADD ? t('goals:add_new_goal') : `${t('action:edit')} `}
          okText={t('timesheet:Save')}
          cancelText={t('timesheet:Cancel')}
          width={750}
          maskClosable={false}
          onCancel={() => {
            setMode('');
          }}
          onOk={() => {}}
          centered
          footer={null}
        >
          <AddNewResponsibility
            record={currentItem}
            onSave={onSaveMainResponsibility}
            mode={mode}
            data={getValues()}
          />
        </Modal>
      )}
    </>
  );
};

export default PerformanceDetail;

export const StatusWrapper = styled.div`
  border-radius: 18px;
  padding: 5px 8px;
`;

const EmployeeInfoWrapper = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 12px;
`;

import axiosClient from './axiosClient';

const authApi = {
  loginWithPassword: (data: any) => {
    const response = axiosClient.post(`/auth/signin`, data);
    return response;
  },
  loginWithMicrosoft: (data: any, accessToken: any) => {
    axiosClient.defaults.headers.common['authorizationMs'] = accessToken;
    const response = axiosClient.post(`/auth/signin`, data);
    return response;
  },
  logout: (userId: any) => {
    const response = axiosClient.get(`/auth/signout/${userId}`);
    return response;
  },

  checkAccountExist: (data: any) => {
    const response = axiosClient.post(`/token/check-account-exist`, data);
    return response;
  },

  passwordChange: (params: any) => {
    const response = axiosClient.put(`/employee/change-password/${params.userId}`, params.data);
    return response;
  },
  passwordSendInfoReset: (data: any) => {
    const response = axiosClient.post(`/token/send`, data);
    return response;
  },
  passwordResetVerify: (data: any) => {
    const response = axiosClient.post(`/token/verify`, data);
    return response;
  },
  passwordReset: (data: any) => {
    const response = axiosClient.post(`/token/reset-password`, data);
    return response;
  },

  registerOTPSend: (data: any) => {
    const response = axiosClient.post(`/otp/send`, data);
    return response;
  },
  registerOTPVerify: (data: any) => {
    const response = axiosClient.post(`/otp/verify`, data);
    return response;
  },
  registerEmailSendLink: (data: any) => {
    const response = axiosClient.post(`/otp/send`, data);
    return response;
  },
  registerEmailVerify: (data: any) => {
    const response = axiosClient.post(`/otp/verify`, data);
    return response;
  },
};

export default authApi;

import { Row, Col } from 'antd';
import Select from 'react-select';

import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'app/hooks';
import { selectConfiguration } from 'features/configuration/configurationSlice';
import { selectFormatDate } from 'app/commonRedux/appSlice';

import { DATE_FORMAT } from 'constants/commons';

import CustomInput from 'styles/inputStyled';
import CustomFormItem from 'styles/formStyled';
import CustomDatePicker from 'styles/datePickerStyled';

const EducationInformationItems = (props: any) => {
  const { status } = props;
  const { t } = useTranslation(['validation', 'employee']);
  const dataFormatDate = useAppSelector(selectFormatDate) || DATE_FORMAT;
  const dataConfiguration = useAppSelector(selectConfiguration);

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col md={12} xs={24}>
          <CustomFormItem
            label={t('employee:personnel_information_fields.institute_training_school')}
            required={false}
            name="school"
            rules={[
              {
                whitespace: true,
                message: t('HRM_VAD_BLANK_CHARACTERS', {
                  field: t('employee:personnel_information_fields.institute_training_school'),
                }),
              },
            ]}
          >
            <CustomInput
              disabled={status === 'deleted' ? true : false}
              placeholder={t(
                'employee:personnel_information_fields.institute_training_school_placeholder',
              )}
            />
          </CustomFormItem>
        </Col>
        <Col md={6} xs={24}>
          <CustomFormItem
            label={t('employee:personnel_information_fields.training_period')}
            required={false}
            name="trainingPeriodFrom"
          >
            <CustomDatePicker
              disabled={status === 'deleted' ? true : false}
              placeholder={dataFormatDate.toString().toLowerCase()}
              format={dataFormatDate}
            />
          </CustomFormItem>
        </Col>
        <Col md={6} xs={24}>
          <CustomFormItem label="  " required={false} name="trainingPeriodTo">
            <CustomDatePicker
              disabled={status === 'deleted' ? true : false}
              placeholder={dataFormatDate.toString().toLowerCase()}
              format={dataFormatDate}
            />
          </CustomFormItem>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col md={12} xs={24}>
          <CustomFormItem
            label={t('employee:personnel_information_fields.academic_standard')}
            required={false}
            name="degree"
          >
            <Select
              isDisabled={status === 'deleted' ? true : false}
              placeholder={t('employee:personnel_information_fields.academic_standard_placeholder')}
              maxMenuHeight={200}
              options={dataConfiguration.dataDegree ? dataConfiguration.dataDegree : []}
              cacheOptions
              defaultOptions
            />
          </CustomFormItem>
        </Col>
        <Col md={12} xs={24}>
          <CustomFormItem
            label={t('employee:personnel_information_fields.major')}
            required={false}
            name="schoolMajor"
            rules={[
              {
                whitespace: true,
                message: t('HRM_VAD_BLANK_CHARACTERS', {
                  field: t('employee:personnel_information_fields.major'),
                }),
              },
            ]}
          >
            <CustomInput
              disabled={status === 'deleted' ? true : false}
              placeholder={t('employee:personnel_information_fields.major_placeholder')}
            />
          </CustomFormItem>
        </Col>
      </Row>
    </>
  );
};

export default EducationInformationItems;

export const SelectType = {
  ALL: 'all',
  LATE_MISS: 'latemiss',
};

export const SORT_TYPES = {
  ascend: 'asc',
  descend: 'desc',
};

export const TIMESHEET_SORT_FIELDS = {
  employee_id: 'employee_id',
  fullName: 'fullName',
  full_name: 'full_name',
  department: 'department',
  position: 'position',
  role: 'role',
  level: 'level',
  actualWorkDay: 'actualWorkDay',
  paidLeave: 'paidLeave',
  unPaidLeave: 'unPaidLeave',
  lateearly: 'lateearly',
  misscheck: 'misscheck',
  status: 'status',
  overtime: 'overtime',
  line: 'line',
};

export const REQUEST_APPROVAL_OVERVIEW_SORT_FIELDS = {
  employeeid: 'employeeid',
  request_count: 'request_count',
  fullName: 'fullName',
  department: 'department',
  team: 'team',
  position: 'position',
  level: 'level',
};

export const TIMESHEET_TYPES = {
  RANGE: 'range',
  MONTH: 'month',
  YEAR: 'YEAR',
};

export const TAB_KEYS = {
  RANGE: 'range',
  MONTH: 'month',
  YEAR: 'year',
};

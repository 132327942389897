import { Button, DatePicker, Modal, Popconfirm } from 'antd';
import { useAppSelector } from 'app/hooks';
import EmployeeColumn from 'components/EmployeeColumn';
import ITVSearch from 'components/ITVSearch';
import ITVTable, { COLUMNS } from 'components/ITVTable';
import { notificationToast } from 'components/notificationToast';
import { Notification } from 'constants/notification';
import { selectEmployee, setCheckRow } from 'features/employee/employeeSlice';
import { ListUserCamera } from 'features/masterData/General/Interfaces/CameraAI';
import ModelImportImage from 'features/masterData/General/components/pages/CameraAI/components/ModalImportImage';
import { getPersonCameraAI, removeCheckInPerson } from 'features/masterData/masterDataAction';
import { selectMasterData } from 'features/masterData/masterDataSlice';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import CustomButton from 'styles/buttonStyled';
import { TitleColumnCenter } from 'styles/tableStyled';
import { removeAccents } from 'utils/text';
import UserColumn from './UserColumn';

const ListOfUsers = (props: any) => {
  const { placeId } = props;
  const { t } = useTranslation(['masterConfig', 'employee', 'leaveOff']);
  const selectRowKey = useAppSelector(selectEmployee).selectRowKey;
  const [, setResultTotal] = useState(0);
  const [searching, setSearching] = useState(false);
  const [textSearch, setTextSearch] = useState('');
  const masterData = useAppSelector(selectMasterData);
  const dataUsers: ListUserCamera[] = masterData.dataEmployee;
  const loading: boolean = masterData.loading;
  const [dataUserState, setDataUserState] = useState<ListUserCamera[]>([]);
  const [selectedUser, setSelectedUser] = useState<any>();
  const [selectedUsers, setSelectedUsers] = useState<any>([]);
  const [showModal, setShowModal] = useState(false);
  const [sizePage, setSizePage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();

  useEffect(() => {
    setSelectedUsers(selectRowKey);
  }, [selectRowKey]);

  useEffect(() => {
    dispatch(getPersonCameraAI(placeId));
  }, [placeId, dispatch]);

  useEffect(() => {
    if (masterData) {
      setDataUserState(dataUsers);
    }
    if (textSearch) {
      handleSearchIdName(textSearch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataUsers]);

  const handleSearchIdName = (keySearch: string) => {
    const temp = dataUsers?.filter((item: any) => {
      return (
        item?.name?.toString()?.toLowerCase()?.includes(keySearch) ||
        removeAccents(item?.name?.replace(/ /g, ''))
          ?.toLowerCase()
          ?.includes(removeAccents(keySearch).replace(/ /g, '').toLowerCase())
      );
    });

    setResultTotal(temp.length);
    setDataUserState(temp);
  };

  let COLUMNS: COLUMNS[] = [
    {
      title: () => {
        return (
          <TitleColumnCenter>{t('employee:employee_information_fields.id')}</TitleColumnCenter>
        );
      },
      dataIndex: 'employeeId',
      key: 'employeeId',
      ellipsis: true,
      width: 60,
      minWidth: 60,
      align: 'center',
    },
    {
      title: () => <TitleColumnCenter>{t('masterConfig:manage_place.image')}</TitleColumnCenter>,
      key: 'image',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (text: string, record: any) => {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {<img alt="" src={record?.avatar} width="79px" height="80px" />}
            {record?.avatar && (
              <span
                onClick={() => {
                  setSelectedUser(record);
                  setShowModal(true);
                }}
              >
                {t('masterConfig:manage_place.Import_image')}
              </span>
            )}
          </div>
        );
      },
    },
    {
      title: () => (
        <TitleColumnCenter>{t('employee:employee_information_fields.employee')}</TitleColumnCenter>
      ),
      dataIndex: 'fullName',
      key: 'fullName',
      width: 220,
      minWidth: 220,
      ellipsis: true,
      render: function (text: any, record: any, index: any) {
        return (
          <EmployeeColumn
            avatar={`${record?.name}`}
            fullName={`${record?.name}`}
            email={record?.email}
          />
        );
      },
    },
  ];

  const onSearchList = (key: string) => {
    setSearching(true);
    const text = key;
    if (text) {
      setTextSearch(text);
      handleSearchIdName(text);
    } else {
      setTextSearch(text);
      setDataUserState(dataUsers);
      setResultTotal(dataUserState?.length);
    }
    setSearching(false);
  };

  const [isVisibleAddColumn, setIsVisibleAddColumn] = useState(false);
  const childRefAddColumn = useRef<any>();

  const handleHideAddColumn = (value: any) => {
    setIsVisibleAddColumn(false);
  };

  const handleVisibleChange = () => {
    setIsVisibleAddColumn(true);
  };
  return (
    <>
      {showModal && (
        <Modal
          visible={showModal}
          onCancel={() => setShowModal(false)}
          closable={true}
          footer={null}
          width={500}
          centered={true}
        >
          <ModelImportImage
            placeId={placeId}
            setShowModal={setShowModal}
            prev={null}
            selectedEmployee={selectedUser}
          />
        </Modal>
      )}
      <Modal
        visible={isVisibleAddColumn}
        onCancel={() => setIsVisibleAddColumn(false)}
        footer={null}
        destroyOnClose
        centered
      >
        <UserColumn
          ref={childRefAddColumn}
          handleVisibleAddColumn={handleHideAddColumn}
          addColumns={props.addColumns}
          isVisibleAddColumn={isVisibleAddColumn}
          placeId={placeId}
        />
      </Modal>
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <SearchBar handleSearch={(value: any) => onSearchList(value)} />
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Popconfirm
            title={t('leaveOff:preview.are_you_sure')}
            onConfirm={() => {
              dispatch(
                removeCheckInPerson({
                  employeeIds: selectedUsers,
                  placeId,
                  callBack: (res: any) => {
                    dispatch(setCheckRow([]));
                    dispatch(getPersonCameraAI(placeId));
                    notificationToast(
                      Notification.Type.Success,
                      res?.message,
                      Notification.Duration._3s,
                    );
                  },
                }),
              );
            }}
            okText={t('leaveOff:item.allow_add_yes')}
            cancelText={t('leaveOff:item.allow_add_no')}
            placement="topRight"
          >
            <CustomButton disabled={selectedUsers?.length ? false : true}>
              {t('employee:employee_manage.delete')}
            </CustomButton>
          </Popconfirm>
          <CustomButton onClick={handleVisibleChange} style={{ marginLeft: 15 }}>
            {t('masterConfig:manage_place.Add_user')}
          </CustomButton>
        </div>
      </div>
      <ITVTable
        columns={COLUMNS}
        data={dataUserState}
        height={500}
        isScroll={true}
        rowKey={['employeeId']}
        loading={loading || searching}
        isRowSelect={true}
        setOffset={setCurrentPage}
        offset={currentPage}
        setLimit={setSizePage}
        limit={sizePage}
      />
    </>
  );
};

export default ListOfUsers;

export const DatePickerCustom = styled(DatePicker)`
  width: 100%;
`;

export const DatePickerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const FormContainer = styled.div`
  width: 80%;
  margin: 20px;
`;

export const FormContent = styled.div`
  display: flex;
  align-content: space-between;
  flex-direction: column;
  padding: 20px 30px 10px 30px;
  background-color: white;
`;

export const CustomApprove = styled(Button)`
  background: #f1592a;
  border-radius: 4px;
  padding: 4px 12px;
  margin-left: 10px;
  height: 30px;
  color: #ffffff;
`;

export const SearchBar = styled(ITVSearch)`
  width: 40%;
  margin-bottom: 10px;
`;

export const Titles = styled.div`
  display: flex;
  height: 20px;
  font-size: 14px;
  font-family: Roboto;
`;

export const ItemBody = styled.div`
  margin-bottom: 6px;
`;

export const InfoButtonCancel = styled(Button)`
  border-radius: 4px;
  padding-left: 12px;
  padding-right: 12px;
  height: 30px;
`;

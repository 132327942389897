import { useState } from 'react';
import { CloseCircleFilled } from '@ant-design/icons';
import { Popover } from 'antd';
import { CaptionLayout, DayPicker } from 'react-day-picker';
import styled, { CSSProperties, css } from 'styled-components';
import { Control, FieldValues, Path, useController } from 'react-hook-form';
import moment from 'moment';

import { DatePickerIcon } from 'assets/icons';
import { DATE_FORMAT } from 'constants/commons';

import 'react-day-picker/dist/style.css';
import './DatePickerFieldVer2.scss';

interface StyledWrapperProps {
  directionLabel: 'horizontal' | 'vertical';
}

interface StyledDayLabelProps {
  isPlaceholder: boolean;
  disabled: boolean;
}

interface StyledSelectionProps {
  isError: boolean;
  disabled: boolean;
}

export type DatePickerFieldVer2Props<T extends FieldValues> = {
  requiredIcon?: boolean;
  label?: string;
  name: Path<T>;
  control: Control<T>;
  format?: string;
  allowClear?: boolean;
  directionLabel?: 'horizontal' | 'vertical';
  labelStyle?: CSSProperties;
  placeholder?: string;
  disabled?: boolean;
  fromYear?: number;
  toYear?: number;
  captionLayout?: CaptionLayout;
  onChange?: (date?: Date) => void;
};

export function DatePickerFieldVer2<T extends FieldValues>({
  name,
  control,
  directionLabel = 'vertical',
  label,
  format = DATE_FORMAT,
  allowClear = false,
  placeholder,
  disabled = false,
  fromYear = 2000,
  toYear = 2100,
  captionLayout = 'dropdown-buttons',
  onChange: externalOnChange,
}: DatePickerFieldVer2Props<T>) {
  const {
    field: { onChange, value, ref },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const [localValue, setLocalValue] = useState<Date | undefined>(value);

  const dayLabel = localValue ? moment(localValue).format(format) : placeholder;

  const resetDate = () => {
    setLocalValue(undefined);
    onChange(undefined);
    externalOnChange?.(undefined);
  };

  return (
    <StyledContainer>
      <StyledWrapper directionLabel={directionLabel}>
        {label && <StyledLabel directionLabel={directionLabel}>{label}</StyledLabel>}

        <Popover
          content={
            !disabled ? (
              <DayPicker
                mode="single"
                showOutsideDays={true}
                initialFocus
                captionLayout={captionLayout}
                fromYear={fromYear}
                toYear={toYear}
                defaultMonth={localValue}
                selected={localValue}
                onSelect={(day) => {
                  setLocalValue(day);
                  onChange(day);
                  externalOnChange?.(day);
                }}
              />
            ) : undefined
          }
          overlayClassName="hrm-overlay-popover"
          arrowPointAtCenter={false}
          trigger="click"
          getPopupContainer={(triggerNode) => triggerNode.parentElement as HTMLElement}
        >
          <StyledSelection isError={Boolean(error?.message)} disabled={disabled} ref={ref}>
            <StyledSelectLabel>
              <StyledDayLabel isPlaceholder={!localValue} disabled={disabled}>
                {dayLabel}
              </StyledDayLabel>
            </StyledSelectLabel>

            <DatePickerIcon />

            {allowClear && !disabled && (
              <StyledWrapperCloseIcon>
                <CloseCircleFilled
                  onClick={(event) => {
                    event.stopPropagation();
                    resetDate();
                  }}
                />
              </StyledWrapperCloseIcon>
            )}
          </StyledSelection>
        </Popover>
      </StyledWrapper>

      {error?.message && <StyledErrorMessage>{error?.message}</StyledErrorMessage>}
    </StyledContainer>
  );
}

const StyledContainer = styled.div``;

const StyledWrapper = styled.div`
  ${(props: StyledWrapperProps) =>
    props?.directionLabel === 'horizontal' &&
    css`
      display: flex;
      align-items: center;
    `}
`;

const StyledLabel = styled.p`
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: var(--cl_gray900);
  ${(props: StyledWrapperProps) =>
    props?.directionLabel === 'vertical' &&
    css`
      margin-bottom: 6px;
    `}
`;

const StyledErrorMessage = styled.div`
  color: var(--cl_error500);
  margin-top: 6px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
`;

const StyledWrapperCloseIcon = styled.span`
  position: absolute;
  right: 13px;
  background-color: white;
  color: rgba(0, 0, 0, 0.25);
  transition: all 0.3s;
  opacity: 0;

  &:hover {
    color: rgba(0, 0, 0, 0.45);
  }
`;

const StyledSelection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 0 12px;
  border-radius: 6px;
  border: 1px solid var(--cl_neutral300);
  height: 32px;
  width: 100%;
  cursor: pointer;
  position: relative;

  ${(props: StyledSelectionProps) =>
    props?.isError &&
    css`
      border-color: #ff4d4f;
    `}

  ${(props: StyledSelectionProps) =>
    props?.disabled &&
    css`
      background: #f5f5f5;
      border-color: #d9d9d9;
      cursor: not-allowed;
    `}

  &:hover {
    background-color: #f4f4f5;
  }

  &:hover ${StyledWrapperCloseIcon} {
    opacity: 1;
    background-color: #f4f4f5;
  }
`;

const StyledSelectLabel = styled.span`
  min-width: 165px;
`;

const StyledDayLabel = styled.span`
  min-width: 70px;
  width: 70px;
  color: ${(props: StyledDayLabelProps) => props.isPlaceholder && '#bfbfbf'};
  opacity: ${(props: StyledDayLabelProps) => props.disabled && 0.25};
`;

import { Button, DatePicker, Modal } from 'antd';
import ITVTable from 'components/ITVTable';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { getPersonalAccessCheckInHistory } from 'features/masterData/masterDataAction';
import { useAppSelector } from 'app/hooks';
import { selectMasterData } from 'features/masterData/masterDataSlice';
import camera from 'assets/icons/camera.png';
import icon_machine from 'assets/icons/icon_machine.png';
import online from 'assets/icons/online.png';
import { IconType } from 'features/timesheet/components/Calenda_title';
import { selectFormatDate } from 'app/commonRedux/appSlice';

const HistoryAccess = (props: any) => {
  const { typeHistory, visible, setVisibleHistory, record } = props;
  const [data, setData] = useState([]);
  const [startDate, setStartDate]: any = useState();
  const { t } = useTranslation(['masterConfig', 'employee']);
  const [endDate, setEndDate]: any = useState();
  const dispatch = useDispatch();
  const masterData = useAppSelector(selectMasterData);
  const personalHistory = masterData.personalHistory?.result;
  const total = masterData.personalHistory?.total;
  const [sizePage, setSizePage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const formatDate = useAppSelector(selectFormatDate);

  useEffect(() => {
    if (visible) {
      const params = {
        offset: 1,
        limit: sizePage,
        body: {
          userId: record?.user?.id,
          type: typeHistory,
          searchStartDate: '',
          searchEndDate: '',
        },
      };
      dispatch(getPersonalAccessCheckInHistory(params));
    } else {
      setCurrentPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  useEffect(() => {
    //handle paginate and change pagesize
    if (visible) {
      const params = {
        offset: currentPage,
        limit: sizePage,
        body: {
          userId: record?.user?.id,
          type: typeHistory,
          searchStartDate: startDate ? startDate : '',
          searchEndDate: endDate ? endDate : '',
        },
      };
      dispatch(getPersonalAccessCheckInHistory(params));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, sizePage]);

  useEffect(() => {
    if (personalHistory) {
      setData(personalHistory);
    }
  }, [personalHistory]);

  const handleGetDataByUserInput = () => {
    const params = {
      offset: 1,
      limit: 10,
      body: {
        userId: record?.user?.id,
        type: typeHistory,
        searchStartDate: startDate,
        searchEndDate: endDate,
      },
    };
    dispatch(getPersonalAccessCheckInHistory(params));
    setCurrentPage(1);
  };

  const transType = () => {
    switch (typeHistory) {
      case 'online':
        return (
          <div>
            <IconType src={online} style={{ display: 'flex', marginRight: 3 }} />{' '}
            {t('masterConfig:general.online')}
          </div>
        );
      case 'machine':
        return (
          <div>
            <IconType src={icon_machine} style={{ display: 'flex', marginRight: 3 }} />{' '}
            {t('masterConfig:general.machine')}
          </div>
        );
      case 'camera':
        return (
          <div>
            <IconType src={camera} style={{ display: 'flex', marginRight: 3 }} />{' '}
            {t('masterConfig:general.camera')}
          </div>
        );
      default:
        return '';
    }
  };

  const handleCancel = () => {
    setVisibleHistory(false);
    setStartDate(undefined);
    setEndDate(undefined);
  };

  const handleOnChangeStartDate = (date: any, dateString: any) => {
    setStartDate(dateString ? moment(dateString).format(formatDate) : null);
  };

  const handleOnChangeEndDate = (date: any, dateString: any) => {
    setEndDate(dateString ? moment(dateString).format(formatDate) : null);
  };

  const handleDisableDate = (date: any) => {
    if (moment(date).isBefore(moment(startDate))) {
      return true;
    } else {
      return false;
    }
  };

  const columns: any = [
    {
      title: 'No',
      dataIndex: 'noDetail',
      key: 'noDetail',
      ellipsis: true,
      width: 10,
      minWidth: 10,
      align: 'center',
      fixed: 'left',
      render: function (record: any) {
        return <div>{`${record}`}</div>;
      },
    },
    {
      title: t('masterConfig:general.date_start'),
      dataIndex: 'startTime',
      key: 'startTime',
      ellipsis: true,
      width: 30,
      minWidth: 30,
      align: 'center',
      fixed: 'left',
      render: function (record: any) {
        return <div>{`${record ? moment(record).format(formatDate) : ''}`}</div>;
      },
    },
    {
      title: t('masterConfig:general.date_end'),
      dataIndex: 'endTime',
      key: 'endTime',
      ellipsis: true,
      width: 30,
      minWidth: 30,
      align: 'center',
      fixed: 'left',
      render: function (record: any) {
        return <div>{`${record ? moment(record).format(formatDate) : ''}`}</div>;
      },
    },
    {
      title: t('masterConfig:general.status'),
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      width: 15,
      minWidth: 15,
      align: 'center',
      fixed: 'left',
      render: function (record: any) {
        return <div>{`${record}`}</div>;
      },
    },
    {
      title: t('masterConfig:general.update_date'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      ellipsis: true,
      width: 40,
      minWidth: 40,
      align: 'center',
      fixed: 'left',
      render: function (record: any) {
        return <div>{`${moment(record).format(`${formatDate} hh:mm:ss`)}`}</div>;
      },
    },
  ];

  return (
    <Modal
      width={800}
      visible={visible}
      onCancel={handleCancel}
      title={t('masterConfig:general.update_history')}
      footer={null}
    >
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row-reverse',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <CheckInTypeContainer>{transType()}</CheckInTypeContainer>
          </div>
          <div style={{ margin: '10px 0', fontSize: '24px', fontWeight: '700' }}>
            {t('masterConfig:general.employee_name')} {record?.user?.fullName}
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: '20px',
          }}
        >
          <div>
            {t('masterConfig:general.date_start')}:{' '}
            <DatePicker
              onChange={handleOnChangeStartDate}
              value={startDate ? moment(startDate) : null}
              format={formatDate}
            />
          </div>
          <div>
            {t('masterConfig:general.date_end')}:{' '}
            <DatePicker
              onChange={handleOnChangeEndDate}
              value={endDate ? moment(endDate) : null}
              disabledDate={handleDisableDate}
              format={formatDate}
            />
          </div>
        </div>
      </div>

      <ITVTable
        columns={columns}
        data={data}
        isRowSelect={false}
        totalResult={total}
        setOffset={setCurrentPage}
        offset={currentPage}
        setLimit={setSizePage}
        limit={sizePage}
        width={700}
      />
      <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          disabled={!startDate || !endDate}
          type="primary"
          // style={{ backgroundColor: '#FE753E', color: '#fff', borderRadius: '10px' }}
          onClick={handleGetDataByUserInput}
        >
          {t('masterConfig:general.view_history')}
        </Button>
      </div>
    </Modal>
  );
};

export default HistoryAccess;

export const CheckInTypeContainer = styled.div`
  // background-color: #01c901;
  // width: fit-content;
  // padding: 5px;
  // border-radius: 14px;
`;

import React, { useState } from 'react';
import Icon from '@ant-design/icons';
import IconTritangle from 'assets/icons/ic_tritangle_orange.svg';
import IconTrash from 'assets/icons/ic_trash_gray.svg';

import MainSkillFieldsAdd from './MainSkillFieldsAdd';
import {
  SkillInfoItem,
  MainSkillContainerInfo,
  MainSkillInfoItemName,
  MainSkillInfoItemNameIcon,
  MainSkillInfoItemYoe,
  MainSkillInfoItemButtonContainer,
  SkillInfoItemButton,
  SkillDescInputContainer,
} from '../styled';

const MainSkillItems = (props: any) => {
  const {
    isCanUpdate,
    indexMainSkillItem,
    valueMainSkillItem,
    mainSkill,
    setMainSkill,
    onEmployeeUpdateMainSkill,
    onDeleteMainSkill,
  } = props;

  const [isUpdatingMainSkill, setIsUpdatingMainSkill] = useState(false);

  const onShowUpdatingMainSkill = () => {
    setIsUpdatingMainSkill(!isUpdatingMainSkill);
  };

  return (
    <>
      <SkillInfoItem key={'MainSkillItems' + indexMainSkillItem}>
        <MainSkillContainerInfo onClick={onShowUpdatingMainSkill}>
          <MainSkillInfoItemName>
            {valueMainSkillItem.name}
            <MainSkillInfoItemNameIcon component={() => <img src={IconTritangle} alt="" />} />
          </MainSkillInfoItemName>
          <MainSkillInfoItemYoe>YOE: {valueMainSkillItem.yearOfExperience}</MainSkillInfoItemYoe>
        </MainSkillContainerInfo>
        {isCanUpdate && (
          <MainSkillInfoItemButtonContainer>
            <SkillInfoItemButton
              onClick={onDeleteMainSkill(indexMainSkillItem)}
              icon={<Icon component={() => <img src={IconTrash} alt="" />} />}
            ></SkillInfoItemButton>
          </MainSkillInfoItemButtonContainer>
        )}
      </SkillInfoItem>
      {isUpdatingMainSkill && (
        <SkillDescInputContainer>
          <MainSkillFieldsAdd
            isCanUpdate={isCanUpdate}
            indexMainSkillItem={indexMainSkillItem}
            valueMainSkillItem={valueMainSkillItem}
            mainSkill={mainSkill}
            setMainSkill={setMainSkill}
            onEmployeeUpdateMainSkill={onEmployeeUpdateMainSkill}
            setIsAddingMainSkill={setIsUpdatingMainSkill}
          />
        </SkillDescInputContainer>
      )}
    </>
  );
};

export default MainSkillItems;

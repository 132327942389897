import { Dispatch, useEffect, useState } from 'react';
import { Tabs } from 'antd';
import type { TabsProps } from 'antd';
import { useAppSelector } from 'app/hooks';
import { selectRecruitmentData } from 'features/recruitment/recruitmentSlice';
import BadgeStatusApproved from 'features/timesheet/components/BadgeStatusApproved';
import { isApproveOffer } from 'features/recruitment/jobs/components/types';
import { useTranslation } from 'react-i18next';

enum HiringProcessStatusCandidate {
  ALL = 'All',
  APPLICATION_RECEIVED = 'Application Received',
  SHORTLISTED = 'Shortlisted',
  IN_PROGRESS = 'In Progress',
  JOB_OFFER = 'Job Offer',
  OFFER_ACCEPTED = 'Offer Accepted',
  OFFER_REJECTED = 'Offer Rejected',
  CLOSED = 'Closed',
}

const SideBarMenu = ({
  status,
  setStatus,
  onChange,
}: {
  status: HiringProcessStatusCandidate | undefined;
  setStatus: Dispatch<React.SetStateAction<HiringProcessStatusCandidate | undefined>>;
  onChange?: (key: string) => void;
}) => {
  const { t, i18n } = useTranslation(['recruitment']);
  const recruitment = useAppSelector(selectRecruitmentData);
  const candidateManagementCount = recruitment?.candidateManagement?.count || [];
  const [activeKey, setActiveKey] = useState<string>(HiringProcessStatusCandidate.ALL);
  const [items, setItems] = useState<TabsProps['items']>([]);

  useEffect(() => {
    const listMenu = [
      { id: HiringProcessStatusCandidate.ALL, name: t('recruitment:all_candidates'), total: 50 },
      {
        id: HiringProcessStatusCandidate.APPLICATION_RECEIVED,
        name: t('recruitment:application_received'),
        total: 50,
      },
      {
        id: HiringProcessStatusCandidate.SHORTLISTED,
        name: t('recruitment:shortlisted'),
        total: 50,
      },
      {
        id: HiringProcessStatusCandidate.IN_PROGRESS,
        name: t('recruitment:in_progress'),
        total: 50,
      },
      { id: HiringProcessStatusCandidate.JOB_OFFER, name: t('recruitment:job_offer'), total: 50 },
      {
        id: HiringProcessStatusCandidate.OFFER_ACCEPTED,
        name: t('recruitment:offer_accepted'),
        total: 50,
      },
      {
        id: HiringProcessStatusCandidate.OFFER_REJECTED,
        name: t('recruitment:offer_rejected'),
        total: 50,
      },
      { id: HiringProcessStatusCandidate.CLOSED, name: t('recruitment:closed'), total: 50 },
    ];
    if (candidateManagementCount.length > 0) {
      const candidateManagementCountMapped = candidateManagementCount.map((item) => ({
        id: item.status === 'All Candidates' ? HiringProcessStatusCandidate.ALL : item.status,
        name: item.status,
        total: item.count,
      }));
      const newItems: TabsProps['items'] = candidateManagementCountMapped.map((menu) => ({
        key: menu.id,
        label: (
          <BadgeStatusApproved count={menu.total}>
            <span>{t(`recruitment:${menu.name.toLowerCase().replace(/ /g, '_')}`)}</span>
          </BadgeStatusApproved>
        ),
      }));
      const finalItems = newItems;
      setItems(finalItems);
    } else {
      const defaultItems: TabsProps['items'] = listMenu.map((menu) => ({
        key: menu.id,
        label: (
          <BadgeStatusApproved count={0}>
            <span>{menu.name}</span>
          </BadgeStatusApproved>
        ),
      }));
      const finalDefaultItems = defaultItems;
      setItems(finalDefaultItems);
    }
  }, [recruitment?.candidateManagement?.count, t, i18n.language]);

  const handleChange = (key: string) => {
    setActiveKey(key);
    setStatus(
      key === HiringProcessStatusCandidate.ALL ? undefined : (key as HiringProcessStatusCandidate),
    );
    onChange && onChange(key);
  };

  return (
    <Tabs
      style={{ height: '100%', width: '100%', display: 'flex' }}
      activeKey={activeKey}
      items={items}
      onChange={handleChange}
    />
  );
};

export default SideBarMenu;

import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import dashboardEmployeeApi from 'api/dashboardEmployeeApi';
import { MONTH_FORMAT } from 'constants/commons';
import { TimesheetAdjustmentReport } from 'types';
import { exportToCSV } from 'utils/common';
import HeadcountLocationChart from './HeadcountLocationChart';
import HeadcountLocationFilters from './HeadcountLocationFilters';

const StyledContainer = styled.div`
  padding: 16px 24px;
  background-color: var(--cl_white);
  border-radius: 12px;
  border: 0.5px solid var(--cl_gray200);
  height: 255px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const StyledHeaderTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto;
  line-height: 20px;
  color: #121926;
`;

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  flex-wrap: wrap;
  gap: 10px;
`;

export function HeadcountByLocation() {
  const { t } = useTranslation(['dashboard']);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const [dataChart, setDataChart] = useState<TimesheetAdjustmentReport>({
    labels: [],
    datasets: [],
  });

  const [monthYear, setMonthYear] = useState<Moment | null>(moment(new Date()));

  function formatNumber(num: number) {
    if (num >= 1 && num <= 9) {
      return '0' + num.toString();
    } else {
      return num.toString();
    }
  }

  useEffect(() => {
    async function fetchHeadcountLocation() {
      try {
        if (!monthYear) return;

        setIsLoading(true);
        const response = await dashboardEmployeeApi.getHeadcountLocation({
          month: formatNumber(monthYear.month() + 1),
          year: monthYear.year(),
        });
        const labels = response.data.map((data: any) => data.name ?? 'null');
        const datasets = [
          {
            label: '',
            data: response.data?.map((x: any) => x.data_location),
            fill: true,
            backgroundColor: '#4472C4',
            borderColor: '#4472C4',
            barThickness: 20,
            borderRadius: 4,
          },
        ];
        setIsLoading(false);
        setDataChart((prevState) => ({
          ...prevState,
          labels,
          datasets,
        }));
      } catch (error) {
        setIsLoading(false);
      }
    }

    fetchHeadcountLocation();
  }, [monthYear]);

  const handleExport = async () => {
    try {
      if (!monthYear) return;

      setIsLoadingExport(true);
      const response = await dashboardEmployeeApi.exportHeadcountLocation({
        month: formatNumber(monthYear.month() + 1),
        year: monthYear.year(),
      });
      setIsLoadingExport(false);

      const exportFileName = `Headcount_By_Location_${moment(monthYear).format(MONTH_FORMAT)}.xlsx`;
      exportToCSV(response, exportFileName);
    } catch (error) {
      setIsLoadingExport(false);
    }
  };

  return (
    <StyledContainer>
      <StyledWrapper>
        <StyledHeaderTitle>{t('dashboard_employee.headcount_locations')}</StyledHeaderTitle>

        <HeadcountLocationFilters
          initialMonthYear={monthYear}
          onSubmit={(monthYear: Moment | null) => setMonthYear(monthYear)}
          loadingExport={isLoadingExport}
          handleExport={handleExport}
        />
      </StyledWrapper>

      <HeadcountLocationChart loading={isLoading} dataChart={dataChart} />
    </StyledContainer>
  );
}

import { DownOutlined } from '@ant-design/icons';
import { DatePicker } from 'antd';
import { Moment } from 'moment';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import { RangePickerField } from 'components/FormFields';
import { EmployeeSummaryPayload } from 'types';

const StyledWrapperYear = styled.div`
  width: 80px;
  .ant-picker {
    border-radius: 6px;
    border: 1px solid var(--cl_neutral300);
  }
  .ant-picker-input > input {
    color: #1a2b88;
    font-weight: bold;
    font-size: 12px;
    text-align: center;
  }
  .ant-picker-suffix {
    font-size: 10px;
  }
  .ant-picker {
    background-color: #f6f6f6;
    padding: 5px;
  }
`;

const StyledWrapperYearRange = styled.div`
  width: 150px;
  .ant-picker-input > input {
    color: #1a2b88;
    font-weight: bold;
    font-size: 12px;
    text-align: center;
  }
  .ant-picker-suffix {
    font-size: 10px;
  }
  .ant-picker {
    background-color: #f6f6f6;
    padding: 5px;
  }
`;

interface EmployeeSummaryFiltersProps {
  initialValues?: EmployeeSummaryPayload;
  onSubmit?: (payload: EmployeeSummaryPayload) => void;
  isSwitch: boolean;
}

export default function EmployeeSummaryFilters({
  initialValues,
  onSubmit,
  isSwitch,
}: EmployeeSummaryFiltersProps) {
  const [year, setYear] = useState<Moment | null>(initialValues?.year || null);

  const { control, handleSubmit } = useForm<EmployeeSummaryPayload>({
    defaultValues: initialValues,
  });

  const handleFiltersSubmit = (formValues: EmployeeSummaryPayload) => {
    onSubmit?.(formValues);
  };

  const handleDateRangeChange = () => {
    handleSubmit(handleFiltersSubmit)();
  };

  return (
    <>
      {isSwitch ? (
        <StyledWrapperYearRange>
          <RangePickerField
            name="yearRange"
            picker="year"
            control={control}
            allowClear={false}
            onChange={handleDateRangeChange}
            value={[initialValues?.yearRange.fromYear, initialValues?.yearRange.endYear]}
            suffixIcon={<DownOutlined />}
          />
        </StyledWrapperYearRange>
      ) : (
        <StyledWrapperYear>
          <DatePicker
            picker="year"
            value={year}
            allowClear={false}
            onChange={(value) => {
              setYear(value);
              onSubmit?.({ year: value });
            }}
            suffixIcon={<DownOutlined />}
          />
        </StyledWrapperYear>
      )}
    </>
  );
}

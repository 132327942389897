import { InputField, SelectField, TextAreaField } from 'components/FormFields';
import { useForm } from 'react-hook-form';
import CustomButton from 'styles/buttonStyled';
import { VIEW_SCREEN } from './CreateJob';
import { useEffect, useState } from 'react';
import { Radio } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { getJobHirringProcess, getJobTemplates } from 'features/recruitment/recruitmentAction';
import { selectRecruitmentData } from 'features/recruitment/recruitmentSlice';
import { JobHirringProcess, JobTemplate } from './types';
import { useTranslation } from 'react-i18next';

enum VIEW_SCREEN_TEMPLATE {
  CREATE_JOB = 'CREATE_JOB',
  HIRING_PROCESS = 'HIRING_PROCESS',
}

const UseTemplate = ({
  setViewScreen,
  setVisible,
  callBack,
}: {
  setViewScreen: (screen: VIEW_SCREEN) => void;
  setVisible: (visible: boolean) => void;
  callBack: (data: any) => void;
}) => {
  const { t } = useTranslation(['recruitment', 'action']);
  const dispatch = useAppDispatch();
  const jobHirringProcess: { result: JobHirringProcess[] } =
    useAppSelector(selectRecruitmentData)?.jobHirringProcess;
  const jobTemplates: { result: JobTemplate[] } =
    useAppSelector(selectRecruitmentData)?.jobTemplates;

  const [viewScreenTempl, setViewScreenTempl] = useState<VIEW_SCREEN_TEMPLATE>(
    VIEW_SCREEN_TEMPLATE.CREATE_JOB,
  );
  const [selectedTemplate, setSelectedTemplate] = useState<string>('');
  const [selectedHirringProcess, setSelectedHirringProcess] = useState<string>('');

  useEffect(() => {
    dispatch(getJobTemplates({}));
  }, [dispatch]);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: undefined,
  });

  const onSubmit = () => {
    const data = {
      isTemplate: true,
      selectedTemplate,
      selectedHirringProcess,
      jobHirringProcess: jobHirringProcess.result.find((i) => i.id === selectedHirringProcess),
      jobTemplates: jobTemplates.result.find((i) => i.id === selectedTemplate),
    };
    callBack && callBack(data);
  };

  const renderScreen = (screen: VIEW_SCREEN_TEMPLATE) => {
    const jobTemplatesOptions =
      jobTemplates?.result?.map((item) => ({ label: item.name, value: item.id })) || [];
    const hirringProcess = jobHirringProcess?.result || [];
    switch (screen) {
      case VIEW_SCREEN_TEMPLATE.HIRING_PROCESS:
        return (
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                fontWeight: 'bold',
                fontSize: 18,
              }}
            >
              {t('recruitment:please_select_hiring_process')}
            </div>
            <Radio.Group
              style={{ display: 'flex', flexDirection: 'column', gap: 8, marginTop: 16 }}
              onChange={(e) => {
                setSelectedHirringProcess(e.target.value);
              }}
            >
              {hirringProcess?.map((item) => (
                <Radio key={item.id} value={item.id}>
                  {item.name}
                </Radio>
              ))}
            </Radio.Group>
            <div style={{ display: 'flex', justifyContent: 'center', gap: 8, marginTop: '1rem' }}>
              <CustomButton
                aType="text"
                onClick={() => {
                  setViewScreenTempl(VIEW_SCREEN_TEMPLATE.CREATE_JOB);
                }}
              >
                {t('action:cancel')}
              </CustomButton>
              <CustomButton
                disabled={!selectedHirringProcess}
                onClick={() => {
                  onSubmit();
                }}
              >
                {t('recruitment:select')}
              </CustomButton>
            </div>
          </div>
        );

      default:
        return (
          <form>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                fontWeight: 'bold',
                fontSize: 18,
              }}
            >
              {t('recruitment:create_job')}
            </div>
            <SelectField
              control={control}
              onSelect={setSelectedTemplate}
              options={jobTemplatesOptions}
              label={
                <>
                  {t('recruitment:select_job_template')} <span style={{ color: 'red' }}>*</span>
                </>
              }
              name="templateId"
              rules={{ required: 'This field is required' }}
            />
            <div style={{ display: 'flex', justifyContent: 'center', gap: 8, marginTop: '1rem' }}>
              <CustomButton
                aType="text"
                onClick={() => {
                  setViewScreen && setViewScreen(VIEW_SCREEN.CREATE_JOB);
                }}
              >
                {t('action:cancel')}
              </CustomButton>
              <CustomButton
                onClick={() => {
                  if (selectedTemplate) {
                    setViewScreenTempl(VIEW_SCREEN_TEMPLATE.HIRING_PROCESS);
                  }
                }}
                disabled={!selectedTemplate}
              >
                {t('recruitment:continue')}
              </CustomButton>
            </div>
          </form>
        );
    }
  };

  return renderScreen(viewScreenTempl);
};

export default UseTemplate;

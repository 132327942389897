import styled from 'styled-components';
import { PROFILE_STATUS_COLORS } from 'constants/profiles';

interface StatusColumnProps {
  status: string;
}

export const RecordHistoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 730px;
`;

export const StatusColumn = styled.div`
  color: ${(props: StatusColumnProps) => PROFILE_STATUS_COLORS[props.status]};
`;

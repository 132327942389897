import { useAppSelector } from 'app/hooks';
import ITVTable from 'components/ITVTable';
import { selectConfiguration } from 'features/configuration/configurationSlice';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { getHistoryMoveData } from './../../../../leaveOffAction';

const Review = () => {
  const { t } = useTranslation(['leaveOff']);
  const config = useAppSelector(selectConfiguration);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [sizePage, setSizePage] = useState(10);
  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(getHistoryMoveData());
  }, [dispatch]);

  useEffect(() => {
    setData(config.dataMoveHistory);
  }, [config.dataMoveHistory]);

  let COLUMNS = [
    {
      title: t('leaveOff:move_days.move_days_from_type'),
      dataIndex: 'typeChoose',
      key: 'typeChoose',
      width: 150,
      minWidth: 120,
      ellipsis: true,
    },
    {
      title: t('leaveOff:move_days.move_days_to_type'),
      dataIndex: 'typeMove',
      key: 'typeMove',
      width: 150,
      minWidth: 120,
      ellipsis: true,
    },
    {
      title: t('leaveOff:move_days.from_group'),
      dataIndex: 'groupChoose',
      key: 'groupChoose',
      width: 140,
      minWidth: 120,
      ellipsis: true,
    },
    {
      title: t('leaveOff:move_days.to_group'),
      dataIndex: 'groupMove',
      key: 'groupMove',
      width: 140,
      minWidth: 120,
      ellipsis: true,
    },
    {
      title: t('leaveOff:move_days.user_effect'),
      dataIndex: 'userEffect',
      key: 'userEffect',
      width: 140,
      minWidth: 120,
      ellipsis: true,
    },
    {
      title: t('leaveOff:move_days.user_assign'),
      dataIndex: 'userAssign',
      key: 'userAssign',
      width: 140,
      minWidth: 120,
      ellipsis: true,
    },
    {
      title: t('leaveOff:move_days.detail'),
      dataIndex: 'userAssign',
      key: 'userAssign',
      width: 140,
      minWidth: 120,
      ellipsis: true,
    },
    {
      title: t('leaveOff:move_days.review'),
      key: 'action',
      ellipsis: true,
      width: 160,
      minWidth: 120,
      render: function (record: any) {
        return (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {/* <Button>{t('Detail')}</Button> */}
          </div>
        );
      },
    },
  ];
  return (
    <div>
      <StyledHeader>{t('leaveOff:move_days.history')}</StyledHeader>
      <div>
        <ITVTable
          columns={COLUMNS}
          data={data}
          totalResult={data?.length}
          isRowSelect={false}
          height={300}
          limit={sizePage}
          setLimit={setSizePage}
          offset={currentPage}
          setOffset={setCurrentPage}
        />
      </div>
    </div>
  );
};

export default Review;

export const StyledHeader = styled.div`
  padding: 10px;
  font-size: 15px;
  font-weight: 500;
`;

import { StatusDefaultNode } from 'hrm-common/extensions/enums/personel';
export const getActionCurrentUser = (workflow: any, status: any, userId: any) => {
  if (!workflow) return [];
  //filter node chứa user và phải đang là node hiện hành trên flow
  const nodesIncludeUser = workflow?.nodes?.filter((item: any) =>
    item?.data?.employees?.some((i: any) => i?.id === userId && item?.data?.statusName === status),
  );
  //filter action của node
  const actionsOfUser = workflow?.edges?.filter((item: any) =>
    nodesIncludeUser?.some((i: any) => i?.id === item?.source),
  );
  return (
    actionsOfUser?.map((item: any) => ({ actionId: item?.actionId?.id, label: item?.label })) || []
  );
};

export const checkPermissionCurrentUser = (
  workflow: any,
  permission: any,
  status: any,
  userId: any,
) => {
  if (!workflow) return false;
  switch (permission) {
    case 'view':
      if (status === StatusDefaultNode.CLOSE) {
        return true;
      }
      return workflow?.nodes?.some((item: any) =>
        item?.data?.employees?.some((i: any) => i?.id === userId),
      );
    case 'edit':
      return workflow?.nodes?.some(
        (item: any) =>
          item?.data?.statusName === status &&
          item?.data?.employees?.some((i: any) => i?.id === userId && i?.permissionEdit),
      );
    case 'delete':
      return workflow?.nodes?.some(
        (item: any) =>
          item?.data?.statusName === status &&
          item?.data?.employees?.some((i: any) => i?.id === userId && i?.permissionDelete),
      );
    case 'create':
      return workflow?.nodes?.some(
        (item: any) =>
          item?.data?.statusName === status &&
          item?.data?.employees?.some((i: any) => i?.id === userId && i?.permissionCreate),
      );
    default:
      return false;
  }
};

import React from 'react';
import { useTranslation } from 'react-i18next';

import phoneBlue from 'assets/icons/phoneblue.svg';
import mailBlue from 'assets/icons/mailblue.svg';

import AuthLabel from 'features/auth/components/AuthLabel';
import { RowStyled, VideoStyled } from './styled';

const AuthVideoMobile = () => {
  const { t } = useTranslation(['layout']);
  return (
    <>
      <VideoStyled
        src="https://www.youtube.com/embed/HOu_n0KWXhM"
        title="How to use cloud"
        // @ts-ignore
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></VideoStyled>
      <AuthLabel label={t('layout:introduce.introduce_first')} />
      <AuthLabel label={t('layout:introduce.introduce_second')} />
      <AuthLabel label={t('layout:introduce.introduce_third')} />
      <RowStyled className="bettween">
        <AuthLabel label={t('layout:introduce.introduce_fourth')} />
        <AuthLabel icon={phoneBlue} label="+84(028)3999-1895" />
      </RowStyled>
      <RowStyled className="bettween">
        <AuthLabel label={t('layout:introduce.introduce_fifth')} />
        <AuthLabel icon={mailBlue} label="info@innotech-vn.com" />
      </RowStyled>
    </>
  );
};

export default AuthVideoMobile;

export interface Device {
  address: string;
  deviceID: string;
  deviceName: string;
  placeName: string;
  status: boolean;
}
export interface Place {
  id?: number;
  address: string;
  place: string;
  totalDevices: number;
  totalUsers: number;
}

export interface AppCameraAI {
  id?: number;
  name: string;
  description: string;
  clientId: string;
  clientSecret: string;
  accessToken: string;
}

export interface ListAddUserCamera {
  email: string;
  employeeId: string;
  name: string;
}

export interface ListUserCamera extends ListAddUserCamera {
  avatar: string;
}

export interface DataCheckInOutCamera {
  cameraType: string;
  checkin: string;
  checkout: string;
  date: string;
  name: string;
  place: string;
  result: Array<CheckInOutPerson>;
}

export interface CheckInOutPerson {
  avatar: string;
  checkinTime: string;
  date: string;
  deviceName: string;
  personID: string;
  personName: string;
  place: string;
}

export enum TypePerson {
  EMPLOYEE = 0,
  CLIENT = 1,
  STRANGER = 2,
}
export * from '../Interfaces/CameraAI';

import { Layout, Tabs } from 'antd';
import { useAppSelector, useWindowSize } from 'app/hooks';
import { selectConfiguration } from 'features/configuration/configurationSlice';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Container, HeaderTitle } from '../ManageWorkshift/styled';
import SetupDeduction from './components/SetupDeduction';
import SetupPIT from './components/SetupPIT';

export const KEY_PANE_PAYROLL = {
  SET_UP_DEDUCTION: 'set_up_deduction',
  SET_UP_PIT: 'set_up_pit',
};

function TaxManagement() {
  const { height } = useWindowSize();
  const heightComponent = height - 100;
  const { t } = useTranslation(['configuration', 'workshift']);
  const location: any = useLocation();
  const menuStore = useAppSelector(selectConfiguration);
  const dataScreenManage = menuStore?.dataScreenManage?.result;
  const [titleHeader, setTitleHeader] = useState('');

  useEffect(() => {
    const temp = dataScreenManage
      ?.filter((item: any) => location.pathname === item.path)
      ?.map((item: any) => item.nameScreen);

    setTitleHeader(temp ? temp[0] : '');
  }, [dataScreenManage, location.pathname]);

  const handleChangeTab = (key: any) => {};

  return (
    <Container style={{ minHeight: heightComponent }}>
      <HeaderTitle>{titleHeader}</HeaderTitle>
      <Layout.Content>
        <Tabs
          defaultActiveKey={KEY_PANE_PAYROLL.SET_UP_DEDUCTION}
          centered
          onChange={handleChangeTab}
          size="large"
        >
          <Tabs.TabPane
            tab={t('configuration:payroll.set_up_deduction')}
            key={KEY_PANE_PAYROLL.SET_UP_DEDUCTION}
          >
            <SetupDeduction />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={t('configuration:payroll.set_up_pit')}
            key={KEY_PANE_PAYROLL.SET_UP_PIT}
          >
            <SetupPIT />
          </Tabs.TabPane>
        </Tabs>
      </Layout.Content>
    </Container>
  );
}

export default TaxManagement;

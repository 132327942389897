import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  PERMISSION_PERSONNEL_RECORD_REQUEST_APPROVAL,
  PERMISSION_PERSONNEL_RECORD_APPROVAL,
} from 'constants/permissions';
import {
  PROFILE_DRAFT,
  PROFILE_PENDING,
  PROFILE_DENIED,
  PROFILE_INACTIVE,
} from 'constants/profiles';
import CustomButton from 'styles/buttonStyled';

interface FooterButtonsProps {
  profileStatus: string;
  permissions: Array<string>;
  handleSave: () => void;
  handleResetAll: () => void;
  handleSaveAndSendRequestApprove: () => void | null;
  handleSaveAndApproveProfile: () => void | null;
  handleSendRequestApprove: () => void | null;
  handleApproveProfile: () => void | null;
  handleRejectProfile: () => void | null;
  handleDeleteProfile: () => void | null;
}
const FooterButtons = (props: FooterButtonsProps) => {
  const {
    profileStatus,
    permissions,
    handleSave,
    handleResetAll,
    handleSaveAndSendRequestApprove,
    handleSaveAndApproveProfile,
    handleSendRequestApprove,
    handleApproveProfile,
    handleRejectProfile,
    handleDeleteProfile,
  } = props;

  const { t } = useTranslation(['action']);

  return (
    <>
      {profileStatus !== PROFILE_INACTIVE && (
        <CustomButton aType="primary" key="save-button" onClick={handleSave}>
          {t('action:save')}
        </CustomButton>
      )}
      {/* Modal add */}
      {profileStatus === 'IsAddEmployee' &&
        permissions.includes(PERMISSION_PERSONNEL_RECORD_REQUEST_APPROVAL) && (
          <CustomButton
            aType="primary"
            key="send-request-button"
            onClick={handleSaveAndSendRequestApprove}
          >
            {t('action:send_request')}
          </CustomButton>
        )}
      {profileStatus === 'IsAddEmployee' &&
        permissions.includes(PERMISSION_PERSONNEL_RECORD_APPROVAL) && (
          <CustomButton aType="primary" key="approve-button" onClick={handleSaveAndApproveProfile}>
            {t('action:approve')}
          </CustomButton>
        )}
      {/* ------------ */}
      {/* Modal update */}
      {(profileStatus === PROFILE_DRAFT || profileStatus === PROFILE_DENIED) &&
        permissions.includes(PERMISSION_PERSONNEL_RECORD_REQUEST_APPROVAL) && (
          <CustomButton
            aType="primary"
            key="send-request-button"
            onClick={handleSendRequestApprove}
          >
            {t('action:send_request')}
          </CustomButton>
        )}
      {(profileStatus === PROFILE_DRAFT ||
        profileStatus === PROFILE_PENDING ||
        profileStatus === PROFILE_DENIED) &&
        permissions.includes(PERMISSION_PERSONNEL_RECORD_APPROVAL) && (
          <CustomButton aType="primary" key="approve-button" onClick={handleApproveProfile}>
            {t('action:approve')}
          </CustomButton>
        )}
      {(profileStatus === PROFILE_DRAFT || profileStatus === PROFILE_PENDING) &&
        permissions.includes(PERMISSION_PERSONNEL_RECORD_APPROVAL) && (
          <CustomButton onClick={handleRejectProfile} aType="primary" key="reject-button">
            {t('action:reject')}
          </CustomButton>
        )}
      {profileStatus === PROFILE_DRAFT &&
        (permissions.includes(PERMISSION_PERSONNEL_RECORD_APPROVAL) ||
          permissions.includes(PERMISSION_PERSONNEL_RECORD_REQUEST_APPROVAL)) && (
          <CustomButton onClick={handleDeleteProfile} aType="primary" key="delete-button">
            {t('action:delete')}
          </CustomButton>
        )}
      {/* ------------ */}
      <CustomButton aType="outline" key="reset-button" onClick={handleResetAll}>
        {t('action:reset_all')}
      </CustomButton>
    </>
  );
};

export default FooterButtons;

import { IconProps } from 'types';

export const DownTrendIcon = ({ width = '12px', height = '12px', className }: IconProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.16699 2.5L4.74887 6.25199C5.17637 6.86799 5.93574 6.91 6.39699 6.329L6.93137 5.66399C7.39262 5.08999 8.15199 5.125 8.57949 5.741L11.167 9.5M11.167 9.5V7.34615M11.167 9.5H9.36699"
      stroke="#F04438"
      strokeWidth="1.2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';
import { persistor, store } from './app/store';
import { Provider } from 'react-redux';
import { Configuration, PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import * as serviceWorker from './serviceWorker';
import { msalConfig } from 'constants/msAuthConfig';
import { PersistGate } from 'redux-persist/es/integration/react';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from 'components/ErrorFallback';
import { loginWithMicrosoft } from './features/auth/loginAction';
import './index.scss';

declare global {
  interface Window {
    OneSignal: any;
  }
}

const msalInstance = new PublicClientApplication(msalConfig as Configuration);

const handleResponse = (response: any) => {
  if (response !== null) {
    const email = response.account ? response.account.username : '';
    const accessToken = response.accessToken;
    store.dispatch(loginWithMicrosoft({ email, accessToken }));
  }
};

msalInstance
  .handleRedirectPromise()
  .then(handleResponse)
  .catch((error) => {
    console.log(error);
  });

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Suspense fallback={null}>
          <MsalProvider instance={msalInstance}>
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onReset={() => {
                window.location.reload();
              }}
            >
              <App />
            </ErrorBoundary>
          </MsalProvider>
        </Suspense>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

serviceWorker.unregister();

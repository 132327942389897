export const TYPE_HEADER = {
  Reminder: 'reminder',
  Notification: 'noti',
};

export const DISPLAY_HEADER = {
  Personnel: 'Personnel',
  Reminder: 'Reminder',
};

export const Notification = {
  Type: {
    Error: 'error',
    Success: 'success',
    Warning: 'warning',
    Info: 'info',
  },
  Duration: {
    _1s: 1,
    _2s: 2,
    _3s: 3,
    _4s: 4,
    _5s: 5,
    _10s: 10,
  },
};

import { EyeOutlined } from '@ant-design/icons';
import { useAppSelector } from 'app/hooks';
import EmployeeColumn from 'components/EmployeeColumn';
import ITVTable from 'components/ITVTable';
import MenuAction from 'components/MenuAction';
import PageHeader from 'components/PageHeader';
import { selectConfiguration } from 'features/configuration/configurationSlice';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { getManagerView } from '../performanceAction';
import { getUser } from 'utils/auth';
import { selectPerformanceData } from '../performanceSlice';
import ITVSearch from 'components/ITVSearch';
import { useTranslation } from 'react-i18next';

export default function ManagerViewPage() {
  const { t } = useTranslation(['performance']);
  const dispatch = useDispatch();
  const location: any = useLocation();
  const history = useHistory();
  const menuStore = useAppSelector(selectConfiguration);
  const performanceStore = useAppSelector(selectPerformanceData);
  const managerViewData = performanceStore?.managerViewData;
  const dataScreenManage = menuStore?.dataScreenManage?.result;
  const [titleHeader, setTitleHeader] = useState('');
  const [sizePage, setSizePage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');

  useEffect(() => {
    const params = {
      page: currentPage,
      limit: sizePage,
      managerId: getUser()?.employeeId,
      searchText: search,
    };
    dispatch(getManagerView(params));
  }, [dispatch, sizePage, currentPage, search]);

  useEffect(() => {
    const temp = dataScreenManage
      ?.filter((item: any) => location.pathname === item.path)
      ?.map((item: any) => item.nameScreen);

    setTitleHeader(temp ? temp[0] : '');
  }, [dataScreenManage, location.pathname]);

  const menu = [
    {
      name: t('performance:performance_review.view_detail'),
      icon: <EyeOutlined style={{ color: '#1890FF' }} />,
      handleClick: (value: any) => {
        history.push({
          pathname: `/performance/employee-pr-management/${value?.id}`,
          state: value,
        });
        // Handle the detail view action here
      },
    },
  ];

  const columns = [
    {
      title: '#',
      key: 'index',
      dataIndex: 'index',
      width: 80,
      minWidth: 80,
      align: 'center',
      render: (_: any, __: any, index: any) => {
        const trueIndex = index + sizePage * (currentPage - 1);
        return <div>{trueIndex + 1}</div>;
      },
    },
    {
      title: t('performance:pending_review.employee_name'),
      key: 'fullName',
      dataIndex: 'fullName',
      width: 100,
      minWidth: 100,
      render: (_: any, record: any) => {
        return (
          <EmployeeColumn
            avatar={
              record?.full_name
                ? `${record?.full_name}`
                : `${record?.lastName} ${record?.firstName}`
            }
            fullName={
              record?.full_name
                ? `${record?.full_name}`
                : `${record?.lastName} ${record?.firstName}`
            }
            email={record?.email}
          />
        );
      },
    },
    {
      title: t('performance:pending_review.employee_id'),
      key: 'employeeId',
      dataIndex: 'employeeId',
      width: 80,
      minWidth: 80,
      align: 'center',
      render: (_: any, record: any) => record?.employee_id,
    },
    // {
    //   title: 'Overal Score',
    //   key: 'total_rating',
    //   dataIndex: 'total_rating',
    //   width: 100,
    //   minWidth: 100,
    //   align: 'center',
    //   render: (text: any) => text || '',
    // },
    {
      title: t('performance:pending_review.job_title'),
      key: 'job_title',
      dataIndex: 'job_title',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (text: any) => text || '',
    },
    {
      title: t('performance:pending_review.direct_manager'),
      key: 'directManager',
      dataIndex: 'directManager',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (text: any) => text || '',
    },
    {
      title: t('performance:pending_review.current_level'),
      key: 'level',
      dataIndex: 'level',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (text: any) => text || '',
    },
    {
      title: t('performance:pending_review.action'),
      key: 'action',
      dataIndex: 'action',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (text: any, record: any) => <MenuAction menu={menu} data={record} />,
    },
  ];

  const handleSearch = (text: string) => {
    setSearch(text);
  };

  return (
    <>
      <PageHeader title={titleHeader} />
      <ITVSearch style={{ marginBottom: '1rem' }} handleSearch={handleSearch} />
      <ITVTable
        data={managerViewData?.result || []}
        columns={columns}
        isRowSelect={false}
        setOffset={setCurrentPage}
        offset={currentPage}
        setLimit={setSizePage}
        limit={sizePage}
        totalResult={managerViewData?.totalResult || undefined}
      />
    </>
  );
}

import styled from 'styled-components';

export const ImageStyled = styled.img`
  width: 1.5rem;
  height: 1.5rem;
`;

export const FooterTopStyled = styled.div`
  display: flex;
  margin: 0px 60px;
  //padding:1% 0px;
  height: 85%;
  padding-top: 10px;
`;
export const FooterButtomStyled = styled.div`
  display: flex;
  height: 16%;
  background: #0079e9;
  align-items: center;
`;
export const FooterTitleStyled = styled.div`
  font-size: 20px;
  color: #ffffff;
  padding-bottom: 10px;
`;
export const FooterLiStyled = styled.div`
  padding-bottom: 2px;
  font-size: 14px;
  color: #ffffff;
`;

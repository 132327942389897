import moment, { Moment } from 'moment-timezone';
import {
  getLocaleDateString,
  getLocaleDateTimeString,
  getLocaleMonthString,
  getLocaleDateMonthString,
} from 'utils/function';
import { getUser } from 'utils/auth';
import { HOUR_FORMAT, TIMEZONE_DEFAULT } from 'constants/commons';

export enum DayOfWeek {
  'Sun',
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat',
}

export const getStartDateOfCurrentMonth = (date?: string) =>
  moment(date ? date : new Date(), 'YYYY-MM-DD')
    .clone()
    .startOf('month')
    .format('YYYY-MM-DD');

export const getEndDateOfCurrentMonth = (date?: string) =>
  moment(date ? date : new Date(), 'YYYY-MM-DD')
    .clone()
    .endOf('month')
    .format('YYYY-MM-DD');

export const getYesterday = () => moment(new Date()).add(-1, 'days').format('YYYY-MM-DD');

export const getStartDayOf6MonthAgo = () =>
  moment(new Date()).add(-7, 'months').clone().startOf('month').format('YYYY-MM-DD');

export const getStartDateOfPreviousMonth = (date?: string) =>
  moment(date ? date : new Date(), 'YYYY-MM-DD')
    .clone()
    .subtract(1, 'month')
    .startOf('month')
    .format('YYYY-MM-DD');

export const getEndDateOfPreviousMonth = (date?: string) =>
  moment(date ? date : new Date(), 'YYYY-MM-DD')
    .clone()
    .subtract(1, 'month')
    .endOf('month')
    .format('YYYY-MM-DD');

export const formatHour = (
  date: any = null,
  format: any = null,
  isUseDefaultNow: boolean = false,
  isUseDefaultEmpty: boolean = false,
) => {
  const formatString = format ? format : HOUR_FORMAT;
  const timeZone = getUser().timeZone ? getUser().timeZone : TIMEZONE_DEFAULT;
  if (date) {
    return moment(date).tz(timeZone).format(formatString);
  } else {
    if (isUseDefaultNow) {
      return moment().tz(timeZone).format(formatString);
    } else if (isUseDefaultEmpty) {
      return '';
    } else {
      return moment('0001-01-01 00:00:00.000').format(formatString);
    }
  }
};

export const formatMonth = (
  date: any = null,
  format: any = null,
  isUseDefaultNow: boolean = false,
  isUseDefaultEmpty: boolean = false,
) => {
  const formatString = format ? format : getLocaleMonthString();
  const timeZone = getUser().timeZone ? getUser().timeZone : TIMEZONE_DEFAULT;
  if (date) {
    return moment(date).tz(timeZone).format(formatString);
  } else {
    if (isUseDefaultNow) {
      return moment().tz(timeZone).format(formatString);
    } else if (isUseDefaultEmpty) {
      return '';
    } else {
      return moment('0001-01-01 00:00:00.000').format(formatString);
    }
  }
};

export const transMomentTimezone = (date?: string): Moment => {
  const timeZone = getUser()?.timeZone ? getUser().timeZone : TIMEZONE_DEFAULT;

  if (date) return moment(date).tz(timeZone);

  return moment().tz(timeZone);
};

export const formatDate = (
  date: any = null,
  format: any = null,
  isUseDefaultNow: boolean = false,
  isUseDefaultEmpty: boolean = false,
) => {
  const formatString = format ? format : getLocaleDateString();
  const timeZone = getUser()?.timeZone ? getUser()?.timeZone : TIMEZONE_DEFAULT;
  if (date) {
    return moment(date).tz(timeZone).format(formatString);
  } else {
    if (isUseDefaultNow) {
      return moment().tz(timeZone).format(formatString);
    } else if (isUseDefaultEmpty) {
      return '';
    } else {
      return moment('0001-01-01 00:00:00.000').format(formatString);
    }
  }
};

export const formatDateTime = (
  date: any = null,
  format: any = null,
  isUseDefaultNow: boolean = false,
  isUseDefaultEmpty: boolean = false,
) => {
  const formatString = format ? format : getLocaleDateTimeString();
  const timeZone = getUser()?.timeZone ? getUser()?.timeZone : TIMEZONE_DEFAULT;
  if (date) {
    return moment(date).tz(timeZone).format(formatString);
  } else {
    if (isUseDefaultNow) {
      return moment().tz(timeZone).format(formatString);
    } else if (isUseDefaultEmpty) {
      return '';
    } else {
      return moment('0001-01-01 00:00:00.000').format(formatString);
    }
  }
};

export const formatDateMonth = (
  date: any = null,
  format: any = null,
  isUseDefaultNow: boolean = false,
  isUseDefaultEmpty: boolean = false,
) => {
  const formatString = format ? format : getLocaleDateMonthString();
  const timeZone = getUser()?.timeZone ? getUser()?.timeZone : TIMEZONE_DEFAULT;
  if (date) {
    return moment(date).tz(timeZone).format(formatString);
  } else {
    if (isUseDefaultNow) {
      return moment().tz(timeZone).format(formatString);
    } else if (isUseDefaultEmpty) {
      return '';
    } else {
      return moment('0001-01-01 00:00:00.000').format(formatString);
    }
  }
};

export const calculateDuration = (
  startTime: Date | moment.Moment,
  endTime: Date | moment.Moment,
  freeHours = 0,
  fixed = false,
  timezone = TIMEZONE_DEFAULT,
) => {
  let start;
  let end;
  if (moment.isMoment(startTime) && moment.isMoment(endTime)) {
    start = startTime;
    end = endTime;
  } else {
    start = moment['tz'](startTime, timezone);
    end = moment['tz'](endTime, timezone);
  }

  const actualMinuteStart = start.minutes();
  const minutesEnd = end.minutes() + end.hours() * 60;
  const minutesStart = actualMinuteStart + start.hours() * 60;
  const hoursWorking = (minutesEnd - minutesStart) / 60 - freeHours;
  if (fixed) {
    return parseFloat(hoursWorking.toFixed(2));
  }
  return hoursWorking;
};

export const calculateTotalHours = (
  checkInTime: string,
  checkOutTime: string,
  timeZone = TIMEZONE_DEFAULT,
  startBreakHour = 12,
  endBreakHour = 13,
) => {
  const breakHour = endBreakHour - startBreakHour;

  if (breakHour < 0) {
    throw new Error('Start Time must be greater than End Time');
  }

  if (checkInTime && checkOutTime) {
    const checkIn = moment['tz'](checkInTime, timeZone);
    const checkOut = moment['tz'](checkOutTime, timeZone);

    const checkInHours = checkIn.hours();
    const checkOutHours = checkOut.hours();
    const checkInMinutes = checkIn.minutes();
    const checkOutMinutes = checkOut.minutes();

    // IN: before 12:00
    // OUT: after 13:00
    // ---> minus 1 hour
    const normalCase = checkInHours < startBreakHour && checkOutHours >= endBreakHour;
    if (normalCase) {
      return calculateDuration(checkIn, checkOut, breakHour);
    }

    // IN: before 12:00
    // OUT: before 12:00
    const checkInAndOutBeforeBreakHourCase =
      checkInHours < startBreakHour && checkOutHours < startBreakHour;

    // IN: before 12:00
    // OUT: === 12:00
    const checkOutEqualsBreakHourCase =
      checkInHours < startBreakHour && checkOutHours === startBreakHour && checkOutMinutes === 0;

    // IN: before 13:00
    // OUT: before 13:00
    const checkInAndOutAfterBreakHourCase =
      checkInHours >= endBreakHour && checkOutHours >= endBreakHour;

    // ---> minus 0 hour
    if (
      checkInAndOutBeforeBreakHourCase ||
      checkInAndOutAfterBreakHourCase ||
      checkOutEqualsBreakHourCase
    ) {
      return calculateDuration(checkIn, checkOut);
    }

    // IN: before 12:00
    // OUT: on 12:00 - 13:00
    // ---> minus [~~~~] hour
    const checkOutOnBreakTimeCase =
      checkInHours < startBreakHour && checkOutHours === startBreakHour;
    if (checkOutOnBreakTimeCase) {
      const mustBreakTime = checkOutMinutes / 60;

      return calculateDuration(checkIn, checkOut, mustBreakTime);
    }

    // IN: on 12:00 - 13:00
    // OUT: after 13:00
    // ---> minus [~~~~] hour
    const checkInOnBreakTimeCase = checkInHours === startBreakHour && checkOutHours >= endBreakHour;
    if (checkInOnBreakTimeCase) {
      const mustBreakTime = (60 - checkInMinutes) / 60;

      return calculateDuration(checkIn, checkOut, mustBreakTime);
    }
  }

  return 0;
};

export const checkIsFirstOrLastOfTime = (
  checkType: 'firstOfTime' | 'lastOfTime',
  hour = -1,
  minute = -1,
): boolean => {
  const caseTime = {
    hourMinute: (checkType: 'firstOfTime' | 'lastOfTime', hour: number, minute: number) => {
      if (checkType === 'firstOfTime') return hour === 0 && minute === 0;
      if (checkType === 'lastOfTime') return hour === 23 && minute === 59;
      throw Error('Not this check type! Please add case in function checkIsFirstOrLastOfTime()!');
    },
    hour: (checkType: 'firstOfTime' | 'lastOfTime', hour: number) => {
      if (checkType === 'firstOfTime') return hour === 0;
      if (checkType === 'lastOfTime') return hour === 23;
      throw Error('Not this check type! Please add case in function checkIsFirstOrLastOfTime()!');
    },
    minute: (checkType: 'firstOfTime' | 'lastOfTime', minute: number) => {
      if (checkType === 'firstOfTime') return minute === 0;
      if (checkType === 'lastOfTime') return minute === 59;
      throw Error('Not this check type! Please add case in function checkIsFirstOrLastOfTime()!');
    },
  };

  if (hour > -1 && minute > -1) return caseTime['hourMinute'](checkType, hour, minute);

  if (hour > -1) return caseTime['hour'](checkType, hour);

  if (minute > -1) return caseTime['minute'](checkType, minute);

  throw Error('Not this time! Please add case time in function checkIsFirstOrLastOfTime()!');
};

export const numberDayExcludedBetweenRange = (
  startDate: Moment,
  endDate: Moment,
  excludedDays: Array<DayOfWeek>,
): number => {
  const numberStartDate = startDate.date();
  const numberEndDate = endDate.date();
  let resultNumberDayBetweenRange = 0;

  for (let i = numberStartDate; i <= numberEndDate; i++) {
    const dayInWeek = moment().set('date', i).day();
    if (excludedDays.includes(dayInWeek)) {
      resultNumberDayBetweenRange += 1;
    }
  }

  return resultNumberDayBetweenRange;
};

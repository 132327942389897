import { Popconfirm, Steps } from 'antd';
import { useAppSelector } from 'app/hooks';
import ButtonBack from 'components/ButtonBack';
import { notificationToast } from 'components/notificationToast';
import { Notification } from 'constants/notification';
import { selectConfiguration } from 'features/configuration/configurationSlice';
import { previewMoveDays, submitMoveDays } from 'features/configuration/leaveOffAction';
import { setCheckRow } from 'features/employee/employeeSlice';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import CustomButton from 'styles/buttonStyled';
import ConfirmPreview from '../ConfirmPreview';
import Preview from '../Preview';
import FormMoveDays from './component/FormMoveDays/FormMoveDays';
import Review from './component/Review/Review';

const { Step } = Steps;

const MoveDaysLeaveOff = () => {
  const { t } = useTranslation(['leaveOff']);
  const dispatch = useDispatch();
  const config = useAppSelector(selectConfiguration);
  const payloadMoveDaysSelect = config.payloadMoveDaysSelect;
  const userIds = config.userIds;
  const [current, setCurrent] = useState(0);
  const [show, setShow] = useState(false);
  const [payload, setPayload] = useState<any>({});
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showPop, setShowPop] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (current === 0) dispatch(setCheckRow([]));
  }, [current, dispatch]);

  useEffect(() => {
    if (userIds?.length) {
      setPayload({ ...payloadMoveDaysSelect, userIds: userIds });
    } else setPayload(payloadMoveDaysSelect);
  }, [payloadMoveDaysSelect, userIds]);

  const handleSubmit = () => {
    if (payload && payload?.chooseId && payload?.moveId && userIds) {
      payload['chooseId'] = parseInt(payload?.chooseId);
      payload['moveId'] = parseInt(payload?.moveId);
      dispatch(submitMoveDays({ saveSuccess, ...payload }));
    }
  };

  const saveSuccess = (response: any) => {
    response &&
      notificationToast(
        Notification.Type.Success,
        t('leaveOff:preview.move_success'),
        Notification.Duration._3s,
      );
    setCurrent(0);
  };

  const steps = [
    {
      title: t('leaveOff:preview.step1'),
      description: t('leaveOff:preview.select_move_type'),
      content: (
        <>
          <FormMoveDays setShow={setShow} setShowError={setShowError} />
        </>
      ),
    },
    {
      title: t('leaveOff:preview.step2'),
      description: t('leaveOff:preview.preview_change'),
      content: <Preview setShow={setShow} />,
    },
    {
      title: t('leaveOff:preview.step3'),
      description: t('leaveOff:preview.preview_save'),
      content: <ConfirmPreview />,
    },
  ];

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const handleReview = () => {
    if (payload && payload?.chooseId && payload?.moveId) {
      dispatch(previewMoveDays({ checkDaysLeaveOffUser, ...payload }));
    }
  };
  const checkDaysLeaveOffUser = (response: any) => {
    if (response) {
      if (!response?.data) {
        setShowError(true);
        setErrorMessage(response?.message);
      } else {
        setShowError(false);
        return next();
      }
    }
  };

  const handleStep = () => {
    switch (current) {
      case 0:
        return handleReview();
      default:
        return next();
    }
  };
  return (
    <PageContainer>
      <ButtonBack handleBack={() => history.goBack()} />
      <FormHeader>
        <CustomHeader>{t('leaveOff:move_days.move_days')}</CustomHeader>
      </FormHeader>
      <StepsWrapper>
        <CustomSteps current={current}>
          {steps.map((item) => (
            <Step key={item.title} title={item.title} description={item.description} />
          ))}
        </CustomSteps>
        <div className="steps-content">{steps[current].content}</div>
        {showError && (
          <p style={{ color: 'red', display: 'flex', justifyContent: 'center' }}>{errorMessage}</p>
        )}
        <div className="steps-action" style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {current > 0 && (
            <CustomButton
              style={{ margin: '0 8px' }}
              onClick={() => {
                prev();
              }}
            >
              {t('leaveOff:preview.previous')}
            </CustomButton>
          )}
          {current < steps.length - 1 && (
            <CustomButton type="primary" disabled={show} onClick={() => handleStep()}>
              {t('leaveOff:preview.next')}
            </CustomButton>
          )}
          {current === steps.length - 1 && (
            <Popconfirm
              visible={showPop}
              title={t('leaveOff:preview.are_you_sure')}
              onConfirm={handleSubmit}
              onCancel={() => setShowPop(false)}
              okText={t('leaveOff:item.allow_add_yes')}
              cancelText={t('leaveOff:item.allow_add_no')}
              placement="topRight"
            >
              <CustomButton
                type="primary"
                onClick={() => {
                  setShowPop(true);
                }}
              >
                {t('leaveOff:item.btn_save')}
              </CustomButton>
            </Popconfirm>
          )}
        </div>
      </StepsWrapper>
      {!current && (
        <StyleReviewContainer>
          <Review />
        </StyleReviewContainer>
      )}
    </PageContainer>
  );
};

export default MoveDaysLeaveOff;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 10px;
`;

export const FormHeader = styled.div`
  background-color: #f1592a;
  height: 40px;
  padding: 3px;
`;

export const StepsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  padding: 20px;
`;

export const CustomSteps = styled(Steps)`
  padding: 0 15%;
  margin-bottom: 30px;
  padding: 0px 15%;
  .ant-steps-item-process .ant-steps-item-icon {
    background-color: #f1592a;
    border-color: #f1592a;
  }
  .ant-steps-item-finish {
    .ant-steps-item-icon {
      border-color: #f1592a;
    }
  }
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background-color: #f1592a;
  }
  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: #f1592a;
  }
`;

const StyleReviewContainer = styled.div`
  background-color: #fff;
  padding: 15px;
  margin-top: 30px;
`;

const CustomHeader = styled.p`
  color: white;
  margin: 0px 0px 0px 25px;
  font-size: 22px;
  font-weight: 600;
`;

import moment from 'moment';
import { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import ITVTable from 'components/ITVTable';
import PayrollInDetailFilters from './PayrollInDetailFilters';
import payrollDashboardApi from 'api/payrollDashboardApi';
import { formatNumber } from 'utils/function';
import { formatCurrency } from 'features/insurances/constants/common';

const StyledContainer = styled.div`
  padding: 16px 24px;
  background-color: var(--cl_white);
  border-radius: 12px;
  border: 0.5px solid var(--cl_gray200);
  height: 100%;
`;

const StyledHeaderTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto;
  line-height: 20px;
  color: #121926;
`;

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export function PayrollInDetail({ location }: any) {
  const { t } = useTranslation(['dashboard']);

  const [isLoading, setIsLoading] = useState(false);
  const [filterMonthYear, setFilterMonthYear] = useState({
    monthYear: moment(new Date()).clone().subtract(1, 'months').format('YYYY-MM'),
  });
  const [filterLocation, setFilterLocation] = useState(0);

  const [sizePage, setSizePage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPayrollInDetail, setDataPayrollInDetail] = useState<any>([]);

  const handleSubmit = ({ value, from }: any) => {
    if (from === 'monthYear') {
      const formattedMonthYear = moment(value).format('YYYY-MM');
      setFilterMonthYear({
        monthYear: formattedMonthYear,
      });
    } else {
      setFilterLocation(value?.viewByLocation);
    }
  };

  let COLUMNS = [
    {
      title: 'Store',
      width: 150,
      minWidth: 150,
      dataIndex: 'storeName',
      key: 'storeName',
      align: 'left',
      render: (text: any, record: any, index: any) => {
        return text;
      },
    },
    {
      title: 'Number of Employees',
      width: 150,
      minWidth: 150,
      dataIndex: 'employeeCount',
      key: 'employeeCount',
      align: 'center',
      render: (text: any, record: any, index: any) => {
        return text && formatCurrency(text);
      },
    },
    {
      title: 'Total Gross Salary',
      width: 150,
      minWidth: 150,
      dataIndex: 'totalGrossSalary',
      key: 'totalGrossSalary',
      align: 'right',
      render: (text: any, record: any, index: any) => {
        return text && formatCurrency(text);
      },
    },
    {
      title: 'Main Salary',
      width: 150,
      minWidth: 150,
      dataIndex: 'mainSalary',
      key: 'mainSalary',
      align: 'right',
      render: (text: any, record: any, index: any) => {
        return text && formatCurrency(text);
      },
    },
    {
      title: 'Allowances',
      width: 150,
      minWidth: 150,
      dataIndex: 'allowance',
      key: 'allowance',
      align: 'right',
      render: (text: any, record: any, index: any) => {
        return text && formatCurrency(text);
      },
    },
    {
      title: 'Onsite Fee',
      width: 150,
      minWidth: 150,
      dataIndex: 'onsiteFee',
      key: 'onsiteFee',
      align: 'right',
      render: (text: any, record: any, index: any) => {
        return text && formatCurrency(text);
      },
    },
    {
      title: 'Bonus',
      width: 150,
      minWidth: 150,
      dataIndex: 'bonus',
      key: 'bonus',
      align: 'right',
      render: (text: any, record: any, index: any) => {
        return text && formatCurrency(text);
      },
    },
    {
      title: 'Adjustment',
      width: 150,
      minWidth: 150,
      dataIndex: 'adjustment',
      key: 'adjustment',
      align: 'right',
      render: (text: any, record: any, index: any) => {
        return text && formatCurrency(text);
      },
    },
    {
      title: 'Overtime',
      width: 150,
      minWidth: 150,
      dataIndex: 'overtime',
      key: 'overtime',
      align: 'right',
      render: (text: any, record: any, index: any) => {
        return text && formatCurrency(text);
      },
    },
    {
      title: 'Total Compensation',
      width: 150,
      minWidth: 150,
      dataIndex: 'totalCompensation',
      key: 'totalCompensation',
      align: 'right',
      render: (text: any, record: any, index: any) => {
        return text && formatCurrency(text);
      },
    },
    {
      title: 'Personal Income Tax',
      width: 150,
      minWidth: 150,
      dataIndex: 'personIncomeTax',
      key: 'personIncomeTax',
      align: 'right',
      render: (text: any, record: any, index: any) => {
        return text && formatCurrency(text);
      },
    },
    {
      title: 'Employee Contribution(Free PTI)',
      width: 150,
      minWidth: 150,
      dataIndex: 'employeeContribute',
      key: 'employeeContribute',
      align: 'right',
      render: (text: any, record: any, index: any) => {
        return text && formatCurrency(text);
      },
    },
    {
      title: 'Employee Contribution(Insurance)',
      width: 150,
      minWidth: 150,
      dataIndex: 'empoloyerContribute',
      key: 'empoloyerContribute',
      align: 'right',
      render: (text: any, record: any, index: any) => {
        return text && formatCurrency(text);
      },
    },
    {
      title: 'Addition/Deduction',
      width: 150,
      minWidth: 150,
      dataIndex: 'additDeduct',
      key: 'additDeduct',
      align: 'right',
      render: (text: any, record: any, index: any) => {
        return text && formatCurrency(text);
      },
    },
    {
      title: 'Actual Take Home',
      width: 150,
      minWidth: 150,
      dataIndex: 'actualTakeHome',
      key: 'actualTakeHome',
      align: 'right',
      render: (text: any, record: any, index: any) => {
        return text && formatCurrency(text);
      },
    },
    {
      title: 'Total Cost',
      width: 150,
      minWidth: 150,
      dataIndex: 'totalCost',
      key: 'totalCost',
      align: 'right',
      render: (text: any, record: any, index: any) => {
        return text && formatCurrency(text);
      },
    },
  ];

  useEffect(() => {
    const fetchDataPayrollInDetail = async () => {
      setIsLoading(true);
      const params = {
        period: filterMonthYear.monthYear,
        locationId: filterLocation,
      };
      const response = location && (await payrollDashboardApi.getPayrollInDetail(params));
      setDataPayrollInDetail(response?.data);
      setIsLoading(false);
    };
    fetchDataPayrollInDetail();
  }, [filterMonthYear, filterLocation]);

  return (
    <StyledContainer>
      <StyledWrapper>
        <StyledHeaderTitle>Payroll In Detail</StyledHeaderTitle>
        <PayrollInDetailFilters
          initialValues={{
            monthYear: moment(new Date(filterMonthYear.monthYear)),
            viewByLocation: filterLocation,
          }}
          loading={isLoading}
          onSubmit={handleSubmit}
          location={location}
        />
      </StyledWrapper>

      <ITVTable
        // loading={loading}
        columns={COLUMNS}
        data={dataPayrollInDetail}
        isRowSelect={false}
        setOffset={setCurrentPage}
        offset={currentPage}
        setLimit={setSizePage}
        limit={sizePage}
        height={400}
        scroll={{ x: 100, y: 100 }}
        loading={isLoading}
      />
    </StyledContainer>
  );
}

import { Switch, useRouteMatch } from 'react-router-dom';

import { PermissionType } from 'hrm-common/extensions/enums/personel';
import { PrivateRoute } from 'routes/PrivateRoute';
import { TimesheetReportPage } from './pages';

export default function TimesheetReport() {
  const match = useRouteMatch();

  return (
    <Switch>
      <PrivateRoute path={match.path} exact permissions={[PermissionType.TIMESHEET_REPORT]}>
        <TimesheetReportPage />
      </PrivateRoute>
    </Switch>
  );
}

import { useAppDispatch, useAppSelector } from 'app/hooks';
import ITVSearch from 'components/ITVSearch';
import ITVTable from 'components/ITVTable';
import { TIMEZONE_DEFAULT } from 'constants/commons';
import { selectDepartment } from 'features/department/departmentSlice';
import EmployeeColumn from 'features/employee/components/EmployeeColumn';
import { selectEmployee } from 'features/employee/employeeSlice';
import { getColumnSearchProps } from 'features/employee/pages/EmployeeLists';
import ChooseLeaveDayList from 'features/leaveOff/components/ChooseLeaveDayList';
import { ButtonBack } from 'features/leaveOff/components/ChooseLeaveDayListStyles';
import { getListManageExclude } from 'features/leaveOff/leaveOffAction';
import { selectLeaveOff } from 'features/leaveOff/leaveOffSlice';
import { DEFAULT_CURRENT_PAGE } from 'hrm-common/extensions/constant/personel';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ITVButton from 'styles/buttonStyled';
import { getUser } from 'utils/auth';
import { getEndDateOfCurrentMonth, getStartDateOfCurrentMonth } from 'utils/date';

const AssignList = ({
  handleAutoAddLeaveDays,
  handleManualAddLeaveDays,
  submitText,
  selectionType = 'checkbox',
  rowKey,
  startDate,
  endDate,
  type,
  leaveDaysImmediately,
  stepText,
  backText,
}: any) => {
  const dispatch = useAppDispatch();
  const employee = useAppSelector(selectLeaveOff);
  const employeeList = employee.listStaffAssign;
  const loading = employee.loadingStaff;
  const timeZone = getUser()?.timeZone ? getUser()?.timeZone : TIMEZONE_DEFAULT;
  //Set default timezone in server
  moment.tz.setDefault(timeZone);
  const { t } = useTranslation(['timesheet', 'leaveOff']);
  let [resultTotal, setResultTotal] = useState(0);
  const widthTable = 978;
  const [selectedUser, setSelectedUser] = useState<string[]>([]);
  const [data, setData] = useState<any>([]);
  const [searchText, setSearchText] = useState('');
  const selectRowKey = useAppSelector(selectEmployee).selectRowKey;
  const isCheckAll: boolean = useAppSelector(selectEmployee).isCheckAll;
  let year = moment().year(),
    month = moment().month();
  const [selectUsersAssigned, setSelectUsersAssigned] = useState<
    { data: any; dateAddLeaveDays: Moment | null }[]
  >([]);
  const departments = useAppSelector(selectDepartment);
  const departmentsArr = departments.data.map((item: any) => item.name);
  const [sizePage, setSizePage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const monthYearSelect = localStorage.getItem('monthYearSelect');
  const monthYearValue = monthYearSelect?.split('-');

  if (monthYearSelect && monthYearValue) {
    month = Number(monthYearValue[1]);
    year = Number(monthYearValue[0]);
    startDate = getStartDateOfCurrentMonth(`${year}-${month}-01`);
    endDate = getEndDateOfCurrentMonth(`${year}-${month}-01`);
  } else {
    startDate = getStartDateOfCurrentMonth(`${year}-${month}-01`);
    endDate = getEndDateOfCurrentMonth(`${year}-${month}-01`);
  }
  const param = {
    sort: [],
    filter: {
      status: ['approved'],
      departmentName: [],
    },
    search: { name: '' },
    fromDate: startDate,
    toDate: endDate,
    leaveTypeId: type,
    status: 'false',
    limit: sizePage,
    offset: 1,
  };

  const [postParam, setPostParam] = useState(param);

  useEffect(() => {
    const getParamWithoutSortFilterSearch = {
      ...param,
    };
    dispatch(getListManageExclude(getParamWithoutSortFilterSearch));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isCheckAll) {
      const postParamFilterSort = {
        ...postParam,
        search: { name: searchText },
        limit: sizePage,
        offset: currentPage,
      };
      //@ts-ignore
      delete postParamFilterSort.limit;
      //@ts-ignore
      delete postParamFilterSort.offset;
      dispatch(getListManageExclude(postParamFilterSort));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCheckAll]);

  useEffect(() => {
    setSelectedUser(selectRowKey);
  }, [selectRowKey]);

  useEffect(() => {
    if (!isEqual(param, postParam)) {
      const postParamFilterSort = {
        ...postParam,
        search: { name: searchText },
        limit: sizePage,
        offset: currentPage,
      };
      dispatch(getListManageExclude(postParamFilterSort));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postParam, sizePage, currentPage]);

  useEffect(() => {
    if (employeeList) {
      setData(employeeList);
      setResultTotal(employee.totalAssign);
      const temp = columns.map((item: any) => {
        if (item.dataIndex === 'departmentNames') {
          return {
            ...item,
            ...getColumnSearchProps(
              { options: departmentsArr, dataIndex: 'departmentNames' },
              onClickFilter,
            ),
          };
        }
        return item;
      });
      setColumns(temp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeList, postParam]);

  const onClickFilter = (selectedKeys: any, dataIndex: any) => {
    const paramObj = { ...postParam };
    switch (dataIndex) {
      case 'departmentNames':
        paramObj.filter.departmentName = selectedKeys;
        setPostParam(paramObj);
        break;
      default:
        break;
    }
    const postParamFilterSort = {
      ...postParam,
    };
    setPostParam(postParamFilterSort);
  };

  let COLUMNS: any = [
    {
      title: t('insurance:is_code'),
      dataIndex: 'employeeCode',
      key: 'employeeCode',
      ellipsis: true,
      width: 60,
      minWidth: 60,
      align: 'center',
      sorter: {
        compare: (a: any, b: any) => {},
        multiple: 3,
      },
    },
    {
      title: t('Employee'),
      dataIndex: 'fullName',
      key: 'fullName',
      width: 200,
      minWidth: 200,
      ellipsis: true,
      sorter: {
        compare: (a: any, b: any) => {},
        multiple: 3,
      },
      render: function (text: any, record: any, index: any) {
        return (
          <EmployeeColumn
            avatar={
              record?.fullName ? `${record?.fullName}` : `${record?.lastName} ${record?.firstName}`
            }
            fullName={
              record?.fullName ? `${record?.fullName}` : `${record?.lastName} ${record?.firstName}`
            }
            email={record?.email}
          />
        );
      },
    },
    {
      title: t('insurance:department'),
      dataIndex: 'departmentNames',
      key: 'departmentNames',
      width: 120,
      minWidth: 120,
      sorter: {
        compare: (a: any, b: any) => {},
        multiple: 3,
      },
    },
  ];

  const [columns, setColumns] = useState(COLUMNS);

  const search = (key: any) => {
    setSearchText(key);
    const paramWithText = {
      ...postParam,
      search: {
        name: key,
      },
    };
    if (!isEqual(paramWithText, postParam)) {
      debounce(() => {
        setPostParam(paramWithText);
      }, 500)();
    }
    setCurrentPage(DEFAULT_CURRENT_PAGE);
  };

  const handleInitDateAssigned = (selectedUser: string[]) => {
    setSelectUsersAssigned(
      selectedUser.map((userId: string) => ({
        data: data.find((user: any) => user.userId === userId),
        dateAddLeaveDays: null,
      })),
    );
  };

  const handleUpdateUser = (
    user: { data: any; dateAddLeaveDays: Moment | null },
    dateAddLeaveDays: Moment | null,
  ) => {
    const indexUser = selectUsersAssigned.findIndex(
      (userAssigned) => userAssigned.data.userId === user.data.userId,
    );
    const newUser = { ...selectUsersAssigned[indexUser], dateAddLeaveDays };
    setSelectUsersAssigned((selectUsersAssigned) => [
      ...selectUsersAssigned.slice(0, indexUser),
      newUser,
      ...selectUsersAssigned.slice(indexUser + 1),
    ]);
  };

  const onChangeTableAssign = (pagination: any, filters: any, sorter: any) => {
    //call api to sort here
    const sortParam = { ...postParam };
    if (sorter.length) {
      const sortValue = sorter?.map((item: any) =>
        item.order
          ? {
              [item.columnKey === 'employeeCode'
                ? 'employeeId'
                : item.columnKey === 'departmentNames'
                ? 'departmentName'
                : item.columnKey]: item.order === 'ascend' ? 'ASC' : 'DESC',
            }
          : [],
      );
      sortParam.sort = sortValue;
    } else {
      if (sorter.order) {
        const array: any = [
          {
            [sorter.columnKey === 'employeeCode'
              ? 'employeeId'
              : sorter.columnKey === 'deparmentNames'
              ? 'departmentName'
              : sorter.columnKey]: sorter.order === 'ascend' ? 'ASC' : 'DESC',
          },
        ];
        sortParam.sort = array;
      } else {
        sortParam.sort = [];
      }
    }
    const postParamFilterSort = {
      ...sortParam,
      offset: pagination.current,
      limit: sizePage,
    };
    setPostParam(postParamFilterSort);
  };

  return (
    <WrapContainer>
      {selectUsersAssigned.length > 0 ? (
        <>
          <Container>
            <div></div>
            <div>
              <ButtonBack onClick={() => setSelectUsersAssigned([])}>{backText}</ButtonBack>
              <ITVButton
                disabled={!selectUsersAssigned.every((user) => user.dateAddLeaveDays)}
                style={{ width: 100 }}
                onClick={() =>
                  handleManualAddLeaveDays && handleManualAddLeaveDays(selectUsersAssigned)
                }
              >
                {submitText}
              </ITVButton>
            </div>
          </Container>
          <ChooseLeaveDayList
            selectUsersAssigned={selectUsersAssigned}
            handleUpdateUser={handleUpdateUser}
            t={t}
          />
        </>
      ) : (
        <>
          <Container>
            <ContainerLeft>
              <ITVSearch handleSearch={(value: any) => search(value)} />
            </ContainerLeft>
            <ITVButton
              disabled={!selectedUser || selectedUser.length === 0}
              style={{ width: 100 }}
              onClick={() =>
                leaveDaysImmediately
                  ? handleAutoAddLeaveDays && handleAutoAddLeaveDays(selectedUser)
                  : handleInitDateAssigned(selectedUser)
              }
            >
              {leaveDaysImmediately ? submitText : stepText}
            </ITVButton>
          </Container>

          <ITVTable
            columns={columns}
            data={data}
            width={widthTable}
            loading={loading}
            totalIni={resultTotal}
            rowKey={rowKey}
            totalResult={resultTotal}
            setSelectedUser={setSelectedUser}
            selectionType={selectionType}
            onChange={onChangeTableAssign}
            setOffset={setCurrentPage}
            offset={currentPage}
            setLimit={setSizePage}
            limit={sizePage}
          />
        </>
      )}
    </WrapContainer>
  );
};

export default AssignList;

export const StyledTest = styled.div`
  .epl-employee-list {
    height: 86vh;
    text-align: center;
    background-color: $grey;
    overflow-y: hidden;
  }

  .epl-employee-container {
    display: flex;
    font-size: 30px;
    flex-direction: column;
    height: 100%;

    &__item-header {
      flex: 5%;
      height: 100vh;
    }

    &__item-functions {
      flex: 10%;
      height: 100vh;
    }

    &__item-table {
      flex: 85%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: 100vh;
      .ant-btn .ant-dropdown-trigger {
        top: 100%;
      }
    }
  }
  .ant-layout-content {
    max-height: 100vh;
    overflow: hidden;
    & > div {
      padding: 0 !important;
      min-height: 0;
    }
  }
  .info {
    margin-left: 1rem;
  }
`;

const WrapContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const ContainerLeft = styled.div``;

export const ContainerRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

import { AddOnsiteEmployeePayload } from 'types';
import {
  AddOnsiteEmployee,
  ListParams,
  ListResponse,
  OnsiteEmployee,
  SuccessResponse,
  TimeSynchronize,
} from 'types';
import axiosClient from './axiosClient';

const onsiteEmployeeApi = {
  getAll(params: Partial<ListParams>): Promise<SuccessResponse<ListResponse<OnsiteEmployee>>> {
    return axiosClient.get('/access-check-in-out/onsite', {
      params: params,
    });
  },

  addOrUpdate(payload: AddOnsiteEmployeePayload[]): Promise<SuccessResponse<AddOnsiteEmployee[]>> {
    return axiosClient.put('/access-check-in-out/update-access-check-in-out', payload);
  },

  getEmployeeList(
    params?: Partial<ListParams>,
  ): Promise<
    SuccessResponse<Array<Pick<OnsiteEmployee, 'userId' | 'email' | 'employeeId' | 'fullName'>>>
  > {
    return axiosClient.get('/employee/list-employee-by-project-code', {
      params: params,
    });
  },

  synchronizedDataOnsite(payload: TimeSynchronize): Promise<SuccessResponse<any>> {
    return axiosClient.post('/schedule/synchronized-data-onsite', payload);
  },
};

export default onsiteEmployeeApi;

import debounce from 'lodash/debounce';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';

import { TextAreaField } from 'components/FormFields';
import { Reason } from 'types';

const StyledForm = styled.form`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 8px;
`;

const StyledWrapperReason = styled.div`
  width: 100%;
`;

interface ReasonFormProps {
  onSubmit?: (payload: Reason) => void;
}

export function ReasonForm({ onSubmit }: ReasonFormProps) {
  const { t } = useTranslation(['common']);

  const schema = yup.object().shape({
    reason: yup.string().required(t('required_reason_rule')),
  });

  const { control, handleSubmit } = useForm<Reason>({
    defaultValues: {
      reason: '',
    },
    resolver: yupResolver(schema),
  });

  const handleFormSubmit = (formValues: Reason) => {
    onSubmit?.(formValues);
  };

  const debounceReasonChange = debounce(handleSubmit(handleFormSubmit), 350);

  return (
    <StyledForm onSubmit={handleSubmit(handleFormSubmit)}>
      <StyledWrapperReason>
        <TextAreaField
          name="reason"
          control={control}
          placeholder={t('reason_placeholder')}
          onChange={() => debounceReasonChange()}
        />
      </StyledWrapperReason>
    </StyledForm>
  );
}

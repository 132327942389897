import { ExportOutlined } from '@ant-design/icons';
import { Modal, TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import payrollApi from 'api/payrollApi';
import { useAppSelector } from 'app/hooks';
import { ReasonForm } from 'components/Forms';
import { notificationToast } from 'components/notificationToast';
import { Notification } from 'constants/notification';
import {
  ALLOWANCE_SORT_FIELDS,
  ContractSalaryConfirmStatus,
  MONTH_YEAR_FORMAT,
  SORT_TYPES,
} from 'constants/payroll';
import CustomButton from 'styles/buttonStyled';
import { ContainerBgWhite } from 'styles/containerBgWhite';
import { AdvancePayload, IAdvanceFilters, IAdvanceList, Reason } from 'types';
import { getErrorMessage } from 'utils/getErrorMessage';
import { AdvanceFilters } from './AdvanceFilters';
import { AdvanceList } from './AdvanceList';
import { selectEmployee } from 'features/employee/employeeSlice';

interface AdvanceProps {
  monthYear: string;
  fetchListStatusTabPayrollAPI: () => void;
}

export function Advance({ monthYear, fetchListStatusTabPayrollAPI }: AdvanceProps) {
  const { t } = useTranslation(['action', 'payroll']);
  const selectRowKey = useAppSelector((state) => state.employee.selectRowKey) as string[];

  const [advanceList, setAdvanceList] = useState<Array<IAdvanceList>>([]);
  const [totalResult, setTotalResult] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);
  const [statusConfirm, setStatusConfirm] = useState('');
  const [offset, setOffset] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filters, setFilters] = useState<Partial<IAdvanceFilters>>({
    search: '',
    line: '',
  });
  const isCheckAll: boolean = useAppSelector(selectEmployee).isCheckAll;

  const filtersMemo = useMemo(() => {
    if (isCheckAll) {
      return {
        ...filters,
        monthYear: monthYear ?? moment(new Date()).format('YYYY-MM'),
      };
    }
    return {
      ...filters,
      monthYear: monthYear ?? moment(new Date()).format('YYYY-MM'),
      offset,
      limit,
    };
  }, [filters, monthYear, offset, limit, isCheckAll]);

  const advancePayload: Partial<AdvancePayload> = {
    search: filters.search || '',
  };

  const fetchAdvanceListAPI = async () => {
    try {
      setIsLoading(true);

      const response = await payrollApi.getAllUserHaveAdvance(filtersMemo);
      setAdvanceList(
        response.data?.result.map((item: IAdvanceList) => ({ ...item, userId: item?.user?.id })),
      );
      setTotalResult(response.data?.totalResult || 0);
      setStatusConfirm(response.data?.status || '');

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAdvanceListAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersMemo]);

  const handleFiltersChange = ({ ...payload }: AdvancePayload) => {
    setOffset(1);

    setFilters((prevFilter) => ({
      ...prevFilter,
      ...payload,
    }));
  };

  const handleConfirm = async () => {
    try {
      const payload = {
        monthYear: moment(filtersMemo.monthYear).format(MONTH_YEAR_FORMAT),
        status: ContractSalaryConfirmStatus.APPROVED,
      };
      setIsConfirmLoading(true);
      const response = await payrollApi.confirmAdvance(payload);
      setIsConfirmLoading(false);
      notificationToast(Notification.Type.Success, response.message, Notification.Duration._3s);
      fetchListStatusTabPayrollAPI();
      fetchAdvanceListAPI();
    } catch (error) {
      setIsConfirmLoading(false);
      const message = getErrorMessage(error);
      notificationToast(Notification.Type.Error, message, Notification.Duration._3s);
    }
  };

  const handleConfirmAdvance = () => {
    Modal.confirm({
      title: <div style={{ fontWeight: 'bold' }}>{t('payroll:confirm_bonus_title')}</div>,
      okButtonProps: { loading: isConfirmLoading },
      okText: t('action:ok'),
      cancelText: t('action:cancel'),
      onOk: handleConfirm,
    });
  };

  const handleExport = () => {};

  const handleTableChange = (
    pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue | null>,
    sorter: SorterResult<any>,
  ) => {
    if (pagination?.current === offset) {
      setOffset(1);

      setFilters((prevFilters) => ({
        ...prevFilters,
        viewBy: sorter.column
          ? ALLOWANCE_SORT_FIELDS[sorter.field as keyof typeof ALLOWANCE_SORT_FIELDS]
          : ALLOWANCE_SORT_FIELDS.fullName,
        sortBy: sorter.column
          ? SORT_TYPES[sorter.order as keyof typeof SORT_TYPES]
          : SORT_TYPES.ascend,
      }));
    }
  };

  const handleReset = async (reason: string) => {
    try {
      const params = {
        monthYear: moment(filtersMemo.monthYear).format(MONTH_YEAR_FORMAT),
        timesheetType: 'advance',
        reason,
      };

      setIsConfirmLoading(true);
      const response = await payrollApi.resetConfirmPayroll(params);
      setIsConfirmLoading(false);
      notificationToast(Notification.Type.Success, response.message, Notification.Duration._3s);
      fetchListStatusTabPayrollAPI();
      fetchAdvanceListAPI();
    } catch (error) {
      setIsConfirmLoading(false);
      const message = getErrorMessage(error);
      notificationToast(Notification.Type.Error, message, Notification.Duration._3s);
    }
  };

  const handleResetAdvance = () => {
    let reason = '';
    Modal.confirm({
      title: <div style={{ fontWeight: 'bold' }}>{t('reset_reason')}</div>,
      content: (
        <>
          <ReasonForm
            onSubmit={(payload: Reason) => {
              if (!payload.reason) return;
              reason = payload.reason;
            }}
          />
          <p style={{ margin: 0, color: 'red', fontStyle: 'italic' }}>
            {t('payroll:payroll_deleted', { monthYear })}
          </p>
        </>
      ),
      okButtonProps: { loading: isLoading },
      okText: t('action:ok'),
      cancelText: t('action:cancel'),
      onOk: () => handleReset(reason),
      onCancel: () => (reason = ''),
    });
  };

  return (
    <ContainerBgWhite>
      <StyledWrapperFilters>
        <AdvanceFilters initialValues={advancePayload} onSubmit={handleFiltersChange} />

        <StyledWrapperActionBtn>
          {statusConfirm === ContractSalaryConfirmStatus.APPROVED ? (
            <>
              <CustomButton loading={false} onClick={handleResetAdvance}>
                {t('reset')}
              </CustomButton>{' '}
              <ButtonConfirmed>{t('Confirmed')}</ButtonConfirmed>
            </>
          ) : (
            <CustomButton loading={false} onClick={handleConfirmAdvance}>
              {t('Confirm')}
            </CustomButton>
          )}

          <CustomButton
            icon={<ExportOutlined />}
            onClick={handleExport}
            disabled={selectRowKey.length === 0}
          >
            {t('export')}
          </CustomButton>
        </StyledWrapperActionBtn>
      </StyledWrapperFilters>

      <AdvanceList
        loading={isLoading}
        data={advanceList}
        totalResult={totalResult}
        offset={offset}
        limit={limit}
        setOffset={setOffset}
        setLimit={setLimit}
        onRefresh={fetchAdvanceListAPI}
        onTableChange={handleTableChange}
        monthYear={filtersMemo.monthYear ?? ''}
      />
    </ContainerBgWhite>
  );
}
const StyledWrapperFilters = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;
const StyledWrapperActionBtn = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
const ButtonConfirmed = styled.div`
  background-color: var(--cl_success100);
  color: var(--cl_success600);
  font-weight: bold;
  border-radius: 5px;
  text-align: center;
  display: inline;
  padding: 5px 10px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 4px;
`;

import { Badge } from 'antd';
import styled, { css } from 'styled-components';
import { capitalizeFirstLetter } from 'utils/text';

interface StatusViewProps {
  text: string;
}

type TStatus = 'success' | 'processing' | 'default' | 'error' | 'warning';

const StatusView = ({ text }: StatusViewProps) => {
  let status: TStatus = 'default';
  switch (text?.toLowerCase()) {
    case 'approved':
    case 'approve':
    case 'active':
    case 'validated':
      status = 'success';
      break;
    case 'waiting for approve':
    case 'waiting for approval':
    case 'not yet':
    case 'pending':
      status = 'warning';
      break;
    case 'confirm':
      status = 'processing';
      break;
    case 'close':
    case 'reset':
    case 'reject':
    case 'rejected':
    case 'deactive':
    case 'expired':
    case 'waiting for confirm':
      status = 'error';
      break;
    default:
      status = 'default';
      break;
  }

  return <CustomBadge status={status} text={capitalizeFirstLetter(text)} />;
};

export default StatusView;
interface StatusText {
  status: string;
}
export const CustomBadge = styled(Badge)`
  .ant-badge-status-text {
    ${(props: StatusText) => {
      switch (props.status) {
        case 'success':
          return css`
            color: var(--cl_success600);
          `;
        case 'processing':
          return css`
            color: var(--cl_info500);
          `;
        case 'error':
          return css`
            color: var(--cl_error500);
          `;
        case 'warning':
          return css`
            color: var(--cl_warning600);
          `;
        default:
          break;
      }
    }}
  }
`;

import { Col, Form, Input, InputNumber, Popconfirm, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import CustomButton from 'styles/buttonStyled';

const ViewBonus = (props: any) => {
  const { current } = props;
  const [errorMessage] = useState('');
  const { t } = useTranslation(['masterConfig', 'insurance', 'common']);
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      name: current?.name,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current]);

  return (
    <Form form={form}>
      <Row gutter={[16, 16]}>
        <Col span={8} style={{ display: 'flex' }}>
          <p style={{ marginTop: '2%', display: 'flex' }}>Name</p>
        </Col>
        <Col span={16}>
          <Form.Item name={'name'}>{<Input />}</Form.Item>
        </Col>
      </Row>

      {errorMessage && (
        <Row gutter={[16, 16]} style={{ paddingLeft: 8, paddingBottom: 10, color: 'red' }}>
          {errorMessage}
        </Row>
      )}
      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: '2%' }}>
        <Popconfirm
          title={`${t('insurance:Are_you_sure_confirm_this_request')}`}
          onConfirm={() => {
            form.submit();
          }}
          okText={`${t('insurance:Yes')}`}
          cancelText={`${t('insurance:no')}`}
        >
          <CustomButton>{t('masterConfig:action.save')}</CustomButton>
        </Popconfirm>
      </div>
    </Form>
  );
};

export default ViewBonus;

export const CustomInputNumber = styled(InputNumber)`
  .ant-input-number-input {
    text-align: right;
  }
  .ant-input-number-handler-wrap {
    padding-right: 5px;
  }
  .ant-input-number-handler-wrap {
    display: none;
  }
`;

import { DatePicker, Form, InputNumber, Popconfirm, Radio, RadioChangeEvent, Select } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { DATE_PAYLOAD_FORMAT } from 'constants/commons';
import { createUpdateContractUser, getContractUser } from 'features/employee/employeeAction';
import { selectEmployee } from 'features/employee/employeeSlice';
import { TaxProfiles } from 'hrm-common/extensions/enums/personel';
import cond from 'lodash/cond';
import stubTrue from 'lodash/stubTrue';
import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import CustomButton from 'styles/buttonStyled';
import CustomSelect from 'styles/inputSelectStyled';

const ContractTable = (props: any) => {
  const { employeeData, callBack, payrollUpdate, reloadContract } = props;
  const [monthYear, setMonthYear] = useState<Moment | null>(props.monthYear ?? moment());

  const dispatch = useAppDispatch();
  const [formAdd] = Form.useForm();
  const { t } = useTranslation([
    'validation',
    'overtime',
    'action',
    'onboard',
    'validation',
    'timesheet',
    'employee',
    'leaveOff',
  ]);
  let listTaxProfile = [
    {
      id: 1,
      name: t('employee:contract_information_fields.more_than_month'),
      value: TaxProfiles.MORE_THAN_THREE_MONTH,
    },
    {
      id: 2,
      name: t('employee:contract_information_fields.less_than_month'),
      value: TaxProfiles.LESS_THAN_THREE_MONTH,
    },
    {
      id: 3,
      name: t('employee:contract_information_fields.intern'),
      value: TaxProfiles.INTERN,
    },
    {
      id: 4,
      name: t('employee:contract_information_fields.probation'),
      value: TaxProfiles.PROBATION,
    },
  ];
  let currencies = [{ id: 1, value: 'VND' }];
  const employee = useAppSelector<any>(selectEmployee);
  const dataContract = employee?.dataUserContract;
  const [data, setData] = useState<any>({});
  const [reside, setReside] = useState(false);
  const [disableBtnSave, setDisableBtnSave] = useState(false);
  const [valueTaxProfile, setValueTaxProfile] = useState('');

  useEffect(() => {
    if (payrollUpdate?.userId) {
      dispatch(
        getContractUser({ userId: payrollUpdate?.userId, monthYear: payrollUpdate?.monthYear }),
      );
    } else if (employeeData?.id) {
      dispatch(
        getContractUser({
          userId: employeeData?.id,
          monthYear: moment(monthYear).format('YYYY-MM'),
        }),
      );
    }
    formAdd.setFieldsValue({
      dateApply: moment(data?.dateApply ? data?.dateApply : new Date()).set({
        month: monthYear?.month(),
        year: monthYear?.year(),
      }),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeData?.id, payrollUpdate?.userId, monthYear]);

  useEffect(() => {
    if (dataContract) {
      setData(dataContract);
    } else {
      setData({});
    }
  }, [dataContract]);

  useEffect(() => {
    formAdd.setFieldsValue({
      taxProfile: data?.taxProfile
        ? data?.taxProfile === TaxProfiles.LESS_THAN_THREE_MONTH
          ? t('employee:contract_information_fields.less_than_month')
          : data?.taxProfile === TaxProfiles.MORE_THAN_THREE_MONTH
          ? t('employee:contract_information_fields.more_than_month')
          : data?.taxProfile === TaxProfiles.PROBATION
          ? t('employee:contract_information_fields.probation')
          : t('employee:contract_information_fields.intern')
        : null,
      salary: data?.salary ? data?.salary : 0,
      ratio: data?.ratio ?? 100,
      dateApply: moment(data?.dateApply ? data?.dateApply : new Date()).set({
        month: monthYear?.month(),
        year: monthYear?.year(),
      }),
    });
    setReside(data?.resideStatus ?? true);
    setValueTaxProfile(data?.taxProfile);

    if (data.taxProfile && data.salary) {
      setDisableBtnSave(false);
    } else {
      setDisableBtnSave(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, reloadContract]);

  useEffect(() => {
    if (valueTaxProfile && valueTaxProfile !== TaxProfiles.PROBATION) {
      formAdd.setFieldsValue({
        ratio: 100,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueTaxProfile]);

  const onChangeReside = (e: RadioChangeEvent) => {
    setReside(e.target.value);
  };

  const getTaxProfile = cond([
    [
      (x: string) => x === t('employee:contract_information_fields.less_than_month'),
      () => TaxProfiles.LESS_THAN_THREE_MONTH,
    ],
    [
      (x: string) => x === TaxProfiles.LESS_THAN_THREE_MONTH,
      () => TaxProfiles.LESS_THAN_THREE_MONTH,
    ],
    [(x: string) => x === TaxProfiles.INTERN, () => TaxProfiles.INTERN],
    [(x: string) => x === TaxProfiles.PROBATION, () => TaxProfiles.PROBATION],
    [
      (x: string) => x === TaxProfiles.MORE_THAN_THREE_MONTH,
      () => TaxProfiles.MORE_THAN_THREE_MONTH,
    ],
    [stubTrue, () => TaxProfiles.MORE_THAN_THREE_MONTH],
  ]);

  const onFinish = () => {
    formAdd.validateFields().then((values: any) => {
      const obj = {
        id: data?.id,
        taxProfile: getTaxProfile(values.taxProfile),
        salary: values.salary,
        ratio: values.ratio,
        resideStatus: reside,
        monthYear: moment(payrollUpdate?.monthYear ? payrollUpdate?.monthYear : monthYear).format(
          'YYYY-MM',
        ),
        dateApply: moment(values.dateApply).format(DATE_PAYLOAD_FORMAT),
      };

      dispatch(
        createUpdateContractUser({
          userId: payrollUpdate?.userId ? payrollUpdate?.userId : employeeData?.id,
          data: obj,
          callBack: () => {
            if (payrollUpdate && payrollUpdate?.callBack) {
              dispatch(
                getContractUser({
                  userId: payrollUpdate?.userId,
                  monthYear: payrollUpdate?.monthYear,
                }),
              );
              payrollUpdate?.callBack();
            } else {
              dispatch(
                getContractUser({
                  userId: employeeData?.id,
                  monthYear: moment(monthYear).format('YYYY-MM'),
                }),
              );
              callBack && callBack('contract');
            }
          },
        }),
      );
    });
  };

  const handleChange = (value: any) => {
    formAdd.setFieldsValue({
      salary: value ? parseInt(value) : 1,
    });
  };
  const handleChangeRatio = (value: any) => {
    formAdd.setFieldsValue({
      ratio: value ? parseInt(value) : 0,
    });
  };

  const handleChangeTax = (value: any) => {
    if (value) {
      setValueTaxProfile(value);
      setDisableBtnSave(false);
    } else {
      setValueTaxProfile('');
      setDisableBtnSave(true);
    }
  };

  const handleApplyDateChange = (date: Moment | null) => {
    formAdd.setFieldsValue({
      dateApply: date,
    });
  };

  return (
    <Form form={formAdd} layout="vertical">
      <ContainerContract>
        <ContractInformation>
          <StyledWrapper>
            <CustomFormItem>
              <DatePicker
                value={monthYear}
                picker="month"
                onChange={(value) => setMonthYear(value)}
                disabledDate={(currentMonth) => moment() < currentMonth}
                allowClear={false}
              />
            </CustomFormItem>

            <CustomFormItem>
              <div>
                <span>{t('employee:contract_information_fields.currency_units')}: </span>
                <Select defaultValue="VND" style={{ width: 80 }}>
                  {currencies.map((data: any) => {
                    return (
                      <Select.Option defaultValue="VND" value={data.value}>
                        {data.value}
                      </Select.Option>
                    );
                  })}
                </Select>
              </div>
            </CustomFormItem>
          </StyledWrapper>

          <CustomFormItem>
            <div>{t('employee:contract_information_fields.reside_in_Vietnam')}</div>
            <InfoRadio.Group onChange={onChangeReside} value={reside} disabled>
              <InfoRadio value={true}>Yes</InfoRadio>
              <InfoRadio value={false}>No</InfoRadio>
            </InfoRadio.Group>
          </CustomFormItem>

          <ContainerContractLine>
            <CustomFormItem
              name={'taxProfile'}
              label={<div>{t('employee:contract_information_fields.tax_profile')}</div>}
              rules={[
                {
                  required: true,
                  message: t('HRM_VAD_REQUIRED', {
                    field: t('employee:contract_information_fields.tax_profile'),
                  }),
                },
              ]}
            >
              <CustomSelect
                placeholder={'Please select Tax Profile'}
                onChange={handleChangeTax}
                disabled={data?.isCheck}
              >
                {listTaxProfile.map((data: any, key: any) => {
                  return (
                    <Select.Option key={key} value={data.value}>
                      {data?.name}
                    </Select.Option>
                  );
                })}
              </CustomSelect>
            </CustomFormItem>

            <CustomFormItem
              name={'dateApply'}
              label={<div>{t('employee:contract_information_fields.date_apply_salary')}</div>}
            >
              <DatePicker
                style={{ padding: '8px' }}
                onChange={handleApplyDateChange}
                disabledDate={(date) => {
                  const disabled =
                    date < moment(monthYear).startOf('month') ||
                    date > moment(monthYear).endOf('month');
                  return disabled;
                }}
                disabled={data?.isCheck}
              />
            </CustomFormItem>
          </ContainerContractLine>

          <ContainerContractLine>
            <CustomFormItem
              name={'salary'}
              label={<div>{t('employee:contract_information_fields.salary')}</div>}
              rules={[
                {
                  required: true,
                  message: t('HRM_VAD_REQUIRED', {
                    field: t('employee:contract_information_fields.salary'),
                  }),
                },
              ]}
            >
              <CustomInputNumber
                style={{ width: '100%' }}
                min={0}
                max={2000000000}
                formatter={(value: any) => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                onChange={handleChange}
                disabled={data?.isCheck}
              />
            </CustomFormItem>

            <CustomFormItem
              name={'ratio'}
              label={<div>{t('employee:contract_information_fields.ratio')}</div>}
              rules={[
                {
                  required: true,
                  message: t('HRM_VAD_REQUIRED', {
                    field: t('employee:contract_information_fields.ratio'),
                  }),
                },
              ]}
            >
              <CustomInputNumber
                style={{ width: '40%' }}
                min={0}
                max={100}
                defaultValue={100}
                formatter={(value: any) => {
                  if (value) {
                    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                  }
                  return 0;
                }}
                parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                onChange={handleChangeRatio}
                addonAfter="%"
                type="number"
                disabled={valueTaxProfile !== TaxProfiles.PROBATION || data?.isCheck}
              />
            </CustomFormItem>
          </ContainerContractLine>
        </ContractInformation>

        <div
          style={{
            width: '100%',
            height: '10%',
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '1%',
          }}
        >
          <Popconfirm
            title={t('leaveOff:modal_item.are_you_sure_save')}
            onConfirm={() => {
              onFinish();
            }}
            okText={t('timesheet:Yes')}
            cancelText={t('timesheet:No')}
            disabled={data?.isCheck || disableBtnSave}
          >
            <CustomButton disabled={data?.isCheck || disableBtnSave}>
              {t('timesheet:Save')}
            </CustomButton>
          </Popconfirm>
        </div>
      </ContainerContract>
    </Form>
  );
};

export default ContractTable;

const ContainerContract = styled.div`
  height: 100%;
  width: 100%;
  margin-right: -15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const ContractInformation = styled.div`
  height: 450px;
  width: 100%;
  overflow-y: auto;
`;

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const ContainerContractLine = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const CustomFormItem = styled(Form.Item)`
  margin: 0 0 20px 0;
  width: 300px;
  .ant-form-item-label {
    padding: 0px;
  }
`;
const CustomInputNumber = styled(InputNumber)`
  .ant-input-number-input {
    text-align: right;
  }
  .ant-input-number-handler-wrap {
    padding-right: 5px;
  }
  .ant-input-number-handler-wrap {
    display: none;
  }
`;

const InfoRadio = styled(Radio)`
  .ant-radio-disabled .ant-radio-inner {
    border-color: #f1592a;
  }
  .ant-radio-inner::after {
    background: #f1592a;
    border-color: #f1592a;
  }
`;

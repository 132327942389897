import React, { useState, useEffect } from 'react';
import { Badge, Modal, Form, InputNumber, Popconfirm, Table, Select, Space } from 'antd';
import styled from 'styled-components';
import CustomButton from 'styles/buttonStyled';
import { colors } from 'assets/colors';
import { useAppSelector } from 'app/hooks';
import { useDispatch } from 'react-redux';
import {
  deleteRecordLeave,
  editRecord,
  getListSlideLeaveoff,
  assignStaffManagerPopup,
} from '../leaveOffAction';
import {
  deleteUserSameLeaveOffType,
  editCompilationDateUser,
  resetDataSlideLeaveOff,
  selectLeaveOff,
} from '../leaveOffSlice';
import Icon, {
  EditTwoTone,
  DeleteTwoTone,
  SaveTwoTone,
  CloseCircleTwoTone,
  PlusOutlined,
} from '@ant-design/icons';
import { formatMonth } from 'utils/date';
import { formatNumber } from 'utils/function';
import { useTranslation } from 'react-i18next';
import { getDotColor } from 'utils/function';
import { IUserRecord } from '../interfaces/leaveManageInterfaces';
interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: 'number' | 'text';
  record: any;
  index: number;
  children: React.ReactNode;
}

function ModalListOfPolicy(props: any) {
  const { setUserEdited, dataLeave, idLeaveOffSelected, userEdited } = props;
  const { userId, fullName, firstName, lastName } = userEdited as IUserRecord;
  const [data, setData] = useState([]);
  const { t } = useTranslation(['timesheet', 'leaveOff', 'insurance', 'onboard']);
  const [isVisible, setIsVisible] = useState(false);
  const [isVisibleAdd, setIsVisibleAdd] = useState(false);
  const dataTotalLeave = useAppSelector(selectLeaveOff).dataSlideLeaveoff;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const [type, setType] = useState(null);

  useEffect(() => {
    dispatch(getListSlideLeaveoff({ user: userId }));
    setIsVisible(userId ? true : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  useEffect(() => {
    if (Array.isArray(dataTotalLeave?.data)) {
      setData(dataTotalLeave?.data);

      const tmpDataLeave = dataLeave.filter(
        (item: any) => dataTotalLeave?.data.map((item: any) => item.name).indexOf(item.name) === -1,
      );
      setType(tmpDataLeave[0]?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTotalLeave]);

  const handleCancel = () => {
    setUserEdited(null);
    setIsVisible(false);
    dispatch(resetDataSlideLeaveOff());
  };

  const handleDelete = async (id: any, configTypeId: number) => {
    dispatch(deleteRecordLeave({ id: id, user: userId }));
    idLeaveOffSelected === configTypeId && dispatch(deleteUserSameLeaveOffType({ userId }));
  };

  const EditableCell: React.FC<EditableCellProps> = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode = <InputNumber />;

    return (
      <td {...restProps}>
        {editing && !record?.disable ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                message: t('leaveOff:Required_field'),
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const isEditing = (record: any) => record.id === editingKey;

  const edit = (record: any) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.id);
  };

  const tmpDataLeave = dataLeave.filter(
    (item: any) => data.map((item: any) => item.name).indexOf(item.name) === -1,
  );

  const add = () => {
    setIsVisibleAdd(false);
    if (type) {
      dispatch(
        assignStaffManagerPopup({
          type,
          arrStaff: [userId],
          userEdited: idLeaveOffSelected === type ? userEdited : null,
        }),
      );
    }
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (record: any) => {
    try {
      const row = await form.validateFields();
      dispatch(editRecord({ ...record, ...row, user: userId }));
      dispatch(editCompilationDateUser({ totalLeaveOff: row.total_leave_off, userId }));
      setEditingKey('');
    } catch (errInfo) {
      setEditingKey('');
      console.log('Validate Failed:', errInfo);
    }
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      width: 50,
      align: 'center',
      render: (text: string, row: any, index: any) => index + 1,
    },
    {
      title: t('Spell_Type'),
      dataIndex: 'name',
      render: (text: any, record: any, index: any) => {
        return (
          <ContainerNameStyled>
            <DotStyled style={{ backgroundColor: getDotColor(record?.color) }} />
            <div>{text}</div>
          </ContainerNameStyled>
        );
      },
    },
    {
      title: t('leaveOff:common.Expiry_date'),
      dataIndex: 'start_time',
      key: 'start_time',
      render: (text: string, record: any, index: any) => {
        return (
          <>
            {formatMonth(record?.start_time, null, false, true) +
              ' - ' +
              formatMonth(record?.end_time, null, false, true)}
          </>
        );
      },
    },
    {
      title: t('leaveOff:common.Compilation_date'),
      key: 'total_leave_off',
      dataIndex: 'total_leave_off',
      ellipsis: true,
      render: (text: any) => {
        return <>{text + ' ' + t('day', { s: text > 1 ? 's' : '' })}</>;
      },
      editable: true,
    },
    {
      title: t('leaveOff:common.Date_allow_current'),
      key: 'current',
      dataIndex: 'current',
      ellipsis: true,
      render: (text: any, record: any, index: any) => {
        const remain = formatNumber(record?.total_leave_off - record?.total_used);
        const used = formatNumber(record?.total_used);
        return (
          <>
            <div>
              {t('leaveOff:Remaining_leave_days') +
                remain +
                ' ' +
                t('day', { s: remain > 1 ? 's' : '' })}
            </div>
            <Badge
              status="default"
              text={t('Used') + ': ' + used + ' ' + t('day', { s: used > 1 ? 's' : '' })}
              style={{ color: colors.gray400 }}
            />
          </>
        );
      },
    },
    {
      title: t('leaveOff:common.date_add_leave_days'),
      key: 'DateAddLeaveDays',
      dataIndex: 'DateAddLeaveDays',
      ellipsis: true,
      // sorter: {
      //   compare: (a: any, b: any) => {
      //     if (a.contactType && b.contactType) return a.contactType.localeCompare(b.contactType);
      //     return 0;
      //   },
      //   multiple: 3,
      // },
    },
    {
      title: '',
      key: 'action',
      dataIndex: 'action',
      width: 100,
      render: (_: any, record: any) => {
        const editable = isEditing(record);

        return !editable && !record?.disable ? (
          <div>
            <Icon
              component={() => <EditTwoTone style={{ fontSize: 20 }} twoToneColor="#F1592A" />}
              onClick={() => edit(record)}
            />
            <Popconfirm
              title={t('onboard:are_you_sure_to_delete_this')}
              onConfirm={() => handleDelete(record?.id, record?.configTypeId)}
            >
              <DeleteTwoTone twoToneColor="#B7B7B7" style={{ marginLeft: 20, fontSize: 20 }} />
            </Popconfirm>
          </div>
        ) : (
          <div>
            <Popconfirm
              title={t('leaveOff:modal_item.are_you_sure_save')}
              onConfirm={() => save(record)}
            >
              <SaveTwoTone style={{ fontSize: 20 }} twoToneColor="#F1592A" />
            </Popconfirm>
            <Icon
              component={() => (
                <CloseCircleTwoTone
                  twoToneColor="#B7B7B7"
                  style={{ marginLeft: 20, fontSize: 20 }}
                />
              )}
              onClick={cancel}
            />
          </div>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col: any) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: any) => {
        return {
          record,
          inputType: 'number',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
        };
      },
    };
  });
  const handleChangType = (value: any) => {
    setType(value);
  };

  return (
    <Modal visible={isVisible} onCancel={handleCancel} style={{ minWidth: 1280 }} footer={null}>
      <Container>
        <Header>
          <div style={{ display: 'flex', marginBottom: -20 }}>
            <p style={{ fontSize: 20, fontWeight: 'bold' }}>
              {t('leaveOff:modal_item.List_of_Leave_assigned')} -{' '}
              {fullName ? fullName : lastName + ' ' + firstName}
            </p>
          </div>
          <Space>
            {type && (
              <CustomButton style={{ minWidth: 200 }} onClick={() => setIsVisibleAdd(true)}>
                <PlusOutlined /> {t('leaveOff:addMore')}
              </CustomButton>
            )}
          </Space>
        </Header>
        {isVisibleAdd && type && (
          <AddView>
            <Space>
              <div>{t('leaveOff:Select_type_of_leave')}: </div>
              <Select
                style={{ minWidth: 200, marginRight: 50 }}
                placeholder={t('Select_spell_type')}
                defaultValue={type}
                onChange={handleChangType}
              >
                {tmpDataLeave.map((item: any) => {
                  return (
                    <Select.Option value={item?.id}>
                      <div>
                        <span
                          style={{
                            minWidth: 6,
                            minHeight: 6,
                            background: getDotColor(item.color),
                            marginRight: 5,
                            borderRadius: '50%',
                          }}
                        ></span>
                        {item?.name}
                      </div>
                    </Select.Option>
                  );
                })}
              </Select>
              <Popconfirm title={t('leaveOff:modal_item.are_you_sure_save')} onConfirm={add}>
                <CustomButton style={{ minWidth: 100 }}>{t('timesheet:Save')}</CustomButton>
              </Popconfirm>
              <CustomButton
                style={{ minWidth: 100, backgroundColor: '#fff' }}
                onClick={() => setIsVisibleAdd(false)}
              >
                <span style={{ color: '#f1592a' }}>{t('timesheet:Cancel')}</span>
              </CustomButton>
            </Space>
          </AddView>
        )}

        {data.length > 0 && (
          <Form form={form} component={false}>
            <Table
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              bordered
              locale={{ emptyText: t('insurance:no_data') }}
              rowKey={(record: any) => record.userId}
              dataSource={data}
              columns={mergedColumns}
              rowClassName="editable-row"
              pagination={{
                onChange: cancel,
              }}
            />
          </Form>
        )}
      </Container>
    </Modal>
  );
}

export default ModalListOfPolicy;
export const Container = styled.div``;
export const Header = styled.div`
  display: flex;
  width: 97%;
  justify-content: space-between;
  margin-bottom: 20px;
`;
export const TrimSpace = styled.div`
  width: 18%;
`;
export const AssignButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const ContainerNameStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const DotStyled = styled.div`
  height: 8px;
  min-width: 8px;
  margin-right: 10px;
  border-radius: 8px;
`;
export const AddView = styled.div`
  margin: 30px;
  padding: 20px;
  place-content: center;
  display: flex;
  border-radius: 5px;
  border: 3px dotted #b4d2f1;
  background-color: #61b3f71c;
`;

import { useState, useEffect } from 'react';
import { Menu } from 'antd';

import Icon, {
  IdcardOutlined,
  UserOutlined,
  StarOutlined,
  EditTwoTone,
  HistoryOutlined,
  SolutionOutlined,
} from '@ant-design/icons';

import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'app/hooks';
import { selectPermissions } from 'features/auth/authSlice';
import { PERMISSION_PERSONNEL_RECORD_APPROVAL } from 'constants/permissions';

import AvatarBig from 'components/AvatarBig';
import EmployeeUpdateId from 'features/employee/pages/EmployeeUpdateId';
import { StyledEmployeeAddModalAvatar, EmployeeAvatar } from './styled';

interface SideBarMenuProps {
  isShowFullMenu: boolean;
  isShowHistory?: boolean;
  isShowContract?: boolean;
  employeeData: any;
  openKeys: string;
  employeeMenuKey: string;
  personalMenuKey: string;
  skillMenuKey: string;
  historyMenuKey: string;
  contractMenuKey?: string;
  allowanceMenuKey?: string;
  bonusMenuKey?: string;
  salaryMenuKey?: string;
  dependantsMenuKey?: string;
  certificatesMenuKey?: string;
  handleClickMenu: (keys: any) => void;
  onCloseModal: () => void;
  status?: any;
}

const SideBarMenu = (props: SideBarMenuProps) => {
  const {
    isShowFullMenu,
    isShowHistory = true,
    isShowContract,
    employeeData,
    openKeys,
    employeeMenuKey,
    personalMenuKey,
    skillMenuKey,
    historyMenuKey,
    contractMenuKey,
    certificatesMenuKey,
    handleClickMenu,
    onCloseModal,
  } = props;
  const { t } = useTranslation(['employee']);
  const dataPermissions = useAppSelector(selectPermissions);

  const [isVisibleUpdateId, setIsVisibleUpdateId] = useState(false);
  const [isAllowEditEmployeeId, setIsAllowEditEmployeeId] = useState(false);

  useEffect(() => {
    if (dataPermissions && dataPermissions.includes(PERMISSION_PERSONNEL_RECORD_APPROVAL)) {
      setIsAllowEditEmployeeId(true);
    }
  }, [dataPermissions]);

  let renderAvatar;
  if (employeeData) {
    renderAvatar = (
      <>
        <AvatarBig
          size={87}
          fontSize={'28px'}
          firstName={employeeData.firstName}
          lastName={employeeData.lastName}
        />
        <p>
          {t('employee:employee_manage.employee_id')} : {employeeData.employeeId}
          {isAllowEditEmployeeId && isShowFullMenu && (
            <Icon
              component={() => <EditTwoTone style={{ fontSize: 15, paddingLeft: 10 }} />}
              onClick={() => {
                setIsVisibleUpdateId(true);
              }}
            />
          )}
        </p>
      </>
    );
  } else {
    renderAvatar = (
      <>
        <EmployeeAvatar size={74} icon={<UserOutlined />} />
        <h2>{t('employee:employee_manage.unknown')}</h2>
        <p>
          {t('employee:employee_manage.employee_id')} : {t('employee:employee_manage.unknown')}
        </p>
      </>
    );
  }

  return (
    <>
      <StyledEmployeeAddModalAvatar>{renderAvatar}</StyledEmployeeAddModalAvatar>
      <Menu
        mode="inline"
        selectedKeys={[openKeys]}
        style={{ border: 'none', width: 'max-content' }}
      >
        {isShowFullMenu && (
          <Menu.Item
            key={employeeMenuKey}
            icon={<IdcardOutlined />}
            onClick={handleClickMenu}
            style={{ border: 'none' }}
          >
            {t('employee:employee_manage.menu_employee_info')}
          </Menu.Item>
        )}
        {isShowFullMenu && (
          <Menu.Item
            key={personalMenuKey}
            icon={<UserOutlined />}
            onClick={handleClickMenu}
            style={{ border: 'none' }}
          >
            {t('employee:employee_manage.menu_personnel_info')}
          </Menu.Item>
        )}
        <Menu.Item
          key={skillMenuKey}
          icon={<StarOutlined />}
          onClick={handleClickMenu}
          style={{ border: 'none' }}
        >
          {t('employee:employee_manage.skill_info')}
        </Menu.Item>
        {/* <Menu.Item
          key={certificatesMenuKey}
          icon={<StarOutlined />}
          onClick={handleClickMenu}
          style={{ border: 'none' }}
        >
          {'Certifications'}
        </Menu.Item> */}
        {/* <Menu.Item
          key={allowanceMenuKey}
          icon={<MoneyCollectOutlined />}
          onClick={handleClickMenu}
          style={{ border: 'none' }}
        >
          {t('employee:employee_manage.menu_allowance_info')}
        </Menu.Item>
        <Menu.Item
          key={bonusMenuKey}
          icon={<MoneyCollectOutlined />}
          onClick={handleClickMenu}
          style={{ border: 'none' }}
        >
          {t('employee:employee_manage.menu_bonus_info')}
        </Menu.Item>
        <Menu.Item
          key={salaryMenuKey}
          icon={<MoneyCollectOutlined />}
          onClick={handleClickMenu}
          style={{ border: 'none' }}
        >
          {t('employee:employee_manage.menu_salary_info')}
        </Menu.Item>
        <Menu.Item
          key={dependantsMenuKey}
          icon={<MoneyCollectOutlined />}
          onClick={handleClickMenu}
          style={{ border: 'none' }}
        >
          {t('employee:employee_manage.menu_dependants_info')}
        </Menu.Item> */}
        {isShowFullMenu && isShowContract && (
          <Menu.Item
            key={contractMenuKey}
            icon={<SolutionOutlined />}
            onClick={handleClickMenu}
            style={{ border: 'none' }}
          >
            Contract
          </Menu.Item>
        )}
        {isShowFullMenu && isShowHistory && (
          <Menu.Item
            key={historyMenuKey}
            icon={<HistoryOutlined />}
            onClick={handleClickMenu}
            style={{ border: 'none' }}
          >
            {t('employee:employee_manage.record_history')}
          </Menu.Item>
        )}
      </Menu>
      <EmployeeUpdateId
        recordId={employeeData?.id}
        employeeIdOld={employeeData?.employeeId}
        isVisible={isVisibleUpdateId}
        onClose={() => {
          setIsVisibleUpdateId(false);
          onCloseModal();
        }}
      />
    </>
  );
};

export default SideBarMenu;

import React from 'react';
import styled, { css } from 'styled-components';
import {
  CountryPhoneInput,
  CountryPhoneInputValue,
  ConfigProvider,
} from 'antd-country-phone-input';
import en from 'world_countries_lists/data/countries/en/world.json';
import 'antd/dist/antd.css';
import 'antd-country-phone-input/dist/index.css';

interface Props {
  isNoneBorder?: boolean;
  disabled?: boolean;
  value?: CountryPhoneInputValue;
  onChange?: (e: any) => void;
  placeholder?: string;
}

function ITVPhone(props: Props) {
  const { isNoneBorder = false, disabled = false, placeholder, value, onChange } = props;
  const getFlag = (short: string) => {
    const data = require(`world_countries_lists/data/flags/24x24/${short.toLowerCase()}.png`);
    if (typeof data === 'string') {
      return data;
    }
    return data.default;
  };
  return (
    <ConfigProvider
      locale={en}
      areaMapper={(area) => {
        return {
          ...area,
          emoji: (
            <img
              alt="flag"
              style={{ width: 18, height: 18, verticalAlign: 'sub' }}
              src={getFlag(area.short)}
            />
          ),
        };
      }}
    >
      {isNoneBorder && (
        <CountryPhoneNoneStyled
          disabled={disabled}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
        />
      )}
      {!isNoneBorder && (
        <CountryPhoneBorderStyled
          disabled={disabled}
          disabledstyled={disabled ? 'yes' : 'no'}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
        />
      )}
    </ConfigProvider>
  );
}

export default ITVPhone;

export const CountryPhoneNoneStyled = styled(CountryPhoneInput)`
  border: none !important;
  outline: none !important;
  border-right-width: 0px;
  box-shadow: none !important;
  width: 100%;
  padding: 0px;
  &:focus {
    outline: none !important;
    border: none !important;
    border-right-width: 0px;
    box-shadow: none !important;
  }
  .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    cursor: pointer;
  }
  .ant-form-item-has-error
    .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
    .ant-select-selector {
    border: none;
    box-shadow: none;
    outline: none;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    box-shadow: none;
    outline: none;
  }
`;

interface CountryPhoneBorderStyledProps {
  disabledstyled: string | 'no';
}

export const CountryPhoneBorderStyled = styled(CountryPhoneInput)`
  width: 100%;
  padding: 0px;
  height: 39px;
  border-radius: 4px;
  & > input {
    font-size: 14px;
    color: var(--cl_gray900);
  }
  &[disabled] {
    background-color: hsl(0, 0%, 95%);
    border-color: hsl(0, 0%, 90%);
    color: var(--cl_gray900);
  }

  .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    box-shadow: none;
    outline: none;
    cursor: pointer;
  }
  ${(props: CountryPhoneBorderStyledProps) =>
    props.disabledstyled === 'yes' &&
    css`
      .ant-select {
        pointer-events: none;
        cursor: not-allowed !important;
      }
    `}
`;

import { EyeTwoTone } from '@ant-design/icons';
import { Menu } from 'antd';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'app/hooks';
import { logout } from 'features/auth/authSlice';
import { selectEmployee } from 'features/employee/employeeSlice';

import { getEmployeeDetail } from 'features/employee/employeeAction';
import authApi from 'api/authApi';
import { TIMEZONE_DEFAULT } from 'constants/commons';
import styled from 'styled-components';
import { getUser } from 'utils/auth';
import EmployeeUpdateModal from '../EmployeeUpdateModal';

const MenuActions = (props: any) => {
  const timeZone = getUser()?.timeZone ? getUser()?.timeZone : TIMEZONE_DEFAULT;

  //Set default timezone in server
  moment.tz.setDefault(timeZone);
  const {
    isShowFullMenu,
    item,
    handleHideMenuActions,
    t,
    paramSortFilter,
    showMenu,
    callBack,
    monthYear,
  } = props;
  const dispatch = useAppDispatch();
  const stateEmployee = useAppSelector(selectEmployee);
  const [isVisibleEmployeeUpdate, setIsVisibleEmployeeUpdate] = useState(false);

  const handleCloseEmployeeUpdate = (value?: string) => {
    setIsVisibleEmployeeUpdate(value === 'contract');
  };

  useEffect(() => {
    // Logout if deactive myself
    if (stateEmployee?.isDeactiveMySelf) {
      authApi.logout(getUser()?.id);
      dispatch(logout());
      localStorage.removeItem('current_date_choose');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateEmployee?.isDeactiveMySelf]);

  const handleViewProfile = () => {
    handleHideMenuActions();
    dispatch(getEmployeeDetail({ idItem: item.id }));
    setIsVisibleEmployeeUpdate(true);
  };

  return (
    <>
      <Menu>
        <Menu.Item onClick={handleViewProfile} icon={<EyeTwoTone />} key="0">
          {t('employee:employee_manage.view_profile')}
        </Menu.Item>
      </Menu>
      <EmployeeUpdateModal
        showMenu={showMenu}
        isVisible={isVisibleEmployeeUpdate}
        isShowFullMenu={isShowFullMenu}
        itemId={item.id}
        status={item.status}
        onClose={handleCloseEmployeeUpdate}
        paramSortFilter={paramSortFilter}
        monthYear={monthYear}
        callBack={(value: string) => {
          callBack && callBack();
          handleCloseEmployeeUpdate(value);
        }}
      />
    </>
  );
};

export default MenuActions;

export const IconImage = styled.img`
  padding-bottom: 6px;
  width: 20px;
  height: 20px;
`;

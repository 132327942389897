import { DeleteTwoTone, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Checkbox, DatePicker, Input, InputNumber, Popconfirm, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { selectFormatMonth } from 'app/commonRedux/appSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import ITVTable from 'components/ITVTable';
import MenuAction from 'components/MenuAction';
import { getAllAdjustmentUser, updateAdjustmentUser } from 'features/employee/employeeAction';
import { actions, selectEmployee } from 'features/employee/employeeSlice';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import CustomButton from 'styles/buttonStyled';
import { Adjustment, AdjustmentSalaryMap, IAdjustmentSalary } from 'types';

const { Option } = Select;

const AdjustmentSalaryTable = (props: any) => {
  const { employeeData, callBack, monthYear, payrollUpdate } = props;
  const dispatch = useAppDispatch();
  const [sizePage, setSizePage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const employee = useAppSelector<any>(selectEmployee);
  const dataFormatMonth = useAppSelector(selectFormatMonth);
  const dataAssignAdjustment = employee?.dataAssignAdjustment?.result;
  const loadingAdjustment = employee?.loadingAdjustment;
  const [data, setData] = useState<any>([]);
  const [dataOri, setDataOri] = useState<any>([]);
  const [monthYearMain, setMonthYearMain] = useState(moment(monthYear));

  const { t } = useTranslation([
    'validation',
    'menu',
    'action',
    'overtime',
    'timesheet',
    'employee',
    'leaveOff',
  ]);
  const { setErrorMessage } = actions;
  const error = employee.error;
  const [errMsg, setErrMsg] = useState<any>('');
  const [errList, setErrList] = useState<any>([]);
  const dateFormat = useAppSelector(selectFormatMonth);

  const listOptionType = [
    {
      label: <PlusOutlined />,
      value: true,
    },
    {
      label: <MinusOutlined />,
      value: false,
    },
  ];

  useEffect(() => {
    if (error?.fieldErrors?.allowanceDetails) {
      const err = JSON.parse(error?.fieldErrors?.allowanceDetails);
      const msg = Object.entries(err).map((entry) => entry?.[1]);
      setErrMsg(msg);
      const errorListArray = data
        ?.filter((item: any) => msg?.some((i: any) => i?.index === item?.key))
        ?.map(({ key }: any) => key);
      setErrList(errorListArray);
    }
    return () => {
      dispatch(setErrorMessage(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (payrollUpdate?.userId) {
      dispatch(
        getAllAdjustmentUser({
          userId: payrollUpdate?.userId,
          monthYear: moment(monthYearMain).format('YYYY-MM') ?? moment().format('YYYY-MM'),
        }),
      );
    }
    if (employeeData?.id) {
      dispatch(
        getAllAdjustmentUser({
          userId: employeeData?.id,
          monthYear: moment(monthYearMain).format('YYYY-MM') ?? moment().format('YYYY-MM'),
        }),
      );
    }
  }, [employeeData?.id, monthYearMain, payrollUpdate?.userId, dispatch]);

  useEffect(() => {
    if (payrollUpdate?.userId) {
      setMonthYearMain(moment(payrollUpdate?.monthYear));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payrollUpdate?.userId]);

  // useEffect(() => {
  //   if (dataAssignAdjustment?.length)
  //     setData(dataAssignAdjustment?.map((item: any, index: any) => ({ ...item, key: index })));
  //   else setData([]);
  // }, [dataAssignAdjustment]);

  useEffect(() => {
    if (dataAssignAdjustment?.length > 0) {
      let arr: AdjustmentSalaryMap[] = [];
      dataAssignAdjustment.forEach((item: IAdjustmentSalary) => {
        let array = item.adjustments.map((obj: Adjustment, index: number) => {
          return {
            ...obj,
            key: index,
            emailParent: item.email,
            userId: item.id,
            fullNameParent: item.fullName,
            employeeIdParent: item.employeeId,
            sumAdjustment: item.sumAdjustment,
          };
        });
        arr.push(...array);
      });
      setData(arr);
      setDataOri(arr);
    } else {
      setData([]);
      setDataOri([]);
    }
  }, [dataAssignAdjustment]);

  const menu = [
    {
      name: t('action:delete'),
      type: 'delete',
      icon: <DeleteTwoTone twoToneColor="#B7B7B7" />,
      handleClick: (value: any) => {
        let copyArr = JSON.parse(JSON.stringify(data));
        copyArr.splice(
          copyArr.findIndex((item: any) => item?.key === value?.key),
          1,
        );
        setData(copyArr?.map((item: any, index: any) => ({ ...item, key: index })));
      },
    },
  ];

  const COLUMNS: any = [
    {
      title: t('employee:bonus_information_fields.col_id'),
      dataIndex: 'id',
      key: 'id',
      width: 80,
      minWidth: 80,
      align: 'center',
      render: function (text: any, record: any, index: any) {
        return <div>{record?.key + 1}</div>;
      },
    },
    {
      title: t('employee:adjustment_information_fields.adjust_name'),
      dataIndex: 'name',
      key: 'name',
      width: 150,
      minWidth: 150,
      ellipsis: true,
      render: function (text: any, record: any, index: any) {
        const trueIndex = index + sizePage * (currentPage - 1);
        return (
          <Input
            maxLength={50}
            placeholder={t('employee:adjustment_information_fields.adjust_name')}
            type={'text'}
            value={record?.typeName ?? ''}
            onChange={(e: any) => {
              let temp1: any = data?.map((obj: any, objIndex: any) =>
                objIndex === trueIndex ? { ...obj, typeName: e?.target?.value } : obj,
              );
              let temp2: any = dataOri?.map((obj: any, objIndex: any) =>
                objIndex === trueIndex ? { ...obj, typeName: e?.target?.value } : obj,
              );
              setData(temp1);
              setDataOri(temp2);
            }}
          />
        );
      },
    },
    {
      title: t('employee:allowance_information_fields.is_pay_taxes'),
      dataIndex: 'isTax',
      key: 'isTax',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: function (text: any, record: any, index: any) {
        const trueIndex = index + sizePage * (currentPage - 1);
        return (
          <Checkbox
            checked={record.isTax ?? false}
            onChange={(value: any) => {
              let temp1: any = data?.map((obj: any, objIndex: any) =>
                objIndex === trueIndex ? { ...obj, isTax: value.target.checked } : obj,
              );
              let temp2: any = dataOri?.map((obj: any, objIndex: any) =>
                objIndex === trueIndex ? { ...obj, isTax: value.target.checked } : obj,
              );
              setData(temp1);
              setDataOri(temp2);
            }}
          ></Checkbox>
        );
      },
    },
    {
      title: (
        <div>
          <PlusOutlined /> / <MinusOutlined />
        </div>
      ),
      dataIndex: 'increaseFlag',
      key: 'increaseFlag',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: function (text: any, record: any, index: any) {
        const trueIndex = index + sizePage * (currentPage - 1);
        return (
          <Select
            onChange={(value: any) => {
              let temp1: any = data?.map((obj: any, objIndex: any) =>
                objIndex === trueIndex ? { ...obj, increaseFlag: value } : obj,
              );
              let temp2: any = dataOri?.map((obj: any, objIndex: any) =>
                objIndex === trueIndex ? { ...obj, increaseFlag: value } : obj,
              );
              setData(temp1);
              setDataOri(temp2);
            }}
            value={record?.increaseFlag ?? listOptionType[0]}
            style={{ width: '100%' }}
          >
            {listOptionType.map((item: any, key: any) => (
              <Option key={key} value={item.value}>
                {item.label}
              </Option>
            ))}
          </Select>
        );
      },
    },
    {
      title: t('employee:bonus_information_fields.col_month'),
      dataIndex: 'month',
      key: 'month',
      width: 150,
      minWidth: 150,
      render: function (text: any, record: any, index: any) {
        const trueIndex = index + sizePage * (currentPage - 1);
        return (
          <DatePicker
            allowClear={false}
            format={dateFormat}
            onChange={(value: any) => {
              let temp1: any = data?.map((obj: any, objIndex: any) =>
                objIndex === trueIndex ? { ...obj, monthYear: value ? moment(value) : null } : obj,
              );
              let temp2: any = dataOri?.map((obj: any, objIndex: any) =>
                objIndex === trueIndex ? { ...obj, monthYear: value ? moment(value) : null } : obj,
              );
              setData(temp1);
              setDataOri(temp2);
            }}
            value={record?.monthYear ? moment(record?.monthYear) : undefined}
            style={{ width: '100%' }}
            picker="month"
            disabledDate={(current) => {
              return current && current < monthYearMain;
            }}
          ></DatePicker>
        );
      },
    },
    {
      title: t('employee:advance_information_fields.amount'),
      dataIndex: 'amount',
      key: 'amount',
      width: 200,
      render: function (text: any, record: any, index: any) {
        const trueIndex = index + sizePage * (currentPage - 1);
        return (
          <CustomInputNumber
            max={2000000000}
            min={1}
            style={{ width: '100%' }}
            onChange={(value: any) => {
              let temp1: any = data?.map((obj: any, objIndex: any) =>
                objIndex === trueIndex ? { ...obj, amount: value ?? 1 } : obj,
              );
              let temp2: any = dataOri?.map((obj: any, objIndex: any) =>
                objIndex === trueIndex ? { ...obj, amount: value ?? 1 } : obj,
              );

              setData(temp1);
              setDataOri(temp2);
            }}
            defaultValue={text}
            formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value: any) => parseInt(value ? value.replace(/\$\s?|(,*)/g, '') : 1)}
            addonAfter="VNĐ"
          />
        );
      },
    },
    {
      title: t('employee:adjustment_information_fields.description'),
      dataIndex: 'description',
      key: 'description',
      width: 150,
      minWidth: 150,
      ellipsis: true,
      render: function (text: any, record: any, index: any) {
        const trueIndex = index + sizePage * (currentPage - 1);
        return (
          <TextArea
            value={record?.description ?? ''}
            onChange={(e: any) => {
              let temp1: any = data?.map((obj: any, objIndex: any) =>
                objIndex === trueIndex ? { ...obj, description: e?.target?.value } : obj,
              );
              let temp2: any = dataOri?.map((obj: any, objIndex: any) =>
                objIndex === trueIndex ? { ...obj, description: e?.target?.value } : obj,
              );
              setData(temp1);
              setDataOri(temp2);
            }}
          />
        );
      },
    },
    {
      title: t('employee:employee_information_fields.action'),
      dataIndex: 'action',
      key: 'action',
      width: 60,
      minWidth: 60,
      align: 'center',
      render: function (text: any, record: any, index: any) {
        return <MenuAction menu={menu} data={record} />;
      },
    },
  ];

  const onAddRow = () => {
    if (data?.length >= 0) {
      const newList = data.concat({
        typeName: '',
        isTax: false,
        increaseFlag: listOptionType[0].value,
        monthYear: monthYearMain ?? moment(),
        key: data?.length,
        amount: 1,
        description: '',
      });
      setData(newList);
    }
  };

  const onSave = () => {
    const updateData = data?.map((item: any) => {
      const newItem = {
        monthYear: moment(item?.monthYear).format('YYYY-MM'),
        amount: item?.amount,
        typeName: item?.typeName,
        isTax: item.isTax ?? false,
        increaseFlag: item?.increaseFlag ?? true,
        description: item?.description,
      };

      if (item?.id) {
        Object.assign(newItem, { id: item?.id });
      }

      return newItem;
    });

    const deleteData = dataOri
      ?.filter((item: any) => !updateData?.some((i: any) => i?.id === item?.id))
      .map((item: any) => ({
        id: item?.id,
        typeName: item?.typeName,
        isTax: item.isTax ?? false,
        increaseFlag: item?.increaseFlag ?? true,
        monthYear: moment(item?.monthYear).format('YYYY-MM'),
        amount: item?.amount,
        description: item?.description,
        deleteFlag: true,
      }))
      .filter((item: any) => item.id);

    const childData = [...updateData, ...deleteData]?.map((item: AdjustmentSalaryMap) => {
      const { emailParent, userId, fullNameParent, employeeIdParent, sumAdjustment, ...rest } =
        item;
      return {
        ...rest,
      };
    });

    const checkError = data?.filter((item: any) => item?.typeName?.trim()?.length === 0);

    if (checkError?.length) {
      const error = data?.filter((item: any) => checkError?.some((i: any) => i?.key === item?.key));
      const errorMessage = error.map((item: any) => ({
        index: item?.index,
        message: [
          `${t('HRM_VAD_REQUIRED', {
            field: t('employee:adjustment_information_fields.adjust_name'),
          })}`,
        ],
        name: '',
      }));
      setErrMsg(errorMessage);
      const errorListArray = error?.map(({ key }: any) => key);
      setErrList(errorListArray);
      return;
    }

    dispatch(
      updateAdjustmentUser({
        id: payrollUpdate?.userId ? payrollUpdate?.userId : employeeData?.id,
        data: childData,
        callBack: () => {
          if (payrollUpdate && payrollUpdate?.callBack) {
            dispatch(
              getAllAdjustmentUser({
                userId: payrollUpdate?.userId,
                monthYear: moment(monthYearMain).format('YYYY-MM'),
              }),
            );
            payrollUpdate?.callBack();
          } else {
            dispatch(
              getAllAdjustmentUser({
                userId: employeeData?.id,
                monthYear: moment(monthYear).format('YYYY-MM'),
              }),
            );
            callBack && callBack();
          }
        },
      }),
    );
  };

  const onChangeMonthYear = (value: any) => {
    const newValue = moment(value);
    setMonthYearMain(newValue);
  };

  return (
    <div style={{ backgroundColor: '#fff', padding: 10, display: 'grid' }}>
      <StyledWrapperFilters>
        <DatePicker
          value={monthYearMain ?? moment()}
          picker="month"
          format={dataFormatMonth}
          onChange={onChangeMonthYear}
          allowClear={false}
        />

        <CustomButton icon={<PlusOutlined />} onClick={onAddRow}>
          {t('action:add_new')}
        </CustomButton>
      </StyledWrapperFilters>

      <div style={{ overflow: 'scroll' }}>
        <ITVTable
          height={350}
          loading={loadingAdjustment}
          columns={COLUMNS}
          data={data}
          isRowSelect={false}
          setOffset={setCurrentPage}
          offset={currentPage}
          setLimit={setSizePage}
          limit={sizePage}
          errorList={errList}
        />
      </div>
      {errMsg && (
        <div style={{ color: 'red', height: 80, overflowY: 'scroll' }}>
          {errMsg?.flat(1)?.map((item: any, i: any, arr: any) => {
            let divider = i < arr.length - 1 && <div></div>;
            return (
              <span key={i}>
                <div>{item?.name}</div>
                <ul style={{ listStylePosition: 'inside' }}>
                  {item?.message?.map((childItem: any) => (
                    <li style={{ listStyleType: 'disc' }}>{childItem}</li>
                  ))}
                </ul>
                {divider}
              </span>
            );
          })}
        </div>
      )}
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Popconfirm
          title={t('leaveOff:modal_item.are_you_sure_save')}
          onConfirm={() => {
            setErrList([]);
            setErrMsg(null);
            onSave();
          }}
          okText={t('Yes')}
          cancelText={t('No')}
          placement="topRight"
        >
          <CustomButton>{t('action:save')}</CustomButton>
        </Popconfirm>
      </div>
    </div>
  );
};

export default AdjustmentSalaryTable;

const CustomInputNumber = styled(InputNumber)`
  .ant-input-number-input {
    text-align: right;
  }
  .ant-input-number-handler-wrap {
    padding-right: 5px;
  }
  .ant-input-number-handler-wrap {
    display: none;
  }
`;

const StyledWrapperFilters = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
`;

import styled, { css } from 'styled-components';
import { InputNumber } from 'antd';

interface CustomInputNumberProps {
  hidearrow: boolean | false;
}

const CustomInputNumber = styled(InputNumber)`
  width: 100%;
  height: 39px;
  border-radius: 4px;
  padding-top: 4px;
  & > input {
    font-size: 14px;
    color: var(--cl_gray900);
  }
  &[disabled] {
    background-color: hsl(0, 0%, 95%);
    border-color: hsl(0, 0%, 90%);
    color: var(--cl_gray900);
  }

  ${(props: CustomInputNumberProps) =>
    props.hidearrow &&
    css`
      .ant-input-number-handler-wrap {
        display: none;
      }
    `}
`;

export default CustomInputNumber;

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { notification } from 'antd';
import moment from 'moment';

import timesheetApi from 'api/timesheetApi';
import { setGlobalLoading } from 'app/commonRedux/appSlice';
import { RootState } from 'app/store';
import { getUser } from 'utils/auth';

export const getListCheckIn = createAsyncThunk(
  'employee/GetListCheckIn',
  async (dataRequest: any) => {
    console.log(dataRequest);
    try {
      const dataResponse: any = await timesheetApi.GetListCheckIn(dataRequest);
      return dataResponse;
    } catch (error) {
      return error;
    }
  },
);

export const getCheckinData = createAsyncThunk(
  'timesheet/getCheckinData',
  async ({ callBack, ...params }: any, thunkApi: any) => {
    try {
      const response = await timesheetApi.GetListCheckIn({
        userId: params.userId,
        currentDay: params.currentDay,
      });
      callBack?.(response.data);
      return response.data;
    } catch (error) {
      callBack?.({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const checkin = createAsyncThunk(
  'timesheet/checkin',
  async ({ callBack, ...params }: any, thunkApi) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await timesheetApi.checkin(params);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack?.(response);
      return response;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack?.({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const checkout = createAsyncThunk(
  'timesheet/checkout',
  async ({ callBack, ...params }: any, thunkApi) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await timesheetApi.checkout(params);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack?.(response);
      return response;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack?.({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getTimeSheets = createAsyncThunk(
  'timesheet/getTimeSheets',
  async ({ callBack, ...params }: any, thunkApi: any) => {
    try {
      const response: any = await timesheetApi.getEmployeeTimesheet(params);
      callBack?.(response.data);
      return response.data;
    } catch (error) {
      callBack?.({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getTimeSheetsPagination = createAsyncThunk(
  'timesheet/getTimeSheetsPagination',
  async ({ callBack, ...params }: any, thunkApi: any) => {
    try {
      const response = await timesheetApi.getEmployeeTimesheetPagination(params);

      callBack?.(response?.data);
      return response?.data;
    } catch (error) {
      callBack?.({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getTimesheetByUser = createAsyncThunk(
  'timesheet/getTimesheetByUser',
  async (
    { monthYear, userId, isLoad = false }: { monthYear: string; userId: string; isLoad?: boolean },
    thunkApi,
  ) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: isLoad }));
      const response: any = await timesheetApi.getTimesheetByUser({
        monthYear,
        userId,
      });
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return response.data;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return error;
    }
  },
);

export const getManagerRequestApprovalList = createAsyncThunk(
  'timesheet/getManagerRequestApprovalList',
  async ({ callBack, ...params }: any, thunkApi: any) => {
    try {
      const response: any = await timesheetApi.GetListUserTimeManager(params);
      callBack?.(response.data);
      return response.data;
    } catch (error) {
      callBack?.({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getRequestApprovalList = createAsyncThunk(
  'timesheet/getRequestApprovalList',
  async ({ callBack, ...params }: any, thunkApi: any) => {
    try {
      const response: any = await timesheetApi.GetListUserTime(params);
      callBack?.(response.data);
      return response.data;
    } catch (error) {
      callBack?.({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getRequestApprovalDetail = createAsyncThunk(
  'timesheet/getRequestApprovalDetail',
  async ({ callBack, ...params }: any, thunkApi: any) => {
    try {
      const response: any = await timesheetApi.getRequestApprovalDetail(params);
      callBack?.(response.data);
      return response.data;
    } catch (error) {
      callBack?.({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getRequestPending = createAsyncThunk(
  'timesheet/getRequestPending',
  async ({ callBack, ...params }: any, thunkApi: any) => {
    try {
      const response: any = await timesheetApi.getRequestPending(params);
      // notification.destroy();
      callBack?.(response.data);
      return response.data;
    } catch (error) {
      callBack?.({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);
export const getRequestPendingLeave = createAsyncThunk(
  'timesheet/getRequestPendingLeave',
  async ({ callBack, ...params }: any, thunkApi: any) => {
    try {
      const response: any = await timesheetApi.getRequestPending(params);
      // notification.destroy();
      callBack?.(response.data);
      return response.data;
    } catch (error) {
      callBack?.({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);
export const getRequestPendingOT = createAsyncThunk(
  'timesheet/getRequestPendingOT',
  async ({ callBack, ...params }: any, thunkApi: any) => {
    try {
      const response: any = await timesheetApi.getRequestPending(params);
      // notification.destroy();
      callBack?.(response.data);
      return response.data;
    } catch (error) {
      callBack?.({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const createAdjustTime = createAsyncThunk(
  'timesheet/createAdjustTime',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await timesheetApi.createAdjustTime(params);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      if (params.callBack) params.callBack();
      return response;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return error;
    }
  },
);

export const updateAdjustTime = createAsyncThunk(
  'timesheet/updateAdjustTime',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await timesheetApi.updateAdjustTime(params);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      if (params.callBack) params.callBack();
      return response;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return error;
    }
  },
);

export const updateRequestLeave = createAsyncThunk(
  'timesheet/updateRequestLeave',
  async ({ callBack, ...params }: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await timesheetApi.putRequestLeave(params);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack?.(response.data);
      return response.data;
    } catch (error) {
      callBack?.({ error });
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const confirmTimesheet = createAsyncThunk(
  'timesheet/confirm',
  async ({ callBack, ...params }: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await timesheetApi.apiEditRequestIdUser(params);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack?.();
      return response;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return error;
    }
  },
);

export const cancelOverTimeRequest = createAsyncThunk(
  'timesheet/cancelOverTimeRequest',
  async ({ callBack, ...params }: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await timesheetApi.apiCancelRequest(params);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack?.(response);
      return response;
    } catch (error) {
      callBack?.({ error });
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const cancelLeaveTimeRequest = createAsyncThunk(
  'timesheet/cancelLeaveTimeRequest',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await timesheetApi.cancelLeaveTimeRequest(params);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      if (params.callBack) params.callBack(response);
      return response;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return error;
    }
  },
);

export const cancelAdjustTimeRequest = createAsyncThunk(
  'timesheet/cancelAdjustTimeRequest',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await timesheetApi.cancelAdjustTimeRequest(params);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      if (params.callBack) params.callBack();
      return response;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return error;
    }
  },
);

export const cancelConfirmationTimesheet = createAsyncThunk(
  'timesheet/cancelConfirmation',
  async (params: any, thunkApi: any) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await timesheetApi.apiEditRequestIdUser(params);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      if (params.callBack) params.callBack();
      return response;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      return error;
    }
  },
);

export const getLeaveOffType = createAsyncThunk(
  'timesheet/getLeaveOffType',
  async ({ callBack, ...params }: any, thunkApi) => {
    try {
      const response = await timesheetApi.getRequestLeaveType({ userId: getUser()?.id });
      callBack?.(response.data);
      return response.data;
    } catch (error) {
      callBack?.({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const createLeaveOff = createAsyncThunk(
  'timesheet/createLeaveOff',
  async ({ callBack, ...params }: any, thunkApi) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await timesheetApi.createLeaveOff(params);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack?.(response.data);
      return response.data;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack?.({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getLeaveOffById = createAsyncThunk(
  'timesheet/getLeaveOffById',
  async ({ callBack, ...params }: any, thunkApi) => {
    try {
      const response: any = await timesheetApi.getLeaveOffById(params);
      callBack?.(response.data);
      return response.data;
    } catch (error) {
      callBack?.({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getAdjustTimeById = createAsyncThunk(
  'timesheet/getAdjustTimeById',
  async ({ callBack, ...params }: any, thunkApi) => {
    try {
      const response: any = await timesheetApi.getAdjustTimeById(params);
      callBack?.(response.data);
      return response.data;
    } catch (error) {
      callBack?.({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getOverTimeById = createAsyncThunk(
  'timesheet/getOverTimeById',
  async ({ callBack, ...params }: any, thunkApi) => {
    try {
      const response: any = await timesheetApi.getOverTimeById(params);
      callBack?.(response.data);
      return response.data;
    } catch (error) {
      callBack?.({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getTimeTotalHour = createAsyncThunk(
  'timesheet/getTimeTotalHour',
  async ({ callBack, ...params }: any, thunkApi) => {
    try {
      const response: any = await timesheetApi.getTimeTotalHour(params);
      callBack?.(response.data);
      return response.data;
    } catch (error) {
      callBack?.({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getInformedRequests = createAsyncThunk(
  'timesheet/getInformedRequests',
  async ({ callBack, ...params }: any, thunkApi) => {
    try {
      const response: any = await timesheetApi.apiInformTo(params);
      callBack?.(response.data);
      return response.data;
    } catch (error) {
      callBack?.({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const createOverTime = createAsyncThunk(
  'timesheet/createOverTime',
  async ({ callBack, ...params }: any, thunkApi) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await timesheetApi.createOverTime(params);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack?.(response.data);
      return response.data;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack?.({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const approveRequest = createAsyncThunk(
  'timesheet/approveRequest',
  async ({ callBack, ...params }: any, thunkApi) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await timesheetApi.apiManagerResetRequest(params);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack?.(response);
      return response;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack?.({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const approveRequestForRequestPending = createAsyncThunk(
  'timesheet/approveRequestForRequestPending',
  async ({ callBack, ...params }: any, thunkApi) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await timesheetApi.apiRequestPending(params);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack?.(response);
      return response;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack?.({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const handleRequestTimesheet = createAsyncThunk(
  'timesheet/handleRequestTimesheet',
  async ({ callBack, ...params }: any, thunkApi) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await timesheetApi.apiHandleRequestTimesheet(params);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack?.(response);
      return response;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack?.({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const approveMultiRequest = createAsyncThunk(
  'timesheet/approveMultiRequest',
  async ({ callBack, ...params }: any, thunkApi) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await timesheetApi.apiApproveMultiRequest(params);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack?.(response);
      return response;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack?.({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const editRequestMultiTimeSheet = createAsyncThunk(
  'timesheet/editRequestMultiTimeSheet',
  async ({ callBack, ...params }: any, thunkApi) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await timesheetApi.apiEditRequestIdUser(params);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack?.(response);
      return response;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack?.({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const resetTimeSheet = createAsyncThunk(
  'timesheet/resetTimeSheet',
  async ({ callBack, ...params }: any, thunkApi) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await timesheetApi.apiEditRequestIdUser(params);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack?.(response);
      return response;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack?.({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const alertBeforeCreateOt = createAsyncThunk(
  '/timesheet/alert-request-over-time',
  async ({ callBack, ...params }: any, thunkApi) => {
    try {
      thunkApi.dispatch(setGlobalLoading({ loading: true }));
      const response: any = await timesheetApi.getAlertBeforeCreateOt(params);
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack?.(response);
      return response;
    } catch (error) {
      thunkApi.dispatch(setGlobalLoading({ loading: false }));
      callBack?.({ error });
      return thunkApi.rejectWithValue(error);
    }
  },
);

const timesheet = createSlice({
  name: 'timesheet',
  initialState: {
    isGettingTimesheet: false,
    isLoadingTimeSheets: false,
    isLoadingLeaveOffById: false,
    isLoadingOverTimeById: false,
    isLoadingRequestApprovalList: false,
    isLoadingRequestApprovals: false,
    isLoadingRequestApprovalDetail: false,
    isLoadingInformedRequests: false,
    isCallApiOneTime: false,
    timesheetListApproved: [],
    error: null,
    data: [],
    total: 0,
    searchCriteria: {},
    lstColTableEmployee: [],

    isLoading: false,
    dataResponse: null,
    calendarData: [],
    timeSheets: [],
    totalResult: 0,
    limit: '',
    offset: '',
    requestApprovals: [],
    informedRequests: [],
    currentMonth: moment().format(),
    currentEmployee: {},
    project: '',

    totalPendingAdjust: 0,
    totalPendingAdjustSearch: 0,
    isLoadingPendingAdjust: false,
    listPendingAdjust: [],

    totalPendingLeave: 0,
    totalPendingLeaveSearch: 0,
    isLoadingPendingLeave: false,
    listPendingLeave: [],

    totalPendingOT: 0,
    totalPendingOTSearch: 0,
    isLoadingPendingOT: false,
    listPendingOT: [],
  },
  reducers: {
    setCurrentMonth(state, action: PayloadAction<string>) {
      state.currentMonth = action.payload;
    },
    setCurrentEmployee(state, action: any) {
      state.currentEmployee = action.payload;
    },
    setErrorMessage(state, action) {
      state.error = action.payload;
    },
    setCurrentFilter(state, action) {
      state.project = action.payload;
    },
    setDefaultCalendar(state) {
      state.calendarData = [];
    },
    setCallApiOneTime(state, action) {
      state.isCallApiOneTime = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTimeSheets.pending, (state, action: any) => {
        state.isLoadingTimeSheets = true;
        state.error = null;
      })
      .addCase(getTimeSheets.fulfilled, (state, action) => {
        state.isLoadingTimeSheets = false;
        state.error = null;
        state.timeSheets = action.payload.result;
      })
      .addCase(getTimeSheets.rejected, (state, action: any) => {
        state.isLoadingTimeSheets = false;
        state.error = action.payload;
        state.timeSheets = [];
      })
      .addCase(getTimeSheetsPagination.pending, (state, action: any) => {
        state.isLoadingTimeSheets = true;
        state.error = null;
      })
      .addCase(getTimeSheetsPagination.fulfilled, (state, action) => {
        state.isLoadingTimeSheets = false;
        state.error = null;
        state.timeSheets = action.payload.result;
        state.limit = action.payload.limit;
        state.total = action.payload.totalWorkDay;
        state.totalResult = action.payload.totalResult;
      })
      .addCase(getTimeSheetsPagination.rejected, (state, action: any) => {
        state.isLoadingTimeSheets = false;
        state.error = action.payload;
        state.timeSheets = [];
      })
      .addCase(getListCheckIn.pending, (state, action) => {
        state.isGettingTimesheet = true;
        state.error = null;
        state.timesheetListApproved = [];
      })
      .addCase(getListCheckIn.fulfilled, (state, action) => {
        state.isGettingTimesheet = false;
        state.error = null;
      })
      .addCase(getListCheckIn.rejected, (state, action: any) => {
        state.isGettingTimesheet = false;
        state.data = [];
      })
      .addCase(getTimesheetByUser.pending, (state, action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getTimesheetByUser.fulfilled, (state, action: any) => {
        state.isLoading = false;
        state.error = null;
        state.calendarData = action.payload ? action.payload : [];
      })
      .addCase(getTimesheetByUser.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getLeaveOffById.pending, (state, action) => {
        state.isLoadingLeaveOffById = true;
      })
      .addCase(getLeaveOffById.fulfilled, (state, action: any) => {
        state.isLoadingLeaveOffById = false;
      })
      .addCase(getLeaveOffById.rejected, (state, action: any) => {
        state.isLoadingLeaveOffById = false;
      })
      .addCase(getOverTimeById.pending, (state, action) => {
        state.isLoadingOverTimeById = true;
      })
      .addCase(getOverTimeById.fulfilled, (state, action: any) => {
        state.isLoadingOverTimeById = false;
      })
      .addCase(getOverTimeById.rejected, (state, action: any) => {
        state.isLoadingOverTimeById = false;
      })
      .addCase(getManagerRequestApprovalList.pending, (state, action) => {
        state.isLoadingRequestApprovalList = true;
      })
      .addCase(getManagerRequestApprovalList.fulfilled, (state, action: any) => {
        state.isLoadingRequestApprovalList = false;
      })
      .addCase(getManagerRequestApprovalList.rejected, (state, action: any) => {
        state.isLoadingRequestApprovalList = false;
      })
      .addCase(getRequestApprovalDetail.pending, (state, action) => {
        state.isLoadingRequestApprovalDetail = true;
      })
      .addCase(getRequestApprovalDetail.fulfilled, (state, action: any) => {
        state.isLoadingRequestApprovalDetail = false;
      })
      .addCase(getRequestApprovalDetail.rejected, (state, action: any) => {
        state.isLoadingRequestApprovalDetail = false;
      })
      .addCase(getRequestApprovalList.pending, (state, action) => {
        state.isLoadingRequestApprovals = true;
      })
      .addCase(getRequestApprovalList.fulfilled, (state, action: any) => {
        state.isLoadingRequestApprovals = false;
        state.requestApprovals = action.payload.result;
      })
      .addCase(getRequestApprovalList.rejected, (state, action: any) => {
        state.isLoadingRequestApprovals = false;
        state.requestApprovals = [];
      })
      .addCase(getInformedRequests.pending, (state, action) => {
        state.isLoadingInformedRequests = true;
      })
      .addCase(getInformedRequests.fulfilled, (state, action: any) => {
        state.isLoadingInformedRequests = false;
        state.informedRequests = action.payload.result;
      })
      .addCase(getInformedRequests.rejected, (state, action: any) => {
        state.isLoadingInformedRequests = false;
        state.informedRequests = [];
      })
      .addCase(createOverTime.pending, (state, action) => {
        state.error = null;
      })
      .addCase(createOverTime.fulfilled, (state, action: any) => {
        state.error = null;
      })
      .addCase(createOverTime.rejected, (state, action: any) => {
        state.error = action.payload;
      })
      .addCase(getRequestPending.pending, (state, action) => {
        state.isLoadingPendingAdjust = true;
      })
      .addCase(getRequestPending.fulfilled, (state, action: any) => {
        state.isLoadingPendingAdjust = false;
        let list = action.payload.result.map(function (obj: any) {
          const { id, request_id, ...objRest } = obj;

          return { ...objRest, user_id: id, id: request_id };
        });
        state.listPendingAdjust = list;
        state.totalPendingAdjust = action.payload.total;
        state.totalPendingAdjustSearch = action.payload.totalResult;
      })
      .addCase(getRequestPending.rejected, (state, action: any) => {
        state.isLoadingPendingAdjust = false;
        state.totalPendingAdjust = 0;
      })
      .addCase(getRequestPendingLeave.pending, (state, action) => {
        state.isLoadingPendingLeave = true;
      })
      .addCase(getRequestPendingLeave.fulfilled, (state, action: any) => {
        state.isLoadingPendingLeave = false;
        let list = action.payload.result.map(function (obj: any) {
          const { id, request_id, ...objRest } = obj;

          return { ...objRest, user_id: id, id: request_id };
        });
        state.listPendingLeave = list;
        state.totalPendingLeave = action.payload.total;
        state.totalPendingLeaveSearch = action.payload.totalResult;
      })
      .addCase(getRequestPendingLeave.rejected, (state, action: any) => {
        state.isLoadingPendingLeave = false;
        state.totalPendingLeave = 0;
      })
      .addCase(getRequestPendingOT.pending, (state, action) => {
        state.isLoadingPendingOT = true;
      })
      .addCase(getRequestPendingOT.fulfilled, (state, action: any) => {
        state.isLoadingPendingOT = false;
        let list = action.payload.result.map(function (obj: any) {
          const { id, request_id, ...objRest } = obj;

          return { ...objRest, user_id: id, id: request_id };
        });
        state.listPendingOT = list;
        state.totalPendingOT = action.payload.total;
        state.totalPendingOTSearch = action.payload.totalResult;
      })
      .addCase(getRequestPendingOT.rejected, (state, action: any) => {
        state.isLoadingPendingOT = false;
        state.totalPendingOT = 0;
      });
  },
});

export const selectTimesheet = (state: RootState) => state.timesheet;

export const selectCurrentMonth = (state: RootState) => state.timesheet.currentMonth;

export const selectCurrentEmployee = (state: RootState) => state.timesheet.currentEmployee;

export const selectUserIdCurrentEmployee = (state: RootState) =>
  state.timesheet.currentEmployee?.user;

export const selectUsernameCurrentEmployee = (state: RootState) =>
  state.timesheet.currentEmployee?.userName;

export const selectDisableAdjustTime = (state: RootState) =>
  state.timesheet?.calendarData?.disableAdjustTime;

export const { reducer, actions } = timesheet;

export const {
  setCurrentMonth,
  setCurrentEmployee,
  setCurrentFilter,
  setDefaultCalendar,
  setCallApiOneTime,
} = actions;

export default reducer;

export const customStyleManager = (editor, options) => {
  const { isTypeTable, componentTypeTable, defaultValueCellspacing } = options;
  const styleManager = editor?.StyleManager;

  editor?.on('component:selected', (component) => {
    const checkTypeTable = component.is('table');
    isTypeTable.current = checkTypeTable;
    if (checkTypeTable) {
      componentTypeTable.current = component;
      defaultValueCellspacing.current = component.getAttributes()?.cellspacing;
      component.set({ unstylable: ['padding', 'margin'] });
    } else {
      component.set({ unstylable: ['cellspacing', 'padding'] });
    }
  });

  if (styleManager) {
    editor?.on('load', () => {
      styleManager.addProperty(
        'dimension',
        {
          label: 'Cellspacing',
          property: 'cellspacing',
          type: 'number',
          placeholder: 'auto',
          min: 0,
          onChange: ({ property, opts }) => {
            const getValueChangeStyleSpacing = property.getValue();
            if (componentTypeTable.current && isTypeTable.current) {
              if (opts.__clear) {
                property.upValue(0);
              }
              if (!property.hasValue() && defaultValueCellspacing.current) {
                property.upValue(defaultValueCellspacing.current);
              } else {
                if (!getValueChangeStyleSpacing) {
                  componentTypeTable.current.setAttributes({ cellspacing: 0 });
                } else {
                  componentTypeTable.current.setAttributes({
                    cellspacing: getValueChangeStyleSpacing,
                  });
                }
              }
            }
          },
        },
        { at: 4 },
      );
    });
  }
};

import { Tabs } from 'antd';
import ITVTable from 'components/ITVTable';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Container } from './styled';
import PageHeader from 'components/PageHeader';
import { useAppSelector } from 'app/hooks';
import { selectConfiguration } from 'features/configuration/configurationSlice';
import { selectPerformanceData } from 'features/performance/performanceSlice';
import { getListAssessmentEmployee } from 'features/performance/performanceAction';
import { getUser } from 'utils/auth';
import ButtonBack from 'components/ButtonBack';
import { useTranslation } from 'react-i18next';
import { TitleColumnCenter } from 'styles/tableStyled';

const { TabPane } = Tabs;

const PerformanceReviewSelf = ({ isStaff }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { id }: any = useParams();
  const stateURL = location.state;

  const { t } = useTranslation(['employee', 'performance']);

  const menuStore = useAppSelector(selectConfiguration);
  const dataScreenManage = menuStore?.dataScreenManage?.result;
  const performanceStore = useAppSelector(selectPerformanceData);
  const listAssessmentEmployee = performanceStore?.listAssessmentEmployee?.result || [];
  const maxNumberReviewer = performanceStore?.listAssessmentEmployee?.maxNumberReviewer || 0;
  const [titleHeader, setTitleHeader] = useState('');
  const [sizePage, setSizePage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const temp = dataScreenManage
      ?.filter((item) => location.pathname === item.path)
      ?.map((item) => item.nameScreen);

    setTitleHeader(temp ? temp[0] : '');
  }, [dataScreenManage, location.pathname]);

  useEffect(() => {
    dispatch(getListAssessmentEmployee({ userId: id ? id : getUser()?.id }));
  }, [dispatch]);

  // Generate reviewer columns based on maxNumberReviewer
  const reviewerColumns = [];
  for (let i = 0; i < maxNumberReviewer; i++) {
    reviewerColumns.push({
      title: `Reviewer ${i + 1}`,
      key: `reviewer_${i}`,
      dataIndex: `reviewer_${i}`,
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (text) => {
        return text || '_';
      },
    });
  }

  const columns = [
    {
      title: () => {
        return <TitleColumnCenter>{t('employee:performance_review.col_id')}</TitleColumnCenter>;
      },
      key: 'employeeCode',
      dataIndex: 'employeeCode',
      width: 80,
      minWidth: 80,
      align: 'center',
      render: (text, record, index) => {
        const trueIndex = index + sizePage * (currentPage - 1);
        return <div>{trueIndex + 1}</div>;
      },
    },
    {
      title: () => {
        return (
          <TitleColumnCenter>
            {t('employee:performance_review.review_cycle_name')}
          </TitleColumnCenter>
        );
      },
      key: 'reviewCycleName',
      dataIndex: 'reviewCycleName',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (text: any) => {
        return text;
      },
    },
    ...reviewerColumns,
    {
      title: () => {
        return <TitleColumnCenter>{t('employee:performance_review.status')}</TitleColumnCenter>;
      },
      key: 'assessmentStatus',
      dataIndex: 'assessmentStatus',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (text: any) => {
        return text;
      },
    },
    {
      title: () => {
        return (
          <TitleColumnCenter>{t('employee:performance_review.overall_score')}</TitleColumnCenter>
        );
      },
      key: 'overallScore',
      dataIndex: 'overallScore',
      width: 100,
      minWidth: 100,
      align: 'center',
      render: (text: any) => {
        return typeof text === 'number' ? text : '_';
      },
    },
    {
      title: () => {
        return <TitleColumnCenter>{t('employee:performance_review.action')}</TitleColumnCenter>;
      },
      key: 'action',
      width: 100,
      minWidth: 100,
      align: 'center',
      fixed: 'right',
      render: (text: any, record: any) => (
        <p
          style={{ color: 'blue', cursor: 'pointer', marginBottom: 0 }}
          onClick={() => {
            history.push({
              pathname: isStaff
                ? `/my-page/performance-review/detail/${record?.revieCycleId}`
                : `/performance/employee-pr-management/${id}/${record?.revieCycleId}`,
            });
          }}
        >
          {t('performance:view_evaluate')}
        </p>
      ),
    },
  ];

  // Transform the data to match the columns
  const transformedData = listAssessmentEmployee.map((item) => {
    const reviewers = {};
    for (let i = 0; i < maxNumberReviewer; i++) {
      reviewers[`reviewer_${i}`] = item.reviewers[i] ? item.reviewers[i].userName : '_';
    }
    return {
      ...item,
      ...reviewers,
    };
  });

  return (
    <Container>
      {id && <ButtonBack handleBack={() => history.goBack()} />}
      <PageHeader title={`${stateURL?.full_name || ''}`} />
      <ITVTable
        data={transformedData}
        columns={columns}
        isRowSelect={false}
        setOffset={setCurrentPage}
        offset={currentPage}
        setLimit={setSizePage}
        limit={sizePage}
        totalResult={performanceStore?.listAssessmentEmployee?.totalResult || undefined}
      />
    </Container>
  );
};

export default PerformanceReviewSelf;

import { Modal } from 'antd';
import styled from 'styled-components';

export const EmployeeModalDetail = styled(Modal)`
  .ant-modal-content {
    width: 1000px;
    .ant-modal-close-x {
      margin-top: -10px;
      margin-right: -10px;
    }
    .ant-modal-body {
    }
  }
`;

import { useNodes, SmoothStepEdge, EdgeText } from 'react-flow-renderer';
import { getSmartEdge } from '../pathFinding/getSmartEdge';
import { edgePaths } from './Utils';

export default function SmartEdge(props, onEdgePathUpdate) {
  const {
    id,
    sourcePosition,
    targetPosition,
    sourceX,
    sourceY,
    targetX,
    targetY,
    style,
    markerStart,
    markerEnd,
    label,
    labelStyle,
    labelShowBg,
    labelBgStyle,
    labelBgPadding,
    labelBgBorderRadius,
  } = props;

  const nodes = useNodes();

  const currentEdgePathIndex = edgePaths.getEdgePathIndex(id);
  let currentEdgePaths = edgePaths.getEdgePaths();

  if (currentEdgePathIndex !== null) {
    currentEdgePaths = currentEdgePaths.slice(0, currentEdgePathIndex);
  }

  const getSmartEdgeResponse = getSmartEdge({
    sourcePosition,
    targetPosition,
    sourceX,
    sourceY,
    targetX,
    targetY,
    nodes,
    edgesPath: currentEdgePaths,
    options: {
      nodePadding: 20,
      edgePadding: 3, //edgePadding => mutiple of gridRatio
      gridRatio: 5,
    },
  });

  let { smoothedPath, graphPath } = getSmartEdgeResponse || {};

  edgePaths.setEdgePaths({ id, smoothedPath, graphPath });

  // If the value returned is null, it means "getSmartEdge" was unable to find
  // a valid path, and you should do something else instead
  if (getSmartEdgeResponse === null) {
    return <SmoothStepEdge {...props} />;
  }

  const index = Math.floor(graphPath.length / 2);
  const middlePoint = graphPath[index];
  const [middleX, middleY] = middlePoint;

  const { svgPathString } = getSmartEdgeResponse;

  return (
    <>
      <path
        id={id}
        style={style}
        d={svgPathString}
        className="react-flow__edge-path"
        markerEnd={markerEnd}
        markerStart={markerStart}
      />
      <EdgeText
        x={middleX}
        y={middleY}
        label={label}
        labelStyle={labelStyle}
        labelShowBg={labelShowBg}
        labelBgStyle={labelBgStyle}
        labelBgPadding={labelBgPadding}
        labelBgBorderRadius={labelBgBorderRadius}
      />
    </>
  );
}

import { CheckOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import { selectFormatDate, selectFormatDatetime } from 'app/commonRedux/appSlice';
import { useAppSelector } from 'app/hooks';
import ITVSearch from 'components/ITVSearch';
import ITVTable from 'components/ITVTable';
import StatusView from 'components/StatusView';
import EmployeeColumn from 'features/employee/components/EmployeeColumn';
import {
  getHistoryProjectManageUserDetail,
  getProjectList,
} from 'features/overtime/overtimeAction';
import { selectOvertimeData } from 'features/overtime/overtimeSlice';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { removeAccents } from 'utils/text';

const { Option } = Select;

const ViewUser = (props: any) => {
  const dispatch = useDispatch();
  const [data, setData] = useState<any>([]);
  const { t } = useTranslation(['validation', 'overtime', 'timesheet', 'employee']);
  const [sizePage, setSizePage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const dateFormat = useAppSelector(selectFormatDate);
  const formatDatetime = useAppSelector(selectFormatDatetime);
  const overtimeData = useAppSelector(selectOvertimeData);
  const listProject = overtimeData?.listProject;
  const dataHistoryUserDetail = overtimeData?.dataHistoryUserDetail;
  const loadingDataHistoryUserDetail = overtimeData?.loadingDataHistoryUserDetail;

  const [project, setProject] = useState('');
  const projectStatus = [
    { key: '', value: 'All' },
    { key: 'new', value: 'New' },
    { key: 'active', value: 'Active' },
    { key: 'pending', value: 'Pending' },
    { key: 'close', value: 'Close' },
  ];
  const [projectStatusState, setProjectStatusState] = useState('');
  const [text, setText] = useState('');

  useEffect(() => {
    dispatch(getProjectList());
  }, [dispatch]);

  useEffect(() => {
    const params = {
      projectCode: project,
      status: projectStatusState,
    };
    dispatch(getHistoryProjectManageUserDetail(params));
  }, [projectStatusState, project, dispatch]);

  useEffect(() => {
    if (dataHistoryUserDetail) {
      if (text) {
        const temp = dataHistoryUserDetail.filter((item: any) => {
          return removeAccents(item?.projectDetail?.user?.fullName?.toLowerCase()).includes(text);
        });
        setData(temp);
      } else setData(dataHistoryUserDetail);
    }
  }, [dataHistoryUserDetail, text]);

  const COLUMNS = [
    {
      title: 'ID',
      dataIndex: 'employeeId',
      key: 'employeeId',
      width: 60,
      minWidth: 60,
      ellipsis: true,
      render: function (text: any, record: any, index: any) {
        const trueIndex = index + sizePage * (currentPage - 1);
        const obj: any = {
          children: record?.projectDetail?.user?.employeeId,
          props: { style: { verticalAlign: 'top' } },
        };
        if (
          index >= 1 &&
          record?.projectDetail?.user?.employeeId ===
            data[trueIndex - 1]?.projectDetail?.user?.employeeId
        ) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== data.length &&
            record?.projectDetail?.user?.employeeId ===
              data[trueIndex + i]?.projectDetail?.user?.employeeId;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }
        return obj;
      },
    },
    {
      title: t('timesheet:Employee'),
      dataIndex: 'fullName',
      key: 'fullName',
      width: 200,
      minWidth: 200,
      ellipsis: true,
      render: function (text: any, record: any, index: any) {
        const trueIndex = index + sizePage * (currentPage - 1);
        const obj: any = {
          children: (
            <EmployeeColumn
              avatar={
                record?.projectDetail?.user?.fullName
                  ? `${record?.projectDetail?.user?.fullName}`
                  : `${record?.projectDetail?.user?.lastName} ${record?.projectDetail?.user?.firstName}`
              }
              fullName={
                record?.projectDetail?.user?.fullName
                  ? `${record?.projectDetail?.user?.fullName}`
                  : `${record?.projectDetail?.user?.lastName} ${record?.projectDetail?.user?.firstName}`
              }
              email={record?.projectDetail?.user?.email}
            />
          ),
          props: { style: { verticalAlign: 'top' } },
        };
        if (
          index >= 1 &&
          record?.projectDetail?.user?.employeeId ===
            data[trueIndex - 1]?.projectDetail?.user?.employeeId
        ) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== data.length &&
            record?.projectDetail?.user?.employeeId ===
              data[trueIndex + i]?.projectDetail?.user?.employeeId;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }
        return obj;
      },
    },
    {
      title: 'Updated at',
      dataIndex: 'updatedAt',
      align: 'center',
      key: 'updatedAt',
      width: 100,
      minWidth: 100,
      render: function (text: any, record: any, index: any) {
        return text ? moment(text).format(formatDatetime) : null;
      },
    },
    {
      title: t('overtime:project_code'),
      dataIndex: 'projectCode',
      key: 'projectCode',
      width: 100,
      minWidth: 100,
      align: 'left',
      render: function (text: any, record: any, index: any) {
        const trueIndex = index + sizePage * (currentPage - 1);
        const obj: any = {
          children: record?.projectCode,
          props: { style: { verticalAlign: 'top' } },
        };
        if (
          index >= 1 &&
          record?.projectDetail?.user?.employeeId ===
            data[trueIndex - 1]?.projectDetail?.user?.employeeId &&
          data[trueIndex - 1]?.projectCode === record?.projectCode
        ) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== data.length &&
            record?.projectDetail?.user?.employeeId ===
              data[trueIndex + i]?.projectDetail?.user?.employeeId &&
            data[trueIndex + i]?.projectCode === record?.projectCode;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }
        return obj;
      },
    },
    {
      title: t('overtime:project_name'),
      dataIndex: 'name',
      key: 'name',
      width: 100,
      minWidth: 100,
      align: 'left',
      render: function (text: any, record: any, index: any) {
        const trueIndex = index + sizePage * (currentPage - 1);
        const obj: any = {
          children: record?.projectDetail?.project?.name,
          props: { style: { verticalAlign: 'top' } },
        };
        if (
          index >= 1 &&
          record?.projectDetail?.user?.employeeId ===
            data[trueIndex - 1]?.projectDetail?.user?.employeeId &&
          data[trueIndex - 1]?.projectCode === record?.projectCode
        ) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== data.length &&
            record?.projectDetail?.user?.employeeId ===
              data[trueIndex + i]?.projectDetail?.user?.employeeId &&
            data[trueIndex + i]?.projectCode === record?.projectCode;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }
        return obj;
      },
    },
    {
      title: t('overtime:join_date'),
      dataIndex: 'joinDate',
      key: 'joinDate',
      width: 100,
      minWidth: 100,
      render: function (text: any, record: any, index: any) {
        return text ? moment(text).format(dateFormat) : null;
      },
    },
    {
      title: t('timesheet:End_date'),
      dataIndex: 'endDate',
      key: 'endDate',
      width: 100,
      minWidth: 100,
      render: function (text: any, record: any, index: any) {
        return text ? moment(text).format(dateFormat) : null;
      },
    },
    {
      title: t('overtime:project_leader'),
      dataIndex: 'isProjectLeader',
      key: 'isProjectLeader',
      width: 100,
      minWidth: 100,
      align: 'left',
      render: function (text: any, record: any, index: any) {
        return text ? <CheckOutlined /> : null;
      },
    },
    {
      title: t('employee:employee_information_fields.position'),
      dataIndex: 'position',
      key: 'position',
      width: 100,
      minWidth: 100,
      align: 'left',
    },
    {
      title: t('timesheet:Status'),
      dataIndex: 'deleteFlag',
      key: 'deleteFlag',
      width: 120,
      minWidth: 120,
      align: 'left',
      render: function (text: any, record: any, index: any) {
        return <StatusView text={text ? 'delete' : 'active'} />;
      },
    },
    {
      title: 'Updated by',
      dataIndex: 'updatedBy',
      key: 'updatedBy',
      width: 200,
      minWidth: 200,
      render: function (text: any, record: any, index: any) {
        return (
          <EmployeeColumn avatar={text?.fullName} fullName={text?.fullName} email={text?.email} />
        );
      },
    },
  ];

  const onSearchList = (key: string) => {
    const txt = removeAccents(key.toLowerCase());
    setCurrentPage(1);
    if (txt) {
      setText(txt);
    } else {
      setText('');
    }
  };

  return (
    <Container>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ width: '100%', display: 'flex' }}>
          <SearchContainer>
            <ITVSearch
              style={{ width: 'inherit' }}
              handleSearch={(value: any) => {
                onSearchList(value);
              }}
            />
          </SearchContainer>
          <div style={{ marginLeft: 10 }}>
            <Select
              showSearch
              style={{ width: 200 }}
              defaultValue={project}
              onChange={(value: any) => setProject(value)}
              filterOption={(input, option: any) =>
                (option!.value as unknown as string).toLowerCase().includes(input.toLowerCase())
              }
            >
              <Option value="">{t('overtime:all_projects')}</Option>
              {listProject?.map((item: any) => {
                return (
                  <Option value={item?.projectCode} title={item?.name}>
                    {item?.projectCode} - {item?.name}
                  </Option>
                );
              })}
            </Select>
          </div>
          <div style={{ marginLeft: 10 }}>
            <Select
              onChange={(value: any) => setProjectStatusState(value)}
              defaultValue=""
              style={{ width: 100 }}
              filterOption={(input, option: any) =>
                (option!.value as unknown as string).toLowerCase().includes(input.toLowerCase())
              }
            >
              {projectStatus?.map((item: any) => (
                <Option value={item?.key}>{item?.value}</Option>
              ))}
            </Select>
          </div>
        </div>
      </div>
      <ITVTable
        isRowSelect={false}
        loading={loadingDataHistoryUserDetail}
        columns={COLUMNS}
        height={550}
        isScroll={true}
        width={'max-content'}
        data={data}
        isRowLight={true}
        setOffset={setCurrentPage}
        offset={currentPage}
        setLimit={setSizePage}
        limit={sizePage}
      />
    </Container>
  );
};

export default ViewUser;

export const SearchContainer = styled.div`
  width: 200px;
  margin-bottom: 20px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  background: white;
  width: 100%;
`;

import axiosClient from './axiosClient';

const equipmentApi = {
  getEquipments: () => {
    const response = axiosClient.get(`/equipment`);
    return response;
  },
  createEquipment: (params: any) => {
    const response = axiosClient.post(`/equipment`, {
      user: params.userId,
      status: 'not-required',
    });
    return response;
  },
  getEquipmentsByEmployee: (params: any) => {
    const response = axiosClient.get(`/equipment-detail/employee/${params.id}`);
    return response;
  },
  createEquipmentDetail: (params: any) => {
    const response = axiosClient.post('/equipment-detail', params);
    return response;
  },
  confirmEquipmentDetail: (params: any) => {
    const response = axiosClient.put(`/equipment-detail/${params.id}/confirm`, {
      confirm: params.status,
    });
    return response;
  },
  updateEquipmentDetailStatus: (params: any) => {
    const response = axiosClient.put(`/equipment-detail/${params.id}/status`, {
      status: 'done-handover',
    });
    return response;
  },
  updateEquipmentDetail: (params: any) => {
    const response = axiosClient.put(`/equipment-detail/${params.id}`, {
      name: params.name,
      description: params.description,
      status: params.status,
      type: params.type,
      handoverParty: params.handoverParty,
      equipment: params.equipment,
      handoverDate: params.handoverDate,
    });
    return response;
  },
  deleteEquipmentDetail: (params: any) => {
    const response = axiosClient.delete(`/equipment-detail/${params.id}`);
    return response;
  },
};

export default equipmentApi;
